import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DetailDrawer from 'containers/APModule/components/DetailDrawer'
import format from 'date-fns/format'
import { dateFormat } from 'helpers/StringNumberFunction/FormatDate'
import React, { useState } from 'react'

export const InboundTable = (props: any) => {
  const { data } = props

  // ==== Table Pagination =====
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClick = x => {
    console.log('Clicked row:', x)
    // Add any additional actions here, such as navigation or opening a modal
    setDrawerOpen(true)
  }

  const [drawerOpen, setDrawerOpen] = useState(false)

  const closeDrawer = () => {
    setDrawerOpen(false)
  }

  console.log('Clicked row:', data)

  return (
    <>
      {/* <TemporaryDrawer /> */}
      <TableContainer component={Paper}>
        <Table style={{ minWidth: '700' }} size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '10%',
                }}
              >
                Doc Date
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '15%',
                }}
              >
                Doc No
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  width: '15%',
                }}
              >
                Doc Type
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '40%',
                }}
              >
                Creditor Name
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Doc Amount
              </StyledTableCell>
              <StyledTableCell
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ff9800',
                  textAlign: 'right',
                  width: '15%',
                }}
              >
                Tax Amount
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((v, i) => {
                return (
                  <TableRow key={i}>
                    <StyledTableCell size="small">
                      {!!v?.APDocDate
                        ? format(new Date(v?.APDocDate), 'dd/MM/yyyy')
                        : dateFormat(v?.DocDate)}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <span
                        onClick={() => {
                          console.log(v, 'apa eh')

                          handleClick(v)
                        }}
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        {v?.DocNo || v?.APDocNo}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      {v?.CreditorAccount?.CompanyName}
                    </StyledTableCell>
                    <StyledTableCell
                      size="small"
                      style={{ textAlign: 'right' }}
                    >
                      {amtStr(v?.DocAmt)}
                    </StyledTableCell>
                    <StyledTableCell
                      size="small"
                      style={{ textAlign: 'right' }}
                    >
                      {amtStr(v?.TaxAmt)}
                    </StyledTableCell>
                  </TableRow>
                )
              })}
          </TableBody>
          {/* Render the drawer component */}
          <DetailDrawer
            anchor="right" // Set the drawer anchor as desired
            isOpen={drawerOpen}
            onClose={closeDrawer}
            data={data}
          />
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2F53E6',
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 12,
  },
  body: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
  },
}))(TableCell)
