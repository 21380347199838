import React from 'react'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import clsx from 'clsx'
import { APItemDetailContent } from '../APDetail/APItemDetailExpansion'
import { Box, Divider, Drawer, Typography } from '@material-ui/core'
import theme from '@ifca-root/react-component/src/assets/theme'

const useStyles = makeStyles({
  list: {
    width: '100%',
  },
  drawer: {
    width: '70%', // Customize width as per your need
    maxWidth: 600, // Optional: max width to prevent it from getting too wide on large screens
  },
  drawerPaper: {
    position: 'fixed',
    bottom: 0, // Align the drawer to the bottom of the screen
    right: 0, // Align the drawer to the right side of the screen
    width: '30vw', // Customize this width to what you prefer
    height: '70vh', // Customize this height as needed
    borderTopLeftRadius: theme.spacing(2), // Optional: rounded corner on top left
    borderTopRightRadius: theme.spacing(2), // Optional: rounded corner on top right
    overflowY: 'auto',
  },
  header: {
    backgroundColor: '#f7941e', // Customize the background color
    color: 'white', // Text color
    fontSize: '12px', // Font size
    fontWeight: 'bold',
    padding: '16px', // Padding around the text
    textAlign: 'center', // Center the text
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    fontSize: '12px', // Font size
    fontWeight: 'bold',
  },
  accordionSummary: {
    backgroundColor: '#fde6c1',
    fontWeight: 'bold',
  },
})

type Anchor = 'top' | 'left' | 'bottom' | 'right'

interface DetailDrawerProps {
  anchor: Anchor
  isOpen: boolean
  data: any
  onClose: () => void
}

export default function DetailDrawer({
  anchor,
  isOpen,
  onClose,
  data,
}: DetailDrawerProps) {
  const classes = useStyles()

  console.log(data, 'data')

  const list = data => (
    <>
      {/* Header Section */}
      <div className={classes.header}>{data?.DocNo}</div>

      {/* Content Section with Accordions */}
      <div className={classes.content}>
        {[
          'Document Detail',
          'Supplier Detail',
          'Buyer Detail',
          'Recipient Detail',
          'Transaction Tax',
          'Transaction Ref No',
          'Transaction Item',
        ].map((section, index) => (
          <Accordion key={section}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDown />}
              className={classes.accordionSummary}
            >
              <Typography>{section}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {/* Replace this text with actual details content for each section */}
                Content for {section}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        {/* Additional Download Section */}
        <Accordion>
          <AccordionSummary
            expandIcon={<KeyboardArrowDown />}
            className={classes.accordionSummary}
          >
            <Typography>Download XML File</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* Add download functionality or details here */}
              Download XML file content or link.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<KeyboardArrowDown />}
            className={classes.accordionSummary}
          >
            <Typography>Download Submit Payload</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* Add download functionality or details here */}
              Download submit payload content or link.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      // classes={{
      //   paper: classes.drawerPaper,
      // }}
      PaperProps={{
        style: { width: '70%' }, // Adjust width to 70% of the screen
      }}
    >
      {list(data[0])}
    </Drawer>
  )
}
