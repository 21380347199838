import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import AuditLog from 'assets/icons/MFRS16/auditlog.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import {
  useDocumentListingQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { APDetailContent } from './APDetailContent'
import { APItemDetailContent } from './APItemDetailExpansion'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'

export const APDetail = props => {
  let history = useHistory()
  let location = useLocation()
  const listData = location?.state as any
  const { CompanyID, DocumentID }: any = useParams()
  const { apSubmenu, routeSegments, accountType, module } = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)

  const {
    loading: userLoading,
    error: userError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: { superUserBool: UserSelection.All },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: DocumentID },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  let itemName, tableName, primaryKey
  switch (apSubmenu) {
    case 'advance':
      tableName = 'AP_Advance'
      primaryKey = 'Advance'
      break
    case 'credit-note':
      tableName = 'AP_CreditNote'
      primaryKey = 'CreditNote'
      itemName = 'APCreditNoteItem'
      break
    case 'creditor-credit-note':
      tableName = 'AP_CreditorCreditNote'
      primaryKey = 'CreditorCreditNote'
      itemName = 'APCreditorCreditNoteItem'
      break
    case 'invoice':
      tableName = 'AP_Invoice'
      primaryKey = 'Invoice'
      itemName = 'APInvoiceItem'
      break
    case 'debit-note':
      tableName = 'AP_DebitNote'
      primaryKey = 'DebitNote'
      itemName = 'APDebitNoteItem'
      break
    case 'creditor-debit-note':
      tableName = 'AP_CreditorDebitNote'
      primaryKey = 'CreditorDebitNote'
      itemName = 'APCreditorDebitNoteItem'
      break
    case 'self-billed':
      tableName = 'AP_SelfBilled'
      primaryKey = 'SelfBilled'
      itemName = 'APSelfBilledItem'
      break
    case 'payment':
      tableName = 'AP_Payment'
      primaryKey = 'Payment'
      itemName = ''
      break
    case 'refund':
      tableName = 'AP_Refund'
      primaryKey = 'Refund'
      itemName = ''
      break
    default:
      break
  }

  return (
    <>
      {docLoading && <Loading />}
      {userLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/${accountType}/${CompanyID}/${apSubmenu}`)
        }
        smTitle={smTitle.ACCOUNT_PAYABLE}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Accounts Payable' },
          { name: 'AP Submenu' },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <span className="xsTitle" style={{ color: '#ff9800' }}>
            {listData?.DocNo}
          </span>
        }
        rightText={
          <span className="c-orange">
            {formatDate(
              apSubmenu === 'self-billed'
                ? listData?.DocDate
                : listData?.einvoice_self_billed_date
            )}
          </span>
        }
        infoLine={
          <div
            className="text-overflow"
            style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
          >
            {listData?.Description}
          </div>
        }
        rightInfoLine={
          <IconText
            label={
              <span
                className="text-overflow"
                style={{
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'orange',
                }}
              >
                {amtStr(listData?.DocAmt)}
              </span>
            }
            icon={
              <img
                src={AuditLog}
                style={{
                  width: '17px',
                  marginRight: '5px',
                  marginLeft: '12px',
                }}
                onClick={() => {
                  history.push({
                    pathname: `/${accountType}/${CompanyID}/${apSubmenu}/${DocumentID}/detail/audit-log`,
                    state: {
                      ID: DocumentID,
                      tableName: tableName,
                      primaryKey: primaryKey,
                      ...listData,
                    },
                  })
                }}
              />
            }
            font="desc"
          />
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <APDetailContent
            apSubmenu={apSubmenu}
            listEl={listData}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
            isSelfBilled={listData?.einvoice_self_billed}
          />
        </CardContents>
        {(apSubmenu === 'invoice' ||
          apSubmenu === 'debit-note' ||
          apSubmenu === 'credit-note' ||
          apSubmenu === 'creditor-credit-note' ||
          apSubmenu === 'self-billed') && (
          <CardContents
            section={{
              header: {
                title: `${routeSegments} Detail`,
                customFontSizeClass: 'xsTitle',
              },
            }}
          >
            <APItemDetailContent
              listItem={listData?.[itemName]}
              isSelfBilled={listData?.einvoice_self_billed}
            />
          </CardContents>
        )}
        {(apSubmenu === 'creditor-credit-note' ||
          apSubmenu === 'credit-note' ||
          apSubmenu === 'payment' ||
          apSubmenu === 'refund' ||
          (apSubmenu === 'self-billed' && listData?.Allocation?.length > 0) ||
          (apSubmenu === 'self-billed' &&
            listData?.AllocationCNFromCreditor?.length > 0)) && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                },
              }}
            >
              <DetailAllocationTableContent
                listItem={
                  apSubmenu === 'self-billed' &&
                  listData?.AllocationCNFromCreditor?.length > 0
                    ? listData?.AllocationCNFromCreditor
                    : listData?.Allocation
                }
                apSubmenu={apSubmenu}
              />
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={listData}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={routeSegments}
      />
      {apSubmenu !== 'advance' &&
        apSubmenu !== 'refund' &&
        apSubmenu !== 'payment' && (
          <TooltipAmountFooter
            data={listData}
            module={`${apSubmenu}-approved`}
            detail={true}
          />
        )}
    </>
  )
}
