import theme from '@ifca-root/react-component/src/assets/theme'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useMediaQuery } from '@material-ui/core'
import { ConsolidateDialog } from 'components/Dialog/ConsolidateDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  EInvoiceTrxType,
  useCreateEinvoiceConsolidateDocTypeAllMutation,
  useCreateSelfBilledConsolidateMutation,
  useGetCompanyNameQuery,
  useGetCreditorAccountCompanyAssignmentNameLazyQuery,
  useGetSelfBilledToConsolidateLazyQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  dateFormat,
  formatDashDate,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { InboundTable } from './InboundTable'

export const InboundEInvoice = (props: any) => {
  /* -------------------------------------------- */
  /*                VARIABLES                     */
  /* -------------------------------------------- */

  let history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any
  const today = new Date()

  /* -------------------------------------------- */
  /*                    STATE                     */
  /* -------------------------------------------- */

  const { CompanyID }: any = useParams()
  const [itemData, setItemData] = useState([])
  const [errMsg, setErrMsg] = useState<string>('')
  const [docType, setDocType] = useState('INVOICE')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [labelState, setLabelState] = useState<string>()
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(false)

  /* -------------------------------------------- */
  /*                    QUERY                     */
  /* -------------------------------------------- */

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
    onCompleted: ({ getCompany }) => {
      if (!!getCompany) {
        setLabelState(getCompany[0]?.Name)
        fetchDocs({
          variables: {
            CompanyID,
            DocType: docType,
            DocNo: '',
            CreditorName: '',
          },
        })
        fetchCreditorAccountCompanyAssignment({
          variables: {
            CompanyID: CompanyID,
          },
        })
      }
    },
  })

  const [
    fetchCreditorAccountCompanyAssignment,
    {
      loading: CreditorAccountCompanyAssignmentLoading,
      data: { getCreditorAccountCompanyAssignment } = {
        getCreditorAccountCompanyAssignment: [],
      },
    },
  ] = useGetCreditorAccountCompanyAssignmentNameLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  let creditorAcc = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  const [
    fetchDocs,
    {
      loading: ConsolidateLoading,
      data: { getSelfBilledToConsolidate } = { getSelfBilledToConsolidate: [] },
    },
  ] = useGetSelfBilledToConsolidateLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getSelfBilledToConsolidate }) => {
      let arry = []
      if (getSelfBilledToConsolidate?.length > 0) {
        getSelfBilledToConsolidate?.map(el => {
          arry.push({
            ...el,
          })
        })
        setItemData(arry)
      }
    },
  })

  const exampleDocument = [
    {
      APDocDate: '2024-04-25T00:00:00Z',
      DocDate: '2024-04-20T00:00:00Z',
      DocNo: 'INV0106',
      APDocNo: 'APINV0106',
      CreditorAccount: {
        CompanyName: 'ABC Corp',
      },
      DocAmt: 1000.0,
      TaxAmt: 100.0,
      xmlFileUrl: '/path/to/xml/file.xml',
      payloadUrl: '/path/to/payload.json',
      // Add more fields as necessary
    },
  ]

  /* -------------------------------------------- */
  /*                 MUTATION                     */
  /* -------------------------------------------- */
  //Change accordingly
  const [
    createSelfBilledConsolidate,
    { loading: createSelfBilledConsolidateLoading },
  ] = useCreateSelfBilledConsolidateMutation({
    onError: error => {
      setOpenDialog(false)
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())

      setTimeout(() => {
        history.push({
          pathname: `/account-payable/${CompanyID}/consolidate-self-billed`,
        })
      }, 500)
    },
  })

  const [
    createEinvoiceConsolidateDocTypeAll,
    { loading: createEinvoiceConsolidateDocTypeAllLoading },
  ] = useCreateEinvoiceConsolidateDocTypeAllMutation({
    onError: error => {
      setOpenDialog(false)
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/consolidate-einvoice`,
        })
      }, 500)
    },
  })

  /* -------------------------------------------- */
  /*                 USE FORM                     */
  /* -------------------------------------------- */

  const {
    register: registerFilter,
    control: controlFilter,
    setValue: setValueFilter,
    getValues: getValueFilter,
    watch: watchFilter,
  } = useForm()

  /* ----------------------------------------- */
  /*                FUNCTION                   */
  /* ----------------------------------------- */

  //This is for header filter document type & date range initially will just show all document based on default document type

  const onSubmitFilter = () => {
    const selectedDocType =
      getValueFilter('DocType') === 'All' ? 'All' : getValueFilter('DocType')

    setDocType(selectedDocType)
    setStartDate(
      getValueFilter('StartDate') !== null &&
        getValueFilter('StartDate') !== undefined
        ? dateFormat(new Date(getValueFilter('StartDate')).toISOString())
        : ''
    )
    setEndDate(
      getValueFilter('EndDate') !== null &&
        getValueFilter('EndDate') !== undefined
        ? dateFormat(new Date(getValueFilter('EndDate')).toISOString())
        : ''
    )

    fetchDocs({
      variables: {
        CompanyID: CompanyID,
        DocType: selectedDocType,
        DocNo: getValueFilter('DocNo') ?? '',
        CreditorName: getValueFilter('CreditorName') ?? '',
        StartDate:
          getValueFilter('StartDate') !== null &&
          getValueFilter('StartDate') !== undefined
            ? formatDashDate(
                new Date(getValueFilter('StartDate')).toISOString()
              )
            : null,
        EndDate:
          getValueFilter('EndDate') !== null &&
          getValueFilter('EndDate') !== undefined
            ? formatDashDate(new Date(getValueFilter('EndDate')).toISOString())
            : null,
      },
    })
  }

  const resetFilter = () => {
    setDocType('INVOICE')
    setValueFilter('DocType', 'INVOICE')
    setValueFilter(
      'StartDate',
      new Date(today.getFullYear(), today.getMonth() - 1, 1)
    )
    setValueFilter(
      'EndDate',
      new Date(today.getFullYear(), today.getMonth(), 0)
    )
    setValueFilter('CreditorName', '')
    setValueFilter('DocNo', '')

    setStartDate('')
    setEndDate('')

    fetchDocs({
      variables: {
        CompanyID,
        DocType: 'INVOICE',
        DocNo: '',
        CreditorName: '',
      },
    })

    fetchCreditorAccountCompanyAssignment({
      variables: {
        CompanyID: CompanyID,
      },
    })
  }

  let docTitle
  switch (docType) {
    case 'INVOICE':
      docTitle = 'Self-Billed Invoice'
      break
    case 'CREDIT_NOTE':
      docTitle = 'Self-Billed Credit Note'
      break
    case 'DEBIT_NOTE':
      docTitle = 'Self-Billed Debit Note'
      break
    case 'REFUND_NOTE':
      docTitle = 'Self-Billed Refund Note'
      break
  }

  const renderFilterHeader = () => {
    return (
      <span className={`xsTitle flex-space`}>
        {`Doc Type: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {docTitle}
        </span>
        {startDate !== '' && endDate !== '' ? `, ` : null}
        {startDate !== '' && `From: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {startDate}
        </span>
        {endDate !== '' && ` To: `}
        <span className={`mdDesc`} style={{ color: '#000000' }}>
          {endDate}
        </span>
      </span>
    )
  }

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const footerOptions = [
    {
      disabled: getSelfBilledToConsolidate?.length === 0 ? true : false,
      name: 'Send to LHDN',
      onClick: () => {
        setOpenDialog(true)
      },
      color: 'primary',
      props: { type: 'submit' },
      style: { float: 'right', margin: isDesktop ? null : '0 auto' },
    },
  ]

  const documentType = [
    {
      name: 'Self-Billed Invoice',
      value: 'INVOICE',
      einvoice_type: EInvoiceTrxType.SelfBilledInvoice,
    },
    {
      name: 'Self-Billed Credit Note',
      value: 'CREDIT_NOTE',
      einvoice_type: EInvoiceTrxType.SelfBilledCreditNote,
    },
    {
      name: 'Self-Billed Debit Note',
      value: 'DEBIT_NOTE',
      einvoice_type: EInvoiceTrxType.SelfBilledDebitNote,
    },
    {
      name: 'Self-Billed Refund Note',
      value: 'REFUND_NOTE',
      einvoice_type: EInvoiceTrxType.SelfBilledRefundNote,
    },
  ]

  const totalDocAmt = getSelfBilledToConsolidate?.reduce(
    (total, current) => total + Number(current?.DocAmt),
    0
  )

  const totalTaxAmt = getSelfBilledToConsolidate?.reduce(
    (total, current) => total + Number(current?.TaxAmt),
    0
  )

  /* --------------------------------- */
  /*              ON SUBMIT            */
  /* --------------------------------- */
  const trimData = (data, itemData) => {
    // Separate arrays for different document types
    const invoices = []
    const creditNotes = []
    const debitNotes = []
    const filteredData = []

    // Categorize documents into different types
    itemData?.map(doc => {
      switch (doc.DocType) {
        case 'Invoice':
          invoices.push(doc)
          break
        case 'Credit Note':
          creditNotes.push(doc)
          break
        case 'Debit Note':
          debitNotes.push(doc)
          break
      }
    })

    if (invoices.length > 0) {
      const totalInvDocAmt = invoices?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalInvTaxAmt = invoices?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalInvDocAmt) - parseFloat(totalInvTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalInvTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalInvDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.SelfBilledInvoice,
        consolidateItems: invoices?.map(x => {
          return {
            RefRecordID: x?.SelfBilledID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (creditNotes.length > 0) {
      const totalCNDocAmt = creditNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalCNTaxAmt = creditNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalCNDocAmt) - parseFloat(totalCNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalCNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalCNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.SelfBilledCreditNote,
        consolidateItems: creditNotes?.map(x => {
          return {
            RefRecordID: x?.SelfBilledID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    if (debitNotes.length > 0) {
      const totalDNDocAmt = debitNotes?.reduce(
        (total, current) => total + Number(current?.DocAmt),
        0
      )
      const totalDNTaxAmt = debitNotes?.reduce(
        (total, current) => total + Number(current?.TaxAmt),
        0
      )

      filteredData.push({
        CompanyID: CompanyID,
        DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
        Description: data?.Description,
        Amount: Number(
          parseFloat(totalDNDocAmt) - parseFloat(totalDNTaxAmt ?? 0.0)
        ),
        TaxAmt: Number(parseFloat(totalDNTaxAmt ?? 0.0)),
        DocAmt: Number(parseFloat(totalDNDocAmt)),
        einvoice_trx_type: EInvoiceTrxType.SelfBilledDebitNote,
        consolidateItems: debitNotes?.map(x => {
          return {
            RefRecordID: x?.SelfBilledID,
            RefTable: x?.RefTable,
            Amount: Number(x?.DocAmt) - Number(x?.TaxAmt),
            TaxAmt: Number(x?.TaxAmt),
            DocAmt: Number(x?.DocAmt),
            DocType: x?.DocType,
          }
        }),
      })
    }

    return filteredData
  }

  const onSubmit = data => {
    if (getValueFilter('DocType') !== 'All') {
      createSelfBilledConsolidate({
        variables: {
          input: {
            CompanyID: CompanyID,
            DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
            Description: data?.Description,
            Amount: totalDocAmt - totalTaxAmt,
            TaxAmt: totalTaxAmt,
            DocAmt: totalDocAmt,
            einvoice_trx_type: documentType?.find(
              x => x?.value === getValueFilter('DocType')
            )?.einvoice_type,
          },
          itemInput: itemData?.map((x, i) => {
            return {
              RefRecordID: x?.SelfBilledID,
              DocType: x?.DocType,
              Amount: Number(
                parseFloat(x?.DocAmt) - parseFloat(x?.TaxAmt ?? 0.0)
              ),
              TaxAmt: Number(parseFloat(x?.TaxAmt ?? 0.0)),
              DocAmt: Number(parseFloat(x?.DocAmt)),
            }
          }),
        },
      })
    } else {
      createEinvoiceConsolidateDocTypeAll({
        variables: {
          input: trimData(data, itemData),
        },
      })
    }
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {ConsolidateLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}
      {createSelfBilledConsolidateLoading && <Loading />}
      {createEinvoiceConsolidateDocTypeAllLoading && <Loading />}

      <ContentWrapper footer>
        {/* Table Section */}
        <InboundTable
          //   creditorOrDebtor={'Creditor'}
          data={exampleDocument}
        />
      </ContentWrapper>

      <ConsolidateDialog
        title={'Self-Billed'}
        onSubmit={onSubmit}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        length={getSelfBilledToConsolidate?.length}
        totalDocAmt={totalDocAmt}
      />

      <TotalAmountFooter
        countRecord={
          getSelfBilledToConsolidate?.length > 0
            ? getSelfBilledToConsolidate?.length
            : 0
        }
        taxInfoDetailWithRecord={true}
        docAmt={totalDocAmt}
        totalTaxAmt={totalTaxAmt}
      />

      <AccountFooter
        optionStyle={{ float: isDesktop ? 'right' : null }}
        options={footerOptions}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
