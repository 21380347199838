import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAdvanceDeleteInput = {
  AdvanceID: Scalars['String'];
};

export type ApAdvanceInput = {
  AdvanceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  BankAccountID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type ApAdvanceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApAgingEntity = AuditEntity & {
  __typename?: 'APAgingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AgingID: Scalars['String'];
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type ApAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationAmt: Scalars['Float'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type ApAllocationItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  AllocationItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitItemRefTable?: Maybe<Scalars['String']>;
  DebitItemID?: Maybe<Scalars['String']>;
  CreditItemRefTable?: Maybe<Scalars['String']>;
  CreditItemID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type ApBatchPaymentDeleteInput = {
  BatchPaymentID: Scalars['String'];
};

export type ApBatchPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  BatchPaymentID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApConsolidationDeleteInput = {
  ConsolidationID: Scalars['String'];
};

export type ApConsolidationRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApCreditNoteDeleteInput = {
  CreditNoteID: Scalars['String'];
};

export type ApCreditNoteDocRefInput = {
  CreditNoteID?: Maybe<Scalars['String']>;
  DocRefID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteInput = {
  CreditNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  TransactionDate?: Maybe<Scalars['String']>;
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditNoteItemDeleteInput = {
  CreditNoteItemID: Scalars['String'];
};

export type ApCreditNoteItemInput = {
  CreditNoteItemID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApCreditNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApCreditorCreditNoteDeleteInput = {
  CreditorCreditNoteID: Scalars['String'];
};

export type ApCreditorCreditNoteInput = {
  CompanyID: Scalars['String'];
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditorCreditNoteItemDeleteInput = {
  CreditorCreditNoteItemID: Scalars['String'];
};

export type ApCreditorCreditNoteItemInput = {
  CreditorCreditNoteItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApCreditorCreditNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApCreditorDebitNoteDeleteInput = {
  DebitNoteID: Scalars['String'];
};

export type ApCreditorDebitNoteInput = {
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefNo: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApCreditorDebitNoteItemDeleteInput = {
  CreditorDebitNoteItemID: Scalars['String'];
};

export type ApCreditorDebitNoteItemInput = {
  CreditorDebitNoteItemID?: Maybe<Scalars['String']>;
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApCreditorDebitNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApDebitNoteDeleteInput = {
  DebitNoteID: Scalars['String'];
};

export type ApDebitNoteInput = {
  DebitNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefNo: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApDebitNoteItemDeleteInput = {
  DebitNoteItemID: Scalars['String'];
};

export type ApDebitNoteItemInput = {
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApDebitNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApFavoriteInvoiceDeleteInput = {
  FavoriteInvoiceID: Scalars['String'];
};

export type ApFavoriteInvoiceInput = {
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ApFavoriteInvoiceItemDeleteInput = {
  FavoriteInvoiceItemID: Scalars['String'];
};

export type ApFavoriteInvoiceItemInput = {
  FavoriteInvoiceItemID?: Maybe<Scalars['String']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
};

export type ApFavoritePaymentDeleteInput = {
  FavoritePaymentID: Scalars['String'];
};

export type ApFavoritePaymentInput = {
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ApglExportEntity = AuditEntity & {
  __typename?: 'APGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type ApInvoiceDeleteInput = {
  InvoiceID: Scalars['String'];
};

export type ApInvoiceInput = {
  InvoiceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditTerm: Scalars['Float'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Discount?: Maybe<Scalars['Float']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
  OtherCharges?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApInvoiceItemDeleteInput = {
  APInvoiceItemID: Scalars['String'];
};

export type ApInvoiceItemInput = {
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DOItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApInvoiceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApPaymentDeleteInput = {
  PaymentID: Scalars['String'];
};

export type ApPaymentInput = {
  PaymentID?: Maybe<Scalars['String']>;
  AdvanceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApPaymentRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApRefundDeleteInput = {
  RefundID: Scalars['String'];
};

export type ApRefundInput = {
  RefundID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApRefundRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ApSelfBilledDeleteInput = {
  SelfBilledID: Scalars['String'];
};

export type ApSelfBilledInput = {
  SelfBilledID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  RefNo: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  is_double_entry?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceTrxType>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ApSelfBilledItemDeleteInput = {
  SelfBilledItemID: Scalars['String'];
};

export type ApSelfBilledItemInput = {
  SelfBilledItemID?: Maybe<Scalars['String']>;
  SelfBilledID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ApSelfBilledRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ArAdvanceDeleteInput = {
  AdvanceID: Scalars['String'];
};

export type ArAdvanceInput = {
  AdvanceID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  TaxSchemeID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount: Scalars['Float'];
  TaxAmt: Scalars['Float'];
  TaxRate: Scalars['Float'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type ArAdvanceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ArAdvanceReturnData = {
  __typename?: 'ARAdvanceReturnData';
  Data?: Maybe<Array<AccArAdvanceEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ArAgingEntity = AuditEntity & {
  __typename?: 'ARAgingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AgingID: Scalars['String'];
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type ArAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationAmt: Scalars['Float'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type ArCreditNoteDeleteInput = {
  CreditNoteID: Scalars['String'];
};

export type ArCreditNoteInput = {
  CreditNoteID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  BillItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
};

export type ArCreditNoteItemDeleteInput = {
  CreditNoteItemID: Scalars['String'];
};

export type ArCreditNoteItemInput = {
  CreditNoteItemID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ArCreditNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ArCreditNoteReturnData = {
  __typename?: 'ARCreditNoteReturnData';
  Data?: Maybe<Array<AccArCreditNoteEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ArDebitNoteDeleteInput = {
  DebitNoteID: Scalars['String'];
};

export type ArDebitNoteInput = {
  CompanyID: Scalars['String'];
  DebitNoteID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
};

export type ArDebitNoteItemDeleteInput = {
  DebitNoteItemID: Scalars['String'];
};

export type ArDebitNoteItemInput = {
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  BillItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ArDebitNoteRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ArDebitNoteReturnData = {
  __typename?: 'ARDebitNoteReturnData';
  Data?: Maybe<Array<AccArDebitNoteEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ArFavoriteInvoiceDeleteInput = {
  FavoriteInvoiceID: Scalars['String'];
};

export type ArFavoriteInvoiceInput = {
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  InterestRate?: Maybe<Scalars['Float']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type ArFavoriteInvoiceItemDeleteInput = {
  FavoriteInvoiceItemID: Scalars['String'];
};

export type ArFavoriteInvoiceItemInput = {
  FavoriteInvoiceItemID?: Maybe<Scalars['String']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ArglExportEntity = AuditEntity & {
  __typename?: 'ARGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type ArInvoiceDeleteInput = {
  InvoiceID: Scalars['String'];
};

export type ArInvoiceInput = {
  CompanyID: Scalars['String'];
  InvoiceID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  DueDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  TransactionDate: Scalars['String'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
};

export type ArInvoiceItemDeleteInput = {
  InvoiceItemID: Scalars['String'];
};

export type ArInvoiceItemInput = {
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
};

export type ArInvoiceRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ArInvoiceReturnData = {
  __typename?: 'ARInvoiceReturnData';
  Data?: Maybe<Array<AccArInvoiceEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ArOfficialReceiptDeleteInput = {
  ReceiptID: Scalars['String'];
};

export type ArOfficialReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  PaymentMethodID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
};

export type ArOfficialReceiptRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ArOfficialReceiptReturnData = {
  __typename?: 'AROfficialReceiptReturnData';
  Data?: Maybe<Array<AccArOfficialReceiptEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ArRefundDeleteInput = {
  RefundID: Scalars['String'];
};

export type ArRefundInput = {
  RefundID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  DocDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type ArRefundRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ArRefundReturnData = {
  __typename?: 'ARRefundReturnData';
  Data?: Maybe<Array<AccArRefundEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type AccApAdvanceEntity = AuditEntity & {
  __typename?: 'AccAPAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  AdvanceID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  IsCBPosted: Scalars['Boolean'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  PaymentID?: Maybe<Scalars['String']>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApAllocationEntity = AuditEntity & {
  __typename?: 'AccAPAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
  AllocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  APAllocationItem?: Maybe<Array<AccApAllocationItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccApAllocationItemEntity = AuditEntity & {
  __typename?: 'AccAPAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
  AllocationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AllocationID: Scalars['String'];
  Allocation?: Maybe<AccApAllocationEntity>;
};

export type AccApAllocationItemObj = {
  __typename?: 'AccAPAllocationItemObj';
  AllocationItemID: Scalars['String'];
  AllocationID: Scalars['String'];
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
};

export type AccApAllocationObj = {
  __typename?: 'AccAPAllocationObj';
  AllocationID: Scalars['String'];
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitRefNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['DateTime']>;
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditRefNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditDefaultAmt?: Maybe<Scalars['Float']>;
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocatedDocs?: Maybe<Array<AllocatedDocs>>;
};

export type AccApBatchPaymentEntity = AuditEntity & {
  __typename?: 'AccAPBatchPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  BatchPaymentID: Scalars['String'];
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  Remark?: Maybe<Scalars['String']>;
  GLGenerated: Scalars['Boolean'];
  CBGenerated: Scalars['Boolean'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  DocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApCreditNoteDocRefEntity = {
  __typename?: 'AccAPCreditNoteDocRefEntity';
  CreditNoteDocRefID: Scalars['String'];
  AccountID: Scalars['String'];
  DocRefID?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<AccApCreditNoteEntity>;
  APCreditorCreditNote?: Maybe<AccApCreditorCreditNoteEntity>;
};

export type AccApCreditNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  CNDocRef?: Maybe<Array<AccApCreditNoteDocRefEntity>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  DocRefNo?: Maybe<Scalars['JSON']>;
};

export type AccApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditNoteItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<AccApCreditNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  AllocationItem?: Maybe<Array<AccApAllocationItemObj>>;
  /** CustomFieldResolver */
  BalanceItemAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApCreditorCreditNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditorCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditorCreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CNDocRef?: Maybe<Array<AccApCreditNoteDocRefEntity>>;
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  GRTN?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  DocRefNo?: Maybe<Scalars['JSON']>;
};

export type AccApCreditorCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditorCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditorCreditNoteItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  CreditorCreditNoteID: Scalars['String'];
  APCreditorCreditNote?: Maybe<AccApCreditorCreditNoteEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  AllocationItem?: Maybe<Array<AccApAllocationItemObj>>;
  /** CustomFieldResolver */
  BalanceItemAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApCreditorDebitNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditorDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditorDebitNoteID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  DNDocRef?: Maybe<Array<AccApDebitNoteDocRefEntity>>;
  IsCBPosted: Scalars['Boolean'];
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  DocRefNo?: Maybe<Scalars['JSON']>;
};

export type AccApCreditorDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditorDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditorDebitNoteItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  CreditorDebitNoteID: Scalars['String'];
  APCreditorDebitNote?: Maybe<AccApCreditorDebitNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApDebitNoteDocRefEntity = {
  __typename?: 'AccAPDebitNoteDocRefEntity';
  DebitNoteDocRefID: Scalars['String'];
  AccountID: Scalars['String'];
  DocRefID?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<AccApDebitNoteEntity>;
  APCreditorDebitNote?: Maybe<AccApCreditorDebitNoteEntity>;
};

export type AccApDebitNoteEntity = AuditEntity & {
  __typename?: 'AccAPDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  DNDocRef?: Maybe<Array<AccApDebitNoteDocRefEntity>>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  DocRefNo?: Maybe<Scalars['JSON']>;
};

export type AccApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DebitNoteItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<AccApDebitNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApFavoriteInvoiceEntity = AuditEntity & {
  __typename?: 'AccAPFavoriteInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoriteInvoiceID: Scalars['String'];
  UserID: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  Name?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
};

export type AccApFavoriteInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccAPFavoriteInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  FavoriteInvoiceItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID: Scalars['String'];
  APFavoriteInvoice?: Maybe<AccApFavoriteInvoiceEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  ExpenseItemID: Scalars['String'];
  ExpenseItem?: Maybe<ExpenseItemEntity>;
};

export type AccApFavoritePaymentEntity = {
  __typename?: 'AccAPFavoritePaymentEntity';
  FavoritePaymentID: Scalars['String'];
  AccountID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  BankAccountID?: Maybe<Scalars['String']>;
  AccBankAccount?: Maybe<AccBankAccountEntity>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
};

export type AccApInvoiceEntity = AuditEntity & {
  __typename?: 'AccAPInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  InvoiceID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DiscountRate?: Maybe<Scalars['Float']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
  OtherCharges?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  DO?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SB?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice_status?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  einvoice_sent_date?: Maybe<Scalars['String']>;
};

export type AccApInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccAPInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InvoiceItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  InvoiceID: Scalars['String'];
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  APInvoice?: Maybe<AccApInvoiceEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DOItemID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccApPaymentEntity = AuditEntity & {
  __typename?: 'AccAPPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  PaymentID: Scalars['String'];
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  AdvanceID?: Maybe<Scalars['String']>;
  APAdvance?: Maybe<AccApAdvanceEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  APBatchPayment?: Maybe<AccApBatchPaymentEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApRefundEntity = AuditEntity & {
  __typename?: 'AccAPRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  RefundID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApSelfBilledDocRefEntity = {
  __typename?: 'AccAPSelfBilledDocRefEntity';
  SelfBilledDocRefID: Scalars['String'];
  AccountID: Scalars['String'];
  DocRefID?: Maybe<Scalars['String']>;
  SelfBilledID: Scalars['String'];
  APSelfBilled?: Maybe<AccApSelfBilledEntity>;
};

export type AccApSelfBilledEntity = AuditEntity & {
  __typename?: 'AccAPSelfBilledEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  SelfBilledID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceTrxType>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  SBDocRef?: Maybe<Array<AccApSelfBilledDocRefEntity>>;
  IsCBPosted: Scalars['Boolean'];
  einvoice_self_billed_date?: Maybe<Scalars['String']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_failed_resend_date?: Maybe<Scalars['DateTime']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_consol_uniqueID?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  is_double_entry?: Maybe<Scalars['Boolean']>;
  einvoice_signature_value?: Maybe<Scalars['String']>;
  einvoice_signing_time?: Maybe<Scalars['DateTime']>;
  einvoice_cert_issuer?: Maybe<Scalars['String']>;
  einvoice_cert_serial_no?: Maybe<Scalars['String']>;
  einvoice_doc_digest?: Maybe<Scalars['String']>;
  einvoice_props_digest?: Maybe<Scalars['String']>;
  einvoice_cert_digest?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  AllocationCNFromCreditor?: Maybe<Array<AccApAllocationObj>>;
  /** CustomFieldResolver */
  APLedger?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  DocRefNo?: Maybe<Scalars['JSON']>;
};

export type AccApSelfBilledItemEntity = AuditEntity & {
  __typename?: 'AccAPSelfBilledItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  SelfBilledItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  SelfBilledID: Scalars['String'];
  APSelfBilled?: Maybe<AccApSelfBilledEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccArAdvanceEntity = AuditEntity & {
  __typename?: 'AccARAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  AdvanceID: Scalars['String'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxInvoiceNo: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsCBPosted: Scalars['Boolean'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
};

export type AccArAllocationEntity = AuditEntity & {
  __typename?: 'AccARAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
  AllocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  ARAllocationItem?: Maybe<Array<AccArAllocationItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccArAllocationItemEntity = AuditEntity & {
  __typename?: 'AccARAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
  AllocationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AllocationID: Scalars['String'];
  Allocation?: Maybe<AccArAllocationEntity>;
};

export type AccArAllocationItemObj = {
  __typename?: 'AccARAllocationItemObj';
  AllocationItemID: Scalars['String'];
  AllocationID: Scalars['String'];
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitItemDocNo?: Maybe<Scalars['String']>;
  DebitItemDocDate?: Maybe<Scalars['DateTime']>;
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemDocNo?: Maybe<Scalars['String']>;
  CreditItemDocDate?: Maybe<Scalars['DateTime']>;
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
};

export type AccArAllocationObj = {
  __typename?: 'AccARAllocationObj';
  AllocationID: Scalars['String'];
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['DateTime']>;
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
};

export type AccArcnDocRefInput = {
  CreditNoteID?: Maybe<Scalars['String']>;
  DocRefID?: Maybe<Scalars['String']>;
};

export type AccArCollectionEntity = AuditEntity & {
  __typename?: 'AccARCollectionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  CollectionActivityID: Scalars['String'];
  ActionDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccArCreditNoteDocRefEntity = {
  __typename?: 'AccARCreditNoteDocRefEntity';
  CreditNoteDocRefID: Scalars['String'];
  AccountID: Scalars['String'];
  DocRefID?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  ARCreditNote?: Maybe<AccArCreditNoteEntity>;
};

export type AccArCreditNoteEntity = AuditEntity & {
  __typename?: 'AccARCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_consol_uniqueID?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_is_refund_note?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
  einvoice_signature_value?: Maybe<Scalars['String']>;
  einvoice_signing_time?: Maybe<Scalars['DateTime']>;
  einvoice_cert_issuer?: Maybe<Scalars['String']>;
  einvoice_cert_serial_no?: Maybe<Scalars['String']>;
  einvoice_doc_digest?: Maybe<Scalars['String']>;
  einvoice_props_digest?: Maybe<Scalars['String']>;
  einvoice_cert_digest?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  Remark?: Maybe<Scalars['String']>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  ARCreditDocRef?: Maybe<Array<AccArCreditNoteDocRefEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  CNDocRef?: Maybe<Scalars['JSON']>;
};

export type AccArCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccARCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditNoteItemID: Scalars['String'];
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  ARCreditNote?: Maybe<AccArCreditNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccArdnDocRefInput = {
  DebitNoteID?: Maybe<Scalars['String']>;
  DocRefID?: Maybe<Scalars['String']>;
};

export type AccArDebitNoteDocRefEntity = {
  __typename?: 'AccARDebitNoteDocRefEntity';
  DebitNoteDocRefID: Scalars['String'];
  AccountID: Scalars['String'];
  DocRefID?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  ARDebitNote?: Maybe<AccArDebitNoteEntity>;
};

export type AccArDebitNoteEntity = AuditEntity & {
  __typename?: 'AccARDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  CompanyID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InterestRate?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_consol_uniqueID?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_signature_value?: Maybe<Scalars['String']>;
  einvoice_signing_time?: Maybe<Scalars['DateTime']>;
  einvoice_cert_issuer?: Maybe<Scalars['String']>;
  einvoice_cert_serial_no?: Maybe<Scalars['String']>;
  einvoice_doc_digest?: Maybe<Scalars['String']>;
  einvoice_props_digest?: Maybe<Scalars['String']>;
  einvoice_cert_digest?: Maybe<Scalars['String']>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
  ARDebitDocRef?: Maybe<Array<AccArDebitNoteDocRefEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  DNDocRef?: Maybe<Scalars['JSON']>;
};

export type AccArDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccARDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DebitNoteItemID: Scalars['String'];
  DebitNoteID: Scalars['String'];
  ARDebitNote?: Maybe<AccArDebitNoteEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccArFavoriteInvoiceEntity = AuditEntity & {
  __typename?: 'AccARFavoriteInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  FavoriteInvoiceID: Scalars['String'];
  UserID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  Name?: Maybe<Scalars['String']>;
  InterestRate?: Maybe<Scalars['Float']>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  Remark: Scalars['String'];
};

export type AccArFavoriteInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccARFavoriteInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoriteInvoiceItemID: Scalars['String'];
  FavoriteInvoiceID: Scalars['String'];
  FavoriteInvoice?: Maybe<AccArFavoriteInvoiceEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type AccArInvoiceEntity = AuditEntity & {
  __typename?: 'AccARInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  InvoiceID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  CompanyID: Scalars['String'];
  IsCBPosted: Scalars['Boolean'];
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_consol_uniqueID?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_signature_value?: Maybe<Scalars['String']>;
  einvoice_signing_time?: Maybe<Scalars['DateTime']>;
  einvoice_cert_issuer?: Maybe<Scalars['String']>;
  einvoice_cert_serial_no?: Maybe<Scalars['String']>;
  einvoice_doc_digest?: Maybe<Scalars['String']>;
  einvoice_props_digest?: Maybe<Scalars['String']>;
  einvoice_cert_digest?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
};

export type AccArInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccARInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InvoiceItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  InvoiceID: Scalars['String'];
  ARInvoice?: Maybe<AccArInvoiceEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
};

export type AccArOfficialReceiptEntity = AuditEntity & {
  __typename?: 'AccAROfficialReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  ReceiptID: Scalars['String'];
  CompanyID: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
};

export type AccArRefundEntity = AuditEntity & {
  __typename?: 'AccARRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  RefundID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  IsCBPosted: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Allocation?: Maybe<Array<AccArAllocationObj>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type AccApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'AccApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyAssignmentID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  ApprovalPolicy: AccApprovalPolicyEntity;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  Category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccApprovalPolicyEntity = AuditEntity & {
  __typename?: 'AccApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  ApprovalPolicyAssignment: Array<AccApprovalPolicyAssignmentEntity>;
};

export type AccBankAccountEntity = AuditEntity & {
  __typename?: 'AccBankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankProfileID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  BankProfile?: Maybe<BankProfileEntity>;
  Code: Scalars['String'];
  AccountNo: Scalars['String'];
  Type: BankAccountType;
  SWIFTCode: Scalars['String'];
  EffectiveDate: Scalars['String'];
  CurrencyID?: Maybe<Scalars['String']>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  ExternalLedger?: Maybe<Array<ExternalLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  APBatchPayment?: Maybe<Array<AccApBatchPaymentEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Currency?: Maybe<CurrencyEntity>;
  /** CustomFieldResolver */
  BankBalance?: Maybe<Scalars['JSON']>;
};

export type AccCompanyEntity = AuditEntity & {
  __typename?: 'AccCompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CompanyID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
};

export type AccContactEntity = AuditEntity & {
  __typename?: 'AccContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ContactID: Scalars['String'];
  AssociateID: Scalars['String'];
  Name: Scalars['String'];
  Designation?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  ContactNo: Scalars['String'];
  Email: Scalars['String'];
  Relationship?: Maybe<Scalars['String']>;
  DocRefTable: Scalars['String'];
};

export type AccCurrencyEntity = AuditEntity & {
  __typename?: 'AccCurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CurrencyID: Scalars['String'];
  Code: Scalars['String'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
};

export type AccCurrencyExchangeEntity = AuditEntity & {
  __typename?: 'AccCurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CurrencyExchangeID: Scalars['String'];
  FromCurrencyID: Scalars['String'];
  ToCurrencyID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['String']>;
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  CurrencyLog: Scalars['JSON'];
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CurrencyEntity>;
  /** CustomFieldResolver */
  ToCurrency?: Maybe<CurrencyEntity>;
};

export type AccReportDevEntity = {
  __typename?: 'AccReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type AccRoleEntity = AuditEntity & {
  __typename?: 'AccRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<AccRolePermissionAssignmentEntity>;
  ModuleType: ModuleType;
  /** CustomFieldResolver */
  userList?: Maybe<Array<UserEntity>>;
};

export type AccRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'AccRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type AccRoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'AccRoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type AccRoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type AccTax = {
  __typename?: 'AccTax';
  Rate?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
};

export enum AccTaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum AccTaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type AccUomEntity = AuditEntity & {
  __typename?: 'AccUOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UOMID: Scalars['String'];
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  StockTransferRequisitionItem?: Maybe<Array<StockTransferRequisitionItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
  FavoriteStockTransferRequisitionItem?: Maybe<Array<FavoriteStockTransferRequisitionItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
};

export type AccUomExchangeEntity = AuditEntity & {
  __typename?: 'AccUOMExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  FromUomID: Scalars['String'];
  ToUomID: Scalars['String'];
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  UomLog?: Maybe<Array<Scalars['JSON']>>;
  FromUOM: AccUomEntity;
  ToUOM: AccUomEntity;
};

export type AccWorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'AccWorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowApprovedStepID: Scalars['String'];
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: AccWorkFlowStepEntity;
  /** CustomFieldResolver */
  ApproverDetail?: Maybe<UserEntity>;
};

export type AccWorkFlowEntity = AuditEntity & {
  __typename?: 'AccWorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowID: Scalars['String'];
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<ApprovalStatus>;
  WorkFlowStep?: Maybe<Array<AccWorkFlowStepEntity>>;
  /** CustomFieldResolver */
  SubmitterDetail?: Maybe<UserEntity>;
};

export type AccWorkFlowStepEntity = AuditEntity & {
  __typename?: 'AccWorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowStepID: Scalars['String'];
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: AccWorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<AccWorkFlowApprovedStepEntity>>;
  /** CustomFieldResolver */
  RoleDetail?: Maybe<AccRoleEntity>;
};

export type AccountCodeSummary = {
  __typename?: 'AccountCodeSummary';
  CodeLength?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  AllowControl?: Maybe<Scalars['Boolean']>;
};

export type AccountCoreAllocationEntity = AuditEntity & {
  __typename?: 'AccountCoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
};

export type AccountCoreAllocationItemEntity = AuditEntity & {
  __typename?: 'AccountCoreAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
};

export type AccountCoreApprovalEntity = AuditEntity & {
  __typename?: 'AccountCoreApprovalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type AccountCoreDocItemEntity = AuditEntity & {
  __typename?: 'AccountCoreDocItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type AccountCoreEntity = AuditEntity & {
  __typename?: 'AccountCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type AccountCoreLedgerItemEntity = AuditEntity & {
  __typename?: 'AccountCoreLedgerItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
};

export type AccountCoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'AccountCoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
};

export type AccountCoreTaxItemEntity = AuditEntity & {
  __typename?: 'AccountCoreTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type AccountDocDate = AuditEntity & {
  __typename?: 'AccountDocDate';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
};

export type AccountDocRef = AuditEntity & {
  __typename?: 'AccountDocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type AccountDocRefNoApproval = AuditEntity & {
  __typename?: 'AccountDocRefNoApproval';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
};

export type AccountForex = AuditEntity & {
  __typename?: 'AccountForex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
};

export type AccountLedgerEntity = AuditEntity & {
  __typename?: 'AccountLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
};

export type AccountLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  AllocationAmt?: Maybe<Scalars['Float']>;
};

export type AccountMappingDeleteInput = {
  AccountMappingID: Scalars['String'];
};

export type AccountMappingInput = {
  AccountMappingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  ROUMasterCOAID: Scalars['String'];
  LeaseLiabilityMasterCOAID: Scalars['String'];
  DepreciationROUMasterCOAID: Scalars['String'];
  ProvisionDROUMasterCOAID: Scalars['String'];
  RecurringROUMasterCOAID?: Maybe<Scalars['String']>;
  InterestExpenseMasterCOAID: Scalars['String'];
  LeaseLiabilitiesMasterCOAID: Scalars['String'];
  AccrualLeaseLiabilitiesMasterCOAID: Scalars['String'];
  GLTerminationMasterCOAID: Scalars['String'];
  CapitalisationROUJournalTypeID: Scalars['String'];
  ROUJournalTypeID: Scalars['String'];
  LeaseLiabilitiesJournalTypeID: Scalars['String'];
};

export type AccountPeriodCheck = {
  __typename?: 'AccountPeriodCheck';
  YearClosed?: Maybe<Scalars['Boolean']>;
  IsEditable?: Maybe<Scalars['Boolean']>;
  LatestUsedPeriod?: Maybe<Scalars['Float']>;
  MinStartDate?: Maybe<Scalars['DateTime']>;
  MaxEndDate?: Maybe<Scalars['DateTime']>;
};

export type AccountPeriodDateRange = {
  __typename?: 'AccountPeriodDateRange';
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
};

export type AccountPeriodDeleteInput = {
  AccountPeriodID: Scalars['String'];
};

export type AccountPeriodEntity = AuditEntity & {
  __typename?: 'AccountPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AccountPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  MonthEndClose: Scalars['Boolean'];
  YearEndClose: Scalars['Boolean'];
  BudgetItemPeriod?: Maybe<Array<BudgetItemPeriodEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type AccountPeriodInput = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FYear?: Maybe<Scalars['Float']>;
  FPeriod?: Maybe<Scalars['Float']>;
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  MonthEndClose?: Maybe<Scalars['Boolean']>;
  YearEndClose?: Maybe<Scalars['Boolean']>;
};

export type AccountPeriodYearAndPeriod = {
  __typename?: 'AccountPeriodYearAndPeriod';
  StartYear?: Maybe<Scalars['Float']>;
  StartPeriod?: Maybe<Scalars['Float']>;
};

export type AccountTaxLedgerEntity = AuditEntity & {
  __typename?: 'AccountTaxLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
};

export enum AccountType {
  Asset = 'ASSET',
  Liability = 'LIABILITY',
  Equity = 'EQUITY',
  Revenue = 'REVENUE',
  Expense = 'EXPENSE'
}

export enum AcctPermission {
  AccessSecurityIcon = 'ACCESS_SECURITY_______ICON',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserInactive = 'ACCESS_SECURITY_USER_______INACTIVE',
  AccessSecurityUserCompanyAccess = 'ACCESS_SECURITY_USER_______COMPANY_ACCESS',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserResendInvitation = 'ACCESS_SECURITY_USER_______RESEND_INVITATION',
  AccessSecurityRoleView = 'ACCESS_SECURITY_ROLE_______VIEW',
  AccessSecurityRoleCreate = 'ACCESS_SECURITY_ROLE_______CREATE',
  AccessSecurityRoleUpdate = 'ACCESS_SECURITY_ROLE_______UPDATE',
  AccessSecurityRoleDelete = 'ACCESS_SECURITY_ROLE_______DELETE',
  AccessSecurityRoleDuplicate = 'ACCESS_SECURITY_ROLE_______DUPLICATE',
  CompanySetupIcon = 'COMPANY_SETUP__________ICON',
  CompanySetupView = 'COMPANY_SETUP__________VIEW',
  CompanySetupCreate = 'COMPANY_SETUP__________CREATE',
  CompanySetupUpdate = 'COMPANY_SETUP__________UPDATE',
  CommonSettingIcon = 'COMMON_SETTING__________ICON',
  CommonSettingTaxSetupView = 'COMMON_SETTING_TAX_SETUP_______VIEW',
  CommonSettingTaxSetupCreate = 'COMMON_SETTING_TAX_SETUP_______CREATE',
  CommonSettingTaxSetupUpdate = 'COMMON_SETTING_TAX_SETUP_______UPDATE',
  CommonSettingTaxSetupDelete = 'COMMON_SETTING_TAX_SETUP_______DELETE',
  CommonSettingTaxSetupInactive = 'COMMON_SETTING_TAX_SETUP_______INACTIVE',
  CommonSettingUomView = 'COMMON_SETTING_UOM_______VIEW',
  CommonSettingUomCreate = 'COMMON_SETTING_UOM_______CREATE',
  CommonSettingUomUpdate = 'COMMON_SETTING_UOM_______UPDATE',
  CommonSettingUomInactive = 'COMMON_SETTING_UOM_______INACTIVE',
  CommonSettingUomDelete = 'COMMON_SETTING_UOM_______DELETE',
  CommonSettingUomExchangeView = 'COMMON_SETTING_UOM_EXCHANGE_______VIEW',
  CommonSettingUomExchangeCreate = 'COMMON_SETTING_UOM_EXCHANGE_______CREATE',
  CommonSettingUomExchangeUpdate = 'COMMON_SETTING_UOM_EXCHANGE_______UPDATE',
  CommonSettingUomExchangeInactive = 'COMMON_SETTING_UOM_EXCHANGE_______INACTIVE',
  CommonSettingUomExchangeDelete = 'COMMON_SETTING_UOM_EXCHANGE_______DELETE',
  GeneralLedgerIcon = 'GENERAL_LEDGER__________ICON',
  GeneralLedgerJournalEnquiryView = 'GENERAL_LEDGER_JOURNAL_ENQUIRY_______VIEW',
  GeneralLedgerOriginalBudgetView = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______VIEW',
  GeneralLedgerOriginalBudgetCreate = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______CREATE',
  GeneralLedgerOriginalBudgetUpdate = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______UPDATE',
  GeneralLedgerOriginalBudgetDraft = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______DRAFT',
  GeneralLedgerOriginalBudgetVerified = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______VERIFIED',
  GeneralLedgerBudgetRevisionView = 'GENERAL_LEDGER_BUDGET_REVISION_______VIEW',
  GeneralLedgerBudgetRevisionCreate = 'GENERAL_LEDGER_BUDGET_REVISION_______CREATE',
  GeneralLedgerBudgetRevisionUpdate = 'GENERAL_LEDGER_BUDGET_REVISION_______UPDATE',
  GeneralLedgerBudgetRevisionDraft = 'GENERAL_LEDGER_BUDGET_REVISION_______DRAFT',
  GeneralLedgerBudgetRevisionVerified = 'GENERAL_LEDGER_BUDGET_REVISION_______VERIFIED',
  GeneralLedgerJournalProcessingView = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______VIEW',
  GeneralLedgerJournalProcessingCreate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______CREATE',
  GeneralLedgerJournalProcessingUpdate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______UPDATE',
  GeneralLedgerJournalProcessingDraft = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______DRAFT',
  GeneralLedgerJournalProcessingDelete = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______DELETE',
  GeneralLedgerJournalProcessingApproveReject = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______APPROVE_REJECT',
  GeneralLedgerExternalJournalView = 'GENERAL_LEDGER_EXTERNAL_JOURNAL_______VIEW',
  GeneralLedgerExternalJournalImport = 'GENERAL_LEDGER_EXTERNAL_JOURNAL_______IMPORT',
  GeneralLedgerGenerateRecurringJournalView = 'GENERAL_LEDGER_GENERATE_RECURRING_JOURNAL_______VIEW',
  GeneralLedgerGenerateRecurringJournalCreate = 'GENERAL_LEDGER_GENERATE_RECURRING_JOURNAL_______CREATE',
  GeneralLedgerRecurringJournalView = 'GENERAL_LEDGER_RECURRING_JOURNAL_______VIEW',
  GeneralLedgerRecurringJournalCreate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CREATE',
  GeneralLedgerRecurringJournalUpdate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______UPDATE',
  GeneralLedgerRecurringJournalDraft = 'GENERAL_LEDGER_RECURRING_JOURNAL_______DRAFT',
  GeneralLedgerRecurringJournalDelete = 'GENERAL_LEDGER_RECURRING_JOURNAL_______DELETE',
  GeneralLedgerRecurringJournalCancel = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CANCEL',
  GeneralLedgerRecurringJournalApproveReject = 'GENERAL_LEDGER_RECURRING_JOURNAL_______APPROVE_REJECT',
  GeneralLedgerAuditAdjustmentView = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______VIEW',
  GeneralLedgerAuditAdjustmentCreate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______CREATE',
  GeneralLedgerAuditAdjustmentUpdate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______UPDATE',
  GeneralLedgerAuditAdjustmentDraft = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DRAFT',
  GeneralLedgerAuditAdjustmentDelete = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DELETE',
  GeneralLedgerAuditAdjustmentApproveReject = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______APPROVE_REJECT',
  GeneralLedgerClosingMonthYearEndView = 'GENERAL_LEDGER_CLOSING_MONTH_YEAR_END_______VIEW',
  GeneralLedgerDigitalReportsView = 'GENERAL_LEDGER_DIGITAL_REPORTS_______VIEW',
  GeneralLedgerDigitalReportsGlJournalView = 'GENERAL_LEDGER_DIGITAL_REPORTS_GL_JOURNAL____VIEW',
  GeneralLedgerDigitalReportsTrialBalanceView = 'GENERAL_LEDGER_DIGITAL_REPORTS_TRIAL_BALANCE____VIEW',
  GeneralLedgerDigitalReportsTrialBalanceCostCentreView = 'GENERAL_LEDGER_DIGITAL_REPORTS_TRIAL_BALANCE_COST_CENTRE____VIEW',
  GeneralLedgerDigitalReportsGlJournalCostCentreView = 'GENERAL_LEDGER_DIGITAL_REPORTS_GL_JOURNAL_COST_CENTRE____VIEW',
  GeneralLedgerDigitalReportsMasterCoaView = 'GENERAL_LEDGER_DIGITAL_REPORTS_MASTER_COA____VIEW',
  GeneralLedgerFinancialReportView = 'GENERAL_LEDGER_FINANCIAL_REPORT_______VIEW',
  GeneralLedgerFinancialReportSettingsDataSourceView = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_VIEW',
  GeneralLedgerFinancialReportSettingsDataSourceCreate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_CREATE',
  GeneralLedgerFinancialReportSettingsDataSourceUpdate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_UPDATE',
  GeneralLedgerFinancialReportSettingsDataSourceDelete = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_DATA_SOURCE_DELETE',
  GeneralLedgerFinancialReportSettingsFormulaToolsView = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_FORMULA_TOOLS_VIEW',
  GeneralLedgerFinancialReportSettingsReportFormatView = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_VIEW',
  GeneralLedgerFinancialReportSettingsReportFormatCreate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_CREATE',
  GeneralLedgerFinancialReportSettingsReportFormatUpdate = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_UPDATE',
  GeneralLedgerFinancialReportSettingsReportFormatDelete = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_DELETE',
  GeneralLedgerFinancialReportSettingsReportFormatDownload = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_DOWNLOAD',
  GeneralLedgerFinancialReportSettingsReportFormatUpload = 'GENERAL_LEDGER_FINANCIAL_REPORT_SETTINGS_REPORT_FORMAT_UPLOAD',
  GeneralLedgerGlexportDotnetView = 'GENERAL_LEDGER_GLEXPORT_DOTNET_______VIEW',
  GeneralLedgerGlexportDotnetExport = 'GENERAL_LEDGER_GLEXPORT_DOTNET_______EXPORT',
  GeneralLedgerGeneralSettingsView = 'GENERAL_LEDGER_GENERAL_SETTINGS_______VIEW',
  GeneralLedgerGeneralSettingsAccCodeStructureView = 'GENERAL_LEDGER_GENERAL_SETTINGS_ACC_CODE_STRUCTURE____VIEW',
  GeneralLedgerGeneralSettingsAccCodeStructureCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_ACC_CODE_STRUCTURE____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaView = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____VIEW',
  GeneralLedgerGeneralSettingsMasterCoaCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____UPDATE',
  GeneralLedgerGeneralSettingsMasterCoaAssign = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____ASSIGN',
  GeneralLedgerGeneralSettingsMasterCoaDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____DELETE',
  GeneralLedgerGeneralSettingsMasterCoaInactive = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____INACTIVE',
  GeneralLedgerGeneralSettingsCurrencySetupView = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____VIEW',
  GeneralLedgerGeneralSettingsCurrencySetupCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____CREATE',
  GeneralLedgerGeneralSettingsCurrencySetupUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____UPDATE',
  GeneralLedgerGeneralSettingsCurrencySetupDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____DELETE',
  GeneralLedgerGeneralSettingsCurrencyExchangeView = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____VIEW',
  GeneralLedgerGeneralSettingsCurrencyExchangeCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____CREATE',
  GeneralLedgerGeneralSettingsCurrencyExchangeUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____UPDATE',
  GeneralLedgerGeneralSettingsCurrencyExchangeDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____DELETE',
  GeneralLedgerCompanySettingsView = 'GENERAL_LEDGER_COMPANY_SETTINGS_______VIEW',
  GeneralLedgerCompanySettingsCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_______CREATE',
  GeneralLedgerCompanySettingsCompanyProfileView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_PROFILE____VIEW',
  GeneralLedgerCompanySettingsCompanyProfileUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_PROFILE____UPDATE',
  GeneralLedgerCompanySettingsCostCentreView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____VIEW',
  GeneralLedgerCompanySettingsCostCentreCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____CREATE',
  GeneralLedgerCompanySettingsCostCentreUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____UPDATE',
  GeneralLedgerCompanySettingsCostCentreDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____DELETE',
  GeneralLedgerCompanySettingsCostCentreSetDefault = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____SET_DEFAULT',
  GeneralLedgerCompanySettingsEntityCoaView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____VIEW',
  GeneralLedgerCompanySettingsEntityCoaInactive = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____INACTIVE',
  GeneralLedgerCompanySettingsEntityCoaUnassign = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____UNASSIGN',
  GeneralLedgerCompanySettingsNumberingStructureView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____VIEW',
  GeneralLedgerCompanySettingsNumberingStructureCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____CREATE',
  GeneralLedgerCompanySettingsNumberingStructureUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____UPDATE',
  GeneralLedgerCompanySettingsNumberingStructureDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____DELETE',
  GeneralLedgerCompanySettingsAccountingPeriodView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____VIEW',
  GeneralLedgerCompanySettingsAccountingPeriodCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____CREATE',
  GeneralLedgerCompanySettingsAccountingPeriodUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____UPDATE',
  GeneralLedgerCompanySettingsAccountingPeriodDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____DELETE',
  GeneralLedgerCompanySettingsJournalTypeView = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____VIEW',
  GeneralLedgerCompanySettingsJournalTypeCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____CREATE',
  GeneralLedgerCompanySettingsJournalTypeUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____UPDATE',
  GeneralLedgerCompanySettingsJournalTypeDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____DELETE',
  GeneralLedgerCompanySettingsRetainEarningAccView = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC____VIEW',
  GeneralLedgerCompanySettingsRetainEarningAccCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC____CREATE',
  GeneralLedgerCompanySettingsCompanyAuthorizationView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_AUTHORIZATION____VIEW',
  CashBookIcon = 'CASH_BOOK__________ICON',
  CashBookPaymentView = 'CASH_BOOK_PAYMENT_______VIEW',
  CashBookPaymentCreate = 'CASH_BOOK_PAYMENT_______CREATE',
  CashBookPaymentUpdate = 'CASH_BOOK_PAYMENT_______UPDATE',
  CashBookPaymentDraft = 'CASH_BOOK_PAYMENT_______DRAFT',
  CashBookPaymentApproveReject = 'CASH_BOOK_PAYMENT_______APPROVE_REJECT',
  CashBookPaymentDelete = 'CASH_BOOK_PAYMENT_______DELETE',
  CashBookReceiptView = 'CASH_BOOK_RECEIPT_______VIEW',
  CashBookReceiptCreate = 'CASH_BOOK_RECEIPT_______CREATE',
  CashBookReceiptUpdate = 'CASH_BOOK_RECEIPT_______UPDATE',
  CashBookReceiptApproveReject = 'CASH_BOOK_RECEIPT_____APPROVE_REJECT',
  CashBookReceiptDraft = 'CASH_BOOK_RECEIPT_____DRAFT',
  CashBookReceiptDelete = 'CASH_BOOK_RECEIPT_____DELETE',
  CashBookBankTransferView = 'CASH_BOOK_BANK_TRANSFER_______VIEW',
  CashBookBankTransferCreate = 'CASH_BOOK_BANK_TRANSFER_______CREATE',
  CashBookBankTransferUpdate = 'CASH_BOOK_BANK_TRANSFER_______UPDATE',
  CashBookBankTransferDraft = 'CASH_BOOK_BANK_TRANSFER_______DRAFT',
  CashBookBankTransferDelete = 'CASH_BOOK_BANK_TRANSFER_______DELETE',
  CashBookBankTransferApproveReject = 'CASH_BOOK_BANK_TRANSFER_______APPROVE_REJECT',
  CashBookBankReconcilationView = 'CASH_BOOK_BANK_RECONCILATION_______VIEW',
  CashBookBankReconcilationCreate = 'CASH_BOOK_BANK_RECONCILATION_______CREATE',
  CashBookBankReconcilationUpdate = 'CASH_BOOK_BANK_RECONCILATION_______UPDATE',
  CashBookBankReconcilationDelete = 'CASH_BOOK_BANK_RECONCILATION_______DELETE',
  CashBookBankReconcilationMatch = 'CASH_BOOK_BANK_RECONCILATION_______MATCH',
  CashBookBankReconcilationUnmatch = 'CASH_BOOK_BANK_RECONCILATION_______UNMATCH',
  CashBookExternalLedgerView = 'CASH_BOOK_EXTERNAL_LEDGER_______VIEW',
  CashBookExternalLedgerImport = 'CASH_BOOK_EXTERNAL_LEDGER_______IMPORT',
  CashBookGlExportView = 'CASH_BOOK_GL_EXPORT_______VIEW',
  CashBookGlExportShare = 'CASH_BOOK_GL_EXPORT_______SHARE',
  CashBookGlExportDownload = 'CASH_BOOK_GL_EXPORT_______DOWNLOAD',
  CashBookDigitalReportView = 'CASH_BOOK_DIGITAL_REPORT_______VIEW',
  CashBookDigitalReportCashLedgerView = 'CASH_BOOK_DIGITAL_REPORT_CASH_LEDGER____VIEW',
  CashBookDigitalReportCashInOutFlowView = 'CASH_BOOK_DIGITAL_REPORT_CASH_IN_OUT_FLOW____VIEW',
  CashBookDigitalReportBankReconView = 'CASH_BOOK_DIGITAL_REPORT_BANK_RECON____VIEW',
  CashBookGeneralSettingsView = 'CASH_BOOK_GENERAL_SETTINGS_______VIEW',
  CashBookGeneralSettingsBankProfileView = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____VIEW',
  CashBookGeneralSettingsBankProfileCreate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____CREATE',
  CashBookGeneralSettingsBankProfileUpdate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____UPDATE',
  CashBookGeneralSettingsBankProfileDelete = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____DELETE',
  CashBookGeneralSettingsPolicySettingsView = 'CASH_BOOK_GENERAL_SETTINGS_POLICY_SETTINGS____VIEW',
  CashBookGeneralSettingsPolicySettingsCreate = 'CASH_BOOK_GENERAL_SETTINGS_POLICY_SETTINGS____CREATE',
  CashBookGeneralSettingsTaxSetupView = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____VIEW',
  CashBookGeneralSettingsTaxSetupCreate = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____CREATE',
  CashBookGeneralSettingsTaxSetupUpdate = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____UPDATE',
  CashBookGeneralSettingsTaxSetupInactive = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____INACTIVE',
  CashBookGeneralSettingsTaxSetupDelete = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____DELETE',
  CashBookGeneralSettingsPaymentMethodView = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____VIEW',
  CashBookGeneralSettingsPaymentMethodCreate = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____CREATE',
  CashBookGeneralSettingsPaymentMethodUpdate = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____UPDATE',
  CashBookGeneralSettingsPaymentMethodDelete = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____DELETE',
  CashBookGeneralSettingsCreditCardTypeView = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____VIEW',
  CashBookGeneralSettingsCreditCardTypeCreate = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____CREATE',
  CashBookGeneralSettingsCreditCardTypeUpdate = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____UPDATE',
  CashBookGeneralSettingsCreditCardTypeDelete = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____DELETE',
  CashBookCompanySettingsView = 'CASH_BOOK_COMPANY_SETTINGS_____VIEW',
  CashBookCompanySettingsNumberingStructuresView = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  CashBookCompanySettingsNumberingStructuresCreate = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  CashBookCompanySettingsNumberingStructuresUpdate = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  CashBookCompanySettingsNumberingStructuresDelete = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  CashBookCompanySettingsBankAccountView = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____VIEW',
  CashBookCompanySettingsBankAccountCreate = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____CREATE',
  CashBookCompanySettingsBankAccountUpdate = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____UPDATE',
  CashBookCompanySettingsBankAccountDelete = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____DELETE',
  CashBookCompanySettingsBankAccountInactive = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____INACTIVE',
  AccPayableIcon = 'ACC_PAYABLE__________ICON',
  AccPayableCreditorProfileView = 'ACC_PAYABLE_CREDITOR_PROFILE_______VIEW',
  AccPayableCreditorProfileUpdate = 'ACC_PAYABLE_CREDITOR_PROFILE_______UPDATE',
  AccPayableAdvancesView = 'ACC_PAYABLE_ADVANCES_______VIEW',
  AccPayableAdvancesCreate = 'ACC_PAYABLE_ADVANCES_______CREATE',
  AccPayableAdvancesUpdate = 'ACC_PAYABLE_ADVANCES_______UPDATE',
  AccPayableAdvancesDraft = 'ACC_PAYABLE_ADVANCES_______DRAFT',
  AccPayableAdvancesDelete = 'ACC_PAYABLE_ADVANCES_______DELETE',
  AccPayableAdvancesApproveReject = 'ACC_PAYABLE_ADVANCES_______APPROVE_REJECT',
  AccPayableAdvancesAllocation = 'ACC_PAYABLE_ADVANCES_______ALLOCATION',
  AccPayableInvoicesView = 'ACC_PAYABLE_INVOICES_______VIEW',
  AccPayableInvoicesCreate = 'ACC_PAYABLE_INVOICES_______CREATE',
  AccPayableInvoicesUpdate = 'ACC_PAYABLE_INVOICES_______UPDATE',
  AccPayableInvoicesDraft = 'ACC_PAYABLE_INVOICES_______DRAFT',
  AccPayableInvoicesDelete = 'ACC_PAYABLE_INVOICES_______DELETE',
  AccPayableInvoicesApproveReject = 'ACC_PAYABLE_INVOICES_______APPROVE_REJECT',
  AccPayableInvoicesDuplicate = 'ACC_PAYABLE_INVOICES_______DUPLICATE',
  AccPayableCreditNotesView = 'ACC_PAYABLE_CREDIT_NOTES_______VIEW',
  AccPayableCreditNotesCreate = 'ACC_PAYABLE_CREDIT_NOTES_______CREATE',
  AccPayableCreditNotesUpdate = 'ACC_PAYABLE_CREDIT_NOTES_______UPDATE',
  AccPayableCreditNotesAllocation = 'ACC_PAYABLE_CREDIT_NOTES_______ALLOCATION',
  AccPayableCreditNotesDraft = 'ACC_PAYABLE_CREDIT_NOTES_______DRAFT',
  AccPayableCreditNotesDelete = 'ACC_PAYABLE_CREDIT_NOTES_______DELETE',
  AccPayableCreditNotesApproveReject = 'ACC_PAYABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableCreditorCreditNotesView = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______VIEW',
  AccPayableCreditorCreditNotesCreate = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______CREATE',
  AccPayableCreditorCreditNotesUpdate = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______UPDATE',
  AccPayableCreditorCreditNotesAllocation = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______ALLOCATION',
  AccPayableCreditorCreditNotesDraft = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______DRAFT',
  AccPayableCreditorCreditNotesDelete = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______DELETE',
  AccPayableCreditorCreditNotesApproveReject = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableDebitNotesView = 'ACC_PAYABLE_DEBIT_NOTES_______VIEW',
  AccPayableDebitNotesCreate = 'ACC_PAYABLE_DEBIT_NOTES_______CREATE',
  AccPayableDebitNotesUpdate = 'ACC_PAYABLE_DEBIT_NOTES_______UPDATE',
  AccPayableDebitNotesDraft = 'ACC_PAYABLE_DEBIT_NOTES_______DRAFT',
  AccPayableDebitNotesDelete = 'ACC_PAYABLE_DEBIT_NOTES_______DELETE',
  AccPayableDebitNotesApproveReject = 'ACC_PAYABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccPayableCreditorDebitNotesView = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______VIEW',
  AccPayableCreditorDebitNotesCreate = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______CREATE',
  AccPayableCreditorDebitNotesUpdate = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______UPDATE',
  AccPayableCreditorDebitNotesDraft = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______DRAFT',
  AccPayableCreditorDebitNotesDelete = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______DELETE',
  AccPayableCreditorDebitNotesApproveReject = 'ACC_PAYABLE_CREDITOR_DEBIT_NOTES_______APPROVE_REJECT',
  AccPayablePaymentView = 'ACC_PAYABLE_PAYMENT_______VIEW',
  AccPayablePaymentCreate = 'ACC_PAYABLE_PAYMENT_______CREATE',
  AccPayablePaymentUpdate = 'ACC_PAYABLE_PAYMENT_______UPDATE',
  AccPayablePaymentAllocation = 'ACC_PAYABLE_PAYMENT_______ALLOCATION',
  AccPayablePaymentDraft = 'ACC_PAYABLE_PAYMENT_______DRAFT',
  AccPayablePaymentDelete = 'ACC_PAYABLE_PAYMENT_______DELETE',
  AccPayablePaymentApproveReject = 'ACC_PAYABLE_PAYMENT_______APPROVE_REJECT',
  AccPayableBatchPaymentView = 'ACC_PAYABLE_BATCH_PAYMENT_______VIEW',
  AccPayableBatchPaymentCreate = 'ACC_PAYABLE_BATCH_PAYMENT_______CREATE',
  AccPayableBatchPaymentUpdate = 'ACC_PAYABLE_BATCH_PAYMENT_______UPDATE',
  AccPayableBatchPaymentDraft = 'ACC_PAYABLE_BATCH_PAYMENT_______DRAFT',
  AccPayableBatchPaymentDelete = 'ACC_PAYABLE_BATCH_PAYMENT_______DELETE',
  AccPayableBatchPaymentPaid = 'ACC_PAYABLE_BATCH_PAYMENT_______PAID',
  AccPayableBatchPaymentApproveReject = 'ACC_PAYABLE_BATCH_PAYMENT_______APPROVE_REJECT',
  AccPayableRefundView = 'ACC_PAYABLE_REFUND_______VIEW',
  AccPayableRefundCreate = 'ACC_PAYABLE_REFUND_______CREATE',
  AccPayableRefundUpdate = 'ACC_PAYABLE_REFUND_______UPDATE',
  AccPayableRefundDraft = 'ACC_PAYABLE_REFUND_______DRAFT',
  AccPayableRefundDelete = 'ACC_PAYABLE_REFUND_______DELETE',
  AccPayableRefundApproveReject = 'ACC_PAYABLE_REFUND_______APPROVE_REJECT',
  AccPayableSelfBilledView = 'ACC_PAYABLE_SELF_BILLED_______VIEW',
  AccPayableSelfBilledCreate = 'ACC_PAYABLE_SELF_BILLED_______CREATE',
  AccPayableSelfBilledUpdate = 'ACC_PAYABLE_SELF_BILLED_______UPDATE',
  AccPayableSelfBilledDraft = 'ACC_PAYABLE_SELF_BILLED_______DRAFT',
  AccPayableSelfBilledDelete = 'ACC_PAYABLE_SELF_BILLED_______DELETE',
  AccPayableSelfBilledApproveReject = 'ACC_PAYABLE_SELF_BILLED_______APPROVE_REJECT',
  AccPayableConsolidateSelfBilledView = 'ACC_PAYABLE_CONSOLIDATE_SELF_BILLED_______VIEW',
  AccPayableConsolidateSelfBilledCreate = 'ACC_PAYABLE_CONSOLIDATE_SELF_BILLED_______CREATE',
  AccPayableConsolidateSelfBilledSendLhdn = 'ACC_PAYABLE_CONSOLIDATE_SELF_BILLED_______SEND_LHDN',
  AccPayableDigitalReportView = 'ACC_PAYABLE_DIGITAL_REPORT_______VIEW',
  AccPayableDigitalReportOverduePayableView = 'ACC_PAYABLE_DIGITAL_REPORT_OVERDUE_PAYABLE______VIEW',
  AccPayableDigitalReportCreditorLedgerByDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_LEDGER_BY_DOC_DATE______VIEW',
  AccPayableDigitalReportCreditorAgingByDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_BY_DOC_DATE______VIEW',
  AccPayableDigitalReportAdvanceLedgerView = 'ACC_PAYABLE_DIGITAL_REPORT_ADVANCE_LEDGER____VIEW',
  AccPayableDigitalReportCreditorLedgerDetailTrxDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_LEDGER_DETAIL_TRX_DATE____VIEW',
  AccPayableDigitalReportCreditorAgingTrxDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_TRX_DATE____VIEW',
  AccPayableDigitalReportCreditorAgingSummaryDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_SUMMARY_DOC_DATE____VIEW',
  AccPayableDigitalReportCreditorAgingSummaryTrxDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_SUMMARY_TRX_DATE____VIEW',
  AccPayableDigitalReportCreditorControlSummaryView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_CONTROL_SUMMARY____VIEW',
  AccPayableDigitalReportCreditorControlDetailView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_CONTROL_DETAIL____VIEW',
  AccPayableGeneralSettingsView = 'ACC_PAYABLE_GENERAL_SETTINGS_____VIEW',
  AccPayableGeneralSettingsCreditorTypeView = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____VIEW',
  AccPayableGeneralSettingsCreditorTypeCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____CREATE',
  AccPayableGeneralSettingsCreditorTypeUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____UPDATE',
  AccPayableGeneralSettingsCreditorTypeDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____DELETE',
  AccPayableGeneralSettingsCreditorProfileView = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____VIEW',
  AccPayableGeneralSettingsCreditorProfileCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____CREATE',
  AccPayableGeneralSettingsCreditorProfileAssign = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____ASSIGN',
  AccPayableGeneralSettingsCreditorProfileUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____UPDATE',
  AccPayableGeneralSettingsCreditorProfileInactive = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____INACTIVE',
  AccPayableGeneralSettingsExpenseClassView = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____VIEW',
  AccPayableGeneralSettingsExpenseClassCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____CREATE',
  AccPayableGeneralSettingsExpenseClassUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____UPDATE',
  AccPayableGeneralSettingsExpenseClassAssign = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____ASSIGN',
  AccPayableGeneralSettingsExpenseClassDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____DELETE',
  AccPayableGeneralSettingsExpenseItemView = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____VIEW',
  AccPayableGeneralSettingsExpenseItemCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____CREATE',
  AccPayableGeneralSettingsExpenseItemUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____UPDATE',
  AccPayableGeneralSettingsExpenseItemInactive = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____INACTIVE',
  AccPayableGeneralSettingsExpenseItemDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____DELETE',
  AccPayableGeneralSettingsUomSetupView = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____VIEW',
  AccPayableGeneralSettingsUomSetupCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____CREATE',
  AccPayableGeneralSettingsUomSetupUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____UPDATE',
  AccPayableGeneralSettingsUomSetupDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____DELETE',
  AccPayableGeneralSettingsUomConversionView = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____VIEW',
  AccPayableGeneralSettingsUomConversionCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____CREATE',
  AccPayableGeneralSettingsUomConversionUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____UPDATE',
  AccPayableGeneralSettingsUomConversionDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____DELETE',
  AccPayableCompanySettingsView = 'ACC_PAYABLE_COMPANY_SETTINGS____VIEW',
  AccPayableCompanySettingsNumberingStructuresView = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  AccPayableCompanySettingsNumberingStructuresCreate = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  AccPayableCompanySettingsNumberingStructuresUpdate = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  AccPayableCompanySettingsNumberingStructuresDelete = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  AccPayableCompanySettingsAgingView = 'ACC_PAYABLE_COMPANY_SETTINGS_AGING____VIEW',
  AccPayableCompanySettingsAgingCreate = 'ACC_PAYABLE_COMPANY_SETTINGS_AGING____CREATE',
  AccReceivableIcon = 'ACC_RECEIVABLE__________ICON',
  AccReceivableDebtorAccView = 'ACC_RECEIVABLE_DEBTOR_ACC_______VIEW',
  AccReceivableDebtorAccCreate = 'ACC_RECEIVABLE_DEBTOR_ACC_______CREATE',
  AccReceivableDebtorAccUpdate = 'ACC_RECEIVABLE_DEBTOR_ACC_______UPDATE',
  AccReceivableInvoicesView = 'ACC_RECEIVABLE_INVOICES_______VIEW',
  AccReceivableInvoicesCreate = 'ACC_RECEIVABLE_INVOICES_______CREATE',
  AccReceivableInvoicesUpdate = 'ACC_RECEIVABLE_INVOICES_______UPDATE',
  AccReceivableInvoicesDraft = 'ACC_RECEIVABLE_INVOICES_______DRAFT',
  AccReceivableInvoicesDelete = 'ACC_RECEIVABLE_INVOICES_______DELETE',
  AccReceivableInvoicesApproveReject = 'ACC_RECEIVABLE_INVOICES_______APPROVE_REJECT',
  AccReceivableCreditNotesView = 'ACC_RECEIVABLE_CREDIT_NOTES_______VIEW',
  AccReceivableCreditNotesCreate = 'ACC_RECEIVABLE_CREDIT_NOTES_______CREATE',
  AccReceivableCreditNotesUpdate = 'ACC_RECEIVABLE_CREDIT_NOTES_______UPDATE',
  AccReceivableCreditNotesDraft = 'ACC_RECEIVABLE_CREDIT_NOTES_______DRAFT',
  AccReceivableCreditNotesDelete = 'ACC_RECEIVABLE_CREDIT_NOTES_______DELETE',
  AccReceivableCreditNotesAllocation = 'ACC_RECEIVABLE_CREDIT_NOTES_______ALLOCATION',
  AccReceivableCreditNotesApproveReject = 'ACC_RECEIVABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccReceivableDebitNotesView = 'ACC_RECEIVABLE_DEBIT_NOTES_______VIEW',
  AccReceivableDebitNotesCreate = 'ACC_RECEIVABLE_DEBIT_NOTES_______CREATE',
  AccReceivableDebitNotesUpdate = 'ACC_RECEIVABLE_DEBIT_NOTES_______UPDATE',
  AccReceivableDebitNotesDraft = 'ACC_RECEIVABLE_DEBIT_NOTES_______DRAFT',
  AccReceivableDebitNotesDelete = 'ACC_RECEIVABLE_DEBIT_NOTES_______DELETE',
  AccReceivableDebitNotesApproveReject = 'ACC_RECEIVABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccReceivableReceiptsView = 'ACC_RECEIVABLE_RECEIPTS_______VIEW',
  AccReceivableReceiptsCreate = 'ACC_RECEIVABLE_RECEIPTS_______CREATE',
  AccReceivableReceiptsUpdate = 'ACC_RECEIVABLE_RECEIPTS_______UPDATE',
  AccReceivableReceiptsDraft = 'ACC_RECEIVABLE_RECEIPTS_______DRAFT',
  AccReceivableReceiptsDelete = 'ACC_RECEIVABLE_RECEIPTS_______DELETE',
  AccReceivableReceiptsAllocation = 'ACC_RECEIVABLE_RECEIPTS_______ALLOCATION',
  AccReceivableReceiptsApproveReject = 'ACC_RECEIVABLE_RECEIPTS_______APPROVE_REJECT',
  AccReceivableAdvancesView = 'ACC_RECEIVABLE_ADVANCES_______VIEW',
  AccReceivableAdvancesCreate = 'ACC_RECEIVABLE_ADVANCES_______CREATE',
  AccReceivableAdvancesUpdate = 'ACC_RECEIVABLE_ADVANCES_______UPDATE',
  AccReceivableAdvancesDraft = 'ACC_RECEIVABLE_ADVANCES_______DRAFT',
  AccReceivableAdvancesDelete = 'ACC_RECEIVABLE_ADVANCES_______DELETE',
  AccReceivableAdvancesAllocation = 'ACC_RECEIVABLE_ADVANCES_______ALLOCATION',
  AccReceivableAdvancesApproveReject = 'ACC_RECEIVABLE_ADVANCES_______APPROVE_REJECT',
  AccReceivableRefundView = 'ACC_RECEIVABLE_REFUND_______VIEW',
  AccReceivableRefundCreate = 'ACC_RECEIVABLE_REFUND_______CREATE',
  AccReceivableRefundUpdate = 'ACC_RECEIVABLE_REFUND_______UPDATE',
  AccReceivableRefundDraft = 'ACC_RECEIVABLE_REFUND_______DRAFT',
  AccReceivableRefundDelete = 'ACC_RECEIVABLE_REFUND_______DELETE',
  AccReceivableRefundApproveReject = 'ACC_RECEIVABLE_REFUND_______APPROVE_REJECT',
  AccReceivableConsolidateEinvoiceView = 'ACC_RECEIVABLE_CONSOLIDATE_EINVOICE_______VIEW',
  AccReceivableConsolidateEinvoiceCreate = 'ACC_RECEIVABLE_CONSOLIDATE_EINVOICE_______CREATE',
  AccReceivableConsolidateEinvoiceSendLhdn = 'ACC_RECEIVABLE_CONSOLIDATE_EINVOICE_______SEND_LHDN',
  AccReceivableDigitalReportsView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_______VIEW',
  AccReceivableDigitalReportsDebtorLedgerByDocDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_LEDGER_BY_DOC_DATE______VIEW',
  AccReceivableDigitalReportsStatementAccountView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_STATEMENT_ACCOUNT____VIEW',
  AccReceivableDigitalReportsDebtorAgingSummaryByTrxDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_AGING_SUMMARY_BY_TRX_DATE____VIEW',
  AccReceivableDigitalReportsDebtorAgingSummaryByDocDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_AGING_SUMMARY_BY_DOC_DATE____VIEW',
  AccReceivableDigitalReportsDebtorAgingDetailByTrxDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_AGING_DETAIL_BY_TRX_DATE____VIEW',
  AccReceivableDigitalReportsDebtorControlSummaryView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_CONTROL_SUMMARY____VIEW',
  AccReceivableDigitalReportsDebtorControlDetailView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_CONTROL_DETAIL____VIEW',
  AccReceivableGeneralSettingsView = 'ACC_RECEIVABLE_GENERAL_SETTINGS______VIEW',
  AccReceivableGeneralSettingsDebtorTypeView = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___VIEW',
  AccReceivableGeneralSettingsDebtorTypeCreate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___CREATE',
  AccReceivableGeneralSettingsDebtorTypeUpdate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___UPDATE',
  AccReceivableGeneralSettingsDebtorTypeDelete = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___DELETE',
  AccReceivableGeneralSettingsBillItemView = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___VIEW',
  AccReceivableGeneralSettingsBillItemCreate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___CREATE',
  AccReceivableGeneralSettingsBillItemUpdate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___UPDATE',
  AccReceivableGeneralSettingsBillItemDelete = 'ACC_RECEIVABLE_GENERAL_SETTINGS_BILL_ITEM___DELETE',
  AccReceivableCompanySettingsView = 'ACC_RECEIVABLE_COMPANY_SETTINGS____VIEW',
  AccReceivableCompanySettingsNumberingStructuresView = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___VIEW',
  AccReceivableCompanySettingsNumberingStructuresCreate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___CREATE',
  AccReceivableCompanySettingsNumberingStructuresUpdate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___UPDATE',
  AccReceivableCompanySettingsNumberingStructuresDelete = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___DELETE',
  AccReceivableCompanySettingsAgingView = 'ACC_RECEIVABLE_COMPANY_SETTINGS_AGING___VIEW',
  AccReceivableCompanySettingsAgingCreate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_AGING___CREATE',
  InventoryControlIcon = 'INVENTORY_CONTROL__________ICON',
  InventoryControlStockReceiptView = 'INVENTORY_CONTROL_STOCK_RECEIPT_______VIEW',
  InventoryControlStockReceiptCreate = 'INVENTORY_CONTROL_STOCK_RECEIPT_______CREATE',
  InventoryControlStockReceiptUpdate = 'INVENTORY_CONTROL_STOCK_RECEIPT_______UPDATE',
  InventoryControlStockReceiptDraft = 'INVENTORY_CONTROL_STOCK_RECEIPT_______DRAFT',
  InventoryControlStockReceiptDelete = 'INVENTORY_CONTROL_STOCK_RECEIPT_______DELETE',
  InventoryControlStockReceiptApproveReject = 'INVENTORY_CONTROL_STOCK_RECEIPT_______APPROVE_REJECT',
  InventoryControlStockIssueView = 'INVENTORY_CONTROL_STOCK_ISSUE_______VIEW',
  InventoryControlStockIssueCreate = 'INVENTORY_CONTROL_STOCK_ISSUE_______CREATE',
  InventoryControlStockIssueUpdate = 'INVENTORY_CONTROL_STOCK_ISSUE_______UPDATE',
  InventoryControlStockIssueDraft = 'INVENTORY_CONTROL_STOCK_ISSUE_______DRAFT',
  InventoryControlStockIssueDelete = 'INVENTORY_CONTROL_STOCK_ISSUE_______DELETE',
  InventoryControlStockIssueApproveReject = 'INVENTORY_CONTROL_STOCK_ISSUE_______APPROVE_REJECT',
  InventoryControlStockTransferView = 'INVENTORY_CONTROL_STOCK_TRANSFER_______VIEW',
  InventoryControlStockTransferCreate = 'INVENTORY_CONTROL_STOCK_TRANSFER_______CREATE',
  InventoryControlStockTransferUpdate = 'INVENTORY_CONTROL_STOCK_TRANSFER_______UPDATE',
  InventoryControlStockTransferDraft = 'INVENTORY_CONTROL_STOCK_TRANSFER_______DRAFT',
  InventoryControlStockTransferDelete = 'INVENTORY_CONTROL_STOCK_TRANSFER_______DELETE',
  InventoryControlStockTransferApproveReject = 'INVENTORY_CONTROL_STOCK_TRANSFER_______APPROVE_REJECT',
  InventoryControlStockAdjustmentView = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______VIEW',
  InventoryControlStockAdjustmentCreate = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______CREATE',
  InventoryControlStockAdjustmentUpdate = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______UPDATE',
  InventoryControlStockAdjustmentDraft = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______DRAFT',
  InventoryControlStockAdjustmentDelete = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______DELETE',
  InventoryControlStockAdjustmentApproveReject = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  InventoryControlStockCostAdjustmentView = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______VIEW',
  InventoryControlStockCostAdjustmentCreate = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______CREATE',
  InventoryControlStockCostAdjustmentUpdate = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______UPDATE',
  InventoryControlStockCostAdjustmentDraft = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______DRAFT',
  InventoryControlStockCostAdjustmentDelete = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______DELETE',
  InventoryControlStockCostAdjustmentApproveReject = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______APPROVE_REJECT',
  InventoryControlStockRequisitionView = 'INVENTORY_CONTROL_STOCK_REQUISITION_______VIEW',
  InventoryControlStockRequisitionCreate = 'INVENTORY_CONTROL_STOCK_REQUISITION_______CREATE',
  InventoryControlStockRequisitionUpdate = 'INVENTORY_CONTROL_STOCK_REQUISITION_______UPDATE',
  InventoryControlStockRequisitionDraft = 'INVENTORY_CONTROL_STOCK_REQUISITION_______DRAFT',
  InventoryControlStockRequisitionCancel = 'INVENTORY_CONTROL_STOCK_REQUISITION_______CANCEL',
  InventoryControlStockRequisitionDelete = 'INVENTORY_CONTROL_STOCK_REQUISITION_______DELETE',
  InventoryControlStockRequisitionApproveReject = 'INVENTORY_CONTROL_STOCK_REQUISITION_______APPROVE_REJECT',
  InventoryControlStockTransferRequisitionView = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______VIEW',
  InventoryControlStockTransferRequisitionCreate = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______CREATE',
  InventoryControlStockTransferRequisitionUpdate = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______UPDATE',
  InventoryControlStockTransferRequisitionDraft = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______DRAFT',
  InventoryControlStockTransferRequisitionCancel = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______CANCEL',
  InventoryControlStockTransferRequisitionDelete = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______DELETE',
  InventoryControlStockTransferRequisitionApproveReject = 'INVENTORY_CONTROL_STOCK_TRANSFER_REQUISITION_______APPROVE_REJECT',
  InventoryControlCycleCountingView = 'INVENTORY_CONTROL_CYCLE_COUNTING_______VIEW',
  InventoryControlCycleCountingCreate = 'INVENTORY_CONTROL_CYCLE_COUNTING_______CREATE',
  InventoryControlCycleCountingUpdate = 'INVENTORY_CONTROL_CYCLE_COUNTING_______UPDATE',
  InventoryControlCycleCountingDraft = 'INVENTORY_CONTROL_CYCLE_COUNTING_______DRAFT',
  InventoryControlCycleCountingDelete = 'INVENTORY_CONTROL_CYCLE_COUNTING_______DELETE',
  InventoryControlCycleCountingApproveReject = 'INVENTORY_CONTROL_CYCLE_COUNTING_______APPROVE_REJECT',
  InventoryControlStockEnquiryView = 'INVENTORY_CONTROL_STOCK_ENQUIRY_______VIEW',
  InventoryControlDigitalReportsView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_____VIEW',
  InventoryControlDigitalReportsStockLedgerByLocationView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_LEDGER_BY_LOCATION____VIEW',
  InventoryControlDigitalReportsStockBalanceByDocDateView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_BALANCE_BY_DOC_DATE____VIEW',
  InventoryControlDigitalReportsStockBalanceByTrxDateView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_BALANCE_BY_TRX_DATE____VIEW',
  InventoryControlGeneralSettingsView = 'INVENTORY_CONTROL_GENERAL_SETTINGS____VIEW',
  InventoryControlGeneralSettingsWarehouseView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____VIEW',
  InventoryControlGeneralSettingsWarehouseCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____CREATE',
  InventoryControlGeneralSettingsWarehouseUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____UPDATE',
  InventoryControlGeneralSettingsWarehouseDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_WAREHOUSE____DELETE',
  InventoryControlGeneralSettingsUomView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____VIEW',
  InventoryControlGeneralSettingsUomCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____CREATE',
  InventoryControlGeneralSettingsUomUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____UPDATE',
  InventoryControlGeneralSettingsUomDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM____DELETE',
  InventoryControlGeneralSettingsUomExchangeView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____VIEW',
  InventoryControlGeneralSettingsUomExchangeCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____CREATE',
  InventoryControlGeneralSettingsUomExchangeUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____UPDATE',
  InventoryControlGeneralSettingsUomExchangeDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_UOM_EXCHANGE____DELETE',
  InventoryControlGeneralSettingsStockCategoryView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____VIEW',
  InventoryControlGeneralSettingsStockCategoryCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____CREATE',
  InventoryControlGeneralSettingsStockCategoryUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____UPDATE',
  InventoryControlGeneralSettingsStockCategoryDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_CATEGORY____DELETE',
  InventoryControlGeneralSettingsStockItemView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____VIEW',
  InventoryControlGeneralSettingsStockItemCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____CREATE',
  InventoryControlGeneralSettingsStockItemUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____UPDATE',
  InventoryControlGeneralSettingsStockItemDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____DELETE',
  InventoryControlGeneralSettingsStockItemInactive = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_ITEM____INACTIVE',
  InventoryControlGeneralSettingsTrxTypeView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____VIEW',
  InventoryControlGeneralSettingsTrxTypeCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____CREATE',
  InventoryControlGeneralSettingsTrxTypeUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____UPDATE',
  InventoryControlGeneralSettingsTrxTypeInactive = 'INVENTORY_CONTROL_GENERAL_SETTINGS_TRX_TYPE____INACTIVE',
  InventoryControlGeneralSettingsStockQtyAdjReasonsView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____VIEW',
  InventoryControlGeneralSettingsStockQtyAdjReasonsCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____CREATE',
  InventoryControlGeneralSettingsStockQtyAdjReasonsUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____UPDATE',
  InventoryControlGeneralSettingsStockQtyAdjReasonsDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_STOCK_QTY_ADJ_REASONS____DELETE',
  InventoryControlGeneralSettingsNumberingStructureView = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____VIEW',
  InventoryControlGeneralSettingsNumberingStructureCreate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____CREATE',
  InventoryControlGeneralSettingsNumberingStructureUpdate = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____UPDATE',
  InventoryControlGeneralSettingsNumberingStructureDelete = 'INVENTORY_CONTROL_GENERAL_SETTINGS_NUMBERING_STRUCTURE____DELETE',
  InventoryControlCompanySettingsView = 'INVENTORY_CONTROL_COMPANY_SETTINGS____VIEW',
  GeneralPurchaseIcon = 'GENERAL_PURCHASE__________ICON',
  GeneralPurchaseScheduledPurchasesView = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______VIEW',
  GeneralPurchaseScheduledPurchasesCreate = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______CREATE',
  GeneralPurchaseScheduledPurchasesUpdate = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______UPDATE',
  GeneralPurchaseScheduledPurchasesDelete = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______DELETE',
  GeneralPurchaseScheduledPurchasesPreparePo = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______PREPARE_PO',
  GeneralPurchaseScheduledPurchasesGeneratePo = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______GENERATE_PO',
  GeneralPurchaseScheduledPurchasesPurchaseRequest = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______PURCHASE_REQUEST',
  GeneralPurchaseScheduledPurchasesVerifyRequest = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______VERIFY_REQUEST',
  GeneralPurchaseDirectPoView = 'GENERAL_PURCHASE_DIRECT_PO_______VIEW',
  GeneralPurchaseDirectPoCreate = 'GENERAL_PURCHASE_DIRECT_PO_______CREATE',
  GeneralPurchaseDirectPoUpdate = 'GENERAL_PURCHASE_DIRECT_PO_______UPDATE',
  GeneralPurchaseDirectPoDelete = 'GENERAL_PURCHASE_DIRECT_PO_______DELETE',
  GeneralPurchaseDirectPoCancel = 'GENERAL_PURCHASE_DIRECT_PO_______CANCEL',
  GeneralPurchaseDirectPoDuplicate = 'GENERAL_PURCHASE_DIRECT_PO_______DUPLICATE',
  GeneralPurchaseDirectPoApproveReject = 'GENERAL_PURCHASE_DIRECT_PO_______APPROVE_REJECT',
  GeneralPurchasePurchaseRequisitionView = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  GeneralPurchasePurchaseRequisitionCreate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  GeneralPurchasePurchaseRequisitionUpdate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  GeneralPurchasePurchaseRequisitionDelete = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  GeneralPurchasePurchaseRequisitionCancel = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  GeneralPurchasePurchaseRequisitionDuplicate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______DUPLICATE',
  GeneralPurchasePurchaseRequisitionApproveReject = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  GeneralPurchaseRequestForQuotationView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  GeneralPurchaseRequestForQuotationCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  GeneralPurchaseRequestForQuotationUpdate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  GeneralPurchaseRequestForQuotationDelete = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  GeneralPurchaseRequestForQuotationApproveReject = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  GeneralPurchaseRequestForQuotationRfqInviteesView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  GeneralPurchaseRequestForQuotationRfqInviteesCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  GeneralPurchaseRequestForQuotationRfqInviteesUninvite = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqDelete = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_DELETE',
  GeneralPurchaseRequestForQuotationEvaluationSelectionView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_SELECTION____VIEW',
  GeneralPurchaseRequestForQuotationEvaluationSelectionCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_SELECTION____CREATE',
  GeneralPurchaseGeneratePoFromPrView = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______VIEW',
  GeneralPurchaseGeneratePoFromPrCreate = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______CREATE',
  GeneralPurchaseGeneratePoFromPrUpdate = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______UPDATE',
  GeneralPurchaseGeneratePoFromPrDelete = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______DELETE',
  GeneralPurchaseGeneratePoFromPrApproveReject = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______APPROVE_REJECT',
  GeneralPurchaseGeneratePoFromPrCancel = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______CANCEL',
  GeneralPurchaseCentralisedPoView = 'GENERAL_PURCHASE_CENTRALISED_PO_______VIEW',
  GeneralPurchaseCentralisedPoCreate = 'GENERAL_PURCHASE_CENTRALISED_PO_______CREATE',
  GeneralPurchaseCentralisedPoUpdate = 'GENERAL_PURCHASE_CENTRALISED_PO_______UPDATE',
  GeneralPurchaseCentralisedPoDelete = 'GENERAL_PURCHASE_CENTRALISED_PO_______DELETE',
  GeneralPurchaseCentralisedPoApproveReject = 'GENERAL_PURCHASE_CENTRALISED_PO_______APPROVE_REJECT',
  GeneralPurchaseCentralisedPoCancel = 'GENERAL_PURCHASE_CENTRALISED_PO_______CANCEL',
  GeneralPurchaseGoodReceiveNoteView = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______VIEW',
  GeneralPurchaseGoodReceiveNoteCreate = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______CREATE',
  GeneralPurchaseGoodReceiveNoteUpdate = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______UPDATE',
  GeneralPurchaseGoodReceiveNoteDelete = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______DELETE',
  GeneralPurchaseGoodReceiveNoteApproveReject = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  GeneralPurchaseGoodReturnNoteView = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  GeneralPurchaseGoodReturnNoteCreate = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  GeneralPurchaseGoodReturnNoteUpdate = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  GeneralPurchaseGoodReturnNoteDelete = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  GeneralPurchaseGoodReturnNoteApproveReject = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  GeneralPurchaseDigitalReportsView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_______VIEW',
  GeneralPurchaseDigitalReportsPurchaseOrder_4WaysMatchReportView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PURCHASE_ORDER_4_WAYS_MATCH_REPORT_______VIEW',
  GeneralPurchaseDigitalReportsPoOutstandingView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PO_OUTSTANDING_______VIEW',
  GeneralPurchaseDigitalReportsPurchaseByItemCategoryView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PURCHASE_BY_ITEM_CATEGORY_______VIEW',
  GeneralPurchaseDigitalReportsPurchaseAnalysisByItemView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  GeneralPurchaseDigitalReportsGrnGrtnDailyReportView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_GRN_GRTN_DAILY_REPORT_______VIEW',
  GeneralPurchaseGeneralSettingsView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_____VIEW',
  GeneralPurchaseGeneralSettingsSupplierCategoryView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____VIEW',
  GeneralPurchaseGeneralSettingsSupplierCategoryCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____CREATE',
  GeneralPurchaseGeneralSettingsSupplierCategoryUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____UPDATE',
  GeneralPurchaseGeneralSettingsSupplierCategoryDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____DELETE',
  GeneralPurchaseGeneralSettingsSupplierProfileView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____VIEW',
  GeneralPurchaseGeneralSettingsSupplierProfileCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____CREATE',
  GeneralPurchaseGeneralSettingsSupplierProfileUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____UPDATE',
  GeneralPurchaseGeneralSettingsSupplierProfileInactive = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____INACTIVE',
  GeneralPurchaseGeneralSettingsSupplierProfileAssign = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____ASSIGN',
  GeneralPurchaseGeneralSettingsUomSetupView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____VIEW',
  GeneralPurchaseGeneralSettingsUomSetupCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____CREATE',
  GeneralPurchaseGeneralSettingsUomSetupUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____UPDATE',
  GeneralPurchaseGeneralSettingsUomSetupDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____DELETE',
  GeneralPurchaseGeneralSettingsUomConversionView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____VIEW',
  GeneralPurchaseGeneralSettingsUomConversionCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____CREATE',
  GeneralPurchaseGeneralSettingsUomConversionUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____UPDATE',
  GeneralPurchaseGeneralSettingsUomConversionDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____VIEW',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____CREATE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____UPDATE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryAssign = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____ASSIGN',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____VIEW',
  GeneralPurchaseGeneralSettingsPurchaseItemCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____CREATE',
  GeneralPurchaseGeneralSettingsPurchaseItemUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____UPDATE',
  GeneralPurchaseGeneralSettingsPurchaseItemInactive = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____INACTIVE',
  GeneralPurchaseGeneralSettingsPurchaseItemDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemDisallow = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____DISALLOW',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____VIEW',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____CREATE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____UPDATE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseRequisitionDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_REQUISITION___DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyRfqDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_RFQ___DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyPurchaseOrderDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_PURCHASE_ORDER___DELETE',
  GeneralPurchaseGeneralSettingsGeneralPurchasePolicyView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_GENERAL_PURCHASE_POLICY____VIEW',
  GeneralPurchaseGeneralSettingsGeneralPurchasePolicyCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_GENERAL_PURCHASE_POLICY____CREATE',
  GeneralPurchaseGeneralSettingsGeneralPurchasePolicyUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_GENERAL_PURCHASE_POLICY____UPDATE',
  GeneralPurchaseCompanySettingsView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_____VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY____VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyPrView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyPrCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPrUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___UPDATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPrDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PR___DELETE',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___UPDATE',
  GeneralPurchaseCompanySettingsApprovalPolicyRfqDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_RFQ___DELETE',
  GeneralPurchaseCompanySettingsApprovalPolicyPoView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyPoCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPoUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___UPDATE',
  GeneralPurchaseCompanySettingsApprovalPolicyPoDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY_PO___DELETE',
  GeneralPurchaseCompanySettingsNumberingStructuresView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  GeneralPurchaseCompanySettingsNumberingStructuresCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  GeneralPurchaseCompanySettingsNumberingStructuresUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  GeneralPurchaseCompanySettingsNumberingStructuresDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  GeneralPurchaseCompanySettingsDeliveryLocationView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____VIEW',
  GeneralPurchaseCompanySettingsDeliveryLocationCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____CREATE',
  GeneralPurchaseCompanySettingsDeliveryLocationUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____UPDATE',
  GeneralPurchaseCompanySettingsDeliveryLocationDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____DELETE'
}

export type AccuserRoleId = {
  __typename?: 'AccuserRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AgingDeleteInput = {
  AgingID: Scalars['String'];
};

export type AgingInput = {
  AgingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType: Scalars['String'];
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type AllocatedDocs = {
  __typename?: 'AllocatedDocs';
  AllocationID?: Maybe<Scalars['String']>;
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebitDescription?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitRefNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['String']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditDescription?: Maybe<Scalars['String']>;
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditRefNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
};

export type AllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationAmt: Scalars['Float'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export type AllocationItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  AllocationID?: Maybe<Scalars['String']>;
  DebitItemRefTable?: Maybe<Scalars['String']>;
  DebitItemID?: Maybe<Scalars['String']>;
  CreditItemRefTable?: Maybe<Scalars['String']>;
  CreditItemID?: Maybe<Scalars['String']>;
  CreditOrDebit?: Maybe<Scalars['String']>;
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};

export enum AmountType {
  Opening = 'OPENING',
  YearOpening = 'YEAR_OPENING',
  Closing = 'CLOSING',
  YearClosing = 'YEAR_CLOSING',
  Transaction = 'TRANSACTION'
}

export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};

export type ApprovalPolicyAssignmentInput = {
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  upperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export type ApprovalPolicyDeleteInput = {
  ApprovalPolicyID: Scalars['String'];
};

export type ApprovalPolicyInput = {
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  upperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  PendingClose = 'PENDING_CLOSE',
  PendingCancel = 'PENDING_CANCEL',
  SubmitCancel = 'SUBMIT_CANCEL',
  ApprovedCancel = 'APPROVED_CANCEL',
  RejectedCancel = 'REJECTED_CANCEL',
  CompletedCancel = 'COMPLETED_CANCEL',
  Closed = 'CLOSED',
  New = 'NEW',
  Packed = 'PACKED'
}

export type ApproverInput = {
  RoleID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type AttachmentEntity = AuditEntity & {
  __typename?: 'AttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AttachmentID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: AttachmentRefTable;
  refID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
};

/** JsonType */
export type AttachmentInfo = {
  __typename?: 'AttachmentInfo';
  fileURL?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum AttachmentRefTable {
  Contact = 'CONTACT',
  Company = 'COMPANY',
  Ledger = 'LEDGER',
  Project = 'PROJECT'
}

export type AuditAdjustmentDeleteInput = {
  AuditAdjustmentID: Scalars['String'];
};

export type AuditAdjustmentEntity = AuditEntity & {
  __typename?: 'AuditAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  AuditAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  AdjYearOrPeriod: Scalars['String'];
  JournalID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type AuditAdjustmentInput = {
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  AdjYearOrPeriod: Scalars['String'];
  RefNo: Scalars['String'];
  submittedTs?: Maybe<Scalars['DateTime']>;
  Description: Scalars['String'];
  Status?: Maybe<JournalStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type AuditAdjustmentItemDeleteInput = {
  AuditAdjustmentItemID: Scalars['String'];
};

export type AuditAdjustmentItemEntity = AuditEntity & {
  __typename?: 'AuditAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AuditAdjustmentItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AuditAdjustmentID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  AuditAdjustment?: Maybe<AuditAdjustmentEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AuditAdjustmentItemInput = {
  AuditAdjustmentItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  createdTs?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  DocAmt: Scalars['Float'];
  Sequence?: Maybe<Scalars['Float']>;
};

export type AuditAdjustmentRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type AuditAdjustmentReturnData = {
  __typename?: 'AuditAdjustmentReturnData';
  Data?: Maybe<Array<AuditAdjustmentEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BaImportInput = {
  BankName?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  GLAccountCode?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type BpImportInput = {
  BankName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type BankAccDetail = {
  __typename?: 'BankAccDetail';
  AccountNo?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
};

export type BankAccountCancellationInput = {
  Remarks?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
};

export type BankAccountDeleteInput = {
  BankAccountID: Scalars['String'];
};

export type BankAccountInput = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankProfileID: Scalars['String'];
  Code: Scalars['String'];
  AccountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  EffectiveDate: Scalars['String'];
  CurrencyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Type?: Maybe<BankAccountType>;
  RecordStatus?: Maybe<RecordStatus>;
};

/** JsonType */
export type BankAccountReturnData = {
  __typename?: 'BankAccountReturnData';
  Data?: Maybe<Array<AccBankAccountEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum BankAccountType {
  Cash = 'CASH',
  Bank = 'BANK'
}

export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankProfileDeleteInput = {
  BankProfileID: Scalars['String'];
};

export type BankProfileEntity = AuditEntity & {
  __typename?: 'BankProfileEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankProfileID: Scalars['String'];
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
};

export type BankProfileInput = {
  BankProfileID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

export type BankReconciliationDeleteInput = {
  BankReconciliationID: Scalars['String'];
};

export type BankReconciliationEntity = AuditEntity & {
  __typename?: 'BankReconciliationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<AccBankAccountEntity>;
  DocumentDate?: Maybe<Scalars['String']>;
  Remarks: Scalars['String'];
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BankReconciliationInput = {
  BankReconciliationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  DocumentDate: Scalars['String'];
  Remarks: Scalars['String'];
  StatementBalance: Scalars['Float'];
  IsExcelMatch: Scalars['Boolean'];
};

export type BankReconciliationItemDeleteInput = {
  BankReconciliationItemID: Scalars['String'];
};

export type BankReconciliationItemEntity = AuditEntity & {
  __typename?: 'BankReconciliationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  BankReconciliation?: Maybe<BankReconciliationEntity>;
  Match?: Maybe<Array<Match>>;
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  DocumentDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  DocNo: Scalars['String'];
  Remarks: Scalars['String'];
  IsMatch: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BankReconciliationItemImportInput = {
  DocNo?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type BankReconciliationItemInput = {
  BankReconciliationItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  DocNo: Scalars['String'];
  DocumentDate: Scalars['String'];
  DocAmt: Scalars['Float'];
  Remarks: Scalars['String'];
  IsMatch: Scalars['Boolean'];
};

export type BankReconciliationMatchDeleteInput = {
  BankReconciliationMatchID: Scalars['String'];
};

export type BankReconciliationMatchEntity = AuditEntity & {
  __typename?: 'BankReconciliationMatchEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationMatchID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationID?: Maybe<Scalars['String']>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BankReconciliationMatchInput = {
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
};

export type BankTransferDeleteInput = {
  BankTransferID: Scalars['String'];
};

export type BankTransferEntity = AuditEntity & {
  __typename?: 'BankTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  BankTransferID: Scalars['String'];
  CompanyID: Scalars['String'];
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<AccBankAccountEntity>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  TransferDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  Amount: Scalars['Float'];
  LedgerID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  ToBankAccountDetail?: Maybe<BankAccDetail>;
  /** CustomFieldResolver */
  FromBankAccountDetail?: Maybe<BankAccDetail>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type BankTransferInput = {
  BankTransferID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  TransferDate?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type BankTransferRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

/** JsonType */
export type BillItemArg = {
  __typename?: 'BillItemArg';
  BillItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
};

export type BillItemDeleteInput = {
  BillItemID: Scalars['String'];
};

export type BillItemEntity = AuditEntity & {
  __typename?: 'BillItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BillItemID: Scalars['String'];
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  einv_integrated: Scalars['Boolean'];
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  BalanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  IsUsed?: Maybe<Scalars['Boolean']>;
};


export type BillItemEntityBalanceAmtArgs = {
  DebitID?: Maybe<Scalars['String']>;
};

export type BillItemInput = {
  BillItemID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  TaxRate?: Maybe<Scalars['Float']>;
  einv_integrated?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetDeleteInput = {
  BudgetID: Scalars['String'];
};

export type BudgetEntity = AuditEntity & {
  __typename?: 'BudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  BudgetID: Scalars['String'];
  CompanyID: Scalars['String'];
  Fyear?: Maybe<Scalars['Float']>;
  Version: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
};

export type BudgetInput = {
  BudgetID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Fyear?: Maybe<Scalars['Float']>;
  Version: Scalars['String'];
  Description: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
};

export type BudgetItemCostCentreDeleteInput = {
  BudgetItemCostCentreID: Scalars['String'];
};

export type BudgetItemCostCentreEntity = AuditEntity & {
  __typename?: 'BudgetItemCostCentreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemCostCentreID: Scalars['String'];
  BudgetItemID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
  BudgetItemPeriod?: Maybe<Array<BudgetItemPeriodEntity>>;
  BudgetItem?: Maybe<BudgetItemEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type BudgetItemCostCentreInput = {
  BudgetItemID?: Maybe<Scalars['String']>;
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
};

export type BudgetItemDeleteInput = {
  BudgetItemID: Scalars['String'];
};

export type BudgetItemEntity = AuditEntity & {
  __typename?: 'BudgetItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  BudgetID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  BudgetItemCostCentre?: Maybe<Array<BudgetItemCostCentreEntity>>;
  Budget?: Maybe<BudgetEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type BudgetItemInput = {
  BudgetItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BudgetID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
};

export type BudgetItemPeriodDeleteInput = {
  BudgetItemPeriodID: Scalars['String'];
};

export type BudgetItemPeriodEntity = AuditEntity & {
  __typename?: 'BudgetItemPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemPeriodID: Scalars['String'];
  BudgetItemCostCentreID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountPeriod?: Maybe<AccountPeriodEntity>;
  BudgetItemCostCentre?: Maybe<BudgetItemCostCentreEntity>;
};

export type BudgetItemPeriodInput = {
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  BudgetItemPeriodID?: Maybe<Scalars['String']>;
  BudgetAmt: Scalars['Float'];
  AccountPeriodID?: Maybe<Scalars['String']>;
};

export type BulkPurchasePriceDetailEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceDetailID: Scalars['String'];
  BulkPurchasePriceItemID: Scalars['String'];
  BulkPurchasePriceItem?: Maybe<BulkPurchasePriceItemEntity>;
  LowerLimit: Scalars['Float'];
  UpperLimit: Scalars['Float'];
  UnitPrice: Scalars['Float'];
};

export type BulkPurchasePriceDetailInput = {
  BulkPurchasePriceDetailID?: Maybe<Scalars['String']>;
  BulkPurchasePriceItemID?: Maybe<Scalars['String']>;
  LowerLimit?: Maybe<Scalars['Float']>;
  UpperLimit?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
};

export type BulkPurchasePriceHeaderEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  BulkPurchasePriceItem?: Maybe<Array<BulkPurchasePriceItemEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
};

export type BulkPurchasePriceHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type BulkPurchasePriceItemEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceItemID: Scalars['String'];
  BulkPurchasePriceHeaderID: Scalars['String'];
  BulkPurchasePriceHeader?: Maybe<BulkPurchasePriceHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  UOMID: Scalars['String'];
  BulkPurchasePriceDetail?: Maybe<Array<BulkPurchasePriceDetailEntity>>;
  UOM?: Maybe<AccUomEntity>;
};

export type BulkPurchasePriceItemInput = {
  BulkPurchasePriceItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  ItemID: Scalars['String'];
  BulkPurchaseDetail: Array<BulkPurchasePriceDetailInput>;
};

export type CbglExportEntity = AuditEntity & {
  __typename?: 'CBGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type CbImportLedgerInput = {
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  LedgerTypeName?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  PayeeReceiverName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type CnDocRefInputType = {
  CreditNoteID?: Maybe<Scalars['String']>;
  DocRefID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CoaImport = {
  __typename?: 'COAImport';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  Code?: Maybe<Scalars['String']>;
  ParentCode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['String']>;
  ErrorList?: Maybe<Array<Scalars['String']>>;
};

/** JsonType */
export type CoaImportFinal = {
  __typename?: 'COAImportFinal';
  ErrorData?: Maybe<Array<CoaImport>>;
  ValidatedData?: Maybe<Array<CoaImport>>;
};

export type CoaStructureDeleteInput = {
  COAStructureID: Scalars['String'];
};

export type CoaStructureEntity = AuditEntity & {
  __typename?: 'COAStructureEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  COAStructureID: Scalars['String'];
  Level: Scalars['Float'];
  CodeLength: Scalars['String'];
};

export type CoaStructureInput = {
  COAStructureID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  CodeLength: Scalars['String'];
};

export type CancelInput = {
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
};

export type CellValuesInput = {
  Coordinate: Scalars['String'];
  Value: Scalars['String'];
};

export type CellValuesJson = {
  __typename?: 'CellValuesJson';
  Coordinate: Scalars['String'];
  Value: Scalars['String'];
};

export type CheckExistingAccountPeriod = {
  __typename?: 'CheckExistingAccountPeriod';
  LastYear?: Maybe<Scalars['Boolean']>;
  LastMonth?: Maybe<Scalars['Boolean']>;
};

export enum ColumnSource {
  Actual = 'ACTUAL',
  Budget = 'BUDGET'
}

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

/** JsonType */
export type CompanyAuthorisationReturnType = {
  __typename?: 'CompanyAuthorisationReturnType';
  Data?: Maybe<Scalars['JSON']>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type CompanyDeleteInput = {
  CompanyID: Scalars['String'];
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency: CurrencyEntity;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactNo?: Maybe<Scalars['String']>;
  OfficeTel?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation: Scalars['Boolean'];
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation: Scalars['Boolean'];
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax: CompanyTax;
  TourismTaxNo?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvSecretKey?: Maybe<Scalars['String']>;
  eInvSecretKey2?: Maybe<Scalars['String']>;
  RecordStatus: Scalars['String'];
  companyMsicAssignment: Array<CompanyMsicAssignmentEntity>;
  /** CustomFieldResolver */
  Location?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  BankAccount?: Maybe<AccBankAccountEntity>;
  /** CustomFieldResolver */
  EntityCOA?: Maybe<EntityCoaEntity>;
  /** CustomFieldResolver */
  ParentCompany?: Maybe<CompanyInfo>;
  /** CustomFieldResolver */
  DefaultCostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Logo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  EInvoiceStarted?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  ItemCompanyDisallow?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  PendingUseGPItem?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  GLCompanyPolicy?: Maybe<GlCompanyPolicyEntity>;
};


export type CompanyEntityEInvoiceStartedArgs = {
  DocDate?: Maybe<Scalars['DateTime']>;
};


export type CompanyEntityItemCompanyDisallowArgs = {
  ItemID?: Maybe<Scalars['String']>;
};


export type CompanyEntityPendingUseGpItemArgs = {
  ItemID?: Maybe<Scalars['String']>;
};

export type CompanyImportInput = {
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  BaseCurrency?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  TourismTaxNo?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<CompanyTax>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  eInvIntegrated: Scalars['Boolean'];
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvSecretKey?: Maybe<Scalars['String']>;
  eInvSecretKey2?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  RecordStatus: Scalars['String'];
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  LogoFiles?: Maybe<Array<Scalars['Upload']>>;
};

export type CompanyLetterTemplateDeleteInput = {
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
};

export type CompanyLetterTemplateEntity = {
  __typename?: 'CompanyLetterTemplateEntity';
  CompanyLetterTemplateID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  StandardLetterID: Scalars['String'];
  LetterTemplateID: Scalars['String'];
  Name: Scalars['String'];
  TemplateBody?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type CompanyLetterTemplateInput = {
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  LetterTemplateID?: Maybe<Scalars['String']>;
};

export type CompanyMsicAssignmentEntity = AuditEntity & {
  __typename?: 'CompanyMsicAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  company: CompanyEntity;
  eInvoiceMsicCodeID: Scalars['String'];
  eInvoiceMsicCode: EInvoiceMsicEntity;
};

/** JsonType */
export type CompanyReturnType = {
  __typename?: 'CompanyReturnType';
  Data?: Maybe<Array<CompanyEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type ConsolidationDetailInput = {
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
};

export type ConsolidationDocTypeAllInput = {
  ConsolidationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<Scalars['String']>;
  consolidateItems: Array<ConsolidationDetailInput>;
};

export type ConsolidationInput = {
  ConsolidationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  AssociateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  DocRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CostCentreCoaEntity = AuditEntity & {
  __typename?: 'CostCentreCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  EntityCOAID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CostCentreDefSummary = {
  __typename?: 'CostCentreDefSummary';
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
  CostCentreDefinitionDetail?: Maybe<Array<CostCentreDefinitionDetailEntity>>;
};

export type CostCentreDefinitionAsgmtEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionAsgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionAsgmtID: Scalars['String'];
  CompanyID: Scalars['String'];
  CostCentreDefinitionHeaderID: Scalars['String'];
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type CostCentreDefinitionAsgmtInput = {
  CostCentreDefinitionAsgmtID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};

export type CostCentreDefinitionDetailDeleteInput = {
  CostCentreDefinitionDetailID: Scalars['String'];
};

export type CostCentreDefinitionDetailEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionDetailID: Scalars['String'];
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
};

export type CostCentreDefinitionDetailInput = {
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CostCentreDefinitionHeaderDeleteInput = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};

export type CostCentreDefinitionHeaderEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionHeaderID: Scalars['String'];
  Title: Scalars['String'];
  CostCentreDefConnection?: Maybe<Array<CostCentreDefinitionAsgmtEntity>>;
  CostCentreDefinitionDetail?: Maybe<Array<CostCentreDefinitionDetailEntity>>;
};

export type CostCentreDefinitionHeaderInput = {
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};

export type CostCentreDeleteInput = {
  CostCentreID: Scalars['String'];
};

export type CostCentreEntity = AuditEntity & {
  __typename?: 'CostCentreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreID: Scalars['String'];
  ParentCostCentreID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  IsDefault: Scalars['Boolean'];
  Code?: Maybe<Scalars['String']>;
  MasterCode?: Maybe<Scalars['String']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  BudgetItemCostCentre?: Maybe<Array<BudgetItemCostCentreEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  ARCreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  APCreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  Warehouse?: Maybe<Array<WarehouseEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockTransfer?: Maybe<Array<StockTransferEntity>>;
  StockTransferRequisition?: Maybe<Array<StockTransferRequisitionEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  FavoriteStockRequisition?: Maybe<Array<FavoriteStockRequisitionEntity>>;
  FavoriteStockTransferRequisition?: Maybe<Array<FavoriteStockTransferRequisitionEntity>>;
  CostCentreCOA?: Maybe<Array<CostCentreCoaEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  UsedDepartment?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  FirstCostCentre?: Maybe<CostCentreEntity>;
};

export type CostCentreInput = {
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  MasterCode?: Maybe<Scalars['String']>;
  IsLastNode: Scalars['Boolean'];
};

export type CostCentreTypeDeleteInput = {
  CostCentreTypeID: Scalars['String'];
};

export type CostCentreTypeEntity = AuditEntity & {
  __typename?: 'CostCentreTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreTypeID: Scalars['String'];
  Name: Scalars['String'];
};

export type CostCentreTypeInput = {
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreditCardTypeDeleteInput = {
  CreditCardID: Scalars['String'];
};

export type CreditCardTypeEntity = AuditEntity & {
  __typename?: 'CreditCardTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditCardID: Scalars['String'];
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  FavoritePayment?: Maybe<Array<FavoritePaymentEntity>>;
  FavoriteReceipt?: Maybe<Array<FavoriteReceiptEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APAdvance?: Maybe<Array<AccArAdvanceEntity>>;
};

export type CreditCardTypeInput = {
  CreditCardID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type CreditorAccountCompanyAssignmentDeleteInput = {
  CreditorAccountCompanyAssignmentID: Scalars['String'];
};

export type CreditorAccountCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CreditorAccountCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorAccountCompanyAssignmentID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorAccountCompanyAssignmentInput = {
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

/** JsonType */
export type CreditorAccountCompanyAssignmentReturnData = {
  __typename?: 'CreditorAccountCompanyAssignmentReturnData';
  Data?: Maybe<Array<CreditorAccountCompanyAssignmentEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type CreditorAccountDeleteInput = {
  CreditorAccountID: Scalars['String'];
};

export type CreditorAccountEntity = AuditEntity & {
  __typename?: 'CreditorAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorAccountID: Scalars['String'];
  CreditorTypeID: Scalars['String'];
  CreditorType?: Maybe<CreditorTypeEntity>;
  CompanyRegNo: Scalars['String'];
  CompanyName: Scalars['String'];
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  IsCompany: Scalars['Boolean'];
  einvoice_foreign_supplier: Scalars['Boolean'];
  einvoice_self_billed: Scalars['Boolean'];
  einvoice_TIN?: Maybe<Scalars['String']>;
  einvoice_type: EInvoiceType;
  einvoice_send_individually: Scalars['Boolean'];
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  CreditorTypeEnum: CreditorType;
  CreditorStatus: CreditorStatus;
  Advance?: Maybe<Array<AccApAdvanceEntity>>;
  Invoice?: Maybe<Array<AccApInvoiceEntity>>;
  CreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  CreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  DebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  SelfBilled?: Maybe<Array<AccApSelfBilledEntity>>;
  CreditorDebitNote?: Maybe<Array<AccApCreditorDebitNoteEntity>>;
  Refund?: Maybe<Array<AccApRefundEntity>>;
  Payment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  FavInvoice?: Maybe<Array<AccApInvoiceEntity>>;
  CreditorContact?: Maybe<Array<CreditorContactEntity>>;
  CreditorDirector?: Maybe<Array<CreditorDirectorEntity>>;
  CreditorAccountCompanyAssignment?: Maybe<Array<CreditorAccountCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  DocNoList?: Maybe<Array<Scalars['String']>>;
  /** CustomFieldResolver */
  TotalOutstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ClassificationCode?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  MSICCode?: Maybe<Scalars['JSON']>;
};


export type CreditorAccountEntityTotalOutstandingAmtArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type CreditorAccountInput = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID: Scalars['String'];
  CompanyRegNo: Scalars['String'];
  CompanyName: Scalars['String'];
  IsCompany?: Maybe<Scalars['Boolean']>;
  ContactNo?: Maybe<Scalars['String']>;
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  einvoice_TIN?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  einvoice_foreign_supplier?: Maybe<Scalars['Boolean']>;
  einvoice_self_billed?: Maybe<Scalars['Boolean']>;
  einvoice_send_individually?: Maybe<Scalars['Boolean']>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_classification_code_id?: Maybe<Scalars['String']>;
  CreditorTypeEnum: CreditorType;
  einvoice_type: EInvoiceType;
  CreditorStatus: CreditorStatus;
  Address?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type CreditorAccountReturnData = {
  __typename?: 'CreditorAccountReturnData';
  Data?: Maybe<Scalars['JSON']>;
  TotalLength?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type CreditorArg = {
  __typename?: 'CreditorArg';
  CreditorAccountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CreditorContactDeleteInput = {
  CreditorContactID: Scalars['String'];
};

export type CreditorContactEntity = AuditEntity & {
  __typename?: 'CreditorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorContactID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorContactInput = {
  CreditorContactID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

/** JsonType */
export type CreditorDebtorSubInput = {
  __typename?: 'CreditorDebtorSubInput';
  AdvanceTotalDocAmt?: Maybe<Scalars['Float']>;
  CreditNoteTotalDocAmt?: Maybe<Scalars['Float']>;
  DebitNoteTotalDocAmt?: Maybe<Scalars['Float']>;
  InvoiceTotalDocAmt?: Maybe<Scalars['Float']>;
};

export type CreditorDirectorDeleteInput = {
  CreditorDirectorID: Scalars['String'];
};

export type CreditorDirectorEntity = AuditEntity & {
  __typename?: 'CreditorDirectorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorDirectorID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  IdentityNo: Scalars['String'];
  IdentityType?: Maybe<IdentityType>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Email: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorDirectorInput = {
  CreditorDirectorID?: Maybe<Scalars['String']>;
  IdentityNo: Scalars['String'];
  IdentityType?: Maybe<IdentityType>;
  Name?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CreditorInput = {
  __typename?: 'CreditorInput';
  CreditorAccountID?: Maybe<Scalars['String']>;
  TotalDocAmount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CreditorLatestUpdatedDateInput = {
  __typename?: 'CreditorLatestUpdatedDateInput';
  CreditorAccountID?: Maybe<Scalars['String']>;
  LatestUpdatedDate?: Maybe<Scalars['DateTime']>;
};

export type CreditorProfileImportInput = {
  CreditorCategory?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CreditorProfileType = {
  __typename?: 'CreditorProfileType';
  Data?: Maybe<Array<CreditorAccountEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum CreditorStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export enum CreditorType {
  Supplier = 'SUPPLIER',
  Contractor = 'CONTRACTOR',
  Consultant = 'CONSULTANT',
  Agent = 'AGENT'
}

export type CreditorTypeDeleteInput = {
  CreditorTypeID: Scalars['String'];
};

export type CreditorTypeEntity = AuditEntity & {
  __typename?: 'CreditorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorTypeID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  CreditorType: CreditorType;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  AccrualCOAID?: Maybe<Scalars['String']>;
  AccrualCOA?: Maybe<MasterCoaEntity>;
  AdvanceCOAID?: Maybe<Scalars['String']>;
  AdvanceCOA?: Maybe<MasterCoaEntity>;
  SelfBilledCOAID?: Maybe<Scalars['String']>;
  SelfBilledCOA?: Maybe<MasterCoaEntity>;
  CreditorAccount?: Maybe<Array<CreditorAccountEntity>>;
};

export type CreditorTypeInput = {
  CreditorTypeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  AccrualCOAID?: Maybe<Scalars['String']>;
  AdvanceCOAID?: Maybe<Scalars['String']>;
  SelfBilledCOAID?: Maybe<Scalars['String']>;
  CreditorType: CreditorType;
};

export type CurrencyDeleteInput = {
  CurrencyID: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  format: NumericFormatter;
  commonStatus: CommonStatus;
  company: Array<CompanyEntity>;
};

export type CurrencyExchangeDeleteInput = {
  CurrencyExchangeID: Scalars['String'];
};

export type CurrencyExchangeInput = {
  CurrencyExchangeID?: Maybe<Scalars['String']>;
  FromCurrencyID: Scalars['String'];
  ToCurrencyID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['String']>;
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  Symbol?: Maybe<Scalars['String']>;
  CurrencyLog?: Maybe<Array<CurrencyLogInput>>;
};

export type CurrencyInput = {
  CurrencyID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
};

export type CurrencyLog = {
  __typename?: 'CurrencyLog';
  PrevFromAmt: Scalars['Float'];
  PrevToAmt: Scalars['Float'];
  ModTs: Scalars['DateTime'];
  ModBy: Scalars['DateTime'];
};

export type CurrencyLogInput = {
  PrevFromAmt: Scalars['Float'];
  PrevToAmt: Scalars['Float'];
  ModTs: Scalars['DateTime'];
  ModBy: Scalars['DateTime'];
};

export type CycleCountDeleteInput = {
  CycleCountID: Scalars['String'];
};

export type CycleCountEntity = AuditEntity & {
  __typename?: 'CycleCountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CycleCountID: Scalars['String'];
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type CycleCountInput = {
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type CycleCountItemDeleteInput = {
  CycleCountItemID: Scalars['String'];
};

export type CycleCountItemEntity = AuditEntity & {
  __typename?: 'CycleCountItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CycleCountItemID: Scalars['String'];
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  IsReconciled: Scalars['Boolean'];
  CompanyID?: Maybe<Scalars['String']>;
  CycleCountID: Scalars['String'];
  CycleCount?: Maybe<CycleCountEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type CycleCountItemInput = {
  CycleCountItemID?: Maybe<Scalars['String']>;
  CycleCountID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
};

export type CycleCountRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CycleCountType = {
  __typename?: 'CycleCountType';
  Data: Array<CycleCountEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type DoHeaderEntity = AuditEntity & {
  __typename?: 'DOHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DOHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DoNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DOItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  UpToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  PurchaseOrder?: Maybe<Array<Scalars['JSON']>>;
};


export type DoHeaderEntityUpToDateAmtArgs = {
  TaxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  DoNo: Scalars['String'];
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  ContactPerson?: Maybe<ContactPersonInput>;
  DeliveryAddress?: Maybe<AddressInput>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DOItemID: Scalars['String'];
  DOHeaderID: Scalars['String'];
  DOHeader?: Maybe<DoHeaderEntity>;
  POItemID?: Maybe<Scalars['String']>;
  POItem?: Maybe<PoItemEntity>;
  GRTNItemID?: Maybe<Scalars['String']>;
  ReplacedGRTNItem?: Maybe<GrtnItemEntity>;
  AcceptedQty: Scalars['Float'];
  DeliveredQty?: Maybe<Scalars['Float']>;
  AcceptedAmt?: Maybe<Scalars['Float']>;
  DeliveredAmt?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  GRTNItem?: Maybe<Array<GrtnItemEntity>>;
  /** CustomFieldResolver */
  UpToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ReturnedQty?: Maybe<Scalars['Float']>;
};

export type DoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  POItemsInput: Array<PoItemsInput>;
};

export type DoRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type DoReturnData = {
  __typename?: 'DOReturnData';
  Data?: Maybe<Array<DoHeaderEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type DOsInput = {
  PurchaseOrderID: Scalars['String'];
  POItemID: Scalars['String'];
  ReceivedQty: Scalars['Float'];
};

export type DataSource = {
  __typename?: 'DataSource';
  DataSource: Scalars['String'];
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  DynamicFilterColumn?: Maybe<Scalars['String']>;
};

export type DataSourceInput = {
  DataSource: Scalars['String'];
  Columns?: Maybe<Array<Scalars['String']>>;
  DataMember: Scalars['String'];
  IsStoreProcedure?: Maybe<Scalars['Boolean']>;
  IsMainQuery?: Maybe<Scalars['Boolean']>;
  DynamicFilterColumn?: Maybe<Scalars['String']>;
};

export type DataSourceSettingsDeleteInput = {
  DataSourceSettingsID: Scalars['String'];
};

export type DataSourceSettingsEntity = AuditEntity & {
  __typename?: 'DataSourceSettingsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DataSourceSettingsID: Scalars['String'];
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ColumnSource: ColumnSource;
  ColumnValue: AmountType;
  YearType: YearPeriodType;
  YearValue: Scalars['Float'];
  PeriodType?: Maybe<YearPeriodType>;
  PeriodValue?: Maybe<Scalars['Float']>;
  Duration: DurationType;
};

export type DataSourceSettingsInput = {
  DataSourceSettingsID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ColumnSource?: Maybe<ColumnSource>;
  ColumnValue?: Maybe<AmountType>;
  YearType?: Maybe<YearPeriodType>;
  PeriodType?: Maybe<YearPeriodType>;
  YearValue?: Maybe<Scalars['Float']>;
  PeriodValue?: Maybe<Scalars['Float']>;
  Duration?: Maybe<Scalars['String']>;
};


export type DebtorAccountDeleteInput = {
  DebtorAccountID: Scalars['String'];
};

export type DebtorAccountEntity = AuditEntity & {
  __typename?: 'DebtorAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorAccountID: Scalars['String'];
  DebtorTypeID: Scalars['String'];
  DebtorType?: Maybe<DebtorTypeEntity>;
  CompanyID: Scalars['String'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CompanyRegNo: Scalars['String'];
  DebtorName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  Amount?: Maybe<Scalars['Float']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  IsVIP: Scalars['Boolean'];
  IsCompany: Scalars['Boolean'];
  Remark?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  einvoice_TIN?: Maybe<Scalars['String']>;
  einvoice_type: EInvoiceType;
  einvoice_send_individually: Scalars['Boolean'];
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  Advance?: Maybe<Array<AccArAdvanceEntity>>;
  Invoice?: Maybe<Array<AccArInvoiceEntity>>;
  FavInvoice?: Maybe<Array<AccArFavoriteInvoiceEntity>>;
  CreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  DebitNote?: Maybe<Array<AccArDebitNoteEntity>>;
  Receipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  Refund?: Maybe<Array<AccArRefundEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  TotalOSAmt?: Maybe<Scalars['Float']>;
};

export type DebtorAccountImportInput = {
  DebtorType?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['String']>;
  IsCompany?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type DebtorAccountInput = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  DebtorTypeID: Scalars['String'];
  CompanyRegNo: Scalars['String'];
  DebtorName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  IsCompany?: Maybe<Scalars['Boolean']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  SSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  einvoice_TIN?: Maybe<Scalars['String']>;
  einvoice_type?: Maybe<EInvoiceType>;
  einvoice_send_individually?: Maybe<Scalars['Boolean']>;
  Address?: Maybe<Scalars['JSON']>;
};

export type DebtorContactDeleteInput = {
  DebtorContactID: Scalars['String'];
};

export type DebtorContactEntity = AuditEntity & {
  __typename?: 'DebtorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorContactID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
};

export type DebtorContactInput = {
  DebtorContactID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
};

/** JsonType */
export type DebtorInput = {
  __typename?: 'DebtorInput';
  DebtorAccountID?: Maybe<Scalars['String']>;
  TotalDocAmount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type DebtorLatestUpdatedDateInput = {
  __typename?: 'DebtorLatestUpdatedDateInput';
  DebtorAccountID?: Maybe<Scalars['String']>;
  LatestUpdatedDate?: Maybe<Scalars['DateTime']>;
};

export type DebtorTypeDeleteInput = {
  DebtorTypeID: Scalars['String'];
};

export type DebtorTypeEntity = AuditEntity & {
  __typename?: 'DebtorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorTypeID: Scalars['String'];
  Name: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
};

export type DebtorTypeInput = {
  DebtorTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type DefaultUomEntity = AuditEntity & {
  __typename?: 'DefaultUomEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
};

export type DeliveryLocationDeleteInput = {
  DeliveryLocationID: Scalars['String'];
};

export type DeliveryLocationEntity = AuditEntity & {
  __typename?: 'DeliveryLocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DeliveryLocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['String']>;
  MainLocation: Scalars['Boolean'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type DeliveryLocationInput = {
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['String']>;
  MainLocation?: Maybe<Scalars['Boolean']>;
};

export type DirectPoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  Instruction?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
  POItems: Array<PoItemInput>;
  CreditTerm?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type DocDateInput = {
  __typename?: 'DocDateInput';
  DocDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type DocNoInfo = {
  __typename?: 'DocNoInfo';
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  OriDocNo?: Maybe<Scalars['String']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type DocRefInputType = {
  DebitNoteID?: Maybe<Scalars['String']>;
  SelfBilledID?: Maybe<Scalars['String']>;
  DocRefID?: Maybe<Scalars['String']>;
};

export type DocumentDateValidationDeleteInput = {
  DocumentDateValidationID: Scalars['String'];
};

export type DocumentDateValidationEntity = AuditEntity & {
  __typename?: 'DocumentDateValidationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentDateValidationID: Scalars['String'];
  MonthsBefore: Scalars['Float'];
  MonthsAfter: Scalars['Float'];
};

export type DocumentDateValidationInput = {
  DocumentDateValidationID?: Maybe<Scalars['String']>;
  MonthsBefore?: Maybe<Scalars['Float']>;
  MonthsAfter?: Maybe<Scalars['Float']>;
};

export type DocumentEntity = AuditEntity & {
  __typename?: 'DocumentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  DocumentFolderID?: Maybe<Scalars['String']>;
  DocumentFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  DocumentFolderID: Scalars['String'];
  AccountID: Scalars['String'];
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<Array<DocumentEntity>>;
};

export type DocumentFolderInput = {
  DocumentFolderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
};

export type DocumentNumberDetailDeleteInput = {
  DocumentNumberDetailID: Scalars['String'];
};

export type DocumentNumberDetailEntity = AuditEntity & {
  __typename?: 'DocumentNumberDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberDetailID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  ResetBy?: Maybe<Scalars['String']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  ResetBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberHeaderDeleteInput = {
  DocumentNumberHeaderID: Scalars['String'];
};

export type DocumentNumberHeaderEntity = AuditEntity & {
  __typename?: 'DocumentNumberHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  Type: DocumentType;
  SampleOutput: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
  DocumentNumberResetBy?: Maybe<Array<DocumentNumberResetByEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  SampleOutput: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
};

export type DocumentNumberResetByEntity = AuditEntity & {
  __typename?: 'DocumentNumberResetByEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberResetByID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  NextNumber?: Maybe<Scalars['Float']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export enum DocumentType {
  JournalProcessing = 'JOURNAL_PROCESSING',
  ExternalJournal = 'EXTERNAL_JOURNAL',
  RecurringJournal = 'RECURRING_JOURNAL',
  AuditAdjustment = 'AUDIT_ADJUSTMENT',
  Payment = 'PAYMENT',
  PaymentAp = 'PAYMENT_AP',
  PaymentCb = 'PAYMENT_CB',
  ReceiptCb = 'RECEIPT_CB',
  Receipt = 'RECEIPT',
  BankTransfer = 'BANK_TRANSFER',
  Advance = 'ADVANCE',
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  Refund = 'REFUND',
  SelfBilled = 'SELF_BILLED',
  ConsolidateEinvoice = 'CONSOLIDATE_EINVOICE',
  ConsolidateSelfBilled = 'CONSOLIDATE_SELF_BILLED',
  OfficialReceipt = 'OFFICIAL_RECEIPT',
  Interest = 'INTEREST',
  StockReceipt = 'STOCK_RECEIPT',
  StockIssue = 'STOCK_ISSUE',
  StockTransfer = 'STOCK_TRANSFER',
  CycleCounting = 'CYCLE_COUNTING',
  StockAdjustment = 'STOCK_ADJUSTMENT',
  StockCostAdjustment = 'STOCK_COST_ADJUSTMENT',
  StockRequisition = 'STOCK_REQUISITION',
  StockTransferRequisition = 'STOCK_TRANSFER_REQUISITION',
  PurchaseRequisition = 'PURCHASE_REQUISITION',
  RequestForQuotation = 'REQUEST_FOR_QUOTATION',
  PurchaseOrder = 'PURCHASE_ORDER',
  GoodReceiveNote = 'GOOD_RECEIVE_NOTE',
  GoodReturnNote = 'GOOD_RETURN_NOTE',
  Employee = 'EMPLOYEE'
}

export type DropDownDataSource = {
  __typename?: 'DropDownDataSource';
  DataSource: Scalars['String'];
  DataMember: Scalars['String'];
  parameters: Array<DropDownParamterInterface>;
};

export type DropDownDataSourceInput = {
  DataSource: Scalars['String'];
  DataMember: Scalars['String'];
  parameters: Array<DropDownParamterInterfaceInput>;
};

export type DropDownMember = {
  __typename?: 'DropDownMember';
  ValueSource: Scalars['String'];
  DynamicSource?: Maybe<DynamicSource>;
  StaticData?: Maybe<Array<StaticData>>;
};

export type DropDownMemberInput = {
  ValueSource: Scalars['String'];
  DynamicSource?: Maybe<DynamicSourceInput>;
  StaticData?: Maybe<Array<StaticDataInput>>;
};

export type DropDownParamterInterface = {
  __typename?: 'DropDownParamterInterface';
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export type DropDownParamterInterfaceInput = {
  Name: Scalars['String'];
  ParameterName: Scalars['String'];
};

export enum DurationType {
  Year = 'YEAR',
  Month = 'MONTH'
}

export type DynamicSource = {
  __typename?: 'DynamicSource';
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
  DataSource: DropDownDataSource;
};

export type DynamicSourceInput = {
  DisplayMember: Scalars['String'];
  ValueMember: Scalars['String'];
  DataSource: DropDownDataSourceInput;
};

export enum EInvoiceTrxType {
  SelfBilledInvoice = 'SELF_BILLED_INVOICE',
  SelfBilledCreditNote = 'SELF_BILLED_CREDIT_NOTE',
  SelfBilledDebitNote = 'SELF_BILLED_DEBIT_NOTE',
  SelfBilledRefundNote = 'SELF_BILLED_REFUND_NOTE',
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  RefundNote = 'REFUND_NOTE',
  All = 'ALL'
}

export enum EInvoiceType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Foreigner = 'FOREIGNER',
  Government = 'GOVERNMENT'
}

export type EinvoiceConsolidationDetailEntity = {
  __typename?: 'EinvoiceConsolidationDetailEntity';
  Consolidation_DetailID: Scalars['String'];
  AccountID: Scalars['String'];
  ConsolidationID: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  Amount: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  eInvoiceConsolidate?: Maybe<EinvoiceConsolidationEntity>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  DocType?: Maybe<Scalars['String']>;
  CreditorDebtorName?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  APDocNo?: Maybe<Scalars['JSON']>;
  SB?: Maybe<Scalars['JSON']>;
};

export type EinvoiceConsolidationEntity = AuditEntity & {
  __typename?: 'EinvoiceConsolidationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ConsolidationID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  Amount: Scalars['Float'];
  DocAmt: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  DocDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  eInvoiceConsolidationDetail?: Maybe<Array<EinvoiceConsolidationDetailEntity>>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceTrxType>;
  einvoice_msic_code_id?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_signature_value?: Maybe<Scalars['String']>;
  einvoice_signing_time?: Maybe<Scalars['DateTime']>;
  einvoice_cert_issuer?: Maybe<Scalars['String']>;
  einvoice_cert_serial_no?: Maybe<Scalars['String']>;
  einvoice_doc_digest?: Maybe<Scalars['String']>;
  einvoice_props_digest?: Maybe<Scalars['String']>;
  einvoice_cert_digest?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  einvoice?: Maybe<Scalars['JSON']>;
};

export type EntityCoaDeleteInput = {
  EntityCOAID: Scalars['String'];
};

export type EntityCoaEntity = AuditEntity & {
  __typename?: 'EntityCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  EntityCOAID: Scalars['String'];
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type EntityCoaInput = {
  EntityCOAID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  roleID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ExcelJournalInput = {
  Obj?: Maybe<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExpenseClassDeleteInput = {
  ExpenseClassID: Scalars['String'];
};

export type ExpenseClassEntity = AuditEntity & {
  __typename?: 'ExpenseClassEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExpenseClassID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  Name: Scalars['String'];
  ParentExpenseClassID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  ItemClassCompanyAssignment?: Maybe<Array<ItemClassCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  ParentClassMCOA?: Maybe<Scalars['JSON']>;
};

export type ExpenseClassInput = {
  ExpenseClassID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentExpenseClassID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};

export type ExpenseItemDeleteInput = {
  ExpenseItemID: Scalars['String'];
};

export type ExpenseItemEntity = AuditEntity & {
  __typename?: 'ExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExpenseItemID: Scalars['String'];
  ItemName: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  ExpenseClassID: Scalars['String'];
  ExpenseClass?: Maybe<ExpenseClassEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
  APDebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  APCreditorDebitNote?: Maybe<Array<AccApCreditorDebitNoteEntity>>;
  /** CustomFieldResolver */
  UsedExpenseItem?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  IsInventory?: Maybe<Scalars['Boolean']>;
};

export type ExpenseItemInput = {
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  ItemName: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

/** JsonType */
export type ExportDotNetReturn = {
  __typename?: 'ExportDotNetReturn';
  Success?: Maybe<Scalars['Float']>;
  Fail?: Maybe<Scalars['Float']>;
  GenerateExportDotNet?: Maybe<Scalars['Boolean']>;
};

export type ExternalJournalDeleteInput = {
  ExternalJournalID: Scalars['String'];
};

export type ExternalJournalEntity = AuditEntity & {
  __typename?: 'ExternalJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  ExternalJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  JournalType?: Maybe<JournalTypeEntity>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  IsManual: Scalars['Boolean'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  AccountName?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  DocRefName: Scalars['String'];
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  ItemCount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ItemAmount?: Maybe<Scalars['Float']>;
};

export type ExternalJournalImportInput = {
  JournalDate?: Maybe<Scalars['DateTime']>;
  JournalType?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  JournalDescription?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ExternalJournalInput = {
  JournalID?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  CostCentreCode?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type ExternalJournalItemEntity = AuditEntity & {
  __typename?: 'ExternalJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExternalJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  ExternalJournalID: Scalars['String'];
  ExternalJournal?: Maybe<ExternalJournalEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ExternalJournalType = {
  __typename?: 'ExternalJournalType';
  Data?: Maybe<Array<ExternalJournalEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type ExternalLedgerDeleteInput = {
  ExternalLedgerID: Scalars['String'];
};

export type ExternalLedgerEntity = AuditEntity & {
  __typename?: 'ExternalLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  ExternalLedgerID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  TrxType?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  RefTable: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  IsManual: Scalars['Boolean'];
  IsMatch: Scalars['Boolean'];
  LedgerType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Currency?: Maybe<CurrencyEntity>;
};

export type ExternalLedgerImportInput = {
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  PayeeReceiverName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ExternalLedgerInput = {
  ExternalLedgerID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  DocNo: Scalars['String'];
  DocDate: Scalars['String'];
  TrxType: Scalars['String'];
  RefNo: Scalars['String'];
  CurrencyID: Scalars['String'];
  ExchangeRate: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  DocAmt: Scalars['Float'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefTable: Scalars['String'];
  PayeeName: Scalars['String'];
  ReceivedFrom: Scalars['String'];
};

export type FavoriteApproverDeleteInput = {
  FavoriteApproverID: Scalars['String'];
};

export type FavoriteApproverEntity = AuditEntity & {
  __typename?: 'FavoriteApproverEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteApproverID: Scalars['String'];
  CompanyID: Scalars['String'];
  UserID: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
};

export type FavoriteApproverInput = {
  FavoriteApproverID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<FavoriteApproverListJsonInput>>;
};

export type FavoriteApproverListJson = {
  __typename?: 'FavoriteApproverListJson';
  RoleID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type FavoriteApproverListJsonInput = {
  RoleID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type FavoriteJournalProcessingDeleteInput = {
  FavoriteJournalProcessingID: Scalars['String'];
};

export type FavoriteJournalProcessingEntity = AuditEntity & {
  __typename?: 'FavoriteJournalProcessingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteJournalProcessingID: Scalars['String'];
  UserID: Scalars['String'];
  JournalTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type FavoriteJournalProcessingInput = {
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type FavoriteJournalProcessingItemDeleteInput = {
  FavoriteJournalProcessingItemID: Scalars['String'];
};

export type FavoriteJournalProcessingItemEntity = AuditEntity & {
  __typename?: 'FavoriteJournalProcessingItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteJournalProcessingItemID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  FavoriteJournalProcessingID: Scalars['String'];
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  FavoriteJournalProcessing?: Maybe<FavoriteJournalProcessingEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type FavoriteJournalProcessingItemInput = {
  FavoriteJournalProcessingItemID?: Maybe<Scalars['String']>;
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};

export type FavoritePrDeleteInput = {
  FavoritePRHeaderID: Scalars['String'];
};

export type FavoritePrEntity = AuditEntity & {
  __typename?: 'FavoritePREntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  FavoritePRHeaderID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  FavoritePRItem?: Maybe<Array<FavoritePrItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type FavoritePrInput = {
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoritePrItemDeleteInput = {
  FavoritePRItemID: Scalars['String'];
};

export type FavoritePrItemEntity = AuditEntity & {
  __typename?: 'FavoritePRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoritePRItemID: Scalars['String'];
  FavoritePRHeaderID: Scalars['String'];
  ItemID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  Quantity: Scalars['Float'];
  OutstandingQty?: Maybe<Scalars['Float']>;
  POItemPricingID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsPOGenerated: Scalars['Boolean'];
  FavoritePRHeader?: Maybe<FavoritePrEntity>;
  Item?: Maybe<ItemEntity>;
  POItemPricing?: Maybe<PoItemPricingEntity>;
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
};

export type FavoritePrItemInput = {
  FavoritePRItemID?: Maybe<Scalars['String']>;
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoritePaymentDeleteInput = {
  FavoritePaymentID: Scalars['String'];
};

export type FavoritePaymentEntity = AuditEntity & {
  __typename?: 'FavoritePaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoritePaymentID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
};

export type FavoritePaymentInput = {
  FavoritePaymentID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
};

export type FavoritePaymentItemDeleteInput = {
  FavoritePaymentItemID: Scalars['String'];
};

export type FavoritePaymentItemEntity = AuditEntity & {
  __typename?: 'FavoritePaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoritePaymentItemID: Scalars['String'];
  FavoritePaymentID: Scalars['String'];
  FavoritePayment?: Maybe<FavoritePaymentEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
};

export type FavoritePaymentItemInput = {
  FavoritePaymentItemID?: Maybe<Scalars['String']>;
  FavoritePaymentID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type FavoriteReceiptDeleteInput = {
  FavoriteReceiptID: Scalars['String'];
};

export type FavoriteReceiptEntity = AuditEntity & {
  __typename?: 'FavoriteReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteReceiptID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
};

export type FavoriteReceiptInput = {
  FavoriteReceiptID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
};

export type FavoriteReceiptItemDeleteInput = {
  FavoriteReceiptItemID: Scalars['String'];
};

export type FavoriteReceiptItemEntity = AuditEntity & {
  __typename?: 'FavoriteReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoriteReceiptItemID: Scalars['String'];
  FavoriteReceiptID: Scalars['String'];
  FavoriteReceipt?: Maybe<FavoriteReceiptEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type FavoriteReceiptItemInput = {
  FavoriteReceiptItemID?: Maybe<Scalars['String']>;
  FavoriteReceiptID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoriteStockRequisitionDeleteInput = {
  FavoriteStockRequisitionID: Scalars['String'];
};

export type FavoriteStockRequisitionEntity = AuditEntity & {
  __typename?: 'FavoriteStockRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteStockRequisitionID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
};

export type FavoriteStockRequisitionInput = {
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type FavoriteStockRequisitionItemDeleteInput = {
  FavoriteStockRequisitionItemID: Scalars['String'];
};

export type FavoriteStockRequisitionItemEntity = AuditEntity & {
  __typename?: 'FavoriteStockRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteStockRequisitionItemID: Scalars['String'];
  FavoriteStockRequisitionID: Scalars['String'];
  StockItemID: Scalars['String'];
  UOMID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  FavoriteStockRequisition?: Maybe<FavoriteStockRequisitionEntity>;
  StockItem?: Maybe<StockItemEntity>;
  UOM?: Maybe<AccUomEntity>;
  TransactionType?: Maybe<TransactionTypeEntity>;
};

export type FavoriteStockRequisitionItemInput = {
  FavoriteStockRequisitionItemID?: Maybe<Scalars['String']>;
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type FavoriteStockTransferRequisitionDeleteInput = {
  FavoriteStockTransferRequisitionID: Scalars['String'];
};

export type FavoriteStockTransferRequisitionEntity = AuditEntity & {
  __typename?: 'FavoriteStockTransferRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteStockTransferRequisitionID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FromWarehouseID: Scalars['String'];
  ToWarehouseID: Scalars['String'];
  RequestDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  FavoriteStockTransferRequisitionItem?: Maybe<Array<FavoriteStockTransferRequisitionItemEntity>>;
};

export type FavoriteStockTransferRequisitionInput = {
  FavoriteStockTransferRequisitionID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};

export type FavoriteStockTransferRequisitionItemDeleteInput = {
  FavoriteStockTransferRequisitionItemID: Scalars['String'];
};

export type FavoriteStockTransferRequisitionItemEntity = AuditEntity & {
  __typename?: 'FavoriteStockTransferRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteStockTransferRequisitionItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteStockTransferRequisitionID: Scalars['String'];
  FavoriteStockTransferRequisition?: Maybe<FavoriteStockTransferRequisitionEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
};

export type FavoriteStockTransferRequisitionItemInput = {
  FavoriteStockTransferRequisitionItemID?: Maybe<Scalars['String']>;
  FavoriteStockTransferRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ReasonID?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
};

export type GdoActionInput = {
  DOHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
};

export type GlCompanyPolicyEntity = {
  __typename?: 'GLCompanyPolicyEntity';
  GLCompanyPolicyID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultDepartmentBalanceSheetCOA?: Maybe<Scalars['Boolean']>;
};

export type GlCompanyPolicyInput = {
  GLCompanyPolicyID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DefaultDepartmentBalanceSheetCOA: Scalars['Boolean'];
};

export type GlExportDotNetDeleteInput = {
  ExportDotNetID: Scalars['String'];
};

export type GlExportDotNetEntity = AuditEntity & {
  __typename?: 'GLExportDotNetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ExportDotNetID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  ExportDate?: Maybe<Scalars['String']>;
  Journal: Array<JournalEntity>;
};

export type GlExportDotNetInput = {
  ExportDotNetID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
};

export type GlExportDotNetRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type GlExportInput = {
  AccountCode?: Maybe<Scalars['String']>;
  GLLedgerID?: Maybe<Scalars['String']>;
  GLLedgerTable?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  TrxDesc?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['Float']>;
  Debit?: Maybe<Scalars['Float']>;
};

export type GlImportLedgerInput = {
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type GlJournalItem = AuditEntity & {
  __typename?: 'GLJournalItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  Journal?: Maybe<JournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  AccountCode?: Maybe<Scalars['String']>;
  JournalNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreCode?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  FPeriod?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  DebitAmount?: Maybe<Scalars['String']>;
  CreditAmount?: Maybe<Scalars['String']>;
};

export type Glmfrs16AccountMappingEntity = {
  __typename?: 'GLMFRS16AccountMappingEntity';
  AccountMappingID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  ROUMasterCOAID: Scalars['String'];
  LeaseLiabilityMasterCOAID: Scalars['String'];
  RecurringROUMasterCOAID?: Maybe<Scalars['String']>;
  DepreciationROUMasterCOAID: Scalars['String'];
  ProvisionDROUMasterCOAID: Scalars['String'];
  InterestExpenseMasterCOAID: Scalars['String'];
  LeaseLiabilitiesMasterCOAID: Scalars['String'];
  AccrualLeaseLiabilitiesMasterCOAID: Scalars['String'];
  GLTerminationMasterCOAID: Scalars['String'];
  CapitalisationROUJournalTypeID: Scalars['String'];
  ROUJournalTypeID: Scalars['String'];
  LeaseLiabilitiesJournalTypeID: Scalars['String'];
};

export type Glmfrs16LeaseContractEntity = AuditEntity & {
  __typename?: 'GLMFRS16LeaseContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  LeaseContractID: Scalars['String'];
  CompanyID: Scalars['String'];
  ContractNo: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  StartJournalDate: Scalars['String'];
  LeasePayment: Scalars['Float'];
  LeaseMonth: Scalars['Float'];
  TotalLeaseAmount: Scalars['Float'];
  AnnualDiscountRate: Scalars['Float'];
  DiscountedTotalCashFlows: Scalars['Float'];
  DiscountedTotalCashFlowsValues: Scalars['Float'];
  IsAccountROU: Scalars['Boolean'];
  IsGLPosted: Scalars['Boolean'];
  rejectedBy?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  TerminatedBy?: Maybe<Scalars['String']>;
  TerminatedDate?: Maybe<Scalars['String']>;
  TerminateDescription?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
};

export type Glmfrs16LeasePeriodEntity = {
  __typename?: 'GLMFRS16LeasePeriodEntity';
  LeasePeriodID: Scalars['String'];
  AccountID: Scalars['String'];
  LeaseContractID: Scalars['String'];
  LeaseYear: Scalars['Float'];
  LeasePeriod: Scalars['Float'];
  LeasePayment: Scalars['Float'];
  Principal: Scalars['Float'];
  Interest: Scalars['Float'];
  LeaseLiabilityCF: Scalars['Float'];
  LeaseLiabilityBFValue: Scalars['Float'];
  LeasePaymentValue: Scalars['Float'];
  PrincipalValue: Scalars['Float'];
  InterestValue: Scalars['Float'];
  LeaseLiabilityCFValue: Scalars['Float'];
  GLPostStatus: GlPostStatus;
  GeneratedBy: Scalars['String'];
  GeneratedDate: Scalars['String'];
  ApprovedBy: Scalars['String'];
  ApprovedDate: Scalars['String'];
};

export enum GlPostStatus {
  Pending = 'PENDING',
  Generated = 'GENERATED',
  Approved = 'APPROVED'
}

export enum GpApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  PendingClose = 'PENDING_CLOSE',
  PendingCancel = 'PENDING_CANCEL',
  SubmitCancel = 'SUBMIT_CANCEL',
  ApprovedCancel = 'APPROVED_CANCEL',
  RejectedCancel = 'REJECTED_CANCEL',
  CompletedCancel = 'COMPLETED_CANCEL',
  Closed = 'CLOSED',
  New = 'NEW',
  Packed = 'PACKED'
}

export type GprActionInput = {
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnHeaderEntity = AuditEntity & {
  __typename?: 'GRTNHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  GRTNHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  GRTNHeaderItem?: Maybe<Array<GrtnItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
};

export type GrtnItemEntity = AuditEntity & {
  __typename?: 'GRTNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GRTNItemID: Scalars['String'];
  GRTNHeaderID: Scalars['String'];
  GRTNHeader?: Maybe<GrtnHeaderEntity>;
  DOItemID: Scalars['String'];
  DOItem?: Maybe<DoItemEntity>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  ReturnedQty?: Maybe<Scalars['Float']>;
  ReturnedAmt?: Maybe<Scalars['Float']>;
  Replacement: Scalars['Boolean'];
  ReturnedDoItem?: Maybe<Array<DoItemEntity>>;
};

/** JsonType */
export type GrtnReturnData = {
  __typename?: 'GRTNReturnData';
  Data?: Maybe<Array<GrtnHeaderEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type GeneralGrtnInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type GeneralGrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GeneralGrtnItemInput = {
  GRTNHeaderID?: Maybe<Scalars['String']>;
  DoItemID: Scalars['String'];
  ReturnedQty: Scalars['Float'];
  ReturnedAmt: Scalars['Float'];
  Replacement: Scalars['Boolean'];
};

export type GeneralGrtnItemInputGrn = {
  DOItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GeneralGrtnRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type GeneralPurchasePolicyEntity = {
  __typename?: 'GeneralPurchasePolicyEntity';
  GeneralPurchasePolicyID: Scalars['String'];
  AccountID: Scalars['String'];
  VariancePerc?: Maybe<Scalars['Float']>;
  RFQRequired?: Maybe<Scalars['Boolean']>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  RecordStatus: RecordStatus;
  PRConfidentiality?: Maybe<Scalars['Boolean']>;
  GRNStatus?: Maybe<Scalars['Boolean']>;
  GRNUnitPrice?: Maybe<Scalars['Float']>;
};

export type GeneralPurchasePolicyInput = {
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
  RFQRequired?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  PRConfidentiality?: Maybe<Scalars['Boolean']>;
  GRNStatus?: Maybe<Scalars['Boolean']>;
  GRNUnitPrice?: Maybe<Scalars['Float']>;
};

export type GeneralScheduleItemInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  description?: Maybe<Scalars['String']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IcItemInput = {
  Name?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Uom?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  reasonName?: Maybe<Scalars['String']>;
  StockIssueItemID?: Maybe<Scalars['String']>;
  StockTransferItemID?: Maybe<Scalars['String']>;
  StockAdjustmentItemID?: Maybe<Scalars['String']>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export enum IdentityType {
  IdentityCard = 'IDENTITY_CARD',
  MilitaryCard = 'MILITARY_CARD',
  Passport = 'PASSPORT',
  BusinessLicense = 'BUSINESS_LICENSE'
}

/** JsonType */
export type Input = {
  __typename?: 'Input';
  EntityCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};

export type InventoryPolicyDeleteInput = {
  InventoryPolicyID: Scalars['String'];
};

export type InventoryPolicyEntity = AuditEntity & {
  __typename?: 'InventoryPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  InventoryPolicyID: Scalars['String'];
  PaymentReminder1: Scalars['Float'];
  PaymentReminder2: Scalars['Float'];
  PaymentReminder3: Scalars['Float'];
  PaymentReminder4: Scalars['Float'];
  PaymentReminder5: Scalars['Float'];
  PaymentReminder6: Scalars['Float'];
  StockCostingType: StockCostingType;
  CompanyID: Scalars['String'];
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CompanyEntity>;
};

export type InventoryPolicyInput = {
  InventoryPolicyID?: Maybe<Scalars['String']>;
  StockCostingType?: Maybe<Scalars['String']>;
  PaymentReminder1?: Maybe<Scalars['Float']>;
  PaymentReminder2?: Maybe<Scalars['Float']>;
  PaymentReminder3?: Maybe<Scalars['Float']>;
  PaymentReminder4?: Maybe<Scalars['Float']>;
  PaymentReminder5?: Maybe<Scalars['Float']>;
  PaymentReminder6?: Maybe<Scalars['Float']>;
};

export type ItemCategoryCompanyAssignmentDeleteInput = {
  ItemCategoryCompanyAssignmentID: Scalars['String'];
};

export type ItemCategoryCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'ItemCategoryCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCategoryCompanyAssignmentID: Scalars['String'];
  ItemCategoryID: Scalars['String'];
  CompanyID: Scalars['String'];
  ItemCategory?: Maybe<ItemCategoryEntity>;
};

export type ItemCategoryCompanyAssignmentInput = {
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  ItemCategoryID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ItemCategoryDeleteInput = {
  ID: Scalars['String'];
};

export type ItemCategoryEntity = AuditEntity & {
  __typename?: 'ItemCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCategoryID: Scalars['String'];
  Name: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  ParentItemCategoryID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  Item?: Maybe<Array<ItemEntity>>;
  ItemCategoryCompanyAssignment?: Maybe<Array<ItemCategoryCompanyAssignmentEntity>>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  ParentCategoryMCOA?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ItemCount?: Maybe<Scalars['Float']>;
};

export type ItemCategoryInput = {
  ItemCategoryID?: Maybe<Scalars['String']>;
  ParentItemCategoryID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
};

export type ItemClassCompanyAssignmentDeleteInput = {
  ItemClassCompanyAssignmentID: Scalars['String'];
};

export type ItemClassCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'ItemClassCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemClassCompanyAssignmentID: Scalars['String'];
  ExpenseClassID: Scalars['String'];
  CompanyID: Scalars['String'];
  ExpenseClass?: Maybe<ExpenseClassEntity>;
};

export type ItemClassCompanyAssignmentInput = {
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  ExpenseClassID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

/** JsonType */
export type ItemCompanyDisallowArg = {
  __typename?: 'ItemCompanyDisallowArg';
  ItemCompanyDisallowID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
};

export type ItemCompanyDisallowDeleteInput = {
  ItemCompanyDisallowID: Scalars['String'];
};

export type ItemCompanyDisallowEntity = AuditEntity & {
  __typename?: 'ItemCompanyDisallowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCompanyDisallowID: Scalars['String'];
  ItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  /** CustomFieldResolver */
  PendingUse?: Maybe<Scalars['Boolean']>;
};

export type ItemCompanyDisallowInput = {
  ItemCompanyDisallowID?: Maybe<Scalars['String']>;
  ItemID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ItemEntity = AuditEntity & {
  __typename?: 'ItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemID: Scalars['String'];
  ItemCategoryID: Scalars['String'];
  ItemCategory?: Maybe<ItemCategoryEntity>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  DefaultSupplierID?: Maybe<Scalars['String']>;
  MarketPrice: Scalars['Float'];
  IsCentralised: Scalars['Boolean'];
  IsInventory: Scalars['Boolean'];
  VariancePerc?: Maybe<Scalars['Float']>;
  LatestPurchasePrice?: Maybe<Scalars['Float']>;
  EInvoiceIntegrated: Scalars['Boolean'];
  ClassificationCodeID?: Maybe<Scalars['String']>;
  LatestUOMID?: Maybe<Scalars['String']>;
  BulkPurchasePriceItem?: Maybe<Array<BulkPurchasePriceItemEntity>>;
  QuotationItem?: Maybe<Array<QuotationItemEntity>>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  FavoritePRItem?: Maybe<Array<FavoritePrItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  ScheduledPurchasesItem?: Maybe<Array<ScheduledPurchasesItemEntity>>;
  ParentItem: Array<Scalars['JSON']>;
  UOM?: Maybe<AccUomEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DefaultSupplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  LatestPurchase?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ExpenseItemDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  UsedInIC?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  PendingUse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  LatestUOMCode?: Maybe<Scalars['JSON']>;
};

export type ItemExcelInput = {
  ID?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UOM?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ItemInput = {
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  DefaultSupplierID?: Maybe<Scalars['String']>;
  IsCentralised?: Maybe<Scalars['Boolean']>;
  IsInventory?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
  EInvoiceIntegrated?: Maybe<Scalars['Boolean']>;
  ClassificationCodeID?: Maybe<Scalars['String']>;
  MarketPrice?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type ItemObject = {
  __typename?: 'ItemObject';
  ItemID: Scalars['String'];
  ParentItemID?: Maybe<Scalars['String']>;
};


export type JournalDeleteInput = {
  JournalID: Scalars['String'];
};

export type JournalEntity = AuditEntity & {
  __typename?: 'JournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  JournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  JournalType?: Maybe<JournalTypeEntity>;
  JournalNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  IsManual: Scalars['Boolean'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  ExportDotNetID?: Maybe<Scalars['String']>;
  ExportDotNet?: Maybe<GlExportDotNetEntity>;
  ExportDotNetError?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalInput = {
  JournalID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalTypeID: Scalars['String'];
  JournalNo?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type JournalItemDeleteInput = {
  JournalItemID: Scalars['String'];
};

export type JournalItemEntity = AuditEntity & {
  __typename?: 'JournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  Journal?: Maybe<JournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalItemInput = {
  JournalItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
};

export type JournalProcessingDeleteInput = {
  JournalProcessingID: Scalars['String'];
};

export type JournalProcessingEntity = AuditEntity & {
  __typename?: 'JournalProcessingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  JournalProcessingID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalID?: Maybe<Scalars['String']>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type JournalProcessingInput = {
  JournalProcessingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocDate: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type JournalProcessingItemDeleteInput = {
  JournalProcessingItemID: Scalars['String'];
};

export type JournalProcessingItemEntity = AuditEntity & {
  __typename?: 'JournalProcessingItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalProcessingItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalProcessingID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  JournalProcessing?: Maybe<JournalProcessingEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalProcessingItemInput = {
  JournalProcessingItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalProcessingID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
  Sequence?: Maybe<Scalars['Float']>;
};

export type JournalProcessingRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type JournalProcessingReturnData = {
  __typename?: 'JournalProcessingReturnData';
  Data?: Maybe<Array<JournalProcessingEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type JournalReturnData = {
  __typename?: 'JournalReturnData';
  Data?: Maybe<Array<JournalEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum JournalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type JournalTypeDeleteInput = {
  JournalTypeID: Scalars['String'];
};

export type JournalTypeEntity = AuditEntity & {
  __typename?: 'JournalTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalType: Scalars['String'];
  Description: Scalars['String'];
  IsSystem: Scalars['Boolean'];
  Journal?: Maybe<Array<JournalEntity>>;
  RoutineJournal?: Maybe<Array<RoutineJournalEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
  RecurringJournalGeneration?: Maybe<Array<RecurringJournalGenerationEntity>>;
  FavoriteJournalProcessing?: Maybe<Array<FavoriteJournalProcessingEntity>>;
  JournalProcessing?: Maybe<Array<JournalProcessingEntity>>;
  ExternalExternalJournal?: Maybe<Array<ExternalJournalEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type JournalTypeInput = {
  JournalTypeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalType: Scalars['String'];
  Description: Scalars['String'];
  IsSystem?: Maybe<Scalars['Boolean']>;
};

export type LeaseContractDeleteInput = {
  LeaseContractID: Scalars['String'];
};

export type LeaseContractInput = {
  LeaseContractID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  ContractNo: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  StartJournalDate: Scalars['String'];
  LeasePayment: Scalars['Float'];
  LeaseMonth: Scalars['Float'];
  TotalLeaseAmount: Scalars['Float'];
  AnnualDiscountRate: Scalars['Float'];
  DiscountedTotalCashFlows: Scalars['Float'];
  DiscountedTotalCashFlowsValues: Scalars['Float'];
  IsAccountROU: Scalars['Boolean'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type LeaseContractPostInput = {
  LeaseContractID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
};

export type LeaseContractRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type LedgerDeleteInput = {
  LedgerID: Scalars['String'];
};

export type LedgerEntity = AuditEntity & {
  __typename?: 'LedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  Match?: Maybe<Array<Match>>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  TrxType?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  IsManual: Scalars['Boolean'];
  IsMatch: Scalars['Boolean'];
  LedgerType?: Maybe<Scalars['String']>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Currency?: Maybe<CurrencyEntity>;
};

export type LedgerInput = {
  LedgerID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  DocNo: Scalars['String'];
  DocDate: Scalars['String'];
  TrxType: Scalars['String'];
  RefNo: Scalars['String'];
  CurrencyID: Scalars['String'];
  ExchangeRate: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  DocAmt: Scalars['Float'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  TransactionDate: Scalars['String'];
  RefTable: Scalars['String'];
  PayeeName: Scalars['String'];
  ReceivedFrom: Scalars['String'];
};

export type LedgerItemDeleteInput = {
  LedgerItemID: Scalars['String'];
};

export type LedgerItemEntity = AuditEntity & {
  __typename?: 'LedgerItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  LedgerItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  LedgerID: Scalars['String'];
  Ledger?: Maybe<LedgerEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type LedgerItemInput = {
  LedgerItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  JournalAmount: Scalars['Float'];
};

/** JsonType */
export type LedgerType = {
  __typename?: 'LedgerType';
  Data?: Maybe<Array<LedgerEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type LetterTemplateDeleteInput = {
  LetterTemplateID?: Maybe<Scalars['String']>;
};

export type LetterTemplateEntity = AuditEntity & {
  __typename?: 'LetterTemplateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  LetterTemplateID: Scalars['String'];
  AccountID: Scalars['String'];
  StandardLetterID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  TemplateBody?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardLetterDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
};

export type LetterTemplateInput = {
  LetterTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  JointBuyerSignRequired?: Maybe<Scalars['Boolean']>;
  StandardDataSourceVersion?: Maybe<Scalars['Float']>;
  IsReportDesignEditor?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<AccRoleUserInput>;
};

export type LocalBankEntity = AuditEntity & {
  __typename?: 'LocalBankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  countryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  address: Address;
  contactNo: Scalars['String'];
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MasterCoaDeleteInput = {
  MasterCOAID: Scalars['String'];
};

export type MasterCoaEntity = AuditEntity & {
  __typename?: 'MasterCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  MasterCOAID: Scalars['String'];
  ParentMasterCOAID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Name: Scalars['String'];
  Level: Scalars['Float'];
  AccountType: AccountType;
  IsControl: Scalars['Boolean'];
  IsLastNode: Scalars['Boolean'];
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  CreditorType?: Maybe<Array<CreditorTypeEntity>>;
  AccrualCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  AdvanceCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  SelfBilledCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  TaxScheme?: Maybe<Array<TaxSchemeEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  EntityCOA?: Maybe<Array<EntityCoaEntity>>;
  RetainEarningAccountDefID?: Maybe<Array<RetainEarningAccountDefEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  ExpenseClass?: Maybe<Array<ExpenseClassEntity>>;
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  SCStockAccount?: Maybe<Array<StockCategoryEntity>>;
  BillItem?: Maybe<Array<BillItemEntity>>;
  TransactionType?: Maybe<Array<TransactionTypeEntity>>;
  CostCentreCOA?: Maybe<Array<CostCentreCoaEntity>>;
  GeneralPurchasePolicy?: Maybe<Array<GeneralPurchasePolicyEntity>>;
};

export type MasterCoaInput = {
  MasterCOAID?: Maybe<Scalars['String']>;
  ParentMasterCOAID?: Maybe<Scalars['String']>;
  ParentCode?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  IsControl?: Maybe<Scalars['Boolean']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  AccountType?: Maybe<AccountType>;
  RowNo?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type Match = {
  __typename?: 'Match';
  sequence?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  ismatch?: Maybe<Scalars['Boolean']>;
  createdby?: Maybe<Scalars['String']>;
  createdts?: Maybe<Scalars['DateTime']>;
};

export type MatchInput = {
  sequence?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  ismatch?: Maybe<Scalars['Boolean']>;
  createdby?: Maybe<Scalars['String']>;
  createdts?: Maybe<Scalars['DateTime']>;
};

export enum ModuleType {
  AllModule = 'ALL_MODULE',
  Gl = 'GL',
  Cb = 'CB',
  Ar = 'AR',
  Ap = 'AP',
  Ic = 'IC',
  Gp = 'GP'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: UserEntity;
  createUserToken: UserEntity;
  updateUser: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  changePasswordForUser: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  toggleUserActivation: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resendInvitation: Scalars['Boolean'];
  createAPAdvance: AccApAdvanceEntity;
  updateAPAdvance: AccApAdvanceEntity;
  deleteAPAdvance: Scalars['Boolean'];
  postAPAdvance: Scalars['Boolean'];
  cancelAPAdvance: Scalars['Boolean'];
  rejectAPAdvance: Scalars['Boolean'];
  generatePayment: AccApPaymentEntity;
  createARAdvance: Scalars['Boolean'];
  updateARAdvance: Scalars['Boolean'];
  deleteARAdvance: Scalars['Boolean'];
  postARAdvance: Scalars['Boolean'];
  cancelARAdvance: Scalars['Boolean'];
  rejectARAdvance: Scalars['Boolean'];
  createAllocation: Scalars['JSON'];
  updateAPBatchPayment: Scalars['Boolean'];
  deleteAPBatchPayment: Scalars['Boolean'];
  cancelAPBatchPayment: Scalars['Boolean'];
  createAPBatchPaymentWithAllocation: Scalars['Boolean'];
  updateAPBatchPaymentWithAllocation: Scalars['Boolean'];
  payAPBatchPayment: Scalars['Boolean'];
  deleteBatchPayment: Scalars['Boolean'];
  rejectBatchPayment: Scalars['Boolean'];
  createSelfBilledConsolidate: Scalars['Boolean'];
  createEinvoiceConsolidate: Scalars['Boolean'];
  createEinvoiceConsolidateDocTypeAll: Scalars['JSON'];
  createAPCreditNote: AccApCreditNoteEntity;
  updateAPCreditNote: AccApCreditNoteEntity;
  deleteAPCreditNote: Scalars['Boolean'];
  postAPCreditNote: Scalars['Boolean'];
  cancelAPCreditNote: Scalars['Boolean'];
  rejectAPCreditNote: Scalars['Boolean'];
  deleteARCreditNote: Scalars['Boolean'];
  postARCreditNote: Scalars['Boolean'];
  cancelARCreditNote: Scalars['Boolean'];
  createARCreditNote: Scalars['JSON'];
  updateARCreditNote: Scalars['JSON'];
  rejectARCreditNote: Scalars['Boolean'];
  createAPCreditorCreditNote: Scalars['Boolean'];
  updateAPCreditorCreditNote: Scalars['Boolean'];
  deleteAPCreditorCreditNote: Scalars['Boolean'];
  postAPCreditorCreditNote: Scalars['Boolean'];
  rejectAPCreditorCreditNote: Scalars['Boolean'];
  createAPCreditNoteItem: AccApCreditNoteItemEntity;
  updateAPCreditNoteItem: AccApCreditNoteItemEntity;
  deleteAPCreditNoteItem: Scalars['Boolean'];
  cancelAPCreditNoteItem: Scalars['Boolean'];
  createAPDebitNote: AccApDebitNoteEntity;
  updateAPDebitNote: AccApDebitNoteEntity;
  deleteAPDebitNote: Scalars['Boolean'];
  postAPDebitNote: Scalars['Boolean'];
  cancelAPDebitNote: Scalars['Boolean'];
  rejectAPDebitNote: Scalars['Boolean'];
  deleteARDebitNote: Scalars['Boolean'];
  postARDebitNote: Scalars['Boolean'];
  cancelARDebitNote: Scalars['Boolean'];
  rejectARDebitNote: Scalars['Boolean'];
  createARDebitNote: Scalars['JSON'];
  updateARDebitNote: Scalars['JSON'];
  createAPCreditorDebitNote: AccApCreditorDebitNoteEntity;
  updateAPCreditorDebitNote: AccApCreditorDebitNoteEntity;
  deleteAPCreditorDebitNote: Scalars['Boolean'];
  postAPCreditorDebitNote: Scalars['Boolean'];
  cancelAPCreditorDebitNote: Scalars['Boolean'];
  rejectAPCreditorDebitNote: Scalars['Boolean'];
  createAPDebitNoteItem: AccApDebitNoteItemEntity;
  updateAPDebitNoteItem: AccApDebitNoteItemEntity;
  deleteAPDebitNoteItem: Scalars['Boolean'];
  cancelAPDebitNoteItem: Scalars['Boolean'];
  createARDebitNoteItem: AccArDebitNoteItemEntity;
  updateARDebitNoteItem: AccArDebitNoteItemEntity;
  deleteARDebitNoteItem: Scalars['Boolean'];
  cancelARDebitNoteItem: Scalars['Boolean'];
  deleteAPFavoriteInvoice: Scalars['Boolean'];
  createAPFavoriteInvoice: Scalars['Boolean'];
  deleteARFavoriteInvoice: Scalars['Boolean'];
  createARFavoriteInvoice: Scalars['Boolean'];
  createAPFavoritePayment: Scalars['Boolean'];
  deleteAPFavoritePayment: Scalars['Boolean'];
  createAPInvoice: AccApInvoiceEntity;
  updateAPInvoice: AccApInvoiceEntity;
  deleteAPInvoice: Scalars['Boolean'];
  postAPInvoice: Scalars['Boolean'];
  cancelAPInvoice: Scalars['Boolean'];
  rejectAPInvoice: Scalars['Boolean'];
  duplicateInvoice: Scalars['JSON'];
  createARInvoice: AccArInvoiceEntity;
  updateARInvoice: AccArInvoiceEntity;
  deleteARInvoice: Scalars['Boolean'];
  postARInvoice: Scalars['Boolean'];
  rejectARInvoice: Scalars['Boolean'];
  createAPInvoiceItem: AccApInvoiceItemEntity;
  updateAPInvoiceItem: AccApInvoiceItemEntity;
  deleteAPInvoiceItem: Scalars['Boolean'];
  cancelAPInvoiceItem: Scalars['Boolean'];
  createARInvoiceItem: AccArInvoiceItemEntity;
  updateARInvoiceItem: AccArInvoiceItemEntity;
  deleteARInvoiceItem: Scalars['Boolean'];
  cancelARInvoiceItem: Scalars['Boolean'];
  cancelAROfficialReceipt: Scalars['Boolean'];
  createAROfficialReceipt: AccArOfficialReceiptEntity;
  updateAROfficialReceipt: AccArOfficialReceiptEntity;
  deleteAROfficialReceipt: Scalars['Boolean'];
  postAROfficialReceipt: Scalars['Boolean'];
  rejectAROfficialReceipt: Scalars['Boolean'];
  cancelAPPayment: Scalars['Boolean'];
  createAPPayment: AccApPaymentEntity;
  updateAPPayment: AccApPaymentEntity;
  deleteAPPayment: Scalars['Boolean'];
  postAPPayment: Scalars['Boolean'];
  rejectAPPayment: Scalars['Boolean'];
  cancelAPRefund: Scalars['Boolean'];
  createAPRefund: AccApRefundEntity;
  updateAPRefund: AccApRefundEntity;
  deleteAPRefund: Scalars['Boolean'];
  postAPRefund: Scalars['Boolean'];
  rejectAPRefund: Scalars['Boolean'];
  cancelARRefund: Scalars['Boolean'];
  createARRefund: AccArRefundEntity;
  deleteARRefund: Scalars['Boolean'];
  updateARRefund: AccArRefundEntity;
  postARRefund: Scalars['Boolean'];
  rejectARRefund: Scalars['Boolean'];
  createAPSelfBilled: AccApSelfBilledEntity;
  updateAPSelfBilled: AccApSelfBilledEntity;
  deleteAPSelfBilled: Scalars['Boolean'];
  postAPSelfBilled: Scalars['Boolean'];
  cancelAPSelfBilled: Scalars['Boolean'];
  rejectAPSelfBilled: Scalars['Boolean'];
  cancelEinvoice: Scalars['Boolean'];
  getShareAttachment?: Maybe<AttachmentEntity>;
  singleUpload: Scalars['Boolean'];
  createBankReconciliation: BankReconciliationEntity;
  updateBankReconciliation: BankReconciliationEntity;
  cancelBankReconciliation: Scalars['Boolean'];
  createImportBankReconciliation: Scalars['Boolean'];
  updateImportBankReconciliation: Scalars['Boolean'];
  createBankReconciliationWihoutExcel: Scalars['Boolean'];
  updateBankReconciliationWihoutExcel: Scalars['Boolean'];
  MatchBankReconciliation: Scalars['Boolean'];
  deleteBankReconciliation: Scalars['Boolean'];
  createBankReconciliationItem: BankReconciliationItemEntity;
  updateBankReconciliationItem: BankReconciliationItemEntity;
  deleteBankReconciliationItem: Scalars['Boolean'];
  cancelBankReconciliationItem: Scalars['Boolean'];
  createBankReconciliationMatch: BankReconciliationMatchEntity;
  updateBankReconciliationMatch: BankReconciliationMatchEntity;
  deleteBankReconciliationMatch: Scalars['Boolean'];
  cancelBankReconciliationMatch: Scalars['Boolean'];
  createBankTransfer: BankTransferEntity;
  updateBankTransfer: BankTransferEntity;
  deleteBankTransfer: Scalars['Boolean'];
  cancelBankTransfer: Scalars['Boolean'];
  postCBBankTransfer: Scalars['Boolean'];
  rejectBankTransfer: Scalars['Boolean'];
  createExternalLedger: Scalars['Boolean'];
  CBImportLedger: Scalars['JSON'];
  deleteFavoritePayment: Scalars['Boolean'];
  createFavoritePayment: Scalars['Boolean'];
  deleteFavoriteReceipt: Scalars['Boolean'];
  createFavoriteReceipt: Scalars['Boolean'];
  createLedger: LedgerEntity;
  updateLedger: LedgerEntity;
  deleteLedger: Scalars['Boolean'];
  cancelLedger: Scalars['Boolean'];
  createLedgerItem: LedgerItemEntity;
  updateLedgerItem: LedgerItemEntity;
  deleteLedgerItem: Scalars['Boolean'];
  cancelLedgerItem: Scalars['Boolean'];
  createPayment: PaymentEntity;
  updatePayment: PaymentEntity;
  deletePayment: Scalars['Boolean'];
  cancelPayment: Scalars['Boolean'];
  postCBPayment: Scalars['Boolean'];
  rejectPayment: Scalars['Boolean'];
  createPaymentItem: PaymentItemEntity;
  updatePaymentItem: PaymentItemEntity;
  deletePaymentItem: Scalars['Boolean'];
  cancelPaymentItem: Scalars['Boolean'];
  createReceipt: ReceiptEntity;
  updateReceipt: ReceiptEntity;
  deleteReceipt: Scalars['Boolean'];
  cancelReceipt: Scalars['Boolean'];
  postCBReceipt: Scalars['Boolean'];
  rejectReceipt: Scalars['Boolean'];
  createReceiptItem: ReceiptItemEntity;
  updateReceiptItem: ReceiptItemEntity;
  deleteReceiptItem: Scalars['Boolean'];
  cancelReceiptItem: Scalars['Boolean'];
  deleteCompanyLetterTemplate: Scalars['Boolean'];
  cancelCompanyLetterTemplate: Scalars['Boolean'];
  createCompanyLetterTemplate: CompanyLetterTemplateEntity;
  updateCompanyLetterTemplate: Scalars['Boolean'];
  deleteLetterTemplate: Scalars['Boolean'];
  cancelLetterTemplate: Scalars['Boolean'];
  createLetterTemplate: LetterTemplateEntity;
  updateLetterTemplate: Scalars['Boolean'];
  createStandardLetterDataSource: StandardLetterDataSourceEntity;
  updateStandardLetterDataSource: StandardLetterDataSourceEntity;
  deleteStandardLetterDataSource: Scalars['Boolean'];
  cancelStandardLetterDataSource: Scalars['Boolean'];
  createAuditAdjustment: AuditAdjustmentEntity;
  updateAuditAdjustment: AuditAdjustmentEntity;
  deleteAuditAdjustment: Scalars['Boolean'];
  cancelAuditAdjustment: Scalars['Boolean'];
  postAuditAdjustment: Scalars['Boolean'];
  rejectAuditAdjustment: Scalars['Boolean'];
  createAuditAdjustmentItem: AuditAdjustmentItemEntity;
  updateAuditAdjustmentItem: AuditAdjustmentItemEntity;
  deleteAuditAdjustmentItem: Scalars['Boolean'];
  cancelAuditAdjustmentItem: Scalars['Boolean'];
  createBudget: BudgetEntity;
  updateBudget: BudgetEntity;
  deleteBudget: Scalars['Boolean'];
  cancelBudget: Scalars['Boolean'];
  createBudgetWithDetail: Scalars['Boolean'];
  updateBudgetWithDetail: Scalars['Boolean'];
  setBudgetDefault: Scalars['Boolean'];
  createBudgetItem: BudgetItemEntity;
  updateBudgetItem: BudgetItemEntity;
  deleteBudgetItem: Scalars['Boolean'];
  cancelBudgetItem: Scalars['Boolean'];
  createBudgetItemCostCentre: BudgetItemCostCentreEntity;
  updateBudgetItemCostCentre: BudgetItemCostCentreEntity;
  deleteBudgetItemCostCentre: Scalars['Boolean'];
  cancelBudgetItemCostCentre: Scalars['Boolean'];
  createBudgetItemPeriod: BudgetItemPeriodEntity;
  updateBudgetItemPeriod: BudgetItemPeriodEntity;
  deleteBudgetItemPeriod: Scalars['Boolean'];
  cancelBudgetItemPeriod: Scalars['Boolean'];
  createExternalJournal: Scalars['Boolean'];
  deleteFavoriteJournalProcessing: Scalars['Boolean'];
  createFavoriteJournalProcessing: Scalars['Boolean'];
  createDataSourceSettings: DataSourceSettingsEntity;
  updateDataSourceSettings: DataSourceSettingsEntity;
  deleteDataSourceSettings: Scalars['Boolean'];
  cancelDataSourceSettings: Scalars['Boolean'];
  createReportSettings: ReportSettingsEntity;
  updateReportSettings: ReportSettingsEntity;
  deleteReportSettings: Scalars['Boolean'];
  cancelReportSettings: Scalars['Boolean'];
  uploadReportTemplate: Scalars['Boolean'];
  ExportGLToDotNet: ExportDotNetReturn;
  updateJournal: Scalars['Boolean'];
  deleteJournal: Scalars['Boolean'];
  cancelJournal: Scalars['Boolean'];
  createJournal: Scalars['Boolean'];
  GLImportLedger: Scalars['JSON'];
  createJournalItem: JournalItemEntity;
  updateJournalItem: JournalItemEntity;
  deleteJournalItem: Scalars['Boolean'];
  cancelJournalItem: Scalars['Boolean'];
  createJournalProcessing: JournalProcessingEntity;
  updateJournalProcessing: JournalProcessingEntity;
  deleteJournalProcessing: Scalars['Boolean'];
  cancelJournalProcessing: Scalars['Boolean'];
  postJournalProcessing: Scalars['Boolean'];
  rejectJournalProcessing: Scalars['Boolean'];
  processMonthEndClosing?: Maybe<ProcessMonthYearEndOutput>;
  revertMonthEndClosing?: Maybe<Scalars['Boolean']>;
  revertYearEndClosing?: Maybe<Scalars['Boolean']>;
  processYearEndClosing: Scalars['Boolean'];
  createRecurringJournal: Scalars['Boolean'];
  updateRecurringJournal: Scalars['Boolean'];
  deleteRecurringJournal: Scalars['Boolean'];
  cancelRecurringJournal: Scalars['Boolean'];
  postRecurringJournal: Scalars['Boolean'];
  rejectRecurringJournal: Scalars['Boolean'];
  generateRecurringJournal: Scalars['Boolean'];
  createRecurringJournalItem: RecurringJournalItemEntity;
  updateRecurringJournalItem: RecurringJournalItemEntity;
  deleteRecurringJournalItem: Scalars['Boolean'];
  cancelRecurringJournalItem: Scalars['Boolean'];
  updateRoutineJournal: RoutineJournalEntity;
  deleteRoutineJournal: Scalars['Boolean'];
  cancelRoutineJournal: Scalars['Boolean'];
  createRoutineJournal: Scalars['Boolean'];
  updateRoutineJournals: Scalars['Boolean'];
  createRoutineJournalItem: RoutineJournalItemEntity;
  updateRoutineJournalItem: RoutineJournalItemEntity;
  deleteRoutineJournalItem: Scalars['Boolean'];
  cancelRoutineJournalItem: Scalars['Boolean'];
  postGLExport: Scalars['Boolean'];
  createGLExport: Scalars['Boolean'];
  deleteCycleCount: Scalars['Boolean'];
  cancelCycleCount: Scalars['Boolean'];
  createCycleCount: CycleCountEntity;
  updateCycleCount: CycleCountEntity;
  postCycleCount: Scalars['Boolean'];
  createStockRecon: Scalars['Boolean'];
  createCycleCountItem: CycleCountItemEntity;
  updateCycleCountItem: CycleCountItemEntity;
  deleteCycleCountItem: Scalars['Boolean'];
  cancelCycleCountItem: Scalars['Boolean'];
  createFavoriteStockRequisition: Scalars['Boolean'];
  deleteFavoriteStockRequisition: Scalars['Boolean'];
  createFavoriteStockTransferRequisition: Scalars['Boolean'];
  deleteFavoriteStockTransferRequisition: Scalars['Boolean'];
  createInventoryPolicy: InventoryPolicyEntity;
  updateInventoryPolicy: InventoryPolicyEntity;
  deleteInventoryPolicy: Scalars['Boolean'];
  cancelInventoryPolicy: Scalars['Boolean'];
  deleteStockAdjustment: Scalars['Boolean'];
  cancelStockAdjustment: Scalars['Boolean'];
  createStockAdjustment: Scalars['JSON'];
  updateStockAdjustment: Scalars['JSON'];
  postStockAdjustment: Scalars['Boolean'];
  createStockAdjustmentItem: StockAdjustmentItemEntity;
  updateStockAdjustmentItem: StockAdjustmentItemEntity;
  deleteStockAdjustmentItem: Scalars['Boolean'];
  cancelStockAdjustmentItem: Scalars['Boolean'];
  createStockAdjustmentReason: StockAdjustmentReasonEntity;
  updateStockAdjustmentReason: StockAdjustmentReasonEntity;
  deleteStockAdjustmentReason: Scalars['Boolean'];
  cancelStockAdjustmentReason: Scalars['Boolean'];
  updateDefaultSAReason: Scalars['Boolean'];
  cancelStockCategory: Scalars['Boolean'];
  deleteStockCategory: Scalars['Boolean'];
  createStockCategory: Scalars['Boolean'];
  updateStockCategory: Scalars['Boolean'];
  createStockCostAdjustment: StockCostAdjustmentEntity;
  updateStockCostAdjustment: StockCostAdjustmentEntity;
  deleteStockCostAdjustment: Scalars['Boolean'];
  cancelStockCostAdjustment: Scalars['Boolean'];
  postStockCostAdjustment: Scalars['Boolean'];
  deleteStockIssue: Scalars['Boolean'];
  cancelStockIssue: Scalars['Boolean'];
  createStockIssue: Scalars['JSON'];
  updateStockIssue: Scalars['JSON'];
  postStockIssue: Scalars['Boolean'];
  createStockIssueItem: StockIssueItemEntity;
  updateStockIssueItem: StockIssueItemEntity;
  deleteStockIssueItem: Scalars['Boolean'];
  cancelStockIssueItem: Scalars['Boolean'];
  deleteStockReceipt: Scalars['Boolean'];
  cancelStockReceipt: Scalars['Boolean'];
  createStockReceipt: Scalars['JSON'];
  updateStockReceipt: Scalars['JSON'];
  postStockReceipt: Scalars['Boolean'];
  RevokeStockReceipt: Scalars['Boolean'];
  createStockReceiptItem: StockReceiptItemEntity;
  updateStockReceiptItem: StockReceiptItemEntity;
  deleteStockReceiptItem: Scalars['Boolean'];
  cancelStockReceiptItem: Scalars['Boolean'];
  deleteStockRequisition: Scalars['Boolean'];
  cancelStockRequisition: Scalars['Boolean'];
  createStockRequisition: Scalars['JSON'];
  updateStockRequisition: Scalars['JSON'];
  postStockRequisition: Scalars['Boolean'];
  createStockRequisitionItem: StockRequisitionItemEntity;
  updateStockRequisitionItem: StockRequisitionItemEntity;
  deleteStockRequisitionItem: Scalars['Boolean'];
  cancelStockRequisitionItem: Scalars['Boolean'];
  deleteStockTransfer: Scalars['Boolean'];
  cancelStockTransfer: Scalars['Boolean'];
  createStockTransfer: Scalars['JSON'];
  updateStockTransfer: Scalars['JSON'];
  postStockTransfer: Scalars['Boolean'];
  createStockTransferItem: StockTransferItemEntity;
  updateStockTransferItem: StockTransferItemEntity;
  deleteStockTransferItem: Scalars['Boolean'];
  cancelStockTransferItem: Scalars['Boolean'];
  deleteStockTransferRequisition: Scalars['Boolean'];
  createStockTransferRequisition: StockTransferRequisitionEntity;
  updateStockTransferRequisition: StockTransferRequisitionEntity;
  postStockTransferRequisition: Scalars['Boolean'];
  cancelStockTransferRequisition: Scalars['Boolean'];
  createStockTransferRequisitionItem: StockTransferRequisitionItemEntity;
  updateStockTransferRequisitionItem: StockTransferRequisitionItemEntity;
  deleteStockTransferRequisitionItem: Scalars['Boolean'];
  cancelStockTransferRequisitionItem: Scalars['Boolean'];
  createLeaseContract: Glmfrs16LeaseContractEntity;
  updateLeaseContract: Glmfrs16LeaseContractEntity;
  deleteLeaseContract: Scalars['Boolean'];
  cancelLeaseContract: Scalars['Boolean'];
  postLeaseContract: Scalars['Boolean'];
  rejectLeaseContract: Scalars['Boolean'];
  updateAccountMapping: Glmfrs16AccountMappingEntity;
  deleteAccountMapping: Scalars['Boolean'];
  cancelAccountMapping: Scalars['Boolean'];
  createAccountMapping: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  cancelCompany: Scalars['Boolean'];
  createCompany: CompanyEntity;
  updateCompany: Scalars['Boolean'];
  deleteCompanyProfile: Scalars['Boolean'];
  createCompanyImport: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  patchAPPayment: Scalars['Boolean'];
  patchNullGLRecurringDescriptionData: Scalars['Boolean'];
  patchNullBaseUOMInStockItemData: Scalars['Boolean'];
  patchJournalTypeForCompanies: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  insertAdjustmentReason: Scalars['Boolean'];
  createSystemJournalType: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DuplicateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  AssignEntityRoleUser: Scalars['Boolean'];
  UnassignEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  deleteAccountPeriod: Scalars['Boolean'];
  cancelAccountPeriod: Scalars['Boolean'];
  createAccountPeriod: Scalars['Boolean'];
  updateAccountPeriod: Scalars['Boolean'];
  closeAccountPeriod: Scalars['Boolean'];
  updateARAging: ArAgingEntity;
  deleteARAging: Scalars['Boolean'];
  cancelARAging: Scalars['Boolean'];
  updateAPAging: ApAgingEntity;
  deleteAPAging: Scalars['Boolean'];
  cancelAPAging: Scalars['Boolean'];
  createARAging: Scalars['Boolean'];
  createAPAging: Scalars['Boolean'];
  cancelApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: AccApprovalPolicyEntity;
  updateApprovalPolicy: AccApprovalPolicyEntity;
  createUpdateDeleteApprovalPolicyPO: Scalars['JSON'];
  createUpdateDeleteApprovalPolicyAssignmentPO: Scalars['JSON'];
  createUpdateDeleteApprovalPolicyAP: Scalars['JSON'];
  createUpdateDeleteApprovalPolicyAssignmentAP: Scalars['JSON'];
  deleteApprovalPolicy: Scalars['Boolean'];
  createApprovalPolicyAssignment: AccApprovalPolicyAssignmentEntity;
  updateApprovalPolicyAssignment: AccApprovalPolicyAssignmentEntity;
  createBankAccount: AccBankAccountEntity;
  updateBankAccount: AccBankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  createBankAccountImport: Scalars['Boolean'];
  createBankProfile: BankProfileEntity;
  updateBankProfile: BankProfileEntity;
  deleteBankProfile: Scalars['Boolean'];
  cancelBankProfile: Scalars['Boolean'];
  createBankProfileImport: Scalars['Boolean'];
  createBillItem: BillItemEntity;
  updateBillItem: BillItemEntity;
  deleteBillItem: Scalars['Boolean'];
  cancelBillItem: Scalars['Boolean'];
  updateCOAStructure: CoaStructureEntity;
  deleteCOAStructure: Scalars['Boolean'];
  cancelCOAStructure: Scalars['Boolean'];
  createCOAStructure: Scalars['Boolean'];
  updateContact: AccContactEntity;
  updateCostCentre: CostCentreEntity;
  cancelCostCentre: Scalars['Boolean'];
  createCostCentreDefinitionAsgmt: Scalars['Boolean'];
  deleteCostCentre: Scalars['Boolean'];
  createCostCentre: Scalars['Boolean'];
  setDefaultCostCentre: Scalars['Boolean'];
  assignCostCentreCOA: Scalars['Boolean'];
  unassignCostCentreCOA: Scalars['Boolean'];
  updateCostCentreDefinitionHeader: CostCentreDefinitionHeaderEntity;
  cancelCostCentreDefinitionHeader: Scalars['Boolean'];
  updateCostCentreDefinitionDetail: CostCentreDefinitionDetailEntity;
  deleteCostCentreDefinitionDetail: Scalars['Boolean'];
  cancelCostCentreDefinitionDetail: Scalars['Boolean'];
  createCostCentreDefinition: Scalars['Boolean'];
  createUpdateCostCentreDefinition: Scalars['Boolean'];
  deleteCostCentreDefinition: Scalars['Boolean'];
  createCostCentreType: CostCentreTypeEntity;
  updateCostCentreType: CostCentreTypeEntity;
  deleteCostCentreType: Scalars['Boolean'];
  cancelCostCentreType: Scalars['Boolean'];
  createUpdateCostCentreType: Scalars['Boolean'];
  updateCreditCardType: CreditCardTypeEntity;
  deleteCreditCardType: Scalars['Boolean'];
  cancelCreditCardType: Scalars['Boolean'];
  createCreditCardType: Scalars['Boolean'];
  updateCreditorAccount: CreditorAccountEntity;
  deleteCreditorAccount: Scalars['Boolean'];
  cancelCreditorAccount: Scalars['Boolean'];
  CreateCreditorAccount: CreditorAccountEntity;
  UpdateCreditorAccountWithItem: Scalars['Boolean'];
  CreateCreditorAccountWithAccount: Scalars['Boolean'];
  createCreditorProfileImport: Scalars['Boolean'];
  updateCreditorAccountCompanyAssignment: CreditorAccountCompanyAssignmentEntity;
  deleteCreditorAccountCompanyAssignment: Scalars['Boolean'];
  cancelCreditorAccountCompanyAssignment: Scalars['Boolean'];
  AssignCreditorAccountCompany: Scalars['Boolean'];
  UnassignCreditorAccountCompany: Scalars['Boolean'];
  createCreditorContact: CreditorContactEntity;
  updateCreditorContact: CreditorContactEntity;
  deleteCreditorContact: Scalars['Boolean'];
  cancelCreditorContact: Scalars['Boolean'];
  createCreditorDirector: CreditorDirectorEntity;
  updateCreditorDirector: CreditorDirectorEntity;
  deleteCreditorDirector: Scalars['Boolean'];
  cancelCreditorDirector: Scalars['Boolean'];
  createCreditorType: CreditorTypeEntity;
  updateCreditorType: CreditorTypeEntity;
  deleteCreditorType: Scalars['Boolean'];
  cancelCreditorType: Scalars['Boolean'];
  CreateCreditorTypeWithAccount: Scalars['Boolean'];
  createCurrency: CurrencyEntity;
  updateCurrency: CurrencyEntity;
  deleteCurrency: Scalars['Boolean'];
  cancelCurrency: Scalars['Boolean'];
  cancelCurrencyExchange: Scalars['Boolean'];
  createCurrencyExchange: Scalars['Boolean'];
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  updateDebtorAccount: DebtorAccountEntity;
  deleteDebtorAccount: Scalars['Boolean'];
  cancelDebtorAccount: Scalars['Boolean'];
  CreateDebtorAccount: Scalars['Boolean'];
  UpdateDebtorAccountWithItem: Scalars['Boolean'];
  createDebtorAccountImport: Scalars['Boolean'];
  createDebtorContact: DebtorContactEntity;
  updateDebtorContact: DebtorContactEntity;
  deleteDebtorContact: Scalars['Boolean'];
  cancelDebtorContact: Scalars['Boolean'];
  createDebtorType: DebtorTypeEntity;
  updateDebtorType: DebtorTypeEntity;
  deleteDebtorType: Scalars['Boolean'];
  cancelDebtorType: Scalars['Boolean'];
  deleteDeliveryLocation: Scalars['Boolean'];
  cancelDeliveryLocation: Scalars['Boolean'];
  createDeliveryLoc: Scalars['Boolean'];
  updateDeliveryLoc: Scalars['Boolean'];
  updateDocumentDateValidation: DocumentDateValidationEntity;
  deleteDocumentDateValidation: Scalars['Boolean'];
  cancelDocumentDateValidation: Scalars['Boolean'];
  createDocumentDateValidation: Scalars['Boolean'];
  createDocumentNumberDetail: DocumentNumberDetailEntity;
  updateDocumentNumberDetail: DocumentNumberDetailEntity;
  deleteDocumentNumberDetail: Scalars['Boolean'];
  cancelDocumentNumberDetail: Scalars['Boolean'];
  deleteDocumentNumberHeader: Scalars['Boolean'];
  cancelDocumentNumberHeader: Scalars['Boolean'];
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateIsAutoDocNo: Scalars['Boolean'];
  createEntityCOA: EntityCoaEntity;
  updateEntityCOA: EntityCoaEntity;
  deleteEntityCOA: Scalars['Boolean'];
  cancelEntityCOA: Scalars['Boolean'];
  assignEntityCOA: Scalars['Boolean'];
  deleteAssignedEntityCOA: Scalars['Boolean'];
  activateInactivateEntityCOA: Scalars['Boolean'];
  cancelExpenseClass: Scalars['Boolean'];
  deleteExpenseClass: Scalars['Boolean'];
  createExpenseClass: Scalars['Boolean'];
  updateExpenseClass: Scalars['Boolean'];
  cancelExpenseItem: Scalars['Boolean'];
  createExpenseItem: ExpenseItemEntity;
  updateExpenseItem: Scalars['Boolean'];
  deleteExpenseItem: Scalars['Boolean'];
  testDeleteExpenseItem: Scalars['Boolean'];
  initialTaxSetup: Scalars['Boolean'];
  createSuperUser: UserEntity;
  updateGLCompanyPolicy: Scalars['Boolean'];
  updateItemClassCompanyAssignment: ItemClassCompanyAssignmentEntity;
  deleteItemClassCompanyAssignment: Scalars['Boolean'];
  cancelItemClassCompanyAssignment: Scalars['Boolean'];
  AssignItemClassCompany: Scalars['Boolean'];
  UnassignItemClassCompany: Scalars['Boolean'];
  createJournalType: JournalTypeEntity;
  updateJournalType: JournalTypeEntity;
  deleteJournalType: Scalars['Boolean'];
  cancelJournalType: Scalars['Boolean'];
  updateMasterCOA: MasterCoaEntity;
  cancelMasterCOA: Scalars['Boolean'];
  createMasterCOA: Scalars['Boolean'];
  deleteMasterCOA: Scalars['Boolean'];
  createExcelMasterCOA: Scalars['Boolean'];
  inactiveMasterCOA: Scalars['Boolean'];
  activateMasterCOA: Scalars['Boolean'];
  createNumberFormat: NumberFormatEntity;
  updateNumberFormat: NumberFormatEntity;
  deleteNumberFormat: Scalars['Boolean'];
  cancelNumberFormat: Scalars['Boolean'];
  updatePaymentMethod: PaymentMethodEntity;
  deletePaymentMethod: Scalars['Boolean'];
  cancelPaymentMethod: Scalars['Boolean'];
  createPaymentMethod: Scalars['Boolean'];
  updateRetainEarningAccountDef: RetainEarningAccountDefEntity;
  deleteRetainEarningAccountDef: Scalars['Boolean'];
  cancelRetainEarningAccountDef: Scalars['Boolean'];
  createRetainEarningAccountDef: Scalars['Boolean'];
  cancelStockItem: Scalars['Boolean'];
  createStockItem: Scalars['Boolean'];
  UpdateStockItemWithWarehouse: Scalars['Boolean'];
  updateStockItem: Scalars['Boolean'];
  deleteStockItem: Scalars['Boolean'];
  createStockItemWarehouse: StockItemWarehouseEntity;
  updateStockItemWarehouse: StockItemWarehouseEntity;
  deleteStockItemWarehouse: Scalars['Boolean'];
  cancelStockItemWarehouse: Scalars['Boolean'];
  createTaxType: TaxTypeEntity;
  updateTaxType: TaxTypeEntity;
  deleteTaxType: Scalars['Boolean'];
  createTaxScheme: TaxSchemeEntity;
  updateTaxScheme: TaxSchemeEntity;
  createTaxEffectiveDate: TaxEffectiveDateEntity;
  updateTaxEffectiveDate: TaxEffectiveDateEntity;
  deleteTaxEffectiveDate: Scalars['Boolean'];
  insertTax: TaxSchemeEntity;
  updateTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createTransactionType: TransactionTypeEntity;
  updateTransactionType: TransactionTypeEntity;
  deleteTransactionType: Scalars['Boolean'];
  cancelTransactionType: Scalars['Boolean'];
  createUOM: AccUomEntity;
  updateUOM: AccUomEntity;
  deleteUOM: Scalars['Boolean'];
  createUOMWithAccount: Scalars['Boolean'];
  updateUOMWithAccount: Scalars['Boolean'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  createUpdateUserActivityLog: Scalars['Boolean'];
  updateWarehouse: Scalars['Boolean'];
  cancelWarehouse: Scalars['Boolean'];
  createWarehouse: Scalars['Boolean'];
  DeleteWarehouse: Scalars['Boolean'];
  AssignWarehouseUser: Scalars['Boolean'];
  UnassignUserWarehouse: Scalars['Boolean'];
  AssignWarehouseItem: Scalars['Boolean'];
  UnassignWarehouseItem: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createProcurementApprovalPolicy: ProcurementApprovalPolicyEntity;
  updateProcurementApprovalPolicy: ProcurementApprovalPolicyEntity;
  cancelProcurementApprovalPolicy: Scalars['Boolean'];
  createProcurementApprovalPolicyAssignment: ProcurementApprovalPolicyAssignmentEntity;
  updateProcurementApprovalPolicyAssignment: ProcurementApprovalPolicyAssignmentEntity;
  cancelProcurementApprovalPolicyAssignment: Scalars['Boolean'];
  deleteProcurementApprovalPolicy: Scalars['Boolean'];
  createBulkPurchasePrice: BulkPurchasePriceHeaderEntity;
  updateBulkPurchasePrice: Scalars['JSON'];
  deleteBulkPurchasePrice: Scalars['Boolean'];
  submitGeneralDO: Scalars['JSON'];
  deleteGeneralDO: Scalars['Boolean'];
  rejectGeneralDO: Scalars['Boolean'];
  actionOnDOStatus: Scalars['Boolean'];
  createFavoriteApprover: Scalars['Boolean'];
  deleteFavoriteApprover: Scalars['Boolean'];
  createFavoritePR: Scalars['Boolean'];
  deleteFavoritePR: Scalars['Boolean'];
  createGeneralPurchasePolicy: Scalars['Boolean'];
  updateGeneralPurchasePolicy: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  CreateGRTN: Scalars['JSON'];
  UpdateGRTN: Scalars['JSON'];
  deleteGRTN: Scalars['Boolean'];
  actionOnGRTNStatus: Scalars['Boolean'];
  GRTNPostApproval: Scalars['Boolean'];
  createGeneralPO: Scalars['Boolean'];
  updateGeneralPO: Scalars['Boolean'];
  createDirectPO: Scalars['JSON'];
  updateDirectPO: Scalars['JSON'];
  deletePO: Scalars['Boolean'];
  actionOnGPOStatus: Scalars['Boolean'];
  cancelClosePO: Scalars['Boolean'];
  duplicatePO: Scalars['JSON'];
  combinePO: Scalars['JSON'];
  createGeneralPR: PrHeaderEntity;
  updateGeneralPR: Scalars['JSON'];
  actionOnGPRStatus: Scalars['Boolean'];
  deleteGeneralPR: Scalars['Boolean'];
  cancelGeneralPR: Scalars['Boolean'];
  cancelClosePR: Scalars['Boolean'];
  patchPRItemOutstandingQtyData: Scalars['Boolean'];
  duplicatePR: Scalars['JSON'];
  createGeneralRFQ: Scalars['Boolean'];
  updateGeneralRFQ: Scalars['Boolean'];
  deleteGeneralRFQ: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  actionOnRFQStatus: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  submitGeneralRFQQuote: Scalars['Boolean'];
  deleteRFQSupplierSubmission: Scalars['Boolean'];
  createMainScheduledPurchases: Scalars['JSON'];
  updateMainScheduledPurchases: Scalars['JSON'];
  createScheduledPurchases: Scalars['JSON'];
  updateScheduledPurchases: Scalars['JSON'];
  deleteScheduledPurchases: Scalars['Boolean'];
  deleteScheduledPurchasesItem: Scalars['Boolean'];
  createPreparePO: Scalars['JSON'];
  AssignScheduledPurchasesUser: Scalars['Boolean'];
  UnassignUserScheduledPurchases: Scalars['Boolean'];
  createItem: ItemEntity;
  updateItem: Scalars['Boolean'];
  cancelItem: Scalars['Boolean'];
  deleteItem: Scalars['Boolean'];
  createBatchItem: Scalars['Boolean'];
  createItemCategory: ItemCategoryEntity;
  updateItemCategory: Scalars['Boolean'];
  deleteItemCategory: Scalars['Boolean'];
  AssignItemCategoryCompany: Scalars['Boolean'];
  UnassignItemCategoryCompany: Scalars['Boolean'];
  addDisallowGPItem: Scalars['Boolean'];
  deleteDisallowGPItem: Scalars['Boolean'];
  createPurchaseItemVariancePolicy: Scalars['Boolean'];
  updatePurchaseItemVariancePolicy: Scalars['Boolean'];
  procurementLogin: LoginResponse;
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
};


export type MutationCreateUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationCreateUserTokenArgs = {
  roleID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationUpdateUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordAccInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordAccInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationChangePasswordForUserArgs = {
  userID: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationToggleUserActivationArgs = {
  userID: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  userID?: Maybe<Scalars['String']>;
};


export type MutationCreateApAdvanceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApAdvanceInput>>;
  input: ApAdvanceInput;
};


export type MutationUpdateApAdvanceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApAdvanceInput>>;
  input: ApAdvanceInput;
};


export type MutationDeleteApAdvanceArgs = {
  AdvanceID: Scalars['String'];
};


export type MutationPostApAdvanceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationCancelApAdvanceArgs = {
  input: ApAdvanceDeleteInput;
};


export type MutationRejectApAdvanceArgs = {
  input: ApAdvanceRejectInput;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationGeneratePaymentArgs = {
  input: ApPaymentInput;
};


export type MutationCreateArAdvanceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ArAdvanceInput>>;
  input: ArAdvanceInput;
};


export type MutationUpdateArAdvanceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ArAdvanceInput>>;
  input: ArAdvanceInput;
};


export type MutationDeleteArAdvanceArgs = {
  AdvanceID: Scalars['String'];
};


export type MutationPostArAdvanceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationCancelArAdvanceArgs = {
  input: ArAdvanceDeleteInput;
};


export type MutationRejectArAdvanceArgs = {
  input: ArAdvanceRejectInput;
  AdvanceIDs: Array<Scalars['String']>;
};


export type MutationCreateAllocationArgs = {
  entityID?: Maybe<Scalars['String']>;
  input?: Maybe<Array<AllocationInput>>;
};


export type MutationUpdateApBatchPaymentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApBatchPaymentInput>>;
  input: ApBatchPaymentInput;
};


export type MutationDeleteApBatchPaymentArgs = {
  BatchPaymentID: Scalars['String'];
};


export type MutationCancelApBatchPaymentArgs = {
  input: ApBatchPaymentDeleteInput;
};


export type MutationCreateApBatchPaymentWithAllocationArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApBatchPaymentInput;
};


export type MutationUpdateApBatchPaymentWithAllocationArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApBatchPaymentInput;
};


export type MutationPayApBatchPaymentArgs = {
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  allocationInput?: Maybe<Array<AllocationInput>>;
  BatchPaymentID: Scalars['String'];
};


export type MutationDeleteBatchPaymentArgs = {
  BatchPaymentID: Scalars['String'];
};


export type MutationRejectBatchPaymentArgs = {
  BatchPaymentID: Scalars['String'];
};


export type MutationCreateSelfBilledConsolidateArgs = {
  itemInput: Array<ConsolidationDetailInput>;
  input: ConsolidationInput;
};


export type MutationCreateEinvoiceConsolidateArgs = {
  itemInput: Array<ConsolidationDetailInput>;
  input: ConsolidationInput;
};


export type MutationCreateEinvoiceConsolidateDocTypeAllArgs = {
  input: Array<ConsolidationDocTypeAllInput>;
};


export type MutationCreateApCreditNoteArgs = {
  docRefInput?: Maybe<Array<CnDocRefInputType>>;
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditNoteItemInput>>;
  input: ApCreditNoteInput;
};


export type MutationUpdateApCreditNoteArgs = {
  docRefInput?: Maybe<Array<CnDocRefInputType>>;
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditNoteItemInput>>;
  input: ApCreditNoteInput;
};


export type MutationDeleteApCreditNoteArgs = {
  CreditNoteID: Scalars['String'];
};


export type MutationPostApCreditNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelApCreditNoteArgs = {
  input: ApCreditNoteDeleteInput;
};


export type MutationRejectApCreditNoteArgs = {
  input: ApCreditNoteRejectInput;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationDeleteArCreditNoteArgs = {
  CreditNoteID: Scalars['String'];
};


export type MutationPostArCreditNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelArCreditNoteArgs = {
  input: ArCreditNoteDeleteInput;
};


export type MutationCreateArCreditNoteArgs = {
  docRefInput?: Maybe<Array<AccArcnDocRefInput>>;
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArCreditNoteItemInput>>;
  input: ArCreditNoteInput;
};


export type MutationUpdateArCreditNoteArgs = {
  docRefInput?: Maybe<Array<AccArcnDocRefInput>>;
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArCreditNoteItemInput>>;
  input: ArCreditNoteInput;
  CreditNoteID: Scalars['String'];
};


export type MutationRejectArCreditNoteArgs = {
  input: ArCreditNoteRejectInput;
  CreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApCreditorCreditNoteArgs = {
  docRefInput?: Maybe<Array<CnDocRefInputType>>;
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditorCreditNoteItemInput>>;
  input: ApCreditorCreditNoteInput;
};


export type MutationUpdateApCreditorCreditNoteArgs = {
  docRefInput?: Maybe<Array<CnDocRefInputType>>;
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApCreditorCreditNoteItemInput>>;
  input: ApCreditorCreditNoteInput;
};


export type MutationDeleteApCreditorCreditNoteArgs = {
  CreditorCreditNoteID: Scalars['String'];
};


export type MutationPostApCreditorCreditNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditorCreditNoteIDs: Array<Scalars['String']>;
};


export type MutationRejectApCreditorCreditNoteArgs = {
  input: ApCreditorCreditNoteRejectInput;
  CreditorCreditNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApCreditNoteItemArgs = {
  input: ApCreditNoteItemInput;
};


export type MutationUpdateApCreditNoteItemArgs = {
  input: ApCreditNoteItemInput;
};


export type MutationDeleteApCreditNoteItemArgs = {
  input: ApCreditNoteItemDeleteInput;
};


export type MutationCancelApCreditNoteItemArgs = {
  input: ApCreditNoteItemDeleteInput;
};


export type MutationCreateApDebitNoteArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApDebitNoteItemInput>>;
  input: ApDebitNoteInput;
};


export type MutationUpdateApDebitNoteArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApDebitNoteItemInput>>;
  input: ApDebitNoteInput;
};


export type MutationDeleteApDebitNoteArgs = {
  DebitNoteID: Scalars['String'];
};


export type MutationPostApDebitNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelApDebitNoteArgs = {
  input: ApDebitNoteDeleteInput;
};


export type MutationRejectApDebitNoteArgs = {
  input: ApDebitNoteRejectInput;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationDeleteArDebitNoteArgs = {
  DebitNoteID: Scalars['String'];
};


export type MutationPostArDebitNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelArDebitNoteArgs = {
  input: ArDebitNoteDeleteInput;
};


export type MutationRejectArDebitNoteArgs = {
  input: ArDebitNoteRejectInput;
  DebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateArDebitNoteArgs = {
  docRefInput?: Maybe<Array<AccArdnDocRefInput>>;
  itemInput?: Maybe<Array<ArDebitNoteItemInput>>;
  input: ArDebitNoteInput;
};


export type MutationUpdateArDebitNoteArgs = {
  docRefInput?: Maybe<Array<AccArdnDocRefInput>>;
  itemInput?: Maybe<Array<ArDebitNoteItemInput>>;
  input: ArDebitNoteInput;
  DebitNoteID: Scalars['String'];
};


export type MutationCreateApCreditorDebitNoteArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApCreditorDebitNoteItemInput>>;
  input: ApCreditorDebitNoteInput;
};


export type MutationUpdateApCreditorDebitNoteArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApCreditorDebitNoteItemInput>>;
  input: ApCreditorDebitNoteInput;
};


export type MutationDeleteApCreditorDebitNoteArgs = {
  CreditorDebitNoteID: Scalars['String'];
};


export type MutationPostApCreditorDebitNoteArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CreditorDebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCancelApCreditorDebitNoteArgs = {
  input: ApCreditorDebitNoteDeleteInput;
};


export type MutationRejectApCreditorDebitNoteArgs = {
  input: ApCreditorDebitNoteRejectInput;
  CreditorDebitNoteIDs: Array<Scalars['String']>;
};


export type MutationCreateApDebitNoteItemArgs = {
  input: ApDebitNoteItemInput;
};


export type MutationUpdateApDebitNoteItemArgs = {
  input: ApDebitNoteItemInput;
};


export type MutationDeleteApDebitNoteItemArgs = {
  input: ApDebitNoteItemDeleteInput;
};


export type MutationCancelApDebitNoteItemArgs = {
  input: ApDebitNoteItemDeleteInput;
};


export type MutationCreateArDebitNoteItemArgs = {
  input: ArDebitNoteItemInput;
};


export type MutationUpdateArDebitNoteItemArgs = {
  input: ArDebitNoteItemInput;
};


export type MutationDeleteArDebitNoteItemArgs = {
  input: ArDebitNoteItemDeleteInput;
};


export type MutationCancelArDebitNoteItemArgs = {
  input: ArDebitNoteItemDeleteInput;
};


export type MutationDeleteApFavoriteInvoiceArgs = {
  FavoriteInvoiceID: Scalars['String'];
};


export type MutationCreateApFavoriteInvoiceArgs = {
  APFavoriteInvoiceItemInput?: Maybe<Array<ApFavoriteInvoiceItemInput>>;
  APFavoriteInvoiceInput: ApFavoriteInvoiceInput;
};


export type MutationDeleteArFavoriteInvoiceArgs = {
  FavoriteInvoiceID: Scalars['String'];
};


export type MutationCreateArFavoriteInvoiceArgs = {
  ARFavoriteInvoiceItemInput?: Maybe<Array<ArFavoriteInvoiceItemInput>>;
  ARFavoriteInvoiceInput: ArFavoriteInvoiceInput;
};


export type MutationCreateApFavoritePaymentArgs = {
  APFavoritePaymentInput: ApFavoritePaymentInput;
};


export type MutationDeleteApFavoritePaymentArgs = {
  FavoritePaymentID: Scalars['String'];
};


export type MutationCreateApInvoiceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApInvoiceItemInput>>;
  input: ApInvoiceInput;
};


export type MutationUpdateApInvoiceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApInvoiceItemInput>>;
  input: ApInvoiceInput;
};


export type MutationDeleteApInvoiceArgs = {
  InvoiceID: Scalars['String'];
};


export type MutationPostApInvoiceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  InvoiceIDs: Array<Scalars['String']>;
};


export type MutationCancelApInvoiceArgs = {
  input: ApInvoiceDeleteInput;
};


export type MutationRejectApInvoiceArgs = {
  input: ApInvoiceRejectInput;
  APInvoiceIDs: Array<Scalars['String']>;
};


export type MutationDuplicateInvoiceArgs = {
  InvoiceID?: Maybe<Scalars['String']>;
};


export type MutationCreateArInvoiceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ArInvoiceItemInput>>;
  input: ArInvoiceInput;
};


export type MutationUpdateArInvoiceArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ArInvoiceItemInput>>;
  input: ArInvoiceInput;
};


export type MutationDeleteArInvoiceArgs = {
  InvoiceID: Scalars['String'];
};


export type MutationPostArInvoiceArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  InvoiceIDs: Array<Scalars['String']>;
};


export type MutationRejectArInvoiceArgs = {
  input: ArInvoiceRejectInput;
  ARInvoiceIDs: Array<Scalars['String']>;
};


export type MutationCreateApInvoiceItemArgs = {
  input: ApInvoiceItemInput;
};


export type MutationUpdateApInvoiceItemArgs = {
  input: ApInvoiceItemInput;
};


export type MutationDeleteApInvoiceItemArgs = {
  input: ApInvoiceItemDeleteInput;
};


export type MutationCancelApInvoiceItemArgs = {
  input: ApInvoiceItemDeleteInput;
};


export type MutationCreateArInvoiceItemArgs = {
  input: ArInvoiceItemInput;
};


export type MutationUpdateArInvoiceItemArgs = {
  input: ArInvoiceItemInput;
};


export type MutationDeleteArInvoiceItemArgs = {
  input: ArInvoiceItemDeleteInput;
};


export type MutationCancelArInvoiceItemArgs = {
  input: ArInvoiceItemDeleteInput;
};


export type MutationCancelArOfficialReceiptArgs = {
  input: ArOfficialReceiptDeleteInput;
};


export type MutationCreateArOfficialReceiptArgs = {
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArOfficialReceiptInput>>;
  input: ArOfficialReceiptInput;
};


export type MutationUpdateArOfficialReceiptArgs = {
  allocationInput?: Maybe<Array<ArAllocationInput>>;
  itemInput?: Maybe<Array<ArOfficialReceiptInput>>;
  input: ArOfficialReceiptInput;
};


export type MutationDeleteArOfficialReceiptArgs = {
  ReceiptID: Scalars['String'];
};


export type MutationPostArOfficialReceiptArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationRejectArOfficialReceiptArgs = {
  input: ArOfficialReceiptRejectInput;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationCancelApPaymentArgs = {
  input: ApPaymentDeleteInput;
};


export type MutationCreateApPaymentArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApPaymentInput;
};


export type MutationUpdateApPaymentArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ApPaymentInput;
};


export type MutationDeleteApPaymentArgs = {
  PaymentID: Scalars['String'];
};


export type MutationPostApPaymentArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationRejectApPaymentArgs = {
  input: ApPaymentRejectInput;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationCancelApRefundArgs = {
  input: ApRefundDeleteInput;
};


export type MutationCreateApRefundArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApRefundInput>>;
  input: ApRefundInput;
};


export type MutationUpdateApRefundArgs = {
  allocationInput?: Maybe<Array<ApAllocationInput>>;
  itemInput?: Maybe<Array<ApRefundInput>>;
  input: ApRefundInput;
};


export type MutationDeleteApRefundArgs = {
  RefundID: Scalars['String'];
};


export type MutationPostApRefundArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationRejectApRefundArgs = {
  input: ApRefundRejectInput;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationCancelArRefundArgs = {
  input: ArRefundDeleteInput;
};


export type MutationCreateArRefundArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ArRefundInput;
};


export type MutationDeleteArRefundArgs = {
  RefundID: Scalars['String'];
};


export type MutationUpdateArRefundArgs = {
  allocationInput?: Maybe<Array<AllocationInput>>;
  input: ArRefundInput;
};


export type MutationPostArRefundArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationRejectArRefundArgs = {
  input: ArRefundRejectInput;
  RefundIDs: Array<Scalars['String']>;
};


export type MutationCreateApSelfBilledArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApSelfBilledItemInput>>;
  input: ApSelfBilledInput;
};


export type MutationUpdateApSelfBilledArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ApSelfBilledItemInput>>;
  input: ApSelfBilledInput;
};


export type MutationDeleteApSelfBilledArgs = {
  SelfBilledID: Scalars['String'];
};


export type MutationPostApSelfBilledArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SelfBilledIDs: Array<Scalars['String']>;
};


export type MutationCancelApSelfBilledArgs = {
  input: ApSelfBilledDeleteInput;
};


export type MutationRejectApSelfBilledArgs = {
  input: ApSelfBilledRejectInput;
  SelfBilledIDs: Array<Scalars['String']>;
};


export type MutationCancelEinvoiceArgs = {
  CompanyID: Scalars['String'];
  SelfBilledID: Scalars['String'];
};


export type MutationGetShareAttachmentArgs = {
  file: Scalars['Upload'];
  CompanyID: Scalars['String'];
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationCreateBankReconciliationArgs = {
  input: BankReconciliationInput;
};


export type MutationUpdateBankReconciliationArgs = {
  input: BankReconciliationInput;
};


export type MutationCancelBankReconciliationArgs = {
  input: BankReconciliationDeleteInput;
};


export type MutationCreateImportBankReconciliationArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput>;
};


export type MutationUpdateImportBankReconciliationArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput>;
};


export type MutationCreateBankReconciliationWihoutExcelArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdateBankReconciliationWihoutExcelArgs = {
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  Description: Scalars['String'];
  Date: Scalars['DateTime'];
  BankAccountID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
};


export type MutationMatchBankReconciliationArgs = {
  Mode: Scalars['String'];
  LedgerIDs: Array<Scalars['String']>;
  BankReconItemIDs?: Maybe<Array<Scalars['String']>>;
  input: BankReconciliationMatchInput;
};


export type MutationDeleteBankReconciliationArgs = {
  BankReconciliationID: Scalars['String'];
};


export type MutationCreateBankReconciliationItemArgs = {
  input: BankReconciliationItemInput;
};


export type MutationUpdateBankReconciliationItemArgs = {
  input: BankReconciliationItemInput;
};


export type MutationDeleteBankReconciliationItemArgs = {
  input: BankReconciliationItemDeleteInput;
};


export type MutationCancelBankReconciliationItemArgs = {
  input: BankReconciliationItemDeleteInput;
};


export type MutationCreateBankReconciliationMatchArgs = {
  input: BankReconciliationMatchInput;
};


export type MutationUpdateBankReconciliationMatchArgs = {
  input: BankReconciliationMatchInput;
};


export type MutationDeleteBankReconciliationMatchArgs = {
  input: BankReconciliationMatchDeleteInput;
};


export type MutationCancelBankReconciliationMatchArgs = {
  input: BankReconciliationMatchDeleteInput;
};


export type MutationCreateBankTransferArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<BankTransferInput>>;
  input: BankTransferInput;
};


export type MutationUpdateBankTransferArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<BankTransferInput>>;
  input: BankTransferInput;
};


export type MutationDeleteBankTransferArgs = {
  BankTransferID: Scalars['String'];
};


export type MutationCancelBankTransferArgs = {
  input: BankTransferDeleteInput;
};


export type MutationPostCbBankTransferArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  BankTransferIDs: Array<Scalars['String']>;
};


export type MutationRejectBankTransferArgs = {
  input: BankTransferRejectInput;
  BankTransferIDs: Array<Scalars['String']>;
};


export type MutationCreateExternalLedgerArgs = {
  DocRefName: Scalars['String'];
  CompanyID: Scalars['String'];
  ExternalLedgerImportInput: Scalars['String'];
};


export type MutationCbImportLedgerArgs = {
  input: Array<CbImportLedgerInput>;
};


export type MutationDeleteFavoritePaymentArgs = {
  FavoritePaymentID: Scalars['String'];
};


export type MutationCreateFavoritePaymentArgs = {
  FavoritePaymentItemInput?: Maybe<Array<FavoritePaymentItemInput>>;
  FavoritePaymentInput: FavoritePaymentInput;
};


export type MutationDeleteFavoriteReceiptArgs = {
  FavoriteReceiptID: Scalars['String'];
};


export type MutationCreateFavoriteReceiptArgs = {
  FavoriteReceiptItemInput?: Maybe<Array<FavoriteReceiptItemInput>>;
  FavoriteReceiptInput: FavoriteReceiptInput;
};


export type MutationCreateLedgerArgs = {
  input: LedgerInput;
};


export type MutationUpdateLedgerArgs = {
  input: LedgerInput;
};


export type MutationDeleteLedgerArgs = {
  input: LedgerDeleteInput;
};


export type MutationCancelLedgerArgs = {
  input: LedgerDeleteInput;
};


export type MutationCreateLedgerItemArgs = {
  input: LedgerItemInput;
};


export type MutationUpdateLedgerItemArgs = {
  input: LedgerItemInput;
};


export type MutationDeleteLedgerItemArgs = {
  input: LedgerItemDeleteInput;
};


export type MutationCancelLedgerItemArgs = {
  input: LedgerItemDeleteInput;
};


export type MutationCreatePaymentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<PaymentItemInput>>;
  input: PaymentInput;
};


export type MutationUpdatePaymentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<PaymentItemInput>>;
  input: PaymentInput;
};


export type MutationDeletePaymentArgs = {
  PaymentID: Scalars['String'];
};


export type MutationCancelPaymentArgs = {
  input: PaymentDeleteInput;
};


export type MutationPostCbPaymentArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationRejectPaymentArgs = {
  input: PaymentRejectInput;
  PaymentIDs: Array<Scalars['String']>;
};


export type MutationCreatePaymentItemArgs = {
  input: PaymentItemInput;
};


export type MutationUpdatePaymentItemArgs = {
  input: PaymentItemInput;
};


export type MutationDeletePaymentItemArgs = {
  input: PaymentItemDeleteInput;
};


export type MutationCancelPaymentItemArgs = {
  input: PaymentItemDeleteInput;
};


export type MutationCreateReceiptArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ReceiptItemInput>>;
  input: ReceiptInput;
};


export type MutationUpdateReceiptArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<ReceiptItemInput>>;
  input: ReceiptInput;
};


export type MutationDeleteReceiptArgs = {
  ReceiptID: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  input: ReceiptDeleteInput;
};


export type MutationPostCbReceiptArgs = {
  RefTable: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationRejectReceiptArgs = {
  input: ReceiptRejectInput;
  ReceiptIDs: Array<Scalars['String']>;
};


export type MutationCreateReceiptItemArgs = {
  input: ReceiptItemInput;
};


export type MutationUpdateReceiptItemArgs = {
  input: ReceiptItemInput;
};


export type MutationDeleteReceiptItemArgs = {
  input: ReceiptItemDeleteInput;
};


export type MutationCancelReceiptItemArgs = {
  input: ReceiptItemDeleteInput;
};


export type MutationDeleteCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateDeleteInput;
};


export type MutationCancelCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateDeleteInput;
};


export type MutationCreateCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateInput;
};


export type MutationUpdateCompanyLetterTemplateArgs = {
  input: CompanyLetterTemplateInput;
};


export type MutationDeleteLetterTemplateArgs = {
  input: LetterTemplateDeleteInput;
};


export type MutationCancelLetterTemplateArgs = {
  input: LetterTemplateDeleteInput;
};


export type MutationCreateLetterTemplateArgs = {
  input: LetterTemplateInput;
};


export type MutationUpdateLetterTemplateArgs = {
  input: LetterTemplateInput;
};


export type MutationCreateStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceInput;
};


export type MutationUpdateStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceInput;
};


export type MutationDeleteStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceDeleteInput;
};


export type MutationCancelStandardLetterDataSourceArgs = {
  input: StandardLetterDataSourceDeleteInput;
};


export type MutationCreateAuditAdjustmentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<AuditAdjustmentItemInput>>;
  input: AuditAdjustmentInput;
};


export type MutationUpdateAuditAdjustmentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<AuditAdjustmentItemInput>>;
  input: AuditAdjustmentInput;
};


export type MutationDeleteAuditAdjustmentArgs = {
  AuditAdjustmentID: Scalars['String'];
};


export type MutationCancelAuditAdjustmentArgs = {
  input: AuditAdjustmentDeleteInput;
};


export type MutationPostAuditAdjustmentArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  AuditAdjustmentIDs: Array<Scalars['String']>;
};


export type MutationRejectAuditAdjustmentArgs = {
  input: AuditAdjustmentRejectInput;
  AuditAdjustmentIDs: Array<Scalars['String']>;
};


export type MutationCreateAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemInput;
};


export type MutationUpdateAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemInput;
};


export type MutationDeleteAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemDeleteInput;
};


export type MutationCancelAuditAdjustmentItemArgs = {
  input: AuditAdjustmentItemDeleteInput;
};


export type MutationCreateBudgetArgs = {
  input: BudgetInput;
};


export type MutationUpdateBudgetArgs = {
  input: BudgetInput;
};


export type MutationDeleteBudgetArgs = {
  input: BudgetDeleteInput;
};


export type MutationCancelBudgetArgs = {
  input: BudgetDeleteInput;
};


export type MutationCreateBudgetWithDetailArgs = {
  BudgetItemPeriodInput?: Maybe<Array<BudgetItemPeriodInput>>;
  BudgetItemCostCentreInput?: Maybe<Array<BudgetItemCostCentreInput>>;
  BudgetItemInput: Array<BudgetItemInput>;
  BudgetInput: BudgetInput;
};


export type MutationUpdateBudgetWithDetailArgs = {
  BudgetItemPeriodInput?: Maybe<Array<BudgetItemPeriodInput>>;
  BudgetItemCostCentreInput?: Maybe<Array<BudgetItemCostCentreInput>>;
  BudgetItemInput: Array<BudgetItemInput>;
  BudgetInput: BudgetInput;
};


export type MutationSetBudgetDefaultArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
  BudgetID: Scalars['String'];
};


export type MutationCreateBudgetItemArgs = {
  input: BudgetItemInput;
};


export type MutationUpdateBudgetItemArgs = {
  input: BudgetItemInput;
};


export type MutationDeleteBudgetItemArgs = {
  input: BudgetItemDeleteInput;
};


export type MutationCancelBudgetItemArgs = {
  input: BudgetItemDeleteInput;
};


export type MutationCreateBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreInput;
};


export type MutationUpdateBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreInput;
};


export type MutationDeleteBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreDeleteInput;
};


export type MutationCancelBudgetItemCostCentreArgs = {
  input: BudgetItemCostCentreInput;
};


export type MutationCreateBudgetItemPeriodArgs = {
  input: BudgetItemPeriodInput;
};


export type MutationUpdateBudgetItemPeriodArgs = {
  input: BudgetItemPeriodInput;
};


export type MutationDeleteBudgetItemPeriodArgs = {
  input: BudgetItemPeriodDeleteInput;
};


export type MutationCancelBudgetItemPeriodArgs = {
  input: BudgetItemPeriodInput;
};


export type MutationCreateExternalJournalArgs = {
  DocRefName: Scalars['String'];
  CompanyID: Scalars['String'];
  ExtJournalInput: ExcelJournalInput;
};


export type MutationDeleteFavoriteJournalProcessingArgs = {
  FavoriteJournalProcessingID: Scalars['String'];
};


export type MutationCreateFavoriteJournalProcessingArgs = {
  FavoriteJournalProcessingItemInput?: Maybe<Array<FavoriteJournalProcessingItemInput>>;
  FavoriteJournalProcessingInput: FavoriteJournalProcessingInput;
};


export type MutationCreateDataSourceSettingsArgs = {
  input: DataSourceSettingsInput;
};


export type MutationUpdateDataSourceSettingsArgs = {
  input: DataSourceSettingsInput;
};


export type MutationDeleteDataSourceSettingsArgs = {
  input: DataSourceSettingsDeleteInput;
};


export type MutationCancelDataSourceSettingsArgs = {
  input: DataSourceSettingsDeleteInput;
};


export type MutationCreateReportSettingsArgs = {
  input: ReportSettingsInput;
};


export type MutationUpdateReportSettingsArgs = {
  input: ReportSettingsInput;
};


export type MutationDeleteReportSettingsArgs = {
  input: ReportSettingsDeleteInput;
};


export type MutationCancelReportSettingsArgs = {
  input: ReportSettingsDeleteInput;
};


export type MutationUploadReportTemplateArgs = {
  file: Scalars['Upload'];
  ReportSettingsID: Scalars['String'];
};


export type MutationExportGlToDotNetArgs = {
  JournalIDs: Array<Scalars['String']>;
  input: GlExportDotNetInput;
};


export type MutationUpdateJournalArgs = {
  JournalItemInput: Array<JournalItemInput>;
  JournalInput: JournalInput;
};


export type MutationDeleteJournalArgs = {
  input: JournalDeleteInput;
};


export type MutationCancelJournalArgs = {
  input: JournalDeleteInput;
};


export type MutationCreateJournalArgs = {
  JournalItemInput: Array<JournalItemInput>;
  JournalInput: JournalInput;
};


export type MutationGlImportLedgerArgs = {
  input: Array<GlImportLedgerInput>;
};


export type MutationCreateJournalItemArgs = {
  input: JournalItemInput;
};


export type MutationUpdateJournalItemArgs = {
  input: JournalItemInput;
};


export type MutationDeleteJournalItemArgs = {
  input: JournalItemDeleteInput;
};


export type MutationCancelJournalItemArgs = {
  input: JournalItemDeleteInput;
};


export type MutationCreateJournalProcessingArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<JournalProcessingItemInput>>;
  input: JournalProcessingInput;
};


export type MutationUpdateJournalProcessingArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<JournalProcessingItemInput>>;
  input: JournalProcessingInput;
};


export type MutationDeleteJournalProcessingArgs = {
  JournalProcessingID: Scalars['String'];
};


export type MutationCancelJournalProcessingArgs = {
  input: JournalProcessingDeleteInput;
};


export type MutationPostJournalProcessingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  JournalProcessingIDs: Array<Scalars['String']>;
};


export type MutationRejectJournalProcessingArgs = {
  input: JournalProcessingRejectInput;
  JournalProcessingIDs: Array<Scalars['String']>;
};


export type MutationProcessMonthEndClosingArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationRevertMonthEndClosingArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationRevertYearEndClosingArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationProcessYearEndClosingArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type MutationCreateRecurringJournalArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<RecurringJournalItemInput>>;
  input: RecurringJournalInput;
};


export type MutationUpdateRecurringJournalArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<RecurringJournalItemInput>>;
  input: RecurringJournalInput;
};


export type MutationDeleteRecurringJournalArgs = {
  RecurringJournalID: Scalars['String'];
};


export type MutationCancelRecurringJournalArgs = {
  RecurringJournalID: Scalars['String'];
};


export type MutationPostRecurringJournalArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  RecurringJournalIDs: Array<Scalars['String']>;
};


export type MutationRejectRecurringJournalArgs = {
  input: RecurringJournalRejectInput;
  RecurringJournalIDs: Array<Scalars['String']>;
};


export type MutationGenerateRecurringJournalArgs = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type MutationCreateRecurringJournalItemArgs = {
  input: RecurringJournalItemInput;
};


export type MutationUpdateRecurringJournalItemArgs = {
  input: RecurringJournalItemInput;
};


export type MutationDeleteRecurringJournalItemArgs = {
  input: RecurringJournalItemDeleteInput;
};


export type MutationCancelRecurringJournalItemArgs = {
  input: RecurringJournalItemDeleteInput;
};


export type MutationUpdateRoutineJournalArgs = {
  input: RoutineJournalInput;
};


export type MutationDeleteRoutineJournalArgs = {
  input: RoutineJournalDeleteInput;
};


export type MutationCancelRoutineJournalArgs = {
  input: RoutineJournalDeleteInput;
};


export type MutationCreateRoutineJournalArgs = {
  RoutineJournalItemInput: Array<RoutineJournalItemInput>;
  RoutineJournalInput: RoutineJournalInput;
};


export type MutationUpdateRoutineJournalsArgs = {
  RoutineItemInput: Array<RoutineJournalItemInput>;
  RoutineJournalInput: RoutineJournalInput;
  CompanyID: Scalars['String'];
  RoutineJournalID: Scalars['String'];
};


export type MutationCreateRoutineJournalItemArgs = {
  input: RoutineJournalItemInput;
};


export type MutationUpdateRoutineJournalItemArgs = {
  input: RoutineJournalItemInput;
};


export type MutationDeleteRoutineJournalItemArgs = {
  input: RoutineJournalItemDeleteInput;
};


export type MutationCancelRoutineJournalItemArgs = {
  input: RoutineJournalItemDeleteInput;
};


export type MutationPostGlExportArgs = {
  prefix: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  GLDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationCreateGlExportArgs = {
  prefix: Scalars['String'];
  CompanyID: Scalars['String'];
  GLDate: Scalars['DateTime'];
  input?: Maybe<Array<GlExportInput>>;
};


export type MutationDeleteCycleCountArgs = {
  CycleCountID: Scalars['String'];
};


export type MutationCancelCycleCountArgs = {
  input: CycleCountDeleteInput;
};


export type MutationCreateCycleCountArgs = {
  itemInput?: Maybe<Array<CycleCountItemInput>>;
  input: CycleCountInput;
};


export type MutationUpdateCycleCountArgs = {
  itemInput?: Maybe<Array<CycleCountItemInput>>;
  input: CycleCountInput;
};


export type MutationPostCycleCountArgs = {
  input: CycleCountRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockReconArgs = {
  itemInput?: Maybe<Array<StockReconItemInput>>;
  input: StockReconInput;
};


export type MutationCreateCycleCountItemArgs = {
  input: CycleCountItemInput;
};


export type MutationUpdateCycleCountItemArgs = {
  input: CycleCountItemInput;
};


export type MutationDeleteCycleCountItemArgs = {
  input: CycleCountItemDeleteInput;
};


export type MutationCancelCycleCountItemArgs = {
  input: CycleCountItemDeleteInput;
};


export type MutationCreateFavoriteStockRequisitionArgs = {
  FavoriteStockRequisitionItemInput?: Maybe<Array<FavoriteStockRequisitionItemInput>>;
  FavoriteStockRequisitionInput: FavoriteStockRequisitionInput;
};


export type MutationDeleteFavoriteStockRequisitionArgs = {
  FavoriteStockRequisitionID: Scalars['String'];
};


export type MutationCreateFavoriteStockTransferRequisitionArgs = {
  FavoriteStockTransferRequisitionItemInput?: Maybe<Array<FavoriteStockTransferRequisitionItemInput>>;
  FavoriteStockTransferRequisitionInput: FavoriteStockTransferRequisitionInput;
};


export type MutationDeleteFavoriteStockTransferRequisitionArgs = {
  FavoriteStockTransferRequisitionID: Scalars['String'];
};


export type MutationCreateInventoryPolicyArgs = {
  input: InventoryPolicyInput;
};


export type MutationUpdateInventoryPolicyArgs = {
  input: InventoryPolicyInput;
};


export type MutationDeleteInventoryPolicyArgs = {
  input: InventoryPolicyDeleteInput;
};


export type MutationCancelInventoryPolicyArgs = {
  input: InventoryPolicyDeleteInput;
};


export type MutationDeleteStockAdjustmentArgs = {
  StockAdjustmentID: Scalars['String'];
};


export type MutationCancelStockAdjustmentArgs = {
  input: StockAdjustmentDeleteInput;
};


export type MutationCreateStockAdjustmentArgs = {
  itemInput?: Maybe<Array<StockAdjustmentItemInput>>;
  input: StockAdjustmentInput;
};


export type MutationUpdateStockAdjustmentArgs = {
  itemInput?: Maybe<Array<StockAdjustmentItemInput>>;
  input: StockAdjustmentInput;
};


export type MutationPostStockAdjustmentArgs = {
  input: StockAdjustmentRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockAdjustmentItemArgs = {
  input: StockAdjustmentItemInput;
};


export type MutationUpdateStockAdjustmentItemArgs = {
  input: StockAdjustmentItemInput;
};


export type MutationDeleteStockAdjustmentItemArgs = {
  input: StockAdjustmentItemDeleteInput;
};


export type MutationCancelStockAdjustmentItemArgs = {
  input: StockAdjustmentItemDeleteInput;
};


export type MutationCreateStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonInput;
};


export type MutationUpdateStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonInput;
};


export type MutationDeleteStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonDeleteInput;
};


export type MutationCancelStockAdjustmentReasonArgs = {
  input: StockAdjustmentReasonDeleteInput;
};


export type MutationUpdateDefaultSaReasonArgs = {
  StockAdjustmentReasonID: Scalars['String'];
};


export type MutationCancelStockCategoryArgs = {
  input: StockCategoryDeleteInput;
};


export type MutationDeleteStockCategoryArgs = {
  StockCategoryID: Scalars['String'];
};


export type MutationCreateStockCategoryArgs = {
  input: StockCategoryInput;
};


export type MutationUpdateStockCategoryArgs = {
  input: StockCategoryInput;
};


export type MutationCreateStockCostAdjustmentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<StockCostAdjustmentItemInput>>;
  input: StockCostAdjustmentInput;
};


export type MutationUpdateStockCostAdjustmentArgs = {
  docRefInput?: Maybe<Array<DocRefInputType>>;
  itemInput?: Maybe<Array<StockCostAdjustmentItemInput>>;
  input: StockCostAdjustmentInput;
};


export type MutationDeleteStockCostAdjustmentArgs = {
  StockCostAdjustmentID: Scalars['String'];
};


export type MutationCancelStockCostAdjustmentArgs = {
  input: StockCostAdjustmentDeleteInput;
};


export type MutationPostStockCostAdjustmentArgs = {
  CompanyID: Scalars['String'];
  input: StockCostAdjustmentRejectInput;
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationDeleteStockIssueArgs = {
  StockIssueID: Scalars['String'];
};


export type MutationCancelStockIssueArgs = {
  input: StockIssueDeleteInput;
};


export type MutationCreateStockIssueArgs = {
  itemInput?: Maybe<Array<StockIssueItemInput>>;
  input: StockIssueInput;
};


export type MutationUpdateStockIssueArgs = {
  itemInput?: Maybe<Array<StockIssueItemInput>>;
  input: StockIssueInput;
};


export type MutationPostStockIssueArgs = {
  input: StockIssueRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockIssueItemArgs = {
  input: StockIssueItemInput;
};


export type MutationUpdateStockIssueItemArgs = {
  input: StockIssueItemInput;
};


export type MutationDeleteStockIssueItemArgs = {
  input: StockIssueItemDeleteInput;
};


export type MutationCancelStockIssueItemArgs = {
  input: StockIssueItemDeleteInput;
};


export type MutationDeleteStockReceiptArgs = {
  StockReceiptID: Scalars['String'];
};


export type MutationCancelStockReceiptArgs = {
  input: StockReceiptDeleteInput;
};


export type MutationCreateStockReceiptArgs = {
  itemInput?: Maybe<Array<StockReceiptItemInput>>;
  input: StockReceiptInput;
};


export type MutationUpdateStockReceiptArgs = {
  itemInput?: Maybe<Array<StockReceiptItemInput>>;
  input: StockReceiptInput;
};


export type MutationPostStockReceiptArgs = {
  input: StockReceiptRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationRevokeStockReceiptArgs = {
  StockReceiptID: Scalars['String'];
};


export type MutationCreateStockReceiptItemArgs = {
  input: StockReceiptItemInput;
};


export type MutationUpdateStockReceiptItemArgs = {
  input: StockReceiptItemInput;
};


export type MutationDeleteStockReceiptItemArgs = {
  input: StockReceiptItemDeleteInput;
};


export type MutationCancelStockReceiptItemArgs = {
  input: StockReceiptItemDeleteInput;
};


export type MutationDeleteStockRequisitionArgs = {
  StockRequisitionID: Scalars['String'];
};


export type MutationCancelStockRequisitionArgs = {
  input: StockRequisitionCancelInput;
};


export type MutationCreateStockRequisitionArgs = {
  itemInput?: Maybe<Array<StockRequisitionItemInput>>;
  input: StockRequisitionInput;
};


export type MutationUpdateStockRequisitionArgs = {
  itemInput?: Maybe<Array<StockRequisitionItemInput>>;
  input: StockRequisitionInput;
};


export type MutationPostStockRequisitionArgs = {
  input: StockRequisitionRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockRequisitionItemArgs = {
  input: StockRequisitionItemInput;
};


export type MutationUpdateStockRequisitionItemArgs = {
  input: StockRequisitionItemInput;
};


export type MutationDeleteStockRequisitionItemArgs = {
  input: StockRequisitionItemDeleteInput;
};


export type MutationCancelStockRequisitionItemArgs = {
  input: StockRequisitionItemDeleteInput;
};


export type MutationDeleteStockTransferArgs = {
  StockTransferID: Scalars['String'];
};


export type MutationCancelStockTransferArgs = {
  input: StockTransferDeleteInput;
};


export type MutationCreateStockTransferArgs = {
  itemInput?: Maybe<Array<StockTransferItemInput>>;
  input: StockTransferInput;
};


export type MutationUpdateStockTransferArgs = {
  itemInput?: Maybe<Array<StockTransferItemInput>>;
  input: StockTransferInput;
};


export type MutationPostStockTransferArgs = {
  input: StockTransferRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCreateStockTransferItemArgs = {
  input: StockTransferItemInput;
};


export type MutationUpdateStockTransferItemArgs = {
  input: StockTransferItemInput;
};


export type MutationDeleteStockTransferItemArgs = {
  input: StockTransferItemDeleteInput;
};


export type MutationCancelStockTransferItemArgs = {
  input: StockTransferItemDeleteInput;
};


export type MutationDeleteStockTransferRequisitionArgs = {
  StockTransferRequisitionID: Scalars['String'];
};


export type MutationCreateStockTransferRequisitionArgs = {
  itemInput?: Maybe<Array<StockTransferRequisitionItemInput>>;
  input: StockTransferRequisitionInput;
};


export type MutationUpdateStockTransferRequisitionArgs = {
  itemInput?: Maybe<Array<StockTransferRequisitionItemInput>>;
  input: StockTransferRequisitionInput;
};


export type MutationPostStockTransferRequisitionArgs = {
  input: StockTransferRequisitionRejectInput;
  CompanyID: Scalars['String'];
  ApprovalStatus: Scalars['String'];
  postIDs: Array<Scalars['String']>;
};


export type MutationCancelStockTransferRequisitionArgs = {
  input: StockTransferRequisitionCancelInput;
};


export type MutationCreateStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemInput;
};


export type MutationUpdateStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemInput;
};


export type MutationDeleteStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemDeleteInput;
};


export type MutationCancelStockTransferRequisitionItemArgs = {
  input: StockTransferRequisitionItemDeleteInput;
};


export type MutationCreateLeaseContractArgs = {
  input: LeaseContractInput;
};


export type MutationUpdateLeaseContractArgs = {
  input: LeaseContractInput;
};


export type MutationDeleteLeaseContractArgs = {
  input: LeaseContractDeleteInput;
};


export type MutationCancelLeaseContractArgs = {
  input: LeaseContractDeleteInput;
};


export type MutationPostLeaseContractArgs = {
  LeaseContractID?: Maybe<Scalars['String']>;
};


export type MutationRejectLeaseContractArgs = {
  input: LeaseContractRejectInput;
  LeaseContractID?: Maybe<Scalars['String']>;
};


export type MutationUpdateAccountMappingArgs = {
  input: AccountMappingInput;
};


export type MutationDeleteAccountMappingArgs = {
  input: AccountMappingDeleteInput;
};


export type MutationCancelAccountMappingArgs = {
  input: AccountMappingDeleteInput;
};


export type MutationCreateAccountMappingArgs = {
  input: AccountMappingInput;
};


export type MutationDeleteCompanyArgs = {
  input: CompanyDeleteInput;
};


export type MutationCancelCompanyArgs = {
  input: CompanyDeleteInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyProfileArgs = {
  CompanyID: Scalars['String'];
};


export type MutationCreateCompanyImportArgs = {
  TemplateStr: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  Type: DocumentType;
  AccountID: Scalars['String'];
};


export type MutationPatchApPaymentArgs = {
  AccountID: Scalars['String'];
};


export type MutationPatchNullGlRecurringDescriptionDataArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type MutationPatchNullBaseUomInStockItemDataArgs = {
  tableName: Scalars['String'];
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  AccountID: Scalars['String'];
};


export type MutationInsertAdjustmentReasonArgs = {
  AccountID: Scalars['String'];
};


export type MutationCreateSystemJournalTypeArgs = {
  CompanyIDs: Array<Scalars['String']>;
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<AcctPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<AcctPermission>;
  input: RoleInput;
};


export type MutationDuplicateRolePermissionArgs = {
  name: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationAssignEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationUnassignEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<AccRoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAccountPeriodArgs = {
  input: AccountPeriodDeleteInput;
};


export type MutationCancelAccountPeriodArgs = {
  input: AccountPeriodDeleteInput;
};


export type MutationCreateAccountPeriodArgs = {
  input?: Maybe<Array<AccountPeriodInput>>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationUpdateAccountPeriodArgs = {
  input: Array<AccountPeriodInput>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationCloseAccountPeriodArgs = {
  input: Array<AccountPeriodInput>;
  AccountPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationUpdateArAgingArgs = {
  input: AgingInput;
};


export type MutationDeleteArAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCancelArAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationUpdateApAgingArgs = {
  input: AgingInput;
};


export type MutationDeleteApAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCancelApAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCreateArAgingArgs = {
  input: AgingInput;
};


export type MutationCreateApAgingArgs = {
  input: AgingInput;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationCreateUpdateDeleteApprovalPolicyPoArgs = {
  deleteInput?: Maybe<Array<ApprovalPolicyDeleteInput>>;
  input: Array<ApprovalPolicyInput>;
};


export type MutationCreateUpdateDeleteApprovalPolicyAssignmentPoArgs = {
  deleteInput?: Maybe<Array<ApprovalPolicyAssignmentDeleteInput>>;
  input: Array<ApprovalPolicyAssignmentInput>;
};


export type MutationCreateUpdateDeleteApprovalPolicyApArgs = {
  deleteInput?: Maybe<Array<ApprovalPolicyDeleteInput>>;
  input: Array<ApprovalPolicyInput>;
};


export type MutationCreateUpdateDeleteApprovalPolicyAssignmentApArgs = {
  deleteInput?: Maybe<Array<ApprovalPolicyAssignmentDeleteInput>>;
  input: Array<ApprovalPolicyAssignmentInput>;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  BankAccountID: Scalars['String'];
};


export type MutationActivateBankAccountArgs = {
  BankAccountID: Scalars['String'];
};


export type MutationCreateBankAccountImportArgs = {
  CompanyID: Scalars['String'];
  TemplateStr: Scalars['String'];
};


export type MutationCreateBankProfileArgs = {
  input: BankProfileInput;
};


export type MutationUpdateBankProfileArgs = {
  input: BankProfileInput;
};


export type MutationDeleteBankProfileArgs = {
  input: BankProfileDeleteInput;
};


export type MutationCancelBankProfileArgs = {
  input: BankProfileDeleteInput;
};


export type MutationCreateBankProfileImportArgs = {
  TemplateStr: Scalars['String'];
};


export type MutationCreateBillItemArgs = {
  input: BillItemInput;
};


export type MutationUpdateBillItemArgs = {
  input: BillItemInput;
};


export type MutationDeleteBillItemArgs = {
  input: BillItemDeleteInput;
};


export type MutationCancelBillItemArgs = {
  input: BillItemDeleteInput;
};


export type MutationUpdateCoaStructureArgs = {
  input: CoaStructureInput;
};


export type MutationDeleteCoaStructureArgs = {
  input: CoaStructureDeleteInput;
};


export type MutationCancelCoaStructureArgs = {
  input: CoaStructureDeleteInput;
};


export type MutationCreateCoaStructureArgs = {
  COAStructureInput: Array<CoaStructureInput>;
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationUpdateCostCentreArgs = {
  input: CostCentreInput;
};


export type MutationCancelCostCentreArgs = {
  input: CostCentreDeleteInput;
};


export type MutationCreateCostCentreDefinitionAsgmtArgs = {
  input: CostCentreDefinitionAsgmtInput;
};


export type MutationDeleteCostCentreArgs = {
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
};


export type MutationCreateCostCentreArgs = {
  input: CostCentreInput;
};


export type MutationSetDefaultCostCentreArgs = {
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
};


export type MutationAssignCostCentreCoaArgs = {
  CostCentreArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUnassignCostCentreCoaArgs = {
  CostCentreCOAArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdateCostCentreDefinitionHeaderArgs = {
  input: CostCentreDefinitionHeaderInput;
};


export type MutationCancelCostCentreDefinitionHeaderArgs = {
  input: CostCentreDefinitionHeaderDeleteInput;
};


export type MutationUpdateCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailInput;
};


export type MutationDeleteCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailDeleteInput;
};


export type MutationCancelCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailDeleteInput;
};


export type MutationCreateCostCentreDefinitionArgs = {
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput>;
  CostCentreDefinitionHeaderInput: CostCentreDefinitionHeaderInput;
};


export type MutationCreateUpdateCostCentreDefinitionArgs = {
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput>;
  CompanyID: Scalars['String'];
};


export type MutationDeleteCostCentreDefinitionArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type MutationCreateCostCentreTypeArgs = {
  input: CostCentreTypeInput;
};


export type MutationUpdateCostCentreTypeArgs = {
  input: CostCentreTypeInput;
};


export type MutationDeleteCostCentreTypeArgs = {
  input: CostCentreTypeDeleteInput;
};


export type MutationCancelCostCentreTypeArgs = {
  input: CostCentreTypeDeleteInput;
};


export type MutationCreateUpdateCostCentreTypeArgs = {
  CostCentreTypeArr: Array<CostCentreTypeInput>;
};


export type MutationUpdateCreditCardTypeArgs = {
  input: CreditCardTypeInput;
};


export type MutationDeleteCreditCardTypeArgs = {
  input: CreditCardTypeDeleteInput;
};


export type MutationCancelCreditCardTypeArgs = {
  input: CreditCardTypeDeleteInput;
};


export type MutationCreateCreditCardTypeArgs = {
  CreditCardTypeInput: Array<CreditCardTypeInput>;
};


export type MutationUpdateCreditorAccountArgs = {
  input: CreditorAccountInput;
};


export type MutationDeleteCreditorAccountArgs = {
  input: CreditorAccountDeleteInput;
};


export type MutationCancelCreditorAccountArgs = {
  input: CreditorAccountDeleteInput;
};


export type MutationCreateCreditorAccountArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
};


export type MutationUpdateCreditorAccountWithItemArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
};


export type MutationCreateCreditorAccountWithAccountArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
  userID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationCreateCreditorProfileImportArgs = {
  TemplateStr: Scalars['String'];
};


export type MutationUpdateCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationDeleteCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentDeleteInput;
};


export type MutationCancelCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentDeleteInput;
};


export type MutationAssignCreditorAccountCompanyArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationUnassignCreditorAccountCompanyArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationCreateCreditorContactArgs = {
  input: CreditorContactInput;
};


export type MutationUpdateCreditorContactArgs = {
  input: CreditorContactInput;
};


export type MutationDeleteCreditorContactArgs = {
  input: CreditorContactDeleteInput;
};


export type MutationCancelCreditorContactArgs = {
  input: CreditorContactDeleteInput;
};


export type MutationCreateCreditorDirectorArgs = {
  input: CreditorDirectorInput;
};


export type MutationUpdateCreditorDirectorArgs = {
  input: CreditorDirectorInput;
};


export type MutationDeleteCreditorDirectorArgs = {
  input: CreditorDirectorDeleteInput;
};


export type MutationCancelCreditorDirectorArgs = {
  input: CreditorDirectorDeleteInput;
};


export type MutationCreateCreditorTypeArgs = {
  input: CreditorTypeInput;
};


export type MutationUpdateCreditorTypeArgs = {
  input: CreditorTypeInput;
};


export type MutationDeleteCreditorTypeArgs = {
  input: CreditorTypeDeleteInput;
};


export type MutationCancelCreditorTypeArgs = {
  input: CreditorTypeDeleteInput;
};


export type MutationCreateCreditorTypeWithAccountArgs = {
  input: CreditorTypeInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationUpdateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationDeleteCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationCancelCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationCancelCurrencyExchangeArgs = {
  input: CurrencyExchangeDeleteInput;
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  CurrencyExchangeID: Scalars['String'];
};


export type MutationUpdateDebtorAccountArgs = {
  input: DebtorAccountInput;
};


export type MutationDeleteDebtorAccountArgs = {
  input: DebtorAccountDeleteInput;
};


export type MutationCancelDebtorAccountArgs = {
  input: DebtorAccountDeleteInput;
};


export type MutationCreateDebtorAccountArgs = {
  input: DebtorAccountInput;
  contactItem?: Maybe<Array<DebtorContactInput>>;
};


export type MutationUpdateDebtorAccountWithItemArgs = {
  contactItem?: Maybe<Array<DebtorContactInput>>;
  input: DebtorAccountInput;
};


export type MutationCreateDebtorAccountImportArgs = {
  CompanyID: Scalars['String'];
  TemplateStr: Scalars['String'];
};


export type MutationCreateDebtorContactArgs = {
  input: DebtorContactInput;
};


export type MutationUpdateDebtorContactArgs = {
  input: DebtorContactInput;
};


export type MutationDeleteDebtorContactArgs = {
  input: DebtorContactDeleteInput;
};


export type MutationCancelDebtorContactArgs = {
  input: DebtorContactDeleteInput;
};


export type MutationCreateDebtorTypeArgs = {
  input: DebtorTypeInput;
};


export type MutationUpdateDebtorTypeArgs = {
  input: DebtorTypeInput;
};


export type MutationDeleteDebtorTypeArgs = {
  input: DebtorTypeDeleteInput;
};


export type MutationCancelDebtorTypeArgs = {
  input: DebtorTypeDeleteInput;
};


export type MutationDeleteDeliveryLocationArgs = {
  input: DeliveryLocationDeleteInput;
};


export type MutationCancelDeliveryLocationArgs = {
  input: DeliveryLocationDeleteInput;
};


export type MutationCreateDeliveryLocArgs = {
  input: DeliveryLocationInput;
};


export type MutationUpdateDeliveryLocArgs = {
  input: DeliveryLocationInput;
};


export type MutationUpdateDocumentDateValidationArgs = {
  input: DocumentDateValidationInput;
};


export type MutationDeleteDocumentDateValidationArgs = {
  input: DocumentDateValidationDeleteInput;
};


export type MutationCancelDocumentDateValidationArgs = {
  input: DocumentDateValidationDeleteInput;
};


export type MutationCreateDocumentDateValidationArgs = {
  DocumentDateValidationInput: Array<DocumentDateValidationInput>;
};


export type MutationCreateDocumentNumberDetailArgs = {
  input: DocumentNumberDetailInput;
};


export type MutationUpdateDocumentNumberDetailArgs = {
  input: DocumentNumberDetailInput;
};


export type MutationDeleteDocumentNumberDetailArgs = {
  input: DocumentNumberDetailDeleteInput;
};


export type MutationCancelDocumentNumberDetailArgs = {
  input: DocumentNumberDetailDeleteInput;
};


export type MutationDeleteDocumentNumberHeaderArgs = {
  input: DocumentNumberHeaderDeleteInput;
};


export type MutationCancelDocumentNumberHeaderArgs = {
  input: DocumentNumberHeaderDeleteInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
};


export type MutationUpdateIsAutoDocNoArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  input: DocumentNumberHeaderInput;
  IsAutoDocNo: Scalars['Boolean'];
  RefTable: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCreateEntityCoaArgs = {
  input: EntityCoaInput;
};


export type MutationUpdateEntityCoaArgs = {
  input: EntityCoaInput;
};


export type MutationDeleteEntityCoaArgs = {
  input: EntityCoaDeleteInput;
};


export type MutationCancelEntityCoaArgs = {
  input: EntityCoaDeleteInput;
};


export type MutationAssignEntityCoaArgs = {
  CompanyArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationDeleteAssignedEntityCoaArgs = {
  CompanyArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationActivateInactivateEntityCoaArgs = {
  ActiveCOA: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
};


export type MutationCancelExpenseClassArgs = {
  input: ExpenseClassDeleteInput;
};


export type MutationDeleteExpenseClassArgs = {
  ExpenseClassID: Scalars['String'];
};


export type MutationCreateExpenseClassArgs = {
  input: ExpenseClassInput;
};


export type MutationUpdateExpenseClassArgs = {
  input: ExpenseClassInput;
};


export type MutationCancelExpenseItemArgs = {
  input: ExpenseItemDeleteInput;
};


export type MutationCreateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationUpdateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationDeleteExpenseItemArgs = {
  ExpenseItemID: Scalars['String'];
};


export type MutationTestDeleteExpenseItemArgs = {
  ExpenseItemID: Scalars['String'];
};


export type MutationInitialTaxSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationUpdateGlCompanyPolicyArgs = {
  GLCompanyPolicyInput: GlCompanyPolicyInput;
};


export type MutationUpdateItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationDeleteItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentDeleteInput;
};


export type MutationCancelItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentDeleteInput;
};


export type MutationAssignItemClassCompanyArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationUnassignItemClassCompanyArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationCreateJournalTypeArgs = {
  input: JournalTypeInput;
};


export type MutationUpdateJournalTypeArgs = {
  input: JournalTypeInput;
};


export type MutationDeleteJournalTypeArgs = {
  input: JournalTypeDeleteInput;
};


export type MutationCancelJournalTypeArgs = {
  input: JournalTypeDeleteInput;
};


export type MutationUpdateMasterCoaArgs = {
  input: MasterCoaInput;
};


export type MutationCancelMasterCoaArgs = {
  input: MasterCoaDeleteInput;
};


export type MutationCreateMasterCoaArgs = {
  input: Array<MasterCoaInput>;
};


export type MutationDeleteMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationCreateExcelMasterCoaArgs = {
  MasterCOAExcelInput: Array<MasterCoaInput>;
};


export type MutationInactiveMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationActivateMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationCreateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationDeleteNumberFormatArgs = {
  input: NumberFormatDeleteInput;
};


export type MutationCancelNumberFormatArgs = {
  input: NumberFormatDeleteInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: PaymentMethodDeleteInput;
};


export type MutationCancelPaymentMethodArgs = {
  input: PaymentMethodDeleteInput;
};


export type MutationCreatePaymentMethodArgs = {
  PaymentMethodInput: Array<PaymentMethodInput>;
};


export type MutationUpdateRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefInput;
};


export type MutationDeleteRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefDeleteInput;
};


export type MutationCancelRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefDeleteInput;
};


export type MutationCreateRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefInput;
};


export type MutationCancelStockItemArgs = {
  input: StockItemDeleteInput;
};


export type MutationCreateStockItemArgs = {
  input: StockItemInput;
  warehouseInput?: Maybe<Array<StockItemWarehouseInput>>;
};


export type MutationUpdateStockItemWithWarehouseArgs = {
  warehouse?: Maybe<Array<StockItemWarehouseInput>>;
  StockItemID: Scalars['String'];
  input: StockItemInput;
};


export type MutationUpdateStockItemArgs = {
  input: StockItemInput;
};


export type MutationDeleteStockItemArgs = {
  input: StockItemDeleteInput;
};


export type MutationCreateStockItemWarehouseArgs = {
  input: StockItemWarehouseInput;
};


export type MutationUpdateStockItemWarehouseArgs = {
  input: StockItemWarehouseInput;
};


export type MutationDeleteStockItemWarehouseArgs = {
  input: StockItemWarehouseDeleteInput;
};


export type MutationCancelStockItemWarehouseArgs = {
  input: StockItemWarehouseDeleteInput;
};


export type MutationCreateTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationUpdateTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationDeleteTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationCreateTaxSchemeArgs = {
  input: TaxSchemeInput;
};


export type MutationUpdateTaxSchemeArgs = {
  input: TaxSchemeInput;
};


export type MutationCreateTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationUpdateTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationDeleteTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationInsertTaxArgs = {
  TaxEffectiveInput: Array<TaxEffectiveInput>;
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationUpdateTaxArgs = {
  TaxEffectiveInput: Array<TaxEffectiveInput>;
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  TaxSchemeID: Scalars['String'];
};


export type MutationCreateTransactionTypeArgs = {
  input: TransactionTypeInput;
};


export type MutationUpdateTransactionTypeArgs = {
  input: TransactionTypeInput;
};


export type MutationDeleteTransactionTypeArgs = {
  input: TransactionTypeDeleteInput;
};


export type MutationCancelTransactionTypeArgs = {
  input: TransactionTypeDeleteInput;
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateUomWithAccountArgs = {
  input: UomInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationUpdateUomWithAccountArgs = {
  input: UomInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUpdateUserActivityLogArgs = {
  UserAction: Scalars['String'];
  LastAccessedModule?: Maybe<Scalars['String']>;
};


export type MutationUpdateWarehouseArgs = {
  WarehouseID: Scalars['String'];
  WarehouseInput: WarehouseInput;
};


export type MutationCancelWarehouseArgs = {
  input: WarehouseDeleteInput;
};


export type MutationCreateWarehouseArgs = {
  WarehouseInput: WarehouseInput;
};


export type MutationDeleteWarehouseArgs = {
  input: WarehouseDeleteInput;
};


export type MutationAssignWarehouseUserArgs = {
  input: WarehouseUserAssignmentInput;
};


export type MutationUnassignUserWarehouseArgs = {
  input: WarehouseUserAssignmentInput;
};


export type MutationAssignWarehouseItemArgs = {
  input: WarehouseItemAssignmentInput;
};


export type MutationUnassignWarehouseItemArgs = {
  input: WarehouseItemAssignmentInput;
};


export type MutationApproveRejectWorkDeskArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  WorkDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyInput;
};


export type MutationUpdateProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyInput;
};


export type MutationCancelProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyDeleteInput;
};


export type MutationCreateProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentInput;
};


export type MutationUpdateProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentInput;
};


export type MutationCancelProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteProcurementApprovalPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBulkPurchasePriceArgs = {
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput>;
  input: BulkPurchasePriceHeaderInput;
};


export type MutationUpdateBulkPurchasePriceArgs = {
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput>;
  input: BulkPurchasePriceHeaderInput;
  BulkPurchasePriceHeaderID: Scalars['String'];
};


export type MutationDeleteBulkPurchasePriceArgs = {
  BulkPurchasePriceHeaderID: Scalars['String'];
};


export type MutationSubmitGeneralDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeleteGeneralDoArgs = {
  DOHeaderID: Scalars['String'];
};


export type MutationRejectGeneralDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationActionOnDoStatusArgs = {
  CompanyID: Scalars['String'];
  input: DoRejectInput;
  ApprovalStatus: ApprovalStatus;
  DOHeaderID: Array<Scalars['String']>;
};


export type MutationCreateFavoriteApproverArgs = {
  FavoriteApproverInput: FavoriteApproverInput;
};


export type MutationDeleteFavoriteApproverArgs = {
  FavoriteApproverID: Scalars['String'];
};


export type MutationCreateFavoritePrArgs = {
  FavoritePRItemInput?: Maybe<Array<FavoritePrItemInput>>;
  FavoritePRInput: FavoritePrInput;
};


export type MutationDeleteFavoritePrArgs = {
  FavoritePRHeaderID: Scalars['String'];
};


export type MutationCreateGeneralPurchasePolicyArgs = {
  GeneralPurchasePolicyInput: GeneralPurchasePolicyInput;
};


export type MutationUpdateGeneralPurchasePolicyArgs = {
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  GeneralPurchasePolicyInput: GeneralPurchasePolicyInput;
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};


export type MutationCreateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
};


export type MutationUpdateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeleteGrtnArgs = {
  GRTNHeaderID: Scalars['String'];
};


export type MutationActionOnGrtnStatusArgs = {
  CompanyID: Scalars['String'];
  input: GeneralGrtnRejectInput;
  ApprovalStatus: ApprovalStatus;
  GRTNHeaderID: Array<Scalars['String']>;
};


export type MutationGrtnPostApprovalArgs = {
  CompanyID: Scalars['String'];
  input: GeneralGrtnRejectInput;
  ApprovalStatus: ApprovalStatus;
  GRTNHeaderID: Array<Scalars['String']>;
};


export type MutationCreateGeneralPoArgs = {
  generalPOInput: Array<PoInput>;
};


export type MutationUpdateGeneralPoArgs = {
  generalPOInput: Array<PoInput>;
  POHeaderID: Scalars['String'];
};


export type MutationCreateDirectPoArgs = {
  input: DirectPoInput;
};


export type MutationUpdateDirectPoArgs = {
  input: DirectPoInput;
  POHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeletePoArgs = {
  POHeaderID: Scalars['String'];
};


export type MutationActionOnGpoStatusArgs = {
  CompanyID: Scalars['String'];
  input: PoRejectInput;
  ApprovalStatus: ApprovalStatus;
  POHeaderID: Array<Scalars['String']>;
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  POHeaderID: Scalars['String'];
};


export type MutationDuplicatePoArgs = {
  POHeaderID?: Maybe<Scalars['String']>;
};


export type MutationCombinePoArgs = {
  input: Array<DirectPoInput>;
};


export type MutationCreateGeneralPrArgs = {
  generalPRItemInput?: Maybe<Array<PrItemInput>>;
  generalPRInput: PrInput;
};


export type MutationUpdateGeneralPrArgs = {
  generalPRItemInput: Array<PrItemInput>;
  generalPRInput: PrInput;
  PRHeaderID: Scalars['String'];
};


export type MutationActionOnGprStatusArgs = {
  input: GprActionInput;
  GeneralPRIDs: Array<Scalars['String']>;
};


export type MutationDeleteGeneralPrArgs = {
  PRHeaderID: Scalars['String'];
};


export type MutationCancelGeneralPrArgs = {
  PRHeaderID: Array<Scalars['String']>;
};


export type MutationCancelClosePrArgs = {
  input: GprActionInput;
  PRHeaderID: Scalars['String'];
};


export type MutationDuplicatePrArgs = {
  PRHeaderID?: Maybe<Scalars['String']>;
};


export type MutationCreateGeneralRfqArgs = {
  RFQItemInput: Array<RfqItemInput>;
  RFQInput: RfqInput;
};


export type MutationUpdateGeneralRfqArgs = {
  generalRFQItemInput: Array<RfqItemInput>;
  generalRFQInput: RfqInput;
  RFQHeaderID: Scalars['String'];
};


export type MutationDeleteGeneralRfqArgs = {
  RFQHeaderID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  RFQEvalSelectInput: Array<RfqEvalSelectInput>;
  RFQInput: RfqEvalInput;
};


export type MutationActionOnRfqStatusArgs = {
  CompanyID: Scalars['String'];
  input: RfqRejectInput;
  RFQHeaderID: Array<Scalars['String']>;
};


export type MutationInviteSupplierArgs = {
  SupplierIDs: Array<Scalars['String']>;
  RFQHeaderID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  SupplierID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
};


export type MutationSubmitGeneralRfqQuoteArgs = {
  input: RfqSupplierSubmissionInput;
};


export type MutationDeleteRfqSupplierSubmissionArgs = {
  RFQSupplierSubmissionID: Scalars['String'];
};


export type MutationCreateMainScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
};


export type MutationUpdateMainScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationCreateScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
};


export type MutationUpdateScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationDeleteScheduledPurchasesArgs = {
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationDeleteScheduledPurchasesItemArgs = {
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationCreatePreparePoArgs = {
  ScheduledPurchasesID: Scalars['String'];
  input: Array<PreparePoInput>;
};


export type MutationAssignScheduledPurchasesUserArgs = {
  input: ScheduledPurchasesUserAssignmentInput;
};


export type MutationUnassignUserScheduledPurchasesArgs = {
  input: ScheduledPurchasesUserAssignmentInput;
};


export type MutationCreateItemArgs = {
  input: ItemInput;
};


export type MutationUpdateItemArgs = {
  input: ItemInput;
};


export type MutationCancelItemArgs = {
  ItemID: Scalars['String'];
};


export type MutationDeleteItemArgs = {
  ItemID: Scalars['String'];
};


export type MutationCreateBatchItemArgs = {
  input: Array<ItemExcelInput>;
};


export type MutationCreateItemCategoryArgs = {
  ItemCategoryInput: ItemCategoryInput;
};


export type MutationUpdateItemCategoryArgs = {
  ItemCategoryInput: ItemCategoryInput;
};


export type MutationDeleteItemCategoryArgs = {
  ItemCategoryID: Scalars['String'];
};


export type MutationAssignItemCategoryCompanyArgs = {
  input: ItemCategoryCompanyAssignmentInput;
};


export type MutationUnassignItemCategoryCompanyArgs = {
  input: ItemCategoryCompanyAssignmentInput;
};


export type MutationAddDisallowGpItemArgs = {
  CompanyArr: Array<Scalars['String']>;
  ItemID: Scalars['String'];
};


export type MutationDeleteDisallowGpItemArgs = {
  CompanyArr: Array<Scalars['String']>;
  ItemID: Scalars['String'];
};


export type MutationCreatePurchaseItemVariancePolicyArgs = {
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
};


export type MutationUpdatePurchaseItemVariancePolicyArgs = {
  PurchaseItemVariancePolicyID: Scalars['String'];
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
};


export type MutationProcurementLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};

export type NumberFormatDeleteInput = {
  NumberFormatID: Scalars['String'];
};

export type NumberFormatEntity = AuditEntity & {
  __typename?: 'NumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  NumberFormatID: Scalars['String'];
  Format: Scalars['String'];
  Constant: Scalars['String'];
  Day: Scalars['Float'];
  Month: Scalars['Float'];
  Year: Scalars['Float'];
  CompanyCode: Scalars['String'];
  SerialNo: Scalars['String'];
  RunningNumber?: Maybe<Array<RunningNumberEntity>>;
};

export type NumberFormatInput = {
  NumberFormatID?: Maybe<Scalars['String']>;
  Format: Scalars['String'];
  Constant: Scalars['String'];
  Day: Scalars['Float'];
  Month: Scalars['Float'];
  Year: Scalars['Float'];
  CompanyCode: Scalars['String'];
  SerialNo: Scalars['String'];
  CompanyID: Scalars['String'];
};

export enum NumericFormatter {
  Lumpsum = 'LUMPSUM',
  Thousands = 'THOUSANDS',
  Millions = 'MILLIONS',
  Billions = 'BILLIONS'
}

/** JsonType */
export type OsprItemReturnData = {
  __typename?: 'OSPRItemReturnData';
  Data?: Maybe<Array<PrItemEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type PoHeaderEntity = AuditEntity & {
  __typename?: 'POHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  POHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocRef?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  ContactPerson?: Maybe<Scalars['JSON']>;
  ExpectedDate: Scalars['String'];
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  POType: PoType;
  Remarks?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  POItem?: Maybe<Array<PoItemEntity>>;
  POItemPricing?: Maybe<Array<PoItemPricingEntity>>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  ApprovalWorkflow?: Maybe<Scalars['JSON']>;
};

export type PoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocRef?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POItems: Array<PoItemInput>;
  POType: PoType;
  CreditTerm?: Maybe<Scalars['Float']>;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  POItemID: Scalars['String'];
  POHeaderID: Scalars['String'];
  POHeader?: Maybe<PoHeaderEntity>;
  PRItemID?: Maybe<Scalars['String']>;
  FavoritePRItemID?: Maybe<Scalars['String']>;
  PRItem?: Maybe<PrItemEntity>;
  FavoritePRItem?: Maybe<FavoritePrItemEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  OrderedQty: Scalars['Float'];
  OutstandingQty: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxAmt: Scalars['Float'];
  TotalAmt: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DOItem?: Maybe<Array<DoItemEntity>>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
  PriceRefTable?: Maybe<Scalars['String']>;
  PriceRefID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  UOMExchange?: Maybe<Array<PoUomExchange>>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  TaxScheme?: Maybe<TaxSchemeEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  ScheduledPurchaseDetail?: Maybe<Scalars['String']>;
};

export type PoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  DeliveryTo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OrderedQty?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
};

export type PoItemPricingEntity = AuditEntity & {
  __typename?: 'POItemPricingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  POItemPricingID: Scalars['String'];
  SupplierID: Scalars['String'];
  ItemID: Scalars['String'];
  UOMID?: Maybe<Scalars['String']>;
  TotalQuantity: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  PriceType: PriceType;
  POHeaderID: Scalars['String'];
  POHeader?: Maybe<PoHeaderEntity>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  FavoritePRItem?: Maybe<Array<FavoritePrItemEntity>>;
};

export type PoItemsInput = {
  DOItemID?: Maybe<Scalars['String']>;
  POItemID?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  AcceptedQty?: Maybe<Scalars['Float']>;
  AcceptedAmt?: Maybe<Scalars['Float']>;
  DeliveredQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};

export type PoRejectInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};

/** JsonType */
export type PoReturnData = {
  __typename?: 'POReturnData';
  Data?: Maybe<Array<PoHeaderEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum PoType {
  PoFromPr = 'PO_FROM_PR',
  DirectPo = 'DIRECT_PO',
  CentralisedPo = 'CENTRALISED_PO'
}

export type PrHeaderEntity = AuditEntity & {
  __typename?: 'PRHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  PRHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  CheckboxPRDisabled?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  ApprovalWorkflow?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type PrHeaderReturnData = {
  __typename?: 'PRHeaderReturnData';
  Data?: Maybe<Array<PrHeaderEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type PrInput = {
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  Instruction: Scalars['String'];
  ContactNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus: ApprovalStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  ApprovalList?: Maybe<Array<FavoriteApproverListJsonInput>>;
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PRItemID: Scalars['String'];
  PRHeaderID: Scalars['String'];
  PRHeader?: Maybe<PrHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  CostCentreID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  Quantity: Scalars['Float'];
  UnitCost: Scalars['Float'];
  TotalUnitCost: Scalars['Float'];
  OutstandingQty?: Maybe<Scalars['Float']>;
  POItemPricingID?: Maybe<Scalars['String']>;
  POItemPricing?: Maybe<PoItemPricingEntity>;
  Remark?: Maybe<Scalars['String']>;
  IsPOGenerated: Scalars['Boolean'];
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  bulkPurchaseSelected?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  masterFileSelected?: Maybe<Scalars['JSON']>;
};

export type PrItemInput = {
  ItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalUnitCost?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PRHeaderID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type PrWorkflowInfo = {
  __typename?: 'PRWorkflowInfo';
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type PaymentDeleteInput = {
  PaymentID: Scalars['String'];
};

export type PaymentEntity = AuditEntity & {
  __typename?: 'PaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  PaymentID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PaymentMethodID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  PayeeName?: Maybe<Scalars['String']>;
};

export type PaymentItemDeleteInput = {
  PaymentItemID: Scalars['String'];
};

export type PaymentItemEntity = AuditEntity & {
  __typename?: 'PaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  PaymentItemID: Scalars['String'];
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  PaymentID: Scalars['String'];
  Payment?: Maybe<PaymentEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type PaymentItemInput = {
  PaymentItemID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type PaymentMethodDeleteInput = {
  PaymentMethodID: Scalars['String'];
};

export type PaymentMethodEntity = AuditEntity & {
  __typename?: 'PaymentMethodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PaymentMethodID: Scalars['String'];
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  FavoritePayment?: Maybe<Array<FavoritePaymentEntity>>;
  FavoriteReceipt?: Maybe<Array<FavoriteReceiptEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APFavoritePayment?: Maybe<Array<AccApFavoritePaymentEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
};

export type PaymentMethodInput = {
  PaymentMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type PaymentRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type PendingToDo = {
  __typename?: 'PendingToDo';
  PendingGL?: Maybe<Scalars['Float']>;
  PendingCB?: Maybe<Scalars['Float']>;
  PendingAR?: Maybe<Scalars['Float']>;
  PendingAP?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type PendingUseGpItemarg = {
  __typename?: 'PendingUseGPItemarg';
  CompanyID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
};

export type PeriodBudgetItemInput = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  PeriodBudgetAmount?: Maybe<Scalars['Float']>;
  Period?: Maybe<Scalars['Float']>;
};

export type PreparePoEntry = {
  input: Array<PreparePoInput>;
};

export type PreparePoHeaderInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  ContactPerson?: Maybe<Scalars['JSON']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type PreparePoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  Instruction?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POItems: Array<PreparePoItemInput>;
  CreditTerm?: Maybe<Scalars['Float']>;
};

export type PreparePoItemBySupplierInput = {
  SupplierID?: Maybe<Scalars['String']>;
  POItems: Array<PreparePoItemInput>;
};

export type PreparePoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  DeliveryTo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  OrderQty?: Maybe<Scalars['Float']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OrderedQty?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
  IsCentralised?: Maybe<Scalars['Boolean']>;
  StockItemID?: Maybe<Scalars['String']>;
};

export enum PriceType {
  BulkPurchase = 'BULK_PURCHASE',
  MarketPrice = 'MARKET_PRICE',
  Rfq = 'RFQ'
}

export type PrintReportOutput = {
  __typename?: 'PrintReportOutput';
  file_name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
};

export type ProcessMonthYearEndOutput = {
  __typename?: 'ProcessMonthYearEndOutput';
  pendingJP?: Maybe<Scalars['Float']>;
  pendingRJ?: Maybe<Scalars['Float']>;
  pendingAA?: Maybe<Scalars['Float']>;
  pendingGenrateRJ?: Maybe<Scalars['Float']>;
  pendingAPAdv?: Maybe<Scalars['Float']>;
  pendingAPInv?: Maybe<Scalars['Float']>;
  pendingAPCN?: Maybe<Scalars['Float']>;
  pendingAPDN?: Maybe<Scalars['Float']>;
  pendingAPPayment?: Maybe<Scalars['Float']>;
  pendingAPBatchPayment?: Maybe<Scalars['Float']>;
  pendingAPRefund?: Maybe<Scalars['Float']>;
  pendingARAdv?: Maybe<Scalars['Float']>;
  pendingARInv?: Maybe<Scalars['Float']>;
  pendingARCN?: Maybe<Scalars['Float']>;
  pendingARDN?: Maybe<Scalars['Float']>;
  pendingAROfficialReceipt?: Maybe<Scalars['Float']>;
  pendingARRefund?: Maybe<Scalars['Float']>;
  pendingCBPayment?: Maybe<Scalars['Float']>;
  pendingCBReceipt?: Maybe<Scalars['Float']>;
  pendingCBBankTransfer?: Maybe<Scalars['Float']>;
  pendingStockReceipt?: Maybe<Scalars['Float']>;
  pendingStockIssue?: Maybe<Scalars['Float']>;
  pendingStockTransfer?: Maybe<Scalars['Float']>;
  pendingStockAdjustment?: Maybe<Scalars['Float']>;
  pendingStockCostAdjustment?: Maybe<Scalars['Float']>;
  pendingStockRequisition?: Maybe<Scalars['Float']>;
  pendingCycleCount?: Maybe<Scalars['Float']>;
  pendingGRN?: Maybe<Scalars['Float']>;
  pendingGRTN?: Maybe<Scalars['Float']>;
  processStatus?: Maybe<Scalars['Boolean']>;
};

export type ProcurementApprovalListJson = {
  __typename?: 'ProcurementApprovalListJson';
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ProcurementApprovalListJsonInput = {
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ProcurementApprovalPolicyAssignmentDeleteInput = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};

export type ProcurementApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ProcurementApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyAssignmentID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  Category: Scalars['String'];
  LowerLimit?: Maybe<Scalars['Float']>;
  UpperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type ProcurementApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList: Array<ProcurementApprovalListJsonInput>;
};

export type ProcurementApprovalPolicyDeleteInput = {
  ApprovalPolicyID: Scalars['String'];
};

export type ProcurementApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ProcurementApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ProcurementApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList: Array<ProcurementApprovalListJsonInput>;
};

export type ProcurementConDocTypeEntity = AuditEntity & {
  __typename?: 'ProcurementConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: PurchaserDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ProcurementConNumberFormatEntity>;
};

export type ProcurementConNumberFormatEntity = AuditEntity & {
  __typename?: 'ProcurementConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docType: ProcurementConDocTypeEntity;
  runningNum: Array<ProcurementConRunningNumberSetupEntity>;
};

export type ProcurementConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ProcurementConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ProcurementConNumberFormatEntity;
};

export type ProcurementCoreApprovalEntity = AuditEntity & {
  __typename?: 'ProcurementCoreApprovalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type ProcurementCoreEntity = AuditEntity & {
  __typename?: 'ProcurementCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type ProcurementDocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  RecordStatus?: Maybe<RecordStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type ProcurementWorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowApprovedStepID: Scalars['String'];
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<GpApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: ProcurementWorkFlowStepEntity;
};

export type ProcurementWorkFlowEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowID: Scalars['String'];
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<GpApprovalStatus>;
  WorkFlowStep?: Maybe<Array<ProcurementWorkFlowStepEntity>>;
};

export type ProcurementWorkFlowStepEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowStepID: Scalars['String'];
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<GpApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: ProcurementWorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<ProcurementWorkFlowApprovedStepEntity>>;
};

export type PurchaseActionInput = {
  CancelCloseRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseBudgetInput = {
  Remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseItemVariancePolicyEntity = AuditEntity & {
  __typename?: 'PurchaseItemVariancePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PurchaseItemVariancePolicyID: Scalars['String'];
  VariancePerc?: Maybe<Scalars['Float']>;
};

export type PurchaseItemVariancePolicyInput = {
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
};

export type PurchaserCoreAssociateEntity = AuditEntity & {
  __typename?: 'PurchaserCoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
};

export enum PurchaserDocNum {
  GPr = 'G_PR',
  GRfq = 'G_RFQ',
  GPo = 'G_PO',
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO'
}

export type PurchaserRoleEntity = AuditEntity & {
  __typename?: 'PurchaserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
  conRolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
  siteRolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
};

export type PurchaserRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'PurchaserRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type PurchaserRoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'PurchaserRoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getUserRole: Array<UserRoleEntity>;
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUsersWithAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getUsersByAccountAndSoftwareListing: UserReturnType;
  getActiveUsersCount: Scalars['Float'];
  getSuperUsers: Array<UserEntity>;
  getAssignedUserByCompany: Scalars['JSON'];
  getRoleNameByUser?: Maybe<Scalars['JSON']>;
  getUserByRoleAssigned?: Maybe<Scalars['JSON']>;
  getAPAdvance: Array<AccApAdvanceEntity>;
  getAPAdvancebyStatus: Array<AccApAdvanceEntity>;
  getAPAdvancebyStatusListing: Array<AccApAdvanceEntity>;
  getARAdvance: Array<AccArAdvanceEntity>;
  getARAdvancebyStatus: Array<AccArAdvanceEntity>;
  getARAdvancebyStatusListing: ArAdvanceReturnData;
  getAllocationDocument: Scalars['JSON'];
  getDocumentDetail: Scalars['JSON'];
  getAllocationItemDocument: Scalars['JSON'];
  getAPBatchPayment: Array<AccApBatchPaymentEntity>;
  getAPBatchPaymentbyStatus: Array<AccApBatchPaymentEntity>;
  getAPBatchPaymentbyStatusListing: Array<AccApBatchPaymentEntity>;
  getSelfBilledConsolidation: Array<EinvoiceConsolidationEntity>;
  getSelfBilledToConsolidate?: Maybe<Array<AccApSelfBilledEntity>>;
  getEinvoiceConsolidation: Array<EinvoiceConsolidationEntity>;
  getDocToConsolidate: Scalars['JSON'];
  getAPCreditNote: Array<AccApCreditNoteEntity>;
  getAPCreditNotebyStatus: Array<AccApCreditNoteEntity>;
  getAPCreditNotebyStatusListing: Array<AccApCreditNoteEntity>;
  getAPValidatedDocRef: Scalars['JSON'];
  getARCreditNote: Array<AccArCreditNoteEntity>;
  getARCreditNotebyStatus: Array<AccArCreditNoteEntity>;
  getARCreditNotebyStatusListing: ArCreditNoteReturnData;
  getAPCreditorCreditNote: Array<AccApCreditorCreditNoteEntity>;
  getAPCreditorCreditNotebyStatus: Array<AccApCreditorCreditNoteEntity>;
  getAPCreditorCreditNotebyStatusListing: Array<AccApCreditorCreditNoteEntity>;
  getGeneralGRTN: Scalars['JSON'];
  getGRTNItem: Scalars['JSON'];
  getAPCreditNoteItem: Array<AccApCreditNoteItemEntity>;
  getAPDebitNote: Array<AccApDebitNoteEntity>;
  getAPDebitNotebyStatus: Array<AccApDebitNoteEntity>;
  getAPDebitNotebyStatusListing: Array<AccApDebitNoteEntity>;
  getARDebitNote: Array<AccArDebitNoteEntity>;
  getARDebitNotebyStatus: Array<AccArDebitNoteEntity>;
  getARDebitNotebyStatusListing: ArDebitNoteReturnData;
  getAPCreditorDebitNote: Array<AccApCreditorDebitNoteEntity>;
  getAPCreditorDebitNotebyStatus: Array<AccApCreditorDebitNoteEntity>;
  getAPCreditorDebitNotebyStatusListing: Array<AccApCreditorDebitNoteEntity>;
  getAPDebitNoteItem: Array<AccApDebitNoteItemEntity>;
  getARDebitNoteItem: Array<AccArDebitNoteItemEntity>;
  getGeneralTin: Scalars['JSON'];
  checkInvalidTinNo: Scalars['Boolean'];
  getAPFavoriteInvoice: Array<AccApFavoriteInvoiceEntity>;
  getARFavoriteInvoice: Array<AccArFavoriteInvoiceEntity>;
  getAPFavoritePayment: Array<AccApFavoritePaymentEntity>;
  getAPInvoice: Array<AccApInvoiceEntity>;
  getAPInvoicebyStatus: Array<AccApInvoiceEntity>;
  getAPInvoicebyStatusListing: Array<AccApInvoiceEntity>;
  getGeneralDOs: Scalars['JSON'];
  getDOItem: Scalars['JSON'];
  getSelfBilledInvoice: Array<AccApInvoiceEntity>;
  getARInvoice: Array<AccArInvoiceEntity>;
  getARInvoicebyStatus: Array<AccArInvoiceEntity>;
  getARInvoicebyStatusListing: ArInvoiceReturnData;
  getValidatedDocRef: Scalars['JSON'];
  getAPInvoiceItem: Array<AccApInvoiceItemEntity>;
  getARInvoiceItem: Array<AccArInvoiceItemEntity>;
  getAROfficialReceipt: Array<AccArOfficialReceiptEntity>;
  getAROfficialReceiptbyStatus: Array<AccArOfficialReceiptEntity>;
  getAROfficialReceiptbyStatusListing: ArOfficialReceiptReturnData;
  getAPPayment: Array<AccApPaymentEntity>;
  getAPPaymentbyStatus: Array<AccApPaymentEntity>;
  getAPPaymentbyStatusListing: Array<AccApPaymentEntity>;
  getAPRefund: Array<AccApRefundEntity>;
  getAPRefundbyStatus: Array<AccApRefundEntity>;
  getARRefund: Array<AccArRefundEntity>;
  getARRefundbyStatus: Array<AccArRefundEntity>;
  getARRefundbyStatusListing: ArRefundReturnData;
  getAPSelfBilled: Array<AccApSelfBilledEntity>;
  getAPSelfBilledbyStatus: Array<AccApSelfBilledEntity>;
  getAPSelfBilledbyStatusListing: Array<AccApSelfBilledEntity>;
  getAPInvoiceSelfBilled: Array<AccApSelfBilledEntity>;
  DocumentListing?: Maybe<Array<AttachmentEntity>>;
  viewLogo: AttachmentEntity;
  attachmentListing?: Maybe<Array<AttachmentEntity>>;
  fetchShareAttachment?: Maybe<Array<AttachmentEntity>>;
  AuditLogDetails: Scalars['JSON'];
  getBankReconciliation: Array<BankReconciliationEntity>;
  getCheckingValidationBankRecon: Scalars['JSON'];
  getBankStatement: Array<BankReconciliationItemEntity>;
  getBankReconCBLedger: Array<LedgerEntity>;
  getBankReconciliationItem: Array<BankReconciliationItemEntity>;
  getBankReconciliationMatch: Array<BankReconciliationMatchEntity>;
  getBankTransfer: Array<BankTransferEntity>;
  getBankTransferbyStatus: Array<BankTransferEntity>;
  getBankTransferbyStatusListing: Array<BankTransferEntity>;
  getApprovedBankTransfer: Array<Scalars['JSON']>;
  getApprovedBankTransferListing: Array<Scalars['JSON']>;
  getExternalLedger: Array<ExternalLedgerEntity>;
  getCheckingValidationExtLedger: Scalars['JSON'];
  getFavoritePayment: Array<FavoritePaymentEntity>;
  getFavoriteReceipt: Array<FavoriteReceiptEntity>;
  getLedger: Array<LedgerEntity>;
  getLedgerCount: Scalars['Float'];
  getLedgerItem: Array<LedgerItemEntity>;
  getPayment: Array<PaymentEntity>;
  getPaymentbyStatus: Array<PaymentEntity>;
  getPaymentbyStatusListing: Array<PaymentEntity>;
  getPaymentItem: Array<PaymentItemEntity>;
  getReceipt: Array<ReceiptEntity>;
  getReceiptbyStatus: Array<ReceiptEntity>;
  getReceiptbyStatusListing: Array<ReceiptEntity>;
  getReceiptItem: Array<ReceiptItemEntity>;
  GetGLJournalItemReport: Array<GlJournalItem>;
  getCompanyLetterTemplate: Array<CompanyLetterTemplateEntity>;
  getLetterTemplate: Array<LetterTemplateEntity>;
  getLetterTemplateByModule: Scalars['String'];
  DocumentTemplateCount?: Maybe<Scalars['Float']>;
  CompanyDocumentTemplateCount?: Maybe<Scalars['Float']>;
  getStandardLetter: Array<StandardLetterEntity>;
  getStandardLetterByCategory: Scalars['JSON'];
  getStandardLetterDataSource: Array<StandardLetterDataSourceEntity>;
  getStandardLetterParameter: Array<StandardLetterParameterEntity>;
  getAuditAdjustment: Array<AuditAdjustmentEntity>;
  getAuditAdjustmentbyStatus: Array<AuditAdjustmentEntity>;
  getAuditAdjustmentbyStatusListing: AuditAdjustmentReturnData;
  getAuditAdjustmentAdjDate: AccountPeriodEntity;
  getAuditAdjustmentItem: Array<AuditAdjustmentItemEntity>;
  getBudget: Array<BudgetEntity>;
  getBudgetbyStatus: Array<BudgetEntity>;
  getBudgetMasterCOA: Scalars['JSON'];
  getParentMasterCOAForTree: Scalars['JSON'];
  getBudgetCostCentre: Scalars['JSON'];
  getBudgetImportList: Scalars['JSON'];
  ImportExcelBudget: Scalars['JSON'];
  getBudgetItem: Array<BudgetItemEntity>;
  getBudgetItemCostCentre: Array<BudgetItemCostCentreEntity>;
  getBudgetItemPeriod: Array<BudgetItemPeriodEntity>;
  getExternalJournal: Array<ExternalJournalEntity>;
  getExternalJournalCount: Scalars['Float'];
  getCheckingValidationExtJournal: Scalars['JSON'];
  getFavoriteJournalProcessing: Array<FavoriteJournalProcessingEntity>;
  getDataSourceSettings: Array<DataSourceSettingsEntity>;
  getReportSettings: Array<ReportSettingsEntity>;
  printReport?: Maybe<PrintReportOutput>;
  DesignReport?: Maybe<PrintReportOutput>;
  testExtractReportDataService?: Maybe<Scalars['JSON']>;
  getGLExportDotNet: Array<GlExportDotNetEntity>;
  getGLExportDotNetDetail: Scalars['JSON'];
  getDotNetToken: Scalars['JSON'];
  getJournalExportDotNet: Scalars['JSON'];
  getJournal: Array<JournalEntity>;
  getJournalbyStatus: Array<JournalEntity>;
  getJournalbyStatusListing: JournalReturnData;
  getJournalItem: Array<JournalItemEntity>;
  getJournalProcessing: Array<JournalProcessingEntity>;
  getJournalProcessingbyStatus: Array<JournalProcessingEntity>;
  getJournalProcessingbyStatusListing: JournalProcessingReturnData;
  getJournalReporting: Array<JournalEntity>;
  latestOpenPeriodChecking?: Maybe<AccountPeriodEntity>;
  latestOpenYearChecking: AccountPeriodEntity;
  getLastClosedPeriod?: Maybe<AccountPeriodEntity>;
  needYearEndClose: Scalars['Boolean'];
  MonthEndYearEndDate?: Maybe<Scalars['DateTime']>;
  latestOpenPeriodCheckingDate?: Maybe<Scalars['JSON']>;
  getRecurringJournal: Array<RecurringJournalEntity>;
  getRecurringJournalbyStatus: Array<RecurringJournalEntity>;
  getRecurringJournalbyStatusListing: RecurringJournalReturnData;
  getRecurringJournalGeneration: Array<RecurringJournalGenerationEntity>;
  getRecurringJournalItem: Array<RecurringJournalItemEntity>;
  getRoutineJournal: Array<RoutineJournalEntity>;
  getRoutineJournalItem: Array<RoutineJournalItemEntity>;
  checkCBDoubleEntry: Scalars['JSON'];
  checkAccRecDoubleEntry: Scalars['JSON'];
  checkAccPayDoubleEntry: Scalars['JSON'];
  getExportedGLListing: Scalars['JSON'];
  getExportedGLDetail: Array<Scalars['JSON']>;
  getGLExport: Scalars['JSON'];
  getGLExportDetailByAccountCode: Scalars['JSON'];
  getCycleCount: Array<CycleCountEntity>;
  getCycleCountbyStatus: Array<CycleCountEntity>;
  getCycleCountListing: CycleCountType;
  getLatestCycleCountDate?: Maybe<Scalars['JSON']>;
  pendingICTransactionChecking: Scalars['Boolean'];
  pendingCycleCount: Scalars['Boolean'];
  getCycleCountItem: Array<CycleCountItemEntity>;
  getStockItemFromWarehouse: Scalars['JSON'];
  getStockItemCycleCount: Scalars['JSON'];
  getFavoriteStockRequisition: Array<FavoriteStockRequisitionEntity>;
  getFavoriteStockTransferRequisition: Array<FavoriteStockTransferRequisitionEntity>;
  stockItemMovementChecking: Scalars['Float'];
  stockIssueInfoLine: Scalars['JSON'];
  stockReceiptInfoLine: Scalars['JSON'];
  stockTransferInfoLine: Scalars['JSON'];
  stockAdjustmentInfoLine: Scalars['JSON'];
  cycleCountInfoLine: Scalars['JSON'];
  stockRequisitionInfoLine: Scalars['JSON'];
  digitalReportInfoLine: Scalars['JSON'];
  stockIssueCount?: Maybe<Scalars['JSON']>;
  stockReceiptCount?: Maybe<Scalars['JSON']>;
  stockRequisitionCount?: Maybe<Scalars['JSON']>;
  stockTransferCount?: Maybe<Scalars['JSON']>;
  stockTransferRequisitionCount?: Maybe<Scalars['JSON']>;
  stockAdjustmentCount?: Maybe<Scalars['JSON']>;
  stockCostAdjustmentCount?: Maybe<Scalars['JSON']>;
  cycleCountCount?: Maybe<Scalars['JSON']>;
  getInventoryPolicy: Array<InventoryPolicyEntity>;
  getStockAdjustment: Array<StockAdjustmentEntity>;
  getStockAdjustmentbyStatus: Array<StockAdjustmentEntity>;
  getStockQtyAdjustmentListing: StockQtyAdjustmentType;
  getStockAdjustmentItem: Array<StockAdjustmentItemEntity>;
  getStockAdjustmentReason: Array<StockAdjustmentReasonEntity>;
  getStockAdjustmentReasonPerm: Array<StockAdjustmentReasonEntity>;
  getStockAdjustmentReasonCount: Scalars['Float'];
  getStockCategory: Array<StockCategoryEntity>;
  getStockCategoryPerm: Array<StockCategoryEntity>;
  getStockCostAdjustment: Array<StockCostAdjustmentEntity>;
  getStockCostAdjustmentbyStatus: Array<StockCostAdjustmentEntity>;
  getStockCostAdjustmentListing: StockCostAdjustmentType;
  getStockIssue: Array<StockIssueEntity>;
  getStockIssuebyStatus: Array<StockIssueEntity>;
  getStockIssueListing: StockIssueType;
  getStockIssueItem: Array<StockIssueItemEntity>;
  getStockReceipt: Array<StockReceiptEntity>;
  getStockReceiptbyStatus: Array<StockReceiptEntity>;
  getStockReceiptListing: StockReceiptType;
  getStockReceiptItem: Array<StockReceiptItemEntity>;
  getStockRequisition: Array<StockRequisitionEntity>;
  getStockRequisitionbyStatus: Array<StockRequisitionEntity>;
  getStockRequisitionListing?: Maybe<StockRequisitionType>;
  getStockRequisitionItem: Array<StockRequisitionItemEntity>;
  getStockTransfer: Array<StockTransferEntity>;
  getStockTransferbyStatus: Array<StockTransferEntity>;
  getStockTransferListing: StockTransferType;
  getStockTransferItem: Array<StockTransferItemEntity>;
  getStockTransferRequisition: Array<StockTransferRequisitionEntity>;
  getStockTransferRequisitionbyStatus: Array<StockTransferRequisitionEntity>;
  getStockTransferRequisitionListing?: Maybe<StockTransferRequisitionType>;
  getStockTransferRequisitionItem: Array<StockTransferRequisitionItemEntity>;
  getLeaseContract: Array<Glmfrs16LeaseContractEntity>;
  getAccountMapping: Array<Glmfrs16AccountMappingEntity>;
  getCreditorSubmenuAmt?: Maybe<Array<CreditorDebtorSubInput>>;
  apAdvanceCount?: Maybe<Scalars['JSON']>;
  apAdvanceTotalAmt?: Maybe<Scalars['JSON']>;
  apAdvanceLatestUpdate?: Maybe<Scalars['JSON']>;
  apInvoiceCount?: Maybe<Scalars['JSON']>;
  apInvoiceTotalAmt?: Maybe<Scalars['JSON']>;
  apInvoiceLatestUpdate?: Maybe<Scalars['JSON']>;
  apCreditNoteCount?: Maybe<Scalars['JSON']>;
  apCreditNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apCreditNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apCreditorCreditNoteCount?: Maybe<Scalars['JSON']>;
  apCreditorCreditNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apCreditorCreditNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apDebitNoteCount?: Maybe<Scalars['JSON']>;
  apDebitNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apDebitNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apCreditorDebitNoteCount?: Maybe<Scalars['JSON']>;
  apCreditorDebitNoteTotalAmt?: Maybe<Scalars['JSON']>;
  apCreditorDebitNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  apSelfBilledCount?: Maybe<Scalars['JSON']>;
  apSelfBilledTotalAmt?: Maybe<Scalars['JSON']>;
  apSelfBilledLatestUpdate?: Maybe<Scalars['JSON']>;
  apPaymentCount?: Maybe<Scalars['JSON']>;
  apPaymentTotalAmt?: Maybe<Scalars['JSON']>;
  apPaymentLatestUpdate?: Maybe<Scalars['JSON']>;
  apBatchPaymentCount?: Maybe<Scalars['JSON']>;
  apRefundCount?: Maybe<Scalars['JSON']>;
  creditorAccountCount?: Maybe<Scalars['JSON']>;
  apRefundTotalAmt?: Maybe<Scalars['JSON']>;
  apRefundLatestUpdate?: Maybe<Scalars['JSON']>;
  APDigitalReportDate?: Maybe<Scalars['DateTime']>;
  apConsolidateCount?: Maybe<Scalars['JSON']>;
  getCreditorTotalDocAmt?: Maybe<Array<CreditorInput>>;
  getDebtorTotalDocAmt?: Maybe<Array<DebtorInput>>;
  getEachSubDocAmt?: Maybe<Array<CreditorDebtorSubInput>>;
  getDebtorLatestUpdatedDate?: Maybe<Array<DebtorLatestUpdatedDateInput>>;
  getCreditorLatestUpdatedDate?: Maybe<Array<CreditorLatestUpdatedDateInput>>;
  getARSubmenuTotal?: Maybe<Array<CreditorDebtorSubInput>>;
  arAdvanceCount?: Maybe<Scalars['JSON']>;
  arAdvanceTotalAmt?: Maybe<Scalars['JSON']>;
  arAdvanceLatestUpdate?: Maybe<Scalars['JSON']>;
  arInvoiceCount?: Maybe<Scalars['JSON']>;
  arInvoiceTotalAmt?: Maybe<Scalars['JSON']>;
  arInvoiceLatestUpdate?: Maybe<Scalars['JSON']>;
  arCreditNoteCount?: Maybe<Scalars['JSON']>;
  arCreditNoteTotalAmt?: Maybe<Scalars['JSON']>;
  arCreditNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  arDebitNoteCount?: Maybe<Scalars['JSON']>;
  arDebitNoteTotalAmt?: Maybe<Scalars['JSON']>;
  arDebitNoteLatestUpdate?: Maybe<Scalars['JSON']>;
  arOfficialReceiptCount?: Maybe<Scalars['JSON']>;
  arOfficialReceiptTotalAmt?: Maybe<Scalars['JSON']>;
  arOfficialReceiptLatestUpdate?: Maybe<Scalars['JSON']>;
  arRefundCount?: Maybe<Scalars['JSON']>;
  arRefundTotalAmt?: Maybe<Scalars['JSON']>;
  arRefundLatestUpdate?: Maybe<Scalars['JSON']>;
  paymentCount?: Maybe<Scalars['JSON']>;
  receiptCount?: Maybe<Scalars['JSON']>;
  bankTransferCount?: Maybe<Scalars['JSON']>;
  BankReconciliationDate?: Maybe<Scalars['DateTime']>;
  BankTransferDate?: Maybe<Scalars['DateTime']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ExternalLedgerDate?: Maybe<Scalars['DateTime']>;
  CashBookCount?: Maybe<Scalars['JSON']>;
  CostCentreDate?: Maybe<Scalars['DateTime']>;
  CostCentreDefinitionDate?: Maybe<Scalars['DateTime']>;
  EntityCOADate?: Maybe<Scalars['DateTime']>;
  AccountPeriodDate?: Maybe<Scalars['String']>;
  JournalTypeDate?: Maybe<Scalars['DateTime']>;
  BudgetDate?: Maybe<Scalars['DateTime']>;
  BankAccountDate?: Maybe<Scalars['DateTime']>;
  NumberingStructureGeneralLedgerDate?: Maybe<Scalars['JSON']>;
  NumberingStructureCashBookDate?: Maybe<Scalars['JSON']>;
  NumberingStructureAPDate?: Maybe<Scalars['JSON']>;
  NumberingStructureARDate?: Maybe<Scalars['JSON']>;
  NumberingStructureICDate?: Maybe<Scalars['JSON']>;
  ApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  OriginalBudgetApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  BudgetRevisionApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  RecurringJournalApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  JournalProcessingApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  AuditAdjustmentApprovalPolicyAssignmentDate?: Maybe<Scalars['DateTime']>;
  BankTransferStandardApprovalFlowDate?: Maybe<Scalars['DateTime']>;
  ReceiptStandardApprovalFlowDate?: Maybe<Scalars['DateTime']>;
  PaymentStandardApprovalFlowDate?: Maybe<Scalars['DateTime']>;
  CompanyAuthorizationDate?: Maybe<Scalars['DateTime']>;
  journalProcessingCount?: Maybe<Scalars['JSON']>;
  recurringJournalCount?: Maybe<Scalars['JSON']>;
  auditAdjustmentCount?: Maybe<Scalars['JSON']>;
  RoutineJournalDate?: Maybe<Scalars['DateTime']>;
  RecurringJournalDate?: Maybe<Scalars['DateTime']>;
  JournalProcessingDate?: Maybe<Scalars['DateTime']>;
  AuditAdjustmentDate?: Maybe<Scalars['DateTime']>;
  JournalPending?: Maybe<Scalars['JSON']>;
  GenerateRJCount: Scalars['Int'];
  OriginalBudgetDate?: Maybe<Scalars['DateTime']>;
  ExternalJournalDate?: Maybe<Scalars['DateTime']>;
  DataSourceDate?: Maybe<Scalars['DateTime']>;
  FinancialReportDate?: Maybe<Scalars['DateTime']>;
  GLDigitalReportDate?: Maybe<Scalars['DateTime']>;
  BankProfileDate?: Maybe<Scalars['DateTime']>;
  CurrencyExchangeDate?: Maybe<Scalars['DateTime']>;
  CurrencyDate?: Maybe<Scalars['DateTime']>;
  TaxDate?: Maybe<Scalars['DateTime']>;
  CompanyDate?: Maybe<Scalars['DateTime']>;
  UserDate: Scalars['DateTime'];
  RoleDate?: Maybe<Scalars['DateTime']>;
  PaymentMethodDate?: Maybe<Scalars['DateTime']>;
  CreditCardTypeDate?: Maybe<Scalars['DateTime']>;
  ExpenseClassDate?: Maybe<Scalars['DateTime']>;
  ExpenseItemDate?: Maybe<Scalars['DateTime']>;
  CreditorTypeDate?: Maybe<Scalars['DateTime']>;
  PolicySettingsDate?: Maybe<Scalars['DateTime']>;
  ApprovalPolicyDate?: Maybe<Scalars['DateTime']>;
  PaymentApprovalDate?: Maybe<Scalars['DateTime']>;
  ReceiptApprovalDate?: Maybe<Scalars['DateTime']>;
  BankTransferApprovalDate?: Maybe<Scalars['DateTime']>;
  CreditorTypeCount?: Maybe<Scalars['JSON']>;
  WarehouseDate?: Maybe<Scalars['DateTime']>;
  UOMDate?: Maybe<Scalars['DateTime']>;
  UOMExchangeDate?: Maybe<Scalars['DateTime']>;
  StockCategoryDate?: Maybe<Scalars['DateTime']>;
  StockAdjustmentReasonDate?: Maybe<Scalars['DateTime']>;
  DocumentNumberingDate?: Maybe<Scalars['DateTime']>;
  StockEnquiryDate?: Maybe<Scalars['DateTime']>;
  StockReceiptDate?: Maybe<Scalars['DateTime']>;
  StockIssueDate?: Maybe<Scalars['DateTime']>;
  StockTransferDate?: Maybe<Scalars['DateTime']>;
  CycleCountDate?: Maybe<Scalars['DateTime']>;
  StockAdjustmentDate?: Maybe<Scalars['DateTime']>;
  getCompany: Array<CompanyEntity>;
  getParentCompany: Array<CompanyEntity>;
  getCompanyListing: CompanyReturnType;
  getAssignedCompanyByUser: Scalars['JSON'];
  getAssignedWarehouseCompanyByUser: Scalars['JSON'];
  getActiveCompanyCount: Scalars['Float'];
  getCompanyWithAccount: Scalars['JSON'];
  getCompanyNameWithAccount: Scalars['JSON'];
  getCompanyEntityWithAccount: Array<CompanyEntity>;
  getCheckingValidationCompany: Scalars['JSON'];
  getEInvoiceCompanyStartDate: Scalars['Boolean'];
  CompanySumDate?: Maybe<Scalars['DateTime']>;
  getLocalBank: Array<LocalBankEntity>;
  testDocNoOutput: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<AccRoleUserEntAssignmentEntity>;
  getRole: Array<AccRoleEntity>;
  getRolePerm: Array<AccRoleEntity>;
  getRoleListing: RoleReturnType;
  getRoleWithAccount: Array<AccRoleEntity>;
  AccuserRoleIDs: Array<AccuserRoleId>;
  getAccountPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleCompany: Scalars['JSON'];
  getDrawerPermission: Scalars['JSON'];
  getAccountPeriod: Array<AccountPeriodEntity>;
  getAccountPeriodPerm: Array<AccountPeriodEntity>;
  getAccountYear: Scalars['JSON'];
  getNextAccPeriod: Scalars['JSON'];
  getSelectedAccountPeriod: AccountPeriodEntity;
  accountPeriodChecking: AccountPeriodCheck;
  getOpenAccPeriodDateRange: AccountPeriodDateRange;
  CheckExistingAccountPeriod: CheckExistingAccountPeriod;
  getPreviousPeriodOfYear?: Maybe<AccountPeriodEntity>;
  getARAging: Array<ArAgingEntity>;
  getAPAging: Array<ApAgingEntity>;
  getApprovalPolicy: Array<AccApprovalPolicyEntity>;
  getApprovalPolicyAssignment?: Maybe<Array<ProcurementApprovalPolicyAssignmentEntity>>;
  getApprovalPolicyAssignmentAP?: Maybe<Array<AccApprovalPolicyAssignmentEntity>>;
  getAllApprovalPolicyAssignment?: Maybe<Array<ProcurementApprovalPolicyAssignmentEntity>>;
  getAllApprovalPolicy?: Maybe<Array<ProcurementApprovalPolicyEntity>>;
  getGPApprovalPolicyInfoline: Scalars['JSON'];
  getPendingGP: Scalars['JSON'];
  getPendingGPByCompany: Scalars['JSON'];
  getPendingAP: Scalars['JSON'];
  getPendingAPByCompany: Scalars['JSON'];
  getAPApprovalPolicyInfoline: Scalars['JSON'];
  getAPApprovalPolicyAssignmentInfoline: Scalars['JSON'];
  getBankAccount: Array<AccBankAccountEntity>;
  getBankAccountCount: Scalars['Float'];
  getCheckingValidationBA: Scalars['JSON'];
  getBankAccountProfileListing: BankAccountReturnData;
  getBankProfile: Array<BankProfileEntity>;
  getBankProfileCount: Scalars['Float'];
  getBankProfileWithAccount: Array<BankProfileEntity>;
  getCheckingValidationBP: Scalars['JSON'];
  getBillItem: Array<BillItemEntity>;
  getBillItemCount: Scalars['Float'];
  getClassificationCode: Array<EInvoiceClassificationEntity>;
  getCOAStructure: Array<CoaStructureEntity>;
  getCompanyAssignedUsersByRole: Scalars['JSON'];
  getCompanyAssignedUsersByRoleListing: CompanyAuthorisationReturnType;
  getUserCompanyAssignedUsersByRole: Scalars['JSON'];
  getContact: Array<AccContactEntity>;
  getAllCostCentre: Array<CostCentreEntity>;
  getCostCentreDefinitionAsgmt: Array<CostCentreDefinitionAsgmtEntity>;
  getCostCentre: Array<CostCentreEntity>;
  getCostCentreListing: Scalars['JSON'];
  getNextLevelCCDefinition: Scalars['JSON'];
  getParentCostCentreForTree: Scalars['JSON'];
  getCostCentreWithAccount: CostCentreEntity;
  getCostCentreEntityWithAccount: Array<CostCentreEntity>;
  getCostCentreCOA: Array<CostCentreCoaEntity>;
  getCostCentreCOACount: Scalars['JSON'];
  getCostCentreDefinitionHeader: Array<CostCentreDefinitionHeaderEntity>;
  getCostCentreDefinitionDetail: Array<CostCentreDefinitionDetailEntity>;
  getCostCentreDefinitionListing: Array<CostCentreDefinitionHeaderEntity>;
  getCostCentreDefinition: CostCentreDefSummary;
  getCostCentreDefinitionHeaderDetails: Array<CostCentreDefinitionDetailEntity>;
  getCostCentreType: Array<CostCentreTypeEntity>;
  getCreditCardType: Array<CreditCardTypeEntity>;
  getCreditorAccount: Array<CreditorAccountEntity>;
  getCreditorAccountCount: Scalars['Float'];
  getCreditorProfileWithAccount: Array<CreditorAccountEntity>;
  getCheckingValidationCreditorProfile: Scalars['JSON'];
  getCreditorAccountCompanyAssignment: Array<CreditorAccountCompanyAssignmentEntity>;
  getCreditorAccountCompanyAssignmentPerm: Array<CreditorAccountCompanyAssignmentEntity>;
  getCreditorAccountCompanyAssignmentCount: Scalars['Float'];
  CreditorAccountTotalDue: Scalars['JSON'];
  getCreditorAccountCompanyAssignmentListing: CreditorAccountCompanyAssignmentReturnData;
  getCreditorContact: Array<CreditorContactEntity>;
  getCreditorDirector: Array<CreditorDirectorEntity>;
  getCreditorType: Array<CreditorTypeEntity>;
  getCreditorTypeCount: Scalars['Float'];
  getCreditorTypeWithAccount: Array<CreditorTypeEntity>;
  getCurrency: Array<CurrencyEntity>;
  getCurrencyExchange: Array<AccCurrencyExchangeEntity>;
  createCurrencyExchange: Scalars['JSON'];
  getCurrencyExchangeData: Scalars['JSON'];
  getDebtorAccount: Array<DebtorAccountEntity>;
  getDebtorAccountCount: Scalars['Float'];
  DebtorAccountTotalDue: Scalars['JSON'];
  getCheckingValidationDebtorAccount: Scalars['JSON'];
  getDebtorContact: Array<DebtorContactEntity>;
  getDebtorType: Array<DebtorTypeEntity>;
  getDebtorTypeCount: Scalars['Float'];
  getDepartmentListingByCOA: Array<CostCentreEntity>;
  getDepartmentListingByItemID: Array<CostCentreEntity>;
  getDeliveryLocation: Array<DeliveryLocationEntity>;
  getDeliveryLocationCount: Scalars['Float'];
  getDocumentDateValidation: Array<DocumentDateValidationEntity>;
  getDocumentNumberDetail: Array<DocumentNumberDetailEntity>;
  getDocumentNumberHeader: Array<DocumentNumberHeaderEntity>;
  getDocumentNumberHeaderWithPerm: Array<DocumentNumberHeaderEntity>;
  getDocNumByRefTable: Array<DocumentNumberHeaderEntity>;
  getDocumentNumberHeaderAndDetail: Array<DocumentNumberDetailEntity>;
  getEntityCOA: Array<EntityCoaEntity>;
  getAssignedMasterCOA: Array<MasterCoaEntity>;
  getMasterCOAForForm: Array<MasterCoaEntity>;
  getEntityCOAWithAccount: Array<EntityCoaEntity>;
  getEntityCOAFiltered: Array<EntityCoaEntity>;
  getExpenseClass: Array<ExpenseClassEntity>;
  getExpenseClassPerm: Array<ExpenseClassEntity>;
  getParentExpenseItemCategoryCOAAccountType: Scalars['JSON'];
  getExpenseItem: Array<ExpenseItemEntity>;
  getExpenseItemPerm: Array<ExpenseItemEntity>;
  getSelectedItem: Scalars['JSON'];
  getItemByCompany: Array<ExpenseItemEntity>;
  getGLCompanyPolicy: Array<GlCompanyPolicyEntity>;
  getAllTransactionChecking: Scalars['Boolean'];
  getItemClassCompanyAssignment: Array<ItemClassCompanyAssignmentEntity>;
  getJournalType: Array<JournalTypeEntity>;
  getJournalTypePerm: Array<JournalTypeEntity>;
  getJournalTypeCount: Scalars['Float'];
  getMasterCOAPerm: Array<MasterCoaEntity>;
  getMasterCOA: Array<MasterCoaEntity>;
  getMasterCOAAccountType: Array<MasterCoaEntity>;
  getMasterCOAListing: Array<MasterCoaEntity>;
  getParentMasterCOA: Array<MasterCoaEntity>;
  accountCodeChecking: Scalars['JSON'];
  getFirstMasterCOA: Scalars['Boolean'];
  getMasterCOAWithAccount: Array<MasterCoaEntity>;
  getMasterCOAFiltered: Array<MasterCoaEntity>;
  getCheckingValidationCOA: Scalars['JSON'];
  getMSICCode: Array<EInvoiceMsicEntity>;
  getNumberFormat: Array<NumberFormatEntity>;
  getPaymentMethod: Array<PaymentMethodEntity>;
  getRetainEarningAccountDef: Array<RetainEarningAccountDefEntity>;
  getStockItemPerm: Array<StockItemEntity>;
  getStockItem: Array<StockItemEntity>;
  getStockLedger: Scalars['JSON'];
  stockItemWarehouseSummary: Scalars['JSON'];
  getStockCategoryItemAssignment: Scalars['JSON'];
  getStockItemWarehouse: Array<StockItemWarehouseEntity>;
  getStockItemWarehouseByCostCentre: Array<StockItemWarehouseEntity>;
  getStockItemWarehouseListing: StockItemWarehouseType;
  stockEnquiryDetails: StockEnquiryDetailsType;
  getTaxScheme: Array<TaxSchemeEntity>;
  getTaxEffectiveDate: Array<TaxEffectiveDateEntity>;
  getTaxType: Array<TaxTypeEntity>;
  getTaxSchemeWithAccount: Array<TaxSchemeEntity>;
  getTransactionType: Array<TransactionTypeEntity>;
  getTransactionTypePerm: Array<TransactionTypeEntity>;
  getTransactionTypeCount: Scalars['Float'];
  getUOM: Array<AccUomEntity>;
  getUOMPerm: Array<AccUomEntity>;
  getUOMCount: Scalars['Float'];
  getUOMWithAccount: Array<AccUomEntity>;
  getUOMExchange: Array<AccUomExchangeEntity>;
  getUOMExchangePerm: Array<AccUomExchangeEntity>;
  getUOMExchangeCount: Scalars['Float'];
  getUomExchangeData: Scalars['JSON'];
  getUomExchangeDataListing: UomExchangeType;
  getUOMExchangeWithAccount: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getWarehouse: Array<WarehouseEntity>;
  getWarehouseCount: Scalars['Float'];
  getCompanyWarehouse?: Maybe<Scalars['JSON']>;
  approvableChecking: Scalars['Boolean'];
  getToDoSummary: PendingToDo;
  getWorkDeskHistory: Array<ToDo>;
  getWorkDeskToDo: Array<ToDo>;
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getProcurementApprovalPolicy: Array<ProcurementApprovalPolicyEntity>;
  getProcurementApprovalPolicyAssignment: Array<ProcurementApprovalPolicyAssignmentEntity>;
  getGPApprovalPolicyAssignmentInfoline: Scalars['JSON'];
  getApprovalPolicyAssignmentPO?: Maybe<Array<AccApprovalPolicyAssignmentEntity>>;
  getBulkPurchasePrice: Array<BulkPurchasePriceHeaderEntity>;
  getBulkPurchasePricePerm: Array<BulkPurchasePriceHeaderEntity>;
  getSupplierAccountByCompany: Scalars['JSON'];
  getGeneralDO: Array<DoHeaderEntity>;
  getGeneralDOItem: Array<DoItemEntity>;
  getGeneralDOCount: Scalars['Float'];
  getSupplierByDOStatus: Array<CreditorAccountEntity>;
  getWarehouseDeliveryLocation?: Maybe<Scalars['JSON']>;
  getWarehouseDeliveryLocationName?: Maybe<Scalars['JSON']>;
  getGeneralPOs: Array<PoHeaderEntity>;
  getOldGeneralPOs?: Maybe<Scalars['JSON']>;
  getGeneralGRTNs: Array<GrtnHeaderEntity>;
  getGeneralPOsListByIDs: Array<PoHeaderEntity>;
  getGeneralGRTNsListByIDs: Array<GrtnHeaderEntity>;
  getGeneralDOsListByIDs: Array<DoHeaderEntity>;
  getGRNbyStatusListing: DoReturnData;
  getFavoriteApprover: Array<FavoriteApproverEntity>;
  getFavoritePRHeader: Array<FavoritePrEntity>;
  getGeneralPurchasePolicy: Array<GeneralPurchasePolicyEntity>;
  getGRTNHeader: Array<GrtnHeaderEntity>;
  getGRTNHeaderCount: Scalars['Float'];
  getGRTNbyStatusListing: GrtnReturnData;
  getGeneralPRItem: Array<PrItemEntity>;
  getCentralisedPRItems: Array<PrItemEntity>;
  getPOHeader: Array<PoHeaderEntity>;
  getPOHeaderCount: Scalars['Float'];
  getSupplierByPOStatus: Array<CreditorAccountEntity>;
  checkingCancelClosePO: Scalars['Boolean'];
  checkPOApprovable: Scalars['Boolean'];
  getPObyStatusListing: PoReturnData;
  getPRHeader: Array<PrHeaderEntity>;
  getPRHeaderCount: Scalars['Float'];
  getPRNoList: Array<Scalars['JSON']>;
  getOutstandingGeneralPRItems: Array<PrItemEntity>;
  getOutstandingGeneralPRItemsListing: OsprItemReturnData;
  getOSGeneralPR: Array<PrHeaderEntity>;
  checkingCancelClosePR: Scalars['Boolean'];
  getPRbyStatusListing: PrHeaderReturnData;
  getRFQHeader: Array<RfqHeaderEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getRFQbyStatus: Array<RfqHeaderEntity>;
  getRFQbyStatusListing: RfqHeaderReturnData;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getScheduledPurchases: Array<ScheduledPurchasesEntity>;
  getScheduledPurchasesCount: Scalars['Float'];
  getLatestPurchaseSupplierFromPO?: Maybe<Scalars['JSON']>;
  getAssignedScheduledPurchaseByUser: GetAssignedScheduledPurchaseByUserReturnData;
  getAssignedUserByCompanyForScheduledPurchase: Scalars['JSON'];
  getItem: Array<ItemEntity>;
  getItemPerm: Array<ItemEntity>;
  getCheckingValidationItem: Scalars['JSON'];
  getLatestTaxByTaxSchemeIDs: Scalars['JSON'];
  getWarehouseByStockItem: Scalars['JSON'];
  getItemByCompanyAssignment: Array<ItemEntity>;
  getItemCategory: Array<ItemCategoryEntity>;
  getItemCategoryPerm: Array<ItemCategoryEntity>;
  getItemCategoryCompanyAssignment: Array<ItemCategoryCompanyAssignmentEntity>;
  getItemCompanyDisallow: Array<ItemCompanyDisallowEntity>;
  getPurchaseItemVariancePolicy: Array<PurchaseItemVariancePolicyEntity>;
  getSiteWithAccount: Scalars['JSON'];
  getSupplierLedgerByCreditorAccount: Array<Scalars['JSON']>;
  getSupplierAccount: Array<Scalars['JSON']>;
  getSupplierAccountListing: CreditorAccountReturnData;
  procurementLoggedInUserProfile: UserEntity;
  getAdjustmentType: Scalars['Boolean'];
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  lastApprovalPolicy?: Maybe<Scalars['DateTime']>;
  lastNumberingStructure?: Maybe<Scalars['DateTime']>;
  activeDeliveryLocationCount: Scalars['Float'];
  scheduledPurchaseInfoCount: Scalars['Float'];
  scheduledPurchaseAssignmentInfoCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  rfqCount: Scalars['Float'];
  poInfoCount: Scalars['JSON'];
  doInfoCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnInfoCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  activeSupplierCategoryCount: Scalars['Float'];
  supplierCategoryCount: Scalars['JSON'];
  activeSupplierProfileCount: Scalars['Float'];
  supplierProfileCount: Scalars['JSON'];
  activeUomCount: Scalars['Float'];
  lastUomExchange?: Maybe<Scalars['DateTime']>;
  activePurchaseCategoryCount: Scalars['Float'];
  activeItemCount: Scalars['Float'];
  activeBulkPurchaseCount: Scalars['Float'];
  lastPurchaseVariance?: Maybe<Scalars['DateTime']>;
  purchaseVariancePercenetage?: Maybe<Scalars['Float']>;
};


export type QueryGetUserRoleArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetUsersByAccountAndSoftwareListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetAssignedUserByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRoleNameByUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUserByRoleAssignedArgs = {
  RoleID: Scalars['String'];
};


export type QueryGetApAdvanceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AdvanceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApAdvancebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApAdvancebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArAdvanceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AdvanceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArAdvancebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArAdvancebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllocationDocumentArgs = {
  isEinvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  billExpenseItemID?: Maybe<Scalars['String']>;
  creditorDebtorID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
  refTable: Scalars['String'];
};


export type QueryGetDocumentDetailArgs = {
  expBillItemID: Scalars['String'];
  expBillItem: Scalars['String'];
  refTableItem: Scalars['String'];
  refTable: Scalars['String'];
  prefixID: Scalars['String'];
  prefix: Scalars['String'];
};


export type QueryGetAllocationItemDocumentArgs = {
  documentID: Scalars['String'];
  entityItemID?: Maybe<Array<Scalars['String']>>;
  refTable: Scalars['String'];
};


export type QueryGetApBatchPaymentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApBatchPaymentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApBatchPaymentbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetSelfBilledConsolidationArgs = {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  DocType?: Maybe<Array<Scalars['String']>>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetSelfBilledToConsolidateArgs = {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  CreditorName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetEinvoiceConsolidationArgs = {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  DocType?: Maybe<Array<Scalars['String']>>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDocToConsolidateArgs = {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocType: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditNoteArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApCreditNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditNotebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApValidatedDocRefArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetArCreditNoteArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArCreditNotebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArCreditNotebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorCreditNoteArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApCreditorCreditNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorCreditNotebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGeneralGrtnArgs = {
  GRTNItemIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
};


export type QueryGetGrtnItemArgs = {
  GRTNHeaderID: Array<Scalars['String']>;
};


export type QueryGetApCreditNoteItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditNoteItemID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetApDebitNoteArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApDebitNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApDebitNotebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArDebitNoteArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArDebitNotebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArDebitNotebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorDebitNoteArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApCreditorDebitNotebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApCreditorDebitNotebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApDebitNoteItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetArDebitNoteItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebitNoteItemID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryCheckInvalidTinNoArgs = {
  identityNo: Scalars['String'];
  identityType: Scalars['String'];
  tinNo: Scalars['String'];
};


export type QueryGetApFavoriteInvoiceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetArFavoriteInvoiceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetApFavoritePaymentArgs = {
  FavoritePaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApInvoicebyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApInvoicebyStatusListingArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGeneralDOsArgs = {
  DOItemIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
};


export type QueryGetDoItemArgs = {
  DOHeaderID: Array<Scalars['String']>;
};


export type QueryGetSelfBilledInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArInvoiceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArInvoicebyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArInvoicebyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetValidatedDocRefArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetApInvoiceItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetArInvoiceItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InvoiceItemID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetArOfficialReceiptArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArOfficialReceiptbyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArOfficialReceiptbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApPaymentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApPaymentbyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApPaymentbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApRefundArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RefundID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApRefundbyStatusArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArRefundArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RefundID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetArRefundbyStatusArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetArRefundbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApSelfBilledArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SelfBilledID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetApSelfBilledbyStatusArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApSelfBilledbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApInvoiceSelfBilledArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryViewLogoArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryAttachmentListingArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryFetchShareAttachmentArgs = {
  file: Scalars['Upload'];
  Prefix: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAuditLogDetailsArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  primaryKey: Scalars['String'];
  FieldID: Scalars['String'];
  TableName: Scalars['String'];
};


export type QueryGetBankReconciliationArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationBankReconArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput>;
};


export type QueryGetBankStatementArgs = {
  Mode: Scalars['String'];
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
};


export type QueryGetBankReconCbLedgerArgs = {
  DocDate: Scalars['String'];
  Mode: Scalars['String'];
  BankAccountID?: Maybe<Scalars['String']>;
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBankReconciliationItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankReconciliationItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
};


export type QueryGetBankReconciliationMatchArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
};


export type QueryGetBankTransferArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankTransferID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetBankTransferbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  FromBankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBankTransferbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  FromBankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetApprovedBankTransferArgs = {
  StatusArr: Array<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovedBankTransferListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetExternalLedgerArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExternalLedgerID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationExtLedgerArgs = {
  CompanyID: Scalars['String'];
  ExternalLedgerImportInput: Array<ExternalLedgerImportInput>;
};


export type QueryGetFavoritePaymentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoritePaymentID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetFavoriteReceiptArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteReceiptID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetLedgerArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LedgerID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
  RefTable?: Maybe<Scalars['String']>;
};


export type QueryGetLedgerCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LedgerID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
  RefTable?: Maybe<Scalars['String']>;
};


export type QueryGetLedgerItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LedgerItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetPaymentbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetPaymentItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentItemID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetReceiptArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetReceiptbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetReceiptbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetReceiptItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReceiptItemID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
};


export type QueryGetGlJournalItemReportArgs = {
  AccountCode?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<Scalars['String']>;
  JournalNo?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
};


export type QueryGetCompanyLetterTemplateArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetLetterTemplateArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LetterTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetLetterTemplateByModuleArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  LetterType: DocumentType;
  LetterCategory: ModuleType;
};


export type QueryDocumentTemplateCountArgs = {
  Module: Scalars['String'];
};


export type QueryCompanyDocumentTemplateCountArgs = {
  CompanyID: Scalars['String'];
  Module: Scalars['String'];
};


export type QueryGetStandardLetterArgs = {
  LetterCategory?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
};


export type QueryGetStandardLetterDataSourceArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StandardLetterDataSourceID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};


export type QueryGetStandardLetterParameterArgs = {
  StandardLetterID?: Maybe<Scalars['String']>;
};


export type QueryGetAuditAdjustmentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAuditAdjustmentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAuditAdjustmentbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAuditAdjustmentAdjDateArgs = {
  AdjYearOrPeriod: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAuditAdjustmentItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AuditAdjustmentItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Fyear?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetbyStatusArgs = {
  Fyear?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetBudgetMasterCoaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetParentMasterCoaForTreeArgs = {
  CompanyID: Scalars['String'];
  MasterCOABudgetArr?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetBudgetCostCentreArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBudgetImportListArgs = {
  PeriodBudgetItemInput?: Maybe<Array<PeriodBudgetItemInput>>;
  CompanyID: Scalars['String'];
};


export type QueryImportExcelBudgetArgs = {
  BudgetexcelImportInput: Scalars['String'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetBudgetItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetItemCostCentreArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BudgetItemID?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetItemPeriodArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BudgetItemPeriodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
};


export type QueryGetExternalJournalArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
};


export type QueryGetExternalJournalCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCheckingValidationExtJournalArgs = {
  CompanyID: Scalars['String'];
  ExternalJournalImportInput: Array<ExternalJournalImportInput>;
};


export type QueryGetFavoriteJournalProcessingArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDataSourceSettingsArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DataSourceSettingsID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
};


export type QueryGetReportSettingsArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ReportSettingsID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
};


export type QueryPrintReportArgs = {
  FilterZero: Scalars['Boolean'];
  FPeriod: Scalars['Float'];
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
  ReportSettingsID: Scalars['String'];
};


export type QueryDesignReportArgs = {
  ReportSettingsID: Scalars['String'];
};


export type QueryTestExtractReportDataServiceArgs = {
  FilterZero: Scalars['Boolean'];
  FPeriod: Scalars['Float'];
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
  ReportSettingsID: Scalars['String'];
};


export type QueryGetGlExportDotNetArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExportDotNetID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExportDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
};


export type QueryGetGlExportDotNetDetailArgs = {
  ExportDotNetID: Scalars['String'];
};


export type QueryGetDotNetTokenArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetJournalExportDotNetArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
};


export type QueryGetJournalbyStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  TxnArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalbyStatusListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  TxnArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalProcessingArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalProcessingID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalProcessingbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalProcessingbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetJournalReportingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLatestOpenPeriodCheckingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLatestOpenYearCheckingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLastClosedPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryNeedYearEndCloseArgs = {
  CompanyID: Scalars['String'];
};


export type QueryMonthEndYearEndDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLatestOpenPeriodCheckingDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RecurringJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
};


export type QueryGetRecurringJournalbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalGenerationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRecurringJournalItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RecurringJournalItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetRoutineJournalArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RoutineJournalID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetRoutineJournalItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RoutineJournalItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryCheckCbDoubleEntryArgs = {
  isBankTransfer?: Maybe<Scalars['Boolean']>;
  RefTable: Scalars['String'];
  suffixID: Scalars['String'];
  CompanyID: Scalars['String'];
  entityID: Scalars['String'];
};


export type QueryCheckAccRecDoubleEntryArgs = {
  isReceipt?: Maybe<Scalars['Boolean']>;
  RefTable: Scalars['String'];
  suffixID: Scalars['String'];
  CompanyID: Scalars['String'];
  entityID: Scalars['String'];
};


export type QueryCheckAccPayDoubleEntryArgs = {
  isPayment?: Maybe<Scalars['Boolean']>;
  RefTable: Scalars['String'];
  suffixID: Scalars['String'];
  CompanyID: Scalars['String'];
  entityID: Scalars['String'];
};


export type QueryGetExportedGlListingArgs = {
  prefix: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetExportedGlDetailArgs = {
  prefix: Scalars['String'];
  BatchNo: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  prefix: Scalars['String'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetGlExportDetailByAccountCodeArgs = {
  prefix: Scalars['String'];
  AccountCode: Scalars['String'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetCycleCountArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetCycleCountbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetCycleCountListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetLatestCycleCountDateArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryPendingIcTransactionCheckingArgs = {
  DocDate?: Maybe<Scalars['DateTime']>;
  WarehouseID: Scalars['String'];
};


export type QueryPendingCycleCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryGetCycleCountItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CycleCountItemID?: Maybe<Scalars['String']>;
  CycleCountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsReconciled?: Maybe<Scalars['Boolean']>;
  DocDate?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemFromWarehouseArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemCycleCountArgs = {
  DocDate?: Maybe<Scalars['DateTime']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetFavoriteStockRequisitionArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetFavoriteStockTransferRequisitionArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteStockTransferRequisitionID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};


export type QueryStockItemMovementCheckingArgs = {
  ICItemInput?: Maybe<Array<IcItemInput>>;
  input: StockItemMovementInput;
};


export type QueryStockIssueInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockReceiptInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockTransferInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockAdjustmentInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryCycleCountInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockRequisitionInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryDigitalReportInfoLineArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockIssueCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockReceiptCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockRequisitionCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockTransferCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockTransferRequisitionCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockAdjustmentCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryStockCostAdjustmentCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryCycleCountCountArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryGetInventoryPolicyArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  InventoryPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockQtyAdjustmentListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockAdjustmentItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentItemID?: Maybe<Scalars['String']>;
  StockAdjustmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentReasonArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentReasonPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
};


export type QueryGetStockAdjustmentReasonCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCategoryArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCategoryPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCostAdjustmentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockCostAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCostAdjustmentbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockCostAdjustmentListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockIssueArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockIssueID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockIssuebyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockIssueListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockIssueItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockIssueItemID?: Maybe<Scalars['String']>;
  StockIssueID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockReceiptArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockReceiptbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockReceiptListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockReceiptItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockReceiptItemID?: Maybe<Scalars['String']>;
  StockReceiptID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockRequisitionArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockRequisitionbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockRequisitionListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockRequisitionItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferItemID?: Maybe<Scalars['String']>;
  StockTransferID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferRequisitionArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockTransferRequisitionbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferRequisitionListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryGetStockTransferRequisitionItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockTransferRequisitionItemID?: Maybe<Scalars['String']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetLeaseContractArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  LeaseContractID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAccountMappingArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountMappingID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorSubmenuAmtArgs = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryApAdvanceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApAdvanceTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApAdvanceLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApInvoiceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApInvoiceTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApInvoiceLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApCreditNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorCreditNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApCreditorCreditNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorCreditNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApDebitNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApDebitNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApDebitNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorDebitNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApCreditorDebitNoteTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApCreditorDebitNoteLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApSelfBilledCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApSelfBilledTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApSelfBilledLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApPaymentCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApPaymentTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApPaymentLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApBatchPaymentCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApRefundCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCreditorAccountCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApRefundTotalAmtArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApRefundLatestUpdateArgs = {
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryApDigitalReportDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApConsolidateCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorTotalDocAmtArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDebtorTotalDocAmtArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEachSubDocAmtArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDebtorLatestUpdatedDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorLatestUpdatedDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetArSubmenuTotalArgs = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryArAdvanceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArAdvanceTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArAdvanceLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArInvoiceCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArInvoiceTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArInvoiceLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArCreditNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArCreditNoteTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArCreditNoteLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArDebitNoteCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArDebitNoteTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArDebitNoteLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArOfficialReceiptCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArOfficialReceiptTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArOfficialReceiptLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArRefundCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryArRefundTotalAmtArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryArRefundLatestUpdateArgs = {
  DebtorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPaymentCountArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryReceiptCountArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryBankTransferCountArgs = {
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryBankTransferDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentDateArgs = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReceiptDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryExternalLedgerDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCashBookCountArgs = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCostCentreDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEntityCoaDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAccountPeriodDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJournalTypeDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBudgetDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBankAccountDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureGeneralLedgerDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureCashBookDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureApDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureArDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryNumberingStructureIcDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyAssignmentDateArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryOriginalBudgetApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBudgetRevisionApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryRecurringJournalApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJournalProcessingApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAuditAdjustmentApprovalPolicyAssignmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryBankTransferStandardApprovalFlowDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReceiptStandardApprovalFlowDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentStandardApprovalFlowDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJournalProcessingCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRecurringJournalCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAuditAdjustmentCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGenerateRjCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOriginalBudgetDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryExternalJournalDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyDateArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
};


export type QueryStockEnquiryDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockReceiptDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockIssueDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockTransferDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCycleCountDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStockAdjustmentDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCompanyListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCompanyWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCompanyNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCompanyEntityWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};


export type QueryGetCheckingValidationCompanyArgs = {
  CompanyImportInput: Array<CompanyImportInput>;
};


export type QueryGetEInvoiceCompanyStartDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanySumDateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetLocalBankArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetRolePermArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleWithAccountArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryAccuserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryGetUsersRoleCompanyArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryGetAccountPeriodArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
};


export type QueryGetAccountPeriodPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
};


export type QueryGetAccountYearArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetNextAccPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSelectedAccountPeriodArgs = {
  selectedDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryAccountPeriodCheckingArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetOpenAccPeriodDateRangeArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckExistingAccountPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPreviousPeriodOfYearArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetArAgingArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AgingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
};


export type QueryGetApAgingArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AgingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetApprovalPolicyAssignmentApArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllApprovalPolicyAssignmentArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllApprovalPolicyArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
};


export type QueryGetPendingGpArgs = {
  RefTable: Scalars['String'];
};


export type QueryGetPendingGpByCompanyArgs = {
  CompanyID: Scalars['String'];
  RefTable: Scalars['String'];
};


export type QueryGetPendingApArgs = {
  RefTable: Scalars['String'];
};


export type QueryGetPendingApByCompanyArgs = {
  CompanyID: Scalars['String'];
  RefTable: Scalars['String'];
};


export type QueryGetApApprovalPolicyAssignmentInfolineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBankAccountArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetBankAccountCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCheckingValidationBaArgs = {
  CompanyID: Scalars['String'];
  BAImportInput: Array<BaImportInput>;
};


export type QueryGetBankAccountProfileListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetBankProfileArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankProfileID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBankProfileCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankProfileID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBankProfileWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCheckingValidationBpArgs = {
  BPImportInput: Array<BpImportInput>;
};


export type QueryGetBillItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BillItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetBillItemCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BillItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetClassificationCodeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetCoaStructureArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  COAStructureID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  CodeLength?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyAssignedUsersByRoleArgs = {
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAssignedUsersByRoleListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetUserCompanyAssignedUsersByRoleArgs = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetContactArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllCostCentreArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostCentreDefinitionAsgmtArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionAsgmtID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreArgs = {
  Level?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetNextLevelCcDefinitionArgs = {
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetParentCostCentreForTreeArgs = {
  CostCentreArr?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetCostCentreWithAccountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreEntityWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};


export type QueryGetCostCentreCoaArgs = {
  EntityCOAID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCostCentreCoaCountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCostCentreDefinitionHeaderArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionDetailArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type QueryGetCostCentreDefinitionHeaderDetailsArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type QueryGetCostCentreTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};


export type QueryGetCreditCardTypeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditCardID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorAccountArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
};


export type QueryGetCreditorAccountCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
};


export type QueryGetCreditorProfileWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCheckingValidationCreditorProfileArgs = {
  CreditorProfileImportInput: Array<CreditorProfileImportInput>;
};


export type QueryGetCreditorAccountCompanyAssignmentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorAccountCompanyAssignmentPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorAccountCompanyAssignmentCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryCreditorAccountTotalDueArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorAccountCompanyAssignmentListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorContactID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorDirectorArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorDirectorID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorTypeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
};


export type QueryGetCreditorTypeCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
};


export type QueryGetCreditorTypeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCurrencyArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyExchangeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CurrencyExchangeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type QueryGetDebtorAccountArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDebtorAccountCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryDebtorAccountTotalDueArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCheckingValidationDebtorAccountArgs = {
  CompanyID: Scalars['String'];
  DebtorAccountImportInput: Array<DebtorAccountImportInput>;
};


export type QueryGetDebtorContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorContactID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDebtorTypeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDebtorTypeCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDepartmentListingByCoaArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDepartmentListingByItemIdArgs = {
  StockItemID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDeliveryLocationArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDeliveryLocationCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentDateValidationArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentDateValidationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Type?: Maybe<DocumentType>;
};


export type QueryGetDocumentNumberHeaderWithPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Type?: Maybe<DocumentType>;
};


export type QueryGetDocNumByRefTableArgs = {
  RefTable: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocumentType: DocumentType;
};


export type QueryGetEntityCoaArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  EntityCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetAssignedMasterCoaArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
  CompanyID: Scalars['String'];
};


export type QueryGetMasterCoaForFormArgs = {
  AccountType?: Maybe<Array<AccountType>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetEntityCoaWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetEntityCoaFilteredArgs = {
  IsControl?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetExpenseClassArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetExpenseClassPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetParentExpenseItemCategoryCoaAccountTypeArgs = {
  ExpenseItemID?: Maybe<Scalars['String']>;
};


export type QueryGetExpenseItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetExpenseItemPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetSelectedItemArgs = {
  creditorDebtorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  refTable: Scalars['String'];
  prefix: Scalars['String'];
};


export type QueryGetItemByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetGlCompanyPolicyArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  GLCompanyPolicyID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAllTransactionCheckingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetItemClassCompanyAssignmentArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalTypeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
};


export type QueryGetJournalTypePermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
};


export type QueryGetJournalTypeCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  IsControl?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  IsControl?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaAccountTypeArgs = {
  AccountType: AccountType;
  CompanyID: Scalars['String'];
};


export type QueryGetMasterCoaListingArgs = {
  AccountType: Scalars['String'];
};


export type QueryAccountCodeCheckingArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetMasterCoaWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetMasterCoaFilteredArgs = {
  IsControl?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationCoaArgs = {
  MasterCOAInput: Array<MasterCoaInput>;
};


export type QueryGetMsicCodeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};


export type QueryGetNumberFormatArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  NumberFormatID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentMethodArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetRetainEarningAccountDefArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RetainEarningAccountDefIDID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetStockLedgerArgs = {
  WarehouseID: Scalars['String'];
  StockItemID: Scalars['String'];
};


export type QueryStockItemWarehouseSummaryArgs = {
  WarehouseIDs: Array<Scalars['String']>;
  StockItemID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetStockCategoryItemAssignmentArgs = {
  mode: Scalars['String'];
  WarehouseID: Scalars['String'];
};


export type QueryGetStockItemWarehouseArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemWarehouseByCostCentreArgs = {
  StockItemID?: Maybe<Scalars['String']>;
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemWarehouseListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
};


export type QueryStockEnquiryDetailsArgs = {
  WarehouseID: Scalars['String'];
};


export type QueryGetTaxSchemeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetTaxEffectiveDateArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TaxEffectiveDateID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxTypeArgs = {
  RecordStatus?: Maybe<RecordStatus>;
  TaxTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxSchemeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetTransactionTypeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetTransactionTypePermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetTransactionTypeCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetUomArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetUomPermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetUomCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetUomWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetUomExchangeArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangePermArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeDataListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetUomExchangeListArgs = {
  UOMID: Scalars['String'];
};


export type QueryGetWarehouseArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetWarehouseCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovableCheckingArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  RefID: Scalars['String'];
};


export type QueryGetToDoSummaryArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  RoleID: Scalars['String'];
};


export type QueryGetWorkDeskHistoryArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  tableIdentifier: Scalars['String'];
};


export type QueryGetWorkDeskToDoArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  tableIdentifier: Scalars['String'];
};


export type QueryGetApprovalLogArgs = {
  RefID: Scalars['String'];
};


export type QueryGetProcurementApprovalPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetProcurementApprovalPolicyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetGpApprovalPolicyAssignmentInfolineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetApprovalPolicyAssignmentPoArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetBulkPurchasePriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};


export type QueryGetBulkPurchasePricePermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierAccountByCompanyArgs = {
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralDoArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetGeneralDoItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOItemID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralDoCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetSupplierByDoStatusArgs = {
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type QueryGetWarehouseDeliveryLocationArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetWarehouseDeliveryLocationNameArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralPOsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetOldGeneralPOsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralGrtNsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralPOsListByIDsArgs = {
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  POHeaderID: Array<Scalars['String']>;
};


export type QueryGetGeneralGrtNsListByIDsArgs = {
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  GRTNHeaderID: Array<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralDOsListByIDsArgs = {
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DOHeaderID: Array<Scalars['String']>;
};


export type QueryGetGrNbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetFavoriteApproverArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoriteApproverID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
};


export type QueryGetFavoritePrHeaderArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  FavoritePRHeaderID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralPurchasePolicyArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  GeneralPurchasePolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtnHeaderArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetGrtnHeaderCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetGrtNbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGeneralPrItemArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoHeaderArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
};


export type QueryGetPoHeaderCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
};


export type QueryGetSupplierByPoStatusArgs = {
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type QueryCheckingCancelClosePoArgs = {
  POHeaderID: Scalars['String'];
};


export type QueryCheckPoApprovableArgs = {
  POHeaderID: Array<Scalars['String']>;
};


export type QueryGetPObyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  POType?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetPrHeaderArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetPrHeaderCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetPrNoListArgs = {
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetOutstandingGeneralPrItemsArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOutstandingGeneralPrItemsListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetOsGeneralPrArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckingCancelClosePrArgs = {
  PRHeaderID: Scalars['String'];
};


export type QueryGetPRbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRfqHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetRfqItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
};


export type QueryGetRfQbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetRfQbyStatusListingArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetInvitedSupplierArgs = {
  companyID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetRfqSupplierSubmissionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  CompanyID: Scalars['String'];
  PRHeaderID: Scalars['String'];
};


export type QueryGetScheduledPurchasesArgs = {
  statusArr?: Maybe<Array<Scalars['String']>>;
  searchValue?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledPurchasesCountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
};


export type QueryGetLatestPurchaseSupplierFromPoArgs = {
  ItemID?: Maybe<Scalars['String']>;
};


export type QueryGetAssignedScheduledPurchaseByUserArgs = {
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAssignedUserByCompanyForScheduledPurchaseArgs = {
  ScheduledPurchaseID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetItemPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationItemArgs = {
  ItemExcelInput: Array<ItemExcelInput>;
};


export type QueryGetLatestTaxByTaxSchemeIDsArgs = {
  taxSchemeIDs: Array<Scalars['String']>;
};


export type QueryGetWarehouseByStockItemArgs = {
  CompanyID: Scalars['String'];
  StockItemID: Scalars['String'];
};


export type QueryGetItemByCompanyAssignmentArgs = {
  IsDisallow?: Maybe<Scalars['Boolean']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetItemCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetItemCategoryPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetItemCategoryCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetItemCompanyDisallowArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCompanyDisallowID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseItemVariancePolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierLedgerByCreditorAccountArgs = {
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
};


export type QueryGetSupplierAccountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSupplierAccountListingArgs = {
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetAdjustmentTypeArgs = {
  optField?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryLastApprovalPolicyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastNumberingStructureArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryActiveDeliveryLocationCountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryScheduledPurchaseInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryScheduledPurchaseAssignmentInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryDoInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGrtnInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type QueryFilterString = {
  __typename?: 'QueryFilterString';
  Column: Scalars['String'];
  Expression: Scalars['String'];
  Value: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
};

export type QueryFilterStringInput = {
  Column: Scalars['String'];
  Expression: Scalars['String'];
  Value: Scalars['String'];
  ParameterName?: Maybe<Scalars['String']>;
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  ValueType: Scalars['String'];
  QueryFilterStringalue: Scalars['String'];
};

export type QueryParameterInput = {
  Name: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  ValueType: Scalars['String'];
  QueryFilterStringalue: Scalars['String'];
};

export type QuotationDetailEntity = AuditEntity & {
  __typename?: 'QuotationDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  QuotationDetailID: Scalars['String'];
  QuotationItemID: Scalars['String'];
  QuotationItem?: Maybe<QuotationItemEntity>;
  LowerLimit: Scalars['Float'];
  UpperLimit: Scalars['Float'];
  Price: Scalars['Float'];
};

export type QuotationHeaderEntity = AuditEntity & {
  __typename?: 'QuotationHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  QuotationHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  QuotationNo?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  QuotationItem?: Maybe<Array<QuotationItemEntity>>;
};

export type QuotationItemEntity = AuditEntity & {
  __typename?: 'QuotationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  QuotationItemID: Scalars['String'];
  QuotationHeaderID: Scalars['String'];
  QuotationHeader?: Maybe<QuotationHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  UOMID: Scalars['String'];
  QuotationDetail?: Maybe<Array<QuotationDetailEntity>>;
};

export type RfqEvalInput = {
  RFQHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  JustificationForSelection?: Maybe<Scalars['String']>;
};

export type RfqEvalSelectInput = {
  RFQItemID?: Maybe<Scalars['String']>;
  RFQSubmissionItemID?: Maybe<Scalars['String']>;
};

export type RfqHeaderEntity = AuditEntity & {
  __typename?: 'RFQHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RFQHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  JustificationForSelection?: Maybe<Scalars['String']>;
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  RFQSupplierInvitation?: Maybe<Array<RfqSupplierInvitationEntity>>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  PRHeaderInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ApprovalWorkflow?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type RfqHeaderReturnData = {
  __typename?: 'RFQHeaderReturnData';
  Data?: Maybe<Array<RfqHeaderEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type RfqInput = {
  PRHeaderID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQItemID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
  RFQHeader?: Maybe<RfqHeaderEntity>;
  PRItemID: Scalars['String'];
  FavoritePRItemID?: Maybe<Scalars['String']>;
  PRItem?: Maybe<PrItemEntity>;
  FavoritePRItem?: Maybe<FavoritePrItemEntity>;
  RFQSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  UOM?: Maybe<AccUomEntity>;
};

export type RfqItemInput = {
  PRItemID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
};

export type RfqRejectInput = {
  RFQHeaderID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSubmissionItemID: Scalars['String'];
  RFQSupplierSubmissionID: Scalars['String'];
  RFQSupplierSubmission?: Maybe<RfqSupplierSubmissionEntity>;
  RFQItemID: Scalars['String'];
  RFQItem?: Maybe<RfqItemEntity>;
  UnitPrice: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  Cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  TotalPrice?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  RFQHeaderID?: Maybe<Scalars['String']>;
};


export type RfqSubmissionItemEntityTotalPriceArgs = {
  RFQHeaderID?: Maybe<Scalars['String']>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  RFQItemID: Scalars['String'];
  UnitPrice: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  isSelected?: Maybe<Scalars['Boolean']>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSupplierInvitationID: Scalars['String'];
  SupplierID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
  RFQHeader?: Maybe<RfqHeaderEntity>;
  RFQSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
  LatestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSupplierSubmissionID: Scalars['String'];
  RFQSupplierInvitationID: Scalars['String'];
  RFQSupplierInvitation?: Maybe<RfqSupplierInvitationEntity>;
  RefNo: Scalars['String'];
  CreditTerm: Scalars['Float'];
  SubmittedDate: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  RFQSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
};

export type RfqSupplierSubmissionInput = {
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID: Scalars['String'];
  RefNo: Scalars['String'];
  CreditTerm: Scalars['Float'];
  SubmittedDate: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  RecordStatus: RecordStatus;
  RFQSubmissionItem: Array<RfqSubmissionItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ReceiptDeleteInput = {
  ReceiptID: Scalars['String'];
};

export type ReceiptEntity = AuditEntity & {
  __typename?: 'ReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  ReceiptID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  ReceivedFrom?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_consol_uniqueID?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
};

export type ReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DocRef?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocDueDate?: Maybe<Scalars['String']>;
  CancellationDate?: Maybe<Scalars['String']>;
  CancellationRemark?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalBaseAmt?: Maybe<Scalars['Float']>;
  BankDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ReceiptID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  PaymentMethodID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
};

export type ReceiptItemDeleteInput = {
  ReceiptItemID: Scalars['String'];
};

export type ReceiptItemEntity = AuditEntity & {
  __typename?: 'ReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  ReceiptItemID: Scalars['String'];
  ReceiptID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  Receipt?: Maybe<ReceiptEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type ReceiptItemInput = {
  ReceiptItemID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ReceiptRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

export enum RecordStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type RecurringJournalDeleteInput = {
  RecurringJournalID: Scalars['String'];
};

export type RecurringJournalEntity = AuditEntity & {
  __typename?: 'RecurringJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RecurringJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  StartPeriod: Scalars['Float'];
  Interval: Scalars['Float'];
  NoOfRecurrance: Scalars['Float'];
  PostAt?: Maybe<Scalars['String']>;
  Specify?: Maybe<Scalars['Float']>;
  StartYear?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  AccountPeriod?: Maybe<AccountPeriodEntity>;
  JournalType?: Maybe<JournalTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  /** CustomFieldResolver */
  GeneratedJournals?: Maybe<Array<JournalEntity>>;
  /** CustomFieldResolver */
  RecurringJournalStartDate?: Maybe<Scalars['JSON']>;
};

export type RecurringJournalGenerationEntity = AuditEntity & {
  __typename?: 'RecurringJournalGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RecurringJournalGenerationID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  JournalTypeID: Scalars['String'];
  JournalNo?: Maybe<Scalars['String']>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  IsManual: Scalars['Boolean'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  RefTable: Scalars['String'];
  IsGenerated: Scalars['Boolean'];
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type RecurringJournalGenerationItemEntity = AuditEntity & {
  __typename?: 'RecurringJournalGenerationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RecurringJournalGenerationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  JournalID: Scalars['String'];
  RecurringJournalGeneration?: Maybe<RecurringJournalGenerationEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type RecurringJournalInput = {
  RecurringJournalID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  StartYear: Scalars['Float'];
  StartPeriod: Scalars['Float'];
  Interval: Scalars['Float'];
  PostAt?: Maybe<Scalars['String']>;
  Specify?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
  NoOfRecurrance: Scalars['Float'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type RecurringJournalItemDeleteInput = {
  RecurringJournalItemID: Scalars['String'];
};

export type RecurringJournalItemEntity = AuditEntity & {
  __typename?: 'RecurringJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RecurringJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RecurringJournalID: Scalars['String'];
  RecurringJournal?: Maybe<RecurringJournalEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type RecurringJournalItemInput = {
  RecurringJournalItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  RecurringJournalID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
};

export type RecurringJournalRejectInput = {
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type RecurringJournalReturnData = {
  __typename?: 'RecurringJournalReturnData';
  Data?: Maybe<Array<RecurringJournalEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum RefTable {
  GeneralLedger = 'GENERAL_LEDGER',
  Cashbook = 'CASHBOOK',
  FixedAsset = 'FIXED_ASSET'
}

export type RejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  Name: Scalars['String'];
  Description: Scalars['String'];
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
  AllowNull?: Maybe<Scalars['Boolean']>;
  DropDownMember?: Maybe<DropDownMember>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  GlobalType?: Maybe<Scalars['String']>;
  Tag?: Maybe<Scalars['String']>;
};

export type ReportParameterInput = {
  Name: Scalars['String'];
  Description: Scalars['String'];
  Type: Scalars['String'];
  Visible?: Maybe<Scalars['Boolean']>;
  AllowNull?: Maybe<Scalars['Boolean']>;
  DropDownMember?: Maybe<DropDownMemberInput>;
  MultiValue?: Maybe<Scalars['Boolean']>;
  IsDateRange?: Maybe<Scalars['Boolean']>;
  GlobalType?: Maybe<Scalars['String']>;
  Tag?: Maybe<Scalars['String']>;
};

export type ReportSettingsDeleteInput = {
  ReportSettingsID: Scalars['String'];
};

export type ReportSettingsEntity = AuditEntity & {
  __typename?: 'ReportSettingsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ReportSettingsID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DataSourceCodes?: Maybe<Array<Scalars['JSON']>>;
  AccountCodes?: Maybe<Array<Scalars['JSON']>>;
  CostCentreCodes?: Maybe<Array<Scalars['JSON']>>;
  CompanyCodes?: Maybe<Array<Scalars['JSON']>>;
  Description?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  FileURL?: Maybe<Scalars['String']>;
  CellValues?: Maybe<Array<Scalars['JSON']>>;
  BucketFileName?: Maybe<Scalars['String']>;
  MediaType?: Maybe<Scalars['String']>;
  Opacity?: Maybe<Scalars['String']>;
  IsPrivate: Scalars['Boolean'];
};

export type ReportSettingsInput = {
  ReportSettingsID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  FileURL?: Maybe<Scalars['String']>;
  CellValuesJson?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Opacity?: Maybe<Scalars['String']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
};

export type RetainEarningAccountDefDeleteInput = {
  RetainEarningAccountDefID: Scalars['String'];
};

export type RetainEarningAccountDefEntity = AuditEntity & {
  __typename?: 'RetainEarningAccountDefEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RetainEarningAccountDefID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type RetainEarningAccountDefInput = {
  RetainEarningAccountDefID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  Code: Scalars['String'];
};

/** JsonType */
export type RoleInfo = {
  __typename?: 'RoleInfo';
  Name?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

/** JsonType */
export type RoleReturnType = {
  __typename?: 'RoleReturnType';
  Data?: Maybe<Array<AccRoleEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type RoleUser = {
  __typename?: 'RoleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoutineJournalDeleteInput = {
  RoutineJournalID: Scalars['String'];
};

export type RoutineJournalEntity = AuditEntity & {
  __typename?: 'RoutineJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RoutineJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Remark: Scalars['String'];
  JournalID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type RoutineJournalInput = {
  RoutineJournalID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<JournalStatus>;
};

export type RoutineJournalItemDeleteInput = {
  RoutineJournalItemID: Scalars['String'];
};

export type RoutineJournalItemEntity = AuditEntity & {
  __typename?: 'RoutineJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RoutineJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoutineJournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  DocAmt: Scalars['Float'];
  RoutineJournal?: Maybe<RoutineJournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type RoutineJournalItemInput = {
  RoutineJournalItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RoutineJournalID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  DocAmt: Scalars['Float'];
};

export type RunningNumberEntity = AuditEntity & {
  __typename?: 'RunningNumberEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RunningNumberID: Scalars['String'];
  NumberFormatID: Scalars['String'];
  NumberFormat?: Maybe<NumberFormatEntity>;
  NextNumber: Scalars['Float'];
};

export type SampleField = {
  __typename?: 'SampleField';
  testID: Scalars['String'];
  arrayID: Array<Scalars['String']>;
};

export type SampleFieldInput = {
  testID: Scalars['String'];
  arrayID: Array<Scalars['String']>;
};

export enum ScheduledFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  HalfYearly = 'HALF_YEARLY',
  Yearly = 'YEARLY',
  SpecificDate = 'SPECIFIC_DATE'
}

export type ScheduledPurchasesEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesID: Scalars['String'];
  CompanyID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  RequestStatus: ScheduledPurchasesRequestStatus;
  DeliveryLocationID: Scalars['String'];
  ScheduledFrequency: ScheduledFrequency;
  ScheduledDate?: Maybe<Scalars['String']>;
  ScheduledPurchasesItem?: Maybe<Array<ScheduledPurchasesItemEntity>>;
  ScheduledPurchasesUserAssignment?: Maybe<Array<ScheduledPurchasesUserAssignmentEntity>>;
  /** CustomFieldResolver */
  userAssigned?: Maybe<Array<ScheduledPurchasesUserInfo>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type ScheduledPurchasesInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  ScheduledFrequency?: Maybe<ScheduledFrequency>;
  ScheduledDate?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledPurchasesItems: Array<ScheduledPurchasesItemInput>;
  RequestedBy?: Maybe<Scalars['String']>;
  RequestStatus?: Maybe<ScheduledPurchasesRequestStatus>;
};

export type ScheduledPurchasesItemEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesItemID: Scalars['String'];
  ScheduledPurchasesID: Scalars['String'];
  ScheduledPurchases?: Maybe<ScheduledPurchasesEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Quantity: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxAmt: Scalars['Float'];
  TotalAmt: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  Supplier?: Maybe<CreditorAccountEntity>;
  UOM?: Maybe<AccUomEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  /** CustomFieldResolver */
  UOMExchangeList?: Maybe<Scalars['JSON']>;
};

export type ScheduledPurchasesItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export enum ScheduledPurchasesRequestStatus {
  Draft = 'DRAFT',
  PurchaseRequest = 'PURCHASE_REQUEST',
  VerifyRequest = 'VERIFY_REQUEST',
  PreparePo = 'PREPARE_PO'
}

export type ScheduledPurchasesUserAssignmentEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesUserAssignmentID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  ScheduledPurchasesID: Scalars['String'];
  ScheduledPurchases?: Maybe<ScheduledPurchasesEntity>;
};

export type ScheduledPurchasesUserAssignmentInput = {
  ScheduledPurchasesUserAssignmentID?: Maybe<Scalars['String']>;
  ScheduledPurchasesID: Scalars['String'];
  CompanyID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

/** JsonType */
export type ScheduledPurchasesUserInfo = {
  __typename?: 'ScheduledPurchasesUserInfo';
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ShareAttachmentID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardAuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StandardLetterDataSourceDeleteInput = {
  StandardLetterDataSourceID?: Maybe<Scalars['String']>;
};

export type StandardLetterDataSourceEntity = {
  __typename?: 'StandardLetterDataSourceEntity';
  StandardLetterDataSourceID: Scalars['String'];
  AccountID: Scalars['String'];
  StandardLetterID: Scalars['String'];
  Name: Scalars['String'];
  DataSource: Array<DataSource>;
  QueryFilterString: Array<QueryFilterString>;
  QueryParameter: Array<QueryParameter>;
  ReportParameter: Array<ReportParameter>;
  Version?: Maybe<Scalars['Float']>;
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
};

export type StandardLetterDataSourceInput = {
  StandardLetterDataSourceID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DataSource?: Maybe<Array<DataSourceInput>>;
  QueryFilterString?: Maybe<Array<QueryFilterStringInput>>;
  QueryParameter?: Maybe<Array<QueryParameterInput>>;
  ReportParameter?: Maybe<Array<ReportParameterInput>>;
  Version?: Maybe<Scalars['Float']>;
};

export type StandardLetterEntity = {
  __typename?: 'StandardLetterEntity';
  StandardLetterID: Scalars['String'];
  Name: Scalars['String'];
  LetterCategory: Scalars['String'];
  LetterType: Scalars['String'];
  DefaultTemplateBody?: Maybe<Scalars['String']>;
  IsAllowHtmlEditor?: Maybe<Scalars['Boolean']>;
  IsAllowReportDesignEditor?: Maybe<Scalars['Boolean']>;
  RecordStatus: RecordStatus;
  StandardLetterParameter: Array<StandardLetterParameterEntity>;
  StandardLetterDataSource: Array<StandardLetterDataSourceEntity>;
  LetterTemplate: Array<LetterTemplateEntity>;
  CompanyLetterTemplate: Array<CompanyLetterTemplateEntity>;
  /** CustomFieldResolver */
  LetterTemplateSubscription?: Maybe<Array<LetterTemplateEntity>>;
  /** CustomFieldResolver */
  CompanyLetterTemplateSubscription?: Maybe<Array<CompanyLetterTemplateEntity>>;
};

/** JsonType */
export type StandardLetterInfo = {
  __typename?: 'StandardLetterInfo';
  AccountID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
};

export type StandardLetterParameterEntity = {
  __typename?: 'StandardLetterParameterEntity';
  StandardLetterParameterID: Scalars['String'];
  StandardLetterID: Scalars['String'];
  DisplayName: Scalars['String'];
  ColumnName: Scalars['String'];
  RecordStatus: RecordStatus;
  StandardLetter?: Maybe<StandardLetterEntity>;
};

export type StandardProcurementCoreEntity = AuditEntity & {
  __typename?: 'StandardProcurementCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type StaticData = {
  __typename?: 'StaticData';
  Value: Scalars['String'];
  Desc: DynamicSource;
};

export type StaticDataInput = {
  Value: Scalars['String'];
  Desc: DynamicSourceInput;
};

export type StockAdjustmentDeleteInput = {
  StockAdjustmentID: Scalars['String'];
};

export type StockAdjustmentEntity = AuditEntity & {
  __typename?: 'StockAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockAdjustmentID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};

export type StockAdjustmentInput = {
  StockAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockAdjustmentItemDeleteInput = {
  StockAdjustmentItemID: Scalars['String'];
};

export type StockAdjustmentItemEntity = AuditEntity & {
  __typename?: 'StockAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockAdjustmentItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CycleCountItemID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  StockAdjustmentID: Scalars['String'];
  StockAdjustment?: Maybe<StockAdjustmentEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockAdjustmentItemInput = {
  StockAdjustmentItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockAdjustmentReasonDeleteInput = {
  StockAdjustmentReasonID: Scalars['String'];
};

export type StockAdjustmentReasonEntity = AuditEntity & {
  __typename?: 'StockAdjustmentReasonEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockAdjustmentReasonID: Scalars['String'];
  Reason: Scalars['String'];
  IsSystem: Scalars['Boolean'];
  IsDefault: Scalars['Boolean'];
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  StockTransferRequisitionItem?: Maybe<Array<StockTransferRequisitionItemEntity>>;
  FavoriteStockTransferRequisitionItem?: Maybe<Array<FavoriteStockTransferRequisitionItemEntity>>;
};

export type StockAdjustmentReasonInput = {
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StockAdjustmentRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type StockCategoryDeleteInput = {
  StockCategoryID: Scalars['String'];
};

export type StockCategoryEntity = AuditEntity & {
  __typename?: 'StockCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockCategoryID: Scalars['String'];
  Name: Scalars['String'];
  ParentStockCategoryID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  StockAccountCodeID?: Maybe<Scalars['String']>;
  StockAccountCode?: Maybe<MasterCoaEntity>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  /** CustomFieldResolver */
  ParentCategoryMCOA?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  StockItemCount?: Maybe<Scalars['Float']>;
};

export type StockCategoryInput = {
  StockCategoryID?: Maybe<Scalars['String']>;
  ParentStockCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  ISLastNode?: Maybe<Scalars['Boolean']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
};

export type StockCostAdjustmentDeleteInput = {
  StockCostAdjustmentID: Scalars['String'];
};

export type StockCostAdjustmentEntity = AuditEntity & {
  __typename?: 'StockCostAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockCostAdjustmentID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CompanyEntity>;
};

export type StockCostAdjustmentInput = {
  StockCostAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockCostAdjustmentItemDeleteInput = {
  StockCostAdjustmentItemID: Scalars['String'];
};

export type StockCostAdjustmentItemEntity = AuditEntity & {
  __typename?: 'StockCostAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockCostAdjustmentItemID: Scalars['String'];
  Cost?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  StockCostAdjustmentID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  StockCostAdjustment?: Maybe<StockCostAdjustmentEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  FromCurrency?: Maybe<CompanyEntity>;
};

export type StockCostAdjustmentItemInput = {
  StockCostAdjustmentItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockCostAdjustmentRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockCostAdjustmentType = {
  __typename?: 'StockCostAdjustmentType';
  Data: Array<StockCostAdjustmentEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export enum StockCostingType {
  Weighted = 'WEIGHTED',
  Fifo = 'FIFO',
  Lifo = 'LIFO'
}

export type StockIssueDeleteInput = {
  StockIssueID: Scalars['String'];
};

export type StockIssueEntity = AuditEntity & {
  __typename?: 'StockIssueEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockIssueID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  StockRequisition?: Maybe<StockRequisitionEntity>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
};

export type StockIssueInput = {
  StockIssueID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
};

export type StockIssueItemDeleteInput = {
  StockIssueItemID: Scalars['String'];
};

export type StockIssueItemEntity = AuditEntity & {
  __typename?: 'StockIssueItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockIssueItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockIssueID: Scalars['String'];
  StockIssue?: Maybe<StockIssueEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionItem?: Maybe<StockRequisitionItemEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockIssueItemInput = {
  StockIssueItemID?: Maybe<Scalars['String']>;
  StockIssueID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockIssueRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockIssueType = {
  __typename?: 'StockIssueType';
  Data: Array<StockIssueEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockItemDeleteInput = {
  StockItemID: Scalars['String'];
};

export type StockItemEntity = AuditEntity & {
  __typename?: 'StockItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockItemID: Scalars['String'];
  Name: Scalars['String'];
  ABCClass?: Maybe<Scalars['String']>;
  ShelfLife?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  EInvoiceIntegrated: Scalars['Boolean'];
  ClassificationCodeID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  StockCategoryID: Scalars['String'];
  StockCategory?: Maybe<StockCategoryEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemWarehouse?: Maybe<Array<StockItemWarehouseEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  StockTransferRequisitionItem?: Maybe<Array<StockTransferRequisitionItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
  FavoriteStockTransferRequisitionItem?: Maybe<Array<FavoriteStockTransferRequisitionItemEntity>>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  CurrentQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
};


export type StockItemEntityCurrentQtyArgs = {
  DocDate?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockItemInfo = {
  __typename?: 'StockItemInfo';
  Name?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
};

export type StockItemInput = {
  StockItemID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ABCClass?: Maybe<Scalars['String']>;
  ShelfLife?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  EInvoiceIntegrated?: Maybe<Scalars['Boolean']>;
  ClassificationCodeID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type StockItemMovementInput = {
  StockItemID: Scalars['String'];
  WarehouseID: Scalars['String'];
  UOMID: Scalars['String'];
  CompanyID: Scalars['String'];
  IsAdjustment?: Maybe<Scalars['Boolean']>;
  DocDate: Scalars['String'];
};

export type StockItemWarehouseDeleteInput = {
  StockItemWarehouseID: Scalars['String'];
};

export type StockItemWarehouseEntity = AuditEntity & {
  __typename?: 'StockItemWarehouseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockItemWarehouseID: Scalars['String'];
  MaximumQty: Scalars['Float'];
  MinimumQty: Scalars['Float'];
  ReorderLevel: Scalars['Float'];
  ReorderQty: Scalars['Float'];
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  StockQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  StockQtyByWarehouse?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  SubmitStockQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  StockTotalValue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  StockAvgUnitCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  UsedStockItemWarehouse?: Maybe<Scalars['Boolean']>;
};


export type StockItemWarehouseEntityStockQtyArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntityStockQtyByWarehouseArgs = {
  StockItemID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntitySubmitStockQtyArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntityStockTotalValueArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};


export type StockItemWarehouseEntityStockAvgUnitCostArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockItemWarehouseInput = {
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  MaximumQty?: Maybe<Scalars['Float']>;
  MinimumQty?: Maybe<Scalars['Float']>;
  ReorderLevel?: Maybe<Scalars['Float']>;
  ReorderQty?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockItemWarehouseType = {
  __typename?: 'StockItemWarehouseType';
  Data: Array<StockItemWarehouseEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type StockMovementInfo = {
  __typename?: 'StockMovementInfo';
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockMovementInput = {
  __typename?: 'StockMovementInput';
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockQtyAdjustmentType = {
  __typename?: 'StockQtyAdjustmentType';
  Data: Array<StockAdjustmentEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockReceiptDeleteInput = {
  StockReceiptID: Scalars['String'];
};

export type StockReceiptEntity = AuditEntity & {
  __typename?: 'StockReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockReceiptID: Scalars['String'];
  CompanyID: Scalars['String'];
  DOHeaderID?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockReceiptInput = {
  StockReceiptID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockReceiptItemDeleteInput = {
  StockReceiptItemID: Scalars['String'];
};

export type StockReceiptItemEntity = AuditEntity & {
  __typename?: 'StockReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockReceiptItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  BalanceQty?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockReceiptID: Scalars['String'];
  StockReceipt?: Maybe<StockReceiptEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  StockItemID?: Maybe<Scalars['String']>;
  StockItem?: Maybe<StockItemEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockReceiptItemInput = {
  StockReceiptItemID?: Maybe<Scalars['String']>;
  StockReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  BalanceQty?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockReceiptRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockReceiptType = {
  __typename?: 'StockReceiptType';
  Data: Array<StockReceiptEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockReconInput = {
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};

export type StockReconItemInput = {
  CycleCountItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
};

export type StockRequisitionCancelInput = {
  StockRequisitionID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockRequisitionDeleteInput = {
  StockRequisitionID: Scalars['String'];
};

export type StockRequisitionEntity = AuditEntity & {
  __typename?: 'StockRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  StockRequisitionID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  RequestDate: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockRequisitionInput = {
  StockRequisitionID?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockRequisitionItemDeleteInput = {
  StockRequisitionItemID: Scalars['String'];
};

export type StockRequisitionItemEntity = AuditEntity & {
  __typename?: 'StockRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockRequisitionItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  StockRequisitionID: Scalars['String'];
  StockRequisition?: Maybe<StockRequisitionEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  Warehouse?: Maybe<WarehouseEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockRequisitionItemInput = {
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type StockRequisitionRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockRequisitionType = {
  __typename?: 'StockRequisitionType';
  Data?: Maybe<Array<StockRequisitionEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockTransferDeleteInput = {
  StockTransferID: Scalars['String'];
};

export type StockTransferEntity = AuditEntity & {
  __typename?: 'StockTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockTransferID: Scalars['String'];
  CompanyID: Scalars['String'];
  FromWarehouseID: Scalars['String'];
  FromWarehouse?: Maybe<WarehouseEntity>;
  ToWarehouseID: Scalars['String'];
  ToWarehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  StockTransferRequisition?: Maybe<StockTransferRequisitionEntity>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockTransferInput = {
  StockTransferID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
};

export type StockTransferItemDeleteInput = {
  StockTransferItemID: Scalars['String'];
};

export type StockTransferItemEntity = AuditEntity & {
  __typename?: 'StockTransferItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockTransferItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockTransferID: Scalars['String'];
  StockTransfer?: Maybe<StockTransferEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockTransferItemInput = {
  StockTransferItemID?: Maybe<Scalars['String']>;
  StockTransferID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};

export type StockTransferRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type StockTransferRequisitionCancelInput = {
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type StockTransferRequisitionDeleteInput = {
  StockTransferRequisitionID: Scalars['String'];
};

export type StockTransferRequisitionEntity = AuditEntity & {
  __typename?: 'StockTransferRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  StockTransferRequisitionID: Scalars['String'];
  CompanyID: Scalars['String'];
  FromWarehouseID: Scalars['String'];
  FromWarehouse?: Maybe<WarehouseEntity>;
  ToWarehouseID: Scalars['String'];
  ToWarehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockTransferRequisitionItem?: Maybe<Array<StockTransferRequisitionItemEntity>>;
  StockTransfer?: Maybe<Array<StockTransferEntity>>;
  RequestDate: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type StockTransferRequisitionInput = {
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  RequestDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
};

export type StockTransferRequisitionItemDeleteInput = {
  StockTransferRequisitionItemID: Scalars['String'];
};

export type StockTransferRequisitionItemEntity = AuditEntity & {
  __typename?: 'StockTransferRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockTransferRequisitionItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StockTransferRequisitionID: Scalars['String'];
  StockTransferRequisition?: Maybe<StockTransferRequisitionEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  StockItemWarehouse?: Maybe<StockItemWarehouseEntity>;
};

export type StockTransferRequisitionItemInput = {
  StockTransferRequisitionItemID?: Maybe<Scalars['String']>;
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  UnitCost?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
};

export type StockTransferRequisitionRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

/** JsonType */
export type StockTransferRequisitionType = {
  __typename?: 'StockTransferRequisitionType';
  Data?: Maybe<Array<StockTransferRequisitionEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type StockTransferType = {
  __typename?: 'StockTransferType';
  Data: Array<StockTransferEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type StockWeightedAvgEntity = AuditEntity & {
  __typename?: 'StockWeightedAvgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockWeightedAvgID: Scalars['String'];
  CompanyID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  ReserveQty: Scalars['Float'];
  BalanceQty: Scalars['Float'];
  UnitCost: Scalars['Float'];
  TotalValue: Scalars['Float'];
  SubmitQty: Scalars['Float'];
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
};

export type SubLocalBankInput = {
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  Date?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'TaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TaxEffectiveDateID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  Date: Scalars['String'];
  Rate: Scalars['Float'];
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type TaxEffectiveDateInput = {
  TaxSchemeID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
};

export type TaxEffectiveDateRateInput = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TaxRate?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveInput = {
  Date?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TaxSchemeEffectiveInput = {
  TaxSchemeID: Scalars['String'];
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccTaxCategory?: Maybe<AccTaxCategory>;
  AccTaxClass?: Maybe<AccTaxClass>;
  RecordStatus?: Maybe<RecordStatus>;
  EffectiveDateRate: Array<TaxEffectiveDateRateInput>;
};

export type TaxSchemeEntity = AuditEntity & {
  __typename?: 'TaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TaxSchemeID: Scalars['String'];
  TaxTypeID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  AccTaxCategory: AccTaxCategory;
  AccTaxClass: AccTaxClass;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxType?: Maybe<TaxTypeEntity>;
  TaxEffective?: Maybe<Array<TaxEffectiveDateEntity>>;
  /** CustomFieldResolver */
  LatestTax?: Maybe<AccTax>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  CreditorAccount?: Maybe<Array<CreditorAccountEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APSelfBilledItem?: Maybe<Array<AccApSelfBilledItemEntity>>;
  APCreditorDebitNoteItem?: Maybe<Array<AccApCreditorDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  BillItem?: Maybe<Array<BillItemEntity>>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
};

export type TaxSchemeInput = {
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccTaxCategory?: Maybe<AccTaxCategory>;
  AccTaxClass?: Maybe<AccTaxClass>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type TaxTypeEntity = AuditEntity & {
  __typename?: 'TaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  TaxTypeID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RecordStatus: RecordStatus;
  TaxScheme?: Maybe<Array<TaxSchemeEntity>>;
};

export type TaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ToDo = {
  __typename?: 'ToDo';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  RefTable?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  FromBankCode?: Maybe<Scalars['String']>;
  FromBankAccountNo?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  ToBankCode?: Maybe<Scalars['String']>;
  ToBankAccountNo?: Maybe<Scalars['String']>;
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  JournalType?: Maybe<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  CcReceipt = 'CC_RECEIPT',
  CcIssue = 'CC_ISSUE',
  Receipt = 'RECEIPT',
  Issue = 'ISSUE',
  Grn = 'GRN',
  Grtn = 'GRTN'
}

export type TransactionTypeDeleteInput = {
  TransactionTypeID: Scalars['String'];
};

export type TransactionTypeEntity = AuditEntity & {
  __typename?: 'TransactionTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TransactionTypeID: Scalars['String'];
  Name: Scalars['String'];
  Type: TransactionType;
  IsSystem: Scalars['Boolean'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  FavoriteStockRequisitionItem?: Maybe<Array<FavoriteStockRequisitionItemEntity>>;
};

export type TransactionTypeInput = {
  TransactionTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

export type UomDeleteInput = {
  UOMID: Scalars['String'];
};

/** JsonType */
export type UomExchangeType = {
  __typename?: 'UOMExchangeType';
  Data?: Maybe<Scalars['JSON']>;
  TotalLength?: Maybe<Scalars['JSON']>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  UOMID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  FromAmt?: Maybe<Scalars['Float']>;
  ToAmt?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  UomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  ContactPerson?: Maybe<ContactPersonInput>;
  SupplierID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DoNo: Scalars['String'];
  CompanyID: Scalars['String'];
  File?: Maybe<Array<Scalars['Upload']>>;
};


export type UserActivityLogEntity = {
  __typename?: 'UserActivityLogEntity';
  UserActivityLogID: Scalars['String'];
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
};

export type UserApprovalAssignmentEntity = AuditEntity & {
  __typename?: 'UserApprovalAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UserApprovalAssignmentID: Scalars['String'];
  UserID: Scalars['String'];
  RoleID: Scalars['String'];
  DocRefID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
};

/** JsonType */
export type UserApprovalListInfo = {
  __typename?: 'UserApprovalListInfo';
  RoleID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  roleDetail?: Maybe<RoleInfo>;
  /** CustomFieldResolver */
  companyAssigned?: Maybe<Array<CompanyInfo>>;
};

/** JsonType */
export type UserInfo = {
  __typename?: 'UserInfo';
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

/** JsonType */
export type UserReturnType = {
  __typename?: 'UserReturnType';
  Data?: Maybe<Array<UserEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type UserRoleEntity = AuditEntity & {
  __typename?: 'UserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UserRoleID: Scalars['String'];
  UserID: Scalars['String'];
  RoleID?: Maybe<Scalars['String']>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type WarehouseDeleteInput = {
  WarehouseID: Scalars['String'];
};

export type WarehouseEntity = AuditEntity & {
  __typename?: 'WarehouseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  Code?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo: Scalars['String'];
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  StockItemWarehouse?: Maybe<Array<StockItemWarehouseEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  FromStockTransfer?: Maybe<Array<StockTransferEntity>>;
  ToStockTransfer?: Maybe<Array<StockTransferEntity>>;
  FromStockTransferRequisition?: Maybe<Array<StockTransferRequisitionEntity>>;
  ToStockTransferRequisition?: Maybe<Array<StockTransferRequisitionEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  Attachment?: Maybe<Array<AttachmentInfo>>;
  /** CustomFieldResolver */
  UsedWarehouse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  userAssigned?: Maybe<Array<UserInfo>>;
  /** CustomFieldResolver */
  stockItemAssigned?: Maybe<Array<StockItemInfo>>;
};

/** JsonType */
export type WarehouseInfo = {
  __typename?: 'WarehouseInfo';
  AccountID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type WarehouseInput = {
  WarehouseID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type WarehouseItemAssignmentInput = {
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  stockItemID: Array<Scalars['String']>;
};

/** JsonType */
export type WarehouseType = {
  __typename?: 'WarehouseType';
  Data: Array<WarehouseEntity>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type WarehouseUserAssignmentEntity = AuditEntity & {
  __typename?: 'WarehouseUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WarehouseUserAssignmentID: Scalars['String'];
  UserID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type WarehouseUserAssignmentInput = {
  WarehouseUserAssignmentID?: Maybe<Scalars['String']>;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type WorkFlowInput = {
  AccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<ApprovalStatus>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type WorkFlowStepInput = {
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserList?: Maybe<Array<UserListInput>>;
  Predicate?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalList?: Maybe<Array<ApproverInput>>;
};

/** JsonType */
export type WorkflowApprovalInfo = {
  __typename?: 'WorkflowApprovalInfo';
  WorkFlowID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitterRoleID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['DateTime']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  WorkflowStep?: Maybe<Array<Scalars['String']>>;
};

export enum YearPeriodType {
  Relative = 'RELATIVE',
  Fixed = 'FIXED'
}

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
  companyMsicAssignment: Array<CompanyMsicAssignmentEntity>;
};

export type GeneralPrItemInfo = {
  __typename?: 'generalPRItemInfo';
  generalPRItemID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  generalPRID?: Maybe<Scalars['String']>;
  purchaseItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  generalRFQItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  purchaseItemName?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
};

/** JsonType */
export type GetAssignedScheduledPurchaseByUserReturnData = {
  __typename?: 'getAssignedScheduledPurchaseByUserReturnData';
  Data?: Maybe<Array<ScheduledPurchasesEntity>>;
  TotalLength?: Maybe<Scalars['Float']>;
};

export type LhdnEinvoiceSettingsEntity = {
  __typename?: 'lhdnEinvoiceSettingsEntity';
  eInvClientId: Scalars['String'];
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  eInvAccessToken?: Maybe<Scalars['String']>;
  eInvApiUrl?: Maybe<Scalars['String']>;
};

export type PasswordAccInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type PurchaserUserRoleId = {
  __typename?: 'purchaserUserRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

/** JsonType */
export type StockEnquiryDetailsType = {
  __typename?: 'stockEnquiryDetailsType';
  ItemCount?: Maybe<Scalars['Float']>;
  TotalItemCost?: Maybe<Scalars['Float']>;
};

export type GetApAdvanceQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AdvanceID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApAdvanceQuery = (
  { __typename?: 'Query' }
  & { getAPAdvance: Array<(
    { __typename?: 'AccAPAdvanceEntity' }
    & Pick<AccApAdvanceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'AdvanceID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'CreditorAccountID' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'PaymentID' | 'Description' | 'Remark' | 'Amount' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName' | 'GSTRegNo' | 'BankAccountNo' | 'BankName'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'BankAccountID' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, APPayment?: Maybe<Array<(
      { __typename?: 'AccAPPaymentEntity' }
      & Pick<AccApPaymentEntity, 'PaymentID' | 'DocNo' | 'DocDate'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateApAdvanceMutationVariables = Exact<{
  input: ApAdvanceInput;
}>;


export type CreateApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createAPAdvance: (
    { __typename?: 'AccAPAdvanceEntity' }
    & Pick<AccApAdvanceEntity, 'AdvanceID'>
  ) }
);

export type UpdateApAdvanceMutationVariables = Exact<{
  input: ApAdvanceInput;
}>;


export type UpdateApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateAPAdvance: (
    { __typename?: 'AccAPAdvanceEntity' }
    & Pick<AccApAdvanceEntity, 'AdvanceID'>
  ) }
);

export type DeleteApAdvanceMutationVariables = Exact<{
  AdvanceID: Scalars['String'];
}>;


export type DeleteApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPAdvance'>
);

export type PostApAdvanceMutationVariables = Exact<{
  AdvanceIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPAdvance'>
);

export type RejectApAdvanceMutationVariables = Exact<{
  AdvanceIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApAdvanceRejectInput;
}>;


export type RejectApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPAdvance'>
);

export type GetApAdvancebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApAdvancebyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPAdvancebyStatus: Array<(
    { __typename?: 'AccAPAdvanceEntity' }
    & Pick<AccApAdvanceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'AdvanceID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'CreditorAccountID' | 'ProfileInfo' | 'IsAutoDocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'PaymentID' | 'BankAccountID' | 'Description' | 'Remark' | 'Amount' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
    )>, APPayment?: Maybe<Array<(
      { __typename?: 'AccAPPaymentEntity' }
      & Pick<AccApPaymentEntity, 'PaymentID' | 'DocNo' | 'DocDate'>
    )>>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID' | 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApAdvancebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApAdvancebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPAdvancebyStatusListing: Array<(
    { __typename?: 'AccAPAdvanceEntity' }
    & Pick<AccApAdvanceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'AdvanceID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'CreditorAccountID' | 'ProfileInfo' | 'IsAutoDocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'PaymentID' | 'BankAccountID' | 'Description' | 'Remark' | 'Amount' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
    )>, APPayment?: Maybe<Array<(
      { __typename?: 'AccAPPaymentEntity' }
      & Pick<AccApPaymentEntity, 'PaymentID' | 'DocNo' | 'DocDate'>
    )>>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID' | 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GeneratePaymentMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type GeneratePaymentMutation = (
  { __typename?: 'Mutation' }
  & { generatePayment: (
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'PaymentID'>
  ) }
);

export type GetApAllocationDocumentQueryVariables = Exact<{
  entityID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  refTable: Scalars['String'];
  companyID: Scalars['String'];
  creditorDebtorID?: Maybe<Scalars['String']>;
  isEinvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
}>;


export type GetApAllocationDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllocationDocument'>
);

export type GetApAllocationItemDocumentQueryVariables = Exact<{
  refTable: Scalars['String'];
  documentID: Scalars['String'];
  entityItemID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetApAllocationItemDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllocationItemDocument'>
);

export type CreateApAllocationMutationVariables = Exact<{
  input?: Maybe<Array<AllocationInput> | AllocationInput>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type CreateApAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAllocation'>
);

export type GetDocumentDetailQueryVariables = Exact<{
  expBillItem: Scalars['String'];
  expBillItemID: Scalars['String'];
  refTable: Scalars['String'];
  refTableItem: Scalars['String'];
  prefix: Scalars['String'];
  prefixID: Scalars['String'];
}>;


export type GetDocumentDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocumentDetail'>
);

export type GetApItemQueryVariables = Exact<{
  refID: Scalars['String'];
}>;


export type GetApItemQuery = (
  { __typename?: 'Query' }
  & { getAPInvoiceItem: Array<(
    { __typename?: 'AccAPInvoiceItemEntity' }
    & Pick<AccApInvoiceItemEntity, 'InvoiceItemID' | 'BalanceAmt' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'ExpenseItemID'>
    & { TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      & { LatestTax?: Maybe<(
        { __typename?: 'AccTax' }
        & Pick<AccTax, 'Rate'>
      )> }
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
    )>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName'>
    )> }
  )>, getAPDebitNoteItem: Array<(
    { __typename?: 'AccAPDebitNoteItemEntity' }
    & Pick<AccApDebitNoteItemEntity, 'DebitNoteItemID' | 'BalanceAmt' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'ExpenseItemID'>
    & { TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      & { LatestTax?: Maybe<(
        { __typename?: 'AccTax' }
        & Pick<AccTax, 'Rate'>
      )> }
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
    )>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName'>
    )> }
  )> }
);

export type GetApBatchPaymentQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
}>;


export type GetApBatchPaymentQuery = (
  { __typename?: 'Query' }
  & { getAPBatchPayment: Array<(
    { __typename?: 'AccAPBatchPaymentEntity' }
    & Pick<AccApBatchPaymentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CompanyID' | 'BatchPaymentID' | 'DocDate' | 'TransactionDate' | 'DocAmt' | 'Description' | 'BankAccountID' | 'ApprovalStatus' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'RefNo'>
    & { BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, APPayment?: Maybe<Array<(
      { __typename?: 'AccAPPaymentEntity' }
      & Pick<AccApPaymentEntity, 'PaymentID' | 'DocAmt' | 'ApprovalStatus' | 'CreditorAccountID' | 'DocDate' | 'DocNo' | 'Description'>
      & { CreditorAccount?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CompanyName'>
      )>, Allocation?: Maybe<Array<(
        { __typename?: 'AccAPAllocationObj' }
        & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt' | 'DebitDocDate' | 'CreditDocDate'>
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt' | 'DebitDocDate' | 'CreditDocDate'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>> }
  )> }
);

export type CreateApBatchPaymentWithAllocationMutationVariables = Exact<{
  input: ApBatchPaymentInput;
  allocationInput: Array<AllocationInput> | AllocationInput;
}>;


export type CreateApBatchPaymentWithAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAPBatchPaymentWithAllocation'>
);

export type UpdateApBatchPaymentWithAllocationMutationVariables = Exact<{
  input: ApBatchPaymentInput;
  allocationInput: Array<AllocationInput> | AllocationInput;
}>;


export type UpdateApBatchPaymentWithAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAPBatchPaymentWithAllocation'>
);

export type DeleteBatchPaymentMutationVariables = Exact<{
  BatchPaymentID: Scalars['String'];
}>;


export type DeleteBatchPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBatchPayment'>
);

export type PayApBatchPaymentMutationVariables = Exact<{
  BatchPaymentID: Scalars['String'];
  allocationInput: Array<AllocationInput> | AllocationInput;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PayApBatchPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'payAPBatchPayment'>
);

export type GetApBatchPaymentbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  BatchPaymentID?: Maybe<Scalars['String']>;
}>;


export type GetApBatchPaymentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPBatchPaymentbyStatus: Array<(
    { __typename?: 'AccAPBatchPaymentEntity' }
    & Pick<AccApBatchPaymentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'BatchPaymentID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BankAccountID' | 'RefNo' | 'Description' | 'ApprovalStatus' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate'>
    & { BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, APPayment?: Maybe<Array<(
      { __typename?: 'AccAPPaymentEntity' }
      & Pick<AccApPaymentEntity, 'PaymentID' | 'DocAmt' | 'ApprovalStatus' | 'CreditorAccountID' | 'DocDate' | 'DocNo' | 'Description'>
      & { CreditorAccount?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CompanyName'>
      )>, Allocation?: Maybe<Array<(
        { __typename?: 'AccAPAllocationObj' }
        & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt' | 'DebitDocDate' | 'CreditDocDate'>
      )>> }
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type RejectBatchPaymentMutationVariables = Exact<{
  BatchPaymentID: Scalars['String'];
}>;


export type RejectBatchPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectBatchPayment'>
);

export type GetSelfBilledConsolidationQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DocType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
}>;


export type GetSelfBilledConsolidationQuery = (
  { __typename?: 'Query' }
  & { getSelfBilledConsolidation: Array<(
    { __typename?: 'EinvoiceConsolidationEntity' }
    & Pick<EinvoiceConsolidationEntity, 'AccountID' | 'CompanyID' | 'ConsolidationID' | 'DocDate' | 'DocNo' | 'DocAmt' | 'Amount' | 'TaxAmt' | 'Description' | 'ProfileInfo' | 'einvoice_trx_type' | 'createdTs' | 'createdBy' | 'einvoice_status' | 'einvoice'>
    & { eInvoiceConsolidationDetail?: Maybe<Array<(
      { __typename?: 'EinvoiceConsolidationDetailEntity' }
      & Pick<EinvoiceConsolidationDetailEntity, 'Consolidation_DetailID' | 'AccountID' | 'ConsolidationID' | 'APDocNo' | 'SB' | 'CreditorDebtorName' | 'RefTable' | 'RefRecordID' | 'Amount' | 'TaxAmt' | 'DocAmt'>
    )>> }
  )> }
);

export type GetSelfBilledToConsolidateQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  DocType?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  CreditorName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
}>;


export type GetSelfBilledToConsolidateQuery = (
  { __typename?: 'Query' }
  & { getSelfBilledToConsolidate?: Maybe<Array<(
    { __typename?: 'AccAPSelfBilledEntity' }
    & Pick<AccApSelfBilledEntity, 'AccountID' | 'CompanyID' | 'SelfBilledID' | 'CreditorAccountID' | 'DocDate' | 'DocNo' | 'APLedger' | 'DocAmt' | 'Amount' | 'TaxAmt'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName'>
    )> }
  )>> }
);

export type CreateSelfBilledConsolidateMutationVariables = Exact<{
  input: ConsolidationInput;
  itemInput: Array<ConsolidationDetailInput> | ConsolidationDetailInput;
}>;


export type CreateSelfBilledConsolidateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSelfBilledConsolidate'>
);

export type GetApCreditNoteQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNoteQuery = (
  { __typename?: 'Query' }
  & { getAPCreditNote: Array<(
    { __typename?: 'AccAPCreditNoteEntity' }
    & Pick<AccApCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CreditNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'BalanceAmt' | 'DocAmtBeforeTax' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName' | 'GSTRegNo' | 'BankAccountNo' | 'BankName'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditNoteItemEntity' }
      & Pick<AccApCreditNoteItemEntity, 'ExpenseItemID' | 'CreditNoteItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'Quantity' | 'CostCentreID' | 'Remark' | 'Sequence'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'Description' | 'ItemName'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateApCreditNoteMutationVariables = Exact<{
  input: ApCreditNoteInput;
  itemInput?: Maybe<Array<ApCreditNoteItemInput> | ApCreditNoteItemInput>;
  allocationInput: Array<ApAllocationInput> | ApAllocationInput;
  docRefInput?: Maybe<Array<CnDocRefInputType> | CnDocRefInputType>;
}>;


export type CreateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPCreditNote: (
    { __typename?: 'AccAPCreditNoteEntity' }
    & Pick<AccApCreditNoteEntity, 'CreditNoteID'>
  ) }
);

export type UpdateApCreditNoteMutationVariables = Exact<{
  input: ApCreditNoteInput;
  itemInput?: Maybe<Array<ApCreditNoteItemInput> | ApCreditNoteItemInput>;
  allocationInput: Array<ApAllocationInput> | ApAllocationInput;
  docRefInput?: Maybe<Array<CnDocRefInputType> | CnDocRefInputType>;
}>;


export type UpdateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPCreditNote: (
    { __typename?: 'AccAPCreditNoteEntity' }
    & Pick<AccApCreditNoteEntity, 'CreditNoteID'>
  ) }
);

export type PostApCreditNoteMutationVariables = Exact<{
  CreditNoteIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPCreditNote'>
);

export type RejectApCreditNoteMutationVariables = Exact<{
  CreditNoteIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApCreditNoteRejectInput;
}>;


export type RejectApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPCreditNote'>
);

export type DeleteApCreditNoteMutationVariables = Exact<{
  CreditNoteID: Scalars['String'];
}>;


export type DeleteApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPCreditNote'>
);

export type GetApCreditNotebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNotebyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPCreditNotebyStatus: Array<(
    { __typename?: 'AccAPCreditNoteEntity' }
    & Pick<AccApCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CreditNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'einvoice_irbm_uniqueID' | 'MSICCode' | 'CreditorAccountID' | 'DocNo' | 'CostCentreID' | 'DocNumGenerated' | 'Amount' | 'BalanceAmt' | 'DocAmtBeforeTax' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus' | 'ExpenseItemID'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'Address'>
    )>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditNoteItemEntity' }
      & Pick<AccApCreditNoteItemEntity, 'CreditNoteItemID' | 'ExpenseItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'Quantity' | 'CostCentreID' | 'UOMID' | 'Remark' | 'Sequence'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )> }
    )>>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'Description' | 'ItemName' | 'TaxRate' | 'TaxSchemeID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
        & { LatestTax?: Maybe<(
          { __typename?: 'AccTax' }
          & Pick<AccTax, 'Rate'>
        )> }
      )> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApCreditNotebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNotebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPCreditNotebyStatusListing: Array<(
    { __typename?: 'AccAPCreditNoteEntity' }
    & Pick<AccApCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CreditNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'MSICCode' | 'einvoice_irbm_uniqueID' | 'einvoice_status' | 'einvoice_sent_date' | 'einvoice_is_refund_note' | 'einvoice' | 'SB' | 'DocRefNo' | 'CreditorAccountID' | 'DocNo' | 'CostCentreID' | 'DocNumGenerated' | 'Amount' | 'BalanceAmt' | 'DocAmtBeforeTax' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus' | 'ExpenseItemID'>
    & { CNDocRef?: Maybe<Array<(
      { __typename?: 'AccAPCreditNoteDocRefEntity' }
      & Pick<AccApCreditNoteDocRefEntity, 'CreditNoteID' | 'DocRefID'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'Address'>
    )>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditNoteItemEntity' }
      & Pick<AccApCreditNoteItemEntity, 'CreditNoteItemID' | 'ExpenseItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'Quantity' | 'CostCentreID' | 'UOMID' | 'Remark' | 'Sequence' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )> }
    )>>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'Description' | 'ItemName' | 'TaxRate' | 'TaxSchemeID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
        & { LatestTax?: Maybe<(
          { __typename?: 'AccTax' }
          & Pick<AccTax, 'Rate'>
        )> }
      )> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApCreditorCreditNoteQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorCreditNoteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditorCreditNoteQuery = (
  { __typename?: 'Query' }
  & { getAPCreditorCreditNote: Array<(
    { __typename?: 'AccAPCreditorCreditNoteEntity' }
    & Pick<AccApCreditorCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CreditorCreditNoteID' | 'GRTN' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'BalanceAmt' | 'DocAmtBeforeTax' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { APCreditorCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditorCreditNoteItemEntity' }
      & Pick<AccApCreditorCreditNoteItemEntity, 'CreditorCreditNoteItemID' | 'GRTNItemID' | 'Amount' | 'BalanceItemAmt' | 'DocAmt' | 'UnitPrice' | 'Quantity' | 'TaxAmt' | 'TaxRate' | 'TaxSchemeID' | 'ItemID' | 'ExpenseItemID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'Description' | 'ItemName'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )>, AllocationItem?: Maybe<Array<(
        { __typename?: 'AccAPAllocationItemObj' }
        & Pick<AccApAllocationItemObj, 'AllocationID' | 'AllocationItemID' | 'DebitItemRefTable' | 'DebitItemID' | 'CreditItemRefTable' | 'CreditItemID' | 'CreditOrDebit' | 'AllocationItemAmt'>
      )>> }
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'Address'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApCreditorCreditNoteByStatusQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetApCreditorCreditNoteByStatusQuery = (
  { __typename?: 'Query' }
  & { getAPCreditorCreditNotebyStatus: Array<(
    { __typename?: 'AccAPCreditorCreditNoteEntity' }
    & Pick<AccApCreditorCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CreditorCreditNoteID' | 'GRTN' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'MSICCode' | 'einvoice_irbm_uniqueID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'BalanceAmt' | 'DocAmtBeforeTax' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { APCreditorCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditorCreditNoteItemEntity' }
      & Pick<AccApCreditorCreditNoteItemEntity, 'CreditorCreditNoteItemID' | 'GRTNItemID' | 'Amount' | 'BalanceItemAmt' | 'DocAmt' | 'UnitPrice' | 'Quantity' | 'Remark' | 'TaxSchemeID' | 'CostCentreID' | 'UOMID' | 'TaxAmt' | 'TaxRate' | 'ItemID' | 'ExpenseItemID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'Description' | 'ItemName'>
      )> }
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'Address'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApCreditorCreditNoteByStatusListingQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApCreditorCreditNoteByStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPCreditorCreditNotebyStatusListing: Array<(
    { __typename?: 'AccAPCreditorCreditNoteEntity' }
    & Pick<AccApCreditorCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'CreditorCreditNoteID' | 'GRTN' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_is_refund_note' | 'einvoice_msic_code_id' | 'MSICCode' | 'einvoice_irbm_uniqueID' | 'einvoice' | 'SB' | 'DocRefNo' | 'einvoice_status' | 'einvoice_sent_date' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'BalanceAmt' | 'DocAmtBeforeTax' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { CNDocRef?: Maybe<Array<(
      { __typename?: 'AccAPCreditNoteDocRefEntity' }
      & Pick<AccApCreditNoteDocRefEntity, 'CreditNoteID' | 'DocRefID'>
    )>>, APCreditorCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditorCreditNoteItemEntity' }
      & Pick<AccApCreditorCreditNoteItemEntity, 'CreditorCreditNoteItemID' | 'GRTNItemID' | 'Amount' | 'BalanceItemAmt' | 'DocAmt' | 'UnitPrice' | 'Quantity' | 'Remark' | 'TaxSchemeID' | 'CostCentreID' | 'UOMID' | 'TaxAmt' | 'TaxRate' | 'ItemID' | 'ExpenseItemID' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'Description' | 'ItemName'>
      )> }
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'Address'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateApCreditorCreditNoteMutationVariables = Exact<{
  input: ApCreditorCreditNoteInput;
  allocationInput: Array<ApAllocationInput> | ApAllocationInput;
  itemInput?: Maybe<Array<ApCreditorCreditNoteItemInput> | ApCreditorCreditNoteItemInput>;
  docRefInput?: Maybe<Array<CnDocRefInputType> | CnDocRefInputType>;
}>;


export type CreateApCreditorCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAPCreditorCreditNote'>
);

export type UpdateApCreditorCreditNoteMutationVariables = Exact<{
  input: ApCreditorCreditNoteInput;
  allocationInput: Array<ApAllocationInput> | ApAllocationInput;
  itemInput?: Maybe<Array<ApCreditorCreditNoteItemInput> | ApCreditorCreditNoteItemInput>;
  docRefInput?: Maybe<Array<CnDocRefInputType> | CnDocRefInputType>;
}>;


export type UpdateApCreditorCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAPCreditorCreditNote'>
);

export type PostApCreditorCreditNoteMutationVariables = Exact<{
  CreditorCreditNoteIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApCreditorCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPCreditorCreditNote'>
);

export type RejectApCreditorCreditNoteMutationVariables = Exact<{
  CreditorCreditNoteIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApCreditorCreditNoteRejectInput;
}>;


export type RejectApCreditorCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPCreditorCreditNote'>
);

export type DeleteApCreditorCreditNoteMutationVariables = Exact<{
  CreditorCreditNoteID: Scalars['String'];
}>;


export type DeleteApCreditorCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPCreditorCreditNote'>
);

export type GetGeneralGrtnQueryVariables = Exact<{
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  GRTNItemIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetGeneralGrtnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGeneralGRTN'>
);

export type GetGrtnItemQueryVariables = Exact<{
  GRTNHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetGrtnItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGRTNItem'>
);

export type GetApValidatedDocRefQueryVariables = Exact<{
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetApValidatedDocRefQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAPValidatedDocRef'>
);

export type GetApDebitNoteQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNoteQuery = (
  { __typename?: 'Query' }
  & { getAPDebitNote: Array<(
    { __typename?: 'AccAPDebitNoteEntity' }
    & Pick<AccApDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'DebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditTerm' | 'DueDate' | 'Description' | 'Remark' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus' | 'ExpenseItemID'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName' | 'GSTRegNo' | 'BankAccountNo' | 'BankName' | 'CreditTerm'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
    )>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteItemEntity' }
      & Pick<AccApDebitNoteItemEntity, 'DebitNoteItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'Quantity' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'CostCentreID' | 'Remark' | 'Sequence'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateApDebitNoteMutationVariables = Exact<{
  input: ApDebitNoteInput;
  itemInput?: Maybe<Array<ApDebitNoteItemInput> | ApDebitNoteItemInput>;
  docRefInput?: Maybe<Array<DocRefInputType> | DocRefInputType>;
}>;


export type CreateApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPDebitNote: (
    { __typename?: 'AccAPDebitNoteEntity' }
    & Pick<AccApDebitNoteEntity, 'DebitNoteID'>
  ) }
);

export type UpdateApDebitNoteMutationVariables = Exact<{
  input: ApDebitNoteInput;
  itemInput?: Maybe<Array<ApDebitNoteItemInput> | ApDebitNoteItemInput>;
  docRefInput?: Maybe<Array<DocRefInputType> | DocRefInputType>;
}>;


export type UpdateApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPDebitNote: (
    { __typename?: 'AccAPDebitNoteEntity' }
    & Pick<AccApDebitNoteEntity, 'DebitNoteID'>
  ) }
);

export type PostApDebitNoteMutationVariables = Exact<{
  DebitNoteIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPDebitNote'>
);

export type RejectApDebitNoteMutationVariables = Exact<{
  DebitNoteIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApDebitNoteRejectInput;
}>;


export type RejectApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPDebitNote'>
);

export type DeleteApDebitNoteMutationVariables = Exact<{
  DebitNoteID: Scalars['String'];
}>;


export type DeleteApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPDebitNote'>
);

export type GetApDebitNotebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNotebyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPDebitNotebyStatus: Array<(
    { __typename?: 'AccAPDebitNoteEntity' }
    & Pick<AccApDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'DebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'ExpenseItemID' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'MSICCode' | 'einvoice_irbm_uniqueID' | 'DocNo' | 'Amount' | 'BalanceAmt' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus' | 'ProfileInfo'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditTerm'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteItemEntity' }
      & Pick<AccApDebitNoteItemEntity, 'DebitNoteItemID' | 'UnitPrice' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'UOMID' | 'Remark' | 'Quantity' | 'ExpenseItemID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetApDebitNotebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNotebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPDebitNotebyStatusListing: Array<(
    { __typename?: 'AccAPDebitNoteEntity' }
    & Pick<AccApDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'DebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'ExpenseItemID' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'MSICCode' | 'einvoice' | 'SB' | 'DocRefNo' | 'einvoice_irbm_uniqueID' | 'einvoice_doc_ref_invoice_id' | 'einvoice_status' | 'einvoice_sent_date' | 'DocNo' | 'Amount' | 'BalanceAmt' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus' | 'ProfileInfo'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditTerm'>
    )>, DNDocRef?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteDocRefEntity' }
      & Pick<AccApDebitNoteDocRefEntity, 'DebitNoteID' | 'DocRefID'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteItemEntity' }
      & Pick<AccApDebitNoteItemEntity, 'DebitNoteItemID' | 'UnitPrice' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'UOMID' | 'Remark' | 'Quantity' | 'ExpenseItemID' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetApCreditorDebitNoteQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorDebitNoteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditorDebitNoteQuery = (
  { __typename?: 'Query' }
  & { getAPCreditorDebitNote: Array<(
    { __typename?: 'AccAPCreditorDebitNoteEntity' }
    & Pick<AccApCreditorDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'CreditorDebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditTerm' | 'DueDate' | 'Description' | 'Remark' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus' | 'ExpenseItemID'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName' | 'GSTRegNo' | 'BankAccountNo' | 'BankName'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
    )>, APCreditorDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditorDebitNoteItemEntity' }
      & Pick<AccApCreditorDebitNoteItemEntity, 'CreditorDebitNoteItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'Quantity' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'CostCentreID' | 'Remark' | 'Sequence'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateApCreditorDebitNoteMutationVariables = Exact<{
  input: ApCreditorDebitNoteInput;
  itemInput?: Maybe<Array<ApCreditorDebitNoteItemInput> | ApCreditorDebitNoteItemInput>;
  docRefInput?: Maybe<Array<DocRefInputType> | DocRefInputType>;
}>;


export type CreateApCreditorDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPCreditorDebitNote: (
    { __typename?: 'AccAPCreditorDebitNoteEntity' }
    & Pick<AccApCreditorDebitNoteEntity, 'CreditorDebitNoteID'>
  ) }
);

export type UpdateApCreditorDebitNoteMutationVariables = Exact<{
  input: ApCreditorDebitNoteInput;
  itemInput?: Maybe<Array<ApCreditorDebitNoteItemInput> | ApCreditorDebitNoteItemInput>;
  docRefInput?: Maybe<Array<DocRefInputType> | DocRefInputType>;
}>;


export type UpdateApCreditorDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPCreditorDebitNote: (
    { __typename?: 'AccAPCreditorDebitNoteEntity' }
    & Pick<AccApCreditorDebitNoteEntity, 'CreditorDebitNoteID'>
  ) }
);

export type PostApCreditorDebitNoteMutationVariables = Exact<{
  CreditorDebitNoteIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApCreditorDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPCreditorDebitNote'>
);

export type RejectApCreditorDebitNoteMutationVariables = Exact<{
  CreditorDebitNoteIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApCreditorDebitNoteRejectInput;
}>;


export type RejectApCreditorDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPCreditorDebitNote'>
);

export type DeleteApCreditorDebitNoteMutationVariables = Exact<{
  CreditorDebitNoteID: Scalars['String'];
}>;


export type DeleteApCreditorDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPCreditorDebitNote'>
);

export type GetApCreditorDebitNotebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditorDebitNotebyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPCreditorDebitNotebyStatus: Array<(
    { __typename?: 'AccAPCreditorDebitNoteEntity' }
    & Pick<AccApCreditorDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'CreditorDebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditTerm' | 'DueDate' | 'Description' | 'Remark' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus' | 'ExpenseItemID'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'Address' | 'CompanyName' | 'GSTRegNo' | 'BankAccountNo' | 'BankName'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ExpenseItem?: Maybe<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
    )>, APCreditorDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditorDebitNoteItemEntity' }
      & Pick<AccApCreditorDebitNoteItemEntity, 'CreditorDebitNoteItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'Quantity' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'CostCentreID' | 'Remark' | 'Sequence'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetApCreditorDebitNotebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApCreditorDebitNotebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPCreditorDebitNotebyStatusListing: Array<(
    { __typename?: 'AccAPCreditorDebitNoteEntity' }
    & Pick<AccApCreditorDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'CreditorDebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditTerm' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'MSICCode' | 'einvoice_irbm_uniqueID' | 'einvoice_doc_ref_invoice_id' | 'einvoice' | 'SB' | 'DocRefNo' | 'einvoice_status' | 'einvoice_sent_date' | 'DueDate' | 'Description' | 'Remark' | 'CreditorAccountID' | 'DocNo' | 'IsAutoDocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, DNDocRef?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteDocRefEntity' }
      & Pick<AccApDebitNoteDocRefEntity, 'DebitNoteID' | 'DocRefID'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditTerm'>
    )>, APCreditorDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPCreditorDebitNoteItemEntity' }
      & Pick<AccApCreditorDebitNoteItemEntity, 'CreditorDebitNoteItemID' | 'ExpenseItemID' | 'UOMID' | 'Amount' | 'TaxSchemeID' | 'Quantity' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'UnitPrice' | 'CostCentreID' | 'Remark' | 'Sequence' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetSelfBilledInvoiceQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetSelfBilledInvoiceQuery = (
  { __typename?: 'Query' }
  & { getSelfBilledInvoice: Array<(
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'InvoiceID' | 'DocNo' | 'einvoice_directsend' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_status'>
  )> }
);

export type GetArFavoriteInvoiceQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
}>;


export type GetArFavoriteInvoiceQuery = (
  { __typename?: 'Query' }
  & { getARFavoriteInvoice: Array<(
    { __typename?: 'AccARFavoriteInvoiceEntity' }
    & Pick<AccArFavoriteInvoiceEntity, 'FavoriteInvoiceID' | 'Name' | 'DebtorAccountID' | 'InterestRate' | 'Remark' | 'Description'>
    & { FavoriteInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccARFavoriteInvoiceItemEntity' }
      & Pick<AccArFavoriteInvoiceItemEntity, 'FavoriteInvoiceID' | 'MasterCOAID' | 'CostCentreID' | 'TaxSchemeID' | 'Amount' | 'Remark' | 'TaxRate' | 'TaxAmt' | 'DocAmt'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )> }
    )>> }
  )> }
);

export type GetApFavoriteInvoiceQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteInvoiceID?: Maybe<Scalars['String']>;
}>;


export type GetApFavoriteInvoiceQuery = (
  { __typename?: 'Query' }
  & { getAPFavoriteInvoice: Array<(
    { __typename?: 'AccAPFavoriteInvoiceEntity' }
    & Pick<AccApFavoriteInvoiceEntity, 'FavoriteInvoiceID' | 'Name' | 'DocAmt' | 'CreditorAccountID' | 'CreditTerm' | 'Description' | 'Remark' | 'RefNo'>
    & { APFavoriteInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPFavoriteInvoiceItemEntity' }
      & Pick<AccApFavoriteInvoiceItemEntity, 'Quantity' | 'UnitPrice' | 'FavoriteInvoiceID' | 'ExpenseItemID' | 'CostCentreID' | 'TaxSchemeID' | 'UOMID' | 'Amount' | 'Remark' | 'TaxRate' | 'TaxAmt' | 'DocAmt'>
      & { ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ItemName' | 'Description'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateArFavoriteInvoiceMutationVariables = Exact<{
  ARFavoriteInvoiceInput: ArFavoriteInvoiceInput;
  ARFavoriteInvoiceItemInput: Array<ArFavoriteInvoiceItemInput> | ArFavoriteInvoiceItemInput;
}>;


export type CreateArFavoriteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createARFavoriteInvoice'>
);

export type DeleteArFavoriteInvoiceMutationVariables = Exact<{
  FavoriteInvoiceID: Scalars['String'];
}>;


export type DeleteArFavoriteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteARFavoriteInvoice'>
);

export type CreateApFavoriteInvoiceMutationVariables = Exact<{
  APFavoriteInvoiceInput: ApFavoriteInvoiceInput;
  APFavoriteInvoiceItemInput: Array<ApFavoriteInvoiceItemInput> | ApFavoriteInvoiceItemInput;
}>;


export type CreateApFavoriteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAPFavoriteInvoice'>
);

export type DeleteApFavoriteInvoiceMutationVariables = Exact<{
  FavoriteInvoiceID: Scalars['String'];
}>;


export type DeleteApFavoriteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPFavoriteInvoice'>
);

export type GetApFavoritePaymentQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  FavoritePaymentID?: Maybe<Scalars['String']>;
}>;


export type GetApFavoritePaymentQuery = (
  { __typename?: 'Query' }
  & { getAPFavoritePayment: Array<(
    { __typename?: 'AccAPFavoritePaymentEntity' }
    & Pick<AccApFavoritePaymentEntity, 'FavoritePaymentID' | 'CompanyID' | 'UserID' | 'AccountID' | 'Name' | 'RefNo' | 'CreditorAccountID' | 'PaymentMethodID' | 'BankAccountID' | 'CreditCardID' | 'Description' | 'Remark'>
  )> }
);

export type CreateApFavoritePaymentMutationVariables = Exact<{
  APFavoritePaymentInput: ApFavoritePaymentInput;
}>;


export type CreateApFavoritePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAPFavoritePayment'>
);

export type DeleteApFavoritePaymentMutationVariables = Exact<{
  FavoritePaymentID: Scalars['String'];
}>;


export type DeleteApFavoritePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPFavoritePayment'>
);

export type GetApglExportQueryVariables = Exact<{
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prefix: Scalars['String'];
}>;


export type GetApglExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExport'>
);

export type GetApExportedGlListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetApExportedGlListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLListing'>
);

export type GetApExportedGlDetailQueryVariables = Exact<{
  BatchNo: Scalars['Float'];
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetApExportedGlDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLDetail'>
);

export type GetApglExportDetailByAccountCodeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  AccountCode: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetApglExportDetailByAccountCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExportDetailByAccountCode'>
);

export type PostApglExportMutationVariables = Exact<{
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  GLDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prefix: Scalars['String'];
}>;


export type PostApglExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postGLExport'>
);

export type GetApInvoicebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApInvoicebyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPInvoicebyStatus: Array<(
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'InvoiceID' | 'DocDate' | 'TransactionDate' | 'DueDate' | 'DocNo' | 'RefNo' | 'IsAutoDocNo' | 'DocNumGenerated' | 'ApprovalStatus' | 'CreditorAccountID' | 'Description' | 'Remark' | 'DocAmt' | 'BalanceAmt' | 'DO' | 'ProfileInfo'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditTerm'>
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID' | 'DOItemID' | 'Amount' | 'UnitPrice' | 'Quantity' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'MasterCOAID' | 'CostCentreID' | 'UOMID' | 'ExpenseItemID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetApInvoicebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetApInvoicebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPInvoicebyStatusListing: Array<(
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'InvoiceID' | 'DocDate' | 'TransactionDate' | 'DueDate' | 'DocNo' | 'RefNo' | 'IsAutoDocNo' | 'DocNumGenerated' | 'ApprovalStatus' | 'CreditorAccountID' | 'Description' | 'Remark' | 'DocAmt' | 'BalanceAmt' | 'DO' | 'ProfileInfo' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'einvoice_status' | 'einvoice_sent_date' | 'einvoice' | 'MSICCode' | 'SB' | 'einvoice_irbm_uniqueID'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditTerm'>
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID' | 'DOItemID' | 'Amount' | 'UnitPrice' | 'Quantity' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'MasterCOAID' | 'CostCentreID' | 'UOMID' | 'ExpenseItemID' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetApInvoiceQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApInvoiceQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'InvoiceID' | 'DocDate' | 'DueDate' | 'CreditTerm' | 'DocNo' | 'RefNo' | 'CreditorAccountID' | 'Description' | 'Remark' | 'DocAmt' | 'BalanceAmt' | 'DO'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName'>
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID' | 'DOItemID' | 'Amount' | 'UnitPrice' | 'Quantity' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'MasterCOAID' | 'CostCentreID' | 'UOMID' | 'ExpenseItemID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )> }
    )>> }
  )> }
);

export type CreateApInvoiceMutationVariables = Exact<{
  input: ApInvoiceInput;
  itemInput?: Maybe<Array<ApInvoiceItemInput> | ApInvoiceItemInput>;
}>;


export type CreateApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createAPInvoice: (
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'InvoiceID'>
  ) }
);

export type UpdateApInvoiceMutationVariables = Exact<{
  input: ApInvoiceInput;
  itemInput?: Maybe<Array<ApInvoiceItemInput> | ApInvoiceItemInput>;
}>;


export type UpdateApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateAPInvoice: (
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'InvoiceID'>
  ) }
);

export type PostApInvoiceMutationVariables = Exact<{
  InvoiceIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPInvoice'>
);

export type RejectApInvoiceMutationVariables = Exact<{
  APInvoiceIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApInvoiceRejectInput;
}>;


export type RejectApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPInvoice'>
);

export type DeleteApInvoiceMutationVariables = Exact<{
  InvoiceID: Scalars['String'];
}>;


export type DeleteApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPInvoice'>
);

export type GetGeneralDOsQueryVariables = Exact<{
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  DOItemIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetGeneralDOsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGeneralDOs'>
);

export type GetDoItemQueryVariables = Exact<{
  DOHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetDoItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDOItem'>
);

export type DuplicateInvoiceMutationVariables = Exact<{
  InvoiceID: Scalars['String'];
}>;


export type DuplicateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'duplicateInvoice'>
);

export type GetApPaymentQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentQuery = (
  { __typename?: 'Query' }
  & { getAPPayment: Array<(
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'PaymentID' | 'BatchPaymentID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'CreditorAccountID' | 'BankAccountID' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'RefNo' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'ContactNo' | 'GSTRegNo' | 'Address' | 'BankAccountNo' | 'BankName'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitRefNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditRefNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt' | 'CreditDefaultAmt'>
      & { AllocatedDocs?: Maybe<Array<(
        { __typename?: 'AllocatedDocs' }
        & Pick<AllocatedDocs, 'AllocationID' | 'DebitDescription' | 'DebitDocNo' | 'DebitRefNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDescription' | 'CreditDocNo' | 'CreditRefNo' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>> }
    )>> }
  )> }
);

export type CreateApPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
  allocationInput?: Maybe<Array<AllocationInput> | AllocationInput>;
}>;


export type CreateApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createAPPayment: (
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'PaymentID'>
  ) }
);

export type UpdateApPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
  allocationInput?: Maybe<Array<AllocationInput> | AllocationInput>;
}>;


export type UpdateApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateAPPayment: (
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'PaymentID'>
  ) }
);

export type PostApPaymentMutationVariables = Exact<{
  PaymentIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPPayment'>
);

export type RejectApPaymentMutationVariables = Exact<{
  PaymentIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApPaymentRejectInput;
}>;


export type RejectApPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPPayment'>
);

export type DeleteApPaymentMutationVariables = Exact<{
  PaymentID: Scalars['String'];
}>;


export type DeleteApPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPPayment'>
);

export type GetApPaymentbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPPaymentbyStatus: Array<(
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'PaymentID' | 'BatchPaymentID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'CreditorAccountID' | 'ChequeNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'RefNo' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'Address' | 'ContactNo'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApPaymentbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPPaymentbyStatusListing: Array<(
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'PaymentID' | 'BatchPaymentID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'CreditorAccountID' | 'ChequeNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'RefNo' | 'Description' | 'Remark' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'Address' | 'ContactNo'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      & { AllocatedDocs?: Maybe<Array<(
        { __typename?: 'AllocatedDocs' }
        & Pick<AllocatedDocs, 'AllocationID' | 'DebitDescription' | 'DebitDocNo' | 'DebitRefNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDescription' | 'CreditDocNo' | 'CreditRefNo' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>> }
    )>> }
  )> }
);

export type GetApRefundQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetApRefundQuery = (
  { __typename?: 'Query' }
  & { getAPRefund: Array<(
    { __typename?: 'AccAPRefundEntity' }
    & Pick<AccApRefundEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RefundID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'CreditorAccountID' | 'CreditCardID' | 'BankAccountID' | 'PaymentMethodID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'RefNo' | 'Description' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'ContactNo' | 'Address' | 'GSTRegNo' | 'BankAccountNo' | 'BankName'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitRefNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditRefNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateApRefundMutationVariables = Exact<{
  input: ApRefundInput;
  allocationInput?: Maybe<Array<ApAllocationInput> | ApAllocationInput>;
}>;


export type CreateApRefundMutation = (
  { __typename?: 'Mutation' }
  & { createAPRefund: (
    { __typename?: 'AccAPRefundEntity' }
    & Pick<AccApRefundEntity, 'RefundID'>
  ) }
);

export type UpdateApRefundMutationVariables = Exact<{
  input: ApRefundInput;
  allocationInput?: Maybe<Array<ApAllocationInput> | ApAllocationInput>;
}>;


export type UpdateApRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateAPRefund: (
    { __typename?: 'AccAPRefundEntity' }
    & Pick<AccApRefundEntity, 'RefundID'>
  ) }
);

export type PostApRefundMutationVariables = Exact<{
  RefundIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPRefund'>
);

export type RejectApRefundMutationVariables = Exact<{
  RefundIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApRefundRejectInput;
}>;


export type RejectApRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPRefund'>
);

export type DeleteApRefundMutationVariables = Exact<{
  RefundID: Scalars['String'];
}>;


export type DeleteApRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPRefund'>
);

export type GetApRefundbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApRefundbyStatusQuery = (
  { __typename?: 'Query' }
  & { getAPRefundbyStatus: Array<(
    { __typename?: 'AccAPRefundEntity' }
    & Pick<AccApRefundEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'ProfileInfo' | 'RefundID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'CreditorAccountID' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'RefNo' | 'Description' | 'ApprovalStatus'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetApSelfBilledbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetApSelfBilledbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAPSelfBilledbyStatusListing: Array<(
    { __typename?: 'AccAPSelfBilledEntity' }
    & Pick<AccApSelfBilledEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'SelfBilledID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CreditorAccountID' | 'DocRefNo' | 'is_einvoice' | 'einvoice_directsend' | 'einvoice_self_billed' | 'einvoice_self_billed_date' | 'einvoice_msic_code_id' | 'einvoice_irbm_uniqueID' | 'einvoice_trx_type' | 'einvoice_is_refund_note' | 'einvoice_doc_ref_invoice_id' | 'einvoice_sent_date' | 'einvoice_status' | 'einvoice' | 'is_double_entry' | 'MSICCode' | 'DocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'Description' | 'Remark' | 'ApprovalStatus' | 'APLedger' | 'ProfileInfo'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditTerm'>
    )>, SBDocRef?: Maybe<Array<(
      { __typename?: 'AccAPSelfBilledDocRefEntity' }
      & Pick<AccApSelfBilledDocRefEntity, 'SelfBilledID' | 'DocRefID'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, AllocationCNFromCreditor?: Maybe<Array<(
      { __typename?: 'AccAPAllocationObj' }
      & Pick<AccApAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, APSelfBilledItem?: Maybe<Array<(
      { __typename?: 'AccAPSelfBilledItemEntity' }
      & Pick<AccApSelfBilledItemEntity, 'SelfBilledItemID' | 'UnitPrice' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'UOMID' | 'Remark' | 'Quantity' | 'ExpenseItemID' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, ExpenseItem?: Maybe<(
        { __typename?: 'ExpenseItemEntity' }
        & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ItemName' | 'Description'>
      )> }
    )>> }
  )> }
);

export type CreateApSelfBilledMutationVariables = Exact<{
  input: ApSelfBilledInput;
  itemInput?: Maybe<Array<ApSelfBilledItemInput> | ApSelfBilledItemInput>;
  docRefInput?: Maybe<Array<DocRefInputType> | DocRefInputType>;
}>;


export type CreateApSelfBilledMutation = (
  { __typename?: 'Mutation' }
  & { createAPSelfBilled: (
    { __typename?: 'AccAPSelfBilledEntity' }
    & Pick<AccApSelfBilledEntity, 'SelfBilledID'>
  ) }
);

export type UpdateApSelfBilledMutationVariables = Exact<{
  input: ApSelfBilledInput;
  itemInput?: Maybe<Array<ApSelfBilledItemInput> | ApSelfBilledItemInput>;
  docRefInput?: Maybe<Array<DocRefInputType> | DocRefInputType>;
}>;


export type UpdateApSelfBilledMutation = (
  { __typename?: 'Mutation' }
  & { updateAPSelfBilled: (
    { __typename?: 'AccAPSelfBilledEntity' }
    & Pick<AccApSelfBilledEntity, 'SelfBilledID'>
  ) }
);

export type PostApSelfBilledMutationVariables = Exact<{
  SelfBilledIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostApSelfBilledMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAPSelfBilled'>
);

export type RejectApSelfBilledMutationVariables = Exact<{
  SelfBilledIDs: Array<Scalars['String']> | Scalars['String'];
  input: ApSelfBilledRejectInput;
}>;


export type RejectApSelfBilledMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAPSelfBilled'>
);

export type DeleteApSelfBilledMutationVariables = Exact<{
  SelfBilledID: Scalars['String'];
}>;


export type DeleteApSelfBilledMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPSelfBilled'>
);

export type GetApInvoiceSelfBilledQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetApInvoiceSelfBilledQuery = (
  { __typename?: 'Query' }
  & { getAPInvoiceSelfBilled: Array<(
    { __typename?: 'AccAPSelfBilledEntity' }
    & Pick<AccApSelfBilledEntity, 'SelfBilledID' | 'DocNo' | 'einvoice_status' | 'einvoice_directsend'>
  )> }
);

export type CancelEinvoiceMutationVariables = Exact<{
  SelfBilledID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type CancelEinvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelEinvoice'>
);

export type GetApSubmenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetApSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'apAdvanceCount' | 'apInvoiceCount' | 'apCreditNoteCount' | 'apDebitNoteCount' | 'apPaymentCount' | 'apBatchPaymentCount' | 'apRefundCount' | 'apCreditorCreditNoteCount' | 'apCreditorDebitNoteCount' | 'apSelfBilledCount' | 'creditorAccountCount' | 'apConsolidateCount'>
);

export type GetCreditorSubmenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
}>;


export type GetCreditorSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'apAdvanceTotalAmt' | 'apInvoiceTotalAmt' | 'apCreditNoteTotalAmt' | 'apCreditorCreditNoteTotalAmt' | 'apDebitNoteTotalAmt' | 'apCreditorDebitNoteTotalAmt' | 'apPaymentTotalAmt' | 'apRefundTotalAmt'>
);

export type GetCreditorTrxSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
}>;


export type GetCreditorTrxSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'apAdvanceLatestUpdate' | 'apInvoiceLatestUpdate' | 'apCreditNoteLatestUpdate' | 'apCreditorCreditNoteLatestUpdate' | 'apDebitNoteLatestUpdate' | 'apCreditorDebitNoteLatestUpdate' | 'apPaymentLatestUpdate' | 'apRefundLatestUpdate'>
);

export type GetOsCreditorCreditDocQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetOsCreditorCreditDocQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'AccAPInvoiceEntity' }
    & Pick<AccApInvoiceEntity, 'InvoiceID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAPDebitNote: Array<(
    { __typename?: 'AccAPDebitNoteEntity' }
    & Pick<AccApDebitNoteEntity, 'DebitNoteID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAPCreditNote: Array<(
    { __typename?: 'AccAPCreditNoteEntity' }
    & Pick<AccApCreditNoteEntity, 'CreditNoteID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAPCreditorDebitNote: Array<(
    { __typename?: 'AccAPCreditorDebitNoteEntity' }
    & Pick<AccApCreditorDebitNoteEntity, 'CreditorDebitNoteID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAPCreditorCreditNote: Array<(
    { __typename?: 'AccAPCreditorCreditNoteEntity' }
    & Pick<AccApCreditorCreditNoteEntity, 'CreditorCreditNoteID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAPPayment: Array<(
    { __typename?: 'AccAPPaymentEntity' }
    & Pick<AccApPaymentEntity, 'PaymentID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAPRefund: Array<(
    { __typename?: 'AccAPRefundEntity' }
    & Pick<AccApRefundEntity, 'RefundID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )> }
);

export type GetCreditorTotalDocAmtQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCreditorTotalDocAmtQuery = (
  { __typename?: 'Query' }
  & { getCreditorTotalDocAmt?: Maybe<Array<(
    { __typename?: 'CreditorInput' }
    & Pick<CreditorInput, 'CreditorAccountID' | 'TotalDocAmount'>
  )>> }
);

export type GetDebtorTotalDocAmtQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetDebtorTotalDocAmtQuery = (
  { __typename?: 'Query' }
  & { getDebtorTotalDocAmt?: Maybe<Array<(
    { __typename?: 'DebtorInput' }
    & Pick<DebtorInput, 'DebtorAccountID' | 'TotalDocAmount'>
  )>> }
);

export type GetDebtorLatestUpdatedDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetDebtorLatestUpdatedDateQuery = (
  { __typename?: 'Query' }
  & { getDebtorLatestUpdatedDate?: Maybe<Array<(
    { __typename?: 'DebtorLatestUpdatedDateInput' }
    & Pick<DebtorLatestUpdatedDateInput, 'DebtorAccountID' | 'LatestUpdatedDate'>
  )>> }
);

export type GetCreditorLatestUpdatedDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCreditorLatestUpdatedDateQuery = (
  { __typename?: 'Query' }
  & { getCreditorLatestUpdatedDate?: Maybe<Array<(
    { __typename?: 'CreditorLatestUpdatedDateInput' }
    & Pick<CreditorLatestUpdatedDateInput, 'CreditorAccountID' | 'LatestUpdatedDate'>
  )>> }
);

export type GetEachSubDocAmtQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetEachSubDocAmtQuery = (
  { __typename?: 'Query' }
  & { getEachSubDocAmt?: Maybe<Array<(
    { __typename?: 'CreditorDebtorSubInput' }
    & Pick<CreditorDebtorSubInput, 'AdvanceTotalDocAmt' | 'CreditNoteTotalDocAmt' | 'DebitNoteTotalDocAmt' | 'InvoiceTotalDocAmt'>
  )>> }
);

export type GetAllocationDocumentQueryVariables = Exact<{
  entityID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  refTable: Scalars['String'];
  companyID: Scalars['String'];
  creditorDebtorID: Scalars['String'];
  isEinvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
}>;


export type GetAllocationDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllocationDocument'>
);

export type CreateAllocationMutationVariables = Exact<{
  entityID?: Maybe<Scalars['String']>;
  input?: Maybe<Array<AllocationInput> | AllocationInput>;
}>;


export type CreateAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAllocation'>
);

export type GetClassificationCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClassificationCodeQuery = (
  { __typename?: 'Query' }
  & { getClassificationCode: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetMsicCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMsicCodeQuery = (
  { __typename?: 'Query' }
  & { getMSICCode: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type GetArAdvanceQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AdvanceID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArAdvanceQuery = (
  { __typename?: 'Query' }
  & { getARAdvance: Array<(
    { __typename?: 'AccARAdvanceEntity' }
    & Pick<AccArAdvanceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'AdvanceID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'DebtorAccountID' | 'PaymentMethodID' | 'TaxSchemeID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'Description' | 'Remark' | 'Amount' | 'TaxAmt' | 'TaxRate' | 'TaxInvoiceNo' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateArAdvanceMutationVariables = Exact<{
  input: ArAdvanceInput;
}>;


export type CreateArAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createARAdvance'>
);

export type UpdateArAdvanceMutationVariables = Exact<{
  input: ArAdvanceInput;
}>;


export type UpdateArAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateARAdvance'>
);

export type DeleteArAdvanceMutationVariables = Exact<{
  AdvanceID: Scalars['String'];
}>;


export type DeleteArAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteARAdvance'>
);

export type PostArAdvanceMutationVariables = Exact<{
  AdvanceIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostArAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postARAdvance'>
);

export type RejectArAdvanceMutationVariables = Exact<{
  AdvanceIDs: Array<Scalars['String']> | Scalars['String'];
  input: ArAdvanceRejectInput;
}>;


export type RejectArAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectARAdvance'>
);

export type GetArAdvancebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArAdvancebyStatusQuery = (
  { __typename?: 'Query' }
  & { getARAdvancebyStatus: Array<(
    { __typename?: 'AccARAdvanceEntity' }
    & Pick<AccArAdvanceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'AdvanceID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'DebtorAccountID' | 'PaymentMethodID' | 'TaxSchemeID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'Description' | 'Remark' | 'Amount' | 'TaxAmt' | 'TaxRate' | 'TaxInvoiceNo' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID' | 'Code' | 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetArAdvancebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetArAdvancebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getARAdvancebyStatusListing: (
    { __typename?: 'ARAdvanceReturnData' }
    & Pick<ArAdvanceReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccARAdvanceEntity' }
      & Pick<AccArAdvanceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'AdvanceID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'DebtorAccountID' | 'PaymentMethodID' | 'TaxSchemeID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'Description' | 'Remark' | 'Amount' | 'TaxAmt' | 'TaxRate' | 'TaxInvoiceNo' | 'DocAmt' | 'BalanceAmt' | 'ApprovalStatus'>
      & { Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, DebtorAccount?: Maybe<(
        { __typename?: 'DebtorAccountEntity' }
        & Pick<DebtorAccountEntity, 'DebtorName'>
      )>, PaymentMethod?: Maybe<(
        { __typename?: 'PaymentMethodEntity' }
        & Pick<PaymentMethodEntity, 'Name'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CreditCardType?: Maybe<(
        { __typename?: 'CreditCardTypeEntity' }
        & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
      )>, BankAccount?: Maybe<(
        { __typename?: 'AccBankAccountEntity' }
        & Pick<AccBankAccountEntity, 'BankAccountID' | 'Code' | 'AccountNo'>
        & { BankProfile?: Maybe<(
          { __typename?: 'BankProfileEntity' }
          & Pick<BankProfileEntity, 'Name' | 'Address'>
        )> }
      )>, WorkFlow?: Maybe<Array<(
        { __typename?: 'AccWorkFlowEntity' }
        & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
        & { SubmitterDetail?: Maybe<(
          { __typename?: 'UserEntity' }
          & Pick<UserEntity, 'ID' | 'name'>
        )>, WorkFlowStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowStepEntity' }
          & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
          & { RoleDetail?: Maybe<(
            { __typename?: 'AccRoleEntity' }
            & Pick<AccRoleEntity, 'ID' | 'name'>
          )>, WorkFlowApprovedStep?: Maybe<Array<(
            { __typename?: 'AccWorkFlowApprovedStepEntity' }
            & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
            & { ApproverDetail?: Maybe<(
              { __typename?: 'UserEntity' }
              & Pick<UserEntity, 'ID' | 'name'>
            )> }
          )>> }
        )>> }
      )>>, Allocation?: Maybe<Array<(
        { __typename?: 'AccARAllocationObj' }
        & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>> }
    )>> }
  ) }
);

export type GetArAllocationDocumentQueryVariables = Exact<{
  entityID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  refTable: Scalars['String'];
  companyID: Scalars['String'];
  creditorDebtorID?: Maybe<Scalars['String']>;
  billExpenseItemID?: Maybe<Scalars['String']>;
}>;


export type GetArAllocationDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllocationDocument'>
);

export type CreateArAllocationMutationVariables = Exact<{
  input?: Maybe<Array<AllocationInput> | AllocationInput>;
}>;


export type CreateArAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAllocation'>
);

export type GetArItemQueryVariables = Exact<{
  refID: Scalars['String'];
}>;


export type GetArItemQuery = (
  { __typename?: 'Query' }
  & { getARInvoiceItem: Array<(
    { __typename?: 'AccARInvoiceItemEntity' }
    & Pick<AccArInvoiceItemEntity, 'InvoiceItemID' | 'BalanceAmt' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'BillItemID'>
    & { TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      & { LatestTax?: Maybe<(
        { __typename?: 'AccTax' }
        & Pick<AccTax, 'Rate'>
      )> }
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
    )>, BillItem?: Maybe<(
      { __typename?: 'BillItemEntity' }
      & Pick<BillItemEntity, 'BillItemID' | 'Name'>
    )> }
  )>, getARDebitNoteItem: Array<(
    { __typename?: 'AccARDebitNoteItemEntity' }
    & Pick<AccArDebitNoteItemEntity, 'DebitNoteItemID' | 'BalanceAmt' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'BillItemID'>
    & { TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      & { LatestTax?: Maybe<(
        { __typename?: 'AccTax' }
        & Pick<AccTax, 'Rate'>
      )> }
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
    )>, BillItem?: Maybe<(
      { __typename?: 'BillItemEntity' }
      & Pick<BillItemEntity, 'BillItemID' | 'Name'>
    )> }
  )> }
);

export type GetBillItemQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  BillItemID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetBillItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBillItemCount'>
  & { getBillItem: Array<(
    { __typename?: 'BillItemEntity' }
    & Pick<BillItemEntity, 'AccountID' | 'RecordStatus' | 'BillItemID' | 'Name' | 'Description' | 'IsUsed' | 'TaxSchemeID' | 'TaxRate' | 'MasterCOAID' | 'einv_integrated' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id'>
    & { ARInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccARInvoiceItemEntity' }
      & Pick<AccArInvoiceItemEntity, 'DocAmt'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description'>
        & { LatestTax?: Maybe<(
          { __typename?: 'AccTax' }
          & Pick<AccTax, 'Rate'>
        )>, TaxEffective?: Maybe<Array<(
          { __typename?: 'TaxEffectiveDateEntity' }
          & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
        )>> }
      )> }
    )>>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description'>
      & { LatestTax?: Maybe<(
        { __typename?: 'AccTax' }
        & Pick<AccTax, 'Rate'>
      )>, TaxEffective?: Maybe<Array<(
        { __typename?: 'TaxEffectiveDateEntity' }
        & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
      )>> }
    )>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
    )> }
  )> }
);

export type GetBillItemListingQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetBillItemListingQuery = (
  { __typename?: 'Query' }
  & { getBillItem: Array<(
    { __typename?: 'BillItemEntity' }
    & Pick<BillItemEntity, 'BillItemID' | 'MasterCOAID' | 'Name' | 'Description' | 'TaxSchemeID' | 'einv_integrated' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id'>
  )> }
);

export type CreateBillItemMutationVariables = Exact<{
  input: BillItemInput;
}>;


export type CreateBillItemMutation = (
  { __typename?: 'Mutation' }
  & { createBillItem: (
    { __typename?: 'BillItemEntity' }
    & Pick<BillItemEntity, 'BillItemID'>
  ) }
);

export type UpdateBillItemMutationVariables = Exact<{
  input: BillItemInput;
}>;


export type UpdateBillItemMutation = (
  { __typename?: 'Mutation' }
  & { updateBillItem: (
    { __typename?: 'BillItemEntity' }
    & Pick<BillItemEntity, 'BillItemID'>
  ) }
);

export type DeleteBillItemMutationVariables = Exact<{
  input: BillItemDeleteInput;
}>;


export type DeleteBillItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBillItem'>
);

export type GetDocToConsolidateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DocType: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
}>;


export type GetDocToConsolidateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocToConsolidate'>
);

export type CreateEinvoiceConsolidateMutationVariables = Exact<{
  input: ConsolidationInput;
  itemInput: Array<ConsolidationDetailInput> | ConsolidationDetailInput;
}>;


export type CreateEinvoiceConsolidateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEinvoiceConsolidate'>
);

export type CreateEinvoiceConsolidateDocTypeAllMutationVariables = Exact<{
  input: Array<ConsolidationDocTypeAllInput> | ConsolidationDocTypeAllInput;
}>;


export type CreateEinvoiceConsolidateDocTypeAllMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEinvoiceConsolidateDocTypeAll'>
);

export type GetEinvoiceConsolidationQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  DocType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
}>;


export type GetEinvoiceConsolidationQuery = (
  { __typename?: 'Query' }
  & { getEinvoiceConsolidation: Array<(
    { __typename?: 'EinvoiceConsolidationEntity' }
    & Pick<EinvoiceConsolidationEntity, 'AccountID' | 'CompanyID' | 'ConsolidationID' | 'DocDate' | 'DocNo' | 'DocAmt' | 'Amount' | 'TaxAmt' | 'Description' | 'ProfileInfo' | 'einvoice_trx_type' | 'createdTs' | 'createdBy' | 'einvoice_status' | 'einvoice'>
    & { eInvoiceConsolidationDetail?: Maybe<Array<(
      { __typename?: 'EinvoiceConsolidationDetailEntity' }
      & Pick<EinvoiceConsolidationDetailEntity, 'Consolidation_DetailID' | 'AccountID' | 'ConsolidationID' | 'DocNo' | 'DocDate' | 'DocType' | 'CreditorDebtorName' | 'RefTable' | 'RefRecordID' | 'Amount' | 'TaxAmt' | 'DocAmt'>
    )>> }
  )> }
);

export type GetArCreditNoteQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArCreditNoteQuery = (
  { __typename?: 'Query' }
  & { getARCreditNote: Array<(
    { __typename?: 'AccARCreditNoteEntity' }
    & Pick<AccArCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'CreditNoteID' | 'DocDate' | 'RefNo' | 'DebtorAccountID' | 'Amount' | 'DocAmt' | 'CostCentreID' | 'DocNo' | 'BalanceAmt' | 'TaxAmt' | 'ApprovalStatus' | 'Remark' | 'Description' | 'is_einvoice' | 'einvoice_qr_url'>
    & { DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'Address' | 'DebtorName' | 'DebtorAccountID' | 'CompanyID' | 'CreditTerm' | 'SSTRegNo' | 'IsCompany' | 'GSTRegNo' | 'OfficeNo' | 'MobileNo' | 'einvoice_TIN'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'Name' | 'ContactNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
        )>, BankAccount?: Maybe<(
          { __typename?: 'AccBankAccountEntity' }
          & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
          & { BankProfile?: Maybe<(
            { __typename?: 'BankProfileEntity' }
            & Pick<BankProfileEntity, 'Name' | 'Address'>
          )> }
        )> }
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
    )>, ARCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccARCreditNoteItemEntity' }
      & Pick<AccArCreditNoteItemEntity, 'CreditNoteItemID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'Remark' | 'BillItemID' | 'Sequence'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )>, BillItem?: Maybe<(
        { __typename?: 'BillItemEntity' }
        & Pick<BillItemEntity, 'Name' | 'TaxRate' | 'TaxSchemeID'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
          & { LatestTax?: Maybe<(
            { __typename?: 'AccTax' }
            & Pick<AccTax, 'Rate'>
          )>, TaxEffective?: Maybe<Array<(
            { __typename?: 'TaxEffectiveDateEntity' }
            & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
          )>> }
        )> }
      )> }
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateArCreditNoteMutationVariables = Exact<{
  input: ArCreditNoteInput;
  itemInput?: Maybe<Array<ArCreditNoteItemInput> | ArCreditNoteItemInput>;
  allocationInput: Array<ArAllocationInput> | ArAllocationInput;
  docRefInput: Array<AccArcnDocRefInput> | AccArcnDocRefInput;
}>;


export type CreateArCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createARCreditNote'>
);

export type UpdateArCreditNoteMutationVariables = Exact<{
  CreditNoteID: Scalars['String'];
  input: ArCreditNoteInput;
  itemInput?: Maybe<Array<ArCreditNoteItemInput> | ArCreditNoteItemInput>;
  allocationInput: Array<ArAllocationInput> | ArAllocationInput;
  docRefInput: Array<AccArcnDocRefInput> | AccArcnDocRefInput;
}>;


export type UpdateArCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateARCreditNote'>
);

export type PostArCreditNoteMutationVariables = Exact<{
  CreditNoteIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostArCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postARCreditNote'>
);

export type RejectArCreditNoteMutationVariables = Exact<{
  CreditNoteIDs: Array<Scalars['String']> | Scalars['String'];
  input: ArCreditNoteRejectInput;
}>;


export type RejectArCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectARCreditNote'>
);

export type DeleteArCreditNoteMutationVariables = Exact<{
  CreditNoteID: Scalars['String'];
}>;


export type DeleteArCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteARCreditNote'>
);

export type GetArCreditNotebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArCreditNotebyStatusQuery = (
  { __typename?: 'Query' }
  & { getARCreditNotebyStatus: Array<(
    { __typename?: 'AccARCreditNoteEntity' }
    & Pick<AccArCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'CompanyID' | 'AccountID' | 'RecordStatus' | 'CreditNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'Remark' | 'Description' | 'DebtorAccountID' | 'Amount' | 'DocAmt' | 'CostCentreID' | 'DocNo' | 'TaxAmt' | 'BalanceAmt' | 'ApprovalStatus' | 'is_einvoice' | 'einvoice_qr_url' | 'einvoice_status' | 'einvoice_validation_date'>
    & { DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorAccountID' | 'DebtorName' | 'OfficeNo' | 'MobileNo' | 'GSTRegNo' | 'SSTRegNo' | 'CompanyRegNo' | 'Address' | 'einvoice_TIN'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, ARCreditNoteItem?: Maybe<Array<(
      { __typename?: 'AccARCreditNoteItemEntity' }
      & Pick<AccArCreditNoteItemEntity, 'CreditNoteItemID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'Sequence' | 'Remark' | 'BillItemID' | 'einvoice_classification_code_id' | 'ClassificationCode'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )>, BillItem?: Maybe<(
        { __typename?: 'BillItemEntity' }
        & Pick<BillItemEntity, 'Name' | 'TaxRate' | 'TaxSchemeID'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
          & { LatestTax?: Maybe<(
            { __typename?: 'AccTax' }
            & Pick<AccTax, 'Rate'>
          )>, TaxEffective?: Maybe<Array<(
            { __typename?: 'TaxEffectiveDateEntity' }
            & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
          )>> }
        )> }
      )> }
    )>>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetArCreditNotebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetArCreditNotebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getARCreditNotebyStatusListing: (
    { __typename?: 'ARCreditNoteReturnData' }
    & Pick<ArCreditNoteReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccARCreditNoteEntity' }
      & Pick<AccArCreditNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'CompanyID' | 'AccountID' | 'RecordStatus' | 'CreditNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'Remark' | 'Description' | 'einvoice' | 'DebtorAccountID' | 'Amount' | 'DocAmt' | 'CostCentreID' | 'DocNo' | 'TaxAmt' | 'BalanceAmt' | 'ApprovalStatus' | 'is_einvoice' | 'einvoice_qr_url' | 'einvoice_status' | 'einvoice_validation_date' | 'einvoice_directsend' | 'einvoice_is_refund_note' | 'CNDocRef'>
      & { DebtorAccount?: Maybe<(
        { __typename?: 'DebtorAccountEntity' }
        & Pick<DebtorAccountEntity, 'DebtorAccountID' | 'DebtorName' | 'OfficeNo' | 'MobileNo' | 'GSTRegNo' | 'CompanyRegNo' | 'Address'>
      )>, Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )>, ARCreditNoteItem?: Maybe<Array<(
        { __typename?: 'AccARCreditNoteItemEntity' }
        & Pick<AccArCreditNoteItemEntity, 'CreditNoteItemID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'Remark' | 'BillItemID' | 'Sequence' | 'einvoice_classification_code_id' | 'ClassificationCode'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & Pick<TaxSchemeEntity, 'Code'>
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
        )>, BillItem?: Maybe<(
          { __typename?: 'BillItemEntity' }
          & Pick<BillItemEntity, 'Name' | 'TaxRate' | 'TaxSchemeID'>
          & { TaxScheme?: Maybe<(
            { __typename?: 'TaxSchemeEntity' }
            & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
            & { LatestTax?: Maybe<(
              { __typename?: 'AccTax' }
              & Pick<AccTax, 'Rate'>
            )>, TaxEffective?: Maybe<Array<(
              { __typename?: 'TaxEffectiveDateEntity' }
              & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
            )>> }
          )> }
        )> }
      )>>, Allocation?: Maybe<Array<(
        { __typename?: 'AccARAllocationObj' }
        & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>> }
    )>> }
  ) }
);

export type GetValidatedDocRefQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
}>;


export type GetValidatedDocRefQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getValidatedDocRef'>
);

export type GetArDebitNoteQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArDebitNoteQuery = (
  { __typename?: 'Query' }
  & { getARDebitNote: Array<(
    { __typename?: 'AccARDebitNoteEntity' }
    & Pick<AccArDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'CompanyID' | 'CreditTerm' | 'AccountID' | 'RecordStatus' | 'DebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'BalanceAmt' | 'Description' | 'Remark' | 'DebtorAccountID' | 'DocNo' | 'Amount' | 'DocAmt' | 'DueDate' | 'ApprovalStatus' | 'is_einvoice' | 'einvoice_qr_url' | 'DNDocRef'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'Address' | 'DebtorName' | 'DebtorAccountID' | 'CompanyID' | 'CreditTerm' | 'IsCompany' | 'GSTRegNo' | 'SSTRegNo' | 'OfficeNo' | 'MobileNo' | 'einvoice_TIN'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'Name' | 'ContactNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
        )>, BankAccount?: Maybe<(
          { __typename?: 'AccBankAccountEntity' }
          & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
          & { BankProfile?: Maybe<(
            { __typename?: 'BankProfileEntity' }
            & Pick<BankProfileEntity, 'Name' | 'Address'>
          )> }
        )> }
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, ARDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccARDebitNoteItemEntity' }
      & Pick<AccArDebitNoteItemEntity, 'DebitNoteItemID' | 'Amount' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'BillItemID' | 'CostCentreID' | 'ClassificationCode'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, BillItem?: Maybe<(
        { __typename?: 'BillItemEntity' }
        & Pick<BillItemEntity, 'BillItemID' | 'TaxSchemeID' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateArDebitNoteMutationVariables = Exact<{
  input: ArDebitNoteInput;
  itemInput?: Maybe<Array<ArDebitNoteItemInput> | ArDebitNoteItemInput>;
  docRefInput: Array<AccArdnDocRefInput> | AccArdnDocRefInput;
}>;


export type CreateArDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createARDebitNote'>
);

export type UpdateArDebitNoteMutationVariables = Exact<{
  DebitNoteID: Scalars['String'];
  input: ArDebitNoteInput;
  itemInput?: Maybe<Array<ArDebitNoteItemInput> | ArDebitNoteItemInput>;
  docRefInput: Array<AccArdnDocRefInput> | AccArdnDocRefInput;
}>;


export type UpdateArDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateARDebitNote'>
);

export type PostArDebitNoteMutationVariables = Exact<{
  DebitNoteIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostArDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postARDebitNote'>
);

export type RejectArDebitNoteMutationVariables = Exact<{
  DebitNoteIDs: Array<Scalars['String']> | Scalars['String'];
  input: ArDebitNoteRejectInput;
}>;


export type RejectArDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectARDebitNote'>
);

export type DeleteArDebitNoteMutationVariables = Exact<{
  DebitNoteID: Scalars['String'];
}>;


export type DeleteArDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteARDebitNote'>
);

export type GetArDebitNotebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArDebitNotebyStatusQuery = (
  { __typename?: 'Query' }
  & { getARDebitNotebyStatus: Array<(
    { __typename?: 'AccARDebitNoteEntity' }
    & Pick<AccArDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'CompanyID' | 'CreditTerm' | 'AccountID' | 'RecordStatus' | 'DebitNoteID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'BalanceAmt' | 'DebtorAccountID' | 'DocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'DueDate' | 'Description' | 'Remark' | 'ApprovalStatus' | 'is_einvoice' | 'einvoice_qr_url' | 'einvoice_doc_ref_invoice_id' | 'einvoice_status' | 'einvoice_validation_date' | 'DNDocRef'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorName'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, ARDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccARDebitNoteItemEntity' }
      & Pick<AccArDebitNoteItemEntity, 'DebitNoteItemID' | 'Amount' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'BillItemID' | 'CostCentreID' | 'einvoice_classification_code_id'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
        & { LatestTax?: Maybe<(
          { __typename?: 'AccTax' }
          & Pick<AccTax, 'Rate'>
        )> }
      )>, BillItem?: Maybe<(
        { __typename?: 'BillItemEntity' }
        & Pick<BillItemEntity, 'BillItemID' | 'TaxSchemeID' | 'Name'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & { TaxEffective?: Maybe<Array<(
            { __typename?: 'TaxEffectiveDateEntity' }
            & Pick<TaxEffectiveDateEntity, 'Date'>
          )>> }
        )> }
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetArDebitNotebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetArDebitNotebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getARDebitNotebyStatusListing: (
    { __typename?: 'ARDebitNoteReturnData' }
    & Pick<ArDebitNoteReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccARDebitNoteEntity' }
      & Pick<AccArDebitNoteEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'CompanyID' | 'CreditTerm' | 'AccountID' | 'RecordStatus' | 'DebitNoteID' | 'einvoice' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'BalanceAmt' | 'DebtorAccountID' | 'DocNo' | 'Amount' | 'TaxAmt' | 'DocAmt' | 'DueDate' | 'Description' | 'Remark' | 'ApprovalStatus' | 'is_einvoice' | 'einvoice_qr_url' | 'einvoice_doc_ref_invoice_id' | 'einvoice_status' | 'einvoice_validation_date' | 'einvoice_directsend' | 'DNDocRef'>
      & { Allocation?: Maybe<Array<(
        { __typename?: 'AccARAllocationObj' }
        & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>>, DebtorAccount?: Maybe<(
        { __typename?: 'DebtorAccountEntity' }
        & Pick<DebtorAccountEntity, 'DebtorName'>
      )>, Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, ARDebitNoteItem?: Maybe<Array<(
        { __typename?: 'AccARDebitNoteItemEntity' }
        & Pick<AccArDebitNoteItemEntity, 'DebitNoteItemID' | 'Amount' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'BillItemID' | 'CostCentreID' | 'einvoice_classification_code_id' | 'ClassificationCode'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
          & { LatestTax?: Maybe<(
            { __typename?: 'AccTax' }
            & Pick<AccTax, 'Rate'>
          )> }
        )>, BillItem?: Maybe<(
          { __typename?: 'BillItemEntity' }
          & Pick<BillItemEntity, 'BillItemID' | 'TaxSchemeID' | 'Name'>
          & { TaxScheme?: Maybe<(
            { __typename?: 'TaxSchemeEntity' }
            & { TaxEffective?: Maybe<Array<(
              { __typename?: 'TaxEffectiveDateEntity' }
              & Pick<TaxEffectiveDateEntity, 'Date'>
            )>> }
          )> }
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
        )> }
      )>> }
    )>> }
  ) }
);

export type DebtorAccountTotalDueQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DebtorAccountTotalDueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DebtorAccountTotalDue'>
);

export type GetArglExportQueryVariables = Exact<{
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prefix: Scalars['String'];
}>;


export type GetArglExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExport'>
);

export type GetArExportedGlListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetArExportedGlListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLListing'>
);

export type GetArExportedGlDetailQueryVariables = Exact<{
  BatchNo: Scalars['Float'];
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetArExportedGlDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLDetail'>
);

export type GetArglExportDetailByAccountCodeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  AccountCode: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetArglExportDetailByAccountCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExportDetailByAccountCode'>
);

export type PostArglExportMutationVariables = Exact<{
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  GLDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prefix: Scalars['String'];
}>;


export type PostArglExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postGLExport'>
);

export type GetArInvoicebyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArInvoicebyStatusQuery = (
  { __typename?: 'Query' }
  & { getARInvoicebyStatus: Array<(
    { __typename?: 'AccARInvoiceEntity' }
    & Pick<AccArInvoiceEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'RecordStatus' | 'ApprovalStatus' | 'InvoiceID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'RefNo' | 'DueDate' | 'CreditTerm' | 'Description' | 'DocAmt' | 'BalanceAmt' | 'Remark' | 'DebtorAccountID' | 'is_einvoice' | 'einvoice_status' | 'einvoice_validation_date'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'Address' | 'DebtorName' | 'DebtorAccountID' | 'CompanyID' | 'CreditTerm' | 'IsCompany' | 'GSTRegNo' | 'OfficeNo' | 'MobileNo'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'Name' | 'ContactNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
        )>, BankAccount?: Maybe<(
          { __typename?: 'AccBankAccountEntity' }
          & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
          & { BankProfile?: Maybe<(
            { __typename?: 'BankProfileEntity' }
            & Pick<BankProfileEntity, 'Name' | 'Address'>
          )> }
        )> }
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, ARInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccARInvoiceItemEntity' }
      & Pick<AccArInvoiceItemEntity, 'InvoiceItemID' | 'Amount' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'einvoice_classification_code_id' | 'ClassificationCode' | 'BillItemID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
        & { LatestTax?: Maybe<(
          { __typename?: 'AccTax' }
          & Pick<AccTax, 'Rate'>
        )> }
      )>, BillItem?: Maybe<(
        { __typename?: 'BillItemEntity' }
        & Pick<BillItemEntity, 'BillItemID' | 'TaxSchemeID' | 'Name'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & { TaxEffective?: Maybe<Array<(
            { __typename?: 'TaxEffectiveDateEntity' }
            & Pick<TaxEffectiveDateEntity, 'Date'>
          )>> }
        )> }
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
      )> }
    )>> }
  )> }
);

export type GetArInvoicebyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetArInvoicebyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getARInvoicebyStatusListing: (
    { __typename?: 'ARInvoiceReturnData' }
    & Pick<ArInvoiceReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccARInvoiceEntity' }
      & Pick<AccArInvoiceEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AccountID' | 'RecordStatus' | 'ApprovalStatus' | 'InvoiceID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'RefNo' | 'DueDate' | 'CreditTerm' | 'Description' | 'DocAmt' | 'BalanceAmt' | 'Remark' | 'einvoice' | 'DebtorAccountID' | 'is_einvoice' | 'einvoice_status' | 'einvoice_validation_date' | 'einvoice_directsend' | 'einvoice_irbm_uniqueID' | 'einvoice_qr_url'>
      & { Allocation?: Maybe<Array<(
        { __typename?: 'AccARAllocationObj' }
        & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>>, Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, DebtorAccount?: Maybe<(
        { __typename?: 'DebtorAccountEntity' }
        & Pick<DebtorAccountEntity, 'Address' | 'DebtorName' | 'DebtorAccountID' | 'CompanyID' | 'CreditTerm' | 'IsCompany' | 'GSTRegNo' | 'SSTRegNo' | 'OfficeNo' | 'MobileNo' | 'einvoice_TIN'>
        & { Company?: Maybe<(
          { __typename?: 'CompanyEntity' }
          & Pick<CompanyEntity, 'Name' | 'ContactNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
          )>, BankAccount?: Maybe<(
            { __typename?: 'AccBankAccountEntity' }
            & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
            & { BankProfile?: Maybe<(
              { __typename?: 'BankProfileEntity' }
              & Pick<BankProfileEntity, 'Name' | 'Address'>
            )> }
          )> }
        )>, DebtorContact?: Maybe<Array<(
          { __typename?: 'DebtorContactEntity' }
          & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email'>
        )>> }
      )>, ARInvoiceItem?: Maybe<Array<(
        { __typename?: 'AccARInvoiceItemEntity' }
        & Pick<AccArInvoiceItemEntity, 'InvoiceItemID' | 'Amount' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'einvoice_classification_code_id' | 'ClassificationCode' | 'BillItemID' | 'CostCentreID'>
        & { TaxScheme?: Maybe<(
          { __typename?: 'TaxSchemeEntity' }
          & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
          & { LatestTax?: Maybe<(
            { __typename?: 'AccTax' }
            & Pick<AccTax, 'Rate'>
          )> }
        )>, BillItem?: Maybe<(
          { __typename?: 'BillItemEntity' }
          & Pick<BillItemEntity, 'BillItemID' | 'TaxSchemeID' | 'Name'>
          & { TaxScheme?: Maybe<(
            { __typename?: 'TaxSchemeEntity' }
            & { TaxEffective?: Maybe<Array<(
              { __typename?: 'TaxEffectiveDateEntity' }
              & Pick<TaxEffectiveDateEntity, 'Date'>
            )>> }
          )> }
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
        )> }
      )>> }
    )>> }
  ) }
);

export type CreateArInvoiceMutationVariables = Exact<{
  input: ArInvoiceInput;
  itemInput?: Maybe<Array<ArInvoiceItemInput> | ArInvoiceItemInput>;
}>;


export type CreateArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createARInvoice: (
    { __typename?: 'AccARInvoiceEntity' }
    & Pick<AccArInvoiceEntity, 'InvoiceID'>
  ) }
);

export type UpdateArInvoiceMutationVariables = Exact<{
  input: ArInvoiceInput;
  itemInput?: Maybe<Array<ArInvoiceItemInput> | ArInvoiceItemInput>;
}>;


export type UpdateArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateARInvoice: (
    { __typename?: 'AccARInvoiceEntity' }
    & Pick<AccArInvoiceEntity, 'InvoiceID'>
  ) }
);

export type PostArInvoiceMutationVariables = Exact<{
  InvoiceIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postARInvoice'>
);

export type RejectArInvoiceMutationVariables = Exact<{
  ARInvoiceIDs: Array<Scalars['String']> | Scalars['String'];
  input: ArInvoiceRejectInput;
}>;


export type RejectArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectARInvoice'>
);

export type GetArInvoiceQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArInvoiceQuery = (
  { __typename?: 'Query' }
  & { getARInvoice: Array<(
    { __typename?: 'AccARInvoiceEntity' }
    & Pick<AccArInvoiceEntity, 'createdBy' | 'InvoiceID' | 'DocDate' | 'TransactionDate' | 'DueDate' | 'ApprovalStatus' | 'createdTs' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'DebtorAccountID' | 'RefNo' | 'Remark' | 'Description' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'is_einvoice' | 'einvoice_qr_url'>
    & { Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorAccountID' | 'DebtorName' | 'OfficeNo' | 'MobileNo' | 'GSTRegNo' | 'SSTRegNo' | 'CompanyRegNo' | 'Address' | 'einvoice_TIN'>
      & { DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'ContactNo' | 'Email' | 'Name'>
      )>> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, ARInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccARInvoiceItemEntity' }
      & Pick<AccArInvoiceItemEntity, 'InvoiceItemID' | 'Amount' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'DocAmtBeforeTax' | 'Sequence' | 'BillItemID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, BillItem?: Maybe<(
        { __typename?: 'BillItemEntity' }
        & Pick<BillItemEntity, 'BillItemID' | 'TaxSchemeID' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name'>
      )> }
    )>> }
  )> }
);

export type DeleteArInvoiceMutationVariables = Exact<{
  InvoiceID: Scalars['String'];
}>;


export type DeleteArInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteARInvoice'>
);

export type GetArOfficialReceiptQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetArOfficialReceiptQuery = (
  { __typename?: 'Query' }
  & { getAROfficialReceipt: Array<(
    { __typename?: 'AccAROfficialReceiptEntity' }
    & Pick<AccArOfficialReceiptEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'Amount' | 'RecordStatus' | 'ReceiptID' | 'CompanyID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'DebtorAccountID' | 'TaxInvoiceNo' | 'PaymentMethodID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'DocAmt' | 'BalanceAmt' | 'Remark' | 'Description' | 'ApprovalStatus'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'Name'>
    )>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'Address' | 'DebtorName' | 'DebtorAccountID' | 'CompanyID' | 'CreditTerm' | 'IsCompany' | 'GSTRegNo' | 'OfficeNo' | 'MobileNo'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'Name' | 'ContactNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
        )>, BankAccount?: Maybe<(
          { __typename?: 'AccBankAccountEntity' }
          & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
          & { BankProfile?: Maybe<(
            { __typename?: 'BankProfileEntity' }
            & Pick<BankProfileEntity, 'Name' | 'Address'>
          )> }
        )> }
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID' | 'Code' | 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateArOfficialReceiptMutationVariables = Exact<{
  input: ArOfficialReceiptInput;
  allocationInput?: Maybe<Array<ArAllocationInput> | ArAllocationInput>;
}>;


export type CreateArOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & { createAROfficialReceipt: (
    { __typename?: 'AccAROfficialReceiptEntity' }
    & Pick<AccArOfficialReceiptEntity, 'ReceiptID'>
  ) }
);

export type UpdateArOfficialReceiptMutationVariables = Exact<{
  input: ArOfficialReceiptInput;
  allocationInput?: Maybe<Array<ArAllocationInput> | ArAllocationInput>;
}>;


export type UpdateArOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & { updateAROfficialReceipt: (
    { __typename?: 'AccAROfficialReceiptEntity' }
    & Pick<AccArOfficialReceiptEntity, 'ReceiptID'>
  ) }
);

export type PostArOfficialReceiptMutationVariables = Exact<{
  ReceiptIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostArOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAROfficialReceipt'>
);

export type RejectArOfficialReceiptMutationVariables = Exact<{
  ReceiptIDs: Array<Scalars['String']> | Scalars['String'];
  input: ArOfficialReceiptRejectInput;
}>;


export type RejectArOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAROfficialReceipt'>
);

export type GetArOfficialReceiptbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArOfficialReceiptbyStatusQuery = (
  { __typename?: 'Query' }
  & { getAROfficialReceiptbyStatus: Array<(
    { __typename?: 'AccAROfficialReceiptEntity' }
    & Pick<AccArOfficialReceiptEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'Amount' | 'AccountID' | 'RecordStatus' | 'ReceiptID' | 'CompanyID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'DebtorAccountID' | 'TaxInvoiceNo' | 'PaymentMethodID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'DocAmt' | 'BalanceAmt' | 'Remark' | 'Description' | 'ApprovalStatus'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'Name'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorAccountID' | 'DebtorName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID' | 'Code' | 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetArOfficialReceiptbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetArOfficialReceiptbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAROfficialReceiptbyStatusListing: (
    { __typename?: 'AROfficialReceiptReturnData' }
    & Pick<ArOfficialReceiptReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccAROfficialReceiptEntity' }
      & Pick<AccArOfficialReceiptEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'Amount' | 'AccountID' | 'RecordStatus' | 'ReceiptID' | 'CompanyID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'DocNo' | 'DebtorAccountID' | 'TaxInvoiceNo' | 'PaymentMethodID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'BankAccountID' | 'DocAmt' | 'BalanceAmt' | 'Remark' | 'Description' | 'ApprovalStatus'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'Name'>
      )>, Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, DebtorAccount?: Maybe<(
        { __typename?: 'DebtorAccountEntity' }
        & Pick<DebtorAccountEntity, 'DebtorAccountID' | 'DebtorName'>
      )>, PaymentMethod?: Maybe<(
        { __typename?: 'PaymentMethodEntity' }
        & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description'>
      )>, BankAccount?: Maybe<(
        { __typename?: 'AccBankAccountEntity' }
        & Pick<AccBankAccountEntity, 'BankAccountID' | 'Code' | 'AccountNo'>
        & { BankProfile?: Maybe<(
          { __typename?: 'BankProfileEntity' }
          & Pick<BankProfileEntity, 'Name' | 'Address'>
        )> }
      )>, Allocation?: Maybe<Array<(
        { __typename?: 'AccARAllocationObj' }
        & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>> }
    )>> }
  ) }
);

export type DeleteArOfficialReceiptMutationVariables = Exact<{
  ReceiptID: Scalars['String'];
}>;


export type DeleteArOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAROfficialReceipt'>
);

export type GetArRefundQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetArRefundQuery = (
  { __typename?: 'Query' }
  & { getARRefund: Array<(
    { __typename?: 'AccARRefundEntity' }
    & Pick<AccArRefundEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'RefundID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'Description' | 'DebtorAccountID' | 'BankAccountID' | 'PaymentMethodID' | 'RefNo' | 'Remark' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'ApprovalStatus'>
    & { DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'Address' | 'DebtorName' | 'DebtorAccountID' | 'CompanyID' | 'CreditTerm' | 'IsCompany' | 'GSTRegNo' | 'OfficeNo' | 'MobileNo'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'Name' | 'ContactNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
        )>, BankAccount?: Maybe<(
          { __typename?: 'AccBankAccountEntity' }
          & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
          & { BankProfile?: Maybe<(
            { __typename?: 'BankProfileEntity' }
            & Pick<BankProfileEntity, 'Name' | 'Address'>
          )> }
        )> }
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email'>
      )>> }
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type CreateArRefundMutationVariables = Exact<{
  input: ArRefundInput;
  allocationInput: Array<AllocationInput> | AllocationInput;
}>;


export type CreateArRefundMutation = (
  { __typename?: 'Mutation' }
  & { createARRefund: (
    { __typename?: 'AccARRefundEntity' }
    & Pick<AccArRefundEntity, 'RefundID'>
  ) }
);

export type UpdateArRefundMutationVariables = Exact<{
  input: ArRefundInput;
  allocationInput: Array<AllocationInput> | AllocationInput;
}>;


export type UpdateArRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateARRefund: (
    { __typename?: 'AccARRefundEntity' }
    & Pick<AccArRefundEntity, 'RefundID'>
  ) }
);

export type PostArRefundMutationVariables = Exact<{
  RefundIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostArRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postARRefund'>
);

export type RejectArRefundMutationVariables = Exact<{
  RefundIDs: Array<Scalars['String']> | Scalars['String'];
  input: ArRefundRejectInput;
}>;


export type RejectArRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectARRefund'>
);

export type GetArRefundbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetArRefundbyStatusQuery = (
  { __typename?: 'Query' }
  & { getARRefundbyStatus: Array<(
    { __typename?: 'AccARRefundEntity' }
    & Pick<AccArRefundEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'RefundID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'ProfileInfo' | 'DebtorAccountID' | 'PaymentMethodID' | 'BankAccountID' | 'RefNo' | 'Description' | 'Remark' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'ApprovalStatus'>
    & { DebtorAccount?: Maybe<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorName'>
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name' | 'Address'>
      )> }
    )>, Allocation?: Maybe<Array<(
      { __typename?: 'AccARAllocationObj' }
      & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
    )>> }
  )> }
);

export type GetArRefundbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetArRefundbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getARRefundbyStatusListing: (
    { __typename?: 'ARRefundReturnData' }
    & Pick<ArRefundReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccARRefundEntity' }
      & Pick<AccArRefundEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'AccountID' | 'RecordStatus' | 'RefundID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'DocAmt' | 'BalanceAmt' | 'ProfileInfo' | 'DebtorAccountID' | 'PaymentMethodID' | 'BankAccountID' | 'RefNo' | 'Description' | 'Remark' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'ApprovalStatus'>
      & { DebtorAccount?: Maybe<(
        { __typename?: 'DebtorAccountEntity' }
        & Pick<DebtorAccountEntity, 'DebtorName'>
      )>, PaymentMethod?: Maybe<(
        { __typename?: 'PaymentMethodEntity' }
        & Pick<PaymentMethodEntity, 'Name'>
      )>, BankAccount?: Maybe<(
        { __typename?: 'AccBankAccountEntity' }
        & Pick<AccBankAccountEntity, 'AccountNo'>
        & { BankProfile?: Maybe<(
          { __typename?: 'BankProfileEntity' }
          & Pick<BankProfileEntity, 'Name' | 'Address'>
        )> }
      )>, Allocation?: Maybe<Array<(
        { __typename?: 'AccARAllocationObj' }
        & Pick<AccArAllocationObj, 'AllocationID' | 'Description' | 'DebitDocNo' | 'DebitDocDate' | 'DebitRefTable' | 'DebitID' | 'CreditDocNo' | 'CreditDocDate' | 'CreditRefTable' | 'CreditID' | 'CreditOrDebit' | 'AllocationAmt'>
      )>> }
    )>> }
  ) }
);

export type DeleteArRefundMutationVariables = Exact<{
  RefundID: Scalars['String'];
}>;


export type DeleteArRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteARRefund'>
);

export type GetArSubmenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetArSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'arAdvanceCount' | 'arInvoiceCount' | 'arCreditNoteCount' | 'arDebitNoteCount' | 'arOfficialReceiptCount' | 'arRefundCount'>
);

export type GetDebtorSubmenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
}>;


export type GetDebtorSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'arAdvanceTotalAmt' | 'arInvoiceTotalAmt' | 'arCreditNoteTotalAmt' | 'arDebitNoteTotalAmt' | 'arOfficialReceiptTotalAmt' | 'arRefundTotalAmt'>
);

export type GetDebtorTrxSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
}>;


export type GetDebtorTrxSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'arAdvanceLatestUpdate' | 'arInvoiceLatestUpdate' | 'arCreditNoteLatestUpdate' | 'arDebitNoteLatestUpdate' | 'arOfficialReceiptLatestUpdate' | 'arRefundLatestUpdate'>
);

export type GetOsDebtorDebitDocQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DebtorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetOsDebtorDebitDocQuery = (
  { __typename?: 'Query' }
  & { getARInvoice: Array<(
    { __typename?: 'AccARInvoiceEntity' }
    & Pick<AccArInvoiceEntity, 'InvoiceID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getARDebitNote: Array<(
    { __typename?: 'AccARDebitNoteEntity' }
    & Pick<AccArDebitNoteEntity, 'DebitNoteID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getARCreditNote: Array<(
    { __typename?: 'AccARCreditNoteEntity' }
    & Pick<AccArCreditNoteEntity, 'CreditNoteID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getAROfficialReceipt: Array<(
    { __typename?: 'AccAROfficialReceiptEntity' }
    & Pick<AccArOfficialReceiptEntity, 'ReceiptID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )>, getARRefund: Array<(
    { __typename?: 'AccARRefundEntity' }
    & Pick<AccArRefundEntity, 'RefundID' | 'DocAmt' | 'BalanceAmt' | 'DocNo' | 'DocDate' | 'DueDate' | 'Description'>
  )> }
);

export type UploadProfilePicMutationVariables = Exact<{
  userID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadProfilePic'>
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'jobTitle' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'accountID' | 'lastestAccessedEntity'>
    ) }
  ) }
);

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'jobTitle' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'accountID'>
  ) }
);

export type ViewLogoQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type ViewLogoQuery = (
  { __typename?: 'Query' }
  & { viewLogo: (
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'fileName' | 'fileURL'>
  ) }
);

export type LogoutMutationVariables = Exact<{
  ID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordAccInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetBankReconciliationQueryVariables = Exact<{
  BankReconciliationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type GetBankReconciliationQuery = (
  { __typename?: 'Query' }
  & { getBankReconciliation: Array<(
    { __typename?: 'BankReconciliationEntity' }
    & Pick<BankReconciliationEntity, 'createdTs' | 'createdBy' | 'BankReconciliationID' | 'CompanyID' | 'BankAccountID' | 'DocumentDate' | 'Remarks' | 'IsExcelMatch' | 'StatementBalance' | 'BankReconciliationMatchID'>
    & { BankReconciliationItem?: Maybe<Array<(
      { __typename?: 'BankReconciliationItemEntity' }
      & Pick<BankReconciliationItemEntity, 'DocNo' | 'DocumentDate' | 'Remarks' | 'DocAmt' | 'IsMatch'>
    )>>, BankReconciliationMatch?: Maybe<(
      { __typename?: 'BankReconciliationMatchEntity' }
      & Pick<BankReconciliationMatchEntity, 'BankReconciliationMatchID'>
      & { Ledger?: Maybe<Array<(
        { __typename?: 'LedgerEntity' }
        & Pick<LedgerEntity, 'LedgerID'>
      )>> }
    )> }
  )> }
);

export type GetBankReconciliationRemarksQueryVariables = Exact<{
  BankReconciliationID?: Maybe<Scalars['String']>;
}>;


export type GetBankReconciliationRemarksQuery = (
  { __typename?: 'Query' }
  & { getBankReconciliation: Array<(
    { __typename?: 'BankReconciliationEntity' }
    & Pick<BankReconciliationEntity, 'BankReconciliationID' | 'Remarks'>
  )> }
);

export type GetBankReconciliationDescriptionQueryVariables = Exact<{
  BankReconciliationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type GetBankReconciliationDescriptionQuery = (
  { __typename?: 'Query' }
  & { getBankReconciliation: Array<(
    { __typename?: 'BankReconciliationEntity' }
    & Pick<BankReconciliationEntity, 'createdTs' | 'createdBy' | 'BankReconciliationID' | 'CompanyID' | 'BankAccountID' | 'DocumentDate' | 'Remarks'>
  )> }
);

export type CreateBankReconciliationMutationVariables = Exact<{
  input: BankReconciliationInput;
}>;


export type CreateBankReconciliationMutation = (
  { __typename?: 'Mutation' }
  & { createBankReconciliation: (
    { __typename?: 'BankReconciliationEntity' }
    & Pick<BankReconciliationEntity, 'BankReconciliationID'>
  ) }
);

export type UpdateBankReconciliationMutationVariables = Exact<{
  input: BankReconciliationInput;
}>;


export type UpdateBankReconciliationMutation = (
  { __typename?: 'Mutation' }
  & { updateBankReconciliation: (
    { __typename?: 'BankReconciliationEntity' }
    & Pick<BankReconciliationEntity, 'BankReconciliationID'>
  ) }
);

export type GetCheckingValidationBankReconQueryVariables = Exact<{
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput> | BankReconciliationItemImportInput;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
}>;


export type GetCheckingValidationBankReconQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationBankRecon'>
);

export type CreateImportBankReconciliationMutationVariables = Exact<{
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput> | BankReconciliationItemImportInput;
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
  StatementBalance: Scalars['Float'];
  IsExcelMatch: Scalars['Boolean'];
}>;


export type CreateImportBankReconciliationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createImportBankReconciliation'>
);

export type UpdateImportBankReconciliationMutationVariables = Exact<{
  BankReconciliationItemImportInput: Array<BankReconciliationItemImportInput> | BankReconciliationItemImportInput;
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
  StatementBalance: Scalars['Float'];
  IsExcelMatch: Scalars['Boolean'];
}>;


export type UpdateImportBankReconciliationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateImportBankReconciliation'>
);

export type CreateBankReconciliationWihoutExcelMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
  StatementBalance: Scalars['Float'];
  IsExcelMatch: Scalars['Boolean'];
}>;


export type CreateBankReconciliationWihoutExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBankReconciliationWihoutExcel'>
);

export type UpdateBankReconciliationWihoutExcelMutationVariables = Exact<{
  BankReconciliationID: Scalars['String'];
  BankAccountID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
  StatementBalance: Scalars['Float'];
  IsExcelMatch: Scalars['Boolean'];
}>;


export type UpdateBankReconciliationWihoutExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBankReconciliationWihoutExcel'>
);

export type GetBankReconciliationItemQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  BankReconciliationID?: Maybe<Scalars['String']>;
  BankReconciliationItemID?: Maybe<Scalars['String']>;
  IsMatch?: Maybe<Scalars['Boolean']>;
}>;


export type GetBankReconciliationItemQuery = (
  { __typename?: 'Query' }
  & { getBankReconciliationItem: Array<(
    { __typename?: 'BankReconciliationItemEntity' }
    & Pick<BankReconciliationItemEntity, 'BankReconciliationItemID' | 'BankReconciliationID' | 'DocNo' | 'DocumentDate' | 'Remarks' | 'DocAmt' | 'IsMatch'>
  )> }
);

export type GetBankStatementQueryVariables = Exact<{
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  Mode: Scalars['String'];
}>;


export type GetBankStatementQuery = (
  { __typename?: 'Query' }
  & { getBankStatement: Array<(
    { __typename?: 'BankReconciliationItemEntity' }
    & Pick<BankReconciliationItemEntity, 'BankReconciliationItemID' | 'BankReconciliationID' | 'DocNo' | 'DocumentDate' | 'Remarks' | 'DocAmt' | 'IsMatch'>
  )> }
);

export type GetBankReconCbLedgerQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BankReconciliationID: Scalars['String'];
  BankAccountID?: Maybe<Scalars['String']>;
  Mode: Scalars['String'];
  DocDate: Scalars['String'];
}>;


export type GetBankReconCbLedgerQuery = (
  { __typename?: 'Query' }
  & { getBankReconCBLedger: Array<(
    { __typename?: 'LedgerEntity' }
    & Pick<LedgerEntity, 'DocNo' | 'createdTs' | 'modTs' | 'createdBy' | 'modBy' | 'RefNo' | 'Description' | 'LedgerID' | 'CompanyID' | 'BankAccountID' | 'DocAmt' | 'BaseAmt' | 'DocDate' | 'TransactionDate' | 'TrxType' | 'ExchangeRate' | 'CurrencyID' | 'ApprovalStatus' | 'RefTable' | 'BankReconciliationMatchID' | 'PayeeName' | 'ReceivedFrom' | 'LedgerType'>
  )> }
);

export type MatchBankReconciliationMutationVariables = Exact<{
  input: BankReconciliationMatchInput;
  BankReconItemIDs: Array<Scalars['String']> | Scalars['String'];
  LedgerIDs: Array<Scalars['String']> | Scalars['String'];
  Mode: Scalars['String'];
}>;


export type MatchBankReconciliationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MatchBankReconciliation'>
);

export type GetBankReconciliationMatchQueryVariables = Exact<{
  BankReconciliationID?: Maybe<Scalars['String']>;
}>;


export type GetBankReconciliationMatchQuery = (
  { __typename?: 'Query' }
  & { getBankReconciliationMatch: Array<(
    { __typename?: 'BankReconciliationMatchEntity' }
    & Pick<BankReconciliationMatchEntity, 'BankReconciliationMatchID' | 'BankReconciliationID' | 'CompanyID'>
    & { Ledger?: Maybe<Array<(
      { __typename?: 'LedgerEntity' }
      & Pick<LedgerEntity, 'LedgerID' | 'DocNo' | 'DocDate' | 'DocAmt' | 'Description' | 'RefTable' | 'RefNo' | 'PayeeName' | 'ReceivedFrom'>
    )>>, BankReconciliationItem?: Maybe<Array<(
      { __typename?: 'BankReconciliationItemEntity' }
      & Pick<BankReconciliationItemEntity, 'BankReconciliationItemID' | 'DocNo' | 'DocumentDate' | 'DocAmt' | 'Remarks'>
    )>> }
  )> }
);

export type DeleteBankReconciliationMutationVariables = Exact<{
  BankReconciliationID: Scalars['String'];
}>;


export type DeleteBankReconciliationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankReconciliation'>
);

export type GetBankTransferQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankTransferID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type GetBankTransferQuery = (
  { __typename?: 'Query' }
  & { getBankTransfer: Array<(
    { __typename?: 'BankTransferEntity' }
    & Pick<BankTransferEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'BankTransferID' | 'DocNo' | 'CompanyID' | 'TransferDate' | 'TransactionDate' | 'FromBankAccountID' | 'ToBankAccountID' | 'PaymentMethodID' | 'Amount' | 'RefNo' | 'Description' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name'>
    )>, ToBankAccountDetail?: Maybe<(
      { __typename?: 'BankAccDetail' }
      & Pick<BankAccDetail, 'AccountNo' | 'BankName'>
    )>, FromBankAccountDetail?: Maybe<(
      { __typename?: 'BankAccDetail' }
      & Pick<BankAccDetail, 'AccountNo' | 'BankName'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetBankTransferbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  FromBankAccountID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetBankTransferbyStatusQuery = (
  { __typename?: 'Query' }
  & { getBankTransferbyStatus: Array<(
    { __typename?: 'BankTransferEntity' }
    & Pick<BankTransferEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'BankTransferID' | 'DocNo' | 'TransactionDate' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'CompanyID' | 'TransferDate' | 'FromBankAccountID' | 'ToBankAccountID' | 'Amount' | 'RefNo' | 'Description' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name'>
    )>, ToBankAccountDetail?: Maybe<(
      { __typename?: 'BankAccDetail' }
      & Pick<BankAccDetail, 'AccountNo' | 'BankName'>
    )>, FromBankAccountDetail?: Maybe<(
      { __typename?: 'BankAccDetail' }
      & Pick<BankAccDetail, 'AccountNo' | 'BankName'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'StatusRemarks' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetApprovedBankTransferQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetApprovedBankTransferQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApprovedBankTransfer'>
);

export type GetApprovedBankTransferListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetApprovedBankTransferListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApprovedBankTransferListing'>
);

export type CreateBankTransferMutationVariables = Exact<{
  input: BankTransferInput;
}>;


export type CreateBankTransferMutation = (
  { __typename?: 'Mutation' }
  & { createBankTransfer: (
    { __typename?: 'BankTransferEntity' }
    & Pick<BankTransferEntity, 'BankTransferID'>
  ) }
);

export type UpdateBankTransferMutationVariables = Exact<{
  input: BankTransferInput;
}>;


export type UpdateBankTransferMutation = (
  { __typename?: 'Mutation' }
  & { updateBankTransfer: (
    { __typename?: 'BankTransferEntity' }
    & Pick<BankTransferEntity, 'BankTransferID'>
  ) }
);

export type DeleteBankTransferMutationVariables = Exact<{
  BankTransferID: Scalars['String'];
}>;


export type DeleteBankTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankTransfer'>
);

export type PostCbBankTransferMutationVariables = Exact<{
  BankTransferIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostCbBankTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postCBBankTransfer'>
);

export type RejectBankTransferMutationVariables = Exact<{
  BankTransferIDs: Array<Scalars['String']> | Scalars['String'];
  input: BankTransferRejectInput;
}>;


export type RejectBankTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectBankTransfer'>
);

export type GetCbglExportQueryVariables = Exact<{
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prefix: Scalars['String'];
}>;


export type GetCbglExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExport'>
);

export type GetCbExportedGlListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetCbExportedGlListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLListing'>
);

export type GetCbExportedGlDetailQueryVariables = Exact<{
  BatchNo: Scalars['Float'];
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetCbExportedGlDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedGLDetail'>
);

export type GetCbglExportDetailByAccountCodeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  AccountCode: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type GetCbglExportDetailByAccountCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExportDetailByAccountCode'>
);

export type PostCbglExportMutationVariables = Exact<{
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  GLDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  prefix: Scalars['String'];
}>;


export type PostCbglExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postGLExport'>
);

export type CreateGlExportMutationVariables = Exact<{
  input?: Maybe<Array<GlExportInput> | GlExportInput>;
  GLDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  prefix: Scalars['String'];
}>;


export type CreateGlExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGLExport'>
);

export type GetCashBookSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type GetCashBookSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BankReconciliationDate' | 'BankTransferDate' | 'PaymentDate' | 'ReceiptDate' | 'ExternalLedgerDate'>
);

export type CashBookCountQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type CashBookCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CashBookCount'>
);

export type GetCbSubmenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
}>;


export type GetCbSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'paymentCount' | 'receiptCount' | 'bankTransferCount' | 'BankReconciliationDate' | 'BankTransferDate' | 'PaymentDate' | 'ReceiptDate' | 'ExternalLedgerDate'>
);

export type GetExternalLedgerQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  ExternalLedgerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetExternalLedgerQuery = (
  { __typename?: 'Query' }
  & { getExternalLedger: Array<(
    { __typename?: 'ExternalLedgerEntity' }
    & Pick<ExternalLedgerEntity, 'DocNo' | 'createdTs' | 'RefNo' | 'Description' | 'ExternalLedgerID' | 'CompanyID' | 'BankAccountID' | 'LedgerType' | 'DocAmt' | 'DocDate' | 'TransactionDate' | 'TrxType' | 'ExchangeRate'>
  )> }
);

export type GetCheckingValidationExtLedgerQueryVariables = Exact<{
  ExternalLedgerImportInput: Array<ExternalLedgerImportInput> | ExternalLedgerImportInput;
  CompanyID: Scalars['String'];
}>;


export type GetCheckingValidationExtLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationExtLedger'>
);

export type CreateExternalLedgerMutationVariables = Exact<{
  ExternalLedgerImportInput: Scalars['String'];
  CompanyID: Scalars['String'];
  DocRefName: Scalars['String'];
}>;


export type CreateExternalLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createExternalLedger'>
);

export type GetFavoritePaymentQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoritePaymentID?: Maybe<Scalars['String']>;
}>;


export type GetFavoritePaymentQuery = (
  { __typename?: 'Query' }
  & { getFavoritePayment: Array<(
    { __typename?: 'FavoritePaymentEntity' }
    & Pick<FavoritePaymentEntity, 'createdBy' | 'FavoritePaymentID' | 'Name' | 'RefNo' | 'Description' | 'PayeeName' | 'DocAmt' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo'>
    & { PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'Name'>
    )>, FavoritePaymentItem?: Maybe<Array<(
      { __typename?: 'FavoritePaymentItemEntity' }
      & Pick<FavoritePaymentItemEntity, 'Sequence' | 'FavoritePaymentID' | 'MasterCOAID' | 'DocNo' | 'DocDate' | 'CostCentreID' | 'TaxSchemeID' | 'Amount' | 'Remark' | 'TaxRate' | 'TaxAmt' | 'DocAmt'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateFavoritePaymentMutationVariables = Exact<{
  FavoritePaymentInput: FavoritePaymentInput;
  FavoritePaymentItemInput: Array<FavoritePaymentItemInput> | FavoritePaymentItemInput;
}>;


export type CreateFavoritePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFavoritePayment'>
);

export type DeleteFavoritePaymentMutationVariables = Exact<{
  FavoritePaymentID: Scalars['String'];
}>;


export type DeleteFavoritePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFavoritePayment'>
);

export type GetFavoriteReceiptQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteReceiptID?: Maybe<Scalars['String']>;
}>;


export type GetFavoriteReceiptQuery = (
  { __typename?: 'Query' }
  & { getFavoriteReceipt: Array<(
    { __typename?: 'FavoriteReceiptEntity' }
    & Pick<FavoriteReceiptEntity, 'createdBy' | 'FavoriteReceiptID' | 'Name' | 'RefNo' | 'Description' | 'ReceivedFrom' | 'ChequeNo' | 'PaymentMethodID' | 'CreditCardID'>
    & { PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'Name'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'Name'>
    )>, FavoriteReceiptItem?: Maybe<Array<(
      { __typename?: 'FavoriteReceiptItemEntity' }
      & Pick<FavoriteReceiptItemEntity, 'Sequence' | 'FavoriteReceiptID' | 'MasterCOAID' | 'CostCentreID' | 'TaxSchemeID' | 'Amount' | 'Remark' | 'TaxRate' | 'TaxAmt' | 'DocAmt'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateFavoriteReceiptMutationVariables = Exact<{
  FavoriteReceiptInput: FavoriteReceiptInput;
  FavoriteReceiptItemInput: Array<FavoriteReceiptItemInput> | FavoriteReceiptItemInput;
}>;


export type CreateFavoriteReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFavoriteReceipt'>
);

export type DeleteFavoriteReceiptMutationVariables = Exact<{
  FavoriteReceiptID: Scalars['String'];
}>;


export type DeleteFavoriteReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFavoriteReceipt'>
);

export type GetLedgerQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLedgerCount'>
  & { getLedger: Array<(
    { __typename?: 'LedgerEntity' }
    & Pick<LedgerEntity, 'DocNo' | 'createdTs' | 'modTs' | 'createdBy' | 'modBy' | 'RefNo' | 'Description' | 'LedgerID' | 'CompanyID' | 'BankAccountID' | 'DocAmt' | 'BaseAmt' | 'LedgerType' | 'DocDate' | 'TransactionDate' | 'PayeeName' | 'ReceivedFrom' | 'TrxType' | 'ExchangeRate' | 'CurrencyID'>
    & { Currency?: Maybe<(
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type GetPaymentQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentQuery = (
  { __typename?: 'Query' }
  & { getPayment: Array<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'PaymentID' | 'TransactionDate' | 'DocDate' | 'RefNo' | 'CompanyID' | 'Description' | 'PayeeName' | 'ApprovalStatus' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'DocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'DocAmt'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID' | 'StatusRemarks' | 'createdTs'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, PaymentItem?: Maybe<Array<(
      { __typename?: 'PaymentItemEntity' }
      & Pick<PaymentItemEntity, 'PaymentItemID' | 'Amount' | 'CompanyID' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'DocNo' | 'DocDate' | 'MasterCOAID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetPaymentItemQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentItemQuery = (
  { __typename?: 'Query' }
  & { getPaymentItem: Array<(
    { __typename?: 'PaymentItemEntity' }
    & Pick<PaymentItemEntity, 'PaymentItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'Remark'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Code' | 'Name'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )> }
  )> }
);

export type CreatePaymentMutationVariables = Exact<{
  input: PaymentInput;
  itemInput?: Maybe<Array<PaymentItemInput> | PaymentItemInput>;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'PaymentID'>
  ) }
);

export type UpdatePaymentMutationVariables = Exact<{
  input: PaymentInput;
  itemInput?: Maybe<Array<PaymentItemInput> | PaymentItemInput>;
}>;


export type UpdatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { updatePayment: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'PaymentID'>
  ) }
);

export type DeletePaymentMutationVariables = Exact<{
  PaymentID: Scalars['String'];
}>;


export type DeletePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePayment'>
);

export type CancelPaymentMutationVariables = Exact<{
  input: PaymentDeleteInput;
}>;


export type CancelPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelPayment'>
);

export type UpdatePaymentItemMutationVariables = Exact<{
  input: PaymentItemInput;
}>;


export type UpdatePaymentItemMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentItem: (
    { __typename?: 'PaymentItemEntity' }
    & Pick<PaymentItemEntity, 'PaymentItemID'>
  ) }
);

export type PostCbPaymentMutationVariables = Exact<{
  PaymentIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostCbPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postCBPayment'>
);

export type RejectPaymentMutationVariables = Exact<{
  PaymentIDs: Array<Scalars['String']> | Scalars['String'];
  input: PaymentRejectInput;
}>;


export type RejectPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectPayment'>
);

export type GetPaymentbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPaymentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getPaymentbyStatus: Array<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'PaymentID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CompanyID' | 'Description' | 'PayeeName' | 'ApprovalStatus' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'DocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'DocAmt'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID' | 'StatusRemarks' | 'createdTs'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, PaymentItem?: Maybe<Array<(
      { __typename?: 'PaymentItemEntity' }
      & Pick<PaymentItemEntity, 'PaymentItemID' | 'Amount' | 'CompanyID' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'DocNo' | 'DocDate' | 'Sequence' | 'MasterCOAID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetPaymentbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetPaymentbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getPaymentbyStatusListing: Array<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'PaymentID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CompanyID' | 'Description' | 'PayeeName' | 'ApprovalStatus' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'DocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'DocAmt'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID' | 'StatusRemarks' | 'createdTs'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, PaymentItem?: Maybe<Array<(
      { __typename?: 'PaymentItemEntity' }
      & Pick<PaymentItemEntity, 'PaymentItemID' | 'Amount' | 'CompanyID' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'DocNo' | 'DocDate' | 'Sequence' | 'MasterCOAID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetReceiptQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
}>;


export type GetReceiptQuery = (
  { __typename?: 'Query' }
  & { getReceipt: Array<(
    { __typename?: 'ReceiptEntity' }
    & Pick<ReceiptEntity, 'createdBy' | 'ReceiptID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CompanyID' | 'Description' | 'ReceivedFrom' | 'BankAccountID' | 'ApprovalStatus' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'DocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'DocAmt'>
    & { BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code' | 'BankAccountID'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'WorkFlowApprovedStepID' | 'StatusRemarks'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ReceiptItem?: Maybe<Array<(
      { __typename?: 'ReceiptItemEntity' }
      & Pick<ReceiptItemEntity, 'ReceiptItemID' | 'Amount' | 'CompanyID' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'MasterCOAID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetReceiptItemQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReceiptID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
}>;


export type GetReceiptItemQuery = (
  { __typename?: 'Query' }
  & { getReceiptItem: Array<(
    { __typename?: 'ReceiptItemEntity' }
    & Pick<ReceiptItemEntity, 'ReceiptItemID' | 'MasterCOAID' | 'Amount' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'CostCentreID' | 'Remark'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Code' | 'Name'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )> }
  )> }
);

export type GetReceiptbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetReceiptbyStatusQuery = (
  { __typename?: 'Query' }
  & { getReceiptbyStatus: Array<(
    { __typename?: 'ReceiptEntity' }
    & Pick<ReceiptEntity, 'createdBy' | 'ReceiptID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CompanyID' | 'Description' | 'ReceivedFrom' | 'BankAccountID' | 'ApprovalStatus' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'DocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'DocAmt'>
    & { BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'WorkFlowApprovedStepID' | 'StatusRemarks'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ReceiptItem?: Maybe<Array<(
      { __typename?: 'ReceiptItemEntity' }
      & Pick<ReceiptItemEntity, 'ReceiptItemID' | 'Amount' | 'CompanyID' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'MasterCOAID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetReceiptbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BankAccountID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetReceiptbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getReceiptbyStatusListing: Array<(
    { __typename?: 'ReceiptEntity' }
    & Pick<ReceiptEntity, 'createdBy' | 'ReceiptID' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CompanyID' | 'Description' | 'ReceivedFrom' | 'BankAccountID' | 'ApprovalStatus' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'DocNo' | 'PaymentMethodID' | 'CreditCardID' | 'ChequeNo' | 'ChequeDate' | 'ChequeExpiryDate' | 'DocAmt'>
    & { BankAccount?: Maybe<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'AccountNo' | 'Code'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'Name'>
      )> }
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, PaymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodEntity' }
      & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, CreditCardType?: Maybe<(
      { __typename?: 'CreditCardTypeEntity' }
      & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    )>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'WorkFlowApprovedStepID' | 'StatusRemarks'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, ReceiptItem?: Maybe<Array<(
      { __typename?: 'ReceiptItemEntity' }
      & Pick<ReceiptItemEntity, 'ReceiptItemID' | 'Amount' | 'CompanyID' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmt' | 'DocAmt' | 'Sequence' | 'MasterCOAID' | 'CostCentreID'>
      & { TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code'>
      )>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type PostCbReceiptMutationVariables = Exact<{
  ReceiptIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RefTable: Scalars['String'];
}>;


export type PostCbReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postCBReceipt'>
);

export type RejectReceiptMutationVariables = Exact<{
  ReceiptIDs: Array<Scalars['String']> | Scalars['String'];
  input: ReceiptRejectInput;
}>;


export type RejectReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectReceipt'>
);

export type CreateReceiptMutationVariables = Exact<{
  input: ReceiptInput;
  itemInput?: Maybe<Array<ReceiptItemInput> | ReceiptItemInput>;
}>;


export type CreateReceiptMutation = (
  { __typename?: 'Mutation' }
  & { createReceipt: (
    { __typename?: 'ReceiptEntity' }
    & Pick<ReceiptEntity, 'ReceiptID'>
  ) }
);

export type UpdateReceiptMutationVariables = Exact<{
  input: ReceiptInput;
  itemInput?: Maybe<Array<ReceiptItemInput> | ReceiptItemInput>;
}>;


export type UpdateReceiptMutation = (
  { __typename?: 'Mutation' }
  & { updateReceipt: (
    { __typename?: 'ReceiptEntity' }
    & Pick<ReceiptEntity, 'ReceiptID'>
  ) }
);

export type CancelReceiptMutationVariables = Exact<{
  input: ReceiptDeleteInput;
}>;


export type CancelReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelReceipt'>
);

export type DeleteReceiptMutationVariables = Exact<{
  ReceiptID: Scalars['String'];
}>;


export type DeleteReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReceipt'>
);

export type UpdateReceiptItemMutationVariables = Exact<{
  input: ReceiptItemInput;
}>;


export type UpdateReceiptItemMutation = (
  { __typename?: 'Mutation' }
  & { updateReceiptItem: (
    { __typename?: 'ReceiptItemEntity' }
    & Pick<ReceiptItemEntity, 'ReceiptItemID'>
  ) }
);

export type GetAccountPeriodQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
  orderByAsc?: Maybe<Scalars['String']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
}>;


export type GetAccountPeriodQuery = (
  { __typename?: 'Query' }
  & { getAccountPeriod: Array<(
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'CompanyID' | 'FYear' | 'FPeriod' | 'StartDate' | 'EndDate' | 'MonthEndClose' | 'YearEndClose'>
  )> }
);

export type GetAccountPeriodPermQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAccountPeriodPermQuery = (
  { __typename?: 'Query' }
  & { getAccountPeriodPerm: Array<(
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'CompanyID' | 'FYear' | 'FPeriod' | 'StartDate' | 'EndDate' | 'MonthEndClose' | 'YearEndClose'>
  )> }
);

export type GetAccountPeriodYearPeriodQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAccountPeriodYearPeriodQuery = (
  { __typename?: 'Query' }
  & { getAccountPeriod: Array<(
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'CompanyID' | 'FYear' | 'FPeriod' | 'MonthEndClose' | 'StartDate' | 'EndDate'>
  )> }
);

export type GetSelectedAccountPeriodQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  selectedDate: Scalars['DateTime'];
}>;


export type GetSelectedAccountPeriodQuery = (
  { __typename?: 'Query' }
  & { getSelectedAccountPeriod: (
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'FYear' | 'FPeriod'>
  ) }
);

export type GetAccountYearQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetAccountYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAccountYear'>
);

export type GetOpenAccPeriodDateRangeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetOpenAccPeriodDateRangeQuery = (
  { __typename?: 'Query' }
  & { getOpenAccPeriodDateRange: (
    { __typename?: 'AccountPeriodDateRange' }
    & Pick<AccountPeriodDateRange, 'StartDate' | 'EndDate'>
  ) }
);

export type GetNextAccPeriodQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetNextAccPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNextAccPeriod'>
);

export type AccountPeriodCheckingQueryVariables = Exact<{
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
}>;


export type AccountPeriodCheckingQuery = (
  { __typename?: 'Query' }
  & { accountPeriodChecking: (
    { __typename?: 'AccountPeriodCheck' }
    & Pick<AccountPeriodCheck, 'YearClosed' | 'IsEditable' | 'LatestUsedPeriod' | 'MinStartDate' | 'MaxEndDate'>
  ) }
);

export type CheckExistingAccountPeriodQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CheckExistingAccountPeriodQuery = (
  { __typename?: 'Query' }
  & { CheckExistingAccountPeriod: (
    { __typename?: 'CheckExistingAccountPeriod' }
    & Pick<CheckExistingAccountPeriod, 'LastYear' | 'LastMonth'>
  ) }
);

export type CreateAccountPeriodMutationVariables = Exact<{
  input?: Maybe<Array<AccountPeriodInput> | AccountPeriodInput>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
}>;


export type CreateAccountPeriodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAccountPeriod'>
);

export type UpdateAccountPeriodMutationVariables = Exact<{
  input: Array<AccountPeriodInput> | AccountPeriodInput;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
}>;


export type UpdateAccountPeriodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountPeriod'>
);

export type GetPreviousPeriodOfYearQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
}>;


export type GetPreviousPeriodOfYearQuery = (
  { __typename?: 'Query' }
  & { getPreviousPeriodOfYear?: Maybe<(
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'CompanyID' | 'FYear' | 'FPeriod' | 'StartDate' | 'EndDate' | 'MonthEndClose' | 'YearEndClose'>
  )> }
);

export type GetArAgingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  AgingID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
}>;


export type GetArAgingQuery = (
  { __typename?: 'Query' }
  & { getARAging: Array<(
    { __typename?: 'ARAgingEntity' }
    & Pick<ArAgingEntity, 'AgingID' | 'Period1' | 'Period2' | 'Period3' | 'Period4' | 'Period5' | 'Period6' | 'AccountType'>
  )> }
);

export type CreateArAgingMutationVariables = Exact<{
  input: AgingInput;
}>;


export type CreateArAgingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createARAging'>
);

export type GetApAgingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  AgingID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
}>;


export type GetApAgingQuery = (
  { __typename?: 'Query' }
  & { getAPAging: Array<(
    { __typename?: 'APAgingEntity' }
    & Pick<ApAgingEntity, 'AgingID' | 'Period1' | 'Period2' | 'Period3' | 'Period4' | 'Period5' | 'Period6' | 'AccountType'>
  )> }
);

export type CreateApAgingMutationVariables = Exact<{
  input: AgingInput;
}>;


export type CreateApAgingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAPAging'>
);

export type GetApprovalPolicyAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Category?: Maybe<Scalars['String']>;
}>;


export type GetApprovalPolicyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicyAssignment?: Maybe<Array<(
    { __typename?: 'ProcurementApprovalPolicyAssignmentEntity' }
    & Pick<ProcurementApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID' | 'Category' | 'modTs' | 'createdTs' | 'ApprovalList' | 'CompanyID'>
  )>> }
);

export type GetAllApprovalPolicyAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Category?: Maybe<Scalars['String']>;
}>;


export type GetAllApprovalPolicyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getAllApprovalPolicyAssignment?: Maybe<Array<(
    { __typename?: 'ProcurementApprovalPolicyAssignmentEntity' }
    & Pick<ProcurementApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID' | 'Category' | 'modTs' | 'createdTs' | 'ApprovalList' | 'CompanyID'>
  )>> }
);

export type CreateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type CreateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicyAssignment: (
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID'>
  ) }
);

export type UpdateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type UpdateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicyAssignment: (
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID'>
  ) }
);

export type DeleteApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentDeleteInput;
}>;


export type DeleteApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicyAssignment'>
);

export type GetBankAccountQueryVariables = Exact<{
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetBankAccountQuery = (
  { __typename?: 'Query' }
  & { getBankAccount: Array<(
    { __typename?: 'AccBankAccountEntity' }
    & Pick<AccBankAccountEntity, 'BankAccountID' | 'BankProfileID' | 'Code' | 'AccountNo' | 'Type' | 'MasterCOAID' | 'CostCentreID' | 'SWIFTCode' | 'EffectiveDate' | 'CurrencyID' | 'CompanyID' | 'RecordStatus'>
    & { BankProfile?: Maybe<(
      { __typename?: 'BankProfileEntity' }
      & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'Name'>
    )>, Currency?: Maybe<(
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'ID' | 'name'>
    )>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'PaymentID'>
    )>>, Receipt?: Maybe<Array<(
      { __typename?: 'ReceiptEntity' }
      & Pick<ReceiptEntity, 'ReceiptID'>
    )>>, BankTransfer?: Maybe<Array<(
      { __typename?: 'BankTransferEntity' }
      & Pick<BankTransferEntity, 'BankTransferID'>
    )>>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Code' | 'IsDefault'>
    )> }
  )> }
);

export type GetBankAccountListingQueryVariables = Exact<{
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetBankAccountListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBankAccountCount'>
  & { getBankAccount: Array<(
    { __typename?: 'AccBankAccountEntity' }
    & Pick<AccBankAccountEntity, 'BankAccountID' | 'BankProfileID' | 'Code' | 'AccountNo' | 'Type' | 'MasterCOAID' | 'CostCentreID' | 'SWIFTCode' | 'EffectiveDate' | 'CurrencyID' | 'CompanyID' | 'RecordStatus' | 'BankBalance'>
    & { BankProfile?: Maybe<(
      { __typename?: 'BankProfileEntity' }
      & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'Name'>
    )>, Currency?: Maybe<(
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'ID' | 'name'>
    )>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'PaymentID'>
    )>>, Receipt?: Maybe<Array<(
      { __typename?: 'ReceiptEntity' }
      & Pick<ReceiptEntity, 'ReceiptID'>
    )>>, BankTransfer?: Maybe<Array<(
      { __typename?: 'BankTransferEntity' }
      & Pick<BankTransferEntity, 'BankTransferID'>
    )>>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Code'>
    )> }
  )> }
);

export type GetBankAccountProfileListingQueryVariables = Exact<{
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  RecordStatus?: Maybe<RecordStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetBankAccountProfileListingQuery = (
  { __typename?: 'Query' }
  & { getBankAccountProfileListing: (
    { __typename?: 'BankAccountReturnData' }
    & Pick<BankAccountReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID' | 'BankProfileID' | 'Code' | 'AccountNo' | 'Type' | 'MasterCOAID' | 'CostCentreID' | 'SWIFTCode' | 'EffectiveDate' | 'CurrencyID' | 'CompanyID' | 'RecordStatus' | 'BankBalance'>
      & { BankProfile?: Maybe<(
        { __typename?: 'BankProfileEntity' }
        & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'Name'>
      )>, Currency?: Maybe<(
        { __typename?: 'CurrencyEntity' }
        & Pick<CurrencyEntity, 'ID' | 'name'>
      )>, Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'PaymentID'>
      )>>, Receipt?: Maybe<Array<(
        { __typename?: 'ReceiptEntity' }
        & Pick<ReceiptEntity, 'ReceiptID'>
      )>>, BankTransfer?: Maybe<Array<(
        { __typename?: 'BankTransferEntity' }
        & Pick<BankTransferEntity, 'BankTransferID'>
      )>>, MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )> }
    )>> }
  ) }
);

export type GetBankAccountCodeQueryVariables = Exact<{
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetBankAccountCodeQuery = (
  { __typename?: 'Query' }
  & { getBankAccount: Array<(
    { __typename?: 'AccBankAccountEntity' }
    & Pick<AccBankAccountEntity, 'BankAccountID' | 'BankProfileID' | 'Code' | 'CompanyID'>
  )> }
);

export type CreateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount: (
    { __typename?: 'AccBankAccountEntity' }
    & Pick<AccBankAccountEntity, 'BankAccountID' | 'BankProfileID' | 'Code' | 'AccountNo' | 'SWIFTCode' | 'EffectiveDate' | 'CompanyID' | 'MasterCOAID' | 'CostCentreID'>
  ) }
);

export type UpdateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBankAccount: (
    { __typename?: 'AccBankAccountEntity' }
    & Pick<AccBankAccountEntity, 'BankAccountID'>
  ) }
);

export type CancelBankAccountMutationVariables = Exact<{
  input: BankAccountCancellationInput;
  BankAccountID: Scalars['String'];
}>;


export type CancelBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelBankAccount'>
);

export type ActivateBankAccountMutationVariables = Exact<{
  BankAccountID: Scalars['String'];
}>;


export type ActivateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateBankAccount'>
);

export type DeleteBankAccountMutationVariables = Exact<{
  input: BankAccountDeleteInput;
}>;


export type DeleteBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankAccount'>
);

export type GetCheckingValidationBaQueryVariables = Exact<{
  BAImportInput: Array<BaImportInput> | BaImportInput;
  CompanyID: Scalars['String'];
}>;


export type GetCheckingValidationBaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationBA'>
);

export type CreateBankAccountImportMutationVariables = Exact<{
  TemplateStr: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type CreateBankAccountImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBankAccountImport'>
);

export type GetLocalBankQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetLocalBankQuery = (
  { __typename?: 'Query' }
  & { getLocalBank: Array<(
    { __typename?: 'LocalBankEntity' }
    & Pick<LocalBankEntity, 'ID' | 'name' | 'code'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'district' | 'state' | 'postCode' | 'country'>
    ) }
  )> }
);

export type GetCoaStructureQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetCoaStructureQuery = (
  { __typename?: 'Query' }
  & { getCOAStructure: Array<(
    { __typename?: 'COAStructureEntity' }
    & Pick<CoaStructureEntity, 'COAStructureID' | 'Level' | 'CodeLength'>
  )> }
);

export type CreateCoaStructureMutationVariables = Exact<{
  COAStructureInput: Array<CoaStructureInput> | CoaStructureInput;
}>;


export type CreateCoaStructureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCOAStructure'>
);

export type GetCompanyQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'Name' | 'CompanyRegNo' | 'CompanyTax' | 'Code' | 'SSTNo' | 'TourismTaxNo' | 'GSTNo' | 'GSTExpiryDate' | 'GroupGSTRegNo' | 'ContactNo' | 'Email' | 'BaseCurrencyID' | 'RecordStatus' | 'Logo' | 'Tin' | 'eSign' | 'eSignExpiryDate' | 'eInvIntegrated' | 'eInvStartDate' | 'eInvEndDate' | 'eInvClientID' | 'eInvSecretKey' | 'eInvSecretKey2'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
    )>, Currency: (
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'ID' | 'name' | 'code'>
    ), EntityCOA?: Maybe<(
      { __typename?: 'EntityCOAEntity' }
      & Pick<EntityCoaEntity, 'MasterCOAID'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name' | 'IsLastNode' | 'AccountType'>
      )> }
    )>, ParentCompany?: Maybe<(
      { __typename?: 'CompanyInfo' }
      & Pick<CompanyInfo, 'CompanyID' | 'Name'>
    )> }
  )> }
);

export type GetCompanyListingQueryVariables = Exact<{
  RecordStatus?: Maybe<RecordStatus>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetCompanyListingQuery = (
  { __typename?: 'Query' }
  & { getCompanyListing: (
    { __typename?: 'CompanyReturnType' }
    & Pick<CompanyReturnType, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'Name' | 'CompanyRegNo' | 'CompanyTax' | 'Code' | 'SSTNo' | 'TourismTaxNo' | 'GSTNo' | 'GSTExpiryDate' | 'ContactNo' | 'Email' | 'BaseCurrencyID' | 'RecordStatus' | 'Logo'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
      )>, Currency: (
        { __typename?: 'CurrencyEntity' }
        & Pick<CurrencyEntity, 'ID' | 'name' | 'code'>
      ), EntityCOA?: Maybe<(
        { __typename?: 'EntityCOAEntity' }
        & Pick<EntityCoaEntity, 'MasterCOAID'>
        & { MasterCOA?: Maybe<(
          { __typename?: 'MasterCOAEntity' }
          & Pick<MasterCoaEntity, 'Code' | 'Name' | 'IsLastNode' | 'AccountType'>
        )> }
      )>, ParentCompany?: Maybe<(
        { __typename?: 'CompanyInfo' }
        & Pick<CompanyInfo, 'CompanyID' | 'Name'>
      )> }
    )>> }
  ) }
);

export type GetParentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParentCompanyQuery = (
  { __typename?: 'Query' }
  & { getParentCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name'>
  )> }
);

export type GetCompanyNameQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanyNameQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'Logo' | 'CompanyTax' | 'SSTNo' | 'GSTNo' | 'eSign' | 'eSignExpiryDate' | 'eInvIntegrated' | 'eInvStartDate' | 'eInvEndDate' | 'EInvoiceStarted' | 'eInvClientID' | 'eInvSecretKey' | 'eInvSecretKey2'>
    & { DefaultCostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
    )>, GLCompanyPolicy?: Maybe<(
      { __typename?: 'GLCompanyPolicyEntity' }
      & Pick<GlCompanyPolicyEntity, 'DefaultDepartmentBalanceSheetCOA'>
    )> }
  )> }
);

export type GetCompanySubmenuQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanySubmenuQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'Logo' | 'EInvoiceStarted' | 'eInvIntegrated'>
  )> }
);

export type GetEInvoiceCompanyStartQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  DocDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetEInvoiceCompanyStartQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'eInvStartDate' | 'eInvEndDate' | 'eInvIntegrated' | 'EInvoiceStarted'>
  )> }
);

export type GetAssignedCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssignedCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAssignedCompanyByUser'>
);

export type GetActiveCompanyCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveCompanyCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActiveCompanyCount'>
);

export type CreateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID'>
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type CancelCompanyMutationVariables = Exact<{
  input: CompanyDeleteInput;
}>;


export type CancelCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCompany'>
);

export type DeleteCompanyMutationVariables = Exact<{
  input: CompanyDeleteInput;
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type DeleteCompanyProfileMutationVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DeleteCompanyProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompanyProfile'>
);

export type GetCheckingValidationCompanyQueryVariables = Exact<{
  CompanyImportInput: Array<CompanyImportInput> | CompanyImportInput;
}>;


export type GetCheckingValidationCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationCompany'>
);

export type CreateCompanyImportMutationVariables = Exact<{
  TemplateStr: Scalars['String'];
}>;


export type CreateCompanyImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCompanyImport'>
);

export type GetEInvoiceCompanyStartDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetEInvoiceCompanyStartDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEInvoiceCompanyStartDate'>
);

export type GetCompanyListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyListQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'Name' | 'CompanyID'>
  )> }
);

export type GetEntityRoleUserAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type GetEntityRoleUserAssignmentQuery = (
  { __typename?: 'Query' }
  & { getEntityRoleUserAssignment: Array<(
    { __typename?: 'AccRoleUserEntAssignmentEntity' }
    & Pick<AccRoleUserEntAssignmentEntity, 'userID' | 'roleID'>
  )> }
);

export type GetUsersRoleCompanyQueryVariables = Exact<{
  companyID: Scalars['String'];
  roleID: Scalars['String'];
}>;


export type GetUsersRoleCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsersRoleCompany'>
);

export type GetUserRoleListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRoleListingQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'name'>
  )>, getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name'>
  )> }
);

export type GetCompanyListForRoleAuthQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyListForRoleAuthQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'Name' | 'CompanyID'>
  )>, getEntityRoleUserAssignment: Array<(
    { __typename?: 'AccRoleUserEntAssignmentEntity' }
    & Pick<AccRoleUserEntAssignmentEntity, 'entityID' | 'userID' | 'roleID'>
  )> }
);

export type GetSubmitterNameQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetSubmitterNameQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'name'>
  ) }
);

export type GetCompanySummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanySummaryQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'EInvoiceStarted' | 'eInvIntegrated'>
  )> }
);

export type GetCompanyExecutiveSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCompanyExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CostCentreDate' | 'CostCentreDefinitionDate' | 'EntityCOADate' | 'AccountPeriodDate' | 'JournalTypeDate' | 'BudgetDate' | 'BankAccountDate' | 'CompanySumDate' | 'NumberingStructureGeneralLedgerDate' | 'NumberingStructureCashBookDate' | 'NumberingStructureARDate' | 'NumberingStructureAPDate' | 'NumberingStructureICDate' | 'ApprovalPolicyAssignmentDate' | 'CompanyAuthorizationDate' | 'OriginalBudgetApprovalPolicyAssignmentDate' | 'BudgetRevisionApprovalPolicyAssignmentDate' | 'RecurringJournalApprovalPolicyAssignmentDate' | 'JournalProcessingApprovalPolicyAssignmentDate' | 'AuditAdjustmentApprovalPolicyAssignmentDate' | 'BankTransferStandardApprovalFlowDate' | 'ReceiptStandardApprovalFlowDate' | 'PaymentStandardApprovalFlowDate'>
);

export type GetCostCentreQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreQuery = (
  { __typename?: 'Query' }
  & { getCostCentre: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'CompanyID' | 'ParentCostCentreID' | 'Level' | 'Code' | 'MasterCode' | 'IsLastNode' | 'IsDefault' | 'UsedDepartment'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'Name'>
    )>, FavoriteJournalProcessingItem?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingItemEntity' }
      & Pick<FavoriteJournalProcessingItemEntity, 'FavoriteJournalProcessingItemID'>
    )>>, JournalItem?: Maybe<Array<(
      { __typename?: 'JournalItemEntity' }
      & Pick<JournalItemEntity, 'JournalItemID'>
    )>>, BudgetItemCostCentre?: Maybe<Array<(
      { __typename?: 'BudgetItemCostCentreEntity' }
      & Pick<BudgetItemCostCentreEntity, 'BudgetItemCostCentreID'>
    )>>, JournalProcessingItem?: Maybe<Array<(
      { __typename?: 'JournalProcessingItemEntity' }
      & Pick<JournalProcessingItemEntity, 'JournalProcessingItemID'>
    )>> }
  )> }
);

export type GetCostCentreCodeQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreCodeQuery = (
  { __typename?: 'Query' }
  & { getCostCentre: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code' | 'MasterCode' | 'IsDefault'>
  )> }
);

export type GetCostCentreListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCostCentreListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCentreListing'>
);

export type CreateCostCentreMutationVariables = Exact<{
  input: CostCentreInput;
}>;


export type CreateCostCentreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCostCentre'>
);

export type UpdateCostCentreMutationVariables = Exact<{
  input: CostCentreInput;
}>;


export type UpdateCostCentreMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCentre: (
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID'>
  ) }
);

export type CancelCostCentreMutationVariables = Exact<{
  input: CostCentreDeleteInput;
}>;


export type CancelCostCentreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostCentre'>
);

export type DeleteCostCentreMutationVariables = Exact<{
  CostCentreID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type DeleteCostCentreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCentre'>
);

export type CreateCostCentreDefinitionAsgmtMutationVariables = Exact<{
  input: CostCentreDefinitionAsgmtInput;
}>;


export type CreateCostCentreDefinitionAsgmtMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCostCentreDefinitionAsgmt'>
);

export type GetCostCentreDefinitionAsgmtQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionAsgmtQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionAsgmt: Array<(
    { __typename?: 'CostCentreDefinitionAsgmtEntity' }
    & Pick<CostCentreDefinitionAsgmtEntity, 'CostCentreDefinitionAsgmtID' | 'CostCentreDefinitionHeaderID' | 'CompanyID'>
    & { CostCentreDefinitionHeader?: Maybe<(
      { __typename?: 'CostCentreDefinitionHeaderEntity' }
      & Pick<CostCentreDefinitionHeaderEntity, 'Title'>
      & { CostCentreDefinitionDetail?: Maybe<Array<(
        { __typename?: 'CostCentreDefinitionDetailEntity' }
        & Pick<CostCentreDefinitionDetailEntity, 'Name' | 'Level' | 'IsLastNode'>
      )>> }
    )> }
  )> }
);

export type GetCostCentreDefinitionDetailQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionDetailQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionDetail: Array<(
    { __typename?: 'CostCentreDefinitionDetailEntity' }
    & Pick<CostCentreDefinitionDetailEntity, 'CostCentreDefinitionDetailID' | 'ParentCostCentreDefinitionDetailID' | 'CostCentreDefinitionHeaderID' | 'Name' | 'Level'>
  )> }
);

export type GetNextLevelCcDefinitionQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
}>;


export type GetNextLevelCcDefinitionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNextLevelCCDefinition'>
);

export type GetParentCostCentreForTreeQueryVariables = Exact<{
  CostCentreArr?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetParentCostCentreForTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getParentCostCentreForTree'>
);

export type SetDefaultCostCentreMutationVariables = Exact<{
  CostCentreID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type SetDefaultCostCentreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultCostCentre'>
);

export type GetDepartmentListingByItemIdQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  BillItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
}>;


export type GetDepartmentListingByItemIdQuery = (
  { __typename?: 'Query' }
  & { getDepartmentListingByItemID: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code' | 'IsDefault'>
  )> }
);

export type GetDepartmentListingByCoaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
}>;


export type GetDepartmentListingByCoaQuery = (
  { __typename?: 'Query' }
  & { getDepartmentListingByCOA: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code' | 'IsDefault'>
  )> }
);

export type GetCostCentreCoaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  EntityCOAID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreCoaQuery = (
  { __typename?: 'Query' }
  & { getCostCentreCOA: Array<(
    { __typename?: 'CostCentreCOAEntity' }
    & Pick<CostCentreCoaEntity, 'createdTs' | 'createdBy' | 'modBy' | 'modTs' | 'MasterCOAID' | 'RecordStatus' | 'CostCentreID' | 'CostCentreCOAID' | 'AccountID'>
    & { CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'CompanyID' | 'ParentCostCentreID' | 'Level' | 'Code' | 'MasterCode' | 'IsLastNode' | 'IsDefault'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'Name'>
      )>, FavoriteJournalProcessingItem?: Maybe<Array<(
        { __typename?: 'FavoriteJournalProcessingItemEntity' }
        & Pick<FavoriteJournalProcessingItemEntity, 'FavoriteJournalProcessingItemID'>
      )>>, JournalItem?: Maybe<Array<(
        { __typename?: 'JournalItemEntity' }
        & Pick<JournalItemEntity, 'JournalItemID'>
      )>>, BudgetItemCostCentre?: Maybe<Array<(
        { __typename?: 'BudgetItemCostCentreEntity' }
        & Pick<BudgetItemCostCentreEntity, 'BudgetItemCostCentreID'>
      )>>, JournalProcessingItem?: Maybe<Array<(
        { __typename?: 'JournalProcessingItemEntity' }
        & Pick<JournalProcessingItemEntity, 'JournalProcessingItemID'>
      )>> }
    )> }
  )> }
);

export type GetCostCentreCoaCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCostCentreCoaCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCentreCOACount'>
);

export type AssignCostCentreCoaMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignCostCentreCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignCostCentreCOA'>
);

export type UnassignCostCentreCoaMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreCOAArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignCostCentreCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignCostCentreCOA'>
);

export type GetEntityCoaQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetEntityCoaQuery = (
  { __typename?: 'Query' }
  & { getEntityCOA: Array<(
    { __typename?: 'EntityCOAEntity' }
    & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID' | 'MasterCOAID' | 'RecordStatus'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level' | 'RecordStatus'>
    )> }
  )> }
);

export type GetEntityCoaRecordStatusQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetEntityCoaRecordStatusQuery = (
  { __typename?: 'Query' }
  & { getEntityCOA: Array<(
    { __typename?: 'EntityCOAEntity' }
    & Pick<EntityCoaEntity, 'MasterCOAID' | 'RecordStatus'>
  )> }
);

export type GetMasterCoaForFormQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Array<AccountType> | AccountType>;
}>;


export type GetMasterCoaForFormQuery = (
  { __typename?: 'Query' }
  & { getMasterCOAForForm: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'Name' | 'Code' | 'AccountType' | 'IsLastNode' | 'IsControl' | 'RecordStatus'>
  )> }
);

export type AccountCodeCheckingQueryVariables = Exact<{
  MasterCOAID?: Maybe<Scalars['String']>;
}>;


export type AccountCodeCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'accountCodeChecking'>
);

export type GetAssignedMasterCoaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
}>;


export type GetAssignedMasterCoaQuery = (
  { __typename?: 'Query' }
  & { getAssignedMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'IsLastNode' | 'Level'>
    & { CostCentreCOA?: Maybe<Array<(
      { __typename?: 'CostCentreCOAEntity' }
      & Pick<CostCentreCoaEntity, 'RecordStatus' | 'CostCentreCOAID' | 'CostCentreID' | 'MasterCOAID'>
    )>>, AuditAdjustmentItem?: Maybe<Array<(
      { __typename?: 'AuditAdjustmentItemEntity' }
      & Pick<AuditAdjustmentItemEntity, 'AuditAdjustmentItemID' | 'CompanyID'>
    )>>, FavoriteJournalProcessingItem?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingItemEntity' }
      & Pick<FavoriteJournalProcessingItemEntity, 'FavoriteJournalProcessingItemID'>
    )>>, JournalProcessingItem?: Maybe<Array<(
      { __typename?: 'JournalProcessingItemEntity' }
      & Pick<JournalProcessingItemEntity, 'JournalProcessingItemID' | 'CompanyID'>
    )>>, RecurringJournalItem?: Maybe<Array<(
      { __typename?: 'RecurringJournalItemEntity' }
      & Pick<RecurringJournalItemEntity, 'RecurringJournalItemID' | 'CompanyID'>
    )>>, BudgetItem?: Maybe<Array<(
      { __typename?: 'BudgetItemEntity' }
      & Pick<BudgetItemEntity, 'BudgetItemID' | 'CompanyID'>
    )>>, RoutineJournalItem?: Maybe<Array<(
      { __typename?: 'RoutineJournalItemEntity' }
      & Pick<RoutineJournalItemEntity, 'RoutineJournalItemID' | 'CompanyID'>
    )>> }
  )> }
);

export type GetAssignedMasterCoaCodeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
}>;


export type GetAssignedMasterCoaCodeQuery = (
  { __typename?: 'Query' }
  & { getAssignedMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'IsLastNode' | 'Level' | 'RecordStatus'>
    & { CostCentreCOA?: Maybe<Array<(
      { __typename?: 'CostCentreCOAEntity' }
      & Pick<CostCentreCoaEntity, 'RecordStatus' | 'CostCentreCOAID' | 'CostCentreID' | 'MasterCOAID'>
    )>> }
  )> }
);

export type GetAssignedMasterCoaForListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
}>;


export type GetAssignedMasterCoaForListingQuery = (
  { __typename?: 'Query' }
  & { getAssignedMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'IsLastNode' | 'Level' | 'RecordStatus'>
  )> }
);

export type GetAssignedMasterCoaListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
}>;


export type GetAssignedMasterCoaListingQuery = (
  { __typename?: 'Query' }
  & { getAssignedMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'IsLastNode' | 'Level' | 'RecordStatus'>
  )> }
);

export type CreateEntityCoaMutationVariables = Exact<{
  input: EntityCoaInput;
}>;


export type CreateEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & { createEntityCOA: (
    { __typename?: 'EntityCOAEntity' }
    & Pick<EntityCoaEntity, 'EntityCOAID'>
  ) }
);

export type AssignEntityCoaMutationVariables = Exact<{
  MasterCOAID: Scalars['String'];
  CompanyArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignEntityCOA'>
);

export type UpdateEntityCoaMutationVariables = Exact<{
  input: EntityCoaInput;
}>;


export type UpdateEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & { updateEntityCOA: (
    { __typename?: 'EntityCOAEntity' }
    & Pick<EntityCoaEntity, 'EntityCOAID'>
  ) }
);

export type CancelEntityCoaMutationVariables = Exact<{
  input: EntityCoaDeleteInput;
}>;


export type CancelEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelEntityCOA'>
);

export type DeleteEntityCoaMutationVariables = Exact<{
  input: EntityCoaDeleteInput;
}>;


export type DeleteEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEntityCOA'>
);

export type ActivateInactivateEntityCoaMutationVariables = Exact<{
  MasterCOAID: Scalars['String'];
  CompanyID: Scalars['String'];
  ActiveCOA: Scalars['Boolean'];
}>;


export type ActivateInactivateEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateInactivateEntityCOA'>
);

export type DeleteAssignedEntityCoaMutationVariables = Exact<{
  MasterCOAID: Scalars['String'];
  CompanyArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteAssignedEntityCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAssignedEntityCOA'>
);

export type GetEntityCoaFilteredQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  IsControl?: Maybe<Scalars['Boolean']>;
}>;


export type GetEntityCoaFilteredQuery = (
  { __typename?: 'Query' }
  & { getEntityCOAFiltered: Array<(
    { __typename?: 'EntityCOAEntity' }
    & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID' | 'MasterCOAID'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code' | 'AccountType' | 'IsLastNode' | 'IsControl' | 'MasterCOAID' | 'RecordStatus'>
    )> }
  )> }
);

export type GetAssignedEntityCoaQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAssignedEntityCoaQuery = (
  { __typename?: 'Query' }
  & { getEntityCOA: Array<(
    { __typename?: 'EntityCOAEntity' }
    & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID' | 'MasterCOAID' | 'IsUsed'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level'>
    )> }
  )> }
);

export type GetGlCompanyPolicyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetGlCompanyPolicyQuery = (
  { __typename?: 'Query' }
  & { getGLCompanyPolicy: Array<(
    { __typename?: 'GLCompanyPolicyEntity' }
    & Pick<GlCompanyPolicyEntity, 'GLCompanyPolicyID' | 'CompanyID' | 'DefaultDepartmentBalanceSheetCOA'>
  )> }
);

export type GetAllTransactionCheckingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetAllTransactionCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllTransactionChecking'>
);

export type UpdateGlCompanyPolicyMutationVariables = Exact<{
  GLCompanyPolicyInput: GlCompanyPolicyInput;
}>;


export type UpdateGlCompanyPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGLCompanyPolicy'>
);

export type GetJournalTypeQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetJournalTypeQuery = (
  { __typename?: 'Query' }
  & { getJournalType: Array<(
    { __typename?: 'JournalTypeEntity' }
    & Pick<JournalTypeEntity, 'JournalTypeID' | 'CompanyID' | 'JournalType' | 'Description' | 'IsSystem'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name'>
    )>, FavoriteJournalProcessing?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingEntity' }
      & Pick<FavoriteJournalProcessingEntity, 'FavoriteJournalProcessingID'>
    )>>, Journal?: Maybe<Array<(
      { __typename?: 'JournalEntity' }
      & Pick<JournalEntity, 'JournalID'>
    )>>, RecurringJournal?: Maybe<Array<(
      { __typename?: 'RecurringJournalEntity' }
      & Pick<RecurringJournalEntity, 'RecurringJournalID'>
    )>>, JournalProcessing?: Maybe<Array<(
      { __typename?: 'JournalProcessingEntity' }
      & Pick<JournalProcessingEntity, 'JournalProcessingID'>
    )>>, RoutineJournal?: Maybe<Array<(
      { __typename?: 'RoutineJournalEntity' }
      & Pick<RoutineJournalEntity, 'RoutineJournalID'>
    )>> }
  )> }
);

export type GetJournalTypeListingQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetJournalTypeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getJournalTypeCount'>
  & { getJournalTypePerm: Array<(
    { __typename?: 'JournalTypeEntity' }
    & Pick<JournalTypeEntity, 'JournalTypeID' | 'CompanyID' | 'JournalType' | 'Description' | 'IsSystem'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name'>
    )>, FavoriteJournalProcessing?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingEntity' }
      & Pick<FavoriteJournalProcessingEntity, 'FavoriteJournalProcessingID'>
    )>>, Journal?: Maybe<Array<(
      { __typename?: 'JournalEntity' }
      & Pick<JournalEntity, 'JournalID'>
    )>>, RecurringJournal?: Maybe<Array<(
      { __typename?: 'RecurringJournalEntity' }
      & Pick<RecurringJournalEntity, 'RecurringJournalID'>
    )>>, JournalProcessing?: Maybe<Array<(
      { __typename?: 'JournalProcessingEntity' }
      & Pick<JournalProcessingEntity, 'JournalProcessingID'>
    )>>, RoutineJournal?: Maybe<Array<(
      { __typename?: 'RoutineJournalEntity' }
      & Pick<RoutineJournalEntity, 'RoutineJournalID'>
    )>> }
  )> }
);

export type CreateJournalTypeMutationVariables = Exact<{
  input: JournalTypeInput;
}>;


export type CreateJournalTypeMutation = (
  { __typename?: 'Mutation' }
  & { createJournalType: (
    { __typename?: 'JournalTypeEntity' }
    & Pick<JournalTypeEntity, 'JournalTypeID'>
  ) }
);

export type UpdateJournalTypeMutationVariables = Exact<{
  input: JournalTypeInput;
}>;


export type UpdateJournalTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateJournalType: (
    { __typename?: 'JournalTypeEntity' }
    & Pick<JournalTypeEntity, 'JournalTypeID'>
  ) }
);

export type CancelJournalTypeMutationVariables = Exact<{
  input: JournalTypeDeleteInput;
}>;


export type CancelJournalTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelJournalType'>
);

export type DeleteJournalTypeMutationVariables = Exact<{
  input: JournalTypeDeleteInput;
}>;


export type DeleteJournalTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJournalType'>
);

export type GetDocNumQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocNumQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeaderWithPerm: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'SampleOutput' | 'IsAutoDocNo' | 'DocNumGenerated'>
    & { DocumentNumberDetail?: Maybe<Array<(
      { __typename?: 'DocumentNumberDetailEntity' }
      & Pick<DocumentNumberDetailEntity, 'DocumentNumberDetailID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
    )>> }
  )> }
);

export type GetDocNumByRefTableQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  RefTable: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetDocNumByRefTableQuery = (
  { __typename?: 'Query' }
  & { getDocNumByRefTable: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'SampleOutput' | 'IsAutoDocNo' | 'DocNumGenerated'>
    & { DocumentNumberDetail?: Maybe<Array<(
      { __typename?: 'DocumentNumberDetailEntity' }
      & Pick<DocumentNumberDetailEntity, 'DocumentNumberDetailID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
    )>> }
  )> }
);

export type GetDocNumTitleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocNumTitleQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'SampleOutput' | 'DocumentNumberHeaderID' | 'Type' | 'RefTable' | 'IsAutoDocNo' | 'DocNumGenerated'>
  )> }
);

export type UpdateDocumentNumberHeaderMutationVariables = Exact<{
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput> | DocumentNumberDetailInput;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
}>;


export type UpdateDocumentNumberHeaderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentNumberHeader'>
);

export type UpdateIsAutoDocNoMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  RefTable: Scalars['String'];
  IsAutoDocNo: Scalars['Boolean'];
  input: DocumentNumberHeaderInput;
}>;


export type UpdateIsAutoDocNoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIsAutoDocNo'>
);

export type GetRetainEarningAccountDefQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetRetainEarningAccountDefQuery = (
  { __typename?: 'Query' }
  & { getRetainEarningAccountDef: Array<(
    { __typename?: 'RetainEarningAccountDefEntity' }
    & Pick<RetainEarningAccountDefEntity, 'RetainEarningAccountDefID' | 'Code' | 'MasterCOAID'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type CreateRetainEarningAccountDefMutationVariables = Exact<{
  input: RetainEarningAccountDefInput;
}>;


export type CreateRetainEarningAccountDefMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRetainEarningAccountDef'>
);

export type GetMasterCoaAccountTypeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountType: AccountType;
}>;


export type GetMasterCoaAccountTypeQuery = (
  { __typename?: 'Query' }
  & { getMasterCOAAccountType: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'Name' | 'Code'>
  )> }
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'AttachmentID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type AttachmentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
}>;


export type AttachmentListingQuery = (
  { __typename?: 'Query' }
  & { attachmentListing?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'AttachmentID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type FetchShareAttachmentQueryVariables = Exact<{
  file: Scalars['Upload'];
  CompanyID: Scalars['String'];
  Prefix: Scalars['String'];
}>;


export type FetchShareAttachmentQuery = (
  { __typename?: 'Query' }
  & { fetchShareAttachment?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'AttachmentID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type GetShareAttachmentMutationVariables = Exact<{
  file: Scalars['Upload'];
  CompanyID: Scalars['String'];
}>;


export type GetShareAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { getShareAttachment?: Maybe<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description'>
  )> }
);

export type GetGeneralTinQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralTinQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGeneralTin'>
);

export type CheckInvalidTinNoQueryVariables = Exact<{
  tinNo: Scalars['String'];
  identityNo: Scalars['String'];
  identityType: Scalars['String'];
}>;


export type CheckInvalidTinNoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkInvalidTinNo'>
);

export type GetAuditAdjustmentQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AuditAdjustmentID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetAuditAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getAuditAdjustment: Array<(
    { __typename?: 'AuditAdjustmentEntity' }
    & Pick<AuditAdjustmentEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AuditAdjustmentID' | 'DocDate' | 'DocNo' | 'AdjYearOrPeriod' | 'CompanyID' | 'RefNo' | 'Description' | 'RecordStatus' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, AuditAdjustmentItem?: Maybe<Array<(
      { __typename?: 'AuditAdjustmentItemEntity' }
      & Pick<AuditAdjustmentItemEntity, 'createdTs' | 'modTs' | 'AuditAdjustmentItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'Remark' | 'DocAmt'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
        & { RoutineJournalItem?: Maybe<Array<(
          { __typename?: 'RoutineJournalItemEntity' }
          & Pick<RoutineJournalItemEntity, 'DocAmt'>
        )>> }
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'StatusRemarks' | 'createdTs' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type CreateAuditAdjustmentMutationVariables = Exact<{
  input: AuditAdjustmentInput;
  itemInput?: Maybe<Array<AuditAdjustmentItemInput> | AuditAdjustmentItemInput>;
}>;


export type CreateAuditAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { createAuditAdjustment: (
    { __typename?: 'AuditAdjustmentEntity' }
    & Pick<AuditAdjustmentEntity, 'AuditAdjustmentID'>
  ) }
);

export type UpdateAuditAdjustmentMutationVariables = Exact<{
  input: AuditAdjustmentInput;
  itemInput?: Maybe<Array<AuditAdjustmentItemInput> | AuditAdjustmentItemInput>;
}>;


export type UpdateAuditAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAuditAdjustment: (
    { __typename?: 'AuditAdjustmentEntity' }
    & Pick<AuditAdjustmentEntity, 'AuditAdjustmentID'>
  ) }
);

export type CancelAuditAdjustmentMutationVariables = Exact<{
  input: AuditAdjustmentDeleteInput;
}>;


export type CancelAuditAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelAuditAdjustment'>
);

export type DeleteAuditAdjustmentMutationVariables = Exact<{
  AuditAdjustmentID: Scalars['String'];
}>;


export type DeleteAuditAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAuditAdjustment'>
);

export type PostAuditAdjustmentMutationVariables = Exact<{
  AuditAdjustmentIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type PostAuditAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postAuditAdjustment'>
);

export type RejectAuditAdjustmentMutationVariables = Exact<{
  AuditAdjustmentIDs: Array<Scalars['String']> | Scalars['String'];
  input: AuditAdjustmentRejectInput;
}>;


export type RejectAuditAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectAuditAdjustment'>
);

export type GetAuditAdjustmentbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAuditAdjustmentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getAuditAdjustmentbyStatus: Array<(
    { __typename?: 'AuditAdjustmentEntity' }
    & Pick<AuditAdjustmentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AuditAdjustmentID' | 'DocDate' | 'DocNo' | 'AdjYearOrPeriod' | 'CompanyID' | 'RefNo' | 'RejectionDate' | 'RejectionRemark' | 'Description' | 'RecordStatus' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, AuditAdjustmentItem?: Maybe<Array<(
      { __typename?: 'AuditAdjustmentItemEntity' }
      & Pick<AuditAdjustmentItemEntity, 'createdTs' | 'modTs' | 'AuditAdjustmentItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'Remark' | 'DocAmt'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
        & { RoutineJournalItem?: Maybe<Array<(
          { __typename?: 'RoutineJournalItemEntity' }
          & Pick<RoutineJournalItemEntity, 'DocAmt'>
        )>> }
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'StatusRemarks' | 'createdTs' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetAuditAdjustmentbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetAuditAdjustmentbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getAuditAdjustmentbyStatusListing: (
    { __typename?: 'AuditAdjustmentReturnData' }
    & Pick<AuditAdjustmentReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AuditAdjustmentEntity' }
      & Pick<AuditAdjustmentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'AuditAdjustmentID' | 'DocDate' | 'DocNo' | 'AdjYearOrPeriod' | 'CompanyID' | 'RefNo' | 'RejectionDate' | 'RejectionRemark' | 'Description' | 'RecordStatus' | 'ApprovalStatus'>
      & { Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, AuditAdjustmentItem?: Maybe<Array<(
        { __typename?: 'AuditAdjustmentItemEntity' }
        & Pick<AuditAdjustmentItemEntity, 'createdTs' | 'modTs' | 'AuditAdjustmentItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'Remark' | 'DocAmt'>
        & { MasterCOA?: Maybe<(
          { __typename?: 'MasterCOAEntity' }
          & Pick<MasterCoaEntity, 'Name' | 'Code'>
          & { RoutineJournalItem?: Maybe<Array<(
            { __typename?: 'RoutineJournalItemEntity' }
            & Pick<RoutineJournalItemEntity, 'DocAmt'>
          )>> }
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'Name'>
        )> }
      )>>, WorkFlow?: Maybe<Array<(
        { __typename?: 'AccWorkFlowEntity' }
        & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
        & { SubmitterDetail?: Maybe<(
          { __typename?: 'UserEntity' }
          & Pick<UserEntity, 'ID' | 'name'>
        )>, WorkFlowStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowStepEntity' }
          & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
          & { RoleDetail?: Maybe<(
            { __typename?: 'AccRoleEntity' }
            & Pick<AccRoleEntity, 'ID' | 'name'>
          )>, WorkFlowApprovedStep?: Maybe<Array<(
            { __typename?: 'AccWorkFlowApprovedStepEntity' }
            & Pick<AccWorkFlowApprovedStepEntity, 'StatusRemarks' | 'createdTs' | 'WorkFlowApprovedStepID'>
            & { ApproverDetail?: Maybe<(
              { __typename?: 'UserEntity' }
              & Pick<UserEntity, 'ID' | 'name'>
            )> }
          )>> }
        )>> }
      )>> }
    )>> }
  ) }
);

export type GetAuditAdjustmentAdjDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AdjYearOrPeriod: Scalars['String'];
}>;


export type GetAuditAdjustmentAdjDateQuery = (
  { __typename?: 'Query' }
  & { getAuditAdjustmentAdjDate: (
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'EndDate'>
  ) }
);

export type AuditLogDetailsQueryVariables = Exact<{
  primaryKey: Scalars['String'];
  TableName: Scalars['String'];
  FieldID: Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type AuditLogDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AuditLogDetails'>
);

export type GetBudgetQueryVariables = Exact<{
  BudgetID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Fyear?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetBudgetQuery = (
  { __typename?: 'Query' }
  & { getBudget: Array<(
    { __typename?: 'BudgetEntity' }
    & Pick<BudgetEntity, 'createdBy' | 'createdTs' | 'AccountID' | 'RecordStatus' | 'ApprovalStatus' | 'BudgetID' | 'CompanyID' | 'Fyear' | 'Version' | 'Description' | 'IsDefault' | 'modTs'>
    & { BudgetItem?: Maybe<Array<(
      { __typename?: 'BudgetItemEntity' }
      & Pick<BudgetItemEntity, 'BudgetItemID' | 'BudgetID' | 'BudgetAmt' | 'MasterCOAID'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, BudgetItemCostCentre?: Maybe<Array<(
        { __typename?: 'BudgetItemCostCentreEntity' }
        & Pick<BudgetItemCostCentreEntity, 'BudgetItemCostCentreID' | 'BudgetItemID' | 'BudgetAmt' | 'CostCentreID'>
        & { CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'Name' | 'Code'>
        )>, BudgetItemPeriod?: Maybe<Array<(
          { __typename?: 'BudgetItemPeriodEntity' }
          & Pick<BudgetItemPeriodEntity, 'BudgetItemPeriodID' | 'BudgetItemCostCentreID' | 'AccountPeriodID' | 'BudgetAmt'>
          & { AccountPeriod?: Maybe<(
            { __typename?: 'AccountPeriodEntity' }
            & Pick<AccountPeriodEntity, 'FYear' | 'FPeriod'>
          )> }
        )>> }
      )>> }
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'StatusRemarks' | 'createdTs' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetBudgetItemQueryVariables = Exact<{
  BudgetItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetBudgetItemQuery = (
  { __typename?: 'Query' }
  & { getBudgetItem: Array<(
    { __typename?: 'BudgetItemEntity' }
    & Pick<BudgetItemEntity, 'BudgetItemID' | 'CompanyID' | 'BudgetID' | 'MasterCOAID' | 'BudgetAmt'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code'>
    )> }
  )> }
);

export type GetBudgetItemCostCentreQueryVariables = Exact<{
  BudgetItemCostCentreID?: Maybe<Scalars['String']>;
  BudgetItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetBudgetItemCostCentreQuery = (
  { __typename?: 'Query' }
  & { getBudgetItemCostCentre: Array<(
    { __typename?: 'BudgetItemCostCentreEntity' }
    & Pick<BudgetItemCostCentreEntity, 'BudgetItemCostCentreID' | 'AccountID' | 'BudgetItemID' | 'BudgetAmt' | 'CostCentreID'>
  )> }
);

export type CreateBudgetWithDetailMutationVariables = Exact<{
  BudgetInput: BudgetInput;
  BudgetItemInput: Array<BudgetItemInput> | BudgetItemInput;
  BudgetItemCostCentreInput?: Maybe<Array<BudgetItemCostCentreInput> | BudgetItemCostCentreInput>;
  BudgetItemPeriodInput?: Maybe<Array<BudgetItemPeriodInput> | BudgetItemPeriodInput>;
}>;


export type CreateBudgetWithDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBudgetWithDetail'>
);

export type UpdateBudgetWithDetailMutationVariables = Exact<{
  BudgetInput: BudgetInput;
  BudgetItemInput: Array<BudgetItemInput> | BudgetItemInput;
  BudgetItemCostCentreInput?: Maybe<Array<BudgetItemCostCentreInput> | BudgetItemCostCentreInput>;
  BudgetItemPeriodInput?: Maybe<Array<BudgetItemPeriodInput> | BudgetItemPeriodInput>;
}>;


export type UpdateBudgetWithDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBudgetWithDetail'>
);

export type UpdateBudgetMutationVariables = Exact<{
  input: BudgetInput;
}>;


export type UpdateBudgetMutation = (
  { __typename?: 'Mutation' }
  & { updateBudget: (
    { __typename?: 'BudgetEntity' }
    & Pick<BudgetEntity, 'BudgetID'>
  ) }
);

export type SetBudgetDefaultMutationVariables = Exact<{
  BudgetID: Scalars['String'];
  Year: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type SetBudgetDefaultMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setBudgetDefault'>
);

export type GetBudgetbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Fyear?: Maybe<Scalars['String']>;
}>;


export type GetBudgetbyStatusQuery = (
  { __typename?: 'Query' }
  & { getBudgetbyStatus: Array<(
    { __typename?: 'BudgetEntity' }
    & Pick<BudgetEntity, 'Fyear' | 'Version'>
  )> }
);

export type GetBudgetMasterCoaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetBudgetMasterCoaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBudgetMasterCOA'>
);

export type GetParentMasterCoaForTreeQueryVariables = Exact<{
  MasterCOABudgetArr?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID: Scalars['String'];
}>;


export type GetParentMasterCoaForTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getParentMasterCOAForTree'>
);

export type GetBudgetCostCentreQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetBudgetCostCentreQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBudgetCostCentre'>
);

export type GetBudgetImportListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PeriodBudgetItemInput?: Maybe<Array<PeriodBudgetItemInput> | PeriodBudgetItemInput>;
}>;


export type GetBudgetImportListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBudgetImportList'>
);

export type ImportExcelBudgetQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  BudgetexcelImportInput: Scalars['String'];
}>;


export type ImportExcelBudgetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ImportExcelBudget'>
);

export type GetExternalJournalQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  RefRecordID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetExternalJournalQuery = (
  { __typename?: 'Query' }
  & { getExternalJournal: Array<(
    { __typename?: 'ExternalJournalEntity' }
    & Pick<ExternalJournalEntity, 'ExternalJournalID' | 'JournalDate' | 'DocNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description' | 'DocRefName' | 'AccountName' | 'ApprovalStatus'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
    )>, ExternalJournalItem?: Maybe<Array<(
      { __typename?: 'ExternalJournalItemEntity' }
      & Pick<ExternalJournalItemEntity, 'MasterCOAID' | 'CostCentreID' | 'DocAmt' | 'TaxInvoiceNo' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code' | 'Level'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetExternalJournalListQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  RefRecordID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetExternalJournalListQuery = (
  { __typename?: 'Query' }
  & { getExternalJournal: Array<(
    { __typename?: 'ExternalJournalEntity' }
    & Pick<ExternalJournalEntity, 'ExternalJournalID' | 'JournalDate' | 'DocNo' | 'IsManual' | 'RefNo' | 'Description' | 'DocRefName' | 'AccountName' | 'ApprovalStatus' | 'JournalTypeID' | 'ItemCount' | 'ItemAmount'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
    )> }
  )> }
);

export type GetExternalJournalListingQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExternalJournalID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetExternalJournalListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExternalJournalCount'>
  & { getExternalJournal: Array<(
    { __typename?: 'ExternalJournalEntity' }
    & Pick<ExternalJournalEntity, 'ExternalJournalID' | 'JournalDate' | 'DocNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description' | 'DocRefName' | 'AccountName' | 'ApprovalStatus'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
    )>, ExternalJournalItem?: Maybe<Array<(
      { __typename?: 'ExternalJournalItemEntity' }
      & Pick<ExternalJournalItemEntity, 'MasterCOAID' | 'CostCentreID' | 'DocAmt' | 'TaxInvoiceNo' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code' | 'Level'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateExternalJournalMutationVariables = Exact<{
  ExtJournalInput: ExcelJournalInput;
  CompanyID: Scalars['String'];
  DocRefName: Scalars['String'];
}>;


export type CreateExternalJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createExternalJournal'>
);

export type GetFavoriteJournalProcessingQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteJournalProcessingID?: Maybe<Scalars['String']>;
}>;


export type GetFavoriteJournalProcessingQuery = (
  { __typename?: 'Query' }
  & { getFavoriteJournalProcessing: Array<(
    { __typename?: 'FavoriteJournalProcessingEntity' }
    & Pick<FavoriteJournalProcessingEntity, 'createdBy' | 'FavoriteJournalProcessingID' | 'Name' | 'JournalTypeID' | 'RefNo' | 'Description'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType'>
    )>, FavoriteJournalProcessingItem?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingItemEntity' }
      & Pick<FavoriteJournalProcessingItemEntity, 'FavoriteJournalProcessingID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'DocAmt' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateFavoriteJournalProcessingMutationVariables = Exact<{
  FavoriteJournalProcessingInput: FavoriteJournalProcessingInput;
  FavoriteJournalProcessingItemInput: Array<FavoriteJournalProcessingItemInput> | FavoriteJournalProcessingItemInput;
}>;


export type CreateFavoriteJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFavoriteJournalProcessing'>
);

export type DeleteFavoriteJournalProcessingMutationVariables = Exact<{
  FavoriteJournalProcessingID: Scalars['String'];
}>;


export type DeleteFavoriteJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFavoriteJournalProcessing'>
);

export type GetJournalExportDotNetQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetJournalExportDotNetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getJournalExportDotNet'>
);

export type GetGlExportJournalDetailQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  RefRecordID?: Maybe<Scalars['String']>;
}>;


export type GetGlExportJournalDetailQuery = (
  { __typename?: 'Query' }
  & { getJournal: Array<(
    { __typename?: 'JournalEntity' }
    & Pick<JournalEntity, 'JournalID' | 'createdTs' | 'JournalDate' | 'JournalNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description' | 'ExportDotNetID' | 'ExportDotNetError' | 'ApprovalStatus'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
    )>, JournalItem?: Maybe<Array<(
      { __typename?: 'JournalItemEntity' }
      & Pick<JournalItemEntity, 'createdTs' | 'modTs' | 'Remark' | 'MasterCOAID' | 'CostCentreID' | 'DocAmt' | 'TaxInvoiceNo'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code' | 'Level'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetGlExportDotNetQueryVariables = Exact<{
  ExportDotNetID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetGlExportDotNetQuery = (
  { __typename?: 'Query' }
  & { getGLExportDotNet: Array<(
    { __typename?: 'GLExportDotNetEntity' }
    & Pick<GlExportDotNetEntity, 'ExportDotNetID' | 'CompanyID' | 'StartDate' | 'EndDate' | 'ExportDate' | 'createdTs' | 'createdBy'>
    & { Journal: Array<(
      { __typename?: 'JournalEntity' }
      & Pick<JournalEntity, 'JournalID'>
    )> }
  )> }
);

export type GetGlExportDotNetDetailQueryVariables = Exact<{
  ExportDotNetID: Scalars['String'];
}>;


export type GetGlExportDotNetDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLExportDotNetDetail'>
);

export type ExportGlToDotNetMutationVariables = Exact<{
  input: GlExportDotNetInput;
  JournalIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ExportGlToDotNetMutation = (
  { __typename?: 'Mutation' }
  & { ExportGLToDotNet: (
    { __typename?: 'ExportDotNetReturn' }
    & Pick<ExportDotNetReturn, 'Success' | 'Fail' | 'GenerateExportDotNet'>
  ) }
);

export type GetGlSubmenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetGlSubmenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'journalProcessingCount' | 'recurringJournalCount' | 'auditAdjustmentCount' | 'RoutineJournalDate' | 'GenerateRJCount' | 'RecurringJournalDate' | 'JournalPending' | 'JournalProcessingDate' | 'AuditAdjustmentDate' | 'OriginalBudgetDate' | 'ExternalJournalDate' | 'GLDigitalReportDate'>
);

export type GetRecurringJournalGenerationQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetRecurringJournalGenerationQuery = (
  { __typename?: 'Query' }
  & { getRecurringJournalGeneration: Array<(
    { __typename?: 'RecurringJournalGenerationEntity' }
    & Pick<RecurringJournalGenerationEntity, 'RecurringJournalGenerationID' | 'FYear' | 'FPeriod' | 'Description' | 'RefNo' | 'IsGenerated' | 'JournalTypeID'>
    & { RecurringJournalGenerationItem?: Maybe<Array<(
      { __typename?: 'RecurringJournalGenerationItemEntity' }
      & Pick<RecurringJournalGenerationItemEntity, 'RecurringJournalGenerationItemID' | 'DocAmt'>
    )>>, JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalTypeID' | 'JournalType'>
    )> }
  )> }
);

export type GenerateRecurringJournalMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountPeriodID?: Maybe<Scalars['String']>;
}>;


export type GenerateRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateRecurringJournal'>
);

export type GetJournalQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  IsManual?: Maybe<Scalars['Boolean']>;
  RefRecordID?: Maybe<Scalars['String']>;
}>;


export type GetJournalQuery = (
  { __typename?: 'Query' }
  & { getJournal: Array<(
    { __typename?: 'JournalEntity' }
    & Pick<JournalEntity, 'JournalID' | 'createdTs' | 'JournalDate' | 'JournalNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description' | 'ApprovalStatus'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
    )>, JournalItem?: Maybe<Array<(
      { __typename?: 'JournalItemEntity' }
      & Pick<JournalItemEntity, 'createdTs' | 'modTs' | 'Remark' | 'MasterCOAID' | 'CostCentreID' | 'DocAmt' | 'TaxInvoiceNo'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code' | 'Level'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetJournalbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  TxnArr: Array<Scalars['String']> | Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetJournalbyStatusQuery = (
  { __typename?: 'Query' }
  & { getJournalbyStatus: Array<(
    { __typename?: 'JournalEntity' }
    & Pick<JournalEntity, 'JournalID' | 'createdTs' | 'JournalDate' | 'JournalNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description' | 'ApprovalStatus'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
    )>, JournalItem?: Maybe<Array<(
      { __typename?: 'JournalItemEntity' }
      & Pick<JournalItemEntity, 'createdTs' | 'modTs' | 'Remark' | 'MasterCOAID' | 'CostCentreID' | 'DocAmt' | 'TaxInvoiceNo'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code' | 'Level'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetJournalbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  TxnArr: Array<Scalars['String']> | Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetJournalbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getJournalbyStatusListing: (
    { __typename?: 'JournalReturnData' }
    & Pick<JournalReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'JournalEntity' }
      & Pick<JournalEntity, 'JournalID' | 'createdTs' | 'JournalDate' | 'JournalNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description' | 'ApprovalStatus'>
      & { JournalType?: Maybe<(
        { __typename?: 'JournalTypeEntity' }
        & Pick<JournalTypeEntity, 'JournalType' | 'Description'>
      )>, JournalItem?: Maybe<Array<(
        { __typename?: 'JournalItemEntity' }
        & Pick<JournalItemEntity, 'createdTs' | 'modTs' | 'Remark' | 'MasterCOAID' | 'CostCentreID' | 'DocAmt' | 'TaxInvoiceNo'>
        & { MasterCOA?: Maybe<(
          { __typename?: 'MasterCOAEntity' }
          & Pick<MasterCoaEntity, 'Name' | 'Code' | 'Level'>
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'Name'>
        )> }
      )>> }
    )>> }
  ) }
);

export type CreateJournalMutationVariables = Exact<{
  JournalInput: JournalInput;
  JournalItemInput: Array<JournalItemInput> | JournalItemInput;
}>;


export type CreateJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createJournal'>
);

export type UpdateJournalMutationVariables = Exact<{
  JournalInput: JournalInput;
  JournalItemInput: Array<JournalItemInput> | JournalItemInput;
}>;


export type UpdateJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateJournal'>
);

export type CancelJournalMutationVariables = Exact<{
  input: JournalDeleteInput;
}>;


export type CancelJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelJournal'>
);

export type DeleteJournalMutationVariables = Exact<{
  input: JournalDeleteInput;
}>;


export type DeleteJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJournal'>
);

export type GetJournalProcessingQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JournalProcessingID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetJournalProcessingQuery = (
  { __typename?: 'Query' }
  & { getJournalProcessing: Array<(
    { __typename?: 'JournalProcessingEntity' }
    & Pick<JournalProcessingEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'JournalProcessingID' | 'DocDate' | 'FYear' | 'FPeriod' | 'RefNo' | 'CompanyID' | 'JournalTypeID' | 'DocNo' | 'Description' | 'ApprovalStatus'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'StatusRemarks' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType'>
    )>, JournalProcessingItem?: Maybe<Array<(
      { __typename?: 'JournalProcessingItemEntity' }
      & Pick<JournalProcessingItemEntity, 'createdTs' | 'modTs' | 'JournalProcessingItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'DocAmt' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateJournalProcessingMutationVariables = Exact<{
  input: JournalProcessingInput;
  itemInput?: Maybe<Array<JournalProcessingItemInput> | JournalProcessingItemInput>;
}>;


export type CreateJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & { createJournalProcessing: (
    { __typename?: 'JournalProcessingEntity' }
    & Pick<JournalProcessingEntity, 'JournalProcessingID' | 'ApprovalStatus'>
  ) }
);

export type UpdateJournalProcessingMutationVariables = Exact<{
  input: JournalProcessingInput;
  itemInput?: Maybe<Array<JournalProcessingItemInput> | JournalProcessingItemInput>;
}>;


export type UpdateJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & { updateJournalProcessing: (
    { __typename?: 'JournalProcessingEntity' }
    & Pick<JournalProcessingEntity, 'JournalProcessingID' | 'ApprovalStatus'>
  ) }
);

export type DeleteJournalProcessingMutationVariables = Exact<{
  JournalProcessingID: Scalars['String'];
}>;


export type DeleteJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJournalProcessing'>
);

export type CancelJournalProcessingMutationVariables = Exact<{
  input: JournalProcessingDeleteInput;
}>;


export type CancelJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelJournalProcessing'>
);

export type GetJournalProcessingbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetJournalProcessingbyStatusQuery = (
  { __typename?: 'Query' }
  & { getJournalProcessingbyStatus: Array<(
    { __typename?: 'JournalProcessingEntity' }
    & Pick<JournalProcessingEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'JournalProcessingID' | 'DocDate' | 'FYear' | 'FPeriod' | 'RefNo' | 'CompanyID' | 'JournalTypeID' | 'DocNo' | 'Description' | 'ApprovalStatus' | 'DocNumGenerated' | 'RejectionDate' | 'RejectionRemark'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'StatusRemarks' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType'>
    )>, JournalProcessingItem?: Maybe<Array<(
      { __typename?: 'JournalProcessingItemEntity' }
      & Pick<JournalProcessingItemEntity, 'createdTs' | 'modTs' | 'JournalProcessingItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'DocAmt' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetJournalProcessingbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetJournalProcessingbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getJournalProcessingbyStatusListing: (
    { __typename?: 'JournalProcessingReturnData' }
    & Pick<JournalProcessingReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'JournalProcessingEntity' }
      & Pick<JournalProcessingEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'JournalProcessingID' | 'DocDate' | 'FYear' | 'FPeriod' | 'RefNo' | 'CompanyID' | 'JournalTypeID' | 'DocNo' | 'Description' | 'ApprovalStatus' | 'DocNumGenerated' | 'RejectionDate' | 'RejectionRemark'>
      & { Attachment?: Maybe<Array<(
        { __typename?: 'AttachmentInfo' }
        & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
      )>>, WorkFlow?: Maybe<Array<(
        { __typename?: 'AccWorkFlowEntity' }
        & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
        & { SubmitterDetail?: Maybe<(
          { __typename?: 'UserEntity' }
          & Pick<UserEntity, 'ID' | 'name'>
        )>, WorkFlowStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowStepEntity' }
          & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
          & { RoleDetail?: Maybe<(
            { __typename?: 'AccRoleEntity' }
            & Pick<AccRoleEntity, 'ID' | 'name'>
          )>, WorkFlowApprovedStep?: Maybe<Array<(
            { __typename?: 'AccWorkFlowApprovedStepEntity' }
            & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'StatusRemarks' | 'WorkFlowApprovedStepID'>
            & { ApproverDetail?: Maybe<(
              { __typename?: 'UserEntity' }
              & Pick<UserEntity, 'ID' | 'name'>
            )> }
          )>> }
        )>> }
      )>>, JournalType?: Maybe<(
        { __typename?: 'JournalTypeEntity' }
        & Pick<JournalTypeEntity, 'JournalType'>
      )>, JournalProcessingItem?: Maybe<Array<(
        { __typename?: 'JournalProcessingItemEntity' }
        & Pick<JournalProcessingItemEntity, 'createdTs' | 'modTs' | 'JournalProcessingItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'DocAmt' | 'Remark'>
        & { MasterCOA?: Maybe<(
          { __typename?: 'MasterCOAEntity' }
          & Pick<MasterCoaEntity, 'Name' | 'Code'>
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'Name' | 'Code'>
        )> }
      )>> }
    )>> }
  ) }
);

export type PostJournalProcessingMutationVariables = Exact<{
  JournalProcessingIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type PostJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postJournalProcessing'>
);

export type RejectJournalProcessingMutationVariables = Exact<{
  JournalProcessingIDs: Array<Scalars['String']> | Scalars['String'];
  input: JournalProcessingRejectInput;
}>;


export type RejectJournalProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectJournalProcessing'>
);

export type GetJournalReportingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetJournalReportingQuery = (
  { __typename?: 'Query' }
  & { getJournalReporting: Array<(
    { __typename?: 'JournalEntity' }
    & Pick<JournalEntity, 'JournalID' | 'createdTs' | 'JournalDate' | 'JournalNo' | 'IsManual' | 'CompanyID' | 'JournalTypeID' | 'RefNo' | 'Description'>
  )> }
);

export type LatestOpenPeriodCheckingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LatestOpenPeriodCheckingQuery = (
  { __typename?: 'Query' }
  & { latestOpenPeriodChecking?: Maybe<(
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'FYear' | 'FPeriod'>
  )> }
);

export type LatestOpenPeriodCheckingDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LatestOpenPeriodCheckingDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'latestOpenPeriodCheckingDate'>
);

export type LatestOpenYearCheckingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LatestOpenYearCheckingQuery = (
  { __typename?: 'Query' }
  & { latestOpenYearChecking: (
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'FYear' | 'FPeriod'>
  ) }
);

export type ProcessMonthEndClosingMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountPeriodID: Scalars['String'];
}>;


export type ProcessMonthEndClosingMutation = (
  { __typename?: 'Mutation' }
  & { processMonthEndClosing?: Maybe<(
    { __typename?: 'ProcessMonthYearEndOutput' }
    & Pick<ProcessMonthYearEndOutput, 'pendingJP' | 'pendingRJ' | 'pendingAA' | 'pendingGenrateRJ' | 'pendingAPAdv' | 'pendingAPInv' | 'pendingAPCN' | 'pendingAPDN' | 'pendingAPPayment' | 'pendingAPBatchPayment' | 'pendingAPRefund' | 'pendingARAdv' | 'pendingARInv' | 'pendingARCN' | 'pendingARDN' | 'pendingAROfficialReceipt' | 'pendingARRefund' | 'pendingCBPayment' | 'pendingCBReceipt' | 'pendingCBBankTransfer' | 'pendingStockReceipt' | 'pendingStockIssue' | 'pendingStockTransfer' | 'pendingStockAdjustment' | 'pendingStockCostAdjustment' | 'pendingCycleCount' | 'processStatus' | 'pendingGRN' | 'pendingGRTN'>
  )> }
);

export type RevertMonthEndClosingMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountPeriodID: Scalars['String'];
}>;


export type RevertMonthEndClosingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revertMonthEndClosing'>
);

export type RevertYearEndClosingMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountPeriodID: Scalars['String'];
}>;


export type RevertYearEndClosingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revertYearEndClosing'>
);

export type ProcessYearEndClosingMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
}>;


export type ProcessYearEndClosingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'processYearEndClosing'>
);

export type GetLastClosedPeriodQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLastClosedPeriodQuery = (
  { __typename?: 'Query' }
  & { getLastClosedPeriod?: Maybe<(
    { __typename?: 'AccountPeriodEntity' }
    & Pick<AccountPeriodEntity, 'AccountPeriodID' | 'FYear' | 'FPeriod' | 'YearEndClose' | 'MonthEndClose'>
  )> }
);

export type NeedYearEndCloseQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type NeedYearEndCloseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'needYearEndClose'>
);

export type MonthEndYearEndDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type MonthEndYearEndDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MonthEndYearEndDate'>
);

export type GetRecurringJournalQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  RecurringJournalID?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRecurringJournalQuery = (
  { __typename?: 'Query' }
  & { getRecurringJournal: Array<(
    { __typename?: 'RecurringJournalEntity' }
    & Pick<RecurringJournalEntity, 'createdBy' | 'createdTs' | 'modBy' | 'modTs' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'RecurringJournalID' | 'CompanyID' | 'Remark' | 'JournalID' | 'JournalTypeID' | 'RefNo' | 'DocNo' | 'Description' | 'StartYear' | 'StartPeriod' | 'Interval' | 'PostAt' | 'Specify' | 'NoOfRecurrance' | 'RecurringJournalStartDate' | 'TransactionDate' | 'ApprovalStatus'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType'>
    )>, RecurringJournalItem?: Maybe<Array<(
      { __typename?: 'RecurringJournalItemEntity' }
      & Pick<RecurringJournalItemEntity, 'RecurringJournalItemID' | 'MasterCOAID' | 'Sequence' | 'DocAmt' | 'CostCentreID' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )> }
    )>>, WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'StatusRemarks' | 'createdTs' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, GeneratedJournals?: Maybe<Array<(
      { __typename?: 'JournalEntity' }
      & Pick<JournalEntity, 'JournalID' | 'JournalDate' | 'createdTs' | 'CompanyID' | 'RefRecordID' | 'RefNo' | 'Description' | 'JournalNo'>
    )>> }
  )> }
);

export type CreateRecurringJournalMutationVariables = Exact<{
  input: RecurringJournalInput;
  itemInput?: Maybe<Array<RecurringJournalItemInput> | RecurringJournalItemInput>;
}>;


export type CreateRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRecurringJournal'>
);

export type UpdateRecurringJournalMutationVariables = Exact<{
  input: RecurringJournalInput;
  itemInput?: Maybe<Array<RecurringJournalItemInput> | RecurringJournalItemInput>;
}>;


export type UpdateRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRecurringJournal'>
);

export type CancelRecurringJournalMutationVariables = Exact<{
  RecurringJournalID: Scalars['String'];
}>;


export type CancelRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelRecurringJournal'>
);

export type DeleteRecurringJournalMutationVariables = Exact<{
  RecurringJournalID: Scalars['String'];
}>;


export type DeleteRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRecurringJournal'>
);

export type GetRecurringJournalbyStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetRecurringJournalbyStatusQuery = (
  { __typename?: 'Query' }
  & { getRecurringJournalbyStatus: Array<(
    { __typename?: 'RecurringJournalEntity' }
    & Pick<RecurringJournalEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'RecurringJournalID' | 'RefNo' | 'CompanyID' | 'JournalTypeID' | 'DocNo' | 'StartYear' | 'StartPeriod' | 'Interval' | 'PostAt' | 'Specify' | 'RecurringJournalStartDate' | 'NoOfRecurrance' | 'Description' | 'TransactionDate' | 'RejectionDate' | 'RejectionRemark' | 'ApprovalStatus'>
    & { WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'StatusRemarks' | 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>>, JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType'>
    )>, RecurringJournalItem?: Maybe<Array<(
      { __typename?: 'RecurringJournalItemEntity' }
      & Pick<RecurringJournalItemEntity, 'RecurringJournalItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'DocAmt' | 'Remark'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Code'>
      )> }
    )>>, GeneratedJournals?: Maybe<Array<(
      { __typename?: 'JournalEntity' }
      & Pick<JournalEntity, 'JournalID' | 'JournalDate' | 'createdTs' | 'CompanyID' | 'RefRecordID' | 'RefNo' | 'Description' | 'JournalNo'>
    )>> }
  )> }
);

export type GetRecurringJournalbyStatusListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetRecurringJournalbyStatusListingQuery = (
  { __typename?: 'Query' }
  & { getRecurringJournalbyStatusListing: (
    { __typename?: 'RecurringJournalReturnData' }
    & Pick<RecurringJournalReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'RecurringJournalEntity' }
      & Pick<RecurringJournalEntity, 'createdBy' | 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'RecurringJournalID' | 'RefNo' | 'CompanyID' | 'JournalTypeID' | 'DocNo' | 'StartYear' | 'StartPeriod' | 'Interval' | 'PostAt' | 'Specify' | 'RecurringJournalStartDate' | 'NoOfRecurrance' | 'Description' | 'TransactionDate' | 'RejectionDate' | 'RejectionRemark' | 'ApprovalStatus'>
      & { WorkFlow?: Maybe<Array<(
        { __typename?: 'AccWorkFlowEntity' }
        & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
        & { SubmitterDetail?: Maybe<(
          { __typename?: 'UserEntity' }
          & Pick<UserEntity, 'ID' | 'name'>
        )>, WorkFlowStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowStepEntity' }
          & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
          & { RoleDetail?: Maybe<(
            { __typename?: 'AccRoleEntity' }
            & Pick<AccRoleEntity, 'ID' | 'name'>
          )>, WorkFlowApprovedStep?: Maybe<Array<(
            { __typename?: 'AccWorkFlowApprovedStepEntity' }
            & Pick<AccWorkFlowApprovedStepEntity, 'createdTs' | 'StatusRemarks' | 'WorkFlowApprovedStepID'>
            & { ApproverDetail?: Maybe<(
              { __typename?: 'UserEntity' }
              & Pick<UserEntity, 'ID' | 'name'>
            )> }
          )>> }
        )>> }
      )>>, JournalType?: Maybe<(
        { __typename?: 'JournalTypeEntity' }
        & Pick<JournalTypeEntity, 'JournalType'>
      )>, RecurringJournalItem?: Maybe<Array<(
        { __typename?: 'RecurringJournalItemEntity' }
        & Pick<RecurringJournalItemEntity, 'RecurringJournalItemID' | 'MasterCOAID' | 'Sequence' | 'CostCentreID' | 'DocAmt' | 'Remark'>
        & { MasterCOA?: Maybe<(
          { __typename?: 'MasterCOAEntity' }
          & Pick<MasterCoaEntity, 'Name' | 'Code'>
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'Name' | 'Code'>
        )> }
      )>>, GeneratedJournals?: Maybe<Array<(
        { __typename?: 'JournalEntity' }
        & Pick<JournalEntity, 'JournalID' | 'JournalDate' | 'createdTs' | 'CompanyID' | 'RefRecordID' | 'RefNo' | 'Description' | 'JournalNo'>
      )>> }
    )>> }
  ) }
);

export type PostRecurringJournalMutationVariables = Exact<{
  RecurringJournalIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type PostRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postRecurringJournal'>
);

export type RejectRecurringJournalMutationVariables = Exact<{
  RecurringJournalIDs: Array<Scalars['String']> | Scalars['String'];
  input: RecurringJournalRejectInput;
}>;


export type RejectRecurringJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectRecurringJournal'>
);

export type GetRoutineJournalQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  RoutineJournalID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetRoutineJournalQuery = (
  { __typename?: 'Query' }
  & { getRoutineJournal: Array<(
    { __typename?: 'RoutineJournalEntity' }
    & Pick<RoutineJournalEntity, 'createdBy' | 'RoutineJournalID' | 'createdTs' | 'CompanyID' | 'JournalTypeID' | 'Remark' | 'RefNo' | 'Description'>
    & { JournalType?: Maybe<(
      { __typename?: 'JournalTypeEntity' }
      & Pick<JournalTypeEntity, 'JournalType'>
    )>, RoutineJournalItem?: Maybe<Array<(
      { __typename?: 'RoutineJournalItemEntity' }
      & Pick<RoutineJournalItemEntity, 'MasterCOAID' | 'DocAmt'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateRoutineJournalMutationVariables = Exact<{
  RoutineJournalInput: RoutineJournalInput;
  RoutineJournalItemInput: Array<RoutineJournalItemInput> | RoutineJournalItemInput;
}>;


export type CreateRoutineJournalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRoutineJournal'>
);

export type UpdateRoutineJournalsMutationVariables = Exact<{
  RoutineJournalInput: RoutineJournalInput;
  RoutineItemInput: Array<RoutineJournalItemInput> | RoutineJournalItemInput;
  CompanyID: Scalars['String'];
  RoutineJournalID: Scalars['String'];
}>;


export type UpdateRoutineJournalsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRoutineJournals'>
);

export type ApprovableCheckingQueryVariables = Exact<{
  RefID: Scalars['String'];
  CompanyID: Scalars['String'];
  UserID: Scalars['String'];
}>;


export type ApprovableCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'approvableChecking'>
);

export type GetApprovalPolicyQueryVariables = Exact<{
  Category: Scalars['String'];
}>;


export type GetApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicy: Array<(
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID' | 'createdTs' | 'modTs' | 'Category' | 'ApprovalList' | 'lowerLimit' | 'upperLimit'>
  )> }
);

export type GetAllApprovalPolicyQueryVariables = Exact<{
  Category: Scalars['String'];
}>;


export type GetAllApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getAllApprovalPolicy?: Maybe<Array<(
    { __typename?: 'ProcurementApprovalPolicyEntity' }
    & Pick<ProcurementApprovalPolicyEntity, 'ApprovalPolicyID' | 'createdTs' | 'modTs' | 'Category' | 'ApprovalList'>
  )>> }
);

export type CreateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type CreateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicy: (
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID'>
  ) }
);

export type UpdateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type UpdateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicy: (
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID'>
  ) }
);

export type DeleteApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyDeleteInput;
}>;


export type DeleteApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicy'>
);

export type CreateUpdateDeleteApprovalPolicyPoMutationVariables = Exact<{
  input: Array<ApprovalPolicyInput> | ApprovalPolicyInput;
  deleteInput?: Maybe<Array<ApprovalPolicyDeleteInput> | ApprovalPolicyDeleteInput>;
}>;


export type CreateUpdateDeleteApprovalPolicyPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateDeleteApprovalPolicyPO'>
);

export type GetBankProfileQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetBankProfileQuery = (
  { __typename?: 'Query' }
  & { getBankProfile: Array<(
    { __typename?: 'BankProfileEntity' }
    & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address' | 'ContactNo' | 'Email'>
    & { BankAccount?: Maybe<Array<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID'>
    )>> }
  )> }
);

export type GetBankProfileListingQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetBankProfileListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBankProfileCount'>
  & { getBankProfile: Array<(
    { __typename?: 'BankProfileEntity' }
    & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address' | 'ContactNo' | 'Email'>
    & { BankAccount?: Maybe<Array<(
      { __typename?: 'AccBankAccountEntity' }
      & Pick<AccBankAccountEntity, 'BankAccountID'>
    )>> }
  )> }
);

export type CreateBankProfileMutationVariables = Exact<{
  input: BankProfileInput;
}>;


export type CreateBankProfileMutation = (
  { __typename?: 'Mutation' }
  & { createBankProfile: (
    { __typename?: 'BankProfileEntity' }
    & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address' | 'ContactNo' | 'Email'>
  ) }
);

export type UpdateBankProfileMutationVariables = Exact<{
  input: BankProfileInput;
}>;


export type UpdateBankProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateBankProfile: (
    { __typename?: 'BankProfileEntity' }
    & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address' | 'ContactNo'>
  ) }
);

export type DeleteBankProfileMutationVariables = Exact<{
  input: BankProfileDeleteInput;
}>;


export type DeleteBankProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankProfile'>
);

export type GetCheckingValidationBpQueryVariables = Exact<{
  BPImportInput: Array<BpImportInput> | BpImportInput;
}>;


export type GetCheckingValidationBpQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationBP'>
);

export type CreateBankProfileImportMutationVariables = Exact<{
  TemplateStr: Scalars['String'];
}>;


export type CreateBankProfileImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBankProfileImport'>
);

export type GetCostCentreDefinitionHeaderQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionHeaderQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionHeader: Array<(
    { __typename?: 'CostCentreDefinitionHeaderEntity' }
    & Pick<CostCentreDefinitionHeaderEntity, 'CostCentreDefinitionHeaderID' | 'Title'>
    & { CostCentreDefConnection?: Maybe<Array<(
      { __typename?: 'CostCentreDefinitionAsgmtEntity' }
      & Pick<CostCentreDefinitionAsgmtEntity, 'CostCentreDefinitionAsgmtID' | 'CompanyID'>
    )>> }
  )> }
);

export type GetCostCentreDefinitionListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCostCentreDefinitionListingQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionListing: Array<(
    { __typename?: 'CostCentreDefinitionHeaderEntity' }
    & Pick<CostCentreDefinitionHeaderEntity, 'CostCentreDefinitionHeaderID' | 'Title'>
    & { CostCentreDefConnection?: Maybe<Array<(
      { __typename?: 'CostCentreDefinitionAsgmtEntity' }
      & Pick<CostCentreDefinitionAsgmtEntity, 'CostCentreDefinitionAsgmtID' | 'CompanyID'>
    )>> }
  )> }
);

export type GetCostCentreDefinitionQueryVariables = Exact<{
  CostCentreDefinitionHeaderID: Scalars['String'];
}>;


export type GetCostCentreDefinitionQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinition: (
    { __typename?: 'CostCentreDefSummary' }
    & { CostCentreDefinitionHeader?: Maybe<(
      { __typename?: 'CostCentreDefinitionHeaderEntity' }
      & Pick<CostCentreDefinitionHeaderEntity, 'CostCentreDefinitionHeaderID' | 'Title'>
    )>, CostCentreDefinitionDetail?: Maybe<Array<(
      { __typename?: 'CostCentreDefinitionDetailEntity' }
      & Pick<CostCentreDefinitionDetailEntity, 'CostCentreDefinitionDetailID' | 'ParentCostCentreDefinitionDetailID' | 'Name' | 'Level' | 'CostCentreDefinitionHeaderID'>
    )>> }
  ) }
);

export type CreateCostCentreDefinitionMutationVariables = Exact<{
  CostCentreDefinitionHeaderInput: CostCentreDefinitionHeaderInput;
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput> | CostCentreDefinitionDetailInput;
}>;


export type CreateCostCentreDefinitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCostCentreDefinition'>
);

export type CreateUpdateCostCentreDefinitionMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput> | CostCentreDefinitionDetailInput;
}>;


export type CreateUpdateCostCentreDefinitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateCostCentreDefinition'>
);

export type DeleteCostCentreDefinitionMutationVariables = Exact<{
  CostCentreDefinitionHeaderID: Scalars['String'];
}>;


export type DeleteCostCentreDefinitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCentreDefinition'>
);

export type UpdateCostCentreDefinitionHeaderMutationVariables = Exact<{
  input: CostCentreDefinitionHeaderInput;
}>;


export type UpdateCostCentreDefinitionHeaderMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCentreDefinitionHeader: (
    { __typename?: 'CostCentreDefinitionHeaderEntity' }
    & Pick<CostCentreDefinitionHeaderEntity, 'CostCentreDefinitionHeaderID'>
  ) }
);

export type CancelCostCentreDefinitionHeaderMutationVariables = Exact<{
  input: CostCentreDefinitionHeaderDeleteInput;
}>;


export type CancelCostCentreDefinitionHeaderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostCentreDefinitionHeader'>
);

export type UpdateCostCentreDefinitionDetailMutationVariables = Exact<{
  input: CostCentreDefinitionDetailInput;
}>;


export type UpdateCostCentreDefinitionDetailMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCentreDefinitionDetail: (
    { __typename?: 'CostCentreDefinitionDetailEntity' }
    & Pick<CostCentreDefinitionDetailEntity, 'CostCentreDefinitionDetailID'>
  ) }
);

export type CancelCostCentreDefinitionDetailMutationVariables = Exact<{
  input: CostCentreDefinitionDetailDeleteInput;
}>;


export type CancelCostCentreDefinitionDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostCentreDefinitionDetail'>
);

export type DeleteCostCentreDefinitionDetailMutationVariables = Exact<{
  input: CostCentreDefinitionDetailDeleteInput;
}>;


export type DeleteCostCentreDefinitionDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCentreDefinitionDetail'>
);

export type GetCostCentreTypeQueryVariables = Exact<{
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreTypeQuery = (
  { __typename?: 'Query' }
  & { getCostCentreType: Array<(
    { __typename?: 'CostCentreTypeEntity' }
    & Pick<CostCentreTypeEntity, 'CostCentreTypeID' | 'Name'>
  )> }
);

export type CreateCostCentreTypeMutationVariables = Exact<{
  input: CostCentreTypeInput;
}>;


export type CreateCostCentreTypeMutation = (
  { __typename?: 'Mutation' }
  & { createCostCentreType: (
    { __typename?: 'CostCentreTypeEntity' }
    & Pick<CostCentreTypeEntity, 'CostCentreTypeID' | 'Name'>
  ) }
);

export type UpdateCostCentreTypeMutationVariables = Exact<{
  input: CostCentreTypeInput;
}>;


export type UpdateCostCentreTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCentreType: (
    { __typename?: 'CostCentreTypeEntity' }
    & Pick<CostCentreTypeEntity, 'CostCentreTypeID'>
  ) }
);

export type DeleteCostCentreTypeMutationVariables = Exact<{
  input: CostCentreTypeDeleteInput;
}>;


export type DeleteCostCentreTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCentreType'>
);

export type CreateUpdateCostCentreTypeMutationVariables = Exact<{
  CostCentreTypeArr: Array<CostCentreTypeInput> | CostCentreTypeInput;
}>;


export type CreateUpdateCostCentreTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateCostCentreType'>
);

export type GetCreditCardTypeQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
}>;


export type GetCreditCardTypeQuery = (
  { __typename?: 'Query' }
  & { getCreditCardType: Array<(
    { __typename?: 'CreditCardTypeEntity' }
    & Pick<CreditCardTypeEntity, 'CreditCardID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    & { Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'PaymentID'>
    )>>, Receipt?: Maybe<Array<(
      { __typename?: 'ReceiptEntity' }
      & Pick<ReceiptEntity, 'ReceiptID'>
    )>>, FavoritePayment?: Maybe<Array<(
      { __typename?: 'FavoritePaymentEntity' }
      & Pick<FavoritePaymentEntity, 'FavoritePaymentID'>
    )>>, FavoriteReceipt?: Maybe<Array<(
      { __typename?: 'FavoriteReceiptEntity' }
      & Pick<FavoriteReceiptEntity, 'FavoriteReceiptID'>
    )>> }
  )> }
);

export type CreateCreditCardTypeMutationVariables = Exact<{
  CreditCardTypeInput: Array<CreditCardTypeInput> | CreditCardTypeInput;
}>;


export type CreateCreditCardTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCreditCardType'>
);

export type UpdateCreditCardTypeMutationVariables = Exact<{
  input: CreditCardTypeInput;
}>;


export type UpdateCreditCardTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditCardType: (
    { __typename?: 'CreditCardTypeEntity' }
    & Pick<CreditCardTypeEntity, 'CreditCardID'>
  ) }
);

export type DeleteCreditCardTypeMutationVariables = Exact<{
  input: CreditCardTypeDeleteInput;
}>;


export type DeleteCreditCardTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCreditCardType'>
);

export type GetCreditorAccountQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetCreditorAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'DocNoList' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'PayeeName' | 'SWIFTCode' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id' | 'einvoice_foreign_supplier' | 'einvoice_self_billed' | 'einvoice_send_individually' | 'einvoice_type' | 'einvoice_TIN' | 'IsCompany' | 'ClassificationCode' | 'MSICCode'>
    & { CreditorType?: Maybe<(
      { __typename?: 'CreditorTypeEntity' }
      & Pick<CreditorTypeEntity, 'Code' | 'Description'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'Description'>
    )>, CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )> }
);

export type GetCreditorAccountCompanyNameQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetCreditorAccountCompanyNameQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName'>
  )> }
);

export type GetCreditorAccountListingQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCreditorAccountListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCreditorAccountCount'>
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'DocNoList' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'PayeeName' | 'SWIFTCode' | 'CompanyName' | 'einvoice_foreign_supplier' | 'einvoice_self_billed' | 'einvoice_send_individually' | 'einvoice_msic_code_id' | 'einvoice_classification_code_id' | 'ClassificationCode' | 'MSICCode' | 'einvoice_type' | 'einvoice_TIN' | 'IsCompany' | 'modTs' | 'Address' | 'CreditorStatus'>
    & { CreditorType?: Maybe<(
      { __typename?: 'CreditorTypeEntity' }
      & Pick<CreditorTypeEntity, 'Code' | 'Description'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'Description'>
    )>, CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )> }
);

export type CreateCreditorAccountMutationVariables = Exact<{
  input: CreditorAccountInput;
  contactItem?: Maybe<Array<CreditorContactInput> | CreditorContactInput>;
  directorItem?: Maybe<Array<CreditorDirectorInput> | CreditorDirectorInput>;
}>;


export type CreateCreditorAccountMutation = (
  { __typename?: 'Mutation' }
  & { CreateCreditorAccount: (
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID'>
  ) }
);

export type UpdateCreditorAccountWithItemMutationVariables = Exact<{
  input: CreditorAccountInput;
  contactItem?: Maybe<Array<CreditorContactInput> | CreditorContactInput>;
  directorItem?: Maybe<Array<CreditorDirectorInput> | CreditorDirectorInput>;
}>;


export type UpdateCreditorAccountWithItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateCreditorAccountWithItem'>
);

export type UpdateCreditorAccountMutationVariables = Exact<{
  input: CreditorAccountInput;
}>;


export type UpdateCreditorAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorAccount: (
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID'>
  ) }
);

export type GetCreditorAccountCompanyAssignmentNameQueryVariables = Exact<{
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetCreditorAccountCompanyAssignmentNameQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccountCompanyAssignment: Array<(
    { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
    & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountID' | 'CompanyID'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditTerm' | 'CreditorAccountID' | 'CompanyName' | 'DocNoList' | 'TotalOutstandingAmt' | 'einvoice_self_billed' | 'einvoice_send_individually' | 'einvoice_classification_code_id' | 'einvoice_msic_code_id' | 'createdTs' | 'modTs'>
    )> }
  )> }
);

export type GetCreditorAccountCompanyAssignmentPermQueryVariables = Exact<{
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetCreditorAccountCompanyAssignmentPermQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCreditorAccountCompanyAssignmentCount'>
  & { getCreditorAccountCompanyAssignmentPerm: Array<(
    { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
    & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountCompanyAssignmentID' | 'CreditorAccountID' | 'CompanyID'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'createdTs' | 'modTs' | 'CreditorAccountID' | 'TotalOutstandingAmt' | 'CreditorTypeID' | 'CompanyRegNo' | 'DocNoList' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'TaxIdentificationNo' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'Address' | 'CreditorStatus'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
      )>>, CreditorDirector?: Maybe<Array<(
        { __typename?: 'CreditorDirectorEntity' }
        & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
      )>> }
    )> }
  )> }
);

export type GetCreditorAccountCompanyAssignmentQueryVariables = Exact<{
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetCreditorAccountCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCreditorAccountCompanyAssignmentCount'>
  & { getCreditorAccountCompanyAssignment: Array<(
    { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
    & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountCompanyAssignmentID' | 'CreditorAccountID' | 'CompanyID'>
    & { CreditorAccount?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'createdTs' | 'modTs' | 'CreditorAccountID' | 'TotalOutstandingAmt' | 'CreditorTypeID' | 'CompanyRegNo' | 'DocNoList' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'TaxIdentificationNo' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'Address' | 'CreditorStatus'>
      & { CreditorContact?: Maybe<Array<(
        { __typename?: 'CreditorContactEntity' }
        & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
      )>>, CreditorDirector?: Maybe<Array<(
        { __typename?: 'CreditorDirectorEntity' }
        & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
      )>> }
    )> }
  )> }
);

export type AssignCreditorAccountCompanyMutationVariables = Exact<{
  input: CreditorAccountCompanyAssignmentInput;
}>;


export type AssignCreditorAccountCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignCreditorAccountCompany'>
);

export type UnassignCreditorAccountCompanyMutationVariables = Exact<{
  input: CreditorAccountCompanyAssignmentInput;
}>;


export type UnassignCreditorAccountCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignCreditorAccountCompany'>
);

export type CreditorAccountTotalDueQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CreditorAccountTotalDueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditorAccountTotalDue'>
);

export type GetCheckingValidationCreditorProfileQueryVariables = Exact<{
  CreditorProfileImportInput: Array<CreditorProfileImportInput> | CreditorProfileImportInput;
}>;


export type GetCheckingValidationCreditorProfileQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationCreditorProfile'>
);

export type CreateCreditorProfileImportMutationVariables = Exact<{
  TemplateStr: Scalars['String'];
}>;


export type CreateCreditorProfileImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCreditorProfileImport'>
);

export type GetCreditorAccountCompanyAssignmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetCreditorAccountCompanyAssignmentListingQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccountCompanyAssignmentListing: (
    { __typename?: 'CreditorAccountCompanyAssignmentReturnData' }
    & Pick<CreditorAccountCompanyAssignmentReturnData, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
      & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountCompanyAssignmentID' | 'CreditorAccountID' | 'CompanyID'>
      & { CreditorAccount?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'createdTs' | 'modTs' | 'CreditorAccountID' | 'TotalOutstandingAmt' | 'CreditorTypeID' | 'CompanyRegNo' | 'DocNoList' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'Region' | 'GSTRegNo' | 'SSTRegNo' | 'Remark' | 'BankName' | 'TaxIdentificationNo' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'Address' | 'CreditorStatus'>
        & { CreditorContact?: Maybe<Array<(
          { __typename?: 'CreditorContactEntity' }
          & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
        )>>, CreditorDirector?: Maybe<Array<(
          { __typename?: 'CreditorDirectorEntity' }
          & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
        )>> }
      )> }
    )>> }
  ) }
);

export type GetCreditorTypeQueryVariables = Exact<{
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCreditorTypeQuery = (
  { __typename?: 'Query' }
  & { getCreditorType: Array<(
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID' | 'MasterCOAID' | 'AccrualCOAID' | 'AdvanceCOAID' | 'SelfBilledCOAID' | 'Description' | 'CreditorType' | 'Code'>
    & { CreditorAccount?: Maybe<Array<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'CompanyName' | 'ContactNo'>
    )>>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetCreditorTypeListingQueryVariables = Exact<{
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCreditorTypeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCreditorTypeCount'>
  & { getCreditorType: Array<(
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID' | 'MasterCOAID' | 'AccrualCOAID' | 'AdvanceCOAID' | 'SelfBilledCOAID' | 'Description' | 'CreditorType' | 'Code'>
    & { CreditorAccount?: Maybe<Array<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'CompanyName' | 'ContactNo'>
    )>>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type CreateCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type CreateCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & { createCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type UpdateCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type UpdateCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type DeleteCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeDeleteInput;
}>;


export type DeleteCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCreditorType'>
);

export type GetCurrencyQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetCurrencyQuery = (
  { __typename?: 'Query' }
  & { getCurrency: Array<(
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'ID' | 'code' | 'name'>
  )> }
);

export type CreateCurrencyMutationVariables = Exact<{
  input: CurrencyInput;
}>;


export type CreateCurrencyMutation = (
  { __typename?: 'Mutation' }
  & { createCurrency: (
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'ID'>
  ) }
);

export type UpdateCurrencyMutationVariables = Exact<{
  input: CurrencyInput;
}>;


export type UpdateCurrencyMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrency: (
    { __typename?: 'CurrencyEntity' }
    & Pick<CurrencyEntity, 'ID'>
  ) }
);

export type DeleteCurrencyMutationVariables = Exact<{
  input: CurrencyDeleteInput;
}>;


export type DeleteCurrencyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCurrency'>
);

export type GetCurrencyExchangeQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetCurrencyExchangeQuery = (
  { __typename?: 'Query' }
  & { getCurrencyExchange: Array<(
    { __typename?: 'AccCurrencyExchangeEntity' }
    & Pick<AccCurrencyExchangeEntity, 'CurrencyExchangeID' | 'FromCurrencyID' | 'ToCurrencyID' | 'FromAmt' | 'ToAmt'>
    & { FromCurrency?: Maybe<(
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'name' | 'code'>
    )>, ToCurrency?: Maybe<(
      { __typename?: 'CurrencyEntity' }
      & Pick<CurrencyEntity, 'name' | 'code'>
    )> }
  )> }
);

export type CreateCurrencyExchangeMutationVariables = Exact<{
  input: CurrencyExchangeInput;
}>;


export type CreateCurrencyExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCurrencyExchange'>
);

export type UpdateCurrencyExchangeMutationVariables = Exact<{
  input: CurrencyExchangeInput;
}>;


export type UpdateCurrencyExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCurrencyExchange'>
);

export type DeleteCurrencyExchangeMutationVariables = Exact<{
  CurrencyExchangeID: Scalars['String'];
}>;


export type DeleteCurrencyExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCurrencyExchange'>
);

export type GetCurrencyExchangeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrencyExchangeDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCurrencyExchangeData'>
);

export type GetDebtorAccountQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetDebtorAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDebtorAccountCount'>
  & { getDebtorAccount: Array<(
    { __typename?: 'DebtorAccountEntity' }
    & Pick<DebtorAccountEntity, 'CompanyID' | 'DebtorAccountID' | 'DebtorTypeID' | 'createdTs' | 'modTs' | 'TotalAmt' | 'TotalOSAmt' | 'DebtorName' | 'CompanyRegNo' | 'MobileNo' | 'OfficeNo' | 'Email' | 'CreditTerm' | 'IsVIP' | 'IsCompany' | 'TaxSchemeID' | 'GSTRegNo' | 'GSTStartDate' | 'SSTRegNo' | 'Remark' | 'Address' | 'einvoice_TIN' | 'einvoice_type' | 'einvoice_send_individually'>
    & { DebtorType?: Maybe<(
      { __typename?: 'DebtorTypeEntity' }
      & Pick<DebtorTypeEntity, 'Name'>
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'Name' | 'ContactNo' | 'Email' | 'Designation'>
    )>> }
  )> }
);

export type CreateDebtorAccountMutationVariables = Exact<{
  input: DebtorAccountInput;
  contactItem?: Maybe<Array<DebtorContactInput> | DebtorContactInput>;
}>;


export type CreateDebtorAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateDebtorAccount'>
);

export type UpdateDebtorAccountMutationVariables = Exact<{
  input: DebtorAccountInput;
}>;


export type UpdateDebtorAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateDebtorAccount: (
    { __typename?: 'DebtorAccountEntity' }
    & Pick<DebtorAccountEntity, 'DebtorAccountID'>
  ) }
);

export type UpdateDebtorAccountWithItemMutationVariables = Exact<{
  input: DebtorAccountInput;
  contactItem?: Maybe<Array<DebtorContactInput> | DebtorContactInput>;
}>;


export type UpdateDebtorAccountWithItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateDebtorAccountWithItem'>
);

export type GetCheckingValidationDebtorAccountQueryVariables = Exact<{
  DebtorAccountImportInput: Array<DebtorAccountImportInput> | DebtorAccountImportInput;
  CompanyID: Scalars['String'];
}>;


export type GetCheckingValidationDebtorAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationDebtorAccount'>
);

export type CreateDebtorAccountImportMutationVariables = Exact<{
  TemplateStr: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type CreateDebtorAccountImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDebtorAccountImport'>
);

export type GetDebtorTypeQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDebtorTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDebtorTypeCount'>
  & { getDebtorType: Array<(
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'DebtorTypeID' | 'Name' | 'MasterCOAID'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Code'>
    )>, DebtorAccount?: Maybe<Array<(
      { __typename?: 'DebtorAccountEntity' }
      & Pick<DebtorAccountEntity, 'DebtorAccountID' | 'DebtorTypeID' | 'CompanyRegNo' | 'DebtorName'>
    )>> }
  )> }
);

export type CreateDebtorTypeMutationVariables = Exact<{
  input: DebtorTypeInput;
}>;


export type CreateDebtorTypeMutation = (
  { __typename?: 'Mutation' }
  & { createDebtorType: (
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'DebtorTypeID'>
  ) }
);

export type UpdateDebtorTypeMutationVariables = Exact<{
  input: DebtorTypeInput;
}>;


export type UpdateDebtorTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateDebtorType: (
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'DebtorTypeID'>
  ) }
);

export type DeleteDebtorTypeMutationVariables = Exact<{
  input: DebtorTypeDeleteInput;
}>;


export type DeleteDebtorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDebtorType'>
);

export type GetDrawerPermissionQueryVariables = Exact<{
  pageMode?: Maybe<Scalars['String']>;
}>;


export type GetDrawerPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDrawerPermission'>
);

export type GetStandardLetterQueryVariables = Exact<{
  StandardLetterID?: Maybe<Scalars['String']>;
  LetterCategory?: Maybe<Scalars['String']>;
}>;


export type GetStandardLetterQuery = (
  { __typename?: 'Query' }
  & { getStandardLetter: Array<(
    { __typename?: 'StandardLetterEntity' }
    & Pick<StandardLetterEntity, 'StandardLetterID' | 'LetterType' | 'Name' | 'LetterCategory' | 'RecordStatus'>
    & { LetterTemplateSubscription?: Maybe<Array<(
      { __typename?: 'LetterTemplateEntity' }
      & Pick<LetterTemplateEntity, 'LetterTemplateID'>
    )>>, CompanyLetterTemplateSubscription?: Maybe<Array<(
      { __typename?: 'CompanyLetterTemplateEntity' }
      & Pick<CompanyLetterTemplateEntity, 'CompanyLetterTemplateID'>
    )>> }
  )> }
);

export type GetLetterTemplateQueryVariables = Exact<{
  LetterTemplateID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetLetterTemplateQuery = (
  { __typename?: 'Query' }
  & { getLetterTemplate: Array<(
    { __typename?: 'LetterTemplateEntity' }
    & Pick<LetterTemplateEntity, 'LetterTemplateID' | 'StandardLetterID' | 'Name' | 'JointBuyerSignRequired' | 'StandardLetterDataSourceVersion' | 'IsReportDesignEditor' | 'TemplateBody' | 'RecordStatus' | 'IsDefault'>
  )> }
);

export type CreateLetterTemplateMutationVariables = Exact<{
  input: LetterTemplateInput;
}>;


export type CreateLetterTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createLetterTemplate: (
    { __typename?: 'LetterTemplateEntity' }
    & Pick<LetterTemplateEntity, 'LetterTemplateID'>
  ) }
);

export type UpdateLetterTemplateMutationVariables = Exact<{
  input: LetterTemplateInput;
}>;


export type UpdateLetterTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLetterTemplate'>
);

export type DeleteLetterTemplateMutationVariables = Exact<{
  input: LetterTemplateDeleteInput;
}>;


export type DeleteLetterTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLetterTemplate'>
);

export type GetCompanyLetterTemplateQueryVariables = Exact<{
  CompanyLetterTemplateID?: Maybe<Scalars['String']>;
  StandardLetterID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCompanyLetterTemplateQuery = (
  { __typename?: 'Query' }
  & { getCompanyLetterTemplate: Array<(
    { __typename?: 'CompanyLetterTemplateEntity' }
    & Pick<CompanyLetterTemplateEntity, 'CompanyLetterTemplateID' | 'StandardLetterID' | 'Name' | 'JointBuyerSignRequired' | 'StandardLetterDataSourceVersion' | 'IsReportDesignEditor' | 'TemplateBody' | 'RecordStatus' | 'IsDefault' | 'LetterTemplateID'>
  )> }
);

export type CreateCompanyLetterTemplateMutationVariables = Exact<{
  input: CompanyLetterTemplateInput;
}>;


export type CreateCompanyLetterTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyLetterTemplate: (
    { __typename?: 'CompanyLetterTemplateEntity' }
    & Pick<CompanyLetterTemplateEntity, 'CompanyLetterTemplateID'>
  ) }
);

export type UpdateCompanyLetterTemplateMutationVariables = Exact<{
  input: CompanyLetterTemplateInput;
}>;


export type UpdateCompanyLetterTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompanyLetterTemplate'>
);

export type DeleteCompanyLetterTemplateMutationVariables = Exact<{
  input: CompanyLetterTemplateDeleteInput;
}>;


export type DeleteCompanyLetterTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompanyLetterTemplate'>
);

export type GetStandardLetterParameterQueryVariables = Exact<{
  StandardLetterID?: Maybe<Scalars['String']>;
}>;


export type GetStandardLetterParameterQuery = (
  { __typename?: 'Query' }
  & { getStandardLetterParameter: Array<(
    { __typename?: 'StandardLetterParameterEntity' }
    & Pick<StandardLetterParameterEntity, 'StandardLetterParameterID' | 'StandardLetterID' | 'DisplayName' | 'ColumnName' | 'RecordStatus'>
  )> }
);

export type GetLetterTemplateByModuleQueryVariables = Exact<{
  LetterCategory: ModuleType;
  LetterType: DocumentType;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetLetterTemplateByModuleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLetterTemplateByModule'>
);

export type GetStandardLetterByCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStandardLetterByCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStandardLetterByCategory'>
);

export type DocumentTemplateCountQueryVariables = Exact<{
  Module: Scalars['String'];
}>;


export type DocumentTemplateCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DocumentTemplateCount'>
);

export type CompanyDocumentTemplateCountQueryVariables = Exact<{
  Module: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type CompanyDocumentTemplateCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyDocumentTemplateCount'>
);

export type GetExpenseClassQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
}>;


export type GetExpenseClassQuery = (
  { __typename?: 'Query' }
  & { getExpenseClass: Array<(
    { __typename?: 'ExpenseClassEntity' }
    & Pick<ExpenseClassEntity, 'ExpenseClassID' | 'Name' | 'ParentExpenseClassID' | 'Level' | 'IsLastNode' | 'MasterCOAID'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
    )>, ExpenseItem?: Maybe<Array<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseClassID' | 'ExpenseItemID' | 'UOMID' | 'ItemName' | 'Description' | 'Remark' | 'TaxSchemeID' | 'TaxRate'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetExpenseClassPermQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
}>;


export type GetExpenseClassPermQuery = (
  { __typename?: 'Query' }
  & { getExpenseClassPerm: Array<(
    { __typename?: 'ExpenseClassEntity' }
    & Pick<ExpenseClassEntity, 'ExpenseClassID' | 'Name' | 'ParentExpenseClassID' | 'Level' | 'IsLastNode' | 'MasterCOAID'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
    )>, ExpenseItem?: Maybe<Array<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseClassID' | 'ExpenseItemID' | 'UOMID' | 'ItemName' | 'Description' | 'Remark' | 'TaxSchemeID' | 'TaxRate'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetExpenseClassCoaidQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
}>;


export type GetExpenseClassCoaidQuery = (
  { __typename?: 'Query' }
  & { getExpenseClass: Array<(
    { __typename?: 'ExpenseClassEntity' }
    & Pick<ExpenseClassEntity, 'ExpenseClassID' | 'MasterCOAID'>
  )> }
);

export type GetParentExpenseItemCategoryCoaAccountTypeQueryVariables = Exact<{
  ExpenseItemID?: Maybe<Scalars['String']>;
}>;


export type GetParentExpenseItemCategoryCoaAccountTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getParentExpenseItemCategoryCOAAccountType'>
);

export type GetExpenseClassDetailQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
}>;


export type GetExpenseClassDetailQuery = (
  { __typename?: 'Query' }
  & { getExpenseClass: Array<(
    { __typename?: 'ExpenseClassEntity' }
    & Pick<ExpenseClassEntity, 'ExpenseClassID' | 'Name' | 'ParentExpenseClassID' | 'Level' | 'IsLastNode' | 'MasterCOAID' | 'ParentClassMCOA'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
    )>, ExpenseItem?: Maybe<Array<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseClassID' | 'ExpenseItemID' | 'UOMID' | 'ItemName' | 'Description' | 'Remark' | 'TaxSchemeID' | 'TaxRate'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetItemClassCompanyAssignmentQueryVariables = Exact<{
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
}>;


export type GetItemClassCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getItemClassCompanyAssignment: Array<(
    { __typename?: 'ItemClassCompanyAssignmentEntity' }
    & Pick<ItemClassCompanyAssignmentEntity, 'ItemClassCompanyAssignmentID' | 'ExpenseClassID' | 'CompanyID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'RecordStatus'>
    & { ExpenseClass?: Maybe<(
      { __typename?: 'ExpenseClassEntity' }
      & Pick<ExpenseClassEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ExpenseClassID' | 'MasterCOAID' | 'RecordStatus' | 'Name'>
    )> }
  )> }
);

export type CreateExpenseClassMutationVariables = Exact<{
  input: ExpenseClassInput;
}>;


export type CreateExpenseClassMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createExpenseClass'>
);

export type UpdateExpenseClassMutationVariables = Exact<{
  input: ExpenseClassInput;
}>;


export type UpdateExpenseClassMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateExpenseClass'>
);

export type DeleteExpenseClassMutationVariables = Exact<{
  ExpenseClassID: Scalars['String'];
}>;


export type DeleteExpenseClassMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExpenseClass'>
);

export type AssignItemClassCompanyMutationVariables = Exact<{
  input: ItemClassCompanyAssignmentInput;
}>;


export type AssignItemClassCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignItemClassCompany'>
);

export type UnassignItemClassCompanyMutationVariables = Exact<{
  input: ItemClassCompanyAssignmentInput;
}>;


export type UnassignItemClassCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignItemClassCompany'>
);

export type GetItemQueryVariables = Exact<{
  ExpenseClassID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { getExpenseItem: Array<(
    { __typename?: 'ExpenseItemEntity' }
    & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ExpenseClassID' | 'IsUse' | 'IsInventory' | 'ItemName' | 'Description' | 'Remark' | 'TaxSchemeID' | 'TaxRate' | 'MasterCOAID' | 'UOMID' | 'RecordStatus'>
    & { ExpenseClass?: Maybe<(
      { __typename?: 'ExpenseClassEntity' }
      & Pick<ExpenseClassEntity, 'MasterCOAID' | 'ParentClassMCOA' | 'Name'>
      & { MasterCOA?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Name' | 'Code'>
      )> }
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'AccTaxCategory'>
      & { TaxEffective?: Maybe<Array<(
        { __typename?: 'TaxEffectiveDateEntity' }
        & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
      )>> }
    )>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Name' | 'Code'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
    )> }
  )> }
);

export type GetExpenseItemQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetExpenseItemQuery = (
  { __typename?: 'Query' }
  & { getItemByCompany: Array<(
    { __typename?: 'ExpenseItemEntity' }
    & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'Description' | 'ItemName' | 'ExpenseClassID' | 'TaxSchemeID' | 'UOMID' | 'MasterCOAID'>
  )> }
);

export type FetchExpenseItemQueryVariables = Exact<{
  ExpenseClassID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type FetchExpenseItemQuery = (
  { __typename?: 'Query' }
  & { getExpenseItem: Array<(
    { __typename?: 'ExpenseItemEntity' }
    & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'Description' | 'ItemName' | 'ExpenseClassID' | 'TaxSchemeID' | 'UOMID'>
    & { TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'AccTaxCategory'>
      & { TaxEffective?: Maybe<Array<(
        { __typename?: 'TaxEffectiveDateEntity' }
        & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
      )>> }
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
    )> }
  )> }
);

export type CreateExpenseItemMutationVariables = Exact<{
  input: ExpenseItemInput;
}>;


export type CreateExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { createExpenseItem: (
    { __typename?: 'ExpenseItemEntity' }
    & Pick<ExpenseItemEntity, 'ExpenseItemID'>
  ) }
);

export type UpdateExpenseItemMutationVariables = Exact<{
  input: ExpenseItemInput;
}>;


export type UpdateExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateExpenseItem'>
);

export type CancelExpenseItemMutationVariables = Exact<{
  input: ExpenseItemDeleteInput;
}>;


export type CancelExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelExpenseItem'>
);

export type DeleteExpenseItemMutationVariables = Exact<{
  ExpenseItemID: Scalars['String'];
}>;


export type DeleteExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExpenseItem'>
);

export type GetDataSourceSettingsQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetDataSourceSettingsQuery = (
  { __typename?: 'Query' }
  & { getDataSourceSettings: Array<(
    { __typename?: 'DataSourceSettingsEntity' }
    & Pick<DataSourceSettingsEntity, 'createdTs' | 'modTs' | 'DataSourceSettingsID' | 'Name' | 'Code' | 'Description' | 'ColumnSource' | 'ColumnValue' | 'YearType' | 'YearValue' | 'PeriodType' | 'PeriodValue' | 'Duration'>
  )> }
);

export type CreateDataSourceSettingsMutationVariables = Exact<{
  input: DataSourceSettingsInput;
}>;


export type CreateDataSourceSettingsMutation = (
  { __typename?: 'Mutation' }
  & { createDataSourceSettings: (
    { __typename?: 'DataSourceSettingsEntity' }
    & Pick<DataSourceSettingsEntity, 'DataSourceSettingsID'>
  ) }
);

export type UpdateDataSourceSettingsMutationVariables = Exact<{
  input: DataSourceSettingsInput;
}>;


export type UpdateDataSourceSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateDataSourceSettings: (
    { __typename?: 'DataSourceSettingsEntity' }
    & Pick<DataSourceSettingsEntity, 'DataSourceSettingsID'>
  ) }
);

export type CancelDataSourceSettingsMutationVariables = Exact<{
  input: DataSourceSettingsDeleteInput;
}>;


export type CancelDataSourceSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelDataSourceSettings'>
);

export type DeleteDataSourceSettingsMutationVariables = Exact<{
  input: DataSourceSettingsDeleteInput;
}>;


export type DeleteDataSourceSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDataSourceSettings'>
);

export type GetReportSettingsQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetReportSettingsQuery = (
  { __typename?: 'Query' }
  & { getReportSettings: Array<(
    { __typename?: 'ReportSettingsEntity' }
    & Pick<ReportSettingsEntity, 'createdTs' | 'ReportSettingsID' | 'AccountID' | 'Name' | 'Description' | 'FileName' | 'FileURL' | 'CellValues' | 'BucketFileName' | 'Opacity' | 'IsPrivate'>
  )> }
);

export type CreateReportSettingsMutationVariables = Exact<{
  input: ReportSettingsInput;
}>;


export type CreateReportSettingsMutation = (
  { __typename?: 'Mutation' }
  & { createReportSettings: (
    { __typename?: 'ReportSettingsEntity' }
    & Pick<ReportSettingsEntity, 'ReportSettingsID'>
  ) }
);

export type UpdateReportSettingsMutationVariables = Exact<{
  input: ReportSettingsInput;
}>;


export type UpdateReportSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateReportSettings: (
    { __typename?: 'ReportSettingsEntity' }
    & Pick<ReportSettingsEntity, 'ReportSettingsID'>
  ) }
);

export type CancelReportSettingsMutationVariables = Exact<{
  input: ReportSettingsDeleteInput;
}>;


export type CancelReportSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelReportSettings'>
);

export type DeleteReportSettingsMutationVariables = Exact<{
  input: ReportSettingsDeleteInput;
}>;


export type DeleteReportSettingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReportSettings'>
);

export type DesignReportQueryVariables = Exact<{
  ReportSettingsID: Scalars['String'];
}>;


export type DesignReportQuery = (
  { __typename?: 'Query' }
  & { DesignReport?: Maybe<(
    { __typename?: 'PrintReportOutput' }
    & Pick<PrintReportOutput, 'file_name' | 'file'>
  )> }
);

export type UploadReportTemplateMutationVariables = Exact<{
  ReportSettingsID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadReportTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadReportTemplate'>
);

export type PrintReportQueryVariables = Exact<{
  ReportSettingsID: Scalars['String'];
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  FilterZero: Scalars['Boolean'];
}>;


export type PrintReportQuery = (
  { __typename?: 'Query' }
  & { printReport?: Maybe<(
    { __typename?: 'PrintReportOutput' }
    & Pick<PrintReportOutput, 'file_name' | 'file'>
  )> }
);

export type GetGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeSupplierCategoryCount' | 'activeSupplierProfileCount' | 'activeUomCount' | 'lastUomExchange' | 'activePurchaseCategoryCount' | 'activeItemCount' | 'activeBulkPurchaseCount' | 'lastPurchaseVariance' | 'purchaseVariancePercenetage'>
);

export type GetInventoryPolicyQueryVariables = Exact<{
  InventoryPolicyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetInventoryPolicyQuery = (
  { __typename?: 'Query' }
  & { getInventoryPolicy: Array<(
    { __typename?: 'InventoryPolicyEntity' }
    & Pick<InventoryPolicyEntity, 'InventoryPolicyID' | 'AccountID' | 'StockCostingType' | 'PaymentReminder1' | 'PaymentReminder2' | 'PaymentReminder3' | 'PaymentReminder4' | 'PaymentReminder5' | 'PaymentReminder6'>
  )> }
);

export type CreateInventoryPolicyMutationVariables = Exact<{
  input: InventoryPolicyInput;
}>;


export type CreateInventoryPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createInventoryPolicy: (
    { __typename?: 'InventoryPolicyEntity' }
    & Pick<InventoryPolicyEntity, 'InventoryPolicyID'>
  ) }
);

export type UpdateInventoryPolicyMutationVariables = Exact<{
  input: InventoryPolicyInput;
}>;


export type UpdateInventoryPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateInventoryPolicy: (
    { __typename?: 'InventoryPolicyEntity' }
    & Pick<InventoryPolicyEntity, 'InventoryPolicyID'>
  ) }
);

export type GetParentMasterCoaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParentMasterCoaQuery = (
  { __typename?: 'Query' }
  & { getParentMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType'>
  )> }
);

export type GetMasterCoaListingQueryVariables = Exact<{
  AccountType: Scalars['String'];
}>;


export type GetMasterCoaListingQuery = (
  { __typename?: 'Query' }
  & { getMasterCOAListing: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'IsLastNode' | 'Level' | 'AccountType'>
    & { EntityCOA?: Maybe<Array<(
      { __typename?: 'EntityCOAEntity' }
      & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID'>
    )>>, AuditAdjustmentItem?: Maybe<Array<(
      { __typename?: 'AuditAdjustmentItemEntity' }
      & Pick<AuditAdjustmentItemEntity, 'AuditAdjustmentItemID'>
    )>>, FavoriteJournalProcessingItem?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingItemEntity' }
      & Pick<FavoriteJournalProcessingItemEntity, 'FavoriteJournalProcessingItemID'>
    )>>, JournalItem?: Maybe<Array<(
      { __typename?: 'JournalItemEntity' }
      & Pick<JournalItemEntity, 'JournalItemID'>
    )>>, RecurringJournalItem?: Maybe<Array<(
      { __typename?: 'RecurringJournalItemEntity' }
      & Pick<RecurringJournalItemEntity, 'RecurringJournalItemID'>
    )>>, BudgetItem?: Maybe<Array<(
      { __typename?: 'BudgetItemEntity' }
      & Pick<BudgetItemEntity, 'BudgetItemID'>
    )>>, RoutineJournalItem?: Maybe<Array<(
      { __typename?: 'RoutineJournalItemEntity' }
      & Pick<RoutineJournalItemEntity, 'RoutineJournalItemID'>
    )>> }
  )> }
);

export type GetMasterCoaQueryVariables = Exact<{
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level' | 'RecordStatus'>
    & { EntityCOA?: Maybe<Array<(
      { __typename?: 'EntityCOAEntity' }
      & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID'>
    )>>, AuditAdjustmentItem?: Maybe<Array<(
      { __typename?: 'AuditAdjustmentItemEntity' }
      & Pick<AuditAdjustmentItemEntity, 'AuditAdjustmentItemID'>
    )>>, FavoriteJournalProcessingItem?: Maybe<Array<(
      { __typename?: 'FavoriteJournalProcessingItemEntity' }
      & Pick<FavoriteJournalProcessingItemEntity, 'FavoriteJournalProcessingItemID'>
    )>>, JournalItem?: Maybe<Array<(
      { __typename?: 'JournalItemEntity' }
      & Pick<JournalItemEntity, 'JournalItemID'>
    )>>, RecurringJournalItem?: Maybe<Array<(
      { __typename?: 'RecurringJournalItemEntity' }
      & Pick<RecurringJournalItemEntity, 'RecurringJournalItemID'>
    )>>, BudgetItem?: Maybe<Array<(
      { __typename?: 'BudgetItemEntity' }
      & Pick<BudgetItemEntity, 'BudgetItemID'>
    )>>, RoutineJournalItem?: Maybe<Array<(
      { __typename?: 'RoutineJournalItemEntity' }
      & Pick<RoutineJournalItemEntity, 'RoutineJournalItemID'>
    )>> }
  )> }
);

export type GetMasterCoaCheckingQueryVariables = Exact<{
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaCheckingQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'AccountType'>
  )> }
);

export type GetMasterCoaCodeQueryVariables = Exact<{
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaCodeQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'Name' | 'Code' | 'AccountType' | 'IsLastNode' | 'IsControl' | 'RecordStatus'>
  )> }
);

export type CreateMasterCoaMutationVariables = Exact<{
  input: Array<MasterCoaInput> | MasterCoaInput;
}>;


export type CreateMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMasterCOA'>
);

export type UpdateMasterCoaMutationVariables = Exact<{
  input: MasterCoaInput;
}>;


export type UpdateMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & { updateMasterCOA: (
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID'>
  ) }
);

export type CancelMasterCoaMutationVariables = Exact<{
  input: MasterCoaDeleteInput;
}>;


export type CancelMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelMasterCOA'>
);

export type DeleteMasterCoaMutationVariables = Exact<{
  MasterCOAID: Scalars['String'];
}>;


export type DeleteMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMasterCOA'>
);

export type InactiveMasterCoaMutationVariables = Exact<{
  MasterCOAID: Scalars['String'];
}>;


export type InactiveMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inactiveMasterCOA'>
);

export type ActivateMasterCoaMutationVariables = Exact<{
  MasterCOAID: Scalars['String'];
}>;


export type ActivateMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateMasterCOA'>
);

export type GetCheckingValidationCoaQueryVariables = Exact<{
  MasterCOAInput: Array<MasterCoaInput> | MasterCoaInput;
}>;


export type GetCheckingValidationCoaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationCOA'>
);

export type GetCheckingValidationExtJournalQueryVariables = Exact<{
  ExternalJournalImportInput: Array<ExternalJournalImportInput> | ExternalJournalImportInput;
  CompanyID: Scalars['String'];
}>;


export type GetCheckingValidationExtJournalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationExtJournal'>
);

export type CreateExcelMasterCoaMutationVariables = Exact<{
  MasterCOAExcelInput: Array<MasterCoaInput> | MasterCoaInput;
}>;


export type CreateExcelMasterCoaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createExcelMasterCOA'>
);

export type GetFirstMasterCoaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstMasterCoaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFirstMasterCOA'>
);

export type GetMasterCoaFilteredQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['Boolean']>;
}>;


export type GetMasterCoaFilteredQuery = (
  { __typename?: 'Query' }
  & { getMasterCOAFiltered: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'AccountType' | 'Code' | 'Name' | 'IsLastNode' | 'RecordStatus'>
  )> }
);

export type GetPaymentMethodNameQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentMethodNameQuery = (
  { __typename?: 'Query' }
  & { getPaymentMethod: Array<(
    { __typename?: 'PaymentMethodEntity' }
    & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name'>
  )> }
);

export type GetPaymentMethodQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
}>;


export type GetPaymentMethodQuery = (
  { __typename?: 'Query' }
  & { getPaymentMethod: Array<(
    { __typename?: 'PaymentMethodEntity' }
    & Pick<PaymentMethodEntity, 'PaymentMethodID' | 'Name' | 'Description' | 'Sequence' | 'IsSystem'>
    & { Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'PaymentID'>
    )>>, Receipt?: Maybe<Array<(
      { __typename?: 'ReceiptEntity' }
      & Pick<ReceiptEntity, 'ReceiptID'>
    )>>, FavoritePayment?: Maybe<Array<(
      { __typename?: 'FavoritePaymentEntity' }
      & Pick<FavoritePaymentEntity, 'FavoritePaymentID'>
    )>>, FavoriteReceipt?: Maybe<Array<(
      { __typename?: 'FavoriteReceiptEntity' }
      & Pick<FavoriteReceiptEntity, 'FavoriteReceiptID'>
    )>> }
  )> }
);

export type CreatePaymentMethodMutationVariables = Exact<{
  PaymentMethodInput: Array<PaymentMethodInput> | PaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPaymentMethod'>
);

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: PaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentMethod: (
    { __typename?: 'PaymentMethodEntity' }
    & Pick<PaymentMethodEntity, 'PaymentMethodID'>
  ) }
);

export type DeletePaymentMethodMutationVariables = Exact<{
  input: PaymentMethodDeleteInput;
}>;


export type DeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePaymentMethod'>
);

export type GetDocumentDateValidationQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  DocumentDateValidationID?: Maybe<Scalars['String']>;
}>;


export type GetDocumentDateValidationQuery = (
  { __typename?: 'Query' }
  & { getDocumentDateValidation: Array<(
    { __typename?: 'DocumentDateValidationEntity' }
    & Pick<DocumentDateValidationEntity, 'DocumentDateValidationID' | 'MonthsBefore' | 'MonthsAfter'>
  )> }
);

export type CreateDocumentDateValidationMutationVariables = Exact<{
  DocumentDateValidationInput: Array<DocumentDateValidationInput> | DocumentDateValidationInput;
}>;


export type CreateDocumentDateValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDocumentDateValidation'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<AcctPermission> | AcctPermission;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type UpdateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<AcctPermission> | AcctPermission;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'name'>
    & { rolePerm: Array<(
      { __typename?: 'AccRolePermissionAssignmentEntity' }
      & Pick<AccRolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )>, userList?: Maybe<Array<(
      { __typename?: 'UserEntity' }
      & Pick<UserEntity, 'name' | 'ID'>
      & { companyAssigned?: Maybe<Array<(
        { __typename?: 'CompanyInfo' }
        & Pick<CompanyInfo, 'Name' | 'CompanyID'>
      )>> }
    )>> }
  )> }
);

export type GetRoleNameQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleNameQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'name'>
    & { userList?: Maybe<Array<(
      { __typename?: 'UserEntity' }
      & Pick<UserEntity, 'name' | 'ID'>
    )>> }
  )> }
);

export type GetRoleListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetRoleListingQuery = (
  { __typename?: 'Query' }
  & { getRoleListing: (
    { __typename?: 'RoleReturnType' }
    & Pick<RoleReturnType, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'AccRoleEntity' }
      & Pick<AccRoleEntity, 'ID' | 'name'>
      & { rolePerm: Array<(
        { __typename?: 'AccRolePermissionAssignmentEntity' }
        & Pick<AccRolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
      )>, userList?: Maybe<Array<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'name' | 'ID'>
      )>> }
    )>> }
  ) }
);

export type GetRolePermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolePermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermission'>
);

export type CreateListEntityRoleUserMutationVariables = Exact<{
  companyID: Scalars['String'];
  roleUserIDs: Array<AccRoleUserInput> | AccRoleUserInput;
}>;


export type CreateListEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateListEntityRoleUser'>
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type DuplicateRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
  name: Scalars['String'];
}>;


export type DuplicateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DuplicateRolePermission'>
);

export type GetStockAdjustmentReasonQueryVariables = Exact<{
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetStockAdjustmentReasonQuery = (
  { __typename?: 'Query' }
  & { getStockAdjustmentReason: Array<(
    { __typename?: 'StockAdjustmentReasonEntity' }
    & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason' | 'modTs' | 'createdTs' | 'IsSystem' | 'IsDefault'>
  )> }
);

export type GetStockAdjustmentReasonListingQueryVariables = Exact<{
  StockAdjustmentReasonID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetStockAdjustmentReasonListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockAdjustmentReasonCount'>
  & { getStockAdjustmentReasonPerm: Array<(
    { __typename?: 'StockAdjustmentReasonEntity' }
    & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason' | 'modTs' | 'createdTs' | 'IsSystem' | 'IsDefault'>
  )> }
);

export type CreateStockAdjustmentReasonMutationVariables = Exact<{
  input: StockAdjustmentReasonInput;
}>;


export type CreateStockAdjustmentReasonMutation = (
  { __typename?: 'Mutation' }
  & { createStockAdjustmentReason: (
    { __typename?: 'StockAdjustmentReasonEntity' }
    & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID'>
  ) }
);

export type UpdateStockAdjustmentReasonMutationVariables = Exact<{
  input: StockAdjustmentReasonInput;
}>;


export type UpdateStockAdjustmentReasonMutation = (
  { __typename?: 'Mutation' }
  & { updateStockAdjustmentReason: (
    { __typename?: 'StockAdjustmentReasonEntity' }
    & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
  ) }
);

export type UpdateDefaultSaReasonMutationVariables = Exact<{
  StockAdjustmentReasonID: Scalars['String'];
}>;


export type UpdateDefaultSaReasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDefaultSAReason'>
);

export type DeleteStockAdjustmentReasonMutationVariables = Exact<{
  input: StockAdjustmentReasonDeleteInput;
}>;


export type DeleteStockAdjustmentReasonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockAdjustmentReason'>
);

export type GetStockCategoryQueryVariables = Exact<{
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockCategoryQuery = (
  { __typename?: 'Query' }
  & { getStockCategory: Array<(
    { __typename?: 'StockCategoryEntity' }
    & Pick<StockCategoryEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'AccountID' | 'RecordStatus' | 'StockCategoryID' | 'ParentStockCategoryID' | 'Level' | 'Name' | 'StockAccountCodeID'>
    & { StockAccountCode?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Code' | 'Name'>
    )>, StockItem?: Maybe<Array<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name' | 'Description'>
    )>> }
  )> }
);

export type GetStockCategoryPermQueryVariables = Exact<{
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockCategoryPermQuery = (
  { __typename?: 'Query' }
  & { getStockCategoryPerm: Array<(
    { __typename?: 'StockCategoryEntity' }
    & Pick<StockCategoryEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'AccountID' | 'RecordStatus' | 'StockCategoryID' | 'ParentStockCategoryID' | 'Level' | 'Name' | 'StockAccountCodeID'>
    & { StockAccountCode?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Code' | 'Name'>
    )>, StockItem?: Maybe<Array<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name' | 'Description'>
    )>> }
  )> }
);

export type GetStockCategoryNameQueryVariables = Exact<{
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockCategoryNameQuery = (
  { __typename?: 'Query' }
  & { getStockCategory: Array<(
    { __typename?: 'StockCategoryEntity' }
    & Pick<StockCategoryEntity, 'StockCategoryID' | 'Name'>
  )> }
);

export type GetStockCategoryDetailQueryVariables = Exact<{
  StockCategoryID?: Maybe<Scalars['String']>;
  StockAccountCodeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockCategoryDetailQuery = (
  { __typename?: 'Query' }
  & { getStockCategory: Array<(
    { __typename?: 'StockCategoryEntity' }
    & Pick<StockCategoryEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'AccountID' | 'RecordStatus' | 'StockCategoryID' | 'ParentStockCategoryID' | 'Level' | 'Name' | 'StockAccountCodeID' | 'ParentCategoryMCOA'>
    & { StockAccountCode?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Code' | 'Name'>
    )>, StockItem?: Maybe<Array<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name'>
    )>> }
  )> }
);

export type CreateStockCategoryMutationVariables = Exact<{
  input: StockCategoryInput;
}>;


export type CreateStockCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockCategory'>
);

export type UpdateStockCategoryMutationVariables = Exact<{
  input: StockCategoryInput;
}>;


export type UpdateStockCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockCategory'>
);

export type DeleteStockCategoryMutationVariables = Exact<{
  StockCategoryID: Scalars['String'];
}>;


export type DeleteStockCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockCategory'>
);

export type GetStockItemQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemQuery = (
  { __typename?: 'Query' }
  & { getStockItem: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'AccountID' | 'StockItemID' | 'StockCategoryID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'TaxSchemeID' | 'Description' | 'Remark' | 'RecordStatus' | 'Name' | 'IsUse' | 'ABCClass' | 'ShelfLife' | 'StandardCost' | 'EInvoiceIntegrated' | 'ClassificationCodeID' | 'UOMID'>
    & { StockCategory?: Maybe<(
      { __typename?: 'StockCategoryEntity' }
      & Pick<StockCategoryEntity, 'Name'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code'>
    )>, StockItemWarehouse?: Maybe<Array<(
      { __typename?: 'StockItemWarehouseEntity' }
      & Pick<StockItemWarehouseEntity, 'WarehouseID' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty' | 'RecordStatus' | 'UsedStockItemWarehouse'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )> }
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'TaxSchemeID' | 'Description'>
    )> }
  )> }
);

export type GetStockItemWStockItemWarehouseQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemWStockItemWarehouseQuery = (
  { __typename?: 'Query' }
  & { getStockItem: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'AccountID' | 'StockItemID' | 'StockCategoryID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'TaxSchemeID' | 'Description' | 'Remark' | 'RecordStatus' | 'Name' | 'IsUse' | 'ABCClass' | 'ShelfLife' | 'StandardCost' | 'EInvoiceIntegrated' | 'ClassificationCodeID' | 'UOMID'>
    & { StockCategory?: Maybe<(
      { __typename?: 'StockCategoryEntity' }
      & Pick<StockCategoryEntity, 'Name'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code'>
    )>, StockItemWarehouse?: Maybe<Array<(
      { __typename?: 'StockItemWarehouseEntity' }
      & Pick<StockItemWarehouseEntity, 'WarehouseID' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty' | 'RecordStatus' | 'UsedStockItemWarehouse' | 'StockQtyByWarehouse'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )> }
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'TaxSchemeID' | 'Description'>
    )> }
  )> }
);

export type GetStockItemNameQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemNameQuery = (
  { __typename?: 'Query' }
  & { getStockItem: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'StockItemID' | 'Name'>
    & { StockItemWarehouse?: Maybe<Array<(
      { __typename?: 'StockItemWarehouseEntity' }
      & Pick<StockItemWarehouseEntity, 'WarehouseID'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetStockItemDetailQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemDetailQuery = (
  { __typename?: 'Query' }
  & { getStockItem: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'AccountID' | 'StockItemID' | 'StockCategoryID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'TaxSchemeID' | 'Description' | 'Remark' | 'RecordStatus' | 'Name' | 'IsUse' | 'ABCClass' | 'ShelfLife' | 'StandardCost' | 'UOMID'>
    & { StockCategory?: Maybe<(
      { __typename?: 'StockCategoryEntity' }
      & Pick<StockCategoryEntity, 'Name' | 'StockAccountCodeID' | 'ParentCategoryMCOA'>
      & { StockAccountCode?: Maybe<(
        { __typename?: 'MasterCOAEntity' }
        & Pick<MasterCoaEntity, 'Code' | 'Name'>
      )> }
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code'>
    )>, StockItemWarehouse?: Maybe<Array<(
      { __typename?: 'StockItemWarehouseEntity' }
      & Pick<StockItemWarehouseEntity, 'WarehouseID' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty' | 'RecordStatus' | 'UsedStockItemWarehouse'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )> }
    )>>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'Code' | 'TaxSchemeID' | 'Description'>
    )> }
  )> }
);

export type CreateStockItemMutationVariables = Exact<{
  input: StockItemInput;
  warehouseInput?: Maybe<Array<StockItemWarehouseInput> | StockItemWarehouseInput>;
}>;


export type CreateStockItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockItem'>
);

export type UpdateStockItemMutationVariables = Exact<{
  input: StockItemInput;
}>;


export type UpdateStockItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockItem'>
);

export type UpdateStockItemWithWarehouseMutationVariables = Exact<{
  input: StockItemInput;
  warehouse?: Maybe<Array<StockItemWarehouseInput> | StockItemWarehouseInput>;
  StockItemID: Scalars['String'];
}>;


export type UpdateStockItemWithWarehouseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateStockItemWithWarehouse'>
);

export type DeleteStockItemMutationVariables = Exact<{
  input: StockItemDeleteInput;
}>;


export type DeleteStockItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockItem'>
);

export type GetStockLedgerQueryVariables = Exact<{
  StockItemID: Scalars['String'];
  WarehouseID: Scalars['String'];
}>;


export type GetStockLedgerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockLedger'>
);

export type StockItemWarehouseSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StockItemID: Scalars['String'];
  WarehouseIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type StockItemWarehouseSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockItemWarehouseSummary'>
);

export type GetStockItemListQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemListQuery = (
  { __typename?: 'Query' }
  & { getStockItemPerm: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'StockItemID' | 'RecordStatus' | 'Name' | 'IsUse' | 'ABCClass' | 'ShelfLife'>
    & { UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code'>
    )>, StockItemWarehouse?: Maybe<Array<(
      { __typename?: 'StockItemWarehouseEntity' }
      & Pick<StockItemWarehouseEntity, 'WarehouseID' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetStockItemWarehouseQueryVariables = Exact<{
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockItemWarehouseQuery = (
  { __typename?: 'Query' }
  & { getStockItemWarehouse: Array<(
    { __typename?: 'StockItemWarehouseEntity' }
    & Pick<StockItemWarehouseEntity, 'AccountID' | 'StockItemWarehouseID' | 'StockItemID' | 'RecordStatus' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty' | 'StockQty' | 'SubmitStockQty' | 'StockTotalValue' | 'StockAvgUnitCost' | 'UsedStockItemWarehouse' | 'WarehouseID'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name' | 'StockItemID' | 'UOMID' | 'RecordStatus'>
      & { StockCategory?: Maybe<(
        { __typename?: 'StockCategoryEntity' }
        & Pick<StockCategoryEntity, 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
    )> }
  )> }
);

export type GetStockItemWarehouseListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetStockItemWarehouseListingQuery = (
  { __typename?: 'Query' }
  & { getStockItemWarehouseListing: (
    { __typename?: 'StockItemWarehouseType' }
    & Pick<StockItemWarehouseType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'StockItemWarehouseEntity' }
      & Pick<StockItemWarehouseEntity, 'AccountID' | 'StockItemWarehouseID' | 'StockItemID' | 'RecordStatus' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty' | 'StockQty' | 'SubmitStockQty' | 'StockTotalValue' | 'StockAvgUnitCost' | 'WarehouseID'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name' | 'StockItemID' | 'UOMID'>
        & { StockCategory?: Maybe<(
          { __typename?: 'StockCategoryEntity' }
          & Pick<StockCategoryEntity, 'Name'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )> }
      )>, Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
      )> }
    )> }
  ) }
);

export type StockEnquiryDetailsQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
}>;


export type StockEnquiryDetailsQuery = (
  { __typename?: 'Query' }
  & { stockEnquiryDetails: (
    { __typename?: 'stockEnquiryDetailsType' }
    & Pick<StockEnquiryDetailsType, 'ItemCount' | 'TotalItemCost'>
  ) }
);

export type StockEnquiryListingQueryVariables = Exact<{
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type StockEnquiryListingQuery = (
  { __typename?: 'Query' }
  & { getStockItemWarehouse: Array<(
    { __typename?: 'StockItemWarehouseEntity' }
    & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID' | 'StockItemID' | 'StockTotalValue' | 'StockAvgUnitCost' | 'SubmitStockQty' | 'StockQty'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name'>
      & { StockCategory?: Maybe<(
        { __typename?: 'StockCategoryEntity' }
        & Pick<StockCategoryEntity, 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
    )> }
  )> }
);

export type StockItemWarehouseListQueryVariables = Exact<{
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
}>;


export type StockItemWarehouseListQuery = (
  { __typename?: 'Query' }
  & { getStockItemWarehouse: Array<(
    { __typename?: 'StockItemWarehouseEntity' }
    & Pick<StockItemWarehouseEntity, 'AccountID' | 'StockItemWarehouseID' | 'StockItemID' | 'UsedStockItemWarehouse' | 'WarehouseID'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name' | 'StockItemID' | 'UOMID' | 'RecordStatus'>
      & { StockCategory?: Maybe<(
        { __typename?: 'StockCategoryEntity' }
        & Pick<StockCategoryEntity, 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
    )> }
  )> }
);

export type GetStockItemWarehouseByCostCentreQueryVariables = Exact<{
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
}>;


export type GetStockItemWarehouseByCostCentreQuery = (
  { __typename?: 'Query' }
  & { getStockItemWarehouseByCostCentre: Array<(
    { __typename?: 'StockItemWarehouseEntity' }
    & Pick<StockItemWarehouseEntity, 'AccountID' | 'StockItemWarehouseID' | 'StockItemID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'RecordStatus' | 'MaximumQty' | 'MinimumQty' | 'ReorderLevel' | 'ReorderQty' | 'StockQty' | 'SubmitStockQty' | 'StockTotalValue' | 'StockAvgUnitCost' | 'UsedStockItemWarehouse' | 'WarehouseID'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name' | 'StockItemID' | 'UOMID' | 'RecordStatus'>
      & { StockCategory?: Maybe<(
        { __typename?: 'StockCategoryEntity' }
        & Pick<StockCategoryEntity, 'Name' | 'StockAccountCodeID'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'WarehouseID' | 'Name'>
    )> }
  )> }
);

export type CreateStockItemWarehouseMutationVariables = Exact<{
  input: StockItemWarehouseInput;
}>;


export type CreateStockItemWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { createStockItemWarehouse: (
    { __typename?: 'StockItemWarehouseEntity' }
    & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID'>
  ) }
);

export type UpdateStockItemWarehouseMutationVariables = Exact<{
  input: StockItemWarehouseInput;
}>;


export type UpdateStockItemWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { updateStockItemWarehouse: (
    { __typename?: 'StockItemWarehouseEntity' }
    & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID'>
  ) }
);

export type GetGeneralSettingExecutiveSummaryQueryVariables = Exact<{
  Category?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetGeneralSettingExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CurrencyDate' | 'BankProfileDate' | 'CurrencyExchangeDate' | 'CompanyDate' | 'RoleDate' | 'UserDate' | 'TaxDate' | 'CostCentreDefinitionDate' | 'PaymentMethodDate' | 'CreditCardTypeDate' | 'ExpenseClassDate' | 'ExpenseItemDate' | 'CreditorTypeDate' | 'PolicySettingsDate' | 'ApprovalPolicyDate' | 'PaymentApprovalDate' | 'ReceiptApprovalDate' | 'BankTransferApprovalDate' | 'CreditorTypeCount' | 'WarehouseDate' | 'UOMDate' | 'UOMExchangeDate' | 'StockCategoryDate' | 'StockAdjustmentReasonDate' | 'DocumentNumberingDate' | 'DataSourceDate' | 'FinancialReportDate' | 'GLDigitalReportDate'>
);

export type GetCommonSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommonSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TaxDate' | 'UOMDate' | 'UOMExchangeDate'>
);

export type GetTaxTypeQueryVariables = Exact<{
  TaxTypeID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetTaxTypeQuery = (
  { __typename?: 'Query' }
  & { getTaxType: Array<(
    { __typename?: 'TaxTypeEntity' }
    & Pick<TaxTypeEntity, 'TaxTypeID' | 'Code' | 'RecordStatus' | 'Description'>
  )> }
);

export type CreateTaxTypeMutationVariables = Exact<{
  input: TaxTypeInput;
}>;


export type CreateTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & { createTaxType: (
    { __typename?: 'TaxTypeEntity' }
    & Pick<TaxTypeEntity, 'TaxTypeID'>
  ) }
);

export type UpdateTaxTypeMutationVariables = Exact<{
  input: TaxTypeInput;
}>;


export type UpdateTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateTaxType: (
    { __typename?: 'TaxTypeEntity' }
    & Pick<TaxTypeEntity, 'TaxTypeID' | 'Code' | 'Description'>
  ) }
);

export type DeleteTaxTypeMutationVariables = Exact<{
  input: TaxTypeInput;
}>;


export type DeleteTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaxType'>
);

export type GetTaxSchemeQueryVariables = Exact<{
  TaxTypeID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'createdTs' | 'Code' | 'Description' | 'AccTaxCategory' | 'TaxTypeID' | 'AccTaxClass' | 'TaxSchemeID' | 'MasterCOAID' | 'RecordStatus'>
    & { LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )>, TaxEffective?: Maybe<Array<(
      { __typename?: 'TaxEffectiveDateEntity' }
      & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate' | 'createdTs' | 'createdBy'>
    )>>, TaxType?: Maybe<(
      { __typename?: 'TaxTypeEntity' }
      & Pick<TaxTypeEntity, 'Code'>
    )>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetTaxSchemeListingQueryVariables = Exact<{
  TaxTypeID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetTaxSchemeListingQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'createdTs' | 'Code' | 'Description' | 'AccTaxCategory' | 'TaxTypeID' | 'AccTaxClass' | 'TaxSchemeID' | 'MasterCOAID' | 'RecordStatus'>
    & { ReceiptItem?: Maybe<Array<(
      { __typename?: 'ReceiptItemEntity' }
      & Pick<ReceiptItemEntity, 'ReceiptItemID'>
    )>>, PaymentItem?: Maybe<Array<(
      { __typename?: 'PaymentItemEntity' }
      & Pick<PaymentItemEntity, 'PaymentItemID'>
    )>>, LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )>, TaxEffective?: Maybe<Array<(
      { __typename?: 'TaxEffectiveDateEntity' }
      & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate' | 'createdTs' | 'createdBy'>
    )>>, TaxType?: Maybe<(
      { __typename?: 'TaxTypeEntity' }
      & Pick<TaxTypeEntity, 'Code'>
    )>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type CreateTaxSchemeMutationVariables = Exact<{
  input: TaxSchemeInput;
}>;


export type CreateTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & { createTaxScheme: (
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'MasterCOAID'>
  ) }
);

export type UpdateTaxSchemeMutationVariables = Exact<{
  input: TaxSchemeInput;
}>;


export type UpdateTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & { updateTaxScheme: (
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'MasterCOAID'>
  ) }
);

export type DeleteTaxSchemeMutationVariables = Exact<{
  TaxSchemeID: Scalars['String'];
}>;


export type DeleteTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaxScheme'>
);

export type CreateTaxEffectiveDateMutationVariables = Exact<{
  input: TaxEffectiveDateInput;
}>;


export type CreateTaxEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & { createTaxEffectiveDate: (
    { __typename?: 'TaxEffectiveDateEntity' }
    & Pick<TaxEffectiveDateEntity, 'TaxEffectiveDateID'>
  ) }
);

export type GetTaxSchemeCodeQueryVariables = Exact<{
  TaxTypeID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetTaxSchemeCodeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'AccTaxClass'>
    & { LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )>, TaxEffective?: Maybe<Array<(
      { __typename?: 'TaxEffectiveDateEntity' }
      & Pick<TaxEffectiveDateEntity, 'Date' | 'Rate'>
    )>> }
  )> }
);

export type UpdateTaxEffectiveDateMutationVariables = Exact<{
  input: TaxEffectiveDateInput;
}>;


export type UpdateTaxEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & { updateTaxEffectiveDate: (
    { __typename?: 'TaxEffectiveDateEntity' }
    & Pick<TaxEffectiveDateEntity, 'TaxEffectiveDateID'>
  ) }
);

export type GetTaxEffectiveDateQueryVariables = Exact<{
  TaxEffectiveDateID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
}>;


export type GetTaxEffectiveDateQuery = (
  { __typename?: 'Query' }
  & { getTaxEffectiveDate: Array<(
    { __typename?: 'TaxEffectiveDateEntity' }
    & Pick<TaxEffectiveDateEntity, 'TaxEffectiveDateID' | 'TaxSchemeID' | 'Date' | 'Rate'>
  )> }
);

export type DeleteTaxEffectiveDateMutationVariables = Exact<{
  input: TaxEffectiveDateInput;
}>;


export type DeleteTaxEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaxEffectiveDate'>
);

export type InsertTaxMutationVariables = Exact<{
  TaxEffectiveInput: Array<TaxEffectiveInput> | TaxEffectiveInput;
  TaxSchemeInput: TaxSchemeInput;
}>;


export type InsertTaxMutation = (
  { __typename?: 'Mutation' }
  & { insertTax: (
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID'>
  ) }
);

export type UpdateTaxMutationVariables = Exact<{
  TaxEffectiveInput: Array<TaxEffectiveInput> | TaxEffectiveInput;
  TaxSchemeInput: TaxSchemeInput;
}>;


export type UpdateTaxMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTax'>
);

export type GetTransactionTypeQueryVariables = Exact<{
  TransactionTypeID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
}>;


export type GetTransactionTypeQuery = (
  { __typename?: 'Query' }
  & { getTransactionType: Array<(
    { __typename?: 'TransactionTypeEntity' }
    & Pick<TransactionTypeEntity, 'AccountID' | 'TransactionTypeID' | 'Name' | 'Type' | 'MasterCOAID' | 'IsSystem' | 'RecordStatus'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetTransactionTypeListingQueryVariables = Exact<{
  TransactionTypeID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetTransactionTypeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTransactionTypeCount'>
  & { getTransactionTypePerm: Array<(
    { __typename?: 'TransactionTypeEntity' }
    & Pick<TransactionTypeEntity, 'AccountID' | 'TransactionTypeID' | 'Name' | 'Type' | 'MasterCOAID' | 'IsSystem' | 'RecordStatus'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type CreateTransactionTypeMutationVariables = Exact<{
  input: TransactionTypeInput;
}>;


export type CreateTransactionTypeMutation = (
  { __typename?: 'Mutation' }
  & { createTransactionType: (
    { __typename?: 'TransactionTypeEntity' }
    & Pick<TransactionTypeEntity, 'TransactionTypeID'>
  ) }
);

export type UpdateTransactionTypeMutationVariables = Exact<{
  input: TransactionTypeInput;
}>;


export type UpdateTransactionTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateTransactionType: (
    { __typename?: 'TransactionTypeEntity' }
    & Pick<TransactionTypeEntity, 'TransactionTypeID'>
  ) }
);

export type DeleteTransactionTypeMutationVariables = Exact<{
  input: TransactionTypeDeleteInput;
}>;


export type DeleteTransactionTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTransactionType'>
);

export type GetUomCodeQueryVariables = Exact<{
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
}>;


export type GetUomCodeQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
  )> }
);

export type GetUomQueryVariables = Exact<{
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code' | 'RecordStatus'>
    & { ExpenseItem?: Maybe<Array<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID'>
    )>>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID'>
    )>>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteItemEntity' }
      & Pick<AccApDebitNoteItemEntity, 'DebitNoteItemID'>
    )>>, APFavoriteInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID'>
    )>>, StockItem?: Maybe<Array<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'StockItemID'>
    )>>, StockReceiptItem?: Maybe<Array<(
      { __typename?: 'StockReceiptItemEntity' }
      & Pick<StockReceiptItemEntity, 'StockReceiptItemID'>
    )>>, StockIssueItem?: Maybe<Array<(
      { __typename?: 'StockIssueItemEntity' }
      & Pick<StockIssueItemEntity, 'StockIssueItemID'>
    )>>, StockTransferItem?: Maybe<Array<(
      { __typename?: 'StockTransferItemEntity' }
      & Pick<StockTransferItemEntity, 'StockTransferItemID'>
    )>>, CycleCountItem?: Maybe<Array<(
      { __typename?: 'CycleCountItemEntity' }
      & Pick<CycleCountItemEntity, 'CycleCountItemID'>
    )>>, StockAdjusmentItem?: Maybe<Array<(
      { __typename?: 'StockAdjustmentItemEntity' }
      & Pick<StockAdjustmentItemEntity, 'StockAdjustmentItemID'>
    )>> }
  )> }
);

export type GetUomListingQueryVariables = Exact<{
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetUomListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMCount'>
  & { getUOMPerm: Array<(
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code' | 'RecordStatus'>
    & { ExpenseItem?: Maybe<Array<(
      { __typename?: 'ExpenseItemEntity' }
      & Pick<ExpenseItemEntity, 'ExpenseItemID'>
    )>>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID'>
    )>>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'AccAPDebitNoteItemEntity' }
      & Pick<AccApDebitNoteItemEntity, 'DebitNoteItemID'>
    )>>, APFavoriteInvoiceItem?: Maybe<Array<(
      { __typename?: 'AccAPInvoiceItemEntity' }
      & Pick<AccApInvoiceItemEntity, 'InvoiceItemID'>
    )>>, StockItem?: Maybe<Array<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'StockItemID'>
    )>>, StockReceiptItem?: Maybe<Array<(
      { __typename?: 'StockReceiptItemEntity' }
      & Pick<StockReceiptItemEntity, 'StockReceiptItemID'>
    )>>, StockIssueItem?: Maybe<Array<(
      { __typename?: 'StockIssueItemEntity' }
      & Pick<StockIssueItemEntity, 'StockIssueItemID'>
    )>>, StockTransferItem?: Maybe<Array<(
      { __typename?: 'StockTransferItemEntity' }
      & Pick<StockTransferItemEntity, 'StockTransferItemID'>
    )>>, CycleCountItem?: Maybe<Array<(
      { __typename?: 'CycleCountItemEntity' }
      & Pick<CycleCountItemEntity, 'CycleCountItemID'>
    )>>, StockAdjusmentItem?: Maybe<Array<(
      { __typename?: 'StockAdjustmentItemEntity' }
      & Pick<StockAdjustmentItemEntity, 'StockAdjustmentItemID'>
    )>> }
  )> }
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID'>
  ) }
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  input: UomDeleteInput;
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type GetUomExchangeQueryVariables = Exact<{
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeQuery = (
  { __typename?: 'Query' }
  & { getUOMExchange: Array<(
    { __typename?: 'AccUOMExchangeEntity' }
    & Pick<AccUomExchangeEntity, 'ID' | 'FromUomID' | 'ToUomID' | 'FromAmt' | 'ToAmt' | 'ExchangeRate' | 'UomLog'>
  )> }
);

export type GetUomExchangeListingQueryVariables = Exact<{
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeCount'>
  & { getUOMExchangePerm: Array<(
    { __typename?: 'AccUOMExchangeEntity' }
    & Pick<AccUomExchangeEntity, 'ID' | 'FromUomID' | 'ToUomID' | 'FromAmt' | 'ToAmt' | 'ExchangeRate' | 'UomLog'>
  )> }
);

export type GetUomExchangeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUomExchangeDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUomExchangeData'>
);

export type GetUomExchangeDataListingQueryVariables = Exact<{
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeDataListingQuery = (
  { __typename?: 'Query' }
  & { getUomExchangeDataListing: (
    { __typename?: 'UOMExchangeType' }
    & Pick<UomExchangeType, 'Data' | 'TotalLength'>
  ) }
);

export type GetUomExchangeListQueryVariables = Exact<{
  UOMID: Scalars['String'];
}>;


export type GetUomExchangeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeList'>
);

export type CreateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type CreateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUOMExchange'>
);

export type UpdateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type UpdateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUOMExchange'>
);

export type DeleteUomExchangeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOMExchange'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
    & { roleDetail?: Maybe<(
      { __typename?: 'RoleInfo' }
      & Pick<RoleInfo, 'RoleID' | 'Name'>
    )>, companyAssigned?: Maybe<Array<(
      { __typename?: 'CompanyInfo' }
      & Pick<CompanyInfo, 'Name' | 'CompanyID'>
    )>> }
  ) }
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
}>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'confirmed' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName' | 'blockCount'>
    & { roleDetail?: Maybe<(
      { __typename?: 'RoleInfo' }
      & Pick<RoleInfo, 'RoleID' | 'Name'>
    )>, companyAssigned?: Maybe<Array<(
      { __typename?: 'CompanyInfo' }
      & Pick<CompanyInfo, 'CompanyID' | 'Name'>
    )>> }
  )> }
);

export type GetUsersByAccountAndSoftwareListingQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetUsersByAccountAndSoftwareListingQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftwareListing: (
    { __typename?: 'UserReturnType' }
    & Pick<UserReturnType, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'UserEntity' }
      & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'confirmed' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName' | 'blockCount'>
      & { roleDetail?: Maybe<(
        { __typename?: 'RoleInfo' }
        & Pick<RoleInfo, 'RoleID' | 'Name'>
      )>, companyAssigned?: Maybe<Array<(
        { __typename?: 'CompanyInfo' }
        & Pick<CompanyInfo, 'CompanyID' | 'Name'>
      )>> }
    )>> }
  ) }
);

export type GetActiveUsersCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveUsersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActiveUsersCount'>
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
  roleID?: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
  roleID?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type AssignCompanyMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type AssignCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignEntityRoleUser'>
);

export type UnassignCompanyMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type UnassignCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignEntityRoleUser'>
);

export type ChangePasswordForUserMutationVariables = Exact<{
  userID: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordForUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePasswordForUser'>
);

export type ResendInvitationMutationVariables = Exact<{
  userID: Scalars['String'];
}>;


export type ResendInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendInvitation'>
);

export type ToggleUserActivationMutationVariables = Exact<{
  userID: Scalars['String'];
}>;


export type ToggleUserActivationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleUserActivation'>
);

export type CreateUpdateUserActivityLogMutationVariables = Exact<{
  LastAccessedModule?: Maybe<Scalars['String']>;
  UserAction: Scalars['String'];
}>;


export type CreateUpdateUserActivityLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateUserActivityLog'>
);

export type GetUserRoleQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
}>;


export type GetUserRoleQuery = (
  { __typename?: 'Query' }
  & { getUserRole: Array<(
    { __typename?: 'UserRoleEntity' }
    & Pick<UserRoleEntity, 'UserRoleID' | 'RoleID' | 'UserID'>
  )> }
);

export type GetCompanyAssignedUsersByRoleQueryVariables = Exact<{
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetCompanyAssignedUsersByRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyAssignedUsersByRole'>
);

export type GetCompanyAssignedUsersByRoleListingQueryVariables = Exact<{
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCompanyAssignedUsersByRoleListingQuery = (
  { __typename?: 'Query' }
  & { getCompanyAssignedUsersByRoleListing: (
    { __typename?: 'CompanyAuthorisationReturnType' }
    & Pick<CompanyAuthorisationReturnType, 'Data' | 'TotalLength'>
  ) }
);

export type GetUserCompanyAssignedUsersByRoleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
}>;


export type GetUserCompanyAssignedUsersByRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserCompanyAssignedUsersByRole'>
);

export type GetRoleNameByUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetRoleNameByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRoleNameByUser'>
);

export type GetUserByRoleAssignedQueryVariables = Exact<{
  RoleID: Scalars['String'];
}>;


export type GetUserByRoleAssignedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserByRoleAssigned'>
);

export type GetWarehouseQueryVariables = Exact<{
  WarehouseID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseQuery = (
  { __typename?: 'Query' }
  & { getWarehouse: Array<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'WarehouseID' | 'Code' | 'Name' | 'Address' | 'ContactNo' | 'Designation' | 'Email' | 'CompanyID' | 'CostCentreID' | 'UsedWarehouse'>
    & { StockReceipt?: Maybe<Array<(
      { __typename?: 'StockReceiptEntity' }
      & Pick<StockReceiptEntity, 'StockReceiptID'>
    )>>, StockIssue?: Maybe<Array<(
      { __typename?: 'StockIssueEntity' }
      & Pick<StockIssueEntity, 'StockIssueID'>
    )>>, StockAdjustment?: Maybe<Array<(
      { __typename?: 'StockAdjustmentEntity' }
      & Pick<StockAdjustmentEntity, 'StockAdjustmentID'>
    )>>, FromStockTransfer?: Maybe<Array<(
      { __typename?: 'StockTransferEntity' }
      & Pick<StockTransferEntity, 'StockTransferID'>
    )>>, CycleCount?: Maybe<Array<(
      { __typename?: 'CycleCountEntity' }
      & Pick<CycleCountEntity, 'CycleCountID'>
    )>>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>>, userAssigned?: Maybe<Array<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'Name' | 'UserID'>
    )>>, stockItemAssigned?: Maybe<Array<(
      { __typename?: 'StockItemInfo' }
      & Pick<StockItemInfo, 'StockItemID' | 'Name'>
    )>> }
  )> }
);

export type GetWarehouseListingQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseCount'>
  & { getWarehouse: Array<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'WarehouseID' | 'Code' | 'Name' | 'Address' | 'ContactNo' | 'Designation' | 'Email' | 'CompanyID' | 'CostCentreID' | 'UsedWarehouse'>
    & { StockReceipt?: Maybe<Array<(
      { __typename?: 'StockReceiptEntity' }
      & Pick<StockReceiptEntity, 'StockReceiptID'>
    )>>, StockIssue?: Maybe<Array<(
      { __typename?: 'StockIssueEntity' }
      & Pick<StockIssueEntity, 'StockIssueID'>
    )>>, StockAdjustment?: Maybe<Array<(
      { __typename?: 'StockAdjustmentEntity' }
      & Pick<StockAdjustmentEntity, 'StockAdjustmentID'>
    )>>, FromStockTransfer?: Maybe<Array<(
      { __typename?: 'StockTransferEntity' }
      & Pick<StockTransferEntity, 'StockTransferID'>
    )>>, CycleCount?: Maybe<Array<(
      { __typename?: 'CycleCountEntity' }
      & Pick<CycleCountEntity, 'CycleCountID'>
    )>>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>> }
  )> }
);

export type GetWarehouseNameQueryVariables = Exact<{
  WarehouseID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseNameQuery = (
  { __typename?: 'Query' }
  & { getWarehouse: Array<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'WarehouseID' | 'CompanyID' | 'Code' | 'Name'>
  )> }
);

export type GetAssignedWarehouseCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssignedWarehouseCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAssignedWarehouseCompanyByUser'>
);

export type GetCompanyWarehouseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyWarehouseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyWarehouse'>
);

export type CreateWarehouseMutationVariables = Exact<{
  WarehouseInput: WarehouseInput;
}>;


export type CreateWarehouseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createWarehouse'>
);

export type UpdateWarehouseMutationVariables = Exact<{
  WarehouseInput: WarehouseInput;
  WarehouseID: Scalars['String'];
}>;


export type UpdateWarehouseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWarehouse'>
);

export type DeleteWarehouseMutationVariables = Exact<{
  input: WarehouseDeleteInput;
}>;


export type DeleteWarehouseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteWarehouse'>
);

export type GetAssignedUserByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetAssignedUserByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAssignedUserByCompany'>
);

export type AssignWarehouseMutationVariables = Exact<{
  input: WarehouseUserAssignmentInput;
}>;


export type AssignWarehouseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignWarehouseUser'>
);

export type UnassignWarehouseMutationVariables = Exact<{
  input: WarehouseUserAssignmentInput;
}>;


export type UnassignWarehouseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignUserWarehouse'>
);

export type AssignWarehouseItemMutationVariables = Exact<{
  input: WarehouseItemAssignmentInput;
}>;


export type AssignWarehouseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignWarehouseItem'>
);

export type UnassignWarehouseItemMutationVariables = Exact<{
  input: WarehouseItemAssignmentInput;
}>;


export type UnassignWarehouseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignWarehouseItem'>
);

export type GetStockCategoryItemAssignmentQueryVariables = Exact<{
  mode: Scalars['String'];
  WarehouseID: Scalars['String'];
}>;


export type GetStockCategoryItemAssignmentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockCategoryItemAssignment'>
);

export type GetHomeSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermission' | 'getAccountPermission'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'userName' | 'department' | 'superUser' | 'jobTitle' | 'lastestAccessedEntity'>
    & { roleDetail?: Maybe<(
      { __typename?: 'RoleInfo' }
      & Pick<RoleInfo, 'Name' | 'RoleID'>
    )> }
  ), getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'Logo' | 'eInvIntegrated'>
  )> }
);

export type GetCycleCountQueryVariables = Exact<{
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetCycleCountQuery = (
  { __typename?: 'Query' }
  & { getCycleCount: Array<(
    { __typename?: 'CycleCountEntity' }
    & Pick<CycleCountEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'CycleCountID' | 'CostCentreID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CycleCountItem?: Maybe<Array<(
      { __typename?: 'CycleCountItemEntity' }
      & Pick<CycleCountItemEntity, 'CycleCountItemID' | 'WarehouseID' | 'StockItemID' | 'TransactionTypeID' | 'UOMID' | 'OnHandQty' | 'CountQty' | 'AdjustQty' | 'ReasonID'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
        & { StockItemWarehouse?: Maybe<Array<(
          { __typename?: 'StockItemWarehouseEntity' }
          & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID' | 'StockQty' | 'SubmitStockQty'>
        )>>, StockCategory?: Maybe<(
          { __typename?: 'StockCategoryEntity' }
          & Pick<StockCategoryEntity, 'Name'>
        )>, StockWeightedAvg?: Maybe<Array<(
          { __typename?: 'StockWeightedAvgEntity' }
          & Pick<StockWeightedAvgEntity, 'UnitCost' | 'WarehouseID'>
        )>> }
      )>, StockItemWarehouse?: Maybe<(
        { __typename?: 'StockItemWarehouseEntity' }
        & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID' | 'StockQty' | 'SubmitStockQty' | 'StockAvgUnitCost'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
      )> }
    )>> }
  )> }
);

export type GetCycleCountItemQueryVariables = Exact<{
  CycleCountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  IsReconciled?: Maybe<Scalars['Boolean']>;
  DocDate?: Maybe<Scalars['String']>;
}>;


export type GetCycleCountItemQuery = (
  { __typename?: 'Query' }
  & { getCycleCountItem: Array<(
    { __typename?: 'CycleCountItemEntity' }
    & Pick<CycleCountItemEntity, 'CycleCountItemID' | 'ReasonID' | 'CycleCountID' | 'UOMID' | 'WarehouseID' | 'StockItemID' | 'OnHandQty' | 'CountQty' | 'AdjustQty' | 'TransactionTypeID'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name' | 'CurrentQty'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, StockCategory?: Maybe<(
        { __typename?: 'StockCategoryEntity' }
        & Pick<StockCategoryEntity, 'Name'>
      )> }
    )>, Reason?: Maybe<(
      { __typename?: 'StockAdjustmentReasonEntity' }
      & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
    )> }
  )> }
);

export type GetCycleCountbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetCycleCountbyStatusQuery = (
  { __typename?: 'Query' }
  & { getCycleCountbyStatus: Array<(
    { __typename?: 'CycleCountEntity' }
    & Pick<CycleCountEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'CycleCountID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'WarehouseID' | 'CostCentreID' | 'Description' | 'ApprovalStatus'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CycleCountItem?: Maybe<Array<(
      { __typename?: 'CycleCountItemEntity' }
      & Pick<CycleCountItemEntity, 'CycleCountItemID' | 'StockItemID' | 'UOMID' | 'OnHandQty' | 'CountQty' | 'AdjustQty' | 'ReasonID'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
      )>, StockItemWarehouse?: Maybe<(
        { __typename?: 'StockItemWarehouseEntity' }
        & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID' | 'StockQty'>
      )> }
    )>> }
  )> }
);

export type GetCycleCountListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetCycleCountListingQuery = (
  { __typename?: 'Query' }
  & { getCycleCountListing: (
    { __typename?: 'CycleCountType' }
    & Pick<CycleCountType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'CycleCountEntity' }
      & Pick<CycleCountEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'CycleCountID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'WarehouseID' | 'CostCentreID' | 'Description' | 'ApprovalStatus'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, CycleCountItem?: Maybe<Array<(
        { __typename?: 'CycleCountItemEntity' }
        & Pick<CycleCountItemEntity, 'CycleCountItemID' | 'StockItemID' | 'UOMID' | 'OnHandQty' | 'CountQty' | 'AdjustQty' | 'ReasonID'>
        & { StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )>, Reason?: Maybe<(
          { __typename?: 'StockAdjustmentReasonEntity' }
          & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
        )>, StockItemWarehouse?: Maybe<(
          { __typename?: 'StockItemWarehouseEntity' }
          & Pick<StockItemWarehouseEntity, 'StockItemWarehouseID' | 'StockQty'>
        )> }
      )>> }
    )> }
  ) }
);

export type CreateCycleCountMutationVariables = Exact<{
  input: CycleCountInput;
  itemInput?: Maybe<Array<CycleCountItemInput> | CycleCountItemInput>;
}>;


export type CreateCycleCountMutation = (
  { __typename?: 'Mutation' }
  & { createCycleCount: (
    { __typename?: 'CycleCountEntity' }
    & Pick<CycleCountEntity, 'CycleCountID'>
  ) }
);

export type CreateCycleCountItemMutationVariables = Exact<{
  input: CycleCountItemInput;
}>;


export type CreateCycleCountItemMutation = (
  { __typename?: 'Mutation' }
  & { createCycleCountItem: (
    { __typename?: 'CycleCountItemEntity' }
    & Pick<CycleCountItemEntity, 'CycleCountID'>
  ) }
);

export type PostCycleCountMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: CycleCountRejectInput;
}>;


export type PostCycleCountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postCycleCount'>
);

export type UpdateCycleCountMutationVariables = Exact<{
  input: CycleCountInput;
  itemInput?: Maybe<Array<CycleCountItemInput> | CycleCountItemInput>;
}>;


export type UpdateCycleCountMutation = (
  { __typename?: 'Mutation' }
  & { updateCycleCount: (
    { __typename?: 'CycleCountEntity' }
    & Pick<CycleCountEntity, 'CycleCountID'>
  ) }
);

export type UpdateCycleCountItemMutationVariables = Exact<{
  input: CycleCountItemInput;
}>;


export type UpdateCycleCountItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCycleCountItem: (
    { __typename?: 'CycleCountItemEntity' }
    & Pick<CycleCountItemEntity, 'CycleCountItemID' | 'StockItemID' | 'WarehouseID' | 'ReasonID' | 'CountQty' | 'OnHandQty' | 'AdjustQty'>
    & { Reason?: Maybe<(
      { __typename?: 'StockAdjustmentReasonEntity' }
      & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
    )> }
  ) }
);

export type DeleteCycleCountMutationVariables = Exact<{
  CycleCountID: Scalars['String'];
}>;


export type DeleteCycleCountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCycleCount'>
);

export type GetStockItemFromWarehouseQueryVariables = Exact<{
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockItemFromWarehouseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockItemFromWarehouse'>
);

export type GetStockItemCycleCountQueryVariables = Exact<{
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetStockItemCycleCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockItemCycleCount'>
);

export type CreateStockReconMutationVariables = Exact<{
  input: StockReconInput;
  itemInput?: Maybe<Array<StockReconItemInput> | StockReconItemInput>;
}>;


export type CreateStockReconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockRecon'>
);

export type GetLatestCycleCountDateQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
}>;


export type GetLatestCycleCountDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLatestCycleCountDate'>
);

export type PendingIcTransactionCheckingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  DocDate?: Maybe<Scalars['DateTime']>;
}>;


export type PendingIcTransactionCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pendingICTransactionChecking'>
);

export type PendingCycleCountQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
}>;


export type PendingCycleCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pendingCycleCount'>
);

export type GetFavoriteStockRequisitionQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  FavoriteStockRequisitionID?: Maybe<Scalars['String']>;
}>;


export type GetFavoriteStockRequisitionQuery = (
  { __typename?: 'Query' }
  & { getFavoriteStockRequisition: Array<(
    { __typename?: 'FavoriteStockRequisitionEntity' }
    & Pick<FavoriteStockRequisitionEntity, 'createdBy' | 'FavoriteStockRequisitionID' | 'Name' | 'RefNo' | 'CostCentreID' | 'Description'>
    & { CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, FavoriteStockRequisitionItem?: Maybe<Array<(
      { __typename?: 'FavoriteStockRequisitionItemEntity' }
      & Pick<FavoriteStockRequisitionItemEntity, 'FavoriteStockRequisitionID' | 'StockItemID' | 'TransactionTypeID' | 'UOMID' | 'Quantity' | 'Remark'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateFavoriteStockRequisitionMutationVariables = Exact<{
  FavoriteStockRequisitionInput: FavoriteStockRequisitionInput;
  FavoriteStockRequisitionItemInput: Array<FavoriteStockRequisitionItemInput> | FavoriteStockRequisitionItemInput;
}>;


export type CreateFavoriteStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFavoriteStockRequisition'>
);

export type DeleteFavoriteStockRequisitionMutationVariables = Exact<{
  FavoriteStockRequisitionID: Scalars['String'];
}>;


export type DeleteFavoriteStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFavoriteStockRequisition'>
);

export type GetFavoriteStockTransferRequisitionQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteStockTransferRequisitionID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetFavoriteStockTransferRequisitionQuery = (
  { __typename?: 'Query' }
  & { getFavoriteStockTransferRequisition: Array<(
    { __typename?: 'FavoriteStockTransferRequisitionEntity' }
    & Pick<FavoriteStockTransferRequisitionEntity, 'createdBy' | 'FavoriteStockTransferRequisitionID' | 'Name' | 'RefNo' | 'CostCentreID' | 'ToWarehouseID' | 'Description'>
    & { CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, FavoriteStockTransferRequisitionItem?: Maybe<Array<(
      { __typename?: 'FavoriteStockTransferRequisitionItemEntity' }
      & Pick<FavoriteStockTransferRequisitionItemEntity, 'FavoriteStockTransferRequisitionID' | 'StockItemID' | 'ReasonID' | 'UOMID' | 'Quantity'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'Reason'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateFavoriteStockTransferRequisitionMutationVariables = Exact<{
  FavoriteStockTransferRequisitionInput: FavoriteStockTransferRequisitionInput;
  FavoriteStockTransferRequisitionItemInput: Array<FavoriteStockTransferRequisitionItemInput> | FavoriteStockTransferRequisitionItemInput;
}>;


export type CreateFavoriteStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createFavoriteStockTransferRequisition'>
);

export type DeleteFavoriteStockTransferRequisitionMutationVariables = Exact<{
  FavoriteStockTransferRequisitionID: Scalars['String'];
}>;


export type DeleteFavoriteStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFavoriteStockTransferRequisition'>
);

export type IcSubmenuDetailQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
}>;


export type IcSubmenuDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockRequisitionCount' | 'stockIssueCount' | 'stockReceiptCount' | 'stockTransferRequisitionCount' | 'stockTransferCount' | 'stockAdjustmentCount' | 'stockCostAdjustmentCount' | 'cycleCountCount'>
);

export type StockItemMovementCheckingQueryVariables = Exact<{
  input: StockItemMovementInput;
  ICItemInput?: Maybe<Array<IcItemInput> | IcItemInput>;
}>;


export type StockItemMovementCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'stockItemMovementChecking'>
);

export type GetStockAdjustmentQueryVariables = Exact<{
  StockAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getStockAdjustment: Array<(
    { __typename?: 'StockAdjustmentEntity' }
    & Pick<StockAdjustmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockAdjustmentID' | 'CompanyID' | 'CostCentreID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockAdjustmentItem?: Maybe<Array<(
      { __typename?: 'StockAdjustmentItemEntity' }
      & Pick<StockAdjustmentItemEntity, 'Quantity' | 'BaseUOMID' | 'UOMID' | 'TransactionTypeID' | 'StockItemID' | 'ReasonID' | 'Remark'>
      & { TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Code'>
        )> }
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
      )> }
    )>> }
  )> }
);

export type GetStockAdjustmentbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockAdjustmentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockAdjustmentbyStatus: Array<(
    { __typename?: 'StockAdjustmentEntity' }
    & Pick<StockAdjustmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockAdjustmentID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, StockAdjustmentItem?: Maybe<Array<(
      { __typename?: 'StockAdjustmentItemEntity' }
      & Pick<StockAdjustmentItemEntity, 'StockAdjustmentItemID' | 'Quantity' | 'BaseUOMID' | 'UOMID' | 'TotalCost' | 'TransactionTypeID' | 'DefaultUOMTotalCost' | 'StockItemID' | 'ReasonID' | 'Remark'>
      & { TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
      )> }
    )>> }
  )> }
);

export type GetStockQtyAdjustmentListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockQtyAdjustmentListingQuery = (
  { __typename?: 'Query' }
  & { getStockQtyAdjustmentListing: (
    { __typename?: 'StockQtyAdjustmentType' }
    & Pick<StockQtyAdjustmentType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'StockAdjustmentEntity' }
      & Pick<StockAdjustmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockAdjustmentID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockAdjustmentItem?: Maybe<Array<(
        { __typename?: 'StockAdjustmentItemEntity' }
        & Pick<StockAdjustmentItemEntity, 'StockAdjustmentItemID' | 'Quantity' | 'BaseUOMID' | 'UOMID' | 'TotalCost' | 'TransactionTypeID' | 'DefaultUOMTotalCost' | 'StockItemID' | 'ReasonID' | 'Remark'>
        & { TransactionType?: Maybe<(
          { __typename?: 'TransactionTypeEntity' }
          & Pick<TransactionTypeEntity, 'Name' | 'Type'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )>, StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )>, Reason?: Maybe<(
          { __typename?: 'StockAdjustmentReasonEntity' }
          & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
        )> }
      )>> }
    )> }
  ) }
);

export type GetStockAdjustmentItemQueryVariables = Exact<{
  StockAdjustmentID?: Maybe<Scalars['String']>;
}>;


export type GetStockAdjustmentItemQuery = (
  { __typename?: 'Query' }
  & { getStockAdjustmentItem: Array<(
    { __typename?: 'StockAdjustmentItemEntity' }
    & Pick<StockAdjustmentItemEntity, 'StockAdjustmentItemID' | 'StockItemID' | 'UOMID' | 'ReasonID' | 'Quantity' | 'TransactionTypeID'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name'>
    )>, Reason?: Maybe<(
      { __typename?: 'StockAdjustmentReasonEntity' }
      & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
    )> }
  )> }
);

export type CreateStockAdjustmentMutationVariables = Exact<{
  input: StockAdjustmentInput;
  itemInput?: Maybe<Array<StockAdjustmentItemInput> | StockAdjustmentItemInput>;
}>;


export type CreateStockAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockAdjustment'>
);

export type UpdateStockAdjustmentMutationVariables = Exact<{
  input: StockAdjustmentInput;
  itemInput?: Maybe<Array<StockAdjustmentItemInput> | StockAdjustmentItemInput>;
}>;


export type UpdateStockAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockAdjustment'>
);

export type CreateStockAdjustmentItemMutationVariables = Exact<{
  input: StockAdjustmentItemInput;
}>;


export type CreateStockAdjustmentItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockAdjustmentItem: (
    { __typename?: 'StockAdjustmentItemEntity' }
    & Pick<StockAdjustmentItemEntity, 'StockAdjustmentItemID'>
  ) }
);

export type DeleteStockAdjustmentMutationVariables = Exact<{
  StockAdjustmentID: Scalars['String'];
}>;


export type DeleteStockAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockAdjustment'>
);

export type PostStockAdjustmentMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockAdjustmentRejectInput;
}>;


export type PostStockAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockAdjustment'>
);

export type GetStockCostAdjustmentQueryVariables = Exact<{
  StockCostAdjustmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockCostAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getStockCostAdjustment: Array<(
    { __typename?: 'StockCostAdjustmentEntity' }
    & Pick<StockCostAdjustmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockCostAdjustmentID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, StockCostAdjustmentItem?: Maybe<Array<(
      { __typename?: 'StockCostAdjustmentItemEntity' }
      & Pick<StockCostAdjustmentItemEntity, 'StockCostAdjustmentItemID' | 'Cost' | 'TransactionTypeID' | 'StockItemID' | 'ReasonID' | 'Remark'>
      & { TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Code'>
        )> }
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
      )> }
    )>> }
  )> }
);

export type GetStockCostAdjustmentbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockCostAdjustmentbyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockCostAdjustmentbyStatus: Array<(
    { __typename?: 'StockCostAdjustmentEntity' }
    & Pick<StockCostAdjustmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockCostAdjustmentID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, StockCostAdjustmentItem?: Maybe<Array<(
      { __typename?: 'StockCostAdjustmentItemEntity' }
      & Pick<StockCostAdjustmentItemEntity, 'StockCostAdjustmentItemID' | 'Cost' | 'StockItemID' | 'TransactionTypeID' | 'ReasonID' | 'Remark'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Code'>
        )> }
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
      )> }
    )>> }
  )> }
);

export type GetStockCostAdjustmentListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockCostAdjustmentListingQuery = (
  { __typename?: 'Query' }
  & { getStockCostAdjustmentListing: (
    { __typename?: 'StockCostAdjustmentType' }
    & Pick<StockCostAdjustmentType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'StockCostAdjustmentEntity' }
      & Pick<StockCostAdjustmentEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockCostAdjustmentID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockCostAdjustmentItem?: Maybe<Array<(
        { __typename?: 'StockCostAdjustmentItemEntity' }
        & Pick<StockCostAdjustmentItemEntity, 'StockCostAdjustmentItemID' | 'Cost' | 'StockItemID' | 'TransactionTypeID' | 'ReasonID' | 'Remark'>
        & { StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )>, TransactionType?: Maybe<(
          { __typename?: 'TransactionTypeEntity' }
          & Pick<TransactionTypeEntity, 'Name' | 'Type'>
        )>, Reason?: Maybe<(
          { __typename?: 'StockAdjustmentReasonEntity' }
          & Pick<StockAdjustmentReasonEntity, 'StockAdjustmentReasonID' | 'Reason'>
        )> }
      )>> }
    )> }
  ) }
);

export type CreateStockCostAdjustmentMutationVariables = Exact<{
  input: StockCostAdjustmentInput;
  itemInput?: Maybe<Array<StockCostAdjustmentItemInput> | StockCostAdjustmentItemInput>;
}>;


export type CreateStockCostAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { createStockCostAdjustment: (
    { __typename?: 'StockCostAdjustmentEntity' }
    & Pick<StockCostAdjustmentEntity, 'StockCostAdjustmentID'>
  ) }
);

export type UpdateStockCostAdjustmentMutationVariables = Exact<{
  input: StockCostAdjustmentInput;
  itemInput?: Maybe<Array<StockCostAdjustmentItemInput> | StockCostAdjustmentItemInput>;
}>;


export type UpdateStockCostAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { updateStockCostAdjustment: (
    { __typename?: 'StockCostAdjustmentEntity' }
    & Pick<StockCostAdjustmentEntity, 'StockCostAdjustmentID'>
  ) }
);

export type DeleteStockCostAdjustmentMutationVariables = Exact<{
  StockCostAdjustmentID: Scalars['String'];
}>;


export type DeleteStockCostAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockCostAdjustment'>
);

export type PostStockCostAdjustmentMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockCostAdjustmentRejectInput;
}>;


export type PostStockCostAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockCostAdjustment'>
);

export type GetStockIssueQueryVariables = Exact<{
  StockIssueID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockIssueQuery = (
  { __typename?: 'Query' }
  & { getStockIssue: Array<(
    { __typename?: 'StockIssueEntity' }
    & Pick<StockIssueEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockIssueID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'CostCentreID' | 'RefNo' | 'StockRequisitionID' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, StockIssueItem?: Maybe<Array<(
      { __typename?: 'StockIssueItemEntity' }
      & Pick<StockIssueItemEntity, 'StockIssueItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'Remark' | 'UnitCost'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetStockIssueListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockIssueListingQuery = (
  { __typename?: 'Query' }
  & { getStockIssueListing: (
    { __typename?: 'StockIssueType' }
    & Pick<StockIssueType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'StockIssueEntity' }
      & Pick<StockIssueEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockIssueID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'StockRequisitionID' | 'WarehouseID' | 'Description' | 'ApprovalStatus'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockIssueItem?: Maybe<Array<(
        { __typename?: 'StockIssueItemEntity' }
        & Pick<StockIssueItemEntity, 'StockIssueItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'TotalCost' | 'DefaultUOMTotalCost' | 'Remark' | 'UnitCost'>
        & { StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )>, TransactionType?: Maybe<(
          { __typename?: 'TransactionTypeEntity' }
          & Pick<TransactionTypeEntity, 'Name' | 'Type'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )> }
      )>> }
    )> }
  ) }
);

export type GetStockIssueItemQueryVariables = Exact<{
  StockIssueID?: Maybe<Scalars['String']>;
}>;


export type GetStockIssueItemQuery = (
  { __typename?: 'Query' }
  & { getStockIssueItem: Array<(
    { __typename?: 'StockIssueItemEntity' }
    & Pick<StockIssueItemEntity, 'StockIssueItemID' | 'StockItemID' | 'UOMID' | 'Quantity' | 'TransactionTypeID'>
  )> }
);

export type GetStockIssuebyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockIssuebyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockIssuebyStatus: Array<(
    { __typename?: 'StockIssueEntity' }
    & Pick<StockIssueEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockIssueID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'StockRequisitionID' | 'WarehouseID' | 'Description' | 'ApprovalStatus'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockIssueItem?: Maybe<Array<(
      { __typename?: 'StockIssueItemEntity' }
      & Pick<StockIssueItemEntity, 'StockIssueItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'TotalCost' | 'DefaultUOMTotalCost' | 'Remark' | 'UnitCost'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type CreateStockIssueMutationVariables = Exact<{
  input: StockIssueInput;
  itemInput?: Maybe<Array<StockIssueItemInput> | StockIssueItemInput>;
}>;


export type CreateStockIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockIssue'>
);

export type CreateStockIssueItemMutationVariables = Exact<{
  input: StockIssueItemInput;
}>;


export type CreateStockIssueItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockIssueItem: (
    { __typename?: 'StockIssueItemEntity' }
    & Pick<StockIssueItemEntity, 'StockIssueItemID'>
  ) }
);

export type UpdateStockIssueMutationVariables = Exact<{
  input: StockIssueInput;
  itemInput?: Maybe<Array<StockIssueItemInput> | StockIssueItemInput>;
}>;


export type UpdateStockIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockIssue'>
);

export type DeleteStockIssueMutationVariables = Exact<{
  StockIssueID: Scalars['String'];
}>;


export type DeleteStockIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockIssue'>
);

export type PostStockIssueMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockIssueRejectInput;
}>;


export type PostStockIssueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockIssue'>
);

export type GetStockReceiptQueryVariables = Exact<{
  StockReceiptID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockReceiptQuery = (
  { __typename?: 'Query' }
  & { getStockReceipt: Array<(
    { __typename?: 'StockReceiptEntity' }
    & Pick<StockReceiptEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockReceiptID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted' | 'CostCentreID'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockReceiptItem?: Maybe<Array<(
      { __typename?: 'StockReceiptItemEntity' }
      & Pick<StockReceiptItemEntity, 'StockReceiptID' | 'StockReceiptItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'Remark'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetStockReceiptbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockReceiptbyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockReceiptbyStatus: Array<(
    { __typename?: 'StockReceiptEntity' }
    & Pick<StockReceiptEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockReceiptID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockReceiptItem?: Maybe<Array<(
      { __typename?: 'StockReceiptItemEntity' }
      & Pick<StockReceiptItemEntity, 'StockReceiptItemID' | 'StockReceiptID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'DefaultUOMTotalCost' | 'Remark'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetStockReceiptListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockReceiptListingQuery = (
  { __typename?: 'Query' }
  & { getStockReceiptListing: (
    { __typename?: 'StockReceiptType' }
    & Pick<StockReceiptType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'StockReceiptEntity' }
      & Pick<StockReceiptEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockReceiptID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockReceiptItem?: Maybe<Array<(
        { __typename?: 'StockReceiptItemEntity' }
        & Pick<StockReceiptItemEntity, 'StockReceiptItemID' | 'StockReceiptID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'DefaultUOMTotalCost' | 'Remark'>
        & { StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )>, TransactionType?: Maybe<(
          { __typename?: 'TransactionTypeEntity' }
          & Pick<TransactionTypeEntity, 'Name' | 'Type'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )> }
      )>> }
    )> }
  ) }
);

export type GetStockReceiptItemQueryVariables = Exact<{
  StockReceiptID?: Maybe<Scalars['String']>;
}>;


export type GetStockReceiptItemQuery = (
  { __typename?: 'Query' }
  & { getStockReceiptItem: Array<(
    { __typename?: 'StockReceiptItemEntity' }
    & Pick<StockReceiptItemEntity, 'StockReceiptItemID' | 'StockReceiptID' | 'StockItemID' | 'UOMID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'TransactionTypeID'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name'>
    )> }
  )> }
);

export type CreateStockReceiptMutationVariables = Exact<{
  input: StockReceiptInput;
  itemInput?: Maybe<Array<StockReceiptItemInput> | StockReceiptItemInput>;
}>;


export type CreateStockReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockReceipt'>
);

export type UpdateStockReceiptMutationVariables = Exact<{
  input: StockReceiptInput;
  itemInput?: Maybe<Array<StockReceiptItemInput> | StockReceiptItemInput>;
}>;


export type UpdateStockReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockReceipt'>
);

export type CreateStockReceiptItemMutationVariables = Exact<{
  input: StockReceiptItemInput;
}>;


export type CreateStockReceiptItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockReceiptItem: (
    { __typename?: 'StockReceiptItemEntity' }
    & Pick<StockReceiptItemEntity, 'StockReceiptItemID'>
  ) }
);

export type DeleteStockReceiptMutationVariables = Exact<{
  StockReceiptID: Scalars['String'];
}>;


export type DeleteStockReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockReceipt'>
);

export type PostStockReceiptMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockReceiptRejectInput;
}>;


export type PostStockReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockReceipt'>
);

export type GetStockRequisitionQueryVariables = Exact<{
  StockRequisitionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockRequisitionQuery = (
  { __typename?: 'Query' }
  & { getStockRequisition: Array<(
    { __typename?: 'StockRequisitionEntity' }
    & Pick<StockRequisitionEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockRequisitionID' | 'CompanyID' | 'CostCentreID' | 'DocNo' | 'RequestDate' | 'ExpectedDate' | 'RefNo' | 'WarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, CostCentre?: Maybe<(
      { __typename?: 'CostCentreEntity' }
      & Pick<CostCentreEntity, 'Name'>
    )>, StockRequisitionItem?: Maybe<Array<(
      { __typename?: 'StockRequisitionItemEntity' }
      & Pick<StockRequisitionItemEntity, 'StockRequisitionItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'Remark'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetStockRequisitionItemQueryVariables = Exact<{
  StockRequisitionID?: Maybe<Scalars['String']>;
}>;


export type GetStockRequisitionItemQuery = (
  { __typename?: 'Query' }
  & { getStockRequisitionItem: Array<(
    { __typename?: 'StockRequisitionItemEntity' }
    & Pick<StockRequisitionItemEntity, 'StockRequisitionItemID' | 'StockItemID' | 'UOMID' | 'Quantity' | 'TransactionTypeID'>
  )> }
);

export type GetStockRequisitionbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockRequisitionbyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockRequisitionbyStatus: Array<(
    { __typename?: 'StockRequisitionEntity' }
    & Pick<StockRequisitionEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockRequisitionID' | 'CompanyID' | 'DocNo' | 'RequestDate' | 'ExpectedDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockRequisitionItem?: Maybe<Array<(
      { __typename?: 'StockRequisitionItemEntity' }
      & Pick<StockRequisitionItemEntity, 'StockRequisitionItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'TotalCost' | 'DefaultUOMTotalCost' | 'Remark'>
      & { StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )>, TransactionType?: Maybe<(
        { __typename?: 'TransactionTypeEntity' }
        & Pick<TransactionTypeEntity, 'Name' | 'Type'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )> }
    )>>, StockIssue?: Maybe<Array<(
      { __typename?: 'StockIssueEntity' }
      & Pick<StockIssueEntity, 'StockIssueID' | 'RefNo' | 'ApprovalStatus'>
    )>> }
  )> }
);

export type GetStockRequisitionListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { getStockRequisitionListing?: Maybe<(
    { __typename?: 'StockRequisitionType' }
    & Pick<StockRequisitionType, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'StockRequisitionEntity' }
      & Pick<StockRequisitionEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockRequisitionID' | 'CompanyID' | 'DocNo' | 'RequestDate' | 'ExpectedDate' | 'RefNo' | 'CostCentreID' | 'WarehouseID' | 'Description' | 'ApprovalStatus'>
      & { Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockRequisitionItem?: Maybe<Array<(
        { __typename?: 'StockRequisitionItemEntity' }
        & Pick<StockRequisitionItemEntity, 'StockRequisitionItemID' | 'StockItemID' | 'TransactionTypeID' | 'BaseUOMID' | 'UOMID' | 'Quantity' | 'TotalCost' | 'DefaultUOMTotalCost' | 'Remark'>
        & { StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )>, TransactionType?: Maybe<(
          { __typename?: 'TransactionTypeEntity' }
          & Pick<TransactionTypeEntity, 'Name' | 'Type'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )> }
      )>>, StockIssue?: Maybe<Array<(
        { __typename?: 'StockIssueEntity' }
        & Pick<StockIssueEntity, 'StockIssueID' | 'RefNo' | 'ApprovalStatus'>
      )>> }
    )>> }
  )> }
);

export type CreateStockRequisitionMutationVariables = Exact<{
  input: StockRequisitionInput;
  itemInput?: Maybe<Array<StockRequisitionItemInput> | StockRequisitionItemInput>;
}>;


export type CreateStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockRequisition'>
);

export type CreateStockRequisitionItemMutationVariables = Exact<{
  input: StockRequisitionItemInput;
}>;


export type CreateStockRequisitionItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockRequisitionItem: (
    { __typename?: 'StockRequisitionItemEntity' }
    & Pick<StockRequisitionItemEntity, 'StockRequisitionItemID'>
  ) }
);

export type UpdateStockRequisitionMutationVariables = Exact<{
  input: StockRequisitionInput;
  itemInput?: Maybe<Array<StockRequisitionItemInput> | StockRequisitionItemInput>;
}>;


export type UpdateStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockRequisition'>
);

export type DeleteStockRequisitionMutationVariables = Exact<{
  StockRequisitionID: Scalars['String'];
}>;


export type DeleteStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockRequisition'>
);

export type PostStockRequisitionMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockRequisitionRejectInput;
}>;


export type PostStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockRequisition'>
);

export type CancelStockRequisitionMutationVariables = Exact<{
  input: StockRequisitionCancelInput;
}>;


export type CancelStockRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelStockRequisition'>
);

export type GetStockTransferQueryVariables = Exact<{
  StockTransferID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockTransferQuery = (
  { __typename?: 'Query' }
  & { getStockTransfer: Array<(
    { __typename?: 'StockTransferEntity' }
    & Pick<StockTransferEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockTransferID' | 'StockTransferRequisitionID' | 'CompanyID' | 'CostCentreID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'FromWarehouseID' | 'ToWarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { FromWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, ToWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockTransferItem?: Maybe<Array<(
      { __typename?: 'StockTransferItemEntity' }
      & Pick<StockTransferItemEntity, 'Quantity' | 'BaseUOMID' | 'UOMID' | 'UnitCost' | 'StockItemID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'Reason'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetStockTransferbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockTransferbyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockTransferbyStatus: Array<(
    { __typename?: 'StockTransferEntity' }
    & Pick<StockTransferEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockTransferID' | 'StockTransferRequisitionID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'FromWarehouseID' | 'ToWarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { FromWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, ToWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockTransferItem?: Maybe<Array<(
      { __typename?: 'StockTransferItemEntity' }
      & Pick<StockTransferItemEntity, 'StockTransferItemID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'BaseUOMID' | 'DefaultUOMTotalCost' | 'ReasonID' | 'UOMID' | 'StockItemID'>
      & { Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'Reason'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetStockTransferListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockTransferListingQuery = (
  { __typename?: 'Query' }
  & { getStockTransferListing: (
    { __typename?: 'StockTransferType' }
    & Pick<StockTransferType, 'TotalLength'>
    & { Data: Array<(
      { __typename?: 'StockTransferEntity' }
      & Pick<StockTransferEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockTransferID' | 'StockTransferRequisitionID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'TransactionDate' | 'RefNo' | 'CostCentreID' | 'FromWarehouseID' | 'ToWarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
      & { FromWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, ToWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockTransferItem?: Maybe<Array<(
        { __typename?: 'StockTransferItemEntity' }
        & Pick<StockTransferItemEntity, 'StockTransferItemID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'BaseUOMID' | 'DefaultUOMTotalCost' | 'ReasonID' | 'UOMID' | 'StockItemID'>
        & { Reason?: Maybe<(
          { __typename?: 'StockAdjustmentReasonEntity' }
          & Pick<StockAdjustmentReasonEntity, 'Reason'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )>, StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )> }
      )>> }
    )> }
  ) }
);

export type GetStockTransferItemQueryVariables = Exact<{
  StockTransferID?: Maybe<Scalars['String']>;
}>;


export type GetStockTransferItemQuery = (
  { __typename?: 'Query' }
  & { getStockTransferItem: Array<(
    { __typename?: 'StockTransferItemEntity' }
    & Pick<StockTransferItemEntity, 'StockTransferItemID' | 'StockItemID' | 'UOMID' | 'Quantity' | 'UnitCost'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name'>
    )> }
  )> }
);

export type CreateStockTransferMutationVariables = Exact<{
  input: StockTransferInput;
  itemInput?: Maybe<Array<StockTransferItemInput> | StockTransferItemInput>;
}>;


export type CreateStockTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStockTransfer'>
);

export type UpdateStockTransferMutationVariables = Exact<{
  input: StockTransferInput;
  itemInput?: Maybe<Array<StockTransferItemInput> | StockTransferItemInput>;
}>;


export type UpdateStockTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStockTransfer'>
);

export type CreateStockTransferItemMutationVariables = Exact<{
  input: StockTransferItemInput;
}>;


export type CreateStockTransferItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockTransferItem: (
    { __typename?: 'StockTransferItemEntity' }
    & Pick<StockTransferItemEntity, 'StockTransferItemID'>
  ) }
);

export type DeleteStockTransferMutationVariables = Exact<{
  StockTransferID: Scalars['String'];
}>;


export type DeleteStockTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockTransfer'>
);

export type PostStockTransferMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockTransferRejectInput;
}>;


export type PostStockTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockTransfer'>
);

export type GetStockTransferRequisitionQueryVariables = Exact<{
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetStockTransferRequisitionQuery = (
  { __typename?: 'Query' }
  & { getStockTransferRequisition: Array<(
    { __typename?: 'StockTransferRequisitionEntity' }
    & Pick<StockTransferRequisitionEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockTransferRequisitionID' | 'CompanyID' | 'CostCentreID' | 'DocNo' | 'RequestDate' | 'ExpectedDate' | 'RefNo' | 'FromWarehouseID' | 'ToWarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { FromWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, ToWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockTransferRequisitionItem?: Maybe<Array<(
      { __typename?: 'StockTransferRequisitionItemEntity' }
      & Pick<StockTransferRequisitionItemEntity, 'Quantity' | 'BaseUOMID' | 'UOMID' | 'UnitCost' | 'StockItemID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )>, Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'Reason'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type GetStockTransferRequisitionbyStatusQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetStockTransferRequisitionbyStatusQuery = (
  { __typename?: 'Query' }
  & { getStockTransferRequisitionbyStatus: Array<(
    { __typename?: 'StockTransferRequisitionEntity' }
    & Pick<StockTransferRequisitionEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockTransferRequisitionID' | 'CompanyID' | 'DocNo' | 'RequestDate' | 'ExpectedDate' | 'RefNo' | 'CostCentreID' | 'FromWarehouseID' | 'ToWarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
    & { FromWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, ToWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, StockTransferRequisitionItem?: Maybe<Array<(
      { __typename?: 'StockTransferRequisitionItemEntity' }
      & Pick<StockTransferRequisitionItemEntity, 'StockTransferRequisitionItemID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'BaseUOMID' | 'DefaultUOMTotalCost' | 'ReasonID' | 'UOMID' | 'StockItemID'>
      & { Reason?: Maybe<(
        { __typename?: 'StockAdjustmentReasonEntity' }
        & Pick<StockAdjustmentReasonEntity, 'Reason'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name'>
      )>, StockItem?: Maybe<(
        { __typename?: 'StockItemEntity' }
        & Pick<StockItemEntity, 'Name'>
      )> }
    )>>, StockTransfer?: Maybe<Array<(
      { __typename?: 'StockTransferEntity' }
      & Pick<StockTransferEntity, 'StockTransferID'>
    )>> }
  )> }
);

export type GetStockTransferRequisitionListingQueryVariables = Exact<{
  WarehouseID: Scalars['String'];
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  StatusArr: Array<Scalars['String']> | Scalars['String'];
  sortOrder?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
}>;


export type GetStockTransferRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { getStockTransferRequisitionListing?: Maybe<(
    { __typename?: 'StockTransferRequisitionType' }
    & Pick<StockTransferRequisitionType, 'TotalLength'>
    & { Data?: Maybe<Array<(
      { __typename?: 'StockTransferRequisitionEntity' }
      & Pick<StockTransferRequisitionEntity, 'createdTs' | 'modTs' | 'modBy' | 'submittedBy' | 'submittedTs' | 'approvedBy' | 'approvedTs' | 'createdBy' | 'StockTransferRequisitionID' | 'CompanyID' | 'DocNo' | 'RequestDate' | 'ExpectedDate' | 'RefNo' | 'CostCentreID' | 'FromWarehouseID' | 'ToWarehouseID' | 'Description' | 'ApprovalStatus' | 'IsGLPosted'>
      & { FromWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, ToWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name'>
      )>, StockTransferRequisitionItem?: Maybe<Array<(
        { __typename?: 'StockTransferRequisitionItemEntity' }
        & Pick<StockTransferRequisitionItemEntity, 'StockTransferRequisitionItemID' | 'Quantity' | 'UnitCost' | 'TotalCost' | 'BaseUOMID' | 'DefaultUOMTotalCost' | 'ReasonID' | 'UOMID' | 'StockItemID'>
        & { Reason?: Maybe<(
          { __typename?: 'StockAdjustmentReasonEntity' }
          & Pick<StockAdjustmentReasonEntity, 'Reason'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'Name'>
        )>, StockItem?: Maybe<(
          { __typename?: 'StockItemEntity' }
          & Pick<StockItemEntity, 'Name'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetStockTransferRequisitionItemQueryVariables = Exact<{
  StockTransferRequisitionID?: Maybe<Scalars['String']>;
}>;


export type GetStockTransferRequisitionItemQuery = (
  { __typename?: 'Query' }
  & { getStockTransferRequisitionItem: Array<(
    { __typename?: 'StockTransferRequisitionItemEntity' }
    & Pick<StockTransferRequisitionItemEntity, 'StockTransferRequisitionItemID' | 'StockItemID' | 'UOMID' | 'Quantity' | 'UnitCost'>
    & { StockItem?: Maybe<(
      { __typename?: 'StockItemEntity' }
      & Pick<StockItemEntity, 'Name'>
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name'>
    )> }
  )> }
);

export type CreateStockTransferRequisitionMutationVariables = Exact<{
  input: StockTransferRequisitionInput;
  itemInput?: Maybe<Array<StockTransferRequisitionItemInput> | StockTransferRequisitionItemInput>;
}>;


export type CreateStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & { createStockTransferRequisition: (
    { __typename?: 'StockTransferRequisitionEntity' }
    & Pick<StockTransferRequisitionEntity, 'StockTransferRequisitionID'>
  ) }
);

export type UpdateStockTransferRequisitionMutationVariables = Exact<{
  input: StockTransferRequisitionInput;
  itemInput?: Maybe<Array<StockTransferRequisitionItemInput> | StockTransferRequisitionItemInput>;
}>;


export type UpdateStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & { updateStockTransferRequisition: (
    { __typename?: 'StockTransferRequisitionEntity' }
    & Pick<StockTransferRequisitionEntity, 'StockTransferRequisitionID'>
  ) }
);

export type CreateStockTransferRequisitionItemMutationVariables = Exact<{
  input: StockTransferRequisitionItemInput;
}>;


export type CreateStockTransferRequisitionItemMutation = (
  { __typename?: 'Mutation' }
  & { createStockTransferRequisitionItem: (
    { __typename?: 'StockTransferRequisitionItemEntity' }
    & Pick<StockTransferRequisitionItemEntity, 'StockTransferRequisitionItemID'>
  ) }
);

export type DeleteStockTransferRequisitionMutationVariables = Exact<{
  StockTransferRequisitionID: Scalars['String'];
}>;


export type DeleteStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStockTransferRequisition'>
);

export type PostStockTransferRequisitionMutationVariables = Exact<{
  postIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: Scalars['String'];
  CompanyID: Scalars['String'];
  input: StockTransferRequisitionRejectInput;
}>;


export type PostStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postStockTransferRequisition'>
);

export type CancelStockTransferRequisitionMutationVariables = Exact<{
  input: StockTransferRequisitionCancelInput;
}>;


export type CancelStockTransferRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelStockTransferRequisition'>
);

export type CreateAccountMappingMutationVariables = Exact<{
  input: AccountMappingInput;
}>;


export type CreateAccountMappingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAccountMapping'>
);

export type GetAccountMappingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetAccountMappingQuery = (
  { __typename?: 'Query' }
  & { getAccountMapping: Array<(
    { __typename?: 'GLMFRS16AccountMappingEntity' }
    & Pick<Glmfrs16AccountMappingEntity, 'CompanyID' | 'ROUMasterCOAID' | 'LeaseLiabilityMasterCOAID' | 'DepreciationROUMasterCOAID' | 'ProvisionDROUMasterCOAID' | 'InterestExpenseMasterCOAID' | 'LeaseLiabilitiesMasterCOAID' | 'AccrualLeaseLiabilitiesMasterCOAID' | 'GLTerminationMasterCOAID' | 'CapitalisationROUJournalTypeID' | 'ROUJournalTypeID' | 'LeaseLiabilitiesJournalTypeID'>
  )> }
);

export type CreateLeaseContractMutationVariables = Exact<{
  input: LeaseContractInput;
}>;


export type CreateLeaseContractMutation = (
  { __typename?: 'Mutation' }
  & { createLeaseContract: (
    { __typename?: 'GLMFRS16LeaseContractEntity' }
    & Pick<Glmfrs16LeaseContractEntity, 'LeaseContractID'>
  ) }
);

export type GetLeaseContractQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  LeaseContractID?: Maybe<Scalars['String']>;
  statusArr?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetLeaseContractQuery = (
  { __typename?: 'Query' }
  & { getLeaseContract: Array<(
    { __typename?: 'GLMFRS16LeaseContractEntity' }
    & Pick<Glmfrs16LeaseContractEntity, 'CompanyID' | 'LeaseContractID' | 'StartDate' | 'EndDate' | 'StartJournalDate' | 'LeaseMonth' | 'LeasePayment' | 'TotalLeaseAmount' | 'DiscountedTotalCashFlows' | 'AnnualDiscountRate' | 'ContractNo' | 'Description' | 'IsAccountROU' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'Remark'>
    & { Attachment?: Maybe<Array<(
      { __typename?: 'AttachmentInfo' }
      & Pick<AttachmentInfo, 'fileURL' | 'mediaType' | 'name'>
    )>> }
  )> }
);

export type UpdateLeaseContractMutationVariables = Exact<{
  input: LeaseContractInput;
}>;


export type UpdateLeaseContractMutation = (
  { __typename?: 'Mutation' }
  & { updateLeaseContract: (
    { __typename?: 'GLMFRS16LeaseContractEntity' }
    & Pick<Glmfrs16LeaseContractEntity, 'LeaseContractID'>
  ) }
);

export type PostLeaseContractMutationVariables = Exact<{
  LeaseContractID: Scalars['String'];
}>;


export type PostLeaseContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postLeaseContract'>
);

export type RejectLeaseContractMutationVariables = Exact<{
  LeaseContractID: Scalars['String'];
  input: LeaseContractRejectInput;
}>;


export type RejectLeaseContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectLeaseContract'>
);

export type GetToDoSummaryQueryVariables = Exact<{
  RoleID: Scalars['String'];
}>;


export type GetToDoSummaryQuery = (
  { __typename?: 'Query' }
  & { getToDoSummary: (
    { __typename?: 'PendingToDo' }
    & Pick<PendingToDo, 'PendingCB' | 'PendingGL' | 'PendingAR' | 'PendingAP'>
  ) }
);

export type GetWorkDeskHistoryQueryVariables = Exact<{
  tableIdentifier: Scalars['String'];
}>;


export type GetWorkDeskHistoryQuery = (
  { __typename?: 'Query' }
  & { getWorkDeskHistory: Array<(
    { __typename?: 'ToDo' }
    & Pick<ToDo, 'createdTs' | 'createdBy' | 'RefID' | 'DocDate' | 'RefTable' | 'CompanyID' | 'DocNo' | 'RefNo' | 'DocAmt' | 'Description'>
    & { WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetWorkDeskToDoQueryVariables = Exact<{
  tableIdentifier: Scalars['String'];
}>;


export type GetWorkDeskToDoQuery = (
  { __typename?: 'Query' }
  & { getWorkDeskToDo: Array<(
    { __typename?: 'ToDo' }
    & Pick<ToDo, 'createdTs' | 'createdBy' | 'RefID' | 'CompanyID' | 'CompanyName' | 'BankAccountID' | 'BankCode' | 'BankAccountNo' | 'FromBankAccountID' | 'FromBankCode' | 'FromBankAccountNo' | 'ToBankAccountID' | 'ToBankCode' | 'ToBankAccountNo' | 'DocDate' | 'RefTable' | 'DocNo' | 'RefNo' | 'DocAmt' | 'Description'>
    & { WorkFlow?: Maybe<Array<(
      { __typename?: 'AccWorkFlowEntity' }
      & Pick<AccWorkFlowEntity, 'WorkFlowID' | 'WorkFlowStatus' | 'SubmitDate'>
      & { SubmitterDetail?: Maybe<(
        { __typename?: 'UserEntity' }
        & Pick<UserEntity, 'ID' | 'name'>
      )>, WorkFlowStep?: Maybe<Array<(
        { __typename?: 'AccWorkFlowStepEntity' }
        & Pick<AccWorkFlowStepEntity, 'StepNo' | 'WorkFlowStepID' | 'ApprovalStatus' | 'ApproverRoleID'>
        & { RoleDetail?: Maybe<(
          { __typename?: 'AccRoleEntity' }
          & Pick<AccRoleEntity, 'ID' | 'name'>
        )>, WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'AccWorkFlowApprovedStepEntity' }
          & Pick<AccWorkFlowApprovedStepEntity, 'WorkFlowApprovedStepID'>
          & { ApproverDetail?: Maybe<(
            { __typename?: 'UserEntity' }
            & Pick<UserEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type ApproveRejectWorkDeskMutationVariables = Exact<{
  WorkDeskInput: Array<WorkDeskInput> | WorkDeskInput;
}>;


export type ApproveRejectWorkDeskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveRejectWorkDesk'>
);


export const GetApAdvanceDocument = gql`
    query getAPAdvance($AccountID: String, $CompanyID: String, $AdvanceID: String, $orderByDesc: String, $CreditorAccountID: String) {
  getAPAdvance(AccountID: $AccountID, CompanyID: $CompanyID, AdvanceID: $AdvanceID, orderByDesc: $orderByDesc, CreditorAccountID: $CreditorAccountID) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    AdvanceID
    DocDate
    TransactionDate
    RefNo
    DocNo
    CreditorAccountID
    CreditorAccount {
      Address
      CompanyName
      GSTRegNo
      BankAccountNo
      BankName
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      AccountNo
      BankAccountID
      BankProfile {
        Name
        Address
      }
      Code
    }
    PaymentID
    APPayment {
      PaymentID
      DocNo
      DocDate
    }
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    Amount
    DocAmt
    BalanceAmt
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApAdvanceQuery__
 *
 * To run a query within a React component, call `useGetApAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvanceQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      AdvanceID: // value for 'AdvanceID'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
      }
export function useGetApAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
        }
export type GetApAdvanceQueryHookResult = ReturnType<typeof useGetApAdvanceQuery>;
export type GetApAdvanceLazyQueryHookResult = ReturnType<typeof useGetApAdvanceLazyQuery>;
export type GetApAdvanceQueryResult = ApolloReactCommon.QueryResult<GetApAdvanceQuery, GetApAdvanceQueryVariables>;
export const CreateApAdvanceDocument = gql`
    mutation createAPAdvance($input: APAdvanceInput!) {
  createAPAdvance(input: $input) {
    AdvanceID
  }
}
    `;
export type CreateApAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>;

/**
 * __useCreateApAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApAdvanceMutation, { data, loading, error }] = useCreateApAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>(CreateApAdvanceDocument, baseOptions);
      }
export type CreateApAdvanceMutationHookResult = ReturnType<typeof useCreateApAdvanceMutation>;
export type CreateApAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateApAdvanceMutation>;
export type CreateApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>;
export const UpdateApAdvanceDocument = gql`
    mutation updateAPAdvance($input: APAdvanceInput!) {
  updateAPAdvance(input: $input) {
    AdvanceID
  }
}
    `;
export type UpdateApAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>;

/**
 * __useUpdateApAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApAdvanceMutation, { data, loading, error }] = useUpdateApAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>(UpdateApAdvanceDocument, baseOptions);
      }
export type UpdateApAdvanceMutationHookResult = ReturnType<typeof useUpdateApAdvanceMutation>;
export type UpdateApAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateApAdvanceMutation>;
export type UpdateApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>;
export const DeleteApAdvanceDocument = gql`
    mutation deleteAPAdvance($AdvanceID: String!) {
  deleteAPAdvance(AdvanceID: $AdvanceID)
}
    `;
export type DeleteApAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>;

/**
 * __useDeleteApAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApAdvanceMutation, { data, loading, error }] = useDeleteApAdvanceMutation({
 *   variables: {
 *      AdvanceID: // value for 'AdvanceID'
 *   },
 * });
 */
export function useDeleteApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>(DeleteApAdvanceDocument, baseOptions);
      }
export type DeleteApAdvanceMutationHookResult = ReturnType<typeof useDeleteApAdvanceMutation>;
export type DeleteApAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteApAdvanceMutation>;
export type DeleteApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>;
export const PostApAdvanceDocument = gql`
    mutation postAPAdvance($AdvanceIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPAdvance(AdvanceIDs: $AdvanceIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApAdvanceMutationFn = ApolloReactCommon.MutationFunction<PostApAdvanceMutation, PostApAdvanceMutationVariables>;

/**
 * __usePostApAdvanceMutation__
 *
 * To run a mutation, you first call `usePostApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApAdvanceMutation, { data, loading, error }] = usePostApAdvanceMutation({
 *   variables: {
 *      AdvanceIDs: // value for 'AdvanceIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApAdvanceMutation, PostApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApAdvanceMutation, PostApAdvanceMutationVariables>(PostApAdvanceDocument, baseOptions);
      }
export type PostApAdvanceMutationHookResult = ReturnType<typeof usePostApAdvanceMutation>;
export type PostApAdvanceMutationResult = ApolloReactCommon.MutationResult<PostApAdvanceMutation>;
export type PostApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApAdvanceMutation, PostApAdvanceMutationVariables>;
export const RejectApAdvanceDocument = gql`
    mutation rejectAPAdvance($AdvanceIDs: [String!]!, $input: APAdvanceRejectInput!) {
  rejectAPAdvance(AdvanceIDs: $AdvanceIDs, input: $input)
}
    `;
export type RejectApAdvanceMutationFn = ApolloReactCommon.MutationFunction<RejectApAdvanceMutation, RejectApAdvanceMutationVariables>;

/**
 * __useRejectApAdvanceMutation__
 *
 * To run a mutation, you first call `useRejectApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApAdvanceMutation, { data, loading, error }] = useRejectApAdvanceMutation({
 *   variables: {
 *      AdvanceIDs: // value for 'AdvanceIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApAdvanceMutation, RejectApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApAdvanceMutation, RejectApAdvanceMutationVariables>(RejectApAdvanceDocument, baseOptions);
      }
export type RejectApAdvanceMutationHookResult = ReturnType<typeof useRejectApAdvanceMutation>;
export type RejectApAdvanceMutationResult = ApolloReactCommon.MutationResult<RejectApAdvanceMutation>;
export type RejectApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApAdvanceMutation, RejectApAdvanceMutationVariables>;
export const GetApAdvancebyStatusDocument = gql`
    query getAPAdvancebyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String) {
  getAPAdvancebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    AdvanceID
    DocDate
    TransactionDate
    RefNo
    DocNo
    CreditorAccountID
    ProfileInfo
    IsAutoDocNo
    CreditorAccount {
      Address
      CompanyName
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    PaymentID
    APPayment {
      PaymentID
      DocNo
      DocDate
    }
    BankAccountID
    BankAccount {
      BankAccountID
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    Amount
    DocAmt
    BalanceAmt
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApAdvancebyStatusQuery__
 *
 * To run a query within a React component, call `useGetApAdvancebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvancebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvancebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApAdvancebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvancebyStatusQuery, GetApAdvancebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvancebyStatusQuery, GetApAdvancebyStatusQueryVariables>(GetApAdvancebyStatusDocument, baseOptions);
      }
export function useGetApAdvancebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvancebyStatusQuery, GetApAdvancebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvancebyStatusQuery, GetApAdvancebyStatusQueryVariables>(GetApAdvancebyStatusDocument, baseOptions);
        }
export type GetApAdvancebyStatusQueryHookResult = ReturnType<typeof useGetApAdvancebyStatusQuery>;
export type GetApAdvancebyStatusLazyQueryHookResult = ReturnType<typeof useGetApAdvancebyStatusLazyQuery>;
export type GetApAdvancebyStatusQueryResult = ApolloReactCommon.QueryResult<GetApAdvancebyStatusQuery, GetApAdvancebyStatusQueryVariables>;
export const GetApAdvancebyStatusListingDocument = gql`
    query getAPAdvancebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPAdvancebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    AdvanceID
    DocDate
    TransactionDate
    RefNo
    DocNo
    CreditorAccountID
    ProfileInfo
    IsAutoDocNo
    CreditorAccount {
      Address
      CompanyName
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    PaymentID
    APPayment {
      PaymentID
      DocNo
      DocDate
    }
    BankAccountID
    BankAccount {
      BankAccountID
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    Amount
    DocAmt
    BalanceAmt
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApAdvancebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApAdvancebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvancebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvancebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApAdvancebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvancebyStatusListingQuery, GetApAdvancebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvancebyStatusListingQuery, GetApAdvancebyStatusListingQueryVariables>(GetApAdvancebyStatusListingDocument, baseOptions);
      }
export function useGetApAdvancebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvancebyStatusListingQuery, GetApAdvancebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvancebyStatusListingQuery, GetApAdvancebyStatusListingQueryVariables>(GetApAdvancebyStatusListingDocument, baseOptions);
        }
export type GetApAdvancebyStatusListingQueryHookResult = ReturnType<typeof useGetApAdvancebyStatusListingQuery>;
export type GetApAdvancebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApAdvancebyStatusListingLazyQuery>;
export type GetApAdvancebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApAdvancebyStatusListingQuery, GetApAdvancebyStatusListingQueryVariables>;
export const GeneratePaymentDocument = gql`
    mutation generatePayment($input: APPaymentInput!) {
  generatePayment(input: $input) {
    PaymentID
  }
}
    `;
export type GeneratePaymentMutationFn = ApolloReactCommon.MutationFunction<GeneratePaymentMutation, GeneratePaymentMutationVariables>;

/**
 * __useGeneratePaymentMutation__
 *
 * To run a mutation, you first call `useGeneratePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePaymentMutation, { data, loading, error }] = useGeneratePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GeneratePaymentMutation, GeneratePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<GeneratePaymentMutation, GeneratePaymentMutationVariables>(GeneratePaymentDocument, baseOptions);
      }
export type GeneratePaymentMutationHookResult = ReturnType<typeof useGeneratePaymentMutation>;
export type GeneratePaymentMutationResult = ApolloReactCommon.MutationResult<GeneratePaymentMutation>;
export type GeneratePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<GeneratePaymentMutation, GeneratePaymentMutationVariables>;
export const GetApAllocationDocumentDocument = gql`
    query getAPAllocationDocument($entityID: [String!], $refTable: String!, $companyID: String!, $creditorDebtorID: String, $isEinvoiceSelfBilled: Boolean) {
  getAllocationDocument(entityID: $entityID, refTable: $refTable, companyID: $companyID, creditorDebtorID: $creditorDebtorID, isEinvoiceSelfBilled: $isEinvoiceSelfBilled)
}
    `;

/**
 * __useGetApAllocationDocumentQuery__
 *
 * To run a query within a React component, call `useGetApAllocationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAllocationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAllocationDocumentQuery({
 *   variables: {
 *      entityID: // value for 'entityID'
 *      refTable: // value for 'refTable'
 *      companyID: // value for 'companyID'
 *      creditorDebtorID: // value for 'creditorDebtorID'
 *      isEinvoiceSelfBilled: // value for 'isEinvoiceSelfBilled'
 *   },
 * });
 */
export function useGetApAllocationDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAllocationDocumentQuery, GetApAllocationDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAllocationDocumentQuery, GetApAllocationDocumentQueryVariables>(GetApAllocationDocumentDocument, baseOptions);
      }
export function useGetApAllocationDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAllocationDocumentQuery, GetApAllocationDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAllocationDocumentQuery, GetApAllocationDocumentQueryVariables>(GetApAllocationDocumentDocument, baseOptions);
        }
export type GetApAllocationDocumentQueryHookResult = ReturnType<typeof useGetApAllocationDocumentQuery>;
export type GetApAllocationDocumentLazyQueryHookResult = ReturnType<typeof useGetApAllocationDocumentLazyQuery>;
export type GetApAllocationDocumentQueryResult = ApolloReactCommon.QueryResult<GetApAllocationDocumentQuery, GetApAllocationDocumentQueryVariables>;
export const GetApAllocationItemDocumentDocument = gql`
    query getAPAllocationItemDocument($refTable: String!, $documentID: String!, $entityItemID: [String!]) {
  getAllocationItemDocument(refTable: $refTable, documentID: $documentID, entityItemID: $entityItemID)
}
    `;

/**
 * __useGetApAllocationItemDocumentQuery__
 *
 * To run a query within a React component, call `useGetApAllocationItemDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAllocationItemDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAllocationItemDocumentQuery({
 *   variables: {
 *      refTable: // value for 'refTable'
 *      documentID: // value for 'documentID'
 *      entityItemID: // value for 'entityItemID'
 *   },
 * });
 */
export function useGetApAllocationItemDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAllocationItemDocumentQuery, GetApAllocationItemDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAllocationItemDocumentQuery, GetApAllocationItemDocumentQueryVariables>(GetApAllocationItemDocumentDocument, baseOptions);
      }
export function useGetApAllocationItemDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAllocationItemDocumentQuery, GetApAllocationItemDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAllocationItemDocumentQuery, GetApAllocationItemDocumentQueryVariables>(GetApAllocationItemDocumentDocument, baseOptions);
        }
export type GetApAllocationItemDocumentQueryHookResult = ReturnType<typeof useGetApAllocationItemDocumentQuery>;
export type GetApAllocationItemDocumentLazyQueryHookResult = ReturnType<typeof useGetApAllocationItemDocumentLazyQuery>;
export type GetApAllocationItemDocumentQueryResult = ApolloReactCommon.QueryResult<GetApAllocationItemDocumentQuery, GetApAllocationItemDocumentQueryVariables>;
export const CreateApAllocationDocument = gql`
    mutation createAPAllocation($input: [AllocationInput!], $entityID: String) {
  createAllocation(input: $input, entityID: $entityID)
}
    `;
export type CreateApAllocationMutationFn = ApolloReactCommon.MutationFunction<CreateApAllocationMutation, CreateApAllocationMutationVariables>;

/**
 * __useCreateApAllocationMutation__
 *
 * To run a mutation, you first call `useCreateApAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApAllocationMutation, { data, loading, error }] = useCreateApAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useCreateApAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApAllocationMutation, CreateApAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApAllocationMutation, CreateApAllocationMutationVariables>(CreateApAllocationDocument, baseOptions);
      }
export type CreateApAllocationMutationHookResult = ReturnType<typeof useCreateApAllocationMutation>;
export type CreateApAllocationMutationResult = ApolloReactCommon.MutationResult<CreateApAllocationMutation>;
export type CreateApAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApAllocationMutation, CreateApAllocationMutationVariables>;
export const GetDocumentDetailDocument = gql`
    query getDocumentDetail($expBillItem: String!, $expBillItemID: String!, $refTable: String!, $refTableItem: String!, $prefix: String!, $prefixID: String!) {
  getDocumentDetail(expBillItem: $expBillItem, expBillItemID: $expBillItemID, refTable: $refTable, refTableItem: $refTableItem, prefix: $prefix, prefixID: $prefixID)
}
    `;

/**
 * __useGetDocumentDetailQuery__
 *
 * To run a query within a React component, call `useGetDocumentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentDetailQuery({
 *   variables: {
 *      expBillItem: // value for 'expBillItem'
 *      expBillItemID: // value for 'expBillItemID'
 *      refTable: // value for 'refTable'
 *      refTableItem: // value for 'refTableItem'
 *      prefix: // value for 'prefix'
 *      prefixID: // value for 'prefixID'
 *   },
 * });
 */
export function useGetDocumentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentDetailQuery, GetDocumentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentDetailQuery, GetDocumentDetailQueryVariables>(GetDocumentDetailDocument, baseOptions);
      }
export function useGetDocumentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentDetailQuery, GetDocumentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentDetailQuery, GetDocumentDetailQueryVariables>(GetDocumentDetailDocument, baseOptions);
        }
export type GetDocumentDetailQueryHookResult = ReturnType<typeof useGetDocumentDetailQuery>;
export type GetDocumentDetailLazyQueryHookResult = ReturnType<typeof useGetDocumentDetailLazyQuery>;
export type GetDocumentDetailQueryResult = ApolloReactCommon.QueryResult<GetDocumentDetailQuery, GetDocumentDetailQueryVariables>;
export const GetApItemDocument = gql`
    query getAPItem($refID: String!) {
  getAPInvoiceItem(InvoiceID: $refID) {
    InvoiceItemID
    BalanceAmt
    TaxSchemeID
    TaxRate
    TaxAmt
    DocAmt
    TaxScheme {
      TaxSchemeID
      Code
      LatestTax {
        Rate
      }
    }
    CostCentreID
    CostCentre {
      CostCentreID
      Name
    }
    ExpenseItemID
    ExpenseItem {
      ExpenseItemID
      ItemName
    }
  }
  getAPDebitNoteItem(DebitNoteID: $refID) {
    DebitNoteItemID
    BalanceAmt
    TaxSchemeID
    TaxRate
    TaxAmt
    DocAmt
    TaxScheme {
      TaxSchemeID
      Code
      LatestTax {
        Rate
      }
    }
    CostCentreID
    CostCentre {
      CostCentreID
      Name
    }
    ExpenseItemID
    ExpenseItem {
      ExpenseItemID
      ItemName
    }
  }
}
    `;

/**
 * __useGetApItemQuery__
 *
 * To run a query within a React component, call `useGetApItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApItemQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useGetApItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApItemQuery, GetApItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApItemQuery, GetApItemQueryVariables>(GetApItemDocument, baseOptions);
      }
export function useGetApItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApItemQuery, GetApItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApItemQuery, GetApItemQueryVariables>(GetApItemDocument, baseOptions);
        }
export type GetApItemQueryHookResult = ReturnType<typeof useGetApItemQuery>;
export type GetApItemLazyQueryHookResult = ReturnType<typeof useGetApItemLazyQuery>;
export type GetApItemQueryResult = ApolloReactCommon.QueryResult<GetApItemQuery, GetApItemQueryVariables>;
export const GetApBatchPaymentDocument = gql`
    query getAPBatchPayment($AccountID: String, $CompanyID: String, $BatchPaymentID: String) {
  getAPBatchPayment(AccountID: $AccountID, CompanyID: $CompanyID, BatchPaymentID: $BatchPaymentID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CompanyID
    BatchPaymentID
    DocDate
    TransactionDate
    DocAmt
    Description
    BankAccountID
    ApprovalStatus
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    APPayment {
      PaymentID
      DocAmt
      ApprovalStatus
      CreditorAccountID
      CreditorAccount {
        CompanyName
      }
      DocDate
      DocNo
      DocAmt
      Description
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitRefTable
        DebitID
        CreditDocNo
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
        DebitDocDate
        CreditDocDate
        Description
      }
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitRefTable
      DebitID
      CreditDocNo
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
      DebitDocDate
      CreditDocDate
      Description
    }
    RefNo
    Attachment {
      fileURL
      mediaType
      name
    }
  }
}
    `;

/**
 * __useGetApBatchPaymentQuery__
 *
 * To run a query within a React component, call `useGetApBatchPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApBatchPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApBatchPaymentQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      BatchPaymentID: // value for 'BatchPaymentID'
 *   },
 * });
 */
export function useGetApBatchPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApBatchPaymentQuery, GetApBatchPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApBatchPaymentQuery, GetApBatchPaymentQueryVariables>(GetApBatchPaymentDocument, baseOptions);
      }
export function useGetApBatchPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApBatchPaymentQuery, GetApBatchPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApBatchPaymentQuery, GetApBatchPaymentQueryVariables>(GetApBatchPaymentDocument, baseOptions);
        }
export type GetApBatchPaymentQueryHookResult = ReturnType<typeof useGetApBatchPaymentQuery>;
export type GetApBatchPaymentLazyQueryHookResult = ReturnType<typeof useGetApBatchPaymentLazyQuery>;
export type GetApBatchPaymentQueryResult = ApolloReactCommon.QueryResult<GetApBatchPaymentQuery, GetApBatchPaymentQueryVariables>;
export const CreateApBatchPaymentWithAllocationDocument = gql`
    mutation createAPBatchPaymentWithAllocation($input: APBatchPaymentInput!, $allocationInput: [AllocationInput!]!) {
  createAPBatchPaymentWithAllocation(input: $input, allocationInput: $allocationInput)
}
    `;
export type CreateApBatchPaymentWithAllocationMutationFn = ApolloReactCommon.MutationFunction<CreateApBatchPaymentWithAllocationMutation, CreateApBatchPaymentWithAllocationMutationVariables>;

/**
 * __useCreateApBatchPaymentWithAllocationMutation__
 *
 * To run a mutation, you first call `useCreateApBatchPaymentWithAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApBatchPaymentWithAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApBatchPaymentWithAllocationMutation, { data, loading, error }] = useCreateApBatchPaymentWithAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useCreateApBatchPaymentWithAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApBatchPaymentWithAllocationMutation, CreateApBatchPaymentWithAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApBatchPaymentWithAllocationMutation, CreateApBatchPaymentWithAllocationMutationVariables>(CreateApBatchPaymentWithAllocationDocument, baseOptions);
      }
export type CreateApBatchPaymentWithAllocationMutationHookResult = ReturnType<typeof useCreateApBatchPaymentWithAllocationMutation>;
export type CreateApBatchPaymentWithAllocationMutationResult = ApolloReactCommon.MutationResult<CreateApBatchPaymentWithAllocationMutation>;
export type CreateApBatchPaymentWithAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApBatchPaymentWithAllocationMutation, CreateApBatchPaymentWithAllocationMutationVariables>;
export const UpdateApBatchPaymentWithAllocationDocument = gql`
    mutation updateAPBatchPaymentWithAllocation($input: APBatchPaymentInput!, $allocationInput: [AllocationInput!]!) {
  updateAPBatchPaymentWithAllocation(input: $input, allocationInput: $allocationInput)
}
    `;
export type UpdateApBatchPaymentWithAllocationMutationFn = ApolloReactCommon.MutationFunction<UpdateApBatchPaymentWithAllocationMutation, UpdateApBatchPaymentWithAllocationMutationVariables>;

/**
 * __useUpdateApBatchPaymentWithAllocationMutation__
 *
 * To run a mutation, you first call `useUpdateApBatchPaymentWithAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApBatchPaymentWithAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApBatchPaymentWithAllocationMutation, { data, loading, error }] = useUpdateApBatchPaymentWithAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useUpdateApBatchPaymentWithAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApBatchPaymentWithAllocationMutation, UpdateApBatchPaymentWithAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApBatchPaymentWithAllocationMutation, UpdateApBatchPaymentWithAllocationMutationVariables>(UpdateApBatchPaymentWithAllocationDocument, baseOptions);
      }
export type UpdateApBatchPaymentWithAllocationMutationHookResult = ReturnType<typeof useUpdateApBatchPaymentWithAllocationMutation>;
export type UpdateApBatchPaymentWithAllocationMutationResult = ApolloReactCommon.MutationResult<UpdateApBatchPaymentWithAllocationMutation>;
export type UpdateApBatchPaymentWithAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApBatchPaymentWithAllocationMutation, UpdateApBatchPaymentWithAllocationMutationVariables>;
export const DeleteBatchPaymentDocument = gql`
    mutation deleteBatchPayment($BatchPaymentID: String!) {
  deleteBatchPayment(BatchPaymentID: $BatchPaymentID)
}
    `;
export type DeleteBatchPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteBatchPaymentMutation, DeleteBatchPaymentMutationVariables>;

/**
 * __useDeleteBatchPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteBatchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBatchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBatchPaymentMutation, { data, loading, error }] = useDeleteBatchPaymentMutation({
 *   variables: {
 *      BatchPaymentID: // value for 'BatchPaymentID'
 *   },
 * });
 */
export function useDeleteBatchPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBatchPaymentMutation, DeleteBatchPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBatchPaymentMutation, DeleteBatchPaymentMutationVariables>(DeleteBatchPaymentDocument, baseOptions);
      }
export type DeleteBatchPaymentMutationHookResult = ReturnType<typeof useDeleteBatchPaymentMutation>;
export type DeleteBatchPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteBatchPaymentMutation>;
export type DeleteBatchPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBatchPaymentMutation, DeleteBatchPaymentMutationVariables>;
export const PayApBatchPaymentDocument = gql`
    mutation payAPBatchPayment($BatchPaymentID: String!, $allocationInput: [AllocationInput!]!, $CompanyID: String, $RefTable: String!) {
  payAPBatchPayment(BatchPaymentID: $BatchPaymentID, allocationInput: $allocationInput, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PayApBatchPaymentMutationFn = ApolloReactCommon.MutationFunction<PayApBatchPaymentMutation, PayApBatchPaymentMutationVariables>;

/**
 * __usePayApBatchPaymentMutation__
 *
 * To run a mutation, you first call `usePayApBatchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayApBatchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payApBatchPaymentMutation, { data, loading, error }] = usePayApBatchPaymentMutation({
 *   variables: {
 *      BatchPaymentID: // value for 'BatchPaymentID'
 *      allocationInput: // value for 'allocationInput'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePayApBatchPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayApBatchPaymentMutation, PayApBatchPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<PayApBatchPaymentMutation, PayApBatchPaymentMutationVariables>(PayApBatchPaymentDocument, baseOptions);
      }
export type PayApBatchPaymentMutationHookResult = ReturnType<typeof usePayApBatchPaymentMutation>;
export type PayApBatchPaymentMutationResult = ApolloReactCommon.MutationResult<PayApBatchPaymentMutation>;
export type PayApBatchPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<PayApBatchPaymentMutation, PayApBatchPaymentMutationVariables>;
export const GetApBatchPaymentbyStatusDocument = gql`
    query getAPBatchPaymentbyStatus($CompanyID: String!, $StatusArr: [String!]!, $BatchPaymentID: String) {
  getAPBatchPaymentbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, BatchPaymentID: $BatchPaymentID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    BatchPaymentID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    APPayment {
      PaymentID
      DocAmt
      ApprovalStatus
      CreditorAccountID
      CreditorAccount {
        CompanyName
      }
      DocDate
      DocNo
      DocAmt
      Description
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitRefTable
        DebitID
        CreditDocNo
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
        DebitDocDate
        CreditDocDate
        Description
      }
    }
    RefNo
    Description
    ApprovalStatus
    Attachment {
      fileURL
      mediaType
      name
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApBatchPaymentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetApBatchPaymentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApBatchPaymentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApBatchPaymentbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      BatchPaymentID: // value for 'BatchPaymentID'
 *   },
 * });
 */
export function useGetApBatchPaymentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApBatchPaymentbyStatusQuery, GetApBatchPaymentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApBatchPaymentbyStatusQuery, GetApBatchPaymentbyStatusQueryVariables>(GetApBatchPaymentbyStatusDocument, baseOptions);
      }
export function useGetApBatchPaymentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApBatchPaymentbyStatusQuery, GetApBatchPaymentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApBatchPaymentbyStatusQuery, GetApBatchPaymentbyStatusQueryVariables>(GetApBatchPaymentbyStatusDocument, baseOptions);
        }
export type GetApBatchPaymentbyStatusQueryHookResult = ReturnType<typeof useGetApBatchPaymentbyStatusQuery>;
export type GetApBatchPaymentbyStatusLazyQueryHookResult = ReturnType<typeof useGetApBatchPaymentbyStatusLazyQuery>;
export type GetApBatchPaymentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetApBatchPaymentbyStatusQuery, GetApBatchPaymentbyStatusQueryVariables>;
export const RejectBatchPaymentDocument = gql`
    mutation rejectBatchPayment($BatchPaymentID: String!) {
  rejectBatchPayment(BatchPaymentID: $BatchPaymentID)
}
    `;
export type RejectBatchPaymentMutationFn = ApolloReactCommon.MutationFunction<RejectBatchPaymentMutation, RejectBatchPaymentMutationVariables>;

/**
 * __useRejectBatchPaymentMutation__
 *
 * To run a mutation, you first call `useRejectBatchPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectBatchPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectBatchPaymentMutation, { data, loading, error }] = useRejectBatchPaymentMutation({
 *   variables: {
 *      BatchPaymentID: // value for 'BatchPaymentID'
 *   },
 * });
 */
export function useRejectBatchPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectBatchPaymentMutation, RejectBatchPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectBatchPaymentMutation, RejectBatchPaymentMutationVariables>(RejectBatchPaymentDocument, baseOptions);
      }
export type RejectBatchPaymentMutationHookResult = ReturnType<typeof useRejectBatchPaymentMutation>;
export type RejectBatchPaymentMutationResult = ApolloReactCommon.MutationResult<RejectBatchPaymentMutation>;
export type RejectBatchPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectBatchPaymentMutation, RejectBatchPaymentMutationVariables>;
export const GetSelfBilledConsolidationDocument = gql`
    query getSelfBilledConsolidation($CompanyID: String!, $skip: Float, $take: Float, $searchValue: String, $DocType: [String!], $StartDate: String, $EndDate: String) {
  getSelfBilledConsolidation(CompanyID: $CompanyID, skip: $skip, take: $take, searchValue: $searchValue, DocType: $DocType, StartDate: $StartDate, EndDate: $EndDate) {
    AccountID
    CompanyID
    ConsolidationID
    DocDate
    DocNo
    DocAmt
    Amount
    TaxAmt
    Description
    ProfileInfo
    einvoice_trx_type
    createdTs
    createdBy
    einvoice_status
    einvoice
    eInvoiceConsolidationDetail {
      Consolidation_DetailID
      AccountID
      ConsolidationID
      APDocNo
      SB
      CreditorDebtorName
      RefTable
      RefRecordID
      Amount
      TaxAmt
      DocAmt
    }
  }
}
    `;

/**
 * __useGetSelfBilledConsolidationQuery__
 *
 * To run a query within a React component, call `useGetSelfBilledConsolidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfBilledConsolidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfBilledConsolidationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      DocType: // value for 'DocType'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetSelfBilledConsolidationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelfBilledConsolidationQuery, GetSelfBilledConsolidationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelfBilledConsolidationQuery, GetSelfBilledConsolidationQueryVariables>(GetSelfBilledConsolidationDocument, baseOptions);
      }
export function useGetSelfBilledConsolidationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelfBilledConsolidationQuery, GetSelfBilledConsolidationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelfBilledConsolidationQuery, GetSelfBilledConsolidationQueryVariables>(GetSelfBilledConsolidationDocument, baseOptions);
        }
export type GetSelfBilledConsolidationQueryHookResult = ReturnType<typeof useGetSelfBilledConsolidationQuery>;
export type GetSelfBilledConsolidationLazyQueryHookResult = ReturnType<typeof useGetSelfBilledConsolidationLazyQuery>;
export type GetSelfBilledConsolidationQueryResult = ApolloReactCommon.QueryResult<GetSelfBilledConsolidationQuery, GetSelfBilledConsolidationQueryVariables>;
export const GetSelfBilledToConsolidateDocument = gql`
    query getSelfBilledToConsolidate($CompanyID: String, $DocType: String, $DocNo: String, $CreditorName: String, $StartDate: String, $EndDate: String) {
  getSelfBilledToConsolidate(CompanyID: $CompanyID, DocType: $DocType, DocNo: $DocNo, CreditorName: $CreditorName, StartDate: $StartDate, EndDate: $EndDate) {
    AccountID
    CompanyID
    SelfBilledID
    CreditorAccountID
    CreditorAccount {
      CreditorAccountID
      CompanyName
    }
    DocDate
    DocNo
    APLedger
    DocAmt
    Amount
    TaxAmt
  }
}
    `;

/**
 * __useGetSelfBilledToConsolidateQuery__
 *
 * To run a query within a React component, call `useGetSelfBilledToConsolidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfBilledToConsolidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfBilledToConsolidateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DocType: // value for 'DocType'
 *      DocNo: // value for 'DocNo'
 *      CreditorName: // value for 'CreditorName'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetSelfBilledToConsolidateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelfBilledToConsolidateQuery, GetSelfBilledToConsolidateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelfBilledToConsolidateQuery, GetSelfBilledToConsolidateQueryVariables>(GetSelfBilledToConsolidateDocument, baseOptions);
      }
export function useGetSelfBilledToConsolidateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelfBilledToConsolidateQuery, GetSelfBilledToConsolidateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelfBilledToConsolidateQuery, GetSelfBilledToConsolidateQueryVariables>(GetSelfBilledToConsolidateDocument, baseOptions);
        }
export type GetSelfBilledToConsolidateQueryHookResult = ReturnType<typeof useGetSelfBilledToConsolidateQuery>;
export type GetSelfBilledToConsolidateLazyQueryHookResult = ReturnType<typeof useGetSelfBilledToConsolidateLazyQuery>;
export type GetSelfBilledToConsolidateQueryResult = ApolloReactCommon.QueryResult<GetSelfBilledToConsolidateQuery, GetSelfBilledToConsolidateQueryVariables>;
export const CreateSelfBilledConsolidateDocument = gql`
    mutation createSelfBilledConsolidate($input: ConsolidationInput!, $itemInput: [ConsolidationDetailInput!]!) {
  createSelfBilledConsolidate(input: $input, itemInput: $itemInput)
}
    `;
export type CreateSelfBilledConsolidateMutationFn = ApolloReactCommon.MutationFunction<CreateSelfBilledConsolidateMutation, CreateSelfBilledConsolidateMutationVariables>;

/**
 * __useCreateSelfBilledConsolidateMutation__
 *
 * To run a mutation, you first call `useCreateSelfBilledConsolidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelfBilledConsolidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelfBilledConsolidateMutation, { data, loading, error }] = useCreateSelfBilledConsolidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateSelfBilledConsolidateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSelfBilledConsolidateMutation, CreateSelfBilledConsolidateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSelfBilledConsolidateMutation, CreateSelfBilledConsolidateMutationVariables>(CreateSelfBilledConsolidateDocument, baseOptions);
      }
export type CreateSelfBilledConsolidateMutationHookResult = ReturnType<typeof useCreateSelfBilledConsolidateMutation>;
export type CreateSelfBilledConsolidateMutationResult = ApolloReactCommon.MutationResult<CreateSelfBilledConsolidateMutation>;
export type CreateSelfBilledConsolidateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSelfBilledConsolidateMutation, CreateSelfBilledConsolidateMutationVariables>;
export const GetApCreditNoteDocument = gql`
    query getAPCreditNote($AccountID: String, $CompanyID: String, $CreditNoteID: String, $orderByAsc: String, $orderByDesc: String, $CreditorAccountID: String) {
  getAPCreditNote(AccountID: $AccountID, CompanyID: $CompanyID, CreditNoteID: $CreditNoteID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, CreditorAccountID: $CreditorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CreditNoteID
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    CreditorAccount {
      Address
      CompanyName
      GSTRegNo
      BankAccountNo
      BankName
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    IsAutoDocNo
    Amount
    BalanceAmt
    DocAmtBeforeTax
    TaxAmt
    Amount
    DocAmt
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ApprovalStatus
    APCreditNoteItem {
      ExpenseItemID
      ExpenseItem {
        Description
        ItemName
      }
      CreditNoteItemID
      MasterCOAID
      MasterCOA {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      Quantity
      CostCentreID
      CostCentre {
        Name
      }
      Remark
      Sequence
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetApCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNoteQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditNoteID: // value for 'CreditNoteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
      }
export function useGetApCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
        }
export type GetApCreditNoteQueryHookResult = ReturnType<typeof useGetApCreditNoteQuery>;
export type GetApCreditNoteLazyQueryHookResult = ReturnType<typeof useGetApCreditNoteLazyQuery>;
export type GetApCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>;
export const CreateApCreditNoteDocument = gql`
    mutation createAPCreditNote($input: APCreditNoteInput!, $itemInput: [APCreditNoteItemInput!], $allocationInput: [APAllocationInput!]!, $docRefInput: [CNDocRefInputType!]) {
  createAPCreditNote(input: $input, itemInput: $itemInput, allocationInput: $allocationInput, docRefInput: $docRefInput) {
    CreditNoteID
  }
}
    `;
export type CreateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;

/**
 * __useCreateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApCreditNoteMutation, { data, loading, error }] = useCreateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      allocationInput: // value for 'allocationInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>(CreateApCreditNoteDocument, baseOptions);
      }
export type CreateApCreditNoteMutationHookResult = ReturnType<typeof useCreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;
export const UpdateApCreditNoteDocument = gql`
    mutation updateAPCreditNote($input: APCreditNoteInput!, $itemInput: [APCreditNoteItemInput!], $allocationInput: [APAllocationInput!]!, $docRefInput: [CNDocRefInputType!]) {
  updateAPCreditNote(input: $input, itemInput: $itemInput, allocationInput: $allocationInput, docRefInput: $docRefInput) {
    CreditNoteID
  }
}
    `;
export type UpdateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;

/**
 * __useUpdateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApCreditNoteMutation, { data, loading, error }] = useUpdateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      allocationInput: // value for 'allocationInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>(UpdateApCreditNoteDocument, baseOptions);
      }
export type UpdateApCreditNoteMutationHookResult = ReturnType<typeof useUpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;
export const PostApCreditNoteDocument = gql`
    mutation postAPCreditNote($CreditNoteIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPCreditNote(CreditNoteIDs: $CreditNoteIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<PostApCreditNoteMutation, PostApCreditNoteMutationVariables>;

/**
 * __usePostApCreditNoteMutation__
 *
 * To run a mutation, you first call `usePostApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApCreditNoteMutation, { data, loading, error }] = usePostApCreditNoteMutation({
 *   variables: {
 *      CreditNoteIDs: // value for 'CreditNoteIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApCreditNoteMutation, PostApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApCreditNoteMutation, PostApCreditNoteMutationVariables>(PostApCreditNoteDocument, baseOptions);
      }
export type PostApCreditNoteMutationHookResult = ReturnType<typeof usePostApCreditNoteMutation>;
export type PostApCreditNoteMutationResult = ApolloReactCommon.MutationResult<PostApCreditNoteMutation>;
export type PostApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApCreditNoteMutation, PostApCreditNoteMutationVariables>;
export const RejectApCreditNoteDocument = gql`
    mutation rejectAPCreditNote($CreditNoteIDs: [String!]!, $input: APCreditNoteRejectInput!) {
  rejectAPCreditNote(CreditNoteIDs: $CreditNoteIDs, input: $input)
}
    `;
export type RejectApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<RejectApCreditNoteMutation, RejectApCreditNoteMutationVariables>;

/**
 * __useRejectApCreditNoteMutation__
 *
 * To run a mutation, you first call `useRejectApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApCreditNoteMutation, { data, loading, error }] = useRejectApCreditNoteMutation({
 *   variables: {
 *      CreditNoteIDs: // value for 'CreditNoteIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApCreditNoteMutation, RejectApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApCreditNoteMutation, RejectApCreditNoteMutationVariables>(RejectApCreditNoteDocument, baseOptions);
      }
export type RejectApCreditNoteMutationHookResult = ReturnType<typeof useRejectApCreditNoteMutation>;
export type RejectApCreditNoteMutationResult = ApolloReactCommon.MutationResult<RejectApCreditNoteMutation>;
export type RejectApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApCreditNoteMutation, RejectApCreditNoteMutationVariables>;
export const DeleteApCreditNoteDocument = gql`
    mutation deleteAPCreditNote($CreditNoteID: String!) {
  deleteAPCreditNote(CreditNoteID: $CreditNoteID)
}
    `;
export type DeleteApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>;

/**
 * __useDeleteApCreditNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApCreditNoteMutation, { data, loading, error }] = useDeleteApCreditNoteMutation({
 *   variables: {
 *      CreditNoteID: // value for 'CreditNoteID'
 *   },
 * });
 */
export function useDeleteApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>(DeleteApCreditNoteDocument, baseOptions);
      }
export type DeleteApCreditNoteMutationHookResult = ReturnType<typeof useDeleteApCreditNoteMutation>;
export type DeleteApCreditNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApCreditNoteMutation>;
export type DeleteApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>;
export const GetApCreditNotebyStatusDocument = gql`
    query getAPCreditNotebyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String) {
  getAPCreditNotebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CreditNoteID
    DocDate
    TransactionDate
    RefNo
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    einvoice_irbm_uniqueID
    MSICCode
    CreditorAccountID
    CreditorAccount {
      CreditorAccountID
      CompanyName
      Address
    }
    APCreditNoteItem {
      CreditNoteItemID
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      MasterCOAID
      MasterCOA {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      Quantity
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      Remark
      Sequence
    }
    DocNo
    CostCentreID
    CostCentre {
      Name
    }
    DocNumGenerated
    Amount
    BalanceAmt
    DocAmtBeforeTax
    TaxAmt
    Amount
    DocAmt
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ApprovalStatus
    ExpenseItemID
    ExpenseItem {
      Description
      ItemName
      TaxRate
      TaxSchemeID
      TaxScheme {
        TaxSchemeID
        Code
        LatestTax {
          Rate
        }
      }
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApCreditNotebyStatusQuery__
 *
 * To run a query within a React component, call `useGetApCreditNotebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNotebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNotebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApCreditNotebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNotebyStatusQuery, GetApCreditNotebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNotebyStatusQuery, GetApCreditNotebyStatusQueryVariables>(GetApCreditNotebyStatusDocument, baseOptions);
      }
export function useGetApCreditNotebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNotebyStatusQuery, GetApCreditNotebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNotebyStatusQuery, GetApCreditNotebyStatusQueryVariables>(GetApCreditNotebyStatusDocument, baseOptions);
        }
export type GetApCreditNotebyStatusQueryHookResult = ReturnType<typeof useGetApCreditNotebyStatusQuery>;
export type GetApCreditNotebyStatusLazyQueryHookResult = ReturnType<typeof useGetApCreditNotebyStatusLazyQuery>;
export type GetApCreditNotebyStatusQueryResult = ApolloReactCommon.QueryResult<GetApCreditNotebyStatusQuery, GetApCreditNotebyStatusQueryVariables>;
export const GetApCreditNotebyStatusListingDocument = gql`
    query getAPCreditNotebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPCreditNotebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CreditNoteID
    DocDate
    TransactionDate
    RefNo
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    MSICCode
    einvoice_irbm_uniqueID
    einvoice_status
    einvoice_sent_date
    einvoice_is_refund_note
    einvoice
    SB
    CNDocRef {
      CreditNoteID
      DocRefID
    }
    DocRefNo
    CreditorAccountID
    CreditorAccount {
      CreditorAccountID
      CompanyName
      Address
    }
    APCreditNoteItem {
      CreditNoteItemID
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      MasterCOAID
      MasterCOA {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      Quantity
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      Remark
      Sequence
      einvoice_classification_code_id
      ClassificationCode
    }
    DocNo
    CostCentreID
    CostCentre {
      Name
    }
    DocNumGenerated
    Amount
    BalanceAmt
    DocAmtBeforeTax
    TaxAmt
    Amount
    DocAmt
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ApprovalStatus
    ExpenseItemID
    ExpenseItem {
      Description
      ItemName
      TaxRate
      TaxSchemeID
      TaxScheme {
        TaxSchemeID
        Code
        LatestTax {
          Rate
        }
      }
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApCreditNotebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApCreditNotebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNotebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNotebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApCreditNotebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNotebyStatusListingQuery, GetApCreditNotebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNotebyStatusListingQuery, GetApCreditNotebyStatusListingQueryVariables>(GetApCreditNotebyStatusListingDocument, baseOptions);
      }
export function useGetApCreditNotebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNotebyStatusListingQuery, GetApCreditNotebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNotebyStatusListingQuery, GetApCreditNotebyStatusListingQueryVariables>(GetApCreditNotebyStatusListingDocument, baseOptions);
        }
export type GetApCreditNotebyStatusListingQueryHookResult = ReturnType<typeof useGetApCreditNotebyStatusListingQuery>;
export type GetApCreditNotebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApCreditNotebyStatusListingLazyQuery>;
export type GetApCreditNotebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApCreditNotebyStatusListingQuery, GetApCreditNotebyStatusListingQueryVariables>;
export const GetApCreditorCreditNoteDocument = gql`
    query getAPCreditorCreditNote($AccountID: String, $CompanyID: String, $CreditorCreditNoteID: String, $orderByAsc: String, $orderByDesc: String, $CreditorAccountID: String) {
  getAPCreditorCreditNote(AccountID: $AccountID, CompanyID: $CompanyID, CreditorCreditNoteID: $CreditorCreditNoteID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, CreditorAccountID: $CreditorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CreditorCreditNoteID
    GRTN
    APCreditorCreditNoteItem {
      CreditorCreditNoteItemID
      GRTNItemID
      Amount
      BalanceItemAmt
      DocAmt
      UnitPrice
      Quantity
      TaxAmt
      TaxRate
      TaxSchemeID
      TaxScheme {
        Code
      }
      ItemID
      ExpenseItemID
      ExpenseItem {
        Description
        ItemName
      }
      CostCentreID
      CostCentre {
        Name
      }
      AllocationItem {
        AllocationID
        AllocationItemID
        DebitItemRefTable
        DebitItemID
        CreditItemRefTable
        CreditItemID
        CreditOrDebit
        AllocationItemAmt
      }
    }
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    CreditorAccount {
      CreditorAccountID
      CompanyName
      Address
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    IsAutoDocNo
    Amount
    BalanceAmt
    DocAmtBeforeTax
    TaxAmt
    Amount
    DocAmt
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApCreditorCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetApCreditorCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditorCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditorCreditNoteQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorCreditNoteID: // value for 'CreditorCreditNoteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApCreditorCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditorCreditNoteQuery, GetApCreditorCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditorCreditNoteQuery, GetApCreditorCreditNoteQueryVariables>(GetApCreditorCreditNoteDocument, baseOptions);
      }
export function useGetApCreditorCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditorCreditNoteQuery, GetApCreditorCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditorCreditNoteQuery, GetApCreditorCreditNoteQueryVariables>(GetApCreditorCreditNoteDocument, baseOptions);
        }
export type GetApCreditorCreditNoteQueryHookResult = ReturnType<typeof useGetApCreditorCreditNoteQuery>;
export type GetApCreditorCreditNoteLazyQueryHookResult = ReturnType<typeof useGetApCreditorCreditNoteLazyQuery>;
export type GetApCreditorCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetApCreditorCreditNoteQuery, GetApCreditorCreditNoteQueryVariables>;
export const GetApCreditorCreditNoteByStatusDocument = gql`
    query getAPCreditorCreditNoteByStatus($CreditorAccountID: String, $CompanyID: String!, $StatusArr: [String!]!) {
  getAPCreditorCreditNotebyStatus(CreditorAccountID: $CreditorAccountID, CompanyID: $CompanyID, StatusArr: $StatusArr) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CreditorCreditNoteID
    GRTN
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    MSICCode
    einvoice_irbm_uniqueID
    APCreditorCreditNoteItem {
      CreditorCreditNoteItemID
      GRTNItemID
      Amount
      BalanceItemAmt
      DocAmt
      UnitPrice
      Quantity
      Remark
      TaxSchemeID
      TaxScheme {
        Code
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      TaxAmt
      TaxRate
      ItemID
      ExpenseItemID
      ExpenseItem {
        Description
        ItemName
      }
      CostCentreID
      CostCentre {
        Name
      }
    }
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    CreditorAccount {
      CreditorAccountID
      CompanyName
      Address
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    IsAutoDocNo
    Amount
    BalanceAmt
    DocAmtBeforeTax
    TaxAmt
    Amount
    DocAmt
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApCreditorCreditNoteByStatusQuery__
 *
 * To run a query within a React component, call `useGetApCreditorCreditNoteByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditorCreditNoteByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditorCreditNoteByStatusQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetApCreditorCreditNoteByStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditorCreditNoteByStatusQuery, GetApCreditorCreditNoteByStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditorCreditNoteByStatusQuery, GetApCreditorCreditNoteByStatusQueryVariables>(GetApCreditorCreditNoteByStatusDocument, baseOptions);
      }
export function useGetApCreditorCreditNoteByStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditorCreditNoteByStatusQuery, GetApCreditorCreditNoteByStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditorCreditNoteByStatusQuery, GetApCreditorCreditNoteByStatusQueryVariables>(GetApCreditorCreditNoteByStatusDocument, baseOptions);
        }
export type GetApCreditorCreditNoteByStatusQueryHookResult = ReturnType<typeof useGetApCreditorCreditNoteByStatusQuery>;
export type GetApCreditorCreditNoteByStatusLazyQueryHookResult = ReturnType<typeof useGetApCreditorCreditNoteByStatusLazyQuery>;
export type GetApCreditorCreditNoteByStatusQueryResult = ApolloReactCommon.QueryResult<GetApCreditorCreditNoteByStatusQuery, GetApCreditorCreditNoteByStatusQueryVariables>;
export const GetApCreditorCreditNoteByStatusListingDocument = gql`
    query getAPCreditorCreditNoteByStatusListing($CreditorAccountID: String, $CompanyID: String!, $StatusArr: [String!]!, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPCreditorCreditNotebyStatusListing(CreditorAccountID: $CreditorAccountID, CompanyID: $CompanyID, StatusArr: $StatusArr, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    CreditorCreditNoteID
    GRTN
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_is_refund_note
    einvoice_msic_code_id
    MSICCode
    einvoice_irbm_uniqueID
    einvoice
    SB
    CNDocRef {
      CreditNoteID
      DocRefID
    }
    DocRefNo
    einvoice_status
    einvoice_sent_date
    APCreditorCreditNoteItem {
      CreditorCreditNoteItemID
      GRTNItemID
      Amount
      BalanceItemAmt
      DocAmt
      UnitPrice
      Quantity
      Remark
      TaxSchemeID
      TaxScheme {
        Code
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      TaxAmt
      TaxRate
      ItemID
      ExpenseItemID
      ExpenseItem {
        Description
        ItemName
      }
      CostCentreID
      CostCentre {
        Name
      }
      einvoice_classification_code_id
      ClassificationCode
    }
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    CreditorAccount {
      CreditorAccountID
      CompanyName
      Address
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    IsAutoDocNo
    Amount
    BalanceAmt
    DocAmtBeforeTax
    TaxAmt
    Amount
    DocAmt
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApCreditorCreditNoteByStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApCreditorCreditNoteByStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditorCreditNoteByStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditorCreditNoteByStatusListingQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApCreditorCreditNoteByStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditorCreditNoteByStatusListingQuery, GetApCreditorCreditNoteByStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditorCreditNoteByStatusListingQuery, GetApCreditorCreditNoteByStatusListingQueryVariables>(GetApCreditorCreditNoteByStatusListingDocument, baseOptions);
      }
export function useGetApCreditorCreditNoteByStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditorCreditNoteByStatusListingQuery, GetApCreditorCreditNoteByStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditorCreditNoteByStatusListingQuery, GetApCreditorCreditNoteByStatusListingQueryVariables>(GetApCreditorCreditNoteByStatusListingDocument, baseOptions);
        }
export type GetApCreditorCreditNoteByStatusListingQueryHookResult = ReturnType<typeof useGetApCreditorCreditNoteByStatusListingQuery>;
export type GetApCreditorCreditNoteByStatusListingLazyQueryHookResult = ReturnType<typeof useGetApCreditorCreditNoteByStatusListingLazyQuery>;
export type GetApCreditorCreditNoteByStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApCreditorCreditNoteByStatusListingQuery, GetApCreditorCreditNoteByStatusListingQueryVariables>;
export const CreateApCreditorCreditNoteDocument = gql`
    mutation createAPCreditorCreditNote($input: APCreditorCreditNoteInput!, $allocationInput: [APAllocationInput!]!, $itemInput: [APCreditorCreditNoteItemInput!], $docRefInput: [CNDocRefInputType!]) {
  createAPCreditorCreditNote(input: $input, allocationInput: $allocationInput, itemInput: $itemInput, docRefInput: $docRefInput)
}
    `;
export type CreateApCreditorCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApCreditorCreditNoteMutation, CreateApCreditorCreditNoteMutationVariables>;

/**
 * __useCreateApCreditorCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateApCreditorCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApCreditorCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApCreditorCreditNoteMutation, { data, loading, error }] = useCreateApCreditorCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateApCreditorCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApCreditorCreditNoteMutation, CreateApCreditorCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApCreditorCreditNoteMutation, CreateApCreditorCreditNoteMutationVariables>(CreateApCreditorCreditNoteDocument, baseOptions);
      }
export type CreateApCreditorCreditNoteMutationHookResult = ReturnType<typeof useCreateApCreditorCreditNoteMutation>;
export type CreateApCreditorCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateApCreditorCreditNoteMutation>;
export type CreateApCreditorCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApCreditorCreditNoteMutation, CreateApCreditorCreditNoteMutationVariables>;
export const UpdateApCreditorCreditNoteDocument = gql`
    mutation updateAPCreditorCreditNote($input: APCreditorCreditNoteInput!, $allocationInput: [APAllocationInput!]!, $itemInput: [APCreditorCreditNoteItemInput!], $docRefInput: [CNDocRefInputType!]) {
  updateAPCreditorCreditNote(input: $input, allocationInput: $allocationInput, itemInput: $itemInput, docRefInput: $docRefInput)
}
    `;
export type UpdateApCreditorCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApCreditorCreditNoteMutation, UpdateApCreditorCreditNoteMutationVariables>;

/**
 * __useUpdateApCreditorCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApCreditorCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApCreditorCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApCreditorCreditNoteMutation, { data, loading, error }] = useUpdateApCreditorCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateApCreditorCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApCreditorCreditNoteMutation, UpdateApCreditorCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApCreditorCreditNoteMutation, UpdateApCreditorCreditNoteMutationVariables>(UpdateApCreditorCreditNoteDocument, baseOptions);
      }
export type UpdateApCreditorCreditNoteMutationHookResult = ReturnType<typeof useUpdateApCreditorCreditNoteMutation>;
export type UpdateApCreditorCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApCreditorCreditNoteMutation>;
export type UpdateApCreditorCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApCreditorCreditNoteMutation, UpdateApCreditorCreditNoteMutationVariables>;
export const PostApCreditorCreditNoteDocument = gql`
    mutation postAPCreditorCreditNote($CreditorCreditNoteIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPCreditorCreditNote(CreditorCreditNoteIDs: $CreditorCreditNoteIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApCreditorCreditNoteMutationFn = ApolloReactCommon.MutationFunction<PostApCreditorCreditNoteMutation, PostApCreditorCreditNoteMutationVariables>;

/**
 * __usePostApCreditorCreditNoteMutation__
 *
 * To run a mutation, you first call `usePostApCreditorCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApCreditorCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApCreditorCreditNoteMutation, { data, loading, error }] = usePostApCreditorCreditNoteMutation({
 *   variables: {
 *      CreditorCreditNoteIDs: // value for 'CreditorCreditNoteIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApCreditorCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApCreditorCreditNoteMutation, PostApCreditorCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApCreditorCreditNoteMutation, PostApCreditorCreditNoteMutationVariables>(PostApCreditorCreditNoteDocument, baseOptions);
      }
export type PostApCreditorCreditNoteMutationHookResult = ReturnType<typeof usePostApCreditorCreditNoteMutation>;
export type PostApCreditorCreditNoteMutationResult = ApolloReactCommon.MutationResult<PostApCreditorCreditNoteMutation>;
export type PostApCreditorCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApCreditorCreditNoteMutation, PostApCreditorCreditNoteMutationVariables>;
export const RejectApCreditorCreditNoteDocument = gql`
    mutation rejectAPCreditorCreditNote($CreditorCreditNoteIDs: [String!]!, $input: APCreditorCreditNoteRejectInput!) {
  rejectAPCreditorCreditNote(CreditorCreditNoteIDs: $CreditorCreditNoteIDs, input: $input)
}
    `;
export type RejectApCreditorCreditNoteMutationFn = ApolloReactCommon.MutationFunction<RejectApCreditorCreditNoteMutation, RejectApCreditorCreditNoteMutationVariables>;

/**
 * __useRejectApCreditorCreditNoteMutation__
 *
 * To run a mutation, you first call `useRejectApCreditorCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApCreditorCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApCreditorCreditNoteMutation, { data, loading, error }] = useRejectApCreditorCreditNoteMutation({
 *   variables: {
 *      CreditorCreditNoteIDs: // value for 'CreditorCreditNoteIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApCreditorCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApCreditorCreditNoteMutation, RejectApCreditorCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApCreditorCreditNoteMutation, RejectApCreditorCreditNoteMutationVariables>(RejectApCreditorCreditNoteDocument, baseOptions);
      }
export type RejectApCreditorCreditNoteMutationHookResult = ReturnType<typeof useRejectApCreditorCreditNoteMutation>;
export type RejectApCreditorCreditNoteMutationResult = ApolloReactCommon.MutationResult<RejectApCreditorCreditNoteMutation>;
export type RejectApCreditorCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApCreditorCreditNoteMutation, RejectApCreditorCreditNoteMutationVariables>;
export const DeleteApCreditorCreditNoteDocument = gql`
    mutation deleteAPCreditorCreditNote($CreditorCreditNoteID: String!) {
  deleteAPCreditorCreditNote(CreditorCreditNoteID: $CreditorCreditNoteID)
}
    `;
export type DeleteApCreditorCreditNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApCreditorCreditNoteMutation, DeleteApCreditorCreditNoteMutationVariables>;

/**
 * __useDeleteApCreditorCreditNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApCreditorCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApCreditorCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApCreditorCreditNoteMutation, { data, loading, error }] = useDeleteApCreditorCreditNoteMutation({
 *   variables: {
 *      CreditorCreditNoteID: // value for 'CreditorCreditNoteID'
 *   },
 * });
 */
export function useDeleteApCreditorCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApCreditorCreditNoteMutation, DeleteApCreditorCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApCreditorCreditNoteMutation, DeleteApCreditorCreditNoteMutationVariables>(DeleteApCreditorCreditNoteDocument, baseOptions);
      }
export type DeleteApCreditorCreditNoteMutationHookResult = ReturnType<typeof useDeleteApCreditorCreditNoteMutation>;
export type DeleteApCreditorCreditNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApCreditorCreditNoteMutation>;
export type DeleteApCreditorCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApCreditorCreditNoteMutation, DeleteApCreditorCreditNoteMutationVariables>;
export const GetGeneralGrtnDocument = gql`
    query getGeneralGRTN($CreditorAccountID: String!, $CompanyID: String!, $GRTNItemIDs: [String!]) {
  getGeneralGRTN(CreditorAccountID: $CreditorAccountID, CompanyID: $CompanyID, GRTNItemIDs: $GRTNItemIDs)
}
    `;

/**
 * __useGetGeneralGrtnQuery__
 *
 * To run a query within a React component, call `useGetGeneralGrtnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralGrtnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralGrtnQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      GRTNItemIDs: // value for 'GRTNItemIDs'
 *   },
 * });
 */
export function useGetGeneralGrtnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralGrtnQuery, GetGeneralGrtnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralGrtnQuery, GetGeneralGrtnQueryVariables>(GetGeneralGrtnDocument, baseOptions);
      }
export function useGetGeneralGrtnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralGrtnQuery, GetGeneralGrtnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralGrtnQuery, GetGeneralGrtnQueryVariables>(GetGeneralGrtnDocument, baseOptions);
        }
export type GetGeneralGrtnQueryHookResult = ReturnType<typeof useGetGeneralGrtnQuery>;
export type GetGeneralGrtnLazyQueryHookResult = ReturnType<typeof useGetGeneralGrtnLazyQuery>;
export type GetGeneralGrtnQueryResult = ApolloReactCommon.QueryResult<GetGeneralGrtnQuery, GetGeneralGrtnQueryVariables>;
export const GetGrtnItemDocument = gql`
    query getGRTNItem($GRTNHeaderID: [String!]!) {
  getGRTNItem(GRTNHeaderID: $GRTNHeaderID)
}
    `;

/**
 * __useGetGrtnItemQuery__
 *
 * To run a query within a React component, call `useGetGrtnItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtnItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtnItemQuery({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *   },
 * });
 */
export function useGetGrtnItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtnItemQuery, GetGrtnItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtnItemQuery, GetGrtnItemQueryVariables>(GetGrtnItemDocument, baseOptions);
      }
export function useGetGrtnItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtnItemQuery, GetGrtnItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtnItemQuery, GetGrtnItemQueryVariables>(GetGrtnItemDocument, baseOptions);
        }
export type GetGrtnItemQueryHookResult = ReturnType<typeof useGetGrtnItemQuery>;
export type GetGrtnItemLazyQueryHookResult = ReturnType<typeof useGetGrtnItemLazyQuery>;
export type GetGrtnItemQueryResult = ApolloReactCommon.QueryResult<GetGrtnItemQuery, GetGrtnItemQueryVariables>;
export const GetApValidatedDocRefDocument = gql`
    query getAPValidatedDocRef($CreditorAccountID: String!, $CompanyID: String!) {
  getAPValidatedDocRef(CreditorAccountID: $CreditorAccountID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetApValidatedDocRefQuery__
 *
 * To run a query within a React component, call `useGetApValidatedDocRefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApValidatedDocRefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApValidatedDocRefQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetApValidatedDocRefQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApValidatedDocRefQuery, GetApValidatedDocRefQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApValidatedDocRefQuery, GetApValidatedDocRefQueryVariables>(GetApValidatedDocRefDocument, baseOptions);
      }
export function useGetApValidatedDocRefLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApValidatedDocRefQuery, GetApValidatedDocRefQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApValidatedDocRefQuery, GetApValidatedDocRefQueryVariables>(GetApValidatedDocRefDocument, baseOptions);
        }
export type GetApValidatedDocRefQueryHookResult = ReturnType<typeof useGetApValidatedDocRefQuery>;
export type GetApValidatedDocRefLazyQueryHookResult = ReturnType<typeof useGetApValidatedDocRefLazyQuery>;
export type GetApValidatedDocRefQueryResult = ApolloReactCommon.QueryResult<GetApValidatedDocRefQuery, GetApValidatedDocRefQueryVariables>;
export const GetApDebitNoteDocument = gql`
    query getAPDebitNote($AccountID: String, $CompanyID: String, $DebitNoteID: String, $orderByAsc: String, $orderByDesc: String, $CreditorAccountID: String) {
  getAPDebitNote(AccountID: $AccountID, CompanyID: $CompanyID, DebitNoteID: $DebitNoteID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, CreditorAccountID: $CreditorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    DebitNoteID
    DocDate
    TransactionDate
    RefNo
    CreditTerm
    DueDate
    Attachment {
      fileURL
      mediaType
      name
    }
    Description
    Remark
    CreditorAccountID
    CreditorAccount {
      Address
      CompanyName
      GSTRegNo
      BankAccountNo
      BankName
      CreditTerm
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    DocNo
    IsAutoDocNo
    Amount
    TaxAmt
    DocAmt
    BalanceAmt
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ExpenseItemID
    ExpenseItem {
      ExpenseItemID
      ItemName
      Description
    }
    APDebitNoteItem {
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      UOM {
        Name
      }
      DebitNoteItemID
      MasterCOAID
      MasterCOA {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      Quantity
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      CostCentreID
      CostCentre {
        Name
      }
      Remark
      Sequence
    }
  }
}
    `;

/**
 * __useGetApDebitNoteQuery__
 *
 * To run a query within a React component, call `useGetApDebitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNoteQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      DebitNoteID: // value for 'DebitNoteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApDebitNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
      }
export function useGetApDebitNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
        }
export type GetApDebitNoteQueryHookResult = ReturnType<typeof useGetApDebitNoteQuery>;
export type GetApDebitNoteLazyQueryHookResult = ReturnType<typeof useGetApDebitNoteLazyQuery>;
export type GetApDebitNoteQueryResult = ApolloReactCommon.QueryResult<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>;
export const CreateApDebitNoteDocument = gql`
    mutation createAPDebitNote($input: APDebitNoteInput!, $itemInput: [APDebitNoteItemInput!], $docRefInput: [DocRefInputType!]) {
  createAPDebitNote(input: $input, itemInput: $itemInput, docRefInput: $docRefInput) {
    DebitNoteID
  }
}
    `;
export type CreateApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>;

/**
 * __useCreateApDebitNoteMutation__
 *
 * To run a mutation, you first call `useCreateApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApDebitNoteMutation, { data, loading, error }] = useCreateApDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>(CreateApDebitNoteDocument, baseOptions);
      }
export type CreateApDebitNoteMutationHookResult = ReturnType<typeof useCreateApDebitNoteMutation>;
export type CreateApDebitNoteMutationResult = ApolloReactCommon.MutationResult<CreateApDebitNoteMutation>;
export type CreateApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>;
export const UpdateApDebitNoteDocument = gql`
    mutation updateAPDebitNote($input: APDebitNoteInput!, $itemInput: [APDebitNoteItemInput!], $docRefInput: [DocRefInputType!]) {
  updateAPDebitNote(input: $input, itemInput: $itemInput, docRefInput: $docRefInput) {
    DebitNoteID
  }
}
    `;
export type UpdateApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>;

/**
 * __useUpdateApDebitNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApDebitNoteMutation, { data, loading, error }] = useUpdateApDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>(UpdateApDebitNoteDocument, baseOptions);
      }
export type UpdateApDebitNoteMutationHookResult = ReturnType<typeof useUpdateApDebitNoteMutation>;
export type UpdateApDebitNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApDebitNoteMutation>;
export type UpdateApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>;
export const PostApDebitNoteDocument = gql`
    mutation postAPDebitNote($DebitNoteIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPDebitNote(DebitNoteIDs: $DebitNoteIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<PostApDebitNoteMutation, PostApDebitNoteMutationVariables>;

/**
 * __usePostApDebitNoteMutation__
 *
 * To run a mutation, you first call `usePostApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApDebitNoteMutation, { data, loading, error }] = usePostApDebitNoteMutation({
 *   variables: {
 *      DebitNoteIDs: // value for 'DebitNoteIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApDebitNoteMutation, PostApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApDebitNoteMutation, PostApDebitNoteMutationVariables>(PostApDebitNoteDocument, baseOptions);
      }
export type PostApDebitNoteMutationHookResult = ReturnType<typeof usePostApDebitNoteMutation>;
export type PostApDebitNoteMutationResult = ApolloReactCommon.MutationResult<PostApDebitNoteMutation>;
export type PostApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApDebitNoteMutation, PostApDebitNoteMutationVariables>;
export const RejectApDebitNoteDocument = gql`
    mutation rejectAPDebitNote($DebitNoteIDs: [String!]!, $input: APDebitNoteRejectInput!) {
  rejectAPDebitNote(DebitNoteIDs: $DebitNoteIDs, input: $input)
}
    `;
export type RejectApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<RejectApDebitNoteMutation, RejectApDebitNoteMutationVariables>;

/**
 * __useRejectApDebitNoteMutation__
 *
 * To run a mutation, you first call `useRejectApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApDebitNoteMutation, { data, loading, error }] = useRejectApDebitNoteMutation({
 *   variables: {
 *      DebitNoteIDs: // value for 'DebitNoteIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApDebitNoteMutation, RejectApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApDebitNoteMutation, RejectApDebitNoteMutationVariables>(RejectApDebitNoteDocument, baseOptions);
      }
export type RejectApDebitNoteMutationHookResult = ReturnType<typeof useRejectApDebitNoteMutation>;
export type RejectApDebitNoteMutationResult = ApolloReactCommon.MutationResult<RejectApDebitNoteMutation>;
export type RejectApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApDebitNoteMutation, RejectApDebitNoteMutationVariables>;
export const DeleteApDebitNoteDocument = gql`
    mutation deleteAPDebitNote($DebitNoteID: String!) {
  deleteAPDebitNote(DebitNoteID: $DebitNoteID)
}
    `;
export type DeleteApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>;

/**
 * __useDeleteApDebitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApDebitNoteMutation, { data, loading, error }] = useDeleteApDebitNoteMutation({
 *   variables: {
 *      DebitNoteID: // value for 'DebitNoteID'
 *   },
 * });
 */
export function useDeleteApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>(DeleteApDebitNoteDocument, baseOptions);
      }
export type DeleteApDebitNoteMutationHookResult = ReturnType<typeof useDeleteApDebitNoteMutation>;
export type DeleteApDebitNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApDebitNoteMutation>;
export type DeleteApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>;
export const GetApDebitNotebyStatusDocument = gql`
    query getAPDebitNotebyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String) {
  getAPDebitNotebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    DebitNoteID
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    ExpenseItemID
    CreditorAccount {
      CompanyName
      CreditTerm
    }
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    MSICCode
    einvoice_irbm_uniqueID
    DocNo
    Amount
    Attachment {
      fileURL
      mediaType
      name
    }
    BalanceAmt
    TaxAmt
    DocAmt
    Description
    Remark
    ApprovalStatus
    APDebitNoteItem {
      DebitNoteItemID
      UnitPrice
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      Remark
      Quantity
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
    }
    ProfileInfo
  }
}
    `;

/**
 * __useGetApDebitNotebyStatusQuery__
 *
 * To run a query within a React component, call `useGetApDebitNotebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNotebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNotebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApDebitNotebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNotebyStatusQuery, GetApDebitNotebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNotebyStatusQuery, GetApDebitNotebyStatusQueryVariables>(GetApDebitNotebyStatusDocument, baseOptions);
      }
export function useGetApDebitNotebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNotebyStatusQuery, GetApDebitNotebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNotebyStatusQuery, GetApDebitNotebyStatusQueryVariables>(GetApDebitNotebyStatusDocument, baseOptions);
        }
export type GetApDebitNotebyStatusQueryHookResult = ReturnType<typeof useGetApDebitNotebyStatusQuery>;
export type GetApDebitNotebyStatusLazyQueryHookResult = ReturnType<typeof useGetApDebitNotebyStatusLazyQuery>;
export type GetApDebitNotebyStatusQueryResult = ApolloReactCommon.QueryResult<GetApDebitNotebyStatusQuery, GetApDebitNotebyStatusQueryVariables>;
export const GetApDebitNotebyStatusListingDocument = gql`
    query getAPDebitNotebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPDebitNotebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    DebitNoteID
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    ExpenseItemID
    CreditorAccount {
      CompanyName
      CreditTerm
    }
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    MSICCode
    einvoice
    SB
    DNDocRef {
      DebitNoteID
      DocRefID
    }
    DocRefNo
    einvoice_irbm_uniqueID
    einvoice_doc_ref_invoice_id
    einvoice_status
    einvoice_sent_date
    DocNo
    Amount
    Attachment {
      fileURL
      mediaType
      name
    }
    BalanceAmt
    TaxAmt
    DocAmt
    Description
    Remark
    ApprovalStatus
    APDebitNoteItem {
      DebitNoteItemID
      UnitPrice
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      Remark
      Quantity
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      einvoice_classification_code_id
      ClassificationCode
    }
    ProfileInfo
  }
}
    `;

/**
 * __useGetApDebitNotebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApDebitNotebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNotebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNotebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApDebitNotebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNotebyStatusListingQuery, GetApDebitNotebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNotebyStatusListingQuery, GetApDebitNotebyStatusListingQueryVariables>(GetApDebitNotebyStatusListingDocument, baseOptions);
      }
export function useGetApDebitNotebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNotebyStatusListingQuery, GetApDebitNotebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNotebyStatusListingQuery, GetApDebitNotebyStatusListingQueryVariables>(GetApDebitNotebyStatusListingDocument, baseOptions);
        }
export type GetApDebitNotebyStatusListingQueryHookResult = ReturnType<typeof useGetApDebitNotebyStatusListingQuery>;
export type GetApDebitNotebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApDebitNotebyStatusListingLazyQuery>;
export type GetApDebitNotebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApDebitNotebyStatusListingQuery, GetApDebitNotebyStatusListingQueryVariables>;
export const GetApCreditorDebitNoteDocument = gql`
    query getAPCreditorDebitNote($AccountID: String, $CompanyID: String, $CreditorDebitNoteID: String, $orderByAsc: String, $orderByDesc: String, $CreditorAccountID: String) {
  getAPCreditorDebitNote(AccountID: $AccountID, CompanyID: $CompanyID, CreditorDebitNoteID: $CreditorDebitNoteID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, CreditorAccountID: $CreditorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    CreditorDebitNoteID
    DocDate
    TransactionDate
    RefNo
    CreditTerm
    DueDate
    Attachment {
      fileURL
      mediaType
      name
    }
    Description
    Remark
    CreditorAccountID
    CreditorAccount {
      Address
      CompanyName
      GSTRegNo
      BankAccountNo
      BankName
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    DocNo
    IsAutoDocNo
    Amount
    TaxAmt
    DocAmt
    BalanceAmt
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ExpenseItemID
    ExpenseItem {
      ExpenseItemID
      ItemName
      Description
    }
    APCreditorDebitNoteItem {
      CreditorDebitNoteItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      UOM {
        Name
      }
      MasterCOAID
      MasterCOA {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      Quantity
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      CostCentreID
      CostCentre {
        Name
      }
      Remark
      Sequence
    }
  }
}
    `;

/**
 * __useGetApCreditorDebitNoteQuery__
 *
 * To run a query within a React component, call `useGetApCreditorDebitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditorDebitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditorDebitNoteQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorDebitNoteID: // value for 'CreditorDebitNoteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApCreditorDebitNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditorDebitNoteQuery, GetApCreditorDebitNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditorDebitNoteQuery, GetApCreditorDebitNoteQueryVariables>(GetApCreditorDebitNoteDocument, baseOptions);
      }
export function useGetApCreditorDebitNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditorDebitNoteQuery, GetApCreditorDebitNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditorDebitNoteQuery, GetApCreditorDebitNoteQueryVariables>(GetApCreditorDebitNoteDocument, baseOptions);
        }
export type GetApCreditorDebitNoteQueryHookResult = ReturnType<typeof useGetApCreditorDebitNoteQuery>;
export type GetApCreditorDebitNoteLazyQueryHookResult = ReturnType<typeof useGetApCreditorDebitNoteLazyQuery>;
export type GetApCreditorDebitNoteQueryResult = ApolloReactCommon.QueryResult<GetApCreditorDebitNoteQuery, GetApCreditorDebitNoteQueryVariables>;
export const CreateApCreditorDebitNoteDocument = gql`
    mutation createAPCreditorDebitNote($input: APCreditorDebitNoteInput!, $itemInput: [APCreditorDebitNoteItemInput!], $docRefInput: [DocRefInputType!]) {
  createAPCreditorDebitNote(input: $input, itemInput: $itemInput, docRefInput: $docRefInput) {
    CreditorDebitNoteID
  }
}
    `;
export type CreateApCreditorDebitNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApCreditorDebitNoteMutation, CreateApCreditorDebitNoteMutationVariables>;

/**
 * __useCreateApCreditorDebitNoteMutation__
 *
 * To run a mutation, you first call `useCreateApCreditorDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApCreditorDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApCreditorDebitNoteMutation, { data, loading, error }] = useCreateApCreditorDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateApCreditorDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApCreditorDebitNoteMutation, CreateApCreditorDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApCreditorDebitNoteMutation, CreateApCreditorDebitNoteMutationVariables>(CreateApCreditorDebitNoteDocument, baseOptions);
      }
export type CreateApCreditorDebitNoteMutationHookResult = ReturnType<typeof useCreateApCreditorDebitNoteMutation>;
export type CreateApCreditorDebitNoteMutationResult = ApolloReactCommon.MutationResult<CreateApCreditorDebitNoteMutation>;
export type CreateApCreditorDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApCreditorDebitNoteMutation, CreateApCreditorDebitNoteMutationVariables>;
export const UpdateApCreditorDebitNoteDocument = gql`
    mutation updateAPCreditorDebitNote($input: APCreditorDebitNoteInput!, $itemInput: [APCreditorDebitNoteItemInput!], $docRefInput: [DocRefInputType!]) {
  updateAPCreditorDebitNote(input: $input, itemInput: $itemInput, docRefInput: $docRefInput) {
    CreditorDebitNoteID
  }
}
    `;
export type UpdateApCreditorDebitNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApCreditorDebitNoteMutation, UpdateApCreditorDebitNoteMutationVariables>;

/**
 * __useUpdateApCreditorDebitNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApCreditorDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApCreditorDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApCreditorDebitNoteMutation, { data, loading, error }] = useUpdateApCreditorDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateApCreditorDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApCreditorDebitNoteMutation, UpdateApCreditorDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApCreditorDebitNoteMutation, UpdateApCreditorDebitNoteMutationVariables>(UpdateApCreditorDebitNoteDocument, baseOptions);
      }
export type UpdateApCreditorDebitNoteMutationHookResult = ReturnType<typeof useUpdateApCreditorDebitNoteMutation>;
export type UpdateApCreditorDebitNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApCreditorDebitNoteMutation>;
export type UpdateApCreditorDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApCreditorDebitNoteMutation, UpdateApCreditorDebitNoteMutationVariables>;
export const PostApCreditorDebitNoteDocument = gql`
    mutation postAPCreditorDebitNote($CreditorDebitNoteIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPCreditorDebitNote(CreditorDebitNoteIDs: $CreditorDebitNoteIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApCreditorDebitNoteMutationFn = ApolloReactCommon.MutationFunction<PostApCreditorDebitNoteMutation, PostApCreditorDebitNoteMutationVariables>;

/**
 * __usePostApCreditorDebitNoteMutation__
 *
 * To run a mutation, you first call `usePostApCreditorDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApCreditorDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApCreditorDebitNoteMutation, { data, loading, error }] = usePostApCreditorDebitNoteMutation({
 *   variables: {
 *      CreditorDebitNoteIDs: // value for 'CreditorDebitNoteIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApCreditorDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApCreditorDebitNoteMutation, PostApCreditorDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApCreditorDebitNoteMutation, PostApCreditorDebitNoteMutationVariables>(PostApCreditorDebitNoteDocument, baseOptions);
      }
export type PostApCreditorDebitNoteMutationHookResult = ReturnType<typeof usePostApCreditorDebitNoteMutation>;
export type PostApCreditorDebitNoteMutationResult = ApolloReactCommon.MutationResult<PostApCreditorDebitNoteMutation>;
export type PostApCreditorDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApCreditorDebitNoteMutation, PostApCreditorDebitNoteMutationVariables>;
export const RejectApCreditorDebitNoteDocument = gql`
    mutation rejectAPCreditorDebitNote($CreditorDebitNoteIDs: [String!]!, $input: APCreditorDebitNoteRejectInput!) {
  rejectAPCreditorDebitNote(CreditorDebitNoteIDs: $CreditorDebitNoteIDs, input: $input)
}
    `;
export type RejectApCreditorDebitNoteMutationFn = ApolloReactCommon.MutationFunction<RejectApCreditorDebitNoteMutation, RejectApCreditorDebitNoteMutationVariables>;

/**
 * __useRejectApCreditorDebitNoteMutation__
 *
 * To run a mutation, you first call `useRejectApCreditorDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApCreditorDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApCreditorDebitNoteMutation, { data, loading, error }] = useRejectApCreditorDebitNoteMutation({
 *   variables: {
 *      CreditorDebitNoteIDs: // value for 'CreditorDebitNoteIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApCreditorDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApCreditorDebitNoteMutation, RejectApCreditorDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApCreditorDebitNoteMutation, RejectApCreditorDebitNoteMutationVariables>(RejectApCreditorDebitNoteDocument, baseOptions);
      }
export type RejectApCreditorDebitNoteMutationHookResult = ReturnType<typeof useRejectApCreditorDebitNoteMutation>;
export type RejectApCreditorDebitNoteMutationResult = ApolloReactCommon.MutationResult<RejectApCreditorDebitNoteMutation>;
export type RejectApCreditorDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApCreditorDebitNoteMutation, RejectApCreditorDebitNoteMutationVariables>;
export const DeleteApCreditorDebitNoteDocument = gql`
    mutation deleteAPCreditorDebitNote($CreditorDebitNoteID: String!) {
  deleteAPCreditorDebitNote(CreditorDebitNoteID: $CreditorDebitNoteID)
}
    `;
export type DeleteApCreditorDebitNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApCreditorDebitNoteMutation, DeleteApCreditorDebitNoteMutationVariables>;

/**
 * __useDeleteApCreditorDebitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApCreditorDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApCreditorDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApCreditorDebitNoteMutation, { data, loading, error }] = useDeleteApCreditorDebitNoteMutation({
 *   variables: {
 *      CreditorDebitNoteID: // value for 'CreditorDebitNoteID'
 *   },
 * });
 */
export function useDeleteApCreditorDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApCreditorDebitNoteMutation, DeleteApCreditorDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApCreditorDebitNoteMutation, DeleteApCreditorDebitNoteMutationVariables>(DeleteApCreditorDebitNoteDocument, baseOptions);
      }
export type DeleteApCreditorDebitNoteMutationHookResult = ReturnType<typeof useDeleteApCreditorDebitNoteMutation>;
export type DeleteApCreditorDebitNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApCreditorDebitNoteMutation>;
export type DeleteApCreditorDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApCreditorDebitNoteMutation, DeleteApCreditorDebitNoteMutationVariables>;
export const GetApCreditorDebitNotebyStatusDocument = gql`
    query getAPCreditorDebitNotebyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String) {
  getAPCreditorDebitNotebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    CreditorDebitNoteID
    DocDate
    TransactionDate
    RefNo
    CreditTerm
    DueDate
    Attachment {
      fileURL
      mediaType
      name
    }
    Description
    Remark
    CreditorAccountID
    CreditorAccount {
      Address
      CompanyName
      GSTRegNo
      BankAccountNo
      BankName
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    DocNo
    IsAutoDocNo
    Amount
    TaxAmt
    DocAmt
    BalanceAmt
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ExpenseItemID
    ExpenseItem {
      ExpenseItemID
      ItemName
      Description
    }
    APCreditorDebitNoteItem {
      CreditorDebitNoteItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      UOM {
        Code
        Name
      }
      MasterCOAID
      MasterCOA {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      Quantity
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      CostCentreID
      CostCentre {
        Name
      }
      Remark
      Sequence
    }
  }
}
    `;

/**
 * __useGetApCreditorDebitNotebyStatusQuery__
 *
 * To run a query within a React component, call `useGetApCreditorDebitNotebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditorDebitNotebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditorDebitNotebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApCreditorDebitNotebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditorDebitNotebyStatusQuery, GetApCreditorDebitNotebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditorDebitNotebyStatusQuery, GetApCreditorDebitNotebyStatusQueryVariables>(GetApCreditorDebitNotebyStatusDocument, baseOptions);
      }
export function useGetApCreditorDebitNotebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditorDebitNotebyStatusQuery, GetApCreditorDebitNotebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditorDebitNotebyStatusQuery, GetApCreditorDebitNotebyStatusQueryVariables>(GetApCreditorDebitNotebyStatusDocument, baseOptions);
        }
export type GetApCreditorDebitNotebyStatusQueryHookResult = ReturnType<typeof useGetApCreditorDebitNotebyStatusQuery>;
export type GetApCreditorDebitNotebyStatusLazyQueryHookResult = ReturnType<typeof useGetApCreditorDebitNotebyStatusLazyQuery>;
export type GetApCreditorDebitNotebyStatusQueryResult = ApolloReactCommon.QueryResult<GetApCreditorDebitNotebyStatusQuery, GetApCreditorDebitNotebyStatusQueryVariables>;
export const GetApCreditorDebitNotebyStatusListingDocument = gql`
    query getAPCreditorDebitNotebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPCreditorDebitNotebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    CreditorDebitNoteID
    DocDate
    TransactionDate
    RefNo
    CreditTerm
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    MSICCode
    einvoice_irbm_uniqueID
    einvoice_doc_ref_invoice_id
    einvoice
    SB
    DNDocRef {
      DebitNoteID
      DocRefID
    }
    DocRefNo
    einvoice_status
    einvoice_sent_date
    DueDate
    Attachment {
      fileURL
      mediaType
      name
    }
    Description
    Remark
    CreditorAccountID
    CreditorAccount {
      CompanyName
      CreditTerm
    }
    DocNo
    IsAutoDocNo
    Amount
    TaxAmt
    DocAmt
    BalanceAmt
    ApprovalStatus
    APCreditorDebitNoteItem {
      CreditorDebitNoteItemID
      ExpenseItemID
      UOMID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      UOM {
        Code
        Name
      }
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      Quantity
      TaxRate
      TaxAmt
      DocAmt
      UnitPrice
      CostCentreID
      CostCentre {
        Name
        Code
      }
      Remark
      Sequence
      einvoice_classification_code_id
      ClassificationCode
    }
  }
}
    `;

/**
 * __useGetApCreditorDebitNotebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApCreditorDebitNotebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditorDebitNotebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditorDebitNotebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApCreditorDebitNotebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditorDebitNotebyStatusListingQuery, GetApCreditorDebitNotebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditorDebitNotebyStatusListingQuery, GetApCreditorDebitNotebyStatusListingQueryVariables>(GetApCreditorDebitNotebyStatusListingDocument, baseOptions);
      }
export function useGetApCreditorDebitNotebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditorDebitNotebyStatusListingQuery, GetApCreditorDebitNotebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditorDebitNotebyStatusListingQuery, GetApCreditorDebitNotebyStatusListingQueryVariables>(GetApCreditorDebitNotebyStatusListingDocument, baseOptions);
        }
export type GetApCreditorDebitNotebyStatusListingQueryHookResult = ReturnType<typeof useGetApCreditorDebitNotebyStatusListingQuery>;
export type GetApCreditorDebitNotebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApCreditorDebitNotebyStatusListingLazyQuery>;
export type GetApCreditorDebitNotebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApCreditorDebitNotebyStatusListingQuery, GetApCreditorDebitNotebyStatusListingQueryVariables>;
export const GetSelfBilledInvoiceDocument = gql`
    query getSelfBilledInvoice($CompanyID: String!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String) {
  getSelfBilledInvoice(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue) {
    InvoiceID
    DocNo
    einvoice_directsend
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_status
  }
}
    `;

/**
 * __useGetSelfBilledInvoiceQuery__
 *
 * To run a query within a React component, call `useGetSelfBilledInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfBilledInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfBilledInvoiceQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetSelfBilledInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelfBilledInvoiceQuery, GetSelfBilledInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelfBilledInvoiceQuery, GetSelfBilledInvoiceQueryVariables>(GetSelfBilledInvoiceDocument, baseOptions);
      }
export function useGetSelfBilledInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelfBilledInvoiceQuery, GetSelfBilledInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelfBilledInvoiceQuery, GetSelfBilledInvoiceQueryVariables>(GetSelfBilledInvoiceDocument, baseOptions);
        }
export type GetSelfBilledInvoiceQueryHookResult = ReturnType<typeof useGetSelfBilledInvoiceQuery>;
export type GetSelfBilledInvoiceLazyQueryHookResult = ReturnType<typeof useGetSelfBilledInvoiceLazyQuery>;
export type GetSelfBilledInvoiceQueryResult = ApolloReactCommon.QueryResult<GetSelfBilledInvoiceQuery, GetSelfBilledInvoiceQueryVariables>;
export const GetArFavoriteInvoiceDocument = gql`
    query getARFavoriteInvoice($UserID: String, $CompanyID: String, $FavoriteInvoiceID: String) {
  getARFavoriteInvoice(UserID: $UserID, CompanyID: $CompanyID, FavoriteInvoiceID: $FavoriteInvoiceID) {
    FavoriteInvoiceID
    Name
    DebtorAccountID
    InterestRate
    Remark
    Description
    FavoriteInvoiceItem {
      FavoriteInvoiceID
      MasterCOAID
      MasterCOA {
        Name
        Code
      }
      CostCentreID
      CostCentre {
        Name
      }
      TaxSchemeID
      TaxScheme {
        Code
      }
      Amount
      Remark
      TaxRate
      TaxAmt
      DocAmt
    }
  }
}
    `;

/**
 * __useGetArFavoriteInvoiceQuery__
 *
 * To run a query within a React component, call `useGetArFavoriteInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArFavoriteInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArFavoriteInvoiceQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoriteInvoiceID: // value for 'FavoriteInvoiceID'
 *   },
 * });
 */
export function useGetArFavoriteInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArFavoriteInvoiceQuery, GetArFavoriteInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArFavoriteInvoiceQuery, GetArFavoriteInvoiceQueryVariables>(GetArFavoriteInvoiceDocument, baseOptions);
      }
export function useGetArFavoriteInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArFavoriteInvoiceQuery, GetArFavoriteInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArFavoriteInvoiceQuery, GetArFavoriteInvoiceQueryVariables>(GetArFavoriteInvoiceDocument, baseOptions);
        }
export type GetArFavoriteInvoiceQueryHookResult = ReturnType<typeof useGetArFavoriteInvoiceQuery>;
export type GetArFavoriteInvoiceLazyQueryHookResult = ReturnType<typeof useGetArFavoriteInvoiceLazyQuery>;
export type GetArFavoriteInvoiceQueryResult = ApolloReactCommon.QueryResult<GetArFavoriteInvoiceQuery, GetArFavoriteInvoiceQueryVariables>;
export const GetApFavoriteInvoiceDocument = gql`
    query getAPFavoriteInvoice($UserID: String, $CompanyID: String, $FavoriteInvoiceID: String) {
  getAPFavoriteInvoice(UserID: $UserID, CompanyID: $CompanyID, FavoriteInvoiceID: $FavoriteInvoiceID) {
    FavoriteInvoiceID
    Name
    DocAmt
    CreditorAccountID
    CreditTerm
    Description
    Remark
    RefNo
    APFavoriteInvoiceItem {
      Quantity
      UnitPrice
      FavoriteInvoiceID
      ExpenseItemID
      ExpenseItem {
        ItemName
        Description
      }
      CostCentreID
      CostCentre {
        Name
        Code
      }
      TaxSchemeID
      TaxScheme {
        Code
      }
      UOMID
      UOM {
        Name
        Code
      }
      Quantity
      Amount
      Remark
      TaxRate
      TaxAmt
      DocAmt
    }
  }
}
    `;

/**
 * __useGetApFavoriteInvoiceQuery__
 *
 * To run a query within a React component, call `useGetApFavoriteInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApFavoriteInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApFavoriteInvoiceQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoriteInvoiceID: // value for 'FavoriteInvoiceID'
 *   },
 * });
 */
export function useGetApFavoriteInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApFavoriteInvoiceQuery, GetApFavoriteInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApFavoriteInvoiceQuery, GetApFavoriteInvoiceQueryVariables>(GetApFavoriteInvoiceDocument, baseOptions);
      }
export function useGetApFavoriteInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApFavoriteInvoiceQuery, GetApFavoriteInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApFavoriteInvoiceQuery, GetApFavoriteInvoiceQueryVariables>(GetApFavoriteInvoiceDocument, baseOptions);
        }
export type GetApFavoriteInvoiceQueryHookResult = ReturnType<typeof useGetApFavoriteInvoiceQuery>;
export type GetApFavoriteInvoiceLazyQueryHookResult = ReturnType<typeof useGetApFavoriteInvoiceLazyQuery>;
export type GetApFavoriteInvoiceQueryResult = ApolloReactCommon.QueryResult<GetApFavoriteInvoiceQuery, GetApFavoriteInvoiceQueryVariables>;
export const CreateArFavoriteInvoiceDocument = gql`
    mutation createARFavoriteInvoice($ARFavoriteInvoiceInput: ARFavoriteInvoiceInput!, $ARFavoriteInvoiceItemInput: [ARFavoriteInvoiceItemInput!]!) {
  createARFavoriteInvoice(ARFavoriteInvoiceInput: $ARFavoriteInvoiceInput, ARFavoriteInvoiceItemInput: $ARFavoriteInvoiceItemInput)
}
    `;
export type CreateArFavoriteInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateArFavoriteInvoiceMutation, CreateArFavoriteInvoiceMutationVariables>;

/**
 * __useCreateArFavoriteInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateArFavoriteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArFavoriteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArFavoriteInvoiceMutation, { data, loading, error }] = useCreateArFavoriteInvoiceMutation({
 *   variables: {
 *      ARFavoriteInvoiceInput: // value for 'ARFavoriteInvoiceInput'
 *      ARFavoriteInvoiceItemInput: // value for 'ARFavoriteInvoiceItemInput'
 *   },
 * });
 */
export function useCreateArFavoriteInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArFavoriteInvoiceMutation, CreateArFavoriteInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArFavoriteInvoiceMutation, CreateArFavoriteInvoiceMutationVariables>(CreateArFavoriteInvoiceDocument, baseOptions);
      }
export type CreateArFavoriteInvoiceMutationHookResult = ReturnType<typeof useCreateArFavoriteInvoiceMutation>;
export type CreateArFavoriteInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateArFavoriteInvoiceMutation>;
export type CreateArFavoriteInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArFavoriteInvoiceMutation, CreateArFavoriteInvoiceMutationVariables>;
export const DeleteArFavoriteInvoiceDocument = gql`
    mutation deleteARFavoriteInvoice($FavoriteInvoiceID: String!) {
  deleteARFavoriteInvoice(FavoriteInvoiceID: $FavoriteInvoiceID)
}
    `;
export type DeleteArFavoriteInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteArFavoriteInvoiceMutation, DeleteArFavoriteInvoiceMutationVariables>;

/**
 * __useDeleteArFavoriteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteArFavoriteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArFavoriteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArFavoriteInvoiceMutation, { data, loading, error }] = useDeleteArFavoriteInvoiceMutation({
 *   variables: {
 *      FavoriteInvoiceID: // value for 'FavoriteInvoiceID'
 *   },
 * });
 */
export function useDeleteArFavoriteInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArFavoriteInvoiceMutation, DeleteArFavoriteInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArFavoriteInvoiceMutation, DeleteArFavoriteInvoiceMutationVariables>(DeleteArFavoriteInvoiceDocument, baseOptions);
      }
export type DeleteArFavoriteInvoiceMutationHookResult = ReturnType<typeof useDeleteArFavoriteInvoiceMutation>;
export type DeleteArFavoriteInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteArFavoriteInvoiceMutation>;
export type DeleteArFavoriteInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArFavoriteInvoiceMutation, DeleteArFavoriteInvoiceMutationVariables>;
export const CreateApFavoriteInvoiceDocument = gql`
    mutation createAPFavoriteInvoice($APFavoriteInvoiceInput: APFavoriteInvoiceInput!, $APFavoriteInvoiceItemInput: [APFavoriteInvoiceItemInput!]!) {
  createAPFavoriteInvoice(APFavoriteInvoiceInput: $APFavoriteInvoiceInput, APFavoriteInvoiceItemInput: $APFavoriteInvoiceItemInput)
}
    `;
export type CreateApFavoriteInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateApFavoriteInvoiceMutation, CreateApFavoriteInvoiceMutationVariables>;

/**
 * __useCreateApFavoriteInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateApFavoriteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApFavoriteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApFavoriteInvoiceMutation, { data, loading, error }] = useCreateApFavoriteInvoiceMutation({
 *   variables: {
 *      APFavoriteInvoiceInput: // value for 'APFavoriteInvoiceInput'
 *      APFavoriteInvoiceItemInput: // value for 'APFavoriteInvoiceItemInput'
 *   },
 * });
 */
export function useCreateApFavoriteInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApFavoriteInvoiceMutation, CreateApFavoriteInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApFavoriteInvoiceMutation, CreateApFavoriteInvoiceMutationVariables>(CreateApFavoriteInvoiceDocument, baseOptions);
      }
export type CreateApFavoriteInvoiceMutationHookResult = ReturnType<typeof useCreateApFavoriteInvoiceMutation>;
export type CreateApFavoriteInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateApFavoriteInvoiceMutation>;
export type CreateApFavoriteInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApFavoriteInvoiceMutation, CreateApFavoriteInvoiceMutationVariables>;
export const DeleteApFavoriteInvoiceDocument = gql`
    mutation deleteAPFavoriteInvoice($FavoriteInvoiceID: String!) {
  deleteAPFavoriteInvoice(FavoriteInvoiceID: $FavoriteInvoiceID)
}
    `;
export type DeleteApFavoriteInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteApFavoriteInvoiceMutation, DeleteApFavoriteInvoiceMutationVariables>;

/**
 * __useDeleteApFavoriteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteApFavoriteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApFavoriteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApFavoriteInvoiceMutation, { data, loading, error }] = useDeleteApFavoriteInvoiceMutation({
 *   variables: {
 *      FavoriteInvoiceID: // value for 'FavoriteInvoiceID'
 *   },
 * });
 */
export function useDeleteApFavoriteInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApFavoriteInvoiceMutation, DeleteApFavoriteInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApFavoriteInvoiceMutation, DeleteApFavoriteInvoiceMutationVariables>(DeleteApFavoriteInvoiceDocument, baseOptions);
      }
export type DeleteApFavoriteInvoiceMutationHookResult = ReturnType<typeof useDeleteApFavoriteInvoiceMutation>;
export type DeleteApFavoriteInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteApFavoriteInvoiceMutation>;
export type DeleteApFavoriteInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApFavoriteInvoiceMutation, DeleteApFavoriteInvoiceMutationVariables>;
export const GetApFavoritePaymentDocument = gql`
    query getAPFavoritePayment($CompanyID: String, $UserID: String, $FavoritePaymentID: String) {
  getAPFavoritePayment(CompanyID: $CompanyID, UserID: $UserID, FavoritePaymentID: $FavoritePaymentID) {
    FavoritePaymentID
    CompanyID
    UserID
    AccountID
    Name
    RefNo
    CreditorAccountID
    PaymentMethodID
    BankAccountID
    CreditCardID
    Description
    Remark
  }
}
    `;

/**
 * __useGetApFavoritePaymentQuery__
 *
 * To run a query within a React component, call `useGetApFavoritePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApFavoritePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApFavoritePaymentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      UserID: // value for 'UserID'
 *      FavoritePaymentID: // value for 'FavoritePaymentID'
 *   },
 * });
 */
export function useGetApFavoritePaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApFavoritePaymentQuery, GetApFavoritePaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApFavoritePaymentQuery, GetApFavoritePaymentQueryVariables>(GetApFavoritePaymentDocument, baseOptions);
      }
export function useGetApFavoritePaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApFavoritePaymentQuery, GetApFavoritePaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApFavoritePaymentQuery, GetApFavoritePaymentQueryVariables>(GetApFavoritePaymentDocument, baseOptions);
        }
export type GetApFavoritePaymentQueryHookResult = ReturnType<typeof useGetApFavoritePaymentQuery>;
export type GetApFavoritePaymentLazyQueryHookResult = ReturnType<typeof useGetApFavoritePaymentLazyQuery>;
export type GetApFavoritePaymentQueryResult = ApolloReactCommon.QueryResult<GetApFavoritePaymentQuery, GetApFavoritePaymentQueryVariables>;
export const CreateApFavoritePaymentDocument = gql`
    mutation createAPFavoritePayment($APFavoritePaymentInput: APFavoritePaymentInput!) {
  createAPFavoritePayment(APFavoritePaymentInput: $APFavoritePaymentInput)
}
    `;
export type CreateApFavoritePaymentMutationFn = ApolloReactCommon.MutationFunction<CreateApFavoritePaymentMutation, CreateApFavoritePaymentMutationVariables>;

/**
 * __useCreateApFavoritePaymentMutation__
 *
 * To run a mutation, you first call `useCreateApFavoritePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApFavoritePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApFavoritePaymentMutation, { data, loading, error }] = useCreateApFavoritePaymentMutation({
 *   variables: {
 *      APFavoritePaymentInput: // value for 'APFavoritePaymentInput'
 *   },
 * });
 */
export function useCreateApFavoritePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApFavoritePaymentMutation, CreateApFavoritePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApFavoritePaymentMutation, CreateApFavoritePaymentMutationVariables>(CreateApFavoritePaymentDocument, baseOptions);
      }
export type CreateApFavoritePaymentMutationHookResult = ReturnType<typeof useCreateApFavoritePaymentMutation>;
export type CreateApFavoritePaymentMutationResult = ApolloReactCommon.MutationResult<CreateApFavoritePaymentMutation>;
export type CreateApFavoritePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApFavoritePaymentMutation, CreateApFavoritePaymentMutationVariables>;
export const DeleteApFavoritePaymentDocument = gql`
    mutation deleteAPFavoritePayment($FavoritePaymentID: String!) {
  deleteAPFavoritePayment(FavoritePaymentID: $FavoritePaymentID)
}
    `;
export type DeleteApFavoritePaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteApFavoritePaymentMutation, DeleteApFavoritePaymentMutationVariables>;

/**
 * __useDeleteApFavoritePaymentMutation__
 *
 * To run a mutation, you first call `useDeleteApFavoritePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApFavoritePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApFavoritePaymentMutation, { data, loading, error }] = useDeleteApFavoritePaymentMutation({
 *   variables: {
 *      FavoritePaymentID: // value for 'FavoritePaymentID'
 *   },
 * });
 */
export function useDeleteApFavoritePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApFavoritePaymentMutation, DeleteApFavoritePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApFavoritePaymentMutation, DeleteApFavoritePaymentMutationVariables>(DeleteApFavoritePaymentDocument, baseOptions);
      }
export type DeleteApFavoritePaymentMutationHookResult = ReturnType<typeof useDeleteApFavoritePaymentMutation>;
export type DeleteApFavoritePaymentMutationResult = ApolloReactCommon.MutationResult<DeleteApFavoritePaymentMutation>;
export type DeleteApFavoritePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApFavoritePaymentMutation, DeleteApFavoritePaymentMutationVariables>;
export const GetApglExportDocument = gql`
    query getAPGLExport($AccountID: String!, $CompanyID: String!, $startDate: DateTime!, $endDate: DateTime!, $prefix: String!) {
  getGLExport(AccountID: $AccountID, CompanyID: $CompanyID, startDate: $startDate, endDate: $endDate, prefix: $prefix)
}
    `;

/**
 * __useGetApglExportQuery__
 *
 * To run a query within a React component, call `useGetApglExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApglExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApglExportQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetApglExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApglExportQuery, GetApglExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApglExportQuery, GetApglExportQueryVariables>(GetApglExportDocument, baseOptions);
      }
export function useGetApglExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApglExportQuery, GetApglExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApglExportQuery, GetApglExportQueryVariables>(GetApglExportDocument, baseOptions);
        }
export type GetApglExportQueryHookResult = ReturnType<typeof useGetApglExportQuery>;
export type GetApglExportLazyQueryHookResult = ReturnType<typeof useGetApglExportLazyQuery>;
export type GetApglExportQueryResult = ApolloReactCommon.QueryResult<GetApglExportQuery, GetApglExportQueryVariables>;
export const GetApExportedGlListingDocument = gql`
    query getAPExportedGLListing($CompanyID: String!, $prefix: String!) {
  getExportedGLListing(CompanyID: $CompanyID, prefix: $prefix)
}
    `;

/**
 * __useGetApExportedGlListingQuery__
 *
 * To run a query within a React component, call `useGetApExportedGlListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApExportedGlListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApExportedGlListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetApExportedGlListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApExportedGlListingQuery, GetApExportedGlListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApExportedGlListingQuery, GetApExportedGlListingQueryVariables>(GetApExportedGlListingDocument, baseOptions);
      }
export function useGetApExportedGlListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApExportedGlListingQuery, GetApExportedGlListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApExportedGlListingQuery, GetApExportedGlListingQueryVariables>(GetApExportedGlListingDocument, baseOptions);
        }
export type GetApExportedGlListingQueryHookResult = ReturnType<typeof useGetApExportedGlListingQuery>;
export type GetApExportedGlListingLazyQueryHookResult = ReturnType<typeof useGetApExportedGlListingLazyQuery>;
export type GetApExportedGlListingQueryResult = ApolloReactCommon.QueryResult<GetApExportedGlListingQuery, GetApExportedGlListingQueryVariables>;
export const GetApExportedGlDetailDocument = gql`
    query getAPExportedGLDetail($BatchNo: Float!, $CompanyID: String!, $prefix: String!) {
  getExportedGLDetail(BatchNo: $BatchNo, CompanyID: $CompanyID, prefix: $prefix)
}
    `;

/**
 * __useGetApExportedGlDetailQuery__
 *
 * To run a query within a React component, call `useGetApExportedGlDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApExportedGlDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApExportedGlDetailQuery({
 *   variables: {
 *      BatchNo: // value for 'BatchNo'
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetApExportedGlDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApExportedGlDetailQuery, GetApExportedGlDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApExportedGlDetailQuery, GetApExportedGlDetailQueryVariables>(GetApExportedGlDetailDocument, baseOptions);
      }
export function useGetApExportedGlDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApExportedGlDetailQuery, GetApExportedGlDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApExportedGlDetailQuery, GetApExportedGlDetailQueryVariables>(GetApExportedGlDetailDocument, baseOptions);
        }
export type GetApExportedGlDetailQueryHookResult = ReturnType<typeof useGetApExportedGlDetailQuery>;
export type GetApExportedGlDetailLazyQueryHookResult = ReturnType<typeof useGetApExportedGlDetailLazyQuery>;
export type GetApExportedGlDetailQueryResult = ApolloReactCommon.QueryResult<GetApExportedGlDetailQuery, GetApExportedGlDetailQueryVariables>;
export const GetApglExportDetailByAccountCodeDocument = gql`
    query getAPGLExportDetailByAccountCode($CompanyID: String!, $AccountID: String!, $AccountCode: String!, $prefix: String!) {
  getGLExportDetailByAccountCode(CompanyID: $CompanyID, AccountID: $AccountID, AccountCode: $AccountCode, prefix: $prefix)
}
    `;

/**
 * __useGetApglExportDetailByAccountCodeQuery__
 *
 * To run a query within a React component, call `useGetApglExportDetailByAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApglExportDetailByAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApglExportDetailByAccountCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountID: // value for 'AccountID'
 *      AccountCode: // value for 'AccountCode'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetApglExportDetailByAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApglExportDetailByAccountCodeQuery, GetApglExportDetailByAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApglExportDetailByAccountCodeQuery, GetApglExportDetailByAccountCodeQueryVariables>(GetApglExportDetailByAccountCodeDocument, baseOptions);
      }
export function useGetApglExportDetailByAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApglExportDetailByAccountCodeQuery, GetApglExportDetailByAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApglExportDetailByAccountCodeQuery, GetApglExportDetailByAccountCodeQueryVariables>(GetApglExportDetailByAccountCodeDocument, baseOptions);
        }
export type GetApglExportDetailByAccountCodeQueryHookResult = ReturnType<typeof useGetApglExportDetailByAccountCodeQuery>;
export type GetApglExportDetailByAccountCodeLazyQueryHookResult = ReturnType<typeof useGetApglExportDetailByAccountCodeLazyQuery>;
export type GetApglExportDetailByAccountCodeQueryResult = ApolloReactCommon.QueryResult<GetApglExportDetailByAccountCodeQuery, GetApglExportDetailByAccountCodeQueryVariables>;
export const PostApglExportDocument = gql`
    mutation postAPGLExport($AccountID: String!, $CompanyID: String!, $GLDate: DateTime!, $startDate: DateTime!, $endDate: DateTime!, $prefix: String!) {
  postGLExport(AccountID: $AccountID, CompanyID: $CompanyID, GLDate: $GLDate, startDate: $startDate, endDate: $endDate, prefix: $prefix)
}
    `;
export type PostApglExportMutationFn = ApolloReactCommon.MutationFunction<PostApglExportMutation, PostApglExportMutationVariables>;

/**
 * __usePostApglExportMutation__
 *
 * To run a mutation, you first call `usePostApglExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApglExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApglExportMutation, { data, loading, error }] = usePostApglExportMutation({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      GLDate: // value for 'GLDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function usePostApglExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApglExportMutation, PostApglExportMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApglExportMutation, PostApglExportMutationVariables>(PostApglExportDocument, baseOptions);
      }
export type PostApglExportMutationHookResult = ReturnType<typeof usePostApglExportMutation>;
export type PostApglExportMutationResult = ApolloReactCommon.MutationResult<PostApglExportMutation>;
export type PostApglExportMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApglExportMutation, PostApglExportMutationVariables>;
export const GetApInvoicebyStatusDocument = gql`
    query getAPInvoicebyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String) {
  getAPInvoicebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    InvoiceID
    DocDate
    TransactionDate
    DueDate
    DocNo
    RefNo
    IsAutoDocNo
    DocNumGenerated
    ApprovalStatus
    CreditorAccountID
    CreditorAccount {
      CompanyName
      CreditTerm
    }
    Description
    Remark
    DocAmt
    BalanceAmt
    DO
    ProfileInfo
    APInvoiceItem {
      InvoiceItemID
      DOItemID
      Amount
      UnitPrice
      Quantity
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Name
        Code
      }
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
    }
  }
}
    `;

/**
 * __useGetApInvoicebyStatusQuery__
 *
 * To run a query within a React component, call `useGetApInvoicebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoicebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoicebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApInvoicebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoicebyStatusQuery, GetApInvoicebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoicebyStatusQuery, GetApInvoicebyStatusQueryVariables>(GetApInvoicebyStatusDocument, baseOptions);
      }
export function useGetApInvoicebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoicebyStatusQuery, GetApInvoicebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoicebyStatusQuery, GetApInvoicebyStatusQueryVariables>(GetApInvoicebyStatusDocument, baseOptions);
        }
export type GetApInvoicebyStatusQueryHookResult = ReturnType<typeof useGetApInvoicebyStatusQuery>;
export type GetApInvoicebyStatusLazyQueryHookResult = ReturnType<typeof useGetApInvoicebyStatusLazyQuery>;
export type GetApInvoicebyStatusQueryResult = ApolloReactCommon.QueryResult<GetApInvoicebyStatusQuery, GetApInvoicebyStatusQueryVariables>;
export const GetApInvoicebyStatusListingDocument = gql`
    query getAPInvoicebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String, $startDate: String, $endDate: String) {
  getAPInvoicebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField, startDate: $startDate, endDate: $endDate) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    InvoiceID
    DocDate
    TransactionDate
    DueDate
    DocNo
    RefNo
    IsAutoDocNo
    DocNumGenerated
    ApprovalStatus
    CreditorAccountID
    CreditorAccount {
      CompanyName
      CreditTerm
    }
    Description
    Remark
    DocAmt
    BalanceAmt
    DO
    ProfileInfo
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    einvoice_status
    einvoice_sent_date
    einvoice
    MSICCode
    SB
    einvoice_irbm_uniqueID
    APInvoiceItem {
      InvoiceItemID
      DOItemID
      Amount
      UnitPrice
      Quantity
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Name
        Code
      }
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      einvoice_classification_code_id
      ClassificationCode
    }
  }
}
    `;

/**
 * __useGetApInvoicebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApInvoicebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoicebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoicebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetApInvoicebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoicebyStatusListingQuery, GetApInvoicebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoicebyStatusListingQuery, GetApInvoicebyStatusListingQueryVariables>(GetApInvoicebyStatusListingDocument, baseOptions);
      }
export function useGetApInvoicebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoicebyStatusListingQuery, GetApInvoicebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoicebyStatusListingQuery, GetApInvoicebyStatusListingQueryVariables>(GetApInvoicebyStatusListingDocument, baseOptions);
        }
export type GetApInvoicebyStatusListingQueryHookResult = ReturnType<typeof useGetApInvoicebyStatusListingQuery>;
export type GetApInvoicebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApInvoicebyStatusListingLazyQuery>;
export type GetApInvoicebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApInvoicebyStatusListingQuery, GetApInvoicebyStatusListingQueryVariables>;
export const GetApInvoiceDocument = gql`
    query getAPInvoice($AccountID: String, $CompanyID: String, $InvoiceID: String, $orderByAsc: String, $orderByDesc: String, $CreditorAccountID: String) {
  getAPInvoice(AccountID: $AccountID, CompanyID: $CompanyID, InvoiceID: $InvoiceID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, CreditorAccountID: $CreditorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    InvoiceID
    DocDate
    DueDate
    CreditTerm
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    RefNo
    CreditorAccountID
    CreditorAccount {
      CompanyName
    }
    Description
    Remark
    DocAmt
    BalanceAmt
    DO
    APInvoiceItem {
      InvoiceItemID
      DOItemID
      Amount
      UnitPrice
      Quantity
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
      }
      UOMID
      UOM {
        Name
        Code
      }
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
    }
  }
}
    `;

/**
 * __useGetApInvoiceQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      InvoiceID: // value for 'InvoiceID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
      }
export function useGetApInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
        }
export type GetApInvoiceQueryHookResult = ReturnType<typeof useGetApInvoiceQuery>;
export type GetApInvoiceLazyQueryHookResult = ReturnType<typeof useGetApInvoiceLazyQuery>;
export type GetApInvoiceQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceQuery, GetApInvoiceQueryVariables>;
export const CreateApInvoiceDocument = gql`
    mutation createAPInvoice($input: APInvoiceInput!, $itemInput: [APInvoiceItemInput!]) {
  createAPInvoice(input: $input, itemInput: $itemInput) {
    InvoiceID
  }
}
    `;
export type CreateApInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>;

/**
 * __useCreateApInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApInvoiceMutation, { data, loading, error }] = useCreateApInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>(CreateApInvoiceDocument, baseOptions);
      }
export type CreateApInvoiceMutationHookResult = ReturnType<typeof useCreateApInvoiceMutation>;
export type CreateApInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateApInvoiceMutation>;
export type CreateApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>;
export const UpdateApInvoiceDocument = gql`
    mutation updateAPInvoice($input: APInvoiceInput!, $itemInput: [APInvoiceItemInput!]) {
  updateAPInvoice(input: $input, itemInput: $itemInput) {
    InvoiceID
  }
}
    `;
export type UpdateApInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateApInvoiceMutation, UpdateApInvoiceMutationVariables>;

/**
 * __useUpdateApInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApInvoiceMutation, { data, loading, error }] = useUpdateApInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApInvoiceMutation, UpdateApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApInvoiceMutation, UpdateApInvoiceMutationVariables>(UpdateApInvoiceDocument, baseOptions);
      }
export type UpdateApInvoiceMutationHookResult = ReturnType<typeof useUpdateApInvoiceMutation>;
export type UpdateApInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateApInvoiceMutation>;
export type UpdateApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApInvoiceMutation, UpdateApInvoiceMutationVariables>;
export const PostApInvoiceDocument = gql`
    mutation postAPInvoice($InvoiceIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPInvoice(InvoiceIDs: $InvoiceIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApInvoiceMutationFn = ApolloReactCommon.MutationFunction<PostApInvoiceMutation, PostApInvoiceMutationVariables>;

/**
 * __usePostApInvoiceMutation__
 *
 * To run a mutation, you first call `usePostApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApInvoiceMutation, { data, loading, error }] = usePostApInvoiceMutation({
 *   variables: {
 *      InvoiceIDs: // value for 'InvoiceIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApInvoiceMutation, PostApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApInvoiceMutation, PostApInvoiceMutationVariables>(PostApInvoiceDocument, baseOptions);
      }
export type PostApInvoiceMutationHookResult = ReturnType<typeof usePostApInvoiceMutation>;
export type PostApInvoiceMutationResult = ApolloReactCommon.MutationResult<PostApInvoiceMutation>;
export type PostApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApInvoiceMutation, PostApInvoiceMutationVariables>;
export const RejectApInvoiceDocument = gql`
    mutation rejectAPInvoice($APInvoiceIDs: [String!]!, $input: APInvoiceRejectInput!) {
  rejectAPInvoice(APInvoiceIDs: $APInvoiceIDs, input: $input)
}
    `;
export type RejectApInvoiceMutationFn = ApolloReactCommon.MutationFunction<RejectApInvoiceMutation, RejectApInvoiceMutationVariables>;

/**
 * __useRejectApInvoiceMutation__
 *
 * To run a mutation, you first call `useRejectApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApInvoiceMutation, { data, loading, error }] = useRejectApInvoiceMutation({
 *   variables: {
 *      APInvoiceIDs: // value for 'APInvoiceIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApInvoiceMutation, RejectApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApInvoiceMutation, RejectApInvoiceMutationVariables>(RejectApInvoiceDocument, baseOptions);
      }
export type RejectApInvoiceMutationHookResult = ReturnType<typeof useRejectApInvoiceMutation>;
export type RejectApInvoiceMutationResult = ApolloReactCommon.MutationResult<RejectApInvoiceMutation>;
export type RejectApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApInvoiceMutation, RejectApInvoiceMutationVariables>;
export const DeleteApInvoiceDocument = gql`
    mutation deleteAPInvoice($InvoiceID: String!) {
  deleteAPInvoice(InvoiceID: $InvoiceID)
}
    `;
export type DeleteApInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>;

/**
 * __useDeleteApInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApInvoiceMutation, { data, loading, error }] = useDeleteApInvoiceMutation({
 *   variables: {
 *      InvoiceID: // value for 'InvoiceID'
 *   },
 * });
 */
export function useDeleteApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>(DeleteApInvoiceDocument, baseOptions);
      }
export type DeleteApInvoiceMutationHookResult = ReturnType<typeof useDeleteApInvoiceMutation>;
export type DeleteApInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteApInvoiceMutation>;
export type DeleteApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>;
export const GetGeneralDOsDocument = gql`
    query getGeneralDOs($CreditorAccountID: String!, $CompanyID: String!, $DOItemIDs: [String!]) {
  getGeneralDOs(CreditorAccountID: $CreditorAccountID, CompanyID: $CompanyID, DOItemIDs: $DOItemIDs)
}
    `;

/**
 * __useGetGeneralDOsQuery__
 *
 * To run a query within a React component, call `useGetGeneralDOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralDOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralDOsQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      DOItemIDs: // value for 'DOItemIDs'
 *   },
 * });
 */
export function useGetGeneralDOsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralDOsQuery, GetGeneralDOsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralDOsQuery, GetGeneralDOsQueryVariables>(GetGeneralDOsDocument, baseOptions);
      }
export function useGetGeneralDOsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralDOsQuery, GetGeneralDOsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralDOsQuery, GetGeneralDOsQueryVariables>(GetGeneralDOsDocument, baseOptions);
        }
export type GetGeneralDOsQueryHookResult = ReturnType<typeof useGetGeneralDOsQuery>;
export type GetGeneralDOsLazyQueryHookResult = ReturnType<typeof useGetGeneralDOsLazyQuery>;
export type GetGeneralDOsQueryResult = ApolloReactCommon.QueryResult<GetGeneralDOsQuery, GetGeneralDOsQueryVariables>;
export const GetDoItemDocument = gql`
    query getDOItem($DOHeaderID: [String!]!) {
  getDOItem(DOHeaderID: $DOHeaderID)
}
    `;

/**
 * __useGetDoItemQuery__
 *
 * To run a query within a React component, call `useGetDoItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoItemQuery({
 *   variables: {
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useGetDoItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDoItemQuery, GetDoItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDoItemQuery, GetDoItemQueryVariables>(GetDoItemDocument, baseOptions);
      }
export function useGetDoItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDoItemQuery, GetDoItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDoItemQuery, GetDoItemQueryVariables>(GetDoItemDocument, baseOptions);
        }
export type GetDoItemQueryHookResult = ReturnType<typeof useGetDoItemQuery>;
export type GetDoItemLazyQueryHookResult = ReturnType<typeof useGetDoItemLazyQuery>;
export type GetDoItemQueryResult = ApolloReactCommon.QueryResult<GetDoItemQuery, GetDoItemQueryVariables>;
export const DuplicateInvoiceDocument = gql`
    mutation duplicateInvoice($InvoiceID: String!) {
  duplicateInvoice(InvoiceID: $InvoiceID)
}
    `;
export type DuplicateInvoiceMutationFn = ApolloReactCommon.MutationFunction<DuplicateInvoiceMutation, DuplicateInvoiceMutationVariables>;

/**
 * __useDuplicateInvoiceMutation__
 *
 * To run a mutation, you first call `useDuplicateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateInvoiceMutation, { data, loading, error }] = useDuplicateInvoiceMutation({
 *   variables: {
 *      InvoiceID: // value for 'InvoiceID'
 *   },
 * });
 */
export function useDuplicateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateInvoiceMutation, DuplicateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateInvoiceMutation, DuplicateInvoiceMutationVariables>(DuplicateInvoiceDocument, baseOptions);
      }
export type DuplicateInvoiceMutationHookResult = ReturnType<typeof useDuplicateInvoiceMutation>;
export type DuplicateInvoiceMutationResult = ApolloReactCommon.MutationResult<DuplicateInvoiceMutation>;
export type DuplicateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateInvoiceMutation, DuplicateInvoiceMutationVariables>;
export const GetApPaymentDocument = gql`
    query getAPPayment($AccountID: String, $CompanyID: String, $CreditorAccountID: String, $PaymentID: String, $orderByAsc: String, $orderByDesc: String) {
  getAPPayment(AccountID: $AccountID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, PaymentID: $PaymentID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    PaymentID
    BatchPaymentID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    CreditorAccountID
    CreditorAccount {
      CompanyName
      ContactNo
      GSTRegNo
      Address
      BankAccountNo
      BankName
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
      Code
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    RefNo
    Description
    Remark
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitRefNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditRefNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
      CreditDefaultAmt
      AllocatedDocs {
        AllocationID
        DebitDescription
        DebitDocNo
        DebitRefNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDescription
        CreditDocNo
        CreditRefNo
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
    }
  }
}
    `;

/**
 * __useGetApPaymentQuery__
 *
 * To run a query within a React component, call `useGetApPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      PaymentID: // value for 'PaymentID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetApPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
      }
export function useGetApPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
        }
export type GetApPaymentQueryHookResult = ReturnType<typeof useGetApPaymentQuery>;
export type GetApPaymentLazyQueryHookResult = ReturnType<typeof useGetApPaymentLazyQuery>;
export type GetApPaymentQueryResult = ApolloReactCommon.QueryResult<GetApPaymentQuery, GetApPaymentQueryVariables>;
export const CreateApPaymentDocument = gql`
    mutation createAPPayment($input: APPaymentInput!, $allocationInput: [AllocationInput!]) {
  createAPPayment(input: $input, allocationInput: $allocationInput) {
    PaymentID
  }
}
    `;
export type CreateApPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateApPaymentMutation, CreateApPaymentMutationVariables>;

/**
 * __useCreateApPaymentMutation__
 *
 * To run a mutation, you first call `useCreateApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApPaymentMutation, { data, loading, error }] = useCreateApPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useCreateApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApPaymentMutation, CreateApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApPaymentMutation, CreateApPaymentMutationVariables>(CreateApPaymentDocument, baseOptions);
      }
export type CreateApPaymentMutationHookResult = ReturnType<typeof useCreateApPaymentMutation>;
export type CreateApPaymentMutationResult = ApolloReactCommon.MutationResult<CreateApPaymentMutation>;
export type CreateApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApPaymentMutation, CreateApPaymentMutationVariables>;
export const UpdateApPaymentDocument = gql`
    mutation updateAPPayment($input: APPaymentInput!, $allocationInput: [AllocationInput!]) {
  updateAPPayment(input: $input, allocationInput: $allocationInput) {
    PaymentID
  }
}
    `;
export type UpdateApPaymentMutationFn = ApolloReactCommon.MutationFunction<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>;

/**
 * __useUpdateApPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApPaymentMutation, { data, loading, error }] = useUpdateApPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useUpdateApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>(UpdateApPaymentDocument, baseOptions);
      }
export type UpdateApPaymentMutationHookResult = ReturnType<typeof useUpdateApPaymentMutation>;
export type UpdateApPaymentMutationResult = ApolloReactCommon.MutationResult<UpdateApPaymentMutation>;
export type UpdateApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>;
export const PostApPaymentDocument = gql`
    mutation postAPPayment($PaymentIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPPayment(PaymentIDs: $PaymentIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApPaymentMutationFn = ApolloReactCommon.MutationFunction<PostApPaymentMutation, PostApPaymentMutationVariables>;

/**
 * __usePostApPaymentMutation__
 *
 * To run a mutation, you first call `usePostApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApPaymentMutation, { data, loading, error }] = usePostApPaymentMutation({
 *   variables: {
 *      PaymentIDs: // value for 'PaymentIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApPaymentMutation, PostApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApPaymentMutation, PostApPaymentMutationVariables>(PostApPaymentDocument, baseOptions);
      }
export type PostApPaymentMutationHookResult = ReturnType<typeof usePostApPaymentMutation>;
export type PostApPaymentMutationResult = ApolloReactCommon.MutationResult<PostApPaymentMutation>;
export type PostApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApPaymentMutation, PostApPaymentMutationVariables>;
export const RejectApPaymentDocument = gql`
    mutation rejectAPPayment($PaymentIDs: [String!]!, $input: APPaymentRejectInput!) {
  rejectAPPayment(PaymentIDs: $PaymentIDs, input: $input)
}
    `;
export type RejectApPaymentMutationFn = ApolloReactCommon.MutationFunction<RejectApPaymentMutation, RejectApPaymentMutationVariables>;

/**
 * __useRejectApPaymentMutation__
 *
 * To run a mutation, you first call `useRejectApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApPaymentMutation, { data, loading, error }] = useRejectApPaymentMutation({
 *   variables: {
 *      PaymentIDs: // value for 'PaymentIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApPaymentMutation, RejectApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApPaymentMutation, RejectApPaymentMutationVariables>(RejectApPaymentDocument, baseOptions);
      }
export type RejectApPaymentMutationHookResult = ReturnType<typeof useRejectApPaymentMutation>;
export type RejectApPaymentMutationResult = ApolloReactCommon.MutationResult<RejectApPaymentMutation>;
export type RejectApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApPaymentMutation, RejectApPaymentMutationVariables>;
export const DeleteApPaymentDocument = gql`
    mutation deleteAPPayment($PaymentID: String!) {
  deleteAPPayment(PaymentID: $PaymentID)
}
    `;
export type DeleteApPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>;

/**
 * __useDeleteApPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApPaymentMutation, { data, loading, error }] = useDeleteApPaymentMutation({
 *   variables: {
 *      PaymentID: // value for 'PaymentID'
 *   },
 * });
 */
export function useDeleteApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>(DeleteApPaymentDocument, baseOptions);
      }
export type DeleteApPaymentMutationHookResult = ReturnType<typeof useDeleteApPaymentMutation>;
export type DeleteApPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteApPaymentMutation>;
export type DeleteApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>;
export const GetApPaymentbyStatusDocument = gql`
    query getAPPaymentbyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String) {
  getAPPaymentbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    PaymentID
    BatchPaymentID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    CreditorAccountID
    ChequeNo
    CreditorAccount {
      CompanyName
      Address
      ContactNo
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    RefNo
    Description
    Remark
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApPaymentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetApPaymentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApPaymentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentbyStatusQuery, GetApPaymentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentbyStatusQuery, GetApPaymentbyStatusQueryVariables>(GetApPaymentbyStatusDocument, baseOptions);
      }
export function useGetApPaymentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentbyStatusQuery, GetApPaymentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentbyStatusQuery, GetApPaymentbyStatusQueryVariables>(GetApPaymentbyStatusDocument, baseOptions);
        }
export type GetApPaymentbyStatusQueryHookResult = ReturnType<typeof useGetApPaymentbyStatusQuery>;
export type GetApPaymentbyStatusLazyQueryHookResult = ReturnType<typeof useGetApPaymentbyStatusLazyQuery>;
export type GetApPaymentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetApPaymentbyStatusQuery, GetApPaymentbyStatusQueryVariables>;
export const GetApPaymentbyStatusListingDocument = gql`
    query getAPPaymentbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPPaymentbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    PaymentID
    BatchPaymentID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    CreditorAccountID
    ChequeNo
    CreditorAccount {
      CompanyName
      Address
      ContactNo
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    RefNo
    Description
    Remark
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
      AllocatedDocs {
        AllocationID
        DebitDescription
        DebitDocNo
        DebitRefNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDescription
        CreditDocNo
        CreditRefNo
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
    }
  }
}
    `;

/**
 * __useGetApPaymentbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApPaymentbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApPaymentbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentbyStatusListingQuery, GetApPaymentbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentbyStatusListingQuery, GetApPaymentbyStatusListingQueryVariables>(GetApPaymentbyStatusListingDocument, baseOptions);
      }
export function useGetApPaymentbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentbyStatusListingQuery, GetApPaymentbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentbyStatusListingQuery, GetApPaymentbyStatusListingQueryVariables>(GetApPaymentbyStatusListingDocument, baseOptions);
        }
export type GetApPaymentbyStatusListingQueryHookResult = ReturnType<typeof useGetApPaymentbyStatusListingQuery>;
export type GetApPaymentbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApPaymentbyStatusListingLazyQuery>;
export type GetApPaymentbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApPaymentbyStatusListingQuery, GetApPaymentbyStatusListingQueryVariables>;
export const GetApRefundDocument = gql`
    query getAPRefund($AccountID: String, $CompanyID: String, $CreditorAccountID: String, $RefundID: String, $orderByAsc: String, $orderByDesc: String) {
  getAPRefund(AccountID: $AccountID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, RefundID: $RefundID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    RefundID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    CreditorAccountID
    Attachment {
      fileURL
      mediaType
      name
    }
    CreditorAccount {
      CompanyName
      ContactNo
      Address
      GSTRegNo
      BankAccountNo
      BankName
      CreditorContact {
        Name
        ContactNo
        Email
      }
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
      Code
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    RefNo
    Description
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitRefNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditRefNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApRefundQuery__
 *
 * To run a query within a React component, call `useGetApRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApRefundQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      RefundID: // value for 'RefundID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetApRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApRefundQuery, GetApRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApRefundQuery, GetApRefundQueryVariables>(GetApRefundDocument, baseOptions);
      }
export function useGetApRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApRefundQuery, GetApRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApRefundQuery, GetApRefundQueryVariables>(GetApRefundDocument, baseOptions);
        }
export type GetApRefundQueryHookResult = ReturnType<typeof useGetApRefundQuery>;
export type GetApRefundLazyQueryHookResult = ReturnType<typeof useGetApRefundLazyQuery>;
export type GetApRefundQueryResult = ApolloReactCommon.QueryResult<GetApRefundQuery, GetApRefundQueryVariables>;
export const CreateApRefundDocument = gql`
    mutation createAPRefund($input: APRefundInput!, $allocationInput: [APAllocationInput!]) {
  createAPRefund(input: $input, allocationInput: $allocationInput) {
    RefundID
  }
}
    `;
export type CreateApRefundMutationFn = ApolloReactCommon.MutationFunction<CreateApRefundMutation, CreateApRefundMutationVariables>;

/**
 * __useCreateApRefundMutation__
 *
 * To run a mutation, you first call `useCreateApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApRefundMutation, { data, loading, error }] = useCreateApRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useCreateApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApRefundMutation, CreateApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApRefundMutation, CreateApRefundMutationVariables>(CreateApRefundDocument, baseOptions);
      }
export type CreateApRefundMutationHookResult = ReturnType<typeof useCreateApRefundMutation>;
export type CreateApRefundMutationResult = ApolloReactCommon.MutationResult<CreateApRefundMutation>;
export type CreateApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApRefundMutation, CreateApRefundMutationVariables>;
export const UpdateApRefundDocument = gql`
    mutation updateAPRefund($input: APRefundInput!, $allocationInput: [APAllocationInput!]) {
  updateAPRefund(input: $input, allocationInput: $allocationInput) {
    RefundID
  }
}
    `;
export type UpdateApRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateApRefundMutation, UpdateApRefundMutationVariables>;

/**
 * __useUpdateApRefundMutation__
 *
 * To run a mutation, you first call `useUpdateApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApRefundMutation, { data, loading, error }] = useUpdateApRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useUpdateApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApRefundMutation, UpdateApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApRefundMutation, UpdateApRefundMutationVariables>(UpdateApRefundDocument, baseOptions);
      }
export type UpdateApRefundMutationHookResult = ReturnType<typeof useUpdateApRefundMutation>;
export type UpdateApRefundMutationResult = ApolloReactCommon.MutationResult<UpdateApRefundMutation>;
export type UpdateApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApRefundMutation, UpdateApRefundMutationVariables>;
export const PostApRefundDocument = gql`
    mutation postAPRefund($RefundIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPRefund(RefundIDs: $RefundIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApRefundMutationFn = ApolloReactCommon.MutationFunction<PostApRefundMutation, PostApRefundMutationVariables>;

/**
 * __usePostApRefundMutation__
 *
 * To run a mutation, you first call `usePostApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApRefundMutation, { data, loading, error }] = usePostApRefundMutation({
 *   variables: {
 *      RefundIDs: // value for 'RefundIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApRefundMutation, PostApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApRefundMutation, PostApRefundMutationVariables>(PostApRefundDocument, baseOptions);
      }
export type PostApRefundMutationHookResult = ReturnType<typeof usePostApRefundMutation>;
export type PostApRefundMutationResult = ApolloReactCommon.MutationResult<PostApRefundMutation>;
export type PostApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApRefundMutation, PostApRefundMutationVariables>;
export const RejectApRefundDocument = gql`
    mutation rejectAPRefund($RefundIDs: [String!]!, $input: APRefundRejectInput!) {
  rejectAPRefund(RefundIDs: $RefundIDs, input: $input)
}
    `;
export type RejectApRefundMutationFn = ApolloReactCommon.MutationFunction<RejectApRefundMutation, RejectApRefundMutationVariables>;

/**
 * __useRejectApRefundMutation__
 *
 * To run a mutation, you first call `useRejectApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApRefundMutation, { data, loading, error }] = useRejectApRefundMutation({
 *   variables: {
 *      RefundIDs: // value for 'RefundIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApRefundMutation, RejectApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApRefundMutation, RejectApRefundMutationVariables>(RejectApRefundDocument, baseOptions);
      }
export type RejectApRefundMutationHookResult = ReturnType<typeof useRejectApRefundMutation>;
export type RejectApRefundMutationResult = ApolloReactCommon.MutationResult<RejectApRefundMutation>;
export type RejectApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApRefundMutation, RejectApRefundMutationVariables>;
export const DeleteApRefundDocument = gql`
    mutation deleteAPRefund($RefundID: String!) {
  deleteAPRefund(RefundID: $RefundID)
}
    `;
export type DeleteApRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteApRefundMutation, DeleteApRefundMutationVariables>;

/**
 * __useDeleteApRefundMutation__
 *
 * To run a mutation, you first call `useDeleteApRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApRefundMutation, { data, loading, error }] = useDeleteApRefundMutation({
 *   variables: {
 *      RefundID: // value for 'RefundID'
 *   },
 * });
 */
export function useDeleteApRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApRefundMutation, DeleteApRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApRefundMutation, DeleteApRefundMutationVariables>(DeleteApRefundDocument, baseOptions);
      }
export type DeleteApRefundMutationHookResult = ReturnType<typeof useDeleteApRefundMutation>;
export type DeleteApRefundMutationResult = ApolloReactCommon.MutationResult<DeleteApRefundMutation>;
export type DeleteApRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApRefundMutation, DeleteApRefundMutationVariables>;
export const GetApRefundbyStatusDocument = gql`
    query getAPRefundbyStatus($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $sortOrder: String, $sortField: String) {
  getAPRefundbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, sortOrder: $sortOrder, sortField: $sortField) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    ProfileInfo
    RefundID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    CreditorAccountID
    CreditorAccount {
      CompanyName
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    RefNo
    Description
    ApprovalStatus
    Attachment {
      fileURL
      mediaType
      name
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetApRefundbyStatusQuery__
 *
 * To run a query within a React component, call `useGetApRefundbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApRefundbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApRefundbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApRefundbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApRefundbyStatusQuery, GetApRefundbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApRefundbyStatusQuery, GetApRefundbyStatusQueryVariables>(GetApRefundbyStatusDocument, baseOptions);
      }
export function useGetApRefundbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApRefundbyStatusQuery, GetApRefundbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApRefundbyStatusQuery, GetApRefundbyStatusQueryVariables>(GetApRefundbyStatusDocument, baseOptions);
        }
export type GetApRefundbyStatusQueryHookResult = ReturnType<typeof useGetApRefundbyStatusQuery>;
export type GetApRefundbyStatusLazyQueryHookResult = ReturnType<typeof useGetApRefundbyStatusLazyQuery>;
export type GetApRefundbyStatusQueryResult = ApolloReactCommon.QueryResult<GetApRefundbyStatusQuery, GetApRefundbyStatusQueryVariables>;
export const GetApSelfBilledbyStatusListingDocument = gql`
    query getAPSelfBilledbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $CreditorAccountID: String, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getAPSelfBilledbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, CreditorAccountID: $CreditorAccountID, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    SelfBilledID
    DocDate
    TransactionDate
    RefNo
    CreditorAccountID
    CreditorAccount {
      CompanyName
      CreditTerm
    }
    SBDocRef {
      SelfBilledID
      DocRefID
    }
    DocRefNo
    is_einvoice
    einvoice_directsend
    einvoice_self_billed
    einvoice_self_billed_date
    einvoice_msic_code_id
    einvoice_irbm_uniqueID
    einvoice_trx_type
    einvoice_is_refund_note
    einvoice_doc_ref_invoice_id
    einvoice_sent_date
    einvoice_status
    einvoice
    is_double_entry
    MSICCode
    DocNo
    Amount
    Attachment {
      fileURL
      mediaType
      name
    }
    TaxAmt
    DocAmt
    Description
    Remark
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    AllocationCNFromCreditor {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    APLedger
    APSelfBilledItem {
      SelfBilledItemID
      UnitPrice
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      UOMID
      UOM {
        Code
        Name
      }
      Remark
      Quantity
      ExpenseItemID
      ExpenseItem {
        ExpenseItemID
        ItemName
        Description
      }
      einvoice_classification_code_id
      ClassificationCode
    }
    ProfileInfo
  }
}
    `;

/**
 * __useGetApSelfBilledbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetApSelfBilledbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApSelfBilledbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApSelfBilledbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetApSelfBilledbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApSelfBilledbyStatusListingQuery, GetApSelfBilledbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApSelfBilledbyStatusListingQuery, GetApSelfBilledbyStatusListingQueryVariables>(GetApSelfBilledbyStatusListingDocument, baseOptions);
      }
export function useGetApSelfBilledbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApSelfBilledbyStatusListingQuery, GetApSelfBilledbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApSelfBilledbyStatusListingQuery, GetApSelfBilledbyStatusListingQueryVariables>(GetApSelfBilledbyStatusListingDocument, baseOptions);
        }
export type GetApSelfBilledbyStatusListingQueryHookResult = ReturnType<typeof useGetApSelfBilledbyStatusListingQuery>;
export type GetApSelfBilledbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetApSelfBilledbyStatusListingLazyQuery>;
export type GetApSelfBilledbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetApSelfBilledbyStatusListingQuery, GetApSelfBilledbyStatusListingQueryVariables>;
export const CreateApSelfBilledDocument = gql`
    mutation createAPSelfBilled($input: APSelfBilledInput!, $itemInput: [APSelfBilledItemInput!], $docRefInput: [DocRefInputType!]) {
  createAPSelfBilled(input: $input, itemInput: $itemInput, docRefInput: $docRefInput) {
    SelfBilledID
  }
}
    `;
export type CreateApSelfBilledMutationFn = ApolloReactCommon.MutationFunction<CreateApSelfBilledMutation, CreateApSelfBilledMutationVariables>;

/**
 * __useCreateApSelfBilledMutation__
 *
 * To run a mutation, you first call `useCreateApSelfBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApSelfBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApSelfBilledMutation, { data, loading, error }] = useCreateApSelfBilledMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateApSelfBilledMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApSelfBilledMutation, CreateApSelfBilledMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApSelfBilledMutation, CreateApSelfBilledMutationVariables>(CreateApSelfBilledDocument, baseOptions);
      }
export type CreateApSelfBilledMutationHookResult = ReturnType<typeof useCreateApSelfBilledMutation>;
export type CreateApSelfBilledMutationResult = ApolloReactCommon.MutationResult<CreateApSelfBilledMutation>;
export type CreateApSelfBilledMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApSelfBilledMutation, CreateApSelfBilledMutationVariables>;
export const UpdateApSelfBilledDocument = gql`
    mutation updateAPSelfBilled($input: APSelfBilledInput!, $itemInput: [APSelfBilledItemInput!], $docRefInput: [DocRefInputType!]) {
  updateAPSelfBilled(input: $input, itemInput: $itemInput, docRefInput: $docRefInput) {
    SelfBilledID
  }
}
    `;
export type UpdateApSelfBilledMutationFn = ApolloReactCommon.MutationFunction<UpdateApSelfBilledMutation, UpdateApSelfBilledMutationVariables>;

/**
 * __useUpdateApSelfBilledMutation__
 *
 * To run a mutation, you first call `useUpdateApSelfBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApSelfBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApSelfBilledMutation, { data, loading, error }] = useUpdateApSelfBilledMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateApSelfBilledMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApSelfBilledMutation, UpdateApSelfBilledMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApSelfBilledMutation, UpdateApSelfBilledMutationVariables>(UpdateApSelfBilledDocument, baseOptions);
      }
export type UpdateApSelfBilledMutationHookResult = ReturnType<typeof useUpdateApSelfBilledMutation>;
export type UpdateApSelfBilledMutationResult = ApolloReactCommon.MutationResult<UpdateApSelfBilledMutation>;
export type UpdateApSelfBilledMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApSelfBilledMutation, UpdateApSelfBilledMutationVariables>;
export const PostApSelfBilledDocument = gql`
    mutation postAPSelfBilled($SelfBilledIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAPSelfBilled(SelfBilledIDs: $SelfBilledIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostApSelfBilledMutationFn = ApolloReactCommon.MutationFunction<PostApSelfBilledMutation, PostApSelfBilledMutationVariables>;

/**
 * __usePostApSelfBilledMutation__
 *
 * To run a mutation, you first call `usePostApSelfBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostApSelfBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postApSelfBilledMutation, { data, loading, error }] = usePostApSelfBilledMutation({
 *   variables: {
 *      SelfBilledIDs: // value for 'SelfBilledIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostApSelfBilledMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostApSelfBilledMutation, PostApSelfBilledMutationVariables>) {
        return ApolloReactHooks.useMutation<PostApSelfBilledMutation, PostApSelfBilledMutationVariables>(PostApSelfBilledDocument, baseOptions);
      }
export type PostApSelfBilledMutationHookResult = ReturnType<typeof usePostApSelfBilledMutation>;
export type PostApSelfBilledMutationResult = ApolloReactCommon.MutationResult<PostApSelfBilledMutation>;
export type PostApSelfBilledMutationOptions = ApolloReactCommon.BaseMutationOptions<PostApSelfBilledMutation, PostApSelfBilledMutationVariables>;
export const RejectApSelfBilledDocument = gql`
    mutation rejectAPSelfBilled($SelfBilledIDs: [String!]!, $input: APSelfBilledRejectInput!) {
  rejectAPSelfBilled(SelfBilledIDs: $SelfBilledIDs, input: $input)
}
    `;
export type RejectApSelfBilledMutationFn = ApolloReactCommon.MutationFunction<RejectApSelfBilledMutation, RejectApSelfBilledMutationVariables>;

/**
 * __useRejectApSelfBilledMutation__
 *
 * To run a mutation, you first call `useRejectApSelfBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApSelfBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApSelfBilledMutation, { data, loading, error }] = useRejectApSelfBilledMutation({
 *   variables: {
 *      SelfBilledIDs: // value for 'SelfBilledIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApSelfBilledMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectApSelfBilledMutation, RejectApSelfBilledMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectApSelfBilledMutation, RejectApSelfBilledMutationVariables>(RejectApSelfBilledDocument, baseOptions);
      }
export type RejectApSelfBilledMutationHookResult = ReturnType<typeof useRejectApSelfBilledMutation>;
export type RejectApSelfBilledMutationResult = ApolloReactCommon.MutationResult<RejectApSelfBilledMutation>;
export type RejectApSelfBilledMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectApSelfBilledMutation, RejectApSelfBilledMutationVariables>;
export const DeleteApSelfBilledDocument = gql`
    mutation deleteAPSelfBilled($SelfBilledID: String!) {
  deleteAPSelfBilled(SelfBilledID: $SelfBilledID)
}
    `;
export type DeleteApSelfBilledMutationFn = ApolloReactCommon.MutationFunction<DeleteApSelfBilledMutation, DeleteApSelfBilledMutationVariables>;

/**
 * __useDeleteApSelfBilledMutation__
 *
 * To run a mutation, you first call `useDeleteApSelfBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApSelfBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApSelfBilledMutation, { data, loading, error }] = useDeleteApSelfBilledMutation({
 *   variables: {
 *      SelfBilledID: // value for 'SelfBilledID'
 *   },
 * });
 */
export function useDeleteApSelfBilledMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApSelfBilledMutation, DeleteApSelfBilledMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApSelfBilledMutation, DeleteApSelfBilledMutationVariables>(DeleteApSelfBilledDocument, baseOptions);
      }
export type DeleteApSelfBilledMutationHookResult = ReturnType<typeof useDeleteApSelfBilledMutation>;
export type DeleteApSelfBilledMutationResult = ApolloReactCommon.MutationResult<DeleteApSelfBilledMutation>;
export type DeleteApSelfBilledMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApSelfBilledMutation, DeleteApSelfBilledMutationVariables>;
export const GetApInvoiceSelfBilledDocument = gql`
    query getAPInvoiceSelfBilled($CompanyID: String!, $CreditorAccountID: String) {
  getAPInvoiceSelfBilled(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID) {
    SelfBilledID
    DocNo
    einvoice_status
    einvoice_directsend
  }
}
    `;

/**
 * __useGetApInvoiceSelfBilledQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceSelfBilledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceSelfBilledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceSelfBilledQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetApInvoiceSelfBilledQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceSelfBilledQuery, GetApInvoiceSelfBilledQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceSelfBilledQuery, GetApInvoiceSelfBilledQueryVariables>(GetApInvoiceSelfBilledDocument, baseOptions);
      }
export function useGetApInvoiceSelfBilledLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceSelfBilledQuery, GetApInvoiceSelfBilledQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceSelfBilledQuery, GetApInvoiceSelfBilledQueryVariables>(GetApInvoiceSelfBilledDocument, baseOptions);
        }
export type GetApInvoiceSelfBilledQueryHookResult = ReturnType<typeof useGetApInvoiceSelfBilledQuery>;
export type GetApInvoiceSelfBilledLazyQueryHookResult = ReturnType<typeof useGetApInvoiceSelfBilledLazyQuery>;
export type GetApInvoiceSelfBilledQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceSelfBilledQuery, GetApInvoiceSelfBilledQueryVariables>;
export const CancelEinvoiceDocument = gql`
    mutation cancelEinvoice($SelfBilledID: String!, $CompanyID: String!) {
  cancelEinvoice(SelfBilledID: $SelfBilledID, CompanyID: $CompanyID)
}
    `;
export type CancelEinvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelEinvoiceMutation, CancelEinvoiceMutationVariables>;

/**
 * __useCancelEinvoiceMutation__
 *
 * To run a mutation, you first call `useCancelEinvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEinvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEinvoiceMutation, { data, loading, error }] = useCancelEinvoiceMutation({
 *   variables: {
 *      SelfBilledID: // value for 'SelfBilledID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCancelEinvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelEinvoiceMutation, CancelEinvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelEinvoiceMutation, CancelEinvoiceMutationVariables>(CancelEinvoiceDocument, baseOptions);
      }
export type CancelEinvoiceMutationHookResult = ReturnType<typeof useCancelEinvoiceMutation>;
export type CancelEinvoiceMutationResult = ApolloReactCommon.MutationResult<CancelEinvoiceMutation>;
export type CancelEinvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelEinvoiceMutation, CancelEinvoiceMutationVariables>;
export const GetApSubmenuSummaryDocument = gql`
    query getAPSubmenuSummary($CompanyID: String!) {
  apAdvanceCount(CompanyID: $CompanyID)
  apInvoiceCount(CompanyID: $CompanyID)
  apCreditNoteCount(CompanyID: $CompanyID)
  apDebitNoteCount(CompanyID: $CompanyID)
  apPaymentCount(CompanyID: $CompanyID)
  apBatchPaymentCount(CompanyID: $CompanyID)
  apRefundCount(CompanyID: $CompanyID)
  apCreditorCreditNoteCount(CompanyID: $CompanyID)
  apCreditorDebitNoteCount(CompanyID: $CompanyID)
  apSelfBilledCount(CompanyID: $CompanyID)
  creditorAccountCount(CompanyID: $CompanyID)
  apConsolidateCount(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetApSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetApSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApSubmenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetApSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApSubmenuSummaryQuery, GetApSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApSubmenuSummaryQuery, GetApSubmenuSummaryQueryVariables>(GetApSubmenuSummaryDocument, baseOptions);
      }
export function useGetApSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApSubmenuSummaryQuery, GetApSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApSubmenuSummaryQuery, GetApSubmenuSummaryQueryVariables>(GetApSubmenuSummaryDocument, baseOptions);
        }
export type GetApSubmenuSummaryQueryHookResult = ReturnType<typeof useGetApSubmenuSummaryQuery>;
export type GetApSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetApSubmenuSummaryLazyQuery>;
export type GetApSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetApSubmenuSummaryQuery, GetApSubmenuSummaryQueryVariables>;
export const GetCreditorSubmenuSummaryDocument = gql`
    query getCreditorSubmenuSummary($CompanyID: String!, $CreditorAccountID: String!) {
  apAdvanceTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apInvoiceTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apCreditNoteTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apCreditorCreditNoteTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apDebitNoteTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apCreditorDebitNoteTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apPaymentTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apRefundTotalAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetCreditorSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetCreditorSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorSubmenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorSubmenuSummaryQuery, GetCreditorSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorSubmenuSummaryQuery, GetCreditorSubmenuSummaryQueryVariables>(GetCreditorSubmenuSummaryDocument, baseOptions);
      }
export function useGetCreditorSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorSubmenuSummaryQuery, GetCreditorSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorSubmenuSummaryQuery, GetCreditorSubmenuSummaryQueryVariables>(GetCreditorSubmenuSummaryDocument, baseOptions);
        }
export type GetCreditorSubmenuSummaryQueryHookResult = ReturnType<typeof useGetCreditorSubmenuSummaryQuery>;
export type GetCreditorSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetCreditorSubmenuSummaryLazyQuery>;
export type GetCreditorSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetCreditorSubmenuSummaryQuery, GetCreditorSubmenuSummaryQueryVariables>;
export const GetCreditorTrxSummaryDocument = gql`
    query getCreditorTrxSummary($CompanyID: String!, $CreditorAccountID: String!) {
  apAdvanceLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apInvoiceLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apCreditNoteLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apCreditorCreditNoteLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apDebitNoteLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apCreditorDebitNoteLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apPaymentLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
  apRefundLatestUpdate(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetCreditorTrxSummaryQuery__
 *
 * To run a query within a React component, call `useGetCreditorTrxSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTrxSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTrxSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorTrxSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTrxSummaryQuery, GetCreditorTrxSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTrxSummaryQuery, GetCreditorTrxSummaryQueryVariables>(GetCreditorTrxSummaryDocument, baseOptions);
      }
export function useGetCreditorTrxSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTrxSummaryQuery, GetCreditorTrxSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTrxSummaryQuery, GetCreditorTrxSummaryQueryVariables>(GetCreditorTrxSummaryDocument, baseOptions);
        }
export type GetCreditorTrxSummaryQueryHookResult = ReturnType<typeof useGetCreditorTrxSummaryQuery>;
export type GetCreditorTrxSummaryLazyQueryHookResult = ReturnType<typeof useGetCreditorTrxSummaryLazyQuery>;
export type GetCreditorTrxSummaryQueryResult = ApolloReactCommon.QueryResult<GetCreditorTrxSummaryQuery, GetCreditorTrxSummaryQueryVariables>;
export const GetOsCreditorCreditDocDocument = gql`
    query getOsCreditorCreditDoc($CompanyID: String!, $CreditorAccountID: String) {
  getAPInvoice(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    InvoiceID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAPDebitNote(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    DebitNoteID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAPCreditNote(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    CreditNoteID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAPCreditorDebitNote(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    CreditorDebitNoteID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAPCreditorCreditNote(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    CreditorCreditNoteID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAPPayment(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    PaymentID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAPRefund(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, ApprovalStatus: COMPLETED) {
    RefundID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
}
    `;

/**
 * __useGetOsCreditorCreditDocQuery__
 *
 * To run a query within a React component, call `useGetOsCreditorCreditDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsCreditorCreditDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsCreditorCreditDocQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetOsCreditorCreditDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsCreditorCreditDocQuery, GetOsCreditorCreditDocQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsCreditorCreditDocQuery, GetOsCreditorCreditDocQueryVariables>(GetOsCreditorCreditDocDocument, baseOptions);
      }
export function useGetOsCreditorCreditDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsCreditorCreditDocQuery, GetOsCreditorCreditDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsCreditorCreditDocQuery, GetOsCreditorCreditDocQueryVariables>(GetOsCreditorCreditDocDocument, baseOptions);
        }
export type GetOsCreditorCreditDocQueryHookResult = ReturnType<typeof useGetOsCreditorCreditDocQuery>;
export type GetOsCreditorCreditDocLazyQueryHookResult = ReturnType<typeof useGetOsCreditorCreditDocLazyQuery>;
export type GetOsCreditorCreditDocQueryResult = ApolloReactCommon.QueryResult<GetOsCreditorCreditDocQuery, GetOsCreditorCreditDocQueryVariables>;
export const GetCreditorTotalDocAmtDocument = gql`
    query getCreditorTotalDocAmt($CompanyID: String!) {
  getCreditorTotalDocAmt(CompanyID: $CompanyID) {
    CreditorAccountID
    TotalDocAmount
  }
}
    `;

/**
 * __useGetCreditorTotalDocAmtQuery__
 *
 * To run a query within a React component, call `useGetCreditorTotalDocAmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTotalDocAmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTotalDocAmtQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCreditorTotalDocAmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTotalDocAmtQuery, GetCreditorTotalDocAmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTotalDocAmtQuery, GetCreditorTotalDocAmtQueryVariables>(GetCreditorTotalDocAmtDocument, baseOptions);
      }
export function useGetCreditorTotalDocAmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTotalDocAmtQuery, GetCreditorTotalDocAmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTotalDocAmtQuery, GetCreditorTotalDocAmtQueryVariables>(GetCreditorTotalDocAmtDocument, baseOptions);
        }
export type GetCreditorTotalDocAmtQueryHookResult = ReturnType<typeof useGetCreditorTotalDocAmtQuery>;
export type GetCreditorTotalDocAmtLazyQueryHookResult = ReturnType<typeof useGetCreditorTotalDocAmtLazyQuery>;
export type GetCreditorTotalDocAmtQueryResult = ApolloReactCommon.QueryResult<GetCreditorTotalDocAmtQuery, GetCreditorTotalDocAmtQueryVariables>;
export const GetDebtorTotalDocAmtDocument = gql`
    query getDebtorTotalDocAmt($CompanyID: String!) {
  getDebtorTotalDocAmt(CompanyID: $CompanyID) {
    DebtorAccountID
    TotalDocAmount
  }
}
    `;

/**
 * __useGetDebtorTotalDocAmtQuery__
 *
 * To run a query within a React component, call `useGetDebtorTotalDocAmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtorTotalDocAmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtorTotalDocAmtQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetDebtorTotalDocAmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebtorTotalDocAmtQuery, GetDebtorTotalDocAmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebtorTotalDocAmtQuery, GetDebtorTotalDocAmtQueryVariables>(GetDebtorTotalDocAmtDocument, baseOptions);
      }
export function useGetDebtorTotalDocAmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebtorTotalDocAmtQuery, GetDebtorTotalDocAmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebtorTotalDocAmtQuery, GetDebtorTotalDocAmtQueryVariables>(GetDebtorTotalDocAmtDocument, baseOptions);
        }
export type GetDebtorTotalDocAmtQueryHookResult = ReturnType<typeof useGetDebtorTotalDocAmtQuery>;
export type GetDebtorTotalDocAmtLazyQueryHookResult = ReturnType<typeof useGetDebtorTotalDocAmtLazyQuery>;
export type GetDebtorTotalDocAmtQueryResult = ApolloReactCommon.QueryResult<GetDebtorTotalDocAmtQuery, GetDebtorTotalDocAmtQueryVariables>;
export const GetDebtorLatestUpdatedDateDocument = gql`
    query getDebtorLatestUpdatedDate($CompanyID: String!) {
  getDebtorLatestUpdatedDate(CompanyID: $CompanyID) {
    DebtorAccountID
    LatestUpdatedDate
  }
}
    `;

/**
 * __useGetDebtorLatestUpdatedDateQuery__
 *
 * To run a query within a React component, call `useGetDebtorLatestUpdatedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtorLatestUpdatedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtorLatestUpdatedDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetDebtorLatestUpdatedDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebtorLatestUpdatedDateQuery, GetDebtorLatestUpdatedDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebtorLatestUpdatedDateQuery, GetDebtorLatestUpdatedDateQueryVariables>(GetDebtorLatestUpdatedDateDocument, baseOptions);
      }
export function useGetDebtorLatestUpdatedDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebtorLatestUpdatedDateQuery, GetDebtorLatestUpdatedDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebtorLatestUpdatedDateQuery, GetDebtorLatestUpdatedDateQueryVariables>(GetDebtorLatestUpdatedDateDocument, baseOptions);
        }
export type GetDebtorLatestUpdatedDateQueryHookResult = ReturnType<typeof useGetDebtorLatestUpdatedDateQuery>;
export type GetDebtorLatestUpdatedDateLazyQueryHookResult = ReturnType<typeof useGetDebtorLatestUpdatedDateLazyQuery>;
export type GetDebtorLatestUpdatedDateQueryResult = ApolloReactCommon.QueryResult<GetDebtorLatestUpdatedDateQuery, GetDebtorLatestUpdatedDateQueryVariables>;
export const GetCreditorLatestUpdatedDateDocument = gql`
    query getCreditorLatestUpdatedDate($CompanyID: String!) {
  getCreditorLatestUpdatedDate(CompanyID: $CompanyID) {
    CreditorAccountID
    LatestUpdatedDate
  }
}
    `;

/**
 * __useGetCreditorLatestUpdatedDateQuery__
 *
 * To run a query within a React component, call `useGetCreditorLatestUpdatedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorLatestUpdatedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorLatestUpdatedDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCreditorLatestUpdatedDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorLatestUpdatedDateQuery, GetCreditorLatestUpdatedDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorLatestUpdatedDateQuery, GetCreditorLatestUpdatedDateQueryVariables>(GetCreditorLatestUpdatedDateDocument, baseOptions);
      }
export function useGetCreditorLatestUpdatedDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorLatestUpdatedDateQuery, GetCreditorLatestUpdatedDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorLatestUpdatedDateQuery, GetCreditorLatestUpdatedDateQueryVariables>(GetCreditorLatestUpdatedDateDocument, baseOptions);
        }
export type GetCreditorLatestUpdatedDateQueryHookResult = ReturnType<typeof useGetCreditorLatestUpdatedDateQuery>;
export type GetCreditorLatestUpdatedDateLazyQueryHookResult = ReturnType<typeof useGetCreditorLatestUpdatedDateLazyQuery>;
export type GetCreditorLatestUpdatedDateQueryResult = ApolloReactCommon.QueryResult<GetCreditorLatestUpdatedDateQuery, GetCreditorLatestUpdatedDateQueryVariables>;
export const GetEachSubDocAmtDocument = gql`
    query getEachSubDocAmt($CompanyID: String!, $CreditorAccountID: String, $DebtorAccountID: String) {
  getEachSubDocAmt(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, DebtorAccountID: $DebtorAccountID) {
    AdvanceTotalDocAmt
    CreditNoteTotalDocAmt
    DebitNoteTotalDocAmt
    InvoiceTotalDocAmt
  }
}
    `;

/**
 * __useGetEachSubDocAmtQuery__
 *
 * To run a query within a React component, call `useGetEachSubDocAmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEachSubDocAmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEachSubDocAmtQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetEachSubDocAmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEachSubDocAmtQuery, GetEachSubDocAmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEachSubDocAmtQuery, GetEachSubDocAmtQueryVariables>(GetEachSubDocAmtDocument, baseOptions);
      }
export function useGetEachSubDocAmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEachSubDocAmtQuery, GetEachSubDocAmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEachSubDocAmtQuery, GetEachSubDocAmtQueryVariables>(GetEachSubDocAmtDocument, baseOptions);
        }
export type GetEachSubDocAmtQueryHookResult = ReturnType<typeof useGetEachSubDocAmtQuery>;
export type GetEachSubDocAmtLazyQueryHookResult = ReturnType<typeof useGetEachSubDocAmtLazyQuery>;
export type GetEachSubDocAmtQueryResult = ApolloReactCommon.QueryResult<GetEachSubDocAmtQuery, GetEachSubDocAmtQueryVariables>;
export const GetAllocationDocumentDocument = gql`
    query getAllocationDocument($entityID: [String!], $refTable: String!, $companyID: String!, $creditorDebtorID: String!, $isEinvoiceSelfBilled: Boolean) {
  getAllocationDocument(entityID: $entityID, refTable: $refTable, companyID: $companyID, creditorDebtorID: $creditorDebtorID, isEinvoiceSelfBilled: $isEinvoiceSelfBilled)
}
    `;

/**
 * __useGetAllocationDocumentQuery__
 *
 * To run a query within a React component, call `useGetAllocationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllocationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllocationDocumentQuery({
 *   variables: {
 *      entityID: // value for 'entityID'
 *      refTable: // value for 'refTable'
 *      companyID: // value for 'companyID'
 *      creditorDebtorID: // value for 'creditorDebtorID'
 *      isEinvoiceSelfBilled: // value for 'isEinvoiceSelfBilled'
 *   },
 * });
 */
export function useGetAllocationDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllocationDocumentQuery, GetAllocationDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllocationDocumentQuery, GetAllocationDocumentQueryVariables>(GetAllocationDocumentDocument, baseOptions);
      }
export function useGetAllocationDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllocationDocumentQuery, GetAllocationDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllocationDocumentQuery, GetAllocationDocumentQueryVariables>(GetAllocationDocumentDocument, baseOptions);
        }
export type GetAllocationDocumentQueryHookResult = ReturnType<typeof useGetAllocationDocumentQuery>;
export type GetAllocationDocumentLazyQueryHookResult = ReturnType<typeof useGetAllocationDocumentLazyQuery>;
export type GetAllocationDocumentQueryResult = ApolloReactCommon.QueryResult<GetAllocationDocumentQuery, GetAllocationDocumentQueryVariables>;
export const CreateAllocationDocument = gql`
    mutation createAllocation($entityID: String, $input: [AllocationInput!]) {
  createAllocation(entityID: $entityID, input: $input)
}
    `;
export type CreateAllocationMutationFn = ApolloReactCommon.MutationFunction<CreateAllocationMutation, CreateAllocationMutationVariables>;

/**
 * __useCreateAllocationMutation__
 *
 * To run a mutation, you first call `useCreateAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllocationMutation, { data, loading, error }] = useCreateAllocationMutation({
 *   variables: {
 *      entityID: // value for 'entityID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAllocationMutation, CreateAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAllocationMutation, CreateAllocationMutationVariables>(CreateAllocationDocument, baseOptions);
      }
export type CreateAllocationMutationHookResult = ReturnType<typeof useCreateAllocationMutation>;
export type CreateAllocationMutationResult = ApolloReactCommon.MutationResult<CreateAllocationMutation>;
export type CreateAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAllocationMutation, CreateAllocationMutationVariables>;
export const GetClassificationCodeDocument = gql`
    query getClassificationCode {
  getClassificationCode {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationCodeQuery__
 *
 * To run a query within a React component, call `useGetClassificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassificationCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
      }
export function useGetClassificationCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
        }
export type GetClassificationCodeQueryHookResult = ReturnType<typeof useGetClassificationCodeQuery>;
export type GetClassificationCodeLazyQueryHookResult = ReturnType<typeof useGetClassificationCodeLazyQuery>;
export type GetClassificationCodeQueryResult = ApolloReactCommon.QueryResult<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>;
export const GetMsicCodeDocument = gql`
    query getMSICCode {
  getMSICCode {
    id
    msicCode
    description
    categoryReference
  }
}
    `;

/**
 * __useGetMsicCodeQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMsicCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
      }
export function useGetMsicCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
        }
export type GetMsicCodeQueryHookResult = ReturnType<typeof useGetMsicCodeQuery>;
export type GetMsicCodeLazyQueryHookResult = ReturnType<typeof useGetMsicCodeLazyQuery>;
export type GetMsicCodeQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeQuery, GetMsicCodeQueryVariables>;
export const GetArAdvanceDocument = gql`
    query getARAdvance($AccountID: String, $CompanyID: String, $AdvanceID: String, $orderByDesc: String, $DebtorAccountID: String) {
  getARAdvance(AccountID: $AccountID, CompanyID: $CompanyID, AdvanceID: $AdvanceID, orderByDesc: $orderByDesc, DebtorAccountID: $DebtorAccountID) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    RecordStatus
    AdvanceID
    DocDate
    TransactionDate
    RefNo
    DocNo
    DebtorAccountID
    DebtorAccount {
      DebtorName
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    TaxSchemeID
    TaxScheme {
      Code
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      BankAccountID
      BankProfile {
        Name
        Address
      }
    }
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    Amount
    TaxAmt
    TaxRate
    TaxInvoiceNo
    DocAmt
    BalanceAmt
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetArAdvanceQuery__
 *
 * To run a query within a React component, call `useGetArAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAdvanceQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      AdvanceID: // value for 'AdvanceID'
 *      orderByDesc: // value for 'orderByDesc'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAdvanceQuery, GetArAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAdvanceQuery, GetArAdvanceQueryVariables>(GetArAdvanceDocument, baseOptions);
      }
export function useGetArAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAdvanceQuery, GetArAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAdvanceQuery, GetArAdvanceQueryVariables>(GetArAdvanceDocument, baseOptions);
        }
export type GetArAdvanceQueryHookResult = ReturnType<typeof useGetArAdvanceQuery>;
export type GetArAdvanceLazyQueryHookResult = ReturnType<typeof useGetArAdvanceLazyQuery>;
export type GetArAdvanceQueryResult = ApolloReactCommon.QueryResult<GetArAdvanceQuery, GetArAdvanceQueryVariables>;
export const CreateArAdvanceDocument = gql`
    mutation createARAdvance($input: ARAdvanceInput!) {
  createARAdvance(input: $input)
}
    `;
export type CreateArAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateArAdvanceMutation, CreateArAdvanceMutationVariables>;

/**
 * __useCreateArAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateArAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArAdvanceMutation, { data, loading, error }] = useCreateArAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArAdvanceMutation, CreateArAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArAdvanceMutation, CreateArAdvanceMutationVariables>(CreateArAdvanceDocument, baseOptions);
      }
export type CreateArAdvanceMutationHookResult = ReturnType<typeof useCreateArAdvanceMutation>;
export type CreateArAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateArAdvanceMutation>;
export type CreateArAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArAdvanceMutation, CreateArAdvanceMutationVariables>;
export const UpdateArAdvanceDocument = gql`
    mutation updateARAdvance($input: ARAdvanceInput!) {
  updateARAdvance(input: $input)
}
    `;
export type UpdateArAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateArAdvanceMutation, UpdateArAdvanceMutationVariables>;

/**
 * __useUpdateArAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateArAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArAdvanceMutation, { data, loading, error }] = useUpdateArAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArAdvanceMutation, UpdateArAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArAdvanceMutation, UpdateArAdvanceMutationVariables>(UpdateArAdvanceDocument, baseOptions);
      }
export type UpdateArAdvanceMutationHookResult = ReturnType<typeof useUpdateArAdvanceMutation>;
export type UpdateArAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateArAdvanceMutation>;
export type UpdateArAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArAdvanceMutation, UpdateArAdvanceMutationVariables>;
export const DeleteArAdvanceDocument = gql`
    mutation deleteARAdvance($AdvanceID: String!) {
  deleteARAdvance(AdvanceID: $AdvanceID)
}
    `;
export type DeleteArAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteArAdvanceMutation, DeleteArAdvanceMutationVariables>;

/**
 * __useDeleteArAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteArAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArAdvanceMutation, { data, loading, error }] = useDeleteArAdvanceMutation({
 *   variables: {
 *      AdvanceID: // value for 'AdvanceID'
 *   },
 * });
 */
export function useDeleteArAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArAdvanceMutation, DeleteArAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArAdvanceMutation, DeleteArAdvanceMutationVariables>(DeleteArAdvanceDocument, baseOptions);
      }
export type DeleteArAdvanceMutationHookResult = ReturnType<typeof useDeleteArAdvanceMutation>;
export type DeleteArAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteArAdvanceMutation>;
export type DeleteArAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArAdvanceMutation, DeleteArAdvanceMutationVariables>;
export const PostArAdvanceDocument = gql`
    mutation postARAdvance($AdvanceIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postARAdvance(AdvanceIDs: $AdvanceIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostArAdvanceMutationFn = ApolloReactCommon.MutationFunction<PostArAdvanceMutation, PostArAdvanceMutationVariables>;

/**
 * __usePostArAdvanceMutation__
 *
 * To run a mutation, you first call `usePostArAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArAdvanceMutation, { data, loading, error }] = usePostArAdvanceMutation({
 *   variables: {
 *      AdvanceIDs: // value for 'AdvanceIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostArAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArAdvanceMutation, PostArAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArAdvanceMutation, PostArAdvanceMutationVariables>(PostArAdvanceDocument, baseOptions);
      }
export type PostArAdvanceMutationHookResult = ReturnType<typeof usePostArAdvanceMutation>;
export type PostArAdvanceMutationResult = ApolloReactCommon.MutationResult<PostArAdvanceMutation>;
export type PostArAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArAdvanceMutation, PostArAdvanceMutationVariables>;
export const RejectArAdvanceDocument = gql`
    mutation rejectARAdvance($AdvanceIDs: [String!]!, $input: ARAdvanceRejectInput!) {
  rejectARAdvance(AdvanceIDs: $AdvanceIDs, input: $input)
}
    `;
export type RejectArAdvanceMutationFn = ApolloReactCommon.MutationFunction<RejectArAdvanceMutation, RejectArAdvanceMutationVariables>;

/**
 * __useRejectArAdvanceMutation__
 *
 * To run a mutation, you first call `useRejectArAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectArAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectArAdvanceMutation, { data, loading, error }] = useRejectArAdvanceMutation({
 *   variables: {
 *      AdvanceIDs: // value for 'AdvanceIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectArAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectArAdvanceMutation, RejectArAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectArAdvanceMutation, RejectArAdvanceMutationVariables>(RejectArAdvanceDocument, baseOptions);
      }
export type RejectArAdvanceMutationHookResult = ReturnType<typeof useRejectArAdvanceMutation>;
export type RejectArAdvanceMutationResult = ApolloReactCommon.MutationResult<RejectArAdvanceMutation>;
export type RejectArAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectArAdvanceMutation, RejectArAdvanceMutationVariables>;
export const GetArAdvancebyStatusDocument = gql`
    query getARAdvancebyStatus($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String) {
  getARAdvancebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID) {
    Attachment {
      fileURL
      mediaType
      name
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    RecordStatus
    AdvanceID
    DocDate
    TransactionDate
    RefNo
    DocNo
    DebtorAccountID
    DebtorAccount {
      DebtorName
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    TaxSchemeID
    TaxScheme {
      Code
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      BankAccountID
      Code
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    Description
    Remark
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    Amount
    TaxAmt
    TaxRate
    TaxInvoiceNo
    DocAmt
    BalanceAmt
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetArAdvancebyStatusQuery__
 *
 * To run a query within a React component, call `useGetArAdvancebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAdvancebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAdvancebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArAdvancebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAdvancebyStatusQuery, GetArAdvancebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAdvancebyStatusQuery, GetArAdvancebyStatusQueryVariables>(GetArAdvancebyStatusDocument, baseOptions);
      }
export function useGetArAdvancebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAdvancebyStatusQuery, GetArAdvancebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAdvancebyStatusQuery, GetArAdvancebyStatusQueryVariables>(GetArAdvancebyStatusDocument, baseOptions);
        }
export type GetArAdvancebyStatusQueryHookResult = ReturnType<typeof useGetArAdvancebyStatusQuery>;
export type GetArAdvancebyStatusLazyQueryHookResult = ReturnType<typeof useGetArAdvancebyStatusLazyQuery>;
export type GetArAdvancebyStatusQueryResult = ApolloReactCommon.QueryResult<GetArAdvancebyStatusQuery, GetArAdvancebyStatusQueryVariables>;
export const GetArAdvancebyStatusListingDocument = gql`
    query getARAdvancebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getARAdvancebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      Attachment {
        fileURL
        mediaType
        name
      }
      createdTs
      createdBy
      modTs
      modBy
      submittedTs
      submittedBy
      approvedTs
      approvedBy
      AccountID
      RecordStatus
      AdvanceID
      DocDate
      TransactionDate
      RefNo
      DocNo
      DebtorAccountID
      DebtorAccount {
        DebtorName
      }
      PaymentMethodID
      PaymentMethod {
        Name
      }
      TaxSchemeID
      TaxScheme {
        Code
      }
      CreditCardID
      CreditCardType {
        CreditCardID
        Name
      }
      ChequeNo
      ChequeDate
      ChequeExpiryDate
      BankAccountID
      BankAccount {
        BankAccountID
        Code
        AccountNo
        BankProfile {
          Name
          Address
        }
      }
      Description
      Remark
      WorkFlow {
        WorkFlowID
        WorkFlowStatus
        SubmitDate
        SubmitterDetail {
          ID
          name
        }
        WorkFlowStep {
          StepNo
          StatusRemarks
          WorkFlowStepID
          ApprovalStatus
          ApproverRoleID
          RoleDetail {
            ID
            name
          }
          WorkFlowApprovedStep {
            WorkFlowApprovedStepID
            ApproverDetail {
              ID
              name
            }
          }
        }
      }
      Amount
      TaxAmt
      TaxRate
      TaxInvoiceNo
      DocAmt
      BalanceAmt
      ApprovalStatus
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDocNo
        CreditDocDate
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetArAdvancebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetArAdvancebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAdvancebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAdvancebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetArAdvancebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAdvancebyStatusListingQuery, GetArAdvancebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAdvancebyStatusListingQuery, GetArAdvancebyStatusListingQueryVariables>(GetArAdvancebyStatusListingDocument, baseOptions);
      }
export function useGetArAdvancebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAdvancebyStatusListingQuery, GetArAdvancebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAdvancebyStatusListingQuery, GetArAdvancebyStatusListingQueryVariables>(GetArAdvancebyStatusListingDocument, baseOptions);
        }
export type GetArAdvancebyStatusListingQueryHookResult = ReturnType<typeof useGetArAdvancebyStatusListingQuery>;
export type GetArAdvancebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetArAdvancebyStatusListingLazyQuery>;
export type GetArAdvancebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetArAdvancebyStatusListingQuery, GetArAdvancebyStatusListingQueryVariables>;
export const GetArAllocationDocumentDocument = gql`
    query getARAllocationDocument($entityID: [String!], $refTable: String!, $companyID: String!, $creditorDebtorID: String, $billExpenseItemID: String) {
  getAllocationDocument(entityID: $entityID, refTable: $refTable, companyID: $companyID, creditorDebtorID: $creditorDebtorID, billExpenseItemID: $billExpenseItemID)
}
    `;

/**
 * __useGetArAllocationDocumentQuery__
 *
 * To run a query within a React component, call `useGetArAllocationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAllocationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAllocationDocumentQuery({
 *   variables: {
 *      entityID: // value for 'entityID'
 *      refTable: // value for 'refTable'
 *      companyID: // value for 'companyID'
 *      creditorDebtorID: // value for 'creditorDebtorID'
 *      billExpenseItemID: // value for 'billExpenseItemID'
 *   },
 * });
 */
export function useGetArAllocationDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAllocationDocumentQuery, GetArAllocationDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAllocationDocumentQuery, GetArAllocationDocumentQueryVariables>(GetArAllocationDocumentDocument, baseOptions);
      }
export function useGetArAllocationDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAllocationDocumentQuery, GetArAllocationDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAllocationDocumentQuery, GetArAllocationDocumentQueryVariables>(GetArAllocationDocumentDocument, baseOptions);
        }
export type GetArAllocationDocumentQueryHookResult = ReturnType<typeof useGetArAllocationDocumentQuery>;
export type GetArAllocationDocumentLazyQueryHookResult = ReturnType<typeof useGetArAllocationDocumentLazyQuery>;
export type GetArAllocationDocumentQueryResult = ApolloReactCommon.QueryResult<GetArAllocationDocumentQuery, GetArAllocationDocumentQueryVariables>;
export const CreateArAllocationDocument = gql`
    mutation createARAllocation($input: [AllocationInput!]) {
  createAllocation(input: $input)
}
    `;
export type CreateArAllocationMutationFn = ApolloReactCommon.MutationFunction<CreateArAllocationMutation, CreateArAllocationMutationVariables>;

/**
 * __useCreateArAllocationMutation__
 *
 * To run a mutation, you first call `useCreateArAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArAllocationMutation, { data, loading, error }] = useCreateArAllocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArAllocationMutation, CreateArAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArAllocationMutation, CreateArAllocationMutationVariables>(CreateArAllocationDocument, baseOptions);
      }
export type CreateArAllocationMutationHookResult = ReturnType<typeof useCreateArAllocationMutation>;
export type CreateArAllocationMutationResult = ApolloReactCommon.MutationResult<CreateArAllocationMutation>;
export type CreateArAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArAllocationMutation, CreateArAllocationMutationVariables>;
export const GetArItemDocument = gql`
    query getARItem($refID: String!) {
  getARInvoiceItem(InvoiceID: $refID) {
    InvoiceItemID
    BalanceAmt
    TaxSchemeID
    TaxRate
    TaxAmt
    DocAmt
    TaxScheme {
      TaxSchemeID
      Code
      LatestTax {
        Rate
      }
    }
    CostCentreID
    CostCentre {
      CostCentreID
      Name
    }
    BillItemID
    BillItem {
      BillItemID
      Name
    }
  }
  getARDebitNoteItem(DebitNoteID: $refID) {
    DebitNoteItemID
    BalanceAmt
    TaxSchemeID
    TaxRate
    TaxAmt
    DocAmt
    TaxScheme {
      TaxSchemeID
      Code
      LatestTax {
        Rate
      }
    }
    CostCentreID
    CostCentre {
      CostCentreID
      Name
    }
    BillItemID
    BillItem {
      BillItemID
      Name
    }
  }
}
    `;

/**
 * __useGetArItemQuery__
 *
 * To run a query within a React component, call `useGetArItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArItemQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useGetArItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArItemQuery, GetArItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArItemQuery, GetArItemQueryVariables>(GetArItemDocument, baseOptions);
      }
export function useGetArItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArItemQuery, GetArItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArItemQuery, GetArItemQueryVariables>(GetArItemDocument, baseOptions);
        }
export type GetArItemQueryHookResult = ReturnType<typeof useGetArItemQuery>;
export type GetArItemLazyQueryHookResult = ReturnType<typeof useGetArItemLazyQuery>;
export type GetArItemQueryResult = ApolloReactCommon.QueryResult<GetArItemQuery, GetArItemQueryVariables>;
export const GetBillItemDocument = gql`
    query getBillItem($AccountID: String, $BillItemID: String, $skip: Float, $take: Float, $searchValue: String, $orderByAsc: String, $orderByDesc: String, $RecordStatus: RecordStatus) {
  getBillItem(AccountID: $AccountID, BillItemID: $BillItemID, skip: $skip, take: $take, searchValue: $searchValue, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, RecordStatus: $RecordStatus) {
    AccountID
    RecordStatus
    BillItemID
    Name
    Description
    IsUsed
    TaxSchemeID
    ARInvoiceItem {
      DocAmt
      TaxScheme {
        TaxSchemeID
        Code
        Description
        LatestTax {
          Rate
        }
        TaxEffective {
          Date
          Rate
        }
      }
    }
    TaxScheme {
      TaxSchemeID
      Code
      Description
      LatestTax {
        Rate
      }
      TaxEffective {
        Date
        Rate
      }
    }
    TaxRate
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
      Name
    }
    einv_integrated
    einvoice_msic_code_id
    einvoice_classification_code_id
  }
  getBillItemCount(AccountID: $AccountID, BillItemID: $BillItemID, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetBillItemQuery__
 *
 * To run a query within a React component, call `useGetBillItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillItemQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      BillItemID: // value for 'BillItemID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetBillItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillItemQuery, GetBillItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillItemQuery, GetBillItemQueryVariables>(GetBillItemDocument, baseOptions);
      }
export function useGetBillItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillItemQuery, GetBillItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillItemQuery, GetBillItemQueryVariables>(GetBillItemDocument, baseOptions);
        }
export type GetBillItemQueryHookResult = ReturnType<typeof useGetBillItemQuery>;
export type GetBillItemLazyQueryHookResult = ReturnType<typeof useGetBillItemLazyQuery>;
export type GetBillItemQueryResult = ApolloReactCommon.QueryResult<GetBillItemQuery, GetBillItemQueryVariables>;
export const GetBillItemListingDocument = gql`
    query getBillItemListing($orderByAsc: String, $RecordStatus: RecordStatus) {
  getBillItem(orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    BillItemID
    MasterCOAID
    Name
    Description
    TaxSchemeID
    einv_integrated
    einvoice_msic_code_id
    einvoice_classification_code_id
  }
}
    `;

/**
 * __useGetBillItemListingQuery__
 *
 * To run a query within a React component, call `useGetBillItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillItemListingQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetBillItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillItemListingQuery, GetBillItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillItemListingQuery, GetBillItemListingQueryVariables>(GetBillItemListingDocument, baseOptions);
      }
export function useGetBillItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillItemListingQuery, GetBillItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillItemListingQuery, GetBillItemListingQueryVariables>(GetBillItemListingDocument, baseOptions);
        }
export type GetBillItemListingQueryHookResult = ReturnType<typeof useGetBillItemListingQuery>;
export type GetBillItemListingLazyQueryHookResult = ReturnType<typeof useGetBillItemListingLazyQuery>;
export type GetBillItemListingQueryResult = ApolloReactCommon.QueryResult<GetBillItemListingQuery, GetBillItemListingQueryVariables>;
export const CreateBillItemDocument = gql`
    mutation createBillItem($input: BillItemInput!) {
  createBillItem(input: $input) {
    BillItemID
  }
}
    `;
export type CreateBillItemMutationFn = ApolloReactCommon.MutationFunction<CreateBillItemMutation, CreateBillItemMutationVariables>;

/**
 * __useCreateBillItemMutation__
 *
 * To run a mutation, you first call `useCreateBillItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillItemMutation, { data, loading, error }] = useCreateBillItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBillItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBillItemMutation, CreateBillItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBillItemMutation, CreateBillItemMutationVariables>(CreateBillItemDocument, baseOptions);
      }
export type CreateBillItemMutationHookResult = ReturnType<typeof useCreateBillItemMutation>;
export type CreateBillItemMutationResult = ApolloReactCommon.MutationResult<CreateBillItemMutation>;
export type CreateBillItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBillItemMutation, CreateBillItemMutationVariables>;
export const UpdateBillItemDocument = gql`
    mutation updateBillItem($input: BillItemInput!) {
  updateBillItem(input: $input) {
    BillItemID
  }
}
    `;
export type UpdateBillItemMutationFn = ApolloReactCommon.MutationFunction<UpdateBillItemMutation, UpdateBillItemMutationVariables>;

/**
 * __useUpdateBillItemMutation__
 *
 * To run a mutation, you first call `useUpdateBillItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillItemMutation, { data, loading, error }] = useUpdateBillItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBillItemMutation, UpdateBillItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBillItemMutation, UpdateBillItemMutationVariables>(UpdateBillItemDocument, baseOptions);
      }
export type UpdateBillItemMutationHookResult = ReturnType<typeof useUpdateBillItemMutation>;
export type UpdateBillItemMutationResult = ApolloReactCommon.MutationResult<UpdateBillItemMutation>;
export type UpdateBillItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBillItemMutation, UpdateBillItemMutationVariables>;
export const DeleteBillItemDocument = gql`
    mutation deleteBillItem($input: BillItemDeleteInput!) {
  deleteBillItem(input: $input)
}
    `;
export type DeleteBillItemMutationFn = ApolloReactCommon.MutationFunction<DeleteBillItemMutation, DeleteBillItemMutationVariables>;

/**
 * __useDeleteBillItemMutation__
 *
 * To run a mutation, you first call `useDeleteBillItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillItemMutation, { data, loading, error }] = useDeleteBillItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBillItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBillItemMutation, DeleteBillItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBillItemMutation, DeleteBillItemMutationVariables>(DeleteBillItemDocument, baseOptions);
      }
export type DeleteBillItemMutationHookResult = ReturnType<typeof useDeleteBillItemMutation>;
export type DeleteBillItemMutationResult = ApolloReactCommon.MutationResult<DeleteBillItemMutation>;
export type DeleteBillItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBillItemMutation, DeleteBillItemMutationVariables>;
export const GetDocToConsolidateDocument = gql`
    query getDocToConsolidate($CompanyID: String!, $DocType: String!, $DocNo: String, $DebtorName: String, $StartDate: String, $EndDate: String) {
  getDocToConsolidate(CompanyID: $CompanyID, DocType: $DocType, DocNo: $DocNo, DebtorName: $DebtorName, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useGetDocToConsolidateQuery__
 *
 * To run a query within a React component, call `useGetDocToConsolidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocToConsolidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocToConsolidateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DocType: // value for 'DocType'
 *      DocNo: // value for 'DocNo'
 *      DebtorName: // value for 'DebtorName'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetDocToConsolidateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocToConsolidateQuery, GetDocToConsolidateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocToConsolidateQuery, GetDocToConsolidateQueryVariables>(GetDocToConsolidateDocument, baseOptions);
      }
export function useGetDocToConsolidateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocToConsolidateQuery, GetDocToConsolidateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocToConsolidateQuery, GetDocToConsolidateQueryVariables>(GetDocToConsolidateDocument, baseOptions);
        }
export type GetDocToConsolidateQueryHookResult = ReturnType<typeof useGetDocToConsolidateQuery>;
export type GetDocToConsolidateLazyQueryHookResult = ReturnType<typeof useGetDocToConsolidateLazyQuery>;
export type GetDocToConsolidateQueryResult = ApolloReactCommon.QueryResult<GetDocToConsolidateQuery, GetDocToConsolidateQueryVariables>;
export const CreateEinvoiceConsolidateDocument = gql`
    mutation createEinvoiceConsolidate($input: ConsolidationInput!, $itemInput: [ConsolidationDetailInput!]!) {
  createEinvoiceConsolidate(input: $input, itemInput: $itemInput)
}
    `;
export type CreateEinvoiceConsolidateMutationFn = ApolloReactCommon.MutationFunction<CreateEinvoiceConsolidateMutation, CreateEinvoiceConsolidateMutationVariables>;

/**
 * __useCreateEinvoiceConsolidateMutation__
 *
 * To run a mutation, you first call `useCreateEinvoiceConsolidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEinvoiceConsolidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEinvoiceConsolidateMutation, { data, loading, error }] = useCreateEinvoiceConsolidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateEinvoiceConsolidateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEinvoiceConsolidateMutation, CreateEinvoiceConsolidateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEinvoiceConsolidateMutation, CreateEinvoiceConsolidateMutationVariables>(CreateEinvoiceConsolidateDocument, baseOptions);
      }
export type CreateEinvoiceConsolidateMutationHookResult = ReturnType<typeof useCreateEinvoiceConsolidateMutation>;
export type CreateEinvoiceConsolidateMutationResult = ApolloReactCommon.MutationResult<CreateEinvoiceConsolidateMutation>;
export type CreateEinvoiceConsolidateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEinvoiceConsolidateMutation, CreateEinvoiceConsolidateMutationVariables>;
export const CreateEinvoiceConsolidateDocTypeAllDocument = gql`
    mutation createEinvoiceConsolidateDocTypeAll($input: [ConsolidationDocTypeAllInput!]!) {
  createEinvoiceConsolidateDocTypeAll(input: $input)
}
    `;
export type CreateEinvoiceConsolidateDocTypeAllMutationFn = ApolloReactCommon.MutationFunction<CreateEinvoiceConsolidateDocTypeAllMutation, CreateEinvoiceConsolidateDocTypeAllMutationVariables>;

/**
 * __useCreateEinvoiceConsolidateDocTypeAllMutation__
 *
 * To run a mutation, you first call `useCreateEinvoiceConsolidateDocTypeAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEinvoiceConsolidateDocTypeAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEinvoiceConsolidateDocTypeAllMutation, { data, loading, error }] = useCreateEinvoiceConsolidateDocTypeAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEinvoiceConsolidateDocTypeAllMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEinvoiceConsolidateDocTypeAllMutation, CreateEinvoiceConsolidateDocTypeAllMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEinvoiceConsolidateDocTypeAllMutation, CreateEinvoiceConsolidateDocTypeAllMutationVariables>(CreateEinvoiceConsolidateDocTypeAllDocument, baseOptions);
      }
export type CreateEinvoiceConsolidateDocTypeAllMutationHookResult = ReturnType<typeof useCreateEinvoiceConsolidateDocTypeAllMutation>;
export type CreateEinvoiceConsolidateDocTypeAllMutationResult = ApolloReactCommon.MutationResult<CreateEinvoiceConsolidateDocTypeAllMutation>;
export type CreateEinvoiceConsolidateDocTypeAllMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEinvoiceConsolidateDocTypeAllMutation, CreateEinvoiceConsolidateDocTypeAllMutationVariables>;
export const GetEinvoiceConsolidationDocument = gql`
    query getEinvoiceConsolidation($CompanyID: String!, $skip: Float, $take: Float, $searchValue: String, $DocType: [String!], $StartDate: String, $EndDate: String) {
  getEinvoiceConsolidation(CompanyID: $CompanyID, skip: $skip, take: $take, searchValue: $searchValue, DocType: $DocType, StartDate: $StartDate, EndDate: $EndDate) {
    AccountID
    CompanyID
    ConsolidationID
    DocDate
    DocNo
    DocAmt
    Amount
    TaxAmt
    Description
    ProfileInfo
    einvoice_trx_type
    createdTs
    createdBy
    einvoice_status
    einvoice
    eInvoiceConsolidationDetail {
      Consolidation_DetailID
      AccountID
      ConsolidationID
      DocNo
      DocDate
      DocType
      CreditorDebtorName
      RefTable
      RefRecordID
      Amount
      TaxAmt
      DocAmt
    }
  }
}
    `;

/**
 * __useGetEinvoiceConsolidationQuery__
 *
 * To run a query within a React component, call `useGetEinvoiceConsolidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEinvoiceConsolidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEinvoiceConsolidationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      DocType: // value for 'DocType'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetEinvoiceConsolidationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEinvoiceConsolidationQuery, GetEinvoiceConsolidationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEinvoiceConsolidationQuery, GetEinvoiceConsolidationQueryVariables>(GetEinvoiceConsolidationDocument, baseOptions);
      }
export function useGetEinvoiceConsolidationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEinvoiceConsolidationQuery, GetEinvoiceConsolidationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEinvoiceConsolidationQuery, GetEinvoiceConsolidationQueryVariables>(GetEinvoiceConsolidationDocument, baseOptions);
        }
export type GetEinvoiceConsolidationQueryHookResult = ReturnType<typeof useGetEinvoiceConsolidationQuery>;
export type GetEinvoiceConsolidationLazyQueryHookResult = ReturnType<typeof useGetEinvoiceConsolidationLazyQuery>;
export type GetEinvoiceConsolidationQueryResult = ApolloReactCommon.QueryResult<GetEinvoiceConsolidationQuery, GetEinvoiceConsolidationQueryVariables>;
export const GetArCreditNoteDocument = gql`
    query getARCreditNote($AccountID: String, $CompanyID: String, $CreditNoteID: String, $orderByAsc: String, $orderByDesc: String, $DebtorAccountID: String) {
  getARCreditNote(AccountID: $AccountID, CompanyID: $CompanyID, CreditNoteID: $CreditNoteID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, DebtorAccountID: $DebtorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    modTs
    modBy
    AccountID
    RecordStatus
    CreditNoteID
    DocDate
    RefNo
    DebtorAccountID
    DebtorAccount {
      Address
      DebtorName
      DebtorAccountID
      CompanyID
      CreditTerm
      SSTRegNo
      Company {
        Name
        Address {
          ID
          address
          address2
          siteName
          country
          city
          district
          state
          postCode
          postCodeName
          AddressType
        }
        ContactNo
        BankAccount {
          AccountNo
          BankProfile {
            Name
            Address
          }
          Code
        }
      }
      IsCompany
      GSTRegNo
      OfficeNo
      MobileNo
      DebtorContact {
        Name
        ContactNo
        Email
      }
      einvoice_TIN
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    Amount
    DocAmt
    CostCentreID
    CostCentre {
      CostCentreID
      Name
      Code
    }
    DocNo
    Amount
    BalanceAmt
    TaxAmt
    DocAmt
    ARCreditNoteItem {
      CreditNoteItemID
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      Remark
      BillItemID
      Sequence
      BillItem {
        Name
        TaxRate
        TaxSchemeID
        TaxScheme {
          TaxSchemeID
          Code
          LatestTax {
            Rate
          }
          TaxEffective {
            Date
            Rate
          }
        }
      }
    }
    ApprovalStatus
    Remark
    Description
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    is_einvoice
    einvoice_qr_url
  }
}
    `;

/**
 * __useGetArCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetArCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArCreditNoteQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditNoteID: // value for 'CreditNoteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArCreditNoteQuery, GetArCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArCreditNoteQuery, GetArCreditNoteQueryVariables>(GetArCreditNoteDocument, baseOptions);
      }
export function useGetArCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArCreditNoteQuery, GetArCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArCreditNoteQuery, GetArCreditNoteQueryVariables>(GetArCreditNoteDocument, baseOptions);
        }
export type GetArCreditNoteQueryHookResult = ReturnType<typeof useGetArCreditNoteQuery>;
export type GetArCreditNoteLazyQueryHookResult = ReturnType<typeof useGetArCreditNoteLazyQuery>;
export type GetArCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetArCreditNoteQuery, GetArCreditNoteQueryVariables>;
export const CreateArCreditNoteDocument = gql`
    mutation createARCreditNote($input: ARCreditNoteInput!, $itemInput: [ARCreditNoteItemInput!], $allocationInput: [ARAllocationInput!]!, $docRefInput: [AccARCNDocRefInput!]!) {
  createARCreditNote(input: $input, itemInput: $itemInput, allocationInput: $allocationInput, docRefInput: $docRefInput)
}
    `;
export type CreateArCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateArCreditNoteMutation, CreateArCreditNoteMutationVariables>;

/**
 * __useCreateArCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateArCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArCreditNoteMutation, { data, loading, error }] = useCreateArCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      allocationInput: // value for 'allocationInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateArCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArCreditNoteMutation, CreateArCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArCreditNoteMutation, CreateArCreditNoteMutationVariables>(CreateArCreditNoteDocument, baseOptions);
      }
export type CreateArCreditNoteMutationHookResult = ReturnType<typeof useCreateArCreditNoteMutation>;
export type CreateArCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateArCreditNoteMutation>;
export type CreateArCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArCreditNoteMutation, CreateArCreditNoteMutationVariables>;
export const UpdateArCreditNoteDocument = gql`
    mutation updateARCreditNote($CreditNoteID: String!, $input: ARCreditNoteInput!, $itemInput: [ARCreditNoteItemInput!], $allocationInput: [ARAllocationInput!]!, $docRefInput: [AccARCNDocRefInput!]!) {
  updateARCreditNote(CreditNoteID: $CreditNoteID, input: $input, itemInput: $itemInput, allocationInput: $allocationInput, docRefInput: $docRefInput)
}
    `;
export type UpdateArCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateArCreditNoteMutation, UpdateArCreditNoteMutationVariables>;

/**
 * __useUpdateArCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateArCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArCreditNoteMutation, { data, loading, error }] = useUpdateArCreditNoteMutation({
 *   variables: {
 *      CreditNoteID: // value for 'CreditNoteID'
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      allocationInput: // value for 'allocationInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateArCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArCreditNoteMutation, UpdateArCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArCreditNoteMutation, UpdateArCreditNoteMutationVariables>(UpdateArCreditNoteDocument, baseOptions);
      }
export type UpdateArCreditNoteMutationHookResult = ReturnType<typeof useUpdateArCreditNoteMutation>;
export type UpdateArCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateArCreditNoteMutation>;
export type UpdateArCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArCreditNoteMutation, UpdateArCreditNoteMutationVariables>;
export const PostArCreditNoteDocument = gql`
    mutation postARCreditNote($CreditNoteIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postARCreditNote(CreditNoteIDs: $CreditNoteIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostArCreditNoteMutationFn = ApolloReactCommon.MutationFunction<PostArCreditNoteMutation, PostArCreditNoteMutationVariables>;

/**
 * __usePostArCreditNoteMutation__
 *
 * To run a mutation, you first call `usePostArCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArCreditNoteMutation, { data, loading, error }] = usePostArCreditNoteMutation({
 *   variables: {
 *      CreditNoteIDs: // value for 'CreditNoteIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostArCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArCreditNoteMutation, PostArCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArCreditNoteMutation, PostArCreditNoteMutationVariables>(PostArCreditNoteDocument, baseOptions);
      }
export type PostArCreditNoteMutationHookResult = ReturnType<typeof usePostArCreditNoteMutation>;
export type PostArCreditNoteMutationResult = ApolloReactCommon.MutationResult<PostArCreditNoteMutation>;
export type PostArCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArCreditNoteMutation, PostArCreditNoteMutationVariables>;
export const RejectArCreditNoteDocument = gql`
    mutation rejectARCreditNote($CreditNoteIDs: [String!]!, $input: ARCreditNoteRejectInput!) {
  rejectARCreditNote(CreditNoteIDs: $CreditNoteIDs, input: $input)
}
    `;
export type RejectArCreditNoteMutationFn = ApolloReactCommon.MutationFunction<RejectArCreditNoteMutation, RejectArCreditNoteMutationVariables>;

/**
 * __useRejectArCreditNoteMutation__
 *
 * To run a mutation, you first call `useRejectArCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectArCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectArCreditNoteMutation, { data, loading, error }] = useRejectArCreditNoteMutation({
 *   variables: {
 *      CreditNoteIDs: // value for 'CreditNoteIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectArCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectArCreditNoteMutation, RejectArCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectArCreditNoteMutation, RejectArCreditNoteMutationVariables>(RejectArCreditNoteDocument, baseOptions);
      }
export type RejectArCreditNoteMutationHookResult = ReturnType<typeof useRejectArCreditNoteMutation>;
export type RejectArCreditNoteMutationResult = ApolloReactCommon.MutationResult<RejectArCreditNoteMutation>;
export type RejectArCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectArCreditNoteMutation, RejectArCreditNoteMutationVariables>;
export const DeleteArCreditNoteDocument = gql`
    mutation deleteARCreditNote($CreditNoteID: String!) {
  deleteARCreditNote(CreditNoteID: $CreditNoteID)
}
    `;
export type DeleteArCreditNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteArCreditNoteMutation, DeleteArCreditNoteMutationVariables>;

/**
 * __useDeleteArCreditNoteMutation__
 *
 * To run a mutation, you first call `useDeleteArCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArCreditNoteMutation, { data, loading, error }] = useDeleteArCreditNoteMutation({
 *   variables: {
 *      CreditNoteID: // value for 'CreditNoteID'
 *   },
 * });
 */
export function useDeleteArCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArCreditNoteMutation, DeleteArCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArCreditNoteMutation, DeleteArCreditNoteMutationVariables>(DeleteArCreditNoteDocument, baseOptions);
      }
export type DeleteArCreditNoteMutationHookResult = ReturnType<typeof useDeleteArCreditNoteMutation>;
export type DeleteArCreditNoteMutationResult = ApolloReactCommon.MutationResult<DeleteArCreditNoteMutation>;
export type DeleteArCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArCreditNoteMutation, DeleteArCreditNoteMutationVariables>;
export const GetArCreditNotebyStatusDocument = gql`
    query getARCreditNotebyStatus($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String) {
  getARCreditNotebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    CompanyID
    AccountID
    RecordStatus
    CreditNoteID
    DocDate
    TransactionDate
    RefNo
    Remark
    Description
    DebtorAccountID
    DebtorAccount {
      DebtorAccountID
      DebtorName
      OfficeNo
      MobileNo
      GSTRegNo
      SSTRegNo
      CompanyRegNo
      Address
      einvoice_TIN
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    Amount
    DocAmt
    CostCentreID
    CostCentre {
      Name
    }
    DocNo
    Amount
    TaxAmt
    DocAmt
    BalanceAmt
    ApprovalStatus
    ARCreditNoteItem {
      CreditNoteItemID
      Amount
      TaxSchemeID
      TaxScheme {
        Code
      }
      TaxRate
      TaxAmt
      DocAmt
      CostCentreID
      Sequence
      CostCentre {
        Name
        Code
      }
      Remark
      BillItemID
      BillItem {
        Name
        TaxRate
        TaxSchemeID
        TaxScheme {
          TaxSchemeID
          Code
          LatestTax {
            Rate
          }
          TaxEffective {
            Date
            Rate
          }
        }
      }
      einvoice_classification_code_id
      ClassificationCode
    }
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    is_einvoice
    einvoice_qr_url
    einvoice_status
    einvoice_validation_date
  }
}
    `;

/**
 * __useGetArCreditNotebyStatusQuery__
 *
 * To run a query within a React component, call `useGetArCreditNotebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArCreditNotebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArCreditNotebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArCreditNotebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArCreditNotebyStatusQuery, GetArCreditNotebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArCreditNotebyStatusQuery, GetArCreditNotebyStatusQueryVariables>(GetArCreditNotebyStatusDocument, baseOptions);
      }
export function useGetArCreditNotebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArCreditNotebyStatusQuery, GetArCreditNotebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArCreditNotebyStatusQuery, GetArCreditNotebyStatusQueryVariables>(GetArCreditNotebyStatusDocument, baseOptions);
        }
export type GetArCreditNotebyStatusQueryHookResult = ReturnType<typeof useGetArCreditNotebyStatusQuery>;
export type GetArCreditNotebyStatusLazyQueryHookResult = ReturnType<typeof useGetArCreditNotebyStatusLazyQuery>;
export type GetArCreditNotebyStatusQueryResult = ApolloReactCommon.QueryResult<GetArCreditNotebyStatusQuery, GetArCreditNotebyStatusQueryVariables>;
export const GetArCreditNotebyStatusListingDocument = gql`
    query getARCreditNotebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getARCreditNotebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      createdBy
      modTs
      modBy
      submittedTs
      submittedBy
      approvedTs
      approvedBy
      CompanyID
      AccountID
      RecordStatus
      CreditNoteID
      DocDate
      TransactionDate
      RefNo
      Remark
      Description
      einvoice
      DebtorAccountID
      DebtorAccount {
        DebtorAccountID
        DebtorName
        OfficeNo
        MobileNo
        GSTRegNo
        CompanyRegNo
        Address
      }
      Attachment {
        fileURL
        mediaType
        name
      }
      Amount
      DocAmt
      CostCentreID
      CostCentre {
        Name
        Code
      }
      DocNo
      Amount
      TaxAmt
      DocAmt
      BalanceAmt
      ApprovalStatus
      ARCreditNoteItem {
        CreditNoteItemID
        Amount
        TaxSchemeID
        TaxScheme {
          Code
        }
        TaxRate
        TaxAmt
        DocAmt
        CostCentreID
        CostCentre {
          CostCentreID
          Name
          Code
        }
        Remark
        BillItemID
        Sequence
        BillItem {
          Name
          TaxRate
          TaxSchemeID
          TaxScheme {
            TaxSchemeID
            Code
            LatestTax {
              Rate
            }
            TaxEffective {
              Date
              Rate
            }
          }
        }
        einvoice_classification_code_id
        ClassificationCode
      }
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDocNo
        CreditDocDate
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
      is_einvoice
      einvoice_qr_url
      einvoice_status
      einvoice_validation_date
      einvoice_directsend
      einvoice_is_refund_note
      CNDocRef
    }
    TotalLength
  }
}
    `;

/**
 * __useGetArCreditNotebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetArCreditNotebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArCreditNotebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArCreditNotebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetArCreditNotebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArCreditNotebyStatusListingQuery, GetArCreditNotebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArCreditNotebyStatusListingQuery, GetArCreditNotebyStatusListingQueryVariables>(GetArCreditNotebyStatusListingDocument, baseOptions);
      }
export function useGetArCreditNotebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArCreditNotebyStatusListingQuery, GetArCreditNotebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArCreditNotebyStatusListingQuery, GetArCreditNotebyStatusListingQueryVariables>(GetArCreditNotebyStatusListingDocument, baseOptions);
        }
export type GetArCreditNotebyStatusListingQueryHookResult = ReturnType<typeof useGetArCreditNotebyStatusListingQuery>;
export type GetArCreditNotebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetArCreditNotebyStatusListingLazyQuery>;
export type GetArCreditNotebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetArCreditNotebyStatusListingQuery, GetArCreditNotebyStatusListingQueryVariables>;
export const GetValidatedDocRefDocument = gql`
    query getValidatedDocRef($CompanyID: String!, $DebtorAccountID: String!) {
  getValidatedDocRef(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
}
    `;

/**
 * __useGetValidatedDocRefQuery__
 *
 * To run a query within a React component, call `useGetValidatedDocRefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidatedDocRefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidatedDocRefQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetValidatedDocRefQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetValidatedDocRefQuery, GetValidatedDocRefQueryVariables>) {
        return ApolloReactHooks.useQuery<GetValidatedDocRefQuery, GetValidatedDocRefQueryVariables>(GetValidatedDocRefDocument, baseOptions);
      }
export function useGetValidatedDocRefLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetValidatedDocRefQuery, GetValidatedDocRefQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetValidatedDocRefQuery, GetValidatedDocRefQueryVariables>(GetValidatedDocRefDocument, baseOptions);
        }
export type GetValidatedDocRefQueryHookResult = ReturnType<typeof useGetValidatedDocRefQuery>;
export type GetValidatedDocRefLazyQueryHookResult = ReturnType<typeof useGetValidatedDocRefLazyQuery>;
export type GetValidatedDocRefQueryResult = ApolloReactCommon.QueryResult<GetValidatedDocRefQuery, GetValidatedDocRefQueryVariables>;
export const GetArDebitNoteDocument = gql`
    query getARDebitNote($AccountID: String, $CompanyID: String, $DebitNoteID: String, $orderByAsc: String, $orderByDesc: String, $DebtorAccountID: String) {
  getARDebitNote(AccountID: $AccountID, CompanyID: $CompanyID, DebitNoteID: $DebitNoteID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, DebtorAccountID: $DebtorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    CompanyID
    CreditTerm
    AccountID
    RecordStatus
    DebitNoteID
    DocDate
    TransactionDate
    RefNo
    BalanceAmt
    Description
    Remark
    DebtorAccountID
    DebtorAccount {
      Address
      DebtorName
      DebtorAccountID
      CompanyID
      CreditTerm
      Company {
        Name
        Address {
          ID
          address
          address2
          siteName
          country
          city
          district
          state
          postCode
          postCodeName
          AddressType
        }
        ContactNo
        BankAccount {
          AccountNo
          BankProfile {
            Name
            Address
          }
          Code
        }
      }
      IsCompany
      GSTRegNo
      SSTRegNo
      OfficeNo
      MobileNo
      DebtorContact {
        Name
        ContactNo
        Email
      }
      einvoice_TIN
    }
    DocNo
    Amount
    Attachment {
      fileURL
      mediaType
      name
    }
    DocAmt
    DueDate
    ApprovalStatus
    ARDebitNoteItem {
      DebitNoteItemID
      Amount
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      BillItemID
      BillItem {
        BillItemID
        TaxSchemeID
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      ClassificationCode
    }
    is_einvoice
    einvoice_qr_url
    DNDocRef
  }
}
    `;

/**
 * __useGetArDebitNoteQuery__
 *
 * To run a query within a React component, call `useGetArDebitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArDebitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArDebitNoteQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      DebitNoteID: // value for 'DebitNoteID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArDebitNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArDebitNoteQuery, GetArDebitNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArDebitNoteQuery, GetArDebitNoteQueryVariables>(GetArDebitNoteDocument, baseOptions);
      }
export function useGetArDebitNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArDebitNoteQuery, GetArDebitNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArDebitNoteQuery, GetArDebitNoteQueryVariables>(GetArDebitNoteDocument, baseOptions);
        }
export type GetArDebitNoteQueryHookResult = ReturnType<typeof useGetArDebitNoteQuery>;
export type GetArDebitNoteLazyQueryHookResult = ReturnType<typeof useGetArDebitNoteLazyQuery>;
export type GetArDebitNoteQueryResult = ApolloReactCommon.QueryResult<GetArDebitNoteQuery, GetArDebitNoteQueryVariables>;
export const CreateArDebitNoteDocument = gql`
    mutation createARDebitNote($input: ARDebitNoteInput!, $itemInput: [ARDebitNoteItemInput!], $docRefInput: [AccARDNDocRefInput!]!) {
  createARDebitNote(input: $input, itemInput: $itemInput, docRefInput: $docRefInput)
}
    `;
export type CreateArDebitNoteMutationFn = ApolloReactCommon.MutationFunction<CreateArDebitNoteMutation, CreateArDebitNoteMutationVariables>;

/**
 * __useCreateArDebitNoteMutation__
 *
 * To run a mutation, you first call `useCreateArDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArDebitNoteMutation, { data, loading, error }] = useCreateArDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useCreateArDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArDebitNoteMutation, CreateArDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArDebitNoteMutation, CreateArDebitNoteMutationVariables>(CreateArDebitNoteDocument, baseOptions);
      }
export type CreateArDebitNoteMutationHookResult = ReturnType<typeof useCreateArDebitNoteMutation>;
export type CreateArDebitNoteMutationResult = ApolloReactCommon.MutationResult<CreateArDebitNoteMutation>;
export type CreateArDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArDebitNoteMutation, CreateArDebitNoteMutationVariables>;
export const UpdateArDebitNoteDocument = gql`
    mutation updateARDebitNote($DebitNoteID: String!, $input: ARDebitNoteInput!, $itemInput: [ARDebitNoteItemInput!], $docRefInput: [AccARDNDocRefInput!]!) {
  updateARDebitNote(DebitNoteID: $DebitNoteID, input: $input, itemInput: $itemInput, docRefInput: $docRefInput)
}
    `;
export type UpdateArDebitNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateArDebitNoteMutation, UpdateArDebitNoteMutationVariables>;

/**
 * __useUpdateArDebitNoteMutation__
 *
 * To run a mutation, you first call `useUpdateArDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArDebitNoteMutation, { data, loading, error }] = useUpdateArDebitNoteMutation({
 *   variables: {
 *      DebitNoteID: // value for 'DebitNoteID'
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *      docRefInput: // value for 'docRefInput'
 *   },
 * });
 */
export function useUpdateArDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArDebitNoteMutation, UpdateArDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArDebitNoteMutation, UpdateArDebitNoteMutationVariables>(UpdateArDebitNoteDocument, baseOptions);
      }
export type UpdateArDebitNoteMutationHookResult = ReturnType<typeof useUpdateArDebitNoteMutation>;
export type UpdateArDebitNoteMutationResult = ApolloReactCommon.MutationResult<UpdateArDebitNoteMutation>;
export type UpdateArDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArDebitNoteMutation, UpdateArDebitNoteMutationVariables>;
export const PostArDebitNoteDocument = gql`
    mutation postARDebitNote($DebitNoteIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postARDebitNote(DebitNoteIDs: $DebitNoteIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostArDebitNoteMutationFn = ApolloReactCommon.MutationFunction<PostArDebitNoteMutation, PostArDebitNoteMutationVariables>;

/**
 * __usePostArDebitNoteMutation__
 *
 * To run a mutation, you first call `usePostArDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArDebitNoteMutation, { data, loading, error }] = usePostArDebitNoteMutation({
 *   variables: {
 *      DebitNoteIDs: // value for 'DebitNoteIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostArDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArDebitNoteMutation, PostArDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArDebitNoteMutation, PostArDebitNoteMutationVariables>(PostArDebitNoteDocument, baseOptions);
      }
export type PostArDebitNoteMutationHookResult = ReturnType<typeof usePostArDebitNoteMutation>;
export type PostArDebitNoteMutationResult = ApolloReactCommon.MutationResult<PostArDebitNoteMutation>;
export type PostArDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArDebitNoteMutation, PostArDebitNoteMutationVariables>;
export const RejectArDebitNoteDocument = gql`
    mutation rejectARDebitNote($DebitNoteIDs: [String!]!, $input: ARDebitNoteRejectInput!) {
  rejectARDebitNote(DebitNoteIDs: $DebitNoteIDs, input: $input)
}
    `;
export type RejectArDebitNoteMutationFn = ApolloReactCommon.MutationFunction<RejectArDebitNoteMutation, RejectArDebitNoteMutationVariables>;

/**
 * __useRejectArDebitNoteMutation__
 *
 * To run a mutation, you first call `useRejectArDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectArDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectArDebitNoteMutation, { data, loading, error }] = useRejectArDebitNoteMutation({
 *   variables: {
 *      DebitNoteIDs: // value for 'DebitNoteIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectArDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectArDebitNoteMutation, RejectArDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectArDebitNoteMutation, RejectArDebitNoteMutationVariables>(RejectArDebitNoteDocument, baseOptions);
      }
export type RejectArDebitNoteMutationHookResult = ReturnType<typeof useRejectArDebitNoteMutation>;
export type RejectArDebitNoteMutationResult = ApolloReactCommon.MutationResult<RejectArDebitNoteMutation>;
export type RejectArDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectArDebitNoteMutation, RejectArDebitNoteMutationVariables>;
export const DeleteArDebitNoteDocument = gql`
    mutation deleteARDebitNote($DebitNoteID: String!) {
  deleteARDebitNote(DebitNoteID: $DebitNoteID)
}
    `;
export type DeleteArDebitNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteArDebitNoteMutation, DeleteArDebitNoteMutationVariables>;

/**
 * __useDeleteArDebitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteArDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArDebitNoteMutation, { data, loading, error }] = useDeleteArDebitNoteMutation({
 *   variables: {
 *      DebitNoteID: // value for 'DebitNoteID'
 *   },
 * });
 */
export function useDeleteArDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArDebitNoteMutation, DeleteArDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArDebitNoteMutation, DeleteArDebitNoteMutationVariables>(DeleteArDebitNoteDocument, baseOptions);
      }
export type DeleteArDebitNoteMutationHookResult = ReturnType<typeof useDeleteArDebitNoteMutation>;
export type DeleteArDebitNoteMutationResult = ApolloReactCommon.MutationResult<DeleteArDebitNoteMutation>;
export type DeleteArDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArDebitNoteMutation, DeleteArDebitNoteMutationVariables>;
export const GetArDebitNotebyStatusDocument = gql`
    query getARDebitNotebyStatus($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String) {
  getARDebitNotebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    CompanyID
    CreditTerm
    AccountID
    RecordStatus
    DebitNoteID
    DocDate
    TransactionDate
    RefNo
    BalanceAmt
    DebtorAccountID
    DebtorAccount {
      DebtorName
    }
    DocNo
    Amount
    Attachment {
      fileURL
      mediaType
      name
    }
    TaxAmt
    DocAmt
    DueDate
    Description
    Remark
    ApprovalStatus
    ARDebitNoteItem {
      DebitNoteItemID
      Amount
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
        LatestTax {
          Rate
        }
      }
      BillItemID
      BillItem {
        BillItemID
        TaxSchemeID
        Name
        TaxScheme {
          TaxEffective {
            Date
          }
        }
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      einvoice_classification_code_id
    }
    is_einvoice
    einvoice_qr_url
    einvoice_doc_ref_invoice_id
    einvoice_status
    einvoice_validation_date
    DNDocRef
  }
}
    `;

/**
 * __useGetArDebitNotebyStatusQuery__
 *
 * To run a query within a React component, call `useGetArDebitNotebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArDebitNotebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArDebitNotebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArDebitNotebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArDebitNotebyStatusQuery, GetArDebitNotebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArDebitNotebyStatusQuery, GetArDebitNotebyStatusQueryVariables>(GetArDebitNotebyStatusDocument, baseOptions);
      }
export function useGetArDebitNotebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArDebitNotebyStatusQuery, GetArDebitNotebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArDebitNotebyStatusQuery, GetArDebitNotebyStatusQueryVariables>(GetArDebitNotebyStatusDocument, baseOptions);
        }
export type GetArDebitNotebyStatusQueryHookResult = ReturnType<typeof useGetArDebitNotebyStatusQuery>;
export type GetArDebitNotebyStatusLazyQueryHookResult = ReturnType<typeof useGetArDebitNotebyStatusLazyQuery>;
export type GetArDebitNotebyStatusQueryResult = ApolloReactCommon.QueryResult<GetArDebitNotebyStatusQuery, GetArDebitNotebyStatusQueryVariables>;
export const GetArDebitNotebyStatusListingDocument = gql`
    query getARDebitNotebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getARDebitNotebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDocNo
        CreditDocDate
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
      createdTs
      createdBy
      modTs
      modBy
      submittedTs
      submittedBy
      approvedTs
      approvedBy
      CompanyID
      CreditTerm
      AccountID
      RecordStatus
      DebitNoteID
      einvoice
      DocDate
      TransactionDate
      RefNo
      BalanceAmt
      DebtorAccountID
      DebtorAccount {
        DebtorName
      }
      DocNo
      Amount
      Attachment {
        fileURL
        mediaType
        name
      }
      TaxAmt
      DocAmt
      DueDate
      Description
      Remark
      ApprovalStatus
      ARDebitNoteItem {
        DebitNoteItemID
        Amount
        Remark
        TaxSchemeID
        TaxRate
        TaxAmt
        DocAmt
        Sequence
        TaxScheme {
          TaxSchemeID
          Code
          LatestTax {
            Rate
          }
        }
        BillItemID
        BillItem {
          BillItemID
          TaxSchemeID
          Name
          TaxScheme {
            TaxEffective {
              Date
            }
          }
        }
        CostCentreID
        CostCentre {
          CostCentreID
          Name
          Code
        }
        einvoice_classification_code_id
        ClassificationCode
      }
      is_einvoice
      einvoice_qr_url
      einvoice_doc_ref_invoice_id
      einvoice_status
      einvoice_validation_date
      einvoice_directsend
      DNDocRef
    }
    TotalLength
  }
}
    `;

/**
 * __useGetArDebitNotebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetArDebitNotebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArDebitNotebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArDebitNotebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetArDebitNotebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArDebitNotebyStatusListingQuery, GetArDebitNotebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArDebitNotebyStatusListingQuery, GetArDebitNotebyStatusListingQueryVariables>(GetArDebitNotebyStatusListingDocument, baseOptions);
      }
export function useGetArDebitNotebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArDebitNotebyStatusListingQuery, GetArDebitNotebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArDebitNotebyStatusListingQuery, GetArDebitNotebyStatusListingQueryVariables>(GetArDebitNotebyStatusListingDocument, baseOptions);
        }
export type GetArDebitNotebyStatusListingQueryHookResult = ReturnType<typeof useGetArDebitNotebyStatusListingQuery>;
export type GetArDebitNotebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetArDebitNotebyStatusListingLazyQuery>;
export type GetArDebitNotebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetArDebitNotebyStatusListingQuery, GetArDebitNotebyStatusListingQueryVariables>;
export const DebtorAccountTotalDueDocument = gql`
    query DebtorAccountTotalDue($CompanyID: String!) {
  DebtorAccountTotalDue(CompanyID: $CompanyID)
}
    `;

/**
 * __useDebtorAccountTotalDueQuery__
 *
 * To run a query within a React component, call `useDebtorAccountTotalDueQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorAccountTotalDueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorAccountTotalDueQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDebtorAccountTotalDueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorAccountTotalDueQuery, DebtorAccountTotalDueQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorAccountTotalDueQuery, DebtorAccountTotalDueQueryVariables>(DebtorAccountTotalDueDocument, baseOptions);
      }
export function useDebtorAccountTotalDueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorAccountTotalDueQuery, DebtorAccountTotalDueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorAccountTotalDueQuery, DebtorAccountTotalDueQueryVariables>(DebtorAccountTotalDueDocument, baseOptions);
        }
export type DebtorAccountTotalDueQueryHookResult = ReturnType<typeof useDebtorAccountTotalDueQuery>;
export type DebtorAccountTotalDueLazyQueryHookResult = ReturnType<typeof useDebtorAccountTotalDueLazyQuery>;
export type DebtorAccountTotalDueQueryResult = ApolloReactCommon.QueryResult<DebtorAccountTotalDueQuery, DebtorAccountTotalDueQueryVariables>;
export const GetArglExportDocument = gql`
    query getARGLExport($AccountID: String!, $CompanyID: String!, $startDate: DateTime!, $endDate: DateTime!, $prefix: String!) {
  getGLExport(AccountID: $AccountID, CompanyID: $CompanyID, startDate: $startDate, endDate: $endDate, prefix: $prefix)
}
    `;

/**
 * __useGetArglExportQuery__
 *
 * To run a query within a React component, call `useGetArglExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArglExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArglExportQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetArglExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArglExportQuery, GetArglExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArglExportQuery, GetArglExportQueryVariables>(GetArglExportDocument, baseOptions);
      }
export function useGetArglExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArglExportQuery, GetArglExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArglExportQuery, GetArglExportQueryVariables>(GetArglExportDocument, baseOptions);
        }
export type GetArglExportQueryHookResult = ReturnType<typeof useGetArglExportQuery>;
export type GetArglExportLazyQueryHookResult = ReturnType<typeof useGetArglExportLazyQuery>;
export type GetArglExportQueryResult = ApolloReactCommon.QueryResult<GetArglExportQuery, GetArglExportQueryVariables>;
export const GetArExportedGlListingDocument = gql`
    query getARExportedGLListing($CompanyID: String!, $prefix: String!) {
  getExportedGLListing(CompanyID: $CompanyID, prefix: $prefix)
}
    `;

/**
 * __useGetArExportedGlListingQuery__
 *
 * To run a query within a React component, call `useGetArExportedGlListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArExportedGlListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArExportedGlListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetArExportedGlListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArExportedGlListingQuery, GetArExportedGlListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArExportedGlListingQuery, GetArExportedGlListingQueryVariables>(GetArExportedGlListingDocument, baseOptions);
      }
export function useGetArExportedGlListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArExportedGlListingQuery, GetArExportedGlListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArExportedGlListingQuery, GetArExportedGlListingQueryVariables>(GetArExportedGlListingDocument, baseOptions);
        }
export type GetArExportedGlListingQueryHookResult = ReturnType<typeof useGetArExportedGlListingQuery>;
export type GetArExportedGlListingLazyQueryHookResult = ReturnType<typeof useGetArExportedGlListingLazyQuery>;
export type GetArExportedGlListingQueryResult = ApolloReactCommon.QueryResult<GetArExportedGlListingQuery, GetArExportedGlListingQueryVariables>;
export const GetArExportedGlDetailDocument = gql`
    query getARExportedGLDetail($BatchNo: Float!, $CompanyID: String!, $prefix: String!) {
  getExportedGLDetail(BatchNo: $BatchNo, CompanyID: $CompanyID, prefix: $prefix)
}
    `;

/**
 * __useGetArExportedGlDetailQuery__
 *
 * To run a query within a React component, call `useGetArExportedGlDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArExportedGlDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArExportedGlDetailQuery({
 *   variables: {
 *      BatchNo: // value for 'BatchNo'
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetArExportedGlDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArExportedGlDetailQuery, GetArExportedGlDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArExportedGlDetailQuery, GetArExportedGlDetailQueryVariables>(GetArExportedGlDetailDocument, baseOptions);
      }
export function useGetArExportedGlDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArExportedGlDetailQuery, GetArExportedGlDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArExportedGlDetailQuery, GetArExportedGlDetailQueryVariables>(GetArExportedGlDetailDocument, baseOptions);
        }
export type GetArExportedGlDetailQueryHookResult = ReturnType<typeof useGetArExportedGlDetailQuery>;
export type GetArExportedGlDetailLazyQueryHookResult = ReturnType<typeof useGetArExportedGlDetailLazyQuery>;
export type GetArExportedGlDetailQueryResult = ApolloReactCommon.QueryResult<GetArExportedGlDetailQuery, GetArExportedGlDetailQueryVariables>;
export const GetArglExportDetailByAccountCodeDocument = gql`
    query getARGLExportDetailByAccountCode($CompanyID: String!, $AccountID: String!, $AccountCode: String!, $prefix: String!) {
  getGLExportDetailByAccountCode(CompanyID: $CompanyID, AccountID: $AccountID, AccountCode: $AccountCode, prefix: $prefix)
}
    `;

/**
 * __useGetArglExportDetailByAccountCodeQuery__
 *
 * To run a query within a React component, call `useGetArglExportDetailByAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArglExportDetailByAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArglExportDetailByAccountCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountID: // value for 'AccountID'
 *      AccountCode: // value for 'AccountCode'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetArglExportDetailByAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArglExportDetailByAccountCodeQuery, GetArglExportDetailByAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArglExportDetailByAccountCodeQuery, GetArglExportDetailByAccountCodeQueryVariables>(GetArglExportDetailByAccountCodeDocument, baseOptions);
      }
export function useGetArglExportDetailByAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArglExportDetailByAccountCodeQuery, GetArglExportDetailByAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArglExportDetailByAccountCodeQuery, GetArglExportDetailByAccountCodeQueryVariables>(GetArglExportDetailByAccountCodeDocument, baseOptions);
        }
export type GetArglExportDetailByAccountCodeQueryHookResult = ReturnType<typeof useGetArglExportDetailByAccountCodeQuery>;
export type GetArglExportDetailByAccountCodeLazyQueryHookResult = ReturnType<typeof useGetArglExportDetailByAccountCodeLazyQuery>;
export type GetArglExportDetailByAccountCodeQueryResult = ApolloReactCommon.QueryResult<GetArglExportDetailByAccountCodeQuery, GetArglExportDetailByAccountCodeQueryVariables>;
export const PostArglExportDocument = gql`
    mutation postARGLExport($AccountID: String!, $CompanyID: String!, $GLDate: DateTime!, $startDate: DateTime!, $endDate: DateTime!, $prefix: String!) {
  postGLExport(AccountID: $AccountID, CompanyID: $CompanyID, GLDate: $GLDate, startDate: $startDate, endDate: $endDate, prefix: $prefix)
}
    `;
export type PostArglExportMutationFn = ApolloReactCommon.MutationFunction<PostArglExportMutation, PostArglExportMutationVariables>;

/**
 * __usePostArglExportMutation__
 *
 * To run a mutation, you first call `usePostArglExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArglExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArglExportMutation, { data, loading, error }] = usePostArglExportMutation({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      GLDate: // value for 'GLDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function usePostArglExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArglExportMutation, PostArglExportMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArglExportMutation, PostArglExportMutationVariables>(PostArglExportDocument, baseOptions);
      }
export type PostArglExportMutationHookResult = ReturnType<typeof usePostArglExportMutation>;
export type PostArglExportMutationResult = ApolloReactCommon.MutationResult<PostArglExportMutation>;
export type PostArglExportMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArglExportMutation, PostArglExportMutationVariables>;
export const GetArInvoicebyStatusDocument = gql`
    query getARInvoicebyStatus($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String) {
  getARInvoicebyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AccountID
    RecordStatus
    ApprovalStatus
    InvoiceID
    DocDate
    TransactionDate
    DocNo
    RefNo
    DueDate
    CreditTerm
    Description
    DocAmt
    BalanceAmt
    Remark
    Attachment {
      fileURL
      mediaType
      name
    }
    DebtorAccountID
    DebtorAccount {
      Address
      DebtorName
      DebtorAccountID
      CompanyID
      CreditTerm
      Company {
        Name
        Address {
          ID
          address
          address2
          siteName
          country
          city
          district
          state
          postCode
          postCodeName
          AddressType
        }
        ContactNo
        BankAccount {
          AccountNo
          BankProfile {
            Name
            Address
          }
          Code
        }
      }
      IsCompany
      GSTRegNo
      OfficeNo
      MobileNo
      DebtorContact {
        Name
        ContactNo
        Email
      }
    }
    ARInvoiceItem {
      InvoiceItemID
      Amount
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      einvoice_classification_code_id
      ClassificationCode
      TaxScheme {
        TaxSchemeID
        Code
        LatestTax {
          Rate
        }
      }
      BillItemID
      BillItem {
        BillItemID
        TaxSchemeID
        Name
        TaxScheme {
          TaxEffective {
            Date
          }
        }
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
      }
    }
    is_einvoice
    einvoice_status
    einvoice_validation_date
  }
}
    `;

/**
 * __useGetArInvoicebyStatusQuery__
 *
 * To run a query within a React component, call `useGetArInvoicebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArInvoicebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArInvoicebyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArInvoicebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArInvoicebyStatusQuery, GetArInvoicebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArInvoicebyStatusQuery, GetArInvoicebyStatusQueryVariables>(GetArInvoicebyStatusDocument, baseOptions);
      }
export function useGetArInvoicebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArInvoicebyStatusQuery, GetArInvoicebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArInvoicebyStatusQuery, GetArInvoicebyStatusQueryVariables>(GetArInvoicebyStatusDocument, baseOptions);
        }
export type GetArInvoicebyStatusQueryHookResult = ReturnType<typeof useGetArInvoicebyStatusQuery>;
export type GetArInvoicebyStatusLazyQueryHookResult = ReturnType<typeof useGetArInvoicebyStatusLazyQuery>;
export type GetArInvoicebyStatusQueryResult = ApolloReactCommon.QueryResult<GetArInvoicebyStatusQuery, GetArInvoicebyStatusQueryVariables>;
export const GetArInvoicebyStatusListingDocument = gql`
    query getARInvoicebyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getARInvoicebyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDocNo
        CreditDocDate
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
      createdBy
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      AccountID
      RecordStatus
      ApprovalStatus
      InvoiceID
      DocDate
      TransactionDate
      DocNo
      RefNo
      DueDate
      CreditTerm
      Description
      DocAmt
      BalanceAmt
      Remark
      einvoice
      Attachment {
        fileURL
        mediaType
        name
      }
      DebtorAccountID
      DebtorAccount {
        Address
        DebtorName
        DebtorAccountID
        CompanyID
        CreditTerm
        Company {
          Name
          Address {
            ID
            address
            address2
            siteName
            country
            city
            district
            state
            postCode
            postCodeName
            AddressType
          }
          ContactNo
          BankAccount {
            AccountNo
            BankProfile {
              Name
              Address
            }
            Code
          }
        }
        IsCompany
        GSTRegNo
        SSTRegNo
        OfficeNo
        MobileNo
        DebtorContact {
          Name
          ContactNo
          Email
        }
        einvoice_TIN
      }
      ARInvoiceItem {
        InvoiceItemID
        Amount
        Remark
        TaxSchemeID
        TaxRate
        TaxAmt
        DocAmt
        Sequence
        einvoice_classification_code_id
        ClassificationCode
        TaxScheme {
          TaxSchemeID
          Code
          LatestTax {
            Rate
          }
        }
        BillItemID
        BillItem {
          BillItemID
          TaxSchemeID
          Name
          TaxScheme {
            TaxEffective {
              Date
            }
          }
        }
        CostCentreID
        CostCentre {
          CostCentreID
          Name
          Code
        }
      }
      is_einvoice
      einvoice_status
      einvoice_validation_date
      einvoice_directsend
      einvoice_irbm_uniqueID
      einvoice_qr_url
    }
    TotalLength
  }
}
    `;

/**
 * __useGetArInvoicebyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetArInvoicebyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArInvoicebyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArInvoicebyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetArInvoicebyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArInvoicebyStatusListingQuery, GetArInvoicebyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArInvoicebyStatusListingQuery, GetArInvoicebyStatusListingQueryVariables>(GetArInvoicebyStatusListingDocument, baseOptions);
      }
export function useGetArInvoicebyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArInvoicebyStatusListingQuery, GetArInvoicebyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArInvoicebyStatusListingQuery, GetArInvoicebyStatusListingQueryVariables>(GetArInvoicebyStatusListingDocument, baseOptions);
        }
export type GetArInvoicebyStatusListingQueryHookResult = ReturnType<typeof useGetArInvoicebyStatusListingQuery>;
export type GetArInvoicebyStatusListingLazyQueryHookResult = ReturnType<typeof useGetArInvoicebyStatusListingLazyQuery>;
export type GetArInvoicebyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetArInvoicebyStatusListingQuery, GetArInvoicebyStatusListingQueryVariables>;
export const CreateArInvoiceDocument = gql`
    mutation createARInvoice($input: ARInvoiceInput!, $itemInput: [ARInvoiceItemInput!]) {
  createARInvoice(input: $input, itemInput: $itemInput) {
    InvoiceID
  }
}
    `;
export type CreateArInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateArInvoiceMutation, CreateArInvoiceMutationVariables>;

/**
 * __useCreateArInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateArInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArInvoiceMutation, { data, loading, error }] = useCreateArInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateArInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArInvoiceMutation, CreateArInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArInvoiceMutation, CreateArInvoiceMutationVariables>(CreateArInvoiceDocument, baseOptions);
      }
export type CreateArInvoiceMutationHookResult = ReturnType<typeof useCreateArInvoiceMutation>;
export type CreateArInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateArInvoiceMutation>;
export type CreateArInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArInvoiceMutation, CreateArInvoiceMutationVariables>;
export const UpdateArInvoiceDocument = gql`
    mutation updateARInvoice($input: ARInvoiceInput!, $itemInput: [ARInvoiceItemInput!]) {
  updateARInvoice(input: $input, itemInput: $itemInput) {
    InvoiceID
  }
}
    `;
export type UpdateArInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateArInvoiceMutation, UpdateArInvoiceMutationVariables>;

/**
 * __useUpdateArInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateArInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArInvoiceMutation, { data, loading, error }] = useUpdateArInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateArInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArInvoiceMutation, UpdateArInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArInvoiceMutation, UpdateArInvoiceMutationVariables>(UpdateArInvoiceDocument, baseOptions);
      }
export type UpdateArInvoiceMutationHookResult = ReturnType<typeof useUpdateArInvoiceMutation>;
export type UpdateArInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateArInvoiceMutation>;
export type UpdateArInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArInvoiceMutation, UpdateArInvoiceMutationVariables>;
export const PostArInvoiceDocument = gql`
    mutation postARInvoice($InvoiceIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postARInvoice(InvoiceIDs: $InvoiceIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostArInvoiceMutationFn = ApolloReactCommon.MutationFunction<PostArInvoiceMutation, PostArInvoiceMutationVariables>;

/**
 * __usePostArInvoiceMutation__
 *
 * To run a mutation, you first call `usePostArInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArInvoiceMutation, { data, loading, error }] = usePostArInvoiceMutation({
 *   variables: {
 *      InvoiceIDs: // value for 'InvoiceIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostArInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArInvoiceMutation, PostArInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArInvoiceMutation, PostArInvoiceMutationVariables>(PostArInvoiceDocument, baseOptions);
      }
export type PostArInvoiceMutationHookResult = ReturnType<typeof usePostArInvoiceMutation>;
export type PostArInvoiceMutationResult = ApolloReactCommon.MutationResult<PostArInvoiceMutation>;
export type PostArInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArInvoiceMutation, PostArInvoiceMutationVariables>;
export const RejectArInvoiceDocument = gql`
    mutation rejectARInvoice($ARInvoiceIDs: [String!]!, $input: ARInvoiceRejectInput!) {
  rejectARInvoice(ARInvoiceIDs: $ARInvoiceIDs, input: $input)
}
    `;
export type RejectArInvoiceMutationFn = ApolloReactCommon.MutationFunction<RejectArInvoiceMutation, RejectArInvoiceMutationVariables>;

/**
 * __useRejectArInvoiceMutation__
 *
 * To run a mutation, you first call `useRejectArInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectArInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectArInvoiceMutation, { data, loading, error }] = useRejectArInvoiceMutation({
 *   variables: {
 *      ARInvoiceIDs: // value for 'ARInvoiceIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectArInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectArInvoiceMutation, RejectArInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectArInvoiceMutation, RejectArInvoiceMutationVariables>(RejectArInvoiceDocument, baseOptions);
      }
export type RejectArInvoiceMutationHookResult = ReturnType<typeof useRejectArInvoiceMutation>;
export type RejectArInvoiceMutationResult = ApolloReactCommon.MutationResult<RejectArInvoiceMutation>;
export type RejectArInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectArInvoiceMutation, RejectArInvoiceMutationVariables>;
export const GetArInvoiceDocument = gql`
    query getARInvoice($AccountID: String, $CompanyID: String, $InvoiceID: String, $orderByAsc: String, $orderByDesc: String, $DebtorAccountID: String) {
  getARInvoice(AccountID: $AccountID, CompanyID: $CompanyID, InvoiceID: $InvoiceID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, DebtorAccountID: $DebtorAccountID) {
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
    createdBy
    InvoiceID
    DocDate
    TransactionDate
    DueDate
    ApprovalStatus
    createdTs
    DocNo
    DocAmt
    BalanceAmt
    ApprovalStatus
    DebtorAccountID
    RefNo
    DebtorAccount {
      DebtorAccountID
      DebtorName
      OfficeNo
      MobileNo
      GSTRegNo
      SSTRegNo
      CompanyRegNo
      Address
      einvoice_TIN
      DebtorContact {
        ContactNo
        Email
        Name
      }
    }
    Remark
    Description
    Attachment {
      fileURL
      mediaType
      name
    }
    createdBy
    createdTs
    modTs
    modBy
    RefNo
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    ARInvoiceItem {
      InvoiceItemID
      Amount
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      DocAmtBeforeTax
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      BillItemID
      BillItem {
        BillItemID
        TaxSchemeID
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
      }
    }
    is_einvoice
    einvoice_qr_url
  }
}
    `;

/**
 * __useGetArInvoiceQuery__
 *
 * To run a query within a React component, call `useGetArInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArInvoiceQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      InvoiceID: // value for 'InvoiceID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArInvoiceQuery, GetArInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArInvoiceQuery, GetArInvoiceQueryVariables>(GetArInvoiceDocument, baseOptions);
      }
export function useGetArInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArInvoiceQuery, GetArInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArInvoiceQuery, GetArInvoiceQueryVariables>(GetArInvoiceDocument, baseOptions);
        }
export type GetArInvoiceQueryHookResult = ReturnType<typeof useGetArInvoiceQuery>;
export type GetArInvoiceLazyQueryHookResult = ReturnType<typeof useGetArInvoiceLazyQuery>;
export type GetArInvoiceQueryResult = ApolloReactCommon.QueryResult<GetArInvoiceQuery, GetArInvoiceQueryVariables>;
export const DeleteArInvoiceDocument = gql`
    mutation deleteARInvoice($InvoiceID: String!) {
  deleteARInvoice(InvoiceID: $InvoiceID)
}
    `;
export type DeleteArInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteArInvoiceMutation, DeleteArInvoiceMutationVariables>;

/**
 * __useDeleteArInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteArInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArInvoiceMutation, { data, loading, error }] = useDeleteArInvoiceMutation({
 *   variables: {
 *      InvoiceID: // value for 'InvoiceID'
 *   },
 * });
 */
export function useDeleteArInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArInvoiceMutation, DeleteArInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArInvoiceMutation, DeleteArInvoiceMutationVariables>(DeleteArInvoiceDocument, baseOptions);
      }
export type DeleteArInvoiceMutationHookResult = ReturnType<typeof useDeleteArInvoiceMutation>;
export type DeleteArInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteArInvoiceMutation>;
export type DeleteArInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArInvoiceMutation, DeleteArInvoiceMutationVariables>;
export const GetArOfficialReceiptDocument = gql`
    query getAROfficialReceipt($AccountID: String, $CompanyID: String, $DebtorAccountID: String, $ReceiptID: String, $orderByAsc: String, $orderByDesc: String) {
  getAROfficialReceipt(AccountID: $AccountID, CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, ReceiptID: $ReceiptID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    Amount
    RecordStatus
    ReceiptID
    CompanyID
    Company {
      CompanyID
      Name
    }
    DocDate
    TransactionDate
    RefNo
    DocNo
    DebtorAccountID
    DebtorAccount {
      Address
      DebtorName
      DebtorAccountID
      CompanyID
      CreditTerm
      Company {
        Name
        Address {
          ID
          address
          address2
          siteName
          country
          city
          district
          state
          postCode
          postCodeName
          AddressType
        }
        ContactNo
        BankAccount {
          AccountNo
          BankProfile {
            Name
            Address
          }
          Code
        }
      }
      IsCompany
      GSTRegNo
      OfficeNo
      MobileNo
      DebtorContact {
        Name
        ContactNo
        Email
      }
    }
    TaxInvoiceNo
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      BankAccountID
      Code
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    DocAmt
    BalanceAmt
    Remark
    Description
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetArOfficialReceiptQuery__
 *
 * To run a query within a React component, call `useGetArOfficialReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArOfficialReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArOfficialReceiptQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      ReceiptID: // value for 'ReceiptID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetArOfficialReceiptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArOfficialReceiptQuery, GetArOfficialReceiptQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArOfficialReceiptQuery, GetArOfficialReceiptQueryVariables>(GetArOfficialReceiptDocument, baseOptions);
      }
export function useGetArOfficialReceiptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArOfficialReceiptQuery, GetArOfficialReceiptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArOfficialReceiptQuery, GetArOfficialReceiptQueryVariables>(GetArOfficialReceiptDocument, baseOptions);
        }
export type GetArOfficialReceiptQueryHookResult = ReturnType<typeof useGetArOfficialReceiptQuery>;
export type GetArOfficialReceiptLazyQueryHookResult = ReturnType<typeof useGetArOfficialReceiptLazyQuery>;
export type GetArOfficialReceiptQueryResult = ApolloReactCommon.QueryResult<GetArOfficialReceiptQuery, GetArOfficialReceiptQueryVariables>;
export const CreateArOfficialReceiptDocument = gql`
    mutation createAROfficialReceipt($input: AROfficialReceiptInput!, $allocationInput: [ARAllocationInput!]) {
  createAROfficialReceipt(input: $input, allocationInput: $allocationInput) {
    ReceiptID
  }
}
    `;
export type CreateArOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<CreateArOfficialReceiptMutation, CreateArOfficialReceiptMutationVariables>;

/**
 * __useCreateArOfficialReceiptMutation__
 *
 * To run a mutation, you first call `useCreateArOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArOfficialReceiptMutation, { data, loading, error }] = useCreateArOfficialReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useCreateArOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArOfficialReceiptMutation, CreateArOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArOfficialReceiptMutation, CreateArOfficialReceiptMutationVariables>(CreateArOfficialReceiptDocument, baseOptions);
      }
export type CreateArOfficialReceiptMutationHookResult = ReturnType<typeof useCreateArOfficialReceiptMutation>;
export type CreateArOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<CreateArOfficialReceiptMutation>;
export type CreateArOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArOfficialReceiptMutation, CreateArOfficialReceiptMutationVariables>;
export const UpdateArOfficialReceiptDocument = gql`
    mutation updateAROfficialReceipt($input: AROfficialReceiptInput!, $allocationInput: [ARAllocationInput!]) {
  updateAROfficialReceipt(input: $input, allocationInput: $allocationInput) {
    ReceiptID
  }
}
    `;
export type UpdateArOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<UpdateArOfficialReceiptMutation, UpdateArOfficialReceiptMutationVariables>;

/**
 * __useUpdateArOfficialReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateArOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArOfficialReceiptMutation, { data, loading, error }] = useUpdateArOfficialReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useUpdateArOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArOfficialReceiptMutation, UpdateArOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArOfficialReceiptMutation, UpdateArOfficialReceiptMutationVariables>(UpdateArOfficialReceiptDocument, baseOptions);
      }
export type UpdateArOfficialReceiptMutationHookResult = ReturnType<typeof useUpdateArOfficialReceiptMutation>;
export type UpdateArOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<UpdateArOfficialReceiptMutation>;
export type UpdateArOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArOfficialReceiptMutation, UpdateArOfficialReceiptMutationVariables>;
export const PostArOfficialReceiptDocument = gql`
    mutation postAROfficialReceipt($ReceiptIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postAROfficialReceipt(ReceiptIDs: $ReceiptIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostArOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<PostArOfficialReceiptMutation, PostArOfficialReceiptMutationVariables>;

/**
 * __usePostArOfficialReceiptMutation__
 *
 * To run a mutation, you first call `usePostArOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArOfficialReceiptMutation, { data, loading, error }] = usePostArOfficialReceiptMutation({
 *   variables: {
 *      ReceiptIDs: // value for 'ReceiptIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostArOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArOfficialReceiptMutation, PostArOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArOfficialReceiptMutation, PostArOfficialReceiptMutationVariables>(PostArOfficialReceiptDocument, baseOptions);
      }
export type PostArOfficialReceiptMutationHookResult = ReturnType<typeof usePostArOfficialReceiptMutation>;
export type PostArOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<PostArOfficialReceiptMutation>;
export type PostArOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArOfficialReceiptMutation, PostArOfficialReceiptMutationVariables>;
export const RejectArOfficialReceiptDocument = gql`
    mutation rejectAROfficialReceipt($ReceiptIDs: [String!]!, $input: AROfficialReceiptRejectInput!) {
  rejectAROfficialReceipt(ReceiptIDs: $ReceiptIDs, input: $input)
}
    `;
export type RejectArOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<RejectArOfficialReceiptMutation, RejectArOfficialReceiptMutationVariables>;

/**
 * __useRejectArOfficialReceiptMutation__
 *
 * To run a mutation, you first call `useRejectArOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectArOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectArOfficialReceiptMutation, { data, loading, error }] = useRejectArOfficialReceiptMutation({
 *   variables: {
 *      ReceiptIDs: // value for 'ReceiptIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectArOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectArOfficialReceiptMutation, RejectArOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectArOfficialReceiptMutation, RejectArOfficialReceiptMutationVariables>(RejectArOfficialReceiptDocument, baseOptions);
      }
export type RejectArOfficialReceiptMutationHookResult = ReturnType<typeof useRejectArOfficialReceiptMutation>;
export type RejectArOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<RejectArOfficialReceiptMutation>;
export type RejectArOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectArOfficialReceiptMutation, RejectArOfficialReceiptMutationVariables>;
export const GetArOfficialReceiptbyStatusDocument = gql`
    query getAROfficialReceiptbyStatus($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String) {
  getAROfficialReceiptbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    Amount
    AccountID
    RecordStatus
    ReceiptID
    CompanyID
    Company {
      CompanyID
      Name
    }
    DocDate
    TransactionDate
    RefNo
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    DebtorAccountID
    DebtorAccount {
      DebtorAccountID
      DebtorName
    }
    TaxInvoiceNo
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    BankAccountID
    BankAccount {
      BankAccountID
      Code
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    DocAmt
    BalanceAmt
    Remark
    Description
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetArOfficialReceiptbyStatusQuery__
 *
 * To run a query within a React component, call `useGetArOfficialReceiptbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArOfficialReceiptbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArOfficialReceiptbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArOfficialReceiptbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArOfficialReceiptbyStatusQuery, GetArOfficialReceiptbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArOfficialReceiptbyStatusQuery, GetArOfficialReceiptbyStatusQueryVariables>(GetArOfficialReceiptbyStatusDocument, baseOptions);
      }
export function useGetArOfficialReceiptbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArOfficialReceiptbyStatusQuery, GetArOfficialReceiptbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArOfficialReceiptbyStatusQuery, GetArOfficialReceiptbyStatusQueryVariables>(GetArOfficialReceiptbyStatusDocument, baseOptions);
        }
export type GetArOfficialReceiptbyStatusQueryHookResult = ReturnType<typeof useGetArOfficialReceiptbyStatusQuery>;
export type GetArOfficialReceiptbyStatusLazyQueryHookResult = ReturnType<typeof useGetArOfficialReceiptbyStatusLazyQuery>;
export type GetArOfficialReceiptbyStatusQueryResult = ApolloReactCommon.QueryResult<GetArOfficialReceiptbyStatusQuery, GetArOfficialReceiptbyStatusQueryVariables>;
export const GetArOfficialReceiptbyStatusListingDocument = gql`
    query getAROfficialReceiptbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getAROfficialReceiptbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      createdBy
      modTs
      modBy
      submittedTs
      submittedBy
      approvedTs
      approvedBy
      Amount
      AccountID
      RecordStatus
      ReceiptID
      CompanyID
      Company {
        CompanyID
        Name
      }
      DocDate
      TransactionDate
      RefNo
      Attachment {
        fileURL
        mediaType
        name
      }
      DocNo
      DebtorAccountID
      DebtorAccount {
        DebtorAccountID
        DebtorName
      }
      TaxInvoiceNo
      PaymentMethodID
      PaymentMethod {
        PaymentMethodID
        Name
        Description
      }
      ChequeNo
      ChequeDate
      ChequeExpiryDate
      BankAccountID
      BankAccount {
        BankAccountID
        Code
        AccountNo
        BankProfile {
          Name
          Address
        }
      }
      DocAmt
      BalanceAmt
      Remark
      Description
      ApprovalStatus
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDocNo
        CreditDocDate
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetArOfficialReceiptbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetArOfficialReceiptbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArOfficialReceiptbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArOfficialReceiptbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetArOfficialReceiptbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArOfficialReceiptbyStatusListingQuery, GetArOfficialReceiptbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArOfficialReceiptbyStatusListingQuery, GetArOfficialReceiptbyStatusListingQueryVariables>(GetArOfficialReceiptbyStatusListingDocument, baseOptions);
      }
export function useGetArOfficialReceiptbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArOfficialReceiptbyStatusListingQuery, GetArOfficialReceiptbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArOfficialReceiptbyStatusListingQuery, GetArOfficialReceiptbyStatusListingQueryVariables>(GetArOfficialReceiptbyStatusListingDocument, baseOptions);
        }
export type GetArOfficialReceiptbyStatusListingQueryHookResult = ReturnType<typeof useGetArOfficialReceiptbyStatusListingQuery>;
export type GetArOfficialReceiptbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetArOfficialReceiptbyStatusListingLazyQuery>;
export type GetArOfficialReceiptbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetArOfficialReceiptbyStatusListingQuery, GetArOfficialReceiptbyStatusListingQueryVariables>;
export const DeleteArOfficialReceiptDocument = gql`
    mutation deleteAROfficialReceipt($ReceiptID: String!) {
  deleteAROfficialReceipt(ReceiptID: $ReceiptID)
}
    `;
export type DeleteArOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<DeleteArOfficialReceiptMutation, DeleteArOfficialReceiptMutationVariables>;

/**
 * __useDeleteArOfficialReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteArOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArOfficialReceiptMutation, { data, loading, error }] = useDeleteArOfficialReceiptMutation({
 *   variables: {
 *      ReceiptID: // value for 'ReceiptID'
 *   },
 * });
 */
export function useDeleteArOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArOfficialReceiptMutation, DeleteArOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArOfficialReceiptMutation, DeleteArOfficialReceiptMutationVariables>(DeleteArOfficialReceiptDocument, baseOptions);
      }
export type DeleteArOfficialReceiptMutationHookResult = ReturnType<typeof useDeleteArOfficialReceiptMutation>;
export type DeleteArOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<DeleteArOfficialReceiptMutation>;
export type DeleteArOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArOfficialReceiptMutation, DeleteArOfficialReceiptMutationVariables>;
export const GetArRefundDocument = gql`
    query getARRefund($AccountID: String, $CompanyID: String, $DebtorAccountID: String, $RefundID: String, $orderByAsc: String, $orderByDesc: String) {
  getARRefund(AccountID: $AccountID, CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, RefundID: $RefundID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    RecordStatus
    RefundID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    Description
    DebtorAccountID
    DebtorAccount {
      Address
      DebtorName
      DebtorAccountID
      CompanyID
      CreditTerm
      Company {
        Name
        Address {
          ID
          address
          address2
          siteName
          country
          city
          district
          state
          postCode
          postCodeName
          AddressType
        }
        ContactNo
        BankAccount {
          AccountNo
          BankProfile {
            Name
            Address
          }
          Code
        }
      }
      IsCompany
      GSTRegNo
      OfficeNo
      MobileNo
      DebtorContact {
        Name
        ContactNo
        Email
      }
    }
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
      Code
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    RefNo
    Description
    Remark
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetArRefundQuery__
 *
 * To run a query within a React component, call `useGetArRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArRefundQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      RefundID: // value for 'RefundID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetArRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArRefundQuery, GetArRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArRefundQuery, GetArRefundQueryVariables>(GetArRefundDocument, baseOptions);
      }
export function useGetArRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArRefundQuery, GetArRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArRefundQuery, GetArRefundQueryVariables>(GetArRefundDocument, baseOptions);
        }
export type GetArRefundQueryHookResult = ReturnType<typeof useGetArRefundQuery>;
export type GetArRefundLazyQueryHookResult = ReturnType<typeof useGetArRefundLazyQuery>;
export type GetArRefundQueryResult = ApolloReactCommon.QueryResult<GetArRefundQuery, GetArRefundQueryVariables>;
export const CreateArRefundDocument = gql`
    mutation createARRefund($input: ARRefundInput!, $allocationInput: [AllocationInput!]!) {
  createARRefund(input: $input, allocationInput: $allocationInput) {
    RefundID
  }
}
    `;
export type CreateArRefundMutationFn = ApolloReactCommon.MutationFunction<CreateArRefundMutation, CreateArRefundMutationVariables>;

/**
 * __useCreateArRefundMutation__
 *
 * To run a mutation, you first call `useCreateArRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArRefundMutation, { data, loading, error }] = useCreateArRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useCreateArRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArRefundMutation, CreateArRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArRefundMutation, CreateArRefundMutationVariables>(CreateArRefundDocument, baseOptions);
      }
export type CreateArRefundMutationHookResult = ReturnType<typeof useCreateArRefundMutation>;
export type CreateArRefundMutationResult = ApolloReactCommon.MutationResult<CreateArRefundMutation>;
export type CreateArRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArRefundMutation, CreateArRefundMutationVariables>;
export const UpdateArRefundDocument = gql`
    mutation updateARRefund($input: ARRefundInput!, $allocationInput: [AllocationInput!]!) {
  updateARRefund(input: $input, allocationInput: $allocationInput) {
    RefundID
  }
}
    `;
export type UpdateArRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateArRefundMutation, UpdateArRefundMutationVariables>;

/**
 * __useUpdateArRefundMutation__
 *
 * To run a mutation, you first call `useUpdateArRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArRefundMutation, { data, loading, error }] = useUpdateArRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      allocationInput: // value for 'allocationInput'
 *   },
 * });
 */
export function useUpdateArRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArRefundMutation, UpdateArRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArRefundMutation, UpdateArRefundMutationVariables>(UpdateArRefundDocument, baseOptions);
      }
export type UpdateArRefundMutationHookResult = ReturnType<typeof useUpdateArRefundMutation>;
export type UpdateArRefundMutationResult = ApolloReactCommon.MutationResult<UpdateArRefundMutation>;
export type UpdateArRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArRefundMutation, UpdateArRefundMutationVariables>;
export const PostArRefundDocument = gql`
    mutation postARRefund($RefundIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postARRefund(RefundIDs: $RefundIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostArRefundMutationFn = ApolloReactCommon.MutationFunction<PostArRefundMutation, PostArRefundMutationVariables>;

/**
 * __usePostArRefundMutation__
 *
 * To run a mutation, you first call `usePostArRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostArRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postArRefundMutation, { data, loading, error }] = usePostArRefundMutation({
 *   variables: {
 *      RefundIDs: // value for 'RefundIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostArRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostArRefundMutation, PostArRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<PostArRefundMutation, PostArRefundMutationVariables>(PostArRefundDocument, baseOptions);
      }
export type PostArRefundMutationHookResult = ReturnType<typeof usePostArRefundMutation>;
export type PostArRefundMutationResult = ApolloReactCommon.MutationResult<PostArRefundMutation>;
export type PostArRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<PostArRefundMutation, PostArRefundMutationVariables>;
export const RejectArRefundDocument = gql`
    mutation rejectARRefund($RefundIDs: [String!]!, $input: ARRefundRejectInput!) {
  rejectARRefund(RefundIDs: $RefundIDs, input: $input)
}
    `;
export type RejectArRefundMutationFn = ApolloReactCommon.MutationFunction<RejectArRefundMutation, RejectArRefundMutationVariables>;

/**
 * __useRejectArRefundMutation__
 *
 * To run a mutation, you first call `useRejectArRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectArRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectArRefundMutation, { data, loading, error }] = useRejectArRefundMutation({
 *   variables: {
 *      RefundIDs: // value for 'RefundIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectArRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectArRefundMutation, RejectArRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectArRefundMutation, RejectArRefundMutationVariables>(RejectArRefundDocument, baseOptions);
      }
export type RejectArRefundMutationHookResult = ReturnType<typeof useRejectArRefundMutation>;
export type RejectArRefundMutationResult = ApolloReactCommon.MutationResult<RejectArRefundMutation>;
export type RejectArRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectArRefundMutation, RejectArRefundMutationVariables>;
export const GetArRefundbyStatusDocument = gql`
    query getARRefundbyStatus($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String) {
  getARRefundbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    AccountID
    RecordStatus
    RefundID
    DocDate
    TransactionDate
    DocNo
    DocAmt
    BalanceAmt
    ProfileInfo
    DebtorAccountID
    DebtorAccount {
      DebtorName
    }
    PaymentMethodID
    PaymentMethod {
      Name
    }
    BankAccountID
    BankAccount {
      AccountNo
      BankProfile {
        Name
        Address
      }
    }
    RefNo
    Description
    Remark
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    ApprovalStatus
    Allocation {
      AllocationID
      Description
      DebitDocNo
      DebitDocDate
      DebitRefTable
      DebitID
      CreditDocNo
      CreditDocDate
      CreditRefTable
      CreditID
      CreditOrDebit
      AllocationAmt
    }
  }
}
    `;

/**
 * __useGetArRefundbyStatusQuery__
 *
 * To run a query within a React component, call `useGetArRefundbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArRefundbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArRefundbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetArRefundbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArRefundbyStatusQuery, GetArRefundbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArRefundbyStatusQuery, GetArRefundbyStatusQueryVariables>(GetArRefundbyStatusDocument, baseOptions);
      }
export function useGetArRefundbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArRefundbyStatusQuery, GetArRefundbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArRefundbyStatusQuery, GetArRefundbyStatusQueryVariables>(GetArRefundbyStatusDocument, baseOptions);
        }
export type GetArRefundbyStatusQueryHookResult = ReturnType<typeof useGetArRefundbyStatusQuery>;
export type GetArRefundbyStatusLazyQueryHookResult = ReturnType<typeof useGetArRefundbyStatusLazyQuery>;
export type GetArRefundbyStatusQueryResult = ApolloReactCommon.QueryResult<GetArRefundbyStatusQuery, GetArRefundbyStatusQueryVariables>;
export const GetArRefundbyStatusListingDocument = gql`
    query getARRefundbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $DebtorAccountID: String, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getARRefundbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, DebtorAccountID: $DebtorAccountID, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      createdBy
      modTs
      modBy
      submittedTs
      submittedBy
      approvedTs
      approvedBy
      AccountID
      RecordStatus
      RefundID
      DocDate
      TransactionDate
      DocNo
      DocAmt
      BalanceAmt
      ProfileInfo
      DebtorAccountID
      DebtorAccount {
        DebtorName
      }
      PaymentMethodID
      PaymentMethod {
        Name
      }
      BankAccountID
      BankAccount {
        AccountNo
        BankProfile {
          Name
          Address
        }
      }
      RefNo
      Description
      Remark
      ChequeNo
      ChequeDate
      ChequeExpiryDate
      ApprovalStatus
      Allocation {
        AllocationID
        Description
        DebitDocNo
        DebitDocDate
        DebitRefTable
        DebitID
        CreditDocNo
        CreditDocDate
        CreditRefTable
        CreditID
        CreditOrDebit
        AllocationAmt
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetArRefundbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetArRefundbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArRefundbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArRefundbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetArRefundbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArRefundbyStatusListingQuery, GetArRefundbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArRefundbyStatusListingQuery, GetArRefundbyStatusListingQueryVariables>(GetArRefundbyStatusListingDocument, baseOptions);
      }
export function useGetArRefundbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArRefundbyStatusListingQuery, GetArRefundbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArRefundbyStatusListingQuery, GetArRefundbyStatusListingQueryVariables>(GetArRefundbyStatusListingDocument, baseOptions);
        }
export type GetArRefundbyStatusListingQueryHookResult = ReturnType<typeof useGetArRefundbyStatusListingQuery>;
export type GetArRefundbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetArRefundbyStatusListingLazyQuery>;
export type GetArRefundbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetArRefundbyStatusListingQuery, GetArRefundbyStatusListingQueryVariables>;
export const DeleteArRefundDocument = gql`
    mutation deleteARRefund($RefundID: String!) {
  deleteARRefund(RefundID: $RefundID)
}
    `;
export type DeleteArRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteArRefundMutation, DeleteArRefundMutationVariables>;

/**
 * __useDeleteArRefundMutation__
 *
 * To run a mutation, you first call `useDeleteArRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArRefundMutation, { data, loading, error }] = useDeleteArRefundMutation({
 *   variables: {
 *      RefundID: // value for 'RefundID'
 *   },
 * });
 */
export function useDeleteArRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArRefundMutation, DeleteArRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArRefundMutation, DeleteArRefundMutationVariables>(DeleteArRefundDocument, baseOptions);
      }
export type DeleteArRefundMutationHookResult = ReturnType<typeof useDeleteArRefundMutation>;
export type DeleteArRefundMutationResult = ApolloReactCommon.MutationResult<DeleteArRefundMutation>;
export type DeleteArRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArRefundMutation, DeleteArRefundMutationVariables>;
export const GetArSubmenuSummaryDocument = gql`
    query getARSubmenuSummary($CompanyID: String!) {
  arAdvanceCount(CompanyID: $CompanyID)
  arInvoiceCount(CompanyID: $CompanyID)
  arCreditNoteCount(CompanyID: $CompanyID)
  arDebitNoteCount(CompanyID: $CompanyID)
  arOfficialReceiptCount(CompanyID: $CompanyID)
  arRefundCount(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetArSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetArSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArSubmenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetArSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArSubmenuSummaryQuery, GetArSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArSubmenuSummaryQuery, GetArSubmenuSummaryQueryVariables>(GetArSubmenuSummaryDocument, baseOptions);
      }
export function useGetArSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArSubmenuSummaryQuery, GetArSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArSubmenuSummaryQuery, GetArSubmenuSummaryQueryVariables>(GetArSubmenuSummaryDocument, baseOptions);
        }
export type GetArSubmenuSummaryQueryHookResult = ReturnType<typeof useGetArSubmenuSummaryQuery>;
export type GetArSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetArSubmenuSummaryLazyQuery>;
export type GetArSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetArSubmenuSummaryQuery, GetArSubmenuSummaryQueryVariables>;
export const GetDebtorSubmenuSummaryDocument = gql`
    query getDebtorSubmenuSummary($CompanyID: String!, $DebtorAccountID: String!) {
  arAdvanceTotalAmt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arInvoiceTotalAmt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arCreditNoteTotalAmt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arDebitNoteTotalAmt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arOfficialReceiptTotalAmt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arRefundTotalAmt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
}
    `;

/**
 * __useGetDebtorSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetDebtorSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtorSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtorSubmenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetDebtorSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebtorSubmenuSummaryQuery, GetDebtorSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebtorSubmenuSummaryQuery, GetDebtorSubmenuSummaryQueryVariables>(GetDebtorSubmenuSummaryDocument, baseOptions);
      }
export function useGetDebtorSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebtorSubmenuSummaryQuery, GetDebtorSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebtorSubmenuSummaryQuery, GetDebtorSubmenuSummaryQueryVariables>(GetDebtorSubmenuSummaryDocument, baseOptions);
        }
export type GetDebtorSubmenuSummaryQueryHookResult = ReturnType<typeof useGetDebtorSubmenuSummaryQuery>;
export type GetDebtorSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetDebtorSubmenuSummaryLazyQuery>;
export type GetDebtorSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetDebtorSubmenuSummaryQuery, GetDebtorSubmenuSummaryQueryVariables>;
export const GetDebtorTrxSummaryDocument = gql`
    query getDebtorTrxSummary($CompanyID: String!, $DebtorAccountID: String!) {
  arAdvanceLatestUpdate(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arInvoiceLatestUpdate(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arCreditNoteLatestUpdate(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arDebitNoteLatestUpdate(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arOfficialReceiptLatestUpdate(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
  arRefundLatestUpdate(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID)
}
    `;

/**
 * __useGetDebtorTrxSummaryQuery__
 *
 * To run a query within a React component, call `useGetDebtorTrxSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtorTrxSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtorTrxSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetDebtorTrxSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebtorTrxSummaryQuery, GetDebtorTrxSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebtorTrxSummaryQuery, GetDebtorTrxSummaryQueryVariables>(GetDebtorTrxSummaryDocument, baseOptions);
      }
export function useGetDebtorTrxSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebtorTrxSummaryQuery, GetDebtorTrxSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebtorTrxSummaryQuery, GetDebtorTrxSummaryQueryVariables>(GetDebtorTrxSummaryDocument, baseOptions);
        }
export type GetDebtorTrxSummaryQueryHookResult = ReturnType<typeof useGetDebtorTrxSummaryQuery>;
export type GetDebtorTrxSummaryLazyQueryHookResult = ReturnType<typeof useGetDebtorTrxSummaryLazyQuery>;
export type GetDebtorTrxSummaryQueryResult = ApolloReactCommon.QueryResult<GetDebtorTrxSummaryQuery, GetDebtorTrxSummaryQueryVariables>;
export const GetOsDebtorDebitDocDocument = gql`
    query getOsDebtorDebitDoc($CompanyID: String!, $DebtorAccountID: String) {
  getARInvoice(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, ApprovalStatus: COMPLETED) {
    InvoiceID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getARDebitNote(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, ApprovalStatus: COMPLETED) {
    DebitNoteID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getARCreditNote(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, ApprovalStatus: COMPLETED) {
    CreditNoteID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getAROfficialReceipt(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, ApprovalStatus: COMPLETED) {
    ReceiptID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
  getARRefund(CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, ApprovalStatus: COMPLETED) {
    RefundID
    DocAmt
    BalanceAmt
    DocNo
    DocDate
    DueDate
    Description
  }
}
    `;

/**
 * __useGetOsDebtorDebitDocQuery__
 *
 * To run a query within a React component, call `useGetOsDebtorDebitDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsDebtorDebitDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsDebtorDebitDocQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *   },
 * });
 */
export function useGetOsDebtorDebitDocQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsDebtorDebitDocQuery, GetOsDebtorDebitDocQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsDebtorDebitDocQuery, GetOsDebtorDebitDocQueryVariables>(GetOsDebtorDebitDocDocument, baseOptions);
      }
export function useGetOsDebtorDebitDocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsDebtorDebitDocQuery, GetOsDebtorDebitDocQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsDebtorDebitDocQuery, GetOsDebtorDebitDocQueryVariables>(GetOsDebtorDebitDocDocument, baseOptions);
        }
export type GetOsDebtorDebitDocQueryHookResult = ReturnType<typeof useGetOsDebtorDebitDocQuery>;
export type GetOsDebtorDebitDocLazyQueryHookResult = ReturnType<typeof useGetOsDebtorDebitDocLazyQuery>;
export type GetOsDebtorDebitDocQueryResult = ApolloReactCommon.QueryResult<GetOsDebtorDebitDocQuery, GetOsDebtorDebitDocQueryVariables>;
export const UploadProfilePicDocument = gql`
    mutation uploadProfilePic($userID: String!, $file: Upload!) {
  uploadProfilePic(userID: $userID, file: $file)
}
    `;
export type UploadProfilePicMutationFn = ApolloReactCommon.MutationFunction<UploadProfilePicMutation, UploadProfilePicMutationVariables>;

/**
 * __useUploadProfilePicMutation__
 *
 * To run a mutation, you first call `useUploadProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePicMutation, { data, loading, error }] = useUploadProfilePicMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProfilePicMutation, UploadProfilePicMutationVariables>(UploadProfilePicDocument, baseOptions);
      }
export type UploadProfilePicMutationHookResult = ReturnType<typeof useUploadProfilePicMutation>;
export type UploadProfilePicMutationResult = ApolloReactCommon.MutationResult<UploadProfilePicMutation>;
export type UploadProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>;
export const LoginDocument = gql`
    mutation Login($password: String!, $loginId: String!) {
  login(password: $password, loginId: $loginId) {
    accessToken
    user {
      ID
      name
      userName
      email
      contactNo
      department
      jobTitle
      commonStatus
      confirmed
      avatar
      superUser
      accountID
      lastestAccessedEntity
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    userName
    email
    contactNo
    department
    jobTitle
    commonStatus
    confirmed
    avatar
    superUser
    accountID
    avatar
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const ViewLogoDocument = gql`
    query viewLogo($refID: String, $description: String) {
  viewLogo(refID: $refID, description: $description) {
    fileName
    fileURL
  }
}
    `;

/**
 * __useViewLogoQuery__
 *
 * To run a query within a React component, call `useViewLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewLogoQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useViewLogoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewLogoQuery, ViewLogoQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewLogoQuery, ViewLogoQueryVariables>(ViewLogoDocument, baseOptions);
      }
export function useViewLogoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewLogoQuery, ViewLogoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewLogoQuery, ViewLogoQueryVariables>(ViewLogoDocument, baseOptions);
        }
export type ViewLogoQueryHookResult = ReturnType<typeof useViewLogoQuery>;
export type ViewLogoLazyQueryHookResult = ReturnType<typeof useViewLogoLazyQuery>;
export type ViewLogoQueryResult = ApolloReactCommon.QueryResult<ViewLogoQuery, ViewLogoQueryVariables>;
export const LogoutDocument = gql`
    mutation logout($ID: String!, $CompanyID: String!) {
  logout(ID: $ID, CompanyID: $CompanyID)
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordAccInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetBankReconciliationDocument = gql`
    query getBankReconciliation($BankReconciliationID: String, $AccountID: String, $orderByDesc: String, $BankAccountID: String) {
  getBankReconciliation(BankReconciliationID: $BankReconciliationID, AccountID: $AccountID, BankAccountID: $BankAccountID, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    BankReconciliationID
    CompanyID
    BankAccountID
    DocumentDate
    Remarks
    IsExcelMatch
    StatementBalance
    BankReconciliationItem {
      DocNo
      DocumentDate
      Remarks
      DocAmt
      IsMatch
    }
    BankReconciliationMatchID
    BankReconciliationMatch {
      BankReconciliationMatchID
      Ledger {
        LedgerID
      }
    }
  }
}
    `;

/**
 * __useGetBankReconciliationQuery__
 *
 * To run a query within a React component, call `useGetBankReconciliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankReconciliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankReconciliationQuery({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetBankReconciliationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankReconciliationQuery, GetBankReconciliationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankReconciliationQuery, GetBankReconciliationQueryVariables>(GetBankReconciliationDocument, baseOptions);
      }
export function useGetBankReconciliationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankReconciliationQuery, GetBankReconciliationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankReconciliationQuery, GetBankReconciliationQueryVariables>(GetBankReconciliationDocument, baseOptions);
        }
export type GetBankReconciliationQueryHookResult = ReturnType<typeof useGetBankReconciliationQuery>;
export type GetBankReconciliationLazyQueryHookResult = ReturnType<typeof useGetBankReconciliationLazyQuery>;
export type GetBankReconciliationQueryResult = ApolloReactCommon.QueryResult<GetBankReconciliationQuery, GetBankReconciliationQueryVariables>;
export const GetBankReconciliationRemarksDocument = gql`
    query getBankReconciliationRemarks($BankReconciliationID: String) {
  getBankReconciliation(BankReconciliationID: $BankReconciliationID) {
    BankReconciliationID
    Remarks
  }
}
    `;

/**
 * __useGetBankReconciliationRemarksQuery__
 *
 * To run a query within a React component, call `useGetBankReconciliationRemarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankReconciliationRemarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankReconciliationRemarksQuery({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *   },
 * });
 */
export function useGetBankReconciliationRemarksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankReconciliationRemarksQuery, GetBankReconciliationRemarksQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankReconciliationRemarksQuery, GetBankReconciliationRemarksQueryVariables>(GetBankReconciliationRemarksDocument, baseOptions);
      }
export function useGetBankReconciliationRemarksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankReconciliationRemarksQuery, GetBankReconciliationRemarksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankReconciliationRemarksQuery, GetBankReconciliationRemarksQueryVariables>(GetBankReconciliationRemarksDocument, baseOptions);
        }
export type GetBankReconciliationRemarksQueryHookResult = ReturnType<typeof useGetBankReconciliationRemarksQuery>;
export type GetBankReconciliationRemarksLazyQueryHookResult = ReturnType<typeof useGetBankReconciliationRemarksLazyQuery>;
export type GetBankReconciliationRemarksQueryResult = ApolloReactCommon.QueryResult<GetBankReconciliationRemarksQuery, GetBankReconciliationRemarksQueryVariables>;
export const GetBankReconciliationDescriptionDocument = gql`
    query getBankReconciliationDescription($BankReconciliationID: String, $AccountID: String, $BankAccountID: String) {
  getBankReconciliation(BankReconciliationID: $BankReconciliationID, AccountID: $AccountID, BankAccountID: $BankAccountID) {
    createdTs
    createdBy
    BankReconciliationID
    CompanyID
    BankAccountID
    DocumentDate
    Remarks
  }
}
    `;

/**
 * __useGetBankReconciliationDescriptionQuery__
 *
 * To run a query within a React component, call `useGetBankReconciliationDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankReconciliationDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankReconciliationDescriptionQuery({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      AccountID: // value for 'AccountID'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetBankReconciliationDescriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankReconciliationDescriptionQuery, GetBankReconciliationDescriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankReconciliationDescriptionQuery, GetBankReconciliationDescriptionQueryVariables>(GetBankReconciliationDescriptionDocument, baseOptions);
      }
export function useGetBankReconciliationDescriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankReconciliationDescriptionQuery, GetBankReconciliationDescriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankReconciliationDescriptionQuery, GetBankReconciliationDescriptionQueryVariables>(GetBankReconciliationDescriptionDocument, baseOptions);
        }
export type GetBankReconciliationDescriptionQueryHookResult = ReturnType<typeof useGetBankReconciliationDescriptionQuery>;
export type GetBankReconciliationDescriptionLazyQueryHookResult = ReturnType<typeof useGetBankReconciliationDescriptionLazyQuery>;
export type GetBankReconciliationDescriptionQueryResult = ApolloReactCommon.QueryResult<GetBankReconciliationDescriptionQuery, GetBankReconciliationDescriptionQueryVariables>;
export const CreateBankReconciliationDocument = gql`
    mutation createBankReconciliation($input: BankReconciliationInput!) {
  createBankReconciliation(input: $input) {
    BankReconciliationID
  }
}
    `;
export type CreateBankReconciliationMutationFn = ApolloReactCommon.MutationFunction<CreateBankReconciliationMutation, CreateBankReconciliationMutationVariables>;

/**
 * __useCreateBankReconciliationMutation__
 *
 * To run a mutation, you first call `useCreateBankReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankReconciliationMutation, { data, loading, error }] = useCreateBankReconciliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankReconciliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankReconciliationMutation, CreateBankReconciliationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankReconciliationMutation, CreateBankReconciliationMutationVariables>(CreateBankReconciliationDocument, baseOptions);
      }
export type CreateBankReconciliationMutationHookResult = ReturnType<typeof useCreateBankReconciliationMutation>;
export type CreateBankReconciliationMutationResult = ApolloReactCommon.MutationResult<CreateBankReconciliationMutation>;
export type CreateBankReconciliationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankReconciliationMutation, CreateBankReconciliationMutationVariables>;
export const UpdateBankReconciliationDocument = gql`
    mutation updateBankReconciliation($input: BankReconciliationInput!) {
  updateBankReconciliation(input: $input) {
    BankReconciliationID
  }
}
    `;
export type UpdateBankReconciliationMutationFn = ApolloReactCommon.MutationFunction<UpdateBankReconciliationMutation, UpdateBankReconciliationMutationVariables>;

/**
 * __useUpdateBankReconciliationMutation__
 *
 * To run a mutation, you first call `useUpdateBankReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankReconciliationMutation, { data, loading, error }] = useUpdateBankReconciliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankReconciliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankReconciliationMutation, UpdateBankReconciliationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankReconciliationMutation, UpdateBankReconciliationMutationVariables>(UpdateBankReconciliationDocument, baseOptions);
      }
export type UpdateBankReconciliationMutationHookResult = ReturnType<typeof useUpdateBankReconciliationMutation>;
export type UpdateBankReconciliationMutationResult = ApolloReactCommon.MutationResult<UpdateBankReconciliationMutation>;
export type UpdateBankReconciliationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankReconciliationMutation, UpdateBankReconciliationMutationVariables>;
export const GetCheckingValidationBankReconDocument = gql`
    query getCheckingValidationBankRecon($BankReconciliationItemImportInput: [BankReconciliationItemImportInput!]!, $CompanyID: String!, $BankAccountID: String!) {
  getCheckingValidationBankRecon(BankReconciliationItemImportInput: $BankReconciliationItemImportInput, CompanyID: $CompanyID, BankAccountID: $BankAccountID)
}
    `;

/**
 * __useGetCheckingValidationBankReconQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationBankReconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationBankReconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationBankReconQuery({
 *   variables: {
 *      BankReconciliationItemImportInput: // value for 'BankReconciliationItemImportInput'
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetCheckingValidationBankReconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationBankReconQuery, GetCheckingValidationBankReconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationBankReconQuery, GetCheckingValidationBankReconQueryVariables>(GetCheckingValidationBankReconDocument, baseOptions);
      }
export function useGetCheckingValidationBankReconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationBankReconQuery, GetCheckingValidationBankReconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationBankReconQuery, GetCheckingValidationBankReconQueryVariables>(GetCheckingValidationBankReconDocument, baseOptions);
        }
export type GetCheckingValidationBankReconQueryHookResult = ReturnType<typeof useGetCheckingValidationBankReconQuery>;
export type GetCheckingValidationBankReconLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationBankReconLazyQuery>;
export type GetCheckingValidationBankReconQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationBankReconQuery, GetCheckingValidationBankReconQueryVariables>;
export const CreateImportBankReconciliationDocument = gql`
    mutation createImportBankReconciliation($BankReconciliationItemImportInput: [BankReconciliationItemImportInput!]!, $CompanyID: String!, $BankAccountID: String!, $Date: DateTime!, $Description: String!, $StatementBalance: Float!, $IsExcelMatch: Boolean!) {
  createImportBankReconciliation(BankReconciliationItemImportInput: $BankReconciliationItemImportInput, CompanyID: $CompanyID, BankAccountID: $BankAccountID, Date: $Date, Description: $Description, StatementBalance: $StatementBalance, IsExcelMatch: $IsExcelMatch)
}
    `;
export type CreateImportBankReconciliationMutationFn = ApolloReactCommon.MutationFunction<CreateImportBankReconciliationMutation, CreateImportBankReconciliationMutationVariables>;

/**
 * __useCreateImportBankReconciliationMutation__
 *
 * To run a mutation, you first call `useCreateImportBankReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportBankReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportBankReconciliationMutation, { data, loading, error }] = useCreateImportBankReconciliationMutation({
 *   variables: {
 *      BankReconciliationItemImportInput: // value for 'BankReconciliationItemImportInput'
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      Date: // value for 'Date'
 *      Description: // value for 'Description'
 *      StatementBalance: // value for 'StatementBalance'
 *      IsExcelMatch: // value for 'IsExcelMatch'
 *   },
 * });
 */
export function useCreateImportBankReconciliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateImportBankReconciliationMutation, CreateImportBankReconciliationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateImportBankReconciliationMutation, CreateImportBankReconciliationMutationVariables>(CreateImportBankReconciliationDocument, baseOptions);
      }
export type CreateImportBankReconciliationMutationHookResult = ReturnType<typeof useCreateImportBankReconciliationMutation>;
export type CreateImportBankReconciliationMutationResult = ApolloReactCommon.MutationResult<CreateImportBankReconciliationMutation>;
export type CreateImportBankReconciliationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateImportBankReconciliationMutation, CreateImportBankReconciliationMutationVariables>;
export const UpdateImportBankReconciliationDocument = gql`
    mutation updateImportBankReconciliation($BankReconciliationItemImportInput: [BankReconciliationItemImportInput!]!, $BankReconciliationID: String!, $CompanyID: String!, $BankAccountID: String!, $Date: DateTime!, $Description: String!, $StatementBalance: Float!, $IsExcelMatch: Boolean!) {
  updateImportBankReconciliation(BankReconciliationItemImportInput: $BankReconciliationItemImportInput, BankReconciliationID: $BankReconciliationID, CompanyID: $CompanyID, BankAccountID: $BankAccountID, Date: $Date, Description: $Description, StatementBalance: $StatementBalance, IsExcelMatch: $IsExcelMatch)
}
    `;
export type UpdateImportBankReconciliationMutationFn = ApolloReactCommon.MutationFunction<UpdateImportBankReconciliationMutation, UpdateImportBankReconciliationMutationVariables>;

/**
 * __useUpdateImportBankReconciliationMutation__
 *
 * To run a mutation, you first call `useUpdateImportBankReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImportBankReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImportBankReconciliationMutation, { data, loading, error }] = useUpdateImportBankReconciliationMutation({
 *   variables: {
 *      BankReconciliationItemImportInput: // value for 'BankReconciliationItemImportInput'
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      Date: // value for 'Date'
 *      Description: // value for 'Description'
 *      StatementBalance: // value for 'StatementBalance'
 *      IsExcelMatch: // value for 'IsExcelMatch'
 *   },
 * });
 */
export function useUpdateImportBankReconciliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateImportBankReconciliationMutation, UpdateImportBankReconciliationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateImportBankReconciliationMutation, UpdateImportBankReconciliationMutationVariables>(UpdateImportBankReconciliationDocument, baseOptions);
      }
export type UpdateImportBankReconciliationMutationHookResult = ReturnType<typeof useUpdateImportBankReconciliationMutation>;
export type UpdateImportBankReconciliationMutationResult = ApolloReactCommon.MutationResult<UpdateImportBankReconciliationMutation>;
export type UpdateImportBankReconciliationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateImportBankReconciliationMutation, UpdateImportBankReconciliationMutationVariables>;
export const CreateBankReconciliationWihoutExcelDocument = gql`
    mutation createBankReconciliationWihoutExcel($CompanyID: String!, $BankAccountID: String!, $Date: DateTime!, $Description: String!, $StatementBalance: Float!, $IsExcelMatch: Boolean!) {
  createBankReconciliationWihoutExcel(CompanyID: $CompanyID, BankAccountID: $BankAccountID, Date: $Date, Description: $Description, StatementBalance: $StatementBalance, IsExcelMatch: $IsExcelMatch)
}
    `;
export type CreateBankReconciliationWihoutExcelMutationFn = ApolloReactCommon.MutationFunction<CreateBankReconciliationWihoutExcelMutation, CreateBankReconciliationWihoutExcelMutationVariables>;

/**
 * __useCreateBankReconciliationWihoutExcelMutation__
 *
 * To run a mutation, you first call `useCreateBankReconciliationWihoutExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankReconciliationWihoutExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankReconciliationWihoutExcelMutation, { data, loading, error }] = useCreateBankReconciliationWihoutExcelMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      Date: // value for 'Date'
 *      Description: // value for 'Description'
 *      StatementBalance: // value for 'StatementBalance'
 *      IsExcelMatch: // value for 'IsExcelMatch'
 *   },
 * });
 */
export function useCreateBankReconciliationWihoutExcelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankReconciliationWihoutExcelMutation, CreateBankReconciliationWihoutExcelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankReconciliationWihoutExcelMutation, CreateBankReconciliationWihoutExcelMutationVariables>(CreateBankReconciliationWihoutExcelDocument, baseOptions);
      }
export type CreateBankReconciliationWihoutExcelMutationHookResult = ReturnType<typeof useCreateBankReconciliationWihoutExcelMutation>;
export type CreateBankReconciliationWihoutExcelMutationResult = ApolloReactCommon.MutationResult<CreateBankReconciliationWihoutExcelMutation>;
export type CreateBankReconciliationWihoutExcelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankReconciliationWihoutExcelMutation, CreateBankReconciliationWihoutExcelMutationVariables>;
export const UpdateBankReconciliationWihoutExcelDocument = gql`
    mutation updateBankReconciliationWihoutExcel($BankReconciliationID: String!, $BankAccountID: String!, $Date: DateTime!, $Description: String!, $StatementBalance: Float!, $IsExcelMatch: Boolean!) {
  updateBankReconciliationWihoutExcel(BankReconciliationID: $BankReconciliationID, BankAccountID: $BankAccountID, Date: $Date, Description: $Description, StatementBalance: $StatementBalance, IsExcelMatch: $IsExcelMatch)
}
    `;
export type UpdateBankReconciliationWihoutExcelMutationFn = ApolloReactCommon.MutationFunction<UpdateBankReconciliationWihoutExcelMutation, UpdateBankReconciliationWihoutExcelMutationVariables>;

/**
 * __useUpdateBankReconciliationWihoutExcelMutation__
 *
 * To run a mutation, you first call `useUpdateBankReconciliationWihoutExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankReconciliationWihoutExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankReconciliationWihoutExcelMutation, { data, loading, error }] = useUpdateBankReconciliationWihoutExcelMutation({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      BankAccountID: // value for 'BankAccountID'
 *      Date: // value for 'Date'
 *      Description: // value for 'Description'
 *      StatementBalance: // value for 'StatementBalance'
 *      IsExcelMatch: // value for 'IsExcelMatch'
 *   },
 * });
 */
export function useUpdateBankReconciliationWihoutExcelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankReconciliationWihoutExcelMutation, UpdateBankReconciliationWihoutExcelMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankReconciliationWihoutExcelMutation, UpdateBankReconciliationWihoutExcelMutationVariables>(UpdateBankReconciliationWihoutExcelDocument, baseOptions);
      }
export type UpdateBankReconciliationWihoutExcelMutationHookResult = ReturnType<typeof useUpdateBankReconciliationWihoutExcelMutation>;
export type UpdateBankReconciliationWihoutExcelMutationResult = ApolloReactCommon.MutationResult<UpdateBankReconciliationWihoutExcelMutation>;
export type UpdateBankReconciliationWihoutExcelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankReconciliationWihoutExcelMutation, UpdateBankReconciliationWihoutExcelMutationVariables>;
export const GetBankReconciliationItemDocument = gql`
    query getBankReconciliationItem($AccountID: String, $BankReconciliationID: String, $BankReconciliationItemID: String, $IsMatch: Boolean) {
  getBankReconciliationItem(AccountID: $AccountID, BankReconciliationID: $BankReconciliationID, BankReconciliationItemID: $BankReconciliationItemID, IsMatch: $IsMatch) {
    BankReconciliationItemID
    BankReconciliationID
    DocNo
    DocumentDate
    Remarks
    DocAmt
    IsMatch
  }
}
    `;

/**
 * __useGetBankReconciliationItemQuery__
 *
 * To run a query within a React component, call `useGetBankReconciliationItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankReconciliationItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankReconciliationItemQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      BankReconciliationItemID: // value for 'BankReconciliationItemID'
 *      IsMatch: // value for 'IsMatch'
 *   },
 * });
 */
export function useGetBankReconciliationItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankReconciliationItemQuery, GetBankReconciliationItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankReconciliationItemQuery, GetBankReconciliationItemQueryVariables>(GetBankReconciliationItemDocument, baseOptions);
      }
export function useGetBankReconciliationItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankReconciliationItemQuery, GetBankReconciliationItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankReconciliationItemQuery, GetBankReconciliationItemQueryVariables>(GetBankReconciliationItemDocument, baseOptions);
        }
export type GetBankReconciliationItemQueryHookResult = ReturnType<typeof useGetBankReconciliationItemQuery>;
export type GetBankReconciliationItemLazyQueryHookResult = ReturnType<typeof useGetBankReconciliationItemLazyQuery>;
export type GetBankReconciliationItemQueryResult = ApolloReactCommon.QueryResult<GetBankReconciliationItemQuery, GetBankReconciliationItemQueryVariables>;
export const GetBankStatementDocument = gql`
    query getBankStatement($BankReconciliationID: String!, $CompanyID: String!, $BankAccountID: String!, $Mode: String!) {
  getBankStatement(BankReconciliationID: $BankReconciliationID, CompanyID: $CompanyID, BankAccountID: $BankAccountID, Mode: $Mode) {
    BankReconciliationItemID
    BankReconciliationID
    DocNo
    DocumentDate
    Remarks
    DocAmt
    IsMatch
  }
}
    `;

/**
 * __useGetBankStatementQuery__
 *
 * To run a query within a React component, call `useGetBankStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankStatementQuery({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      Mode: // value for 'Mode'
 *   },
 * });
 */
export function useGetBankStatementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankStatementQuery, GetBankStatementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankStatementQuery, GetBankStatementQueryVariables>(GetBankStatementDocument, baseOptions);
      }
export function useGetBankStatementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankStatementQuery, GetBankStatementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankStatementQuery, GetBankStatementQueryVariables>(GetBankStatementDocument, baseOptions);
        }
export type GetBankStatementQueryHookResult = ReturnType<typeof useGetBankStatementQuery>;
export type GetBankStatementLazyQueryHookResult = ReturnType<typeof useGetBankStatementLazyQuery>;
export type GetBankStatementQueryResult = ApolloReactCommon.QueryResult<GetBankStatementQuery, GetBankStatementQueryVariables>;
export const GetBankReconCbLedgerDocument = gql`
    query getBankReconCBLedger($CompanyID: String!, $BankReconciliationID: String!, $BankAccountID: String, $Mode: String!, $DocDate: String!) {
  getBankReconCBLedger(CompanyID: $CompanyID, BankReconciliationID: $BankReconciliationID, BankAccountID: $BankAccountID, Mode: $Mode, DocDate: $DocDate) {
    DocNo
    createdTs
    modTs
    createdBy
    modBy
    RefNo
    Description
    LedgerID
    CompanyID
    BankAccountID
    DocAmt
    BaseAmt
    DocDate
    TransactionDate
    TrxType
    ExchangeRate
    CurrencyID
    ApprovalStatus
    RefTable
    BankReconciliationMatchID
    PayeeName
    ReceivedFrom
    LedgerType
  }
}
    `;

/**
 * __useGetBankReconCbLedgerQuery__
 *
 * To run a query within a React component, call `useGetBankReconCbLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankReconCbLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankReconCbLedgerQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *      BankAccountID: // value for 'BankAccountID'
 *      Mode: // value for 'Mode'
 *      DocDate: // value for 'DocDate'
 *   },
 * });
 */
export function useGetBankReconCbLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankReconCbLedgerQuery, GetBankReconCbLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankReconCbLedgerQuery, GetBankReconCbLedgerQueryVariables>(GetBankReconCbLedgerDocument, baseOptions);
      }
export function useGetBankReconCbLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankReconCbLedgerQuery, GetBankReconCbLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankReconCbLedgerQuery, GetBankReconCbLedgerQueryVariables>(GetBankReconCbLedgerDocument, baseOptions);
        }
export type GetBankReconCbLedgerQueryHookResult = ReturnType<typeof useGetBankReconCbLedgerQuery>;
export type GetBankReconCbLedgerLazyQueryHookResult = ReturnType<typeof useGetBankReconCbLedgerLazyQuery>;
export type GetBankReconCbLedgerQueryResult = ApolloReactCommon.QueryResult<GetBankReconCbLedgerQuery, GetBankReconCbLedgerQueryVariables>;
export const MatchBankReconciliationDocument = gql`
    mutation MatchBankReconciliation($input: BankReconciliationMatchInput!, $BankReconItemIDs: [String!]!, $LedgerIDs: [String!]!, $Mode: String!) {
  MatchBankReconciliation(input: $input, BankReconItemIDs: $BankReconItemIDs, LedgerIDs: $LedgerIDs, Mode: $Mode)
}
    `;
export type MatchBankReconciliationMutationFn = ApolloReactCommon.MutationFunction<MatchBankReconciliationMutation, MatchBankReconciliationMutationVariables>;

/**
 * __useMatchBankReconciliationMutation__
 *
 * To run a mutation, you first call `useMatchBankReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchBankReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchBankReconciliationMutation, { data, loading, error }] = useMatchBankReconciliationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      BankReconItemIDs: // value for 'BankReconItemIDs'
 *      LedgerIDs: // value for 'LedgerIDs'
 *      Mode: // value for 'Mode'
 *   },
 * });
 */
export function useMatchBankReconciliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MatchBankReconciliationMutation, MatchBankReconciliationMutationVariables>) {
        return ApolloReactHooks.useMutation<MatchBankReconciliationMutation, MatchBankReconciliationMutationVariables>(MatchBankReconciliationDocument, baseOptions);
      }
export type MatchBankReconciliationMutationHookResult = ReturnType<typeof useMatchBankReconciliationMutation>;
export type MatchBankReconciliationMutationResult = ApolloReactCommon.MutationResult<MatchBankReconciliationMutation>;
export type MatchBankReconciliationMutationOptions = ApolloReactCommon.BaseMutationOptions<MatchBankReconciliationMutation, MatchBankReconciliationMutationVariables>;
export const GetBankReconciliationMatchDocument = gql`
    query getBankReconciliationMatch($BankReconciliationID: String) {
  getBankReconciliationMatch(BankReconciliationID: $BankReconciliationID) {
    BankReconciliationMatchID
    BankReconciliationID
    CompanyID
    Ledger {
      LedgerID
      DocNo
      DocDate
      DocAmt
      Description
      RefTable
      RefNo
      PayeeName
      ReceivedFrom
    }
    BankReconciliationItem {
      BankReconciliationItemID
      DocNo
      DocumentDate
      DocAmt
      Remarks
    }
  }
}
    `;

/**
 * __useGetBankReconciliationMatchQuery__
 *
 * To run a query within a React component, call `useGetBankReconciliationMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankReconciliationMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankReconciliationMatchQuery({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *   },
 * });
 */
export function useGetBankReconciliationMatchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankReconciliationMatchQuery, GetBankReconciliationMatchQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankReconciliationMatchQuery, GetBankReconciliationMatchQueryVariables>(GetBankReconciliationMatchDocument, baseOptions);
      }
export function useGetBankReconciliationMatchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankReconciliationMatchQuery, GetBankReconciliationMatchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankReconciliationMatchQuery, GetBankReconciliationMatchQueryVariables>(GetBankReconciliationMatchDocument, baseOptions);
        }
export type GetBankReconciliationMatchQueryHookResult = ReturnType<typeof useGetBankReconciliationMatchQuery>;
export type GetBankReconciliationMatchLazyQueryHookResult = ReturnType<typeof useGetBankReconciliationMatchLazyQuery>;
export type GetBankReconciliationMatchQueryResult = ApolloReactCommon.QueryResult<GetBankReconciliationMatchQuery, GetBankReconciliationMatchQueryVariables>;
export const DeleteBankReconciliationDocument = gql`
    mutation deleteBankReconciliation($BankReconciliationID: String!) {
  deleteBankReconciliation(BankReconciliationID: $BankReconciliationID)
}
    `;
export type DeleteBankReconciliationMutationFn = ApolloReactCommon.MutationFunction<DeleteBankReconciliationMutation, DeleteBankReconciliationMutationVariables>;

/**
 * __useDeleteBankReconciliationMutation__
 *
 * To run a mutation, you first call `useDeleteBankReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankReconciliationMutation, { data, loading, error }] = useDeleteBankReconciliationMutation({
 *   variables: {
 *      BankReconciliationID: // value for 'BankReconciliationID'
 *   },
 * });
 */
export function useDeleteBankReconciliationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankReconciliationMutation, DeleteBankReconciliationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankReconciliationMutation, DeleteBankReconciliationMutationVariables>(DeleteBankReconciliationDocument, baseOptions);
      }
export type DeleteBankReconciliationMutationHookResult = ReturnType<typeof useDeleteBankReconciliationMutation>;
export type DeleteBankReconciliationMutationResult = ApolloReactCommon.MutationResult<DeleteBankReconciliationMutation>;
export type DeleteBankReconciliationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankReconciliationMutation, DeleteBankReconciliationMutationVariables>;
export const GetBankTransferDocument = gql`
    query getBankTransfer($AccountID: String, $CompanyID: String, $BankTransferID: String, $orderByDesc: String, $orderByAsc: String, $FromBankAccountID: String, $ApprovalStatus: ApprovalStatus, $BankAccountID: String) {
  getBankTransfer(AccountID: $AccountID, CompanyID: $CompanyID, BankTransferID: $BankTransferID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, FromBankAccountID: $FromBankAccountID, ApprovalStatus: $ApprovalStatus, BankAccountID: $BankAccountID) {
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    BankTransferID
    DocNo
    Attachment {
      fileURL
      mediaType
      name
    }
    BankAccount {
      AccountNo
      Code
      BankProfile {
        Name
      }
    }
    CompanyID
    TransferDate
    TransactionDate
    FromBankAccountID
    ToBankAccountID
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
    }
    Amount
    RefNo
    Description
    ApprovalStatus
    ToBankAccountDetail {
      AccountNo
      BankName
    }
    FromBankAccountDetail {
      AccountNo
      BankName
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBankTransferQuery__
 *
 * To run a query within a React component, call `useGetBankTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankTransferQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      BankTransferID: // value for 'BankTransferID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      FromBankAccountID: // value for 'FromBankAccountID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetBankTransferQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankTransferQuery, GetBankTransferQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankTransferQuery, GetBankTransferQueryVariables>(GetBankTransferDocument, baseOptions);
      }
export function useGetBankTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankTransferQuery, GetBankTransferQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankTransferQuery, GetBankTransferQueryVariables>(GetBankTransferDocument, baseOptions);
        }
export type GetBankTransferQueryHookResult = ReturnType<typeof useGetBankTransferQuery>;
export type GetBankTransferLazyQueryHookResult = ReturnType<typeof useGetBankTransferLazyQuery>;
export type GetBankTransferQueryResult = ApolloReactCommon.QueryResult<GetBankTransferQuery, GetBankTransferQueryVariables>;
export const GetBankTransferbyStatusDocument = gql`
    query getBankTransferbyStatus($CompanyID: String!, $FromBankAccountID: String!, $StatusArr: [String!]!) {
  getBankTransferbyStatus(CompanyID: $CompanyID, FromBankAccountID: $FromBankAccountID, StatusArr: $StatusArr) {
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    BankTransferID
    DocNo
    TransactionDate
    Attachment {
      fileURL
      mediaType
      name
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    CompanyID
    TransferDate
    FromBankAccountID
    ToBankAccountID
    Amount
    RefNo
    Description
    ApprovalStatus
    ToBankAccountDetail {
      AccountNo
      BankName
    }
    FromBankAccountDetail {
      AccountNo
      BankName
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        StatusRemarks
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBankTransferbyStatusQuery__
 *
 * To run a query within a React component, call `useGetBankTransferbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankTransferbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankTransferbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromBankAccountID: // value for 'FromBankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetBankTransferbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankTransferbyStatusQuery, GetBankTransferbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankTransferbyStatusQuery, GetBankTransferbyStatusQueryVariables>(GetBankTransferbyStatusDocument, baseOptions);
      }
export function useGetBankTransferbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankTransferbyStatusQuery, GetBankTransferbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankTransferbyStatusQuery, GetBankTransferbyStatusQueryVariables>(GetBankTransferbyStatusDocument, baseOptions);
        }
export type GetBankTransferbyStatusQueryHookResult = ReturnType<typeof useGetBankTransferbyStatusQuery>;
export type GetBankTransferbyStatusLazyQueryHookResult = ReturnType<typeof useGetBankTransferbyStatusLazyQuery>;
export type GetBankTransferbyStatusQueryResult = ApolloReactCommon.QueryResult<GetBankTransferbyStatusQuery, GetBankTransferbyStatusQueryVariables>;
export const GetApprovedBankTransferDocument = gql`
    query getApprovedBankTransfer($CompanyID: String, $FromBankAccountID: String, $ToBankAccountID: String, $StatusArr: [String!]!) {
  getApprovedBankTransfer(CompanyID: $CompanyID, FromBankAccountID: $FromBankAccountID, ToBankAccountID: $ToBankAccountID, StatusArr: $StatusArr)
}
    `;

/**
 * __useGetApprovedBankTransferQuery__
 *
 * To run a query within a React component, call `useGetApprovedBankTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedBankTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedBankTransferQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromBankAccountID: // value for 'FromBankAccountID'
 *      ToBankAccountID: // value for 'ToBankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetApprovedBankTransferQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedBankTransferQuery, GetApprovedBankTransferQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedBankTransferQuery, GetApprovedBankTransferQueryVariables>(GetApprovedBankTransferDocument, baseOptions);
      }
export function useGetApprovedBankTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedBankTransferQuery, GetApprovedBankTransferQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedBankTransferQuery, GetApprovedBankTransferQueryVariables>(GetApprovedBankTransferDocument, baseOptions);
        }
export type GetApprovedBankTransferQueryHookResult = ReturnType<typeof useGetApprovedBankTransferQuery>;
export type GetApprovedBankTransferLazyQueryHookResult = ReturnType<typeof useGetApprovedBankTransferLazyQuery>;
export type GetApprovedBankTransferQueryResult = ApolloReactCommon.QueryResult<GetApprovedBankTransferQuery, GetApprovedBankTransferQueryVariables>;
export const GetApprovedBankTransferListingDocument = gql`
    query getApprovedBankTransferListing($CompanyID: String, $FromBankAccountID: String, $ToBankAccountID: String, $StatusArr: [String!]!, $skip: Float, $take: Float, $searchValue: String) {
  getApprovedBankTransferListing(CompanyID: $CompanyID, FromBankAccountID: $FromBankAccountID, ToBankAccountID: $ToBankAccountID, StatusArr: $StatusArr, skip: $skip, take: $take, searchValue: $searchValue)
}
    `;

/**
 * __useGetApprovedBankTransferListingQuery__
 *
 * To run a query within a React component, call `useGetApprovedBankTransferListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedBankTransferListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedBankTransferListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromBankAccountID: // value for 'FromBankAccountID'
 *      ToBankAccountID: // value for 'ToBankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApprovedBankTransferListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedBankTransferListingQuery, GetApprovedBankTransferListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedBankTransferListingQuery, GetApprovedBankTransferListingQueryVariables>(GetApprovedBankTransferListingDocument, baseOptions);
      }
export function useGetApprovedBankTransferListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedBankTransferListingQuery, GetApprovedBankTransferListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedBankTransferListingQuery, GetApprovedBankTransferListingQueryVariables>(GetApprovedBankTransferListingDocument, baseOptions);
        }
export type GetApprovedBankTransferListingQueryHookResult = ReturnType<typeof useGetApprovedBankTransferListingQuery>;
export type GetApprovedBankTransferListingLazyQueryHookResult = ReturnType<typeof useGetApprovedBankTransferListingLazyQuery>;
export type GetApprovedBankTransferListingQueryResult = ApolloReactCommon.QueryResult<GetApprovedBankTransferListingQuery, GetApprovedBankTransferListingQueryVariables>;
export const CreateBankTransferDocument = gql`
    mutation createBankTransfer($input: BankTransferInput!) {
  createBankTransfer(input: $input) {
    BankTransferID
  }
}
    `;
export type CreateBankTransferMutationFn = ApolloReactCommon.MutationFunction<CreateBankTransferMutation, CreateBankTransferMutationVariables>;

/**
 * __useCreateBankTransferMutation__
 *
 * To run a mutation, you first call `useCreateBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankTransferMutation, { data, loading, error }] = useCreateBankTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankTransferMutation, CreateBankTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankTransferMutation, CreateBankTransferMutationVariables>(CreateBankTransferDocument, baseOptions);
      }
export type CreateBankTransferMutationHookResult = ReturnType<typeof useCreateBankTransferMutation>;
export type CreateBankTransferMutationResult = ApolloReactCommon.MutationResult<CreateBankTransferMutation>;
export type CreateBankTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankTransferMutation, CreateBankTransferMutationVariables>;
export const UpdateBankTransferDocument = gql`
    mutation updateBankTransfer($input: BankTransferInput!) {
  updateBankTransfer(input: $input) {
    BankTransferID
  }
}
    `;
export type UpdateBankTransferMutationFn = ApolloReactCommon.MutationFunction<UpdateBankTransferMutation, UpdateBankTransferMutationVariables>;

/**
 * __useUpdateBankTransferMutation__
 *
 * To run a mutation, you first call `useUpdateBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankTransferMutation, { data, loading, error }] = useUpdateBankTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankTransferMutation, UpdateBankTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankTransferMutation, UpdateBankTransferMutationVariables>(UpdateBankTransferDocument, baseOptions);
      }
export type UpdateBankTransferMutationHookResult = ReturnType<typeof useUpdateBankTransferMutation>;
export type UpdateBankTransferMutationResult = ApolloReactCommon.MutationResult<UpdateBankTransferMutation>;
export type UpdateBankTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankTransferMutation, UpdateBankTransferMutationVariables>;
export const DeleteBankTransferDocument = gql`
    mutation deleteBankTransfer($BankTransferID: String!) {
  deleteBankTransfer(BankTransferID: $BankTransferID)
}
    `;
export type DeleteBankTransferMutationFn = ApolloReactCommon.MutationFunction<DeleteBankTransferMutation, DeleteBankTransferMutationVariables>;

/**
 * __useDeleteBankTransferMutation__
 *
 * To run a mutation, you first call `useDeleteBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankTransferMutation, { data, loading, error }] = useDeleteBankTransferMutation({
 *   variables: {
 *      BankTransferID: // value for 'BankTransferID'
 *   },
 * });
 */
export function useDeleteBankTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankTransferMutation, DeleteBankTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankTransferMutation, DeleteBankTransferMutationVariables>(DeleteBankTransferDocument, baseOptions);
      }
export type DeleteBankTransferMutationHookResult = ReturnType<typeof useDeleteBankTransferMutation>;
export type DeleteBankTransferMutationResult = ApolloReactCommon.MutationResult<DeleteBankTransferMutation>;
export type DeleteBankTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankTransferMutation, DeleteBankTransferMutationVariables>;
export const PostCbBankTransferDocument = gql`
    mutation postCBBankTransfer($BankTransferIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postCBBankTransfer(BankTransferIDs: $BankTransferIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostCbBankTransferMutationFn = ApolloReactCommon.MutationFunction<PostCbBankTransferMutation, PostCbBankTransferMutationVariables>;

/**
 * __usePostCbBankTransferMutation__
 *
 * To run a mutation, you first call `usePostCbBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCbBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCbBankTransferMutation, { data, loading, error }] = usePostCbBankTransferMutation({
 *   variables: {
 *      BankTransferIDs: // value for 'BankTransferIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostCbBankTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCbBankTransferMutation, PostCbBankTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCbBankTransferMutation, PostCbBankTransferMutationVariables>(PostCbBankTransferDocument, baseOptions);
      }
export type PostCbBankTransferMutationHookResult = ReturnType<typeof usePostCbBankTransferMutation>;
export type PostCbBankTransferMutationResult = ApolloReactCommon.MutationResult<PostCbBankTransferMutation>;
export type PostCbBankTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCbBankTransferMutation, PostCbBankTransferMutationVariables>;
export const RejectBankTransferDocument = gql`
    mutation rejectBankTransfer($BankTransferIDs: [String!]!, $input: BankTransferRejectInput!) {
  rejectBankTransfer(BankTransferIDs: $BankTransferIDs, input: $input)
}
    `;
export type RejectBankTransferMutationFn = ApolloReactCommon.MutationFunction<RejectBankTransferMutation, RejectBankTransferMutationVariables>;

/**
 * __useRejectBankTransferMutation__
 *
 * To run a mutation, you first call `useRejectBankTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectBankTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectBankTransferMutation, { data, loading, error }] = useRejectBankTransferMutation({
 *   variables: {
 *      BankTransferIDs: // value for 'BankTransferIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectBankTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectBankTransferMutation, RejectBankTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectBankTransferMutation, RejectBankTransferMutationVariables>(RejectBankTransferDocument, baseOptions);
      }
export type RejectBankTransferMutationHookResult = ReturnType<typeof useRejectBankTransferMutation>;
export type RejectBankTransferMutationResult = ApolloReactCommon.MutationResult<RejectBankTransferMutation>;
export type RejectBankTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectBankTransferMutation, RejectBankTransferMutationVariables>;
export const GetCbglExportDocument = gql`
    query getCBGLExport($AccountID: String!, $CompanyID: String!, $startDate: DateTime!, $endDate: DateTime!, $prefix: String!) {
  getGLExport(AccountID: $AccountID, CompanyID: $CompanyID, startDate: $startDate, endDate: $endDate, prefix: $prefix)
}
    `;

/**
 * __useGetCbglExportQuery__
 *
 * To run a query within a React component, call `useGetCbglExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbglExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbglExportQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetCbglExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbglExportQuery, GetCbglExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbglExportQuery, GetCbglExportQueryVariables>(GetCbglExportDocument, baseOptions);
      }
export function useGetCbglExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbglExportQuery, GetCbglExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbglExportQuery, GetCbglExportQueryVariables>(GetCbglExportDocument, baseOptions);
        }
export type GetCbglExportQueryHookResult = ReturnType<typeof useGetCbglExportQuery>;
export type GetCbglExportLazyQueryHookResult = ReturnType<typeof useGetCbglExportLazyQuery>;
export type GetCbglExportQueryResult = ApolloReactCommon.QueryResult<GetCbglExportQuery, GetCbglExportQueryVariables>;
export const GetCbExportedGlListingDocument = gql`
    query getCBExportedGLListing($CompanyID: String!, $prefix: String!) {
  getExportedGLListing(CompanyID: $CompanyID, prefix: $prefix)
}
    `;

/**
 * __useGetCbExportedGlListingQuery__
 *
 * To run a query within a React component, call `useGetCbExportedGlListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbExportedGlListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbExportedGlListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetCbExportedGlListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbExportedGlListingQuery, GetCbExportedGlListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbExportedGlListingQuery, GetCbExportedGlListingQueryVariables>(GetCbExportedGlListingDocument, baseOptions);
      }
export function useGetCbExportedGlListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbExportedGlListingQuery, GetCbExportedGlListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbExportedGlListingQuery, GetCbExportedGlListingQueryVariables>(GetCbExportedGlListingDocument, baseOptions);
        }
export type GetCbExportedGlListingQueryHookResult = ReturnType<typeof useGetCbExportedGlListingQuery>;
export type GetCbExportedGlListingLazyQueryHookResult = ReturnType<typeof useGetCbExportedGlListingLazyQuery>;
export type GetCbExportedGlListingQueryResult = ApolloReactCommon.QueryResult<GetCbExportedGlListingQuery, GetCbExportedGlListingQueryVariables>;
export const GetCbExportedGlDetailDocument = gql`
    query getCBExportedGLDetail($BatchNo: Float!, $CompanyID: String!, $prefix: String!) {
  getExportedGLDetail(BatchNo: $BatchNo, CompanyID: $CompanyID, prefix: $prefix)
}
    `;

/**
 * __useGetCbExportedGlDetailQuery__
 *
 * To run a query within a React component, call `useGetCbExportedGlDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbExportedGlDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbExportedGlDetailQuery({
 *   variables: {
 *      BatchNo: // value for 'BatchNo'
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetCbExportedGlDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbExportedGlDetailQuery, GetCbExportedGlDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbExportedGlDetailQuery, GetCbExportedGlDetailQueryVariables>(GetCbExportedGlDetailDocument, baseOptions);
      }
export function useGetCbExportedGlDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbExportedGlDetailQuery, GetCbExportedGlDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbExportedGlDetailQuery, GetCbExportedGlDetailQueryVariables>(GetCbExportedGlDetailDocument, baseOptions);
        }
export type GetCbExportedGlDetailQueryHookResult = ReturnType<typeof useGetCbExportedGlDetailQuery>;
export type GetCbExportedGlDetailLazyQueryHookResult = ReturnType<typeof useGetCbExportedGlDetailLazyQuery>;
export type GetCbExportedGlDetailQueryResult = ApolloReactCommon.QueryResult<GetCbExportedGlDetailQuery, GetCbExportedGlDetailQueryVariables>;
export const GetCbglExportDetailByAccountCodeDocument = gql`
    query getCBGLExportDetailByAccountCode($CompanyID: String!, $AccountID: String!, $AccountCode: String!, $prefix: String!) {
  getGLExportDetailByAccountCode(CompanyID: $CompanyID, AccountID: $AccountID, AccountCode: $AccountCode, prefix: $prefix)
}
    `;

/**
 * __useGetCbglExportDetailByAccountCodeQuery__
 *
 * To run a query within a React component, call `useGetCbglExportDetailByAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbglExportDetailByAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbglExportDetailByAccountCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountID: // value for 'AccountID'
 *      AccountCode: // value for 'AccountCode'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetCbglExportDetailByAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbglExportDetailByAccountCodeQuery, GetCbglExportDetailByAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbglExportDetailByAccountCodeQuery, GetCbglExportDetailByAccountCodeQueryVariables>(GetCbglExportDetailByAccountCodeDocument, baseOptions);
      }
export function useGetCbglExportDetailByAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbglExportDetailByAccountCodeQuery, GetCbglExportDetailByAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbglExportDetailByAccountCodeQuery, GetCbglExportDetailByAccountCodeQueryVariables>(GetCbglExportDetailByAccountCodeDocument, baseOptions);
        }
export type GetCbglExportDetailByAccountCodeQueryHookResult = ReturnType<typeof useGetCbglExportDetailByAccountCodeQuery>;
export type GetCbglExportDetailByAccountCodeLazyQueryHookResult = ReturnType<typeof useGetCbglExportDetailByAccountCodeLazyQuery>;
export type GetCbglExportDetailByAccountCodeQueryResult = ApolloReactCommon.QueryResult<GetCbglExportDetailByAccountCodeQuery, GetCbglExportDetailByAccountCodeQueryVariables>;
export const PostCbglExportDocument = gql`
    mutation postCBGLExport($AccountID: String!, $CompanyID: String!, $GLDate: DateTime!, $startDate: DateTime!, $endDate: DateTime!, $prefix: String!) {
  postGLExport(AccountID: $AccountID, CompanyID: $CompanyID, GLDate: $GLDate, startDate: $startDate, endDate: $endDate, prefix: $prefix)
}
    `;
export type PostCbglExportMutationFn = ApolloReactCommon.MutationFunction<PostCbglExportMutation, PostCbglExportMutationVariables>;

/**
 * __usePostCbglExportMutation__
 *
 * To run a mutation, you first call `usePostCbglExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCbglExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCbglExportMutation, { data, loading, error }] = usePostCbglExportMutation({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      GLDate: // value for 'GLDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function usePostCbglExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCbglExportMutation, PostCbglExportMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCbglExportMutation, PostCbglExportMutationVariables>(PostCbglExportDocument, baseOptions);
      }
export type PostCbglExportMutationHookResult = ReturnType<typeof usePostCbglExportMutation>;
export type PostCbglExportMutationResult = ApolloReactCommon.MutationResult<PostCbglExportMutation>;
export type PostCbglExportMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCbglExportMutation, PostCbglExportMutationVariables>;
export const CreateGlExportDocument = gql`
    mutation createGLExport($input: [GLExportInput!], $GLDate: DateTime!, $CompanyID: String!, $prefix: String!) {
  createGLExport(input: $input, GLDate: $GLDate, CompanyID: $CompanyID, prefix: $prefix)
}
    `;
export type CreateGlExportMutationFn = ApolloReactCommon.MutationFunction<CreateGlExportMutation, CreateGlExportMutationVariables>;

/**
 * __useCreateGlExportMutation__
 *
 * To run a mutation, you first call `useCreateGlExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlExportMutation, { data, loading, error }] = useCreateGlExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *      GLDate: // value for 'GLDate'
 *      CompanyID: // value for 'CompanyID'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useCreateGlExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGlExportMutation, CreateGlExportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGlExportMutation, CreateGlExportMutationVariables>(CreateGlExportDocument, baseOptions);
      }
export type CreateGlExportMutationHookResult = ReturnType<typeof useCreateGlExportMutation>;
export type CreateGlExportMutationResult = ApolloReactCommon.MutationResult<CreateGlExportMutation>;
export type CreateGlExportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGlExportMutation, CreateGlExportMutationVariables>;
export const GetCashBookSummaryDocument = gql`
    query getCashBookSummary($CompanyID: String, $BankAccountID: String) {
  BankReconciliationDate
  BankTransferDate(CompanyID: $CompanyID)
  PaymentDate(CompanyID: $CompanyID, BankAccountID: $BankAccountID)
  ReceiptDate(CompanyID: $CompanyID)
  ExternalLedgerDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCashBookSummaryQuery__
 *
 * To run a query within a React component, call `useGetCashBookSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashBookSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashBookSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetCashBookSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCashBookSummaryQuery, GetCashBookSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCashBookSummaryQuery, GetCashBookSummaryQueryVariables>(GetCashBookSummaryDocument, baseOptions);
      }
export function useGetCashBookSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCashBookSummaryQuery, GetCashBookSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCashBookSummaryQuery, GetCashBookSummaryQueryVariables>(GetCashBookSummaryDocument, baseOptions);
        }
export type GetCashBookSummaryQueryHookResult = ReturnType<typeof useGetCashBookSummaryQuery>;
export type GetCashBookSummaryLazyQueryHookResult = ReturnType<typeof useGetCashBookSummaryLazyQuery>;
export type GetCashBookSummaryQueryResult = ApolloReactCommon.QueryResult<GetCashBookSummaryQuery, GetCashBookSummaryQueryVariables>;
export const CashBookCountDocument = gql`
    query CashBookCount($CompanyID: String, $BankAccountID: String) {
  CashBookCount(BankAccountID: $BankAccountID, CompanyID: $CompanyID)
}
    `;

/**
 * __useCashBookCountQuery__
 *
 * To run a query within a React component, call `useCashBookCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashBookCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashBookCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useCashBookCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CashBookCountQuery, CashBookCountQueryVariables>) {
        return ApolloReactHooks.useQuery<CashBookCountQuery, CashBookCountQueryVariables>(CashBookCountDocument, baseOptions);
      }
export function useCashBookCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CashBookCountQuery, CashBookCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CashBookCountQuery, CashBookCountQueryVariables>(CashBookCountDocument, baseOptions);
        }
export type CashBookCountQueryHookResult = ReturnType<typeof useCashBookCountQuery>;
export type CashBookCountLazyQueryHookResult = ReturnType<typeof useCashBookCountLazyQuery>;
export type CashBookCountQueryResult = ApolloReactCommon.QueryResult<CashBookCountQuery, CashBookCountQueryVariables>;
export const GetCbSubmenuSummaryDocument = gql`
    query getCBSubmenuSummary($CompanyID: String!, $BankAccountID: String!) {
  paymentCount(CompanyID: $CompanyID, BankAccountID: $BankAccountID)
  receiptCount(CompanyID: $CompanyID, BankAccountID: $BankAccountID)
  bankTransferCount(CompanyID: $CompanyID, BankAccountID: $BankAccountID)
  BankReconciliationDate
  BankTransferDate(CompanyID: $CompanyID)
  PaymentDate(CompanyID: $CompanyID, BankAccountID: $BankAccountID)
  ReceiptDate(CompanyID: $CompanyID)
  ExternalLedgerDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCbSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetCbSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCbSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCbSubmenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetCbSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCbSubmenuSummaryQuery, GetCbSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCbSubmenuSummaryQuery, GetCbSubmenuSummaryQueryVariables>(GetCbSubmenuSummaryDocument, baseOptions);
      }
export function useGetCbSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCbSubmenuSummaryQuery, GetCbSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCbSubmenuSummaryQuery, GetCbSubmenuSummaryQueryVariables>(GetCbSubmenuSummaryDocument, baseOptions);
        }
export type GetCbSubmenuSummaryQueryHookResult = ReturnType<typeof useGetCbSubmenuSummaryQuery>;
export type GetCbSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetCbSubmenuSummaryLazyQuery>;
export type GetCbSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetCbSubmenuSummaryQuery, GetCbSubmenuSummaryQueryVariables>;
export const GetExternalLedgerDocument = gql`
    query getExternalLedger($AccountID: String, $ExternalLedgerID: String, $CompanyID: String) {
  getExternalLedger(AccountID: $AccountID, ExternalLedgerID: $ExternalLedgerID, CompanyID: $CompanyID) {
    DocNo
    createdTs
    RefNo
    Description
    ExternalLedgerID
    CompanyID
    BankAccountID
    LedgerType
    DocAmt
    DocDate
    TransactionDate
    TrxType
    ExchangeRate
  }
}
    `;

/**
 * __useGetExternalLedgerQuery__
 *
 * To run a query within a React component, call `useGetExternalLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalLedgerQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      ExternalLedgerID: // value for 'ExternalLedgerID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetExternalLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExternalLedgerQuery, GetExternalLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExternalLedgerQuery, GetExternalLedgerQueryVariables>(GetExternalLedgerDocument, baseOptions);
      }
export function useGetExternalLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExternalLedgerQuery, GetExternalLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExternalLedgerQuery, GetExternalLedgerQueryVariables>(GetExternalLedgerDocument, baseOptions);
        }
export type GetExternalLedgerQueryHookResult = ReturnType<typeof useGetExternalLedgerQuery>;
export type GetExternalLedgerLazyQueryHookResult = ReturnType<typeof useGetExternalLedgerLazyQuery>;
export type GetExternalLedgerQueryResult = ApolloReactCommon.QueryResult<GetExternalLedgerQuery, GetExternalLedgerQueryVariables>;
export const GetCheckingValidationExtLedgerDocument = gql`
    query getCheckingValidationExtLedger($ExternalLedgerImportInput: [ExternalLedgerImportInput!]!, $CompanyID: String!) {
  getCheckingValidationExtLedger(ExternalLedgerImportInput: $ExternalLedgerImportInput, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCheckingValidationExtLedgerQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationExtLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationExtLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationExtLedgerQuery({
 *   variables: {
 *      ExternalLedgerImportInput: // value for 'ExternalLedgerImportInput'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCheckingValidationExtLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationExtLedgerQuery, GetCheckingValidationExtLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationExtLedgerQuery, GetCheckingValidationExtLedgerQueryVariables>(GetCheckingValidationExtLedgerDocument, baseOptions);
      }
export function useGetCheckingValidationExtLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationExtLedgerQuery, GetCheckingValidationExtLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationExtLedgerQuery, GetCheckingValidationExtLedgerQueryVariables>(GetCheckingValidationExtLedgerDocument, baseOptions);
        }
export type GetCheckingValidationExtLedgerQueryHookResult = ReturnType<typeof useGetCheckingValidationExtLedgerQuery>;
export type GetCheckingValidationExtLedgerLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationExtLedgerLazyQuery>;
export type GetCheckingValidationExtLedgerQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationExtLedgerQuery, GetCheckingValidationExtLedgerQueryVariables>;
export const CreateExternalLedgerDocument = gql`
    mutation createExternalLedger($ExternalLedgerImportInput: String!, $CompanyID: String!, $DocRefName: String!) {
  createExternalLedger(ExternalLedgerImportInput: $ExternalLedgerImportInput, CompanyID: $CompanyID, DocRefName: $DocRefName)
}
    `;
export type CreateExternalLedgerMutationFn = ApolloReactCommon.MutationFunction<CreateExternalLedgerMutation, CreateExternalLedgerMutationVariables>;

/**
 * __useCreateExternalLedgerMutation__
 *
 * To run a mutation, you first call `useCreateExternalLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalLedgerMutation, { data, loading, error }] = useCreateExternalLedgerMutation({
 *   variables: {
 *      ExternalLedgerImportInput: // value for 'ExternalLedgerImportInput'
 *      CompanyID: // value for 'CompanyID'
 *      DocRefName: // value for 'DocRefName'
 *   },
 * });
 */
export function useCreateExternalLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExternalLedgerMutation, CreateExternalLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExternalLedgerMutation, CreateExternalLedgerMutationVariables>(CreateExternalLedgerDocument, baseOptions);
      }
export type CreateExternalLedgerMutationHookResult = ReturnType<typeof useCreateExternalLedgerMutation>;
export type CreateExternalLedgerMutationResult = ApolloReactCommon.MutationResult<CreateExternalLedgerMutation>;
export type CreateExternalLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExternalLedgerMutation, CreateExternalLedgerMutationVariables>;
export const GetFavoritePaymentDocument = gql`
    query getFavoritePayment($UserID: String, $CompanyID: String, $FavoritePaymentID: String) {
  getFavoritePayment(UserID: $UserID, CompanyID: $CompanyID, FavoritePaymentID: $FavoritePaymentID) {
    createdBy
    FavoritePaymentID
    Name
    RefNo
    Description
    PayeeName
    DocAmt
    PaymentMethodID
    PaymentMethod {
      Name
    }
    CreditCardID
    CreditCardType {
      Name
    }
    ChequeNo
    FavoritePaymentItem {
      Sequence
      FavoritePaymentID
      MasterCOAID
      MasterCOA {
        Name
        Code
      }
      DocNo
      DocDate
      CostCentreID
      CostCentre {
        Name
        Code
      }
      TaxSchemeID
      TaxScheme {
        Code
      }
      Amount
      Remark
      TaxRate
      TaxAmt
      DocAmt
    }
  }
}
    `;

/**
 * __useGetFavoritePaymentQuery__
 *
 * To run a query within a React component, call `useGetFavoritePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritePaymentQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoritePaymentID: // value for 'FavoritePaymentID'
 *   },
 * });
 */
export function useGetFavoritePaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoritePaymentQuery, GetFavoritePaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoritePaymentQuery, GetFavoritePaymentQueryVariables>(GetFavoritePaymentDocument, baseOptions);
      }
export function useGetFavoritePaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoritePaymentQuery, GetFavoritePaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoritePaymentQuery, GetFavoritePaymentQueryVariables>(GetFavoritePaymentDocument, baseOptions);
        }
export type GetFavoritePaymentQueryHookResult = ReturnType<typeof useGetFavoritePaymentQuery>;
export type GetFavoritePaymentLazyQueryHookResult = ReturnType<typeof useGetFavoritePaymentLazyQuery>;
export type GetFavoritePaymentQueryResult = ApolloReactCommon.QueryResult<GetFavoritePaymentQuery, GetFavoritePaymentQueryVariables>;
export const CreateFavoritePaymentDocument = gql`
    mutation createFavoritePayment($FavoritePaymentInput: FavoritePaymentInput!, $FavoritePaymentItemInput: [FavoritePaymentItemInput!]!) {
  createFavoritePayment(FavoritePaymentInput: $FavoritePaymentInput, FavoritePaymentItemInput: $FavoritePaymentItemInput)
}
    `;
export type CreateFavoritePaymentMutationFn = ApolloReactCommon.MutationFunction<CreateFavoritePaymentMutation, CreateFavoritePaymentMutationVariables>;

/**
 * __useCreateFavoritePaymentMutation__
 *
 * To run a mutation, you first call `useCreateFavoritePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoritePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoritePaymentMutation, { data, loading, error }] = useCreateFavoritePaymentMutation({
 *   variables: {
 *      FavoritePaymentInput: // value for 'FavoritePaymentInput'
 *      FavoritePaymentItemInput: // value for 'FavoritePaymentItemInput'
 *   },
 * });
 */
export function useCreateFavoritePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFavoritePaymentMutation, CreateFavoritePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFavoritePaymentMutation, CreateFavoritePaymentMutationVariables>(CreateFavoritePaymentDocument, baseOptions);
      }
export type CreateFavoritePaymentMutationHookResult = ReturnType<typeof useCreateFavoritePaymentMutation>;
export type CreateFavoritePaymentMutationResult = ApolloReactCommon.MutationResult<CreateFavoritePaymentMutation>;
export type CreateFavoritePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFavoritePaymentMutation, CreateFavoritePaymentMutationVariables>;
export const DeleteFavoritePaymentDocument = gql`
    mutation deleteFavoritePayment($FavoritePaymentID: String!) {
  deleteFavoritePayment(FavoritePaymentID: $FavoritePaymentID)
}
    `;
export type DeleteFavoritePaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteFavoritePaymentMutation, DeleteFavoritePaymentMutationVariables>;

/**
 * __useDeleteFavoritePaymentMutation__
 *
 * To run a mutation, you first call `useDeleteFavoritePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoritePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoritePaymentMutation, { data, loading, error }] = useDeleteFavoritePaymentMutation({
 *   variables: {
 *      FavoritePaymentID: // value for 'FavoritePaymentID'
 *   },
 * });
 */
export function useDeleteFavoritePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFavoritePaymentMutation, DeleteFavoritePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFavoritePaymentMutation, DeleteFavoritePaymentMutationVariables>(DeleteFavoritePaymentDocument, baseOptions);
      }
export type DeleteFavoritePaymentMutationHookResult = ReturnType<typeof useDeleteFavoritePaymentMutation>;
export type DeleteFavoritePaymentMutationResult = ApolloReactCommon.MutationResult<DeleteFavoritePaymentMutation>;
export type DeleteFavoritePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFavoritePaymentMutation, DeleteFavoritePaymentMutationVariables>;
export const GetFavoriteReceiptDocument = gql`
    query getFavoriteReceipt($UserID: String, $CompanyID: String, $FavoriteReceiptID: String) {
  getFavoriteReceipt(UserID: $UserID, CompanyID: $CompanyID, FavoriteReceiptID: $FavoriteReceiptID) {
    createdBy
    FavoriteReceiptID
    Name
    RefNo
    Description
    ReceivedFrom
    ChequeNo
    PaymentMethodID
    PaymentMethod {
      Name
    }
    CreditCardID
    CreditCardType {
      Name
    }
    FavoriteReceiptItem {
      Sequence
      FavoriteReceiptID
      MasterCOAID
      MasterCOA {
        Name
        Code
      }
      CostCentreID
      CostCentre {
        Name
        Code
      }
      TaxSchemeID
      TaxScheme {
        Code
      }
      Amount
      Remark
      TaxRate
      TaxAmt
      DocAmt
    }
  }
}
    `;

/**
 * __useGetFavoriteReceiptQuery__
 *
 * To run a query within a React component, call `useGetFavoriteReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteReceiptQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoriteReceiptID: // value for 'FavoriteReceiptID'
 *   },
 * });
 */
export function useGetFavoriteReceiptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoriteReceiptQuery, GetFavoriteReceiptQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoriteReceiptQuery, GetFavoriteReceiptQueryVariables>(GetFavoriteReceiptDocument, baseOptions);
      }
export function useGetFavoriteReceiptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoriteReceiptQuery, GetFavoriteReceiptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoriteReceiptQuery, GetFavoriteReceiptQueryVariables>(GetFavoriteReceiptDocument, baseOptions);
        }
export type GetFavoriteReceiptQueryHookResult = ReturnType<typeof useGetFavoriteReceiptQuery>;
export type GetFavoriteReceiptLazyQueryHookResult = ReturnType<typeof useGetFavoriteReceiptLazyQuery>;
export type GetFavoriteReceiptQueryResult = ApolloReactCommon.QueryResult<GetFavoriteReceiptQuery, GetFavoriteReceiptQueryVariables>;
export const CreateFavoriteReceiptDocument = gql`
    mutation createFavoriteReceipt($FavoriteReceiptInput: FavoriteReceiptInput!, $FavoriteReceiptItemInput: [FavoriteReceiptItemInput!]!) {
  createFavoriteReceipt(FavoriteReceiptInput: $FavoriteReceiptInput, FavoriteReceiptItemInput: $FavoriteReceiptItemInput)
}
    `;
export type CreateFavoriteReceiptMutationFn = ApolloReactCommon.MutationFunction<CreateFavoriteReceiptMutation, CreateFavoriteReceiptMutationVariables>;

/**
 * __useCreateFavoriteReceiptMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteReceiptMutation, { data, loading, error }] = useCreateFavoriteReceiptMutation({
 *   variables: {
 *      FavoriteReceiptInput: // value for 'FavoriteReceiptInput'
 *      FavoriteReceiptItemInput: // value for 'FavoriteReceiptItemInput'
 *   },
 * });
 */
export function useCreateFavoriteReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFavoriteReceiptMutation, CreateFavoriteReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFavoriteReceiptMutation, CreateFavoriteReceiptMutationVariables>(CreateFavoriteReceiptDocument, baseOptions);
      }
export type CreateFavoriteReceiptMutationHookResult = ReturnType<typeof useCreateFavoriteReceiptMutation>;
export type CreateFavoriteReceiptMutationResult = ApolloReactCommon.MutationResult<CreateFavoriteReceiptMutation>;
export type CreateFavoriteReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFavoriteReceiptMutation, CreateFavoriteReceiptMutationVariables>;
export const DeleteFavoriteReceiptDocument = gql`
    mutation deleteFavoriteReceipt($FavoriteReceiptID: String!) {
  deleteFavoriteReceipt(FavoriteReceiptID: $FavoriteReceiptID)
}
    `;
export type DeleteFavoriteReceiptMutationFn = ApolloReactCommon.MutationFunction<DeleteFavoriteReceiptMutation, DeleteFavoriteReceiptMutationVariables>;

/**
 * __useDeleteFavoriteReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteReceiptMutation, { data, loading, error }] = useDeleteFavoriteReceiptMutation({
 *   variables: {
 *      FavoriteReceiptID: // value for 'FavoriteReceiptID'
 *   },
 * });
 */
export function useDeleteFavoriteReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFavoriteReceiptMutation, DeleteFavoriteReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFavoriteReceiptMutation, DeleteFavoriteReceiptMutationVariables>(DeleteFavoriteReceiptDocument, baseOptions);
      }
export type DeleteFavoriteReceiptMutationHookResult = ReturnType<typeof useDeleteFavoriteReceiptMutation>;
export type DeleteFavoriteReceiptMutationResult = ApolloReactCommon.MutationResult<DeleteFavoriteReceiptMutation>;
export type DeleteFavoriteReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFavoriteReceiptMutation, DeleteFavoriteReceiptMutationVariables>;
export const GetLedgerDocument = gql`
    query getLedger($AccountID: String, $LedgerID: String, $CompanyID: String, $BankAccountID: String, $IsManual: Boolean, $orderByAsc: String, $skip: Float, $take: Float, $searchValue: String) {
  getLedger(AccountID: $AccountID, LedgerID: $LedgerID, CompanyID: $CompanyID, BankAccountID: $BankAccountID, IsManual: $IsManual, orderByAsc: $orderByAsc, skip: $skip, take: $take, searchValue: $searchValue) {
    DocNo
    createdTs
    modTs
    createdBy
    modBy
    RefNo
    Description
    LedgerID
    CompanyID
    BankAccountID
    DocAmt
    BaseAmt
    LedgerType
    DocDate
    TransactionDate
    PayeeName
    ReceivedFrom
    TrxType
    ExchangeRate
    CurrencyID
    Currency {
      ID
      name
      code
    }
  }
  getLedgerCount(AccountID: $AccountID, CompanyID: $CompanyID, BankAccountID: $BankAccountID, LedgerID: $LedgerID, IsManual: $IsManual, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetLedgerQuery__
 *
 * To run a query within a React component, call `useGetLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedgerQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      LedgerID: // value for 'LedgerID'
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      IsManual: // value for 'IsManual'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLedgerQuery, GetLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLedgerQuery, GetLedgerQueryVariables>(GetLedgerDocument, baseOptions);
      }
export function useGetLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLedgerQuery, GetLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLedgerQuery, GetLedgerQueryVariables>(GetLedgerDocument, baseOptions);
        }
export type GetLedgerQueryHookResult = ReturnType<typeof useGetLedgerQuery>;
export type GetLedgerLazyQueryHookResult = ReturnType<typeof useGetLedgerLazyQuery>;
export type GetLedgerQueryResult = ApolloReactCommon.QueryResult<GetLedgerQuery, GetLedgerQueryVariables>;
export const GetPaymentDocument = gql`
    query getPayment($AccountID: String, $CompanyID: String, $PaymentID: String, $orderByDesc: String, $orderByAsc: String, $BankAccountID: String) {
  getPayment(AccountID: $AccountID, CompanyID: $CompanyID, PaymentID: $PaymentID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, BankAccountID: $BankAccountID) {
    PaymentID
    TransactionDate
    DocDate
    RefNo
    CompanyID
    Description
    PayeeName
    ApprovalStatus
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    DocNo
    Attachment {
      fileURL
      mediaType
      name
    }
    BankAccount {
      AccountNo
      Code
      BankProfile {
        Name
      }
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          StatusRemarks
          createdTs
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    DocAmt
    PaymentItem {
      PaymentItemID
      Amount
      CompanyID
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      DocNo
      DocDate
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
    }
  }
}
    `;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      PaymentID: // value for 'PaymentID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, baseOptions);
      }
export function useGetPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, baseOptions);
        }
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = ApolloReactCommon.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
export const GetPaymentItemDocument = gql`
    query getPaymentItem($AccountID: String, $CompanyID: String, $PaymentID: String, $TaxSchemeID: String) {
  getPaymentItem(AccountID: $AccountID, CompanyID: $CompanyID, PaymentID: $PaymentID, TaxSchemeID: $TaxSchemeID) {
    PaymentItemID
    MasterCOAID
    MasterCOA {
      Code
      Name
    }
    Amount
    TaxSchemeID
    TaxScheme {
      Code
    }
    TaxRate
    TaxAmt
    DocAmt
    CostCentreID
    CostCentre {
      Name
    }
    Remark
  }
}
    `;

/**
 * __useGetPaymentItemQuery__
 *
 * To run a query within a React component, call `useGetPaymentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentItemQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      PaymentID: // value for 'PaymentID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *   },
 * });
 */
export function useGetPaymentItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentItemQuery, GetPaymentItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentItemQuery, GetPaymentItemQueryVariables>(GetPaymentItemDocument, baseOptions);
      }
export function useGetPaymentItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentItemQuery, GetPaymentItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentItemQuery, GetPaymentItemQueryVariables>(GetPaymentItemDocument, baseOptions);
        }
export type GetPaymentItemQueryHookResult = ReturnType<typeof useGetPaymentItemQuery>;
export type GetPaymentItemLazyQueryHookResult = ReturnType<typeof useGetPaymentItemLazyQuery>;
export type GetPaymentItemQueryResult = ApolloReactCommon.QueryResult<GetPaymentItemQuery, GetPaymentItemQueryVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($input: PaymentInput!, $itemInput: [PaymentItemInput!]) {
  createPayment(input: $input, itemInput: $itemInput) {
    PaymentID
  }
}
    `;
export type CreatePaymentMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const UpdatePaymentDocument = gql`
    mutation updatePayment($input: PaymentInput!, $itemInput: [PaymentItemInput!]) {
  updatePayment(input: $input, itemInput: $itemInput) {
    PaymentID
  }
}
    `;
export type UpdatePaymentMutationFn = ApolloReactCommon.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdatePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, baseOptions);
      }
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>;
export const DeletePaymentDocument = gql`
    mutation deletePayment($PaymentID: String!) {
  deletePayment(PaymentID: $PaymentID)
}
    `;
export type DeletePaymentMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentMutation, DeletePaymentMutationVariables>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      PaymentID: // value for 'PaymentID'
 *   },
 * });
 */
export function useDeletePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, baseOptions);
      }
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = ApolloReactCommon.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentMutation, DeletePaymentMutationVariables>;
export const CancelPaymentDocument = gql`
    mutation cancelPayment($input: PaymentDeleteInput!) {
  cancelPayment(input: $input)
}
    `;
export type CancelPaymentMutationFn = ApolloReactCommon.MutationFunction<CancelPaymentMutation, CancelPaymentMutationVariables>;

/**
 * __useCancelPaymentMutation__
 *
 * To run a mutation, you first call `useCancelPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentMutation, { data, loading, error }] = useCancelPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPaymentMutation, CancelPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPaymentMutation, CancelPaymentMutationVariables>(CancelPaymentDocument, baseOptions);
      }
export type CancelPaymentMutationHookResult = ReturnType<typeof useCancelPaymentMutation>;
export type CancelPaymentMutationResult = ApolloReactCommon.MutationResult<CancelPaymentMutation>;
export type CancelPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPaymentMutation, CancelPaymentMutationVariables>;
export const UpdatePaymentItemDocument = gql`
    mutation updatePaymentItem($input: PaymentItemInput!) {
  updatePaymentItem(input: $input) {
    PaymentItemID
  }
}
    `;
export type UpdatePaymentItemMutationFn = ApolloReactCommon.MutationFunction<UpdatePaymentItemMutation, UpdatePaymentItemMutationVariables>;

/**
 * __useUpdatePaymentItemMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentItemMutation, { data, loading, error }] = useUpdatePaymentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePaymentItemMutation, UpdatePaymentItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePaymentItemMutation, UpdatePaymentItemMutationVariables>(UpdatePaymentItemDocument, baseOptions);
      }
export type UpdatePaymentItemMutationHookResult = ReturnType<typeof useUpdatePaymentItemMutation>;
export type UpdatePaymentItemMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentItemMutation>;
export type UpdatePaymentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePaymentItemMutation, UpdatePaymentItemMutationVariables>;
export const PostCbPaymentDocument = gql`
    mutation postCBPayment($PaymentIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postCBPayment(PaymentIDs: $PaymentIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostCbPaymentMutationFn = ApolloReactCommon.MutationFunction<PostCbPaymentMutation, PostCbPaymentMutationVariables>;

/**
 * __usePostCbPaymentMutation__
 *
 * To run a mutation, you first call `usePostCbPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCbPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCbPaymentMutation, { data, loading, error }] = usePostCbPaymentMutation({
 *   variables: {
 *      PaymentIDs: // value for 'PaymentIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostCbPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCbPaymentMutation, PostCbPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCbPaymentMutation, PostCbPaymentMutationVariables>(PostCbPaymentDocument, baseOptions);
      }
export type PostCbPaymentMutationHookResult = ReturnType<typeof usePostCbPaymentMutation>;
export type PostCbPaymentMutationResult = ApolloReactCommon.MutationResult<PostCbPaymentMutation>;
export type PostCbPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCbPaymentMutation, PostCbPaymentMutationVariables>;
export const RejectPaymentDocument = gql`
    mutation rejectPayment($PaymentIDs: [String!]!, $input: PaymentRejectInput!) {
  rejectPayment(PaymentIDs: $PaymentIDs, input: $input)
}
    `;
export type RejectPaymentMutationFn = ApolloReactCommon.MutationFunction<RejectPaymentMutation, RejectPaymentMutationVariables>;

/**
 * __useRejectPaymentMutation__
 *
 * To run a mutation, you first call `useRejectPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPaymentMutation, { data, loading, error }] = useRejectPaymentMutation({
 *   variables: {
 *      PaymentIDs: // value for 'PaymentIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectPaymentMutation, RejectPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectPaymentMutation, RejectPaymentMutationVariables>(RejectPaymentDocument, baseOptions);
      }
export type RejectPaymentMutationHookResult = ReturnType<typeof useRejectPaymentMutation>;
export type RejectPaymentMutationResult = ApolloReactCommon.MutationResult<RejectPaymentMutation>;
export type RejectPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectPaymentMutation, RejectPaymentMutationVariables>;
export const GetPaymentbyStatusDocument = gql`
    query getPaymentbyStatus($CompanyID: String!, $BankAccountID: String!, $StatusArr: [String!]!) {
  getPaymentbyStatus(CompanyID: $CompanyID, BankAccountID: $BankAccountID, StatusArr: $StatusArr) {
    PaymentID
    DocDate
    TransactionDate
    RefNo
    CompanyID
    Description
    PayeeName
    ApprovalStatus
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    DocNo
    Attachment {
      fileURL
      mediaType
      name
    }
    BankAccount {
      AccountNo
      BankProfile {
        Name
      }
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          StatusRemarks
          createdTs
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    DocAmt
    PaymentItem {
      PaymentItemID
      Amount
      CompanyID
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      DocNo
      DocDate
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
    }
  }
}
    `;

/**
 * __useGetPaymentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetPaymentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetPaymentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentbyStatusQuery, GetPaymentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentbyStatusQuery, GetPaymentbyStatusQueryVariables>(GetPaymentbyStatusDocument, baseOptions);
      }
export function useGetPaymentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentbyStatusQuery, GetPaymentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentbyStatusQuery, GetPaymentbyStatusQueryVariables>(GetPaymentbyStatusDocument, baseOptions);
        }
export type GetPaymentbyStatusQueryHookResult = ReturnType<typeof useGetPaymentbyStatusQuery>;
export type GetPaymentbyStatusLazyQueryHookResult = ReturnType<typeof useGetPaymentbyStatusLazyQuery>;
export type GetPaymentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetPaymentbyStatusQuery, GetPaymentbyStatusQueryVariables>;
export const GetPaymentbyStatusListingDocument = gql`
    query getPaymentbyStatusListing($CompanyID: String!, $BankAccountID: String!, $StatusArr: [String!]!, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getPaymentbyStatusListing(CompanyID: $CompanyID, BankAccountID: $BankAccountID, StatusArr: $StatusArr, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    PaymentID
    DocDate
    TransactionDate
    RefNo
    CompanyID
    Description
    PayeeName
    ApprovalStatus
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    DocNo
    Attachment {
      fileURL
      mediaType
      name
    }
    BankAccount {
      AccountNo
      BankProfile {
        Name
      }
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          StatusRemarks
          createdTs
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    DocAmt
    PaymentItem {
      PaymentItemID
      Amount
      CompanyID
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      DocNo
      DocDate
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
    }
  }
}
    `;

/**
 * __useGetPaymentbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetPaymentbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetPaymentbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentbyStatusListingQuery, GetPaymentbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentbyStatusListingQuery, GetPaymentbyStatusListingQueryVariables>(GetPaymentbyStatusListingDocument, baseOptions);
      }
export function useGetPaymentbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentbyStatusListingQuery, GetPaymentbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentbyStatusListingQuery, GetPaymentbyStatusListingQueryVariables>(GetPaymentbyStatusListingDocument, baseOptions);
        }
export type GetPaymentbyStatusListingQueryHookResult = ReturnType<typeof useGetPaymentbyStatusListingQuery>;
export type GetPaymentbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetPaymentbyStatusListingLazyQuery>;
export type GetPaymentbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetPaymentbyStatusListingQuery, GetPaymentbyStatusListingQueryVariables>;
export const GetReceiptDocument = gql`
    query getReceipt($AccountID: String, $CompanyID: String, $ReceiptID: String, $orderByAsc: String, $orderByDesc: String, $BankAccountID: String) {
  getReceipt(AccountID: $AccountID, CompanyID: $CompanyID, ReceiptID: $ReceiptID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, BankAccountID: $BankAccountID) {
    createdBy
    ReceiptID
    DocDate
    TransactionDate
    RefNo
    CompanyID
    Description
    ReceivedFrom
    BankAccountID
    BankAccount {
      AccountNo
      Code
      BankAccountID
      BankProfile {
        Name
      }
    }
    ApprovalStatus
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    DocNo
    Attachment {
      fileURL
      mediaType
      name
    }
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    DocAmt
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          createdTs
          WorkFlowApprovedStepID
          StatusRemarks
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ReceiptItem {
      ReceiptItemID
      Amount
      CompanyID
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
    }
  }
}
    `;

/**
 * __useGetReceiptQuery__
 *
 * To run a query within a React component, call `useGetReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      ReceiptID: // value for 'ReceiptID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useGetReceiptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiptQuery, GetReceiptQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiptQuery, GetReceiptQueryVariables>(GetReceiptDocument, baseOptions);
      }
export function useGetReceiptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiptQuery, GetReceiptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiptQuery, GetReceiptQueryVariables>(GetReceiptDocument, baseOptions);
        }
export type GetReceiptQueryHookResult = ReturnType<typeof useGetReceiptQuery>;
export type GetReceiptLazyQueryHookResult = ReturnType<typeof useGetReceiptLazyQuery>;
export type GetReceiptQueryResult = ApolloReactCommon.QueryResult<GetReceiptQuery, GetReceiptQueryVariables>;
export const GetReceiptItemDocument = gql`
    query getReceiptItem($AccountID: String, $CompanyID: String, $ReceiptID: String, $TaxSchemeID: String) {
  getReceiptItem(AccountID: $AccountID, CompanyID: $CompanyID, ReceiptID: $ReceiptID, TaxSchemeID: $TaxSchemeID) {
    ReceiptItemID
    MasterCOAID
    MasterCOA {
      Code
      Name
    }
    Amount
    TaxSchemeID
    TaxScheme {
      Code
    }
    TaxRate
    TaxAmt
    DocAmt
    CostCentreID
    CostCentre {
      Name
    }
    Remark
  }
}
    `;

/**
 * __useGetReceiptItemQuery__
 *
 * To run a query within a React component, call `useGetReceiptItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptItemQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      ReceiptID: // value for 'ReceiptID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *   },
 * });
 */
export function useGetReceiptItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiptItemQuery, GetReceiptItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiptItemQuery, GetReceiptItemQueryVariables>(GetReceiptItemDocument, baseOptions);
      }
export function useGetReceiptItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiptItemQuery, GetReceiptItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiptItemQuery, GetReceiptItemQueryVariables>(GetReceiptItemDocument, baseOptions);
        }
export type GetReceiptItemQueryHookResult = ReturnType<typeof useGetReceiptItemQuery>;
export type GetReceiptItemLazyQueryHookResult = ReturnType<typeof useGetReceiptItemLazyQuery>;
export type GetReceiptItemQueryResult = ApolloReactCommon.QueryResult<GetReceiptItemQuery, GetReceiptItemQueryVariables>;
export const GetReceiptbyStatusDocument = gql`
    query getReceiptbyStatus($CompanyID: String!, $BankAccountID: String!, $StatusArr: [String!]!) {
  getReceiptbyStatus(CompanyID: $CompanyID, BankAccountID: $BankAccountID, StatusArr: $StatusArr) {
    createdBy
    ReceiptID
    DocDate
    TransactionDate
    RefNo
    CompanyID
    Description
    ReceivedFrom
    BankAccountID
    BankAccount {
      AccountNo
      Code
      BankProfile {
        Name
      }
    }
    ApprovalStatus
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    DocAmt
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          createdTs
          WorkFlowApprovedStepID
          StatusRemarks
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ReceiptItem {
      ReceiptItemID
      Amount
      CompanyID
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
    }
  }
}
    `;

/**
 * __useGetReceiptbyStatusQuery__
 *
 * To run a query within a React component, call `useGetReceiptbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetReceiptbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiptbyStatusQuery, GetReceiptbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiptbyStatusQuery, GetReceiptbyStatusQueryVariables>(GetReceiptbyStatusDocument, baseOptions);
      }
export function useGetReceiptbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiptbyStatusQuery, GetReceiptbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiptbyStatusQuery, GetReceiptbyStatusQueryVariables>(GetReceiptbyStatusDocument, baseOptions);
        }
export type GetReceiptbyStatusQueryHookResult = ReturnType<typeof useGetReceiptbyStatusQuery>;
export type GetReceiptbyStatusLazyQueryHookResult = ReturnType<typeof useGetReceiptbyStatusLazyQuery>;
export type GetReceiptbyStatusQueryResult = ApolloReactCommon.QueryResult<GetReceiptbyStatusQuery, GetReceiptbyStatusQueryVariables>;
export const GetReceiptbyStatusListingDocument = gql`
    query getReceiptbyStatusListing($CompanyID: String!, $BankAccountID: String!, $StatusArr: [String!]!, $skip: Float, $take: Float, $searchValue: String, $sortOrder: String, $sortField: String) {
  getReceiptbyStatusListing(CompanyID: $CompanyID, BankAccountID: $BankAccountID, StatusArr: $StatusArr, skip: $skip, take: $take, searchValue: $searchValue, sortOrder: $sortOrder, sortField: $sortField) {
    createdBy
    ReceiptID
    DocDate
    TransactionDate
    RefNo
    CompanyID
    Description
    ReceivedFrom
    BankAccountID
    BankAccount {
      AccountNo
      Code
      BankProfile {
        Name
      }
    }
    ApprovalStatus
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    Attachment {
      fileURL
      mediaType
      name
    }
    DocNo
    PaymentMethodID
    PaymentMethod {
      PaymentMethodID
      Name
      Description
      Sequence
      IsSystem
    }
    CreditCardID
    CreditCardType {
      CreditCardID
      Name
      Description
      Sequence
      IsSystem
    }
    ChequeNo
    ChequeDate
    ChequeExpiryDate
    DocAmt
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          createdTs
          WorkFlowApprovedStepID
          StatusRemarks
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    ReceiptItem {
      ReceiptItemID
      Amount
      CompanyID
      Remark
      TaxSchemeID
      TaxRate
      TaxAmt
      DocAmt
      Sequence
      TaxScheme {
        TaxSchemeID
        Code
      }
      MasterCOAID
      MasterCOA {
        MasterCOAID
        Code
        Name
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
    }
  }
}
    `;

/**
 * __useGetReceiptbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetReceiptbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankAccountID: // value for 'BankAccountID'
 *      StatusArr: // value for 'StatusArr'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetReceiptbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiptbyStatusListingQuery, GetReceiptbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiptbyStatusListingQuery, GetReceiptbyStatusListingQueryVariables>(GetReceiptbyStatusListingDocument, baseOptions);
      }
export function useGetReceiptbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiptbyStatusListingQuery, GetReceiptbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiptbyStatusListingQuery, GetReceiptbyStatusListingQueryVariables>(GetReceiptbyStatusListingDocument, baseOptions);
        }
export type GetReceiptbyStatusListingQueryHookResult = ReturnType<typeof useGetReceiptbyStatusListingQuery>;
export type GetReceiptbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetReceiptbyStatusListingLazyQuery>;
export type GetReceiptbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetReceiptbyStatusListingQuery, GetReceiptbyStatusListingQueryVariables>;
export const PostCbReceiptDocument = gql`
    mutation postCBReceipt($ReceiptIDs: [String!]!, $CompanyID: String, $RefTable: String!) {
  postCBReceipt(ReceiptIDs: $ReceiptIDs, CompanyID: $CompanyID, RefTable: $RefTable)
}
    `;
export type PostCbReceiptMutationFn = ApolloReactCommon.MutationFunction<PostCbReceiptMutation, PostCbReceiptMutationVariables>;

/**
 * __usePostCbReceiptMutation__
 *
 * To run a mutation, you first call `usePostCbReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCbReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCbReceiptMutation, { data, loading, error }] = usePostCbReceiptMutation({
 *   variables: {
 *      ReceiptIDs: // value for 'ReceiptIDs'
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function usePostCbReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCbReceiptMutation, PostCbReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCbReceiptMutation, PostCbReceiptMutationVariables>(PostCbReceiptDocument, baseOptions);
      }
export type PostCbReceiptMutationHookResult = ReturnType<typeof usePostCbReceiptMutation>;
export type PostCbReceiptMutationResult = ApolloReactCommon.MutationResult<PostCbReceiptMutation>;
export type PostCbReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCbReceiptMutation, PostCbReceiptMutationVariables>;
export const RejectReceiptDocument = gql`
    mutation rejectReceipt($ReceiptIDs: [String!]!, $input: ReceiptRejectInput!) {
  rejectReceipt(ReceiptIDs: $ReceiptIDs, input: $input)
}
    `;
export type RejectReceiptMutationFn = ApolloReactCommon.MutationFunction<RejectReceiptMutation, RejectReceiptMutationVariables>;

/**
 * __useRejectReceiptMutation__
 *
 * To run a mutation, you first call `useRejectReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectReceiptMutation, { data, loading, error }] = useRejectReceiptMutation({
 *   variables: {
 *      ReceiptIDs: // value for 'ReceiptIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectReceiptMutation, RejectReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectReceiptMutation, RejectReceiptMutationVariables>(RejectReceiptDocument, baseOptions);
      }
export type RejectReceiptMutationHookResult = ReturnType<typeof useRejectReceiptMutation>;
export type RejectReceiptMutationResult = ApolloReactCommon.MutationResult<RejectReceiptMutation>;
export type RejectReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectReceiptMutation, RejectReceiptMutationVariables>;
export const CreateReceiptDocument = gql`
    mutation createReceipt($input: ReceiptInput!, $itemInput: [ReceiptItemInput!]) {
  createReceipt(input: $input, itemInput: $itemInput) {
    ReceiptID
  }
}
    `;
export type CreateReceiptMutationFn = ApolloReactCommon.MutationFunction<CreateReceiptMutation, CreateReceiptMutationVariables>;

/**
 * __useCreateReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptMutation, { data, loading, error }] = useCreateReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReceiptMutation, CreateReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReceiptMutation, CreateReceiptMutationVariables>(CreateReceiptDocument, baseOptions);
      }
export type CreateReceiptMutationHookResult = ReturnType<typeof useCreateReceiptMutation>;
export type CreateReceiptMutationResult = ApolloReactCommon.MutationResult<CreateReceiptMutation>;
export type CreateReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReceiptMutation, CreateReceiptMutationVariables>;
export const UpdateReceiptDocument = gql`
    mutation updateReceipt($input: ReceiptInput!, $itemInput: [ReceiptItemInput!]) {
  updateReceipt(input: $input, itemInput: $itemInput) {
    ReceiptID
  }
}
    `;
export type UpdateReceiptMutationFn = ApolloReactCommon.MutationFunction<UpdateReceiptMutation, UpdateReceiptMutationVariables>;

/**
 * __useUpdateReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceiptMutation, { data, loading, error }] = useUpdateReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReceiptMutation, UpdateReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReceiptMutation, UpdateReceiptMutationVariables>(UpdateReceiptDocument, baseOptions);
      }
export type UpdateReceiptMutationHookResult = ReturnType<typeof useUpdateReceiptMutation>;
export type UpdateReceiptMutationResult = ApolloReactCommon.MutationResult<UpdateReceiptMutation>;
export type UpdateReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReceiptMutation, UpdateReceiptMutationVariables>;
export const CancelReceiptDocument = gql`
    mutation cancelReceipt($input: ReceiptDeleteInput!) {
  cancelReceipt(input: $input)
}
    `;
export type CancelReceiptMutationFn = ApolloReactCommon.MutationFunction<CancelReceiptMutation, CancelReceiptMutationVariables>;

/**
 * __useCancelReceiptMutation__
 *
 * To run a mutation, you first call `useCancelReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReceiptMutation, { data, loading, error }] = useCancelReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelReceiptMutation, CancelReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelReceiptMutation, CancelReceiptMutationVariables>(CancelReceiptDocument, baseOptions);
      }
export type CancelReceiptMutationHookResult = ReturnType<typeof useCancelReceiptMutation>;
export type CancelReceiptMutationResult = ApolloReactCommon.MutationResult<CancelReceiptMutation>;
export type CancelReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelReceiptMutation, CancelReceiptMutationVariables>;
export const DeleteReceiptDocument = gql`
    mutation deleteReceipt($ReceiptID: String!) {
  deleteReceipt(ReceiptID: $ReceiptID)
}
    `;
export type DeleteReceiptMutationFn = ApolloReactCommon.MutationFunction<DeleteReceiptMutation, DeleteReceiptMutationVariables>;

/**
 * __useDeleteReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceiptMutation, { data, loading, error }] = useDeleteReceiptMutation({
 *   variables: {
 *      ReceiptID: // value for 'ReceiptID'
 *   },
 * });
 */
export function useDeleteReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReceiptMutation, DeleteReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReceiptMutation, DeleteReceiptMutationVariables>(DeleteReceiptDocument, baseOptions);
      }
export type DeleteReceiptMutationHookResult = ReturnType<typeof useDeleteReceiptMutation>;
export type DeleteReceiptMutationResult = ApolloReactCommon.MutationResult<DeleteReceiptMutation>;
export type DeleteReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReceiptMutation, DeleteReceiptMutationVariables>;
export const UpdateReceiptItemDocument = gql`
    mutation updateReceiptItem($input: ReceiptItemInput!) {
  updateReceiptItem(input: $input) {
    ReceiptItemID
  }
}
    `;
export type UpdateReceiptItemMutationFn = ApolloReactCommon.MutationFunction<UpdateReceiptItemMutation, UpdateReceiptItemMutationVariables>;

/**
 * __useUpdateReceiptItemMutation__
 *
 * To run a mutation, you first call `useUpdateReceiptItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReceiptItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReceiptItemMutation, { data, loading, error }] = useUpdateReceiptItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReceiptItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReceiptItemMutation, UpdateReceiptItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReceiptItemMutation, UpdateReceiptItemMutationVariables>(UpdateReceiptItemDocument, baseOptions);
      }
export type UpdateReceiptItemMutationHookResult = ReturnType<typeof useUpdateReceiptItemMutation>;
export type UpdateReceiptItemMutationResult = ApolloReactCommon.MutationResult<UpdateReceiptItemMutation>;
export type UpdateReceiptItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReceiptItemMutation, UpdateReceiptItemMutationVariables>;
export const GetAccountPeriodDocument = gql`
    query getAccountPeriod($CompanyID: String, $FYear: Float, $orderByAsc: String, $AccountPeriodID: String) {
  getAccountPeriod(CompanyID: $CompanyID, FYear: $FYear, orderByAsc: $orderByAsc, AccountPeriodID: $AccountPeriodID) {
    AccountPeriodID
    CompanyID
    FYear
    FPeriod
    StartDate
    EndDate
    MonthEndClose
    YearEndClose
  }
}
    `;

/**
 * __useGetAccountPeriodQuery__
 *
 * To run a query within a React component, call `useGetAccountPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *      orderByAsc: // value for 'orderByAsc'
 *      AccountPeriodID: // value for 'AccountPeriodID'
 *   },
 * });
 */
export function useGetAccountPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountPeriodQuery, GetAccountPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountPeriodQuery, GetAccountPeriodQueryVariables>(GetAccountPeriodDocument, baseOptions);
      }
export function useGetAccountPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountPeriodQuery, GetAccountPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountPeriodQuery, GetAccountPeriodQueryVariables>(GetAccountPeriodDocument, baseOptions);
        }
export type GetAccountPeriodQueryHookResult = ReturnType<typeof useGetAccountPeriodQuery>;
export type GetAccountPeriodLazyQueryHookResult = ReturnType<typeof useGetAccountPeriodLazyQuery>;
export type GetAccountPeriodQueryResult = ApolloReactCommon.QueryResult<GetAccountPeriodQuery, GetAccountPeriodQueryVariables>;
export const GetAccountPeriodPermDocument = gql`
    query getAccountPeriodPerm($CompanyID: String, $FYear: Float, $orderByAsc: String) {
  getAccountPeriodPerm(CompanyID: $CompanyID, FYear: $FYear, orderByAsc: $orderByAsc) {
    AccountPeriodID
    CompanyID
    FYear
    FPeriod
    StartDate
    EndDate
    MonthEndClose
    YearEndClose
  }
}
    `;

/**
 * __useGetAccountPeriodPermQuery__
 *
 * To run a query within a React component, call `useGetAccountPeriodPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPeriodPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPeriodPermQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAccountPeriodPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountPeriodPermQuery, GetAccountPeriodPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountPeriodPermQuery, GetAccountPeriodPermQueryVariables>(GetAccountPeriodPermDocument, baseOptions);
      }
export function useGetAccountPeriodPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountPeriodPermQuery, GetAccountPeriodPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountPeriodPermQuery, GetAccountPeriodPermQueryVariables>(GetAccountPeriodPermDocument, baseOptions);
        }
export type GetAccountPeriodPermQueryHookResult = ReturnType<typeof useGetAccountPeriodPermQuery>;
export type GetAccountPeriodPermLazyQueryHookResult = ReturnType<typeof useGetAccountPeriodPermLazyQuery>;
export type GetAccountPeriodPermQueryResult = ApolloReactCommon.QueryResult<GetAccountPeriodPermQuery, GetAccountPeriodPermQueryVariables>;
export const GetAccountPeriodYearPeriodDocument = gql`
    query getAccountPeriodYearPeriod($CompanyID: String, $FYear: Float, $orderByAsc: String) {
  getAccountPeriod(CompanyID: $CompanyID, FYear: $FYear, orderByAsc: $orderByAsc) {
    AccountPeriodID
    CompanyID
    FYear
    FPeriod
    MonthEndClose
    StartDate
    EndDate
  }
}
    `;

/**
 * __useGetAccountPeriodYearPeriodQuery__
 *
 * To run a query within a React component, call `useGetAccountPeriodYearPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPeriodYearPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPeriodYearPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAccountPeriodYearPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountPeriodYearPeriodQuery, GetAccountPeriodYearPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountPeriodYearPeriodQuery, GetAccountPeriodYearPeriodQueryVariables>(GetAccountPeriodYearPeriodDocument, baseOptions);
      }
export function useGetAccountPeriodYearPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountPeriodYearPeriodQuery, GetAccountPeriodYearPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountPeriodYearPeriodQuery, GetAccountPeriodYearPeriodQueryVariables>(GetAccountPeriodYearPeriodDocument, baseOptions);
        }
export type GetAccountPeriodYearPeriodQueryHookResult = ReturnType<typeof useGetAccountPeriodYearPeriodQuery>;
export type GetAccountPeriodYearPeriodLazyQueryHookResult = ReturnType<typeof useGetAccountPeriodYearPeriodLazyQuery>;
export type GetAccountPeriodYearPeriodQueryResult = ApolloReactCommon.QueryResult<GetAccountPeriodYearPeriodQuery, GetAccountPeriodYearPeriodQueryVariables>;
export const GetSelectedAccountPeriodDocument = gql`
    query getSelectedAccountPeriod($CompanyID: String!, $selectedDate: DateTime!) {
  getSelectedAccountPeriod(CompanyID: $CompanyID, selectedDate: $selectedDate) {
    FYear
    FPeriod
  }
}
    `;

/**
 * __useGetSelectedAccountPeriodQuery__
 *
 * To run a query within a React component, call `useGetSelectedAccountPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedAccountPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedAccountPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      selectedDate: // value for 'selectedDate'
 *   },
 * });
 */
export function useGetSelectedAccountPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelectedAccountPeriodQuery, GetSelectedAccountPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelectedAccountPeriodQuery, GetSelectedAccountPeriodQueryVariables>(GetSelectedAccountPeriodDocument, baseOptions);
      }
export function useGetSelectedAccountPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelectedAccountPeriodQuery, GetSelectedAccountPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelectedAccountPeriodQuery, GetSelectedAccountPeriodQueryVariables>(GetSelectedAccountPeriodDocument, baseOptions);
        }
export type GetSelectedAccountPeriodQueryHookResult = ReturnType<typeof useGetSelectedAccountPeriodQuery>;
export type GetSelectedAccountPeriodLazyQueryHookResult = ReturnType<typeof useGetSelectedAccountPeriodLazyQuery>;
export type GetSelectedAccountPeriodQueryResult = ApolloReactCommon.QueryResult<GetSelectedAccountPeriodQuery, GetSelectedAccountPeriodQueryVariables>;
export const GetAccountYearDocument = gql`
    query getAccountYear($CompanyID: String!) {
  getAccountYear(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetAccountYearQuery__
 *
 * To run a query within a React component, call `useGetAccountYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountYearQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAccountYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountYearQuery, GetAccountYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountYearQuery, GetAccountYearQueryVariables>(GetAccountYearDocument, baseOptions);
      }
export function useGetAccountYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountYearQuery, GetAccountYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountYearQuery, GetAccountYearQueryVariables>(GetAccountYearDocument, baseOptions);
        }
export type GetAccountYearQueryHookResult = ReturnType<typeof useGetAccountYearQuery>;
export type GetAccountYearLazyQueryHookResult = ReturnType<typeof useGetAccountYearLazyQuery>;
export type GetAccountYearQueryResult = ApolloReactCommon.QueryResult<GetAccountYearQuery, GetAccountYearQueryVariables>;
export const GetOpenAccPeriodDateRangeDocument = gql`
    query getOpenAccPeriodDateRange($CompanyID: String!) {
  getOpenAccPeriodDateRange(CompanyID: $CompanyID) {
    StartDate
    EndDate
  }
}
    `;

/**
 * __useGetOpenAccPeriodDateRangeQuery__
 *
 * To run a query within a React component, call `useGetOpenAccPeriodDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenAccPeriodDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenAccPeriodDateRangeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetOpenAccPeriodDateRangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOpenAccPeriodDateRangeQuery, GetOpenAccPeriodDateRangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOpenAccPeriodDateRangeQuery, GetOpenAccPeriodDateRangeQueryVariables>(GetOpenAccPeriodDateRangeDocument, baseOptions);
      }
export function useGetOpenAccPeriodDateRangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOpenAccPeriodDateRangeQuery, GetOpenAccPeriodDateRangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOpenAccPeriodDateRangeQuery, GetOpenAccPeriodDateRangeQueryVariables>(GetOpenAccPeriodDateRangeDocument, baseOptions);
        }
export type GetOpenAccPeriodDateRangeQueryHookResult = ReturnType<typeof useGetOpenAccPeriodDateRangeQuery>;
export type GetOpenAccPeriodDateRangeLazyQueryHookResult = ReturnType<typeof useGetOpenAccPeriodDateRangeLazyQuery>;
export type GetOpenAccPeriodDateRangeQueryResult = ApolloReactCommon.QueryResult<GetOpenAccPeriodDateRangeQuery, GetOpenAccPeriodDateRangeQueryVariables>;
export const GetNextAccPeriodDocument = gql`
    query getNextAccPeriod($CompanyID: String!) {
  getNextAccPeriod(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetNextAccPeriodQuery__
 *
 * To run a query within a React component, call `useGetNextAccPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextAccPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextAccPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetNextAccPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNextAccPeriodQuery, GetNextAccPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNextAccPeriodQuery, GetNextAccPeriodQueryVariables>(GetNextAccPeriodDocument, baseOptions);
      }
export function useGetNextAccPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextAccPeriodQuery, GetNextAccPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNextAccPeriodQuery, GetNextAccPeriodQueryVariables>(GetNextAccPeriodDocument, baseOptions);
        }
export type GetNextAccPeriodQueryHookResult = ReturnType<typeof useGetNextAccPeriodQuery>;
export type GetNextAccPeriodLazyQueryHookResult = ReturnType<typeof useGetNextAccPeriodLazyQuery>;
export type GetNextAccPeriodQueryResult = ApolloReactCommon.QueryResult<GetNextAccPeriodQuery, GetNextAccPeriodQueryVariables>;
export const AccountPeriodCheckingDocument = gql`
    query accountPeriodChecking($FYear: Float!, $CompanyID: String!) {
  accountPeriodChecking(FYear: $FYear, CompanyID: $CompanyID) {
    YearClosed
    IsEditable
    LatestUsedPeriod
    MinStartDate
    MaxEndDate
  }
}
    `;

/**
 * __useAccountPeriodCheckingQuery__
 *
 * To run a query within a React component, call `useAccountPeriodCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPeriodCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPeriodCheckingQuery({
 *   variables: {
 *      FYear: // value for 'FYear'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useAccountPeriodCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountPeriodCheckingQuery, AccountPeriodCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountPeriodCheckingQuery, AccountPeriodCheckingQueryVariables>(AccountPeriodCheckingDocument, baseOptions);
      }
export function useAccountPeriodCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountPeriodCheckingQuery, AccountPeriodCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountPeriodCheckingQuery, AccountPeriodCheckingQueryVariables>(AccountPeriodCheckingDocument, baseOptions);
        }
export type AccountPeriodCheckingQueryHookResult = ReturnType<typeof useAccountPeriodCheckingQuery>;
export type AccountPeriodCheckingLazyQueryHookResult = ReturnType<typeof useAccountPeriodCheckingLazyQuery>;
export type AccountPeriodCheckingQueryResult = ApolloReactCommon.QueryResult<AccountPeriodCheckingQuery, AccountPeriodCheckingQueryVariables>;
export const CheckExistingAccountPeriodDocument = gql`
    query CheckExistingAccountPeriod($CompanyID: String!) {
  CheckExistingAccountPeriod(CompanyID: $CompanyID) {
    LastYear
    LastMonth
  }
}
    `;

/**
 * __useCheckExistingAccountPeriodQuery__
 *
 * To run a query within a React component, call `useCheckExistingAccountPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistingAccountPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistingAccountPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCheckExistingAccountPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckExistingAccountPeriodQuery, CheckExistingAccountPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckExistingAccountPeriodQuery, CheckExistingAccountPeriodQueryVariables>(CheckExistingAccountPeriodDocument, baseOptions);
      }
export function useCheckExistingAccountPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckExistingAccountPeriodQuery, CheckExistingAccountPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckExistingAccountPeriodQuery, CheckExistingAccountPeriodQueryVariables>(CheckExistingAccountPeriodDocument, baseOptions);
        }
export type CheckExistingAccountPeriodQueryHookResult = ReturnType<typeof useCheckExistingAccountPeriodQuery>;
export type CheckExistingAccountPeriodLazyQueryHookResult = ReturnType<typeof useCheckExistingAccountPeriodLazyQuery>;
export type CheckExistingAccountPeriodQueryResult = ApolloReactCommon.QueryResult<CheckExistingAccountPeriodQuery, CheckExistingAccountPeriodQueryVariables>;
export const CreateAccountPeriodDocument = gql`
    mutation createAccountPeriod($input: [AccountPeriodInput!], $CompanyID: String!, $FYear: Float!) {
  createAccountPeriod(input: $input, CompanyID: $CompanyID, FYear: $FYear)
}
    `;
export type CreateAccountPeriodMutationFn = ApolloReactCommon.MutationFunction<CreateAccountPeriodMutation, CreateAccountPeriodMutationVariables>;

/**
 * __useCreateAccountPeriodMutation__
 *
 * To run a mutation, you first call `useCreateAccountPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountPeriodMutation, { data, loading, error }] = useCreateAccountPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *   },
 * });
 */
export function useCreateAccountPeriodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountPeriodMutation, CreateAccountPeriodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountPeriodMutation, CreateAccountPeriodMutationVariables>(CreateAccountPeriodDocument, baseOptions);
      }
export type CreateAccountPeriodMutationHookResult = ReturnType<typeof useCreateAccountPeriodMutation>;
export type CreateAccountPeriodMutationResult = ApolloReactCommon.MutationResult<CreateAccountPeriodMutation>;
export type CreateAccountPeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountPeriodMutation, CreateAccountPeriodMutationVariables>;
export const UpdateAccountPeriodDocument = gql`
    mutation updateAccountPeriod($input: [AccountPeriodInput!]!, $CompanyID: String!, $FYear: Float!) {
  updateAccountPeriod(input: $input, CompanyID: $CompanyID, FYear: $FYear)
}
    `;
export type UpdateAccountPeriodMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountPeriodMutation, UpdateAccountPeriodMutationVariables>;

/**
 * __useUpdateAccountPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPeriodMutation, { data, loading, error }] = useUpdateAccountPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *   },
 * });
 */
export function useUpdateAccountPeriodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountPeriodMutation, UpdateAccountPeriodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountPeriodMutation, UpdateAccountPeriodMutationVariables>(UpdateAccountPeriodDocument, baseOptions);
      }
export type UpdateAccountPeriodMutationHookResult = ReturnType<typeof useUpdateAccountPeriodMutation>;
export type UpdateAccountPeriodMutationResult = ApolloReactCommon.MutationResult<UpdateAccountPeriodMutation>;
export type UpdateAccountPeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountPeriodMutation, UpdateAccountPeriodMutationVariables>;
export const GetPreviousPeriodOfYearDocument = gql`
    query getPreviousPeriodOfYear($CompanyID: String!, $FYear: Float!) {
  getPreviousPeriodOfYear(CompanyID: $CompanyID, FYear: $FYear) {
    AccountPeriodID
    CompanyID
    FYear
    FPeriod
    StartDate
    EndDate
    MonthEndClose
    YearEndClose
  }
}
    `;

/**
 * __useGetPreviousPeriodOfYearQuery__
 *
 * To run a query within a React component, call `useGetPreviousPeriodOfYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviousPeriodOfYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviousPeriodOfYearQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *   },
 * });
 */
export function useGetPreviousPeriodOfYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPreviousPeriodOfYearQuery, GetPreviousPeriodOfYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPreviousPeriodOfYearQuery, GetPreviousPeriodOfYearQueryVariables>(GetPreviousPeriodOfYearDocument, baseOptions);
      }
export function useGetPreviousPeriodOfYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPreviousPeriodOfYearQuery, GetPreviousPeriodOfYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPreviousPeriodOfYearQuery, GetPreviousPeriodOfYearQueryVariables>(GetPreviousPeriodOfYearDocument, baseOptions);
        }
export type GetPreviousPeriodOfYearQueryHookResult = ReturnType<typeof useGetPreviousPeriodOfYearQuery>;
export type GetPreviousPeriodOfYearLazyQueryHookResult = ReturnType<typeof useGetPreviousPeriodOfYearLazyQuery>;
export type GetPreviousPeriodOfYearQueryResult = ApolloReactCommon.QueryResult<GetPreviousPeriodOfYearQuery, GetPreviousPeriodOfYearQueryVariables>;
export const GetArAgingDocument = gql`
    query getARAging($CompanyID: String, $AgingID: String, $AccountType: String) {
  getARAging(CompanyID: $CompanyID, AgingID: $AgingID, AccountType: $AccountType) {
    AgingID
    Period1
    Period2
    Period3
    Period4
    Period5
    Period6
    AccountType
  }
}
    `;

/**
 * __useGetArAgingQuery__
 *
 * To run a query within a React component, call `useGetArAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAgingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AgingID: // value for 'AgingID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetArAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAgingQuery, GetArAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAgingQuery, GetArAgingQueryVariables>(GetArAgingDocument, baseOptions);
      }
export function useGetArAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAgingQuery, GetArAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAgingQuery, GetArAgingQueryVariables>(GetArAgingDocument, baseOptions);
        }
export type GetArAgingQueryHookResult = ReturnType<typeof useGetArAgingQuery>;
export type GetArAgingLazyQueryHookResult = ReturnType<typeof useGetArAgingLazyQuery>;
export type GetArAgingQueryResult = ApolloReactCommon.QueryResult<GetArAgingQuery, GetArAgingQueryVariables>;
export const CreateArAgingDocument = gql`
    mutation createARAging($input: AgingInput!) {
  createARAging(input: $input)
}
    `;
export type CreateArAgingMutationFn = ApolloReactCommon.MutationFunction<CreateArAgingMutation, CreateArAgingMutationVariables>;

/**
 * __useCreateArAgingMutation__
 *
 * To run a mutation, you first call `useCreateArAgingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArAgingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArAgingMutation, { data, loading, error }] = useCreateArAgingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArAgingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArAgingMutation, CreateArAgingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArAgingMutation, CreateArAgingMutationVariables>(CreateArAgingDocument, baseOptions);
      }
export type CreateArAgingMutationHookResult = ReturnType<typeof useCreateArAgingMutation>;
export type CreateArAgingMutationResult = ApolloReactCommon.MutationResult<CreateArAgingMutation>;
export type CreateArAgingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArAgingMutation, CreateArAgingMutationVariables>;
export const GetApAgingDocument = gql`
    query getAPAging($CompanyID: String, $AgingID: String, $AccountType: String) {
  getAPAging(CompanyID: $CompanyID, AgingID: $AgingID, AccountType: $AccountType) {
    AgingID
    Period1
    Period2
    Period3
    Period4
    Period5
    Period6
    AccountType
  }
}
    `;

/**
 * __useGetApAgingQuery__
 *
 * To run a query within a React component, call `useGetApAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAgingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AgingID: // value for 'AgingID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetApAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAgingQuery, GetApAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAgingQuery, GetApAgingQueryVariables>(GetApAgingDocument, baseOptions);
      }
export function useGetApAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAgingQuery, GetApAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAgingQuery, GetApAgingQueryVariables>(GetApAgingDocument, baseOptions);
        }
export type GetApAgingQueryHookResult = ReturnType<typeof useGetApAgingQuery>;
export type GetApAgingLazyQueryHookResult = ReturnType<typeof useGetApAgingLazyQuery>;
export type GetApAgingQueryResult = ApolloReactCommon.QueryResult<GetApAgingQuery, GetApAgingQueryVariables>;
export const CreateApAgingDocument = gql`
    mutation createAPAging($input: AgingInput!) {
  createAPAging(input: $input)
}
    `;
export type CreateApAgingMutationFn = ApolloReactCommon.MutationFunction<CreateApAgingMutation, CreateApAgingMutationVariables>;

/**
 * __useCreateApAgingMutation__
 *
 * To run a mutation, you first call `useCreateApAgingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApAgingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApAgingMutation, { data, loading, error }] = useCreateApAgingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApAgingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApAgingMutation, CreateApAgingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApAgingMutation, CreateApAgingMutationVariables>(CreateApAgingDocument, baseOptions);
      }
export type CreateApAgingMutationHookResult = ReturnType<typeof useCreateApAgingMutation>;
export type CreateApAgingMutationResult = ApolloReactCommon.MutationResult<CreateApAgingMutation>;
export type CreateApAgingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApAgingMutation, CreateApAgingMutationVariables>;
export const GetApprovalPolicyAssignmentDocument = gql`
    query getApprovalPolicyAssignment($CompanyID: String!, $Category: String) {
  getApprovalPolicyAssignment(CompanyID: $CompanyID, Category: $Category) {
    ApprovalPolicyAssignmentID
    Category
    modTs
    createdTs
    ApprovalList
    createdTs
    CompanyID
  }
}
    `;

/**
 * __useGetApprovalPolicyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetApprovalPolicyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
      }
export function useGetApprovalPolicyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
        }
export type GetApprovalPolicyAssignmentQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentQuery>;
export type GetApprovalPolicyAssignmentLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentLazyQuery>;
export type GetApprovalPolicyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>;
export const GetAllApprovalPolicyAssignmentDocument = gql`
    query getAllApprovalPolicyAssignment($CompanyID: String!, $Category: String) {
  getAllApprovalPolicyAssignment(CompanyID: $CompanyID, Category: $Category) {
    ApprovalPolicyAssignmentID
    Category
    modTs
    createdTs
    ApprovalList
    createdTs
    CompanyID
  }
}
    `;

/**
 * __useGetAllApprovalPolicyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAllApprovalPolicyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApprovalPolicyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApprovalPolicyAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetAllApprovalPolicyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllApprovalPolicyAssignmentQuery, GetAllApprovalPolicyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllApprovalPolicyAssignmentQuery, GetAllApprovalPolicyAssignmentQueryVariables>(GetAllApprovalPolicyAssignmentDocument, baseOptions);
      }
export function useGetAllApprovalPolicyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllApprovalPolicyAssignmentQuery, GetAllApprovalPolicyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllApprovalPolicyAssignmentQuery, GetAllApprovalPolicyAssignmentQueryVariables>(GetAllApprovalPolicyAssignmentDocument, baseOptions);
        }
export type GetAllApprovalPolicyAssignmentQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyAssignmentQuery>;
export type GetAllApprovalPolicyAssignmentLazyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyAssignmentLazyQuery>;
export type GetAllApprovalPolicyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetAllApprovalPolicyAssignmentQuery, GetAllApprovalPolicyAssignmentQueryVariables>;
export const CreateApprovalPolicyAssignmentDocument = gql`
    mutation createApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  createApprovalPolicyAssignment(input: $input) {
    ApprovalPolicyAssignmentID
  }
}
    `;
export type CreateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useCreateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyAssignmentMutation, { data, loading, error }] = useCreateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>(CreateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type CreateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useCreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;
export const UpdateApprovalPolicyAssignmentDocument = gql`
    mutation updateApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  updateApprovalPolicyAssignment(input: $input) {
    ApprovalPolicyAssignmentID
  }
}
    `;
export type UpdateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useUpdateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyAssignmentMutation, { data, loading, error }] = useUpdateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>(UpdateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type UpdateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;
export const DeleteApprovalPolicyAssignmentDocument = gql`
    mutation deleteApprovalPolicyAssignment($input: ApprovalPolicyAssignmentDeleteInput!) {
  deleteApprovalPolicyAssignment(input: $input)
}
    `;
export type DeleteApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;

/**
 * __useDeleteApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyAssignmentMutation, { data, loading, error }] = useDeleteApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>(DeleteApprovalPolicyAssignmentDocument, baseOptions);
      }
export type DeleteApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;
export const GetBankAccountDocument = gql`
    query getBankAccount($BankAccountID: String, $CompanyID: String, $RecordStatus: RecordStatus) {
  getBankAccount(BankAccountID: $BankAccountID, CompanyID: $CompanyID, RecordStatus: $RecordStatus) {
    BankAccountID
    BankProfileID
    Code
    AccountNo
    Type
    MasterCOAID
    CostCentreID
    SWIFTCode
    EffectiveDate
    CurrencyID
    CompanyID
    RecordStatus
    BankProfile {
      BankProfileID
      Name
      Address
    }
    Company {
      CompanyID
      Name
    }
    Currency {
      ID
      name
    }
    Payment {
      PaymentID
    }
    Receipt {
      ReceiptID
    }
    BankTransfer {
      BankTransferID
    }
    MasterCOA {
      Name
      Code
    }
    CostCentre {
      Code
      IsDefault
    }
  }
}
    `;

/**
 * __useGetBankAccountQuery__
 *
 * To run a query within a React component, call `useGetBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountQuery({
 *   variables: {
 *      BankAccountID: // value for 'BankAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetBankAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
      }
export function useGetBankAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
        }
export type GetBankAccountQueryHookResult = ReturnType<typeof useGetBankAccountQuery>;
export type GetBankAccountLazyQueryHookResult = ReturnType<typeof useGetBankAccountLazyQuery>;
export type GetBankAccountQueryResult = ApolloReactCommon.QueryResult<GetBankAccountQuery, GetBankAccountQueryVariables>;
export const GetBankAccountListingDocument = gql`
    query getBankAccountListing($BankAccountID: String, $CompanyID: String, $RecordStatus: RecordStatus, $skip: Float, $take: Float, $searchValue: String) {
  getBankAccount(BankAccountID: $BankAccountID, CompanyID: $CompanyID, RecordStatus: $RecordStatus, skip: $skip, take: $take, searchValue: $searchValue) {
    BankAccountID
    BankProfileID
    Code
    AccountNo
    Type
    MasterCOAID
    CostCentreID
    SWIFTCode
    EffectiveDate
    CurrencyID
    CompanyID
    RecordStatus
    BankProfile {
      BankProfileID
      Name
      Address
    }
    Company {
      CompanyID
      Name
    }
    Currency {
      ID
      name
    }
    Payment {
      PaymentID
    }
    Receipt {
      ReceiptID
    }
    BankTransfer {
      BankTransferID
    }
    MasterCOA {
      Name
      Code
    }
    CostCentre {
      Code
    }
    BankBalance
  }
  getBankAccountCount(BankAccountID: $BankAccountID, CompanyID: $CompanyID, RecordStatus: $RecordStatus)
}
    `;

/**
 * __useGetBankAccountListingQuery__
 *
 * To run a query within a React component, call `useGetBankAccountListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountListingQuery({
 *   variables: {
 *      BankAccountID: // value for 'BankAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      RecordStatus: // value for 'RecordStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetBankAccountListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountListingQuery, GetBankAccountListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountListingQuery, GetBankAccountListingQueryVariables>(GetBankAccountListingDocument, baseOptions);
      }
export function useGetBankAccountListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountListingQuery, GetBankAccountListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountListingQuery, GetBankAccountListingQueryVariables>(GetBankAccountListingDocument, baseOptions);
        }
export type GetBankAccountListingQueryHookResult = ReturnType<typeof useGetBankAccountListingQuery>;
export type GetBankAccountListingLazyQueryHookResult = ReturnType<typeof useGetBankAccountListingLazyQuery>;
export type GetBankAccountListingQueryResult = ApolloReactCommon.QueryResult<GetBankAccountListingQuery, GetBankAccountListingQueryVariables>;
export const GetBankAccountProfileListingDocument = gql`
    query getBankAccountProfileListing($BankAccountID: String, $CompanyID: String!, $RecordStatus: RecordStatus, $skip: Float, $take: Float, $searchValue: String) {
  getBankAccountProfileListing(BankAccountID: $BankAccountID, CompanyID: $CompanyID, RecordStatus: $RecordStatus, skip: $skip, take: $take, searchValue: $searchValue) {
    Data {
      BankAccountID
      BankProfileID
      Code
      AccountNo
      Type
      MasterCOAID
      CostCentreID
      SWIFTCode
      EffectiveDate
      CurrencyID
      CompanyID
      RecordStatus
      BankProfile {
        BankProfileID
        Name
        Address
      }
      Company {
        CompanyID
        Name
      }
      Currency {
        ID
        name
      }
      Payment {
        PaymentID
      }
      Receipt {
        ReceiptID
      }
      BankTransfer {
        BankTransferID
      }
      MasterCOA {
        Name
        Code
      }
      BankBalance
    }
    TotalLength
  }
}
    `;

/**
 * __useGetBankAccountProfileListingQuery__
 *
 * To run a query within a React component, call `useGetBankAccountProfileListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountProfileListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountProfileListingQuery({
 *   variables: {
 *      BankAccountID: // value for 'BankAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      RecordStatus: // value for 'RecordStatus'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetBankAccountProfileListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountProfileListingQuery, GetBankAccountProfileListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountProfileListingQuery, GetBankAccountProfileListingQueryVariables>(GetBankAccountProfileListingDocument, baseOptions);
      }
export function useGetBankAccountProfileListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountProfileListingQuery, GetBankAccountProfileListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountProfileListingQuery, GetBankAccountProfileListingQueryVariables>(GetBankAccountProfileListingDocument, baseOptions);
        }
export type GetBankAccountProfileListingQueryHookResult = ReturnType<typeof useGetBankAccountProfileListingQuery>;
export type GetBankAccountProfileListingLazyQueryHookResult = ReturnType<typeof useGetBankAccountProfileListingLazyQuery>;
export type GetBankAccountProfileListingQueryResult = ApolloReactCommon.QueryResult<GetBankAccountProfileListingQuery, GetBankAccountProfileListingQueryVariables>;
export const GetBankAccountCodeDocument = gql`
    query getBankAccountCode($BankAccountID: String, $CompanyID: String) {
  getBankAccount(BankAccountID: $BankAccountID, CompanyID: $CompanyID) {
    BankAccountID
    BankProfileID
    Code
    CompanyID
  }
}
    `;

/**
 * __useGetBankAccountCodeQuery__
 *
 * To run a query within a React component, call `useGetBankAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountCodeQuery({
 *   variables: {
 *      BankAccountID: // value for 'BankAccountID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetBankAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountCodeQuery, GetBankAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountCodeQuery, GetBankAccountCodeQueryVariables>(GetBankAccountCodeDocument, baseOptions);
      }
export function useGetBankAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountCodeQuery, GetBankAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountCodeQuery, GetBankAccountCodeQueryVariables>(GetBankAccountCodeDocument, baseOptions);
        }
export type GetBankAccountCodeQueryHookResult = ReturnType<typeof useGetBankAccountCodeQuery>;
export type GetBankAccountCodeLazyQueryHookResult = ReturnType<typeof useGetBankAccountCodeLazyQuery>;
export type GetBankAccountCodeQueryResult = ApolloReactCommon.QueryResult<GetBankAccountCodeQuery, GetBankAccountCodeQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($input: BankAccountInput!) {
  createBankAccount(input: $input) {
    BankAccountID
    BankProfileID
    Code
    AccountNo
    SWIFTCode
    EffectiveDate
    CompanyID
    MasterCOAID
    CostCentreID
  }
}
    `;
export type CreateBankAccountMutationFn = ApolloReactCommon.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = ApolloReactCommon.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const UpdateBankAccountDocument = gql`
    mutation updateBankAccount($input: BankAccountInput!) {
  updateBankAccount(input: $input) {
    BankAccountID
  }
}
    `;
export type UpdateBankAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(UpdateBankAccountDocument, baseOptions);
      }
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = ApolloReactCommon.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;
export const CancelBankAccountDocument = gql`
    mutation cancelBankAccount($input: BankAccountCancellationInput!, $BankAccountID: String!) {
  cancelBankAccount(input: $input, BankAccountID: $BankAccountID)
}
    `;
export type CancelBankAccountMutationFn = ApolloReactCommon.MutationFunction<CancelBankAccountMutation, CancelBankAccountMutationVariables>;

/**
 * __useCancelBankAccountMutation__
 *
 * To run a mutation, you first call `useCancelBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBankAccountMutation, { data, loading, error }] = useCancelBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useCancelBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBankAccountMutation, CancelBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelBankAccountMutation, CancelBankAccountMutationVariables>(CancelBankAccountDocument, baseOptions);
      }
export type CancelBankAccountMutationHookResult = ReturnType<typeof useCancelBankAccountMutation>;
export type CancelBankAccountMutationResult = ApolloReactCommon.MutationResult<CancelBankAccountMutation>;
export type CancelBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelBankAccountMutation, CancelBankAccountMutationVariables>;
export const ActivateBankAccountDocument = gql`
    mutation activateBankAccount($BankAccountID: String!) {
  activateBankAccount(BankAccountID: $BankAccountID)
}
    `;
export type ActivateBankAccountMutationFn = ApolloReactCommon.MutationFunction<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>;

/**
 * __useActivateBankAccountMutation__
 *
 * To run a mutation, you first call `useActivateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBankAccountMutation, { data, loading, error }] = useActivateBankAccountMutation({
 *   variables: {
 *      BankAccountID: // value for 'BankAccountID'
 *   },
 * });
 */
export function useActivateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>(ActivateBankAccountDocument, baseOptions);
      }
export type ActivateBankAccountMutationHookResult = ReturnType<typeof useActivateBankAccountMutation>;
export type ActivateBankAccountMutationResult = ApolloReactCommon.MutationResult<ActivateBankAccountMutation>;
export type ActivateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>;
export const DeleteBankAccountDocument = gql`
    mutation deleteBankAccount($input: BankAccountDeleteInput!) {
  deleteBankAccount(input: $input)
}
    `;
export type DeleteBankAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(DeleteBankAccountDocument, baseOptions);
      }
export type DeleteBankAccountMutationHookResult = ReturnType<typeof useDeleteBankAccountMutation>;
export type DeleteBankAccountMutationResult = ApolloReactCommon.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;
export const GetCheckingValidationBaDocument = gql`
    query getCheckingValidationBA($BAImportInput: [BAImportInput!]!, $CompanyID: String!) {
  getCheckingValidationBA(BAImportInput: $BAImportInput, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCheckingValidationBaQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationBaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationBaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationBaQuery({
 *   variables: {
 *      BAImportInput: // value for 'BAImportInput'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCheckingValidationBaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationBaQuery, GetCheckingValidationBaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationBaQuery, GetCheckingValidationBaQueryVariables>(GetCheckingValidationBaDocument, baseOptions);
      }
export function useGetCheckingValidationBaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationBaQuery, GetCheckingValidationBaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationBaQuery, GetCheckingValidationBaQueryVariables>(GetCheckingValidationBaDocument, baseOptions);
        }
export type GetCheckingValidationBaQueryHookResult = ReturnType<typeof useGetCheckingValidationBaQuery>;
export type GetCheckingValidationBaLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationBaLazyQuery>;
export type GetCheckingValidationBaQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationBaQuery, GetCheckingValidationBaQueryVariables>;
export const CreateBankAccountImportDocument = gql`
    mutation createBankAccountImport($TemplateStr: String!, $CompanyID: String!) {
  createBankAccountImport(TemplateStr: $TemplateStr, CompanyID: $CompanyID)
}
    `;
export type CreateBankAccountImportMutationFn = ApolloReactCommon.MutationFunction<CreateBankAccountImportMutation, CreateBankAccountImportMutationVariables>;

/**
 * __useCreateBankAccountImportMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountImportMutation, { data, loading, error }] = useCreateBankAccountImportMutation({
 *   variables: {
 *      TemplateStr: // value for 'TemplateStr'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCreateBankAccountImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankAccountImportMutation, CreateBankAccountImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankAccountImportMutation, CreateBankAccountImportMutationVariables>(CreateBankAccountImportDocument, baseOptions);
      }
export type CreateBankAccountImportMutationHookResult = ReturnType<typeof useCreateBankAccountImportMutation>;
export type CreateBankAccountImportMutationResult = ApolloReactCommon.MutationResult<CreateBankAccountImportMutation>;
export type CreateBankAccountImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankAccountImportMutation, CreateBankAccountImportMutationVariables>;
export const GetLocalBankDocument = gql`
    query getLocalBank($ID: String, $code: String, $orderByAsc: String, $orderByDesc: String) {
  getLocalBank(ID: $ID, code: $code, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    name
    code
    address {
      city
      district
      state
      postCode
      country
    }
  }
}
    `;

/**
 * __useGetLocalBankQuery__
 *
 * To run a query within a React component, call `useGetLocalBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalBankQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      code: // value for 'code'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetLocalBankQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalBankQuery, GetLocalBankQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocalBankQuery, GetLocalBankQueryVariables>(GetLocalBankDocument, baseOptions);
      }
export function useGetLocalBankLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalBankQuery, GetLocalBankQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocalBankQuery, GetLocalBankQueryVariables>(GetLocalBankDocument, baseOptions);
        }
export type GetLocalBankQueryHookResult = ReturnType<typeof useGetLocalBankQuery>;
export type GetLocalBankLazyQueryHookResult = ReturnType<typeof useGetLocalBankLazyQuery>;
export type GetLocalBankQueryResult = ApolloReactCommon.QueryResult<GetLocalBankQuery, GetLocalBankQueryVariables>;
export const GetCoaStructureDocument = gql`
    query getCOAStructure($AccountID: String) {
  getCOAStructure(AccountID: $AccountID) {
    COAStructureID
    Level
    CodeLength
  }
}
    `;

/**
 * __useGetCoaStructureQuery__
 *
 * To run a query within a React component, call `useGetCoaStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoaStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoaStructureQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetCoaStructureQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoaStructureQuery, GetCoaStructureQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCoaStructureQuery, GetCoaStructureQueryVariables>(GetCoaStructureDocument, baseOptions);
      }
export function useGetCoaStructureLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoaStructureQuery, GetCoaStructureQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCoaStructureQuery, GetCoaStructureQueryVariables>(GetCoaStructureDocument, baseOptions);
        }
export type GetCoaStructureQueryHookResult = ReturnType<typeof useGetCoaStructureQuery>;
export type GetCoaStructureLazyQueryHookResult = ReturnType<typeof useGetCoaStructureLazyQuery>;
export type GetCoaStructureQueryResult = ApolloReactCommon.QueryResult<GetCoaStructureQuery, GetCoaStructureQueryVariables>;
export const CreateCoaStructureDocument = gql`
    mutation createCOAStructure($COAStructureInput: [COAStructureInput!]!) {
  createCOAStructure(COAStructureInput: $COAStructureInput)
}
    `;
export type CreateCoaStructureMutationFn = ApolloReactCommon.MutationFunction<CreateCoaStructureMutation, CreateCoaStructureMutationVariables>;

/**
 * __useCreateCoaStructureMutation__
 *
 * To run a mutation, you first call `useCreateCoaStructureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoaStructureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoaStructureMutation, { data, loading, error }] = useCreateCoaStructureMutation({
 *   variables: {
 *      COAStructureInput: // value for 'COAStructureInput'
 *   },
 * });
 */
export function useCreateCoaStructureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCoaStructureMutation, CreateCoaStructureMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCoaStructureMutation, CreateCoaStructureMutationVariables>(CreateCoaStructureDocument, baseOptions);
      }
export type CreateCoaStructureMutationHookResult = ReturnType<typeof useCreateCoaStructureMutation>;
export type CreateCoaStructureMutationResult = ApolloReactCommon.MutationResult<CreateCoaStructureMutation>;
export type CreateCoaStructureMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCoaStructureMutation, CreateCoaStructureMutationVariables>;
export const GetCompanyDocument = gql`
    query getCompany($CompanyID: String, $ParentCompanyID: String, $orderByAsc: String, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, ParentCompanyID: $ParentCompanyID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    CompanyID
    ParentCompanyID
    Name
    CompanyRegNo
    CompanyTax
    Code
    SSTNo
    TourismTaxNo
    GSTNo
    GSTExpiryDate
    GroupGSTRegNo
    Address {
      ID
      address
      address2
      siteName
      country
      city
      district
      state
      postCode
      postCodeName
      AddressType
    }
    ContactNo
    Email
    BaseCurrencyID
    RecordStatus
    Currency {
      ID
      name
      code
    }
    EntityCOA {
      MasterCOAID
      MasterCOA {
        Code
        Name
        IsLastNode
        AccountType
      }
    }
    ParentCompany {
      CompanyID
      Name
    }
    Logo
    Tin
    eSign
    eSignExpiryDate
    eInvIntegrated
    eInvStartDate
    eInvEndDate
    eInvClientID
    eInvSecretKey
    eInvSecretKey2
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ParentCompanyID: // value for 'ParentCompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyListingDocument = gql`
    query getCompanyListing($RecordStatus: RecordStatus, $searchValue: String, $skip: Float, $take: Float) {
  getCompanyListing(RecordStatus: $RecordStatus, searchValue: $searchValue, skip: $skip, take: $take) {
    Data {
      CompanyID
      ParentCompanyID
      Name
      CompanyRegNo
      CompanyTax
      Code
      SSTNo
      TourismTaxNo
      GSTNo
      GSTExpiryDate
      Address {
        ID
        address
        address2
        siteName
        country
        city
        district
        state
        postCode
        postCodeName
        AddressType
      }
      ContactNo
      Email
      BaseCurrencyID
      RecordStatus
      Currency {
        ID
        name
        code
      }
      EntityCOA {
        MasterCOAID
        MasterCOA {
          Code
          Name
          IsLastNode
          AccountType
        }
      }
      ParentCompany {
        CompanyID
        Name
      }
      Logo
    }
    TotalLength
  }
}
    `;

/**
 * __useGetCompanyListingQuery__
 *
 * To run a query within a React component, call `useGetCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListingQuery({
 *   variables: {
 *      RecordStatus: // value for 'RecordStatus'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListingQuery, GetCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListingQuery, GetCompanyListingQueryVariables>(GetCompanyListingDocument, baseOptions);
      }
export function useGetCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListingQuery, GetCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListingQuery, GetCompanyListingQueryVariables>(GetCompanyListingDocument, baseOptions);
        }
export type GetCompanyListingQueryHookResult = ReturnType<typeof useGetCompanyListingQuery>;
export type GetCompanyListingLazyQueryHookResult = ReturnType<typeof useGetCompanyListingLazyQuery>;
export type GetCompanyListingQueryResult = ApolloReactCommon.QueryResult<GetCompanyListingQuery, GetCompanyListingQueryVariables>;
export const GetParentCompanyDocument = gql`
    query getParentCompany {
  getParentCompany {
    CompanyID
    Name
  }
}
    `;

/**
 * __useGetParentCompanyQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentCompanyQuery, GetParentCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentCompanyQuery, GetParentCompanyQueryVariables>(GetParentCompanyDocument, baseOptions);
      }
export function useGetParentCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentCompanyQuery, GetParentCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentCompanyQuery, GetParentCompanyQueryVariables>(GetParentCompanyDocument, baseOptions);
        }
export type GetParentCompanyQueryHookResult = ReturnType<typeof useGetParentCompanyQuery>;
export type GetParentCompanyLazyQueryHookResult = ReturnType<typeof useGetParentCompanyLazyQuery>;
export type GetParentCompanyQueryResult = ApolloReactCommon.QueryResult<GetParentCompanyQuery, GetParentCompanyQueryVariables>;
export const GetCompanyNameDocument = gql`
    query getCompanyName($CompanyID: String, $orderByAsc: String, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    CompanyID
    Name
    Logo
    DefaultCostCentre {
      CostCentreID
      Name
      Code
    }
    CompanyTax
    SSTNo
    GSTNo
    eSign
    eSignExpiryDate
    eInvIntegrated
    eInvStartDate
    eInvEndDate
    EInvoiceStarted
    eInvClientID
    eInvSecretKey
    eInvSecretKey2
    GLCompanyPolicy {
      DefaultDepartmentBalanceSheetCOA
    }
  }
}
    `;

/**
 * __useGetCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanyNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
      }
export function useGetCompanyNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
        }
export type GetCompanyNameQueryHookResult = ReturnType<typeof useGetCompanyNameQuery>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<typeof useGetCompanyNameLazyQuery>;
export type GetCompanyNameQueryResult = ApolloReactCommon.QueryResult<GetCompanyNameQuery, GetCompanyNameQueryVariables>;
export const GetCompanySubmenuDocument = gql`
    query getCompanySubmenu($CompanyID: String, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, RecordStatus: $RecordStatus) {
    CompanyID
    Name
    Logo
    EInvoiceStarted
    eInvIntegrated
  }
}
    `;

/**
 * __useGetCompanySubmenuQuery__
 *
 * To run a query within a React component, call `useGetCompanySubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySubmenuQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanySubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>(GetCompanySubmenuDocument, baseOptions);
      }
export function useGetCompanySubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>(GetCompanySubmenuDocument, baseOptions);
        }
export type GetCompanySubmenuQueryHookResult = ReturnType<typeof useGetCompanySubmenuQuery>;
export type GetCompanySubmenuLazyQueryHookResult = ReturnType<typeof useGetCompanySubmenuLazyQuery>;
export type GetCompanySubmenuQueryResult = ApolloReactCommon.QueryResult<GetCompanySubmenuQuery, GetCompanySubmenuQueryVariables>;
export const GetEInvoiceCompanyStartDocument = gql`
    query getEInvoiceCompanyStart($CompanyID: String, $orderByAsc: String, $RecordStatus: RecordStatus, $DocDate: DateTime) {
  getCompany(CompanyID: $CompanyID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    CompanyID
    Name
    eInvStartDate
    eInvEndDate
    eInvIntegrated
    EInvoiceStarted(DocDate: $DocDate)
  }
}
    `;

/**
 * __useGetEInvoiceCompanyStartQuery__
 *
 * To run a query within a React component, call `useGetEInvoiceCompanyStartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEInvoiceCompanyStartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEInvoiceCompanyStartQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *      DocDate: // value for 'DocDate'
 *   },
 * });
 */
export function useGetEInvoiceCompanyStartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEInvoiceCompanyStartQuery, GetEInvoiceCompanyStartQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEInvoiceCompanyStartQuery, GetEInvoiceCompanyStartQueryVariables>(GetEInvoiceCompanyStartDocument, baseOptions);
      }
export function useGetEInvoiceCompanyStartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEInvoiceCompanyStartQuery, GetEInvoiceCompanyStartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEInvoiceCompanyStartQuery, GetEInvoiceCompanyStartQueryVariables>(GetEInvoiceCompanyStartDocument, baseOptions);
        }
export type GetEInvoiceCompanyStartQueryHookResult = ReturnType<typeof useGetEInvoiceCompanyStartQuery>;
export type GetEInvoiceCompanyStartLazyQueryHookResult = ReturnType<typeof useGetEInvoiceCompanyStartLazyQuery>;
export type GetEInvoiceCompanyStartQueryResult = ApolloReactCommon.QueryResult<GetEInvoiceCompanyStartQuery, GetEInvoiceCompanyStartQueryVariables>;
export const GetAssignedCompanyDocument = gql`
    query getAssignedCompany {
  getAssignedCompanyByUser
}
    `;

/**
 * __useGetAssignedCompanyQuery__
 *
 * To run a query within a React component, call `useGetAssignedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssignedCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>(GetAssignedCompanyDocument, baseOptions);
      }
export function useGetAssignedCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>(GetAssignedCompanyDocument, baseOptions);
        }
export type GetAssignedCompanyQueryHookResult = ReturnType<typeof useGetAssignedCompanyQuery>;
export type GetAssignedCompanyLazyQueryHookResult = ReturnType<typeof useGetAssignedCompanyLazyQuery>;
export type GetAssignedCompanyQueryResult = ApolloReactCommon.QueryResult<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>;
export const GetActiveCompanyCountDocument = gql`
    query getActiveCompanyCount {
  getActiveCompanyCount
}
    `;

/**
 * __useGetActiveCompanyCountQuery__
 *
 * To run a query within a React component, call `useGetActiveCompanyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCompanyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCompanyCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCompanyCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>(GetActiveCompanyCountDocument, baseOptions);
      }
export function useGetActiveCompanyCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>(GetActiveCompanyCountDocument, baseOptions);
        }
export type GetActiveCompanyCountQueryHookResult = ReturnType<typeof useGetActiveCompanyCountQuery>;
export type GetActiveCompanyCountLazyQueryHookResult = ReturnType<typeof useGetActiveCompanyCountLazyQuery>;
export type GetActiveCompanyCountQueryResult = ApolloReactCommon.QueryResult<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  createCompany(input: $input) {
    CompanyID
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: CompanyInput!) {
  updateCompany(input: $input)
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CancelCompanyDocument = gql`
    mutation cancelCompany($input: CompanyDeleteInput!) {
  cancelCompany(input: $input)
}
    `;
export type CancelCompanyMutationFn = ApolloReactCommon.MutationFunction<CancelCompanyMutation, CancelCompanyMutationVariables>;

/**
 * __useCancelCompanyMutation__
 *
 * To run a mutation, you first call `useCancelCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCompanyMutation, { data, loading, error }] = useCancelCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCompanyMutation, CancelCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCompanyMutation, CancelCompanyMutationVariables>(CancelCompanyDocument, baseOptions);
      }
export type CancelCompanyMutationHookResult = ReturnType<typeof useCancelCompanyMutation>;
export type CancelCompanyMutationResult = ApolloReactCommon.MutationResult<CancelCompanyMutation>;
export type CancelCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCompanyMutation, CancelCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($input: CompanyDeleteInput!) {
  deleteCompany(input: $input)
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DeleteCompanyProfileDocument = gql`
    mutation deleteCompanyProfile($CompanyID: String!) {
  deleteCompanyProfile(CompanyID: $CompanyID)
}
    `;
export type DeleteCompanyProfileMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyProfileMutation, DeleteCompanyProfileMutationVariables>;

/**
 * __useDeleteCompanyProfileMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyProfileMutation, { data, loading, error }] = useDeleteCompanyProfileMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDeleteCompanyProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyProfileMutation, DeleteCompanyProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyProfileMutation, DeleteCompanyProfileMutationVariables>(DeleteCompanyProfileDocument, baseOptions);
      }
export type DeleteCompanyProfileMutationHookResult = ReturnType<typeof useDeleteCompanyProfileMutation>;
export type DeleteCompanyProfileMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyProfileMutation>;
export type DeleteCompanyProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyProfileMutation, DeleteCompanyProfileMutationVariables>;
export const GetCheckingValidationCompanyDocument = gql`
    query getCheckingValidationCompany($CompanyImportInput: [CompanyImportInput!]!) {
  getCheckingValidationCompany(CompanyImportInput: $CompanyImportInput)
}
    `;

/**
 * __useGetCheckingValidationCompanyQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationCompanyQuery({
 *   variables: {
 *      CompanyImportInput: // value for 'CompanyImportInput'
 *   },
 * });
 */
export function useGetCheckingValidationCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationCompanyQuery, GetCheckingValidationCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationCompanyQuery, GetCheckingValidationCompanyQueryVariables>(GetCheckingValidationCompanyDocument, baseOptions);
      }
export function useGetCheckingValidationCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationCompanyQuery, GetCheckingValidationCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationCompanyQuery, GetCheckingValidationCompanyQueryVariables>(GetCheckingValidationCompanyDocument, baseOptions);
        }
export type GetCheckingValidationCompanyQueryHookResult = ReturnType<typeof useGetCheckingValidationCompanyQuery>;
export type GetCheckingValidationCompanyLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationCompanyLazyQuery>;
export type GetCheckingValidationCompanyQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationCompanyQuery, GetCheckingValidationCompanyQueryVariables>;
export const CreateCompanyImportDocument = gql`
    mutation createCompanyImport($TemplateStr: String!) {
  createCompanyImport(TemplateStr: $TemplateStr)
}
    `;
export type CreateCompanyImportMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyImportMutation, CreateCompanyImportMutationVariables>;

/**
 * __useCreateCompanyImportMutation__
 *
 * To run a mutation, you first call `useCreateCompanyImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyImportMutation, { data, loading, error }] = useCreateCompanyImportMutation({
 *   variables: {
 *      TemplateStr: // value for 'TemplateStr'
 *   },
 * });
 */
export function useCreateCompanyImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyImportMutation, CreateCompanyImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyImportMutation, CreateCompanyImportMutationVariables>(CreateCompanyImportDocument, baseOptions);
      }
export type CreateCompanyImportMutationHookResult = ReturnType<typeof useCreateCompanyImportMutation>;
export type CreateCompanyImportMutationResult = ApolloReactCommon.MutationResult<CreateCompanyImportMutation>;
export type CreateCompanyImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyImportMutation, CreateCompanyImportMutationVariables>;
export const GetEInvoiceCompanyStartDateDocument = gql`
    query getEInvoiceCompanyStartDate($CompanyID: String!) {
  getEInvoiceCompanyStartDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetEInvoiceCompanyStartDateQuery__
 *
 * To run a query within a React component, call `useGetEInvoiceCompanyStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEInvoiceCompanyStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEInvoiceCompanyStartDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetEInvoiceCompanyStartDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEInvoiceCompanyStartDateQuery, GetEInvoiceCompanyStartDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEInvoiceCompanyStartDateQuery, GetEInvoiceCompanyStartDateQueryVariables>(GetEInvoiceCompanyStartDateDocument, baseOptions);
      }
export function useGetEInvoiceCompanyStartDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEInvoiceCompanyStartDateQuery, GetEInvoiceCompanyStartDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEInvoiceCompanyStartDateQuery, GetEInvoiceCompanyStartDateQueryVariables>(GetEInvoiceCompanyStartDateDocument, baseOptions);
        }
export type GetEInvoiceCompanyStartDateQueryHookResult = ReturnType<typeof useGetEInvoiceCompanyStartDateQuery>;
export type GetEInvoiceCompanyStartDateLazyQueryHookResult = ReturnType<typeof useGetEInvoiceCompanyStartDateLazyQuery>;
export type GetEInvoiceCompanyStartDateQueryResult = ApolloReactCommon.QueryResult<GetEInvoiceCompanyStartDateQuery, GetEInvoiceCompanyStartDateQueryVariables>;
export const GetCompanyListDocument = gql`
    query getCompanyList {
  getCompany {
    Name
    CompanyID
  }
}
    `;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, baseOptions);
      }
export function useGetCompanyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, baseOptions);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = ApolloReactCommon.QueryResult<GetCompanyListQuery, GetCompanyListQueryVariables>;
export const GetEntityRoleUserAssignmentDocument = gql`
    query getEntityRoleUserAssignment($ID: String, $entityID: String) {
  getEntityRoleUserAssignment(ID: $ID, entityID: $entityID) {
    userID
    roleID
  }
}
    `;

/**
 * __useGetEntityRoleUserAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEntityRoleUserAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityRoleUserAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityRoleUserAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useGetEntityRoleUserAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
      }
export function useGetEntityRoleUserAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
        }
export type GetEntityRoleUserAssignmentQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentQuery>;
export type GetEntityRoleUserAssignmentLazyQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentLazyQuery>;
export type GetEntityRoleUserAssignmentQueryResult = ApolloReactCommon.QueryResult<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>;
export const GetUsersRoleCompanyDocument = gql`
    query getUsersRoleCompany($companyID: String!, $roleID: String!) {
  getUsersRoleCompany(companyID: $companyID, roleID: $roleID)
}
    `;

/**
 * __useGetUsersRoleCompanyQuery__
 *
 * To run a query within a React component, call `useGetUsersRoleCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersRoleCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersRoleCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useGetUsersRoleCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersRoleCompanyQuery, GetUsersRoleCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersRoleCompanyQuery, GetUsersRoleCompanyQueryVariables>(GetUsersRoleCompanyDocument, baseOptions);
      }
export function useGetUsersRoleCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersRoleCompanyQuery, GetUsersRoleCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersRoleCompanyQuery, GetUsersRoleCompanyQueryVariables>(GetUsersRoleCompanyDocument, baseOptions);
        }
export type GetUsersRoleCompanyQueryHookResult = ReturnType<typeof useGetUsersRoleCompanyQuery>;
export type GetUsersRoleCompanyLazyQueryHookResult = ReturnType<typeof useGetUsersRoleCompanyLazyQuery>;
export type GetUsersRoleCompanyQueryResult = ApolloReactCommon.QueryResult<GetUsersRoleCompanyQuery, GetUsersRoleCompanyQueryVariables>;
export const GetUserRoleListingDocument = gql`
    query getUserRoleListing {
  getRole {
    ID
    name
  }
  getUsersByAccountAndSoftware(superUserBool: NONSUPER, status: ACTIVE) {
    ID
    name
  }
}
    `;

/**
 * __useGetUserRoleListingQuery__
 *
 * To run a query within a React component, call `useGetUserRoleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>(GetUserRoleListingDocument, baseOptions);
      }
export function useGetUserRoleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>(GetUserRoleListingDocument, baseOptions);
        }
export type GetUserRoleListingQueryHookResult = ReturnType<typeof useGetUserRoleListingQuery>;
export type GetUserRoleListingLazyQueryHookResult = ReturnType<typeof useGetUserRoleListingLazyQuery>;
export type GetUserRoleListingQueryResult = ApolloReactCommon.QueryResult<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>;
export const GetCompanyListForRoleAuthDocument = gql`
    query getCompanyListForRoleAuth($ID: String) {
  getCompany {
    Name
    CompanyID
  }
  getEntityRoleUserAssignment(entityID: $ID) {
    entityID
    userID
    roleID
  }
}
    `;

/**
 * __useGetCompanyListForRoleAuthQuery__
 *
 * To run a query within a React component, call `useGetCompanyListForRoleAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListForRoleAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListForRoleAuthQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCompanyListForRoleAuthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyListForRoleAuthQuery, GetCompanyListForRoleAuthQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyListForRoleAuthQuery, GetCompanyListForRoleAuthQueryVariables>(GetCompanyListForRoleAuthDocument, baseOptions);
      }
export function useGetCompanyListForRoleAuthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyListForRoleAuthQuery, GetCompanyListForRoleAuthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyListForRoleAuthQuery, GetCompanyListForRoleAuthQueryVariables>(GetCompanyListForRoleAuthDocument, baseOptions);
        }
export type GetCompanyListForRoleAuthQueryHookResult = ReturnType<typeof useGetCompanyListForRoleAuthQuery>;
export type GetCompanyListForRoleAuthLazyQueryHookResult = ReturnType<typeof useGetCompanyListForRoleAuthLazyQuery>;
export type GetCompanyListForRoleAuthQueryResult = ApolloReactCommon.QueryResult<GetCompanyListForRoleAuthQuery, GetCompanyListForRoleAuthQueryVariables>;
export const GetSubmitterNameDocument = gql`
    query getSubmitterName($ID: String!) {
  getUser(ID: $ID) {
    name
  }
}
    `;

/**
 * __useGetSubmitterNameQuery__
 *
 * To run a query within a React component, call `useGetSubmitterNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmitterNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmitterNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSubmitterNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubmitterNameQuery, GetSubmitterNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubmitterNameQuery, GetSubmitterNameQueryVariables>(GetSubmitterNameDocument, baseOptions);
      }
export function useGetSubmitterNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubmitterNameQuery, GetSubmitterNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubmitterNameQuery, GetSubmitterNameQueryVariables>(GetSubmitterNameDocument, baseOptions);
        }
export type GetSubmitterNameQueryHookResult = ReturnType<typeof useGetSubmitterNameQuery>;
export type GetSubmitterNameLazyQueryHookResult = ReturnType<typeof useGetSubmitterNameLazyQuery>;
export type GetSubmitterNameQueryResult = ApolloReactCommon.QueryResult<GetSubmitterNameQuery, GetSubmitterNameQueryVariables>;
export const GetCompanySummaryDocument = gql`
    query getCompanySummary($CompanyID: String) {
  getCompany(CompanyID: $CompanyID) {
    CompanyID
    Name
    EInvoiceStarted
    eInvIntegrated
  }
}
    `;

/**
 * __useGetCompanySummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>(GetCompanySummaryDocument, baseOptions);
      }
export function useGetCompanySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>(GetCompanySummaryDocument, baseOptions);
        }
export type GetCompanySummaryQueryHookResult = ReturnType<typeof useGetCompanySummaryQuery>;
export type GetCompanySummaryLazyQueryHookResult = ReturnType<typeof useGetCompanySummaryLazyQuery>;
export type GetCompanySummaryQueryResult = ApolloReactCommon.QueryResult<GetCompanySummaryQuery, GetCompanySummaryQueryVariables>;
export const GetCompanyExecutiveSummaryDocument = gql`
    query getCompanyExecutiveSummary($CompanyID: String, $Category: [String!]) {
  CostCentreDate(CompanyID: $CompanyID)
  CostCentreDefinitionDate
  EntityCOADate(CompanyID: $CompanyID)
  AccountPeriodDate(CompanyID: $CompanyID)
  JournalTypeDate(CompanyID: $CompanyID)
  BudgetDate(CompanyID: $CompanyID)
  BankAccountDate(CompanyID: $CompanyID)
  CompanySumDate(CompanyID: $CompanyID)
  NumberingStructureGeneralLedgerDate(CompanyID: $CompanyID)
  NumberingStructureCashBookDate(CompanyID: $CompanyID)
  NumberingStructureARDate(CompanyID: $CompanyID)
  NumberingStructureAPDate(CompanyID: $CompanyID)
  NumberingStructureICDate(CompanyID: $CompanyID)
  ApprovalPolicyAssignmentDate(CompanyID: $CompanyID, Category: $Category)
  CompanyAuthorizationDate
  BankAccountDate(CompanyID: $CompanyID)
  OriginalBudgetApprovalPolicyAssignmentDate(CompanyID: $CompanyID)
  BudgetRevisionApprovalPolicyAssignmentDate(CompanyID: $CompanyID)
  RecurringJournalApprovalPolicyAssignmentDate(CompanyID: $CompanyID)
  JournalProcessingApprovalPolicyAssignmentDate(CompanyID: $CompanyID)
  AuditAdjustmentApprovalPolicyAssignmentDate(CompanyID: $CompanyID)
  BankTransferStandardApprovalFlowDate(CompanyID: $CompanyID)
  ReceiptStandardApprovalFlowDate(CompanyID: $CompanyID)
  PaymentStandardApprovalFlowDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCompanyExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanyExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyExecutiveSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetCompanyExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyExecutiveSummaryQuery, GetCompanyExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyExecutiveSummaryQuery, GetCompanyExecutiveSummaryQueryVariables>(GetCompanyExecutiveSummaryDocument, baseOptions);
      }
export function useGetCompanyExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyExecutiveSummaryQuery, GetCompanyExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyExecutiveSummaryQuery, GetCompanyExecutiveSummaryQueryVariables>(GetCompanyExecutiveSummaryDocument, baseOptions);
        }
export type GetCompanyExecutiveSummaryQueryHookResult = ReturnType<typeof useGetCompanyExecutiveSummaryQuery>;
export type GetCompanyExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetCompanyExecutiveSummaryLazyQuery>;
export type GetCompanyExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetCompanyExecutiveSummaryQuery, GetCompanyExecutiveSummaryQueryVariables>;
export const GetCostCentreDocument = gql`
    query getCostCentre($CompanyID: String, $orderByAsc: String, $IsLastNode: Boolean, $MasterCOAID: String, $CostCentreID: String) {
  getCostCentre(CompanyID: $CompanyID, orderByAsc: $orderByAsc, MasterCOAID: $MasterCOAID, IsLastNode: $IsLastNode, CostCentreID: $CostCentreID) {
    CostCentreID
    Name
    CompanyID
    ParentCostCentreID
    Level
    Code
    MasterCode
    IsLastNode
    IsDefault
    Company {
      CompanyID
      Name
    }
    FavoriteJournalProcessingItem {
      FavoriteJournalProcessingItemID
    }
    JournalItem {
      JournalItemID
    }
    BudgetItemCostCentre {
      BudgetItemCostCentreID
    }
    JournalProcessingItem {
      JournalProcessingItemID
    }
    UsedDepartment
  }
}
    `;

/**
 * __useGetCostCentreQuery__
 *
 * To run a query within a React component, call `useGetCostCentreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsLastNode: // value for 'IsLastNode'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      CostCentreID: // value for 'CostCentreID'
 *   },
 * });
 */
export function useGetCostCentreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreQuery, GetCostCentreQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreQuery, GetCostCentreQueryVariables>(GetCostCentreDocument, baseOptions);
      }
export function useGetCostCentreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreQuery, GetCostCentreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreQuery, GetCostCentreQueryVariables>(GetCostCentreDocument, baseOptions);
        }
export type GetCostCentreQueryHookResult = ReturnType<typeof useGetCostCentreQuery>;
export type GetCostCentreLazyQueryHookResult = ReturnType<typeof useGetCostCentreLazyQuery>;
export type GetCostCentreQueryResult = ApolloReactCommon.QueryResult<GetCostCentreQuery, GetCostCentreQueryVariables>;
export const GetCostCentreCodeDocument = gql`
    query getCostCentreCode($CompanyID: String, $IsLastNode: Boolean, $MasterCOAID: String, $orderByAsc: String) {
  getCostCentre(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, IsLastNode: $IsLastNode, orderByAsc: $orderByAsc) {
    CostCentreID
    Name
    Code
    MasterCode
    IsDefault
  }
}
    `;

/**
 * __useGetCostCentreCodeQuery__
 *
 * To run a query within a React component, call `useGetCostCentreCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      IsLastNode: // value for 'IsLastNode'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCostCentreCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>(GetCostCentreCodeDocument, baseOptions);
      }
export function useGetCostCentreCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>(GetCostCentreCodeDocument, baseOptions);
        }
export type GetCostCentreCodeQueryHookResult = ReturnType<typeof useGetCostCentreCodeQuery>;
export type GetCostCentreCodeLazyQueryHookResult = ReturnType<typeof useGetCostCentreCodeLazyQuery>;
export type GetCostCentreCodeQueryResult = ApolloReactCommon.QueryResult<GetCostCentreCodeQuery, GetCostCentreCodeQueryVariables>;
export const GetCostCentreListingDocument = gql`
    query getCostCentreListing($CompanyID: String!) {
  getCostCentreListing(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCostCentreListingQuery__
 *
 * To run a query within a React component, call `useGetCostCentreListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>(GetCostCentreListingDocument, baseOptions);
      }
export function useGetCostCentreListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>(GetCostCentreListingDocument, baseOptions);
        }
export type GetCostCentreListingQueryHookResult = ReturnType<typeof useGetCostCentreListingQuery>;
export type GetCostCentreListingLazyQueryHookResult = ReturnType<typeof useGetCostCentreListingLazyQuery>;
export type GetCostCentreListingQueryResult = ApolloReactCommon.QueryResult<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>;
export const CreateCostCentreDocument = gql`
    mutation createCostCentre($input: CostCentreInput!) {
  createCostCentre(input: $input)
}
    `;
export type CreateCostCentreMutationFn = ApolloReactCommon.MutationFunction<CreateCostCentreMutation, CreateCostCentreMutationVariables>;

/**
 * __useCreateCostCentreMutation__
 *
 * To run a mutation, you first call `useCreateCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCentreMutation, { data, loading, error }] = useCreateCostCentreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCentreMutation, CreateCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCentreMutation, CreateCostCentreMutationVariables>(CreateCostCentreDocument, baseOptions);
      }
export type CreateCostCentreMutationHookResult = ReturnType<typeof useCreateCostCentreMutation>;
export type CreateCostCentreMutationResult = ApolloReactCommon.MutationResult<CreateCostCentreMutation>;
export type CreateCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCentreMutation, CreateCostCentreMutationVariables>;
export const UpdateCostCentreDocument = gql`
    mutation updateCostCentre($input: CostCentreInput!) {
  updateCostCentre(input: $input) {
    CostCentreID
  }
}
    `;
export type UpdateCostCentreMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCentreMutation, UpdateCostCentreMutationVariables>;

/**
 * __useUpdateCostCentreMutation__
 *
 * To run a mutation, you first call `useUpdateCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCentreMutation, { data, loading, error }] = useUpdateCostCentreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCentreMutation, UpdateCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCentreMutation, UpdateCostCentreMutationVariables>(UpdateCostCentreDocument, baseOptions);
      }
export type UpdateCostCentreMutationHookResult = ReturnType<typeof useUpdateCostCentreMutation>;
export type UpdateCostCentreMutationResult = ApolloReactCommon.MutationResult<UpdateCostCentreMutation>;
export type UpdateCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCentreMutation, UpdateCostCentreMutationVariables>;
export const CancelCostCentreDocument = gql`
    mutation cancelCostCentre($input: CostCentreDeleteInput!) {
  cancelCostCentre(input: $input)
}
    `;
export type CancelCostCentreMutationFn = ApolloReactCommon.MutationFunction<CancelCostCentreMutation, CancelCostCentreMutationVariables>;

/**
 * __useCancelCostCentreMutation__
 *
 * To run a mutation, you first call `useCancelCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostCentreMutation, { data, loading, error }] = useCancelCostCentreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostCentreMutation, CancelCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostCentreMutation, CancelCostCentreMutationVariables>(CancelCostCentreDocument, baseOptions);
      }
export type CancelCostCentreMutationHookResult = ReturnType<typeof useCancelCostCentreMutation>;
export type CancelCostCentreMutationResult = ApolloReactCommon.MutationResult<CancelCostCentreMutation>;
export type CancelCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostCentreMutation, CancelCostCentreMutationVariables>;
export const DeleteCostCentreDocument = gql`
    mutation deleteCostCentre($CostCentreID: String!, $CompanyID: String!) {
  deleteCostCentre(CostCentreID: $CostCentreID, CompanyID: $CompanyID)
}
    `;
export type DeleteCostCentreMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCentreMutation, DeleteCostCentreMutationVariables>;

/**
 * __useDeleteCostCentreMutation__
 *
 * To run a mutation, you first call `useDeleteCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCentreMutation, { data, loading, error }] = useDeleteCostCentreMutation({
 *   variables: {
 *      CostCentreID: // value for 'CostCentreID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDeleteCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCentreMutation, DeleteCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCentreMutation, DeleteCostCentreMutationVariables>(DeleteCostCentreDocument, baseOptions);
      }
export type DeleteCostCentreMutationHookResult = ReturnType<typeof useDeleteCostCentreMutation>;
export type DeleteCostCentreMutationResult = ApolloReactCommon.MutationResult<DeleteCostCentreMutation>;
export type DeleteCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCentreMutation, DeleteCostCentreMutationVariables>;
export const CreateCostCentreDefinitionAsgmtDocument = gql`
    mutation createCostCentreDefinitionAsgmt($input: CostCentreDefinitionAsgmtInput!) {
  createCostCentreDefinitionAsgmt(input: $input)
}
    `;
export type CreateCostCentreDefinitionAsgmtMutationFn = ApolloReactCommon.MutationFunction<CreateCostCentreDefinitionAsgmtMutation, CreateCostCentreDefinitionAsgmtMutationVariables>;

/**
 * __useCreateCostCentreDefinitionAsgmtMutation__
 *
 * To run a mutation, you first call `useCreateCostCentreDefinitionAsgmtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCentreDefinitionAsgmtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCentreDefinitionAsgmtMutation, { data, loading, error }] = useCreateCostCentreDefinitionAsgmtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCentreDefinitionAsgmtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCentreDefinitionAsgmtMutation, CreateCostCentreDefinitionAsgmtMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCentreDefinitionAsgmtMutation, CreateCostCentreDefinitionAsgmtMutationVariables>(CreateCostCentreDefinitionAsgmtDocument, baseOptions);
      }
export type CreateCostCentreDefinitionAsgmtMutationHookResult = ReturnType<typeof useCreateCostCentreDefinitionAsgmtMutation>;
export type CreateCostCentreDefinitionAsgmtMutationResult = ApolloReactCommon.MutationResult<CreateCostCentreDefinitionAsgmtMutation>;
export type CreateCostCentreDefinitionAsgmtMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCentreDefinitionAsgmtMutation, CreateCostCentreDefinitionAsgmtMutationVariables>;
export const GetCostCentreDefinitionAsgmtDocument = gql`
    query getCostCentreDefinitionAsgmt($CompanyID: String) {
  getCostCentreDefinitionAsgmt(CompanyID: $CompanyID) {
    CostCentreDefinitionAsgmtID
    CostCentreDefinitionHeaderID
    CompanyID
    CostCentreDefinitionHeader {
      Title
      CostCentreDefinitionDetail {
        Name
        Level
        IsLastNode
      }
    }
  }
}
    `;

/**
 * __useGetCostCentreDefinitionAsgmtQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionAsgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionAsgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionAsgmtQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionAsgmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>(GetCostCentreDefinitionAsgmtDocument, baseOptions);
      }
export function useGetCostCentreDefinitionAsgmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>(GetCostCentreDefinitionAsgmtDocument, baseOptions);
        }
export type GetCostCentreDefinitionAsgmtQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionAsgmtQuery>;
export type GetCostCentreDefinitionAsgmtLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionAsgmtLazyQuery>;
export type GetCostCentreDefinitionAsgmtQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>;
export const GetCostCentreDefinitionDetailDocument = gql`
    query getCostCentreDefinitionDetail($CompanyID: String) {
  getCostCentreDefinitionDetail(CompanyID: $CompanyID) {
    CostCentreDefinitionDetailID
    ParentCostCentreDefinitionDetailID
    CostCentreDefinitionHeaderID
    Name
    Level
  }
}
    `;

/**
 * __useGetCostCentreDefinitionDetailQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionDetailQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>(GetCostCentreDefinitionDetailDocument, baseOptions);
      }
export function useGetCostCentreDefinitionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>(GetCostCentreDefinitionDetailDocument, baseOptions);
        }
export type GetCostCentreDefinitionDetailQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionDetailQuery>;
export type GetCostCentreDefinitionDetailLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionDetailLazyQuery>;
export type GetCostCentreDefinitionDetailQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>;
export const GetNextLevelCcDefinitionDocument = gql`
    query getNextLevelCCDefinition($CompanyID: String!, $CostCentreID: String) {
  getNextLevelCCDefinition(CompanyID: $CompanyID, CostCentreID: $CostCentreID)
}
    `;

/**
 * __useGetNextLevelCcDefinitionQuery__
 *
 * To run a query within a React component, call `useGetNextLevelCcDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextLevelCcDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextLevelCcDefinitionQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CostCentreID: // value for 'CostCentreID'
 *   },
 * });
 */
export function useGetNextLevelCcDefinitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>(GetNextLevelCcDefinitionDocument, baseOptions);
      }
export function useGetNextLevelCcDefinitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>(GetNextLevelCcDefinitionDocument, baseOptions);
        }
export type GetNextLevelCcDefinitionQueryHookResult = ReturnType<typeof useGetNextLevelCcDefinitionQuery>;
export type GetNextLevelCcDefinitionLazyQueryHookResult = ReturnType<typeof useGetNextLevelCcDefinitionLazyQuery>;
export type GetNextLevelCcDefinitionQueryResult = ApolloReactCommon.QueryResult<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>;
export const GetParentCostCentreForTreeDocument = gql`
    query getParentCostCentreForTree($CostCentreArr: [String!]) {
  getParentCostCentreForTree(CostCentreArr: $CostCentreArr)
}
    `;

/**
 * __useGetParentCostCentreForTreeQuery__
 *
 * To run a query within a React component, call `useGetParentCostCentreForTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCostCentreForTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCostCentreForTreeQuery({
 *   variables: {
 *      CostCentreArr: // value for 'CostCentreArr'
 *   },
 * });
 */
export function useGetParentCostCentreForTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>(GetParentCostCentreForTreeDocument, baseOptions);
      }
export function useGetParentCostCentreForTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>(GetParentCostCentreForTreeDocument, baseOptions);
        }
export type GetParentCostCentreForTreeQueryHookResult = ReturnType<typeof useGetParentCostCentreForTreeQuery>;
export type GetParentCostCentreForTreeLazyQueryHookResult = ReturnType<typeof useGetParentCostCentreForTreeLazyQuery>;
export type GetParentCostCentreForTreeQueryResult = ApolloReactCommon.QueryResult<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>;
export const SetDefaultCostCentreDocument = gql`
    mutation setDefaultCostCentre($CostCentreID: String!, $CompanyID: String!) {
  setDefaultCostCentre(CostCentreID: $CostCentreID, CompanyID: $CompanyID)
}
    `;
export type SetDefaultCostCentreMutationFn = ApolloReactCommon.MutationFunction<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>;

/**
 * __useSetDefaultCostCentreMutation__
 *
 * To run a mutation, you first call `useSetDefaultCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultCostCentreMutation, { data, loading, error }] = useSetDefaultCostCentreMutation({
 *   variables: {
 *      CostCentreID: // value for 'CostCentreID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSetDefaultCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>(SetDefaultCostCentreDocument, baseOptions);
      }
export type SetDefaultCostCentreMutationHookResult = ReturnType<typeof useSetDefaultCostCentreMutation>;
export type SetDefaultCostCentreMutationResult = ApolloReactCommon.MutationResult<SetDefaultCostCentreMutation>;
export type SetDefaultCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>;
export const GetDepartmentListingByItemIdDocument = gql`
    query getDepartmentListingByItemID($CompanyID: String!, $BillItemID: String, $ExpenseItemID: String, $ItemID: String, $StockItemID: String) {
  getDepartmentListingByItemID(CompanyID: $CompanyID, BillItemID: $BillItemID, ExpenseItemID: $ExpenseItemID, ItemID: $ItemID, StockItemID: $StockItemID) {
    CostCentreID
    Name
    Code
    IsDefault
  }
}
    `;

/**
 * __useGetDepartmentListingByItemIdQuery__
 *
 * To run a query within a React component, call `useGetDepartmentListingByItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentListingByItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentListingByItemIdQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BillItemID: // value for 'BillItemID'
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *      ItemID: // value for 'ItemID'
 *      StockItemID: // value for 'StockItemID'
 *   },
 * });
 */
export function useGetDepartmentListingByItemIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDepartmentListingByItemIdQuery, GetDepartmentListingByItemIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDepartmentListingByItemIdQuery, GetDepartmentListingByItemIdQueryVariables>(GetDepartmentListingByItemIdDocument, baseOptions);
      }
export function useGetDepartmentListingByItemIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDepartmentListingByItemIdQuery, GetDepartmentListingByItemIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDepartmentListingByItemIdQuery, GetDepartmentListingByItemIdQueryVariables>(GetDepartmentListingByItemIdDocument, baseOptions);
        }
export type GetDepartmentListingByItemIdQueryHookResult = ReturnType<typeof useGetDepartmentListingByItemIdQuery>;
export type GetDepartmentListingByItemIdLazyQueryHookResult = ReturnType<typeof useGetDepartmentListingByItemIdLazyQuery>;
export type GetDepartmentListingByItemIdQueryResult = ApolloReactCommon.QueryResult<GetDepartmentListingByItemIdQuery, GetDepartmentListingByItemIdQueryVariables>;
export const GetDepartmentListingByCoaDocument = gql`
    query getDepartmentListingByCOA($CompanyID: String!, $MasterCOAID: String, $AccountType: String) {
  getDepartmentListingByCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, AccountType: $AccountType) {
    CostCentreID
    Name
    Code
    IsDefault
  }
}
    `;

/**
 * __useGetDepartmentListingByCoaQuery__
 *
 * To run a query within a React component, call `useGetDepartmentListingByCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentListingByCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentListingByCoaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetDepartmentListingByCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDepartmentListingByCoaQuery, GetDepartmentListingByCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDepartmentListingByCoaQuery, GetDepartmentListingByCoaQueryVariables>(GetDepartmentListingByCoaDocument, baseOptions);
      }
export function useGetDepartmentListingByCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDepartmentListingByCoaQuery, GetDepartmentListingByCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDepartmentListingByCoaQuery, GetDepartmentListingByCoaQueryVariables>(GetDepartmentListingByCoaDocument, baseOptions);
        }
export type GetDepartmentListingByCoaQueryHookResult = ReturnType<typeof useGetDepartmentListingByCoaQuery>;
export type GetDepartmentListingByCoaLazyQueryHookResult = ReturnType<typeof useGetDepartmentListingByCoaLazyQuery>;
export type GetDepartmentListingByCoaQueryResult = ApolloReactCommon.QueryResult<GetDepartmentListingByCoaQuery, GetDepartmentListingByCoaQueryVariables>;
export const GetCostCentreCoaDocument = gql`
    query getCostCentreCOA($CompanyID: String!, $MasterCOAID: String, $EntityCOAID: String) {
  getCostCentreCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, EntityCOAID: $EntityCOAID) {
    createdTs
    createdBy
    modBy
    modTs
    MasterCOAID
    RecordStatus
    CostCentreID
    CostCentreCOAID
    AccountID
    CostCentre {
      CostCentreID
      Name
      CompanyID
      ParentCostCentreID
      Level
      Code
      MasterCode
      IsLastNode
      IsDefault
      Company {
        CompanyID
        Name
      }
      FavoriteJournalProcessingItem {
        FavoriteJournalProcessingItemID
      }
      JournalItem {
        JournalItemID
      }
      BudgetItemCostCentre {
        BudgetItemCostCentreID
      }
      JournalProcessingItem {
        JournalProcessingItemID
      }
    }
  }
}
    `;

/**
 * __useGetCostCentreCoaQuery__
 *
 * To run a query within a React component, call `useGetCostCentreCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreCoaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      EntityCOAID: // value for 'EntityCOAID'
 *   },
 * });
 */
export function useGetCostCentreCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreCoaQuery, GetCostCentreCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreCoaQuery, GetCostCentreCoaQueryVariables>(GetCostCentreCoaDocument, baseOptions);
      }
export function useGetCostCentreCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreCoaQuery, GetCostCentreCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreCoaQuery, GetCostCentreCoaQueryVariables>(GetCostCentreCoaDocument, baseOptions);
        }
export type GetCostCentreCoaQueryHookResult = ReturnType<typeof useGetCostCentreCoaQuery>;
export type GetCostCentreCoaLazyQueryHookResult = ReturnType<typeof useGetCostCentreCoaLazyQuery>;
export type GetCostCentreCoaQueryResult = ApolloReactCommon.QueryResult<GetCostCentreCoaQuery, GetCostCentreCoaQueryVariables>;
export const GetCostCentreCoaCountDocument = gql`
    query getCostCentreCOACount($CompanyID: String!) {
  getCostCentreCOACount(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCostCentreCoaCountQuery__
 *
 * To run a query within a React component, call `useGetCostCentreCoaCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreCoaCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreCoaCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreCoaCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreCoaCountQuery, GetCostCentreCoaCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreCoaCountQuery, GetCostCentreCoaCountQueryVariables>(GetCostCentreCoaCountDocument, baseOptions);
      }
export function useGetCostCentreCoaCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreCoaCountQuery, GetCostCentreCoaCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreCoaCountQuery, GetCostCentreCoaCountQueryVariables>(GetCostCentreCoaCountDocument, baseOptions);
        }
export type GetCostCentreCoaCountQueryHookResult = ReturnType<typeof useGetCostCentreCoaCountQuery>;
export type GetCostCentreCoaCountLazyQueryHookResult = ReturnType<typeof useGetCostCentreCoaCountLazyQuery>;
export type GetCostCentreCoaCountQueryResult = ApolloReactCommon.QueryResult<GetCostCentreCoaCountQuery, GetCostCentreCoaCountQueryVariables>;
export const AssignCostCentreCoaDocument = gql`
    mutation assignCostCentreCOA($CompanyID: String!, $MasterCOAID: String!, $CostCentreArr: [String!]!) {
  assignCostCentreCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, CostCentreArr: $CostCentreArr)
}
    `;
export type AssignCostCentreCoaMutationFn = ApolloReactCommon.MutationFunction<AssignCostCentreCoaMutation, AssignCostCentreCoaMutationVariables>;

/**
 * __useAssignCostCentreCoaMutation__
 *
 * To run a mutation, you first call `useAssignCostCentreCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCostCentreCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCostCentreCoaMutation, { data, loading, error }] = useAssignCostCentreCoaMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      CostCentreArr: // value for 'CostCentreArr'
 *   },
 * });
 */
export function useAssignCostCentreCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCostCentreCoaMutation, AssignCostCentreCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCostCentreCoaMutation, AssignCostCentreCoaMutationVariables>(AssignCostCentreCoaDocument, baseOptions);
      }
export type AssignCostCentreCoaMutationHookResult = ReturnType<typeof useAssignCostCentreCoaMutation>;
export type AssignCostCentreCoaMutationResult = ApolloReactCommon.MutationResult<AssignCostCentreCoaMutation>;
export type AssignCostCentreCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCostCentreCoaMutation, AssignCostCentreCoaMutationVariables>;
export const UnassignCostCentreCoaDocument = gql`
    mutation unassignCostCentreCOA($CompanyID: String!, $MasterCOAID: String!, $CostCentreCOAArr: [String!]!) {
  unassignCostCentreCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, CostCentreCOAArr: $CostCentreCOAArr)
}
    `;
export type UnassignCostCentreCoaMutationFn = ApolloReactCommon.MutationFunction<UnassignCostCentreCoaMutation, UnassignCostCentreCoaMutationVariables>;

/**
 * __useUnassignCostCentreCoaMutation__
 *
 * To run a mutation, you first call `useUnassignCostCentreCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCostCentreCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCostCentreCoaMutation, { data, loading, error }] = useUnassignCostCentreCoaMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      CostCentreCOAArr: // value for 'CostCentreCOAArr'
 *   },
 * });
 */
export function useUnassignCostCentreCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCostCentreCoaMutation, UnassignCostCentreCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCostCentreCoaMutation, UnassignCostCentreCoaMutationVariables>(UnassignCostCentreCoaDocument, baseOptions);
      }
export type UnassignCostCentreCoaMutationHookResult = ReturnType<typeof useUnassignCostCentreCoaMutation>;
export type UnassignCostCentreCoaMutationResult = ApolloReactCommon.MutationResult<UnassignCostCentreCoaMutation>;
export type UnassignCostCentreCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCostCentreCoaMutation, UnassignCostCentreCoaMutationVariables>;
export const GetEntityCoaDocument = gql`
    query getEntityCOA($AccountID: String, $CompanyID: String, $MasterCOAID: String, $orderByAsc: String) {
  getEntityCOA(AccountID: $AccountID, CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, orderByAsc: $orderByAsc) {
    EntityCOAID
    CompanyID
    MasterCOAID
    RecordStatus
    MasterCOA {
      MasterCOAID
      ParentMasterCOAID
      Name
      Code
      IsControl
      AccountType
      IsLastNode
      Level
      RecordStatus
    }
  }
}
    `;

/**
 * __useGetEntityCoaQuery__
 *
 * To run a query within a React component, call `useGetEntityCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityCoaQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetEntityCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityCoaQuery, GetEntityCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityCoaQuery, GetEntityCoaQueryVariables>(GetEntityCoaDocument, baseOptions);
      }
export function useGetEntityCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityCoaQuery, GetEntityCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityCoaQuery, GetEntityCoaQueryVariables>(GetEntityCoaDocument, baseOptions);
        }
export type GetEntityCoaQueryHookResult = ReturnType<typeof useGetEntityCoaQuery>;
export type GetEntityCoaLazyQueryHookResult = ReturnType<typeof useGetEntityCoaLazyQuery>;
export type GetEntityCoaQueryResult = ApolloReactCommon.QueryResult<GetEntityCoaQuery, GetEntityCoaQueryVariables>;
export const GetEntityCoaRecordStatusDocument = gql`
    query getEntityCOARecordStatus($AccountID: String, $CompanyID: String, $MasterCOAID: String, $orderByAsc: String) {
  getEntityCOA(AccountID: $AccountID, CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, orderByAsc: $orderByAsc) {
    MasterCOAID
    RecordStatus
  }
}
    `;

/**
 * __useGetEntityCoaRecordStatusQuery__
 *
 * To run a query within a React component, call `useGetEntityCoaRecordStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityCoaRecordStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityCoaRecordStatusQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetEntityCoaRecordStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityCoaRecordStatusQuery, GetEntityCoaRecordStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityCoaRecordStatusQuery, GetEntityCoaRecordStatusQueryVariables>(GetEntityCoaRecordStatusDocument, baseOptions);
      }
export function useGetEntityCoaRecordStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityCoaRecordStatusQuery, GetEntityCoaRecordStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityCoaRecordStatusQuery, GetEntityCoaRecordStatusQueryVariables>(GetEntityCoaRecordStatusDocument, baseOptions);
        }
export type GetEntityCoaRecordStatusQueryHookResult = ReturnType<typeof useGetEntityCoaRecordStatusQuery>;
export type GetEntityCoaRecordStatusLazyQueryHookResult = ReturnType<typeof useGetEntityCoaRecordStatusLazyQuery>;
export type GetEntityCoaRecordStatusQueryResult = ApolloReactCommon.QueryResult<GetEntityCoaRecordStatusQuery, GetEntityCoaRecordStatusQueryVariables>;
export const GetMasterCoaForFormDocument = gql`
    query getMasterCOAForForm($CompanyID: String, $AccountType: [AccountType!]) {
  getMasterCOAForForm(CompanyID: $CompanyID, AccountType: $AccountType) {
    MasterCOAID
    Name
    Code
    AccountType
    IsLastNode
    IsControl
    RecordStatus
  }
}
    `;

/**
 * __useGetMasterCoaForFormQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaForFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaForFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaForFormQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetMasterCoaForFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaForFormQuery, GetMasterCoaForFormQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaForFormQuery, GetMasterCoaForFormQueryVariables>(GetMasterCoaForFormDocument, baseOptions);
      }
export function useGetMasterCoaForFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaForFormQuery, GetMasterCoaForFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaForFormQuery, GetMasterCoaForFormQueryVariables>(GetMasterCoaForFormDocument, baseOptions);
        }
export type GetMasterCoaForFormQueryHookResult = ReturnType<typeof useGetMasterCoaForFormQuery>;
export type GetMasterCoaForFormLazyQueryHookResult = ReturnType<typeof useGetMasterCoaForFormLazyQuery>;
export type GetMasterCoaForFormQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaForFormQuery, GetMasterCoaForFormQueryVariables>;
export const AccountCodeCheckingDocument = gql`
    query accountCodeChecking($MasterCOAID: String) {
  accountCodeChecking(MasterCOAID: $MasterCOAID)
}
    `;

/**
 * __useAccountCodeCheckingQuery__
 *
 * To run a query within a React component, call `useAccountCodeCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCodeCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCodeCheckingQuery({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *   },
 * });
 */
export function useAccountCodeCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountCodeCheckingQuery, AccountCodeCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountCodeCheckingQuery, AccountCodeCheckingQueryVariables>(AccountCodeCheckingDocument, baseOptions);
      }
export function useAccountCodeCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountCodeCheckingQuery, AccountCodeCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountCodeCheckingQuery, AccountCodeCheckingQueryVariables>(AccountCodeCheckingDocument, baseOptions);
        }
export type AccountCodeCheckingQueryHookResult = ReturnType<typeof useAccountCodeCheckingQuery>;
export type AccountCodeCheckingLazyQueryHookResult = ReturnType<typeof useAccountCodeCheckingLazyQuery>;
export type AccountCodeCheckingQueryResult = ApolloReactCommon.QueryResult<AccountCodeCheckingQuery, AccountCodeCheckingQueryVariables>;
export const GetAssignedMasterCoaDocument = gql`
    query getAssignedMasterCOA($CompanyID: String!, $MasterCOAID: String, $AccountType: AccountType!) {
  getAssignedMasterCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, AccountType: $AccountType) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    IsLastNode
    Level
    CostCentreCOA {
      RecordStatus
      CostCentreCOAID
      CostCentreID
      MasterCOAID
    }
    AuditAdjustmentItem {
      AuditAdjustmentItemID
      CompanyID
    }
    FavoriteJournalProcessingItem {
      FavoriteJournalProcessingItemID
    }
    JournalProcessingItem {
      JournalProcessingItemID
      CompanyID
    }
    RecurringJournalItem {
      RecurringJournalItemID
      CompanyID
    }
    BudgetItem {
      BudgetItemID
      CompanyID
    }
    RoutineJournalItem {
      RoutineJournalItemID
      CompanyID
    }
  }
}
    `;

/**
 * __useGetAssignedMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetAssignedMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedMasterCoaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetAssignedMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedMasterCoaQuery, GetAssignedMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedMasterCoaQuery, GetAssignedMasterCoaQueryVariables>(GetAssignedMasterCoaDocument, baseOptions);
      }
export function useGetAssignedMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedMasterCoaQuery, GetAssignedMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedMasterCoaQuery, GetAssignedMasterCoaQueryVariables>(GetAssignedMasterCoaDocument, baseOptions);
        }
export type GetAssignedMasterCoaQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaQuery>;
export type GetAssignedMasterCoaLazyQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaLazyQuery>;
export type GetAssignedMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetAssignedMasterCoaQuery, GetAssignedMasterCoaQueryVariables>;
export const GetAssignedMasterCoaCodeDocument = gql`
    query getAssignedMasterCOACode($CompanyID: String!, $MasterCOAID: String, $AccountType: AccountType!) {
  getAssignedMasterCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, AccountType: $AccountType) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    IsLastNode
    Level
    RecordStatus
    CostCentreCOA {
      RecordStatus
      CostCentreCOAID
      CostCentreID
      MasterCOAID
    }
  }
}
    `;

/**
 * __useGetAssignedMasterCoaCodeQuery__
 *
 * To run a query within a React component, call `useGetAssignedMasterCoaCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedMasterCoaCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedMasterCoaCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetAssignedMasterCoaCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedMasterCoaCodeQuery, GetAssignedMasterCoaCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedMasterCoaCodeQuery, GetAssignedMasterCoaCodeQueryVariables>(GetAssignedMasterCoaCodeDocument, baseOptions);
      }
export function useGetAssignedMasterCoaCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedMasterCoaCodeQuery, GetAssignedMasterCoaCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedMasterCoaCodeQuery, GetAssignedMasterCoaCodeQueryVariables>(GetAssignedMasterCoaCodeDocument, baseOptions);
        }
export type GetAssignedMasterCoaCodeQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaCodeQuery>;
export type GetAssignedMasterCoaCodeLazyQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaCodeLazyQuery>;
export type GetAssignedMasterCoaCodeQueryResult = ApolloReactCommon.QueryResult<GetAssignedMasterCoaCodeQuery, GetAssignedMasterCoaCodeQueryVariables>;
export const GetAssignedMasterCoaForListingDocument = gql`
    query getAssignedMasterCOAForListing($CompanyID: String!, $MasterCOAID: String, $AccountType: AccountType!) {
  getAssignedMasterCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, AccountType: $AccountType) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    IsLastNode
    Level
    RecordStatus
  }
}
    `;

/**
 * __useGetAssignedMasterCoaForListingQuery__
 *
 * To run a query within a React component, call `useGetAssignedMasterCoaForListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedMasterCoaForListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedMasterCoaForListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetAssignedMasterCoaForListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedMasterCoaForListingQuery, GetAssignedMasterCoaForListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedMasterCoaForListingQuery, GetAssignedMasterCoaForListingQueryVariables>(GetAssignedMasterCoaForListingDocument, baseOptions);
      }
export function useGetAssignedMasterCoaForListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedMasterCoaForListingQuery, GetAssignedMasterCoaForListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedMasterCoaForListingQuery, GetAssignedMasterCoaForListingQueryVariables>(GetAssignedMasterCoaForListingDocument, baseOptions);
        }
export type GetAssignedMasterCoaForListingQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaForListingQuery>;
export type GetAssignedMasterCoaForListingLazyQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaForListingLazyQuery>;
export type GetAssignedMasterCoaForListingQueryResult = ApolloReactCommon.QueryResult<GetAssignedMasterCoaForListingQuery, GetAssignedMasterCoaForListingQueryVariables>;
export const GetAssignedMasterCoaListingDocument = gql`
    query getAssignedMasterCOAListing($CompanyID: String!, $MasterCOAID: String, $AccountType: AccountType!) {
  getAssignedMasterCOA(CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, AccountType: $AccountType) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    IsLastNode
    Level
    RecordStatus
  }
}
    `;

/**
 * __useGetAssignedMasterCoaListingQuery__
 *
 * To run a query within a React component, call `useGetAssignedMasterCoaListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedMasterCoaListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedMasterCoaListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetAssignedMasterCoaListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedMasterCoaListingQuery, GetAssignedMasterCoaListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedMasterCoaListingQuery, GetAssignedMasterCoaListingQueryVariables>(GetAssignedMasterCoaListingDocument, baseOptions);
      }
export function useGetAssignedMasterCoaListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedMasterCoaListingQuery, GetAssignedMasterCoaListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedMasterCoaListingQuery, GetAssignedMasterCoaListingQueryVariables>(GetAssignedMasterCoaListingDocument, baseOptions);
        }
export type GetAssignedMasterCoaListingQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaListingQuery>;
export type GetAssignedMasterCoaListingLazyQueryHookResult = ReturnType<typeof useGetAssignedMasterCoaListingLazyQuery>;
export type GetAssignedMasterCoaListingQueryResult = ApolloReactCommon.QueryResult<GetAssignedMasterCoaListingQuery, GetAssignedMasterCoaListingQueryVariables>;
export const CreateEntityCoaDocument = gql`
    mutation createEntityCOA($input: EntityCOAInput!) {
  createEntityCOA(input: $input) {
    EntityCOAID
  }
}
    `;
export type CreateEntityCoaMutationFn = ApolloReactCommon.MutationFunction<CreateEntityCoaMutation, CreateEntityCoaMutationVariables>;

/**
 * __useCreateEntityCoaMutation__
 *
 * To run a mutation, you first call `useCreateEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityCoaMutation, { data, loading, error }] = useCreateEntityCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityCoaMutation, CreateEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityCoaMutation, CreateEntityCoaMutationVariables>(CreateEntityCoaDocument, baseOptions);
      }
export type CreateEntityCoaMutationHookResult = ReturnType<typeof useCreateEntityCoaMutation>;
export type CreateEntityCoaMutationResult = ApolloReactCommon.MutationResult<CreateEntityCoaMutation>;
export type CreateEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityCoaMutation, CreateEntityCoaMutationVariables>;
export const AssignEntityCoaDocument = gql`
    mutation assignEntityCOA($MasterCOAID: String!, $CompanyArr: [String!]!) {
  assignEntityCOA(MasterCOAID: $MasterCOAID, CompanyArr: $CompanyArr)
}
    `;
export type AssignEntityCoaMutationFn = ApolloReactCommon.MutationFunction<AssignEntityCoaMutation, AssignEntityCoaMutationVariables>;

/**
 * __useAssignEntityCoaMutation__
 *
 * To run a mutation, you first call `useAssignEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignEntityCoaMutation, { data, loading, error }] = useAssignEntityCoaMutation({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *      CompanyArr: // value for 'CompanyArr'
 *   },
 * });
 */
export function useAssignEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignEntityCoaMutation, AssignEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignEntityCoaMutation, AssignEntityCoaMutationVariables>(AssignEntityCoaDocument, baseOptions);
      }
export type AssignEntityCoaMutationHookResult = ReturnType<typeof useAssignEntityCoaMutation>;
export type AssignEntityCoaMutationResult = ApolloReactCommon.MutationResult<AssignEntityCoaMutation>;
export type AssignEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignEntityCoaMutation, AssignEntityCoaMutationVariables>;
export const UpdateEntityCoaDocument = gql`
    mutation updateEntityCOA($input: EntityCOAInput!) {
  updateEntityCOA(input: $input) {
    EntityCOAID
  }
}
    `;
export type UpdateEntityCoaMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityCoaMutation, UpdateEntityCoaMutationVariables>;

/**
 * __useUpdateEntityCoaMutation__
 *
 * To run a mutation, you first call `useUpdateEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityCoaMutation, { data, loading, error }] = useUpdateEntityCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityCoaMutation, UpdateEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityCoaMutation, UpdateEntityCoaMutationVariables>(UpdateEntityCoaDocument, baseOptions);
      }
export type UpdateEntityCoaMutationHookResult = ReturnType<typeof useUpdateEntityCoaMutation>;
export type UpdateEntityCoaMutationResult = ApolloReactCommon.MutationResult<UpdateEntityCoaMutation>;
export type UpdateEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityCoaMutation, UpdateEntityCoaMutationVariables>;
export const CancelEntityCoaDocument = gql`
    mutation cancelEntityCOA($input: EntityCOADeleteInput!) {
  cancelEntityCOA(input: $input)
}
    `;
export type CancelEntityCoaMutationFn = ApolloReactCommon.MutationFunction<CancelEntityCoaMutation, CancelEntityCoaMutationVariables>;

/**
 * __useCancelEntityCoaMutation__
 *
 * To run a mutation, you first call `useCancelEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEntityCoaMutation, { data, loading, error }] = useCancelEntityCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelEntityCoaMutation, CancelEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelEntityCoaMutation, CancelEntityCoaMutationVariables>(CancelEntityCoaDocument, baseOptions);
      }
export type CancelEntityCoaMutationHookResult = ReturnType<typeof useCancelEntityCoaMutation>;
export type CancelEntityCoaMutationResult = ApolloReactCommon.MutationResult<CancelEntityCoaMutation>;
export type CancelEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelEntityCoaMutation, CancelEntityCoaMutationVariables>;
export const DeleteEntityCoaDocument = gql`
    mutation deleteEntityCOA($input: EntityCOADeleteInput!) {
  deleteEntityCOA(input: $input)
}
    `;
export type DeleteEntityCoaMutationFn = ApolloReactCommon.MutationFunction<DeleteEntityCoaMutation, DeleteEntityCoaMutationVariables>;

/**
 * __useDeleteEntityCoaMutation__
 *
 * To run a mutation, you first call `useDeleteEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntityCoaMutation, { data, loading, error }] = useDeleteEntityCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEntityCoaMutation, DeleteEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEntityCoaMutation, DeleteEntityCoaMutationVariables>(DeleteEntityCoaDocument, baseOptions);
      }
export type DeleteEntityCoaMutationHookResult = ReturnType<typeof useDeleteEntityCoaMutation>;
export type DeleteEntityCoaMutationResult = ApolloReactCommon.MutationResult<DeleteEntityCoaMutation>;
export type DeleteEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEntityCoaMutation, DeleteEntityCoaMutationVariables>;
export const ActivateInactivateEntityCoaDocument = gql`
    mutation activateInactivateEntityCOA($MasterCOAID: String!, $CompanyID: String!, $ActiveCOA: Boolean!) {
  activateInactivateEntityCOA(MasterCOAID: $MasterCOAID, CompanyID: $CompanyID, ActiveCOA: $ActiveCOA)
}
    `;
export type ActivateInactivateEntityCoaMutationFn = ApolloReactCommon.MutationFunction<ActivateInactivateEntityCoaMutation, ActivateInactivateEntityCoaMutationVariables>;

/**
 * __useActivateInactivateEntityCoaMutation__
 *
 * To run a mutation, you first call `useActivateInactivateEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateInactivateEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateInactivateEntityCoaMutation, { data, loading, error }] = useActivateInactivateEntityCoaMutation({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *      CompanyID: // value for 'CompanyID'
 *      ActiveCOA: // value for 'ActiveCOA'
 *   },
 * });
 */
export function useActivateInactivateEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateInactivateEntityCoaMutation, ActivateInactivateEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateInactivateEntityCoaMutation, ActivateInactivateEntityCoaMutationVariables>(ActivateInactivateEntityCoaDocument, baseOptions);
      }
export type ActivateInactivateEntityCoaMutationHookResult = ReturnType<typeof useActivateInactivateEntityCoaMutation>;
export type ActivateInactivateEntityCoaMutationResult = ApolloReactCommon.MutationResult<ActivateInactivateEntityCoaMutation>;
export type ActivateInactivateEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateInactivateEntityCoaMutation, ActivateInactivateEntityCoaMutationVariables>;
export const DeleteAssignedEntityCoaDocument = gql`
    mutation deleteAssignedEntityCOA($MasterCOAID: String!, $CompanyArr: [String!]!) {
  deleteAssignedEntityCOA(MasterCOAID: $MasterCOAID, CompanyArr: $CompanyArr)
}
    `;
export type DeleteAssignedEntityCoaMutationFn = ApolloReactCommon.MutationFunction<DeleteAssignedEntityCoaMutation, DeleteAssignedEntityCoaMutationVariables>;

/**
 * __useDeleteAssignedEntityCoaMutation__
 *
 * To run a mutation, you first call `useDeleteAssignedEntityCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignedEntityCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignedEntityCoaMutation, { data, loading, error }] = useDeleteAssignedEntityCoaMutation({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *      CompanyArr: // value for 'CompanyArr'
 *   },
 * });
 */
export function useDeleteAssignedEntityCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAssignedEntityCoaMutation, DeleteAssignedEntityCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAssignedEntityCoaMutation, DeleteAssignedEntityCoaMutationVariables>(DeleteAssignedEntityCoaDocument, baseOptions);
      }
export type DeleteAssignedEntityCoaMutationHookResult = ReturnType<typeof useDeleteAssignedEntityCoaMutation>;
export type DeleteAssignedEntityCoaMutationResult = ApolloReactCommon.MutationResult<DeleteAssignedEntityCoaMutation>;
export type DeleteAssignedEntityCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAssignedEntityCoaMutation, DeleteAssignedEntityCoaMutationVariables>;
export const GetEntityCoaFilteredDocument = gql`
    query getEntityCOAFiltered($orderByAsc: String, $CompanyID: String!, $IsControl: Boolean) {
  getEntityCOAFiltered(orderByAsc: $orderByAsc, CompanyID: $CompanyID, IsControl: $IsControl) {
    EntityCOAID
    CompanyID
    MasterCOAID
    MasterCOA {
      Name
      Code
      AccountType
      IsLastNode
      IsControl
      MasterCOAID
      RecordStatus
    }
  }
}
    `;

/**
 * __useGetEntityCoaFilteredQuery__
 *
 * To run a query within a React component, call `useGetEntityCoaFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityCoaFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityCoaFilteredQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      CompanyID: // value for 'CompanyID'
 *      IsControl: // value for 'IsControl'
 *   },
 * });
 */
export function useGetEntityCoaFilteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityCoaFilteredQuery, GetEntityCoaFilteredQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityCoaFilteredQuery, GetEntityCoaFilteredQueryVariables>(GetEntityCoaFilteredDocument, baseOptions);
      }
export function useGetEntityCoaFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityCoaFilteredQuery, GetEntityCoaFilteredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityCoaFilteredQuery, GetEntityCoaFilteredQueryVariables>(GetEntityCoaFilteredDocument, baseOptions);
        }
export type GetEntityCoaFilteredQueryHookResult = ReturnType<typeof useGetEntityCoaFilteredQuery>;
export type GetEntityCoaFilteredLazyQueryHookResult = ReturnType<typeof useGetEntityCoaFilteredLazyQuery>;
export type GetEntityCoaFilteredQueryResult = ApolloReactCommon.QueryResult<GetEntityCoaFilteredQuery, GetEntityCoaFilteredQueryVariables>;
export const GetAssignedEntityCoaDocument = gql`
    query getAssignedEntityCOA($AccountID: String, $CompanyID: String, $MasterCOAID: String, $orderByAsc: String) {
  getEntityCOA(AccountID: $AccountID, CompanyID: $CompanyID, MasterCOAID: $MasterCOAID, orderByAsc: $orderByAsc) {
    EntityCOAID
    CompanyID
    MasterCOAID
    MasterCOA {
      MasterCOAID
      ParentMasterCOAID
      Name
      Code
      IsControl
      AccountType
      IsLastNode
      Level
    }
    IsUsed
  }
}
    `;

/**
 * __useGetAssignedEntityCoaQuery__
 *
 * To run a query within a React component, call `useGetAssignedEntityCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedEntityCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedEntityCoaQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAssignedEntityCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedEntityCoaQuery, GetAssignedEntityCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedEntityCoaQuery, GetAssignedEntityCoaQueryVariables>(GetAssignedEntityCoaDocument, baseOptions);
      }
export function useGetAssignedEntityCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedEntityCoaQuery, GetAssignedEntityCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedEntityCoaQuery, GetAssignedEntityCoaQueryVariables>(GetAssignedEntityCoaDocument, baseOptions);
        }
export type GetAssignedEntityCoaQueryHookResult = ReturnType<typeof useGetAssignedEntityCoaQuery>;
export type GetAssignedEntityCoaLazyQueryHookResult = ReturnType<typeof useGetAssignedEntityCoaLazyQuery>;
export type GetAssignedEntityCoaQueryResult = ApolloReactCommon.QueryResult<GetAssignedEntityCoaQuery, GetAssignedEntityCoaQueryVariables>;
export const GetGlCompanyPolicyDocument = gql`
    query getGLCompanyPolicy($CompanyID: String!) {
  getGLCompanyPolicy(CompanyID: $CompanyID) {
    GLCompanyPolicyID
    CompanyID
    DefaultDepartmentBalanceSheetCOA
  }
}
    `;

/**
 * __useGetGlCompanyPolicyQuery__
 *
 * To run a query within a React component, call `useGetGlCompanyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlCompanyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlCompanyPolicyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetGlCompanyPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlCompanyPolicyQuery, GetGlCompanyPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlCompanyPolicyQuery, GetGlCompanyPolicyQueryVariables>(GetGlCompanyPolicyDocument, baseOptions);
      }
export function useGetGlCompanyPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlCompanyPolicyQuery, GetGlCompanyPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlCompanyPolicyQuery, GetGlCompanyPolicyQueryVariables>(GetGlCompanyPolicyDocument, baseOptions);
        }
export type GetGlCompanyPolicyQueryHookResult = ReturnType<typeof useGetGlCompanyPolicyQuery>;
export type GetGlCompanyPolicyLazyQueryHookResult = ReturnType<typeof useGetGlCompanyPolicyLazyQuery>;
export type GetGlCompanyPolicyQueryResult = ApolloReactCommon.QueryResult<GetGlCompanyPolicyQuery, GetGlCompanyPolicyQueryVariables>;
export const GetAllTransactionCheckingDocument = gql`
    query getAllTransactionChecking($CompanyID: String!) {
  getAllTransactionChecking(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetAllTransactionCheckingQuery__
 *
 * To run a query within a React component, call `useGetAllTransactionCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransactionCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransactionCheckingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAllTransactionCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllTransactionCheckingQuery, GetAllTransactionCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllTransactionCheckingQuery, GetAllTransactionCheckingQueryVariables>(GetAllTransactionCheckingDocument, baseOptions);
      }
export function useGetAllTransactionCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllTransactionCheckingQuery, GetAllTransactionCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllTransactionCheckingQuery, GetAllTransactionCheckingQueryVariables>(GetAllTransactionCheckingDocument, baseOptions);
        }
export type GetAllTransactionCheckingQueryHookResult = ReturnType<typeof useGetAllTransactionCheckingQuery>;
export type GetAllTransactionCheckingLazyQueryHookResult = ReturnType<typeof useGetAllTransactionCheckingLazyQuery>;
export type GetAllTransactionCheckingQueryResult = ApolloReactCommon.QueryResult<GetAllTransactionCheckingQuery, GetAllTransactionCheckingQueryVariables>;
export const UpdateGlCompanyPolicyDocument = gql`
    mutation updateGLCompanyPolicy($GLCompanyPolicyInput: GLCompanyPolicyInput!) {
  updateGLCompanyPolicy(GLCompanyPolicyInput: $GLCompanyPolicyInput)
}
    `;
export type UpdateGlCompanyPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateGlCompanyPolicyMutation, UpdateGlCompanyPolicyMutationVariables>;

/**
 * __useUpdateGlCompanyPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateGlCompanyPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlCompanyPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlCompanyPolicyMutation, { data, loading, error }] = useUpdateGlCompanyPolicyMutation({
 *   variables: {
 *      GLCompanyPolicyInput: // value for 'GLCompanyPolicyInput'
 *   },
 * });
 */
export function useUpdateGlCompanyPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGlCompanyPolicyMutation, UpdateGlCompanyPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGlCompanyPolicyMutation, UpdateGlCompanyPolicyMutationVariables>(UpdateGlCompanyPolicyDocument, baseOptions);
      }
export type UpdateGlCompanyPolicyMutationHookResult = ReturnType<typeof useUpdateGlCompanyPolicyMutation>;
export type UpdateGlCompanyPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateGlCompanyPolicyMutation>;
export type UpdateGlCompanyPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGlCompanyPolicyMutation, UpdateGlCompanyPolicyMutationVariables>;
export const GetJournalTypeDocument = gql`
    query getJournalType($AccountID: String, $CompanyID: String, $IsSystem: Boolean, $orderByDesc: String, $orderByAsc: String) {
  getJournalType(AccountID: $AccountID, CompanyID: $CompanyID, IsSystem: $IsSystem, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    JournalTypeID
    CompanyID
    JournalType
    Description
    Company {
      Name
    }
    IsSystem
    FavoriteJournalProcessing {
      FavoriteJournalProcessingID
    }
    Journal {
      JournalID
    }
    RecurringJournal {
      RecurringJournalID
    }
    JournalProcessing {
      JournalProcessingID
    }
    RoutineJournal {
      RoutineJournalID
    }
  }
}
    `;

/**
 * __useGetJournalTypeQuery__
 *
 * To run a query within a React component, call `useGetJournalTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalTypeQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      IsSystem: // value for 'IsSystem'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetJournalTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalTypeQuery, GetJournalTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalTypeQuery, GetJournalTypeQueryVariables>(GetJournalTypeDocument, baseOptions);
      }
export function useGetJournalTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalTypeQuery, GetJournalTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalTypeQuery, GetJournalTypeQueryVariables>(GetJournalTypeDocument, baseOptions);
        }
export type GetJournalTypeQueryHookResult = ReturnType<typeof useGetJournalTypeQuery>;
export type GetJournalTypeLazyQueryHookResult = ReturnType<typeof useGetJournalTypeLazyQuery>;
export type GetJournalTypeQueryResult = ApolloReactCommon.QueryResult<GetJournalTypeQuery, GetJournalTypeQueryVariables>;
export const GetJournalTypeListingDocument = gql`
    query getJournalTypeListing($AccountID: String, $CompanyID: String, $IsSystem: Boolean, $orderByAsc: String, $orderByDesc: String, $skip: Float, $take: Float, $searchValue: String) {
  getJournalTypePerm(AccountID: $AccountID, CompanyID: $CompanyID, IsSystem: $IsSystem, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, searchValue: $searchValue) {
    JournalTypeID
    CompanyID
    JournalType
    Description
    Company {
      Name
    }
    IsSystem
    FavoriteJournalProcessing {
      FavoriteJournalProcessingID
    }
    Journal {
      JournalID
    }
    RecurringJournal {
      RecurringJournalID
    }
    JournalProcessing {
      JournalProcessingID
    }
    RoutineJournal {
      RoutineJournalID
    }
  }
  getJournalTypeCount(AccountID: $AccountID, CompanyID: $CompanyID, IsSystem: $IsSystem)
}
    `;

/**
 * __useGetJournalTypeListingQuery__
 *
 * To run a query within a React component, call `useGetJournalTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalTypeListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      IsSystem: // value for 'IsSystem'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetJournalTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalTypeListingQuery, GetJournalTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalTypeListingQuery, GetJournalTypeListingQueryVariables>(GetJournalTypeListingDocument, baseOptions);
      }
export function useGetJournalTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalTypeListingQuery, GetJournalTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalTypeListingQuery, GetJournalTypeListingQueryVariables>(GetJournalTypeListingDocument, baseOptions);
        }
export type GetJournalTypeListingQueryHookResult = ReturnType<typeof useGetJournalTypeListingQuery>;
export type GetJournalTypeListingLazyQueryHookResult = ReturnType<typeof useGetJournalTypeListingLazyQuery>;
export type GetJournalTypeListingQueryResult = ApolloReactCommon.QueryResult<GetJournalTypeListingQuery, GetJournalTypeListingQueryVariables>;
export const CreateJournalTypeDocument = gql`
    mutation createJournalType($input: JournalTypeInput!) {
  createJournalType(input: $input) {
    JournalTypeID
  }
}
    `;
export type CreateJournalTypeMutationFn = ApolloReactCommon.MutationFunction<CreateJournalTypeMutation, CreateJournalTypeMutationVariables>;

/**
 * __useCreateJournalTypeMutation__
 *
 * To run a mutation, you first call `useCreateJournalTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJournalTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJournalTypeMutation, { data, loading, error }] = useCreateJournalTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJournalTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJournalTypeMutation, CreateJournalTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJournalTypeMutation, CreateJournalTypeMutationVariables>(CreateJournalTypeDocument, baseOptions);
      }
export type CreateJournalTypeMutationHookResult = ReturnType<typeof useCreateJournalTypeMutation>;
export type CreateJournalTypeMutationResult = ApolloReactCommon.MutationResult<CreateJournalTypeMutation>;
export type CreateJournalTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJournalTypeMutation, CreateJournalTypeMutationVariables>;
export const UpdateJournalTypeDocument = gql`
    mutation updateJournalType($input: JournalTypeInput!) {
  updateJournalType(input: $input) {
    JournalTypeID
  }
}
    `;
export type UpdateJournalTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateJournalTypeMutation, UpdateJournalTypeMutationVariables>;

/**
 * __useUpdateJournalTypeMutation__
 *
 * To run a mutation, you first call `useUpdateJournalTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalTypeMutation, { data, loading, error }] = useUpdateJournalTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJournalTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJournalTypeMutation, UpdateJournalTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJournalTypeMutation, UpdateJournalTypeMutationVariables>(UpdateJournalTypeDocument, baseOptions);
      }
export type UpdateJournalTypeMutationHookResult = ReturnType<typeof useUpdateJournalTypeMutation>;
export type UpdateJournalTypeMutationResult = ApolloReactCommon.MutationResult<UpdateJournalTypeMutation>;
export type UpdateJournalTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJournalTypeMutation, UpdateJournalTypeMutationVariables>;
export const CancelJournalTypeDocument = gql`
    mutation cancelJournalType($input: JournalTypeDeleteInput!) {
  cancelJournalType(input: $input)
}
    `;
export type CancelJournalTypeMutationFn = ApolloReactCommon.MutationFunction<CancelJournalTypeMutation, CancelJournalTypeMutationVariables>;

/**
 * __useCancelJournalTypeMutation__
 *
 * To run a mutation, you first call `useCancelJournalTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJournalTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJournalTypeMutation, { data, loading, error }] = useCancelJournalTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelJournalTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelJournalTypeMutation, CancelJournalTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelJournalTypeMutation, CancelJournalTypeMutationVariables>(CancelJournalTypeDocument, baseOptions);
      }
export type CancelJournalTypeMutationHookResult = ReturnType<typeof useCancelJournalTypeMutation>;
export type CancelJournalTypeMutationResult = ApolloReactCommon.MutationResult<CancelJournalTypeMutation>;
export type CancelJournalTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelJournalTypeMutation, CancelJournalTypeMutationVariables>;
export const DeleteJournalTypeDocument = gql`
    mutation deleteJournalType($input: JournalTypeDeleteInput!) {
  deleteJournalType(input: $input)
}
    `;
export type DeleteJournalTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteJournalTypeMutation, DeleteJournalTypeMutationVariables>;

/**
 * __useDeleteJournalTypeMutation__
 *
 * To run a mutation, you first call `useDeleteJournalTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJournalTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJournalTypeMutation, { data, loading, error }] = useDeleteJournalTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJournalTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJournalTypeMutation, DeleteJournalTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJournalTypeMutation, DeleteJournalTypeMutationVariables>(DeleteJournalTypeDocument, baseOptions);
      }
export type DeleteJournalTypeMutationHookResult = ReturnType<typeof useDeleteJournalTypeMutation>;
export type DeleteJournalTypeMutationResult = ApolloReactCommon.MutationResult<DeleteJournalTypeMutation>;
export type DeleteJournalTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJournalTypeMutation, DeleteJournalTypeMutationVariables>;
export const GetDocNumDocument = gql`
    query getDocNum($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeaderWithPerm(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    DocumentNumberHeaderID
    SampleOutput
    IsAutoDocNo
    DocNumGenerated
    DocumentNumberDetail {
      DocumentNumberDetailID
      Sequence
      FormatType
      FormatValue
      NextNumber
      StartFrom
      ResetBy
    }
  }
}
    `;

/**
 * __useGetDocNumQuery__
 *
 * To run a query within a React component, call `useGetDocNumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumQuery, GetDocNumQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumQuery, GetDocNumQueryVariables>(GetDocNumDocument, baseOptions);
      }
export function useGetDocNumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumQuery, GetDocNumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumQuery, GetDocNumQueryVariables>(GetDocNumDocument, baseOptions);
        }
export type GetDocNumQueryHookResult = ReturnType<typeof useGetDocNumQuery>;
export type GetDocNumLazyQueryHookResult = ReturnType<typeof useGetDocNumLazyQuery>;
export type GetDocNumQueryResult = ApolloReactCommon.QueryResult<GetDocNumQuery, GetDocNumQueryVariables>;
export const GetDocNumByRefTableDocument = gql`
    query getDocNumByRefTable($CompanyID: String!, $RefTable: [String!]!) {
  getDocNumByRefTable(CompanyID: $CompanyID, RefTable: $RefTable) {
    DocumentNumberHeaderID
    SampleOutput
    IsAutoDocNo
    DocNumGenerated
    DocumentNumberDetail {
      DocumentNumberDetailID
      Sequence
      FormatType
      FormatValue
      NextNumber
      StartFrom
      ResetBy
    }
  }
}
    `;

/**
 * __useGetDocNumByRefTableQuery__
 *
 * To run a query within a React component, call `useGetDocNumByRefTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumByRefTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumByRefTableQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumByRefTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumByRefTableQuery, GetDocNumByRefTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumByRefTableQuery, GetDocNumByRefTableQueryVariables>(GetDocNumByRefTableDocument, baseOptions);
      }
export function useGetDocNumByRefTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumByRefTableQuery, GetDocNumByRefTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumByRefTableQuery, GetDocNumByRefTableQueryVariables>(GetDocNumByRefTableDocument, baseOptions);
        }
export type GetDocNumByRefTableQueryHookResult = ReturnType<typeof useGetDocNumByRefTableQuery>;
export type GetDocNumByRefTableLazyQueryHookResult = ReturnType<typeof useGetDocNumByRefTableLazyQuery>;
export type GetDocNumByRefTableQueryResult = ApolloReactCommon.QueryResult<GetDocNumByRefTableQuery, GetDocNumByRefTableQueryVariables>;
export const GetDocNumTitleDocument = gql`
    query getDocNumTitle($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    SampleOutput
    DocumentNumberHeaderID
    Type
    RefTable
    IsAutoDocNo
    DocNumGenerated
  }
}
    `;

/**
 * __useGetDocNumTitleQuery__
 *
 * To run a query within a React component, call `useGetDocNumTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumTitleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>(GetDocNumTitleDocument, baseOptions);
      }
export function useGetDocNumTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>(GetDocNumTitleDocument, baseOptions);
        }
export type GetDocNumTitleQueryHookResult = ReturnType<typeof useGetDocNumTitleQuery>;
export type GetDocNumTitleLazyQueryHookResult = ReturnType<typeof useGetDocNumTitleLazyQuery>;
export type GetDocNumTitleQueryResult = ApolloReactCommon.QueryResult<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>;
export const UpdateDocumentNumberHeaderDocument = gql`
    mutation updateDocumentNumberHeader($DocumentNumberDetailInput: [DocumentNumberDetailInput!]!, $DocumentNumberHeaderInput: DocumentNumberHeaderInput!) {
  updateDocumentNumberHeader(DocumentNumberDetailInput: $DocumentNumberDetailInput, DocumentNumberHeaderInput: $DocumentNumberHeaderInput)
}
    `;
export type UpdateDocumentNumberHeaderMutationFn = ApolloReactCommon.MutationFunction<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;

/**
 * __useUpdateDocumentNumberHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentNumberHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentNumberHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentNumberHeaderMutation, { data, loading, error }] = useUpdateDocumentNumberHeaderMutation({
 *   variables: {
 *      DocumentNumberDetailInput: // value for 'DocumentNumberDetailInput'
 *      DocumentNumberHeaderInput: // value for 'DocumentNumberHeaderInput'
 *   },
 * });
 */
export function useUpdateDocumentNumberHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>(UpdateDocumentNumberHeaderDocument, baseOptions);
      }
export type UpdateDocumentNumberHeaderMutationHookResult = ReturnType<typeof useUpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationResult = ApolloReactCommon.MutationResult<UpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;
export const UpdateIsAutoDocNoDocument = gql`
    mutation updateIsAutoDocNo($CompanyID: String!, $RefTable: String!, $IsAutoDocNo: Boolean!, $input: DocumentNumberHeaderInput!) {
  updateIsAutoDocNo(CompanyID: $CompanyID, RefTable: $RefTable, IsAutoDocNo: $IsAutoDocNo, input: $input)
}
    `;
export type UpdateIsAutoDocNoMutationFn = ApolloReactCommon.MutationFunction<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>;

/**
 * __useUpdateIsAutoDocNoMutation__
 *
 * To run a mutation, you first call `useUpdateIsAutoDocNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsAutoDocNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsAutoDocNoMutation, { data, loading, error }] = useUpdateIsAutoDocNoMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *      IsAutoDocNo: // value for 'IsAutoDocNo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIsAutoDocNoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>(UpdateIsAutoDocNoDocument, baseOptions);
      }
export type UpdateIsAutoDocNoMutationHookResult = ReturnType<typeof useUpdateIsAutoDocNoMutation>;
export type UpdateIsAutoDocNoMutationResult = ApolloReactCommon.MutationResult<UpdateIsAutoDocNoMutation>;
export type UpdateIsAutoDocNoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>;
export const GetRetainEarningAccountDefDocument = gql`
    query getRetainEarningAccountDef($CompanyID: String) {
  getRetainEarningAccountDef(CompanyID: $CompanyID) {
    RetainEarningAccountDefID
    Code
    MasterCOAID
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetRetainEarningAccountDefQuery__
 *
 * To run a query within a React component, call `useGetRetainEarningAccountDefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetainEarningAccountDefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetainEarningAccountDefQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetRetainEarningAccountDefQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRetainEarningAccountDefQuery, GetRetainEarningAccountDefQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRetainEarningAccountDefQuery, GetRetainEarningAccountDefQueryVariables>(GetRetainEarningAccountDefDocument, baseOptions);
      }
export function useGetRetainEarningAccountDefLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRetainEarningAccountDefQuery, GetRetainEarningAccountDefQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRetainEarningAccountDefQuery, GetRetainEarningAccountDefQueryVariables>(GetRetainEarningAccountDefDocument, baseOptions);
        }
export type GetRetainEarningAccountDefQueryHookResult = ReturnType<typeof useGetRetainEarningAccountDefQuery>;
export type GetRetainEarningAccountDefLazyQueryHookResult = ReturnType<typeof useGetRetainEarningAccountDefLazyQuery>;
export type GetRetainEarningAccountDefQueryResult = ApolloReactCommon.QueryResult<GetRetainEarningAccountDefQuery, GetRetainEarningAccountDefQueryVariables>;
export const CreateRetainEarningAccountDefDocument = gql`
    mutation createRetainEarningAccountDef($input: RetainEarningAccountDefInput!) {
  createRetainEarningAccountDef(input: $input)
}
    `;
export type CreateRetainEarningAccountDefMutationFn = ApolloReactCommon.MutationFunction<CreateRetainEarningAccountDefMutation, CreateRetainEarningAccountDefMutationVariables>;

/**
 * __useCreateRetainEarningAccountDefMutation__
 *
 * To run a mutation, you first call `useCreateRetainEarningAccountDefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetainEarningAccountDefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetainEarningAccountDefMutation, { data, loading, error }] = useCreateRetainEarningAccountDefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRetainEarningAccountDefMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRetainEarningAccountDefMutation, CreateRetainEarningAccountDefMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRetainEarningAccountDefMutation, CreateRetainEarningAccountDefMutationVariables>(CreateRetainEarningAccountDefDocument, baseOptions);
      }
export type CreateRetainEarningAccountDefMutationHookResult = ReturnType<typeof useCreateRetainEarningAccountDefMutation>;
export type CreateRetainEarningAccountDefMutationResult = ApolloReactCommon.MutationResult<CreateRetainEarningAccountDefMutation>;
export type CreateRetainEarningAccountDefMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRetainEarningAccountDefMutation, CreateRetainEarningAccountDefMutationVariables>;
export const GetMasterCoaAccountTypeDocument = gql`
    query getMasterCOAAccountType($CompanyID: String!, $AccountType: AccountType!) {
  getMasterCOAAccountType(CompanyID: $CompanyID, AccountType: $AccountType) {
    MasterCOAID
    Name
    Code
  }
}
    `;

/**
 * __useGetMasterCoaAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaAccountTypeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetMasterCoaAccountTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaAccountTypeQuery, GetMasterCoaAccountTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaAccountTypeQuery, GetMasterCoaAccountTypeQueryVariables>(GetMasterCoaAccountTypeDocument, baseOptions);
      }
export function useGetMasterCoaAccountTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaAccountTypeQuery, GetMasterCoaAccountTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaAccountTypeQuery, GetMasterCoaAccountTypeQueryVariables>(GetMasterCoaAccountTypeDocument, baseOptions);
        }
export type GetMasterCoaAccountTypeQueryHookResult = ReturnType<typeof useGetMasterCoaAccountTypeQuery>;
export type GetMasterCoaAccountTypeLazyQueryHookResult = ReturnType<typeof useGetMasterCoaAccountTypeLazyQuery>;
export type GetMasterCoaAccountTypeQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaAccountTypeQuery, GetMasterCoaAccountTypeQueryVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description) {
    AttachmentID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const AttachmentListingDocument = gql`
    query attachmentListing($refID: String) {
  attachmentListing(refID: $refID) {
    AttachmentID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useAttachmentListingQuery__
 *
 * To run a query within a React component, call `useAttachmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useAttachmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttachmentListingQuery, AttachmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AttachmentListingQuery, AttachmentListingQueryVariables>(AttachmentListingDocument, baseOptions);
      }
export function useAttachmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttachmentListingQuery, AttachmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttachmentListingQuery, AttachmentListingQueryVariables>(AttachmentListingDocument, baseOptions);
        }
export type AttachmentListingQueryHookResult = ReturnType<typeof useAttachmentListingQuery>;
export type AttachmentListingLazyQueryHookResult = ReturnType<typeof useAttachmentListingLazyQuery>;
export type AttachmentListingQueryResult = ApolloReactCommon.QueryResult<AttachmentListingQuery, AttachmentListingQueryVariables>;
export const FetchShareAttachmentDocument = gql`
    query fetchShareAttachment($file: Upload!, $CompanyID: String!, $Prefix: String!) {
  fetchShareAttachment(file: $file, CompanyID: $CompanyID, Prefix: $Prefix) {
    AttachmentID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useFetchShareAttachmentQuery__
 *
 * To run a query within a React component, call `useFetchShareAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchShareAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchShareAttachmentQuery({
 *   variables: {
 *      file: // value for 'file'
 *      CompanyID: // value for 'CompanyID'
 *      Prefix: // value for 'Prefix'
 *   },
 * });
 */
export function useFetchShareAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchShareAttachmentQuery, FetchShareAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchShareAttachmentQuery, FetchShareAttachmentQueryVariables>(FetchShareAttachmentDocument, baseOptions);
      }
export function useFetchShareAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchShareAttachmentQuery, FetchShareAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchShareAttachmentQuery, FetchShareAttachmentQueryVariables>(FetchShareAttachmentDocument, baseOptions);
        }
export type FetchShareAttachmentQueryHookResult = ReturnType<typeof useFetchShareAttachmentQuery>;
export type FetchShareAttachmentLazyQueryHookResult = ReturnType<typeof useFetchShareAttachmentLazyQuery>;
export type FetchShareAttachmentQueryResult = ApolloReactCommon.QueryResult<FetchShareAttachmentQuery, FetchShareAttachmentQueryVariables>;
export const GetShareAttachmentDocument = gql`
    mutation getShareAttachment($file: Upload!, $CompanyID: String!) {
  getShareAttachment(file: $file, CompanyID: $CompanyID) {
    fileName
    fileURL
    mediaType
    createdTs
    description
  }
}
    `;
export type GetShareAttachmentMutationFn = ApolloReactCommon.MutationFunction<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;

/**
 * __useGetShareAttachmentMutation__
 *
 * To run a mutation, you first call `useGetShareAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetShareAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getShareAttachmentMutation, { data, loading, error }] = useGetShareAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetShareAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>(GetShareAttachmentDocument, baseOptions);
      }
export type GetShareAttachmentMutationHookResult = ReturnType<typeof useGetShareAttachmentMutation>;
export type GetShareAttachmentMutationResult = ApolloReactCommon.MutationResult<GetShareAttachmentMutation>;
export type GetShareAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<GetShareAttachmentMutation, GetShareAttachmentMutationVariables>;
export const GetGeneralTinDocument = gql`
    query getGeneralTin {
  getGeneralTin
}
    `;

/**
 * __useGetGeneralTinQuery__
 *
 * To run a query within a React component, call `useGetGeneralTinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralTinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralTinQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralTinQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralTinQuery, GetGeneralTinQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralTinQuery, GetGeneralTinQueryVariables>(GetGeneralTinDocument, baseOptions);
      }
export function useGetGeneralTinLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralTinQuery, GetGeneralTinQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralTinQuery, GetGeneralTinQueryVariables>(GetGeneralTinDocument, baseOptions);
        }
export type GetGeneralTinQueryHookResult = ReturnType<typeof useGetGeneralTinQuery>;
export type GetGeneralTinLazyQueryHookResult = ReturnType<typeof useGetGeneralTinLazyQuery>;
export type GetGeneralTinQueryResult = ApolloReactCommon.QueryResult<GetGeneralTinQuery, GetGeneralTinQueryVariables>;
export const CheckInvalidTinNoDocument = gql`
    query checkInvalidTinNo($tinNo: String!, $identityNo: String!, $identityType: String!) {
  checkInvalidTinNo(tinNo: $tinNo, identityNo: $identityNo, identityType: $identityType)
}
    `;

/**
 * __useCheckInvalidTinNoQuery__
 *
 * To run a query within a React component, call `useCheckInvalidTinNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInvalidTinNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInvalidTinNoQuery({
 *   variables: {
 *      tinNo: // value for 'tinNo'
 *      identityNo: // value for 'identityNo'
 *      identityType: // value for 'identityType'
 *   },
 * });
 */
export function useCheckInvalidTinNoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckInvalidTinNoQuery, CheckInvalidTinNoQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckInvalidTinNoQuery, CheckInvalidTinNoQueryVariables>(CheckInvalidTinNoDocument, baseOptions);
      }
export function useCheckInvalidTinNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckInvalidTinNoQuery, CheckInvalidTinNoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckInvalidTinNoQuery, CheckInvalidTinNoQueryVariables>(CheckInvalidTinNoDocument, baseOptions);
        }
export type CheckInvalidTinNoQueryHookResult = ReturnType<typeof useCheckInvalidTinNoQuery>;
export type CheckInvalidTinNoLazyQueryHookResult = ReturnType<typeof useCheckInvalidTinNoLazyQuery>;
export type CheckInvalidTinNoQueryResult = ApolloReactCommon.QueryResult<CheckInvalidTinNoQuery, CheckInvalidTinNoQueryVariables>;
export const GetAuditAdjustmentDocument = gql`
    query getAuditAdjustment($AccountID: String, $CompanyID: String, $AuditAdjustmentID: String, $orderByAsc: String, $orderByDesc: String) {
  getAuditAdjustment(AccountID: $AccountID, CompanyID: $CompanyID, AuditAdjustmentID: $AuditAdjustmentID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AuditAdjustmentID
    DocDate
    DocNo
    DocDate
    AdjYearOrPeriod
    CompanyID
    RefNo
    Description
    RecordStatus
    ApprovalStatus
    Attachment {
      fileURL
      mediaType
      name
    }
    AuditAdjustmentItem {
      createdTs
      modTs
      AuditAdjustmentItemID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
        Code
        RoutineJournalItem {
          DocAmt
        }
      }
      CostCentreID
      CostCentre {
        Name
      }
      Remark
      DocAmt
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          StatusRemarks
          createdTs
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAuditAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetAuditAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditAdjustmentQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      AuditAdjustmentID: // value for 'AuditAdjustmentID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetAuditAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuditAdjustmentQuery, GetAuditAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuditAdjustmentQuery, GetAuditAdjustmentQueryVariables>(GetAuditAdjustmentDocument, baseOptions);
      }
export function useGetAuditAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuditAdjustmentQuery, GetAuditAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuditAdjustmentQuery, GetAuditAdjustmentQueryVariables>(GetAuditAdjustmentDocument, baseOptions);
        }
export type GetAuditAdjustmentQueryHookResult = ReturnType<typeof useGetAuditAdjustmentQuery>;
export type GetAuditAdjustmentLazyQueryHookResult = ReturnType<typeof useGetAuditAdjustmentLazyQuery>;
export type GetAuditAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetAuditAdjustmentQuery, GetAuditAdjustmentQueryVariables>;
export const CreateAuditAdjustmentDocument = gql`
    mutation createAuditAdjustment($input: AuditAdjustmentInput!, $itemInput: [AuditAdjustmentItemInput!]) {
  createAuditAdjustment(input: $input, itemInput: $itemInput) {
    AuditAdjustmentID
  }
}
    `;
export type CreateAuditAdjustmentMutationFn = ApolloReactCommon.MutationFunction<CreateAuditAdjustmentMutation, CreateAuditAdjustmentMutationVariables>;

/**
 * __useCreateAuditAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateAuditAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditAdjustmentMutation, { data, loading, error }] = useCreateAuditAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateAuditAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAuditAdjustmentMutation, CreateAuditAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAuditAdjustmentMutation, CreateAuditAdjustmentMutationVariables>(CreateAuditAdjustmentDocument, baseOptions);
      }
export type CreateAuditAdjustmentMutationHookResult = ReturnType<typeof useCreateAuditAdjustmentMutation>;
export type CreateAuditAdjustmentMutationResult = ApolloReactCommon.MutationResult<CreateAuditAdjustmentMutation>;
export type CreateAuditAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAuditAdjustmentMutation, CreateAuditAdjustmentMutationVariables>;
export const UpdateAuditAdjustmentDocument = gql`
    mutation updateAuditAdjustment($input: AuditAdjustmentInput!, $itemInput: [AuditAdjustmentItemInput!]) {
  updateAuditAdjustment(input: $input, itemInput: $itemInput) {
    AuditAdjustmentID
  }
}
    `;
export type UpdateAuditAdjustmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAuditAdjustmentMutation, UpdateAuditAdjustmentMutationVariables>;

/**
 * __useUpdateAuditAdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdateAuditAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuditAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuditAdjustmentMutation, { data, loading, error }] = useUpdateAuditAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateAuditAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAuditAdjustmentMutation, UpdateAuditAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAuditAdjustmentMutation, UpdateAuditAdjustmentMutationVariables>(UpdateAuditAdjustmentDocument, baseOptions);
      }
export type UpdateAuditAdjustmentMutationHookResult = ReturnType<typeof useUpdateAuditAdjustmentMutation>;
export type UpdateAuditAdjustmentMutationResult = ApolloReactCommon.MutationResult<UpdateAuditAdjustmentMutation>;
export type UpdateAuditAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAuditAdjustmentMutation, UpdateAuditAdjustmentMutationVariables>;
export const CancelAuditAdjustmentDocument = gql`
    mutation cancelAuditAdjustment($input: AuditAdjustmentDeleteInput!) {
  cancelAuditAdjustment(input: $input)
}
    `;
export type CancelAuditAdjustmentMutationFn = ApolloReactCommon.MutationFunction<CancelAuditAdjustmentMutation, CancelAuditAdjustmentMutationVariables>;

/**
 * __useCancelAuditAdjustmentMutation__
 *
 * To run a mutation, you first call `useCancelAuditAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAuditAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAuditAdjustmentMutation, { data, loading, error }] = useCancelAuditAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAuditAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelAuditAdjustmentMutation, CancelAuditAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelAuditAdjustmentMutation, CancelAuditAdjustmentMutationVariables>(CancelAuditAdjustmentDocument, baseOptions);
      }
export type CancelAuditAdjustmentMutationHookResult = ReturnType<typeof useCancelAuditAdjustmentMutation>;
export type CancelAuditAdjustmentMutationResult = ApolloReactCommon.MutationResult<CancelAuditAdjustmentMutation>;
export type CancelAuditAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelAuditAdjustmentMutation, CancelAuditAdjustmentMutationVariables>;
export const DeleteAuditAdjustmentDocument = gql`
    mutation deleteAuditAdjustment($AuditAdjustmentID: String!) {
  deleteAuditAdjustment(AuditAdjustmentID: $AuditAdjustmentID)
}
    `;
export type DeleteAuditAdjustmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAuditAdjustmentMutation, DeleteAuditAdjustmentMutationVariables>;

/**
 * __useDeleteAuditAdjustmentMutation__
 *
 * To run a mutation, you first call `useDeleteAuditAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuditAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuditAdjustmentMutation, { data, loading, error }] = useDeleteAuditAdjustmentMutation({
 *   variables: {
 *      AuditAdjustmentID: // value for 'AuditAdjustmentID'
 *   },
 * });
 */
export function useDeleteAuditAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAuditAdjustmentMutation, DeleteAuditAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAuditAdjustmentMutation, DeleteAuditAdjustmentMutationVariables>(DeleteAuditAdjustmentDocument, baseOptions);
      }
export type DeleteAuditAdjustmentMutationHookResult = ReturnType<typeof useDeleteAuditAdjustmentMutation>;
export type DeleteAuditAdjustmentMutationResult = ApolloReactCommon.MutationResult<DeleteAuditAdjustmentMutation>;
export type DeleteAuditAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAuditAdjustmentMutation, DeleteAuditAdjustmentMutationVariables>;
export const PostAuditAdjustmentDocument = gql`
    mutation postAuditAdjustment($AuditAdjustmentIDs: [String!]!, $CompanyID: String) {
  postAuditAdjustment(AuditAdjustmentIDs: $AuditAdjustmentIDs, CompanyID: $CompanyID)
}
    `;
export type PostAuditAdjustmentMutationFn = ApolloReactCommon.MutationFunction<PostAuditAdjustmentMutation, PostAuditAdjustmentMutationVariables>;

/**
 * __usePostAuditAdjustmentMutation__
 *
 * To run a mutation, you first call `usePostAuditAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostAuditAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postAuditAdjustmentMutation, { data, loading, error }] = usePostAuditAdjustmentMutation({
 *   variables: {
 *      AuditAdjustmentIDs: // value for 'AuditAdjustmentIDs'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePostAuditAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostAuditAdjustmentMutation, PostAuditAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostAuditAdjustmentMutation, PostAuditAdjustmentMutationVariables>(PostAuditAdjustmentDocument, baseOptions);
      }
export type PostAuditAdjustmentMutationHookResult = ReturnType<typeof usePostAuditAdjustmentMutation>;
export type PostAuditAdjustmentMutationResult = ApolloReactCommon.MutationResult<PostAuditAdjustmentMutation>;
export type PostAuditAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostAuditAdjustmentMutation, PostAuditAdjustmentMutationVariables>;
export const RejectAuditAdjustmentDocument = gql`
    mutation rejectAuditAdjustment($AuditAdjustmentIDs: [String!]!, $input: AuditAdjustmentRejectInput!) {
  rejectAuditAdjustment(AuditAdjustmentIDs: $AuditAdjustmentIDs, input: $input)
}
    `;
export type RejectAuditAdjustmentMutationFn = ApolloReactCommon.MutationFunction<RejectAuditAdjustmentMutation, RejectAuditAdjustmentMutationVariables>;

/**
 * __useRejectAuditAdjustmentMutation__
 *
 * To run a mutation, you first call `useRejectAuditAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAuditAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAuditAdjustmentMutation, { data, loading, error }] = useRejectAuditAdjustmentMutation({
 *   variables: {
 *      AuditAdjustmentIDs: // value for 'AuditAdjustmentIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectAuditAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectAuditAdjustmentMutation, RejectAuditAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectAuditAdjustmentMutation, RejectAuditAdjustmentMutationVariables>(RejectAuditAdjustmentDocument, baseOptions);
      }
export type RejectAuditAdjustmentMutationHookResult = ReturnType<typeof useRejectAuditAdjustmentMutation>;
export type RejectAuditAdjustmentMutationResult = ApolloReactCommon.MutationResult<RejectAuditAdjustmentMutation>;
export type RejectAuditAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectAuditAdjustmentMutation, RejectAuditAdjustmentMutationVariables>;
export const GetAuditAdjustmentbyStatusDocument = gql`
    query getAuditAdjustmentbyStatus($CompanyID: String!, $StatusArr: [String!]!) {
  getAuditAdjustmentbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr) {
    createdTs
    createdBy
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    AuditAdjustmentID
    DocDate
    DocNo
    DocDate
    AdjYearOrPeriod
    CompanyID
    RefNo
    RejectionDate
    RejectionRemark
    Description
    RecordStatus
    ApprovalStatus
    Attachment {
      fileURL
      mediaType
      name
    }
    AuditAdjustmentItem {
      createdTs
      modTs
      AuditAdjustmentItemID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
        Code
        RoutineJournalItem {
          DocAmt
        }
      }
      CostCentreID
      CostCentre {
        Name
      }
      Remark
      DocAmt
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          StatusRemarks
          createdTs
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAuditAdjustmentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetAuditAdjustmentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditAdjustmentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditAdjustmentbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetAuditAdjustmentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuditAdjustmentbyStatusQuery, GetAuditAdjustmentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuditAdjustmentbyStatusQuery, GetAuditAdjustmentbyStatusQueryVariables>(GetAuditAdjustmentbyStatusDocument, baseOptions);
      }
export function useGetAuditAdjustmentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuditAdjustmentbyStatusQuery, GetAuditAdjustmentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuditAdjustmentbyStatusQuery, GetAuditAdjustmentbyStatusQueryVariables>(GetAuditAdjustmentbyStatusDocument, baseOptions);
        }
export type GetAuditAdjustmentbyStatusQueryHookResult = ReturnType<typeof useGetAuditAdjustmentbyStatusQuery>;
export type GetAuditAdjustmentbyStatusLazyQueryHookResult = ReturnType<typeof useGetAuditAdjustmentbyStatusLazyQuery>;
export type GetAuditAdjustmentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetAuditAdjustmentbyStatusQuery, GetAuditAdjustmentbyStatusQueryVariables>;
export const GetAuditAdjustmentbyStatusListingDocument = gql`
    query getAuditAdjustmentbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getAuditAdjustmentbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      createdBy
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      AuditAdjustmentID
      DocDate
      DocNo
      DocDate
      AdjYearOrPeriod
      CompanyID
      RefNo
      RejectionDate
      RejectionRemark
      Description
      RecordStatus
      ApprovalStatus
      Attachment {
        fileURL
        mediaType
        name
      }
      AuditAdjustmentItem {
        createdTs
        modTs
        AuditAdjustmentItemID
        MasterCOAID
        Sequence
        MasterCOA {
          Name
          Code
          RoutineJournalItem {
            DocAmt
          }
        }
        CostCentreID
        CostCentre {
          Name
        }
        Remark
        DocAmt
      }
      WorkFlow {
        WorkFlowID
        WorkFlowStatus
        SubmitDate
        SubmitterDetail {
          ID
          name
        }
        WorkFlowStep {
          StepNo
          WorkFlowStepID
          ApprovalStatus
          ApproverRoleID
          RoleDetail {
            ID
            name
          }
          WorkFlowApprovedStep {
            StatusRemarks
            createdTs
            WorkFlowApprovedStepID
            ApproverDetail {
              ID
              name
            }
          }
        }
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetAuditAdjustmentbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetAuditAdjustmentbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditAdjustmentbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditAdjustmentbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetAuditAdjustmentbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuditAdjustmentbyStatusListingQuery, GetAuditAdjustmentbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuditAdjustmentbyStatusListingQuery, GetAuditAdjustmentbyStatusListingQueryVariables>(GetAuditAdjustmentbyStatusListingDocument, baseOptions);
      }
export function useGetAuditAdjustmentbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuditAdjustmentbyStatusListingQuery, GetAuditAdjustmentbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuditAdjustmentbyStatusListingQuery, GetAuditAdjustmentbyStatusListingQueryVariables>(GetAuditAdjustmentbyStatusListingDocument, baseOptions);
        }
export type GetAuditAdjustmentbyStatusListingQueryHookResult = ReturnType<typeof useGetAuditAdjustmentbyStatusListingQuery>;
export type GetAuditAdjustmentbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetAuditAdjustmentbyStatusListingLazyQuery>;
export type GetAuditAdjustmentbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetAuditAdjustmentbyStatusListingQuery, GetAuditAdjustmentbyStatusListingQueryVariables>;
export const GetAuditAdjustmentAdjDateDocument = gql`
    query getAuditAdjustmentAdjDate($CompanyID: String!, $AdjYearOrPeriod: String!) {
  getAuditAdjustmentAdjDate(CompanyID: $CompanyID, AdjYearOrPeriod: $AdjYearOrPeriod) {
    AccountPeriodID
    EndDate
  }
}
    `;

/**
 * __useGetAuditAdjustmentAdjDateQuery__
 *
 * To run a query within a React component, call `useGetAuditAdjustmentAdjDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditAdjustmentAdjDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditAdjustmentAdjDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AdjYearOrPeriod: // value for 'AdjYearOrPeriod'
 *   },
 * });
 */
export function useGetAuditAdjustmentAdjDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuditAdjustmentAdjDateQuery, GetAuditAdjustmentAdjDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuditAdjustmentAdjDateQuery, GetAuditAdjustmentAdjDateQueryVariables>(GetAuditAdjustmentAdjDateDocument, baseOptions);
      }
export function useGetAuditAdjustmentAdjDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuditAdjustmentAdjDateQuery, GetAuditAdjustmentAdjDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuditAdjustmentAdjDateQuery, GetAuditAdjustmentAdjDateQueryVariables>(GetAuditAdjustmentAdjDateDocument, baseOptions);
        }
export type GetAuditAdjustmentAdjDateQueryHookResult = ReturnType<typeof useGetAuditAdjustmentAdjDateQuery>;
export type GetAuditAdjustmentAdjDateLazyQueryHookResult = ReturnType<typeof useGetAuditAdjustmentAdjDateLazyQuery>;
export type GetAuditAdjustmentAdjDateQueryResult = ApolloReactCommon.QueryResult<GetAuditAdjustmentAdjDateQuery, GetAuditAdjustmentAdjDateQueryVariables>;
export const AuditLogDetailsDocument = gql`
    query AuditLogDetails($primaryKey: String!, $TableName: String!, $FieldID: String!, $skip: Float, $take: Float) {
  AuditLogDetails(primaryKey: $primaryKey, TableName: $TableName, FieldID: $FieldID, skip: $skip, take: $take)
}
    `;

/**
 * __useAuditLogDetailsQuery__
 *
 * To run a query within a React component, call `useAuditLogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogDetailsQuery({
 *   variables: {
 *      primaryKey: // value for 'primaryKey'
 *      TableName: // value for 'TableName'
 *      FieldID: // value for 'FieldID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAuditLogDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>(AuditLogDetailsDocument, baseOptions);
      }
export function useAuditLogDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>(AuditLogDetailsDocument, baseOptions);
        }
export type AuditLogDetailsQueryHookResult = ReturnType<typeof useAuditLogDetailsQuery>;
export type AuditLogDetailsLazyQueryHookResult = ReturnType<typeof useAuditLogDetailsLazyQuery>;
export type AuditLogDetailsQueryResult = ApolloReactCommon.QueryResult<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>;
export const GetBudgetDocument = gql`
    query getBudget($BudgetID: String, $AccountID: String, $CompanyID: String, $Fyear: String, $Version: String, $orderByAsc: String) {
  getBudget(BudgetID: $BudgetID, AccountID: $AccountID, CompanyID: $CompanyID, Fyear: $Fyear, Version: $Version, orderByAsc: $orderByAsc) {
    createdBy
    createdTs
    AccountID
    RecordStatus
    ApprovalStatus
    BudgetID
    CompanyID
    Fyear
    Version
    Description
    IsDefault
    modTs
    Version
    BudgetItem {
      BudgetItemID
      BudgetID
      BudgetAmt
      MasterCOAID
      MasterCOA {
        Name
        Code
      }
      BudgetItemCostCentre {
        BudgetItemCostCentreID
        BudgetItemID
        BudgetAmt
        CostCentreID
        CostCentre {
          Name
          Code
        }
        BudgetItemPeriod {
          BudgetItemPeriodID
          BudgetItemCostCentreID
          AccountPeriodID
          BudgetAmt
          AccountPeriod {
            FYear
            FPeriod
          }
        }
      }
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          StatusRemarks
          createdTs
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBudgetQuery__
 *
 * To run a query within a React component, call `useGetBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetQuery({
 *   variables: {
 *      BudgetID: // value for 'BudgetID'
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      Fyear: // value for 'Fyear'
 *      Version: // value for 'Version'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetQuery, GetBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetQuery, GetBudgetQueryVariables>(GetBudgetDocument, baseOptions);
      }
export function useGetBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetQuery, GetBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetQuery, GetBudgetQueryVariables>(GetBudgetDocument, baseOptions);
        }
export type GetBudgetQueryHookResult = ReturnType<typeof useGetBudgetQuery>;
export type GetBudgetLazyQueryHookResult = ReturnType<typeof useGetBudgetLazyQuery>;
export type GetBudgetQueryResult = ApolloReactCommon.QueryResult<GetBudgetQuery, GetBudgetQueryVariables>;
export const GetBudgetItemDocument = gql`
    query getBudgetItem($BudgetItemID: String, $AccountID: String) {
  getBudgetItem(BudgetItemID: $BudgetItemID, AccountID: $AccountID) {
    BudgetItemID
    CompanyID
    BudgetID
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
    }
    BudgetAmt
  }
}
    `;

/**
 * __useGetBudgetItemQuery__
 *
 * To run a query within a React component, call `useGetBudgetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetItemQuery({
 *   variables: {
 *      BudgetItemID: // value for 'BudgetItemID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetBudgetItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetItemQuery, GetBudgetItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetItemQuery, GetBudgetItemQueryVariables>(GetBudgetItemDocument, baseOptions);
      }
export function useGetBudgetItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetItemQuery, GetBudgetItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetItemQuery, GetBudgetItemQueryVariables>(GetBudgetItemDocument, baseOptions);
        }
export type GetBudgetItemQueryHookResult = ReturnType<typeof useGetBudgetItemQuery>;
export type GetBudgetItemLazyQueryHookResult = ReturnType<typeof useGetBudgetItemLazyQuery>;
export type GetBudgetItemQueryResult = ApolloReactCommon.QueryResult<GetBudgetItemQuery, GetBudgetItemQueryVariables>;
export const GetBudgetItemCostCentreDocument = gql`
    query getBudgetItemCostCentre($BudgetItemCostCentreID: String, $BudgetItemID: String, $AccountID: String) {
  getBudgetItemCostCentre(BudgetItemCostCentreID: $BudgetItemCostCentreID, BudgetItemID: $BudgetItemID, AccountID: $AccountID) {
    BudgetItemCostCentreID
    AccountID
    BudgetItemID
    BudgetAmt
    CostCentreID
  }
}
    `;

/**
 * __useGetBudgetItemCostCentreQuery__
 *
 * To run a query within a React component, call `useGetBudgetItemCostCentreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetItemCostCentreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetItemCostCentreQuery({
 *   variables: {
 *      BudgetItemCostCentreID: // value for 'BudgetItemCostCentreID'
 *      BudgetItemID: // value for 'BudgetItemID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetBudgetItemCostCentreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetItemCostCentreQuery, GetBudgetItemCostCentreQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetItemCostCentreQuery, GetBudgetItemCostCentreQueryVariables>(GetBudgetItemCostCentreDocument, baseOptions);
      }
export function useGetBudgetItemCostCentreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetItemCostCentreQuery, GetBudgetItemCostCentreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetItemCostCentreQuery, GetBudgetItemCostCentreQueryVariables>(GetBudgetItemCostCentreDocument, baseOptions);
        }
export type GetBudgetItemCostCentreQueryHookResult = ReturnType<typeof useGetBudgetItemCostCentreQuery>;
export type GetBudgetItemCostCentreLazyQueryHookResult = ReturnType<typeof useGetBudgetItemCostCentreLazyQuery>;
export type GetBudgetItemCostCentreQueryResult = ApolloReactCommon.QueryResult<GetBudgetItemCostCentreQuery, GetBudgetItemCostCentreQueryVariables>;
export const CreateBudgetWithDetailDocument = gql`
    mutation createBudgetWithDetail($BudgetInput: BudgetInput!, $BudgetItemInput: [BudgetItemInput!]!, $BudgetItemCostCentreInput: [BudgetItemCostCentreInput!], $BudgetItemPeriodInput: [BudgetItemPeriodInput!]) {
  createBudgetWithDetail(BudgetInput: $BudgetInput, BudgetItemInput: $BudgetItemInput, BudgetItemCostCentreInput: $BudgetItemCostCentreInput, BudgetItemPeriodInput: $BudgetItemPeriodInput)
}
    `;
export type CreateBudgetWithDetailMutationFn = ApolloReactCommon.MutationFunction<CreateBudgetWithDetailMutation, CreateBudgetWithDetailMutationVariables>;

/**
 * __useCreateBudgetWithDetailMutation__
 *
 * To run a mutation, you first call `useCreateBudgetWithDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetWithDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetWithDetailMutation, { data, loading, error }] = useCreateBudgetWithDetailMutation({
 *   variables: {
 *      BudgetInput: // value for 'BudgetInput'
 *      BudgetItemInput: // value for 'BudgetItemInput'
 *      BudgetItemCostCentreInput: // value for 'BudgetItemCostCentreInput'
 *      BudgetItemPeriodInput: // value for 'BudgetItemPeriodInput'
 *   },
 * });
 */
export function useCreateBudgetWithDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBudgetWithDetailMutation, CreateBudgetWithDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBudgetWithDetailMutation, CreateBudgetWithDetailMutationVariables>(CreateBudgetWithDetailDocument, baseOptions);
      }
export type CreateBudgetWithDetailMutationHookResult = ReturnType<typeof useCreateBudgetWithDetailMutation>;
export type CreateBudgetWithDetailMutationResult = ApolloReactCommon.MutationResult<CreateBudgetWithDetailMutation>;
export type CreateBudgetWithDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBudgetWithDetailMutation, CreateBudgetWithDetailMutationVariables>;
export const UpdateBudgetWithDetailDocument = gql`
    mutation updateBudgetWithDetail($BudgetInput: BudgetInput!, $BudgetItemInput: [BudgetItemInput!]!, $BudgetItemCostCentreInput: [BudgetItemCostCentreInput!], $BudgetItemPeriodInput: [BudgetItemPeriodInput!]) {
  updateBudgetWithDetail(BudgetInput: $BudgetInput, BudgetItemInput: $BudgetItemInput, BudgetItemCostCentreInput: $BudgetItemCostCentreInput, BudgetItemPeriodInput: $BudgetItemPeriodInput)
}
    `;
export type UpdateBudgetWithDetailMutationFn = ApolloReactCommon.MutationFunction<UpdateBudgetWithDetailMutation, UpdateBudgetWithDetailMutationVariables>;

/**
 * __useUpdateBudgetWithDetailMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetWithDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetWithDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetWithDetailMutation, { data, loading, error }] = useUpdateBudgetWithDetailMutation({
 *   variables: {
 *      BudgetInput: // value for 'BudgetInput'
 *      BudgetItemInput: // value for 'BudgetItemInput'
 *      BudgetItemCostCentreInput: // value for 'BudgetItemCostCentreInput'
 *      BudgetItemPeriodInput: // value for 'BudgetItemPeriodInput'
 *   },
 * });
 */
export function useUpdateBudgetWithDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBudgetWithDetailMutation, UpdateBudgetWithDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBudgetWithDetailMutation, UpdateBudgetWithDetailMutationVariables>(UpdateBudgetWithDetailDocument, baseOptions);
      }
export type UpdateBudgetWithDetailMutationHookResult = ReturnType<typeof useUpdateBudgetWithDetailMutation>;
export type UpdateBudgetWithDetailMutationResult = ApolloReactCommon.MutationResult<UpdateBudgetWithDetailMutation>;
export type UpdateBudgetWithDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBudgetWithDetailMutation, UpdateBudgetWithDetailMutationVariables>;
export const UpdateBudgetDocument = gql`
    mutation updateBudget($input: BudgetInput!) {
  updateBudget(input: $input) {
    BudgetID
  }
}
    `;
export type UpdateBudgetMutationFn = ApolloReactCommon.MutationFunction<UpdateBudgetMutation, UpdateBudgetMutationVariables>;

/**
 * __useUpdateBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetMutation, { data, loading, error }] = useUpdateBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBudgetMutation, UpdateBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBudgetMutation, UpdateBudgetMutationVariables>(UpdateBudgetDocument, baseOptions);
      }
export type UpdateBudgetMutationHookResult = ReturnType<typeof useUpdateBudgetMutation>;
export type UpdateBudgetMutationResult = ApolloReactCommon.MutationResult<UpdateBudgetMutation>;
export type UpdateBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBudgetMutation, UpdateBudgetMutationVariables>;
export const SetBudgetDefaultDocument = gql`
    mutation setBudgetDefault($BudgetID: String!, $Year: String!, $CompanyID: String!) {
  setBudgetDefault(BudgetID: $BudgetID, Year: $Year, CompanyID: $CompanyID)
}
    `;
export type SetBudgetDefaultMutationFn = ApolloReactCommon.MutationFunction<SetBudgetDefaultMutation, SetBudgetDefaultMutationVariables>;

/**
 * __useSetBudgetDefaultMutation__
 *
 * To run a mutation, you first call `useSetBudgetDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBudgetDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBudgetDefaultMutation, { data, loading, error }] = useSetBudgetDefaultMutation({
 *   variables: {
 *      BudgetID: // value for 'BudgetID'
 *      Year: // value for 'Year'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSetBudgetDefaultMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetBudgetDefaultMutation, SetBudgetDefaultMutationVariables>) {
        return ApolloReactHooks.useMutation<SetBudgetDefaultMutation, SetBudgetDefaultMutationVariables>(SetBudgetDefaultDocument, baseOptions);
      }
export type SetBudgetDefaultMutationHookResult = ReturnType<typeof useSetBudgetDefaultMutation>;
export type SetBudgetDefaultMutationResult = ApolloReactCommon.MutationResult<SetBudgetDefaultMutation>;
export type SetBudgetDefaultMutationOptions = ApolloReactCommon.BaseMutationOptions<SetBudgetDefaultMutation, SetBudgetDefaultMutationVariables>;
export const GetBudgetbyStatusDocument = gql`
    query getBudgetbyStatus($CompanyID: String!, $Fyear: String) {
  getBudgetbyStatus(CompanyID: $CompanyID, Fyear: $Fyear) {
    Fyear
    Version
  }
}
    `;

/**
 * __useGetBudgetbyStatusQuery__
 *
 * To run a query within a React component, call `useGetBudgetbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Fyear: // value for 'Fyear'
 *   },
 * });
 */
export function useGetBudgetbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetbyStatusQuery, GetBudgetbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetbyStatusQuery, GetBudgetbyStatusQueryVariables>(GetBudgetbyStatusDocument, baseOptions);
      }
export function useGetBudgetbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetbyStatusQuery, GetBudgetbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetbyStatusQuery, GetBudgetbyStatusQueryVariables>(GetBudgetbyStatusDocument, baseOptions);
        }
export type GetBudgetbyStatusQueryHookResult = ReturnType<typeof useGetBudgetbyStatusQuery>;
export type GetBudgetbyStatusLazyQueryHookResult = ReturnType<typeof useGetBudgetbyStatusLazyQuery>;
export type GetBudgetbyStatusQueryResult = ApolloReactCommon.QueryResult<GetBudgetbyStatusQuery, GetBudgetbyStatusQueryVariables>;
export const GetBudgetMasterCoaDocument = gql`
    query getBudgetMasterCOA($CompanyID: String!) {
  getBudgetMasterCOA(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetBudgetMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetBudgetMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetMasterCoaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetBudgetMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetMasterCoaQuery, GetBudgetMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetMasterCoaQuery, GetBudgetMasterCoaQueryVariables>(GetBudgetMasterCoaDocument, baseOptions);
      }
export function useGetBudgetMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetMasterCoaQuery, GetBudgetMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetMasterCoaQuery, GetBudgetMasterCoaQueryVariables>(GetBudgetMasterCoaDocument, baseOptions);
        }
export type GetBudgetMasterCoaQueryHookResult = ReturnType<typeof useGetBudgetMasterCoaQuery>;
export type GetBudgetMasterCoaLazyQueryHookResult = ReturnType<typeof useGetBudgetMasterCoaLazyQuery>;
export type GetBudgetMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetBudgetMasterCoaQuery, GetBudgetMasterCoaQueryVariables>;
export const GetParentMasterCoaForTreeDocument = gql`
    query getParentMasterCOAForTree($MasterCOABudgetArr: [String!], $CompanyID: String!) {
  getParentMasterCOAForTree(MasterCOABudgetArr: $MasterCOABudgetArr, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetParentMasterCoaForTreeQuery__
 *
 * To run a query within a React component, call `useGetParentMasterCoaForTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentMasterCoaForTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentMasterCoaForTreeQuery({
 *   variables: {
 *      MasterCOABudgetArr: // value for 'MasterCOABudgetArr'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetParentMasterCoaForTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentMasterCoaForTreeQuery, GetParentMasterCoaForTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentMasterCoaForTreeQuery, GetParentMasterCoaForTreeQueryVariables>(GetParentMasterCoaForTreeDocument, baseOptions);
      }
export function useGetParentMasterCoaForTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentMasterCoaForTreeQuery, GetParentMasterCoaForTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentMasterCoaForTreeQuery, GetParentMasterCoaForTreeQueryVariables>(GetParentMasterCoaForTreeDocument, baseOptions);
        }
export type GetParentMasterCoaForTreeQueryHookResult = ReturnType<typeof useGetParentMasterCoaForTreeQuery>;
export type GetParentMasterCoaForTreeLazyQueryHookResult = ReturnType<typeof useGetParentMasterCoaForTreeLazyQuery>;
export type GetParentMasterCoaForTreeQueryResult = ApolloReactCommon.QueryResult<GetParentMasterCoaForTreeQuery, GetParentMasterCoaForTreeQueryVariables>;
export const GetBudgetCostCentreDocument = gql`
    query getBudgetCostCentre($CompanyID: String!) {
  getBudgetCostCentre(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetBudgetCostCentreQuery__
 *
 * To run a query within a React component, call `useGetBudgetCostCentreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetCostCentreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetCostCentreQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetBudgetCostCentreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetCostCentreQuery, GetBudgetCostCentreQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetCostCentreQuery, GetBudgetCostCentreQueryVariables>(GetBudgetCostCentreDocument, baseOptions);
      }
export function useGetBudgetCostCentreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetCostCentreQuery, GetBudgetCostCentreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetCostCentreQuery, GetBudgetCostCentreQueryVariables>(GetBudgetCostCentreDocument, baseOptions);
        }
export type GetBudgetCostCentreQueryHookResult = ReturnType<typeof useGetBudgetCostCentreQuery>;
export type GetBudgetCostCentreLazyQueryHookResult = ReturnType<typeof useGetBudgetCostCentreLazyQuery>;
export type GetBudgetCostCentreQueryResult = ApolloReactCommon.QueryResult<GetBudgetCostCentreQuery, GetBudgetCostCentreQueryVariables>;
export const GetBudgetImportListDocument = gql`
    query getBudgetImportList($CompanyID: String!, $PeriodBudgetItemInput: [PeriodBudgetItemInput!]) {
  getBudgetImportList(CompanyID: $CompanyID, PeriodBudgetItemInput: $PeriodBudgetItemInput)
}
    `;

/**
 * __useGetBudgetImportListQuery__
 *
 * To run a query within a React component, call `useGetBudgetImportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetImportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetImportListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PeriodBudgetItemInput: // value for 'PeriodBudgetItemInput'
 *   },
 * });
 */
export function useGetBudgetImportListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBudgetImportListQuery, GetBudgetImportListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBudgetImportListQuery, GetBudgetImportListQueryVariables>(GetBudgetImportListDocument, baseOptions);
      }
export function useGetBudgetImportListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBudgetImportListQuery, GetBudgetImportListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBudgetImportListQuery, GetBudgetImportListQueryVariables>(GetBudgetImportListDocument, baseOptions);
        }
export type GetBudgetImportListQueryHookResult = ReturnType<typeof useGetBudgetImportListQuery>;
export type GetBudgetImportListLazyQueryHookResult = ReturnType<typeof useGetBudgetImportListLazyQuery>;
export type GetBudgetImportListQueryResult = ApolloReactCommon.QueryResult<GetBudgetImportListQuery, GetBudgetImportListQueryVariables>;
export const ImportExcelBudgetDocument = gql`
    query ImportExcelBudget($CompanyID: String!, $Year: Float!, $BudgetexcelImportInput: String!) {
  ImportExcelBudget(CompanyID: $CompanyID, Year: $Year, BudgetexcelImportInput: $BudgetexcelImportInput)
}
    `;

/**
 * __useImportExcelBudgetQuery__
 *
 * To run a query within a React component, call `useImportExcelBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportExcelBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportExcelBudgetQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      BudgetexcelImportInput: // value for 'BudgetexcelImportInput'
 *   },
 * });
 */
export function useImportExcelBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportExcelBudgetQuery, ImportExcelBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportExcelBudgetQuery, ImportExcelBudgetQueryVariables>(ImportExcelBudgetDocument, baseOptions);
      }
export function useImportExcelBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportExcelBudgetQuery, ImportExcelBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportExcelBudgetQuery, ImportExcelBudgetQueryVariables>(ImportExcelBudgetDocument, baseOptions);
        }
export type ImportExcelBudgetQueryHookResult = ReturnType<typeof useImportExcelBudgetQuery>;
export type ImportExcelBudgetLazyQueryHookResult = ReturnType<typeof useImportExcelBudgetLazyQuery>;
export type ImportExcelBudgetQueryResult = ApolloReactCommon.QueryResult<ImportExcelBudgetQuery, ImportExcelBudgetQueryVariables>;
export const GetExternalJournalDocument = gql`
    query getExternalJournal($AccountID: String, $CompanyID: String, $ExternalJournalID: String, $IsManual: Boolean, $RefRecordID: String, $orderByAsc: String) {
  getExternalJournal(AccountID: $AccountID, CompanyID: $CompanyID, ExternalJournalID: $ExternalJournalID, orderByAsc: $orderByAsc, IsManual: $IsManual, RefRecordID: $RefRecordID) {
    ExternalJournalID
    JournalDate
    DocNo
    IsManual
    CompanyID
    JournalTypeID
    RefNo
    Description
    DocRefName
    AccountName
    ApprovalStatus
    JournalType {
      JournalType
      Description
    }
    ExternalJournalItem {
      MasterCOAID
      MasterCOA {
        Name
        Code
        Level
      }
      CostCentreID
      CostCentre {
        Name
      }
      DocAmt
      TaxInvoiceNo
      Remark
    }
  }
}
    `;

/**
 * __useGetExternalJournalQuery__
 *
 * To run a query within a React component, call `useGetExternalJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalJournalQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      ExternalJournalID: // value for 'ExternalJournalID'
 *      IsManual: // value for 'IsManual'
 *      RefRecordID: // value for 'RefRecordID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetExternalJournalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExternalJournalQuery, GetExternalJournalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExternalJournalQuery, GetExternalJournalQueryVariables>(GetExternalJournalDocument, baseOptions);
      }
export function useGetExternalJournalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExternalJournalQuery, GetExternalJournalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExternalJournalQuery, GetExternalJournalQueryVariables>(GetExternalJournalDocument, baseOptions);
        }
export type GetExternalJournalQueryHookResult = ReturnType<typeof useGetExternalJournalQuery>;
export type GetExternalJournalLazyQueryHookResult = ReturnType<typeof useGetExternalJournalLazyQuery>;
export type GetExternalJournalQueryResult = ApolloReactCommon.QueryResult<GetExternalJournalQuery, GetExternalJournalQueryVariables>;
export const GetExternalJournalListDocument = gql`
    query getExternalJournalList($AccountID: String, $CompanyID: String, $ExternalJournalID: String, $IsManual: Boolean, $RefRecordID: String, $orderByAsc: String) {
  getExternalJournal(AccountID: $AccountID, CompanyID: $CompanyID, ExternalJournalID: $ExternalJournalID, orderByAsc: $orderByAsc, IsManual: $IsManual, RefRecordID: $RefRecordID) {
    ExternalJournalID
    JournalDate
    DocNo
    IsManual
    RefNo
    Description
    DocRefName
    AccountName
    ApprovalStatus
    JournalTypeID
    JournalType {
      JournalType
      Description
    }
    ItemCount
    ItemAmount
  }
}
    `;

/**
 * __useGetExternalJournalListQuery__
 *
 * To run a query within a React component, call `useGetExternalJournalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalJournalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalJournalListQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      ExternalJournalID: // value for 'ExternalJournalID'
 *      IsManual: // value for 'IsManual'
 *      RefRecordID: // value for 'RefRecordID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetExternalJournalListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExternalJournalListQuery, GetExternalJournalListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExternalJournalListQuery, GetExternalJournalListQueryVariables>(GetExternalJournalListDocument, baseOptions);
      }
export function useGetExternalJournalListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExternalJournalListQuery, GetExternalJournalListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExternalJournalListQuery, GetExternalJournalListQueryVariables>(GetExternalJournalListDocument, baseOptions);
        }
export type GetExternalJournalListQueryHookResult = ReturnType<typeof useGetExternalJournalListQuery>;
export type GetExternalJournalListLazyQueryHookResult = ReturnType<typeof useGetExternalJournalListLazyQuery>;
export type GetExternalJournalListQueryResult = ApolloReactCommon.QueryResult<GetExternalJournalListQuery, GetExternalJournalListQueryVariables>;
export const GetExternalJournalListingDocument = gql`
    query getExternalJournalListing($AccountID: String, $CompanyID: String, $ExternalJournalID: String, $IsManual: Boolean, $orderByAsc: String, $skip: Float, $take: Float, $searchValue: String) {
  getExternalJournal(AccountID: $AccountID, CompanyID: $CompanyID, ExternalJournalID: $ExternalJournalID, orderByAsc: $orderByAsc, IsManual: $IsManual, skip: $skip, take: $take, searchValue: $searchValue) {
    ExternalJournalID
    JournalDate
    DocNo
    IsManual
    CompanyID
    JournalTypeID
    RefNo
    Description
    DocRefName
    AccountName
    ApprovalStatus
    JournalType {
      JournalType
      Description
    }
    ExternalJournalItem {
      MasterCOAID
      MasterCOA {
        Name
        Code
        Level
      }
      CostCentreID
      CostCentre {
        Name
      }
      DocAmt
      TaxInvoiceNo
      Remark
    }
  }
  getExternalJournalCount(AccountID: $AccountID, CompanyID: $CompanyID, ExternalJournalID: $ExternalJournalID, IsManual: $IsManual, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetExternalJournalListingQuery__
 *
 * To run a query within a React component, call `useGetExternalJournalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalJournalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalJournalListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      ExternalJournalID: // value for 'ExternalJournalID'
 *      IsManual: // value for 'IsManual'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetExternalJournalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExternalJournalListingQuery, GetExternalJournalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExternalJournalListingQuery, GetExternalJournalListingQueryVariables>(GetExternalJournalListingDocument, baseOptions);
      }
export function useGetExternalJournalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExternalJournalListingQuery, GetExternalJournalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExternalJournalListingQuery, GetExternalJournalListingQueryVariables>(GetExternalJournalListingDocument, baseOptions);
        }
export type GetExternalJournalListingQueryHookResult = ReturnType<typeof useGetExternalJournalListingQuery>;
export type GetExternalJournalListingLazyQueryHookResult = ReturnType<typeof useGetExternalJournalListingLazyQuery>;
export type GetExternalJournalListingQueryResult = ApolloReactCommon.QueryResult<GetExternalJournalListingQuery, GetExternalJournalListingQueryVariables>;
export const CreateExternalJournalDocument = gql`
    mutation createExternalJournal($ExtJournalInput: ExcelJournalInput!, $CompanyID: String!, $DocRefName: String!) {
  createExternalJournal(ExtJournalInput: $ExtJournalInput, CompanyID: $CompanyID, DocRefName: $DocRefName)
}
    `;
export type CreateExternalJournalMutationFn = ApolloReactCommon.MutationFunction<CreateExternalJournalMutation, CreateExternalJournalMutationVariables>;

/**
 * __useCreateExternalJournalMutation__
 *
 * To run a mutation, you first call `useCreateExternalJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalJournalMutation, { data, loading, error }] = useCreateExternalJournalMutation({
 *   variables: {
 *      ExtJournalInput: // value for 'ExtJournalInput'
 *      CompanyID: // value for 'CompanyID'
 *      DocRefName: // value for 'DocRefName'
 *   },
 * });
 */
export function useCreateExternalJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExternalJournalMutation, CreateExternalJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExternalJournalMutation, CreateExternalJournalMutationVariables>(CreateExternalJournalDocument, baseOptions);
      }
export type CreateExternalJournalMutationHookResult = ReturnType<typeof useCreateExternalJournalMutation>;
export type CreateExternalJournalMutationResult = ApolloReactCommon.MutationResult<CreateExternalJournalMutation>;
export type CreateExternalJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExternalJournalMutation, CreateExternalJournalMutationVariables>;
export const GetFavoriteJournalProcessingDocument = gql`
    query getFavoriteJournalProcessing($UserID: String, $CompanyID: String, $FavoriteJournalProcessingID: String) {
  getFavoriteJournalProcessing(UserID: $UserID, CompanyID: $CompanyID, FavoriteJournalProcessingID: $FavoriteJournalProcessingID) {
    createdBy
    FavoriteJournalProcessingID
    Name
    JournalTypeID
    JournalType {
      JournalType
    }
    RefNo
    Description
    FavoriteJournalProcessingItem {
      FavoriteJournalProcessingID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
      }
      CostCentreID
      CostCentre {
        Name
      }
      DocAmt
      Remark
    }
  }
}
    `;

/**
 * __useGetFavoriteJournalProcessingQuery__
 *
 * To run a query within a React component, call `useGetFavoriteJournalProcessingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteJournalProcessingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteJournalProcessingQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoriteJournalProcessingID: // value for 'FavoriteJournalProcessingID'
 *   },
 * });
 */
export function useGetFavoriteJournalProcessingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoriteJournalProcessingQuery, GetFavoriteJournalProcessingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoriteJournalProcessingQuery, GetFavoriteJournalProcessingQueryVariables>(GetFavoriteJournalProcessingDocument, baseOptions);
      }
export function useGetFavoriteJournalProcessingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoriteJournalProcessingQuery, GetFavoriteJournalProcessingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoriteJournalProcessingQuery, GetFavoriteJournalProcessingQueryVariables>(GetFavoriteJournalProcessingDocument, baseOptions);
        }
export type GetFavoriteJournalProcessingQueryHookResult = ReturnType<typeof useGetFavoriteJournalProcessingQuery>;
export type GetFavoriteJournalProcessingLazyQueryHookResult = ReturnType<typeof useGetFavoriteJournalProcessingLazyQuery>;
export type GetFavoriteJournalProcessingQueryResult = ApolloReactCommon.QueryResult<GetFavoriteJournalProcessingQuery, GetFavoriteJournalProcessingQueryVariables>;
export const CreateFavoriteJournalProcessingDocument = gql`
    mutation createFavoriteJournalProcessing($FavoriteJournalProcessingInput: FavoriteJournalProcessingInput!, $FavoriteJournalProcessingItemInput: [FavoriteJournalProcessingItemInput!]!) {
  createFavoriteJournalProcessing(FavoriteJournalProcessingInput: $FavoriteJournalProcessingInput, FavoriteJournalProcessingItemInput: $FavoriteJournalProcessingItemInput)
}
    `;
export type CreateFavoriteJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<CreateFavoriteJournalProcessingMutation, CreateFavoriteJournalProcessingMutationVariables>;

/**
 * __useCreateFavoriteJournalProcessingMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteJournalProcessingMutation, { data, loading, error }] = useCreateFavoriteJournalProcessingMutation({
 *   variables: {
 *      FavoriteJournalProcessingInput: // value for 'FavoriteJournalProcessingInput'
 *      FavoriteJournalProcessingItemInput: // value for 'FavoriteJournalProcessingItemInput'
 *   },
 * });
 */
export function useCreateFavoriteJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFavoriteJournalProcessingMutation, CreateFavoriteJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFavoriteJournalProcessingMutation, CreateFavoriteJournalProcessingMutationVariables>(CreateFavoriteJournalProcessingDocument, baseOptions);
      }
export type CreateFavoriteJournalProcessingMutationHookResult = ReturnType<typeof useCreateFavoriteJournalProcessingMutation>;
export type CreateFavoriteJournalProcessingMutationResult = ApolloReactCommon.MutationResult<CreateFavoriteJournalProcessingMutation>;
export type CreateFavoriteJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFavoriteJournalProcessingMutation, CreateFavoriteJournalProcessingMutationVariables>;
export const DeleteFavoriteJournalProcessingDocument = gql`
    mutation deleteFavoriteJournalProcessing($FavoriteJournalProcessingID: String!) {
  deleteFavoriteJournalProcessing(FavoriteJournalProcessingID: $FavoriteJournalProcessingID)
}
    `;
export type DeleteFavoriteJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<DeleteFavoriteJournalProcessingMutation, DeleteFavoriteJournalProcessingMutationVariables>;

/**
 * __useDeleteFavoriteJournalProcessingMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteJournalProcessingMutation, { data, loading, error }] = useDeleteFavoriteJournalProcessingMutation({
 *   variables: {
 *      FavoriteJournalProcessingID: // value for 'FavoriteJournalProcessingID'
 *   },
 * });
 */
export function useDeleteFavoriteJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFavoriteJournalProcessingMutation, DeleteFavoriteJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFavoriteJournalProcessingMutation, DeleteFavoriteJournalProcessingMutationVariables>(DeleteFavoriteJournalProcessingDocument, baseOptions);
      }
export type DeleteFavoriteJournalProcessingMutationHookResult = ReturnType<typeof useDeleteFavoriteJournalProcessingMutation>;
export type DeleteFavoriteJournalProcessingMutationResult = ApolloReactCommon.MutationResult<DeleteFavoriteJournalProcessingMutation>;
export type DeleteFavoriteJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFavoriteJournalProcessingMutation, DeleteFavoriteJournalProcessingMutationVariables>;
export const GetJournalExportDotNetDocument = gql`
    query getJournalExportDotNet($CompanyID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  getJournalExportDotNet(CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useGetJournalExportDotNetQuery__
 *
 * To run a query within a React component, call `useGetJournalExportDotNetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalExportDotNetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalExportDotNetQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetJournalExportDotNetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalExportDotNetQuery, GetJournalExportDotNetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalExportDotNetQuery, GetJournalExportDotNetQueryVariables>(GetJournalExportDotNetDocument, baseOptions);
      }
export function useGetJournalExportDotNetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalExportDotNetQuery, GetJournalExportDotNetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalExportDotNetQuery, GetJournalExportDotNetQueryVariables>(GetJournalExportDotNetDocument, baseOptions);
        }
export type GetJournalExportDotNetQueryHookResult = ReturnType<typeof useGetJournalExportDotNetQuery>;
export type GetJournalExportDotNetLazyQueryHookResult = ReturnType<typeof useGetJournalExportDotNetLazyQuery>;
export type GetJournalExportDotNetQueryResult = ApolloReactCommon.QueryResult<GetJournalExportDotNetQuery, GetJournalExportDotNetQueryVariables>;
export const GetGlExportJournalDetailDocument = gql`
    query getGLExportJournalDetail($AccountID: String, $CompanyID: String, $JournalID: String, $IsManual: Boolean, $RefRecordID: String) {
  getJournal(AccountID: $AccountID, CompanyID: $CompanyID, JournalID: $JournalID, IsManual: $IsManual, RefRecordID: $RefRecordID) {
    JournalID
    createdTs
    JournalDate
    JournalNo
    IsManual
    CompanyID
    JournalTypeID
    RefNo
    Description
    ExportDotNetID
    ExportDotNetError
    ApprovalStatus
    JournalType {
      JournalType
      Description
    }
    JournalItem {
      createdTs
      modTs
      Remark
      MasterCOAID
      MasterCOA {
        Name
        Code
        Level
      }
      CostCentreID
      CostCentre {
        Name
      }
      DocAmt
      TaxInvoiceNo
    }
  }
}
    `;

/**
 * __useGetGlExportJournalDetailQuery__
 *
 * To run a query within a React component, call `useGetGlExportJournalDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportJournalDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportJournalDetailQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      JournalID: // value for 'JournalID'
 *      IsManual: // value for 'IsManual'
 *      RefRecordID: // value for 'RefRecordID'
 *   },
 * });
 */
export function useGetGlExportJournalDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportJournalDetailQuery, GetGlExportJournalDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportJournalDetailQuery, GetGlExportJournalDetailQueryVariables>(GetGlExportJournalDetailDocument, baseOptions);
      }
export function useGetGlExportJournalDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportJournalDetailQuery, GetGlExportJournalDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportJournalDetailQuery, GetGlExportJournalDetailQueryVariables>(GetGlExportJournalDetailDocument, baseOptions);
        }
export type GetGlExportJournalDetailQueryHookResult = ReturnType<typeof useGetGlExportJournalDetailQuery>;
export type GetGlExportJournalDetailLazyQueryHookResult = ReturnType<typeof useGetGlExportJournalDetailLazyQuery>;
export type GetGlExportJournalDetailQueryResult = ApolloReactCommon.QueryResult<GetGlExportJournalDetailQuery, GetGlExportJournalDetailQueryVariables>;
export const GetGlExportDotNetDocument = gql`
    query getGLExportDotNet($ExportDotNetID: String, $orderByAsc: String) {
  getGLExportDotNet(ExportDotNetID: $ExportDotNetID, orderByAsc: $orderByAsc) {
    ExportDotNetID
    CompanyID
    StartDate
    EndDate
    ExportDate
    createdTs
    createdBy
    Journal {
      JournalID
    }
  }
}
    `;

/**
 * __useGetGlExportDotNetQuery__
 *
 * To run a query within a React component, call `useGetGlExportDotNetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportDotNetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportDotNetQuery({
 *   variables: {
 *      ExportDotNetID: // value for 'ExportDotNetID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetGlExportDotNetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportDotNetQuery, GetGlExportDotNetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportDotNetQuery, GetGlExportDotNetQueryVariables>(GetGlExportDotNetDocument, baseOptions);
      }
export function useGetGlExportDotNetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportDotNetQuery, GetGlExportDotNetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportDotNetQuery, GetGlExportDotNetQueryVariables>(GetGlExportDotNetDocument, baseOptions);
        }
export type GetGlExportDotNetQueryHookResult = ReturnType<typeof useGetGlExportDotNetQuery>;
export type GetGlExportDotNetLazyQueryHookResult = ReturnType<typeof useGetGlExportDotNetLazyQuery>;
export type GetGlExportDotNetQueryResult = ApolloReactCommon.QueryResult<GetGlExportDotNetQuery, GetGlExportDotNetQueryVariables>;
export const GetGlExportDotNetDetailDocument = gql`
    query getGLExportDotNetDetail($ExportDotNetID: String!) {
  getGLExportDotNetDetail(ExportDotNetID: $ExportDotNetID)
}
    `;

/**
 * __useGetGlExportDotNetDetailQuery__
 *
 * To run a query within a React component, call `useGetGlExportDotNetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlExportDotNetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlExportDotNetDetailQuery({
 *   variables: {
 *      ExportDotNetID: // value for 'ExportDotNetID'
 *   },
 * });
 */
export function useGetGlExportDotNetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlExportDotNetDetailQuery, GetGlExportDotNetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlExportDotNetDetailQuery, GetGlExportDotNetDetailQueryVariables>(GetGlExportDotNetDetailDocument, baseOptions);
      }
export function useGetGlExportDotNetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlExportDotNetDetailQuery, GetGlExportDotNetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlExportDotNetDetailQuery, GetGlExportDotNetDetailQueryVariables>(GetGlExportDotNetDetailDocument, baseOptions);
        }
export type GetGlExportDotNetDetailQueryHookResult = ReturnType<typeof useGetGlExportDotNetDetailQuery>;
export type GetGlExportDotNetDetailLazyQueryHookResult = ReturnType<typeof useGetGlExportDotNetDetailLazyQuery>;
export type GetGlExportDotNetDetailQueryResult = ApolloReactCommon.QueryResult<GetGlExportDotNetDetailQuery, GetGlExportDotNetDetailQueryVariables>;
export const ExportGlToDotNetDocument = gql`
    mutation ExportGLToDotNet($input: GLExportDotNetInput!, $JournalIDs: [String!]!) {
  ExportGLToDotNet(input: $input, JournalIDs: $JournalIDs) {
    Success
    Fail
    GenerateExportDotNet
  }
}
    `;
export type ExportGlToDotNetMutationFn = ApolloReactCommon.MutationFunction<ExportGlToDotNetMutation, ExportGlToDotNetMutationVariables>;

/**
 * __useExportGlToDotNetMutation__
 *
 * To run a mutation, you first call `useExportGlToDotNetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportGlToDotNetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportGlToDotNetMutation, { data, loading, error }] = useExportGlToDotNetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      JournalIDs: // value for 'JournalIDs'
 *   },
 * });
 */
export function useExportGlToDotNetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportGlToDotNetMutation, ExportGlToDotNetMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportGlToDotNetMutation, ExportGlToDotNetMutationVariables>(ExportGlToDotNetDocument, baseOptions);
      }
export type ExportGlToDotNetMutationHookResult = ReturnType<typeof useExportGlToDotNetMutation>;
export type ExportGlToDotNetMutationResult = ApolloReactCommon.MutationResult<ExportGlToDotNetMutation>;
export type ExportGlToDotNetMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportGlToDotNetMutation, ExportGlToDotNetMutationVariables>;
export const GetGlSubmenuSummaryDocument = gql`
    query getGLSubmenuSummary($CompanyID: String!) {
  journalProcessingCount(CompanyID: $CompanyID)
  recurringJournalCount(CompanyID: $CompanyID)
  auditAdjustmentCount(CompanyID: $CompanyID)
  RoutineJournalDate
  GenerateRJCount(CompanyID: $CompanyID)
  RecurringJournalDate
  JournalPending
  JournalProcessingDate
  AuditAdjustmentDate
  OriginalBudgetDate(CompanyID: $CompanyID)
  ExternalJournalDate(CompanyID: $CompanyID)
  GLDigitalReportDate
}
    `;

/**
 * __useGetGlSubmenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetGlSubmenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlSubmenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlSubmenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetGlSubmenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlSubmenuSummaryQuery, GetGlSubmenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlSubmenuSummaryQuery, GetGlSubmenuSummaryQueryVariables>(GetGlSubmenuSummaryDocument, baseOptions);
      }
export function useGetGlSubmenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlSubmenuSummaryQuery, GetGlSubmenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlSubmenuSummaryQuery, GetGlSubmenuSummaryQueryVariables>(GetGlSubmenuSummaryDocument, baseOptions);
        }
export type GetGlSubmenuSummaryQueryHookResult = ReturnType<typeof useGetGlSubmenuSummaryQuery>;
export type GetGlSubmenuSummaryLazyQueryHookResult = ReturnType<typeof useGetGlSubmenuSummaryLazyQuery>;
export type GetGlSubmenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetGlSubmenuSummaryQuery, GetGlSubmenuSummaryQueryVariables>;
export const GetRecurringJournalGenerationDocument = gql`
    query getRecurringJournalGeneration($CompanyID: String!) {
  getRecurringJournalGeneration(CompanyID: $CompanyID) {
    RecurringJournalGenerationID
    FYear
    FPeriod
    Description
    RefNo
    IsGenerated
    JournalTypeID
    RecurringJournalGenerationItem {
      RecurringJournalGenerationItemID
      DocAmt
    }
    JournalType {
      JournalTypeID
      JournalType
    }
  }
}
    `;

/**
 * __useGetRecurringJournalGenerationQuery__
 *
 * To run a query within a React component, call `useGetRecurringJournalGenerationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringJournalGenerationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringJournalGenerationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetRecurringJournalGenerationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurringJournalGenerationQuery, GetRecurringJournalGenerationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurringJournalGenerationQuery, GetRecurringJournalGenerationQueryVariables>(GetRecurringJournalGenerationDocument, baseOptions);
      }
export function useGetRecurringJournalGenerationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurringJournalGenerationQuery, GetRecurringJournalGenerationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurringJournalGenerationQuery, GetRecurringJournalGenerationQueryVariables>(GetRecurringJournalGenerationDocument, baseOptions);
        }
export type GetRecurringJournalGenerationQueryHookResult = ReturnType<typeof useGetRecurringJournalGenerationQuery>;
export type GetRecurringJournalGenerationLazyQueryHookResult = ReturnType<typeof useGetRecurringJournalGenerationLazyQuery>;
export type GetRecurringJournalGenerationQueryResult = ApolloReactCommon.QueryResult<GetRecurringJournalGenerationQuery, GetRecurringJournalGenerationQueryVariables>;
export const GenerateRecurringJournalDocument = gql`
    mutation generateRecurringJournal($CompanyID: String!, $AccountPeriodID: String) {
  generateRecurringJournal(CompanyID: $CompanyID, AccountPeriodID: $AccountPeriodID)
}
    `;
export type GenerateRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<GenerateRecurringJournalMutation, GenerateRecurringJournalMutationVariables>;

/**
 * __useGenerateRecurringJournalMutation__
 *
 * To run a mutation, you first call `useGenerateRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRecurringJournalMutation, { data, loading, error }] = useGenerateRecurringJournalMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountPeriodID: // value for 'AccountPeriodID'
 *   },
 * });
 */
export function useGenerateRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateRecurringJournalMutation, GenerateRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateRecurringJournalMutation, GenerateRecurringJournalMutationVariables>(GenerateRecurringJournalDocument, baseOptions);
      }
export type GenerateRecurringJournalMutationHookResult = ReturnType<typeof useGenerateRecurringJournalMutation>;
export type GenerateRecurringJournalMutationResult = ApolloReactCommon.MutationResult<GenerateRecurringJournalMutation>;
export type GenerateRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateRecurringJournalMutation, GenerateRecurringJournalMutationVariables>;
export const GetJournalDocument = gql`
    query getJournal($AccountID: String, $CompanyID: String, $JournalID: String, $IsManual: Boolean, $RefRecordID: String) {
  getJournal(AccountID: $AccountID, CompanyID: $CompanyID, JournalID: $JournalID, IsManual: $IsManual, RefRecordID: $RefRecordID) {
    JournalID
    createdTs
    JournalDate
    JournalNo
    IsManual
    CompanyID
    JournalTypeID
    RefNo
    Description
    ApprovalStatus
    JournalType {
      JournalType
      Description
    }
    JournalItem {
      createdTs
      modTs
      Remark
      MasterCOAID
      MasterCOA {
        Name
        Code
        Level
      }
      CostCentreID
      CostCentre {
        Name
      }
      DocAmt
      TaxInvoiceNo
    }
  }
}
    `;

/**
 * __useGetJournalQuery__
 *
 * To run a query within a React component, call `useGetJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      JournalID: // value for 'JournalID'
 *      IsManual: // value for 'IsManual'
 *      RefRecordID: // value for 'RefRecordID'
 *   },
 * });
 */
export function useGetJournalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalQuery, GetJournalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalQuery, GetJournalQueryVariables>(GetJournalDocument, baseOptions);
      }
export function useGetJournalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalQuery, GetJournalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalQuery, GetJournalQueryVariables>(GetJournalDocument, baseOptions);
        }
export type GetJournalQueryHookResult = ReturnType<typeof useGetJournalQuery>;
export type GetJournalLazyQueryHookResult = ReturnType<typeof useGetJournalLazyQuery>;
export type GetJournalQueryResult = ApolloReactCommon.QueryResult<GetJournalQuery, GetJournalQueryVariables>;
export const GetJournalbyStatusDocument = gql`
    query getJournalbyStatus($CompanyID: String!, $TxnArr: [String!]!, $StartDate: DateTime, $EndDate: DateTime) {
  getJournalbyStatus(CompanyID: $CompanyID, TxnArr: $TxnArr, StartDate: $StartDate, EndDate: $EndDate) {
    JournalID
    createdTs
    JournalDate
    JournalNo
    IsManual
    CompanyID
    JournalTypeID
    RefNo
    Description
    ApprovalStatus
    JournalType {
      JournalType
      Description
    }
    JournalItem {
      createdTs
      modTs
      Remark
      MasterCOAID
      MasterCOA {
        Name
        Code
        Level
      }
      CostCentreID
      CostCentre {
        Name
      }
      DocAmt
      TaxInvoiceNo
    }
  }
}
    `;

/**
 * __useGetJournalbyStatusQuery__
 *
 * To run a query within a React component, call `useGetJournalbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      TxnArr: // value for 'TxnArr'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetJournalbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalbyStatusQuery, GetJournalbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalbyStatusQuery, GetJournalbyStatusQueryVariables>(GetJournalbyStatusDocument, baseOptions);
      }
export function useGetJournalbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalbyStatusQuery, GetJournalbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalbyStatusQuery, GetJournalbyStatusQueryVariables>(GetJournalbyStatusDocument, baseOptions);
        }
export type GetJournalbyStatusQueryHookResult = ReturnType<typeof useGetJournalbyStatusQuery>;
export type GetJournalbyStatusLazyQueryHookResult = ReturnType<typeof useGetJournalbyStatusLazyQuery>;
export type GetJournalbyStatusQueryResult = ApolloReactCommon.QueryResult<GetJournalbyStatusQuery, GetJournalbyStatusQueryVariables>;
export const GetJournalbyStatusListingDocument = gql`
    query getJournalbyStatusListing($CompanyID: String!, $TxnArr: [String!]!, $StartDate: DateTime, $EndDate: DateTime, $searchValue: String, $skip: Float, $take: Float) {
  getJournalbyStatusListing(CompanyID: $CompanyID, TxnArr: $TxnArr, StartDate: $StartDate, EndDate: $EndDate, searchValue: $searchValue, skip: $skip, take: $take) {
    Data {
      JournalID
      createdTs
      JournalDate
      JournalNo
      IsManual
      CompanyID
      JournalTypeID
      RefNo
      Description
      ApprovalStatus
      JournalType {
        JournalType
        Description
      }
      JournalItem {
        createdTs
        modTs
        Remark
        MasterCOAID
        MasterCOA {
          Name
          Code
          Level
        }
        CostCentreID
        CostCentre {
          Name
        }
        DocAmt
        TaxInvoiceNo
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetJournalbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetJournalbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      TxnArr: // value for 'TxnArr'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetJournalbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalbyStatusListingQuery, GetJournalbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalbyStatusListingQuery, GetJournalbyStatusListingQueryVariables>(GetJournalbyStatusListingDocument, baseOptions);
      }
export function useGetJournalbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalbyStatusListingQuery, GetJournalbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalbyStatusListingQuery, GetJournalbyStatusListingQueryVariables>(GetJournalbyStatusListingDocument, baseOptions);
        }
export type GetJournalbyStatusListingQueryHookResult = ReturnType<typeof useGetJournalbyStatusListingQuery>;
export type GetJournalbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetJournalbyStatusListingLazyQuery>;
export type GetJournalbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetJournalbyStatusListingQuery, GetJournalbyStatusListingQueryVariables>;
export const CreateJournalDocument = gql`
    mutation createJournal($JournalInput: JournalInput!, $JournalItemInput: [JournalItemInput!]!) {
  createJournal(JournalInput: $JournalInput, JournalItemInput: $JournalItemInput)
}
    `;
export type CreateJournalMutationFn = ApolloReactCommon.MutationFunction<CreateJournalMutation, CreateJournalMutationVariables>;

/**
 * __useCreateJournalMutation__
 *
 * To run a mutation, you first call `useCreateJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJournalMutation, { data, loading, error }] = useCreateJournalMutation({
 *   variables: {
 *      JournalInput: // value for 'JournalInput'
 *      JournalItemInput: // value for 'JournalItemInput'
 *   },
 * });
 */
export function useCreateJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJournalMutation, CreateJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJournalMutation, CreateJournalMutationVariables>(CreateJournalDocument, baseOptions);
      }
export type CreateJournalMutationHookResult = ReturnType<typeof useCreateJournalMutation>;
export type CreateJournalMutationResult = ApolloReactCommon.MutationResult<CreateJournalMutation>;
export type CreateJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJournalMutation, CreateJournalMutationVariables>;
export const UpdateJournalDocument = gql`
    mutation updateJournal($JournalInput: JournalInput!, $JournalItemInput: [JournalItemInput!]!) {
  updateJournal(JournalInput: $JournalInput, JournalItemInput: $JournalItemInput)
}
    `;
export type UpdateJournalMutationFn = ApolloReactCommon.MutationFunction<UpdateJournalMutation, UpdateJournalMutationVariables>;

/**
 * __useUpdateJournalMutation__
 *
 * To run a mutation, you first call `useUpdateJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalMutation, { data, loading, error }] = useUpdateJournalMutation({
 *   variables: {
 *      JournalInput: // value for 'JournalInput'
 *      JournalItemInput: // value for 'JournalItemInput'
 *   },
 * });
 */
export function useUpdateJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJournalMutation, UpdateJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJournalMutation, UpdateJournalMutationVariables>(UpdateJournalDocument, baseOptions);
      }
export type UpdateJournalMutationHookResult = ReturnType<typeof useUpdateJournalMutation>;
export type UpdateJournalMutationResult = ApolloReactCommon.MutationResult<UpdateJournalMutation>;
export type UpdateJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJournalMutation, UpdateJournalMutationVariables>;
export const CancelJournalDocument = gql`
    mutation cancelJournal($input: JournalDeleteInput!) {
  cancelJournal(input: $input)
}
    `;
export type CancelJournalMutationFn = ApolloReactCommon.MutationFunction<CancelJournalMutation, CancelJournalMutationVariables>;

/**
 * __useCancelJournalMutation__
 *
 * To run a mutation, you first call `useCancelJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJournalMutation, { data, loading, error }] = useCancelJournalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelJournalMutation, CancelJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelJournalMutation, CancelJournalMutationVariables>(CancelJournalDocument, baseOptions);
      }
export type CancelJournalMutationHookResult = ReturnType<typeof useCancelJournalMutation>;
export type CancelJournalMutationResult = ApolloReactCommon.MutationResult<CancelJournalMutation>;
export type CancelJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelJournalMutation, CancelJournalMutationVariables>;
export const DeleteJournalDocument = gql`
    mutation deleteJournal($input: JournalDeleteInput!) {
  deleteJournal(input: $input)
}
    `;
export type DeleteJournalMutationFn = ApolloReactCommon.MutationFunction<DeleteJournalMutation, DeleteJournalMutationVariables>;

/**
 * __useDeleteJournalMutation__
 *
 * To run a mutation, you first call `useDeleteJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJournalMutation, { data, loading, error }] = useDeleteJournalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJournalMutation, DeleteJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJournalMutation, DeleteJournalMutationVariables>(DeleteJournalDocument, baseOptions);
      }
export type DeleteJournalMutationHookResult = ReturnType<typeof useDeleteJournalMutation>;
export type DeleteJournalMutationResult = ApolloReactCommon.MutationResult<DeleteJournalMutation>;
export type DeleteJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJournalMutation, DeleteJournalMutationVariables>;
export const GetJournalProcessingDocument = gql`
    query getJournalProcessing($AccountID: String, $CompanyID: String, $JournalProcessingID: String, $orderByAsc: String, $orderByDesc: String) {
  getJournalProcessing(AccountID: $AccountID, CompanyID: $CompanyID, JournalProcessingID: $JournalProcessingID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    JournalProcessingID
    DocDate
    FYear
    FPeriod
    RefNo
    CompanyID
    JournalTypeID
    DocNo
    Description
    ApprovalStatus
    Attachment {
      fileURL
      mediaType
      name
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          createdTs
          StatusRemarks
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    JournalType {
      JournalType
    }
    JournalProcessingItem {
      createdTs
      modTs
      JournalProcessingItemID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
        Code
      }
      CostCentreID
      CostCentre {
        Name
        Code
      }
      DocAmt
      Remark
    }
  }
}
    `;

/**
 * __useGetJournalProcessingQuery__
 *
 * To run a query within a React component, call `useGetJournalProcessingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalProcessingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalProcessingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      JournalProcessingID: // value for 'JournalProcessingID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetJournalProcessingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalProcessingQuery, GetJournalProcessingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalProcessingQuery, GetJournalProcessingQueryVariables>(GetJournalProcessingDocument, baseOptions);
      }
export function useGetJournalProcessingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalProcessingQuery, GetJournalProcessingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalProcessingQuery, GetJournalProcessingQueryVariables>(GetJournalProcessingDocument, baseOptions);
        }
export type GetJournalProcessingQueryHookResult = ReturnType<typeof useGetJournalProcessingQuery>;
export type GetJournalProcessingLazyQueryHookResult = ReturnType<typeof useGetJournalProcessingLazyQuery>;
export type GetJournalProcessingQueryResult = ApolloReactCommon.QueryResult<GetJournalProcessingQuery, GetJournalProcessingQueryVariables>;
export const CreateJournalProcessingDocument = gql`
    mutation createJournalProcessing($input: JournalProcessingInput!, $itemInput: [JournalProcessingItemInput!]) {
  createJournalProcessing(input: $input, itemInput: $itemInput) {
    JournalProcessingID
    ApprovalStatus
  }
}
    `;
export type CreateJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<CreateJournalProcessingMutation, CreateJournalProcessingMutationVariables>;

/**
 * __useCreateJournalProcessingMutation__
 *
 * To run a mutation, you first call `useCreateJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJournalProcessingMutation, { data, loading, error }] = useCreateJournalProcessingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJournalProcessingMutation, CreateJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJournalProcessingMutation, CreateJournalProcessingMutationVariables>(CreateJournalProcessingDocument, baseOptions);
      }
export type CreateJournalProcessingMutationHookResult = ReturnType<typeof useCreateJournalProcessingMutation>;
export type CreateJournalProcessingMutationResult = ApolloReactCommon.MutationResult<CreateJournalProcessingMutation>;
export type CreateJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJournalProcessingMutation, CreateJournalProcessingMutationVariables>;
export const UpdateJournalProcessingDocument = gql`
    mutation updateJournalProcessing($input: JournalProcessingInput!, $itemInput: [JournalProcessingItemInput!]) {
  updateJournalProcessing(input: $input, itemInput: $itemInput) {
    JournalProcessingID
    ApprovalStatus
  }
}
    `;
export type UpdateJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<UpdateJournalProcessingMutation, UpdateJournalProcessingMutationVariables>;

/**
 * __useUpdateJournalProcessingMutation__
 *
 * To run a mutation, you first call `useUpdateJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJournalProcessingMutation, { data, loading, error }] = useUpdateJournalProcessingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJournalProcessingMutation, UpdateJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJournalProcessingMutation, UpdateJournalProcessingMutationVariables>(UpdateJournalProcessingDocument, baseOptions);
      }
export type UpdateJournalProcessingMutationHookResult = ReturnType<typeof useUpdateJournalProcessingMutation>;
export type UpdateJournalProcessingMutationResult = ApolloReactCommon.MutationResult<UpdateJournalProcessingMutation>;
export type UpdateJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJournalProcessingMutation, UpdateJournalProcessingMutationVariables>;
export const DeleteJournalProcessingDocument = gql`
    mutation deleteJournalProcessing($JournalProcessingID: String!) {
  deleteJournalProcessing(JournalProcessingID: $JournalProcessingID)
}
    `;
export type DeleteJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<DeleteJournalProcessingMutation, DeleteJournalProcessingMutationVariables>;

/**
 * __useDeleteJournalProcessingMutation__
 *
 * To run a mutation, you first call `useDeleteJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJournalProcessingMutation, { data, loading, error }] = useDeleteJournalProcessingMutation({
 *   variables: {
 *      JournalProcessingID: // value for 'JournalProcessingID'
 *   },
 * });
 */
export function useDeleteJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJournalProcessingMutation, DeleteJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJournalProcessingMutation, DeleteJournalProcessingMutationVariables>(DeleteJournalProcessingDocument, baseOptions);
      }
export type DeleteJournalProcessingMutationHookResult = ReturnType<typeof useDeleteJournalProcessingMutation>;
export type DeleteJournalProcessingMutationResult = ApolloReactCommon.MutationResult<DeleteJournalProcessingMutation>;
export type DeleteJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJournalProcessingMutation, DeleteJournalProcessingMutationVariables>;
export const CancelJournalProcessingDocument = gql`
    mutation cancelJournalProcessing($input: JournalProcessingDeleteInput!) {
  cancelJournalProcessing(input: $input)
}
    `;
export type CancelJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<CancelJournalProcessingMutation, CancelJournalProcessingMutationVariables>;

/**
 * __useCancelJournalProcessingMutation__
 *
 * To run a mutation, you first call `useCancelJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJournalProcessingMutation, { data, loading, error }] = useCancelJournalProcessingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelJournalProcessingMutation, CancelJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelJournalProcessingMutation, CancelJournalProcessingMutationVariables>(CancelJournalProcessingDocument, baseOptions);
      }
export type CancelJournalProcessingMutationHookResult = ReturnType<typeof useCancelJournalProcessingMutation>;
export type CancelJournalProcessingMutationResult = ApolloReactCommon.MutationResult<CancelJournalProcessingMutation>;
export type CancelJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelJournalProcessingMutation, CancelJournalProcessingMutationVariables>;
export const GetJournalProcessingbyStatusDocument = gql`
    query getJournalProcessingbyStatus($CompanyID: String!, $StatusArr: [String!]!) {
  getJournalProcessingbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr) {
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    JournalProcessingID
    DocDate
    FYear
    FPeriod
    RefNo
    CompanyID
    JournalTypeID
    DocNo
    Description
    ApprovalStatus
    DocNumGenerated
    RejectionDate
    RejectionRemark
    Attachment {
      fileURL
      mediaType
      name
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          createdTs
          StatusRemarks
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    JournalType {
      JournalType
    }
    JournalProcessingItem {
      createdTs
      modTs
      JournalProcessingItemID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
        Code
      }
      CostCentreID
      CostCentre {
        Name
        Code
      }
      DocAmt
      Remark
    }
  }
}
    `;

/**
 * __useGetJournalProcessingbyStatusQuery__
 *
 * To run a query within a React component, call `useGetJournalProcessingbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalProcessingbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalProcessingbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetJournalProcessingbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalProcessingbyStatusQuery, GetJournalProcessingbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalProcessingbyStatusQuery, GetJournalProcessingbyStatusQueryVariables>(GetJournalProcessingbyStatusDocument, baseOptions);
      }
export function useGetJournalProcessingbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalProcessingbyStatusQuery, GetJournalProcessingbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalProcessingbyStatusQuery, GetJournalProcessingbyStatusQueryVariables>(GetJournalProcessingbyStatusDocument, baseOptions);
        }
export type GetJournalProcessingbyStatusQueryHookResult = ReturnType<typeof useGetJournalProcessingbyStatusQuery>;
export type GetJournalProcessingbyStatusLazyQueryHookResult = ReturnType<typeof useGetJournalProcessingbyStatusLazyQuery>;
export type GetJournalProcessingbyStatusQueryResult = ApolloReactCommon.QueryResult<GetJournalProcessingbyStatusQuery, GetJournalProcessingbyStatusQueryVariables>;
export const GetJournalProcessingbyStatusListingDocument = gql`
    query getJournalProcessingbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getJournalProcessingbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdBy
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      JournalProcessingID
      DocDate
      FYear
      FPeriod
      RefNo
      CompanyID
      JournalTypeID
      DocNo
      Description
      ApprovalStatus
      DocNumGenerated
      RejectionDate
      RejectionRemark
      Attachment {
        fileURL
        mediaType
        name
      }
      WorkFlow {
        WorkFlowID
        WorkFlowStatus
        SubmitDate
        SubmitterDetail {
          ID
          name
        }
        WorkFlowStep {
          StepNo
          WorkFlowStepID
          ApprovalStatus
          ApproverRoleID
          RoleDetail {
            ID
            name
          }
          WorkFlowApprovedStep {
            createdTs
            StatusRemarks
            WorkFlowApprovedStepID
            ApproverDetail {
              ID
              name
            }
          }
        }
      }
      JournalType {
        JournalType
      }
      JournalProcessingItem {
        createdTs
        modTs
        JournalProcessingItemID
        MasterCOAID
        Sequence
        MasterCOA {
          Name
          Code
        }
        CostCentreID
        CostCentre {
          Name
          Code
        }
        DocAmt
        Remark
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetJournalProcessingbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetJournalProcessingbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalProcessingbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalProcessingbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetJournalProcessingbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalProcessingbyStatusListingQuery, GetJournalProcessingbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalProcessingbyStatusListingQuery, GetJournalProcessingbyStatusListingQueryVariables>(GetJournalProcessingbyStatusListingDocument, baseOptions);
      }
export function useGetJournalProcessingbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalProcessingbyStatusListingQuery, GetJournalProcessingbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalProcessingbyStatusListingQuery, GetJournalProcessingbyStatusListingQueryVariables>(GetJournalProcessingbyStatusListingDocument, baseOptions);
        }
export type GetJournalProcessingbyStatusListingQueryHookResult = ReturnType<typeof useGetJournalProcessingbyStatusListingQuery>;
export type GetJournalProcessingbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetJournalProcessingbyStatusListingLazyQuery>;
export type GetJournalProcessingbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetJournalProcessingbyStatusListingQuery, GetJournalProcessingbyStatusListingQueryVariables>;
export const PostJournalProcessingDocument = gql`
    mutation postJournalProcessing($JournalProcessingIDs: [String!]!, $CompanyID: String) {
  postJournalProcessing(JournalProcessingIDs: $JournalProcessingIDs, CompanyID: $CompanyID)
}
    `;
export type PostJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<PostJournalProcessingMutation, PostJournalProcessingMutationVariables>;

/**
 * __usePostJournalProcessingMutation__
 *
 * To run a mutation, you first call `usePostJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postJournalProcessingMutation, { data, loading, error }] = usePostJournalProcessingMutation({
 *   variables: {
 *      JournalProcessingIDs: // value for 'JournalProcessingIDs'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePostJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostJournalProcessingMutation, PostJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<PostJournalProcessingMutation, PostJournalProcessingMutationVariables>(PostJournalProcessingDocument, baseOptions);
      }
export type PostJournalProcessingMutationHookResult = ReturnType<typeof usePostJournalProcessingMutation>;
export type PostJournalProcessingMutationResult = ApolloReactCommon.MutationResult<PostJournalProcessingMutation>;
export type PostJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<PostJournalProcessingMutation, PostJournalProcessingMutationVariables>;
export const RejectJournalProcessingDocument = gql`
    mutation rejectJournalProcessing($JournalProcessingIDs: [String!]!, $input: JournalProcessingRejectInput!) {
  rejectJournalProcessing(JournalProcessingIDs: $JournalProcessingIDs, input: $input)
}
    `;
export type RejectJournalProcessingMutationFn = ApolloReactCommon.MutationFunction<RejectJournalProcessingMutation, RejectJournalProcessingMutationVariables>;

/**
 * __useRejectJournalProcessingMutation__
 *
 * To run a mutation, you first call `useRejectJournalProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectJournalProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectJournalProcessingMutation, { data, loading, error }] = useRejectJournalProcessingMutation({
 *   variables: {
 *      JournalProcessingIDs: // value for 'JournalProcessingIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectJournalProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectJournalProcessingMutation, RejectJournalProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectJournalProcessingMutation, RejectJournalProcessingMutationVariables>(RejectJournalProcessingDocument, baseOptions);
      }
export type RejectJournalProcessingMutationHookResult = ReturnType<typeof useRejectJournalProcessingMutation>;
export type RejectJournalProcessingMutationResult = ApolloReactCommon.MutationResult<RejectJournalProcessingMutation>;
export type RejectJournalProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectJournalProcessingMutation, RejectJournalProcessingMutationVariables>;
export const GetJournalReportingDocument = gql`
    query getJournalReporting($CompanyID: String!) {
  getJournalReporting(CompanyID: $CompanyID) {
    JournalID
    createdTs
    JournalDate
    JournalNo
    IsManual
    CompanyID
    JournalTypeID
    RefNo
    Description
  }
}
    `;

/**
 * __useGetJournalReportingQuery__
 *
 * To run a query within a React component, call `useGetJournalReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalReportingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetJournalReportingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJournalReportingQuery, GetJournalReportingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJournalReportingQuery, GetJournalReportingQueryVariables>(GetJournalReportingDocument, baseOptions);
      }
export function useGetJournalReportingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJournalReportingQuery, GetJournalReportingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJournalReportingQuery, GetJournalReportingQueryVariables>(GetJournalReportingDocument, baseOptions);
        }
export type GetJournalReportingQueryHookResult = ReturnType<typeof useGetJournalReportingQuery>;
export type GetJournalReportingLazyQueryHookResult = ReturnType<typeof useGetJournalReportingLazyQuery>;
export type GetJournalReportingQueryResult = ApolloReactCommon.QueryResult<GetJournalReportingQuery, GetJournalReportingQueryVariables>;
export const LatestOpenPeriodCheckingDocument = gql`
    query latestOpenPeriodChecking($CompanyID: String!) {
  latestOpenPeriodChecking(CompanyID: $CompanyID) {
    AccountPeriodID
    FYear
    FPeriod
  }
}
    `;

/**
 * __useLatestOpenPeriodCheckingQuery__
 *
 * To run a query within a React component, call `useLatestOpenPeriodCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOpenPeriodCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOpenPeriodCheckingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLatestOpenPeriodCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestOpenPeriodCheckingQuery, LatestOpenPeriodCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestOpenPeriodCheckingQuery, LatestOpenPeriodCheckingQueryVariables>(LatestOpenPeriodCheckingDocument, baseOptions);
      }
export function useLatestOpenPeriodCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestOpenPeriodCheckingQuery, LatestOpenPeriodCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestOpenPeriodCheckingQuery, LatestOpenPeriodCheckingQueryVariables>(LatestOpenPeriodCheckingDocument, baseOptions);
        }
export type LatestOpenPeriodCheckingQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingQuery>;
export type LatestOpenPeriodCheckingLazyQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingLazyQuery>;
export type LatestOpenPeriodCheckingQueryResult = ApolloReactCommon.QueryResult<LatestOpenPeriodCheckingQuery, LatestOpenPeriodCheckingQueryVariables>;
export const LatestOpenPeriodCheckingDateDocument = gql`
    query latestOpenPeriodCheckingDate($CompanyID: String!) {
  latestOpenPeriodCheckingDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useLatestOpenPeriodCheckingDateQuery__
 *
 * To run a query within a React component, call `useLatestOpenPeriodCheckingDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOpenPeriodCheckingDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOpenPeriodCheckingDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLatestOpenPeriodCheckingDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>(LatestOpenPeriodCheckingDateDocument, baseOptions);
      }
export function useLatestOpenPeriodCheckingDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>(LatestOpenPeriodCheckingDateDocument, baseOptions);
        }
export type LatestOpenPeriodCheckingDateQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingDateQuery>;
export type LatestOpenPeriodCheckingDateLazyQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingDateLazyQuery>;
export type LatestOpenPeriodCheckingDateQueryResult = ApolloReactCommon.QueryResult<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>;
export const LatestOpenYearCheckingDocument = gql`
    query latestOpenYearChecking($CompanyID: String!) {
  latestOpenYearChecking(CompanyID: $CompanyID) {
    AccountPeriodID
    FYear
    FPeriod
  }
}
    `;

/**
 * __useLatestOpenYearCheckingQuery__
 *
 * To run a query within a React component, call `useLatestOpenYearCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOpenYearCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOpenYearCheckingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLatestOpenYearCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestOpenYearCheckingQuery, LatestOpenYearCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestOpenYearCheckingQuery, LatestOpenYearCheckingQueryVariables>(LatestOpenYearCheckingDocument, baseOptions);
      }
export function useLatestOpenYearCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestOpenYearCheckingQuery, LatestOpenYearCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestOpenYearCheckingQuery, LatestOpenYearCheckingQueryVariables>(LatestOpenYearCheckingDocument, baseOptions);
        }
export type LatestOpenYearCheckingQueryHookResult = ReturnType<typeof useLatestOpenYearCheckingQuery>;
export type LatestOpenYearCheckingLazyQueryHookResult = ReturnType<typeof useLatestOpenYearCheckingLazyQuery>;
export type LatestOpenYearCheckingQueryResult = ApolloReactCommon.QueryResult<LatestOpenYearCheckingQuery, LatestOpenYearCheckingQueryVariables>;
export const ProcessMonthEndClosingDocument = gql`
    mutation processMonthEndClosing($CompanyID: String!, $AccountPeriodID: String!) {
  processMonthEndClosing(CompanyID: $CompanyID, AccountPeriodID: $AccountPeriodID) {
    pendingJP
    pendingRJ
    pendingAA
    pendingGenrateRJ
    pendingAPAdv
    pendingAPInv
    pendingAPCN
    pendingAPDN
    pendingAPPayment
    pendingAPBatchPayment
    pendingAPRefund
    pendingARAdv
    pendingARInv
    pendingARCN
    pendingARDN
    pendingAROfficialReceipt
    pendingARRefund
    pendingCBPayment
    pendingCBReceipt
    pendingCBBankTransfer
    pendingStockReceipt
    pendingStockIssue
    pendingStockTransfer
    pendingStockAdjustment
    pendingStockCostAdjustment
    pendingCycleCount
    processStatus
    pendingGRN
    pendingGRTN
  }
}
    `;
export type ProcessMonthEndClosingMutationFn = ApolloReactCommon.MutationFunction<ProcessMonthEndClosingMutation, ProcessMonthEndClosingMutationVariables>;

/**
 * __useProcessMonthEndClosingMutation__
 *
 * To run a mutation, you first call `useProcessMonthEndClosingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessMonthEndClosingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processMonthEndClosingMutation, { data, loading, error }] = useProcessMonthEndClosingMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountPeriodID: // value for 'AccountPeriodID'
 *   },
 * });
 */
export function useProcessMonthEndClosingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProcessMonthEndClosingMutation, ProcessMonthEndClosingMutationVariables>) {
        return ApolloReactHooks.useMutation<ProcessMonthEndClosingMutation, ProcessMonthEndClosingMutationVariables>(ProcessMonthEndClosingDocument, baseOptions);
      }
export type ProcessMonthEndClosingMutationHookResult = ReturnType<typeof useProcessMonthEndClosingMutation>;
export type ProcessMonthEndClosingMutationResult = ApolloReactCommon.MutationResult<ProcessMonthEndClosingMutation>;
export type ProcessMonthEndClosingMutationOptions = ApolloReactCommon.BaseMutationOptions<ProcessMonthEndClosingMutation, ProcessMonthEndClosingMutationVariables>;
export const RevertMonthEndClosingDocument = gql`
    mutation revertMonthEndClosing($CompanyID: String!, $AccountPeriodID: String!) {
  revertMonthEndClosing(CompanyID: $CompanyID, AccountPeriodID: $AccountPeriodID)
}
    `;
export type RevertMonthEndClosingMutationFn = ApolloReactCommon.MutationFunction<RevertMonthEndClosingMutation, RevertMonthEndClosingMutationVariables>;

/**
 * __useRevertMonthEndClosingMutation__
 *
 * To run a mutation, you first call `useRevertMonthEndClosingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertMonthEndClosingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertMonthEndClosingMutation, { data, loading, error }] = useRevertMonthEndClosingMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountPeriodID: // value for 'AccountPeriodID'
 *   },
 * });
 */
export function useRevertMonthEndClosingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertMonthEndClosingMutation, RevertMonthEndClosingMutationVariables>) {
        return ApolloReactHooks.useMutation<RevertMonthEndClosingMutation, RevertMonthEndClosingMutationVariables>(RevertMonthEndClosingDocument, baseOptions);
      }
export type RevertMonthEndClosingMutationHookResult = ReturnType<typeof useRevertMonthEndClosingMutation>;
export type RevertMonthEndClosingMutationResult = ApolloReactCommon.MutationResult<RevertMonthEndClosingMutation>;
export type RevertMonthEndClosingMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertMonthEndClosingMutation, RevertMonthEndClosingMutationVariables>;
export const RevertYearEndClosingDocument = gql`
    mutation revertYearEndClosing($CompanyID: String!, $AccountPeriodID: String!) {
  revertYearEndClosing(CompanyID: $CompanyID, AccountPeriodID: $AccountPeriodID)
}
    `;
export type RevertYearEndClosingMutationFn = ApolloReactCommon.MutationFunction<RevertYearEndClosingMutation, RevertYearEndClosingMutationVariables>;

/**
 * __useRevertYearEndClosingMutation__
 *
 * To run a mutation, you first call `useRevertYearEndClosingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertYearEndClosingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertYearEndClosingMutation, { data, loading, error }] = useRevertYearEndClosingMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountPeriodID: // value for 'AccountPeriodID'
 *   },
 * });
 */
export function useRevertYearEndClosingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertYearEndClosingMutation, RevertYearEndClosingMutationVariables>) {
        return ApolloReactHooks.useMutation<RevertYearEndClosingMutation, RevertYearEndClosingMutationVariables>(RevertYearEndClosingDocument, baseOptions);
      }
export type RevertYearEndClosingMutationHookResult = ReturnType<typeof useRevertYearEndClosingMutation>;
export type RevertYearEndClosingMutationResult = ApolloReactCommon.MutationResult<RevertYearEndClosingMutation>;
export type RevertYearEndClosingMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertYearEndClosingMutation, RevertYearEndClosingMutationVariables>;
export const ProcessYearEndClosingDocument = gql`
    mutation processYearEndClosing($CompanyID: String!, $FYear: Float!) {
  processYearEndClosing(CompanyID: $CompanyID, FYear: $FYear)
}
    `;
export type ProcessYearEndClosingMutationFn = ApolloReactCommon.MutationFunction<ProcessYearEndClosingMutation, ProcessYearEndClosingMutationVariables>;

/**
 * __useProcessYearEndClosingMutation__
 *
 * To run a mutation, you first call `useProcessYearEndClosingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessYearEndClosingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processYearEndClosingMutation, { data, loading, error }] = useProcessYearEndClosingMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *   },
 * });
 */
export function useProcessYearEndClosingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProcessYearEndClosingMutation, ProcessYearEndClosingMutationVariables>) {
        return ApolloReactHooks.useMutation<ProcessYearEndClosingMutation, ProcessYearEndClosingMutationVariables>(ProcessYearEndClosingDocument, baseOptions);
      }
export type ProcessYearEndClosingMutationHookResult = ReturnType<typeof useProcessYearEndClosingMutation>;
export type ProcessYearEndClosingMutationResult = ApolloReactCommon.MutationResult<ProcessYearEndClosingMutation>;
export type ProcessYearEndClosingMutationOptions = ApolloReactCommon.BaseMutationOptions<ProcessYearEndClosingMutation, ProcessYearEndClosingMutationVariables>;
export const GetLastClosedPeriodDocument = gql`
    query getLastClosedPeriod($CompanyID: String!) {
  getLastClosedPeriod(CompanyID: $CompanyID) {
    AccountPeriodID
    FYear
    FPeriod
    YearEndClose
    MonthEndClose
  }
}
    `;

/**
 * __useGetLastClosedPeriodQuery__
 *
 * To run a query within a React component, call `useGetLastClosedPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastClosedPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastClosedPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLastClosedPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastClosedPeriodQuery, GetLastClosedPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLastClosedPeriodQuery, GetLastClosedPeriodQueryVariables>(GetLastClosedPeriodDocument, baseOptions);
      }
export function useGetLastClosedPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastClosedPeriodQuery, GetLastClosedPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLastClosedPeriodQuery, GetLastClosedPeriodQueryVariables>(GetLastClosedPeriodDocument, baseOptions);
        }
export type GetLastClosedPeriodQueryHookResult = ReturnType<typeof useGetLastClosedPeriodQuery>;
export type GetLastClosedPeriodLazyQueryHookResult = ReturnType<typeof useGetLastClosedPeriodLazyQuery>;
export type GetLastClosedPeriodQueryResult = ApolloReactCommon.QueryResult<GetLastClosedPeriodQuery, GetLastClosedPeriodQueryVariables>;
export const NeedYearEndCloseDocument = gql`
    query needYearEndClose($CompanyID: String!) {
  needYearEndClose(CompanyID: $CompanyID)
}
    `;

/**
 * __useNeedYearEndCloseQuery__
 *
 * To run a query within a React component, call `useNeedYearEndCloseQuery` and pass it any options that fit your needs.
 * When your component renders, `useNeedYearEndCloseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNeedYearEndCloseQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useNeedYearEndCloseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NeedYearEndCloseQuery, NeedYearEndCloseQueryVariables>) {
        return ApolloReactHooks.useQuery<NeedYearEndCloseQuery, NeedYearEndCloseQueryVariables>(NeedYearEndCloseDocument, baseOptions);
      }
export function useNeedYearEndCloseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NeedYearEndCloseQuery, NeedYearEndCloseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NeedYearEndCloseQuery, NeedYearEndCloseQueryVariables>(NeedYearEndCloseDocument, baseOptions);
        }
export type NeedYearEndCloseQueryHookResult = ReturnType<typeof useNeedYearEndCloseQuery>;
export type NeedYearEndCloseLazyQueryHookResult = ReturnType<typeof useNeedYearEndCloseLazyQuery>;
export type NeedYearEndCloseQueryResult = ApolloReactCommon.QueryResult<NeedYearEndCloseQuery, NeedYearEndCloseQueryVariables>;
export const MonthEndYearEndDateDocument = gql`
    query MonthEndYearEndDate($CompanyID: String!) {
  MonthEndYearEndDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useMonthEndYearEndDateQuery__
 *
 * To run a query within a React component, call `useMonthEndYearEndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthEndYearEndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthEndYearEndDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useMonthEndYearEndDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthEndYearEndDateQuery, MonthEndYearEndDateQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthEndYearEndDateQuery, MonthEndYearEndDateQueryVariables>(MonthEndYearEndDateDocument, baseOptions);
      }
export function useMonthEndYearEndDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthEndYearEndDateQuery, MonthEndYearEndDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthEndYearEndDateQuery, MonthEndYearEndDateQueryVariables>(MonthEndYearEndDateDocument, baseOptions);
        }
export type MonthEndYearEndDateQueryHookResult = ReturnType<typeof useMonthEndYearEndDateQuery>;
export type MonthEndYearEndDateLazyQueryHookResult = ReturnType<typeof useMonthEndYearEndDateLazyQuery>;
export type MonthEndYearEndDateQueryResult = ApolloReactCommon.QueryResult<MonthEndYearEndDateQuery, MonthEndYearEndDateQueryVariables>;
export const GetRecurringJournalDocument = gql`
    query getRecurringJournal($AccountID: String, $RecurringJournalID: String, $JournalID: String, $CompanyID: String, $orderByAsc: String, $orderByDesc: String) {
  getRecurringJournal(AccountID: $AccountID, RecurringJournalID: $RecurringJournalID, JournalID: $JournalID, CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdBy
    createdTs
    modBy
    modTs
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    RecurringJournalID
    CompanyID
    Remark
    JournalID
    JournalTypeID
    JournalType {
      JournalType
    }
    RefNo
    DocNo
    Description
    StartYear
    StartPeriod
    Interval
    PostAt
    Specify
    NoOfRecurrance
    RecurringJournalStartDate
    Remark
    TransactionDate
    ApprovalStatus
    JournalType {
      JournalType
    }
    RecurringJournalItem {
      RecurringJournalItemID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
        Code
      }
      DocAmt
      CostCentreID
      CostCentre {
        Name
        Code
      }
      DocAmt
      Remark
    }
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          StatusRemarks
          createdTs
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    GeneratedJournals {
      JournalID
      JournalDate
      createdTs
      CompanyID
      RefRecordID
      RefNo
      Description
      JournalNo
      JournalDate
    }
  }
}
    `;

/**
 * __useGetRecurringJournalQuery__
 *
 * To run a query within a React component, call `useGetRecurringJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringJournalQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      RecurringJournalID: // value for 'RecurringJournalID'
 *      JournalID: // value for 'JournalID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRecurringJournalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurringJournalQuery, GetRecurringJournalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurringJournalQuery, GetRecurringJournalQueryVariables>(GetRecurringJournalDocument, baseOptions);
      }
export function useGetRecurringJournalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurringJournalQuery, GetRecurringJournalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurringJournalQuery, GetRecurringJournalQueryVariables>(GetRecurringJournalDocument, baseOptions);
        }
export type GetRecurringJournalQueryHookResult = ReturnType<typeof useGetRecurringJournalQuery>;
export type GetRecurringJournalLazyQueryHookResult = ReturnType<typeof useGetRecurringJournalLazyQuery>;
export type GetRecurringJournalQueryResult = ApolloReactCommon.QueryResult<GetRecurringJournalQuery, GetRecurringJournalQueryVariables>;
export const CreateRecurringJournalDocument = gql`
    mutation createRecurringJournal($input: RecurringJournalInput!, $itemInput: [RecurringJournalItemInput!]) {
  createRecurringJournal(input: $input, itemInput: $itemInput)
}
    `;
export type CreateRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<CreateRecurringJournalMutation, CreateRecurringJournalMutationVariables>;

/**
 * __useCreateRecurringJournalMutation__
 *
 * To run a mutation, you first call `useCreateRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringJournalMutation, { data, loading, error }] = useCreateRecurringJournalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRecurringJournalMutation, CreateRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRecurringJournalMutation, CreateRecurringJournalMutationVariables>(CreateRecurringJournalDocument, baseOptions);
      }
export type CreateRecurringJournalMutationHookResult = ReturnType<typeof useCreateRecurringJournalMutation>;
export type CreateRecurringJournalMutationResult = ApolloReactCommon.MutationResult<CreateRecurringJournalMutation>;
export type CreateRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRecurringJournalMutation, CreateRecurringJournalMutationVariables>;
export const UpdateRecurringJournalDocument = gql`
    mutation updateRecurringJournal($input: RecurringJournalInput!, $itemInput: [RecurringJournalItemInput!]) {
  updateRecurringJournal(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<UpdateRecurringJournalMutation, UpdateRecurringJournalMutationVariables>;

/**
 * __useUpdateRecurringJournalMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringJournalMutation, { data, loading, error }] = useUpdateRecurringJournalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRecurringJournalMutation, UpdateRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRecurringJournalMutation, UpdateRecurringJournalMutationVariables>(UpdateRecurringJournalDocument, baseOptions);
      }
export type UpdateRecurringJournalMutationHookResult = ReturnType<typeof useUpdateRecurringJournalMutation>;
export type UpdateRecurringJournalMutationResult = ApolloReactCommon.MutationResult<UpdateRecurringJournalMutation>;
export type UpdateRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRecurringJournalMutation, UpdateRecurringJournalMutationVariables>;
export const CancelRecurringJournalDocument = gql`
    mutation cancelRecurringJournal($RecurringJournalID: String!) {
  cancelRecurringJournal(RecurringJournalID: $RecurringJournalID)
}
    `;
export type CancelRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<CancelRecurringJournalMutation, CancelRecurringJournalMutationVariables>;

/**
 * __useCancelRecurringJournalMutation__
 *
 * To run a mutation, you first call `useCancelRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecurringJournalMutation, { data, loading, error }] = useCancelRecurringJournalMutation({
 *   variables: {
 *      RecurringJournalID: // value for 'RecurringJournalID'
 *   },
 * });
 */
export function useCancelRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelRecurringJournalMutation, CancelRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelRecurringJournalMutation, CancelRecurringJournalMutationVariables>(CancelRecurringJournalDocument, baseOptions);
      }
export type CancelRecurringJournalMutationHookResult = ReturnType<typeof useCancelRecurringJournalMutation>;
export type CancelRecurringJournalMutationResult = ApolloReactCommon.MutationResult<CancelRecurringJournalMutation>;
export type CancelRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelRecurringJournalMutation, CancelRecurringJournalMutationVariables>;
export const DeleteRecurringJournalDocument = gql`
    mutation deleteRecurringJournal($RecurringJournalID: String!) {
  deleteRecurringJournal(RecurringJournalID: $RecurringJournalID)
}
    `;
export type DeleteRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<DeleteRecurringJournalMutation, DeleteRecurringJournalMutationVariables>;

/**
 * __useDeleteRecurringJournalMutation__
 *
 * To run a mutation, you first call `useDeleteRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecurringJournalMutation, { data, loading, error }] = useDeleteRecurringJournalMutation({
 *   variables: {
 *      RecurringJournalID: // value for 'RecurringJournalID'
 *   },
 * });
 */
export function useDeleteRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRecurringJournalMutation, DeleteRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRecurringJournalMutation, DeleteRecurringJournalMutationVariables>(DeleteRecurringJournalDocument, baseOptions);
      }
export type DeleteRecurringJournalMutationHookResult = ReturnType<typeof useDeleteRecurringJournalMutation>;
export type DeleteRecurringJournalMutationResult = ApolloReactCommon.MutationResult<DeleteRecurringJournalMutation>;
export type DeleteRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRecurringJournalMutation, DeleteRecurringJournalMutationVariables>;
export const GetRecurringJournalbyStatusDocument = gql`
    query getRecurringJournalbyStatus($CompanyID: String!, $StatusArr: [String!]!) {
  getRecurringJournalbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr) {
    createdBy
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    RecurringJournalID
    RefNo
    CompanyID
    JournalTypeID
    DocNo
    StartYear
    StartPeriod
    Interval
    PostAt
    Specify
    RecurringJournalStartDate
    NoOfRecurrance
    Description
    TransactionDate
    RejectionDate
    RejectionRemark
    ApprovalStatus
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          createdTs
          StatusRemarks
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
    JournalType {
      JournalType
    }
    RecurringJournalItem {
      RecurringJournalItemID
      MasterCOAID
      Sequence
      MasterCOA {
        Name
        Code
      }
      CostCentreID
      CostCentre {
        Name
        Code
      }
      DocAmt
      Remark
    }
    GeneratedJournals {
      JournalID
      JournalDate
      createdTs
      CompanyID
      RefRecordID
      RefNo
      Description
      JournalNo
      JournalDate
    }
  }
}
    `;

/**
 * __useGetRecurringJournalbyStatusQuery__
 *
 * To run a query within a React component, call `useGetRecurringJournalbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringJournalbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringJournalbyStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetRecurringJournalbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurringJournalbyStatusQuery, GetRecurringJournalbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurringJournalbyStatusQuery, GetRecurringJournalbyStatusQueryVariables>(GetRecurringJournalbyStatusDocument, baseOptions);
      }
export function useGetRecurringJournalbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurringJournalbyStatusQuery, GetRecurringJournalbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurringJournalbyStatusQuery, GetRecurringJournalbyStatusQueryVariables>(GetRecurringJournalbyStatusDocument, baseOptions);
        }
export type GetRecurringJournalbyStatusQueryHookResult = ReturnType<typeof useGetRecurringJournalbyStatusQuery>;
export type GetRecurringJournalbyStatusLazyQueryHookResult = ReturnType<typeof useGetRecurringJournalbyStatusLazyQuery>;
export type GetRecurringJournalbyStatusQueryResult = ApolloReactCommon.QueryResult<GetRecurringJournalbyStatusQuery, GetRecurringJournalbyStatusQueryVariables>;
export const GetRecurringJournalbyStatusListingDocument = gql`
    query getRecurringJournalbyStatusListing($CompanyID: String!, $StatusArr: [String!]!, $searchValue: String, $skip: Float, $take: Float, $sortOrder: String, $sortField: String) {
  getRecurringJournalbyStatusListing(CompanyID: $CompanyID, StatusArr: $StatusArr, searchValue: $searchValue, skip: $skip, take: $take, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdBy
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      RecurringJournalID
      RefNo
      CompanyID
      JournalTypeID
      DocNo
      StartYear
      StartPeriod
      Interval
      PostAt
      Specify
      RecurringJournalStartDate
      NoOfRecurrance
      Description
      TransactionDate
      RejectionDate
      RejectionRemark
      ApprovalStatus
      WorkFlow {
        WorkFlowID
        WorkFlowStatus
        SubmitDate
        SubmitterDetail {
          ID
          name
        }
        WorkFlowStep {
          StepNo
          WorkFlowStepID
          ApprovalStatus
          ApproverRoleID
          RoleDetail {
            ID
            name
          }
          WorkFlowApprovedStep {
            createdTs
            StatusRemarks
            WorkFlowApprovedStepID
            ApproverDetail {
              ID
              name
            }
          }
        }
      }
      JournalType {
        JournalType
      }
      RecurringJournalItem {
        RecurringJournalItemID
        MasterCOAID
        Sequence
        MasterCOA {
          Name
          Code
        }
        CostCentreID
        CostCentre {
          Name
          Code
        }
        DocAmt
        Remark
      }
      GeneratedJournals {
        JournalID
        JournalDate
        createdTs
        CompanyID
        RefRecordID
        RefNo
        Description
        JournalNo
        JournalDate
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetRecurringJournalbyStatusListingQuery__
 *
 * To run a query within a React component, call `useGetRecurringJournalbyStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringJournalbyStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringJournalbyStatusListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetRecurringJournalbyStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecurringJournalbyStatusListingQuery, GetRecurringJournalbyStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecurringJournalbyStatusListingQuery, GetRecurringJournalbyStatusListingQueryVariables>(GetRecurringJournalbyStatusListingDocument, baseOptions);
      }
export function useGetRecurringJournalbyStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecurringJournalbyStatusListingQuery, GetRecurringJournalbyStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecurringJournalbyStatusListingQuery, GetRecurringJournalbyStatusListingQueryVariables>(GetRecurringJournalbyStatusListingDocument, baseOptions);
        }
export type GetRecurringJournalbyStatusListingQueryHookResult = ReturnType<typeof useGetRecurringJournalbyStatusListingQuery>;
export type GetRecurringJournalbyStatusListingLazyQueryHookResult = ReturnType<typeof useGetRecurringJournalbyStatusListingLazyQuery>;
export type GetRecurringJournalbyStatusListingQueryResult = ApolloReactCommon.QueryResult<GetRecurringJournalbyStatusListingQuery, GetRecurringJournalbyStatusListingQueryVariables>;
export const PostRecurringJournalDocument = gql`
    mutation postRecurringJournal($RecurringJournalIDs: [String!]!, $CompanyID: String) {
  postRecurringJournal(RecurringJournalIDs: $RecurringJournalIDs, CompanyID: $CompanyID)
}
    `;
export type PostRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<PostRecurringJournalMutation, PostRecurringJournalMutationVariables>;

/**
 * __usePostRecurringJournalMutation__
 *
 * To run a mutation, you first call `usePostRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postRecurringJournalMutation, { data, loading, error }] = usePostRecurringJournalMutation({
 *   variables: {
 *      RecurringJournalIDs: // value for 'RecurringJournalIDs'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePostRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostRecurringJournalMutation, PostRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<PostRecurringJournalMutation, PostRecurringJournalMutationVariables>(PostRecurringJournalDocument, baseOptions);
      }
export type PostRecurringJournalMutationHookResult = ReturnType<typeof usePostRecurringJournalMutation>;
export type PostRecurringJournalMutationResult = ApolloReactCommon.MutationResult<PostRecurringJournalMutation>;
export type PostRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<PostRecurringJournalMutation, PostRecurringJournalMutationVariables>;
export const RejectRecurringJournalDocument = gql`
    mutation rejectRecurringJournal($RecurringJournalIDs: [String!]!, $input: RecurringJournalRejectInput!) {
  rejectRecurringJournal(RecurringJournalIDs: $RecurringJournalIDs, input: $input)
}
    `;
export type RejectRecurringJournalMutationFn = ApolloReactCommon.MutationFunction<RejectRecurringJournalMutation, RejectRecurringJournalMutationVariables>;

/**
 * __useRejectRecurringJournalMutation__
 *
 * To run a mutation, you first call `useRejectRecurringJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRecurringJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRecurringJournalMutation, { data, loading, error }] = useRejectRecurringJournalMutation({
 *   variables: {
 *      RecurringJournalIDs: // value for 'RecurringJournalIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectRecurringJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectRecurringJournalMutation, RejectRecurringJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectRecurringJournalMutation, RejectRecurringJournalMutationVariables>(RejectRecurringJournalDocument, baseOptions);
      }
export type RejectRecurringJournalMutationHookResult = ReturnType<typeof useRejectRecurringJournalMutation>;
export type RejectRecurringJournalMutationResult = ApolloReactCommon.MutationResult<RejectRecurringJournalMutation>;
export type RejectRecurringJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectRecurringJournalMutation, RejectRecurringJournalMutationVariables>;
export const GetRoutineJournalDocument = gql`
    query getRoutineJournal($AccountID: String, $RoutineJournalID: String, $CompanyID: String) {
  getRoutineJournal(AccountID: $AccountID, RoutineJournalID: $RoutineJournalID, CompanyID: $CompanyID) {
    createdBy
    RoutineJournalID
    createdTs
    CompanyID
    JournalTypeID
    Remark
    JournalType {
      JournalType
    }
    RefNo
    Description
    RoutineJournalItem {
      MasterCOAID
      MasterCOA {
        Name
      }
      DocAmt
    }
  }
}
    `;

/**
 * __useGetRoutineJournalQuery__
 *
 * To run a query within a React component, call `useGetRoutineJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoutineJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoutineJournalQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      RoutineJournalID: // value for 'RoutineJournalID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetRoutineJournalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoutineJournalQuery, GetRoutineJournalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoutineJournalQuery, GetRoutineJournalQueryVariables>(GetRoutineJournalDocument, baseOptions);
      }
export function useGetRoutineJournalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoutineJournalQuery, GetRoutineJournalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoutineJournalQuery, GetRoutineJournalQueryVariables>(GetRoutineJournalDocument, baseOptions);
        }
export type GetRoutineJournalQueryHookResult = ReturnType<typeof useGetRoutineJournalQuery>;
export type GetRoutineJournalLazyQueryHookResult = ReturnType<typeof useGetRoutineJournalLazyQuery>;
export type GetRoutineJournalQueryResult = ApolloReactCommon.QueryResult<GetRoutineJournalQuery, GetRoutineJournalQueryVariables>;
export const CreateRoutineJournalDocument = gql`
    mutation createRoutineJournal($RoutineJournalInput: RoutineJournalInput!, $RoutineJournalItemInput: [RoutineJournalItemInput!]!) {
  createRoutineJournal(RoutineJournalInput: $RoutineJournalInput, RoutineJournalItemInput: $RoutineJournalItemInput)
}
    `;
export type CreateRoutineJournalMutationFn = ApolloReactCommon.MutationFunction<CreateRoutineJournalMutation, CreateRoutineJournalMutationVariables>;

/**
 * __useCreateRoutineJournalMutation__
 *
 * To run a mutation, you first call `useCreateRoutineJournalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoutineJournalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoutineJournalMutation, { data, loading, error }] = useCreateRoutineJournalMutation({
 *   variables: {
 *      RoutineJournalInput: // value for 'RoutineJournalInput'
 *      RoutineJournalItemInput: // value for 'RoutineJournalItemInput'
 *   },
 * });
 */
export function useCreateRoutineJournalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoutineJournalMutation, CreateRoutineJournalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRoutineJournalMutation, CreateRoutineJournalMutationVariables>(CreateRoutineJournalDocument, baseOptions);
      }
export type CreateRoutineJournalMutationHookResult = ReturnType<typeof useCreateRoutineJournalMutation>;
export type CreateRoutineJournalMutationResult = ApolloReactCommon.MutationResult<CreateRoutineJournalMutation>;
export type CreateRoutineJournalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRoutineJournalMutation, CreateRoutineJournalMutationVariables>;
export const UpdateRoutineJournalsDocument = gql`
    mutation updateRoutineJournals($RoutineJournalInput: RoutineJournalInput!, $RoutineItemInput: [RoutineJournalItemInput!]!, $CompanyID: String!, $RoutineJournalID: String!) {
  updateRoutineJournals(RoutineJournalInput: $RoutineJournalInput, RoutineItemInput: $RoutineItemInput, CompanyID: $CompanyID, RoutineJournalID: $RoutineJournalID)
}
    `;
export type UpdateRoutineJournalsMutationFn = ApolloReactCommon.MutationFunction<UpdateRoutineJournalsMutation, UpdateRoutineJournalsMutationVariables>;

/**
 * __useUpdateRoutineJournalsMutation__
 *
 * To run a mutation, you first call `useUpdateRoutineJournalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoutineJournalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoutineJournalsMutation, { data, loading, error }] = useUpdateRoutineJournalsMutation({
 *   variables: {
 *      RoutineJournalInput: // value for 'RoutineJournalInput'
 *      RoutineItemInput: // value for 'RoutineItemInput'
 *      CompanyID: // value for 'CompanyID'
 *      RoutineJournalID: // value for 'RoutineJournalID'
 *   },
 * });
 */
export function useUpdateRoutineJournalsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoutineJournalsMutation, UpdateRoutineJournalsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoutineJournalsMutation, UpdateRoutineJournalsMutationVariables>(UpdateRoutineJournalsDocument, baseOptions);
      }
export type UpdateRoutineJournalsMutationHookResult = ReturnType<typeof useUpdateRoutineJournalsMutation>;
export type UpdateRoutineJournalsMutationResult = ApolloReactCommon.MutationResult<UpdateRoutineJournalsMutation>;
export type UpdateRoutineJournalsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoutineJournalsMutation, UpdateRoutineJournalsMutationVariables>;
export const ApprovableCheckingDocument = gql`
    query approvableChecking($RefID: String!, $CompanyID: String!, $UserID: String!) {
  approvableChecking(RefID: $RefID, CompanyID: $CompanyID, UserID: $UserID)
}
    `;

/**
 * __useApprovableCheckingQuery__
 *
 * To run a query within a React component, call `useApprovableCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovableCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovableCheckingQuery({
 *   variables: {
 *      RefID: // value for 'RefID'
 *      CompanyID: // value for 'CompanyID'
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useApprovableCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovableCheckingQuery, ApprovableCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovableCheckingQuery, ApprovableCheckingQueryVariables>(ApprovableCheckingDocument, baseOptions);
      }
export function useApprovableCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovableCheckingQuery, ApprovableCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovableCheckingQuery, ApprovableCheckingQueryVariables>(ApprovableCheckingDocument, baseOptions);
        }
export type ApprovableCheckingQueryHookResult = ReturnType<typeof useApprovableCheckingQuery>;
export type ApprovableCheckingLazyQueryHookResult = ReturnType<typeof useApprovableCheckingLazyQuery>;
export type ApprovableCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovableCheckingQuery, ApprovableCheckingQueryVariables>;
export const GetApprovalPolicyDocument = gql`
    query getApprovalPolicy($Category: String!) {
  getApprovalPolicy(Category: $Category) {
    ApprovalPolicyID
    createdTs
    modTs
    Category
    ApprovalList
    lowerLimit
    upperLimit
  }
}
    `;

/**
 * __useGetApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
      }
export function useGetApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
        }
export type GetApprovalPolicyQueryHookResult = ReturnType<typeof useGetApprovalPolicyQuery>;
export type GetApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyLazyQuery>;
export type GetApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>;
export const GetAllApprovalPolicyDocument = gql`
    query getAllApprovalPolicy($Category: String!) {
  getAllApprovalPolicy(Category: $Category) {
    ApprovalPolicyID
    createdTs
    modTs
    Category
    ApprovalList
  }
}
    `;

/**
 * __useGetAllApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetAllApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApprovalPolicyQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetAllApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>(GetAllApprovalPolicyDocument, baseOptions);
      }
export function useGetAllApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>(GetAllApprovalPolicyDocument, baseOptions);
        }
export type GetAllApprovalPolicyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyQuery>;
export type GetAllApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyLazyQuery>;
export type GetAllApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>;
export const CreateApprovalPolicyDocument = gql`
    mutation createApprovalPolicy($input: ApprovalPolicyInput!) {
  createApprovalPolicy(input: $input) {
    ApprovalPolicyID
  }
}
    `;
export type CreateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;

/**
 * __useCreateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyMutation, { data, loading, error }] = useCreateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>(CreateApprovalPolicyDocument, baseOptions);
      }
export type CreateApprovalPolicyMutationHookResult = ReturnType<typeof useCreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;
export const UpdateApprovalPolicyDocument = gql`
    mutation updateApprovalPolicy($input: ApprovalPolicyInput!) {
  updateApprovalPolicy(input: $input) {
    ApprovalPolicyID
  }
}
    `;
export type UpdateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;

/**
 * __useUpdateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyMutation, { data, loading, error }] = useUpdateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>(UpdateApprovalPolicyDocument, baseOptions);
      }
export type UpdateApprovalPolicyMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;
export const DeleteApprovalPolicyDocument = gql`
    mutation deleteApprovalPolicy($input: ApprovalPolicyDeleteInput!) {
  deleteApprovalPolicy(input: $input)
}
    `;
export type DeleteApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;

/**
 * __useDeleteApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyMutation, { data, loading, error }] = useDeleteApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>(DeleteApprovalPolicyDocument, baseOptions);
      }
export type DeleteApprovalPolicyMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;
export const CreateUpdateDeleteApprovalPolicyPoDocument = gql`
    mutation createUpdateDeleteApprovalPolicyPO($input: [ApprovalPolicyInput!]!, $deleteInput: [ApprovalPolicyDeleteInput!]) {
  createUpdateDeleteApprovalPolicyPO(input: $input, deleteInput: $deleteInput)
}
    `;
export type CreateUpdateDeleteApprovalPolicyPoMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateDeleteApprovalPolicyPoMutation, CreateUpdateDeleteApprovalPolicyPoMutationVariables>;

/**
 * __useCreateUpdateDeleteApprovalPolicyPoMutation__
 *
 * To run a mutation, you first call `useCreateUpdateDeleteApprovalPolicyPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateDeleteApprovalPolicyPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateDeleteApprovalPolicyPoMutation, { data, loading, error }] = useCreateUpdateDeleteApprovalPolicyPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      deleteInput: // value for 'deleteInput'
 *   },
 * });
 */
export function useCreateUpdateDeleteApprovalPolicyPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateDeleteApprovalPolicyPoMutation, CreateUpdateDeleteApprovalPolicyPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateDeleteApprovalPolicyPoMutation, CreateUpdateDeleteApprovalPolicyPoMutationVariables>(CreateUpdateDeleteApprovalPolicyPoDocument, baseOptions);
      }
export type CreateUpdateDeleteApprovalPolicyPoMutationHookResult = ReturnType<typeof useCreateUpdateDeleteApprovalPolicyPoMutation>;
export type CreateUpdateDeleteApprovalPolicyPoMutationResult = ApolloReactCommon.MutationResult<CreateUpdateDeleteApprovalPolicyPoMutation>;
export type CreateUpdateDeleteApprovalPolicyPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateDeleteApprovalPolicyPoMutation, CreateUpdateDeleteApprovalPolicyPoMutationVariables>;
export const GetBankProfileDocument = gql`
    query getBankProfile($AccountID: String) {
  getBankProfile(AccountID: $AccountID) {
    BankProfileID
    Name
    Address
    ContactNo
    Email
    BankAccount {
      BankAccountID
    }
  }
}
    `;

/**
 * __useGetBankProfileQuery__
 *
 * To run a query within a React component, call `useGetBankProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankProfileQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetBankProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankProfileQuery, GetBankProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankProfileQuery, GetBankProfileQueryVariables>(GetBankProfileDocument, baseOptions);
      }
export function useGetBankProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankProfileQuery, GetBankProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankProfileQuery, GetBankProfileQueryVariables>(GetBankProfileDocument, baseOptions);
        }
export type GetBankProfileQueryHookResult = ReturnType<typeof useGetBankProfileQuery>;
export type GetBankProfileLazyQueryHookResult = ReturnType<typeof useGetBankProfileLazyQuery>;
export type GetBankProfileQueryResult = ApolloReactCommon.QueryResult<GetBankProfileQuery, GetBankProfileQueryVariables>;
export const GetBankProfileListingDocument = gql`
    query getBankProfileListing($AccountID: String, $skip: Float, $take: Float, $searchValue: String) {
  getBankProfile(AccountID: $AccountID, skip: $skip, take: $take, searchValue: $searchValue) {
    BankProfileID
    Name
    Address
    ContactNo
    Email
    BankAccount {
      BankAccountID
    }
  }
  getBankProfileCount(AccountID: $AccountID)
}
    `;

/**
 * __useGetBankProfileListingQuery__
 *
 * To run a query within a React component, call `useGetBankProfileListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankProfileListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankProfileListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetBankProfileListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankProfileListingQuery, GetBankProfileListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankProfileListingQuery, GetBankProfileListingQueryVariables>(GetBankProfileListingDocument, baseOptions);
      }
export function useGetBankProfileListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankProfileListingQuery, GetBankProfileListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankProfileListingQuery, GetBankProfileListingQueryVariables>(GetBankProfileListingDocument, baseOptions);
        }
export type GetBankProfileListingQueryHookResult = ReturnType<typeof useGetBankProfileListingQuery>;
export type GetBankProfileListingLazyQueryHookResult = ReturnType<typeof useGetBankProfileListingLazyQuery>;
export type GetBankProfileListingQueryResult = ApolloReactCommon.QueryResult<GetBankProfileListingQuery, GetBankProfileListingQueryVariables>;
export const CreateBankProfileDocument = gql`
    mutation createBankProfile($input: BankProfileInput!) {
  createBankProfile(input: $input) {
    BankProfileID
    Name
    Address
    ContactNo
    Email
  }
}
    `;
export type CreateBankProfileMutationFn = ApolloReactCommon.MutationFunction<CreateBankProfileMutation, CreateBankProfileMutationVariables>;

/**
 * __useCreateBankProfileMutation__
 *
 * To run a mutation, you first call `useCreateBankProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankProfileMutation, { data, loading, error }] = useCreateBankProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankProfileMutation, CreateBankProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankProfileMutation, CreateBankProfileMutationVariables>(CreateBankProfileDocument, baseOptions);
      }
export type CreateBankProfileMutationHookResult = ReturnType<typeof useCreateBankProfileMutation>;
export type CreateBankProfileMutationResult = ApolloReactCommon.MutationResult<CreateBankProfileMutation>;
export type CreateBankProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankProfileMutation, CreateBankProfileMutationVariables>;
export const UpdateBankProfileDocument = gql`
    mutation updateBankProfile($input: BankProfileInput!) {
  updateBankProfile(input: $input) {
    BankProfileID
    Name
    Address
    ContactNo
  }
}
    `;
export type UpdateBankProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateBankProfileMutation, UpdateBankProfileMutationVariables>;

/**
 * __useUpdateBankProfileMutation__
 *
 * To run a mutation, you first call `useUpdateBankProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankProfileMutation, { data, loading, error }] = useUpdateBankProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankProfileMutation, UpdateBankProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankProfileMutation, UpdateBankProfileMutationVariables>(UpdateBankProfileDocument, baseOptions);
      }
export type UpdateBankProfileMutationHookResult = ReturnType<typeof useUpdateBankProfileMutation>;
export type UpdateBankProfileMutationResult = ApolloReactCommon.MutationResult<UpdateBankProfileMutation>;
export type UpdateBankProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankProfileMutation, UpdateBankProfileMutationVariables>;
export const DeleteBankProfileDocument = gql`
    mutation deleteBankProfile($input: BankProfileDeleteInput!) {
  deleteBankProfile(input: $input)
}
    `;
export type DeleteBankProfileMutationFn = ApolloReactCommon.MutationFunction<DeleteBankProfileMutation, DeleteBankProfileMutationVariables>;

/**
 * __useDeleteBankProfileMutation__
 *
 * To run a mutation, you first call `useDeleteBankProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankProfileMutation, { data, loading, error }] = useDeleteBankProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBankProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankProfileMutation, DeleteBankProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankProfileMutation, DeleteBankProfileMutationVariables>(DeleteBankProfileDocument, baseOptions);
      }
export type DeleteBankProfileMutationHookResult = ReturnType<typeof useDeleteBankProfileMutation>;
export type DeleteBankProfileMutationResult = ApolloReactCommon.MutationResult<DeleteBankProfileMutation>;
export type DeleteBankProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankProfileMutation, DeleteBankProfileMutationVariables>;
export const GetCheckingValidationBpDocument = gql`
    query getCheckingValidationBP($BPImportInput: [BPImportInput!]!) {
  getCheckingValidationBP(BPImportInput: $BPImportInput)
}
    `;

/**
 * __useGetCheckingValidationBpQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationBpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationBpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationBpQuery({
 *   variables: {
 *      BPImportInput: // value for 'BPImportInput'
 *   },
 * });
 */
export function useGetCheckingValidationBpQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationBpQuery, GetCheckingValidationBpQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationBpQuery, GetCheckingValidationBpQueryVariables>(GetCheckingValidationBpDocument, baseOptions);
      }
export function useGetCheckingValidationBpLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationBpQuery, GetCheckingValidationBpQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationBpQuery, GetCheckingValidationBpQueryVariables>(GetCheckingValidationBpDocument, baseOptions);
        }
export type GetCheckingValidationBpQueryHookResult = ReturnType<typeof useGetCheckingValidationBpQuery>;
export type GetCheckingValidationBpLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationBpLazyQuery>;
export type GetCheckingValidationBpQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationBpQuery, GetCheckingValidationBpQueryVariables>;
export const CreateBankProfileImportDocument = gql`
    mutation createBankProfileImport($TemplateStr: String!) {
  createBankProfileImport(TemplateStr: $TemplateStr)
}
    `;
export type CreateBankProfileImportMutationFn = ApolloReactCommon.MutationFunction<CreateBankProfileImportMutation, CreateBankProfileImportMutationVariables>;

/**
 * __useCreateBankProfileImportMutation__
 *
 * To run a mutation, you first call `useCreateBankProfileImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankProfileImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankProfileImportMutation, { data, loading, error }] = useCreateBankProfileImportMutation({
 *   variables: {
 *      TemplateStr: // value for 'TemplateStr'
 *   },
 * });
 */
export function useCreateBankProfileImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankProfileImportMutation, CreateBankProfileImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankProfileImportMutation, CreateBankProfileImportMutationVariables>(CreateBankProfileImportDocument, baseOptions);
      }
export type CreateBankProfileImportMutationHookResult = ReturnType<typeof useCreateBankProfileImportMutation>;
export type CreateBankProfileImportMutationResult = ApolloReactCommon.MutationResult<CreateBankProfileImportMutation>;
export type CreateBankProfileImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankProfileImportMutation, CreateBankProfileImportMutationVariables>;
export const GetCostCentreDefinitionHeaderDocument = gql`
    query getCostCentreDefinitionHeader($AccountID: String, $orderByAsc: String) {
  getCostCentreDefinitionHeader(AccountID: $AccountID, orderByAsc: $orderByAsc) {
    CostCentreDefinitionHeaderID
    Title
    CostCentreDefConnection {
      CostCentreDefinitionAsgmtID
      CompanyID
    }
  }
}
    `;

/**
 * __useGetCostCentreDefinitionHeaderQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionHeaderQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCostCentreDefinitionHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionHeaderQuery, GetCostCentreDefinitionHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionHeaderQuery, GetCostCentreDefinitionHeaderQueryVariables>(GetCostCentreDefinitionHeaderDocument, baseOptions);
      }
export function useGetCostCentreDefinitionHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionHeaderQuery, GetCostCentreDefinitionHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionHeaderQuery, GetCostCentreDefinitionHeaderQueryVariables>(GetCostCentreDefinitionHeaderDocument, baseOptions);
        }
export type GetCostCentreDefinitionHeaderQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionHeaderQuery>;
export type GetCostCentreDefinitionHeaderLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionHeaderLazyQuery>;
export type GetCostCentreDefinitionHeaderQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionHeaderQuery, GetCostCentreDefinitionHeaderQueryVariables>;
export const GetCostCentreDefinitionListingDocument = gql`
    query getCostCentreDefinitionListing {
  getCostCentreDefinitionListing {
    CostCentreDefinitionHeaderID
    Title
    CostCentreDefConnection {
      CostCentreDefinitionAsgmtID
      CompanyID
    }
  }
}
    `;

/**
 * __useGetCostCentreDefinitionListingQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostCentreDefinitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionListingQuery, GetCostCentreDefinitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionListingQuery, GetCostCentreDefinitionListingQueryVariables>(GetCostCentreDefinitionListingDocument, baseOptions);
      }
export function useGetCostCentreDefinitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionListingQuery, GetCostCentreDefinitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionListingQuery, GetCostCentreDefinitionListingQueryVariables>(GetCostCentreDefinitionListingDocument, baseOptions);
        }
export type GetCostCentreDefinitionListingQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionListingQuery>;
export type GetCostCentreDefinitionListingLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionListingLazyQuery>;
export type GetCostCentreDefinitionListingQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionListingQuery, GetCostCentreDefinitionListingQueryVariables>;
export const GetCostCentreDefinitionDocument = gql`
    query getCostCentreDefinition($CostCentreDefinitionHeaderID: String!) {
  getCostCentreDefinition(CostCentreDefinitionHeaderID: $CostCentreDefinitionHeaderID) {
    CostCentreDefinitionHeader {
      CostCentreDefinitionHeaderID
      Title
    }
    CostCentreDefinitionDetail {
      CostCentreDefinitionDetailID
      ParentCostCentreDefinitionDetailID
      Name
      Level
      CostCentreDefinitionHeaderID
    }
  }
}
    `;

/**
 * __useGetCostCentreDefinitionQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionQuery({
 *   variables: {
 *      CostCentreDefinitionHeaderID: // value for 'CostCentreDefinitionHeaderID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionQuery, GetCostCentreDefinitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionQuery, GetCostCentreDefinitionQueryVariables>(GetCostCentreDefinitionDocument, baseOptions);
      }
export function useGetCostCentreDefinitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionQuery, GetCostCentreDefinitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionQuery, GetCostCentreDefinitionQueryVariables>(GetCostCentreDefinitionDocument, baseOptions);
        }
export type GetCostCentreDefinitionQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionQuery>;
export type GetCostCentreDefinitionLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionLazyQuery>;
export type GetCostCentreDefinitionQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionQuery, GetCostCentreDefinitionQueryVariables>;
export const CreateCostCentreDefinitionDocument = gql`
    mutation createCostCentreDefinition($CostCentreDefinitionHeaderInput: CostCentreDefinitionHeaderInput!, $CostCentreDefinitionDetailInput: [CostCentreDefinitionDetailInput!]!) {
  createCostCentreDefinition(CostCentreDefinitionHeaderInput: $CostCentreDefinitionHeaderInput, CostCentreDefinitionDetailInput: $CostCentreDefinitionDetailInput)
}
    `;
export type CreateCostCentreDefinitionMutationFn = ApolloReactCommon.MutationFunction<CreateCostCentreDefinitionMutation, CreateCostCentreDefinitionMutationVariables>;

/**
 * __useCreateCostCentreDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateCostCentreDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCentreDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCentreDefinitionMutation, { data, loading, error }] = useCreateCostCentreDefinitionMutation({
 *   variables: {
 *      CostCentreDefinitionHeaderInput: // value for 'CostCentreDefinitionHeaderInput'
 *      CostCentreDefinitionDetailInput: // value for 'CostCentreDefinitionDetailInput'
 *   },
 * });
 */
export function useCreateCostCentreDefinitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCentreDefinitionMutation, CreateCostCentreDefinitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCentreDefinitionMutation, CreateCostCentreDefinitionMutationVariables>(CreateCostCentreDefinitionDocument, baseOptions);
      }
export type CreateCostCentreDefinitionMutationHookResult = ReturnType<typeof useCreateCostCentreDefinitionMutation>;
export type CreateCostCentreDefinitionMutationResult = ApolloReactCommon.MutationResult<CreateCostCentreDefinitionMutation>;
export type CreateCostCentreDefinitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCentreDefinitionMutation, CreateCostCentreDefinitionMutationVariables>;
export const CreateUpdateCostCentreDefinitionDocument = gql`
    mutation createUpdateCostCentreDefinition($CompanyID: String!, $CostCentreDefinitionDetailInput: [CostCentreDefinitionDetailInput!]!) {
  createUpdateCostCentreDefinition(CompanyID: $CompanyID, CostCentreDefinitionDetailInput: $CostCentreDefinitionDetailInput)
}
    `;
export type CreateUpdateCostCentreDefinitionMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateCostCentreDefinitionMutation, CreateUpdateCostCentreDefinitionMutationVariables>;

/**
 * __useCreateUpdateCostCentreDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateUpdateCostCentreDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateCostCentreDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateCostCentreDefinitionMutation, { data, loading, error }] = useCreateUpdateCostCentreDefinitionMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CostCentreDefinitionDetailInput: // value for 'CostCentreDefinitionDetailInput'
 *   },
 * });
 */
export function useCreateUpdateCostCentreDefinitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateCostCentreDefinitionMutation, CreateUpdateCostCentreDefinitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateCostCentreDefinitionMutation, CreateUpdateCostCentreDefinitionMutationVariables>(CreateUpdateCostCentreDefinitionDocument, baseOptions);
      }
export type CreateUpdateCostCentreDefinitionMutationHookResult = ReturnType<typeof useCreateUpdateCostCentreDefinitionMutation>;
export type CreateUpdateCostCentreDefinitionMutationResult = ApolloReactCommon.MutationResult<CreateUpdateCostCentreDefinitionMutation>;
export type CreateUpdateCostCentreDefinitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateCostCentreDefinitionMutation, CreateUpdateCostCentreDefinitionMutationVariables>;
export const DeleteCostCentreDefinitionDocument = gql`
    mutation deleteCostCentreDefinition($CostCentreDefinitionHeaderID: String!) {
  deleteCostCentreDefinition(CostCentreDefinitionHeaderID: $CostCentreDefinitionHeaderID)
}
    `;
export type DeleteCostCentreDefinitionMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCentreDefinitionMutation, DeleteCostCentreDefinitionMutationVariables>;

/**
 * __useDeleteCostCentreDefinitionMutation__
 *
 * To run a mutation, you first call `useDeleteCostCentreDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCentreDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCentreDefinitionMutation, { data, loading, error }] = useDeleteCostCentreDefinitionMutation({
 *   variables: {
 *      CostCentreDefinitionHeaderID: // value for 'CostCentreDefinitionHeaderID'
 *   },
 * });
 */
export function useDeleteCostCentreDefinitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCentreDefinitionMutation, DeleteCostCentreDefinitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCentreDefinitionMutation, DeleteCostCentreDefinitionMutationVariables>(DeleteCostCentreDefinitionDocument, baseOptions);
      }
export type DeleteCostCentreDefinitionMutationHookResult = ReturnType<typeof useDeleteCostCentreDefinitionMutation>;
export type DeleteCostCentreDefinitionMutationResult = ApolloReactCommon.MutationResult<DeleteCostCentreDefinitionMutation>;
export type DeleteCostCentreDefinitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCentreDefinitionMutation, DeleteCostCentreDefinitionMutationVariables>;
export const UpdateCostCentreDefinitionHeaderDocument = gql`
    mutation updateCostCentreDefinitionHeader($input: CostCentreDefinitionHeaderInput!) {
  updateCostCentreDefinitionHeader(input: $input) {
    CostCentreDefinitionHeaderID
  }
}
    `;
export type UpdateCostCentreDefinitionHeaderMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCentreDefinitionHeaderMutation, UpdateCostCentreDefinitionHeaderMutationVariables>;

/**
 * __useUpdateCostCentreDefinitionHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateCostCentreDefinitionHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCentreDefinitionHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCentreDefinitionHeaderMutation, { data, loading, error }] = useUpdateCostCentreDefinitionHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCentreDefinitionHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCentreDefinitionHeaderMutation, UpdateCostCentreDefinitionHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCentreDefinitionHeaderMutation, UpdateCostCentreDefinitionHeaderMutationVariables>(UpdateCostCentreDefinitionHeaderDocument, baseOptions);
      }
export type UpdateCostCentreDefinitionHeaderMutationHookResult = ReturnType<typeof useUpdateCostCentreDefinitionHeaderMutation>;
export type UpdateCostCentreDefinitionHeaderMutationResult = ApolloReactCommon.MutationResult<UpdateCostCentreDefinitionHeaderMutation>;
export type UpdateCostCentreDefinitionHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCentreDefinitionHeaderMutation, UpdateCostCentreDefinitionHeaderMutationVariables>;
export const CancelCostCentreDefinitionHeaderDocument = gql`
    mutation cancelCostCentreDefinitionHeader($input: CostCentreDefinitionHeaderDeleteInput!) {
  cancelCostCentreDefinitionHeader(input: $input)
}
    `;
export type CancelCostCentreDefinitionHeaderMutationFn = ApolloReactCommon.MutationFunction<CancelCostCentreDefinitionHeaderMutation, CancelCostCentreDefinitionHeaderMutationVariables>;

/**
 * __useCancelCostCentreDefinitionHeaderMutation__
 *
 * To run a mutation, you first call `useCancelCostCentreDefinitionHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostCentreDefinitionHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostCentreDefinitionHeaderMutation, { data, loading, error }] = useCancelCostCentreDefinitionHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCostCentreDefinitionHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostCentreDefinitionHeaderMutation, CancelCostCentreDefinitionHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostCentreDefinitionHeaderMutation, CancelCostCentreDefinitionHeaderMutationVariables>(CancelCostCentreDefinitionHeaderDocument, baseOptions);
      }
export type CancelCostCentreDefinitionHeaderMutationHookResult = ReturnType<typeof useCancelCostCentreDefinitionHeaderMutation>;
export type CancelCostCentreDefinitionHeaderMutationResult = ApolloReactCommon.MutationResult<CancelCostCentreDefinitionHeaderMutation>;
export type CancelCostCentreDefinitionHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostCentreDefinitionHeaderMutation, CancelCostCentreDefinitionHeaderMutationVariables>;
export const UpdateCostCentreDefinitionDetailDocument = gql`
    mutation updateCostCentreDefinitionDetail($input: CostCentreDefinitionDetailInput!) {
  updateCostCentreDefinitionDetail(input: $input) {
    CostCentreDefinitionDetailID
  }
}
    `;
export type UpdateCostCentreDefinitionDetailMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCentreDefinitionDetailMutation, UpdateCostCentreDefinitionDetailMutationVariables>;

/**
 * __useUpdateCostCentreDefinitionDetailMutation__
 *
 * To run a mutation, you first call `useUpdateCostCentreDefinitionDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCentreDefinitionDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCentreDefinitionDetailMutation, { data, loading, error }] = useUpdateCostCentreDefinitionDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCentreDefinitionDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCentreDefinitionDetailMutation, UpdateCostCentreDefinitionDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCentreDefinitionDetailMutation, UpdateCostCentreDefinitionDetailMutationVariables>(UpdateCostCentreDefinitionDetailDocument, baseOptions);
      }
export type UpdateCostCentreDefinitionDetailMutationHookResult = ReturnType<typeof useUpdateCostCentreDefinitionDetailMutation>;
export type UpdateCostCentreDefinitionDetailMutationResult = ApolloReactCommon.MutationResult<UpdateCostCentreDefinitionDetailMutation>;
export type UpdateCostCentreDefinitionDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCentreDefinitionDetailMutation, UpdateCostCentreDefinitionDetailMutationVariables>;
export const CancelCostCentreDefinitionDetailDocument = gql`
    mutation cancelCostCentreDefinitionDetail($input: CostCentreDefinitionDetailDeleteInput!) {
  cancelCostCentreDefinitionDetail(input: $input)
}
    `;
export type CancelCostCentreDefinitionDetailMutationFn = ApolloReactCommon.MutationFunction<CancelCostCentreDefinitionDetailMutation, CancelCostCentreDefinitionDetailMutationVariables>;

/**
 * __useCancelCostCentreDefinitionDetailMutation__
 *
 * To run a mutation, you first call `useCancelCostCentreDefinitionDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostCentreDefinitionDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostCentreDefinitionDetailMutation, { data, loading, error }] = useCancelCostCentreDefinitionDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCostCentreDefinitionDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostCentreDefinitionDetailMutation, CancelCostCentreDefinitionDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostCentreDefinitionDetailMutation, CancelCostCentreDefinitionDetailMutationVariables>(CancelCostCentreDefinitionDetailDocument, baseOptions);
      }
export type CancelCostCentreDefinitionDetailMutationHookResult = ReturnType<typeof useCancelCostCentreDefinitionDetailMutation>;
export type CancelCostCentreDefinitionDetailMutationResult = ApolloReactCommon.MutationResult<CancelCostCentreDefinitionDetailMutation>;
export type CancelCostCentreDefinitionDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostCentreDefinitionDetailMutation, CancelCostCentreDefinitionDetailMutationVariables>;
export const DeleteCostCentreDefinitionDetailDocument = gql`
    mutation deleteCostCentreDefinitionDetail($input: CostCentreDefinitionDetailDeleteInput!) {
  deleteCostCentreDefinitionDetail(input: $input)
}
    `;
export type DeleteCostCentreDefinitionDetailMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCentreDefinitionDetailMutation, DeleteCostCentreDefinitionDetailMutationVariables>;

/**
 * __useDeleteCostCentreDefinitionDetailMutation__
 *
 * To run a mutation, you first call `useDeleteCostCentreDefinitionDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCentreDefinitionDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCentreDefinitionDetailMutation, { data, loading, error }] = useDeleteCostCentreDefinitionDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostCentreDefinitionDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCentreDefinitionDetailMutation, DeleteCostCentreDefinitionDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCentreDefinitionDetailMutation, DeleteCostCentreDefinitionDetailMutationVariables>(DeleteCostCentreDefinitionDetailDocument, baseOptions);
      }
export type DeleteCostCentreDefinitionDetailMutationHookResult = ReturnType<typeof useDeleteCostCentreDefinitionDetailMutation>;
export type DeleteCostCentreDefinitionDetailMutationResult = ApolloReactCommon.MutationResult<DeleteCostCentreDefinitionDetailMutation>;
export type DeleteCostCentreDefinitionDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCentreDefinitionDetailMutation, DeleteCostCentreDefinitionDetailMutationVariables>;
export const GetCostCentreTypeDocument = gql`
    query getCostCentreType($CostCentreTypeID: String, $Name: String) {
  getCostCentreType(CostCentreTypeID: $CostCentreTypeID, Name: $Name) {
    CostCentreTypeID
    Name
  }
}
    `;

/**
 * __useGetCostCentreTypeQuery__
 *
 * To run a query within a React component, call `useGetCostCentreTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreTypeQuery({
 *   variables: {
 *      CostCentreTypeID: // value for 'CostCentreTypeID'
 *      Name: // value for 'Name'
 *   },
 * });
 */
export function useGetCostCentreTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreTypeQuery, GetCostCentreTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreTypeQuery, GetCostCentreTypeQueryVariables>(GetCostCentreTypeDocument, baseOptions);
      }
export function useGetCostCentreTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreTypeQuery, GetCostCentreTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreTypeQuery, GetCostCentreTypeQueryVariables>(GetCostCentreTypeDocument, baseOptions);
        }
export type GetCostCentreTypeQueryHookResult = ReturnType<typeof useGetCostCentreTypeQuery>;
export type GetCostCentreTypeLazyQueryHookResult = ReturnType<typeof useGetCostCentreTypeLazyQuery>;
export type GetCostCentreTypeQueryResult = ApolloReactCommon.QueryResult<GetCostCentreTypeQuery, GetCostCentreTypeQueryVariables>;
export const CreateCostCentreTypeDocument = gql`
    mutation createCostCentreType($input: CostCentreTypeInput!) {
  createCostCentreType(input: $input) {
    CostCentreTypeID
    Name
  }
}
    `;
export type CreateCostCentreTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCostCentreTypeMutation, CreateCostCentreTypeMutationVariables>;

/**
 * __useCreateCostCentreTypeMutation__
 *
 * To run a mutation, you first call `useCreateCostCentreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCentreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCentreTypeMutation, { data, loading, error }] = useCreateCostCentreTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCentreTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCentreTypeMutation, CreateCostCentreTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCentreTypeMutation, CreateCostCentreTypeMutationVariables>(CreateCostCentreTypeDocument, baseOptions);
      }
export type CreateCostCentreTypeMutationHookResult = ReturnType<typeof useCreateCostCentreTypeMutation>;
export type CreateCostCentreTypeMutationResult = ApolloReactCommon.MutationResult<CreateCostCentreTypeMutation>;
export type CreateCostCentreTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCentreTypeMutation, CreateCostCentreTypeMutationVariables>;
export const UpdateCostCentreTypeDocument = gql`
    mutation updateCostCentreType($input: CostCentreTypeInput!) {
  updateCostCentreType(input: $input) {
    CostCentreTypeID
  }
}
    `;
export type UpdateCostCentreTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCentreTypeMutation, UpdateCostCentreTypeMutationVariables>;

/**
 * __useUpdateCostCentreTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCostCentreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCentreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCentreTypeMutation, { data, loading, error }] = useUpdateCostCentreTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCentreTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCentreTypeMutation, UpdateCostCentreTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCentreTypeMutation, UpdateCostCentreTypeMutationVariables>(UpdateCostCentreTypeDocument, baseOptions);
      }
export type UpdateCostCentreTypeMutationHookResult = ReturnType<typeof useUpdateCostCentreTypeMutation>;
export type UpdateCostCentreTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCostCentreTypeMutation>;
export type UpdateCostCentreTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCentreTypeMutation, UpdateCostCentreTypeMutationVariables>;
export const DeleteCostCentreTypeDocument = gql`
    mutation deleteCostCentreType($input: CostCentreTypeDeleteInput!) {
  deleteCostCentreType(input: $input)
}
    `;
export type DeleteCostCentreTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCentreTypeMutation, DeleteCostCentreTypeMutationVariables>;

/**
 * __useDeleteCostCentreTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCostCentreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCentreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCentreTypeMutation, { data, loading, error }] = useDeleteCostCentreTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostCentreTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCentreTypeMutation, DeleteCostCentreTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCentreTypeMutation, DeleteCostCentreTypeMutationVariables>(DeleteCostCentreTypeDocument, baseOptions);
      }
export type DeleteCostCentreTypeMutationHookResult = ReturnType<typeof useDeleteCostCentreTypeMutation>;
export type DeleteCostCentreTypeMutationResult = ApolloReactCommon.MutationResult<DeleteCostCentreTypeMutation>;
export type DeleteCostCentreTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCentreTypeMutation, DeleteCostCentreTypeMutationVariables>;
export const CreateUpdateCostCentreTypeDocument = gql`
    mutation createUpdateCostCentreType($CostCentreTypeArr: [CostCentreTypeInput!]!) {
  createUpdateCostCentreType(CostCentreTypeArr: $CostCentreTypeArr)
}
    `;
export type CreateUpdateCostCentreTypeMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateCostCentreTypeMutation, CreateUpdateCostCentreTypeMutationVariables>;

/**
 * __useCreateUpdateCostCentreTypeMutation__
 *
 * To run a mutation, you first call `useCreateUpdateCostCentreTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateCostCentreTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateCostCentreTypeMutation, { data, loading, error }] = useCreateUpdateCostCentreTypeMutation({
 *   variables: {
 *      CostCentreTypeArr: // value for 'CostCentreTypeArr'
 *   },
 * });
 */
export function useCreateUpdateCostCentreTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateCostCentreTypeMutation, CreateUpdateCostCentreTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateCostCentreTypeMutation, CreateUpdateCostCentreTypeMutationVariables>(CreateUpdateCostCentreTypeDocument, baseOptions);
      }
export type CreateUpdateCostCentreTypeMutationHookResult = ReturnType<typeof useCreateUpdateCostCentreTypeMutation>;
export type CreateUpdateCostCentreTypeMutationResult = ApolloReactCommon.MutationResult<CreateUpdateCostCentreTypeMutation>;
export type CreateUpdateCostCentreTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateCostCentreTypeMutation, CreateUpdateCostCentreTypeMutationVariables>;
export const GetCreditCardTypeDocument = gql`
    query getCreditCardType($AccountID: String, $orderByDesc: String, $CreditCardID: String) {
  getCreditCardType(AccountID: $AccountID, orderByDesc: $orderByDesc, CreditCardID: $CreditCardID) {
    CreditCardID
    Name
    Description
    Sequence
    IsSystem
    Payment {
      PaymentID
    }
    Receipt {
      ReceiptID
    }
    FavoritePayment {
      FavoritePaymentID
    }
    FavoriteReceipt {
      FavoriteReceiptID
    }
  }
}
    `;

/**
 * __useGetCreditCardTypeQuery__
 *
 * To run a query within a React component, call `useGetCreditCardTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditCardTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditCardTypeQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      CreditCardID: // value for 'CreditCardID'
 *   },
 * });
 */
export function useGetCreditCardTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditCardTypeQuery, GetCreditCardTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditCardTypeQuery, GetCreditCardTypeQueryVariables>(GetCreditCardTypeDocument, baseOptions);
      }
export function useGetCreditCardTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditCardTypeQuery, GetCreditCardTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditCardTypeQuery, GetCreditCardTypeQueryVariables>(GetCreditCardTypeDocument, baseOptions);
        }
export type GetCreditCardTypeQueryHookResult = ReturnType<typeof useGetCreditCardTypeQuery>;
export type GetCreditCardTypeLazyQueryHookResult = ReturnType<typeof useGetCreditCardTypeLazyQuery>;
export type GetCreditCardTypeQueryResult = ApolloReactCommon.QueryResult<GetCreditCardTypeQuery, GetCreditCardTypeQueryVariables>;
export const CreateCreditCardTypeDocument = gql`
    mutation createCreditCardType($CreditCardTypeInput: [CreditCardTypeInput!]!) {
  createCreditCardType(CreditCardTypeInput: $CreditCardTypeInput)
}
    `;
export type CreateCreditCardTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCreditCardTypeMutation, CreateCreditCardTypeMutationVariables>;

/**
 * __useCreateCreditCardTypeMutation__
 *
 * To run a mutation, you first call `useCreateCreditCardTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditCardTypeMutation, { data, loading, error }] = useCreateCreditCardTypeMutation({
 *   variables: {
 *      CreditCardTypeInput: // value for 'CreditCardTypeInput'
 *   },
 * });
 */
export function useCreateCreditCardTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditCardTypeMutation, CreateCreditCardTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditCardTypeMutation, CreateCreditCardTypeMutationVariables>(CreateCreditCardTypeDocument, baseOptions);
      }
export type CreateCreditCardTypeMutationHookResult = ReturnType<typeof useCreateCreditCardTypeMutation>;
export type CreateCreditCardTypeMutationResult = ApolloReactCommon.MutationResult<CreateCreditCardTypeMutation>;
export type CreateCreditCardTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditCardTypeMutation, CreateCreditCardTypeMutationVariables>;
export const UpdateCreditCardTypeDocument = gql`
    mutation updateCreditCardType($input: CreditCardTypeInput!) {
  updateCreditCardType(input: $input) {
    CreditCardID
  }
}
    `;
export type UpdateCreditCardTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditCardTypeMutation, UpdateCreditCardTypeMutationVariables>;

/**
 * __useUpdateCreditCardTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCreditCardTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditCardTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditCardTypeMutation, { data, loading, error }] = useUpdateCreditCardTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditCardTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditCardTypeMutation, UpdateCreditCardTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditCardTypeMutation, UpdateCreditCardTypeMutationVariables>(UpdateCreditCardTypeDocument, baseOptions);
      }
export type UpdateCreditCardTypeMutationHookResult = ReturnType<typeof useUpdateCreditCardTypeMutation>;
export type UpdateCreditCardTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCreditCardTypeMutation>;
export type UpdateCreditCardTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditCardTypeMutation, UpdateCreditCardTypeMutationVariables>;
export const DeleteCreditCardTypeDocument = gql`
    mutation deleteCreditCardType($input: CreditCardTypeDeleteInput!) {
  deleteCreditCardType(input: $input)
}
    `;
export type DeleteCreditCardTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditCardTypeMutation, DeleteCreditCardTypeMutationVariables>;

/**
 * __useDeleteCreditCardTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCreditCardTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditCardTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditCardTypeMutation, { data, loading, error }] = useDeleteCreditCardTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditCardTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditCardTypeMutation, DeleteCreditCardTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditCardTypeMutation, DeleteCreditCardTypeMutationVariables>(DeleteCreditCardTypeDocument, baseOptions);
      }
export type DeleteCreditCardTypeMutationHookResult = ReturnType<typeof useDeleteCreditCardTypeMutation>;
export type DeleteCreditCardTypeMutationResult = ApolloReactCommon.MutationResult<DeleteCreditCardTypeMutation>;
export type DeleteCreditCardTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditCardTypeMutation, DeleteCreditCardTypeMutationVariables>;
export const GetCreditorAccountDocument = gql`
    query getCreditorAccount($CreditorAccountID: String) {
  getCreditorAccount(CreditorAccountID: $CreditorAccountID) {
    CreditorAccountID
    CreditorTypeID
    CreditorType {
      Code
      Description
    }
    CompanyRegNo
    ContactNo
    DocNoList
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    TaxScheme {
      Code
      Description
    }
    Region
    GSTRegNo
    SSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    PayeeName
    SWIFTCode
    CompanyName
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
    einvoice_msic_code_id
    einvoice_classification_code_id
    einvoice_foreign_supplier
    einvoice_self_billed
    einvoice_send_individually
    einvoice_type
    einvoice_TIN
    IsCompany
    ClassificationCode
    MSICCode
  }
}
    `;

/**
 * __useGetCreditorAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>(GetCreditorAccountDocument, baseOptions);
      }
export function useGetCreditorAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>(GetCreditorAccountDocument, baseOptions);
        }
export type GetCreditorAccountQueryHookResult = ReturnType<typeof useGetCreditorAccountQuery>;
export type GetCreditorAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountLazyQuery>;
export type GetCreditorAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>;
export const GetCreditorAccountCompanyNameDocument = gql`
    query getCreditorAccountCompanyName($CreditorAccountID: String) {
  getCreditorAccount(CreditorAccountID: $CreditorAccountID) {
    CreditorAccountID
    CompanyName
  }
}
    `;

/**
 * __useGetCreditorAccountCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyNameQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyNameQuery, GetCreditorAccountCompanyNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyNameQuery, GetCreditorAccountCompanyNameQueryVariables>(GetCreditorAccountCompanyNameDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyNameQuery, GetCreditorAccountCompanyNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyNameQuery, GetCreditorAccountCompanyNameQueryVariables>(GetCreditorAccountCompanyNameDocument, baseOptions);
        }
export type GetCreditorAccountCompanyNameQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyNameQuery>;
export type GetCreditorAccountCompanyNameLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyNameLazyQuery>;
export type GetCreditorAccountCompanyNameQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyNameQuery, GetCreditorAccountCompanyNameQueryVariables>;
export const GetCreditorAccountListingDocument = gql`
    query getCreditorAccountListing($CreditorAccountID: String, $CreditorTypeEnum: CreditorType, $skip: Float, $take: Float, $searchValue: String, $orderByAsc: String) {
  getCreditorAccount(CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum, skip: $skip, take: $take, searchValue: $searchValue, orderByAsc: $orderByAsc) {
    CreditorAccountID
    CreditorTypeID
    CreditorType {
      Code
      Description
    }
    CompanyRegNo
    ContactNo
    DocNoList
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    TaxScheme {
      Code
      Description
    }
    Region
    GSTRegNo
    SSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    PayeeName
    SWIFTCode
    CompanyName
    einvoice_foreign_supplier
    einvoice_self_billed
    einvoice_send_individually
    einvoice_msic_code_id
    einvoice_classification_code_id
    ClassificationCode
    MSICCode
    einvoice_type
    einvoice_TIN
    IsCompany
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
  }
  getCreditorAccountCount(CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum)
}
    `;

/**
 * __useGetCreditorAccountListingQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountListingQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCreditorAccountListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>(GetCreditorAccountListingDocument, baseOptions);
      }
export function useGetCreditorAccountListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>(GetCreditorAccountListingDocument, baseOptions);
        }
export type GetCreditorAccountListingQueryHookResult = ReturnType<typeof useGetCreditorAccountListingQuery>;
export type GetCreditorAccountListingLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountListingLazyQuery>;
export type GetCreditorAccountListingQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountListingQuery, GetCreditorAccountListingQueryVariables>;
export const CreateCreditorAccountDocument = gql`
    mutation CreateCreditorAccount($input: CreditorAccountInput!, $contactItem: [CreditorContactInput!], $directorItem: [CreditorDirectorInput!]) {
  CreateCreditorAccount(input: $input, contactItem: $contactItem, directorItem: $directorItem) {
    CreditorAccountID
  }
}
    `;
export type CreateCreditorAccountMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>;

/**
 * __useCreateCreditorAccountMutation__
 *
 * To run a mutation, you first call `useCreateCreditorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorAccountMutation, { data, loading, error }] = useCreateCreditorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *      directorItem: // value for 'directorItem'
 *   },
 * });
 */
export function useCreateCreditorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>(CreateCreditorAccountDocument, baseOptions);
      }
export type CreateCreditorAccountMutationHookResult = ReturnType<typeof useCreateCreditorAccountMutation>;
export type CreateCreditorAccountMutationResult = ApolloReactCommon.MutationResult<CreateCreditorAccountMutation>;
export type CreateCreditorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>;
export const UpdateCreditorAccountWithItemDocument = gql`
    mutation UpdateCreditorAccountWithItem($input: CreditorAccountInput!, $contactItem: [CreditorContactInput!], $directorItem: [CreditorDirectorInput!]) {
  UpdateCreditorAccountWithItem(input: $input, contactItem: $contactItem, directorItem: $directorItem)
}
    `;
export type UpdateCreditorAccountWithItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>;

/**
 * __useUpdateCreditorAccountWithItemMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorAccountWithItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorAccountWithItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorAccountWithItemMutation, { data, loading, error }] = useUpdateCreditorAccountWithItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *      directorItem: // value for 'directorItem'
 *   },
 * });
 */
export function useUpdateCreditorAccountWithItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>(UpdateCreditorAccountWithItemDocument, baseOptions);
      }
export type UpdateCreditorAccountWithItemMutationHookResult = ReturnType<typeof useUpdateCreditorAccountWithItemMutation>;
export type UpdateCreditorAccountWithItemMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorAccountWithItemMutation>;
export type UpdateCreditorAccountWithItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>;
export const UpdateCreditorAccountDocument = gql`
    mutation updateCreditorAccount($input: CreditorAccountInput!) {
  updateCreditorAccount(input: $input) {
    CreditorAccountID
  }
}
    `;
export type UpdateCreditorAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>;

/**
 * __useUpdateCreditorAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorAccountMutation, { data, loading, error }] = useUpdateCreditorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>(UpdateCreditorAccountDocument, baseOptions);
      }
export type UpdateCreditorAccountMutationHookResult = ReturnType<typeof useUpdateCreditorAccountMutation>;
export type UpdateCreditorAccountMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorAccountMutation>;
export type UpdateCreditorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>;
export const GetCreditorAccountCompanyAssignmentNameDocument = gql`
    query getCreditorAccountCompanyAssignmentName($CreditorAccountCompanyAssignmentID: String, $CompanyID: String, $CreditorAccountID: String, $orderByAsc: String, $searchValue: String, $take: Float, $skip: Float) {
  getCreditorAccountCompanyAssignment(CreditorAccountCompanyAssignmentID: $CreditorAccountCompanyAssignmentID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, orderByAsc: $orderByAsc, searchValue: $searchValue, take: $take, skip: $skip) {
    CreditorAccountID
    CompanyID
    CreditorAccount {
      CreditTerm
      CreditorAccountID
      CompanyName
      DocNoList
      TotalOutstandingAmt
      einvoice_self_billed
      einvoice_send_individually
      einvoice_classification_code_id
      einvoice_msic_code_id
      createdTs
      modTs
    }
  }
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentNameQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentNameQuery({
 *   variables: {
 *      CreditorAccountCompanyAssignmentID: // value for 'CreditorAccountCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>(GetCreditorAccountCompanyAssignmentNameDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>(GetCreditorAccountCompanyAssignmentNameDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentNameQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentNameQuery>;
export type GetCreditorAccountCompanyAssignmentNameLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentNameLazyQuery>;
export type GetCreditorAccountCompanyAssignmentNameQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentNameQuery, GetCreditorAccountCompanyAssignmentNameQueryVariables>;
export const GetCreditorAccountCompanyAssignmentPermDocument = gql`
    query getCreditorAccountCompanyAssignmentPerm($CreditorAccountCompanyAssignmentID: String, $CompanyID: String, $CreditorAccountID: String, $orderByAsc: String, $searchValue: String, $take: Float, $skip: Float) {
  getCreditorAccountCompanyAssignmentPerm(CreditorAccountCompanyAssignmentID: $CreditorAccountCompanyAssignmentID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, orderByAsc: $orderByAsc, searchValue: $searchValue, take: $take, skip: $skip) {
    CreditorAccountCompanyAssignmentID
    CreditorAccountID
    CompanyID
    CreditorAccount {
      createdTs
      modTs
      CreditorAccountID
      TotalOutstandingAmt(CompanyID: $CompanyID)
      CreditorTypeID
      CompanyRegNo
      DocNoList
      ContactNo
      Email
      CreditTerm
      TaxSchemeID
      Region
      GSTRegNo
      SSTRegNo
      Remark
      BankName
      TaxIdentificationNo
      BankAccountNo
      CreditorTypeEnum
      CompanyName
      modTs
      Address
      CreditorStatus
      CreditorContact {
        CreditorContactID
        CreditorAccountID
        Name
        ContactNo
        Designation
        Email
      }
      CreditorDirector {
        CreditorDirectorID
        CreditorAccountID
        Name
        ContactNo
        Email
        IdentityNo
        IdentityType
      }
    }
  }
  getCreditorAccountCompanyAssignmentCount(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentPermQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentPermQuery({
 *   variables: {
 *      CreditorAccountCompanyAssignmentID: // value for 'CreditorAccountCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentPermQuery, GetCreditorAccountCompanyAssignmentPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentPermQuery, GetCreditorAccountCompanyAssignmentPermQueryVariables>(GetCreditorAccountCompanyAssignmentPermDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentPermQuery, GetCreditorAccountCompanyAssignmentPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentPermQuery, GetCreditorAccountCompanyAssignmentPermQueryVariables>(GetCreditorAccountCompanyAssignmentPermDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentPermQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentPermQuery>;
export type GetCreditorAccountCompanyAssignmentPermLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentPermLazyQuery>;
export type GetCreditorAccountCompanyAssignmentPermQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentPermQuery, GetCreditorAccountCompanyAssignmentPermQueryVariables>;
export const GetCreditorAccountCompanyAssignmentDocument = gql`
    query getCreditorAccountCompanyAssignment($CreditorAccountCompanyAssignmentID: String, $CompanyID: String, $CreditorAccountID: String, $orderByAsc: String, $searchValue: String, $take: Float, $skip: Float) {
  getCreditorAccountCompanyAssignment(CreditorAccountCompanyAssignmentID: $CreditorAccountCompanyAssignmentID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID, orderByAsc: $orderByAsc, searchValue: $searchValue, take: $take, skip: $skip) {
    CreditorAccountCompanyAssignmentID
    CreditorAccountID
    CompanyID
    CreditorAccount {
      createdTs
      modTs
      CreditorAccountID
      TotalOutstandingAmt(CompanyID: $CompanyID)
      CreditorTypeID
      CompanyRegNo
      DocNoList
      ContactNo
      Email
      CreditTerm
      TaxSchemeID
      Region
      GSTRegNo
      SSTRegNo
      Remark
      BankName
      TaxIdentificationNo
      BankAccountNo
      CreditorTypeEnum
      CompanyName
      modTs
      Address
      CreditorStatus
      CreditorContact {
        CreditorContactID
        CreditorAccountID
        Name
        ContactNo
        Designation
        Email
      }
      CreditorDirector {
        CreditorDirectorID
        CreditorAccountID
        Name
        ContactNo
        Email
        IdentityNo
        IdentityType
      }
    }
  }
  getCreditorAccountCompanyAssignmentCount(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentQuery({
 *   variables: {
 *      CreditorAccountCompanyAssignmentID: // value for 'CreditorAccountCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>(GetCreditorAccountCompanyAssignmentDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>(GetCreditorAccountCompanyAssignmentDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentQuery>;
export type GetCreditorAccountCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentLazyQuery>;
export type GetCreditorAccountCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>;
export const AssignCreditorAccountCompanyDocument = gql`
    mutation assignCreditorAccountCompany($input: CreditorAccountCompanyAssignmentInput!) {
  AssignCreditorAccountCompany(input: $input)
}
    `;
export type AssignCreditorAccountCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>;

/**
 * __useAssignCreditorAccountCompanyMutation__
 *
 * To run a mutation, you first call `useAssignCreditorAccountCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCreditorAccountCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCreditorAccountCompanyMutation, { data, loading, error }] = useAssignCreditorAccountCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCreditorAccountCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>(AssignCreditorAccountCompanyDocument, baseOptions);
      }
export type AssignCreditorAccountCompanyMutationHookResult = ReturnType<typeof useAssignCreditorAccountCompanyMutation>;
export type AssignCreditorAccountCompanyMutationResult = ApolloReactCommon.MutationResult<AssignCreditorAccountCompanyMutation>;
export type AssignCreditorAccountCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>;
export const UnassignCreditorAccountCompanyDocument = gql`
    mutation unassignCreditorAccountCompany($input: CreditorAccountCompanyAssignmentInput!) {
  UnassignCreditorAccountCompany(input: $input)
}
    `;
export type UnassignCreditorAccountCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>;

/**
 * __useUnassignCreditorAccountCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignCreditorAccountCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCreditorAccountCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCreditorAccountCompanyMutation, { data, loading, error }] = useUnassignCreditorAccountCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCreditorAccountCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>(UnassignCreditorAccountCompanyDocument, baseOptions);
      }
export type UnassignCreditorAccountCompanyMutationHookResult = ReturnType<typeof useUnassignCreditorAccountCompanyMutation>;
export type UnassignCreditorAccountCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignCreditorAccountCompanyMutation>;
export type UnassignCreditorAccountCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>;
export const CreditorAccountTotalDueDocument = gql`
    query CreditorAccountTotalDue($CompanyID: String!) {
  CreditorAccountTotalDue(CompanyID: $CompanyID)
}
    `;

/**
 * __useCreditorAccountTotalDueQuery__
 *
 * To run a query within a React component, call `useCreditorAccountTotalDueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditorAccountTotalDueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditorAccountTotalDueQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCreditorAccountTotalDueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>(CreditorAccountTotalDueDocument, baseOptions);
      }
export function useCreditorAccountTotalDueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>(CreditorAccountTotalDueDocument, baseOptions);
        }
export type CreditorAccountTotalDueQueryHookResult = ReturnType<typeof useCreditorAccountTotalDueQuery>;
export type CreditorAccountTotalDueLazyQueryHookResult = ReturnType<typeof useCreditorAccountTotalDueLazyQuery>;
export type CreditorAccountTotalDueQueryResult = ApolloReactCommon.QueryResult<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>;
export const GetCheckingValidationCreditorProfileDocument = gql`
    query getCheckingValidationCreditorProfile($CreditorProfileImportInput: [CreditorProfileImportInput!]!) {
  getCheckingValidationCreditorProfile(CreditorProfileImportInput: $CreditorProfileImportInput)
}
    `;

/**
 * __useGetCheckingValidationCreditorProfileQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationCreditorProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationCreditorProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationCreditorProfileQuery({
 *   variables: {
 *      CreditorProfileImportInput: // value for 'CreditorProfileImportInput'
 *   },
 * });
 */
export function useGetCheckingValidationCreditorProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationCreditorProfileQuery, GetCheckingValidationCreditorProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationCreditorProfileQuery, GetCheckingValidationCreditorProfileQueryVariables>(GetCheckingValidationCreditorProfileDocument, baseOptions);
      }
export function useGetCheckingValidationCreditorProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationCreditorProfileQuery, GetCheckingValidationCreditorProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationCreditorProfileQuery, GetCheckingValidationCreditorProfileQueryVariables>(GetCheckingValidationCreditorProfileDocument, baseOptions);
        }
export type GetCheckingValidationCreditorProfileQueryHookResult = ReturnType<typeof useGetCheckingValidationCreditorProfileQuery>;
export type GetCheckingValidationCreditorProfileLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationCreditorProfileLazyQuery>;
export type GetCheckingValidationCreditorProfileQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationCreditorProfileQuery, GetCheckingValidationCreditorProfileQueryVariables>;
export const CreateCreditorProfileImportDocument = gql`
    mutation createCreditorProfileImport($TemplateStr: String!) {
  createCreditorProfileImport(TemplateStr: $TemplateStr)
}
    `;
export type CreateCreditorProfileImportMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorProfileImportMutation, CreateCreditorProfileImportMutationVariables>;

/**
 * __useCreateCreditorProfileImportMutation__
 *
 * To run a mutation, you first call `useCreateCreditorProfileImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorProfileImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorProfileImportMutation, { data, loading, error }] = useCreateCreditorProfileImportMutation({
 *   variables: {
 *      TemplateStr: // value for 'TemplateStr'
 *   },
 * });
 */
export function useCreateCreditorProfileImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorProfileImportMutation, CreateCreditorProfileImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorProfileImportMutation, CreateCreditorProfileImportMutationVariables>(CreateCreditorProfileImportDocument, baseOptions);
      }
export type CreateCreditorProfileImportMutationHookResult = ReturnType<typeof useCreateCreditorProfileImportMutation>;
export type CreateCreditorProfileImportMutationResult = ApolloReactCommon.MutationResult<CreateCreditorProfileImportMutation>;
export type CreateCreditorProfileImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorProfileImportMutation, CreateCreditorProfileImportMutationVariables>;
export const GetCreditorAccountCompanyAssignmentListingDocument = gql`
    query getCreditorAccountCompanyAssignmentListing($CompanyID: String!, $searchValue: String, $take: Float, $skip: Float) {
  getCreditorAccountCompanyAssignmentListing(CompanyID: $CompanyID, searchValue: $searchValue, take: $take, skip: $skip) {
    Data {
      CreditorAccountCompanyAssignmentID
      CreditorAccountID
      CompanyID
      CreditorAccount {
        createdTs
        modTs
        CreditorAccountID
        TotalOutstandingAmt(CompanyID: $CompanyID)
        CreditorTypeID
        CompanyRegNo
        DocNoList
        ContactNo
        Email
        CreditTerm
        TaxSchemeID
        Region
        GSTRegNo
        SSTRegNo
        Remark
        BankName
        TaxIdentificationNo
        BankAccountNo
        CreditorTypeEnum
        CompanyName
        modTs
        Address
        CreditorStatus
        CreditorContact {
          CreditorContactID
          CreditorAccountID
          Name
          ContactNo
          Designation
          Email
        }
        CreditorDirector {
          CreditorDirectorID
          CreditorAccountID
          Name
          ContactNo
          Email
          IdentityNo
          IdentityType
        }
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentListingQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentListingQuery, GetCreditorAccountCompanyAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentListingQuery, GetCreditorAccountCompanyAssignmentListingQueryVariables>(GetCreditorAccountCompanyAssignmentListingDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentListingQuery, GetCreditorAccountCompanyAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentListingQuery, GetCreditorAccountCompanyAssignmentListingQueryVariables>(GetCreditorAccountCompanyAssignmentListingDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentListingQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentListingQuery>;
export type GetCreditorAccountCompanyAssignmentListingLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentListingLazyQuery>;
export type GetCreditorAccountCompanyAssignmentListingQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentListingQuery, GetCreditorAccountCompanyAssignmentListingQueryVariables>;
export const GetCreditorTypeDocument = gql`
    query getCreditorType($CreditorTypeID: String, $CreditorType: CreditorType, $orderByAsc: String, $orderByDesc: String) {
  getCreditorType(CreditorTypeID: $CreditorTypeID, CreditorType: $CreditorType, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    CreditorTypeID
    MasterCOAID
    AccrualCOAID
    AdvanceCOAID
    SelfBilledCOAID
    Description
    CreditorType
    Code
    CreditorAccount {
      CreditorAccountID
      CreditorTypeID
      CompanyRegNo
      CompanyName
      ContactNo
    }
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetCreditorTypeQuery__
 *
 * To run a query within a React component, call `useGetCreditorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTypeQuery({
 *   variables: {
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorType: // value for 'CreditorType'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCreditorTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>(GetCreditorTypeDocument, baseOptions);
      }
export function useGetCreditorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>(GetCreditorTypeDocument, baseOptions);
        }
export type GetCreditorTypeQueryHookResult = ReturnType<typeof useGetCreditorTypeQuery>;
export type GetCreditorTypeLazyQueryHookResult = ReturnType<typeof useGetCreditorTypeLazyQuery>;
export type GetCreditorTypeQueryResult = ApolloReactCommon.QueryResult<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>;
export const GetCreditorTypeListingDocument = gql`
    query getCreditorTypeListing($CreditorTypeID: String, $CreditorType: CreditorType, $orderByAsc: String, $skip: Float, $take: Float, $searchValue: String) {
  getCreditorType(CreditorTypeID: $CreditorTypeID, CreditorType: $CreditorType, orderByAsc: $orderByAsc, skip: $skip, take: $take, searchValue: $searchValue) {
    CreditorTypeID
    MasterCOAID
    AccrualCOAID
    AdvanceCOAID
    SelfBilledCOAID
    Description
    CreditorType
    Code
    CreditorAccount {
      CreditorAccountID
      CreditorTypeID
      CompanyRegNo
      CompanyName
      ContactNo
    }
    MasterCOA {
      Name
      Code
    }
  }
  getCreditorTypeCount(CreditorTypeID: $CreditorTypeID, CreditorType: $CreditorType)
}
    `;

/**
 * __useGetCreditorTypeListingQuery__
 *
 * To run a query within a React component, call `useGetCreditorTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTypeListingQuery({
 *   variables: {
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorType: // value for 'CreditorType'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCreditorTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTypeListingQuery, GetCreditorTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTypeListingQuery, GetCreditorTypeListingQueryVariables>(GetCreditorTypeListingDocument, baseOptions);
      }
export function useGetCreditorTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTypeListingQuery, GetCreditorTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTypeListingQuery, GetCreditorTypeListingQueryVariables>(GetCreditorTypeListingDocument, baseOptions);
        }
export type GetCreditorTypeListingQueryHookResult = ReturnType<typeof useGetCreditorTypeListingQuery>;
export type GetCreditorTypeListingLazyQueryHookResult = ReturnType<typeof useGetCreditorTypeListingLazyQuery>;
export type GetCreditorTypeListingQueryResult = ApolloReactCommon.QueryResult<GetCreditorTypeListingQuery, GetCreditorTypeListingQueryVariables>;
export const CreateCreditorTypeDocument = gql`
    mutation createCreditorType($input: CreditorTypeInput!) {
  createCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type CreateCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>;

/**
 * __useCreateCreditorTypeMutation__
 *
 * To run a mutation, you first call `useCreateCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorTypeMutation, { data, loading, error }] = useCreateCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>(CreateCreditorTypeDocument, baseOptions);
      }
export type CreateCreditorTypeMutationHookResult = ReturnType<typeof useCreateCreditorTypeMutation>;
export type CreateCreditorTypeMutationResult = ApolloReactCommon.MutationResult<CreateCreditorTypeMutation>;
export type CreateCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>;
export const UpdateCreditorTypeDocument = gql`
    mutation updateCreditorType($input: CreditorTypeInput!) {
  updateCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type UpdateCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>;

/**
 * __useUpdateCreditorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorTypeMutation, { data, loading, error }] = useUpdateCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>(UpdateCreditorTypeDocument, baseOptions);
      }
export type UpdateCreditorTypeMutationHookResult = ReturnType<typeof useUpdateCreditorTypeMutation>;
export type UpdateCreditorTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorTypeMutation>;
export type UpdateCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>;
export const DeleteCreditorTypeDocument = gql`
    mutation deleteCreditorType($input: CreditorTypeDeleteInput!) {
  deleteCreditorType(input: $input)
}
    `;
export type DeleteCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>;

/**
 * __useDeleteCreditorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditorTypeMutation, { data, loading, error }] = useDeleteCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>(DeleteCreditorTypeDocument, baseOptions);
      }
export type DeleteCreditorTypeMutationHookResult = ReturnType<typeof useDeleteCreditorTypeMutation>;
export type DeleteCreditorTypeMutationResult = ApolloReactCommon.MutationResult<DeleteCreditorTypeMutation>;
export type DeleteCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>;
export const GetCurrencyDocument = gql`
    query getCurrency($AccountID: String) {
  getCurrency(AccountID: $AccountID) {
    ID
    code
    name
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, baseOptions);
      }
export function useGetCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, baseOptions);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencyQueryResult = ApolloReactCommon.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;
export const CreateCurrencyDocument = gql`
    mutation createCurrency($input: CurrencyInput!) {
  createCurrency(input: $input) {
    ID
  }
}
    `;
export type CreateCurrencyMutationFn = ApolloReactCommon.MutationFunction<CreateCurrencyMutation, CreateCurrencyMutationVariables>;

/**
 * __useCreateCurrencyMutation__
 *
 * To run a mutation, you first call `useCreateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrencyMutation, { data, loading, error }] = useCreateCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCurrencyMutation, CreateCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCurrencyMutation, CreateCurrencyMutationVariables>(CreateCurrencyDocument, baseOptions);
      }
export type CreateCurrencyMutationHookResult = ReturnType<typeof useCreateCurrencyMutation>;
export type CreateCurrencyMutationResult = ApolloReactCommon.MutationResult<CreateCurrencyMutation>;
export type CreateCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCurrencyMutation, CreateCurrencyMutationVariables>;
export const UpdateCurrencyDocument = gql`
    mutation updateCurrency($input: CurrencyInput!) {
  updateCurrency(input: $input) {
    ID
  }
}
    `;
export type UpdateCurrencyMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;

/**
 * __useUpdateCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyMutation, { data, loading, error }] = useUpdateCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, baseOptions);
      }
export type UpdateCurrencyMutationHookResult = ReturnType<typeof useUpdateCurrencyMutation>;
export type UpdateCurrencyMutationResult = ApolloReactCommon.MutationResult<UpdateCurrencyMutation>;
export type UpdateCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export const DeleteCurrencyDocument = gql`
    mutation deleteCurrency($input: CurrencyDeleteInput!) {
  deleteCurrency(input: $input)
}
    `;
export type DeleteCurrencyMutationFn = ApolloReactCommon.MutationFunction<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>;

/**
 * __useDeleteCurrencyMutation__
 *
 * To run a mutation, you first call `useDeleteCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrencyMutation, { data, loading, error }] = useDeleteCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCurrencyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>(DeleteCurrencyDocument, baseOptions);
      }
export type DeleteCurrencyMutationHookResult = ReturnType<typeof useDeleteCurrencyMutation>;
export type DeleteCurrencyMutationResult = ApolloReactCommon.MutationResult<DeleteCurrencyMutation>;
export type DeleteCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCurrencyMutation, DeleteCurrencyMutationVariables>;
export const GetCurrencyExchangeDocument = gql`
    query getCurrencyExchange($AccountID: String) {
  getCurrencyExchange(AccountID: $AccountID) {
    CurrencyExchangeID
    FromCurrencyID
    ToCurrencyID
    FromAmt
    ToAmt
    FromCurrency {
      name
      code
    }
    ToCurrency {
      name
      code
    }
  }
}
    `;

/**
 * __useGetCurrencyExchangeQuery__
 *
 * To run a query within a React component, call `useGetCurrencyExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyExchangeQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetCurrencyExchangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencyExchangeQuery, GetCurrencyExchangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrencyExchangeQuery, GetCurrencyExchangeQueryVariables>(GetCurrencyExchangeDocument, baseOptions);
      }
export function useGetCurrencyExchangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencyExchangeQuery, GetCurrencyExchangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrencyExchangeQuery, GetCurrencyExchangeQueryVariables>(GetCurrencyExchangeDocument, baseOptions);
        }
export type GetCurrencyExchangeQueryHookResult = ReturnType<typeof useGetCurrencyExchangeQuery>;
export type GetCurrencyExchangeLazyQueryHookResult = ReturnType<typeof useGetCurrencyExchangeLazyQuery>;
export type GetCurrencyExchangeQueryResult = ApolloReactCommon.QueryResult<GetCurrencyExchangeQuery, GetCurrencyExchangeQueryVariables>;
export const CreateCurrencyExchangeDocument = gql`
    mutation createCurrencyExchange($input: CurrencyExchangeInput!) {
  createCurrencyExchange(input: $input)
}
    `;
export type CreateCurrencyExchangeMutationFn = ApolloReactCommon.MutationFunction<CreateCurrencyExchangeMutation, CreateCurrencyExchangeMutationVariables>;

/**
 * __useCreateCurrencyExchangeMutation__
 *
 * To run a mutation, you first call `useCreateCurrencyExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrencyExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrencyExchangeMutation, { data, loading, error }] = useCreateCurrencyExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrencyExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCurrencyExchangeMutation, CreateCurrencyExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCurrencyExchangeMutation, CreateCurrencyExchangeMutationVariables>(CreateCurrencyExchangeDocument, baseOptions);
      }
export type CreateCurrencyExchangeMutationHookResult = ReturnType<typeof useCreateCurrencyExchangeMutation>;
export type CreateCurrencyExchangeMutationResult = ApolloReactCommon.MutationResult<CreateCurrencyExchangeMutation>;
export type CreateCurrencyExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCurrencyExchangeMutation, CreateCurrencyExchangeMutationVariables>;
export const UpdateCurrencyExchangeDocument = gql`
    mutation updateCurrencyExchange($input: CurrencyExchangeInput!) {
  updateCurrencyExchange(input: $input)
}
    `;
export type UpdateCurrencyExchangeMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrencyExchangeMutation, UpdateCurrencyExchangeMutationVariables>;

/**
 * __useUpdateCurrencyExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyExchangeMutation, { data, loading, error }] = useUpdateCurrencyExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrencyExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrencyExchangeMutation, UpdateCurrencyExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrencyExchangeMutation, UpdateCurrencyExchangeMutationVariables>(UpdateCurrencyExchangeDocument, baseOptions);
      }
export type UpdateCurrencyExchangeMutationHookResult = ReturnType<typeof useUpdateCurrencyExchangeMutation>;
export type UpdateCurrencyExchangeMutationResult = ApolloReactCommon.MutationResult<UpdateCurrencyExchangeMutation>;
export type UpdateCurrencyExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrencyExchangeMutation, UpdateCurrencyExchangeMutationVariables>;
export const DeleteCurrencyExchangeDocument = gql`
    mutation deleteCurrencyExchange($CurrencyExchangeID: String!) {
  deleteCurrencyExchange(CurrencyExchangeID: $CurrencyExchangeID)
}
    `;
export type DeleteCurrencyExchangeMutationFn = ApolloReactCommon.MutationFunction<DeleteCurrencyExchangeMutation, DeleteCurrencyExchangeMutationVariables>;

/**
 * __useDeleteCurrencyExchangeMutation__
 *
 * To run a mutation, you first call `useDeleteCurrencyExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrencyExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrencyExchangeMutation, { data, loading, error }] = useDeleteCurrencyExchangeMutation({
 *   variables: {
 *      CurrencyExchangeID: // value for 'CurrencyExchangeID'
 *   },
 * });
 */
export function useDeleteCurrencyExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCurrencyExchangeMutation, DeleteCurrencyExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCurrencyExchangeMutation, DeleteCurrencyExchangeMutationVariables>(DeleteCurrencyExchangeDocument, baseOptions);
      }
export type DeleteCurrencyExchangeMutationHookResult = ReturnType<typeof useDeleteCurrencyExchangeMutation>;
export type DeleteCurrencyExchangeMutationResult = ApolloReactCommon.MutationResult<DeleteCurrencyExchangeMutation>;
export type DeleteCurrencyExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCurrencyExchangeMutation, DeleteCurrencyExchangeMutationVariables>;
export const GetCurrencyExchangeDataDocument = gql`
    query getCurrencyExchangeData {
  getCurrencyExchangeData
}
    `;

/**
 * __useGetCurrencyExchangeDataQuery__
 *
 * To run a query within a React component, call `useGetCurrencyExchangeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyExchangeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyExchangeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencyExchangeDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencyExchangeDataQuery, GetCurrencyExchangeDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrencyExchangeDataQuery, GetCurrencyExchangeDataQueryVariables>(GetCurrencyExchangeDataDocument, baseOptions);
      }
export function useGetCurrencyExchangeDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencyExchangeDataQuery, GetCurrencyExchangeDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrencyExchangeDataQuery, GetCurrencyExchangeDataQueryVariables>(GetCurrencyExchangeDataDocument, baseOptions);
        }
export type GetCurrencyExchangeDataQueryHookResult = ReturnType<typeof useGetCurrencyExchangeDataQuery>;
export type GetCurrencyExchangeDataLazyQueryHookResult = ReturnType<typeof useGetCurrencyExchangeDataLazyQuery>;
export type GetCurrencyExchangeDataQueryResult = ApolloReactCommon.QueryResult<GetCurrencyExchangeDataQuery, GetCurrencyExchangeDataQueryVariables>;
export const GetDebtorAccountDocument = gql`
    query getDebtorAccount($AccountID: String, $DebtorAccountID: String, $CompanyID: String, $orderByAsc: String, $skip: Float, $take: Float, $searchValue: String) {
  getDebtorAccount(AccountID: $AccountID, DebtorAccountID: $DebtorAccountID, CompanyID: $CompanyID, orderByAsc: $orderByAsc, skip: $skip, take: $take, searchValue: $searchValue) {
    CompanyID
    DebtorAccountID
    DebtorTypeID
    DebtorType {
      Name
    }
    createdTs
    modTs
    TotalAmt
    TotalOSAmt
    DebtorName
    CompanyRegNo
    MobileNo
    OfficeNo
    Email
    CreditTerm
    IsVIP
    IsCompany
    TaxSchemeID
    GSTRegNo
    GSTStartDate
    SSTRegNo
    Remark
    Address
    DebtorContact {
      Name
      ContactNo
      Email
      Designation
    }
    einvoice_TIN
    einvoice_type
    einvoice_send_individually
  }
  getDebtorAccountCount(AccountID: $AccountID, CompanyID: $CompanyID, DebtorAccountID: $DebtorAccountID, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetDebtorAccountQuery__
 *
 * To run a query within a React component, call `useGetDebtorAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtorAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtorAccountQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      DebtorAccountID: // value for 'DebtorAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDebtorAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebtorAccountQuery, GetDebtorAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebtorAccountQuery, GetDebtorAccountQueryVariables>(GetDebtorAccountDocument, baseOptions);
      }
export function useGetDebtorAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebtorAccountQuery, GetDebtorAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebtorAccountQuery, GetDebtorAccountQueryVariables>(GetDebtorAccountDocument, baseOptions);
        }
export type GetDebtorAccountQueryHookResult = ReturnType<typeof useGetDebtorAccountQuery>;
export type GetDebtorAccountLazyQueryHookResult = ReturnType<typeof useGetDebtorAccountLazyQuery>;
export type GetDebtorAccountQueryResult = ApolloReactCommon.QueryResult<GetDebtorAccountQuery, GetDebtorAccountQueryVariables>;
export const CreateDebtorAccountDocument = gql`
    mutation CreateDebtorAccount($input: DebtorAccountInput!, $contactItem: [DebtorContactInput!]) {
  CreateDebtorAccount(input: $input, contactItem: $contactItem)
}
    `;
export type CreateDebtorAccountMutationFn = ApolloReactCommon.MutationFunction<CreateDebtorAccountMutation, CreateDebtorAccountMutationVariables>;

/**
 * __useCreateDebtorAccountMutation__
 *
 * To run a mutation, you first call `useCreateDebtorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDebtorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDebtorAccountMutation, { data, loading, error }] = useCreateDebtorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *   },
 * });
 */
export function useCreateDebtorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDebtorAccountMutation, CreateDebtorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDebtorAccountMutation, CreateDebtorAccountMutationVariables>(CreateDebtorAccountDocument, baseOptions);
      }
export type CreateDebtorAccountMutationHookResult = ReturnType<typeof useCreateDebtorAccountMutation>;
export type CreateDebtorAccountMutationResult = ApolloReactCommon.MutationResult<CreateDebtorAccountMutation>;
export type CreateDebtorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDebtorAccountMutation, CreateDebtorAccountMutationVariables>;
export const UpdateDebtorAccountDocument = gql`
    mutation updateDebtorAccount($input: DebtorAccountInput!) {
  updateDebtorAccount(input: $input) {
    DebtorAccountID
  }
}
    `;
export type UpdateDebtorAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateDebtorAccountMutation, UpdateDebtorAccountMutationVariables>;

/**
 * __useUpdateDebtorAccountMutation__
 *
 * To run a mutation, you first call `useUpdateDebtorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDebtorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDebtorAccountMutation, { data, loading, error }] = useUpdateDebtorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDebtorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDebtorAccountMutation, UpdateDebtorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDebtorAccountMutation, UpdateDebtorAccountMutationVariables>(UpdateDebtorAccountDocument, baseOptions);
      }
export type UpdateDebtorAccountMutationHookResult = ReturnType<typeof useUpdateDebtorAccountMutation>;
export type UpdateDebtorAccountMutationResult = ApolloReactCommon.MutationResult<UpdateDebtorAccountMutation>;
export type UpdateDebtorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDebtorAccountMutation, UpdateDebtorAccountMutationVariables>;
export const UpdateDebtorAccountWithItemDocument = gql`
    mutation UpdateDebtorAccountWithItem($input: DebtorAccountInput!, $contactItem: [DebtorContactInput!]) {
  UpdateDebtorAccountWithItem(input: $input, contactItem: $contactItem)
}
    `;
export type UpdateDebtorAccountWithItemMutationFn = ApolloReactCommon.MutationFunction<UpdateDebtorAccountWithItemMutation, UpdateDebtorAccountWithItemMutationVariables>;

/**
 * __useUpdateDebtorAccountWithItemMutation__
 *
 * To run a mutation, you first call `useUpdateDebtorAccountWithItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDebtorAccountWithItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDebtorAccountWithItemMutation, { data, loading, error }] = useUpdateDebtorAccountWithItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *   },
 * });
 */
export function useUpdateDebtorAccountWithItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDebtorAccountWithItemMutation, UpdateDebtorAccountWithItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDebtorAccountWithItemMutation, UpdateDebtorAccountWithItemMutationVariables>(UpdateDebtorAccountWithItemDocument, baseOptions);
      }
export type UpdateDebtorAccountWithItemMutationHookResult = ReturnType<typeof useUpdateDebtorAccountWithItemMutation>;
export type UpdateDebtorAccountWithItemMutationResult = ApolloReactCommon.MutationResult<UpdateDebtorAccountWithItemMutation>;
export type UpdateDebtorAccountWithItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDebtorAccountWithItemMutation, UpdateDebtorAccountWithItemMutationVariables>;
export const GetCheckingValidationDebtorAccountDocument = gql`
    query getCheckingValidationDebtorAccount($DebtorAccountImportInput: [DebtorAccountImportInput!]!, $CompanyID: String!) {
  getCheckingValidationDebtorAccount(DebtorAccountImportInput: $DebtorAccountImportInput, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCheckingValidationDebtorAccountQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationDebtorAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationDebtorAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationDebtorAccountQuery({
 *   variables: {
 *      DebtorAccountImportInput: // value for 'DebtorAccountImportInput'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCheckingValidationDebtorAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationDebtorAccountQuery, GetCheckingValidationDebtorAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationDebtorAccountQuery, GetCheckingValidationDebtorAccountQueryVariables>(GetCheckingValidationDebtorAccountDocument, baseOptions);
      }
export function useGetCheckingValidationDebtorAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationDebtorAccountQuery, GetCheckingValidationDebtorAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationDebtorAccountQuery, GetCheckingValidationDebtorAccountQueryVariables>(GetCheckingValidationDebtorAccountDocument, baseOptions);
        }
export type GetCheckingValidationDebtorAccountQueryHookResult = ReturnType<typeof useGetCheckingValidationDebtorAccountQuery>;
export type GetCheckingValidationDebtorAccountLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationDebtorAccountLazyQuery>;
export type GetCheckingValidationDebtorAccountQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationDebtorAccountQuery, GetCheckingValidationDebtorAccountQueryVariables>;
export const CreateDebtorAccountImportDocument = gql`
    mutation createDebtorAccountImport($TemplateStr: String!, $CompanyID: String!) {
  createDebtorAccountImport(TemplateStr: $TemplateStr, CompanyID: $CompanyID)
}
    `;
export type CreateDebtorAccountImportMutationFn = ApolloReactCommon.MutationFunction<CreateDebtorAccountImportMutation, CreateDebtorAccountImportMutationVariables>;

/**
 * __useCreateDebtorAccountImportMutation__
 *
 * To run a mutation, you first call `useCreateDebtorAccountImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDebtorAccountImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDebtorAccountImportMutation, { data, loading, error }] = useCreateDebtorAccountImportMutation({
 *   variables: {
 *      TemplateStr: // value for 'TemplateStr'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCreateDebtorAccountImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDebtorAccountImportMutation, CreateDebtorAccountImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDebtorAccountImportMutation, CreateDebtorAccountImportMutationVariables>(CreateDebtorAccountImportDocument, baseOptions);
      }
export type CreateDebtorAccountImportMutationHookResult = ReturnType<typeof useCreateDebtorAccountImportMutation>;
export type CreateDebtorAccountImportMutationResult = ApolloReactCommon.MutationResult<CreateDebtorAccountImportMutation>;
export type CreateDebtorAccountImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDebtorAccountImportMutation, CreateDebtorAccountImportMutationVariables>;
export const GetDebtorTypeDocument = gql`
    query getDebtorType($AccountID: String, $DebtorTypeID: String, $skip: Float, $take: Float, $searchValue: String, $orderByAsc: String) {
  getDebtorType(AccountID: $AccountID, DebtorTypeID: $DebtorTypeID, skip: $skip, take: $take, searchValue: $searchValue, orderByAsc: $orderByAsc) {
    DebtorTypeID
    Name
    MasterCOAID
    MasterCOA {
      Code
    }
    DebtorAccount {
      DebtorAccountID
      DebtorTypeID
      CompanyRegNo
      DebtorName
    }
  }
  getDebtorTypeCount(AccountID: $AccountID, DebtorTypeID: $DebtorTypeID, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetDebtorTypeQuery__
 *
 * To run a query within a React component, call `useGetDebtorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtorTypeQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      DebtorTypeID: // value for 'DebtorTypeID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDebtorTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDebtorTypeQuery, GetDebtorTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDebtorTypeQuery, GetDebtorTypeQueryVariables>(GetDebtorTypeDocument, baseOptions);
      }
export function useGetDebtorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDebtorTypeQuery, GetDebtorTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDebtorTypeQuery, GetDebtorTypeQueryVariables>(GetDebtorTypeDocument, baseOptions);
        }
export type GetDebtorTypeQueryHookResult = ReturnType<typeof useGetDebtorTypeQuery>;
export type GetDebtorTypeLazyQueryHookResult = ReturnType<typeof useGetDebtorTypeLazyQuery>;
export type GetDebtorTypeQueryResult = ApolloReactCommon.QueryResult<GetDebtorTypeQuery, GetDebtorTypeQueryVariables>;
export const CreateDebtorTypeDocument = gql`
    mutation createDebtorType($input: DebtorTypeInput!) {
  createDebtorType(input: $input) {
    DebtorTypeID
  }
}
    `;
export type CreateDebtorTypeMutationFn = ApolloReactCommon.MutationFunction<CreateDebtorTypeMutation, CreateDebtorTypeMutationVariables>;

/**
 * __useCreateDebtorTypeMutation__
 *
 * To run a mutation, you first call `useCreateDebtorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDebtorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDebtorTypeMutation, { data, loading, error }] = useCreateDebtorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDebtorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDebtorTypeMutation, CreateDebtorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDebtorTypeMutation, CreateDebtorTypeMutationVariables>(CreateDebtorTypeDocument, baseOptions);
      }
export type CreateDebtorTypeMutationHookResult = ReturnType<typeof useCreateDebtorTypeMutation>;
export type CreateDebtorTypeMutationResult = ApolloReactCommon.MutationResult<CreateDebtorTypeMutation>;
export type CreateDebtorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDebtorTypeMutation, CreateDebtorTypeMutationVariables>;
export const UpdateDebtorTypeDocument = gql`
    mutation updateDebtorType($input: DebtorTypeInput!) {
  updateDebtorType(input: $input) {
    DebtorTypeID
  }
}
    `;
export type UpdateDebtorTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateDebtorTypeMutation, UpdateDebtorTypeMutationVariables>;

/**
 * __useUpdateDebtorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateDebtorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDebtorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDebtorTypeMutation, { data, loading, error }] = useUpdateDebtorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDebtorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDebtorTypeMutation, UpdateDebtorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDebtorTypeMutation, UpdateDebtorTypeMutationVariables>(UpdateDebtorTypeDocument, baseOptions);
      }
export type UpdateDebtorTypeMutationHookResult = ReturnType<typeof useUpdateDebtorTypeMutation>;
export type UpdateDebtorTypeMutationResult = ApolloReactCommon.MutationResult<UpdateDebtorTypeMutation>;
export type UpdateDebtorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDebtorTypeMutation, UpdateDebtorTypeMutationVariables>;
export const DeleteDebtorTypeDocument = gql`
    mutation deleteDebtorType($input: DebtorTypeDeleteInput!) {
  deleteDebtorType(input: $input)
}
    `;
export type DeleteDebtorTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteDebtorTypeMutation, DeleteDebtorTypeMutationVariables>;

/**
 * __useDeleteDebtorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteDebtorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDebtorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDebtorTypeMutation, { data, loading, error }] = useDeleteDebtorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDebtorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDebtorTypeMutation, DeleteDebtorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDebtorTypeMutation, DeleteDebtorTypeMutationVariables>(DeleteDebtorTypeDocument, baseOptions);
      }
export type DeleteDebtorTypeMutationHookResult = ReturnType<typeof useDeleteDebtorTypeMutation>;
export type DeleteDebtorTypeMutationResult = ApolloReactCommon.MutationResult<DeleteDebtorTypeMutation>;
export type DeleteDebtorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDebtorTypeMutation, DeleteDebtorTypeMutationVariables>;
export const GetDrawerPermissionDocument = gql`
    query getDrawerPermission($pageMode: String) {
  getDrawerPermission(pageMode: $pageMode)
}
    `;

/**
 * __useGetDrawerPermissionQuery__
 *
 * To run a query within a React component, call `useGetDrawerPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawerPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawerPermissionQuery({
 *   variables: {
 *      pageMode: // value for 'pageMode'
 *   },
 * });
 */
export function useGetDrawerPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>(GetDrawerPermissionDocument, baseOptions);
      }
export function useGetDrawerPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>(GetDrawerPermissionDocument, baseOptions);
        }
export type GetDrawerPermissionQueryHookResult = ReturnType<typeof useGetDrawerPermissionQuery>;
export type GetDrawerPermissionLazyQueryHookResult = ReturnType<typeof useGetDrawerPermissionLazyQuery>;
export type GetDrawerPermissionQueryResult = ApolloReactCommon.QueryResult<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>;
export const GetStandardLetterDocument = gql`
    query getStandardLetter($StandardLetterID: String, $LetterCategory: String) {
  getStandardLetter(StandardLetterID: $StandardLetterID, LetterCategory: $LetterCategory) {
    StandardLetterID
    LetterType
    Name
    LetterCategory
    RecordStatus
    LetterTemplateSubscription {
      LetterTemplateID
    }
    CompanyLetterTemplateSubscription {
      CompanyLetterTemplateID
    }
  }
}
    `;

/**
 * __useGetStandardLetterQuery__
 *
 * To run a query within a React component, call `useGetStandardLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardLetterQuery({
 *   variables: {
 *      StandardLetterID: // value for 'StandardLetterID'
 *      LetterCategory: // value for 'LetterCategory'
 *   },
 * });
 */
export function useGetStandardLetterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStandardLetterQuery, GetStandardLetterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStandardLetterQuery, GetStandardLetterQueryVariables>(GetStandardLetterDocument, baseOptions);
      }
export function useGetStandardLetterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStandardLetterQuery, GetStandardLetterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStandardLetterQuery, GetStandardLetterQueryVariables>(GetStandardLetterDocument, baseOptions);
        }
export type GetStandardLetterQueryHookResult = ReturnType<typeof useGetStandardLetterQuery>;
export type GetStandardLetterLazyQueryHookResult = ReturnType<typeof useGetStandardLetterLazyQuery>;
export type GetStandardLetterQueryResult = ApolloReactCommon.QueryResult<GetStandardLetterQuery, GetStandardLetterQueryVariables>;
export const GetLetterTemplateDocument = gql`
    query getLetterTemplate($LetterTemplateID: String, $StandardLetterID: String, $orderByAsc: String) {
  getLetterTemplate(LetterTemplateID: $LetterTemplateID, StandardLetterID: $StandardLetterID, orderByAsc: $orderByAsc) {
    LetterTemplateID
    StandardLetterID
    Name
    JointBuyerSignRequired
    StandardLetterDataSourceVersion
    IsReportDesignEditor
    TemplateBody
    RecordStatus
    IsDefault
  }
}
    `;

/**
 * __useGetLetterTemplateQuery__
 *
 * To run a query within a React component, call `useGetLetterTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterTemplateQuery({
 *   variables: {
 *      LetterTemplateID: // value for 'LetterTemplateID'
 *      StandardLetterID: // value for 'StandardLetterID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetLetterTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>(GetLetterTemplateDocument, baseOptions);
      }
export function useGetLetterTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>(GetLetterTemplateDocument, baseOptions);
        }
export type GetLetterTemplateQueryHookResult = ReturnType<typeof useGetLetterTemplateQuery>;
export type GetLetterTemplateLazyQueryHookResult = ReturnType<typeof useGetLetterTemplateLazyQuery>;
export type GetLetterTemplateQueryResult = ApolloReactCommon.QueryResult<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>;
export const CreateLetterTemplateDocument = gql`
    mutation createLetterTemplate($input: LetterTemplateInput!) {
  createLetterTemplate(input: $input) {
    LetterTemplateID
  }
}
    `;
export type CreateLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>;

/**
 * __useCreateLetterTemplateMutation__
 *
 * To run a mutation, you first call `useCreateLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLetterTemplateMutation, { data, loading, error }] = useCreateLetterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>(CreateLetterTemplateDocument, baseOptions);
      }
export type CreateLetterTemplateMutationHookResult = ReturnType<typeof useCreateLetterTemplateMutation>;
export type CreateLetterTemplateMutationResult = ApolloReactCommon.MutationResult<CreateLetterTemplateMutation>;
export type CreateLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>;
export const UpdateLetterTemplateDocument = gql`
    mutation updateLetterTemplate($input: LetterTemplateInput!) {
  updateLetterTemplate(input: $input)
}
    `;
export type UpdateLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>;

/**
 * __useUpdateLetterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLetterTemplateMutation, { data, loading, error }] = useUpdateLetterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>(UpdateLetterTemplateDocument, baseOptions);
      }
export type UpdateLetterTemplateMutationHookResult = ReturnType<typeof useUpdateLetterTemplateMutation>;
export type UpdateLetterTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateLetterTemplateMutation>;
export type UpdateLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>;
export const DeleteLetterTemplateDocument = gql`
    mutation deleteLetterTemplate($input: LetterTemplateDeleteInput!) {
  deleteLetterTemplate(input: $input)
}
    `;
export type DeleteLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteLetterTemplateMutation, DeleteLetterTemplateMutationVariables>;

/**
 * __useDeleteLetterTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLetterTemplateMutation, { data, loading, error }] = useDeleteLetterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLetterTemplateMutation, DeleteLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLetterTemplateMutation, DeleteLetterTemplateMutationVariables>(DeleteLetterTemplateDocument, baseOptions);
      }
export type DeleteLetterTemplateMutationHookResult = ReturnType<typeof useDeleteLetterTemplateMutation>;
export type DeleteLetterTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteLetterTemplateMutation>;
export type DeleteLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLetterTemplateMutation, DeleteLetterTemplateMutationVariables>;
export const GetCompanyLetterTemplateDocument = gql`
    query getCompanyLetterTemplate($CompanyLetterTemplateID: String, $StandardLetterID: String, $orderByAsc: String) {
  getCompanyLetterTemplate(CompanyLetterTemplateID: $CompanyLetterTemplateID, StandardLetterID: $StandardLetterID, orderByAsc: $orderByAsc) {
    CompanyLetterTemplateID
    StandardLetterID
    Name
    JointBuyerSignRequired
    StandardLetterDataSourceVersion
    IsReportDesignEditor
    TemplateBody
    RecordStatus
    IsDefault
    LetterTemplateID
  }
}
    `;

/**
 * __useGetCompanyLetterTemplateQuery__
 *
 * To run a query within a React component, call `useGetCompanyLetterTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyLetterTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyLetterTemplateQuery({
 *   variables: {
 *      CompanyLetterTemplateID: // value for 'CompanyLetterTemplateID'
 *      StandardLetterID: // value for 'StandardLetterID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCompanyLetterTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyLetterTemplateQuery, GetCompanyLetterTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyLetterTemplateQuery, GetCompanyLetterTemplateQueryVariables>(GetCompanyLetterTemplateDocument, baseOptions);
      }
export function useGetCompanyLetterTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyLetterTemplateQuery, GetCompanyLetterTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyLetterTemplateQuery, GetCompanyLetterTemplateQueryVariables>(GetCompanyLetterTemplateDocument, baseOptions);
        }
export type GetCompanyLetterTemplateQueryHookResult = ReturnType<typeof useGetCompanyLetterTemplateQuery>;
export type GetCompanyLetterTemplateLazyQueryHookResult = ReturnType<typeof useGetCompanyLetterTemplateLazyQuery>;
export type GetCompanyLetterTemplateQueryResult = ApolloReactCommon.QueryResult<GetCompanyLetterTemplateQuery, GetCompanyLetterTemplateQueryVariables>;
export const CreateCompanyLetterTemplateDocument = gql`
    mutation createCompanyLetterTemplate($input: CompanyLetterTemplateInput!) {
  createCompanyLetterTemplate(input: $input) {
    CompanyLetterTemplateID
  }
}
    `;
export type CreateCompanyLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyLetterTemplateMutation, CreateCompanyLetterTemplateMutationVariables>;

/**
 * __useCreateCompanyLetterTemplateMutation__
 *
 * To run a mutation, you first call `useCreateCompanyLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyLetterTemplateMutation, { data, loading, error }] = useCreateCompanyLetterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyLetterTemplateMutation, CreateCompanyLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyLetterTemplateMutation, CreateCompanyLetterTemplateMutationVariables>(CreateCompanyLetterTemplateDocument, baseOptions);
      }
export type CreateCompanyLetterTemplateMutationHookResult = ReturnType<typeof useCreateCompanyLetterTemplateMutation>;
export type CreateCompanyLetterTemplateMutationResult = ApolloReactCommon.MutationResult<CreateCompanyLetterTemplateMutation>;
export type CreateCompanyLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyLetterTemplateMutation, CreateCompanyLetterTemplateMutationVariables>;
export const UpdateCompanyLetterTemplateDocument = gql`
    mutation updateCompanyLetterTemplate($input: CompanyLetterTemplateInput!) {
  updateCompanyLetterTemplate(input: $input)
}
    `;
export type UpdateCompanyLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyLetterTemplateMutation, UpdateCompanyLetterTemplateMutationVariables>;

/**
 * __useUpdateCompanyLetterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyLetterTemplateMutation, { data, loading, error }] = useUpdateCompanyLetterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyLetterTemplateMutation, UpdateCompanyLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyLetterTemplateMutation, UpdateCompanyLetterTemplateMutationVariables>(UpdateCompanyLetterTemplateDocument, baseOptions);
      }
export type UpdateCompanyLetterTemplateMutationHookResult = ReturnType<typeof useUpdateCompanyLetterTemplateMutation>;
export type UpdateCompanyLetterTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyLetterTemplateMutation>;
export type UpdateCompanyLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyLetterTemplateMutation, UpdateCompanyLetterTemplateMutationVariables>;
export const DeleteCompanyLetterTemplateDocument = gql`
    mutation deleteCompanyLetterTemplate($input: CompanyLetterTemplateDeleteInput!) {
  deleteCompanyLetterTemplate(input: $input)
}
    `;
export type DeleteCompanyLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyLetterTemplateMutation, DeleteCompanyLetterTemplateMutationVariables>;

/**
 * __useDeleteCompanyLetterTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyLetterTemplateMutation, { data, loading, error }] = useDeleteCompanyLetterTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyLetterTemplateMutation, DeleteCompanyLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyLetterTemplateMutation, DeleteCompanyLetterTemplateMutationVariables>(DeleteCompanyLetterTemplateDocument, baseOptions);
      }
export type DeleteCompanyLetterTemplateMutationHookResult = ReturnType<typeof useDeleteCompanyLetterTemplateMutation>;
export type DeleteCompanyLetterTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyLetterTemplateMutation>;
export type DeleteCompanyLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyLetterTemplateMutation, DeleteCompanyLetterTemplateMutationVariables>;
export const GetStandardLetterParameterDocument = gql`
    query getStandardLetterParameter($StandardLetterID: String) {
  getStandardLetterParameter(StandardLetterID: $StandardLetterID) {
    StandardLetterParameterID
    StandardLetterID
    DisplayName
    ColumnName
    RecordStatus
  }
}
    `;

/**
 * __useGetStandardLetterParameterQuery__
 *
 * To run a query within a React component, call `useGetStandardLetterParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardLetterParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardLetterParameterQuery({
 *   variables: {
 *      StandardLetterID: // value for 'StandardLetterID'
 *   },
 * });
 */
export function useGetStandardLetterParameterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStandardLetterParameterQuery, GetStandardLetterParameterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStandardLetterParameterQuery, GetStandardLetterParameterQueryVariables>(GetStandardLetterParameterDocument, baseOptions);
      }
export function useGetStandardLetterParameterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStandardLetterParameterQuery, GetStandardLetterParameterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStandardLetterParameterQuery, GetStandardLetterParameterQueryVariables>(GetStandardLetterParameterDocument, baseOptions);
        }
export type GetStandardLetterParameterQueryHookResult = ReturnType<typeof useGetStandardLetterParameterQuery>;
export type GetStandardLetterParameterLazyQueryHookResult = ReturnType<typeof useGetStandardLetterParameterLazyQuery>;
export type GetStandardLetterParameterQueryResult = ApolloReactCommon.QueryResult<GetStandardLetterParameterQuery, GetStandardLetterParameterQueryVariables>;
export const GetLetterTemplateByModuleDocument = gql`
    query getLetterTemplateByModule($LetterCategory: ModuleType!, $LetterType: DocumentType!, $CompanyID: String) {
  getLetterTemplateByModule(LetterCategory: $LetterCategory, LetterType: $LetterType, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLetterTemplateByModuleQuery__
 *
 * To run a query within a React component, call `useGetLetterTemplateByModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterTemplateByModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterTemplateByModuleQuery({
 *   variables: {
 *      LetterCategory: // value for 'LetterCategory'
 *      LetterType: // value for 'LetterType'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLetterTemplateByModuleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLetterTemplateByModuleQuery, GetLetterTemplateByModuleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLetterTemplateByModuleQuery, GetLetterTemplateByModuleQueryVariables>(GetLetterTemplateByModuleDocument, baseOptions);
      }
export function useGetLetterTemplateByModuleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLetterTemplateByModuleQuery, GetLetterTemplateByModuleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLetterTemplateByModuleQuery, GetLetterTemplateByModuleQueryVariables>(GetLetterTemplateByModuleDocument, baseOptions);
        }
export type GetLetterTemplateByModuleQueryHookResult = ReturnType<typeof useGetLetterTemplateByModuleQuery>;
export type GetLetterTemplateByModuleLazyQueryHookResult = ReturnType<typeof useGetLetterTemplateByModuleLazyQuery>;
export type GetLetterTemplateByModuleQueryResult = ApolloReactCommon.QueryResult<GetLetterTemplateByModuleQuery, GetLetterTemplateByModuleQueryVariables>;
export const GetStandardLetterByCategoryDocument = gql`
    query getStandardLetterByCategory {
  getStandardLetterByCategory
}
    `;

/**
 * __useGetStandardLetterByCategoryQuery__
 *
 * To run a query within a React component, call `useGetStandardLetterByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardLetterByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardLetterByCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStandardLetterByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStandardLetterByCategoryQuery, GetStandardLetterByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStandardLetterByCategoryQuery, GetStandardLetterByCategoryQueryVariables>(GetStandardLetterByCategoryDocument, baseOptions);
      }
export function useGetStandardLetterByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStandardLetterByCategoryQuery, GetStandardLetterByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStandardLetterByCategoryQuery, GetStandardLetterByCategoryQueryVariables>(GetStandardLetterByCategoryDocument, baseOptions);
        }
export type GetStandardLetterByCategoryQueryHookResult = ReturnType<typeof useGetStandardLetterByCategoryQuery>;
export type GetStandardLetterByCategoryLazyQueryHookResult = ReturnType<typeof useGetStandardLetterByCategoryLazyQuery>;
export type GetStandardLetterByCategoryQueryResult = ApolloReactCommon.QueryResult<GetStandardLetterByCategoryQuery, GetStandardLetterByCategoryQueryVariables>;
export const DocumentTemplateCountDocument = gql`
    query DocumentTemplateCount($Module: String!) {
  DocumentTemplateCount(Module: $Module)
}
    `;

/**
 * __useDocumentTemplateCountQuery__
 *
 * To run a query within a React component, call `useDocumentTemplateCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTemplateCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTemplateCountQuery({
 *   variables: {
 *      Module: // value for 'Module'
 *   },
 * });
 */
export function useDocumentTemplateCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentTemplateCountQuery, DocumentTemplateCountQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentTemplateCountQuery, DocumentTemplateCountQueryVariables>(DocumentTemplateCountDocument, baseOptions);
      }
export function useDocumentTemplateCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentTemplateCountQuery, DocumentTemplateCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentTemplateCountQuery, DocumentTemplateCountQueryVariables>(DocumentTemplateCountDocument, baseOptions);
        }
export type DocumentTemplateCountQueryHookResult = ReturnType<typeof useDocumentTemplateCountQuery>;
export type DocumentTemplateCountLazyQueryHookResult = ReturnType<typeof useDocumentTemplateCountLazyQuery>;
export type DocumentTemplateCountQueryResult = ApolloReactCommon.QueryResult<DocumentTemplateCountQuery, DocumentTemplateCountQueryVariables>;
export const CompanyDocumentTemplateCountDocument = gql`
    query CompanyDocumentTemplateCount($Module: String!, $CompanyID: String!) {
  CompanyDocumentTemplateCount(Module: $Module, CompanyID: $CompanyID)
}
    `;

/**
 * __useCompanyDocumentTemplateCountQuery__
 *
 * To run a query within a React component, call `useCompanyDocumentTemplateCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDocumentTemplateCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDocumentTemplateCountQuery({
 *   variables: {
 *      Module: // value for 'Module'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanyDocumentTemplateCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyDocumentTemplateCountQuery, CompanyDocumentTemplateCountQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyDocumentTemplateCountQuery, CompanyDocumentTemplateCountQueryVariables>(CompanyDocumentTemplateCountDocument, baseOptions);
      }
export function useCompanyDocumentTemplateCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyDocumentTemplateCountQuery, CompanyDocumentTemplateCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyDocumentTemplateCountQuery, CompanyDocumentTemplateCountQueryVariables>(CompanyDocumentTemplateCountDocument, baseOptions);
        }
export type CompanyDocumentTemplateCountQueryHookResult = ReturnType<typeof useCompanyDocumentTemplateCountQuery>;
export type CompanyDocumentTemplateCountLazyQueryHookResult = ReturnType<typeof useCompanyDocumentTemplateCountLazyQuery>;
export type CompanyDocumentTemplateCountQueryResult = ApolloReactCommon.QueryResult<CompanyDocumentTemplateCountQuery, CompanyDocumentTemplateCountQueryVariables>;
export const GetExpenseClassDocument = gql`
    query getExpenseClass($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ExpenseClassID: String) {
  getExpenseClass(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ExpenseClassID: $ExpenseClassID) {
    ExpenseClassID
    Name
    ParentExpenseClassID
    Level
    IsLastNode
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
      Name
    }
    ExpenseItem {
      ExpenseClassID
      ExpenseItemID
      UOMID
      UOM {
        Name
        Code
      }
      ItemName
      Description
      Remark
      TaxSchemeID
      TaxRate
    }
  }
}
    `;

/**
 * __useGetExpenseClassQuery__
 *
 * To run a query within a React component, call `useGetExpenseClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseClassQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *   },
 * });
 */
export function useGetExpenseClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpenseClassQuery, GetExpenseClassQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpenseClassQuery, GetExpenseClassQueryVariables>(GetExpenseClassDocument, baseOptions);
      }
export function useGetExpenseClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpenseClassQuery, GetExpenseClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpenseClassQuery, GetExpenseClassQueryVariables>(GetExpenseClassDocument, baseOptions);
        }
export type GetExpenseClassQueryHookResult = ReturnType<typeof useGetExpenseClassQuery>;
export type GetExpenseClassLazyQueryHookResult = ReturnType<typeof useGetExpenseClassLazyQuery>;
export type GetExpenseClassQueryResult = ApolloReactCommon.QueryResult<GetExpenseClassQuery, GetExpenseClassQueryVariables>;
export const GetExpenseClassPermDocument = gql`
    query getExpenseClassPerm($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ExpenseClassID: String) {
  getExpenseClassPerm(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ExpenseClassID: $ExpenseClassID) {
    ExpenseClassID
    Name
    ParentExpenseClassID
    Level
    IsLastNode
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
      Name
    }
    ExpenseItem {
      ExpenseClassID
      ExpenseItemID
      UOMID
      UOM {
        Name
        Code
      }
      ItemName
      Description
      Remark
      TaxSchemeID
      TaxRate
    }
  }
}
    `;

/**
 * __useGetExpenseClassPermQuery__
 *
 * To run a query within a React component, call `useGetExpenseClassPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseClassPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseClassPermQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *   },
 * });
 */
export function useGetExpenseClassPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpenseClassPermQuery, GetExpenseClassPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpenseClassPermQuery, GetExpenseClassPermQueryVariables>(GetExpenseClassPermDocument, baseOptions);
      }
export function useGetExpenseClassPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpenseClassPermQuery, GetExpenseClassPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpenseClassPermQuery, GetExpenseClassPermQueryVariables>(GetExpenseClassPermDocument, baseOptions);
        }
export type GetExpenseClassPermQueryHookResult = ReturnType<typeof useGetExpenseClassPermQuery>;
export type GetExpenseClassPermLazyQueryHookResult = ReturnType<typeof useGetExpenseClassPermLazyQuery>;
export type GetExpenseClassPermQueryResult = ApolloReactCommon.QueryResult<GetExpenseClassPermQuery, GetExpenseClassPermQueryVariables>;
export const GetExpenseClassCoaidDocument = gql`
    query getExpenseClassCOAID($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ExpenseClassID: String) {
  getExpenseClass(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ExpenseClassID: $ExpenseClassID) {
    ExpenseClassID
    MasterCOAID
  }
}
    `;

/**
 * __useGetExpenseClassCoaidQuery__
 *
 * To run a query within a React component, call `useGetExpenseClassCoaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseClassCoaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseClassCoaidQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *   },
 * });
 */
export function useGetExpenseClassCoaidQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpenseClassCoaidQuery, GetExpenseClassCoaidQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpenseClassCoaidQuery, GetExpenseClassCoaidQueryVariables>(GetExpenseClassCoaidDocument, baseOptions);
      }
export function useGetExpenseClassCoaidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpenseClassCoaidQuery, GetExpenseClassCoaidQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpenseClassCoaidQuery, GetExpenseClassCoaidQueryVariables>(GetExpenseClassCoaidDocument, baseOptions);
        }
export type GetExpenseClassCoaidQueryHookResult = ReturnType<typeof useGetExpenseClassCoaidQuery>;
export type GetExpenseClassCoaidLazyQueryHookResult = ReturnType<typeof useGetExpenseClassCoaidLazyQuery>;
export type GetExpenseClassCoaidQueryResult = ApolloReactCommon.QueryResult<GetExpenseClassCoaidQuery, GetExpenseClassCoaidQueryVariables>;
export const GetParentExpenseItemCategoryCoaAccountTypeDocument = gql`
    query getParentExpenseItemCategoryCOAAccountType($ExpenseItemID: String) {
  getParentExpenseItemCategoryCOAAccountType(ExpenseItemID: $ExpenseItemID)
}
    `;

/**
 * __useGetParentExpenseItemCategoryCoaAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetParentExpenseItemCategoryCoaAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentExpenseItemCategoryCoaAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentExpenseItemCategoryCoaAccountTypeQuery({
 *   variables: {
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *   },
 * });
 */
export function useGetParentExpenseItemCategoryCoaAccountTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentExpenseItemCategoryCoaAccountTypeQuery, GetParentExpenseItemCategoryCoaAccountTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentExpenseItemCategoryCoaAccountTypeQuery, GetParentExpenseItemCategoryCoaAccountTypeQueryVariables>(GetParentExpenseItemCategoryCoaAccountTypeDocument, baseOptions);
      }
export function useGetParentExpenseItemCategoryCoaAccountTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentExpenseItemCategoryCoaAccountTypeQuery, GetParentExpenseItemCategoryCoaAccountTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentExpenseItemCategoryCoaAccountTypeQuery, GetParentExpenseItemCategoryCoaAccountTypeQueryVariables>(GetParentExpenseItemCategoryCoaAccountTypeDocument, baseOptions);
        }
export type GetParentExpenseItemCategoryCoaAccountTypeQueryHookResult = ReturnType<typeof useGetParentExpenseItemCategoryCoaAccountTypeQuery>;
export type GetParentExpenseItemCategoryCoaAccountTypeLazyQueryHookResult = ReturnType<typeof useGetParentExpenseItemCategoryCoaAccountTypeLazyQuery>;
export type GetParentExpenseItemCategoryCoaAccountTypeQueryResult = ApolloReactCommon.QueryResult<GetParentExpenseItemCategoryCoaAccountTypeQuery, GetParentExpenseItemCategoryCoaAccountTypeQueryVariables>;
export const GetExpenseClassDetailDocument = gql`
    query getExpenseClassDetail($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ExpenseClassID: String) {
  getExpenseClass(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ExpenseClassID: $ExpenseClassID) {
    ExpenseClassID
    Name
    ParentExpenseClassID
    Level
    IsLastNode
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
      Name
    }
    ParentClassMCOA
    ExpenseItem {
      ExpenseClassID
      ExpenseItemID
      UOMID
      UOM {
        Name
        Code
      }
      ItemName
      Description
      Remark
      TaxSchemeID
      TaxRate
    }
  }
}
    `;

/**
 * __useGetExpenseClassDetailQuery__
 *
 * To run a query within a React component, call `useGetExpenseClassDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseClassDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseClassDetailQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *   },
 * });
 */
export function useGetExpenseClassDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpenseClassDetailQuery, GetExpenseClassDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpenseClassDetailQuery, GetExpenseClassDetailQueryVariables>(GetExpenseClassDetailDocument, baseOptions);
      }
export function useGetExpenseClassDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpenseClassDetailQuery, GetExpenseClassDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpenseClassDetailQuery, GetExpenseClassDetailQueryVariables>(GetExpenseClassDetailDocument, baseOptions);
        }
export type GetExpenseClassDetailQueryHookResult = ReturnType<typeof useGetExpenseClassDetailQuery>;
export type GetExpenseClassDetailLazyQueryHookResult = ReturnType<typeof useGetExpenseClassDetailLazyQuery>;
export type GetExpenseClassDetailQueryResult = ApolloReactCommon.QueryResult<GetExpenseClassDetailQuery, GetExpenseClassDetailQueryVariables>;
export const GetItemClassCompanyAssignmentDocument = gql`
    query getItemClassCompanyAssignment($ItemClassCompanyAssignmentID: String, $CompanyID: String, $ExpenseClassID: String) {
  getItemClassCompanyAssignment(ItemClassCompanyAssignmentID: $ItemClassCompanyAssignmentID, CompanyID: $CompanyID, ExpenseClassID: $ExpenseClassID) {
    ItemClassCompanyAssignmentID
    ExpenseClassID
    CompanyID
    createdTs
    createdBy
    modTs
    modBy
    RecordStatus
    ExpenseClass {
      createdTs
      createdBy
      modTs
      modBy
      ExpenseClassID
      MasterCOAID
      RecordStatus
      Name
    }
  }
}
    `;

/**
 * __useGetItemClassCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetItemClassCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemClassCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemClassCompanyAssignmentQuery({
 *   variables: {
 *      ItemClassCompanyAssignmentID: // value for 'ItemClassCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *   },
 * });
 */
export function useGetItemClassCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemClassCompanyAssignmentQuery, GetItemClassCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemClassCompanyAssignmentQuery, GetItemClassCompanyAssignmentQueryVariables>(GetItemClassCompanyAssignmentDocument, baseOptions);
      }
export function useGetItemClassCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemClassCompanyAssignmentQuery, GetItemClassCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemClassCompanyAssignmentQuery, GetItemClassCompanyAssignmentQueryVariables>(GetItemClassCompanyAssignmentDocument, baseOptions);
        }
export type GetItemClassCompanyAssignmentQueryHookResult = ReturnType<typeof useGetItemClassCompanyAssignmentQuery>;
export type GetItemClassCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetItemClassCompanyAssignmentLazyQuery>;
export type GetItemClassCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetItemClassCompanyAssignmentQuery, GetItemClassCompanyAssignmentQueryVariables>;
export const CreateExpenseClassDocument = gql`
    mutation createExpenseClass($input: ExpenseClassInput!) {
  createExpenseClass(input: $input)
}
    `;
export type CreateExpenseClassMutationFn = ApolloReactCommon.MutationFunction<CreateExpenseClassMutation, CreateExpenseClassMutationVariables>;

/**
 * __useCreateExpenseClassMutation__
 *
 * To run a mutation, you first call `useCreateExpenseClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseClassMutation, { data, loading, error }] = useCreateExpenseClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpenseClassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExpenseClassMutation, CreateExpenseClassMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExpenseClassMutation, CreateExpenseClassMutationVariables>(CreateExpenseClassDocument, baseOptions);
      }
export type CreateExpenseClassMutationHookResult = ReturnType<typeof useCreateExpenseClassMutation>;
export type CreateExpenseClassMutationResult = ApolloReactCommon.MutationResult<CreateExpenseClassMutation>;
export type CreateExpenseClassMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExpenseClassMutation, CreateExpenseClassMutationVariables>;
export const UpdateExpenseClassDocument = gql`
    mutation updateExpenseClass($input: ExpenseClassInput!) {
  updateExpenseClass(input: $input)
}
    `;
export type UpdateExpenseClassMutationFn = ApolloReactCommon.MutationFunction<UpdateExpenseClassMutation, UpdateExpenseClassMutationVariables>;

/**
 * __useUpdateExpenseClassMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseClassMutation, { data, loading, error }] = useUpdateExpenseClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseClassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExpenseClassMutation, UpdateExpenseClassMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExpenseClassMutation, UpdateExpenseClassMutationVariables>(UpdateExpenseClassDocument, baseOptions);
      }
export type UpdateExpenseClassMutationHookResult = ReturnType<typeof useUpdateExpenseClassMutation>;
export type UpdateExpenseClassMutationResult = ApolloReactCommon.MutationResult<UpdateExpenseClassMutation>;
export type UpdateExpenseClassMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExpenseClassMutation, UpdateExpenseClassMutationVariables>;
export const DeleteExpenseClassDocument = gql`
    mutation deleteExpenseClass($ExpenseClassID: String!) {
  deleteExpenseClass(ExpenseClassID: $ExpenseClassID)
}
    `;
export type DeleteExpenseClassMutationFn = ApolloReactCommon.MutationFunction<DeleteExpenseClassMutation, DeleteExpenseClassMutationVariables>;

/**
 * __useDeleteExpenseClassMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseClassMutation, { data, loading, error }] = useDeleteExpenseClassMutation({
 *   variables: {
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *   },
 * });
 */
export function useDeleteExpenseClassMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExpenseClassMutation, DeleteExpenseClassMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExpenseClassMutation, DeleteExpenseClassMutationVariables>(DeleteExpenseClassDocument, baseOptions);
      }
export type DeleteExpenseClassMutationHookResult = ReturnType<typeof useDeleteExpenseClassMutation>;
export type DeleteExpenseClassMutationResult = ApolloReactCommon.MutationResult<DeleteExpenseClassMutation>;
export type DeleteExpenseClassMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExpenseClassMutation, DeleteExpenseClassMutationVariables>;
export const AssignItemClassCompanyDocument = gql`
    mutation AssignItemClassCompany($input: ItemClassCompanyAssignmentInput!) {
  AssignItemClassCompany(input: $input)
}
    `;
export type AssignItemClassCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignItemClassCompanyMutation, AssignItemClassCompanyMutationVariables>;

/**
 * __useAssignItemClassCompanyMutation__
 *
 * To run a mutation, you first call `useAssignItemClassCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignItemClassCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignItemClassCompanyMutation, { data, loading, error }] = useAssignItemClassCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignItemClassCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignItemClassCompanyMutation, AssignItemClassCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignItemClassCompanyMutation, AssignItemClassCompanyMutationVariables>(AssignItemClassCompanyDocument, baseOptions);
      }
export type AssignItemClassCompanyMutationHookResult = ReturnType<typeof useAssignItemClassCompanyMutation>;
export type AssignItemClassCompanyMutationResult = ApolloReactCommon.MutationResult<AssignItemClassCompanyMutation>;
export type AssignItemClassCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignItemClassCompanyMutation, AssignItemClassCompanyMutationVariables>;
export const UnassignItemClassCompanyDocument = gql`
    mutation UnassignItemClassCompany($input: ItemClassCompanyAssignmentInput!) {
  UnassignItemClassCompany(input: $input)
}
    `;
export type UnassignItemClassCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignItemClassCompanyMutation, UnassignItemClassCompanyMutationVariables>;

/**
 * __useUnassignItemClassCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignItemClassCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignItemClassCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignItemClassCompanyMutation, { data, loading, error }] = useUnassignItemClassCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignItemClassCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignItemClassCompanyMutation, UnassignItemClassCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignItemClassCompanyMutation, UnassignItemClassCompanyMutationVariables>(UnassignItemClassCompanyDocument, baseOptions);
      }
export type UnassignItemClassCompanyMutationHookResult = ReturnType<typeof useUnassignItemClassCompanyMutation>;
export type UnassignItemClassCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignItemClassCompanyMutation>;
export type UnassignItemClassCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignItemClassCompanyMutation, UnassignItemClassCompanyMutationVariables>;
export const GetItemDocument = gql`
    query getItem($ExpenseClassID: String, $ExpenseItemID: String, $UOMID: String, $orderByAsc: String, $orderByDesc: String) {
  getExpenseItem(ExpenseClassID: $ExpenseClassID, ExpenseItemID: $ExpenseItemID, UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ExpenseItemID
    ExpenseClassID
    ExpenseClass {
      MasterCOAID
      MasterCOA {
        Name
        Code
      }
      ParentClassMCOA
      Name
    }
    IsUse
    IsInventory
    ItemName
    Description
    Remark
    TaxSchemeID
    TaxRate
    TaxScheme {
      TaxSchemeID
      Code
      Description
      AccTaxCategory
      TaxEffective {
        Date
        Rate
      }
    }
    ExpenseClass {
      Name
    }
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Name
      Code
    }
    UOMID
    UOM {
      UOMID
      Name
      Code
    }
    RecordStatus
  }
}
    `;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
      }
export function useGetItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = ApolloReactCommon.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetExpenseItemDocument = gql`
    query getExpenseItem($CompanyID: String!) {
  getItemByCompany(CompanyID: $CompanyID) {
    ExpenseItemID
    Description
    ItemName
    ExpenseClassID
    TaxSchemeID
    UOMID
    MasterCOAID
  }
}
    `;

/**
 * __useGetExpenseItemQuery__
 *
 * To run a query within a React component, call `useGetExpenseItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseItemQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetExpenseItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpenseItemQuery, GetExpenseItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpenseItemQuery, GetExpenseItemQueryVariables>(GetExpenseItemDocument, baseOptions);
      }
export function useGetExpenseItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpenseItemQuery, GetExpenseItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpenseItemQuery, GetExpenseItemQueryVariables>(GetExpenseItemDocument, baseOptions);
        }
export type GetExpenseItemQueryHookResult = ReturnType<typeof useGetExpenseItemQuery>;
export type GetExpenseItemLazyQueryHookResult = ReturnType<typeof useGetExpenseItemLazyQuery>;
export type GetExpenseItemQueryResult = ApolloReactCommon.QueryResult<GetExpenseItemQuery, GetExpenseItemQueryVariables>;
export const FetchExpenseItemDocument = gql`
    query fetchExpenseItem($ExpenseClassID: String, $ExpenseItemID: String, $UOMID: String, $orderByAsc: String) {
  getExpenseItem(ExpenseClassID: $ExpenseClassID, UOMID: $UOMID, ExpenseItemID: $ExpenseItemID, orderByAsc: $orderByAsc) {
    ExpenseItemID
    Description
    ItemName
    ExpenseClassID
    TaxSchemeID
    TaxScheme {
      TaxSchemeID
      Code
      Description
      AccTaxCategory
      TaxEffective {
        Date
        Rate
      }
    }
    UOMID
    UOM {
      UOMID
      Name
      Code
    }
  }
}
    `;

/**
 * __useFetchExpenseItemQuery__
 *
 * To run a query within a React component, call `useFetchExpenseItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExpenseItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExpenseItemQuery({
 *   variables: {
 *      ExpenseClassID: // value for 'ExpenseClassID'
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useFetchExpenseItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchExpenseItemQuery, FetchExpenseItemQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchExpenseItemQuery, FetchExpenseItemQueryVariables>(FetchExpenseItemDocument, baseOptions);
      }
export function useFetchExpenseItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchExpenseItemQuery, FetchExpenseItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchExpenseItemQuery, FetchExpenseItemQueryVariables>(FetchExpenseItemDocument, baseOptions);
        }
export type FetchExpenseItemQueryHookResult = ReturnType<typeof useFetchExpenseItemQuery>;
export type FetchExpenseItemLazyQueryHookResult = ReturnType<typeof useFetchExpenseItemLazyQuery>;
export type FetchExpenseItemQueryResult = ApolloReactCommon.QueryResult<FetchExpenseItemQuery, FetchExpenseItemQueryVariables>;
export const CreateExpenseItemDocument = gql`
    mutation createExpenseItem($input: ExpenseItemInput!) {
  createExpenseItem(input: $input) {
    ExpenseItemID
  }
}
    `;
export type CreateExpenseItemMutationFn = ApolloReactCommon.MutationFunction<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>;

/**
 * __useCreateExpenseItemMutation__
 *
 * To run a mutation, you first call `useCreateExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseItemMutation, { data, loading, error }] = useCreateExpenseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>(CreateExpenseItemDocument, baseOptions);
      }
export type CreateExpenseItemMutationHookResult = ReturnType<typeof useCreateExpenseItemMutation>;
export type CreateExpenseItemMutationResult = ApolloReactCommon.MutationResult<CreateExpenseItemMutation>;
export type CreateExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>;
export const UpdateExpenseItemDocument = gql`
    mutation updateExpenseItem($input: ExpenseItemInput!) {
  updateExpenseItem(input: $input)
}
    `;
export type UpdateExpenseItemMutationFn = ApolloReactCommon.MutationFunction<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>;

/**
 * __useUpdateExpenseItemMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseItemMutation, { data, loading, error }] = useUpdateExpenseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>(UpdateExpenseItemDocument, baseOptions);
      }
export type UpdateExpenseItemMutationHookResult = ReturnType<typeof useUpdateExpenseItemMutation>;
export type UpdateExpenseItemMutationResult = ApolloReactCommon.MutationResult<UpdateExpenseItemMutation>;
export type UpdateExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>;
export const CancelExpenseItemDocument = gql`
    mutation cancelExpenseItem($input: ExpenseItemDeleteInput!) {
  cancelExpenseItem(input: $input)
}
    `;
export type CancelExpenseItemMutationFn = ApolloReactCommon.MutationFunction<CancelExpenseItemMutation, CancelExpenseItemMutationVariables>;

/**
 * __useCancelExpenseItemMutation__
 *
 * To run a mutation, you first call `useCancelExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelExpenseItemMutation, { data, loading, error }] = useCancelExpenseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelExpenseItemMutation, CancelExpenseItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelExpenseItemMutation, CancelExpenseItemMutationVariables>(CancelExpenseItemDocument, baseOptions);
      }
export type CancelExpenseItemMutationHookResult = ReturnType<typeof useCancelExpenseItemMutation>;
export type CancelExpenseItemMutationResult = ApolloReactCommon.MutationResult<CancelExpenseItemMutation>;
export type CancelExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelExpenseItemMutation, CancelExpenseItemMutationVariables>;
export const DeleteExpenseItemDocument = gql`
    mutation deleteExpenseItem($ExpenseItemID: String!) {
  deleteExpenseItem(ExpenseItemID: $ExpenseItemID)
}
    `;
export type DeleteExpenseItemMutationFn = ApolloReactCommon.MutationFunction<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>;

/**
 * __useDeleteExpenseItemMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseItemMutation, { data, loading, error }] = useDeleteExpenseItemMutation({
 *   variables: {
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *   },
 * });
 */
export function useDeleteExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>(DeleteExpenseItemDocument, baseOptions);
      }
export type DeleteExpenseItemMutationHookResult = ReturnType<typeof useDeleteExpenseItemMutation>;
export type DeleteExpenseItemMutationResult = ApolloReactCommon.MutationResult<DeleteExpenseItemMutation>;
export type DeleteExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExpenseItemMutation, DeleteExpenseItemMutationVariables>;
export const GetDataSourceSettingsDocument = gql`
    query getDataSourceSettings($orderByDesc: String) {
  getDataSourceSettings(orderByDesc: $orderByDesc) {
    createdTs
    modTs
    DataSourceSettingsID
    Name
    Code
    Description
    ColumnSource
    ColumnValue
    YearType
    YearValue
    PeriodType
    PeriodValue
    Duration
  }
}
    `;

/**
 * __useGetDataSourceSettingsQuery__
 *
 * To run a query within a React component, call `useGetDataSourceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataSourceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataSourceSettingsQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetDataSourceSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataSourceSettingsQuery, GetDataSourceSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDataSourceSettingsQuery, GetDataSourceSettingsQueryVariables>(GetDataSourceSettingsDocument, baseOptions);
      }
export function useGetDataSourceSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataSourceSettingsQuery, GetDataSourceSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDataSourceSettingsQuery, GetDataSourceSettingsQueryVariables>(GetDataSourceSettingsDocument, baseOptions);
        }
export type GetDataSourceSettingsQueryHookResult = ReturnType<typeof useGetDataSourceSettingsQuery>;
export type GetDataSourceSettingsLazyQueryHookResult = ReturnType<typeof useGetDataSourceSettingsLazyQuery>;
export type GetDataSourceSettingsQueryResult = ApolloReactCommon.QueryResult<GetDataSourceSettingsQuery, GetDataSourceSettingsQueryVariables>;
export const CreateDataSourceSettingsDocument = gql`
    mutation createDataSourceSettings($input: DataSourceSettingsInput!) {
  createDataSourceSettings(input: $input) {
    DataSourceSettingsID
  }
}
    `;
export type CreateDataSourceSettingsMutationFn = ApolloReactCommon.MutationFunction<CreateDataSourceSettingsMutation, CreateDataSourceSettingsMutationVariables>;

/**
 * __useCreateDataSourceSettingsMutation__
 *
 * To run a mutation, you first call `useCreateDataSourceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataSourceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataSourceSettingsMutation, { data, loading, error }] = useCreateDataSourceSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataSourceSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDataSourceSettingsMutation, CreateDataSourceSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDataSourceSettingsMutation, CreateDataSourceSettingsMutationVariables>(CreateDataSourceSettingsDocument, baseOptions);
      }
export type CreateDataSourceSettingsMutationHookResult = ReturnType<typeof useCreateDataSourceSettingsMutation>;
export type CreateDataSourceSettingsMutationResult = ApolloReactCommon.MutationResult<CreateDataSourceSettingsMutation>;
export type CreateDataSourceSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDataSourceSettingsMutation, CreateDataSourceSettingsMutationVariables>;
export const UpdateDataSourceSettingsDocument = gql`
    mutation updateDataSourceSettings($input: DataSourceSettingsInput!) {
  updateDataSourceSettings(input: $input) {
    DataSourceSettingsID
  }
}
    `;
export type UpdateDataSourceSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateDataSourceSettingsMutation, UpdateDataSourceSettingsMutationVariables>;

/**
 * __useUpdateDataSourceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDataSourceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataSourceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataSourceSettingsMutation, { data, loading, error }] = useUpdateDataSourceSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataSourceSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDataSourceSettingsMutation, UpdateDataSourceSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDataSourceSettingsMutation, UpdateDataSourceSettingsMutationVariables>(UpdateDataSourceSettingsDocument, baseOptions);
      }
export type UpdateDataSourceSettingsMutationHookResult = ReturnType<typeof useUpdateDataSourceSettingsMutation>;
export type UpdateDataSourceSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateDataSourceSettingsMutation>;
export type UpdateDataSourceSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDataSourceSettingsMutation, UpdateDataSourceSettingsMutationVariables>;
export const CancelDataSourceSettingsDocument = gql`
    mutation cancelDataSourceSettings($input: DataSourceSettingsDeleteInput!) {
  cancelDataSourceSettings(input: $input)
}
    `;
export type CancelDataSourceSettingsMutationFn = ApolloReactCommon.MutationFunction<CancelDataSourceSettingsMutation, CancelDataSourceSettingsMutationVariables>;

/**
 * __useCancelDataSourceSettingsMutation__
 *
 * To run a mutation, you first call `useCancelDataSourceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDataSourceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDataSourceSettingsMutation, { data, loading, error }] = useCancelDataSourceSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDataSourceSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelDataSourceSettingsMutation, CancelDataSourceSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelDataSourceSettingsMutation, CancelDataSourceSettingsMutationVariables>(CancelDataSourceSettingsDocument, baseOptions);
      }
export type CancelDataSourceSettingsMutationHookResult = ReturnType<typeof useCancelDataSourceSettingsMutation>;
export type CancelDataSourceSettingsMutationResult = ApolloReactCommon.MutationResult<CancelDataSourceSettingsMutation>;
export type CancelDataSourceSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelDataSourceSettingsMutation, CancelDataSourceSettingsMutationVariables>;
export const DeleteDataSourceSettingsDocument = gql`
    mutation deleteDataSourceSettings($input: DataSourceSettingsDeleteInput!) {
  deleteDataSourceSettings(input: $input)
}
    `;
export type DeleteDataSourceSettingsMutationFn = ApolloReactCommon.MutationFunction<DeleteDataSourceSettingsMutation, DeleteDataSourceSettingsMutationVariables>;

/**
 * __useDeleteDataSourceSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteDataSourceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSourceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSourceSettingsMutation, { data, loading, error }] = useDeleteDataSourceSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataSourceSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDataSourceSettingsMutation, DeleteDataSourceSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDataSourceSettingsMutation, DeleteDataSourceSettingsMutationVariables>(DeleteDataSourceSettingsDocument, baseOptions);
      }
export type DeleteDataSourceSettingsMutationHookResult = ReturnType<typeof useDeleteDataSourceSettingsMutation>;
export type DeleteDataSourceSettingsMutationResult = ApolloReactCommon.MutationResult<DeleteDataSourceSettingsMutation>;
export type DeleteDataSourceSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDataSourceSettingsMutation, DeleteDataSourceSettingsMutationVariables>;
export const GetReportSettingsDocument = gql`
    query getReportSettings($orderByDesc: String, $orderByAsc: String) {
  getReportSettings(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    createdTs
    ReportSettingsID
    AccountID
    Name
    Description
    FileName
    FileURL
    CellValues
    BucketFileName
    Opacity
    IsPrivate
  }
}
    `;

/**
 * __useGetReportSettingsQuery__
 *
 * To run a query within a React component, call `useGetReportSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportSettingsQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetReportSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReportSettingsQuery, GetReportSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReportSettingsQuery, GetReportSettingsQueryVariables>(GetReportSettingsDocument, baseOptions);
      }
export function useGetReportSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReportSettingsQuery, GetReportSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReportSettingsQuery, GetReportSettingsQueryVariables>(GetReportSettingsDocument, baseOptions);
        }
export type GetReportSettingsQueryHookResult = ReturnType<typeof useGetReportSettingsQuery>;
export type GetReportSettingsLazyQueryHookResult = ReturnType<typeof useGetReportSettingsLazyQuery>;
export type GetReportSettingsQueryResult = ApolloReactCommon.QueryResult<GetReportSettingsQuery, GetReportSettingsQueryVariables>;
export const CreateReportSettingsDocument = gql`
    mutation createReportSettings($input: ReportSettingsInput!) {
  createReportSettings(input: $input) {
    ReportSettingsID
  }
}
    `;
export type CreateReportSettingsMutationFn = ApolloReactCommon.MutationFunction<CreateReportSettingsMutation, CreateReportSettingsMutationVariables>;

/**
 * __useCreateReportSettingsMutation__
 *
 * To run a mutation, you first call `useCreateReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportSettingsMutation, { data, loading, error }] = useCreateReportSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReportSettingsMutation, CreateReportSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReportSettingsMutation, CreateReportSettingsMutationVariables>(CreateReportSettingsDocument, baseOptions);
      }
export type CreateReportSettingsMutationHookResult = ReturnType<typeof useCreateReportSettingsMutation>;
export type CreateReportSettingsMutationResult = ApolloReactCommon.MutationResult<CreateReportSettingsMutation>;
export type CreateReportSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReportSettingsMutation, CreateReportSettingsMutationVariables>;
export const UpdateReportSettingsDocument = gql`
    mutation updateReportSettings($input: ReportSettingsInput!) {
  updateReportSettings(input: $input) {
    ReportSettingsID
  }
}
    `;
export type UpdateReportSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateReportSettingsMutation, UpdateReportSettingsMutationVariables>;

/**
 * __useUpdateReportSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportSettingsMutation, { data, loading, error }] = useUpdateReportSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReportSettingsMutation, UpdateReportSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReportSettingsMutation, UpdateReportSettingsMutationVariables>(UpdateReportSettingsDocument, baseOptions);
      }
export type UpdateReportSettingsMutationHookResult = ReturnType<typeof useUpdateReportSettingsMutation>;
export type UpdateReportSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateReportSettingsMutation>;
export type UpdateReportSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReportSettingsMutation, UpdateReportSettingsMutationVariables>;
export const CancelReportSettingsDocument = gql`
    mutation cancelReportSettings($input: ReportSettingsDeleteInput!) {
  cancelReportSettings(input: $input)
}
    `;
export type CancelReportSettingsMutationFn = ApolloReactCommon.MutationFunction<CancelReportSettingsMutation, CancelReportSettingsMutationVariables>;

/**
 * __useCancelReportSettingsMutation__
 *
 * To run a mutation, you first call `useCancelReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReportSettingsMutation, { data, loading, error }] = useCancelReportSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReportSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelReportSettingsMutation, CancelReportSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelReportSettingsMutation, CancelReportSettingsMutationVariables>(CancelReportSettingsDocument, baseOptions);
      }
export type CancelReportSettingsMutationHookResult = ReturnType<typeof useCancelReportSettingsMutation>;
export type CancelReportSettingsMutationResult = ApolloReactCommon.MutationResult<CancelReportSettingsMutation>;
export type CancelReportSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelReportSettingsMutation, CancelReportSettingsMutationVariables>;
export const DeleteReportSettingsDocument = gql`
    mutation deleteReportSettings($input: ReportSettingsDeleteInput!) {
  deleteReportSettings(input: $input)
}
    `;
export type DeleteReportSettingsMutationFn = ApolloReactCommon.MutationFunction<DeleteReportSettingsMutation, DeleteReportSettingsMutationVariables>;

/**
 * __useDeleteReportSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportSettingsMutation, { data, loading, error }] = useDeleteReportSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReportSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteReportSettingsMutation, DeleteReportSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteReportSettingsMutation, DeleteReportSettingsMutationVariables>(DeleteReportSettingsDocument, baseOptions);
      }
export type DeleteReportSettingsMutationHookResult = ReturnType<typeof useDeleteReportSettingsMutation>;
export type DeleteReportSettingsMutationResult = ApolloReactCommon.MutationResult<DeleteReportSettingsMutation>;
export type DeleteReportSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteReportSettingsMutation, DeleteReportSettingsMutationVariables>;
export const DesignReportDocument = gql`
    query designReport($ReportSettingsID: String!) {
  DesignReport(ReportSettingsID: $ReportSettingsID) {
    file_name
    file
  }
}
    `;

/**
 * __useDesignReportQuery__
 *
 * To run a query within a React component, call `useDesignReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDesignReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDesignReportQuery({
 *   variables: {
 *      ReportSettingsID: // value for 'ReportSettingsID'
 *   },
 * });
 */
export function useDesignReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DesignReportQuery, DesignReportQueryVariables>) {
        return ApolloReactHooks.useQuery<DesignReportQuery, DesignReportQueryVariables>(DesignReportDocument, baseOptions);
      }
export function useDesignReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DesignReportQuery, DesignReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DesignReportQuery, DesignReportQueryVariables>(DesignReportDocument, baseOptions);
        }
export type DesignReportQueryHookResult = ReturnType<typeof useDesignReportQuery>;
export type DesignReportLazyQueryHookResult = ReturnType<typeof useDesignReportLazyQuery>;
export type DesignReportQueryResult = ApolloReactCommon.QueryResult<DesignReportQuery, DesignReportQueryVariables>;
export const UploadReportTemplateDocument = gql`
    mutation uploadReportTemplate($ReportSettingsID: String!, $file: Upload!) {
  uploadReportTemplate(ReportSettingsID: $ReportSettingsID, file: $file)
}
    `;
export type UploadReportTemplateMutationFn = ApolloReactCommon.MutationFunction<UploadReportTemplateMutation, UploadReportTemplateMutationVariables>;

/**
 * __useUploadReportTemplateMutation__
 *
 * To run a mutation, you first call `useUploadReportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadReportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadReportTemplateMutation, { data, loading, error }] = useUploadReportTemplateMutation({
 *   variables: {
 *      ReportSettingsID: // value for 'ReportSettingsID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadReportTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadReportTemplateMutation, UploadReportTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadReportTemplateMutation, UploadReportTemplateMutationVariables>(UploadReportTemplateDocument, baseOptions);
      }
export type UploadReportTemplateMutationHookResult = ReturnType<typeof useUploadReportTemplateMutation>;
export type UploadReportTemplateMutationResult = ApolloReactCommon.MutationResult<UploadReportTemplateMutation>;
export type UploadReportTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadReportTemplateMutation, UploadReportTemplateMutationVariables>;
export const PrintReportDocument = gql`
    query printReport($ReportSettingsID: String!, $CompanyID: String!, $FYear: Float!, $FPeriod: Float!, $FilterZero: Boolean!) {
  printReport(ReportSettingsID: $ReportSettingsID, CompanyID: $CompanyID, FYear: $FYear, FPeriod: $FPeriod, FilterZero: $FilterZero) {
    file_name
    file
  }
}
    `;

/**
 * __usePrintReportQuery__
 *
 * To run a query within a React component, call `usePrintReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintReportQuery({
 *   variables: {
 *      ReportSettingsID: // value for 'ReportSettingsID'
 *      CompanyID: // value for 'CompanyID'
 *      FYear: // value for 'FYear'
 *      FPeriod: // value for 'FPeriod'
 *      FilterZero: // value for 'FilterZero'
 *   },
 * });
 */
export function usePrintReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrintReportQuery, PrintReportQueryVariables>) {
        return ApolloReactHooks.useQuery<PrintReportQuery, PrintReportQueryVariables>(PrintReportDocument, baseOptions);
      }
export function usePrintReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrintReportQuery, PrintReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrintReportQuery, PrintReportQueryVariables>(PrintReportDocument, baseOptions);
        }
export type PrintReportQueryHookResult = ReturnType<typeof usePrintReportQuery>;
export type PrintReportLazyQueryHookResult = ReturnType<typeof usePrintReportLazyQuery>;
export type PrintReportQueryResult = ApolloReactCommon.QueryResult<PrintReportQuery, PrintReportQueryVariables>;
export const GetGeneralSettingSummaryDocument = gql`
    query getGeneralSettingSummary {
  activeSupplierCategoryCount
  activeSupplierProfileCount
  activeUomCount
  lastUomExchange
  activePurchaseCategoryCount
  activeItemCount
  activeBulkPurchaseCount
  lastPurchaseVariance
  purchaseVariancePercenetage
}
    `;

/**
 * __useGetGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
        }
export type GetGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryQuery>;
export type GetGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryLazyQuery>;
export type GetGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>;
export const GetInventoryPolicyDocument = gql`
    query getInventoryPolicy($InventoryPolicyID: String, $orderByAsc: String, $orderByDesc: String, $AccountID: String) {
  getInventoryPolicy(InventoryPolicyID: $InventoryPolicyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, AccountID: $AccountID) {
    InventoryPolicyID
    AccountID
    StockCostingType
    PaymentReminder1
    PaymentReminder2
    PaymentReminder3
    PaymentReminder4
    PaymentReminder5
    PaymentReminder6
  }
}
    `;

/**
 * __useGetInventoryPolicyQuery__
 *
 * To run a query within a React component, call `useGetInventoryPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryPolicyQuery({
 *   variables: {
 *      InventoryPolicyID: // value for 'InventoryPolicyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetInventoryPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInventoryPolicyQuery, GetInventoryPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInventoryPolicyQuery, GetInventoryPolicyQueryVariables>(GetInventoryPolicyDocument, baseOptions);
      }
export function useGetInventoryPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInventoryPolicyQuery, GetInventoryPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInventoryPolicyQuery, GetInventoryPolicyQueryVariables>(GetInventoryPolicyDocument, baseOptions);
        }
export type GetInventoryPolicyQueryHookResult = ReturnType<typeof useGetInventoryPolicyQuery>;
export type GetInventoryPolicyLazyQueryHookResult = ReturnType<typeof useGetInventoryPolicyLazyQuery>;
export type GetInventoryPolicyQueryResult = ApolloReactCommon.QueryResult<GetInventoryPolicyQuery, GetInventoryPolicyQueryVariables>;
export const CreateInventoryPolicyDocument = gql`
    mutation createInventoryPolicy($input: InventoryPolicyInput!) {
  createInventoryPolicy(input: $input) {
    InventoryPolicyID
  }
}
    `;
export type CreateInventoryPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateInventoryPolicyMutation, CreateInventoryPolicyMutationVariables>;

/**
 * __useCreateInventoryPolicyMutation__
 *
 * To run a mutation, you first call `useCreateInventoryPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryPolicyMutation, { data, loading, error }] = useCreateInventoryPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInventoryPolicyMutation, CreateInventoryPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInventoryPolicyMutation, CreateInventoryPolicyMutationVariables>(CreateInventoryPolicyDocument, baseOptions);
      }
export type CreateInventoryPolicyMutationHookResult = ReturnType<typeof useCreateInventoryPolicyMutation>;
export type CreateInventoryPolicyMutationResult = ApolloReactCommon.MutationResult<CreateInventoryPolicyMutation>;
export type CreateInventoryPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInventoryPolicyMutation, CreateInventoryPolicyMutationVariables>;
export const UpdateInventoryPolicyDocument = gql`
    mutation updateInventoryPolicy($input: InventoryPolicyInput!) {
  updateInventoryPolicy(input: $input) {
    InventoryPolicyID
  }
}
    `;
export type UpdateInventoryPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateInventoryPolicyMutation, UpdateInventoryPolicyMutationVariables>;

/**
 * __useUpdateInventoryPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryPolicyMutation, { data, loading, error }] = useUpdateInventoryPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInventoryPolicyMutation, UpdateInventoryPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInventoryPolicyMutation, UpdateInventoryPolicyMutationVariables>(UpdateInventoryPolicyDocument, baseOptions);
      }
export type UpdateInventoryPolicyMutationHookResult = ReturnType<typeof useUpdateInventoryPolicyMutation>;
export type UpdateInventoryPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateInventoryPolicyMutation>;
export type UpdateInventoryPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInventoryPolicyMutation, UpdateInventoryPolicyMutationVariables>;
export const GetParentMasterCoaDocument = gql`
    query getParentMasterCOA {
  getParentMasterCOA {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    AccountType
  }
}
    `;

/**
 * __useGetParentMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetParentMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentMasterCoaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentMasterCoaQuery, GetParentMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentMasterCoaQuery, GetParentMasterCoaQueryVariables>(GetParentMasterCoaDocument, baseOptions);
      }
export function useGetParentMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentMasterCoaQuery, GetParentMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentMasterCoaQuery, GetParentMasterCoaQueryVariables>(GetParentMasterCoaDocument, baseOptions);
        }
export type GetParentMasterCoaQueryHookResult = ReturnType<typeof useGetParentMasterCoaQuery>;
export type GetParentMasterCoaLazyQueryHookResult = ReturnType<typeof useGetParentMasterCoaLazyQuery>;
export type GetParentMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetParentMasterCoaQuery, GetParentMasterCoaQueryVariables>;
export const GetMasterCoaListingDocument = gql`
    query getMasterCOAListing($AccountType: String!) {
  getMasterCOAListing(AccountType: $AccountType) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    IsLastNode
    Level
    AccountType
    EntityCOA {
      EntityCOAID
      CompanyID
    }
    AuditAdjustmentItem {
      AuditAdjustmentItemID
    }
    FavoriteJournalProcessingItem {
      FavoriteJournalProcessingItemID
    }
    JournalItem {
      JournalItemID
    }
    RecurringJournalItem {
      RecurringJournalItemID
    }
    BudgetItem {
      BudgetItemID
    }
    RoutineJournalItem {
      RoutineJournalItemID
    }
  }
}
    `;

/**
 * __useGetMasterCoaListingQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaListingQuery({
 *   variables: {
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetMasterCoaListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaListingQuery, GetMasterCoaListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaListingQuery, GetMasterCoaListingQueryVariables>(GetMasterCoaListingDocument, baseOptions);
      }
export function useGetMasterCoaListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaListingQuery, GetMasterCoaListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaListingQuery, GetMasterCoaListingQueryVariables>(GetMasterCoaListingDocument, baseOptions);
        }
export type GetMasterCoaListingQueryHookResult = ReturnType<typeof useGetMasterCoaListingQuery>;
export type GetMasterCoaListingLazyQueryHookResult = ReturnType<typeof useGetMasterCoaListingLazyQuery>;
export type GetMasterCoaListingQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaListingQuery, GetMasterCoaListingQueryVariables>;
export const GetMasterCoaDocument = gql`
    query getMasterCOA($AccountType: String, $IsLastNode: Boolean, $orderByAsc: String, $IsControl: Boolean, $AccountID: String, $MasterCOAID: String) {
  getMasterCOA(AccountType: $AccountType, IsLastNode: $IsLastNode, orderByAsc: $orderByAsc, IsControl: $IsControl, AccountID: $AccountID, MasterCOAID: $MasterCOAID) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    AccountType
    IsLastNode
    Level
    AccountType
    RecordStatus
    EntityCOA {
      EntityCOAID
      CompanyID
    }
    AuditAdjustmentItem {
      AuditAdjustmentItemID
    }
    FavoriteJournalProcessingItem {
      FavoriteJournalProcessingItemID
    }
    JournalItem {
      JournalItemID
    }
    RecurringJournalItem {
      RecurringJournalItemID
    }
    BudgetItem {
      BudgetItemID
    }
    RoutineJournalItem {
      RoutineJournalItemID
    }
  }
}
    `;

/**
 * __useGetMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaQuery({
 *   variables: {
 *      AccountType: // value for 'AccountType'
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsControl: // value for 'IsControl'
 *      AccountID: // value for 'AccountID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *   },
 * });
 */
export function useGetMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaQuery, GetMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaQuery, GetMasterCoaQueryVariables>(GetMasterCoaDocument, baseOptions);
      }
export function useGetMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaQuery, GetMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaQuery, GetMasterCoaQueryVariables>(GetMasterCoaDocument, baseOptions);
        }
export type GetMasterCoaQueryHookResult = ReturnType<typeof useGetMasterCoaQuery>;
export type GetMasterCoaLazyQueryHookResult = ReturnType<typeof useGetMasterCoaLazyQuery>;
export type GetMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaQuery, GetMasterCoaQueryVariables>;
export const GetMasterCoaCheckingDocument = gql`
    query getMasterCOAChecking($IsLastNode: Boolean, $orderByAsc: String) {
  getMasterCOA(IsLastNode: $IsLastNode, orderByAsc: $orderByAsc) {
    MasterCOAID
    AccountType
  }
}
    `;

/**
 * __useGetMasterCoaCheckingQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaCheckingQuery({
 *   variables: {
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetMasterCoaCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>(GetMasterCoaCheckingDocument, baseOptions);
      }
export function useGetMasterCoaCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>(GetMasterCoaCheckingDocument, baseOptions);
        }
export type GetMasterCoaCheckingQueryHookResult = ReturnType<typeof useGetMasterCoaCheckingQuery>;
export type GetMasterCoaCheckingLazyQueryHookResult = ReturnType<typeof useGetMasterCoaCheckingLazyQuery>;
export type GetMasterCoaCheckingQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaCheckingQuery, GetMasterCoaCheckingQueryVariables>;
export const GetMasterCoaCodeDocument = gql`
    query getMasterCOACode($IsLastNode: Boolean, $orderByAsc: String) {
  getMasterCOA(IsLastNode: $IsLastNode, orderByAsc: $orderByAsc) {
    MasterCOAID
    Name
    Code
    AccountType
    IsLastNode
    IsControl
    RecordStatus
  }
}
    `;

/**
 * __useGetMasterCoaCodeQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaCodeQuery({
 *   variables: {
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetMasterCoaCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>(GetMasterCoaCodeDocument, baseOptions);
      }
export function useGetMasterCoaCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>(GetMasterCoaCodeDocument, baseOptions);
        }
export type GetMasterCoaCodeQueryHookResult = ReturnType<typeof useGetMasterCoaCodeQuery>;
export type GetMasterCoaCodeLazyQueryHookResult = ReturnType<typeof useGetMasterCoaCodeLazyQuery>;
export type GetMasterCoaCodeQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaCodeQuery, GetMasterCoaCodeQueryVariables>;
export const CreateMasterCoaDocument = gql`
    mutation createMasterCOA($input: [MasterCOAInput!]!) {
  createMasterCOA(input: $input)
}
    `;
export type CreateMasterCoaMutationFn = ApolloReactCommon.MutationFunction<CreateMasterCoaMutation, CreateMasterCoaMutationVariables>;

/**
 * __useCreateMasterCoaMutation__
 *
 * To run a mutation, you first call `useCreateMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMasterCoaMutation, { data, loading, error }] = useCreateMasterCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMasterCoaMutation, CreateMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMasterCoaMutation, CreateMasterCoaMutationVariables>(CreateMasterCoaDocument, baseOptions);
      }
export type CreateMasterCoaMutationHookResult = ReturnType<typeof useCreateMasterCoaMutation>;
export type CreateMasterCoaMutationResult = ApolloReactCommon.MutationResult<CreateMasterCoaMutation>;
export type CreateMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMasterCoaMutation, CreateMasterCoaMutationVariables>;
export const UpdateMasterCoaDocument = gql`
    mutation updateMasterCOA($input: MasterCOAInput!) {
  updateMasterCOA(input: $input) {
    MasterCOAID
  }
}
    `;
export type UpdateMasterCoaMutationFn = ApolloReactCommon.MutationFunction<UpdateMasterCoaMutation, UpdateMasterCoaMutationVariables>;

/**
 * __useUpdateMasterCoaMutation__
 *
 * To run a mutation, you first call `useUpdateMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterCoaMutation, { data, loading, error }] = useUpdateMasterCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMasterCoaMutation, UpdateMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMasterCoaMutation, UpdateMasterCoaMutationVariables>(UpdateMasterCoaDocument, baseOptions);
      }
export type UpdateMasterCoaMutationHookResult = ReturnType<typeof useUpdateMasterCoaMutation>;
export type UpdateMasterCoaMutationResult = ApolloReactCommon.MutationResult<UpdateMasterCoaMutation>;
export type UpdateMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMasterCoaMutation, UpdateMasterCoaMutationVariables>;
export const CancelMasterCoaDocument = gql`
    mutation cancelMasterCOA($input: MasterCOADeleteInput!) {
  cancelMasterCOA(input: $input)
}
    `;
export type CancelMasterCoaMutationFn = ApolloReactCommon.MutationFunction<CancelMasterCoaMutation, CancelMasterCoaMutationVariables>;

/**
 * __useCancelMasterCoaMutation__
 *
 * To run a mutation, you first call `useCancelMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMasterCoaMutation, { data, loading, error }] = useCancelMasterCoaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMasterCoaMutation, CancelMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelMasterCoaMutation, CancelMasterCoaMutationVariables>(CancelMasterCoaDocument, baseOptions);
      }
export type CancelMasterCoaMutationHookResult = ReturnType<typeof useCancelMasterCoaMutation>;
export type CancelMasterCoaMutationResult = ApolloReactCommon.MutationResult<CancelMasterCoaMutation>;
export type CancelMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelMasterCoaMutation, CancelMasterCoaMutationVariables>;
export const DeleteMasterCoaDocument = gql`
    mutation deleteMasterCOA($MasterCOAID: String!) {
  deleteMasterCOA(MasterCOAID: $MasterCOAID)
}
    `;
export type DeleteMasterCoaMutationFn = ApolloReactCommon.MutationFunction<DeleteMasterCoaMutation, DeleteMasterCoaMutationVariables>;

/**
 * __useDeleteMasterCoaMutation__
 *
 * To run a mutation, you first call `useDeleteMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterCoaMutation, { data, loading, error }] = useDeleteMasterCoaMutation({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *   },
 * });
 */
export function useDeleteMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMasterCoaMutation, DeleteMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMasterCoaMutation, DeleteMasterCoaMutationVariables>(DeleteMasterCoaDocument, baseOptions);
      }
export type DeleteMasterCoaMutationHookResult = ReturnType<typeof useDeleteMasterCoaMutation>;
export type DeleteMasterCoaMutationResult = ApolloReactCommon.MutationResult<DeleteMasterCoaMutation>;
export type DeleteMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMasterCoaMutation, DeleteMasterCoaMutationVariables>;
export const InactiveMasterCoaDocument = gql`
    mutation inactiveMasterCOA($MasterCOAID: String!) {
  inactiveMasterCOA(MasterCOAID: $MasterCOAID)
}
    `;
export type InactiveMasterCoaMutationFn = ApolloReactCommon.MutationFunction<InactiveMasterCoaMutation, InactiveMasterCoaMutationVariables>;

/**
 * __useInactiveMasterCoaMutation__
 *
 * To run a mutation, you first call `useInactiveMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInactiveMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inactiveMasterCoaMutation, { data, loading, error }] = useInactiveMasterCoaMutation({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *   },
 * });
 */
export function useInactiveMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InactiveMasterCoaMutation, InactiveMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<InactiveMasterCoaMutation, InactiveMasterCoaMutationVariables>(InactiveMasterCoaDocument, baseOptions);
      }
export type InactiveMasterCoaMutationHookResult = ReturnType<typeof useInactiveMasterCoaMutation>;
export type InactiveMasterCoaMutationResult = ApolloReactCommon.MutationResult<InactiveMasterCoaMutation>;
export type InactiveMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<InactiveMasterCoaMutation, InactiveMasterCoaMutationVariables>;
export const ActivateMasterCoaDocument = gql`
    mutation activateMasterCOA($MasterCOAID: String!) {
  activateMasterCOA(MasterCOAID: $MasterCOAID)
}
    `;
export type ActivateMasterCoaMutationFn = ApolloReactCommon.MutationFunction<ActivateMasterCoaMutation, ActivateMasterCoaMutationVariables>;

/**
 * __useActivateMasterCoaMutation__
 *
 * To run a mutation, you first call `useActivateMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMasterCoaMutation, { data, loading, error }] = useActivateMasterCoaMutation({
 *   variables: {
 *      MasterCOAID: // value for 'MasterCOAID'
 *   },
 * });
 */
export function useActivateMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateMasterCoaMutation, ActivateMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateMasterCoaMutation, ActivateMasterCoaMutationVariables>(ActivateMasterCoaDocument, baseOptions);
      }
export type ActivateMasterCoaMutationHookResult = ReturnType<typeof useActivateMasterCoaMutation>;
export type ActivateMasterCoaMutationResult = ApolloReactCommon.MutationResult<ActivateMasterCoaMutation>;
export type ActivateMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateMasterCoaMutation, ActivateMasterCoaMutationVariables>;
export const GetCheckingValidationCoaDocument = gql`
    query getCheckingValidationCOA($MasterCOAInput: [MasterCOAInput!]!) {
  getCheckingValidationCOA(MasterCOAInput: $MasterCOAInput)
}
    `;

/**
 * __useGetCheckingValidationCoaQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationCoaQuery({
 *   variables: {
 *      MasterCOAInput: // value for 'MasterCOAInput'
 *   },
 * });
 */
export function useGetCheckingValidationCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationCoaQuery, GetCheckingValidationCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationCoaQuery, GetCheckingValidationCoaQueryVariables>(GetCheckingValidationCoaDocument, baseOptions);
      }
export function useGetCheckingValidationCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationCoaQuery, GetCheckingValidationCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationCoaQuery, GetCheckingValidationCoaQueryVariables>(GetCheckingValidationCoaDocument, baseOptions);
        }
export type GetCheckingValidationCoaQueryHookResult = ReturnType<typeof useGetCheckingValidationCoaQuery>;
export type GetCheckingValidationCoaLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationCoaLazyQuery>;
export type GetCheckingValidationCoaQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationCoaQuery, GetCheckingValidationCoaQueryVariables>;
export const GetCheckingValidationExtJournalDocument = gql`
    query getCheckingValidationExtJournal($ExternalJournalImportInput: [ExternalJournalImportInput!]!, $CompanyID: String!) {
  getCheckingValidationExtJournal(ExternalJournalImportInput: $ExternalJournalImportInput, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCheckingValidationExtJournalQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationExtJournalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationExtJournalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationExtJournalQuery({
 *   variables: {
 *      ExternalJournalImportInput: // value for 'ExternalJournalImportInput'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCheckingValidationExtJournalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationExtJournalQuery, GetCheckingValidationExtJournalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationExtJournalQuery, GetCheckingValidationExtJournalQueryVariables>(GetCheckingValidationExtJournalDocument, baseOptions);
      }
export function useGetCheckingValidationExtJournalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationExtJournalQuery, GetCheckingValidationExtJournalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationExtJournalQuery, GetCheckingValidationExtJournalQueryVariables>(GetCheckingValidationExtJournalDocument, baseOptions);
        }
export type GetCheckingValidationExtJournalQueryHookResult = ReturnType<typeof useGetCheckingValidationExtJournalQuery>;
export type GetCheckingValidationExtJournalLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationExtJournalLazyQuery>;
export type GetCheckingValidationExtJournalQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationExtJournalQuery, GetCheckingValidationExtJournalQueryVariables>;
export const CreateExcelMasterCoaDocument = gql`
    mutation createExcelMasterCOA($MasterCOAExcelInput: [MasterCOAInput!]!) {
  createExcelMasterCOA(MasterCOAExcelInput: $MasterCOAExcelInput)
}
    `;
export type CreateExcelMasterCoaMutationFn = ApolloReactCommon.MutationFunction<CreateExcelMasterCoaMutation, CreateExcelMasterCoaMutationVariables>;

/**
 * __useCreateExcelMasterCoaMutation__
 *
 * To run a mutation, you first call `useCreateExcelMasterCoaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExcelMasterCoaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExcelMasterCoaMutation, { data, loading, error }] = useCreateExcelMasterCoaMutation({
 *   variables: {
 *      MasterCOAExcelInput: // value for 'MasterCOAExcelInput'
 *   },
 * });
 */
export function useCreateExcelMasterCoaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExcelMasterCoaMutation, CreateExcelMasterCoaMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExcelMasterCoaMutation, CreateExcelMasterCoaMutationVariables>(CreateExcelMasterCoaDocument, baseOptions);
      }
export type CreateExcelMasterCoaMutationHookResult = ReturnType<typeof useCreateExcelMasterCoaMutation>;
export type CreateExcelMasterCoaMutationResult = ApolloReactCommon.MutationResult<CreateExcelMasterCoaMutation>;
export type CreateExcelMasterCoaMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExcelMasterCoaMutation, CreateExcelMasterCoaMutationVariables>;
export const GetFirstMasterCoaDocument = gql`
    query getFirstMasterCOA {
  getFirstMasterCOA
}
    `;

/**
 * __useGetFirstMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetFirstMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstMasterCoaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFirstMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFirstMasterCoaQuery, GetFirstMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFirstMasterCoaQuery, GetFirstMasterCoaQueryVariables>(GetFirstMasterCoaDocument, baseOptions);
      }
export function useGetFirstMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFirstMasterCoaQuery, GetFirstMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFirstMasterCoaQuery, GetFirstMasterCoaQueryVariables>(GetFirstMasterCoaDocument, baseOptions);
        }
export type GetFirstMasterCoaQueryHookResult = ReturnType<typeof useGetFirstMasterCoaQuery>;
export type GetFirstMasterCoaLazyQueryHookResult = ReturnType<typeof useGetFirstMasterCoaLazyQuery>;
export type GetFirstMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetFirstMasterCoaQuery, GetFirstMasterCoaQueryVariables>;
export const GetMasterCoaFilteredDocument = gql`
    query getMasterCOAFiltered($orderByAsc: String, $IsControl: Boolean) {
  getMasterCOAFiltered(orderByAsc: $orderByAsc, IsControl: $IsControl) {
    MasterCOAID
    AccountType
    Code
    Name
    IsLastNode
    RecordStatus
  }
}
    `;

/**
 * __useGetMasterCoaFilteredQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaFilteredQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      IsControl: // value for 'IsControl'
 *   },
 * });
 */
export function useGetMasterCoaFilteredQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaFilteredQuery, GetMasterCoaFilteredQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaFilteredQuery, GetMasterCoaFilteredQueryVariables>(GetMasterCoaFilteredDocument, baseOptions);
      }
export function useGetMasterCoaFilteredLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaFilteredQuery, GetMasterCoaFilteredQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaFilteredQuery, GetMasterCoaFilteredQueryVariables>(GetMasterCoaFilteredDocument, baseOptions);
        }
export type GetMasterCoaFilteredQueryHookResult = ReturnType<typeof useGetMasterCoaFilteredQuery>;
export type GetMasterCoaFilteredLazyQueryHookResult = ReturnType<typeof useGetMasterCoaFilteredLazyQuery>;
export type GetMasterCoaFilteredQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaFilteredQuery, GetMasterCoaFilteredQueryVariables>;
export const GetPaymentMethodNameDocument = gql`
    query getPaymentMethodName($AccountID: String, $orderByDesc: String, $orderByAsc: String, $PaymentMethodID: String) {
  getPaymentMethod(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, PaymentMethodID: $PaymentMethodID) {
    PaymentMethodID
    Name
  }
}
    `;

/**
 * __useGetPaymentMethodNameQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodNameQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      PaymentMethodID: // value for 'PaymentMethodID'
 *   },
 * });
 */
export function useGetPaymentMethodNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodNameQuery, GetPaymentMethodNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodNameQuery, GetPaymentMethodNameQueryVariables>(GetPaymentMethodNameDocument, baseOptions);
      }
export function useGetPaymentMethodNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodNameQuery, GetPaymentMethodNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodNameQuery, GetPaymentMethodNameQueryVariables>(GetPaymentMethodNameDocument, baseOptions);
        }
export type GetPaymentMethodNameQueryHookResult = ReturnType<typeof useGetPaymentMethodNameQuery>;
export type GetPaymentMethodNameLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodNameLazyQuery>;
export type GetPaymentMethodNameQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodNameQuery, GetPaymentMethodNameQueryVariables>;
export const GetPaymentMethodDocument = gql`
    query getPaymentMethod($AccountID: String, $orderByDesc: String, $orderByAsc: String, $PaymentMethodID: String) {
  getPaymentMethod(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, PaymentMethodID: $PaymentMethodID) {
    PaymentMethodID
    Name
    Description
    Sequence
    IsSystem
    Payment {
      PaymentID
    }
    Receipt {
      ReceiptID
    }
    FavoritePayment {
      FavoritePaymentID
    }
    FavoriteReceipt {
      FavoriteReceiptID
    }
  }
}
    `;

/**
 * __useGetPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      PaymentMethodID: // value for 'PaymentMethodID'
 *   },
 * });
 */
export function useGetPaymentMethodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, baseOptions);
      }
export function useGetPaymentMethodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, baseOptions);
        }
export type GetPaymentMethodQueryHookResult = ReturnType<typeof useGetPaymentMethodQuery>;
export type GetPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodLazyQuery>;
export type GetPaymentMethodQueryResult = ApolloReactCommon.QueryResult<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($PaymentMethodInput: [PaymentMethodInput!]!) {
  createPaymentMethod(PaymentMethodInput: $PaymentMethodInput)
}
    `;
export type CreatePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      PaymentMethodInput: // value for 'PaymentMethodInput'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, baseOptions);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = ApolloReactCommon.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation updatePaymentMethod($input: PaymentMethodInput!) {
  updatePaymentMethod(input: $input) {
    PaymentMethodID
  }
}
    `;
export type UpdatePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, baseOptions);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($input: PaymentMethodDeleteInput!) {
  deletePaymentMethod(input: $input)
}
    `;
export type DeletePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, baseOptions);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = ApolloReactCommon.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const GetDocumentDateValidationDocument = gql`
    query getDocumentDateValidation($AccountID: String, $DocumentDateValidationID: String) {
  getDocumentDateValidation(AccountID: $AccountID, DocumentDateValidationID: $DocumentDateValidationID) {
    DocumentDateValidationID
    MonthsBefore
    MonthsAfter
  }
}
    `;

/**
 * __useGetDocumentDateValidationQuery__
 *
 * To run a query within a React component, call `useGetDocumentDateValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentDateValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentDateValidationQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      DocumentDateValidationID: // value for 'DocumentDateValidationID'
 *   },
 * });
 */
export function useGetDocumentDateValidationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentDateValidationQuery, GetDocumentDateValidationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentDateValidationQuery, GetDocumentDateValidationQueryVariables>(GetDocumentDateValidationDocument, baseOptions);
      }
export function useGetDocumentDateValidationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentDateValidationQuery, GetDocumentDateValidationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentDateValidationQuery, GetDocumentDateValidationQueryVariables>(GetDocumentDateValidationDocument, baseOptions);
        }
export type GetDocumentDateValidationQueryHookResult = ReturnType<typeof useGetDocumentDateValidationQuery>;
export type GetDocumentDateValidationLazyQueryHookResult = ReturnType<typeof useGetDocumentDateValidationLazyQuery>;
export type GetDocumentDateValidationQueryResult = ApolloReactCommon.QueryResult<GetDocumentDateValidationQuery, GetDocumentDateValidationQueryVariables>;
export const CreateDocumentDateValidationDocument = gql`
    mutation createDocumentDateValidation($DocumentDateValidationInput: [DocumentDateValidationInput!]!) {
  createDocumentDateValidation(DocumentDateValidationInput: $DocumentDateValidationInput)
}
    `;
export type CreateDocumentDateValidationMutationFn = ApolloReactCommon.MutationFunction<CreateDocumentDateValidationMutation, CreateDocumentDateValidationMutationVariables>;

/**
 * __useCreateDocumentDateValidationMutation__
 *
 * To run a mutation, you first call `useCreateDocumentDateValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentDateValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentDateValidationMutation, { data, loading, error }] = useCreateDocumentDateValidationMutation({
 *   variables: {
 *      DocumentDateValidationInput: // value for 'DocumentDateValidationInput'
 *   },
 * });
 */
export function useCreateDocumentDateValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDocumentDateValidationMutation, CreateDocumentDateValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDocumentDateValidationMutation, CreateDocumentDateValidationMutationVariables>(CreateDocumentDateValidationDocument, baseOptions);
      }
export type CreateDocumentDateValidationMutationHookResult = ReturnType<typeof useCreateDocumentDateValidationMutation>;
export type CreateDocumentDateValidationMutationResult = ApolloReactCommon.MutationResult<CreateDocumentDateValidationMutation>;
export type CreateDocumentDateValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDocumentDateValidationMutation, CreateDocumentDateValidationMutationVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($input: RoleInput!, $permissionArr: [AcctPermission!]!) {
  CreateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($input: RoleInput!, $permissionArr: [AcctPermission!]!) {
  UpdateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    ID
    name
    rolePerm {
      permissionID
      roleID
      permName
    }
    userList {
      name
      ID
      companyAssigned {
        Name
        CompanyID
      }
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetRoleNameDocument = gql`
    query getRoleName($ID: String) {
  getRole(ID: $ID) {
    ID
    name
    userList {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetRoleNameQuery__
 *
 * To run a query within a React component, call `useGetRoleNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleNameQuery, GetRoleNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleNameQuery, GetRoleNameQueryVariables>(GetRoleNameDocument, baseOptions);
      }
export function useGetRoleNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleNameQuery, GetRoleNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleNameQuery, GetRoleNameQueryVariables>(GetRoleNameDocument, baseOptions);
        }
export type GetRoleNameQueryHookResult = ReturnType<typeof useGetRoleNameQuery>;
export type GetRoleNameLazyQueryHookResult = ReturnType<typeof useGetRoleNameLazyQuery>;
export type GetRoleNameQueryResult = ApolloReactCommon.QueryResult<GetRoleNameQuery, GetRoleNameQueryVariables>;
export const GetRoleListingDocument = gql`
    query getRoleListing($ID: String, $searchValue: String, $take: Float, $skip: Float) {
  getRoleListing(ID: $ID, searchValue: $searchValue, take: $take, skip: $skip) {
    Data {
      ID
      name
      rolePerm {
        permissionID
        roleID
        permName
      }
      userList {
        name
        ID
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetRoleListingQuery__
 *
 * To run a query within a React component, call `useGetRoleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetRoleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleListingQuery, GetRoleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleListingQuery, GetRoleListingQueryVariables>(GetRoleListingDocument, baseOptions);
      }
export function useGetRoleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleListingQuery, GetRoleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleListingQuery, GetRoleListingQueryVariables>(GetRoleListingDocument, baseOptions);
        }
export type GetRoleListingQueryHookResult = ReturnType<typeof useGetRoleListingQuery>;
export type GetRoleListingLazyQueryHookResult = ReturnType<typeof useGetRoleListingLazyQuery>;
export type GetRoleListingQueryResult = ApolloReactCommon.QueryResult<GetRoleListingQuery, GetRoleListingQueryVariables>;
export const GetRolePermissionDocument = gql`
    query getRolePermission {
  getRolePermission
}
    `;

/**
 * __useGetRolePermissionQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolePermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
      }
export function useGetRolePermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
        }
export type GetRolePermissionQueryHookResult = ReturnType<typeof useGetRolePermissionQuery>;
export type GetRolePermissionLazyQueryHookResult = ReturnType<typeof useGetRolePermissionLazyQuery>;
export type GetRolePermissionQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionQuery, GetRolePermissionQueryVariables>;
export const CreateListEntityRoleUserDocument = gql`
    mutation createListEntityRoleUser($companyID: String!, $roleUserIDs: [AccRoleUserInput!]!) {
  CreateListEntityRoleUser(companyID: $companyID, roleUserIDs: $roleUserIDs)
}
    `;
export type CreateListEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;

/**
 * __useCreateListEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateListEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListEntityRoleUserMutation, { data, loading, error }] = useCreateListEntityRoleUserMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      roleUserIDs: // value for 'roleUserIDs'
 *   },
 * });
 */
export function useCreateListEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>(CreateListEntityRoleUserDocument, baseOptions);
      }
export type CreateListEntityRoleUserMutationHookResult = ReturnType<typeof useCreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!) {
  DeleteRolePermission(roleID: $roleID)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const DuplicateRolePermissionDocument = gql`
    mutation DuplicateRolePermission($roleID: String!, $name: String!) {
  DuplicateRolePermission(roleID: $roleID, name: $name)
}
    `;
export type DuplicateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>;

/**
 * __useDuplicateRolePermissionMutation__
 *
 * To run a mutation, you first call `useDuplicateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRolePermissionMutation, { data, loading, error }] = useDuplicateRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDuplicateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>(DuplicateRolePermissionDocument, baseOptions);
      }
export type DuplicateRolePermissionMutationHookResult = ReturnType<typeof useDuplicateRolePermissionMutation>;
export type DuplicateRolePermissionMutationResult = ApolloReactCommon.MutationResult<DuplicateRolePermissionMutation>;
export type DuplicateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateRolePermissionMutation, DuplicateRolePermissionMutationVariables>;
export const GetStockAdjustmentReasonDocument = gql`
    query getStockAdjustmentReason($StockAdjustmentReasonID: String, $orderByAsc: String, $orderByDesc: String) {
  getStockAdjustmentReason(StockAdjustmentReasonID: $StockAdjustmentReasonID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    StockAdjustmentReasonID
    Reason
    modTs
    createdTs
    IsSystem
    IsDefault
  }
}
    `;

/**
 * __useGetStockAdjustmentReasonQuery__
 *
 * To run a query within a React component, call `useGetStockAdjustmentReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAdjustmentReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAdjustmentReasonQuery({
 *   variables: {
 *      StockAdjustmentReasonID: // value for 'StockAdjustmentReasonID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetStockAdjustmentReasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockAdjustmentReasonQuery, GetStockAdjustmentReasonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockAdjustmentReasonQuery, GetStockAdjustmentReasonQueryVariables>(GetStockAdjustmentReasonDocument, baseOptions);
      }
export function useGetStockAdjustmentReasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockAdjustmentReasonQuery, GetStockAdjustmentReasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockAdjustmentReasonQuery, GetStockAdjustmentReasonQueryVariables>(GetStockAdjustmentReasonDocument, baseOptions);
        }
export type GetStockAdjustmentReasonQueryHookResult = ReturnType<typeof useGetStockAdjustmentReasonQuery>;
export type GetStockAdjustmentReasonLazyQueryHookResult = ReturnType<typeof useGetStockAdjustmentReasonLazyQuery>;
export type GetStockAdjustmentReasonQueryResult = ApolloReactCommon.QueryResult<GetStockAdjustmentReasonQuery, GetStockAdjustmentReasonQueryVariables>;
export const GetStockAdjustmentReasonListingDocument = gql`
    query getStockAdjustmentReasonListing($StockAdjustmentReasonID: String, $orderByAsc: String, $orderByDesc: String, $searchValue: String, $skip: Float, $take: Float) {
  getStockAdjustmentReasonPerm(StockAdjustmentReasonID: $StockAdjustmentReasonID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, searchValue: $searchValue, skip: $skip, take: $take) {
    StockAdjustmentReasonID
    Reason
    modTs
    createdTs
    IsSystem
    IsDefault
  }
  getStockAdjustmentReasonCount(StockAdjustmentReasonID: $StockAdjustmentReasonID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc)
}
    `;

/**
 * __useGetStockAdjustmentReasonListingQuery__
 *
 * To run a query within a React component, call `useGetStockAdjustmentReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAdjustmentReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAdjustmentReasonListingQuery({
 *   variables: {
 *      StockAdjustmentReasonID: // value for 'StockAdjustmentReasonID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetStockAdjustmentReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockAdjustmentReasonListingQuery, GetStockAdjustmentReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockAdjustmentReasonListingQuery, GetStockAdjustmentReasonListingQueryVariables>(GetStockAdjustmentReasonListingDocument, baseOptions);
      }
export function useGetStockAdjustmentReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockAdjustmentReasonListingQuery, GetStockAdjustmentReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockAdjustmentReasonListingQuery, GetStockAdjustmentReasonListingQueryVariables>(GetStockAdjustmentReasonListingDocument, baseOptions);
        }
export type GetStockAdjustmentReasonListingQueryHookResult = ReturnType<typeof useGetStockAdjustmentReasonListingQuery>;
export type GetStockAdjustmentReasonListingLazyQueryHookResult = ReturnType<typeof useGetStockAdjustmentReasonListingLazyQuery>;
export type GetStockAdjustmentReasonListingQueryResult = ApolloReactCommon.QueryResult<GetStockAdjustmentReasonListingQuery, GetStockAdjustmentReasonListingQueryVariables>;
export const CreateStockAdjustmentReasonDocument = gql`
    mutation createStockAdjustmentReason($input: StockAdjustmentReasonInput!) {
  createStockAdjustmentReason(input: $input) {
    StockAdjustmentReasonID
  }
}
    `;
export type CreateStockAdjustmentReasonMutationFn = ApolloReactCommon.MutationFunction<CreateStockAdjustmentReasonMutation, CreateStockAdjustmentReasonMutationVariables>;

/**
 * __useCreateStockAdjustmentReasonMutation__
 *
 * To run a mutation, you first call `useCreateStockAdjustmentReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockAdjustmentReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockAdjustmentReasonMutation, { data, loading, error }] = useCreateStockAdjustmentReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockAdjustmentReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockAdjustmentReasonMutation, CreateStockAdjustmentReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockAdjustmentReasonMutation, CreateStockAdjustmentReasonMutationVariables>(CreateStockAdjustmentReasonDocument, baseOptions);
      }
export type CreateStockAdjustmentReasonMutationHookResult = ReturnType<typeof useCreateStockAdjustmentReasonMutation>;
export type CreateStockAdjustmentReasonMutationResult = ApolloReactCommon.MutationResult<CreateStockAdjustmentReasonMutation>;
export type CreateStockAdjustmentReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockAdjustmentReasonMutation, CreateStockAdjustmentReasonMutationVariables>;
export const UpdateStockAdjustmentReasonDocument = gql`
    mutation updateStockAdjustmentReason($input: StockAdjustmentReasonInput!) {
  updateStockAdjustmentReason(input: $input) {
    StockAdjustmentReasonID
    Reason
  }
}
    `;
export type UpdateStockAdjustmentReasonMutationFn = ApolloReactCommon.MutationFunction<UpdateStockAdjustmentReasonMutation, UpdateStockAdjustmentReasonMutationVariables>;

/**
 * __useUpdateStockAdjustmentReasonMutation__
 *
 * To run a mutation, you first call `useUpdateStockAdjustmentReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockAdjustmentReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockAdjustmentReasonMutation, { data, loading, error }] = useUpdateStockAdjustmentReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockAdjustmentReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockAdjustmentReasonMutation, UpdateStockAdjustmentReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockAdjustmentReasonMutation, UpdateStockAdjustmentReasonMutationVariables>(UpdateStockAdjustmentReasonDocument, baseOptions);
      }
export type UpdateStockAdjustmentReasonMutationHookResult = ReturnType<typeof useUpdateStockAdjustmentReasonMutation>;
export type UpdateStockAdjustmentReasonMutationResult = ApolloReactCommon.MutationResult<UpdateStockAdjustmentReasonMutation>;
export type UpdateStockAdjustmentReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockAdjustmentReasonMutation, UpdateStockAdjustmentReasonMutationVariables>;
export const UpdateDefaultSaReasonDocument = gql`
    mutation updateDefaultSAReason($StockAdjustmentReasonID: String!) {
  updateDefaultSAReason(StockAdjustmentReasonID: $StockAdjustmentReasonID)
}
    `;
export type UpdateDefaultSaReasonMutationFn = ApolloReactCommon.MutationFunction<UpdateDefaultSaReasonMutation, UpdateDefaultSaReasonMutationVariables>;

/**
 * __useUpdateDefaultSaReasonMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultSaReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultSaReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultSaReasonMutation, { data, loading, error }] = useUpdateDefaultSaReasonMutation({
 *   variables: {
 *      StockAdjustmentReasonID: // value for 'StockAdjustmentReasonID'
 *   },
 * });
 */
export function useUpdateDefaultSaReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefaultSaReasonMutation, UpdateDefaultSaReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefaultSaReasonMutation, UpdateDefaultSaReasonMutationVariables>(UpdateDefaultSaReasonDocument, baseOptions);
      }
export type UpdateDefaultSaReasonMutationHookResult = ReturnType<typeof useUpdateDefaultSaReasonMutation>;
export type UpdateDefaultSaReasonMutationResult = ApolloReactCommon.MutationResult<UpdateDefaultSaReasonMutation>;
export type UpdateDefaultSaReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDefaultSaReasonMutation, UpdateDefaultSaReasonMutationVariables>;
export const DeleteStockAdjustmentReasonDocument = gql`
    mutation deleteStockAdjustmentReason($input: StockAdjustmentReasonDeleteInput!) {
  deleteStockAdjustmentReason(input: $input)
}
    `;
export type DeleteStockAdjustmentReasonMutationFn = ApolloReactCommon.MutationFunction<DeleteStockAdjustmentReasonMutation, DeleteStockAdjustmentReasonMutationVariables>;

/**
 * __useDeleteStockAdjustmentReasonMutation__
 *
 * To run a mutation, you first call `useDeleteStockAdjustmentReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockAdjustmentReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockAdjustmentReasonMutation, { data, loading, error }] = useDeleteStockAdjustmentReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStockAdjustmentReasonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockAdjustmentReasonMutation, DeleteStockAdjustmentReasonMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockAdjustmentReasonMutation, DeleteStockAdjustmentReasonMutationVariables>(DeleteStockAdjustmentReasonDocument, baseOptions);
      }
export type DeleteStockAdjustmentReasonMutationHookResult = ReturnType<typeof useDeleteStockAdjustmentReasonMutation>;
export type DeleteStockAdjustmentReasonMutationResult = ApolloReactCommon.MutationResult<DeleteStockAdjustmentReasonMutation>;
export type DeleteStockAdjustmentReasonMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockAdjustmentReasonMutation, DeleteStockAdjustmentReasonMutationVariables>;
export const GetStockCategoryDocument = gql`
    query getStockCategory($StockCategoryID: String, $StockAccountCodeID: String, $orderByAsc: String) {
  getStockCategory(StockCategoryID: $StockCategoryID, StockAccountCodeID: $StockAccountCodeID, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    AccountID
    RecordStatus
    StockCategoryID
    ParentStockCategoryID
    Level
    Name
    StockAccountCodeID
    StockAccountCode {
      Code
      Name
    }
    StockItem {
      Name
      Description
    }
  }
}
    `;

/**
 * __useGetStockCategoryQuery__
 *
 * To run a query within a React component, call `useGetStockCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCategoryQuery({
 *   variables: {
 *      StockCategoryID: // value for 'StockCategoryID'
 *      StockAccountCodeID: // value for 'StockAccountCodeID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCategoryQuery, GetStockCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCategoryQuery, GetStockCategoryQueryVariables>(GetStockCategoryDocument, baseOptions);
      }
export function useGetStockCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCategoryQuery, GetStockCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCategoryQuery, GetStockCategoryQueryVariables>(GetStockCategoryDocument, baseOptions);
        }
export type GetStockCategoryQueryHookResult = ReturnType<typeof useGetStockCategoryQuery>;
export type GetStockCategoryLazyQueryHookResult = ReturnType<typeof useGetStockCategoryLazyQuery>;
export type GetStockCategoryQueryResult = ApolloReactCommon.QueryResult<GetStockCategoryQuery, GetStockCategoryQueryVariables>;
export const GetStockCategoryPermDocument = gql`
    query getStockCategoryPerm($StockCategoryID: String, $StockAccountCodeID: String, $orderByAsc: String) {
  getStockCategoryPerm(StockCategoryID: $StockCategoryID, StockAccountCodeID: $StockAccountCodeID, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    AccountID
    RecordStatus
    StockCategoryID
    ParentStockCategoryID
    Level
    Name
    StockAccountCodeID
    StockAccountCode {
      Code
      Name
    }
    StockItem {
      Name
      Description
    }
  }
}
    `;

/**
 * __useGetStockCategoryPermQuery__
 *
 * To run a query within a React component, call `useGetStockCategoryPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCategoryPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCategoryPermQuery({
 *   variables: {
 *      StockCategoryID: // value for 'StockCategoryID'
 *      StockAccountCodeID: // value for 'StockAccountCodeID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockCategoryPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCategoryPermQuery, GetStockCategoryPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCategoryPermQuery, GetStockCategoryPermQueryVariables>(GetStockCategoryPermDocument, baseOptions);
      }
export function useGetStockCategoryPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCategoryPermQuery, GetStockCategoryPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCategoryPermQuery, GetStockCategoryPermQueryVariables>(GetStockCategoryPermDocument, baseOptions);
        }
export type GetStockCategoryPermQueryHookResult = ReturnType<typeof useGetStockCategoryPermQuery>;
export type GetStockCategoryPermLazyQueryHookResult = ReturnType<typeof useGetStockCategoryPermLazyQuery>;
export type GetStockCategoryPermQueryResult = ApolloReactCommon.QueryResult<GetStockCategoryPermQuery, GetStockCategoryPermQueryVariables>;
export const GetStockCategoryNameDocument = gql`
    query getStockCategoryName($StockCategoryID: String, $StockAccountCodeID: String, $orderByAsc: String) {
  getStockCategory(StockCategoryID: $StockCategoryID, StockAccountCodeID: $StockAccountCodeID, orderByAsc: $orderByAsc) {
    StockCategoryID
    Name
  }
}
    `;

/**
 * __useGetStockCategoryNameQuery__
 *
 * To run a query within a React component, call `useGetStockCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCategoryNameQuery({
 *   variables: {
 *      StockCategoryID: // value for 'StockCategoryID'
 *      StockAccountCodeID: // value for 'StockAccountCodeID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockCategoryNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCategoryNameQuery, GetStockCategoryNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCategoryNameQuery, GetStockCategoryNameQueryVariables>(GetStockCategoryNameDocument, baseOptions);
      }
export function useGetStockCategoryNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCategoryNameQuery, GetStockCategoryNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCategoryNameQuery, GetStockCategoryNameQueryVariables>(GetStockCategoryNameDocument, baseOptions);
        }
export type GetStockCategoryNameQueryHookResult = ReturnType<typeof useGetStockCategoryNameQuery>;
export type GetStockCategoryNameLazyQueryHookResult = ReturnType<typeof useGetStockCategoryNameLazyQuery>;
export type GetStockCategoryNameQueryResult = ApolloReactCommon.QueryResult<GetStockCategoryNameQuery, GetStockCategoryNameQueryVariables>;
export const GetStockCategoryDetailDocument = gql`
    query getStockCategoryDetail($StockCategoryID: String, $StockAccountCodeID: String, $orderByAsc: String) {
  getStockCategory(StockCategoryID: $StockCategoryID, StockAccountCodeID: $StockAccountCodeID, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    AccountID
    RecordStatus
    StockCategoryID
    ParentStockCategoryID
    Level
    Name
    StockAccountCodeID
    ParentCategoryMCOA
    StockAccountCode {
      Code
      Name
    }
    StockItem {
      Name
    }
  }
}
    `;

/**
 * __useGetStockCategoryDetailQuery__
 *
 * To run a query within a React component, call `useGetStockCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCategoryDetailQuery({
 *   variables: {
 *      StockCategoryID: // value for 'StockCategoryID'
 *      StockAccountCodeID: // value for 'StockAccountCodeID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockCategoryDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCategoryDetailQuery, GetStockCategoryDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCategoryDetailQuery, GetStockCategoryDetailQueryVariables>(GetStockCategoryDetailDocument, baseOptions);
      }
export function useGetStockCategoryDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCategoryDetailQuery, GetStockCategoryDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCategoryDetailQuery, GetStockCategoryDetailQueryVariables>(GetStockCategoryDetailDocument, baseOptions);
        }
export type GetStockCategoryDetailQueryHookResult = ReturnType<typeof useGetStockCategoryDetailQuery>;
export type GetStockCategoryDetailLazyQueryHookResult = ReturnType<typeof useGetStockCategoryDetailLazyQuery>;
export type GetStockCategoryDetailQueryResult = ApolloReactCommon.QueryResult<GetStockCategoryDetailQuery, GetStockCategoryDetailQueryVariables>;
export const CreateStockCategoryDocument = gql`
    mutation createStockCategory($input: StockCategoryInput!) {
  createStockCategory(input: $input)
}
    `;
export type CreateStockCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateStockCategoryMutation, CreateStockCategoryMutationVariables>;

/**
 * __useCreateStockCategoryMutation__
 *
 * To run a mutation, you first call `useCreateStockCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockCategoryMutation, { data, loading, error }] = useCreateStockCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockCategoryMutation, CreateStockCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockCategoryMutation, CreateStockCategoryMutationVariables>(CreateStockCategoryDocument, baseOptions);
      }
export type CreateStockCategoryMutationHookResult = ReturnType<typeof useCreateStockCategoryMutation>;
export type CreateStockCategoryMutationResult = ApolloReactCommon.MutationResult<CreateStockCategoryMutation>;
export type CreateStockCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockCategoryMutation, CreateStockCategoryMutationVariables>;
export const UpdateStockCategoryDocument = gql`
    mutation updateStockCategory($input: StockCategoryInput!) {
  updateStockCategory(input: $input)
}
    `;
export type UpdateStockCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateStockCategoryMutation, UpdateStockCategoryMutationVariables>;

/**
 * __useUpdateStockCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateStockCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockCategoryMutation, { data, loading, error }] = useUpdateStockCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockCategoryMutation, UpdateStockCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockCategoryMutation, UpdateStockCategoryMutationVariables>(UpdateStockCategoryDocument, baseOptions);
      }
export type UpdateStockCategoryMutationHookResult = ReturnType<typeof useUpdateStockCategoryMutation>;
export type UpdateStockCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateStockCategoryMutation>;
export type UpdateStockCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockCategoryMutation, UpdateStockCategoryMutationVariables>;
export const DeleteStockCategoryDocument = gql`
    mutation deleteStockCategory($StockCategoryID: String!) {
  deleteStockCategory(StockCategoryID: $StockCategoryID)
}
    `;
export type DeleteStockCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteStockCategoryMutation, DeleteStockCategoryMutationVariables>;

/**
 * __useDeleteStockCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteStockCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockCategoryMutation, { data, loading, error }] = useDeleteStockCategoryMutation({
 *   variables: {
 *      StockCategoryID: // value for 'StockCategoryID'
 *   },
 * });
 */
export function useDeleteStockCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockCategoryMutation, DeleteStockCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockCategoryMutation, DeleteStockCategoryMutationVariables>(DeleteStockCategoryDocument, baseOptions);
      }
export type DeleteStockCategoryMutationHookResult = ReturnType<typeof useDeleteStockCategoryMutation>;
export type DeleteStockCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteStockCategoryMutation>;
export type DeleteStockCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockCategoryMutation, DeleteStockCategoryMutationVariables>;
export const GetStockItemDocument = gql`
    query getStockItem($StockItemID: String, $StockCategoryID: String, $orderByAsc: String) {
  getStockItem(StockItemID: $StockItemID, StockCategoryID: $StockCategoryID, orderByAsc: $orderByAsc) {
    AccountID
    StockItemID
    StockCategoryID
    StockCategory {
      Name
    }
    createdTs
    createdBy
    modTs
    modBy
    TaxSchemeID
    Description
    Remark
    RecordStatus
    Name
    IsUse
    ABCClass
    ShelfLife
    StandardCost
    EInvoiceIntegrated
    ClassificationCodeID
    UOMID
    UOM {
      Name
      Code
    }
    StockItemWarehouse {
      WarehouseID
      Warehouse {
        Name
      }
      MaximumQty
      MinimumQty
      ReorderLevel
      ReorderQty
      RecordStatus
      UsedStockItemWarehouse
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    TaxScheme {
      Code
      TaxSchemeID
      Description
    }
  }
}
    `;

/**
 * __useGetStockItemQuery__
 *
 * To run a query within a React component, call `useGetStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      StockCategoryID: // value for 'StockCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemQuery, GetStockItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemQuery, GetStockItemQueryVariables>(GetStockItemDocument, baseOptions);
      }
export function useGetStockItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemQuery, GetStockItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemQuery, GetStockItemQueryVariables>(GetStockItemDocument, baseOptions);
        }
export type GetStockItemQueryHookResult = ReturnType<typeof useGetStockItemQuery>;
export type GetStockItemLazyQueryHookResult = ReturnType<typeof useGetStockItemLazyQuery>;
export type GetStockItemQueryResult = ApolloReactCommon.QueryResult<GetStockItemQuery, GetStockItemQueryVariables>;
export const GetStockItemWStockItemWarehouseDocument = gql`
    query getStockItemWStockItemWarehouse($StockItemID: String, $StockCategoryID: String, $orderByAsc: String) {
  getStockItem(StockItemID: $StockItemID, StockCategoryID: $StockCategoryID, orderByAsc: $orderByAsc) {
    AccountID
    StockItemID
    StockCategoryID
    StockCategory {
      Name
    }
    createdTs
    createdBy
    modTs
    modBy
    TaxSchemeID
    Description
    Remark
    RecordStatus
    Name
    IsUse
    ABCClass
    ShelfLife
    StandardCost
    EInvoiceIntegrated
    ClassificationCodeID
    UOMID
    UOM {
      Name
      Code
    }
    StockItemWarehouse {
      WarehouseID
      Warehouse {
        Name
      }
      MaximumQty
      MinimumQty
      ReorderLevel
      ReorderQty
      RecordStatus
      UsedStockItemWarehouse
      StockQtyByWarehouse(StockItemID: $StockItemID)
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    TaxScheme {
      Code
      TaxSchemeID
      Description
    }
  }
}
    `;

/**
 * __useGetStockItemWStockItemWarehouseQuery__
 *
 * To run a query within a React component, call `useGetStockItemWStockItemWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemWStockItemWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemWStockItemWarehouseQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      StockCategoryID: // value for 'StockCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemWStockItemWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemWStockItemWarehouseQuery, GetStockItemWStockItemWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemWStockItemWarehouseQuery, GetStockItemWStockItemWarehouseQueryVariables>(GetStockItemWStockItemWarehouseDocument, baseOptions);
      }
export function useGetStockItemWStockItemWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemWStockItemWarehouseQuery, GetStockItemWStockItemWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemWStockItemWarehouseQuery, GetStockItemWStockItemWarehouseQueryVariables>(GetStockItemWStockItemWarehouseDocument, baseOptions);
        }
export type GetStockItemWStockItemWarehouseQueryHookResult = ReturnType<typeof useGetStockItemWStockItemWarehouseQuery>;
export type GetStockItemWStockItemWarehouseLazyQueryHookResult = ReturnType<typeof useGetStockItemWStockItemWarehouseLazyQuery>;
export type GetStockItemWStockItemWarehouseQueryResult = ApolloReactCommon.QueryResult<GetStockItemWStockItemWarehouseQuery, GetStockItemWStockItemWarehouseQueryVariables>;
export const GetStockItemNameDocument = gql`
    query getStockItemName($StockItemID: String, $orderByAsc: String) {
  getStockItem(StockItemID: $StockItemID, orderByAsc: $orderByAsc) {
    StockItemID
    Name
    StockItemWarehouse {
      WarehouseID
      Warehouse {
        Name
      }
    }
  }
}
    `;

/**
 * __useGetStockItemNameQuery__
 *
 * To run a query within a React component, call `useGetStockItemNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemNameQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemNameQuery, GetStockItemNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemNameQuery, GetStockItemNameQueryVariables>(GetStockItemNameDocument, baseOptions);
      }
export function useGetStockItemNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemNameQuery, GetStockItemNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemNameQuery, GetStockItemNameQueryVariables>(GetStockItemNameDocument, baseOptions);
        }
export type GetStockItemNameQueryHookResult = ReturnType<typeof useGetStockItemNameQuery>;
export type GetStockItemNameLazyQueryHookResult = ReturnType<typeof useGetStockItemNameLazyQuery>;
export type GetStockItemNameQueryResult = ApolloReactCommon.QueryResult<GetStockItemNameQuery, GetStockItemNameQueryVariables>;
export const GetStockItemDetailDocument = gql`
    query getStockItemDetail($StockItemID: String, $StockCategoryID: String, $orderByAsc: String) {
  getStockItem(StockItemID: $StockItemID, StockCategoryID: $StockCategoryID, orderByAsc: $orderByAsc) {
    AccountID
    StockItemID
    StockCategoryID
    StockCategory {
      Name
      StockAccountCodeID
      StockAccountCode {
        Code
        Name
      }
      ParentCategoryMCOA
    }
    createdTs
    createdBy
    modTs
    modBy
    TaxSchemeID
    Description
    Remark
    RecordStatus
    Name
    IsUse
    ABCClass
    ShelfLife
    StandardCost
    UOMID
    UOM {
      Name
      Code
    }
    StockItemWarehouse {
      WarehouseID
      Warehouse {
        Name
      }
      MaximumQty
      MinimumQty
      ReorderLevel
      ReorderQty
      RecordStatus
      UsedStockItemWarehouse
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    TaxScheme {
      Code
      TaxSchemeID
      Description
    }
  }
}
    `;

/**
 * __useGetStockItemDetailQuery__
 *
 * To run a query within a React component, call `useGetStockItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemDetailQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      StockCategoryID: // value for 'StockCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>(GetStockItemDetailDocument, baseOptions);
      }
export function useGetStockItemDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>(GetStockItemDetailDocument, baseOptions);
        }
export type GetStockItemDetailQueryHookResult = ReturnType<typeof useGetStockItemDetailQuery>;
export type GetStockItemDetailLazyQueryHookResult = ReturnType<typeof useGetStockItemDetailLazyQuery>;
export type GetStockItemDetailQueryResult = ApolloReactCommon.QueryResult<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>;
export const CreateStockItemDocument = gql`
    mutation CreateStockItem($input: StockItemInput!, $warehouseInput: [StockItemWarehouseInput!]) {
  createStockItem(input: $input, warehouseInput: $warehouseInput)
}
    `;
export type CreateStockItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockItemMutation, CreateStockItemMutationVariables>;

/**
 * __useCreateStockItemMutation__
 *
 * To run a mutation, you first call `useCreateStockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockItemMutation, { data, loading, error }] = useCreateStockItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      warehouseInput: // value for 'warehouseInput'
 *   },
 * });
 */
export function useCreateStockItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockItemMutation, CreateStockItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockItemMutation, CreateStockItemMutationVariables>(CreateStockItemDocument, baseOptions);
      }
export type CreateStockItemMutationHookResult = ReturnType<typeof useCreateStockItemMutation>;
export type CreateStockItemMutationResult = ApolloReactCommon.MutationResult<CreateStockItemMutation>;
export type CreateStockItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockItemMutation, CreateStockItemMutationVariables>;
export const UpdateStockItemDocument = gql`
    mutation updateStockItem($input: StockItemInput!) {
  updateStockItem(input: $input)
}
    `;
export type UpdateStockItemMutationFn = ApolloReactCommon.MutationFunction<UpdateStockItemMutation, UpdateStockItemMutationVariables>;

/**
 * __useUpdateStockItemMutation__
 *
 * To run a mutation, you first call `useUpdateStockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockItemMutation, { data, loading, error }] = useUpdateStockItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockItemMutation, UpdateStockItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockItemMutation, UpdateStockItemMutationVariables>(UpdateStockItemDocument, baseOptions);
      }
export type UpdateStockItemMutationHookResult = ReturnType<typeof useUpdateStockItemMutation>;
export type UpdateStockItemMutationResult = ApolloReactCommon.MutationResult<UpdateStockItemMutation>;
export type UpdateStockItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockItemMutation, UpdateStockItemMutationVariables>;
export const UpdateStockItemWithWarehouseDocument = gql`
    mutation UpdateStockItemWithWarehouse($input: StockItemInput!, $warehouse: [StockItemWarehouseInput!], $StockItemID: String!) {
  UpdateStockItemWithWarehouse(input: $input, warehouse: $warehouse, StockItemID: $StockItemID)
}
    `;
export type UpdateStockItemWithWarehouseMutationFn = ApolloReactCommon.MutationFunction<UpdateStockItemWithWarehouseMutation, UpdateStockItemWithWarehouseMutationVariables>;

/**
 * __useUpdateStockItemWithWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateStockItemWithWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockItemWithWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockItemWithWarehouseMutation, { data, loading, error }] = useUpdateStockItemWithWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      warehouse: // value for 'warehouse'
 *      StockItemID: // value for 'StockItemID'
 *   },
 * });
 */
export function useUpdateStockItemWithWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockItemWithWarehouseMutation, UpdateStockItemWithWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockItemWithWarehouseMutation, UpdateStockItemWithWarehouseMutationVariables>(UpdateStockItemWithWarehouseDocument, baseOptions);
      }
export type UpdateStockItemWithWarehouseMutationHookResult = ReturnType<typeof useUpdateStockItemWithWarehouseMutation>;
export type UpdateStockItemWithWarehouseMutationResult = ApolloReactCommon.MutationResult<UpdateStockItemWithWarehouseMutation>;
export type UpdateStockItemWithWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockItemWithWarehouseMutation, UpdateStockItemWithWarehouseMutationVariables>;
export const DeleteStockItemDocument = gql`
    mutation DeleteStockItem($input: StockItemDeleteInput!) {
  deleteStockItem(input: $input)
}
    `;
export type DeleteStockItemMutationFn = ApolloReactCommon.MutationFunction<DeleteStockItemMutation, DeleteStockItemMutationVariables>;

/**
 * __useDeleteStockItemMutation__
 *
 * To run a mutation, you first call `useDeleteStockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockItemMutation, { data, loading, error }] = useDeleteStockItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStockItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockItemMutation, DeleteStockItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockItemMutation, DeleteStockItemMutationVariables>(DeleteStockItemDocument, baseOptions);
      }
export type DeleteStockItemMutationHookResult = ReturnType<typeof useDeleteStockItemMutation>;
export type DeleteStockItemMutationResult = ApolloReactCommon.MutationResult<DeleteStockItemMutation>;
export type DeleteStockItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockItemMutation, DeleteStockItemMutationVariables>;
export const GetStockLedgerDocument = gql`
    query getStockLedger($StockItemID: String!, $WarehouseID: String!) {
  getStockLedger(StockItemID: $StockItemID, WarehouseID: $WarehouseID)
}
    `;

/**
 * __useGetStockLedgerQuery__
 *
 * To run a query within a React component, call `useGetStockLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockLedgerQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockLedgerQuery, GetStockLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockLedgerQuery, GetStockLedgerQueryVariables>(GetStockLedgerDocument, baseOptions);
      }
export function useGetStockLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockLedgerQuery, GetStockLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockLedgerQuery, GetStockLedgerQueryVariables>(GetStockLedgerDocument, baseOptions);
        }
export type GetStockLedgerQueryHookResult = ReturnType<typeof useGetStockLedgerQuery>;
export type GetStockLedgerLazyQueryHookResult = ReturnType<typeof useGetStockLedgerLazyQuery>;
export type GetStockLedgerQueryResult = ApolloReactCommon.QueryResult<GetStockLedgerQuery, GetStockLedgerQueryVariables>;
export const StockItemWarehouseSummaryDocument = gql`
    query stockItemWarehouseSummary($CompanyID: String!, $StockItemID: String!, $WarehouseIDs: [String!]!) {
  stockItemWarehouseSummary(CompanyID: $CompanyID, StockItemID: $StockItemID, WarehouseIDs: $WarehouseIDs)
}
    `;

/**
 * __useStockItemWarehouseSummaryQuery__
 *
 * To run a query within a React component, call `useStockItemWarehouseSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockItemWarehouseSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockItemWarehouseSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StockItemID: // value for 'StockItemID'
 *      WarehouseIDs: // value for 'WarehouseIDs'
 *   },
 * });
 */
export function useStockItemWarehouseSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockItemWarehouseSummaryQuery, StockItemWarehouseSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<StockItemWarehouseSummaryQuery, StockItemWarehouseSummaryQueryVariables>(StockItemWarehouseSummaryDocument, baseOptions);
      }
export function useStockItemWarehouseSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockItemWarehouseSummaryQuery, StockItemWarehouseSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockItemWarehouseSummaryQuery, StockItemWarehouseSummaryQueryVariables>(StockItemWarehouseSummaryDocument, baseOptions);
        }
export type StockItemWarehouseSummaryQueryHookResult = ReturnType<typeof useStockItemWarehouseSummaryQuery>;
export type StockItemWarehouseSummaryLazyQueryHookResult = ReturnType<typeof useStockItemWarehouseSummaryLazyQuery>;
export type StockItemWarehouseSummaryQueryResult = ApolloReactCommon.QueryResult<StockItemWarehouseSummaryQuery, StockItemWarehouseSummaryQueryVariables>;
export const GetStockItemListDocument = gql`
    query getStockItemList($StockItemID: String, $StockCategoryID: String, $orderByAsc: String) {
  getStockItemPerm(StockItemID: $StockItemID, StockCategoryID: $StockCategoryID, orderByAsc: $orderByAsc) {
    StockItemID
    RecordStatus
    Name
    IsUse
    ABCClass
    ShelfLife
    UOM {
      Name
      Code
    }
    StockItemWarehouse {
      WarehouseID
      Warehouse {
        Name
      }
      MaximumQty
      MinimumQty
      ReorderLevel
      ReorderQty
    }
  }
}
    `;

/**
 * __useGetStockItemListQuery__
 *
 * To run a query within a React component, call `useGetStockItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemListQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      StockCategoryID: // value for 'StockCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemListQuery, GetStockItemListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemListQuery, GetStockItemListQueryVariables>(GetStockItemListDocument, baseOptions);
      }
export function useGetStockItemListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemListQuery, GetStockItemListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemListQuery, GetStockItemListQueryVariables>(GetStockItemListDocument, baseOptions);
        }
export type GetStockItemListQueryHookResult = ReturnType<typeof useGetStockItemListQuery>;
export type GetStockItemListLazyQueryHookResult = ReturnType<typeof useGetStockItemListLazyQuery>;
export type GetStockItemListQueryResult = ApolloReactCommon.QueryResult<GetStockItemListQuery, GetStockItemListQueryVariables>;
export const GetStockItemWarehouseDocument = gql`
    query getStockItemWarehouse($StockItemWarehouseID: String, $WarehouseID: String) {
  getStockItemWarehouse(StockItemWarehouseID: $StockItemWarehouseID, WarehouseID: $WarehouseID) {
    AccountID
    StockItemWarehouseID
    StockItemID
    StockItem {
      Name
      StockItemID
      UOMID
      StockCategory {
        Name
      }
      UOM {
        Name
      }
      RecordStatus
    }
    RecordStatus
    MaximumQty
    MinimumQty
    ReorderLevel
    ReorderQty
    StockQty(WarehouseID: $WarehouseID)
    SubmitStockQty(WarehouseID: $WarehouseID)
    StockTotalValue(WarehouseID: $WarehouseID)
    StockAvgUnitCost(WarehouseID: $WarehouseID)
    UsedStockItemWarehouse
    WarehouseID
    Warehouse {
      WarehouseID
      Name
    }
  }
}
    `;

/**
 * __useGetStockItemWarehouseQuery__
 *
 * To run a query within a React component, call `useGetStockItemWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemWarehouseQuery({
 *   variables: {
 *      StockItemWarehouseID: // value for 'StockItemWarehouseID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockItemWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemWarehouseQuery, GetStockItemWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemWarehouseQuery, GetStockItemWarehouseQueryVariables>(GetStockItemWarehouseDocument, baseOptions);
      }
export function useGetStockItemWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemWarehouseQuery, GetStockItemWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemWarehouseQuery, GetStockItemWarehouseQueryVariables>(GetStockItemWarehouseDocument, baseOptions);
        }
export type GetStockItemWarehouseQueryHookResult = ReturnType<typeof useGetStockItemWarehouseQuery>;
export type GetStockItemWarehouseLazyQueryHookResult = ReturnType<typeof useGetStockItemWarehouseLazyQuery>;
export type GetStockItemWarehouseQueryResult = ApolloReactCommon.QueryResult<GetStockItemWarehouseQuery, GetStockItemWarehouseQueryVariables>;
export const GetStockItemWarehouseListingDocument = gql`
    query getStockItemWarehouseListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float) {
  getStockItemWarehouseListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip) {
    Data {
      AccountID
      StockItemWarehouseID
      StockItemID
      StockItem {
        Name
        StockItemID
        UOMID
        StockCategory {
          Name
        }
        UOM {
          Name
        }
      }
      RecordStatus
      MaximumQty
      MinimumQty
      ReorderLevel
      ReorderQty
      StockQty(WarehouseID: $WarehouseID)
      SubmitStockQty(WarehouseID: $WarehouseID)
      StockTotalValue(WarehouseID: $WarehouseID)
      StockAvgUnitCost(WarehouseID: $WarehouseID)
      WarehouseID
      Warehouse {
        WarehouseID
        Name
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockItemWarehouseListingQuery__
 *
 * To run a query within a React component, call `useGetStockItemWarehouseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemWarehouseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemWarehouseListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetStockItemWarehouseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemWarehouseListingQuery, GetStockItemWarehouseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemWarehouseListingQuery, GetStockItemWarehouseListingQueryVariables>(GetStockItemWarehouseListingDocument, baseOptions);
      }
export function useGetStockItemWarehouseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemWarehouseListingQuery, GetStockItemWarehouseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemWarehouseListingQuery, GetStockItemWarehouseListingQueryVariables>(GetStockItemWarehouseListingDocument, baseOptions);
        }
export type GetStockItemWarehouseListingQueryHookResult = ReturnType<typeof useGetStockItemWarehouseListingQuery>;
export type GetStockItemWarehouseListingLazyQueryHookResult = ReturnType<typeof useGetStockItemWarehouseListingLazyQuery>;
export type GetStockItemWarehouseListingQueryResult = ApolloReactCommon.QueryResult<GetStockItemWarehouseListingQuery, GetStockItemWarehouseListingQueryVariables>;
export const StockEnquiryDetailsDocument = gql`
    query stockEnquiryDetails($WarehouseID: String!) {
  stockEnquiryDetails(WarehouseID: $WarehouseID) {
    ItemCount
    TotalItemCost
  }
}
    `;

/**
 * __useStockEnquiryDetailsQuery__
 *
 * To run a query within a React component, call `useStockEnquiryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockEnquiryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockEnquiryDetailsQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useStockEnquiryDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockEnquiryDetailsQuery, StockEnquiryDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<StockEnquiryDetailsQuery, StockEnquiryDetailsQueryVariables>(StockEnquiryDetailsDocument, baseOptions);
      }
export function useStockEnquiryDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockEnquiryDetailsQuery, StockEnquiryDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockEnquiryDetailsQuery, StockEnquiryDetailsQueryVariables>(StockEnquiryDetailsDocument, baseOptions);
        }
export type StockEnquiryDetailsQueryHookResult = ReturnType<typeof useStockEnquiryDetailsQuery>;
export type StockEnquiryDetailsLazyQueryHookResult = ReturnType<typeof useStockEnquiryDetailsLazyQuery>;
export type StockEnquiryDetailsQueryResult = ApolloReactCommon.QueryResult<StockEnquiryDetailsQuery, StockEnquiryDetailsQueryVariables>;
export const StockEnquiryListingDocument = gql`
    query stockEnquiryListing($WarehouseID: String) {
  getStockItemWarehouse(WarehouseID: $WarehouseID) {
    StockItemWarehouseID
    StockItemID
    StockItem {
      Name
      StockCategory {
        Name
      }
      UOM {
        Name
      }
    }
    Warehouse {
      WarehouseID
      Name
    }
    StockTotalValue(WarehouseID: $WarehouseID)
    StockAvgUnitCost(WarehouseID: $WarehouseID)
    SubmitStockQty(WarehouseID: $WarehouseID)
    StockQty(WarehouseID: $WarehouseID)
  }
}
    `;

/**
 * __useStockEnquiryListingQuery__
 *
 * To run a query within a React component, call `useStockEnquiryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockEnquiryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockEnquiryListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useStockEnquiryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockEnquiryListingQuery, StockEnquiryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StockEnquiryListingQuery, StockEnquiryListingQueryVariables>(StockEnquiryListingDocument, baseOptions);
      }
export function useStockEnquiryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockEnquiryListingQuery, StockEnquiryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockEnquiryListingQuery, StockEnquiryListingQueryVariables>(StockEnquiryListingDocument, baseOptions);
        }
export type StockEnquiryListingQueryHookResult = ReturnType<typeof useStockEnquiryListingQuery>;
export type StockEnquiryListingLazyQueryHookResult = ReturnType<typeof useStockEnquiryListingLazyQuery>;
export type StockEnquiryListingQueryResult = ApolloReactCommon.QueryResult<StockEnquiryListingQuery, StockEnquiryListingQueryVariables>;
export const StockItemWarehouseListDocument = gql`
    query stockItemWarehouseList($StockItemWarehouseID: String, $WarehouseID: String, $RecordStatus: String) {
  getStockItemWarehouse(StockItemWarehouseID: $StockItemWarehouseID, WarehouseID: $WarehouseID, RecordStatus: $RecordStatus) {
    AccountID
    StockItemWarehouseID
    StockItemID
    StockItem {
      Name
      StockItemID
      UOMID
      StockCategory {
        Name
      }
      UOM {
        Name
      }
      RecordStatus
    }
    UsedStockItemWarehouse
    WarehouseID
    Warehouse {
      WarehouseID
      Name
    }
  }
}
    `;

/**
 * __useStockItemWarehouseListQuery__
 *
 * To run a query within a React component, call `useStockItemWarehouseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockItemWarehouseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockItemWarehouseListQuery({
 *   variables: {
 *      StockItemWarehouseID: // value for 'StockItemWarehouseID'
 *      WarehouseID: // value for 'WarehouseID'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useStockItemWarehouseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockItemWarehouseListQuery, StockItemWarehouseListQueryVariables>) {
        return ApolloReactHooks.useQuery<StockItemWarehouseListQuery, StockItemWarehouseListQueryVariables>(StockItemWarehouseListDocument, baseOptions);
      }
export function useStockItemWarehouseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockItemWarehouseListQuery, StockItemWarehouseListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockItemWarehouseListQuery, StockItemWarehouseListQueryVariables>(StockItemWarehouseListDocument, baseOptions);
        }
export type StockItemWarehouseListQueryHookResult = ReturnType<typeof useStockItemWarehouseListQuery>;
export type StockItemWarehouseListLazyQueryHookResult = ReturnType<typeof useStockItemWarehouseListLazyQuery>;
export type StockItemWarehouseListQueryResult = ApolloReactCommon.QueryResult<StockItemWarehouseListQuery, StockItemWarehouseListQueryVariables>;
export const GetStockItemWarehouseByCostCentreDocument = gql`
    query getStockItemWarehouseByCostCentre($WarehouseID: String, $CostCentreID: String) {
  getStockItemWarehouseByCostCentre(WarehouseID: $WarehouseID, CostCentreID: $CostCentreID) {
    AccountID
    StockItemWarehouseID
    StockItemID
    StockItem {
      Name
      StockItemID
      UOMID
      StockCategory {
        Name
        StockAccountCodeID
      }
      UOM {
        Name
      }
      RecordStatus
    }
    createdTs
    createdBy
    modTs
    modBy
    RecordStatus
    MaximumQty
    MinimumQty
    ReorderLevel
    ReorderQty
    StockQty(WarehouseID: $WarehouseID)
    SubmitStockQty(WarehouseID: $WarehouseID)
    StockTotalValue(WarehouseID: $WarehouseID)
    StockAvgUnitCost(WarehouseID: $WarehouseID)
    UsedStockItemWarehouse
    WarehouseID
    Warehouse {
      WarehouseID
      Name
    }
  }
}
    `;

/**
 * __useGetStockItemWarehouseByCostCentreQuery__
 *
 * To run a query within a React component, call `useGetStockItemWarehouseByCostCentreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemWarehouseByCostCentreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemWarehouseByCostCentreQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      CostCentreID: // value for 'CostCentreID'
 *   },
 * });
 */
export function useGetStockItemWarehouseByCostCentreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemWarehouseByCostCentreQuery, GetStockItemWarehouseByCostCentreQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemWarehouseByCostCentreQuery, GetStockItemWarehouseByCostCentreQueryVariables>(GetStockItemWarehouseByCostCentreDocument, baseOptions);
      }
export function useGetStockItemWarehouseByCostCentreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemWarehouseByCostCentreQuery, GetStockItemWarehouseByCostCentreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemWarehouseByCostCentreQuery, GetStockItemWarehouseByCostCentreQueryVariables>(GetStockItemWarehouseByCostCentreDocument, baseOptions);
        }
export type GetStockItemWarehouseByCostCentreQueryHookResult = ReturnType<typeof useGetStockItemWarehouseByCostCentreQuery>;
export type GetStockItemWarehouseByCostCentreLazyQueryHookResult = ReturnType<typeof useGetStockItemWarehouseByCostCentreLazyQuery>;
export type GetStockItemWarehouseByCostCentreQueryResult = ApolloReactCommon.QueryResult<GetStockItemWarehouseByCostCentreQuery, GetStockItemWarehouseByCostCentreQueryVariables>;
export const CreateStockItemWarehouseDocument = gql`
    mutation CreateStockItemWarehouse($input: StockItemWarehouseInput!) {
  createStockItemWarehouse(input: $input) {
    StockItemWarehouseID
  }
}
    `;
export type CreateStockItemWarehouseMutationFn = ApolloReactCommon.MutationFunction<CreateStockItemWarehouseMutation, CreateStockItemWarehouseMutationVariables>;

/**
 * __useCreateStockItemWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateStockItemWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockItemWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockItemWarehouseMutation, { data, loading, error }] = useCreateStockItemWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockItemWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockItemWarehouseMutation, CreateStockItemWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockItemWarehouseMutation, CreateStockItemWarehouseMutationVariables>(CreateStockItemWarehouseDocument, baseOptions);
      }
export type CreateStockItemWarehouseMutationHookResult = ReturnType<typeof useCreateStockItemWarehouseMutation>;
export type CreateStockItemWarehouseMutationResult = ApolloReactCommon.MutationResult<CreateStockItemWarehouseMutation>;
export type CreateStockItemWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockItemWarehouseMutation, CreateStockItemWarehouseMutationVariables>;
export const UpdateStockItemWarehouseDocument = gql`
    mutation updateStockItemWarehouse($input: StockItemWarehouseInput!) {
  updateStockItemWarehouse(input: $input) {
    StockItemWarehouseID
  }
}
    `;
export type UpdateStockItemWarehouseMutationFn = ApolloReactCommon.MutationFunction<UpdateStockItemWarehouseMutation, UpdateStockItemWarehouseMutationVariables>;

/**
 * __useUpdateStockItemWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateStockItemWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockItemWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockItemWarehouseMutation, { data, loading, error }] = useUpdateStockItemWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockItemWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockItemWarehouseMutation, UpdateStockItemWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockItemWarehouseMutation, UpdateStockItemWarehouseMutationVariables>(UpdateStockItemWarehouseDocument, baseOptions);
      }
export type UpdateStockItemWarehouseMutationHookResult = ReturnType<typeof useUpdateStockItemWarehouseMutation>;
export type UpdateStockItemWarehouseMutationResult = ApolloReactCommon.MutationResult<UpdateStockItemWarehouseMutation>;
export type UpdateStockItemWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockItemWarehouseMutation, UpdateStockItemWarehouseMutationVariables>;
export const GetGeneralSettingExecutiveSummaryDocument = gql`
    query getGeneralSettingExecutiveSummary($Category: [String!]) {
  CurrencyDate
  BankProfileDate
  CurrencyExchangeDate
  CompanyDate
  RoleDate
  UserDate
  TaxDate
  CostCentreDefinitionDate
  PaymentMethodDate
  CreditCardTypeDate
  ExpenseClassDate
  ExpenseItemDate
  CreditorTypeDate
  PolicySettingsDate
  ApprovalPolicyDate(Category: $Category)
  PaymentApprovalDate
  ReceiptApprovalDate
  BankTransferApprovalDate
  CreditorTypeCount
  WarehouseDate
  UOMDate
  UOMExchangeDate
  StockCategoryDate
  StockAdjustmentReasonDate
  DocumentNumberingDate
  DataSourceDate
  FinancialReportDate
  GLDigitalReportDate
}
    `;

/**
 * __useGetGeneralSettingExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingExecutiveSummaryQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetGeneralSettingExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
        }
export type GetGeneralSettingExecutiveSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryQuery>;
export type GetGeneralSettingExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryLazyQuery>;
export type GetGeneralSettingExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>;
export const GetCommonSettingSummaryDocument = gql`
    query getCommonSettingSummary {
  TaxDate
  UOMDate
  UOMExchangeDate
}
    `;

/**
 * __useGetCommonSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetCommonSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommonSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>(GetCommonSettingSummaryDocument, baseOptions);
      }
export function useGetCommonSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>(GetCommonSettingSummaryDocument, baseOptions);
        }
export type GetCommonSettingSummaryQueryHookResult = ReturnType<typeof useGetCommonSettingSummaryQuery>;
export type GetCommonSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetCommonSettingSummaryLazyQuery>;
export type GetCommonSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>;
export const GetTaxTypeDocument = gql`
    query getTaxType($TaxTypeID: String, $RecordStatus: RecordStatus) {
  getTaxType(TaxTypeID: $TaxTypeID, RecordStatus: $RecordStatus) {
    TaxTypeID
    Code
    RecordStatus
    Description
  }
}
    `;

/**
 * __useGetTaxTypeQuery__
 *
 * To run a query within a React component, call `useGetTaxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxTypeQuery({
 *   variables: {
 *      TaxTypeID: // value for 'TaxTypeID'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetTaxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxTypeQuery, GetTaxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxTypeQuery, GetTaxTypeQueryVariables>(GetTaxTypeDocument, baseOptions);
      }
export function useGetTaxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxTypeQuery, GetTaxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxTypeQuery, GetTaxTypeQueryVariables>(GetTaxTypeDocument, baseOptions);
        }
export type GetTaxTypeQueryHookResult = ReturnType<typeof useGetTaxTypeQuery>;
export type GetTaxTypeLazyQueryHookResult = ReturnType<typeof useGetTaxTypeLazyQuery>;
export type GetTaxTypeQueryResult = ApolloReactCommon.QueryResult<GetTaxTypeQuery, GetTaxTypeQueryVariables>;
export const CreateTaxTypeDocument = gql`
    mutation createTaxType($input: TaxTypeInput!) {
  createTaxType(input: $input) {
    TaxTypeID
  }
}
    `;
export type CreateTaxTypeMutationFn = ApolloReactCommon.MutationFunction<CreateTaxTypeMutation, CreateTaxTypeMutationVariables>;

/**
 * __useCreateTaxTypeMutation__
 *
 * To run a mutation, you first call `useCreateTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxTypeMutation, { data, loading, error }] = useCreateTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaxTypeMutation, CreateTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaxTypeMutation, CreateTaxTypeMutationVariables>(CreateTaxTypeDocument, baseOptions);
      }
export type CreateTaxTypeMutationHookResult = ReturnType<typeof useCreateTaxTypeMutation>;
export type CreateTaxTypeMutationResult = ApolloReactCommon.MutationResult<CreateTaxTypeMutation>;
export type CreateTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaxTypeMutation, CreateTaxTypeMutationVariables>;
export const UpdateTaxTypeDocument = gql`
    mutation updateTaxType($input: TaxTypeInput!) {
  updateTaxType(input: $input) {
    TaxTypeID
    Code
    Description
  }
}
    `;
export type UpdateTaxTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateTaxTypeMutation, UpdateTaxTypeMutationVariables>;

/**
 * __useUpdateTaxTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxTypeMutation, { data, loading, error }] = useUpdateTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaxTypeMutation, UpdateTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaxTypeMutation, UpdateTaxTypeMutationVariables>(UpdateTaxTypeDocument, baseOptions);
      }
export type UpdateTaxTypeMutationHookResult = ReturnType<typeof useUpdateTaxTypeMutation>;
export type UpdateTaxTypeMutationResult = ApolloReactCommon.MutationResult<UpdateTaxTypeMutation>;
export type UpdateTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaxTypeMutation, UpdateTaxTypeMutationVariables>;
export const DeleteTaxTypeDocument = gql`
    mutation deleteTaxType($input: TaxTypeInput!) {
  deleteTaxType(input: $input)
}
    `;
export type DeleteTaxTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteTaxTypeMutation, DeleteTaxTypeMutationVariables>;

/**
 * __useDeleteTaxTypeMutation__
 *
 * To run a mutation, you first call `useDeleteTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxTypeMutation, { data, loading, error }] = useDeleteTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaxTypeMutation, DeleteTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaxTypeMutation, DeleteTaxTypeMutationVariables>(DeleteTaxTypeDocument, baseOptions);
      }
export type DeleteTaxTypeMutationHookResult = ReturnType<typeof useDeleteTaxTypeMutation>;
export type DeleteTaxTypeMutationResult = ApolloReactCommon.MutationResult<DeleteTaxTypeMutation>;
export type DeleteTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaxTypeMutation, DeleteTaxTypeMutationVariables>;
export const GetTaxSchemeDocument = gql`
    query getTaxScheme($TaxTypeID: String, $TaxSchemeID: String, $orderByAsc: String, $AccTaxClass: String, $RecordStatus: RecordStatus) {
  getTaxScheme(TaxTypeID: $TaxTypeID, TaxSchemeID: $TaxSchemeID, AccTaxClass: $AccTaxClass, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    createdTs
    Code
    Description
    AccTaxCategory
    TaxTypeID
    AccTaxClass
    TaxSchemeID
    MasterCOAID
    RecordStatus
    LatestTax {
      Rate
      Date
    }
    TaxEffective {
      Date
      Rate
      createdTs
      createdBy
    }
    TaxType {
      Code
    }
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeQuery({
 *   variables: {
 *      TaxTypeID: // value for 'TaxTypeID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      orderByAsc: // value for 'orderByAsc'
 *      AccTaxClass: // value for 'AccTaxClass'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
      }
export function useGetTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
        }
export type GetTaxSchemeQueryHookResult = ReturnType<typeof useGetTaxSchemeQuery>;
export type GetTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeLazyQuery>;
export type GetTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>;
export const GetTaxSchemeListingDocument = gql`
    query getTaxSchemeListing($TaxTypeID: String, $TaxSchemeID: String, $orderByAsc: String, $AccTaxClass: String, $RecordStatus: RecordStatus) {
  getTaxScheme(TaxTypeID: $TaxTypeID, TaxSchemeID: $TaxSchemeID, AccTaxClass: $AccTaxClass, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    createdTs
    Code
    Description
    AccTaxCategory
    TaxTypeID
    AccTaxClass
    TaxSchemeID
    MasterCOAID
    RecordStatus
    ReceiptItem {
      ReceiptItemID
    }
    PaymentItem {
      PaymentItemID
    }
    LatestTax {
      Rate
      Date
    }
    TaxEffective {
      Date
      Rate
      createdTs
      createdBy
    }
    TaxType {
      Code
    }
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetTaxSchemeListingQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeListingQuery({
 *   variables: {
 *      TaxTypeID: // value for 'TaxTypeID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      orderByAsc: // value for 'orderByAsc'
 *      AccTaxClass: // value for 'AccTaxClass'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetTaxSchemeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>(GetTaxSchemeListingDocument, baseOptions);
      }
export function useGetTaxSchemeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>(GetTaxSchemeListingDocument, baseOptions);
        }
export type GetTaxSchemeListingQueryHookResult = ReturnType<typeof useGetTaxSchemeListingQuery>;
export type GetTaxSchemeListingLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeListingLazyQuery>;
export type GetTaxSchemeListingQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>;
export const CreateTaxSchemeDocument = gql`
    mutation createTaxScheme($input: TaxSchemeInput!) {
  createTaxScheme(input: $input) {
    TaxSchemeID
    MasterCOAID
  }
}
    `;
export type CreateTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<CreateTaxSchemeMutation, CreateTaxSchemeMutationVariables>;

/**
 * __useCreateTaxSchemeMutation__
 *
 * To run a mutation, you first call `useCreateTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxSchemeMutation, { data, loading, error }] = useCreateTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaxSchemeMutation, CreateTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaxSchemeMutation, CreateTaxSchemeMutationVariables>(CreateTaxSchemeDocument, baseOptions);
      }
export type CreateTaxSchemeMutationHookResult = ReturnType<typeof useCreateTaxSchemeMutation>;
export type CreateTaxSchemeMutationResult = ApolloReactCommon.MutationResult<CreateTaxSchemeMutation>;
export type CreateTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaxSchemeMutation, CreateTaxSchemeMutationVariables>;
export const UpdateTaxSchemeDocument = gql`
    mutation updateTaxScheme($input: TaxSchemeInput!) {
  updateTaxScheme(input: $input) {
    TaxSchemeID
    MasterCOAID
  }
}
    `;
export type UpdateTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<UpdateTaxSchemeMutation, UpdateTaxSchemeMutationVariables>;

/**
 * __useUpdateTaxSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxSchemeMutation, { data, loading, error }] = useUpdateTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaxSchemeMutation, UpdateTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaxSchemeMutation, UpdateTaxSchemeMutationVariables>(UpdateTaxSchemeDocument, baseOptions);
      }
export type UpdateTaxSchemeMutationHookResult = ReturnType<typeof useUpdateTaxSchemeMutation>;
export type UpdateTaxSchemeMutationResult = ApolloReactCommon.MutationResult<UpdateTaxSchemeMutation>;
export type UpdateTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaxSchemeMutation, UpdateTaxSchemeMutationVariables>;
export const DeleteTaxSchemeDocument = gql`
    mutation deleteTaxScheme($TaxSchemeID: String!) {
  deleteTaxScheme(TaxSchemeID: $TaxSchemeID)
}
    `;
export type DeleteTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>;

/**
 * __useDeleteTaxSchemeMutation__
 *
 * To run a mutation, you first call `useDeleteTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxSchemeMutation, { data, loading, error }] = useDeleteTaxSchemeMutation({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *   },
 * });
 */
export function useDeleteTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>(DeleteTaxSchemeDocument, baseOptions);
      }
export type DeleteTaxSchemeMutationHookResult = ReturnType<typeof useDeleteTaxSchemeMutation>;
export type DeleteTaxSchemeMutationResult = ApolloReactCommon.MutationResult<DeleteTaxSchemeMutation>;
export type DeleteTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>;
export const CreateTaxEffectiveDateDocument = gql`
    mutation createTaxEffectiveDate($input: TaxEffectiveDateInput!) {
  createTaxEffectiveDate(input: $input) {
    TaxEffectiveDateID
  }
}
    `;
export type CreateTaxEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<CreateTaxEffectiveDateMutation, CreateTaxEffectiveDateMutationVariables>;

/**
 * __useCreateTaxEffectiveDateMutation__
 *
 * To run a mutation, you first call `useCreateTaxEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxEffectiveDateMutation, { data, loading, error }] = useCreateTaxEffectiveDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaxEffectiveDateMutation, CreateTaxEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaxEffectiveDateMutation, CreateTaxEffectiveDateMutationVariables>(CreateTaxEffectiveDateDocument, baseOptions);
      }
export type CreateTaxEffectiveDateMutationHookResult = ReturnType<typeof useCreateTaxEffectiveDateMutation>;
export type CreateTaxEffectiveDateMutationResult = ApolloReactCommon.MutationResult<CreateTaxEffectiveDateMutation>;
export type CreateTaxEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaxEffectiveDateMutation, CreateTaxEffectiveDateMutationVariables>;
export const GetTaxSchemeCodeDocument = gql`
    query getTaxSchemeCode($TaxTypeID: String, $TaxSchemeID: String, $RecordStatus: RecordStatus) {
  getTaxScheme(TaxTypeID: $TaxTypeID, TaxSchemeID: $TaxSchemeID, RecordStatus: $RecordStatus) {
    TaxSchemeID
    Code
    AccTaxClass
    LatestTax {
      Rate
      Date
    }
    TaxEffective {
      Date
      Rate
    }
  }
}
    `;

/**
 * __useGetTaxSchemeCodeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeCodeQuery({
 *   variables: {
 *      TaxTypeID: // value for 'TaxTypeID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetTaxSchemeCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>(GetTaxSchemeCodeDocument, baseOptions);
      }
export function useGetTaxSchemeCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>(GetTaxSchemeCodeDocument, baseOptions);
        }
export type GetTaxSchemeCodeQueryHookResult = ReturnType<typeof useGetTaxSchemeCodeQuery>;
export type GetTaxSchemeCodeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeCodeLazyQuery>;
export type GetTaxSchemeCodeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>;
export const UpdateTaxEffectiveDateDocument = gql`
    mutation updateTaxEffectiveDate($input: TaxEffectiveDateInput!) {
  updateTaxEffectiveDate(input: $input) {
    TaxEffectiveDateID
  }
}
    `;
export type UpdateTaxEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<UpdateTaxEffectiveDateMutation, UpdateTaxEffectiveDateMutationVariables>;

/**
 * __useUpdateTaxEffectiveDateMutation__
 *
 * To run a mutation, you first call `useUpdateTaxEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxEffectiveDateMutation, { data, loading, error }] = useUpdateTaxEffectiveDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaxEffectiveDateMutation, UpdateTaxEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaxEffectiveDateMutation, UpdateTaxEffectiveDateMutationVariables>(UpdateTaxEffectiveDateDocument, baseOptions);
      }
export type UpdateTaxEffectiveDateMutationHookResult = ReturnType<typeof useUpdateTaxEffectiveDateMutation>;
export type UpdateTaxEffectiveDateMutationResult = ApolloReactCommon.MutationResult<UpdateTaxEffectiveDateMutation>;
export type UpdateTaxEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaxEffectiveDateMutation, UpdateTaxEffectiveDateMutationVariables>;
export const GetTaxEffectiveDateDocument = gql`
    query getTaxEffectiveDate($TaxEffectiveDateID: String, $TaxSchemeID: String) {
  getTaxEffectiveDate(TaxEffectiveDateID: $TaxEffectiveDateID, TaxSchemeID: $TaxSchemeID) {
    TaxEffectiveDateID
    TaxSchemeID
    Date
    Rate
  }
}
    `;

/**
 * __useGetTaxEffectiveDateQuery__
 *
 * To run a query within a React component, call `useGetTaxEffectiveDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxEffectiveDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxEffectiveDateQuery({
 *   variables: {
 *      TaxEffectiveDateID: // value for 'TaxEffectiveDateID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *   },
 * });
 */
export function useGetTaxEffectiveDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxEffectiveDateQuery, GetTaxEffectiveDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxEffectiveDateQuery, GetTaxEffectiveDateQueryVariables>(GetTaxEffectiveDateDocument, baseOptions);
      }
export function useGetTaxEffectiveDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxEffectiveDateQuery, GetTaxEffectiveDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxEffectiveDateQuery, GetTaxEffectiveDateQueryVariables>(GetTaxEffectiveDateDocument, baseOptions);
        }
export type GetTaxEffectiveDateQueryHookResult = ReturnType<typeof useGetTaxEffectiveDateQuery>;
export type GetTaxEffectiveDateLazyQueryHookResult = ReturnType<typeof useGetTaxEffectiveDateLazyQuery>;
export type GetTaxEffectiveDateQueryResult = ApolloReactCommon.QueryResult<GetTaxEffectiveDateQuery, GetTaxEffectiveDateQueryVariables>;
export const DeleteTaxEffectiveDateDocument = gql`
    mutation deleteTaxEffectiveDate($input: TaxEffectiveDateInput!) {
  deleteTaxEffectiveDate(input: $input)
}
    `;
export type DeleteTaxEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<DeleteTaxEffectiveDateMutation, DeleteTaxEffectiveDateMutationVariables>;

/**
 * __useDeleteTaxEffectiveDateMutation__
 *
 * To run a mutation, you first call `useDeleteTaxEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxEffectiveDateMutation, { data, loading, error }] = useDeleteTaxEffectiveDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaxEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaxEffectiveDateMutation, DeleteTaxEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaxEffectiveDateMutation, DeleteTaxEffectiveDateMutationVariables>(DeleteTaxEffectiveDateDocument, baseOptions);
      }
export type DeleteTaxEffectiveDateMutationHookResult = ReturnType<typeof useDeleteTaxEffectiveDateMutation>;
export type DeleteTaxEffectiveDateMutationResult = ApolloReactCommon.MutationResult<DeleteTaxEffectiveDateMutation>;
export type DeleteTaxEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaxEffectiveDateMutation, DeleteTaxEffectiveDateMutationVariables>;
export const InsertTaxDocument = gql`
    mutation insertTax($TaxEffectiveInput: [TaxEffectiveInput!]!, $TaxSchemeInput: TaxSchemeInput!) {
  insertTax(TaxEffectiveInput: $TaxEffectiveInput, TaxSchemeInput: $TaxSchemeInput) {
    TaxSchemeID
  }
}
    `;
export type InsertTaxMutationFn = ApolloReactCommon.MutationFunction<InsertTaxMutation, InsertTaxMutationVariables>;

/**
 * __useInsertTaxMutation__
 *
 * To run a mutation, you first call `useInsertTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTaxMutation, { data, loading, error }] = useInsertTaxMutation({
 *   variables: {
 *      TaxEffectiveInput: // value for 'TaxEffectiveInput'
 *      TaxSchemeInput: // value for 'TaxSchemeInput'
 *   },
 * });
 */
export function useInsertTaxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTaxMutation, InsertTaxMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTaxMutation, InsertTaxMutationVariables>(InsertTaxDocument, baseOptions);
      }
export type InsertTaxMutationHookResult = ReturnType<typeof useInsertTaxMutation>;
export type InsertTaxMutationResult = ApolloReactCommon.MutationResult<InsertTaxMutation>;
export type InsertTaxMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTaxMutation, InsertTaxMutationVariables>;
export const UpdateTaxDocument = gql`
    mutation updateTax($TaxEffectiveInput: [TaxEffectiveInput!]!, $TaxSchemeInput: TaxSchemeInput!) {
  updateTax(TaxEffectiveInput: $TaxEffectiveInput, TaxSchemeInput: $TaxSchemeInput)
}
    `;
export type UpdateTaxMutationFn = ApolloReactCommon.MutationFunction<UpdateTaxMutation, UpdateTaxMutationVariables>;

/**
 * __useUpdateTaxMutation__
 *
 * To run a mutation, you first call `useUpdateTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxMutation, { data, loading, error }] = useUpdateTaxMutation({
 *   variables: {
 *      TaxEffectiveInput: // value for 'TaxEffectiveInput'
 *      TaxSchemeInput: // value for 'TaxSchemeInput'
 *   },
 * });
 */
export function useUpdateTaxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaxMutation, UpdateTaxMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaxMutation, UpdateTaxMutationVariables>(UpdateTaxDocument, baseOptions);
      }
export type UpdateTaxMutationHookResult = ReturnType<typeof useUpdateTaxMutation>;
export type UpdateTaxMutationResult = ApolloReactCommon.MutationResult<UpdateTaxMutation>;
export type UpdateTaxMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaxMutation, UpdateTaxMutationVariables>;
export const GetTransactionTypeDocument = gql`
    query getTransactionType($TransactionTypeID: String, $IsSystem: Boolean, $orderByAsc: String, $RecordStatus: String) {
  getTransactionType(TransactionTypeID: $TransactionTypeID, IsSystem: $IsSystem, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    AccountID
    TransactionTypeID
    Name
    Type
    MasterCOAID
    MasterCOA {
      Name
      Code
    }
    IsSystem
    RecordStatus
  }
}
    `;

/**
 * __useGetTransactionTypeQuery__
 *
 * To run a query within a React component, call `useGetTransactionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionTypeQuery({
 *   variables: {
 *      TransactionTypeID: // value for 'TransactionTypeID'
 *      IsSystem: // value for 'IsSystem'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetTransactionTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTransactionTypeQuery, GetTransactionTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTransactionTypeQuery, GetTransactionTypeQueryVariables>(GetTransactionTypeDocument, baseOptions);
      }
export function useGetTransactionTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTransactionTypeQuery, GetTransactionTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTransactionTypeQuery, GetTransactionTypeQueryVariables>(GetTransactionTypeDocument, baseOptions);
        }
export type GetTransactionTypeQueryHookResult = ReturnType<typeof useGetTransactionTypeQuery>;
export type GetTransactionTypeLazyQueryHookResult = ReturnType<typeof useGetTransactionTypeLazyQuery>;
export type GetTransactionTypeQueryResult = ApolloReactCommon.QueryResult<GetTransactionTypeQuery, GetTransactionTypeQueryVariables>;
export const GetTransactionTypeListingDocument = gql`
    query getTransactionTypeListing($TransactionTypeID: String, $IsSystem: Boolean, $orderByAsc: String, $RecordStatus: String, $searchValue: String, $skip: Float, $take: Float) {
  getTransactionTypePerm(TransactionTypeID: $TransactionTypeID, IsSystem: $IsSystem, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus, searchValue: $searchValue, skip: $skip, take: $take) {
    AccountID
    TransactionTypeID
    Name
    Type
    MasterCOAID
    MasterCOA {
      Name
      Code
    }
    IsSystem
    RecordStatus
  }
  getTransactionTypeCount(TransactionTypeID: $TransactionTypeID, IsSystem: $IsSystem, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus)
}
    `;

/**
 * __useGetTransactionTypeListingQuery__
 *
 * To run a query within a React component, call `useGetTransactionTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionTypeListingQuery({
 *   variables: {
 *      TransactionTypeID: // value for 'TransactionTypeID'
 *      IsSystem: // value for 'IsSystem'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetTransactionTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTransactionTypeListingQuery, GetTransactionTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTransactionTypeListingQuery, GetTransactionTypeListingQueryVariables>(GetTransactionTypeListingDocument, baseOptions);
      }
export function useGetTransactionTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTransactionTypeListingQuery, GetTransactionTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTransactionTypeListingQuery, GetTransactionTypeListingQueryVariables>(GetTransactionTypeListingDocument, baseOptions);
        }
export type GetTransactionTypeListingQueryHookResult = ReturnType<typeof useGetTransactionTypeListingQuery>;
export type GetTransactionTypeListingLazyQueryHookResult = ReturnType<typeof useGetTransactionTypeListingLazyQuery>;
export type GetTransactionTypeListingQueryResult = ApolloReactCommon.QueryResult<GetTransactionTypeListingQuery, GetTransactionTypeListingQueryVariables>;
export const CreateTransactionTypeDocument = gql`
    mutation createTransactionType($input: TransactionTypeInput!) {
  createTransactionType(input: $input) {
    TransactionTypeID
  }
}
    `;
export type CreateTransactionTypeMutationFn = ApolloReactCommon.MutationFunction<CreateTransactionTypeMutation, CreateTransactionTypeMutationVariables>;

/**
 * __useCreateTransactionTypeMutation__
 *
 * To run a mutation, you first call `useCreateTransactionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionTypeMutation, { data, loading, error }] = useCreateTransactionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransactionTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTransactionTypeMutation, CreateTransactionTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTransactionTypeMutation, CreateTransactionTypeMutationVariables>(CreateTransactionTypeDocument, baseOptions);
      }
export type CreateTransactionTypeMutationHookResult = ReturnType<typeof useCreateTransactionTypeMutation>;
export type CreateTransactionTypeMutationResult = ApolloReactCommon.MutationResult<CreateTransactionTypeMutation>;
export type CreateTransactionTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTransactionTypeMutation, CreateTransactionTypeMutationVariables>;
export const UpdateTransactionTypeDocument = gql`
    mutation updateTransactionType($input: TransactionTypeInput!) {
  updateTransactionType(input: $input) {
    TransactionTypeID
  }
}
    `;
export type UpdateTransactionTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateTransactionTypeMutation, UpdateTransactionTypeMutationVariables>;

/**
 * __useUpdateTransactionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionTypeMutation, { data, loading, error }] = useUpdateTransactionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransactionTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTransactionTypeMutation, UpdateTransactionTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTransactionTypeMutation, UpdateTransactionTypeMutationVariables>(UpdateTransactionTypeDocument, baseOptions);
      }
export type UpdateTransactionTypeMutationHookResult = ReturnType<typeof useUpdateTransactionTypeMutation>;
export type UpdateTransactionTypeMutationResult = ApolloReactCommon.MutationResult<UpdateTransactionTypeMutation>;
export type UpdateTransactionTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTransactionTypeMutation, UpdateTransactionTypeMutationVariables>;
export const DeleteTransactionTypeDocument = gql`
    mutation DeleteTransactionType($input: TransactionTypeDeleteInput!) {
  deleteTransactionType(input: $input)
}
    `;
export type DeleteTransactionTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteTransactionTypeMutation, DeleteTransactionTypeMutationVariables>;

/**
 * __useDeleteTransactionTypeMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionTypeMutation, { data, loading, error }] = useDeleteTransactionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTransactionTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTransactionTypeMutation, DeleteTransactionTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTransactionTypeMutation, DeleteTransactionTypeMutationVariables>(DeleteTransactionTypeDocument, baseOptions);
      }
export type DeleteTransactionTypeMutationHookResult = ReturnType<typeof useDeleteTransactionTypeMutation>;
export type DeleteTransactionTypeMutationResult = ApolloReactCommon.MutationResult<DeleteTransactionTypeMutation>;
export type DeleteTransactionTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTransactionTypeMutation, DeleteTransactionTypeMutationVariables>;
export const GetUomCodeDocument = gql`
    query getUOMCode($UOMID: String, $orderByAsc: String, $orderByDesc: String, $Name: String, $Code: String) {
  getUOM(UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, Name: $Name, Code: $Code) {
    UOMID
    Name
    Code
  }
}
    `;

/**
 * __useGetUomCodeQuery__
 *
 * To run a query within a React component, call `useGetUomCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomCodeQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      Name: // value for 'Name'
 *      Code: // value for 'Code'
 *   },
 * });
 */
export function useGetUomCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomCodeQuery, GetUomCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomCodeQuery, GetUomCodeQueryVariables>(GetUomCodeDocument, baseOptions);
      }
export function useGetUomCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomCodeQuery, GetUomCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomCodeQuery, GetUomCodeQueryVariables>(GetUomCodeDocument, baseOptions);
        }
export type GetUomCodeQueryHookResult = ReturnType<typeof useGetUomCodeQuery>;
export type GetUomCodeLazyQueryHookResult = ReturnType<typeof useGetUomCodeLazyQuery>;
export type GetUomCodeQueryResult = ApolloReactCommon.QueryResult<GetUomCodeQuery, GetUomCodeQueryVariables>;
export const GetUomDocument = gql`
    query getUOM($UOMID: String, $orderByAsc: String, $orderByDesc: String, $Name: String, $Code: String) {
  getUOM(UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, Name: $Name, Code: $Code) {
    UOMID
    Name
    Code
    RecordStatus
    ExpenseItem {
      ExpenseItemID
    }
    APInvoiceItem {
      InvoiceItemID
    }
    APDebitNoteItem {
      DebitNoteItemID
    }
    APFavoriteInvoiceItem {
      InvoiceItemID
    }
    StockItem {
      StockItemID
    }
    StockReceiptItem {
      StockReceiptItemID
    }
    StockIssueItem {
      StockIssueItemID
    }
    StockTransferItem {
      StockTransferItemID
    }
    CycleCountItem {
      CycleCountItemID
    }
    StockAdjusmentItem {
      StockAdjustmentItemID
    }
  }
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      Name: // value for 'Name'
 *      Code: // value for 'Code'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const GetUomListingDocument = gql`
    query getUOMListing($UOMID: String, $orderByAsc: String, $orderByDesc: String, $Name: String, $Code: String, $skip: Float, $take: Float, $searchValue: String) {
  getUOMPerm(UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, Name: $Name, Code: $Code, skip: $skip, take: $take, searchValue: $searchValue) {
    UOMID
    Name
    Code
    RecordStatus
    ExpenseItem {
      ExpenseItemID
    }
    APInvoiceItem {
      InvoiceItemID
    }
    APDebitNoteItem {
      DebitNoteItemID
    }
    APFavoriteInvoiceItem {
      InvoiceItemID
    }
    StockItem {
      StockItemID
    }
    StockReceiptItem {
      StockReceiptItemID
    }
    StockIssueItem {
      StockIssueItemID
    }
    StockTransferItem {
      StockTransferItemID
    }
    CycleCountItem {
      CycleCountItemID
    }
    StockAdjusmentItem {
      StockAdjustmentItemID
    }
  }
  getUOMCount(UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, Name: $Name, Code: $Code)
}
    `;

/**
 * __useGetUomListingQuery__
 *
 * To run a query within a React component, call `useGetUomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomListingQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      Name: // value for 'Name'
 *      Code: // value for 'Code'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetUomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomListingQuery, GetUomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomListingQuery, GetUomListingQueryVariables>(GetUomListingDocument, baseOptions);
      }
export function useGetUomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomListingQuery, GetUomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomListingQuery, GetUomListingQueryVariables>(GetUomListingDocument, baseOptions);
        }
export type GetUomListingQueryHookResult = ReturnType<typeof useGetUomListingQuery>;
export type GetUomListingLazyQueryHookResult = ReturnType<typeof useGetUomListingLazyQuery>;
export type GetUomListingQueryResult = ApolloReactCommon.QueryResult<GetUomListingQuery, GetUomListingQueryVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    UOMID
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    UOMID
    Name
    Code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($input: UOMDeleteInput!) {
  deleteUOM(input: $input)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const GetUomExchangeDocument = gql`
    query getUOMExchange($FromUomID: String, $ToUomID: String, $orderByAsc: String, $orderByDesc: String) {
  getUOMExchange(FromUomID: $FromUomID, ToUomID: $ToUomID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    FromUomID
    ToUomID
    FromAmt
    ToAmt
    ExchangeRate
    UomLog
  }
}
    `;

/**
 * __useGetUomExchangeQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeQuery({
 *   variables: {
 *      FromUomID: // value for 'FromUomID'
 *      ToUomID: // value for 'ToUomID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetUomExchangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
      }
export function useGetUomExchangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
        }
export type GetUomExchangeQueryHookResult = ReturnType<typeof useGetUomExchangeQuery>;
export type GetUomExchangeLazyQueryHookResult = ReturnType<typeof useGetUomExchangeLazyQuery>;
export type GetUomExchangeQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeQuery, GetUomExchangeQueryVariables>;
export const GetUomExchangeListingDocument = gql`
    query getUOMExchangeListing($FromUomID: String, $ToUomID: String, $orderByAsc: String, $orderByDesc: String, $skip: Float, $take: Float, $searchValue: String) {
  getUOMExchangePerm(FromUomID: $FromUomID, ToUomID: $ToUomID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, searchValue: $searchValue) {
    ID
    FromUomID
    ToUomID
    FromAmt
    ToAmt
    ExchangeRate
    UomLog
  }
  getUOMExchangeCount(FromUomID: $FromUomID, ToUomID: $ToUomID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc)
}
    `;

/**
 * __useGetUomExchangeListingQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListingQuery({
 *   variables: {
 *      FromUomID: // value for 'FromUomID'
 *      ToUomID: // value for 'ToUomID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetUomExchangeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListingQuery, GetUomExchangeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListingQuery, GetUomExchangeListingQueryVariables>(GetUomExchangeListingDocument, baseOptions);
      }
export function useGetUomExchangeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListingQuery, GetUomExchangeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListingQuery, GetUomExchangeListingQueryVariables>(GetUomExchangeListingDocument, baseOptions);
        }
export type GetUomExchangeListingQueryHookResult = ReturnType<typeof useGetUomExchangeListingQuery>;
export type GetUomExchangeListingLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListingLazyQuery>;
export type GetUomExchangeListingQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListingQuery, GetUomExchangeListingQueryVariables>;
export const GetUomExchangeDataDocument = gql`
    query getUomExchangeData {
  getUomExchangeData
}
    `;

/**
 * __useGetUomExchangeDataQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUomExchangeDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
      }
export function useGetUomExchangeDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
        }
export type GetUomExchangeDataQueryHookResult = ReturnType<typeof useGetUomExchangeDataQuery>;
export type GetUomExchangeDataLazyQueryHookResult = ReturnType<typeof useGetUomExchangeDataLazyQuery>;
export type GetUomExchangeDataQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>;
export const GetUomExchangeDataListingDocument = gql`
    query getUomExchangeDataListing($skip: Float, $take: Float, $searchValue: String) {
  getUomExchangeDataListing(skip: $skip, take: $take, searchValue: $searchValue) {
    Data
    TotalLength
  }
}
    `;

/**
 * __useGetUomExchangeDataListingQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeDataListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeDataListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeDataListingQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetUomExchangeDataListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeDataListingQuery, GetUomExchangeDataListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeDataListingQuery, GetUomExchangeDataListingQueryVariables>(GetUomExchangeDataListingDocument, baseOptions);
      }
export function useGetUomExchangeDataListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeDataListingQuery, GetUomExchangeDataListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeDataListingQuery, GetUomExchangeDataListingQueryVariables>(GetUomExchangeDataListingDocument, baseOptions);
        }
export type GetUomExchangeDataListingQueryHookResult = ReturnType<typeof useGetUomExchangeDataListingQuery>;
export type GetUomExchangeDataListingLazyQueryHookResult = ReturnType<typeof useGetUomExchangeDataListingLazyQuery>;
export type GetUomExchangeDataListingQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeDataListingQuery, GetUomExchangeDataListingQueryVariables>;
export const GetUomExchangeListDocument = gql`
    query getUomExchangeList($UOMID: String!) {
  getUOMExchangeList(UOMID: $UOMID)
}
    `;

/**
 * __useGetUomExchangeListQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *   },
 * });
 */
export function useGetUomExchangeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
      }
export function useGetUomExchangeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
        }
export type GetUomExchangeListQueryHookResult = ReturnType<typeof useGetUomExchangeListQuery>;
export type GetUomExchangeListLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListLazyQuery>;
export type GetUomExchangeListQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>;
export const CreateUomExchangeDocument = gql`
    mutation createUOMExchange($input: UomExchangeInput!) {
  createUOMExchange(input: $input)
}
    `;
export type CreateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;

/**
 * __useCreateUomExchangeMutation__
 *
 * To run a mutation, you first call `useCreateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomExchangeMutation, { data, loading, error }] = useCreateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>(CreateUomExchangeDocument, baseOptions);
      }
export type CreateUomExchangeMutationHookResult = ReturnType<typeof useCreateUomExchangeMutation>;
export type CreateUomExchangeMutationResult = ApolloReactCommon.MutationResult<CreateUomExchangeMutation>;
export type CreateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;
export const UpdateUomExchangeDocument = gql`
    mutation updateUOMExchange($input: UomExchangeInput!) {
  updateUOMExchange(input: $input)
}
    `;
export type UpdateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;

/**
 * __useUpdateUomExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomExchangeMutation, { data, loading, error }] = useUpdateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>(UpdateUomExchangeDocument, baseOptions);
      }
export type UpdateUomExchangeMutationHookResult = ReturnType<typeof useUpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationResult = ApolloReactCommon.MutationResult<UpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;
export const DeleteUomExchangeDocument = gql`
    mutation deleteUOMExchange($ID: String!) {
  deleteUOMExchange(ID: $ID)
}
    `;
export type DeleteUomExchangeMutationFn = ApolloReactCommon.MutationFunction<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;

/**
 * __useDeleteUomExchangeMutation__
 *
 * To run a mutation, you first call `useDeleteUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomExchangeMutation, { data, loading, error }] = useDeleteUomExchangeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>(DeleteUomExchangeDocument, baseOptions);
      }
export type DeleteUomExchangeMutationHookResult = ReturnType<typeof useDeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationResult = ApolloReactCommon.MutationResult<DeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
    roleDetail {
      RoleID
      Name
    }
    companyAssigned {
      Name
      CompanyID
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware($superUserBool: UserSelection, $status: CommonStatus) {
  getUsersByAccountAndSoftware(superUserBool: $superUserBool, status: $status) {
    ID
    name
    department
    email
    contactNo
    confirmed
    superUser
    commonStatus
    jobTitle
    userName
    blockCount
    roleDetail {
      RoleID
      Name
    }
    companyAssigned {
      CompanyID
      Name
    }
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const GetUsersByAccountAndSoftwareListingDocument = gql`
    query getUsersByAccountAndSoftwareListing($superUserBool: UserSelection, $status: CommonStatus, $skip: Float, $take: Float, $searchValue: String) {
  getUsersByAccountAndSoftwareListing(superUserBool: $superUserBool, status: $status, skip: $skip, take: $take, searchValue: $searchValue) {
    Data {
      ID
      name
      department
      email
      contactNo
      confirmed
      superUser
      commonStatus
      jobTitle
      userName
      blockCount
      roleDetail {
        RoleID
        Name
      }
      companyAssigned {
        CompanyID
        Name
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareListingQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareListingQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareListingQuery, GetUsersByAccountAndSoftwareListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareListingQuery, GetUsersByAccountAndSoftwareListingQueryVariables>(GetUsersByAccountAndSoftwareListingDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareListingQuery, GetUsersByAccountAndSoftwareListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareListingQuery, GetUsersByAccountAndSoftwareListingQueryVariables>(GetUsersByAccountAndSoftwareListingDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareListingQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareListingQuery>;
export type GetUsersByAccountAndSoftwareListingLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareListingLazyQuery>;
export type GetUsersByAccountAndSoftwareListingQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareListingQuery, GetUsersByAccountAndSoftwareListingQueryVariables>;
export const GetActiveUsersCountDocument = gql`
    query getActiveUsersCount {
  getActiveUsersCount
}
    `;

/**
 * __useGetActiveUsersCountQuery__
 *
 * To run a query within a React component, call `useGetActiveUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUsersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveUsersCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveUsersCountQuery, GetActiveUsersCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveUsersCountQuery, GetActiveUsersCountQueryVariables>(GetActiveUsersCountDocument, baseOptions);
      }
export function useGetActiveUsersCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveUsersCountQuery, GetActiveUsersCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveUsersCountQuery, GetActiveUsersCountQueryVariables>(GetActiveUsersCountDocument, baseOptions);
        }
export type GetActiveUsersCountQueryHookResult = ReturnType<typeof useGetActiveUsersCountQuery>;
export type GetActiveUsersCountLazyQueryHookResult = ReturnType<typeof useGetActiveUsersCountLazyQuery>;
export type GetActiveUsersCountQueryResult = ApolloReactCommon.QueryResult<GetActiveUsersCountQuery, GetActiveUsersCountQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!, $roleID: String) {
  createUser(input: $input, roleID: $roleID) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!, $roleID: String) {
  updateUser(input: $input, roleID: $roleID) {
    ID
    email
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AssignCompanyDocument = gql`
    mutation assignCompany($input: EntityRoleUserAsgInput!) {
  AssignEntityRoleUser(input: $input)
}
    `;
export type AssignCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignCompanyMutation, AssignCompanyMutationVariables>;

/**
 * __useAssignCompanyMutation__
 *
 * To run a mutation, you first call `useAssignCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCompanyMutation, { data, loading, error }] = useAssignCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCompanyMutation, AssignCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCompanyMutation, AssignCompanyMutationVariables>(AssignCompanyDocument, baseOptions);
      }
export type AssignCompanyMutationHookResult = ReturnType<typeof useAssignCompanyMutation>;
export type AssignCompanyMutationResult = ApolloReactCommon.MutationResult<AssignCompanyMutation>;
export type AssignCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCompanyMutation, AssignCompanyMutationVariables>;
export const UnassignCompanyDocument = gql`
    mutation unassignCompany($input: EntityRoleUserAsgInput!) {
  UnassignEntityRoleUser(input: $input)
}
    `;
export type UnassignCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignCompanyMutation, UnassignCompanyMutationVariables>;

/**
 * __useUnassignCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCompanyMutation, { data, loading, error }] = useUnassignCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCompanyMutation, UnassignCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCompanyMutation, UnassignCompanyMutationVariables>(UnassignCompanyDocument, baseOptions);
      }
export type UnassignCompanyMutationHookResult = ReturnType<typeof useUnassignCompanyMutation>;
export type UnassignCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignCompanyMutation>;
export type UnassignCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCompanyMutation, UnassignCompanyMutationVariables>;
export const ChangePasswordForUserDocument = gql`
    mutation changePasswordForUser($userID: String!, $newPassword: String!) {
  changePasswordForUser(userID: $userID, newPassword: $newPassword)
}
    `;
export type ChangePasswordForUserMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>;

/**
 * __useChangePasswordForUserMutation__
 *
 * To run a mutation, you first call `useChangePasswordForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordForUserMutation, { data, loading, error }] = useChangePasswordForUserMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordForUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>(ChangePasswordForUserDocument, baseOptions);
      }
export type ChangePasswordForUserMutationHookResult = ReturnType<typeof useChangePasswordForUserMutation>;
export type ChangePasswordForUserMutationResult = ApolloReactCommon.MutationResult<ChangePasswordForUserMutation>;
export type ChangePasswordForUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordForUserMutation, ChangePasswordForUserMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($userID: String!) {
  resendInvitation(userID: $userID)
}
    `;
export type ResendInvitationMutationFn = ApolloReactCommon.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, baseOptions);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = ApolloReactCommon.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const ToggleUserActivationDocument = gql`
    mutation toggleUserActivation($userID: String!) {
  toggleUserActivation(userID: $userID)
}
    `;
export type ToggleUserActivationMutationFn = ApolloReactCommon.MutationFunction<ToggleUserActivationMutation, ToggleUserActivationMutationVariables>;

/**
 * __useToggleUserActivationMutation__
 *
 * To run a mutation, you first call `useToggleUserActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserActivationMutation, { data, loading, error }] = useToggleUserActivationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useToggleUserActivationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleUserActivationMutation, ToggleUserActivationMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleUserActivationMutation, ToggleUserActivationMutationVariables>(ToggleUserActivationDocument, baseOptions);
      }
export type ToggleUserActivationMutationHookResult = ReturnType<typeof useToggleUserActivationMutation>;
export type ToggleUserActivationMutationResult = ApolloReactCommon.MutationResult<ToggleUserActivationMutation>;
export type ToggleUserActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleUserActivationMutation, ToggleUserActivationMutationVariables>;
export const CreateUpdateUserActivityLogDocument = gql`
    mutation createUpdateUserActivityLog($LastAccessedModule: String, $UserAction: String!) {
  createUpdateUserActivityLog(LastAccessedModule: $LastAccessedModule, UserAction: $UserAction)
}
    `;
export type CreateUpdateUserActivityLogMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateUserActivityLogMutation, CreateUpdateUserActivityLogMutationVariables>;

/**
 * __useCreateUpdateUserActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateUpdateUserActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateUserActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateUserActivityLogMutation, { data, loading, error }] = useCreateUpdateUserActivityLogMutation({
 *   variables: {
 *      LastAccessedModule: // value for 'LastAccessedModule'
 *      UserAction: // value for 'UserAction'
 *   },
 * });
 */
export function useCreateUpdateUserActivityLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateUserActivityLogMutation, CreateUpdateUserActivityLogMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateUserActivityLogMutation, CreateUpdateUserActivityLogMutationVariables>(CreateUpdateUserActivityLogDocument, baseOptions);
      }
export type CreateUpdateUserActivityLogMutationHookResult = ReturnType<typeof useCreateUpdateUserActivityLogMutation>;
export type CreateUpdateUserActivityLogMutationResult = ApolloReactCommon.MutationResult<CreateUpdateUserActivityLogMutation>;
export type CreateUpdateUserActivityLogMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateUserActivityLogMutation, CreateUpdateUserActivityLogMutationVariables>;
export const GetUserRoleDocument = gql`
    query getUserRole($UserID: String) {
  getUserRole(UserID: $UserID) {
    UserRoleID
    RoleID
    UserID
  }
}
    `;

/**
 * __useGetUserRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useGetUserRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, baseOptions);
      }
export function useGetUserRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, baseOptions);
        }
export type GetUserRoleQueryHookResult = ReturnType<typeof useGetUserRoleQuery>;
export type GetUserRoleLazyQueryHookResult = ReturnType<typeof useGetUserRoleLazyQuery>;
export type GetUserRoleQueryResult = ApolloReactCommon.QueryResult<GetUserRoleQuery, GetUserRoleQueryVariables>;
export const GetCompanyAssignedUsersByRoleDocument = gql`
    query getCompanyAssignedUsersByRole($accountID: String!, $CompanyID: String!) {
  getCompanyAssignedUsersByRole(accountID: $accountID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCompanyAssignedUsersByRoleQuery__
 *
 * To run a query within a React component, call `useGetCompanyAssignedUsersByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAssignedUsersByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAssignedUsersByRoleQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanyAssignedUsersByRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyAssignedUsersByRoleQuery, GetCompanyAssignedUsersByRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyAssignedUsersByRoleQuery, GetCompanyAssignedUsersByRoleQueryVariables>(GetCompanyAssignedUsersByRoleDocument, baseOptions);
      }
export function useGetCompanyAssignedUsersByRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAssignedUsersByRoleQuery, GetCompanyAssignedUsersByRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyAssignedUsersByRoleQuery, GetCompanyAssignedUsersByRoleQueryVariables>(GetCompanyAssignedUsersByRoleDocument, baseOptions);
        }
export type GetCompanyAssignedUsersByRoleQueryHookResult = ReturnType<typeof useGetCompanyAssignedUsersByRoleQuery>;
export type GetCompanyAssignedUsersByRoleLazyQueryHookResult = ReturnType<typeof useGetCompanyAssignedUsersByRoleLazyQuery>;
export type GetCompanyAssignedUsersByRoleQueryResult = ApolloReactCommon.QueryResult<GetCompanyAssignedUsersByRoleQuery, GetCompanyAssignedUsersByRoleQueryVariables>;
export const GetCompanyAssignedUsersByRoleListingDocument = gql`
    query getCompanyAssignedUsersByRoleListing($accountID: String!, $CompanyID: String!, $skip: Float, $take: Float, $searchValue: String) {
  getCompanyAssignedUsersByRoleListing(accountID: $accountID, CompanyID: $CompanyID, skip: $skip, take: $take, searchValue: $searchValue) {
    Data
    TotalLength
  }
}
    `;

/**
 * __useGetCompanyAssignedUsersByRoleListingQuery__
 *
 * To run a query within a React component, call `useGetCompanyAssignedUsersByRoleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAssignedUsersByRoleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAssignedUsersByRoleListingQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      CompanyID: // value for 'CompanyID'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCompanyAssignedUsersByRoleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyAssignedUsersByRoleListingQuery, GetCompanyAssignedUsersByRoleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyAssignedUsersByRoleListingQuery, GetCompanyAssignedUsersByRoleListingQueryVariables>(GetCompanyAssignedUsersByRoleListingDocument, baseOptions);
      }
export function useGetCompanyAssignedUsersByRoleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAssignedUsersByRoleListingQuery, GetCompanyAssignedUsersByRoleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyAssignedUsersByRoleListingQuery, GetCompanyAssignedUsersByRoleListingQueryVariables>(GetCompanyAssignedUsersByRoleListingDocument, baseOptions);
        }
export type GetCompanyAssignedUsersByRoleListingQueryHookResult = ReturnType<typeof useGetCompanyAssignedUsersByRoleListingQuery>;
export type GetCompanyAssignedUsersByRoleListingLazyQueryHookResult = ReturnType<typeof useGetCompanyAssignedUsersByRoleListingLazyQuery>;
export type GetCompanyAssignedUsersByRoleListingQueryResult = ApolloReactCommon.QueryResult<GetCompanyAssignedUsersByRoleListingQuery, GetCompanyAssignedUsersByRoleListingQueryVariables>;
export const GetUserCompanyAssignedUsersByRoleDocument = gql`
    query getUserCompanyAssignedUsersByRole($CompanyID: String!, $ID: String) {
  getUserCompanyAssignedUsersByRole(CompanyID: $CompanyID, ID: $ID)
}
    `;

/**
 * __useGetUserCompanyAssignedUsersByRoleQuery__
 *
 * To run a query within a React component, call `useGetUserCompanyAssignedUsersByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCompanyAssignedUsersByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCompanyAssignedUsersByRoleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserCompanyAssignedUsersByRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserCompanyAssignedUsersByRoleQuery, GetUserCompanyAssignedUsersByRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserCompanyAssignedUsersByRoleQuery, GetUserCompanyAssignedUsersByRoleQueryVariables>(GetUserCompanyAssignedUsersByRoleDocument, baseOptions);
      }
export function useGetUserCompanyAssignedUsersByRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserCompanyAssignedUsersByRoleQuery, GetUserCompanyAssignedUsersByRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserCompanyAssignedUsersByRoleQuery, GetUserCompanyAssignedUsersByRoleQueryVariables>(GetUserCompanyAssignedUsersByRoleDocument, baseOptions);
        }
export type GetUserCompanyAssignedUsersByRoleQueryHookResult = ReturnType<typeof useGetUserCompanyAssignedUsersByRoleQuery>;
export type GetUserCompanyAssignedUsersByRoleLazyQueryHookResult = ReturnType<typeof useGetUserCompanyAssignedUsersByRoleLazyQuery>;
export type GetUserCompanyAssignedUsersByRoleQueryResult = ApolloReactCommon.QueryResult<GetUserCompanyAssignedUsersByRoleQuery, GetUserCompanyAssignedUsersByRoleQueryVariables>;
export const GetRoleNameByUserDocument = gql`
    query getRoleNameByUser($ID: String!) {
  getRoleNameByUser(ID: $ID)
}
    `;

/**
 * __useGetRoleNameByUserQuery__
 *
 * To run a query within a React component, call `useGetRoleNameByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleNameByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleNameByUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleNameByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleNameByUserQuery, GetRoleNameByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleNameByUserQuery, GetRoleNameByUserQueryVariables>(GetRoleNameByUserDocument, baseOptions);
      }
export function useGetRoleNameByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleNameByUserQuery, GetRoleNameByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleNameByUserQuery, GetRoleNameByUserQueryVariables>(GetRoleNameByUserDocument, baseOptions);
        }
export type GetRoleNameByUserQueryHookResult = ReturnType<typeof useGetRoleNameByUserQuery>;
export type GetRoleNameByUserLazyQueryHookResult = ReturnType<typeof useGetRoleNameByUserLazyQuery>;
export type GetRoleNameByUserQueryResult = ApolloReactCommon.QueryResult<GetRoleNameByUserQuery, GetRoleNameByUserQueryVariables>;
export const GetUserByRoleAssignedDocument = gql`
    query getUserByRoleAssigned($RoleID: String!) {
  getUserByRoleAssigned(RoleID: $RoleID)
}
    `;

/**
 * __useGetUserByRoleAssignedQuery__
 *
 * To run a query within a React component, call `useGetUserByRoleAssignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByRoleAssignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByRoleAssignedQuery({
 *   variables: {
 *      RoleID: // value for 'RoleID'
 *   },
 * });
 */
export function useGetUserByRoleAssignedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByRoleAssignedQuery, GetUserByRoleAssignedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserByRoleAssignedQuery, GetUserByRoleAssignedQueryVariables>(GetUserByRoleAssignedDocument, baseOptions);
      }
export function useGetUserByRoleAssignedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserByRoleAssignedQuery, GetUserByRoleAssignedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserByRoleAssignedQuery, GetUserByRoleAssignedQueryVariables>(GetUserByRoleAssignedDocument, baseOptions);
        }
export type GetUserByRoleAssignedQueryHookResult = ReturnType<typeof useGetUserByRoleAssignedQuery>;
export type GetUserByRoleAssignedLazyQueryHookResult = ReturnType<typeof useGetUserByRoleAssignedLazyQuery>;
export type GetUserByRoleAssignedQueryResult = ApolloReactCommon.QueryResult<GetUserByRoleAssignedQuery, GetUserByRoleAssignedQueryVariables>;
export const GetWarehouseDocument = gql`
    query getWarehouse($WarehouseID: String, $orderByAsc: String, $orderByDesc: String, $CompanyID: String) {
  getWarehouse(WarehouseID: $WarehouseID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, CompanyID: $CompanyID) {
    WarehouseID
    Code
    Name
    Address
    ContactNo
    Designation
    Email
    CompanyID
    CostCentreID
    UsedWarehouse
    StockReceipt {
      StockReceiptID
    }
    StockIssue {
      StockIssueID
    }
    StockAdjustment {
      StockAdjustmentID
    }
    FromStockTransfer {
      StockTransferID
    }
    CycleCount {
      CycleCountID
    }
    Company {
      Name
    }
    CostCentre {
      Name
    }
    Attachment {
      fileURL
      mediaType
      name
    }
    userAssigned {
      Name
      UserID
    }
    stockItemAssigned {
      StockItemID
      Name
    }
  }
}
    `;

/**
 * __useGetWarehouseQuery__
 *
 * To run a query within a React component, call `useGetWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, baseOptions);
      }
export function useGetWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, baseOptions);
        }
export type GetWarehouseQueryHookResult = ReturnType<typeof useGetWarehouseQuery>;
export type GetWarehouseLazyQueryHookResult = ReturnType<typeof useGetWarehouseLazyQuery>;
export type GetWarehouseQueryResult = ApolloReactCommon.QueryResult<GetWarehouseQuery, GetWarehouseQueryVariables>;
export const GetWarehouseListingDocument = gql`
    query getWarehouseListing($AccountID: String, $CompanyID: String, $orderByAsc: String, $orderByDesc: String, $skip: Float, $take: Float, $searchValue: String) {
  getWarehouse(AccountID: $AccountID, CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, searchValue: $searchValue) {
    WarehouseID
    Code
    Name
    Address
    ContactNo
    Designation
    Email
    CompanyID
    CostCentreID
    UsedWarehouse
    StockReceipt {
      StockReceiptID
    }
    StockIssue {
      StockIssueID
    }
    StockAdjustment {
      StockAdjustmentID
    }
    FromStockTransfer {
      StockTransferID
    }
    CycleCount {
      CycleCountID
    }
    Company {
      Name
    }
    CostCentre {
      Name
    }
    Attachment {
      fileURL
      mediaType
      name
    }
  }
  getWarehouseCount(AccountID: $AccountID, CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc)
}
    `;

/**
 * __useGetWarehouseListingQuery__
 *
 * To run a query within a React component, call `useGetWarehouseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetWarehouseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseListingQuery, GetWarehouseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseListingQuery, GetWarehouseListingQueryVariables>(GetWarehouseListingDocument, baseOptions);
      }
export function useGetWarehouseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseListingQuery, GetWarehouseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseListingQuery, GetWarehouseListingQueryVariables>(GetWarehouseListingDocument, baseOptions);
        }
export type GetWarehouseListingQueryHookResult = ReturnType<typeof useGetWarehouseListingQuery>;
export type GetWarehouseListingLazyQueryHookResult = ReturnType<typeof useGetWarehouseListingLazyQuery>;
export type GetWarehouseListingQueryResult = ApolloReactCommon.QueryResult<GetWarehouseListingQuery, GetWarehouseListingQueryVariables>;
export const GetWarehouseNameDocument = gql`
    query getWarehouseName($WarehouseID: String, $orderByAsc: String) {
  getWarehouse(WarehouseID: $WarehouseID, orderByAsc: $orderByAsc) {
    WarehouseID
    CompanyID
    Code
    Name
  }
}
    `;

/**
 * __useGetWarehouseNameQuery__
 *
 * To run a query within a React component, call `useGetWarehouseNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseNameQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetWarehouseNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseNameQuery, GetWarehouseNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseNameQuery, GetWarehouseNameQueryVariables>(GetWarehouseNameDocument, baseOptions);
      }
export function useGetWarehouseNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseNameQuery, GetWarehouseNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseNameQuery, GetWarehouseNameQueryVariables>(GetWarehouseNameDocument, baseOptions);
        }
export type GetWarehouseNameQueryHookResult = ReturnType<typeof useGetWarehouseNameQuery>;
export type GetWarehouseNameLazyQueryHookResult = ReturnType<typeof useGetWarehouseNameLazyQuery>;
export type GetWarehouseNameQueryResult = ApolloReactCommon.QueryResult<GetWarehouseNameQuery, GetWarehouseNameQueryVariables>;
export const GetAssignedWarehouseCompanyDocument = gql`
    query getAssignedWarehouseCompany {
  getAssignedWarehouseCompanyByUser
}
    `;

/**
 * __useGetAssignedWarehouseCompanyQuery__
 *
 * To run a query within a React component, call `useGetAssignedWarehouseCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedWarehouseCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedWarehouseCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssignedWarehouseCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedWarehouseCompanyQuery, GetAssignedWarehouseCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedWarehouseCompanyQuery, GetAssignedWarehouseCompanyQueryVariables>(GetAssignedWarehouseCompanyDocument, baseOptions);
      }
export function useGetAssignedWarehouseCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedWarehouseCompanyQuery, GetAssignedWarehouseCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedWarehouseCompanyQuery, GetAssignedWarehouseCompanyQueryVariables>(GetAssignedWarehouseCompanyDocument, baseOptions);
        }
export type GetAssignedWarehouseCompanyQueryHookResult = ReturnType<typeof useGetAssignedWarehouseCompanyQuery>;
export type GetAssignedWarehouseCompanyLazyQueryHookResult = ReturnType<typeof useGetAssignedWarehouseCompanyLazyQuery>;
export type GetAssignedWarehouseCompanyQueryResult = ApolloReactCommon.QueryResult<GetAssignedWarehouseCompanyQuery, GetAssignedWarehouseCompanyQueryVariables>;
export const GetCompanyWarehouseDocument = gql`
    query getCompanyWarehouse {
  getCompanyWarehouse
}
    `;

/**
 * __useGetCompanyWarehouseQuery__
 *
 * To run a query within a React component, call `useGetCompanyWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWarehouseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyWarehouseQuery, GetCompanyWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyWarehouseQuery, GetCompanyWarehouseQueryVariables>(GetCompanyWarehouseDocument, baseOptions);
      }
export function useGetCompanyWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyWarehouseQuery, GetCompanyWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyWarehouseQuery, GetCompanyWarehouseQueryVariables>(GetCompanyWarehouseDocument, baseOptions);
        }
export type GetCompanyWarehouseQueryHookResult = ReturnType<typeof useGetCompanyWarehouseQuery>;
export type GetCompanyWarehouseLazyQueryHookResult = ReturnType<typeof useGetCompanyWarehouseLazyQuery>;
export type GetCompanyWarehouseQueryResult = ApolloReactCommon.QueryResult<GetCompanyWarehouseQuery, GetCompanyWarehouseQueryVariables>;
export const CreateWarehouseDocument = gql`
    mutation createWarehouse($WarehouseInput: WarehouseInput!) {
  createWarehouse(WarehouseInput: $WarehouseInput)
}
    `;
export type CreateWarehouseMutationFn = ApolloReactCommon.MutationFunction<CreateWarehouseMutation, CreateWarehouseMutationVariables>;

/**
 * __useCreateWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseMutation, { data, loading, error }] = useCreateWarehouseMutation({
 *   variables: {
 *      WarehouseInput: // value for 'WarehouseInput'
 *   },
 * });
 */
export function useCreateWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWarehouseMutation, CreateWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>(CreateWarehouseDocument, baseOptions);
      }
export type CreateWarehouseMutationHookResult = ReturnType<typeof useCreateWarehouseMutation>;
export type CreateWarehouseMutationResult = ApolloReactCommon.MutationResult<CreateWarehouseMutation>;
export type CreateWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWarehouseMutation, CreateWarehouseMutationVariables>;
export const UpdateWarehouseDocument = gql`
    mutation updateWarehouse($WarehouseInput: WarehouseInput!, $WarehouseID: String!) {
  updateWarehouse(WarehouseInput: $WarehouseInput, WarehouseID: $WarehouseID)
}
    `;
export type UpdateWarehouseMutationFn = ApolloReactCommon.MutationFunction<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>;

/**
 * __useUpdateWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseMutation, { data, loading, error }] = useUpdateWarehouseMutation({
 *   variables: {
 *      WarehouseInput: // value for 'WarehouseInput'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useUpdateWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>(UpdateWarehouseDocument, baseOptions);
      }
export type UpdateWarehouseMutationHookResult = ReturnType<typeof useUpdateWarehouseMutation>;
export type UpdateWarehouseMutationResult = ApolloReactCommon.MutationResult<UpdateWarehouseMutation>;
export type UpdateWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>;
export const DeleteWarehouseDocument = gql`
    mutation deleteWarehouse($input: WarehouseDeleteInput!) {
  DeleteWarehouse(input: $input)
}
    `;
export type DeleteWarehouseMutationFn = ApolloReactCommon.MutationFunction<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>;

/**
 * __useDeleteWarehouseMutation__
 *
 * To run a mutation, you first call `useDeleteWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehouseMutation, { data, loading, error }] = useDeleteWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>(DeleteWarehouseDocument, baseOptions);
      }
export type DeleteWarehouseMutationHookResult = ReturnType<typeof useDeleteWarehouseMutation>;
export type DeleteWarehouseMutationResult = ApolloReactCommon.MutationResult<DeleteWarehouseMutation>;
export type DeleteWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>;
export const GetAssignedUserByCompanyDocument = gql`
    query getAssignedUserByCompany($CompanyID: String!) {
  getAssignedUserByCompany(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetAssignedUserByCompanyQuery__
 *
 * To run a query within a React component, call `useGetAssignedUserByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedUserByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedUserByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAssignedUserByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedUserByCompanyQuery, GetAssignedUserByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedUserByCompanyQuery, GetAssignedUserByCompanyQueryVariables>(GetAssignedUserByCompanyDocument, baseOptions);
      }
export function useGetAssignedUserByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedUserByCompanyQuery, GetAssignedUserByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedUserByCompanyQuery, GetAssignedUserByCompanyQueryVariables>(GetAssignedUserByCompanyDocument, baseOptions);
        }
export type GetAssignedUserByCompanyQueryHookResult = ReturnType<typeof useGetAssignedUserByCompanyQuery>;
export type GetAssignedUserByCompanyLazyQueryHookResult = ReturnType<typeof useGetAssignedUserByCompanyLazyQuery>;
export type GetAssignedUserByCompanyQueryResult = ApolloReactCommon.QueryResult<GetAssignedUserByCompanyQuery, GetAssignedUserByCompanyQueryVariables>;
export const AssignWarehouseDocument = gql`
    mutation assignWarehouse($input: WarehouseUserAssignmentInput!) {
  AssignWarehouseUser(input: $input)
}
    `;
export type AssignWarehouseMutationFn = ApolloReactCommon.MutationFunction<AssignWarehouseMutation, AssignWarehouseMutationVariables>;

/**
 * __useAssignWarehouseMutation__
 *
 * To run a mutation, you first call `useAssignWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignWarehouseMutation, { data, loading, error }] = useAssignWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignWarehouseMutation, AssignWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignWarehouseMutation, AssignWarehouseMutationVariables>(AssignWarehouseDocument, baseOptions);
      }
export type AssignWarehouseMutationHookResult = ReturnType<typeof useAssignWarehouseMutation>;
export type AssignWarehouseMutationResult = ApolloReactCommon.MutationResult<AssignWarehouseMutation>;
export type AssignWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignWarehouseMutation, AssignWarehouseMutationVariables>;
export const UnassignWarehouseDocument = gql`
    mutation unassignWarehouse($input: WarehouseUserAssignmentInput!) {
  UnassignUserWarehouse(input: $input)
}
    `;
export type UnassignWarehouseMutationFn = ApolloReactCommon.MutationFunction<UnassignWarehouseMutation, UnassignWarehouseMutationVariables>;

/**
 * __useUnassignWarehouseMutation__
 *
 * To run a mutation, you first call `useUnassignWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignWarehouseMutation, { data, loading, error }] = useUnassignWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignWarehouseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignWarehouseMutation, UnassignWarehouseMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignWarehouseMutation, UnassignWarehouseMutationVariables>(UnassignWarehouseDocument, baseOptions);
      }
export type UnassignWarehouseMutationHookResult = ReturnType<typeof useUnassignWarehouseMutation>;
export type UnassignWarehouseMutationResult = ApolloReactCommon.MutationResult<UnassignWarehouseMutation>;
export type UnassignWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignWarehouseMutation, UnassignWarehouseMutationVariables>;
export const AssignWarehouseItemDocument = gql`
    mutation assignWarehouseItem($input: WarehouseItemAssignmentInput!) {
  AssignWarehouseItem(input: $input)
}
    `;
export type AssignWarehouseItemMutationFn = ApolloReactCommon.MutationFunction<AssignWarehouseItemMutation, AssignWarehouseItemMutationVariables>;

/**
 * __useAssignWarehouseItemMutation__
 *
 * To run a mutation, you first call `useAssignWarehouseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignWarehouseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignWarehouseItemMutation, { data, loading, error }] = useAssignWarehouseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignWarehouseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignWarehouseItemMutation, AssignWarehouseItemMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignWarehouseItemMutation, AssignWarehouseItemMutationVariables>(AssignWarehouseItemDocument, baseOptions);
      }
export type AssignWarehouseItemMutationHookResult = ReturnType<typeof useAssignWarehouseItemMutation>;
export type AssignWarehouseItemMutationResult = ApolloReactCommon.MutationResult<AssignWarehouseItemMutation>;
export type AssignWarehouseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignWarehouseItemMutation, AssignWarehouseItemMutationVariables>;
export const UnassignWarehouseItemDocument = gql`
    mutation unassignWarehouseItem($input: WarehouseItemAssignmentInput!) {
  UnassignWarehouseItem(input: $input)
}
    `;
export type UnassignWarehouseItemMutationFn = ApolloReactCommon.MutationFunction<UnassignWarehouseItemMutation, UnassignWarehouseItemMutationVariables>;

/**
 * __useUnassignWarehouseItemMutation__
 *
 * To run a mutation, you first call `useUnassignWarehouseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignWarehouseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignWarehouseItemMutation, { data, loading, error }] = useUnassignWarehouseItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignWarehouseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignWarehouseItemMutation, UnassignWarehouseItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignWarehouseItemMutation, UnassignWarehouseItemMutationVariables>(UnassignWarehouseItemDocument, baseOptions);
      }
export type UnassignWarehouseItemMutationHookResult = ReturnType<typeof useUnassignWarehouseItemMutation>;
export type UnassignWarehouseItemMutationResult = ApolloReactCommon.MutationResult<UnassignWarehouseItemMutation>;
export type UnassignWarehouseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignWarehouseItemMutation, UnassignWarehouseItemMutationVariables>;
export const GetStockCategoryItemAssignmentDocument = gql`
    query getStockCategoryItemAssignment($mode: String!, $WarehouseID: String!) {
  getStockCategoryItemAssignment(mode: $mode, WarehouseID: $WarehouseID)
}
    `;

/**
 * __useGetStockCategoryItemAssignmentQuery__
 *
 * To run a query within a React component, call `useGetStockCategoryItemAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCategoryItemAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCategoryItemAssignmentQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockCategoryItemAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCategoryItemAssignmentQuery, GetStockCategoryItemAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCategoryItemAssignmentQuery, GetStockCategoryItemAssignmentQueryVariables>(GetStockCategoryItemAssignmentDocument, baseOptions);
      }
export function useGetStockCategoryItemAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCategoryItemAssignmentQuery, GetStockCategoryItemAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCategoryItemAssignmentQuery, GetStockCategoryItemAssignmentQueryVariables>(GetStockCategoryItemAssignmentDocument, baseOptions);
        }
export type GetStockCategoryItemAssignmentQueryHookResult = ReturnType<typeof useGetStockCategoryItemAssignmentQuery>;
export type GetStockCategoryItemAssignmentLazyQueryHookResult = ReturnType<typeof useGetStockCategoryItemAssignmentLazyQuery>;
export type GetStockCategoryItemAssignmentQueryResult = ApolloReactCommon.QueryResult<GetStockCategoryItemAssignmentQuery, GetStockCategoryItemAssignmentQueryVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary($CompanyID: String) {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    userName
    department
    superUser
    jobTitle
    lastestAccessedEntity
    roleDetail {
      Name
      RoleID
    }
  }
  getRolePermission
  getAccountPermission
  getCompany(CompanyID: $CompanyID) {
    CompanyID
    Name
    Logo
    eInvIntegrated
  }
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;
export const GetCycleCountDocument = gql`
    query getCycleCount($CycleCountID: String, $CompanyID: String, $WarehouseID: String) {
  getCycleCount(CycleCountID: $CycleCountID, CompanyID: $CompanyID, WarehouseID: $WarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    CycleCountID
    CostCentreID
    CostCentre {
      Name
    }
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    WarehouseID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    CycleCountItem {
      CycleCountItemID
      WarehouseID
      StockItemID
      TransactionTypeID
      StockItem {
        Name
        StockItemWarehouse {
          StockItemWarehouseID
          StockQty
          SubmitStockQty
        }
        StockCategory {
          Name
        }
        StockWeightedAvg {
          UnitCost
          WarehouseID
        }
      }
      StockItemWarehouse {
        StockItemWarehouseID
        StockQty
        SubmitStockQty
        StockAvgUnitCost
      }
      UOMID
      UOM {
        Name
        Code
      }
      OnHandQty
      CountQty
      AdjustQty
      ReasonID
      Reason {
        StockAdjustmentReasonID
        Reason
      }
    }
  }
}
    `;

/**
 * __useGetCycleCountQuery__
 *
 * To run a query within a React component, call `useGetCycleCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleCountQuery({
 *   variables: {
 *      CycleCountID: // value for 'CycleCountID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetCycleCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCycleCountQuery, GetCycleCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCycleCountQuery, GetCycleCountQueryVariables>(GetCycleCountDocument, baseOptions);
      }
export function useGetCycleCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCycleCountQuery, GetCycleCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCycleCountQuery, GetCycleCountQueryVariables>(GetCycleCountDocument, baseOptions);
        }
export type GetCycleCountQueryHookResult = ReturnType<typeof useGetCycleCountQuery>;
export type GetCycleCountLazyQueryHookResult = ReturnType<typeof useGetCycleCountLazyQuery>;
export type GetCycleCountQueryResult = ApolloReactCommon.QueryResult<GetCycleCountQuery, GetCycleCountQueryVariables>;
export const GetCycleCountItemDocument = gql`
    query getCycleCountItem($CycleCountID: String, $CompanyID: String, $WarehouseID: String, $IsReconciled: Boolean, $DocDate: String) {
  getCycleCountItem(CycleCountID: $CycleCountID, IsReconciled: $IsReconciled, DocDate: $DocDate) {
    CycleCountItemID
    ReasonID
    CycleCountID
    UOMID
    WarehouseID
    StockItemID
    StockItem {
      Name
      CurrentQty(CompanyID: $CompanyID, WarehouseID: $WarehouseID, DocDate: $DocDate)
      UOM {
        Name
        Code
      }
      StockCategory {
        Name
      }
    }
    Reason {
      StockAdjustmentReasonID
      Reason
    }
    OnHandQty
    CountQty
    AdjustQty
    TransactionTypeID
  }
}
    `;

/**
 * __useGetCycleCountItemQuery__
 *
 * To run a query within a React component, call `useGetCycleCountItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleCountItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleCountItemQuery({
 *   variables: {
 *      CycleCountID: // value for 'CycleCountID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *      IsReconciled: // value for 'IsReconciled'
 *      DocDate: // value for 'DocDate'
 *   },
 * });
 */
export function useGetCycleCountItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCycleCountItemQuery, GetCycleCountItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCycleCountItemQuery, GetCycleCountItemQueryVariables>(GetCycleCountItemDocument, baseOptions);
      }
export function useGetCycleCountItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCycleCountItemQuery, GetCycleCountItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCycleCountItemQuery, GetCycleCountItemQueryVariables>(GetCycleCountItemDocument, baseOptions);
        }
export type GetCycleCountItemQueryHookResult = ReturnType<typeof useGetCycleCountItemQuery>;
export type GetCycleCountItemLazyQueryHookResult = ReturnType<typeof useGetCycleCountItemLazyQuery>;
export type GetCycleCountItemQueryResult = ApolloReactCommon.QueryResult<GetCycleCountItemQuery, GetCycleCountItemQueryVariables>;
export const GetCycleCountbyStatusDocument = gql`
    query getCycleCountbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getCycleCountbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    CycleCountID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    WarehouseID
    CostCentreID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    CycleCountItem {
      CycleCountItemID
      StockItemID
      StockItem {
        Name
      }
      UOMID
      UOM {
        Name
      }
      OnHandQty
      CountQty
      AdjustQty
      ReasonID
      Reason {
        StockAdjustmentReasonID
        Reason
      }
      StockItemWarehouse {
        StockItemWarehouseID
        StockQty
      }
    }
  }
}
    `;

/**
 * __useGetCycleCountbyStatusQuery__
 *
 * To run a query within a React component, call `useGetCycleCountbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleCountbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleCountbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetCycleCountbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCycleCountbyStatusQuery, GetCycleCountbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCycleCountbyStatusQuery, GetCycleCountbyStatusQueryVariables>(GetCycleCountbyStatusDocument, baseOptions);
      }
export function useGetCycleCountbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCycleCountbyStatusQuery, GetCycleCountbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCycleCountbyStatusQuery, GetCycleCountbyStatusQueryVariables>(GetCycleCountbyStatusDocument, baseOptions);
        }
export type GetCycleCountbyStatusQueryHookResult = ReturnType<typeof useGetCycleCountbyStatusQuery>;
export type GetCycleCountbyStatusLazyQueryHookResult = ReturnType<typeof useGetCycleCountbyStatusLazyQuery>;
export type GetCycleCountbyStatusQueryResult = ApolloReactCommon.QueryResult<GetCycleCountbyStatusQuery, GetCycleCountbyStatusQueryVariables>;
export const GetCycleCountListingDocument = gql`
    query getCycleCountListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getCycleCountListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      CycleCountID
      CompanyID
      DocNo
      DocDate
      TransactionDate
      RefNo
      WarehouseID
      CostCentreID
      Warehouse {
        Name
      }
      Description
      ApprovalStatus
      CycleCountItem {
        CycleCountItemID
        StockItemID
        StockItem {
          Name
        }
        UOMID
        UOM {
          Name
        }
        OnHandQty
        CountQty
        AdjustQty
        ReasonID
        Reason {
          StockAdjustmentReasonID
          Reason
        }
        StockItemWarehouse {
          StockItemWarehouseID
          StockQty
        }
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetCycleCountListingQuery__
 *
 * To run a query within a React component, call `useGetCycleCountListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleCountListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleCountListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCycleCountListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCycleCountListingQuery, GetCycleCountListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCycleCountListingQuery, GetCycleCountListingQueryVariables>(GetCycleCountListingDocument, baseOptions);
      }
export function useGetCycleCountListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCycleCountListingQuery, GetCycleCountListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCycleCountListingQuery, GetCycleCountListingQueryVariables>(GetCycleCountListingDocument, baseOptions);
        }
export type GetCycleCountListingQueryHookResult = ReturnType<typeof useGetCycleCountListingQuery>;
export type GetCycleCountListingLazyQueryHookResult = ReturnType<typeof useGetCycleCountListingLazyQuery>;
export type GetCycleCountListingQueryResult = ApolloReactCommon.QueryResult<GetCycleCountListingQuery, GetCycleCountListingQueryVariables>;
export const CreateCycleCountDocument = gql`
    mutation createCycleCount($input: CycleCountInput!, $itemInput: [CycleCountItemInput!]) {
  createCycleCount(input: $input, itemInput: $itemInput) {
    CycleCountID
  }
}
    `;
export type CreateCycleCountMutationFn = ApolloReactCommon.MutationFunction<CreateCycleCountMutation, CreateCycleCountMutationVariables>;

/**
 * __useCreateCycleCountMutation__
 *
 * To run a mutation, you first call `useCreateCycleCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCycleCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCycleCountMutation, { data, loading, error }] = useCreateCycleCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateCycleCountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCycleCountMutation, CreateCycleCountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCycleCountMutation, CreateCycleCountMutationVariables>(CreateCycleCountDocument, baseOptions);
      }
export type CreateCycleCountMutationHookResult = ReturnType<typeof useCreateCycleCountMutation>;
export type CreateCycleCountMutationResult = ApolloReactCommon.MutationResult<CreateCycleCountMutation>;
export type CreateCycleCountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCycleCountMutation, CreateCycleCountMutationVariables>;
export const CreateCycleCountItemDocument = gql`
    mutation createCycleCountItem($input: CycleCountItemInput!) {
  createCycleCountItem(input: $input) {
    CycleCountID
  }
}
    `;
export type CreateCycleCountItemMutationFn = ApolloReactCommon.MutationFunction<CreateCycleCountItemMutation, CreateCycleCountItemMutationVariables>;

/**
 * __useCreateCycleCountItemMutation__
 *
 * To run a mutation, you first call `useCreateCycleCountItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCycleCountItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCycleCountItemMutation, { data, loading, error }] = useCreateCycleCountItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCycleCountItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCycleCountItemMutation, CreateCycleCountItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCycleCountItemMutation, CreateCycleCountItemMutationVariables>(CreateCycleCountItemDocument, baseOptions);
      }
export type CreateCycleCountItemMutationHookResult = ReturnType<typeof useCreateCycleCountItemMutation>;
export type CreateCycleCountItemMutationResult = ApolloReactCommon.MutationResult<CreateCycleCountItemMutation>;
export type CreateCycleCountItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCycleCountItemMutation, CreateCycleCountItemMutationVariables>;
export const PostCycleCountDocument = gql`
    mutation postCycleCount($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: CycleCountRejectInput!) {
  postCycleCount(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostCycleCountMutationFn = ApolloReactCommon.MutationFunction<PostCycleCountMutation, PostCycleCountMutationVariables>;

/**
 * __usePostCycleCountMutation__
 *
 * To run a mutation, you first call `usePostCycleCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCycleCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCycleCountMutation, { data, loading, error }] = usePostCycleCountMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCycleCountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCycleCountMutation, PostCycleCountMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCycleCountMutation, PostCycleCountMutationVariables>(PostCycleCountDocument, baseOptions);
      }
export type PostCycleCountMutationHookResult = ReturnType<typeof usePostCycleCountMutation>;
export type PostCycleCountMutationResult = ApolloReactCommon.MutationResult<PostCycleCountMutation>;
export type PostCycleCountMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCycleCountMutation, PostCycleCountMutationVariables>;
export const UpdateCycleCountDocument = gql`
    mutation updateCycleCount($input: CycleCountInput!, $itemInput: [CycleCountItemInput!]) {
  updateCycleCount(input: $input, itemInput: $itemInput) {
    CycleCountID
  }
}
    `;
export type UpdateCycleCountMutationFn = ApolloReactCommon.MutationFunction<UpdateCycleCountMutation, UpdateCycleCountMutationVariables>;

/**
 * __useUpdateCycleCountMutation__
 *
 * To run a mutation, you first call `useUpdateCycleCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCycleCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCycleCountMutation, { data, loading, error }] = useUpdateCycleCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateCycleCountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCycleCountMutation, UpdateCycleCountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCycleCountMutation, UpdateCycleCountMutationVariables>(UpdateCycleCountDocument, baseOptions);
      }
export type UpdateCycleCountMutationHookResult = ReturnType<typeof useUpdateCycleCountMutation>;
export type UpdateCycleCountMutationResult = ApolloReactCommon.MutationResult<UpdateCycleCountMutation>;
export type UpdateCycleCountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCycleCountMutation, UpdateCycleCountMutationVariables>;
export const UpdateCycleCountItemDocument = gql`
    mutation updateCycleCountItem($input: CycleCountItemInput!) {
  updateCycleCountItem(input: $input) {
    CycleCountItemID
    StockItemID
    WarehouseID
    ReasonID
    CountQty
    OnHandQty
    AdjustQty
    Reason {
      StockAdjustmentReasonID
      Reason
    }
  }
}
    `;
export type UpdateCycleCountItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCycleCountItemMutation, UpdateCycleCountItemMutationVariables>;

/**
 * __useUpdateCycleCountItemMutation__
 *
 * To run a mutation, you first call `useUpdateCycleCountItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCycleCountItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCycleCountItemMutation, { data, loading, error }] = useUpdateCycleCountItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCycleCountItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCycleCountItemMutation, UpdateCycleCountItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCycleCountItemMutation, UpdateCycleCountItemMutationVariables>(UpdateCycleCountItemDocument, baseOptions);
      }
export type UpdateCycleCountItemMutationHookResult = ReturnType<typeof useUpdateCycleCountItemMutation>;
export type UpdateCycleCountItemMutationResult = ApolloReactCommon.MutationResult<UpdateCycleCountItemMutation>;
export type UpdateCycleCountItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCycleCountItemMutation, UpdateCycleCountItemMutationVariables>;
export const DeleteCycleCountDocument = gql`
    mutation deleteCycleCount($CycleCountID: String!) {
  deleteCycleCount(CycleCountID: $CycleCountID)
}
    `;
export type DeleteCycleCountMutationFn = ApolloReactCommon.MutationFunction<DeleteCycleCountMutation, DeleteCycleCountMutationVariables>;

/**
 * __useDeleteCycleCountMutation__
 *
 * To run a mutation, you first call `useDeleteCycleCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCycleCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCycleCountMutation, { data, loading, error }] = useDeleteCycleCountMutation({
 *   variables: {
 *      CycleCountID: // value for 'CycleCountID'
 *   },
 * });
 */
export function useDeleteCycleCountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCycleCountMutation, DeleteCycleCountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCycleCountMutation, DeleteCycleCountMutationVariables>(DeleteCycleCountDocument, baseOptions);
      }
export type DeleteCycleCountMutationHookResult = ReturnType<typeof useDeleteCycleCountMutation>;
export type DeleteCycleCountMutationResult = ApolloReactCommon.MutationResult<DeleteCycleCountMutation>;
export type DeleteCycleCountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCycleCountMutation, DeleteCycleCountMutationVariables>;
export const GetStockItemFromWarehouseDocument = gql`
    query getStockItemFromWarehouse($WarehouseID: String) {
  getStockItemFromWarehouse(WarehouseID: $WarehouseID)
}
    `;

/**
 * __useGetStockItemFromWarehouseQuery__
 *
 * To run a query within a React component, call `useGetStockItemFromWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemFromWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemFromWarehouseQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockItemFromWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemFromWarehouseQuery, GetStockItemFromWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemFromWarehouseQuery, GetStockItemFromWarehouseQueryVariables>(GetStockItemFromWarehouseDocument, baseOptions);
      }
export function useGetStockItemFromWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemFromWarehouseQuery, GetStockItemFromWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemFromWarehouseQuery, GetStockItemFromWarehouseQueryVariables>(GetStockItemFromWarehouseDocument, baseOptions);
        }
export type GetStockItemFromWarehouseQueryHookResult = ReturnType<typeof useGetStockItemFromWarehouseQuery>;
export type GetStockItemFromWarehouseLazyQueryHookResult = ReturnType<typeof useGetStockItemFromWarehouseLazyQuery>;
export type GetStockItemFromWarehouseQueryResult = ApolloReactCommon.QueryResult<GetStockItemFromWarehouseQuery, GetStockItemFromWarehouseQueryVariables>;
export const GetStockItemCycleCountDocument = gql`
    query getStockItemCycleCount($WarehouseID: String, $DocDate: DateTime) {
  getStockItemCycleCount(WarehouseID: $WarehouseID, DocDate: $DocDate)
}
    `;

/**
 * __useGetStockItemCycleCountQuery__
 *
 * To run a query within a React component, call `useGetStockItemCycleCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemCycleCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemCycleCountQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      DocDate: // value for 'DocDate'
 *   },
 * });
 */
export function useGetStockItemCycleCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemCycleCountQuery, GetStockItemCycleCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemCycleCountQuery, GetStockItemCycleCountQueryVariables>(GetStockItemCycleCountDocument, baseOptions);
      }
export function useGetStockItemCycleCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemCycleCountQuery, GetStockItemCycleCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemCycleCountQuery, GetStockItemCycleCountQueryVariables>(GetStockItemCycleCountDocument, baseOptions);
        }
export type GetStockItemCycleCountQueryHookResult = ReturnType<typeof useGetStockItemCycleCountQuery>;
export type GetStockItemCycleCountLazyQueryHookResult = ReturnType<typeof useGetStockItemCycleCountLazyQuery>;
export type GetStockItemCycleCountQueryResult = ApolloReactCommon.QueryResult<GetStockItemCycleCountQuery, GetStockItemCycleCountQueryVariables>;
export const CreateStockReconDocument = gql`
    mutation createStockRecon($input: StockReconInput!, $itemInput: [StockReconItemInput!]) {
  createStockRecon(input: $input, itemInput: $itemInput)
}
    `;
export type CreateStockReconMutationFn = ApolloReactCommon.MutationFunction<CreateStockReconMutation, CreateStockReconMutationVariables>;

/**
 * __useCreateStockReconMutation__
 *
 * To run a mutation, you first call `useCreateStockReconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockReconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockReconMutation, { data, loading, error }] = useCreateStockReconMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockReconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockReconMutation, CreateStockReconMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockReconMutation, CreateStockReconMutationVariables>(CreateStockReconDocument, baseOptions);
      }
export type CreateStockReconMutationHookResult = ReturnType<typeof useCreateStockReconMutation>;
export type CreateStockReconMutationResult = ApolloReactCommon.MutationResult<CreateStockReconMutation>;
export type CreateStockReconMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockReconMutation, CreateStockReconMutationVariables>;
export const GetLatestCycleCountDateDocument = gql`
    query getLatestCycleCountDate($WarehouseID: String!) {
  getLatestCycleCountDate(WarehouseID: $WarehouseID)
}
    `;

/**
 * __useGetLatestCycleCountDateQuery__
 *
 * To run a query within a React component, call `useGetLatestCycleCountDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestCycleCountDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestCycleCountDateQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetLatestCycleCountDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestCycleCountDateQuery, GetLatestCycleCountDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestCycleCountDateQuery, GetLatestCycleCountDateQueryVariables>(GetLatestCycleCountDateDocument, baseOptions);
      }
export function useGetLatestCycleCountDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestCycleCountDateQuery, GetLatestCycleCountDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestCycleCountDateQuery, GetLatestCycleCountDateQueryVariables>(GetLatestCycleCountDateDocument, baseOptions);
        }
export type GetLatestCycleCountDateQueryHookResult = ReturnType<typeof useGetLatestCycleCountDateQuery>;
export type GetLatestCycleCountDateLazyQueryHookResult = ReturnType<typeof useGetLatestCycleCountDateLazyQuery>;
export type GetLatestCycleCountDateQueryResult = ApolloReactCommon.QueryResult<GetLatestCycleCountDateQuery, GetLatestCycleCountDateQueryVariables>;
export const PendingIcTransactionCheckingDocument = gql`
    query pendingICTransactionChecking($WarehouseID: String!, $DocDate: DateTime) {
  pendingICTransactionChecking(WarehouseID: $WarehouseID, DocDate: $DocDate)
}
    `;

/**
 * __usePendingIcTransactionCheckingQuery__
 *
 * To run a query within a React component, call `usePendingIcTransactionCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingIcTransactionCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingIcTransactionCheckingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      DocDate: // value for 'DocDate'
 *   },
 * });
 */
export function usePendingIcTransactionCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingIcTransactionCheckingQuery, PendingIcTransactionCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingIcTransactionCheckingQuery, PendingIcTransactionCheckingQueryVariables>(PendingIcTransactionCheckingDocument, baseOptions);
      }
export function usePendingIcTransactionCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingIcTransactionCheckingQuery, PendingIcTransactionCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingIcTransactionCheckingQuery, PendingIcTransactionCheckingQueryVariables>(PendingIcTransactionCheckingDocument, baseOptions);
        }
export type PendingIcTransactionCheckingQueryHookResult = ReturnType<typeof usePendingIcTransactionCheckingQuery>;
export type PendingIcTransactionCheckingLazyQueryHookResult = ReturnType<typeof usePendingIcTransactionCheckingLazyQuery>;
export type PendingIcTransactionCheckingQueryResult = ApolloReactCommon.QueryResult<PendingIcTransactionCheckingQuery, PendingIcTransactionCheckingQueryVariables>;
export const PendingCycleCountDocument = gql`
    query pendingCycleCount($WarehouseID: String!) {
  pendingCycleCount(WarehouseID: $WarehouseID)
}
    `;

/**
 * __usePendingCycleCountQuery__
 *
 * To run a query within a React component, call `usePendingCycleCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingCycleCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingCycleCountQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function usePendingCycleCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingCycleCountQuery, PendingCycleCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingCycleCountQuery, PendingCycleCountQueryVariables>(PendingCycleCountDocument, baseOptions);
      }
export function usePendingCycleCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingCycleCountQuery, PendingCycleCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingCycleCountQuery, PendingCycleCountQueryVariables>(PendingCycleCountDocument, baseOptions);
        }
export type PendingCycleCountQueryHookResult = ReturnType<typeof usePendingCycleCountQuery>;
export type PendingCycleCountLazyQueryHookResult = ReturnType<typeof usePendingCycleCountLazyQuery>;
export type PendingCycleCountQueryResult = ApolloReactCommon.QueryResult<PendingCycleCountQuery, PendingCycleCountQueryVariables>;
export const GetFavoriteStockRequisitionDocument = gql`
    query getFavoriteStockRequisition($UserID: String, $CompanyID: String, $WarehouseID: String, $FavoriteStockRequisitionID: String) {
  getFavoriteStockRequisition(UserID: $UserID, CompanyID: $CompanyID, FavoriteStockRequisitionID: $FavoriteStockRequisitionID, WarehouseID: $WarehouseID) {
    createdBy
    FavoriteStockRequisitionID
    Name
    RefNo
    CostCentreID
    CostCentre {
      Name
    }
    Description
    FavoriteStockRequisitionItem {
      FavoriteStockRequisitionID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      UOMID
      UOM {
        Name
      }
      Quantity
      Remark
    }
  }
}
    `;

/**
 * __useGetFavoriteStockRequisitionQuery__
 *
 * To run a query within a React component, call `useGetFavoriteStockRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteStockRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteStockRequisitionQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *      FavoriteStockRequisitionID: // value for 'FavoriteStockRequisitionID'
 *   },
 * });
 */
export function useGetFavoriteStockRequisitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoriteStockRequisitionQuery, GetFavoriteStockRequisitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoriteStockRequisitionQuery, GetFavoriteStockRequisitionQueryVariables>(GetFavoriteStockRequisitionDocument, baseOptions);
      }
export function useGetFavoriteStockRequisitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoriteStockRequisitionQuery, GetFavoriteStockRequisitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoriteStockRequisitionQuery, GetFavoriteStockRequisitionQueryVariables>(GetFavoriteStockRequisitionDocument, baseOptions);
        }
export type GetFavoriteStockRequisitionQueryHookResult = ReturnType<typeof useGetFavoriteStockRequisitionQuery>;
export type GetFavoriteStockRequisitionLazyQueryHookResult = ReturnType<typeof useGetFavoriteStockRequisitionLazyQuery>;
export type GetFavoriteStockRequisitionQueryResult = ApolloReactCommon.QueryResult<GetFavoriteStockRequisitionQuery, GetFavoriteStockRequisitionQueryVariables>;
export const CreateFavoriteStockRequisitionDocument = gql`
    mutation createFavoriteStockRequisition($FavoriteStockRequisitionInput: FavoriteStockRequisitionInput!, $FavoriteStockRequisitionItemInput: [FavoriteStockRequisitionItemInput!]!) {
  createFavoriteStockRequisition(FavoriteStockRequisitionInput: $FavoriteStockRequisitionInput, FavoriteStockRequisitionItemInput: $FavoriteStockRequisitionItemInput)
}
    `;
export type CreateFavoriteStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<CreateFavoriteStockRequisitionMutation, CreateFavoriteStockRequisitionMutationVariables>;

/**
 * __useCreateFavoriteStockRequisitionMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteStockRequisitionMutation, { data, loading, error }] = useCreateFavoriteStockRequisitionMutation({
 *   variables: {
 *      FavoriteStockRequisitionInput: // value for 'FavoriteStockRequisitionInput'
 *      FavoriteStockRequisitionItemInput: // value for 'FavoriteStockRequisitionItemInput'
 *   },
 * });
 */
export function useCreateFavoriteStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFavoriteStockRequisitionMutation, CreateFavoriteStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFavoriteStockRequisitionMutation, CreateFavoriteStockRequisitionMutationVariables>(CreateFavoriteStockRequisitionDocument, baseOptions);
      }
export type CreateFavoriteStockRequisitionMutationHookResult = ReturnType<typeof useCreateFavoriteStockRequisitionMutation>;
export type CreateFavoriteStockRequisitionMutationResult = ApolloReactCommon.MutationResult<CreateFavoriteStockRequisitionMutation>;
export type CreateFavoriteStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFavoriteStockRequisitionMutation, CreateFavoriteStockRequisitionMutationVariables>;
export const DeleteFavoriteStockRequisitionDocument = gql`
    mutation deleteFavoriteStockRequisition($FavoriteStockRequisitionID: String!) {
  deleteFavoriteStockRequisition(FavoriteStockRequisitionID: $FavoriteStockRequisitionID)
}
    `;
export type DeleteFavoriteStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<DeleteFavoriteStockRequisitionMutation, DeleteFavoriteStockRequisitionMutationVariables>;

/**
 * __useDeleteFavoriteStockRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteStockRequisitionMutation, { data, loading, error }] = useDeleteFavoriteStockRequisitionMutation({
 *   variables: {
 *      FavoriteStockRequisitionID: // value for 'FavoriteStockRequisitionID'
 *   },
 * });
 */
export function useDeleteFavoriteStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFavoriteStockRequisitionMutation, DeleteFavoriteStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFavoriteStockRequisitionMutation, DeleteFavoriteStockRequisitionMutationVariables>(DeleteFavoriteStockRequisitionDocument, baseOptions);
      }
export type DeleteFavoriteStockRequisitionMutationHookResult = ReturnType<typeof useDeleteFavoriteStockRequisitionMutation>;
export type DeleteFavoriteStockRequisitionMutationResult = ApolloReactCommon.MutationResult<DeleteFavoriteStockRequisitionMutation>;
export type DeleteFavoriteStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFavoriteStockRequisitionMutation, DeleteFavoriteStockRequisitionMutationVariables>;
export const GetFavoriteStockTransferRequisitionDocument = gql`
    query getFavoriteStockTransferRequisition($UserID: String, $CompanyID: String, $FavoriteStockTransferRequisitionID: String, $FromWarehouseID: String, $ToWarehouseID: String) {
  getFavoriteStockTransferRequisition(UserID: $UserID, CompanyID: $CompanyID, FavoriteStockTransferRequisitionID: $FavoriteStockTransferRequisitionID, FromWarehouseID: $FromWarehouseID, ToWarehouseID: $ToWarehouseID) {
    createdBy
    FavoriteStockTransferRequisitionID
    Name
    RefNo
    CostCentreID
    ToWarehouseID
    CostCentre {
      Name
    }
    Description
    FavoriteStockTransferRequisitionItem {
      FavoriteStockTransferRequisitionID
      StockItemID
      StockItem {
        Name
      }
      ReasonID
      Reason {
        Reason
      }
      UOMID
      UOM {
        Name
      }
      Quantity
    }
  }
}
    `;

/**
 * __useGetFavoriteStockTransferRequisitionQuery__
 *
 * To run a query within a React component, call `useGetFavoriteStockTransferRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteStockTransferRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteStockTransferRequisitionQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      CompanyID: // value for 'CompanyID'
 *      FavoriteStockTransferRequisitionID: // value for 'FavoriteStockTransferRequisitionID'
 *      FromWarehouseID: // value for 'FromWarehouseID'
 *      ToWarehouseID: // value for 'ToWarehouseID'
 *   },
 * });
 */
export function useGetFavoriteStockTransferRequisitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoriteStockTransferRequisitionQuery, GetFavoriteStockTransferRequisitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoriteStockTransferRequisitionQuery, GetFavoriteStockTransferRequisitionQueryVariables>(GetFavoriteStockTransferRequisitionDocument, baseOptions);
      }
export function useGetFavoriteStockTransferRequisitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoriteStockTransferRequisitionQuery, GetFavoriteStockTransferRequisitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoriteStockTransferRequisitionQuery, GetFavoriteStockTransferRequisitionQueryVariables>(GetFavoriteStockTransferRequisitionDocument, baseOptions);
        }
export type GetFavoriteStockTransferRequisitionQueryHookResult = ReturnType<typeof useGetFavoriteStockTransferRequisitionQuery>;
export type GetFavoriteStockTransferRequisitionLazyQueryHookResult = ReturnType<typeof useGetFavoriteStockTransferRequisitionLazyQuery>;
export type GetFavoriteStockTransferRequisitionQueryResult = ApolloReactCommon.QueryResult<GetFavoriteStockTransferRequisitionQuery, GetFavoriteStockTransferRequisitionQueryVariables>;
export const CreateFavoriteStockTransferRequisitionDocument = gql`
    mutation createFavoriteStockTransferRequisition($FavoriteStockTransferRequisitionInput: FavoriteStockTransferRequisitionInput!, $FavoriteStockTransferRequisitionItemInput: [FavoriteStockTransferRequisitionItemInput!]!) {
  createFavoriteStockTransferRequisition(FavoriteStockTransferRequisitionInput: $FavoriteStockTransferRequisitionInput, FavoriteStockTransferRequisitionItemInput: $FavoriteStockTransferRequisitionItemInput)
}
    `;
export type CreateFavoriteStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<CreateFavoriteStockTransferRequisitionMutation, CreateFavoriteStockTransferRequisitionMutationVariables>;

/**
 * __useCreateFavoriteStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `useCreateFavoriteStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFavoriteStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFavoriteStockTransferRequisitionMutation, { data, loading, error }] = useCreateFavoriteStockTransferRequisitionMutation({
 *   variables: {
 *      FavoriteStockTransferRequisitionInput: // value for 'FavoriteStockTransferRequisitionInput'
 *      FavoriteStockTransferRequisitionItemInput: // value for 'FavoriteStockTransferRequisitionItemInput'
 *   },
 * });
 */
export function useCreateFavoriteStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFavoriteStockTransferRequisitionMutation, CreateFavoriteStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFavoriteStockTransferRequisitionMutation, CreateFavoriteStockTransferRequisitionMutationVariables>(CreateFavoriteStockTransferRequisitionDocument, baseOptions);
      }
export type CreateFavoriteStockTransferRequisitionMutationHookResult = ReturnType<typeof useCreateFavoriteStockTransferRequisitionMutation>;
export type CreateFavoriteStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<CreateFavoriteStockTransferRequisitionMutation>;
export type CreateFavoriteStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFavoriteStockTransferRequisitionMutation, CreateFavoriteStockTransferRequisitionMutationVariables>;
export const DeleteFavoriteStockTransferRequisitionDocument = gql`
    mutation deleteFavoriteStockTransferRequisition($FavoriteStockTransferRequisitionID: String!) {
  deleteFavoriteStockTransferRequisition(FavoriteStockTransferRequisitionID: $FavoriteStockTransferRequisitionID)
}
    `;
export type DeleteFavoriteStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<DeleteFavoriteStockTransferRequisitionMutation, DeleteFavoriteStockTransferRequisitionMutationVariables>;

/**
 * __useDeleteFavoriteStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteStockTransferRequisitionMutation, { data, loading, error }] = useDeleteFavoriteStockTransferRequisitionMutation({
 *   variables: {
 *      FavoriteStockTransferRequisitionID: // value for 'FavoriteStockTransferRequisitionID'
 *   },
 * });
 */
export function useDeleteFavoriteStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFavoriteStockTransferRequisitionMutation, DeleteFavoriteStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFavoriteStockTransferRequisitionMutation, DeleteFavoriteStockTransferRequisitionMutationVariables>(DeleteFavoriteStockTransferRequisitionDocument, baseOptions);
      }
export type DeleteFavoriteStockTransferRequisitionMutationHookResult = ReturnType<typeof useDeleteFavoriteStockTransferRequisitionMutation>;
export type DeleteFavoriteStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<DeleteFavoriteStockTransferRequisitionMutation>;
export type DeleteFavoriteStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFavoriteStockTransferRequisitionMutation, DeleteFavoriteStockTransferRequisitionMutationVariables>;
export const IcSubmenuDetailDocument = gql`
    query ICSubmenuDetail($WarehouseID: String!) {
  stockRequisitionCount(WarehouseID: $WarehouseID)
  stockIssueCount(WarehouseID: $WarehouseID)
  stockReceiptCount(WarehouseID: $WarehouseID)
  stockTransferRequisitionCount(WarehouseID: $WarehouseID)
  stockTransferCount(WarehouseID: $WarehouseID)
  stockAdjustmentCount(WarehouseID: $WarehouseID)
  stockCostAdjustmentCount(WarehouseID: $WarehouseID)
  cycleCountCount(WarehouseID: $WarehouseID)
}
    `;

/**
 * __useIcSubmenuDetailQuery__
 *
 * To run a query within a React component, call `useIcSubmenuDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcSubmenuDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcSubmenuDetailQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useIcSubmenuDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IcSubmenuDetailQuery, IcSubmenuDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<IcSubmenuDetailQuery, IcSubmenuDetailQueryVariables>(IcSubmenuDetailDocument, baseOptions);
      }
export function useIcSubmenuDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IcSubmenuDetailQuery, IcSubmenuDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IcSubmenuDetailQuery, IcSubmenuDetailQueryVariables>(IcSubmenuDetailDocument, baseOptions);
        }
export type IcSubmenuDetailQueryHookResult = ReturnType<typeof useIcSubmenuDetailQuery>;
export type IcSubmenuDetailLazyQueryHookResult = ReturnType<typeof useIcSubmenuDetailLazyQuery>;
export type IcSubmenuDetailQueryResult = ApolloReactCommon.QueryResult<IcSubmenuDetailQuery, IcSubmenuDetailQueryVariables>;
export const StockItemMovementCheckingDocument = gql`
    query stockItemMovementChecking($input: StockItemMovementInput!, $ICItemInput: [ICItemInput!]) {
  stockItemMovementChecking(input: $input, ICItemInput: $ICItemInput)
}
    `;

/**
 * __useStockItemMovementCheckingQuery__
 *
 * To run a query within a React component, call `useStockItemMovementCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockItemMovementCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockItemMovementCheckingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      ICItemInput: // value for 'ICItemInput'
 *   },
 * });
 */
export function useStockItemMovementCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StockItemMovementCheckingQuery, StockItemMovementCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<StockItemMovementCheckingQuery, StockItemMovementCheckingQueryVariables>(StockItemMovementCheckingDocument, baseOptions);
      }
export function useStockItemMovementCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockItemMovementCheckingQuery, StockItemMovementCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StockItemMovementCheckingQuery, StockItemMovementCheckingQueryVariables>(StockItemMovementCheckingDocument, baseOptions);
        }
export type StockItemMovementCheckingQueryHookResult = ReturnType<typeof useStockItemMovementCheckingQuery>;
export type StockItemMovementCheckingLazyQueryHookResult = ReturnType<typeof useStockItemMovementCheckingLazyQuery>;
export type StockItemMovementCheckingQueryResult = ApolloReactCommon.QueryResult<StockItemMovementCheckingQuery, StockItemMovementCheckingQueryVariables>;
export const GetStockAdjustmentDocument = gql`
    query getStockAdjustment($StockAdjustmentID: String, $CompanyID: String, $WarehouseID: String) {
  getStockAdjustment(StockAdjustmentID: $StockAdjustmentID, CompanyID: $CompanyID, WarehouseID: $WarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockAdjustmentID
    CompanyID
    CostCentreID
    DocNo
    DocDate
    TransactionDate
    RefNo
    WarehouseID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockAdjustmentItem {
      Quantity
      BaseUOMID
      UOMID
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      UOM {
        Name
        Code
      }
      StockItemID
      StockItem {
        Name
        UOM {
          Code
        }
      }
      ReasonID
      Reason {
        StockAdjustmentReasonID
        Reason
      }
      Remark
    }
  }
}
    `;

/**
 * __useGetStockAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetStockAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAdjustmentQuery({
 *   variables: {
 *      StockAdjustmentID: // value for 'StockAdjustmentID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockAdjustmentQuery, GetStockAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockAdjustmentQuery, GetStockAdjustmentQueryVariables>(GetStockAdjustmentDocument, baseOptions);
      }
export function useGetStockAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockAdjustmentQuery, GetStockAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockAdjustmentQuery, GetStockAdjustmentQueryVariables>(GetStockAdjustmentDocument, baseOptions);
        }
export type GetStockAdjustmentQueryHookResult = ReturnType<typeof useGetStockAdjustmentQuery>;
export type GetStockAdjustmentLazyQueryHookResult = ReturnType<typeof useGetStockAdjustmentLazyQuery>;
export type GetStockAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetStockAdjustmentQuery, GetStockAdjustmentQueryVariables>;
export const GetStockAdjustmentbyStatusDocument = gql`
    query getStockAdjustmentbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockAdjustmentbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockAdjustmentID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    CostCentreID
    WarehouseID
    Warehouse {
      Name
    }
    CostCentre {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockAdjustmentItem {
      StockAdjustmentItemID
      Quantity
      BaseUOMID
      UOMID
      TotalCost
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      DefaultUOMTotalCost
      UOM {
        Name
      }
      StockItemID
      StockItem {
        Name
      }
      ReasonID
      Reason {
        StockAdjustmentReasonID
        Reason
      }
      Remark
    }
  }
}
    `;

/**
 * __useGetStockAdjustmentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockAdjustmentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAdjustmentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAdjustmentbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockAdjustmentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockAdjustmentbyStatusQuery, GetStockAdjustmentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockAdjustmentbyStatusQuery, GetStockAdjustmentbyStatusQueryVariables>(GetStockAdjustmentbyStatusDocument, baseOptions);
      }
export function useGetStockAdjustmentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockAdjustmentbyStatusQuery, GetStockAdjustmentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockAdjustmentbyStatusQuery, GetStockAdjustmentbyStatusQueryVariables>(GetStockAdjustmentbyStatusDocument, baseOptions);
        }
export type GetStockAdjustmentbyStatusQueryHookResult = ReturnType<typeof useGetStockAdjustmentbyStatusQuery>;
export type GetStockAdjustmentbyStatusLazyQueryHookResult = ReturnType<typeof useGetStockAdjustmentbyStatusLazyQuery>;
export type GetStockAdjustmentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockAdjustmentbyStatusQuery, GetStockAdjustmentbyStatusQueryVariables>;
export const GetStockQtyAdjustmentListingDocument = gql`
    query getStockQtyAdjustmentListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockQtyAdjustmentListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockAdjustmentID
      CompanyID
      DocNo
      DocDate
      TransactionDate
      RefNo
      CostCentreID
      WarehouseID
      Warehouse {
        Name
      }
      Description
      ApprovalStatus
      IsGLPosted
      StockAdjustmentItem {
        StockAdjustmentItemID
        Quantity
        BaseUOMID
        UOMID
        TotalCost
        TransactionTypeID
        TransactionType {
          Name
          Type
        }
        DefaultUOMTotalCost
        UOM {
          Name
        }
        StockItemID
        StockItem {
          Name
        }
        ReasonID
        Reason {
          StockAdjustmentReasonID
          Reason
        }
        Remark
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockQtyAdjustmentListingQuery__
 *
 * To run a query within a React component, call `useGetStockQtyAdjustmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockQtyAdjustmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockQtyAdjustmentListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockQtyAdjustmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockQtyAdjustmentListingQuery, GetStockQtyAdjustmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockQtyAdjustmentListingQuery, GetStockQtyAdjustmentListingQueryVariables>(GetStockQtyAdjustmentListingDocument, baseOptions);
      }
export function useGetStockQtyAdjustmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockQtyAdjustmentListingQuery, GetStockQtyAdjustmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockQtyAdjustmentListingQuery, GetStockQtyAdjustmentListingQueryVariables>(GetStockQtyAdjustmentListingDocument, baseOptions);
        }
export type GetStockQtyAdjustmentListingQueryHookResult = ReturnType<typeof useGetStockQtyAdjustmentListingQuery>;
export type GetStockQtyAdjustmentListingLazyQueryHookResult = ReturnType<typeof useGetStockQtyAdjustmentListingLazyQuery>;
export type GetStockQtyAdjustmentListingQueryResult = ApolloReactCommon.QueryResult<GetStockQtyAdjustmentListingQuery, GetStockQtyAdjustmentListingQueryVariables>;
export const GetStockAdjustmentItemDocument = gql`
    query getStockAdjustmentItem($StockAdjustmentID: String) {
  getStockAdjustmentItem(StockAdjustmentID: $StockAdjustmentID) {
    StockAdjustmentItemID
    StockItemID
    StockItem {
      Name
    }
    UOMID
    UOM {
      Name
    }
    ReasonID
    Reason {
      StockAdjustmentReasonID
      Reason
    }
    Quantity
    TransactionTypeID
  }
}
    `;

/**
 * __useGetStockAdjustmentItemQuery__
 *
 * To run a query within a React component, call `useGetStockAdjustmentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAdjustmentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAdjustmentItemQuery({
 *   variables: {
 *      StockAdjustmentID: // value for 'StockAdjustmentID'
 *   },
 * });
 */
export function useGetStockAdjustmentItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockAdjustmentItemQuery, GetStockAdjustmentItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockAdjustmentItemQuery, GetStockAdjustmentItemQueryVariables>(GetStockAdjustmentItemDocument, baseOptions);
      }
export function useGetStockAdjustmentItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockAdjustmentItemQuery, GetStockAdjustmentItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockAdjustmentItemQuery, GetStockAdjustmentItemQueryVariables>(GetStockAdjustmentItemDocument, baseOptions);
        }
export type GetStockAdjustmentItemQueryHookResult = ReturnType<typeof useGetStockAdjustmentItemQuery>;
export type GetStockAdjustmentItemLazyQueryHookResult = ReturnType<typeof useGetStockAdjustmentItemLazyQuery>;
export type GetStockAdjustmentItemQueryResult = ApolloReactCommon.QueryResult<GetStockAdjustmentItemQuery, GetStockAdjustmentItemQueryVariables>;
export const CreateStockAdjustmentDocument = gql`
    mutation createStockAdjustment($input: StockAdjustmentInput!, $itemInput: [StockAdjustmentItemInput!]) {
  createStockAdjustment(input: $input, itemInput: $itemInput)
}
    `;
export type CreateStockAdjustmentMutationFn = ApolloReactCommon.MutationFunction<CreateStockAdjustmentMutation, CreateStockAdjustmentMutationVariables>;

/**
 * __useCreateStockAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateStockAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockAdjustmentMutation, { data, loading, error }] = useCreateStockAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockAdjustmentMutation, CreateStockAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockAdjustmentMutation, CreateStockAdjustmentMutationVariables>(CreateStockAdjustmentDocument, baseOptions);
      }
export type CreateStockAdjustmentMutationHookResult = ReturnType<typeof useCreateStockAdjustmentMutation>;
export type CreateStockAdjustmentMutationResult = ApolloReactCommon.MutationResult<CreateStockAdjustmentMutation>;
export type CreateStockAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockAdjustmentMutation, CreateStockAdjustmentMutationVariables>;
export const UpdateStockAdjustmentDocument = gql`
    mutation updateStockAdjustment($input: StockAdjustmentInput!, $itemInput: [StockAdjustmentItemInput!]) {
  updateStockAdjustment(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateStockAdjustmentMutationFn = ApolloReactCommon.MutationFunction<UpdateStockAdjustmentMutation, UpdateStockAdjustmentMutationVariables>;

/**
 * __useUpdateStockAdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdateStockAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockAdjustmentMutation, { data, loading, error }] = useUpdateStockAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockAdjustmentMutation, UpdateStockAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockAdjustmentMutation, UpdateStockAdjustmentMutationVariables>(UpdateStockAdjustmentDocument, baseOptions);
      }
export type UpdateStockAdjustmentMutationHookResult = ReturnType<typeof useUpdateStockAdjustmentMutation>;
export type UpdateStockAdjustmentMutationResult = ApolloReactCommon.MutationResult<UpdateStockAdjustmentMutation>;
export type UpdateStockAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockAdjustmentMutation, UpdateStockAdjustmentMutationVariables>;
export const CreateStockAdjustmentItemDocument = gql`
    mutation createStockAdjustmentItem($input: StockAdjustmentItemInput!) {
  createStockAdjustmentItem(input: $input) {
    StockAdjustmentItemID
  }
}
    `;
export type CreateStockAdjustmentItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockAdjustmentItemMutation, CreateStockAdjustmentItemMutationVariables>;

/**
 * __useCreateStockAdjustmentItemMutation__
 *
 * To run a mutation, you first call `useCreateStockAdjustmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockAdjustmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockAdjustmentItemMutation, { data, loading, error }] = useCreateStockAdjustmentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockAdjustmentItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockAdjustmentItemMutation, CreateStockAdjustmentItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockAdjustmentItemMutation, CreateStockAdjustmentItemMutationVariables>(CreateStockAdjustmentItemDocument, baseOptions);
      }
export type CreateStockAdjustmentItemMutationHookResult = ReturnType<typeof useCreateStockAdjustmentItemMutation>;
export type CreateStockAdjustmentItemMutationResult = ApolloReactCommon.MutationResult<CreateStockAdjustmentItemMutation>;
export type CreateStockAdjustmentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockAdjustmentItemMutation, CreateStockAdjustmentItemMutationVariables>;
export const DeleteStockAdjustmentDocument = gql`
    mutation deleteStockAdjustment($StockAdjustmentID: String!) {
  deleteStockAdjustment(StockAdjustmentID: $StockAdjustmentID)
}
    `;
export type DeleteStockAdjustmentMutationFn = ApolloReactCommon.MutationFunction<DeleteStockAdjustmentMutation, DeleteStockAdjustmentMutationVariables>;

/**
 * __useDeleteStockAdjustmentMutation__
 *
 * To run a mutation, you first call `useDeleteStockAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockAdjustmentMutation, { data, loading, error }] = useDeleteStockAdjustmentMutation({
 *   variables: {
 *      StockAdjustmentID: // value for 'StockAdjustmentID'
 *   },
 * });
 */
export function useDeleteStockAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockAdjustmentMutation, DeleteStockAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockAdjustmentMutation, DeleteStockAdjustmentMutationVariables>(DeleteStockAdjustmentDocument, baseOptions);
      }
export type DeleteStockAdjustmentMutationHookResult = ReturnType<typeof useDeleteStockAdjustmentMutation>;
export type DeleteStockAdjustmentMutationResult = ApolloReactCommon.MutationResult<DeleteStockAdjustmentMutation>;
export type DeleteStockAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockAdjustmentMutation, DeleteStockAdjustmentMutationVariables>;
export const PostStockAdjustmentDocument = gql`
    mutation postStockAdjustment($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockAdjustmentRejectInput!) {
  postStockAdjustment(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockAdjustmentMutationFn = ApolloReactCommon.MutationFunction<PostStockAdjustmentMutation, PostStockAdjustmentMutationVariables>;

/**
 * __usePostStockAdjustmentMutation__
 *
 * To run a mutation, you first call `usePostStockAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockAdjustmentMutation, { data, loading, error }] = usePostStockAdjustmentMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockAdjustmentMutation, PostStockAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockAdjustmentMutation, PostStockAdjustmentMutationVariables>(PostStockAdjustmentDocument, baseOptions);
      }
export type PostStockAdjustmentMutationHookResult = ReturnType<typeof usePostStockAdjustmentMutation>;
export type PostStockAdjustmentMutationResult = ApolloReactCommon.MutationResult<PostStockAdjustmentMutation>;
export type PostStockAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockAdjustmentMutation, PostStockAdjustmentMutationVariables>;
export const GetStockCostAdjustmentDocument = gql`
    query getStockCostAdjustment($StockCostAdjustmentID: String, $CompanyID: String, $WarehouseID: String) {
  getStockCostAdjustment(StockCostAdjustmentID: $StockCostAdjustmentID, CompanyID: $CompanyID, WarehouseID: $WarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockCostAdjustmentID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    CostCentreID
    WarehouseID
    Warehouse {
      Name
    }
    CostCentre {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockCostAdjustmentItem {
      StockCostAdjustmentItemID
      Cost
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      StockItemID
      StockItem {
        Name
        UOM {
          Code
        }
      }
      ReasonID
      Reason {
        StockAdjustmentReasonID
        Reason
      }
      Remark
    }
  }
}
    `;

/**
 * __useGetStockCostAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetStockCostAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCostAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCostAdjustmentQuery({
 *   variables: {
 *      StockCostAdjustmentID: // value for 'StockCostAdjustmentID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockCostAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCostAdjustmentQuery, GetStockCostAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCostAdjustmentQuery, GetStockCostAdjustmentQueryVariables>(GetStockCostAdjustmentDocument, baseOptions);
      }
export function useGetStockCostAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCostAdjustmentQuery, GetStockCostAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCostAdjustmentQuery, GetStockCostAdjustmentQueryVariables>(GetStockCostAdjustmentDocument, baseOptions);
        }
export type GetStockCostAdjustmentQueryHookResult = ReturnType<typeof useGetStockCostAdjustmentQuery>;
export type GetStockCostAdjustmentLazyQueryHookResult = ReturnType<typeof useGetStockCostAdjustmentLazyQuery>;
export type GetStockCostAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetStockCostAdjustmentQuery, GetStockCostAdjustmentQueryVariables>;
export const GetStockCostAdjustmentbyStatusDocument = gql`
    query getStockCostAdjustmentbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockCostAdjustmentbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockCostAdjustmentID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    CostCentreID
    WarehouseID
    Warehouse {
      Name
    }
    CostCentre {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockCostAdjustmentItem {
      StockCostAdjustmentItemID
      Cost
      StockItemID
      StockItem {
        Name
        UOM {
          Code
        }
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      ReasonID
      Reason {
        StockAdjustmentReasonID
        Reason
      }
      Remark
    }
  }
}
    `;

/**
 * __useGetStockCostAdjustmentbyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockCostAdjustmentbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCostAdjustmentbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCostAdjustmentbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockCostAdjustmentbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCostAdjustmentbyStatusQuery, GetStockCostAdjustmentbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCostAdjustmentbyStatusQuery, GetStockCostAdjustmentbyStatusQueryVariables>(GetStockCostAdjustmentbyStatusDocument, baseOptions);
      }
export function useGetStockCostAdjustmentbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCostAdjustmentbyStatusQuery, GetStockCostAdjustmentbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCostAdjustmentbyStatusQuery, GetStockCostAdjustmentbyStatusQueryVariables>(GetStockCostAdjustmentbyStatusDocument, baseOptions);
        }
export type GetStockCostAdjustmentbyStatusQueryHookResult = ReturnType<typeof useGetStockCostAdjustmentbyStatusQuery>;
export type GetStockCostAdjustmentbyStatusLazyQueryHookResult = ReturnType<typeof useGetStockCostAdjustmentbyStatusLazyQuery>;
export type GetStockCostAdjustmentbyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockCostAdjustmentbyStatusQuery, GetStockCostAdjustmentbyStatusQueryVariables>;
export const GetStockCostAdjustmentListingDocument = gql`
    query getStockCostAdjustmentListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockCostAdjustmentListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockCostAdjustmentID
      CompanyID
      DocNo
      DocDate
      TransactionDate
      RefNo
      CostCentreID
      WarehouseID
      Warehouse {
        Name
      }
      Description
      ApprovalStatus
      IsGLPosted
      StockCostAdjustmentItem {
        StockCostAdjustmentItemID
        Cost
        StockItemID
        StockItem {
          Name
        }
        TransactionTypeID
        TransactionType {
          Name
          Type
        }
        ReasonID
        Reason {
          StockAdjustmentReasonID
          Reason
        }
        Remark
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockCostAdjustmentListingQuery__
 *
 * To run a query within a React component, call `useGetStockCostAdjustmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCostAdjustmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCostAdjustmentListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockCostAdjustmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockCostAdjustmentListingQuery, GetStockCostAdjustmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockCostAdjustmentListingQuery, GetStockCostAdjustmentListingQueryVariables>(GetStockCostAdjustmentListingDocument, baseOptions);
      }
export function useGetStockCostAdjustmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockCostAdjustmentListingQuery, GetStockCostAdjustmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockCostAdjustmentListingQuery, GetStockCostAdjustmentListingQueryVariables>(GetStockCostAdjustmentListingDocument, baseOptions);
        }
export type GetStockCostAdjustmentListingQueryHookResult = ReturnType<typeof useGetStockCostAdjustmentListingQuery>;
export type GetStockCostAdjustmentListingLazyQueryHookResult = ReturnType<typeof useGetStockCostAdjustmentListingLazyQuery>;
export type GetStockCostAdjustmentListingQueryResult = ApolloReactCommon.QueryResult<GetStockCostAdjustmentListingQuery, GetStockCostAdjustmentListingQueryVariables>;
export const CreateStockCostAdjustmentDocument = gql`
    mutation createStockCostAdjustment($input: StockCostAdjustmentInput!, $itemInput: [StockCostAdjustmentItemInput!]) {
  createStockCostAdjustment(input: $input, itemInput: $itemInput) {
    StockCostAdjustmentID
  }
}
    `;
export type CreateStockCostAdjustmentMutationFn = ApolloReactCommon.MutationFunction<CreateStockCostAdjustmentMutation, CreateStockCostAdjustmentMutationVariables>;

/**
 * __useCreateStockCostAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateStockCostAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockCostAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockCostAdjustmentMutation, { data, loading, error }] = useCreateStockCostAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockCostAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockCostAdjustmentMutation, CreateStockCostAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockCostAdjustmentMutation, CreateStockCostAdjustmentMutationVariables>(CreateStockCostAdjustmentDocument, baseOptions);
      }
export type CreateStockCostAdjustmentMutationHookResult = ReturnType<typeof useCreateStockCostAdjustmentMutation>;
export type CreateStockCostAdjustmentMutationResult = ApolloReactCommon.MutationResult<CreateStockCostAdjustmentMutation>;
export type CreateStockCostAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockCostAdjustmentMutation, CreateStockCostAdjustmentMutationVariables>;
export const UpdateStockCostAdjustmentDocument = gql`
    mutation updateStockCostAdjustment($input: StockCostAdjustmentInput!, $itemInput: [StockCostAdjustmentItemInput!]) {
  updateStockCostAdjustment(input: $input, itemInput: $itemInput) {
    StockCostAdjustmentID
  }
}
    `;
export type UpdateStockCostAdjustmentMutationFn = ApolloReactCommon.MutationFunction<UpdateStockCostAdjustmentMutation, UpdateStockCostAdjustmentMutationVariables>;

/**
 * __useUpdateStockCostAdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdateStockCostAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockCostAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockCostAdjustmentMutation, { data, loading, error }] = useUpdateStockCostAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockCostAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockCostAdjustmentMutation, UpdateStockCostAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockCostAdjustmentMutation, UpdateStockCostAdjustmentMutationVariables>(UpdateStockCostAdjustmentDocument, baseOptions);
      }
export type UpdateStockCostAdjustmentMutationHookResult = ReturnType<typeof useUpdateStockCostAdjustmentMutation>;
export type UpdateStockCostAdjustmentMutationResult = ApolloReactCommon.MutationResult<UpdateStockCostAdjustmentMutation>;
export type UpdateStockCostAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockCostAdjustmentMutation, UpdateStockCostAdjustmentMutationVariables>;
export const DeleteStockCostAdjustmentDocument = gql`
    mutation deleteStockCostAdjustment($StockCostAdjustmentID: String!) {
  deleteStockCostAdjustment(StockCostAdjustmentID: $StockCostAdjustmentID)
}
    `;
export type DeleteStockCostAdjustmentMutationFn = ApolloReactCommon.MutationFunction<DeleteStockCostAdjustmentMutation, DeleteStockCostAdjustmentMutationVariables>;

/**
 * __useDeleteStockCostAdjustmentMutation__
 *
 * To run a mutation, you first call `useDeleteStockCostAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockCostAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockCostAdjustmentMutation, { data, loading, error }] = useDeleteStockCostAdjustmentMutation({
 *   variables: {
 *      StockCostAdjustmentID: // value for 'StockCostAdjustmentID'
 *   },
 * });
 */
export function useDeleteStockCostAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockCostAdjustmentMutation, DeleteStockCostAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockCostAdjustmentMutation, DeleteStockCostAdjustmentMutationVariables>(DeleteStockCostAdjustmentDocument, baseOptions);
      }
export type DeleteStockCostAdjustmentMutationHookResult = ReturnType<typeof useDeleteStockCostAdjustmentMutation>;
export type DeleteStockCostAdjustmentMutationResult = ApolloReactCommon.MutationResult<DeleteStockCostAdjustmentMutation>;
export type DeleteStockCostAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockCostAdjustmentMutation, DeleteStockCostAdjustmentMutationVariables>;
export const PostStockCostAdjustmentDocument = gql`
    mutation postStockCostAdjustment($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockCostAdjustmentRejectInput!) {
  postStockCostAdjustment(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockCostAdjustmentMutationFn = ApolloReactCommon.MutationFunction<PostStockCostAdjustmentMutation, PostStockCostAdjustmentMutationVariables>;

/**
 * __usePostStockCostAdjustmentMutation__
 *
 * To run a mutation, you first call `usePostStockCostAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockCostAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockCostAdjustmentMutation, { data, loading, error }] = usePostStockCostAdjustmentMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockCostAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockCostAdjustmentMutation, PostStockCostAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockCostAdjustmentMutation, PostStockCostAdjustmentMutationVariables>(PostStockCostAdjustmentDocument, baseOptions);
      }
export type PostStockCostAdjustmentMutationHookResult = ReturnType<typeof usePostStockCostAdjustmentMutation>;
export type PostStockCostAdjustmentMutationResult = ApolloReactCommon.MutationResult<PostStockCostAdjustmentMutation>;
export type PostStockCostAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockCostAdjustmentMutation, PostStockCostAdjustmentMutationVariables>;
export const GetStockIssueDocument = gql`
    query getStockIssue($StockIssueID: String, $CompanyID: String, $WarehouseID: String) {
  getStockIssue(StockIssueID: $StockIssueID, CompanyID: $CompanyID, WarehouseID: $WarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockIssueID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    CostCentreID
    RefNo
    StockRequisitionID
    WarehouseID
    Warehouse {
      Name
    }
    CostCentre {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockIssueItem {
      StockIssueItemID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      BaseUOMID
      UOMID
      UOM {
        Name
        Code
      }
      Quantity
      Remark
      UnitCost
    }
  }
}
    `;

/**
 * __useGetStockIssueQuery__
 *
 * To run a query within a React component, call `useGetStockIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockIssueQuery({
 *   variables: {
 *      StockIssueID: // value for 'StockIssueID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockIssueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockIssueQuery, GetStockIssueQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockIssueQuery, GetStockIssueQueryVariables>(GetStockIssueDocument, baseOptions);
      }
export function useGetStockIssueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockIssueQuery, GetStockIssueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockIssueQuery, GetStockIssueQueryVariables>(GetStockIssueDocument, baseOptions);
        }
export type GetStockIssueQueryHookResult = ReturnType<typeof useGetStockIssueQuery>;
export type GetStockIssueLazyQueryHookResult = ReturnType<typeof useGetStockIssueLazyQuery>;
export type GetStockIssueQueryResult = ApolloReactCommon.QueryResult<GetStockIssueQuery, GetStockIssueQueryVariables>;
export const GetStockIssueListingDocument = gql`
    query getStockIssueListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockIssueListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockIssueID
      CompanyID
      DocNo
      DocDate
      TransactionDate
      RefNo
      CostCentreID
      StockRequisitionID
      WarehouseID
      Warehouse {
        Name
      }
      Description
      ApprovalStatus
      StockIssueItem {
        StockIssueItemID
        StockItemID
        StockItem {
          Name
        }
        TransactionTypeID
        TransactionType {
          Name
          Type
        }
        BaseUOMID
        UOMID
        UOM {
          Name
        }
        Quantity
        TotalCost
        DefaultUOMTotalCost
        Remark
        UnitCost
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockIssueListingQuery__
 *
 * To run a query within a React component, call `useGetStockIssueListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockIssueListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockIssueListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockIssueListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockIssueListingQuery, GetStockIssueListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockIssueListingQuery, GetStockIssueListingQueryVariables>(GetStockIssueListingDocument, baseOptions);
      }
export function useGetStockIssueListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockIssueListingQuery, GetStockIssueListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockIssueListingQuery, GetStockIssueListingQueryVariables>(GetStockIssueListingDocument, baseOptions);
        }
export type GetStockIssueListingQueryHookResult = ReturnType<typeof useGetStockIssueListingQuery>;
export type GetStockIssueListingLazyQueryHookResult = ReturnType<typeof useGetStockIssueListingLazyQuery>;
export type GetStockIssueListingQueryResult = ApolloReactCommon.QueryResult<GetStockIssueListingQuery, GetStockIssueListingQueryVariables>;
export const GetStockIssueItemDocument = gql`
    query getStockIssueItem($StockIssueID: String) {
  getStockIssueItem(StockIssueID: $StockIssueID) {
    StockIssueItemID
    StockItemID
    UOMID
    Quantity
    TransactionTypeID
  }
}
    `;

/**
 * __useGetStockIssueItemQuery__
 *
 * To run a query within a React component, call `useGetStockIssueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockIssueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockIssueItemQuery({
 *   variables: {
 *      StockIssueID: // value for 'StockIssueID'
 *   },
 * });
 */
export function useGetStockIssueItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockIssueItemQuery, GetStockIssueItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockIssueItemQuery, GetStockIssueItemQueryVariables>(GetStockIssueItemDocument, baseOptions);
      }
export function useGetStockIssueItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockIssueItemQuery, GetStockIssueItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockIssueItemQuery, GetStockIssueItemQueryVariables>(GetStockIssueItemDocument, baseOptions);
        }
export type GetStockIssueItemQueryHookResult = ReturnType<typeof useGetStockIssueItemQuery>;
export type GetStockIssueItemLazyQueryHookResult = ReturnType<typeof useGetStockIssueItemLazyQuery>;
export type GetStockIssueItemQueryResult = ApolloReactCommon.QueryResult<GetStockIssueItemQuery, GetStockIssueItemQueryVariables>;
export const GetStockIssuebyStatusDocument = gql`
    query getStockIssuebyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockIssuebyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockIssueID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    CostCentreID
    StockRequisitionID
    WarehouseID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    StockIssueItem {
      StockIssueItemID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      BaseUOMID
      UOMID
      UOM {
        Name
      }
      Quantity
      TotalCost
      DefaultUOMTotalCost
      Remark
      UnitCost
    }
  }
}
    `;

/**
 * __useGetStockIssuebyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockIssuebyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockIssuebyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockIssuebyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockIssuebyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockIssuebyStatusQuery, GetStockIssuebyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockIssuebyStatusQuery, GetStockIssuebyStatusQueryVariables>(GetStockIssuebyStatusDocument, baseOptions);
      }
export function useGetStockIssuebyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockIssuebyStatusQuery, GetStockIssuebyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockIssuebyStatusQuery, GetStockIssuebyStatusQueryVariables>(GetStockIssuebyStatusDocument, baseOptions);
        }
export type GetStockIssuebyStatusQueryHookResult = ReturnType<typeof useGetStockIssuebyStatusQuery>;
export type GetStockIssuebyStatusLazyQueryHookResult = ReturnType<typeof useGetStockIssuebyStatusLazyQuery>;
export type GetStockIssuebyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockIssuebyStatusQuery, GetStockIssuebyStatusQueryVariables>;
export const CreateStockIssueDocument = gql`
    mutation createStockIssue($input: StockIssueInput!, $itemInput: [StockIssueItemInput!]) {
  createStockIssue(input: $input, itemInput: $itemInput)
}
    `;
export type CreateStockIssueMutationFn = ApolloReactCommon.MutationFunction<CreateStockIssueMutation, CreateStockIssueMutationVariables>;

/**
 * __useCreateStockIssueMutation__
 *
 * To run a mutation, you first call `useCreateStockIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockIssueMutation, { data, loading, error }] = useCreateStockIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockIssueMutation, CreateStockIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockIssueMutation, CreateStockIssueMutationVariables>(CreateStockIssueDocument, baseOptions);
      }
export type CreateStockIssueMutationHookResult = ReturnType<typeof useCreateStockIssueMutation>;
export type CreateStockIssueMutationResult = ApolloReactCommon.MutationResult<CreateStockIssueMutation>;
export type CreateStockIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockIssueMutation, CreateStockIssueMutationVariables>;
export const CreateStockIssueItemDocument = gql`
    mutation createStockIssueItem($input: StockIssueItemInput!) {
  createStockIssueItem(input: $input) {
    StockIssueItemID
  }
}
    `;
export type CreateStockIssueItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockIssueItemMutation, CreateStockIssueItemMutationVariables>;

/**
 * __useCreateStockIssueItemMutation__
 *
 * To run a mutation, you first call `useCreateStockIssueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockIssueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockIssueItemMutation, { data, loading, error }] = useCreateStockIssueItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockIssueItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockIssueItemMutation, CreateStockIssueItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockIssueItemMutation, CreateStockIssueItemMutationVariables>(CreateStockIssueItemDocument, baseOptions);
      }
export type CreateStockIssueItemMutationHookResult = ReturnType<typeof useCreateStockIssueItemMutation>;
export type CreateStockIssueItemMutationResult = ApolloReactCommon.MutationResult<CreateStockIssueItemMutation>;
export type CreateStockIssueItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockIssueItemMutation, CreateStockIssueItemMutationVariables>;
export const UpdateStockIssueDocument = gql`
    mutation updateStockIssue($input: StockIssueInput!, $itemInput: [StockIssueItemInput!]) {
  updateStockIssue(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateStockIssueMutationFn = ApolloReactCommon.MutationFunction<UpdateStockIssueMutation, UpdateStockIssueMutationVariables>;

/**
 * __useUpdateStockIssueMutation__
 *
 * To run a mutation, you first call `useUpdateStockIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockIssueMutation, { data, loading, error }] = useUpdateStockIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockIssueMutation, UpdateStockIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockIssueMutation, UpdateStockIssueMutationVariables>(UpdateStockIssueDocument, baseOptions);
      }
export type UpdateStockIssueMutationHookResult = ReturnType<typeof useUpdateStockIssueMutation>;
export type UpdateStockIssueMutationResult = ApolloReactCommon.MutationResult<UpdateStockIssueMutation>;
export type UpdateStockIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockIssueMutation, UpdateStockIssueMutationVariables>;
export const DeleteStockIssueDocument = gql`
    mutation deleteStockIssue($StockIssueID: String!) {
  deleteStockIssue(StockIssueID: $StockIssueID)
}
    `;
export type DeleteStockIssueMutationFn = ApolloReactCommon.MutationFunction<DeleteStockIssueMutation, DeleteStockIssueMutationVariables>;

/**
 * __useDeleteStockIssueMutation__
 *
 * To run a mutation, you first call `useDeleteStockIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockIssueMutation, { data, loading, error }] = useDeleteStockIssueMutation({
 *   variables: {
 *      StockIssueID: // value for 'StockIssueID'
 *   },
 * });
 */
export function useDeleteStockIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockIssueMutation, DeleteStockIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockIssueMutation, DeleteStockIssueMutationVariables>(DeleteStockIssueDocument, baseOptions);
      }
export type DeleteStockIssueMutationHookResult = ReturnType<typeof useDeleteStockIssueMutation>;
export type DeleteStockIssueMutationResult = ApolloReactCommon.MutationResult<DeleteStockIssueMutation>;
export type DeleteStockIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockIssueMutation, DeleteStockIssueMutationVariables>;
export const PostStockIssueDocument = gql`
    mutation postStockIssue($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockIssueRejectInput!) {
  postStockIssue(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockIssueMutationFn = ApolloReactCommon.MutationFunction<PostStockIssueMutation, PostStockIssueMutationVariables>;

/**
 * __usePostStockIssueMutation__
 *
 * To run a mutation, you first call `usePostStockIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockIssueMutation, { data, loading, error }] = usePostStockIssueMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockIssueMutation, PostStockIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockIssueMutation, PostStockIssueMutationVariables>(PostStockIssueDocument, baseOptions);
      }
export type PostStockIssueMutationHookResult = ReturnType<typeof usePostStockIssueMutation>;
export type PostStockIssueMutationResult = ApolloReactCommon.MutationResult<PostStockIssueMutation>;
export type PostStockIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockIssueMutation, PostStockIssueMutationVariables>;
export const GetStockReceiptDocument = gql`
    query getStockReceipt($StockReceiptID: String, $CompanyID: String, $WarehouseID: String) {
  getStockReceipt(StockReceiptID: $StockReceiptID, CompanyID: $CompanyID, WarehouseID: $WarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockReceiptID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    WarehouseID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    CostCentreID
    StockReceiptItem {
      StockReceiptID
      StockReceiptItemID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      BaseUOMID
      UOMID
      UOM {
        Name
        Code
      }
      Quantity
      UnitCost
      TotalCost
      Remark
    }
  }
}
    `;

/**
 * __useGetStockReceiptQuery__
 *
 * To run a query within a React component, call `useGetStockReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockReceiptQuery({
 *   variables: {
 *      StockReceiptID: // value for 'StockReceiptID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockReceiptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockReceiptQuery, GetStockReceiptQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockReceiptQuery, GetStockReceiptQueryVariables>(GetStockReceiptDocument, baseOptions);
      }
export function useGetStockReceiptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockReceiptQuery, GetStockReceiptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockReceiptQuery, GetStockReceiptQueryVariables>(GetStockReceiptDocument, baseOptions);
        }
export type GetStockReceiptQueryHookResult = ReturnType<typeof useGetStockReceiptQuery>;
export type GetStockReceiptLazyQueryHookResult = ReturnType<typeof useGetStockReceiptLazyQuery>;
export type GetStockReceiptQueryResult = ApolloReactCommon.QueryResult<GetStockReceiptQuery, GetStockReceiptQueryVariables>;
export const GetStockReceiptbyStatusDocument = gql`
    query getStockReceiptbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockReceiptbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockReceiptID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    CostCentreID
    WarehouseID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    StockReceiptItem {
      StockReceiptItemID
      StockReceiptID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      BaseUOMID
      UOMID
      UOM {
        Name
      }
      Quantity
      UnitCost
      TotalCost
      DefaultUOMTotalCost
      Remark
    }
  }
}
    `;

/**
 * __useGetStockReceiptbyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockReceiptbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockReceiptbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockReceiptbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockReceiptbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockReceiptbyStatusQuery, GetStockReceiptbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockReceiptbyStatusQuery, GetStockReceiptbyStatusQueryVariables>(GetStockReceiptbyStatusDocument, baseOptions);
      }
export function useGetStockReceiptbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockReceiptbyStatusQuery, GetStockReceiptbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockReceiptbyStatusQuery, GetStockReceiptbyStatusQueryVariables>(GetStockReceiptbyStatusDocument, baseOptions);
        }
export type GetStockReceiptbyStatusQueryHookResult = ReturnType<typeof useGetStockReceiptbyStatusQuery>;
export type GetStockReceiptbyStatusLazyQueryHookResult = ReturnType<typeof useGetStockReceiptbyStatusLazyQuery>;
export type GetStockReceiptbyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockReceiptbyStatusQuery, GetStockReceiptbyStatusQueryVariables>;
export const GetStockReceiptListingDocument = gql`
    query getStockReceiptListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockReceiptListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockReceiptID
      CompanyID
      DocNo
      DocDate
      TransactionDate
      RefNo
      CostCentreID
      WarehouseID
      Warehouse {
        Name
      }
      Description
      ApprovalStatus
      StockReceiptItem {
        StockReceiptItemID
        StockReceiptID
        StockItemID
        StockItem {
          Name
        }
        TransactionTypeID
        TransactionType {
          Name
          Type
        }
        BaseUOMID
        UOMID
        UOM {
          Name
        }
        Quantity
        UnitCost
        TotalCost
        DefaultUOMTotalCost
        Remark
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockReceiptListingQuery__
 *
 * To run a query within a React component, call `useGetStockReceiptListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockReceiptListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockReceiptListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockReceiptListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockReceiptListingQuery, GetStockReceiptListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockReceiptListingQuery, GetStockReceiptListingQueryVariables>(GetStockReceiptListingDocument, baseOptions);
      }
export function useGetStockReceiptListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockReceiptListingQuery, GetStockReceiptListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockReceiptListingQuery, GetStockReceiptListingQueryVariables>(GetStockReceiptListingDocument, baseOptions);
        }
export type GetStockReceiptListingQueryHookResult = ReturnType<typeof useGetStockReceiptListingQuery>;
export type GetStockReceiptListingLazyQueryHookResult = ReturnType<typeof useGetStockReceiptListingLazyQuery>;
export type GetStockReceiptListingQueryResult = ApolloReactCommon.QueryResult<GetStockReceiptListingQuery, GetStockReceiptListingQueryVariables>;
export const GetStockReceiptItemDocument = gql`
    query getStockReceiptItem($StockReceiptID: String) {
  getStockReceiptItem(StockReceiptID: $StockReceiptID) {
    StockReceiptItemID
    StockReceiptID
    StockItemID
    StockItem {
      Name
    }
    UOMID
    UOM {
      Name
    }
    Quantity
    UnitCost
    TotalCost
    TransactionTypeID
  }
}
    `;

/**
 * __useGetStockReceiptItemQuery__
 *
 * To run a query within a React component, call `useGetStockReceiptItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockReceiptItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockReceiptItemQuery({
 *   variables: {
 *      StockReceiptID: // value for 'StockReceiptID'
 *   },
 * });
 */
export function useGetStockReceiptItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockReceiptItemQuery, GetStockReceiptItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockReceiptItemQuery, GetStockReceiptItemQueryVariables>(GetStockReceiptItemDocument, baseOptions);
      }
export function useGetStockReceiptItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockReceiptItemQuery, GetStockReceiptItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockReceiptItemQuery, GetStockReceiptItemQueryVariables>(GetStockReceiptItemDocument, baseOptions);
        }
export type GetStockReceiptItemQueryHookResult = ReturnType<typeof useGetStockReceiptItemQuery>;
export type GetStockReceiptItemLazyQueryHookResult = ReturnType<typeof useGetStockReceiptItemLazyQuery>;
export type GetStockReceiptItemQueryResult = ApolloReactCommon.QueryResult<GetStockReceiptItemQuery, GetStockReceiptItemQueryVariables>;
export const CreateStockReceiptDocument = gql`
    mutation createStockReceipt($input: StockReceiptInput!, $itemInput: [StockReceiptItemInput!]) {
  createStockReceipt(input: $input, itemInput: $itemInput)
}
    `;
export type CreateStockReceiptMutationFn = ApolloReactCommon.MutationFunction<CreateStockReceiptMutation, CreateStockReceiptMutationVariables>;

/**
 * __useCreateStockReceiptMutation__
 *
 * To run a mutation, you first call `useCreateStockReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockReceiptMutation, { data, loading, error }] = useCreateStockReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockReceiptMutation, CreateStockReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockReceiptMutation, CreateStockReceiptMutationVariables>(CreateStockReceiptDocument, baseOptions);
      }
export type CreateStockReceiptMutationHookResult = ReturnType<typeof useCreateStockReceiptMutation>;
export type CreateStockReceiptMutationResult = ApolloReactCommon.MutationResult<CreateStockReceiptMutation>;
export type CreateStockReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockReceiptMutation, CreateStockReceiptMutationVariables>;
export const UpdateStockReceiptDocument = gql`
    mutation updateStockReceipt($input: StockReceiptInput!, $itemInput: [StockReceiptItemInput!]) {
  updateStockReceipt(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateStockReceiptMutationFn = ApolloReactCommon.MutationFunction<UpdateStockReceiptMutation, UpdateStockReceiptMutationVariables>;

/**
 * __useUpdateStockReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateStockReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockReceiptMutation, { data, loading, error }] = useUpdateStockReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockReceiptMutation, UpdateStockReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockReceiptMutation, UpdateStockReceiptMutationVariables>(UpdateStockReceiptDocument, baseOptions);
      }
export type UpdateStockReceiptMutationHookResult = ReturnType<typeof useUpdateStockReceiptMutation>;
export type UpdateStockReceiptMutationResult = ApolloReactCommon.MutationResult<UpdateStockReceiptMutation>;
export type UpdateStockReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockReceiptMutation, UpdateStockReceiptMutationVariables>;
export const CreateStockReceiptItemDocument = gql`
    mutation createStockReceiptItem($input: StockReceiptItemInput!) {
  createStockReceiptItem(input: $input) {
    StockReceiptItemID
  }
}
    `;
export type CreateStockReceiptItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockReceiptItemMutation, CreateStockReceiptItemMutationVariables>;

/**
 * __useCreateStockReceiptItemMutation__
 *
 * To run a mutation, you first call `useCreateStockReceiptItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockReceiptItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockReceiptItemMutation, { data, loading, error }] = useCreateStockReceiptItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockReceiptItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockReceiptItemMutation, CreateStockReceiptItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockReceiptItemMutation, CreateStockReceiptItemMutationVariables>(CreateStockReceiptItemDocument, baseOptions);
      }
export type CreateStockReceiptItemMutationHookResult = ReturnType<typeof useCreateStockReceiptItemMutation>;
export type CreateStockReceiptItemMutationResult = ApolloReactCommon.MutationResult<CreateStockReceiptItemMutation>;
export type CreateStockReceiptItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockReceiptItemMutation, CreateStockReceiptItemMutationVariables>;
export const DeleteStockReceiptDocument = gql`
    mutation deleteStockReceipt($StockReceiptID: String!) {
  deleteStockReceipt(StockReceiptID: $StockReceiptID)
}
    `;
export type DeleteStockReceiptMutationFn = ApolloReactCommon.MutationFunction<DeleteStockReceiptMutation, DeleteStockReceiptMutationVariables>;

/**
 * __useDeleteStockReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteStockReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockReceiptMutation, { data, loading, error }] = useDeleteStockReceiptMutation({
 *   variables: {
 *      StockReceiptID: // value for 'StockReceiptID'
 *   },
 * });
 */
export function useDeleteStockReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockReceiptMutation, DeleteStockReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockReceiptMutation, DeleteStockReceiptMutationVariables>(DeleteStockReceiptDocument, baseOptions);
      }
export type DeleteStockReceiptMutationHookResult = ReturnType<typeof useDeleteStockReceiptMutation>;
export type DeleteStockReceiptMutationResult = ApolloReactCommon.MutationResult<DeleteStockReceiptMutation>;
export type DeleteStockReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockReceiptMutation, DeleteStockReceiptMutationVariables>;
export const PostStockReceiptDocument = gql`
    mutation postStockReceipt($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockReceiptRejectInput!) {
  postStockReceipt(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockReceiptMutationFn = ApolloReactCommon.MutationFunction<PostStockReceiptMutation, PostStockReceiptMutationVariables>;

/**
 * __usePostStockReceiptMutation__
 *
 * To run a mutation, you first call `usePostStockReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockReceiptMutation, { data, loading, error }] = usePostStockReceiptMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockReceiptMutation, PostStockReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockReceiptMutation, PostStockReceiptMutationVariables>(PostStockReceiptDocument, baseOptions);
      }
export type PostStockReceiptMutationHookResult = ReturnType<typeof usePostStockReceiptMutation>;
export type PostStockReceiptMutationResult = ApolloReactCommon.MutationResult<PostStockReceiptMutation>;
export type PostStockReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockReceiptMutation, PostStockReceiptMutationVariables>;
export const GetStockRequisitionDocument = gql`
    query getStockRequisition($StockRequisitionID: String, $CompanyID: String, $WarehouseID: String) {
  getStockRequisition(StockRequisitionID: $StockRequisitionID, CompanyID: $CompanyID, WarehouseID: $WarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockRequisitionID
    CompanyID
    CostCentreID
    DocNo
    RequestDate
    ExpectedDate
    RefNo
    WarehouseID
    Warehouse {
      Name
    }
    CostCentre {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockRequisitionItem {
      StockRequisitionItemID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      BaseUOMID
      UOMID
      UOM {
        Name
        Code
      }
      Quantity
      Remark
    }
  }
}
    `;

/**
 * __useGetStockRequisitionQuery__
 *
 * To run a query within a React component, call `useGetStockRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockRequisitionQuery({
 *   variables: {
 *      StockRequisitionID: // value for 'StockRequisitionID'
 *      CompanyID: // value for 'CompanyID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetStockRequisitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockRequisitionQuery, GetStockRequisitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockRequisitionQuery, GetStockRequisitionQueryVariables>(GetStockRequisitionDocument, baseOptions);
      }
export function useGetStockRequisitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockRequisitionQuery, GetStockRequisitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockRequisitionQuery, GetStockRequisitionQueryVariables>(GetStockRequisitionDocument, baseOptions);
        }
export type GetStockRequisitionQueryHookResult = ReturnType<typeof useGetStockRequisitionQuery>;
export type GetStockRequisitionLazyQueryHookResult = ReturnType<typeof useGetStockRequisitionLazyQuery>;
export type GetStockRequisitionQueryResult = ApolloReactCommon.QueryResult<GetStockRequisitionQuery, GetStockRequisitionQueryVariables>;
export const GetStockRequisitionItemDocument = gql`
    query getStockRequisitionItem($StockRequisitionID: String) {
  getStockRequisitionItem(StockRequisitionID: $StockRequisitionID) {
    StockRequisitionItemID
    StockItemID
    UOMID
    Quantity
    TransactionTypeID
  }
}
    `;

/**
 * __useGetStockRequisitionItemQuery__
 *
 * To run a query within a React component, call `useGetStockRequisitionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockRequisitionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockRequisitionItemQuery({
 *   variables: {
 *      StockRequisitionID: // value for 'StockRequisitionID'
 *   },
 * });
 */
export function useGetStockRequisitionItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockRequisitionItemQuery, GetStockRequisitionItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockRequisitionItemQuery, GetStockRequisitionItemQueryVariables>(GetStockRequisitionItemDocument, baseOptions);
      }
export function useGetStockRequisitionItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockRequisitionItemQuery, GetStockRequisitionItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockRequisitionItemQuery, GetStockRequisitionItemQueryVariables>(GetStockRequisitionItemDocument, baseOptions);
        }
export type GetStockRequisitionItemQueryHookResult = ReturnType<typeof useGetStockRequisitionItemQuery>;
export type GetStockRequisitionItemLazyQueryHookResult = ReturnType<typeof useGetStockRequisitionItemLazyQuery>;
export type GetStockRequisitionItemQueryResult = ApolloReactCommon.QueryResult<GetStockRequisitionItemQuery, GetStockRequisitionItemQueryVariables>;
export const GetStockRequisitionbyStatusDocument = gql`
    query getStockRequisitionbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockRequisitionbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockRequisitionID
    CompanyID
    DocNo
    RequestDate
    ExpectedDate
    RefNo
    CostCentreID
    WarehouseID
    Warehouse {
      Name
    }
    Description
    ApprovalStatus
    StockRequisitionItem {
      StockRequisitionItemID
      StockItemID
      StockItem {
        Name
      }
      TransactionTypeID
      TransactionType {
        Name
        Type
      }
      BaseUOMID
      UOMID
      UOM {
        Name
      }
      Quantity
      TotalCost
      DefaultUOMTotalCost
      Remark
    }
    StockIssue {
      StockIssueID
      RefNo
      ApprovalStatus
    }
  }
}
    `;

/**
 * __useGetStockRequisitionbyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockRequisitionbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockRequisitionbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockRequisitionbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockRequisitionbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockRequisitionbyStatusQuery, GetStockRequisitionbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockRequisitionbyStatusQuery, GetStockRequisitionbyStatusQueryVariables>(GetStockRequisitionbyStatusDocument, baseOptions);
      }
export function useGetStockRequisitionbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockRequisitionbyStatusQuery, GetStockRequisitionbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockRequisitionbyStatusQuery, GetStockRequisitionbyStatusQueryVariables>(GetStockRequisitionbyStatusDocument, baseOptions);
        }
export type GetStockRequisitionbyStatusQueryHookResult = ReturnType<typeof useGetStockRequisitionbyStatusQuery>;
export type GetStockRequisitionbyStatusLazyQueryHookResult = ReturnType<typeof useGetStockRequisitionbyStatusLazyQuery>;
export type GetStockRequisitionbyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockRequisitionbyStatusQuery, GetStockRequisitionbyStatusQueryVariables>;
export const GetStockRequisitionListingDocument = gql`
    query getStockRequisitionListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockRequisitionListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockRequisitionID
      CompanyID
      DocNo
      RequestDate
      ExpectedDate
      RefNo
      CostCentreID
      WarehouseID
      Warehouse {
        Name
      }
      Description
      ApprovalStatus
      StockRequisitionItem {
        StockRequisitionItemID
        StockItemID
        StockItem {
          Name
        }
        TransactionTypeID
        TransactionType {
          Name
          Type
        }
        BaseUOMID
        UOMID
        UOM {
          Name
        }
        Quantity
        TotalCost
        DefaultUOMTotalCost
        Remark
      }
      StockIssue {
        StockIssueID
        RefNo
        ApprovalStatus
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockRequisitionListingQuery__
 *
 * To run a query within a React component, call `useGetStockRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockRequisitionListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockRequisitionListingQuery, GetStockRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockRequisitionListingQuery, GetStockRequisitionListingQueryVariables>(GetStockRequisitionListingDocument, baseOptions);
      }
export function useGetStockRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockRequisitionListingQuery, GetStockRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockRequisitionListingQuery, GetStockRequisitionListingQueryVariables>(GetStockRequisitionListingDocument, baseOptions);
        }
export type GetStockRequisitionListingQueryHookResult = ReturnType<typeof useGetStockRequisitionListingQuery>;
export type GetStockRequisitionListingLazyQueryHookResult = ReturnType<typeof useGetStockRequisitionListingLazyQuery>;
export type GetStockRequisitionListingQueryResult = ApolloReactCommon.QueryResult<GetStockRequisitionListingQuery, GetStockRequisitionListingQueryVariables>;
export const CreateStockRequisitionDocument = gql`
    mutation createStockRequisition($input: StockRequisitionInput!, $itemInput: [StockRequisitionItemInput!]) {
  createStockRequisition(input: $input, itemInput: $itemInput)
}
    `;
export type CreateStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<CreateStockRequisitionMutation, CreateStockRequisitionMutationVariables>;

/**
 * __useCreateStockRequisitionMutation__
 *
 * To run a mutation, you first call `useCreateStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockRequisitionMutation, { data, loading, error }] = useCreateStockRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockRequisitionMutation, CreateStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockRequisitionMutation, CreateStockRequisitionMutationVariables>(CreateStockRequisitionDocument, baseOptions);
      }
export type CreateStockRequisitionMutationHookResult = ReturnType<typeof useCreateStockRequisitionMutation>;
export type CreateStockRequisitionMutationResult = ApolloReactCommon.MutationResult<CreateStockRequisitionMutation>;
export type CreateStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockRequisitionMutation, CreateStockRequisitionMutationVariables>;
export const CreateStockRequisitionItemDocument = gql`
    mutation createStockRequisitionItem($input: StockRequisitionItemInput!) {
  createStockRequisitionItem(input: $input) {
    StockRequisitionItemID
  }
}
    `;
export type CreateStockRequisitionItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockRequisitionItemMutation, CreateStockRequisitionItemMutationVariables>;

/**
 * __useCreateStockRequisitionItemMutation__
 *
 * To run a mutation, you first call `useCreateStockRequisitionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockRequisitionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockRequisitionItemMutation, { data, loading, error }] = useCreateStockRequisitionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockRequisitionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockRequisitionItemMutation, CreateStockRequisitionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockRequisitionItemMutation, CreateStockRequisitionItemMutationVariables>(CreateStockRequisitionItemDocument, baseOptions);
      }
export type CreateStockRequisitionItemMutationHookResult = ReturnType<typeof useCreateStockRequisitionItemMutation>;
export type CreateStockRequisitionItemMutationResult = ApolloReactCommon.MutationResult<CreateStockRequisitionItemMutation>;
export type CreateStockRequisitionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockRequisitionItemMutation, CreateStockRequisitionItemMutationVariables>;
export const UpdateStockRequisitionDocument = gql`
    mutation updateStockRequisition($input: StockRequisitionInput!, $itemInput: [StockRequisitionItemInput!]) {
  updateStockRequisition(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<UpdateStockRequisitionMutation, UpdateStockRequisitionMutationVariables>;

/**
 * __useUpdateStockRequisitionMutation__
 *
 * To run a mutation, you first call `useUpdateStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockRequisitionMutation, { data, loading, error }] = useUpdateStockRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockRequisitionMutation, UpdateStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockRequisitionMutation, UpdateStockRequisitionMutationVariables>(UpdateStockRequisitionDocument, baseOptions);
      }
export type UpdateStockRequisitionMutationHookResult = ReturnType<typeof useUpdateStockRequisitionMutation>;
export type UpdateStockRequisitionMutationResult = ApolloReactCommon.MutationResult<UpdateStockRequisitionMutation>;
export type UpdateStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockRequisitionMutation, UpdateStockRequisitionMutationVariables>;
export const DeleteStockRequisitionDocument = gql`
    mutation deleteStockRequisition($StockRequisitionID: String!) {
  deleteStockRequisition(StockRequisitionID: $StockRequisitionID)
}
    `;
export type DeleteStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<DeleteStockRequisitionMutation, DeleteStockRequisitionMutationVariables>;

/**
 * __useDeleteStockRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockRequisitionMutation, { data, loading, error }] = useDeleteStockRequisitionMutation({
 *   variables: {
 *      StockRequisitionID: // value for 'StockRequisitionID'
 *   },
 * });
 */
export function useDeleteStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockRequisitionMutation, DeleteStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockRequisitionMutation, DeleteStockRequisitionMutationVariables>(DeleteStockRequisitionDocument, baseOptions);
      }
export type DeleteStockRequisitionMutationHookResult = ReturnType<typeof useDeleteStockRequisitionMutation>;
export type DeleteStockRequisitionMutationResult = ApolloReactCommon.MutationResult<DeleteStockRequisitionMutation>;
export type DeleteStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockRequisitionMutation, DeleteStockRequisitionMutationVariables>;
export const PostStockRequisitionDocument = gql`
    mutation postStockRequisition($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockRequisitionRejectInput!) {
  postStockRequisition(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<PostStockRequisitionMutation, PostStockRequisitionMutationVariables>;

/**
 * __usePostStockRequisitionMutation__
 *
 * To run a mutation, you first call `usePostStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockRequisitionMutation, { data, loading, error }] = usePostStockRequisitionMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockRequisitionMutation, PostStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockRequisitionMutation, PostStockRequisitionMutationVariables>(PostStockRequisitionDocument, baseOptions);
      }
export type PostStockRequisitionMutationHookResult = ReturnType<typeof usePostStockRequisitionMutation>;
export type PostStockRequisitionMutationResult = ApolloReactCommon.MutationResult<PostStockRequisitionMutation>;
export type PostStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockRequisitionMutation, PostStockRequisitionMutationVariables>;
export const CancelStockRequisitionDocument = gql`
    mutation cancelStockRequisition($input: StockRequisitionCancelInput!) {
  cancelStockRequisition(input: $input)
}
    `;
export type CancelStockRequisitionMutationFn = ApolloReactCommon.MutationFunction<CancelStockRequisitionMutation, CancelStockRequisitionMutationVariables>;

/**
 * __useCancelStockRequisitionMutation__
 *
 * To run a mutation, you first call `useCancelStockRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStockRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStockRequisitionMutation, { data, loading, error }] = useCancelStockRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelStockRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelStockRequisitionMutation, CancelStockRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelStockRequisitionMutation, CancelStockRequisitionMutationVariables>(CancelStockRequisitionDocument, baseOptions);
      }
export type CancelStockRequisitionMutationHookResult = ReturnType<typeof useCancelStockRequisitionMutation>;
export type CancelStockRequisitionMutationResult = ApolloReactCommon.MutationResult<CancelStockRequisitionMutation>;
export type CancelStockRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelStockRequisitionMutation, CancelStockRequisitionMutationVariables>;
export const GetStockTransferDocument = gql`
    query getStockTransfer($StockTransferID: String, $CompanyID: String, $FromWarehouseID: String, $ToWarehouseID: String) {
  getStockTransfer(StockTransferID: $StockTransferID, CompanyID: $CompanyID, FromWarehouseID: $FromWarehouseID, ToWarehouseID: $ToWarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockTransferID
    StockTransferRequisitionID
    CompanyID
    CostCentreID
    DocNo
    DocDate
    TransactionDate
    RefNo
    FromWarehouseID
    FromWarehouse {
      Name
    }
    ToWarehouseID
    ToWarehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockTransferItem {
      Quantity
      BaseUOMID
      UOMID
      UnitCost
      UOM {
        Name
        Code
      }
      Reason {
        Reason
      }
      StockItemID
      StockItem {
        Name
      }
    }
  }
}
    `;

/**
 * __useGetStockTransferQuery__
 *
 * To run a query within a React component, call `useGetStockTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferQuery({
 *   variables: {
 *      StockTransferID: // value for 'StockTransferID'
 *      CompanyID: // value for 'CompanyID'
 *      FromWarehouseID: // value for 'FromWarehouseID'
 *      ToWarehouseID: // value for 'ToWarehouseID'
 *   },
 * });
 */
export function useGetStockTransferQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferQuery, GetStockTransferQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferQuery, GetStockTransferQueryVariables>(GetStockTransferDocument, baseOptions);
      }
export function useGetStockTransferLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferQuery, GetStockTransferQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferQuery, GetStockTransferQueryVariables>(GetStockTransferDocument, baseOptions);
        }
export type GetStockTransferQueryHookResult = ReturnType<typeof useGetStockTransferQuery>;
export type GetStockTransferLazyQueryHookResult = ReturnType<typeof useGetStockTransferLazyQuery>;
export type GetStockTransferQueryResult = ApolloReactCommon.QueryResult<GetStockTransferQuery, GetStockTransferQueryVariables>;
export const GetStockTransferbyStatusDocument = gql`
    query getStockTransferbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockTransferbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockTransferID
    StockTransferRequisitionID
    CompanyID
    DocNo
    DocDate
    TransactionDate
    RefNo
    CostCentreID
    FromWarehouseID
    FromWarehouse {
      Name
    }
    ToWarehouseID
    ToWarehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockTransferItem {
      StockTransferItemID
      Quantity
      UnitCost
      TotalCost
      BaseUOMID
      DefaultUOMTotalCost
      ReasonID
      Reason {
        Reason
      }
      UOMID
      UOM {
        Name
      }
      StockItemID
      StockItem {
        Name
      }
    }
  }
}
    `;

/**
 * __useGetStockTransferbyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockTransferbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockTransferbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferbyStatusQuery, GetStockTransferbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferbyStatusQuery, GetStockTransferbyStatusQueryVariables>(GetStockTransferbyStatusDocument, baseOptions);
      }
export function useGetStockTransferbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferbyStatusQuery, GetStockTransferbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferbyStatusQuery, GetStockTransferbyStatusQueryVariables>(GetStockTransferbyStatusDocument, baseOptions);
        }
export type GetStockTransferbyStatusQueryHookResult = ReturnType<typeof useGetStockTransferbyStatusQuery>;
export type GetStockTransferbyStatusLazyQueryHookResult = ReturnType<typeof useGetStockTransferbyStatusLazyQuery>;
export type GetStockTransferbyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockTransferbyStatusQuery, GetStockTransferbyStatusQueryVariables>;
export const GetStockTransferListingDocument = gql`
    query getStockTransferListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockTransferListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockTransferID
      StockTransferRequisitionID
      CompanyID
      DocNo
      DocDate
      TransactionDate
      RefNo
      CostCentreID
      FromWarehouseID
      FromWarehouse {
        Name
      }
      ToWarehouseID
      ToWarehouse {
        Name
      }
      Description
      ApprovalStatus
      IsGLPosted
      StockTransferItem {
        StockTransferItemID
        Quantity
        UnitCost
        TotalCost
        BaseUOMID
        DefaultUOMTotalCost
        ReasonID
        Reason {
          Reason
        }
        UOMID
        UOM {
          Name
        }
        StockItemID
        StockItem {
          Name
        }
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockTransferListingQuery__
 *
 * To run a query within a React component, call `useGetStockTransferListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockTransferListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferListingQuery, GetStockTransferListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferListingQuery, GetStockTransferListingQueryVariables>(GetStockTransferListingDocument, baseOptions);
      }
export function useGetStockTransferListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferListingQuery, GetStockTransferListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferListingQuery, GetStockTransferListingQueryVariables>(GetStockTransferListingDocument, baseOptions);
        }
export type GetStockTransferListingQueryHookResult = ReturnType<typeof useGetStockTransferListingQuery>;
export type GetStockTransferListingLazyQueryHookResult = ReturnType<typeof useGetStockTransferListingLazyQuery>;
export type GetStockTransferListingQueryResult = ApolloReactCommon.QueryResult<GetStockTransferListingQuery, GetStockTransferListingQueryVariables>;
export const GetStockTransferItemDocument = gql`
    query getStockTransferItem($StockTransferID: String) {
  getStockTransferItem(StockTransferID: $StockTransferID) {
    StockTransferItemID
    StockItemID
    StockItem {
      Name
    }
    UOMID
    UOM {
      Name
    }
    Quantity
    UnitCost
  }
}
    `;

/**
 * __useGetStockTransferItemQuery__
 *
 * To run a query within a React component, call `useGetStockTransferItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferItemQuery({
 *   variables: {
 *      StockTransferID: // value for 'StockTransferID'
 *   },
 * });
 */
export function useGetStockTransferItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferItemQuery, GetStockTransferItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferItemQuery, GetStockTransferItemQueryVariables>(GetStockTransferItemDocument, baseOptions);
      }
export function useGetStockTransferItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferItemQuery, GetStockTransferItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferItemQuery, GetStockTransferItemQueryVariables>(GetStockTransferItemDocument, baseOptions);
        }
export type GetStockTransferItemQueryHookResult = ReturnType<typeof useGetStockTransferItemQuery>;
export type GetStockTransferItemLazyQueryHookResult = ReturnType<typeof useGetStockTransferItemLazyQuery>;
export type GetStockTransferItemQueryResult = ApolloReactCommon.QueryResult<GetStockTransferItemQuery, GetStockTransferItemQueryVariables>;
export const CreateStockTransferDocument = gql`
    mutation createStockTransfer($input: StockTransferInput!, $itemInput: [StockTransferItemInput!]) {
  createStockTransfer(input: $input, itemInput: $itemInput)
}
    `;
export type CreateStockTransferMutationFn = ApolloReactCommon.MutationFunction<CreateStockTransferMutation, CreateStockTransferMutationVariables>;

/**
 * __useCreateStockTransferMutation__
 *
 * To run a mutation, you first call `useCreateStockTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockTransferMutation, { data, loading, error }] = useCreateStockTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockTransferMutation, CreateStockTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockTransferMutation, CreateStockTransferMutationVariables>(CreateStockTransferDocument, baseOptions);
      }
export type CreateStockTransferMutationHookResult = ReturnType<typeof useCreateStockTransferMutation>;
export type CreateStockTransferMutationResult = ApolloReactCommon.MutationResult<CreateStockTransferMutation>;
export type CreateStockTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockTransferMutation, CreateStockTransferMutationVariables>;
export const UpdateStockTransferDocument = gql`
    mutation updateStockTransfer($input: StockTransferInput!, $itemInput: [StockTransferItemInput!]) {
  updateStockTransfer(input: $input, itemInput: $itemInput)
}
    `;
export type UpdateStockTransferMutationFn = ApolloReactCommon.MutationFunction<UpdateStockTransferMutation, UpdateStockTransferMutationVariables>;

/**
 * __useUpdateStockTransferMutation__
 *
 * To run a mutation, you first call `useUpdateStockTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockTransferMutation, { data, loading, error }] = useUpdateStockTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockTransferMutation, UpdateStockTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockTransferMutation, UpdateStockTransferMutationVariables>(UpdateStockTransferDocument, baseOptions);
      }
export type UpdateStockTransferMutationHookResult = ReturnType<typeof useUpdateStockTransferMutation>;
export type UpdateStockTransferMutationResult = ApolloReactCommon.MutationResult<UpdateStockTransferMutation>;
export type UpdateStockTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockTransferMutation, UpdateStockTransferMutationVariables>;
export const CreateStockTransferItemDocument = gql`
    mutation createStockTransferItem($input: StockTransferItemInput!) {
  createStockTransferItem(input: $input) {
    StockTransferItemID
  }
}
    `;
export type CreateStockTransferItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockTransferItemMutation, CreateStockTransferItemMutationVariables>;

/**
 * __useCreateStockTransferItemMutation__
 *
 * To run a mutation, you first call `useCreateStockTransferItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockTransferItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockTransferItemMutation, { data, loading, error }] = useCreateStockTransferItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockTransferItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockTransferItemMutation, CreateStockTransferItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockTransferItemMutation, CreateStockTransferItemMutationVariables>(CreateStockTransferItemDocument, baseOptions);
      }
export type CreateStockTransferItemMutationHookResult = ReturnType<typeof useCreateStockTransferItemMutation>;
export type CreateStockTransferItemMutationResult = ApolloReactCommon.MutationResult<CreateStockTransferItemMutation>;
export type CreateStockTransferItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockTransferItemMutation, CreateStockTransferItemMutationVariables>;
export const DeleteStockTransferDocument = gql`
    mutation deleteStockTransfer($StockTransferID: String!) {
  deleteStockTransfer(StockTransferID: $StockTransferID)
}
    `;
export type DeleteStockTransferMutationFn = ApolloReactCommon.MutationFunction<DeleteStockTransferMutation, DeleteStockTransferMutationVariables>;

/**
 * __useDeleteStockTransferMutation__
 *
 * To run a mutation, you first call `useDeleteStockTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockTransferMutation, { data, loading, error }] = useDeleteStockTransferMutation({
 *   variables: {
 *      StockTransferID: // value for 'StockTransferID'
 *   },
 * });
 */
export function useDeleteStockTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockTransferMutation, DeleteStockTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockTransferMutation, DeleteStockTransferMutationVariables>(DeleteStockTransferDocument, baseOptions);
      }
export type DeleteStockTransferMutationHookResult = ReturnType<typeof useDeleteStockTransferMutation>;
export type DeleteStockTransferMutationResult = ApolloReactCommon.MutationResult<DeleteStockTransferMutation>;
export type DeleteStockTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockTransferMutation, DeleteStockTransferMutationVariables>;
export const PostStockTransferDocument = gql`
    mutation postStockTransfer($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockTransferRejectInput!) {
  postStockTransfer(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockTransferMutationFn = ApolloReactCommon.MutationFunction<PostStockTransferMutation, PostStockTransferMutationVariables>;

/**
 * __usePostStockTransferMutation__
 *
 * To run a mutation, you first call `usePostStockTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockTransferMutation, { data, loading, error }] = usePostStockTransferMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockTransferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockTransferMutation, PostStockTransferMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockTransferMutation, PostStockTransferMutationVariables>(PostStockTransferDocument, baseOptions);
      }
export type PostStockTransferMutationHookResult = ReturnType<typeof usePostStockTransferMutation>;
export type PostStockTransferMutationResult = ApolloReactCommon.MutationResult<PostStockTransferMutation>;
export type PostStockTransferMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockTransferMutation, PostStockTransferMutationVariables>;
export const GetStockTransferRequisitionDocument = gql`
    query getStockTransferRequisition($StockTransferRequisitionID: String, $CompanyID: String, $FromWarehouseID: String, $ToWarehouseID: String) {
  getStockTransferRequisition(StockTransferRequisitionID: $StockTransferRequisitionID, CompanyID: $CompanyID, FromWarehouseID: $FromWarehouseID, ToWarehouseID: $ToWarehouseID) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockTransferRequisitionID
    CompanyID
    CostCentreID
    DocNo
    RequestDate
    ExpectedDate
    RefNo
    FromWarehouseID
    FromWarehouse {
      Name
    }
    ToWarehouseID
    ToWarehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockTransferRequisitionItem {
      Quantity
      BaseUOMID
      UOMID
      UnitCost
      UOM {
        Name
        Code
      }
      Reason {
        Reason
      }
      StockItemID
      StockItem {
        Name
      }
    }
  }
}
    `;

/**
 * __useGetStockTransferRequisitionQuery__
 *
 * To run a query within a React component, call `useGetStockTransferRequisitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferRequisitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferRequisitionQuery({
 *   variables: {
 *      StockTransferRequisitionID: // value for 'StockTransferRequisitionID'
 *      CompanyID: // value for 'CompanyID'
 *      FromWarehouseID: // value for 'FromWarehouseID'
 *      ToWarehouseID: // value for 'ToWarehouseID'
 *   },
 * });
 */
export function useGetStockTransferRequisitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferRequisitionQuery, GetStockTransferRequisitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferRequisitionQuery, GetStockTransferRequisitionQueryVariables>(GetStockTransferRequisitionDocument, baseOptions);
      }
export function useGetStockTransferRequisitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferRequisitionQuery, GetStockTransferRequisitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferRequisitionQuery, GetStockTransferRequisitionQueryVariables>(GetStockTransferRequisitionDocument, baseOptions);
        }
export type GetStockTransferRequisitionQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionQuery>;
export type GetStockTransferRequisitionLazyQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionLazyQuery>;
export type GetStockTransferRequisitionQueryResult = ApolloReactCommon.QueryResult<GetStockTransferRequisitionQuery, GetStockTransferRequisitionQueryVariables>;
export const GetStockTransferRequisitionbyStatusDocument = gql`
    query getStockTransferRequisitionbyStatus($WarehouseID: String!, $StatusArr: [String!]!) {
  getStockTransferRequisitionbyStatus(WarehouseID: $WarehouseID, StatusArr: $StatusArr) {
    createdTs
    modTs
    modBy
    submittedBy
    submittedTs
    approvedBy
    approvedTs
    createdBy
    StockTransferRequisitionID
    CompanyID
    DocNo
    RequestDate
    ExpectedDate
    RefNo
    CostCentreID
    FromWarehouseID
    FromWarehouse {
      Name
    }
    ToWarehouseID
    ToWarehouse {
      Name
    }
    Description
    ApprovalStatus
    IsGLPosted
    StockTransferRequisitionItem {
      StockTransferRequisitionItemID
      Quantity
      UnitCost
      TotalCost
      BaseUOMID
      DefaultUOMTotalCost
      ReasonID
      Reason {
        Reason
      }
      UOMID
      UOM {
        Name
      }
      StockItemID
      StockItem {
        Name
      }
    }
    StockTransfer {
      StockTransferID
    }
  }
}
    `;

/**
 * __useGetStockTransferRequisitionbyStatusQuery__
 *
 * To run a query within a React component, call `useGetStockTransferRequisitionbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferRequisitionbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferRequisitionbyStatusQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetStockTransferRequisitionbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferRequisitionbyStatusQuery, GetStockTransferRequisitionbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferRequisitionbyStatusQuery, GetStockTransferRequisitionbyStatusQueryVariables>(GetStockTransferRequisitionbyStatusDocument, baseOptions);
      }
export function useGetStockTransferRequisitionbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferRequisitionbyStatusQuery, GetStockTransferRequisitionbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferRequisitionbyStatusQuery, GetStockTransferRequisitionbyStatusQueryVariables>(GetStockTransferRequisitionbyStatusDocument, baseOptions);
        }
export type GetStockTransferRequisitionbyStatusQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionbyStatusQuery>;
export type GetStockTransferRequisitionbyStatusLazyQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionbyStatusLazyQuery>;
export type GetStockTransferRequisitionbyStatusQueryResult = ApolloReactCommon.QueryResult<GetStockTransferRequisitionbyStatusQuery, GetStockTransferRequisitionbyStatusQueryVariables>;
export const GetStockTransferRequisitionListingDocument = gql`
    query getStockTransferRequisitionListing($WarehouseID: String!, $searchValue: String, $take: Float, $skip: Float, $StatusArr: [String!]!, $sortOrder: String, $sortField: String) {
  getStockTransferRequisitionListing(WarehouseID: $WarehouseID, searchValue: $searchValue, take: $take, skip: $skip, StatusArr: $StatusArr, sortOrder: $sortOrder, sortField: $sortField) {
    Data {
      createdTs
      modTs
      modBy
      submittedBy
      submittedTs
      approvedBy
      approvedTs
      createdBy
      StockTransferRequisitionID
      CompanyID
      DocNo
      RequestDate
      ExpectedDate
      RefNo
      CostCentreID
      FromWarehouseID
      FromWarehouse {
        Name
      }
      ToWarehouseID
      ToWarehouse {
        Name
      }
      Description
      ApprovalStatus
      IsGLPosted
      StockTransferRequisitionItem {
        StockTransferRequisitionItemID
        Quantity
        UnitCost
        TotalCost
        BaseUOMID
        DefaultUOMTotalCost
        ReasonID
        Reason {
          Reason
        }
        UOMID
        UOM {
          Name
        }
        StockItemID
        StockItem {
          Name
        }
      }
    }
    TotalLength
  }
}
    `;

/**
 * __useGetStockTransferRequisitionListingQuery__
 *
 * To run a query within a React component, call `useGetStockTransferRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferRequisitionListingQuery({
 *   variables: {
 *      WarehouseID: // value for 'WarehouseID'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      StatusArr: // value for 'StatusArr'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetStockTransferRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferRequisitionListingQuery, GetStockTransferRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferRequisitionListingQuery, GetStockTransferRequisitionListingQueryVariables>(GetStockTransferRequisitionListingDocument, baseOptions);
      }
export function useGetStockTransferRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferRequisitionListingQuery, GetStockTransferRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferRequisitionListingQuery, GetStockTransferRequisitionListingQueryVariables>(GetStockTransferRequisitionListingDocument, baseOptions);
        }
export type GetStockTransferRequisitionListingQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionListingQuery>;
export type GetStockTransferRequisitionListingLazyQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionListingLazyQuery>;
export type GetStockTransferRequisitionListingQueryResult = ApolloReactCommon.QueryResult<GetStockTransferRequisitionListingQuery, GetStockTransferRequisitionListingQueryVariables>;
export const GetStockTransferRequisitionItemDocument = gql`
    query getStockTransferRequisitionItem($StockTransferRequisitionID: String) {
  getStockTransferRequisitionItem(StockTransferRequisitionID: $StockTransferRequisitionID) {
    StockTransferRequisitionItemID
    StockItemID
    StockItem {
      Name
    }
    UOMID
    UOM {
      Name
    }
    Quantity
    UnitCost
  }
}
    `;

/**
 * __useGetStockTransferRequisitionItemQuery__
 *
 * To run a query within a React component, call `useGetStockTransferRequisitionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferRequisitionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferRequisitionItemQuery({
 *   variables: {
 *      StockTransferRequisitionID: // value for 'StockTransferRequisitionID'
 *   },
 * });
 */
export function useGetStockTransferRequisitionItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockTransferRequisitionItemQuery, GetStockTransferRequisitionItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockTransferRequisitionItemQuery, GetStockTransferRequisitionItemQueryVariables>(GetStockTransferRequisitionItemDocument, baseOptions);
      }
export function useGetStockTransferRequisitionItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockTransferRequisitionItemQuery, GetStockTransferRequisitionItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockTransferRequisitionItemQuery, GetStockTransferRequisitionItemQueryVariables>(GetStockTransferRequisitionItemDocument, baseOptions);
        }
export type GetStockTransferRequisitionItemQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionItemQuery>;
export type GetStockTransferRequisitionItemLazyQueryHookResult = ReturnType<typeof useGetStockTransferRequisitionItemLazyQuery>;
export type GetStockTransferRequisitionItemQueryResult = ApolloReactCommon.QueryResult<GetStockTransferRequisitionItemQuery, GetStockTransferRequisitionItemQueryVariables>;
export const CreateStockTransferRequisitionDocument = gql`
    mutation createStockTransferRequisition($input: StockTransferRequisitionInput!, $itemInput: [StockTransferRequisitionItemInput!]) {
  createStockTransferRequisition(input: $input, itemInput: $itemInput) {
    StockTransferRequisitionID
  }
}
    `;
export type CreateStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<CreateStockTransferRequisitionMutation, CreateStockTransferRequisitionMutationVariables>;

/**
 * __useCreateStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `useCreateStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockTransferRequisitionMutation, { data, loading, error }] = useCreateStockTransferRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useCreateStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockTransferRequisitionMutation, CreateStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockTransferRequisitionMutation, CreateStockTransferRequisitionMutationVariables>(CreateStockTransferRequisitionDocument, baseOptions);
      }
export type CreateStockTransferRequisitionMutationHookResult = ReturnType<typeof useCreateStockTransferRequisitionMutation>;
export type CreateStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<CreateStockTransferRequisitionMutation>;
export type CreateStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockTransferRequisitionMutation, CreateStockTransferRequisitionMutationVariables>;
export const UpdateStockTransferRequisitionDocument = gql`
    mutation updateStockTransferRequisition($input: StockTransferRequisitionInput!, $itemInput: [StockTransferRequisitionItemInput!]) {
  updateStockTransferRequisition(input: $input, itemInput: $itemInput) {
    StockTransferRequisitionID
  }
}
    `;
export type UpdateStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<UpdateStockTransferRequisitionMutation, UpdateStockTransferRequisitionMutationVariables>;

/**
 * __useUpdateStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `useUpdateStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockTransferRequisitionMutation, { data, loading, error }] = useUpdateStockTransferRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      itemInput: // value for 'itemInput'
 *   },
 * });
 */
export function useUpdateStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStockTransferRequisitionMutation, UpdateStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStockTransferRequisitionMutation, UpdateStockTransferRequisitionMutationVariables>(UpdateStockTransferRequisitionDocument, baseOptions);
      }
export type UpdateStockTransferRequisitionMutationHookResult = ReturnType<typeof useUpdateStockTransferRequisitionMutation>;
export type UpdateStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<UpdateStockTransferRequisitionMutation>;
export type UpdateStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStockTransferRequisitionMutation, UpdateStockTransferRequisitionMutationVariables>;
export const CreateStockTransferRequisitionItemDocument = gql`
    mutation createStockTransferRequisitionItem($input: StockTransferRequisitionItemInput!) {
  createStockTransferRequisitionItem(input: $input) {
    StockTransferRequisitionItemID
  }
}
    `;
export type CreateStockTransferRequisitionItemMutationFn = ApolloReactCommon.MutationFunction<CreateStockTransferRequisitionItemMutation, CreateStockTransferRequisitionItemMutationVariables>;

/**
 * __useCreateStockTransferRequisitionItemMutation__
 *
 * To run a mutation, you first call `useCreateStockTransferRequisitionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockTransferRequisitionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockTransferRequisitionItemMutation, { data, loading, error }] = useCreateStockTransferRequisitionItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockTransferRequisitionItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStockTransferRequisitionItemMutation, CreateStockTransferRequisitionItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStockTransferRequisitionItemMutation, CreateStockTransferRequisitionItemMutationVariables>(CreateStockTransferRequisitionItemDocument, baseOptions);
      }
export type CreateStockTransferRequisitionItemMutationHookResult = ReturnType<typeof useCreateStockTransferRequisitionItemMutation>;
export type CreateStockTransferRequisitionItemMutationResult = ApolloReactCommon.MutationResult<CreateStockTransferRequisitionItemMutation>;
export type CreateStockTransferRequisitionItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStockTransferRequisitionItemMutation, CreateStockTransferRequisitionItemMutationVariables>;
export const DeleteStockTransferRequisitionDocument = gql`
    mutation deleteStockTransferRequisition($StockTransferRequisitionID: String!) {
  deleteStockTransferRequisition(StockTransferRequisitionID: $StockTransferRequisitionID)
}
    `;
export type DeleteStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<DeleteStockTransferRequisitionMutation, DeleteStockTransferRequisitionMutationVariables>;

/**
 * __useDeleteStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockTransferRequisitionMutation, { data, loading, error }] = useDeleteStockTransferRequisitionMutation({
 *   variables: {
 *      StockTransferRequisitionID: // value for 'StockTransferRequisitionID'
 *   },
 * });
 */
export function useDeleteStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStockTransferRequisitionMutation, DeleteStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStockTransferRequisitionMutation, DeleteStockTransferRequisitionMutationVariables>(DeleteStockTransferRequisitionDocument, baseOptions);
      }
export type DeleteStockTransferRequisitionMutationHookResult = ReturnType<typeof useDeleteStockTransferRequisitionMutation>;
export type DeleteStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<DeleteStockTransferRequisitionMutation>;
export type DeleteStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStockTransferRequisitionMutation, DeleteStockTransferRequisitionMutationVariables>;
export const PostStockTransferRequisitionDocument = gql`
    mutation postStockTransferRequisition($postIDs: [String!]!, $ApprovalStatus: String!, $CompanyID: String!, $input: StockTransferRequisitionRejectInput!) {
  postStockTransferRequisition(postIDs: $postIDs, ApprovalStatus: $ApprovalStatus, CompanyID: $CompanyID, input: $input)
}
    `;
export type PostStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<PostStockTransferRequisitionMutation, PostStockTransferRequisitionMutationVariables>;

/**
 * __usePostStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `usePostStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postStockTransferRequisitionMutation, { data, loading, error }] = usePostStockTransferRequisitionMutation({
 *   variables: {
 *      postIDs: // value for 'postIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostStockTransferRequisitionMutation, PostStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<PostStockTransferRequisitionMutation, PostStockTransferRequisitionMutationVariables>(PostStockTransferRequisitionDocument, baseOptions);
      }
export type PostStockTransferRequisitionMutationHookResult = ReturnType<typeof usePostStockTransferRequisitionMutation>;
export type PostStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<PostStockTransferRequisitionMutation>;
export type PostStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<PostStockTransferRequisitionMutation, PostStockTransferRequisitionMutationVariables>;
export const CancelStockTransferRequisitionDocument = gql`
    mutation cancelStockTransferRequisition($input: StockTransferRequisitionCancelInput!) {
  cancelStockTransferRequisition(input: $input)
}
    `;
export type CancelStockTransferRequisitionMutationFn = ApolloReactCommon.MutationFunction<CancelStockTransferRequisitionMutation, CancelStockTransferRequisitionMutationVariables>;

/**
 * __useCancelStockTransferRequisitionMutation__
 *
 * To run a mutation, you first call `useCancelStockTransferRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStockTransferRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStockTransferRequisitionMutation, { data, loading, error }] = useCancelStockTransferRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelStockTransferRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelStockTransferRequisitionMutation, CancelStockTransferRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelStockTransferRequisitionMutation, CancelStockTransferRequisitionMutationVariables>(CancelStockTransferRequisitionDocument, baseOptions);
      }
export type CancelStockTransferRequisitionMutationHookResult = ReturnType<typeof useCancelStockTransferRequisitionMutation>;
export type CancelStockTransferRequisitionMutationResult = ApolloReactCommon.MutationResult<CancelStockTransferRequisitionMutation>;
export type CancelStockTransferRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelStockTransferRequisitionMutation, CancelStockTransferRequisitionMutationVariables>;
export const CreateAccountMappingDocument = gql`
    mutation createAccountMapping($input: AccountMappingInput!) {
  createAccountMapping(input: $input)
}
    `;
export type CreateAccountMappingMutationFn = ApolloReactCommon.MutationFunction<CreateAccountMappingMutation, CreateAccountMappingMutationVariables>;

/**
 * __useCreateAccountMappingMutation__
 *
 * To run a mutation, you first call `useCreateAccountMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMappingMutation, { data, loading, error }] = useCreateAccountMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMappingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccountMappingMutation, CreateAccountMappingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccountMappingMutation, CreateAccountMappingMutationVariables>(CreateAccountMappingDocument, baseOptions);
      }
export type CreateAccountMappingMutationHookResult = ReturnType<typeof useCreateAccountMappingMutation>;
export type CreateAccountMappingMutationResult = ApolloReactCommon.MutationResult<CreateAccountMappingMutation>;
export type CreateAccountMappingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccountMappingMutation, CreateAccountMappingMutationVariables>;
export const GetAccountMappingDocument = gql`
    query getAccountMapping($CompanyID: String) {
  getAccountMapping(CompanyID: $CompanyID) {
    CompanyID
    ROUMasterCOAID
    LeaseLiabilityMasterCOAID
    DepreciationROUMasterCOAID
    ProvisionDROUMasterCOAID
    InterestExpenseMasterCOAID
    LeaseLiabilitiesMasterCOAID
    AccrualLeaseLiabilitiesMasterCOAID
    GLTerminationMasterCOAID
    CapitalisationROUJournalTypeID
    ROUJournalTypeID
    LeaseLiabilitiesJournalTypeID
  }
}
    `;

/**
 * __useGetAccountMappingQuery__
 *
 * To run a query within a React component, call `useGetAccountMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountMappingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAccountMappingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountMappingQuery, GetAccountMappingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountMappingQuery, GetAccountMappingQueryVariables>(GetAccountMappingDocument, baseOptions);
      }
export function useGetAccountMappingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountMappingQuery, GetAccountMappingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountMappingQuery, GetAccountMappingQueryVariables>(GetAccountMappingDocument, baseOptions);
        }
export type GetAccountMappingQueryHookResult = ReturnType<typeof useGetAccountMappingQuery>;
export type GetAccountMappingLazyQueryHookResult = ReturnType<typeof useGetAccountMappingLazyQuery>;
export type GetAccountMappingQueryResult = ApolloReactCommon.QueryResult<GetAccountMappingQuery, GetAccountMappingQueryVariables>;
export const CreateLeaseContractDocument = gql`
    mutation createLeaseContract($input: LeaseContractInput!) {
  createLeaseContract(input: $input) {
    LeaseContractID
  }
}
    `;
export type CreateLeaseContractMutationFn = ApolloReactCommon.MutationFunction<CreateLeaseContractMutation, CreateLeaseContractMutationVariables>;

/**
 * __useCreateLeaseContractMutation__
 *
 * To run a mutation, you first call `useCreateLeaseContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaseContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaseContractMutation, { data, loading, error }] = useCreateLeaseContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeaseContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLeaseContractMutation, CreateLeaseContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLeaseContractMutation, CreateLeaseContractMutationVariables>(CreateLeaseContractDocument, baseOptions);
      }
export type CreateLeaseContractMutationHookResult = ReturnType<typeof useCreateLeaseContractMutation>;
export type CreateLeaseContractMutationResult = ApolloReactCommon.MutationResult<CreateLeaseContractMutation>;
export type CreateLeaseContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLeaseContractMutation, CreateLeaseContractMutationVariables>;
export const GetLeaseContractDocument = gql`
    query getLeaseContract($CompanyID: String!, $LeaseContractID: String, $statusArr: [String!], $searchValue: String, $skip: Float, $take: Float) {
  getLeaseContract(CompanyID: $CompanyID, LeaseContractID: $LeaseContractID, searchValue: $searchValue, statusArr: $statusArr, skip: $skip, take: $take) {
    CompanyID
    LeaseContractID
    StartDate
    EndDate
    StartJournalDate
    LeaseMonth
    LeasePayment
    TotalLeaseAmount
    DiscountedTotalCashFlows
    AnnualDiscountRate
    ContractNo
    Description
    IsAccountROU
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    Remark
    Attachment {
      fileURL
      mediaType
      name
    }
  }
}
    `;

/**
 * __useGetLeaseContractQuery__
 *
 * To run a query within a React component, call `useGetLeaseContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaseContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaseContractQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      LeaseContractID: // value for 'LeaseContractID'
 *      statusArr: // value for 'statusArr'
 *      searchValue: // value for 'searchValue'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetLeaseContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaseContractQuery, GetLeaseContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaseContractQuery, GetLeaseContractQueryVariables>(GetLeaseContractDocument, baseOptions);
      }
export function useGetLeaseContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaseContractQuery, GetLeaseContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaseContractQuery, GetLeaseContractQueryVariables>(GetLeaseContractDocument, baseOptions);
        }
export type GetLeaseContractQueryHookResult = ReturnType<typeof useGetLeaseContractQuery>;
export type GetLeaseContractLazyQueryHookResult = ReturnType<typeof useGetLeaseContractLazyQuery>;
export type GetLeaseContractQueryResult = ApolloReactCommon.QueryResult<GetLeaseContractQuery, GetLeaseContractQueryVariables>;
export const UpdateLeaseContractDocument = gql`
    mutation updateLeaseContract($input: LeaseContractInput!) {
  updateLeaseContract(input: $input) {
    LeaseContractID
  }
}
    `;
export type UpdateLeaseContractMutationFn = ApolloReactCommon.MutationFunction<UpdateLeaseContractMutation, UpdateLeaseContractMutationVariables>;

/**
 * __useUpdateLeaseContractMutation__
 *
 * To run a mutation, you first call `useUpdateLeaseContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaseContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaseContractMutation, { data, loading, error }] = useUpdateLeaseContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeaseContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLeaseContractMutation, UpdateLeaseContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLeaseContractMutation, UpdateLeaseContractMutationVariables>(UpdateLeaseContractDocument, baseOptions);
      }
export type UpdateLeaseContractMutationHookResult = ReturnType<typeof useUpdateLeaseContractMutation>;
export type UpdateLeaseContractMutationResult = ApolloReactCommon.MutationResult<UpdateLeaseContractMutation>;
export type UpdateLeaseContractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLeaseContractMutation, UpdateLeaseContractMutationVariables>;
export const PostLeaseContractDocument = gql`
    mutation postLeaseContract($LeaseContractID: String!) {
  postLeaseContract(LeaseContractID: $LeaseContractID)
}
    `;
export type PostLeaseContractMutationFn = ApolloReactCommon.MutationFunction<PostLeaseContractMutation, PostLeaseContractMutationVariables>;

/**
 * __usePostLeaseContractMutation__
 *
 * To run a mutation, you first call `usePostLeaseContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostLeaseContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postLeaseContractMutation, { data, loading, error }] = usePostLeaseContractMutation({
 *   variables: {
 *      LeaseContractID: // value for 'LeaseContractID'
 *   },
 * });
 */
export function usePostLeaseContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostLeaseContractMutation, PostLeaseContractMutationVariables>) {
        return ApolloReactHooks.useMutation<PostLeaseContractMutation, PostLeaseContractMutationVariables>(PostLeaseContractDocument, baseOptions);
      }
export type PostLeaseContractMutationHookResult = ReturnType<typeof usePostLeaseContractMutation>;
export type PostLeaseContractMutationResult = ApolloReactCommon.MutationResult<PostLeaseContractMutation>;
export type PostLeaseContractMutationOptions = ApolloReactCommon.BaseMutationOptions<PostLeaseContractMutation, PostLeaseContractMutationVariables>;
export const RejectLeaseContractDocument = gql`
    mutation rejectLeaseContract($LeaseContractID: String!, $input: LeaseContractRejectInput!) {
  rejectLeaseContract(LeaseContractID: $LeaseContractID, input: $input)
}
    `;
export type RejectLeaseContractMutationFn = ApolloReactCommon.MutationFunction<RejectLeaseContractMutation, RejectLeaseContractMutationVariables>;

/**
 * __useRejectLeaseContractMutation__
 *
 * To run a mutation, you first call `useRejectLeaseContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectLeaseContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectLeaseContractMutation, { data, loading, error }] = useRejectLeaseContractMutation({
 *   variables: {
 *      LeaseContractID: // value for 'LeaseContractID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectLeaseContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectLeaseContractMutation, RejectLeaseContractMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectLeaseContractMutation, RejectLeaseContractMutationVariables>(RejectLeaseContractDocument, baseOptions);
      }
export type RejectLeaseContractMutationHookResult = ReturnType<typeof useRejectLeaseContractMutation>;
export type RejectLeaseContractMutationResult = ApolloReactCommon.MutationResult<RejectLeaseContractMutation>;
export type RejectLeaseContractMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectLeaseContractMutation, RejectLeaseContractMutationVariables>;
export const GetToDoSummaryDocument = gql`
    query getToDoSummary($RoleID: String!) {
  getToDoSummary(RoleID: $RoleID) {
    PendingCB
    PendingGL
    PendingAR
    PendingAP
  }
}
    `;

/**
 * __useGetToDoSummaryQuery__
 *
 * To run a query within a React component, call `useGetToDoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoSummaryQuery({
 *   variables: {
 *      RoleID: // value for 'RoleID'
 *   },
 * });
 */
export function useGetToDoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoSummaryQuery, GetToDoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoSummaryQuery, GetToDoSummaryQueryVariables>(GetToDoSummaryDocument, baseOptions);
      }
export function useGetToDoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoSummaryQuery, GetToDoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoSummaryQuery, GetToDoSummaryQueryVariables>(GetToDoSummaryDocument, baseOptions);
        }
export type GetToDoSummaryQueryHookResult = ReturnType<typeof useGetToDoSummaryQuery>;
export type GetToDoSummaryLazyQueryHookResult = ReturnType<typeof useGetToDoSummaryLazyQuery>;
export type GetToDoSummaryQueryResult = ApolloReactCommon.QueryResult<GetToDoSummaryQuery, GetToDoSummaryQueryVariables>;
export const GetWorkDeskHistoryDocument = gql`
    query getWorkDeskHistory($tableIdentifier: String!) {
  getWorkDeskHistory(tableIdentifier: $tableIdentifier) {
    createdTs
    createdBy
    RefID
    DocDate
    RefTable
    CompanyID
    DocNo
    RefNo
    DocAmt
    Description
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetWorkDeskHistoryQuery__
 *
 * To run a query within a React component, call `useGetWorkDeskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkDeskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkDeskHistoryQuery({
 *   variables: {
 *      tableIdentifier: // value for 'tableIdentifier'
 *   },
 * });
 */
export function useGetWorkDeskHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkDeskHistoryQuery, GetWorkDeskHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkDeskHistoryQuery, GetWorkDeskHistoryQueryVariables>(GetWorkDeskHistoryDocument, baseOptions);
      }
export function useGetWorkDeskHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkDeskHistoryQuery, GetWorkDeskHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkDeskHistoryQuery, GetWorkDeskHistoryQueryVariables>(GetWorkDeskHistoryDocument, baseOptions);
        }
export type GetWorkDeskHistoryQueryHookResult = ReturnType<typeof useGetWorkDeskHistoryQuery>;
export type GetWorkDeskHistoryLazyQueryHookResult = ReturnType<typeof useGetWorkDeskHistoryLazyQuery>;
export type GetWorkDeskHistoryQueryResult = ApolloReactCommon.QueryResult<GetWorkDeskHistoryQuery, GetWorkDeskHistoryQueryVariables>;
export const GetWorkDeskToDoDocument = gql`
    query getWorkDeskToDo($tableIdentifier: String!) {
  getWorkDeskToDo(tableIdentifier: $tableIdentifier) {
    createdTs
    createdBy
    RefID
    CompanyID
    CompanyName
    BankAccountID
    BankCode
    BankAccountNo
    FromBankAccountID
    FromBankCode
    FromBankAccountNo
    ToBankAccountID
    ToBankCode
    ToBankAccountNo
    DocDate
    RefTable
    DocNo
    RefNo
    DocAmt
    Description
    WorkFlow {
      WorkFlowID
      WorkFlowStatus
      SubmitDate
      SubmitterDetail {
        ID
        name
      }
      WorkFlowStep {
        StepNo
        WorkFlowStepID
        ApprovalStatus
        ApproverRoleID
        RoleDetail {
          ID
          name
        }
        WorkFlowApprovedStep {
          WorkFlowApprovedStepID
          ApproverDetail {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetWorkDeskToDoQuery__
 *
 * To run a query within a React component, call `useGetWorkDeskToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkDeskToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkDeskToDoQuery({
 *   variables: {
 *      tableIdentifier: // value for 'tableIdentifier'
 *   },
 * });
 */
export function useGetWorkDeskToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkDeskToDoQuery, GetWorkDeskToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkDeskToDoQuery, GetWorkDeskToDoQueryVariables>(GetWorkDeskToDoDocument, baseOptions);
      }
export function useGetWorkDeskToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkDeskToDoQuery, GetWorkDeskToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkDeskToDoQuery, GetWorkDeskToDoQueryVariables>(GetWorkDeskToDoDocument, baseOptions);
        }
export type GetWorkDeskToDoQueryHookResult = ReturnType<typeof useGetWorkDeskToDoQuery>;
export type GetWorkDeskToDoLazyQueryHookResult = ReturnType<typeof useGetWorkDeskToDoLazyQuery>;
export type GetWorkDeskToDoQueryResult = ApolloReactCommon.QueryResult<GetWorkDeskToDoQuery, GetWorkDeskToDoQueryVariables>;
export const ApproveRejectWorkDeskDocument = gql`
    mutation approveRejectWorkDesk($WorkDeskInput: [WorkDeskInput!]!) {
  approveRejectWorkDesk(WorkDeskInput: $WorkDeskInput)
}
    `;
export type ApproveRejectWorkDeskMutationFn = ApolloReactCommon.MutationFunction<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>;

/**
 * __useApproveRejectWorkDeskMutation__
 *
 * To run a mutation, you first call `useApproveRejectWorkDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRejectWorkDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRejectWorkDeskMutation, { data, loading, error }] = useApproveRejectWorkDeskMutation({
 *   variables: {
 *      WorkDeskInput: // value for 'WorkDeskInput'
 *   },
 * });
 */
export function useApproveRejectWorkDeskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>(ApproveRejectWorkDeskDocument, baseOptions);
      }
export type ApproveRejectWorkDeskMutationHookResult = ReturnType<typeof useApproveRejectWorkDeskMutation>;
export type ApproveRejectWorkDeskMutationResult = ApolloReactCommon.MutationResult<ApproveRejectWorkDeskMutation>;
export type ApproveRejectWorkDeskMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>;