import theme from '@ifca-root/react-component/src/assets/theme'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import StandardTabs from '@ifca-root/react-component/src/components/Tabs/StandardTabs'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { useMediaQuery } from '@material-ui/core'
import AuditLog from 'assets/icons/MFRS16/auditlog.svg'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  RecordStatus,
  useCreateAccountMappingMutation,
  useGetAccountMappingQuery,
  useGetCompanyNameQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { InboundEInvoice } from './Form/APInboundForm'

export const InboundTab = (props: any) => {
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [value, setValue] = useState(0)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  let history = useHistory()

  const { CompanyID }: any = useParams()
  const [mergeMapData, setMergeMapData] = useState({
    CompanyID: CompanyID,
    ROUMasterCOAID: null,
    LeaseLiabilityMasterCOAID: null,
    DepreciationROUMasterCOAID: null,
    ProvisionDROUMasterCOAID: null,
    InterestExpenseMasterCOAID: null,
    LeaseLiabilitiesMasterCOAID: null,
    AccrualLeaseLiabilitiesMasterCOAID: null,
    GLTerminationMasterCOAID: null,
    CapitalisationROUJournalTypeID: null,
    ROUJournalTypeID: null,
    LeaseLiabilitiesJournalTypeID: null,
  })

  // ACCOUNTX API CALLS
  const { data: { getCompany } = { getCompany: [] } } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
    },
  })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const {
    loading: AccountMappingLoading,
    error: AccountMappingError,
    data: { getAccountMapping } = { getAccountMapping: [] },
  } = useGetAccountMappingQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      //orderByAsc: 'JournalType'
    },
    onCompleted: ({ getAccountMapping }) => {
      if (getAccountMapping?.length > 0) {
        setMergeMapData(getAccountMapping?.[0])
      }
    },
  })

  const [
    createAccountMapping,
    { loading: mutationLoading, error: mutationError },
  ] = useCreateAccountMappingMutation({
    onError: error => {
      // snackBar(error.message)
    },
    onCompleted: data => {
      if (getAccountMapping) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.updateRecord())
      } else {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.createNewRecord())
      }
      setTimeout(() => {
        history.push({
          pathname: `/mfrs16/${CompanyID}/`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = data => {
    createAccountMapping({
      variables: {
        input: {
          ...mergeMapData,
        },
      },
    })
  }

  return (
    <>
      {AccountMappingLoading && <Loading />}
      {mutationLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-payable/${CompanyID}`)
          localStorage.removeItem('searchFilter')
        }}
        smTitle="Account Payable"
        title={getCompany[0]?.Name}
        routeSegments={[{ name: 'In Bound e-Invoices', current: true }]}
      />

      <StandardTabs
        value={value}
        onChange={handleChange}
        responsive
        fullWidth
        tabs={[
          {
            label: `e-apps`,
            content: (
              <InboundEInvoice
                companyID={CompanyID}
                accountMapData={mergeMapData}
                setAccountMapData={setMergeMapData}
              />
            ),
          },
          {
            label: `Imported`,
            content: (
              <InboundEInvoice
                companyID={CompanyID}
                accountMapData={mergeMapData}
                setAccountMapData={setMergeMapData}
                onSubmit={onSubmit}
              />
            ),
          },
        ]}
      />
    </>
  )
}
